<div class="modal-body rounded-top p-0 settingsbody">
    <div class="row p-0 m-0">
        <div class="col-md-4 p-0 m-0 d-flex">
            <div class="tabs m-3 nav w-100 flex-column no-caret">
                <a
                    class="nav-link h6 mb-0 py-3"
                    id="account-tablink"
                    (click)="switchTab(tabs.Account)"
                    [ngClass]="tabClass(tabs.Account)">
                    {{ 'settings.account' | translate | uppercase }}
                </a>
                <a
                    *ngIf="isSettingsEnabled"
                    class="nav-link h6 mb-0 py-3"
                    id="settings-tablink"
                    (click)="switchTab(tabs.Settings)"
                    [ngClass]="tabClass(tabs.Settings)">
                    {{ 'settings.settings' | translate | uppercase }}
                </a>
                <a
                    *ngIf="appState.isOptician"
                    class="nav-link h6 mb-0 py-3"
                    id="lensconfig-tablink"
                    (click)="switchTab(tabs.LensConfig)"
                    [ngClass]="tabClass(tabs.LensConfig)">
                    {{ 'settings.lensconfiguration' | translate | uppercase }}
                </a>
                <a
                    class="nav-link h6 mb-0 py-3"
                    id="about-tablink"
                    (click)="switchTab(tabs.About)"
                    [ngClass]="tabClass(tabs.About)">
                    {{ 'settings.about' | translate | uppercase }}
                </a>
            </div>
        </div>

        <div class="col-md-8 tabcontent p-0" style="max-height: 100%">
            <div *ngIf="tab === tabs.Account" class="m-4">
                <ng-container *ngTemplateOutlet="account"></ng-container>
            </div>
            <div *ngIf="tab === tabs.Settings" class="m-2">
                <ng-container *ngTemplateOutlet="settings"></ng-container>
            </div>
            <div *ngIf="tab === tabs.LensConfig" class="m-2">
                <ng-container *ngTemplateOutlet="lensConfiguration"></ng-container>
            </div>
            <div *ngIf="tab === tabs.Documentation" class="m-2">
                <ng-container *ngTemplateOutlet="documentation"></ng-container>
            </div>
            <div *ngIf="tab === tabs.About" class="m-2">
                <ng-container *ngTemplateOutlet="about"></ng-container>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer accented-background d-flex">
    <div class="d-flex w-100">
        <div class="me-2">
            <button type="button" id="close" class="btn btn-default me-auto mt-auto" (click)="bsModalRef.hide()">
                {{ 'general.close' | translate }}
            </button>
        </div>
        <div class="ms-auto">
            <button *ngIf="tab === tabs.Settings" class="btn btn-primary" (click)="toRegionPage()">
                {{ 'general.selectregion' | translate }}
            </button>
            <button
                *ngIf="tab === tabs.LensConfig"
                id="saveLensConfiguration"
                type="button"
                class="btn btn-success"
                (click)="saveLensConfiguration()">
                {{ 'general.save' | translate }}
            </button>
            <button
                *ngIf="tab === tabs.Settings"
                id="saveSettings"
                type="button"
                class="btn btn-success"
                (click)="saveSettings()">
                {{ 'general.save' | translate }}
            </button>
        </div>
    </div>
</div>

<ng-template #account>
    <password-settings></password-settings>
    <account-settings></account-settings>
</ng-template>

<ng-template #settings>
    <settings></settings>
</ng-template>

<ng-template #lensConfiguration>
    <lens-configuration-settings></lens-configuration-settings>
</ng-template>

<ng-template #documentation>
    <h3>{{ 'settings.documentation' | translate | uppercase }}</h3>
</ng-template>

<ng-template #about>
    <div class="mt-4">
        <div class="mx-auto visavyCE"></div>
        <div class="d-flex justify-content-center mt-2">
            <div>
                {{ 'general.version' | translate }}:
                @if (appVersion === apiVersion) {
                    {{ appVersion }}
                } @else {
                    {{ 'general.app' | translate }}: {{ appVersion }} &dash; {{ 'general.api' | translate }}:
                    {{ apiVersion }}
                }
            </div>
        </div>
    </div>
</ng-template>
