import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FileUploadComponent, FileUploadStatus } from '@app/shared/components/fileupload/fileupload.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UploadOutput } from 'ngx-uploader';
import { takeWhile } from 'rxjs/operators';

@Component({
    selector: 'upload-file-dialog',
    templateUrl: 'upload-file-dialog.component.html',
})
export class UploadFileDialogComponent {
    @ViewChild('fileUploader') fileUploadComponent: FileUploadComponent;
    @Output() onOutput = new EventEmitter<UploadOutput>();

    @Input() title: string;
    @Input() content: string;
    @Input() uploadUrl: string;

    constructor(public bsModalRef: BsModalRef) {}

    public close(): void {
        this.bsModalRef.hide();
    }

    public uploadFiles(event: boolean): void {
        if (event) {
            if (
                !this.fileUploadComponent ||
                !this.fileUploadComponent.files ||
                this.fileUploadComponent.files.length === 0
            ) {
                return;
            }

            const uploadFormData = {
                IsInternal: true,
            };

            this.fileUploadComponent.startUpload(uploadFormData, this.uploadUrl);
            this.fileUploadComponent.uploadStatus.pipe(takeWhile((u) => u === FileUploadStatus.Completed)).subscribe();
        }
    }

    public handleOnOutput(event: UploadOutput): void {
        this.onOutput.emit(event);
    }
}
