import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Serial, SerialOrder } from '@app/shared/models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SerialService {
    private readonly basePath = 'api/serials';

    constructor(private readonly httpClient: HttpClient) {}

    public findBySerialNumber(serialNumber: string): Observable<Serial> {
        const options = {
            params: new HttpParams().set('serialNumber', serialNumber),
        };

        return this.httpClient.get<Serial>(this.basePath, options);
    }

    public findBySerialOrderNumber(
        serialNumber: string,
        clientId?: number,
        minimumOrderDate?: Date,
    ): Observable<SerialOrder | null> {
        const options = {
            params: new HttpParams().set('serialNumber', serialNumber),
        };

        if (clientId) {
            options.params.set('clientId', clientId);
        }

        if (minimumOrderDate) {
            const offset = minimumOrderDate.getTimezoneOffset();
            minimumOrderDate = new Date(minimumOrderDate.getTime() - offset * 60 * 1000);

            const safeDate = minimumOrderDate.toISOString().split('T')[0];
            options.params.set('minimumOrderDate', safeDate);
        }

        return this.httpClient.get<SerialOrder>(`${this.basePath}/orders`, options);
    }
}
