import { Component, Input, ViewChild, OnInit, EventEmitter, Output, DestroyRef, inject } from '@angular/core';
import {
    FittedLens,
    Client,
    Proposal,
    TopographicMeasurement,
    OrderWithComplexData,
    LensType,
} from '@app/shared/models';
import { EyeSides, LensTypes, Features, MeasurementImageModes, SupportOrderStatuses } from '@app/shared/enums';
import { UntypedFormGroup, UntypedFormBuilder, FormGroup } from '@angular/forms';
import { ImageOptions } from '@app/shared/models/image-options.model';
import { FittedLensOptions } from '@app/shared/models/fittedLensOptions.model';
import { OrderService } from '@app/core/services/api/order.service';
import { ProposalService } from '@app/core/services/api/proposal.service';
import { FitLensComponent } from '@app/fitlens/fitlens.component';
import { UpdateOrder } from '../../models/UpdateOrder.model';
import { Router } from '@angular/router';
import { ApproveOrder } from '../../models/ApproveOrder.model';
import { CancelOrder } from '../../models/CancelOrder.model';
import { DreamLiteFittedLensInfo } from '@app/professional-service/models/DreamLiteFittedLensInfo.model';
import { FittedLensService } from '@app/core/services/api/fitted-lens.service';
import { NumberFormatPipe } from '@app/shared/pipes/number.format.pipe';
import { FittingEventService } from '@app/core/services/fittingEvent.service';
import { Observable, lastValueFrom } from 'rxjs';
import { LensParameterChange } from '@app/fitlens/models/lensParameterChange.model';
import { FittedLensChange } from '@app/fitlens/models/fittedLensChange.model';
import { MeasurementImageComponent } from '@app/measurement/measurement-image.component';
import { CreateFittedLensBasedOnPreviousOrderRequest } from '@app/shared/models/createFittedLensBasedOnPreviousOrderRequest.model';
import { AlertService } from '@app/shared/appservices/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { ChangeLensTypeResponse } from '@app/shared/models/changeLensTypeResponse.model';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { ReOrder } from '@app/professional-service/models/ReOrder.model';
import { ReOrderDialogComponent } from '@app/professional-service/dialogs/reorder-dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmService } from '@app/shared/appservices/confirm.service';
import { QuantityChange } from '@app/fitlens/models/quantityChange.model';
import { UpdateOrderResponse } from '@app/professional-service/models/UpdateOrderResponse.model';
import IPsSupportOrderComponent from '../ps-supportorder.interface';
import { map } from 'rxjs/operators';
import { BaseValidator } from '@app/shared/validators/BaseValidator';
import { DreamliteSupportBcrValueShouldMoreThanAcValidator } from '@app/fitlens/validators/dreamliteBcrValueShouldMoreThanAcValidator';
import { LensService } from '@app/core/services/api/lens.service';
import { LensTypeMeasurementStatus } from '@app/shared/enums/lens-type-measurement-status.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'ps-order',
    templateUrl: './ps-order.component.html',
    styleUrls: ['ps-order.component.scss'],
})
export class PsOrderComponent implements OnInit {
    @Input() psSupportOrderComponent: IPsSupportOrderComponent;
    @Input() client: Client;

    protected readonly supportValidators: BaseValidator[] = [new DreamliteSupportBcrValueShouldMoreThanAcValidator()];

    private readonly blockingErrors = [
        'dreamliteRcValueShouldBeLessOrEqualThanAc',
        'dreamliteRc2ValueShouldBeLessOrEqualThanAc2',
        'dreamliteSupportBcrValueShouldMoreThanAc',
    ];

    @Input() sessionStorageKeysOrder: string[];
    @Output() sessionStorageKeysOrderChange: EventEmitter<string[]> = new EventEmitter();

    loading = true;
    formGroup: UntypedFormGroup;
    get formControls() {
        return this.formGroup.controls;
    }

    // General data
    order: OrderWithComplexData;
    proposal: Proposal;
    imageOptions: ImageOptions;
    currentMode = 'ps';
    eyeSides = EyeSides;
    lensTypes: LensType[];
    canChangeLensType: boolean;

    // Left Lens
    leftFittedLensEnabled = false;
    leftFittedLens: FittedLens;
    leftFittedLensOptions: FittedLensOptions;
    leftReadOnlyOptions: FittedLensOptions;
    leftMeasurementImageLoading = false;
    leftTopo: TopographicMeasurement;
    leftDreamLites: DreamLiteFittedLensInfo[];
    leftFittedLensSelected = false;
    leftSupportFittedLens: FittedLens;

    @ViewChild('leftFitLens')
    leftFitLensComponent: FitLensComponent;
    @ViewChild('leftMeasurementImage')
    private leftMeasurementImage: MeasurementImageComponent;

    // Right Lens
    rightFittedLensEnabled = false;
    rightFittedLens: FittedLens;
    rightFittedLensOptions: FittedLensOptions;
    rightReadOnlyOptions: FittedLensOptions;
    rightMeasurementImageLoading = false;
    rightTopo: TopographicMeasurement;
    rightDreamLites: DreamLiteFittedLensInfo[];
    rightFittedLensSelected = false;
    rightSupportFittedLens: FittedLens;

    @ViewChild('rightFitLens')
    rightFitLensComponent: FitLensComponent;
    @ViewChild('rightMeasurementImage')
    private rightMeasurementImage: MeasurementImageComponent;
    private lensesFromPreviousOrdersPromise: Promise<FittedLens[]>;
    protected canOrderLenses$: Observable<boolean>;
    private readonly destroyRef = inject(DestroyRef);

    get fitlensRightEyeSelected(): boolean {
        return this.rightFittedLensSelected;
    }
    get fitlensLeftEyeSelected(): boolean {
        return this.leftFittedLensSelected;
    }

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly orderService: OrderService,
        private readonly proposalService: ProposalService,
        private readonly fittedLensService: FittedLensService,
        private readonly numberFormatPipe: NumberFormatPipe,
        private readonly fittingEventService: FittingEventService,
        private readonly lensService: LensService,
        private readonly confirmService: ConfirmService,
        private readonly alertService: AlertService,
        private readonly translate: TranslateService,
        private readonly loaderService: LoaderService,
        private readonly appState: AppStateService,
        private readonly modalService: BsModalService,
        private readonly router: Router,
    ) {}

    async ngOnInit() {
        this.formGroup = this.fb.group({
            'modify-od': [false],
            'modify-os': [false],
        });

        this.checkIfAllowedToOrder();

        if (this.psSupportOrderComponent) {
            this.client = this.psSupportOrderComponent.getClient();
        }

        await this.loadFittedLenses();

        this.prepareImageOptions();
        const lensTypeOptions = await lastValueFrom(this.lensService.getLensTypes());
        this.lensTypes = lensTypeOptions.map((option) => ({
            ...option,
            LensTypeMeasurementStatus: LensTypeMeasurementStatus.None,
        }));
        this.canChangeLensType = this.proposal && this.proposal.LensTypeId !== LensTypes.Glass;
        this.leftFittedLensSelected = this.leftFittedLensEnabled;
        this.rightFittedLensSelected = this.rightFittedLensEnabled;

        this.formGroup.patchValue(
            {
                'modify-od': this.rightFittedLensSelected,
                'modify-os': this.leftFittedLensSelected,
            },
            { emitEvent: false },
        );

        this.formControls['modify-od'].valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((result) => {
            this.rightFittedLensSelected = result;
            if (!result && this.rightFitLensComponent) {
                this.rightFitLensComponent.formGroup.disable();
                this.rightFitLensComponent.formGroup.disable(); // twice because somehow once is not enough sometimes
            }
        });

        this.formControls['modify-os'].valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((result) => {
            this.leftFittedLensSelected = result;
            if (!result && this.leftFitLensComponent) {
                this.leftFitLensComponent.formGroup.disable();
                this.leftFitLensComponent.formGroup.disable(); // twice because somehow once is not enough sometimes
            }
        });

        this.fittingEventService.fittedLensChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((result) => {
            this.fittedLensChanged(result);
        });

        this.fittingEventService.lensParameterChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((result) => {
            this.lensParameterChanged(result);
        });

        this.fittingEventService.quantityChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((result) => {
            this.quantityChanged(result);
        });

        this.loading = false;
    }

    private checkIfAllowedToOrder() {
        this.canOrderLenses$ = this.formGroup.statusChanges.pipe(
            map((status) => {
                if (status === 'VALID') {
                    return true;
                }

                const errors = getFormErrors(this.formGroup);

                // return false if keyErrors contains any of the blocking errors
                const canOrder = !errors.some((keyError) => this.blockingErrors.includes(keyError));

                return canOrder;
            }),
            takeUntilDestroyed(this.destroyRef),
        );

        const getFormErrors = (formGroup: FormGroup): string[] => {
            const keyErrors = Object.keys(formGroup.controls)
                .map((key) => formGroup.get(key).errors)
                .filter((errors) => errors != null)
                .flatMap((errors) => Object.keys(errors).map((key) => key));

            return keyErrors;
        };
    }

    prepareImageOptions(): void {
        if (this.proposal) {
            this.imageOptions = new ImageOptions(this.proposal.LensTypeId, this.proposal.Id);
            this.imageOptions.ShowImageChoice = true;
        }
    }

    lensParameterChanged(lensParameterChange: LensParameterChange): void {
        // BR: topo image should only be refreshed when DIAM parameter was changed on a soft lens
        if (lensParameterChange.newFittedLens.EyeSideId === EyeSides.Os) {
            const shouldRefreshTopoImage =
                this.imageOptions &&
                this.imageOptions.leftImageMode === MeasurementImageModes.HeightMap &&
                lensParameterChange.changedParameter === 'DIAM' &&
                (this.proposal.LensTypeId === LensTypes.Soft || this.proposal.LensTypeId === LensTypes.Rgp);

            this.leftFittedLens = lensParameterChange.newFittedLens;
            if (this.psSupportOrderComponent) {
                this.psSupportOrderComponent.getOrder().LeftFittedLens = lensParameterChange.newFittedLens;
            }
            if (
                this.leftMeasurementImage &&
                (shouldRefreshTopoImage ||
                    this.imageOptions.leftImageMode === MeasurementImageModes.Fluo ||
                    this.imageOptions.leftImageMode === MeasurementImageModes.LensProfile)
            ) {
                this.leftMeasurementImage.refresh(this.leftFittedLens);
            }
        } else if (lensParameterChange.newFittedLens.EyeSideId === EyeSides.Od) {
            const shouldRefreshTopoImage =
                this.imageOptions &&
                this.imageOptions.rightImageMode === MeasurementImageModes.HeightMap &&
                lensParameterChange.changedParameter === 'DIAM' &&
                (this.proposal.LensTypeId === LensTypes.Soft || this.proposal.LensTypeId === LensTypes.Rgp);

            this.rightFittedLens = lensParameterChange.newFittedLens;
            if (this.psSupportOrderComponent) {
                this.psSupportOrderComponent.getOrder().RightFittedLens = lensParameterChange.newFittedLens;
            }
            if (
                this.rightMeasurementImage &&
                (shouldRefreshTopoImage ||
                    this.imageOptions.rightImageMode === MeasurementImageModes.Fluo ||
                    this.imageOptions.rightImageMode === MeasurementImageModes.LensProfile)
            ) {
                this.rightMeasurementImage.refresh(this.rightFittedLens);
            }
        }
    }

    fittedLensChanged(fittedLensChange: FittedLensChange): void {
        if (fittedLensChange.newFittedLens.EyeSideId === EyeSides.Os) {
            this.leftFittedLens = fittedLensChange.newFittedLens;
            if (this.psSupportOrderComponent) {
                this.psSupportOrderComponent.getOrder().LeftFittedLens = fittedLensChange.newFittedLens;
            }

            if (this.leftMeasurementImage) {
                this.leftMeasurementImage.refresh(this.leftFittedLens);
            }
        } else if (fittedLensChange.newFittedLens.EyeSideId === EyeSides.Od) {
            this.rightFittedLens = fittedLensChange.newFittedLens;
            if (this.psSupportOrderComponent) {
                this.psSupportOrderComponent.getOrder().RightFittedLens = fittedLensChange.newFittedLens;
            }
            if (this.rightMeasurementImage) {
                this.rightMeasurementImage.refresh(this.rightFittedLens);
            }
        }
    }

    quantityChanged(quantityChange: QuantityChange): void {
        if (this.psSupportOrderComponent) {
            const order = this.psSupportOrderComponent.getOrder();
            if (quantityChange.eyeSide === EyeSides.Os) {
                const orderline = this.order.OrderLines.find((ol) => ol.EyeSideId === EyeSides.Os);
                orderline.Quantity = quantityChange.newQuantity;
            } else if (quantityChange.eyeSide === EyeSides.Od) {
                const orderline = order.OrderLines.find((ol) => ol.EyeSideId === EyeSides.Od);
                orderline.Quantity = quantityChange.newQuantity;
            }
        }
    }

    async loadFittedLenses() {
        if (this.psSupportOrderComponent) {
            this.proposal = this.psSupportOrderComponent.getProposal();
            this.order = this.psSupportOrderComponent.getOrder();
        } else {
            const lastOrderId = await lastValueFrom(this.orderService.getLastOrderIdForClient(this.client.Id));

            if (lastOrderId) {
                this.order = await lastValueFrom(this.orderService.getByIdWithComplexData(lastOrderId));

                if (this.order) {
                    this.proposal = await lastValueFrom(this.proposalService.getById(this.order.ProposalId));
                }
            }
        }

        if (this.order && this.order.LeftFittedLens) {
            this.leftFittedLens = this.order.LeftFittedLens;
            this.leftSupportFittedLens = this.leftFittedLens;
            this.leftFittedLensEnabled = true;

            if (this.isDreamliteLens()) {
                this.leftDreamLites = await lastValueFrom(
                    this.fittedLensService.getDreamLiteFittedLenses(this.leftFittedLens.EyeSideId, this.client.Id),
                );
            }
        }

        if (this.order && this.order.RightFittedLens) {
            this.rightFittedLens = this.order.RightFittedLens;
            this.rightSupportFittedLens = this.rightFittedLens;
            this.rightFittedLensEnabled = true;

            if (this.isDreamliteLens()) {
                this.rightDreamLites = await lastValueFrom(
                    this.fittedLensService.getDreamLiteFittedLenses(this.rightFittedLens.EyeSideId, this.client.Id),
                );
            }
        }

        this.prepareFittedLensOptions();
        this.initializeLensesFromPreviousOrder();
    }

    prepareFittedLensOptions(): void {
        const recalculate = this.proposal ? true : false;

        this.leftFittedLensOptions = new FittedLensOptions(
            EyeSides.Os,
            this.proposal,
            this.isFollowUp,
            this.proposal?.LensTypeId === LensTypes.Med ? this.leftFittedLens?.LensDefinition?.LensTypeId : null,
            this.appState,
        );

        this.rightFittedLensOptions = new FittedLensOptions(
            EyeSides.Od,
            this.proposal,
            this.isFollowUp,
            this.proposal?.LensTypeId === LensTypes.Med ? this.rightFittedLens?.LensDefinition?.LensTypeId : null,
            this.appState,
        );

        this.leftFittedLensOptions.Recalculate = recalculate;
        this.rightFittedLensOptions.Recalculate = recalculate;

        this.leftFittedLensOptions.ShowHiddenParams = this.isDreamliteLens();
        this.rightFittedLensOptions.ShowHiddenParams = this.isDreamliteLens();

        this.leftFittedLensOptions.ShowThicknessField = this.isThinknessEnabled(this.leftFittedLens);
        this.rightFittedLensOptions.ShowThicknessField = this.isThinknessEnabled(this.rightFittedLens);

        this.rightReadOnlyOptions = structuredClone(this.rightFittedLensOptions);
        this.rightReadOnlyOptions.AllParamsReadOnly = true;

        this.leftReadOnlyOptions = structuredClone(this.leftFittedLensOptions);
        this.leftReadOnlyOptions.AllParamsReadOnly = true;
    }

    imageOptionsChanged(imageOptions: ImageOptions): void {
        this.imageOptions = imageOptions;

        if (this.leftMeasurementImage) {
            this.leftMeasurementImage.imageOptions = this.imageOptions;
            this.leftMeasurementImage.refresh(this.leftFittedLens);
        }

        if (this.rightMeasurementImage) {
            this.rightMeasurementImage.imageOptions = this.imageOptions;
            this.rightMeasurementImage.refresh(this.rightFittedLens);
        }
    }

    imageModeChanged(measurementImageMode: MeasurementImageModes): void {
        if (this.leftMeasurementImage) {
            this.imageOptions.leftImageMode = measurementImageMode;
            this.leftMeasurementImage.imageOptions = this.imageOptions;
            this.leftMeasurementImage.refresh(this.leftFittedLens);
        }

        if (this.rightMeasurementImage) {
            this.imageOptions.rightImageMode = measurementImageMode;
            this.rightMeasurementImage.imageOptions = this.imageOptions;
            this.rightMeasurementImage.refresh(this.rightFittedLens);
        }
    }

    isThinknessEnabled(fittedLens: FittedLens): boolean {
        return !fittedLens?.LensDefinition.Product.Code.includes('MISIGHT') ? true : false;
    }

    get isFollowUp(): boolean {
        return this.proposal && this.proposal.ProposalType && this.proposal.ProposalType.Code === 'followup';
    }

    get isDreamLiteFollowUp(): boolean {
        return this.proposal && this.proposal.ProposalType && this.proposal.ProposalType.Code === 'dreamlitefollowup';
    }

    get currentOrderStatus(): number {
        return this.psSupportOrderComponent.getOrder()?.SupportOrderStatusId;
    }

    get rightLensQuantity(): number {
        return this.getLensQuantity(EyeSides.Od);
    }

    get leftLensQuantity(): number {
        return this.getLensQuantity(EyeSides.Os);
    }

    getLensQuantity(eyeSide: EyeSides): number {
        if (this.psSupportOrderComponent) {
            const orderline = this.psSupportOrderComponent
                .getOrder()
                ?.OrderLines.find((ol) => ol.EyeSideId === eyeSide);
            if (orderline) {
                return orderline.Quantity;
            }
        }

        return 1;
    }

    setViewModel(mode: string) {
        this.currentMode = mode;
    }

    async updateOrder(supportOrderStatusId: number = null): Promise<UpdateOrderResponse> {
        this.loaderService.show();

        if (this.proposal) {
            this.proposal = await lastValueFrom(this.proposalService.saveLensType(this.proposal));
        }

        const uo = new UpdateOrder();

        uo.Order = this.psSupportOrderComponent.getOrder();
        uo.Order.SupporterId = this.psSupportOrderComponent.getSelectedSupporter();
        uo.Order.Supporter = null;

        if (this.leftFittedLens && this.fitlensLeftEyeSelected) {
            const lfs = this.leftFitLensComponent.readFittedLens();
            uo.LeftFittedLens = lfs;
            uo.Order.LeftFittedLens = lfs;
        } else {
            uo.LeftFittedLens = null;
            uo.Order.LeftFittedLens = null;
        }

        if (this.rightFittedLens && this.fitlensRightEyeSelected) {
            const rfs = this.rightFitLensComponent.readFittedLens();
            uo.RightFittedLens = rfs;
            uo.Order.RightFittedLens = rfs;
        } else {
            uo.RightFittedLens = null;
            uo.Order.RightFittedLens = null;
        }

        if (supportOrderStatusId) {
            uo.Order.SupportOrderStatusId = supportOrderStatusId;
        }

        const updatedOrder = await lastValueFrom(this.orderService.updateOrder(uo));

        this.loaderService.hide();
        this.router.navigate(['/ps/supportorderoverview']).then();

        return updatedOrder;
    }

    async approveOrder(isPriority: boolean) {
        const updatedOrder = await this.updateOrder();
        const ao = new ApproveOrder();

        ao.PriorityId = isPriority ? 2 : 1;

        if (updatedOrder.LeftFittedLensId && this.fitlensLeftEyeSelected) {
            ao.LeftFittedLensId = updatedOrder.LeftFittedLensId;
        }

        if (updatedOrder.RightFittedLensId && this.fitlensRightEyeSelected) {
            ao.RightFittedLensId = updatedOrder.RightFittedLensId;
        }

        ao.PONumber = '';
        ao.OrderId = updatedOrder.OrderId;

        await lastValueFrom(this.orderService.approveOrder(ao));
        this.onBack();
    }

    async closeOrder() {
        const title = this.translate.instant('ps.closeOrder');
        const confirmationMessage = this.translate.instant('ps.closeOrderConfirmation');
        const isConfirmed = await this.confirmService.show(title, confirmationMessage);

        if (isConfirmed) {
            const co = new CancelOrder();
            co.OrderId = this.order.Id;

            await lastValueFrom(this.orderService.cancelOrder(co));
            await lastValueFrom(this.psSupportOrderComponent.loadOrder(this.psSupportOrderComponent.workItem));
            this.order = this.psSupportOrderComponent.getOrder();
            this.router.navigate(['/ps/supportorderoverview']).then();
        }
    }

    onBack(): void {
        this.router.navigate(['/ps/supportorderoverview']).then();
    }

    isDreamliteLens(): boolean {
        if (this.proposal && this.proposal.LensTypeId) {
            return this.proposal.LensTypeId === LensTypes.DreamLite;
        }

        return false;
    }

    deleteFictionalDreamLiteLens(dreamLiteFittedLensInfo: DreamLiteFittedLensInfo): void {
        const lensList = dreamLiteFittedLensInfo.EyeSideId === EyeSides.Os ? this.leftDreamLites : this.rightDreamLites;

        const index = lensList.indexOf(dreamLiteFittedLensInfo);

        if (dreamLiteFittedLensInfo.ConceptOfFittedLensId === null) {
            return;
        }

        lensList.splice(index, 1);

        this.fittedLensService.deleteFittedLensConcept(dreamLiteFittedLensInfo.Id).subscribe({
            error: () => lensList.splice(index, 0, dreamLiteFittedLensInfo),
        });
    }

    dreamLensSelected(currentDreamLiteFittedLensInfo: DreamLiteFittedLensInfo): void {
        if (currentDreamLiteFittedLensInfo != null) {
            this.fittedLensService.getFittedLens(currentDreamLiteFittedLensInfo.Id).subscribe((fittedLens) => {
                if (fittedLens) {
                    if (fittedLens.EyeSideId === EyeSides.Os) {
                        this.leftFittedLens = fittedLens;
                    } else if (fittedLens.EyeSideId === EyeSides.Od) {
                        this.rightFittedLens = fittedLens;
                    }
                }
            });
        }
    }

    addFictionalDreamLiteLens(eyeSide: EyeSides): void {
        const fittedLens =
            eyeSide === EyeSides.Os
                ? this.leftFitLensComponent.readFittedLens()
                : this.rightFitLensComponent.readFittedLens();
        const lensList = eyeSide === EyeSides.Os ? this.leftDreamLites : this.rightDreamLites;
        const originalFittedLensId = this.getOriginalFittedLensId(lensList);

        if (fittedLens !== null && originalFittedLensId !== null) {
            this.fittedLensService.createConceptOfFittedLens(fittedLens, originalFittedLensId).subscribe((fl) => {
                const newDreamLiteFittedLensInfo = new DreamLiteFittedLensInfo();
                newDreamLiteFittedLensInfo.Id = fl.Id;
                newDreamLiteFittedLensInfo.EyeSideId = fl.EyeSideId;
                newDreamLiteFittedLensInfo.ConceptOfFittedLensId = fl.ConceptOfFittedLensId;
                newDreamLiteFittedLensInfo.copyFittedLensParameterValuesFromFittedLens(
                    fittedLens,
                    this.numberFormatPipe,
                );
                newDreamLiteFittedLensInfo.FitTypeId = fittedLens.LensDefinition.FitTypeId;

                lensList.unshift(newDreamLiteFittedLensInfo);
            });
        }
    }

    getOriginalFittedLensId(dreamLiteFittedLensInfo: DreamLiteFittedLensInfo[]): number {
        let originalFittedLensId = -1;
        let eyeSideId = -1;

        dreamLiteFittedLensInfo.forEach((fl) => {
            if (fl.ConceptOfFittedLensId == null) {
                originalFittedLensId = fl.Id;
                eyeSideId = fl.EyeSideId;
            }
        });

        if (originalFittedLensId === -1 && eyeSideId !== -1) {
            originalFittedLensId = eyeSideId === EyeSides.Os ? this.leftFittedLens.Id : this.rightFittedLens.Id;
        }

        return originalFittedLensId;
    }

    resetDreamLiteLens(eyeSide: EyeSides): void {
        const fittedLens = eyeSide === EyeSides.Os ? this.leftFittedLens : this.rightFittedLens;
        if (fittedLens) {
            this.fittedLensService.recalculateFittedLensUsingBaseLineMeasurements(fittedLens.Id).subscribe((fl) => {
                if (fl) {
                    if (fl.EyeSideId === EyeSides.Os) {
                        this.leftFittedLens = fl;
                    } else if (fl.EyeSideId === EyeSides.Od) {
                        this.rightFittedLens = fl;
                    }
                }
            });
        }
    }

    dreamLiteFixTypeResult(fl: FittedLens) {
        if (fl) {
            if (fl.EyeSideId === EyeSides.Os) {
                this.leftFittedLens = fl;
            } else if (fl.EyeSideId === EyeSides.Od) {
                this.rightFittedLens = fl;
            }
        }
    }

    async generateOrderLine(eyeSideId: number) {
        const requestModel = new CreateFittedLensBasedOnPreviousOrderRequest();
        requestModel.ClientId = this.client.Id;
        requestModel.EyeSideId = eyeSideId;
        requestModel.LensTypeId = this.proposal.LensTypeId;

        this.fittedLensService.createFittedLensBasedOnPreviousOrder(requestModel).subscribe(
            async (fittedLens: FittedLens) => {
                if (eyeSideId === EyeSides.Os) {
                    this.order.LeftFittedLens = fittedLens;
                    this.leftFittedLens = this.order.LeftFittedLens;
                    this.leftFittedLensEnabled = true;
                    this.leftFittedLensSelected = true;
                    this.formControls['modify-os'].patchValue(true, {
                        emitEvent: false,
                    });

                    if (this.isDreamliteLens()) {
                        this.leftDreamLites = await lastValueFrom(
                            this.fittedLensService.getDreamLiteFittedLenses(
                                this.leftFittedLens.EyeSideId,
                                this.client.Id,
                            ),
                        );
                    }
                } else {
                    this.order.RightFittedLens = fittedLens;
                    this.rightFittedLens = this.order.RightFittedLens;
                    this.rightFittedLensEnabled = true;
                    this.rightFittedLensSelected = true;
                    this.formControls['modify-od'].patchValue(true, {
                        emitEvent: false,
                    });

                    if (this.isDreamliteLens()) {
                        this.rightDreamLites = await lastValueFrom(
                            this.fittedLensService.getDreamLiteFittedLenses(
                                this.rightFittedLens.EyeSideId,
                                this.client.Id,
                            ),
                        );
                    }
                }
                this.prepareFittedLensOptions();
                this.prepareImageOptions();
            },
            () => {
                this.alertService.error(this.translate.instant('ps.unabletocreateorderline'));
            },
        );
    }

    availableLensTypes(): LensType[] {
        if (!this.lensTypes) {
            return [];
        }

        if (!this.proposal || !this.proposal.LensTypeId) {
            if (this.appState.isDistributorFeatureEnabled(Features.Crt)) {
                return this.lensTypes.filter((lt) => lt.Id === LensTypes.Crt);
            }

            return this.lensTypes.filter((lt) => lt.Id !== LensTypes.Glass && lt.Id !== LensTypes.Crt);
        }

        if (this.proposal?.Client.Myopie) {
            return this.lensTypes.filter((lt) => lt.Id === LensTypes.Soft || lt.Id === LensTypes.DreamLite);
        }

        return this.lensTypes.filter(
            (lt) => lt.Id !== this.proposal.LensTypeId && lt.Id !== LensTypes.Glass && lt.Id !== LensTypes.Crt,
        );
    }

    async changeLensType(lensTypeId: number): Promise<void> {
        this.loading = true;

        const changeLensTypeResponse: ChangeLensTypeResponse = await lastValueFrom(
            this.fittedLensService.changeLensType(this.order.LeftFittedLens, this.order.RightFittedLens, lensTypeId),
        );

        this.proposal.LensTypeId = lensTypeId;
        this.prepareImageOptions();
        this.prepareFittedLensOptions();
        this.leftFittedLens = changeLensTypeResponse.LeftFittedLensModel;
        this.leftFittedLensOptions.LensTypeId = lensTypeId;
        this.rightFittedLens = changeLensTypeResponse.RightFittedLensModel;
        this.rightFittedLensOptions.LensTypeId = lensTypeId;
        this.loading = false;
    }

    reOrder() {
        const requestModel = new ReOrder();

        requestModel.Order = this.order;

        if (this.leftFittedLens && this.fitlensLeftEyeSelected) {
            requestModel.LeftFittedLens = this.leftFitLensComponent.readFittedLens();
        }

        if (this.rightFittedLens && this.fitlensRightEyeSelected) {
            requestModel.RightFittedLens = this.rightFitLensComponent.readFittedLens();
        }

        const options: unknown = {
            initialState: {
                reOrder: requestModel,
            },
            class: 'modal-half',
        };

        this.modalService.show(ReOrderDialogComponent, options);
    }

    isDistributorSupport(): boolean {
        return this.appState.authenticatedUser && this.appState.authenticatedUser.IsDistributorSupport;
    }

    isMyDistributorOrder(): boolean {
        return (
            this.order &&
            this.appState.authenticatedUser &&
            this.order.Optician.DistributorId === this.appState.authenticatedUser.DistributorId
        );
    }

    isPs(): boolean {
        return this.appState.authenticatedUser && this.appState.authenticatedUser.IsPs;
    }

    hasGlobalSupportAnsweredSaveOption(): boolean {
        return this.order && this.isPs() && this.order.SupportOrderStatusId === SupportOrderStatuses.ToGlobalSupport;
    }

    hasWaitingForResponseSaveOption(): boolean {
        return (
            this.order && this.isMyDistributorOrder() && this.order.SupportOrderStatusId === SupportOrderStatuses.New
        );
    }

    hasNewSaveOption(): boolean {
        return (
            this.order &&
            this.isMyDistributorOrder() &&
            this.order.SupportOrderStatusId === SupportOrderStatuses.WaitingForResponse
        );
    }

    hasSaveButton(): boolean {
        return (
            this.order &&
            (this.hasGlobalSupportAnsweredSaveOption() ||
                this.hasWaitingForResponseSaveOption() ||
                this.hasNewSaveOption())
        );
    }

    hasCloseOrderButton(): boolean {
        if (!this.isMyDistributorOrder()) {
            return false;
        }

        return this.order.PerosOrderNumber
            ? !this.isOrdered()
            : this.isSupportOrder() &&
                  !(
                      this.isOrdered() ||
                      this.order.SupportOrderStatusId === SupportOrderStatuses.Ordered ||
                      this.order.SupportOrderStatusId === SupportOrderStatuses.Closed
                  );
    }

    hasApproveOrderButton(): boolean {
        return (
            this.order &&
            this.isMyDistributorOrder() &&
            this.isSupportOrder() &&
            !(
                this.isOrdered() ||
                this.order.SupportOrderStatusId === SupportOrderStatuses.Ordered ||
                this.order.SupportOrderStatusId === SupportOrderStatuses.Closed
            )
        );
    }

    hasReOrderButton(): boolean {
        return (
            this.order &&
            this.isMyDistributorOrder() &&
            (this.isOrdered() ||
                this.order.SupportOrderStatusId === SupportOrderStatuses.Ordered ||
                this.order.SupportOrderStatusId === SupportOrderStatuses.Closed)
        );
    }

    isGrandvisionOrder(): boolean {
        return !!this.order.PerosOrderNumber;
    }

    isSupportOrder(): boolean {
        return !this.order.SkipOrderEvaluation;
    }

    isOrdered(): boolean {
        return this.order.ReadyForMiddlewareExport || !!this.order.MiddlewareExportDate;
    }

    private initializeLensesFromPreviousOrder(): void {
        if (!this.isFollowUp && !this.isDreamLiteFollowUp) {
            return;
        }

        this.lensesFromPreviousOrdersPromise = lastValueFrom(
            this.fittedLensService.getFromOrders(this.proposal.RightPreviousOrderId, this.proposal.LeftPreviousOrderId),
        );
    }

    lensFromPreviousOrderPromise(eyeSide: EyeSides): () => Promise<FittedLens> {
        return () =>
            new Promise((resolve, reject) => {
                if (!this.isFollowUp && !this.isDreamLiteFollowUp && !this.lensesFromPreviousOrdersPromise) {
                    resolve(null);
                } else {
                    this.lensesFromPreviousOrdersPromise
                        .then((lenses) => resolve(lenses.find((x) => x.EyeSideId === eyeSide)))
                        .catch((err) => reject(err));
                }
            });
    }
}
