<div class="rowstyle">
    <div class="sidebar col-md-4">
        <div class="row select">
            <div class="col-md-9">
                <div class="row selectBar justify-content-center col-md-12">
                    <label>
                        <b>{{ 'translator.searchWord' | translate }}</b>
                    </label>
                    <div class="input-group">
                        <input
                            #searchInput
                            type="text"
                            id="filter-languages"
                            class="form-control"
                            [ngModel]="search$.value"
                            (ngModelChange)="search$.next($event)" />
                        <div class="input-group-append"></div>
                    </div>
                </div>
                <div
                    *ngIf="languages$ | async as languages; else noLanguages"
                    class="row selectBar justify-content-center col-md-12">
                    <label>
                        <b>{{ 'translator.language' | translate }}</b>
                    </label>
                    <div class="input-group">
                        <select
                            required
                            class="form-select"
                            (ngModelChange)="onLanguageChange($event)"
                            [ngModel]="selectedLanguage$ | async">
                            <option *ngFor="let language of languages" [ngValue]="language">
                                {{ language.Name }}
                            </option>
                        </select>
                        <button
                            *ngIf="parentLanguageCode"
                            type="button"
                            id="copy"
                            class="btn btn-warning"
                            (click)="openCopyParentModal()">
                            {{ 'translations.copyParent' | translate }} ({{
                                'language.' + parentLanguageCode | translate
                            }})
                        </button>
                    </div>
                </div>
                <div class="row selectBar justify-content-center col-md-12">
                    <label>
                        <b>{{ 'translator.category' | translate }}</b>
                    </label>
                    <div class="input-group">
                        <select
                            required
                            class="form-select"
                            (ngModelChange)="onCategoryChange($event)"
                            [ngModel]="selectedCategory$ | async">
                            <option *ngFor="let category of categories$ | async" [ngValue]="category">
                                {{ category.CategoryName | translate }}
                                {{
                                    !category.IsTranslatableByTranslator
                                        ? ('translator.systemCategory' | translate)
                                        : ''
                                }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-3 justify-content-center selectBar">
                <p>
                    <b>{{ 'translator.notTranslated' | translate }}</b>
                </p>
                <label class="switch">
                    <input type="checkbox" id="translateToggle" (click)="toggleShowNotTranslated()" />
                    <span class="slider round"></span>
                </label>
            </div>
        </div>

        <div class="row" *ngIf="selectedCategory$ | async">
            <div class="col-5 selectColumn">
                <b>{{ 'general.status' | translate }}</b>
            </div>
            <div class="col-2 selectColumn">
                <b>{{ 'translator.word' | translate }}</b>
            </div>
            <div class="col-3 selectColumn">
                <b>{{ (selectedCategory$ | async)?.CategoryName | translate }}</b>
            </div>
        </div>
        <div class="translation-container">
            <ng-template #noTranslations>
                <div>
                    <div class="box word text-center">
                        <div>
                            <div class="row d-flex">
                                <div>
                                    <b>{{ 'translator.noResult' | translate }}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-container *ngIf="translations as translations; else noTranslations">
                <form *ngFor="let translation of translations">
                    <div>
                        <div (click)="selectTranslation(translation)" class="box word">
                            <div>
                                <div class="row d-flex">
                                    <div
                                        class="col-6"
                                        *ngIf="translation.Translation && translation.Translation.length > 0">
                                        <div class="icon box">
                                            <i class="fa fa-check-circle translated">
                                                {{ 'translator.translated' | translate }}
                                            </i>
                                        </div>
                                    </div>
                                    <div
                                        class="col-6"
                                        *ngIf="!translation.Translation || translation.Translation.length === 0">
                                        <div class="icon box">
                                            <i class="fa fa-times-circle nottranslated">
                                                {{ 'translator.notTranslated' | translate }}
                                            </i>
                                        </div>
                                    </div>
                                    <div class="col-6 marginword">
                                        <div>
                                            <div>
                                                <b>{{ translation.DefaultLanguageTranslation }}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ng-container>
        </div>
    </div>

    <div class="col-md-8">
        <form [formGroup]="currentWordform" *ngIf="currentWordform.get('Id').value">
            <div class="row justify-content-center">
                <h1
                    *ngIf="(selectedLanguage$ | async) && currentWordform.get('DefaultLanguageTranslation').value"
                    class="titel">
                    #{{ currentWordform.get('Code').value }}
                </h1>
                <h1
                    *ngIf="
                        (selectedTranslation$ | async) === null ||
                        !currentWordform.get('DefaultLanguageTranslation').value
                    "
                    class="titel">
                    {{ 'translator.TranslateWord' | translate }}
                </h1>
            </div>

            <div class="row justify-content-center">
                <div class="col-md-5 text-center" style="box-sizing: border-box">
                    <ng-container [ngSwitch]="!!parentLanguageCode">
                        <ng-container *ngSwitchCase="false">
                            <label for="defaultLanguageTextArea" class="translate-label">
                                {{ 'language.en-GB' | translate }}
                            </label>
                        </ng-container>
                        <ng-container *ngSwitchCase="true">
                            <select class="form-select translate-label" (change)="changeBaseLanguage($event)">
                                <option value="en-GB">{{ 'language.en-GB' | translate }}</option>
                                <option value="{{ parentLanguageCode }}">
                                    {{ 'language.' + parentLanguageCode | translate }}
                                </option>
                            </select>
                        </ng-container>
                    </ng-container>
                    <p>
                        <b>{{ 'translator.toTranslate' | translate }}</b>
                    </p>
                    <textarea id="defaultLanguageTextArea" readonly disabled *ngIf="currentBaseLanguage === 'en-GB'">{{
                        currentWordform.get('DefaultLanguageTranslation').value
                    }}</textarea>
                    <textarea id="defaultLanguageTextArea" readonly disabled *ngIf="currentBaseLanguage !== 'en-GB'">{{
                        currentWordform.get('ParentLanguageTranslation').value
                    }}</textarea>
                </div>
                <div class="col-md-5 text-center">
                    <label for="translationTextArea" class="translate-label">
                        {{ (selectedLanguage$ | async)?.Name }}
                    </label>
                    <p>
                        <b>{{ 'translator.translation' | translate }}</b>
                    </p>
                    <div class="editor">
                        <ngx-editor-menu
                            [editor]="editor"
                            [toolbar]="toolbar"
                            [ngClass]="{ 'NgxEditor--Disabled': !this.currentWordform.get('IsHtml').value }"
                            [customMenuRef]="customMenu"></ngx-editor-menu>
                        <ngx-editor [editor]="editor" formControlName="Translation"></ngx-editor>
                        <ng-template #customMenu>
                            <div class="NgxEditor__Seperator"></div>
                            <div class="NgxEditor__MenuItem NgxEditor__MenuItem--Text" (mousedown)="screenSize($event)">
                                <i *ngIf="!this.expanded" class="fa fa-expand"></i>
                                <i *ngIf="this.expanded" class="fa fa-compress"></i>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-md-10 table-container">
                    <table>
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">{{ 'translator.translation' | translate }}</th>
                                <th scope="col">{{ 'general.date' | translate }}</th>
                                <th scope="col">{{ 'settings.roles.Translator' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let history of history$ | async; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td>{{ history.Name }}</td>
                                <td>{{ history.IsTranslatedOn | date: 'shortDate' }}</td>
                                <td>{{ history.IsTranslatedByUsername }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </form>
        <ng-template #noLanguages>
            <div class="text-center translateFilter">
                <h2>{{ 'translator.NoLanguageAssigned' | translate }}</h2>
            </div>
        </ng-template>
    </div>
</div>
<div *footer class="d-flex justify-content-between">
    <div class="d-flex">
        <div>
            <button type="button" class="btn btn-primary me-2" id="import" (click)="openImportTranslationsModal()">
                {{ 'translations.importTranslations' | translate }}
            </button>
        </div>
        <div>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">
                        {{ 'translations.onlyEmptyTranslations' | translate }}
                    </span>
                    <div class="input-group-text">
                        <input type="checkbox" [(ngModel)]="onlyEmptyTranslations" class="me-2" />
                    </div>
                </div>
                <div class="input-group-append">
                    <button class="btn btn-primary" type="button" id="export" (click)="exportTranslations()">
                        {{ 'translations.exportTranslations' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="languagePercentages">
        <div class="progress my-2 mx-2 flex-grow-1 position-relative">
            <div class="progress-bar" role="progressbar" [style.width]="languagePercentages.TranslatedPercentage + '%'">
                <small class="justify-content-center d-flex position-absolute w-100">
                    {{ 'manageLanguages.translationProgress' | translate }}:
                    {{ languagePercentages.TranslatedPercentage }}%
                </small>
            </div>
        </div>

        <div class="progress my-2 mx-2 flex-grow-1 position-relative">
            <div
                class="progress-bar"
                role="progressbar"
                [style.width]="languagePercentages.SystemTranslatedPercentage + '%'">
                <small class="justify-content-center d-flex position-absolute w-100">
                    {{ 'manageLanguages.systemTranslationProgress' | translate }}:
                    {{ languagePercentages.SystemTranslatedPercentage }}%
                </small>
            </div>
        </div>
    </ng-container>
    <div>
        <button type="button" class="btn btn-outline-secondary me-2" (click)="openHelpModal()">
            <i class="fa fa-question-circle"></i>
        </button>
        <button type="button" id="save" class="btn btn-outline-success me-2" (click)="previous()">
            <i class="fa fa-arrow-left"></i>
            {{ 'general.previous' | translate }}
        </button>
        <button type="button" id="save" class="btn btn-outline-success me-2" (click)="next()">
            {{ 'general.next' | translate }}
            <i class="fa fa-arrow-right"></i>
        </button>
        <button type="button" id="save" class="btn btn-success me-2" (click)="save()">
            {{ 'general.save' | translate }}
        </button>
    </div>
</div>
