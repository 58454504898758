import {
    LensDefinitionParameterNumberRange,
    LensDefinitionParameterText,
} from './lens-definition-parameter-range.model';
import { LensDefinitionParameterTranslation } from './lensDefinitionParameterTranslation.model';

export interface SaveLensDefinitionParameterCommand {
    Id?: number;
    ParameterTypeId?: number;
    ColumnIndex?: number;
    LensDefinitionId?: number;
    RankingOrder?: number;
    ExportCode?: string | undefined;
    DefaultValueExpression?: string | undefined;
    CalculateValueExpression?: string | undefined;
    IsReadOnly?: boolean;
    IsHidden?: boolean;
    ExportToERP?: boolean;
    SetInitialClosestRangeValue?: boolean;
    RequireValidValue?: boolean;
    LensDefinitionParameterRanges?: LensDefinitionParameterNumberRange[] | LensDefinitionParameterText[];
    Translations?: LensDefinitionParameterTranslation[];
}
