<!-- Show current lens -->
<div [formGroup]="formGroup" *ngIf="fittedLens" class="flex-fill d-flex flex-column">
    <!-- FILTERS -->
    <div>
        <div
            class="btn-group mb-1 mb-sm-0 filters"
            *ngIf="
                (lensTypeId === lensTypes.Med || lensTypeId === lensTypes.MedPlus) && options.EnableProductGroupFilter
            ">
            <label
                *ngIf="filterProductGroups[productGroupCodes.KC]"
                id="{{ id }}-productGroupFilter-kc"
                class="btn btn-default mb-0"
                [ngClass]="productGroupFilterClass(productGroupCodes.KC)"
                (click)="setProductGroupFilter(productGroupCodes.KC)"
                btnCheckbox>
                {{ 'general.kc' | translate }}
            </label>
            <label
                *ngIf="filterProductGroups[productGroupCodes.EMIN]"
                id="{{ id }}-productGroupFilter-emin"
                class="btn btn-default mb-0"
                [ngClass]="productGroupFilterClass(productGroupCodes.EMIN)"
                (click)="setProductGroupFilter(productGroupCodes.EMIN)"
                btnCheckbox>
                {{ 'general.emin' | translate }}
            </label>
            <label
                *ngIf="filterProductGroups[productGroupCodes.SCLERAL]"
                id="{{ id }}-productGroupFilter-scleral"
                class="btn btn-default mb-0"
                [ngClass]="productGroupFilterClass(productGroupCodes.SCLERAL)"
                (click)="setProductGroupFilter(productGroupCodes.SCLERAL)"
                btnCheckbox>
                {{ 'general.scleral' | translate }}
            </label>
            <label
                *ngIf="filterProductGroups[productGroupCodes.SOFT]"
                id="{{ id }}-productGroupFilter-soft"
                class="btn btn-default mb-0"
                [ngClass]="productGroupFilterClass(productGroupCodes.SOFT)"
                (click)="setProductGroupFilter(productGroupCodes.SOFT)"
                btnCheckbox>
                {{ 'lenstype.soft' | translate }}
            </label>
            <label
                id="{{ id }}-productGroupFilter-all"
                class="btn btn-default mb-0"
                [ngClass]="productGroupFilterClass('')"
                (click)="setProductGroupFilter('')"
                btnCheckbox>
                {{ 'general.all' | translate }}
            </label>
        </div>
        <div class="d-flex flex-row mt-1" [ngClass]="{ 'border-bottom mb-3': appState.isMobile }">
            <div class="btn-group bottomrow filters" *ngIf="lensTypeId !== 3">
                <label
                    id="{{ id }}-lensfilter-s"
                    class="btn btn-default mb-0"
                    *ngIf="lensFilterEnabled(lensFilters.spherical)"
                    [ngClass]="lensFilterClass(lensFilters.spherical)"
                    (click)="setLensFilter(lensFilters.spherical)"
                    btnCheckbox
                    title="{{ 'general.spherical' | translate }}">
                    {{ 'general.sphericalabbr' | translate }}
                </label>
                <label
                    id="{{ id }}-lensfilter-t"
                    class="btn btn-default mb-0"
                    *ngIf="lensFilterEnabled(lensFilters.toric)"
                    [ngClass]="lensFilterClass(lensFilters.toric)"
                    (click)="setLensFilter(lensFilters.toric)"
                    btnCheckbox
                    title="{{ 'general.toric' | translate }}">
                    {{ 'general.toricabbr' | translate }}
                </label>
                <label
                    id="{{ id }}-lensfilter-mf"
                    class="btn btn-default mb-0"
                    *ngIf="lensFilterEnabled(lensFilters.multiFocal)"
                    [ngClass]="lensFilterClass(lensFilters.multiFocal)"
                    (click)="setLensFilter(lensFilters.multiFocal)"
                    btnCheckbox
                    title="{{ 'general.multifocal' | translate }}">
                    {{ 'general.multifocalabbr' | translate }}
                </label>
                <label
                    id="{{ id }}-lensfilter-mft"
                    class="btn btn-default mb-0"
                    *ngIf="lensFilterEnabled(lensFilters.multiFocalToric)"
                    [ngClass]="lensFilterClass(lensFilters.multiFocalToric)"
                    (click)="setLensFilter(lensFilters.multiFocalToric)"
                    btnCheckbox
                    title="{{ 'general.multifocaltoric' | translate }}">
                    {{ 'general.multifocaltoricabbr' | translate }}
                </label>
                <label
                    id="{{ id }}-lensfilter-all"
                    class="btn btn-default mb-0"
                    *ngIf="lensFilterEnabled(lensFilters.all)"
                    [ngClass]="lensFilterClass(lensFilters.all)"
                    (click)="setLensFilter(lensFilters.all)"
                    btnCheckbox
                    title="{{ 'general.all' | translate }}">
                    {{ 'general.all' | translate }}
                </label>
            </div>
            <div class="ms-auto" *ngIf="calculating">
                <i class="fas fa-calculator pulsating"></i>
            </div>
            <div class="ms-auto align-items-center" *ngIf="canCopy">
                <i
                    class="far fa-share-square copylensinfo-button"
                    [ngClass]="{ flipIcon: fittedLens.EyeSideId === 1 }"
                    (click)="copyLensInfo()"
                    id="{{ id }}-copy"></i>
            </div>
        </div>
    </div>

    <!-- LENSPARAMETERS -->
    <div
        class="flex-fill"
        [ngClass]="{
            'p-3 rounded lensparameters': !appState.isMobile,
            pulse: doPulse
        }">
        <div class="form-group row">
            <div class="col-sm-9">
                <lens-selection #lensselection [fitlens]="this" [quantity]="this.quantity"></lens-selection>
            </div>
            <div class="col-sm-3">
                <div class="lensinformation ms-1 mt-auto" *ngIf="options.ShowDatasheets">
                    <product-data-sheets
                        *ngIf="
                            fittedLens.LensDefinition &&
                            fittedLens.LensDefinition.Product &&
                            fittedLens.LensDefinition.Product.DataSheets
                        "
                        id="{{ id }}-datasheets"
                        [productDataSheets]="fittedLens.LensDefinition.Product.DataSheets"></product-data-sheets>
                </div>
            </div>
        </div>

        <div *ngIf="!loading && fittedLens">
            <div *ngIf="isDreamLiteFollowUp && !isPs && !isDistributorSupport">
                <hr />
                <dreamlite-options
                    #dreamliteoptions
                    [fitlens]="this"
                    [fittedLensParameters]="this.fittedLensParameters"
                    [disableConfiguration]="configurationDisabled"></dreamlite-options>
            </div>

            <hr />

            <div formArrayName="parameters" class="form-group row">
                <div class="col-sm-6">
                    <ng-container *ngFor="let paramControl of formGroup.get('parameters')['controls']; let i = index">
                        <div *ngIf="visibleLensParameters[i].LensDefinitionParameter.ColumnIndex === 0">
                            <ng-template
                                *ngTemplateOutlet="tplLensParameter; context: { paramControl, i }"></ng-template>
                        </div>
                    </ng-container>
                </div>

                <div class="col-sm-6">
                    <ng-container *ngFor="let paramControl of formGroup.get('parameters')['controls']; let i = index">
                        <div *ngIf="visibleLensParameters[i].LensDefinitionParameter.ColumnIndex === 1">
                            <ng-template
                                *ngTemplateOutlet="tplLensParameter; context: { paramControl, i }"></ng-template>
                        </div>
                    </ng-container>
                </div>

                @if (
                    fittedLens.LensDefinition.Product.ShowSystemSuggestedValuesMessage &&
                    initialVisibleLensParameterValues.length !== changedVisibleLensDefinitionParameterIds.length &&
                    !isOverrefractionReadonly
                ) {
                    <div class="col-12 mt-2">
                        <div class="alert alert-warning" role="alert">
                            <i class="fa fa-exclamation-circle" placement="right"></i>
                            {{ 'fitlens.information.system-suggested-values-message' | translate }}
                        </div>
                    </div>
                }

                <ng-template #tplLensParameter let-paramControl="paramControl" let-i="i">
                    <mpc-label
                        *ngIf="
                            (visibleLensParameters[i].LensDefinitionParameter.IsReadOnly ||
                                options.AllParamsReadOnly) &&
                            (!isDreamLiteFollowUp || !isPs)
                        "
                        [formControlName]="i"
                        id="{{ id }}-parameter-{{
                            visibleLensParameters[i].LensDefinitionParameter.ParameterType.Code
                        }}"
                        [label]="visibleLensParameters[i].LensDefinitionParameter.Name"
                        [formatting]="
                            (visibleLensParameters[i].LensDefinitionParameter.ParameterType
                            | validateInputType: inputTypes.Text)
                                ? null
                                : visibleLensParameters[i].LensDefinitionParameter.NumberFormatOptions
                        "
                        breakpoint=""></mpc-label>

                    <mpc-input-number
                        stop-propagation
                        *ngIf="
                            !(
                                visibleLensParameters[i].LensDefinitionParameter.IsReadOnly || options.AllParamsReadOnly
                            ) &&
                            (visibleLensParameters[i].LensDefinitionParameter.ParameterType
                                | validateInputType: inputTypes.Number)
                        "
                        [formControlName]="i"
                        id="{{ id }}-parameter-{{
                            visibleLensParameters[i].LensDefinitionParameter.ParameterType.Code
                        }}"
                        [ranges]="
                            visibleLensParameters[i].LensDefinitionParameter.LensDefinitionParameterRanges
                                | lensDefinitionParameterNumberRangeFilter: this
                        "
                        [formatting]="visibleLensParameters[i].LensDefinitionParameter.NumberFormatOptions"
                        [isDisabled]="loading || calculating"
                        [maxLength]="5"
                        [label]="visibleLensParameters[i].LensDefinitionParameter.Name"
                        breakpoint=""
                        [info]="visibleLensParameters[i].LensDefinitionParameter | humanizeExpression: this"
                        [warning]="
                            fittedLens.LensDefinition.Product.ShowSystemSuggestedValuesMessage &&
                            !isOverrefractionReadonly &&
                            !(
                                changedVisibleLensDefinitionParameterIds
                                | isInArray: visibleLensParameters[i].LensDefinitionParameterId
                            )
                                ? ('fitlens.information.system-suggested-value-message' | translate)
                                : ''
                        "
                        [controlHasFormError]="controlHasFormError(visibleLensParameters[i])"
                        [markLabelAsTouched]="isDreamLiteFollowUp"
                        [originalValue]="getOriginalValue(visibleLensParameters[i])"
                        [unit]="getUnit(visibleLensParameters[i].LensDefinitionParameter)"
                        (change)="onInputEvent()"
                        (focusEvent)="onInputFocus($event)"
                        (blur)="onInputBlur($event)"></mpc-input-number>

                    <mpc-input-text
                        *ngIf="
                            !(
                                visibleLensParameters[i].LensDefinitionParameter.IsReadOnly || options.AllParamsReadOnly
                            ) &&
                            visibleLensParameters[i].LensDefinitionParameter.LensDefinitionParameterRanges.length ===
                                0 &&
                            (visibleLensParameters[i].LensDefinitionParameter.ParameterType
                                | validateInputType: inputTypes.Text)
                        "
                        [formControlName]="i"
                        id="{{ id }}-parameter-{{
                            visibleLensParameters[i].LensDefinitionParameter.ParameterType.Code
                        }}"
                        [isDisabled]="loading || calculating"
                        [label]="visibleLensParameters[i].LensDefinitionParameter.Name"
                        [maxLength]="999"
                        [minLength]="5"></mpc-input-text>

                    <div
                        class="form-group row ng-star-inserted"
                        *ngIf="
                            !(
                                visibleLensParameters[i].LensDefinitionParameter.IsReadOnly || options.AllParamsReadOnly
                            ) &&
                            visibleLensParameters[i].LensDefinitionParameter.LensDefinitionParameterRanges.length > 0 &&
                            (visibleLensParameters[i].LensDefinitionParameter.ParameterType
                                | validateInputType: inputTypes.Text)
                        ">
                        <label
                            class="col-form-label my-0 col-4"
                            [ngClass]="{
                                error: paramControl.errors
                            }">
                            {{ visibleLensParameters[i].LensDefinitionParameter.Name | translate }}
                        </label>
                        <div class="col-6">
                            <mpc-select
                                [formControlName]="i"
                                id="{{ id }}-parameter-{{
                                    visibleLensParameters[i].LensDefinitionParameter.ParameterType.Code
                                }}"
                                [items]="
                                    visibleLensParameters[i].LensDefinitionParameter.LensDefinitionParameterRanges
                                        | lensDefinitionParameterListOptionSelector: this
                                "
                                [allowNoSelection]="true"
                                [formControlOnly]="true"
                                [isDisabled]="formDisabled"></mpc-select>
                        </div>
                    </div>
                </ng-template>
            </div>

            <div class="mb-2">
                {{ byoComfortWarning | translate }}
            </div>

            <residual-refraction *ngIf="showResidualRefraction" [fitlens]="this"></residual-refraction>

            <div
                *ngIf="(isPs || isDistributorSupport) && isDreamLite && isMultifocal && !isDreamLiteFollowUp"
                class="mb-2">
                <div>
                    {{ 'lens.nearAdd' | translate }}
                    {{ calculatedAddition | numberFormat: '{"parameterType":"POW"}' }}
                </div>
            </div>

            <previous-lens-params [fitlens]="this"></previous-lens-params>

            <dreamlite-clearance
                *ngIf="(isPs || isDistributorSupport) && isDreamLite"
                [fitlens]="this"
                [collapsable]="true"></dreamlite-clearance>

            <div *ngIf="overrefractionAvailable" class="row mb-2">
                <div class="col-12 mt-3">
                    <div
                        id="toggleOverrefraction"
                        class="title-border-bottom d-flex {{
                            isOverrefractionReadonly ? 'overrefraction-readonly' : ''
                        }}"
                        (click)="toggleOverrefraction()">
                        <div class="flex-fill">
                            <h4 class="pb-0">
                                {{ 'fitlens.overrefraction' | translate }}
                            </h4>
                        </div>
                        <div>
                            <i *ngIf="!isOverrefractionExpanded" class="fas fa-caret-down fa-2x fa-collapse-icon"></i>
                            <i
                                *ngIf="isOverrefractionExpanded && !isOverrefractionReadonly"
                                class="fas fa-caret-up fa-2x fa-collapse-icon"></i>
                        </div>
                    </div>
                    <div *ngIf="isOverrefractionExpanded">
                        <over-refraction
                            id="{{ id }}-overrefraction"
                            [isRegularFollowUp]="isRegularFollowUp"
                            [isDisabled]="loading || calculating"
                            [isreadonly]="isOverrefractionReadonly"
                            [stabilisationDegree]="stabilization"
                            (stabilisationDegreeChange)="onStabilizationChange($event)"
                            (applyOverrefraction)="applyOverrefraction($event)"
                            [(sessionStorageKeysOverrefraction)]="sessionStorageKeysFitlens"></over-refraction>
                    </div>
                </div>
            </div>

            <!-- notch config -->
            <div *ngIf="notchAvailable" class="row mb-2">
                <div class="col-12 mt-3">
                    <div id="notch" class="title-border-bottom d-flex cursor-pointer" (click)="toggleLensNotch()">
                        <div class="flex-fill">
                            <h4 class="pb-0">
                                {{ 'fitlens.notch' | translate }}
                            </h4>
                        </div>
                        <div>
                            <i *ngIf="!isLensNotchExpanded" class="fas fa-caret-down fa-2x fa-collapse-icon"></i>
                            <i
                                *ngIf="isLensNotchExpanded && !isLensNotchReadonly"
                                class="fas fa-caret-up fa-2x fa-collapse-icon"></i>
                        </div>
                    </div>
                    <div *ngIf="isLensNotchExpanded">
                        <lens-notch
                            [initialLensParameters]="fittedLensParameters"
                            [eyeSide]="eyeSide"
                            [stabilizationIsDisabled]="isOverrefractionExpanded || isOverrefractionReadonly"
                            [stabilization]="stabilization"
                            [isReadOnly]="isLensNotchReadonly"
                            (stabilizationChange)="onStabilizationChange($event)"
                            (applyLensNotch)="applyLensNotch($event)"
                            (applyLensNotchReadOnly)="applyLensNotchReadOnly($event)"></lens-notch>
                    </div>
                </div>
            </div>

            <!-- SCLERAL CODE -->
            <mpc-model-label
                *ngIf="showScleralCode()"
                [id]="fittedLens.EyeSideId === 1 ? 'scleralcode-os' : 'scleralcode-od'"
                label="fitlens.scleralcode"
                [value]="scleralCode"
                [labelWidth]="2"></mpc-model-label>
            <!-- SAGITTA -->
            <mpc-model-label
                *ngIf="sagitta"
                [id]="'sagitta'"
                label="fitlens.sagitta"
                [value]="sagitta | translate"
                [labelWidth]="2"
                [customControlClass]="sagittaClass"></mpc-model-label>
        </div>

        <!-- Show initial lens fit for medical lenses -->
        <div
            *ngIf="initialFittedLens && initialFittedLens.LensDefinition.LensTypeId === lensTypes['Med']"
            class="mt-4 mb-2">
            <h4 class="title-border-bottom pb-2">
                {{ 'general.fittinglens' | translate }}
            </h4>
            <div class="row">
                <div class="col-12 col-lg-6 mb-3">
                    <div class="row">
                        <div class="col-6 col-lg-4">
                            {{ 'general.lens' | translate }}
                        </div>
                        <div class="col-6 col-lg-8">
                            {{ initialFittedLens.LensDefinition.Name }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6 col-lg-4">
                            {{ 'general.material' | translate }}
                        </div>
                        <div class="col-6 col-lg-8">
                            {{ initialFittedLens.MaterialColor.Material.Name }}
                            {{ initialFittedLens.MaterialColor.Color.Name }}
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="row">
                        <div class="col">
                            <ng-container *ngFor="let parameter of initialFittedLens.FittedLensParameters">
                                <div
                                    class="row"
                                    *ngIf="
                                        !parameter.LensDefinitionParameter.IsHidden &&
                                        parameter.LensDefinitionParameter.ColumnIndex === 0
                                    ">
                                    <div class="col-7">{{ parameter.LensDefinitionParameter.Name }}:</div>
                                    <div class="col-5">
                                        {{ parameter.Value }}
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <div class="col">
                            <ng-container *ngFor="let parameter of initialFittedLens.FittedLensParameters">
                                <div
                                    class="row"
                                    *ngIf="
                                        !parameter.LensDefinitionParameter.IsHidden &&
                                        parameter.LensDefinitionParameter.ColumnIndex === 1
                                    ">
                                    <div class="col-7">{{ parameter.LensDefinitionParameter.Name }}:</div>
                                    <div class="col-5">
                                        {{ parameter.Value }}
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
