<div class="container-fluid d-flex flex-column flex-fill mt-4 px-0">
    <div
        *ngIf="!!unfinishedProposal"
        id="continuationPopup"
        class="continue-process row mx-0"
        [ngClass]="!appState.isGuiTest ? 'animate' : ''">
        <div class="col-12 align-self-center">
            {{
                unfinishedProposal.WarrantyExchange
                    ? ('client.continuationmessage.warranty' | translate)
                    : ('client.continuationmessage' | translate)
            }}
            <button class="btn btn-primary ms-2" id="continuationYes" (click)="fittingContinue(true)">
                {{ 'general.yes' | translate }}
            </button>
            <button class="btn btn-default ms-2" id="continuationNo" (click)="fittingContinue(false)">
                {{ 'general.no' | translate }}
            </button>
        </div>
    </div>
    <div
        *ngIf="orderBlockedNotification"
        class="spare-warning row mx-0"
        [ngClass]="!appState.isGuiTest ? 'animate' : ''">
        <div class="col-12 align-self-center mx-2">
            {{ orderBlockedText | translate }}
        </div>
        <div class="col-12 align-self-center mb-2">
            <button class="btn btn-success ms-2" (click)="closeSpareOrderBlockedNotification()">
                {{ 'general.close' | translate }}
            </button>
        </div>
    </div>
    <div class="row clientinfo mx-0">
        <div class="col-12 col-md-4 d-flex flex-column px-3 px-lg-4 mb-3">
            <div class="title-border-bottom mb-1">
                <h3>{{ 'general.client' | translate }}</h3>
            </div>
            <div>
                <client-details [client]="client"></client-details>
            </div>
            <div *ngIf="!!client" class="pt-2">
                <div class="row">
                    <div class="col-12 col-sm-8 col-md-12 col-xl-9">
                        <button class="btn btn-secondary btn-block" (click)="editClient()" id="editclient">
                            <i icon="fa fas-pencil-alt"></i>
                            {{ 'client.editclient' | translate }}
                        </button>
                        <button
                            id="myopia-disabled"
                            class="btn btn-secondary btn-block"
                            *ngIf="appState.isMyopiaEnabled && !client.Myopie"
                            (click)="toggleMyopia()">
                            <i class="fas fa-eye"></i>
                            {{ 'client.enablemyopia' | translate }}
                        </button>
                        <button
                            id="myopia-enabled"
                            class="btn btn-secondary btn-block"
                            *ngIf="appState.isMyopiaEnabled && client.Myopie"
                            (click)="toggleMyopia()">
                            <i class="fas fa-eye-slash"></i>
                            {{ 'client.disablemyopia' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-8 mt-3 mt-md-0 d-flex flex-column px-3 px-md-4 mb-3">
            <div class="title-border-bottom">
                <h3>{{ 'general.communicationhistory' | translate }}</h3>
            </div>
            <div class="flex-grow-1">
                <client-communication
                    *ngIf="!!client"
                    [clientId]="client.Id"
                    [showClientNotes]="true"
                    [showOrderNotes]="false"
                    [showAttachments]="true"
                    [isReadOnly]="true"></client-communication>
            </div>
        </div>
    </div>
    <div class="flex-fill px-3 px-lg-4 mt-3 pt-3 d-flex flex-column accented-background">
        <div class="clienttabs mb-1">
            <div
                class="client-tab me-4 mb-1"
                [ngClass]="clientTabClass(1)"
                *hasFeatureSetting="[
                    {
                        Feature: Features.IsReceiptsOnlyEnabled,
                        Category: FeatureCategories.Optician,
                        IsEnabled: false
                    }
                ]">
                <h3>{{ 'general.orderhistory' | translate }}</h3>
                <a href="#" id="orderhistoryLink" (click)="clickTab($event, 1)">
                    <h3>{{ 'general.orderhistory' | translate }}</h3>
                </a>
            </div>
            <div
                class="client-tab me-4 mb-1"
                [ngClass]="clientTabClass(7)"
                *hasFeatureSetting="[
                    {
                        Feature: Features.IsReceiptsOnlyEnabled,
                        Category: FeatureCategories.Optician,
                        IsEnabled: true
                    }
                ]">
                <h3>Receipt History</h3>
                <a href="#" id="receipthistoryLink" (click)="clickTab($event, 7)">
                    <h3>Receipt History</h3>
                </a>
            </div>

            <div class="client-tab me-4 mb-1" [ngClass]="clientTabClass(2)">
                <h3>{{ 'general.measurementhistory' | translate }}</h3>
                <a href="#" id="measurementHistoryLink" (click)="clickTab($event, 2)">
                    <h3>{{ 'general.measurementhistory' | translate }}</h3>
                </a>
            </div>
            <div class="client-tab me-4 mb-1" [ngClass]="clientTabClass(3)" *ngIf="!!client && client.Myopie">
                <h3>{{ 'general.myopiapower' | translate }}</h3>
                <a href="#" id="myopiapowerLink" (click)="clickTab($event, 3)">
                    <h3>{{ 'general.myopiapower' | translate }}</h3>
                </a>
            </div>
            <div class="client-tab me-4 mb-1" [ngClass]="clientTabClass(4)" *ngIf="!!client && client.Myopie">
                <h3>{{ 'general.myopiaaxislength' | translate }}</h3>
                <a href="#" id="myopiaaxislengthLink" (click)="clickTab($event, 4)">
                    <h3>{{ 'general.myopiaaxislength' | translate }}</h3>
                </a>
            </div>
            <div class="client-tab me-4 mb-1" [ngClass]="clientTabClass(5)">
                <h3>{{ 'clientnotes.notes' | translate }}</h3>
                <a href="#" id="clientnotes" (click)="clickTab($event, 5)">
                    <h3>{{ 'clientnotes.notes' | translate }}</h3>
                </a>
            </div>
        </div>

        <div id="orderlines" class="flex-fill d-flex" *ngIf="tab === 1 && !!client && !loading">
            <order-lines
                #orderlines
                class="w-100"
                [clientId]="client.Id"
                [isMyopiaActive]="isMyopiaActive"
                (selectedLensFilterChanged$)="onSelectedLensfilterChanged($event)"
                (selectedOrderLinesChanged$)="onSelectedOrderLinesChanged($event)"></order-lines>
        </div>
        <div id="receipthistory" class="flex-fill d-flex" *ngIf="tab === 7 && !!client && !loading">
            <receipt-history
                #receipthistory
                class="w-100"
                [clientId]="client.Id"
                (selectedLensFilterChanged$)="onSelectedLensfilterChanged($event)"></receipt-history>
        </div>
        <div id="measurementhistory" class="col-12 px-0" *ngIf="tab === 2">
            <measurement-history
                #measurementhistory
                [client]="client"
                [inDeleteMode]="inDeleteMode"></measurement-history>
        </div>
        <div id="myopiapower" class="col-12 px-0" *ngIf="tab === 3 && !!client">
            <myopia-power [clientId]="client.Id"></myopia-power>
        </div>
        <div id="myopiaaxislength" class="col-12 px-0" *ngIf="tab === 4 && !!client && client.Myopie">
            <myopia-axis-length [clientId]="client.Id"></myopia-axis-length>
        </div>
        <div id="clientnotes" class="col-12 px-0" *ngIf="tab === 5">
            <client-notes [client]="client" [showInputfield]="true" [inputPosition]="'right'"></client-notes>
        </div>
        <div id="internalnotes" *ngIf="showInternalNotes && tab === 6" class="col-12 px-0">
            <internal-notes [client]="client"></internal-notes>
        </div>
    </div>
</div>

<div *footer>
    <div class="d-flex align-items-center">
        <div class="d-flex align-items-center">
            <a href="#" (click)="previous($event)" id="returntoclients">
                <i class="fas fa-arrow-alt-circle-left me-1"></i>
                {{ 'general.clients' | translate }}
            </a>

            <div *ngIf="tab === 2" class="btn-group btn-group-toggle ms-3" data-toggle="buttons">
                <label
                    class="btn btn-secondary"
                    [ngClass]="{
                        active: currentImageChoice === imageChoice.Current
                    }"
                    (click)="setImageChoice(imageChoice.Current)">
                    <input type="radio" class="btn-check" name="options" id="measurement-current" autocomplete="off" />
                    {{ 'measurement.current' | translate }}
                    <i class="fas fa-check" *ngIf="currentImageChoice === imageChoice.Current"></i>
                </label>
                <label
                    class="btn btn-secondary"
                    [ngClass]="{
                        active: currentImageChoice === imageChoice.Diff
                    }"
                    (click)="setImageChoice(imageChoice.Diff)">
                    <input type="radio" class="btn-check" name="options" id="measurement-diff" autocomplete="off" />
                    {{ 'measurement.diff' | translate }}
                    <i class="fas fa-check" *ngIf="currentImageChoice === imageChoice.Diff"></i>
                </label>
            </div>

            <div *ngIf="tab === 2">
                <button
                    type="button"
                    id="toggleDeleteMeasurements"
                    class="btn ms-4"
                    popover="{{ 'toggleDeleteMeasurements' | translate }}"
                    triggers="mouseenter:mouseleave"
                    [ngClass]="{
                        'btn-danger': inDeleteMode,
                        'btn-secondary': !inDeleteMode
                    }"
                    (click)="this.inDeleteMode = !this.inDeleteMode">
                    <i class="fas fa-times-circle fa-lg"></i>
                </button>
            </div>

            <div *ngIf="tab === 2 && measurementHistoryReloading" class="spinner-border spinner-border-sm mx-1"></div>

            <div
                *hasFeatureSetting="[
                    {
                        Feature: Features.Webshop,
                        Category: FeatureCategories.Optician,
                        IsEnabled: true
                    }
                ]">
                <div *ngIf="tab === 1" class="ms-3">
                    <button type="button" id="webshop" class="btn btn-info" (click)="gotoWebshop()">
                        {{ 'general.webshop' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="tab === 2" class="ms-auto">
            <button
                type="button"
                id="markAsBaseline"
                class="btn btn-info"
                [disabled]="!isMarkAsBaselineEnabled"
                (click)="markAsBaseline()">
                {{ 'measurement.markasbaseline' | translate }}
            </button>
        </div>
        <div *ngIf="tab === 1 || tab === 7" class="ms-auto d-flex">
            <div *ngIf="!isLensInStock" class="ms-auto me-2 row">
                <span class="col-12 align-self-center">{{ 'lens.lensnolongeravailable' | translate }}</span>
            </div>

            <button
                type="button"
                class="btn btn-primary mx-1"
                id="markAsMcBaseLens"
                *ngIf="isMarkAsBaseLensVisible"
                [disabled]="!isMarkAsMcBaseLensAllowed"
                [ngClass]="{ 'btn-proceed': !isMarkAsMcBaseLensAllowed }"
                (click)="markAsMcBaseLens()">
                {{ 'general.markasbaselens' | translate }}
            </button>
            <button
                type="button"
                class="btn btn-glassfit mx-1"
                id="glassFit"
                *ngIf="isGlassFittingAllowed"
                (click)="onGlassFitClick()">
                {{ 'general.fitglass' | translate }}
            </button>
            <button
                type="button"
                class="btn btn-proceed mx-1"
                id="fulfillAnnualSupply"
                *hasFeatureSetting="[
                    {
                        Feature: Features.AnnualSupply,
                        Category: FeatureCategories.Company,
                        IsEnabled: true
                    }
                ]"
                [disabled]="!isAvailableForAnnualSupplyFullfillment"
                (click)="onFullfillAnnualSupply()">
                {{ 'annual-supply.fulfill-button' | translate }}
            </button>
            <ng-container
                *hasFeatureSetting="[
                    {
                        Feature: Features.IsReceiptsOnlyEnabled,
                        Category: FeatureCategories.Optician,
                        IsEnabled: false
                    }
                ]">
                <button
                    type="button"
                    class="btn btn-proceed mx-1"
                    id="warranty"
                    *hasFeatureSetting="[
                        {
                            Feature: Features.OpticianLensOrderWarranty,
                            Category: FeatureCategories.Company,
                            IsEnabled: true
                        },
                        { Feature: Features.EnableFollowUp, Category: FeatureCategories.Company, IsEnabled: false }
                    ]"
                    [disabled]="!isFollowUpEnabled"
                    (click)="followUp()">
                    {{ 'general.warranty' | translate }}
                </button>

                <button
                    type="button"
                    class="btn btn-proceed mx-1"
                    id="followUp"
                    *hasFeatureSetting="[
                        {
                            Feature: Features.OpticianLensOrderWarranty,
                            Category: FeatureCategories.Company,
                            IsEnabled: false
                        },
                        { Feature: Features.EnableFollowUp, Category: FeatureCategories.Company, IsEnabled: true }
                    ]"
                    [disabled]="!isFollowUpEnabled"
                    (click)="followUp()">
                    {{ 'general.followup' | translate }}
                </button>

                <button
                    type="button"
                    class="btn btn-proceed mx-1"
                    id="reOrder"
                    [disabled]="!isReOrderEnabled || !isLensInStock || isAvailableForAnnualSupplyFullfillment"
                    (click)="reOrder()">
                    {{ reorderButtonText }}
                </button>
            </ng-container>
            <button
                *hasFeatureSetting="[
                    {
                        Feature: Features.EnableFitLens,
                        Category: FeatureCategories.Company,
                        IsEnabled: true
                    }
                ]"
                type="button"
                class="btn btn-proceed mx-1"
                id="newFit"
                (click)="onNewFitClick()">
                {{ 'general.newfit' | translate }}
            </button>
        </div>
    </div>
</div>
