<div class="loginWrapper flex-fill d-flex flex-grow-1">
    <div class="form ms-4 mt-5">
        <div class="logo-visavy">
            <img src="assets/images/logo-visavy.svg" alt="Visavy" />
        </div>

        <div class="header">
            <h1>{{ 'login.requestnewpassword' | translate }}</h1>
        </div>

        <ng-container *ngIf="!hasRequestedReset">
            <div *ngIf="displayPasswordExpiredMessage" class="passwordExpiredMessage">
                {{ 'passwordExpiry.IsExpired' | translate }}
            </div>
            <div class="description">
                {{ 'login.requestnewpassworddescription' | translate }}
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h2>{{ 'login.username' | translate }}</h2>
                    <input type="text" class="transparent-input" id="username" name="username" [(ngModel)]="username" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h2>{{ 'changepassword.newpassword' | translate }}</h2>
                    <input
                        type="password"
                        class="transparent-input"
                        id="newPassword"
                        name="newPassword"
                        maxlength="25"
                        [(ngModel)]="newPassword" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h2>
                        {{ 'changepassword.repeatnewpassword' | translate }}
                    </h2>
                    <input
                        type="password"
                        class="transparent-input"
                        id="repeatNewPassword"
                        name="repeatNewPassword"
                        maxlength="25"
                        [(ngModel)]="repeatNewPassword" />
                </div>
            </div>
            <div class="row loginError" *ngIf="newPasswordErrors.length > 0">
                <ng-container *ngFor="let err of newPasswordErrors">
                    <div class="col-md-12">
                        <label class="text-danger">{{ err }}</label>
                    </div>
                </ng-container>
            </div>
            <div class="row">
                <div class="col-7 col-sm-12">
                    <button
                        id="resetpassword"
                        (click)="resetPassword()"
                        [disabled]="!isResetButtonEnabled"
                        class="btn btn-primary btn-block">
                        {{ 'login.resetpassword' | translate }}
                    </button>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="hasRequestedReset">
            <div class="description" *ngIf="!isFinished && !isActivatingToken">
                {{ 'login.hasrequestednewpassworddescription' | translate }}
            </div>
            <div class="description" *ngIf="isActivatingToken">
                {{ 'login.activatingtoken' | translate }}
            </div>
            <div class="description" *ngIf="isFinished">
                {{ 'login.finishedresettingpassword' | translate }}
            </div>
            <div class="row">
                <div class="col-7 col-sm-12">
                    <button
                        id="resetOk"
                        (click)="gotoLogin()"
                        [disabled]="isActivatingToken"
                        class="btn btn-primary btn-block">
                        {{ 'general.ok' | translate }}
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
