import { Component, OnInit, EventEmitter, DestroyRef, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription, Subject, Observable, lastValueFrom } from 'rxjs';
import { ClientService } from '@app/core/services/api/client.service';
import { Client, ListSelectOption } from '@app/shared/models';
import { ClientSearchRequest } from '@app/shared/requestmodels';
import { Router, ActivatedRoute } from '@angular/router';
import { InputDate } from '@app/shared/components/inputs';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { FittingService } from '@app/core/services/fitting.service';
import { SessionService } from '@app/shared/appservices/session.service';
import { ProductGroupService } from '@app/core/services/api/product-group.service';
import { LensTypeProductGroupNames } from '@app/shared/models/lensTypeProductGroupNames.model';
import { LensReplacements } from '@app/shared/models/lensReplacements.model';
import { Features, FeatureCategories, FittingSteps } from '@app/shared/enums';
import { GenderService } from '@app/core/services/api/gender.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'fitting-client-search',
    templateUrl: 'fitting-client-search.component.html',
    styleUrls: ['fitting-client-search.component.scss'],
})
export class FittingClientSearchComponent implements OnInit {
    formGroup: UntypedFormGroup;
    get formControls() {
        return this.formGroup.controls;
    }

    Features = Features;
    FeatureCategories = FeatureCategories;
    recentlyOpenedClients: Client[];
    isSearchResultsCollapsed = false;
    isRecentlyOpendClientsCollapsed = false;
    isSearched = false;
    loadingRecentlyOpenedClients = false;
    loadPreviouslyEnteredValue = true;
    clientSearchDataSource: ClientSearchDataSource;
    genders: ListSelectOption[] = [
        new ListSelectOption(2, 'general.female', ''),
        new ListSelectOption(1, 'general.male', ''),
    ];
    myopiaOptions: ListSelectOption[] = [
        new ListSelectOption(1, 'general.yes', ''),
        new ListSelectOption(0, 'general.no', ''),
    ];
    public searchFormChanged: Subscription;
    public clientSearchRequest: Subscription;
    public lensesForReplacement = false;
    public lensesForReplacementList: LensReplacements;
    public lensNames: LensTypeProductGroupNames;

    private readonly destroyRef = inject(DestroyRef);

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly fittingService: FittingService,
        private readonly productGroupService: ProductGroupService,
        private readonly genderService: GenderService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly clientService: ClientService,
        private readonly sessionService: SessionService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.fittingService.setIsGlassFitting(false);
        this.fittingService.setFittingStep(this.router.url);
        this.fittingService.setClient(null);
        this.createForm();

        this.clientSearchDataSource = new ClientSearchDataSource(this.clientService);
        this.lensesForReplacementList = new LensReplacements();

        // Get url parameter
        this.route.queryParams.subscribe((params) => {
            if (params.reset) {
                this.loadPreviouslyEnteredValue = false;
            }
        });

        // try to load previously entered values
        const csr = this.fittingService.state.clientSearchRequest;
        if (this.loadPreviouslyEnteredValue && csr && csr.Client) {
            this.formControls.reference.patchValue(csr.Client.Reference);
            this.formControls.birthDate.patchValue(
                new InputDate(csr.Client.BirthDateYear, csr.Client.BirthDateMonth, csr.Client.BirthDateDay),
            );
            if (csr.Client.GenderId !== undefined) {
                this.formControls.gender.patchValue([new ListSelectOption(csr.Client.GenderId, '', '')]);
            }
            if (csr.Client.Myopie !== undefined) {
                this.formControls.myopia.patchValue([new ListSelectOption(csr.Client.Myopie ? 1 : 0, '', '')]);
            }

            const searchClient = this.readClient();
            this.clientSearchDataSource.init(searchClient);
            this.isSearched = true;
        }

        this.searchFormChanged = this.formGroup.valueChanges
            .pipe(debounceTime(500))
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.clientSearchDataSource.clear();
                this.search();
            });

        this.clientSearchRequest = this.clientSearchDataSource.clientSearchRequest$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((clientSearchRequest: ClientSearchRequest) => {
                this.fittingService.state.clientSearchRequest = clientSearchRequest;
            });

        this.loadRecentlyOpenedClients();
        this.checkForReplacement();

        this.lensNames = await lastValueFrom(this.productGroupService.getLensTypeProductGroupNames());
    }

    searchResultsClass(): string {
        // workaround because collapse functionality doesn't work with d-flex
        if (this.isSearchResultsCollapsed) {
            return '';
        }
        return 'd-flex flex-column';
    }

    loadGenders(): void {
        this.genderService.getGenders().subscribe((result: ListSelectOption[]) => {
            this.genders = result;
        });
    }

    loadRecentlyOpenedClients(): void {
        this.loadingRecentlyOpenedClients = true;
        this.clientService.getRecentlyOpenedClients().subscribe((result: Client[]) => {
            this.recentlyOpenedClients = result;
            this.loadingRecentlyOpenedClients = false;
        });
    }

    checkForReplacement() {
        this.clientService
            .getClientReplacementNotificationsForClientPage()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => {
                if ((result['BYO'] || result['DREAMLITE'] || result['MiSight']) > 0) {
                    this.lensesForReplacement = true;
                }

                this.lensesForReplacementList.BYO = result['BYO'];
                this.lensesForReplacementList.DREAMLITE = result['DREAMLITE'];
                this.lensesForReplacementList.MiSight = result['MiSight'];
            });
    }

    createForm(): void {
        this.formGroup = this.fb.group({
            reference: ['', [Validators.required, Validators.maxLength(100)]],
            birthDate: [[]],
            gender: [[]],
            myopia: [[]],
        });
    }

    search(): void {
        this.isSearchResultsCollapsed = false;
        this.isRecentlyOpendClientsCollapsed = true;

        const searchClient = this.readClient();
        this.clientSearchDataSource.init(searchClient);

        this.isSearched = true;

        if (searchClient == null) {
            this.sessionService.remove('clientsearchrequest');
        } else {
            this.sessionService.save('clientsearchrequest', searchClient);
        }
    }

    hasSearchResults(): boolean {
        return this.clientSearchDataSource && this.clientSearchDataSource.hasResults();
    }

    isSearching(): boolean {
        return this.clientSearchDataSource && this.clientSearchDataSource.isLoading;
    }

    readClient(): Client {
        const c = new Client();
        if (this.formControls.reference.value) {
            c.Reference = this.formControls.reference.value;
        }
        if (this.formControls.birthDate.value) {
            const birthDateVal = this.formControls.birthDate.value as InputDate;

            c.BirthDate = new Date(birthDateVal.year, birthDateVal.month - 1, birthDateVal.day);

            if (birthDateVal.year) {
                c.BirthDateYear = birthDateVal.year;
            }
            if (birthDateVal.month) {
                c.BirthDateMonth = birthDateVal.month;
            }
            if (birthDateVal.day) {
                c.BirthDateDay = birthDateVal.day;
            }
        }

        if (this.formGroup.value.gender && this.formGroup.value.gender.length > 0) {
            c.GenderId = this.formGroup.value.gender[0].Id;
        }
        if (this.formGroup.value.myopia && this.formGroup.value.myopia.length > 0) {
            c.Myopie = this.formGroup.value.myopia[0].Id.toString() === '1';
        } else {
            c.Myopie = null;
        }

        if (
            !!c.BirthDateDay ||
            !!c.BirthDateMonth ||
            !!c.BirthDateYear ||
            c.Reference ||
            c.GenderId ||
            c.Myopie != null
        ) {
            return c;
        }
        return null;
    }

    gotoClient(client: Client): void {
        this.fittingService.setClient(client);
        this.fittingService.gotoStep(FittingSteps.Client);
    }

    gotoClients(): void {
        this.router.navigate(['clients']).then();
    }

    addNewClient(): void {
        this.fittingService.setClient(this.readClient());
        this.router.navigate(['/client/new']).then();
    }
}

export class ClientSearchDataSource extends DataSource<Client> {
    private pageSize = 15;
    private cachedData: Client[] = [];
    private fetchedPages = new Set<number>();
    private dataStream = new Subject<Client[]>();
    private subscription = new Subscription();

    private clientSearchRequest: ClientSearchRequest;
    public clientSearchRequest$: EventEmitter<ClientSearchRequest> = new EventEmitter();

    public isLoading: boolean;

    constructor(public clientService: ClientService) {
        super();
    }

    connect(collectionViewer: CollectionViewer): Observable<Client[]> {
        this.subscription.add(
            collectionViewer.viewChange.subscribe((range) => {
                const startPage = this.getPageForIndex(range.start);
                const endPage = this.getPageForIndex(range.end);

                for (let i = startPage; i <= endPage; i++) {
                    this.fetchPage(i);
                }
            }),
        );
        return this.dataStream;
    }

    clear(): void {
        this.cachedData = [];
        this.fetchedPages.clear();

        this.clientSearchRequest = new ClientSearchRequest();
        this.clientSearchRequest.CurrentPage = 0;
        this.clientSearchRequest.PageSize = this.pageSize;

        this.fetchPage(0);
    }

    init(searchClient: Client): void {
        this.cachedData = [];
        this.fetchedPages.clear();

        this.clientSearchRequest = new ClientSearchRequest();
        this.clientSearchRequest.CurrentPage = 0;
        this.clientSearchRequest.PageSize = this.pageSize;
        this.clientSearchRequest.Client = searchClient;

        this.fetchPage(0);
    }

    disconnect(): void {
        this.subscription.unsubscribe();
    }

    public refresh(): void {
        if (this.clientSearchRequest) {
            this.fetchPage(this.clientSearchRequest.PageSize);
        }
    }

    private getPageForIndex(index: number): number {
        return Math.ceil(index / this.pageSize);
    }

    private fetchPage(page: number): void {
        if (!this.clientSearchRequest || !this.clientSearchRequest.Client) {
            this.cachedData = [];
            this.fetchedPages.clear();
            this.dataStream.next([]);
            this.clientSearchRequest$.emit(this.clientSearchRequest);
            return;
        }

        if (this.fetchedPages.has(page)) {
            return;
        }

        this.isLoading = true;
        this.clientSearchRequest.CurrentPage = page;
        this.fetchedPages.add(page);

        if (this.clientSearchRequest.Client.Reference) {
            this.clientSearchRequest.References = this.clientSearchRequest.Client.Reference;
        }

        this.clientService.search(this.clientSearchRequest).subscribe((result: Client[]) => {
            this.cachedData = this.cachedData.concat(result);
            this.clientSearchRequest$.emit(this.clientSearchRequest);
            this.dataStream.next(this.cachedData);
            this.isLoading = false;
        });
    }

    public hasResults(): boolean {
        return this.cachedData && this.cachedData.length > 0;
    }
}
