<div [formGroup]="formGroup" [ngClass]="addClassName ? addClassName : ''">
    <div *ngIf="type" class="chat-type">{{ type }}</div>

    <div #clientCommunication class="client-communication">
        <div class="messages">
            <div #chatContainer class="align-self-end messages-container" id="messages-container">
                <div class="my-2 text-center">
                    <span class="spinner-border spinner-border-sm text-" [class]="{ invisible: !loading }"></span>
                </div>

                <div *ngFor="let message of messages$ | async; let i = index" class="message-wrapper">
                    <div class="message" [ngClass]="message.isSent() ? 'message-sent' : 'message-received'">
                        <div *ngIf="message.hasAttachments()" class="d-flex attachments">
                            <div class="chat-indicator chat-indicator-recieved" *ngIf="message.isReceived()"></div>
                            <div class="attachment-content p-2" [ngClass]="{ 'ms-auto': message.isSent() }">
                                <div
                                    class="attachment d-inline-block me-1"
                                    *ngFor="let attachment of message.attachments"
                                    (click)="downloadAttachment(attachment)"
                                    title="{{ attachment.FileName }}">
                                    <i class="fas fa-paperclip"></i>
                                </div>
                            </div>
                            <div class="chat-indicator chat-indicator-sent" *ngIf="message.isSent()"></div>
                        </div>

                        <div *ngIf="!message.hasAttachments()" class="d-flex messageline">
                            <div *ngIf="message.isReceived()" class="chat-indicator chat-indicator-received"></div>
                            <div
                                class="message-content p-2"
                                [ngClass]="{
                                    'ms-auto': message.isSent(),
                                    sent: message.isSent(),
                                    received: message.isReceived()
                                }">
                                <span [id]="id + '-message-text'" class="message-text">{{ message.content }}</span>
                                <div class="message-from">
                                    <span class="message-sender me-1">
                                        {{ message.createdOn | mpcdate }} {{ message.createdOn | date: 'HH:mm' }}
                                    </span>
                                    <span class="message-createdby">{{ message.createdBy }}</span>
                                </div>
                            </div>
                            <div *ngIf="message.isSent()" class="chat-indicator chat-indicator-sent"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isReadOnly" class="d-flex mt-0 chatmessage">
        <div class="flex-fill">
            <textarea
                rows="1"
                *ngIf="addClassName !== 'ps-chat'"
                id="chatmessage"
                formControlName="chatmessage"
                class="form-control border-end-0"
                placeholder="{{ 'general.chatmessageplaceholder' | translate }}"></textarea>
            <textarea
                rows="1"
                *ngIf="addClassName === 'ps-chat'"
                (input)="autoGrow($event, false)"
                [id]="id + '-newMessage'"
                formControlName="chatmessage"
                class="form-control border-end-0"
                placeholder="{{ 'ps.chatmessageplaceholder' | translate }}"
                (blur)="onBlurChatMessageText()"></textarea>
        </div>
        <div class="ms-auto">
            <button
                [id]="id + '-sendNoteButton'"
                type="button"
                class="btn btn-primary border-start-0"
                (click)="send($event)"
                [disabled]="savingNote">
                <i *ngIf="savingNote" class="fas fa-save pulsating"></i>
                &nbsp;{{ 'general.send' | translate }}
            </button>
        </div>
    </div>
</div>
