import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SupportEventService {
    private onBlockedOrdersApproved = new Subject<void>();

    blockedOrdersApproved$ = this.onBlockedOrdersApproved.asObservable();

    announceBlockedOrdersApproved() {
        this.onBlockedOrdersApproved.next();
    }
}
