import { Component } from '@angular/core';
import { BaseChartComponent } from '../base-chart.component';
import { AppStateService } from '@app/shared/appservices/appState.service';

@Component({
    selector: 'mpc-linechart',
    templateUrl: './../base-chart.component.html',
})
export class LineChartComponent extends BaseChartComponent {
    constructor(public appState: AppStateService) {
        super();
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public setDataSet(dataset: any): void {
        this.chartOptions = {
            type: 'line',
            data: dataset,
            options: {
                legend: {
                    display: true,
                },
                scales: {
                    y: {
                        ticks: {
                            callback: (value: number) => {
                                return value.toFixed(2);
                            },
                        },
                    },
                },
            },
        };
    }

    public addDataPoint(datasetIndex: number, value: number): void {
        if (!this.chart.data.datasets[datasetIndex]) {
            this.chart.data.datasets.push({ data: [] });
        }

        this.chart.data.datasets[datasetIndex].data.push(value);

        if (this.chart.data.labels.length < this.chart.data.datasets[datasetIndex].data.length) {
            this.addLabel('');
        }
    }

    public addLabel(value: string): void {
        this.chart.data.labels.push(value);
    }

    public setColors(): void {
        throw new Error('Method not implemented.');
    }
}
