import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ReturnConclusionChanges } from '@app/shared/models/returnConclusionChanges.model';

@Component({
    selector: 'app-ps-change',
    templateUrl: './ps-change.component.html',
    styleUrls: ['./ps-change.component.scss'],
})
export class PsChangeComponent implements OnInit, OnChanges {
    formGroup: UntypedFormGroup;

    requestData = ['ReturnId', 'IsCreatedOn', 'IsCreatedByUserName', 'Conclusion', 'ReturnResultId', 'ReturnCreditId'];

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.createForm();
    }

    @Input()
    returnConclusionChanges: ReturnConclusionChanges[];

    createForm(): void {
        this.formGroup = this.fb.group({
            ReturnId: [],
            IsCreatedOn: [],
            IsCreatedByUserName: [],
            Conclusion: [],
            ReturnResultId: [],
            ReturnCreditId: [],
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.returnConclusionChanges.previousValue !== changes.returnConclusionChanges.currentValue) {
            this.returnConclusionChanges = changes.returnConclusionChanges.currentValue;
        }
    }
}
