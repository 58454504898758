export class ProposalReceiptLineInfo {
    EyeSideCode: number;
    FitTypeName: string;
    ProductName: string;
    MaterialAndColorName: string;
    Quantity: number;
    LensEngravingName: string;
    FittedLensParameters: FittedLensParameter[];
    LensTypeId: number;
}

interface FittedLensParameter {
    Id: number;
    Code: string;
    Value: number;
    SortOrder: number;
}
