import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ProposalReceiptService } from '@app/core/services/api/proposal-receipt.service';
import { Router } from '@angular/router';
import { OrderService } from '@app/core/services/api/order.service';
import {
    CreateProposalReceiptOrder,
    FittedLensParameter,
    Proposal,
    ProposalReceipt,
    ProposalReceiptOrder,
} from '@app/shared/models';
import { LensTypes } from '@app/shared/enums';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { InputNumberRange } from '@app/shared/models/input-number-range.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface ReceiptSearchForm {
    Code: FormControl<string>;
    Reference: FormControl<string>;
}

interface ReceiptOrderForm {
    LeftQuantity: FormControl<number>;
    RightQuantity: FormControl<number>;
}

@Component({
    selector: 'app-receipt-order',
    templateUrl: './receipt-order.component.html',
    styleUrls: ['./receipt-order.component.scss'],
})
export class ReceiptOrderComponent implements OnInit {
    searchFormGroup: FormGroup<ReceiptSearchForm>;
    orderFormGroup: FormGroup<ReceiptOrderForm>;
    isLoading = false;
    isSearched = false;
    alreadyUsed = false;
    receipt: ProposalReceipt;
    proposal: Proposal;
    usedReceipts$: Observable<ProposalReceiptOrder[]>;
    leftQuantityRange: InputNumberRange;
    rightQuantityRange: InputNumberRange;

    private readonly destroyRef = inject(DestroyRef);

    constructor(
        public proposalReceiptService: ProposalReceiptService,
        public orderService: OrderService,
        public translate: TranslateService,
        private router: Router,
        public appState: AppStateService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.createSearchForm();

        if (!this.appState.currentOptician.IsApproved) {
            this.usedReceipts$ = this.proposalReceiptService.getProposalReceiptOrdersForOptician();
        }

        this.createOrderForm();
    }

    createOrderForm(): void {
        this.orderFormGroup = new FormGroup<ReceiptOrderForm>({
            LeftQuantity: new FormControl(1, { nonNullable: true }),
            RightQuantity: new FormControl(1, { nonNullable: true }),
        });
    }

    createSearchForm(): void {
        this.searchFormGroup = new FormGroup<ReceiptSearchForm>({
            Code: new FormControl('', { nonNullable: true }),
            Reference: new FormControl('', { nonNullable: true }),
        });
    }

    findReceipt(): void {
        const Reference = this.searchFormGroup.controls['Reference'].value;
        const code = this.searchFormGroup.controls['Code'].value;

        this.isLoading = true;

        this.proposalReceiptService
            .find(Reference, code)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                finalize(() => (this.isLoading = false)),
            )
            .subscribe((findResult) => {
                this.receipt = findResult.ProposalReceipt;
                this.proposal = this.receipt?.Proposal;
                this.alreadyUsed = findResult.AlreadyUsed;
                this.isSearched = true;

                this.leftQuantityRange = new InputNumberRange(
                    0,
                    this.receipt.Proposal?.LeftOpticianFittedLens?.LensDefinition?.Product?.MaxQuantity,
                    1,
                    1,
                );
                this.rightQuantityRange = new InputNumberRange(
                    0,
                    this.receipt?.Proposal?.RightOpticianFittedLens?.LensDefinition?.Product?.MaxQuantity,
                    1,
                    1,
                );
            });
    }

    order(): void {
        const createOrderRequest = new CreateProposalReceiptOrder();
        createOrderRequest.ProposalReceiptId = this.receipt.Id;
        createOrderRequest.LeftQuantity = this.orderFormGroup.controls['LeftQuantity'].value;
        createOrderRequest.RightQuantity = this.orderFormGroup.controls['RightQuantity'].value;

        if (this.appState.currentOptician.IsApproved) {
            this.orderService
                .createOrderFromProposalReceipt(createOrderRequest)
                .pipe()
                .subscribe((orderId) => this.router.navigate(['/receipt/order-sent', orderId]));
        } else {
            this.proposalReceiptService
                .claimProposalReceipt(createOrderRequest)
                .pipe()
                .subscribe(() => this.router.navigate(['/receipt/order-sent']));
        }
    }

    getFormatObject(param: FittedLensParameter): object {
        return {
            parameterType: param.LensDefinitionParameter.ParameterType.Code,
        };
    }

    get lensTypeTitle(): string {
        switch (this.proposal.LensTypeId) {
            case LensTypes.Crt:
            case LensTypes.DreamLite:
                return 'lenstype.ortho-k';
            case LensTypes.Med:
                return 'lenstype.med';
            case LensTypes.MedPlus:
                return 'lenstype.med-plus';
            case LensTypes.Rgp:
                return 'lenstype.rgp';
            case LensTypes.Soft:
                return 'lenstype.soft';
            default:
                return '';
        }
    }

    get lensTypeImage(): string {
        switch (this.proposal.LensTypeId) {
            case LensTypes.Crt:
            case LensTypes.DreamLite:
                return 'lens-dreamlite.svg';
            case LensTypes.Med:
                return 'lens-med.svg';
            case LensTypes.MedPlus:
                return 'lens-med-plus.svg';
            case LensTypes.Rgp:
                return 'lens-rgp.svg';
            case LensTypes.Soft:
                return 'lens-soft.svg';
            default:
                return '';
        }
    }
}
