import { Role } from './role.model';
import { Roles } from '@app/shared/enums';

export class AuthenticatedUser {
    constructor(authenticatedUser: AuthenticatedUser) {
        if (authenticatedUser) {
            this.UserId = authenticatedUser.UserId;
            this.UserName = authenticatedUser.UserName;
            this.Name = authenticatedUser.Name;
            this.AccessToken = authenticatedUser.AccessToken;
            this.RefreshToken = authenticatedUser.RefreshToken;
            this.Timestamp = authenticatedUser.Timestamp;
            this.CurrentApplicationTheme = authenticatedUser.CurrentApplicationTheme;
            this.CurrentLanguageId = authenticatedUser.CurrentLanguageId;
            this.CurrentLanguageCode = authenticatedUser.CurrentLanguageCode;
            this.CurrentOpticianId = authenticatedUser.CurrentOpticianId;
            this.CurrentOpticianName = authenticatedUser.CurrentOpticianName;
            this.CurrentRoleId = authenticatedUser.CurrentRoleId;
            this.CurrentRoleName = authenticatedUser.CurrentRoleName;
            this.DistributorId = authenticatedUser.DistributorId;
            this.CompanyId = authenticatedUser.CompanyId;
            this.Roles = authenticatedUser.Roles;
            this.LicenseAgreementApprovedOn = authenticatedUser.LicenseAgreementApprovedOn;
            this.UserRegulationsApprovedOn = authenticatedUser.UserRegulationsApprovedOn;
            this.VisavyPlatformPrivacyPolicyApprovedOn = authenticatedUser.VisavyPlatformPrivacyPolicyApprovedOn;
            this.TrainingCompleteApprovedOn = authenticatedUser.TrainingCompleteApprovedOn;
        }
    }

    UserId: number;
    UserName: string;
    Name: string;
    EmailAddress: string;
    AccessToken: string;
    RefreshToken: string;
    Timestamp: number;
    CurrentApplicationTheme: string;
    CurrentLanguageId: number;
    CurrentLanguageCode: string;
    CurrentOpticianId: number;
    CurrentOpticianName: string;
    CurrentRoleId: number;
    CurrentRoleName: string;
    DistributorId: number;
    CompanyId: number;
    LicenseAgreementApprovedOn: Date;
    UserRegulationsApprovedOn: Date;
    VisavyPlatformPrivacyPolicyApprovedOn: Date;
    TrainingCompleteApprovedOn: Date;
    Roles: Role[];

    public HasRole(role: Roles): boolean {
        return this.Roles && this.Roles.some((r) => r.Id === role);
    }

    get IsAdministrator(): boolean {
        return (
            this.CurrentRoleId === Roles.Administrator &&
            this.Roles &&
            this.Roles.some((r) => r.Id === Roles.Administrator)
        );
    }

    get IsOptician(): boolean {
        return this.CurrentRoleId === Roles.Optician && this.Roles && this.Roles.some((r) => r.Id === Roles.Optician);
    }

    get IsPs(): boolean {
        return this.CurrentRoleId === Roles.Ps && this.Roles && this.Roles.some((r) => r.Id === Roles.Ps);
    }

    get IsSales(): boolean {
        return this.CurrentRoleId === Roles.Sales && this.Roles && this.Roles.some((r) => r.Id === Roles.Sales);
    }

    get IsLensConfigurationAdmin(): boolean {
        return (
            this.CurrentRoleId === Roles.LensConfigurationAdmin &&
            this.Roles &&
            this.Roles.some((r) => r.Id === Roles.LensConfigurationAdmin)
        );
    }

    get IsDistributorSupport(): boolean {
        return (
            this.CurrentRoleId === Roles.DistributorSupport &&
            this.Roles &&
            this.Roles.some((r) => r.Id === Roles.DistributorSupport)
        );
    }

    get IsDistributorAdmin(): boolean {
        return (
            this.CurrentRoleId === Roles.DistributorAdmin &&
            this.Roles &&
            this.Roles.some((r) => r.Id === Roles.DistributorAdmin)
        );
    }
}
