import { Injectable } from '@angular/core';
import { LocalStorageService } from '../../../shared/appservices/localstorage.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { SessionService } from '@app/shared/appservices/session.service';

@Injectable({
    providedIn: 'root',
})
export class ProductTourService {
    storageKey = 'product-tours';
    storageKeyReminder = this.storageKey + '-reminder';

    constructor(
        private readonly localstorageService: LocalStorageService,
        private readonly sessionService: SessionService,
        private readonly appState: AppStateService,
    ) {}

    isTourAvailable(): boolean {
        if (this.appState.isGuiTest) {
            return false;
        }

        // @TODO: Removed when in production, for now it gives us the option to showcase functionality to stakeholders
        const isSessionSet = this.localstorageService.get('tour');
        return isSessionSet ? true : false;
        // Remove until this point

        return true;
    }

    markTourAsDone(date: Date): void {
        this.localstorageService.save(this.storageKey, date.toISOString());
    }

    setTourReminder(date: Date): void {
        this.sessionService.save(this.storageKeyReminder, date.toISOString());
    }

    showTourModal(): boolean {
        const tourDone: string = this.localstorageService.get(this.storageKey);
        if (tourDone) {
            this.sessionService.remove(this.storageKeyReminder);
            return false;
        }

        const reminderSet = this.sessionService.get(this.storageKeyReminder);
        if (reminderSet && new Date(reminderSet) > new Date()) {
            return false;
        }

        return true;
    }
}
