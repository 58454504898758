<div class="modal-header">
    <h4 class="modal-title m-auto">
        {{ 'dreamlitetable.fixtypes' | translate }}
    </h4>
</div>

<div class="modal-body dreamlite-history" [formGroup]="formGroup">
    <div class="row mb-2 table-header">
        <div class="col">{{ 'dreamlitefixtypes.name' | translate }}</div>
        <div class="col">{{ 'dreamlitefixtypes.degree' | translate }}</div>
        <div class="col">{{ 'dreamlitefixtypes.correction' | translate }}</div>
    </div>

    <div class="row dreamlitetable-row table-row mb-1" *ngFor="let fixType of filteredFixTypes">
        <div class="col">
            <label>
                <input type="radio" class="me-2" formControlName="selectedFixType" [value]="fixType.Code" />
                {{ fixType.Name }}
            </label>
        </div>

        <div class="col">
            <div *ngIf="fixType.ShowDegree">
                <input type="radio" class="me-2" formControlName="{{ fixType.Code }}" value="1" [checked]="true" />
                <input type="radio" class="me-2" formControlName="{{ fixType.Code }}" value="2" />
                <input type="radio" class="me-2" formControlName="{{ fixType.Code }}" value="3" />
            </div>
        </div>

        <div class="col">
            <div *ngIf="fixType.ShowCorrection">
                <mpc-input-number
                    formControlName="{{ fixType.Code }}"
                    [formControlOnly]="true"
                    [id]="fixType.Code"
                    [ranges]="fixTypeRanges[fixType.Code]"
                    (click)="correctionChanged(fixType)"></mpc-input-number>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer accented-background">
    <button class="btn next ms-auto" (click)="ok()">
        {{ 'general.apply' | translate }}
    </button>
    <button class="btn btn-secondary ms-2" (click)="cancel()">
        {{ 'general.cancel' | translate }}
    </button>
</div>
