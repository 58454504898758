import { Component } from '@angular/core';
import { ServiceStatus } from '@app/shared/enums';
import { ServiceRequest } from '@app/shared/models/service-request.model';

@Component({
    selector: 'app-complaintsoverview',
    templateUrl: './complaintsoverview.component.html',
    styleUrls: ['./complaintsoverview.component.scss'],
})
export class ComplaintsOverviewComponent {
    creditTypeSelected = null;
    returnStatuses = ServiceStatus;
    returnStatusIds = [this.returnStatuses.AwaitingProduct];
    creditCategory = false;
    returnSelected: ServiceRequest;
    redirectReturnSelected = false;

    constructor() {
        // Do nothing
    }

    receiveReturnParameters($event: ServiceRequest) {
        this.returnSelected = $event;
    }

    receiveReturnRedirect($event: boolean) {
        this.redirectReturnSelected = $event;
    }

    onClickType(key: number) {
        this.creditTypeSelected = null;
        this.returnStatusIds = [key];
        this.creditCategory = false;
    }

    onClickCreditType(type: number) {
        this.creditTypeSelected = type;
        if (this.creditTypeSelected === 1) {
            this.returnStatusIds = [
                this.returnStatuses.AwaitingProduct,
                this.returnStatuses.QaHandling,
                this.returnStatuses.Feedback,
                this.returnStatuses.PsHandling,
            ];
            this.creditCategory = true;
        } else if (this.creditTypeSelected === 2) {
            this.returnStatusIds = [this.returnStatuses.Handled];
            this.creditCategory = true;
        }
    }

    checkIfSelected(statusId: number): string {
        if (this.creditCategory === false) {
            return this.returnStatusIds.includes(statusId) ? 'on' : '';
        }
    }
}
