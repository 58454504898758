import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NavbarItem } from '@app/shared/models/navbarItem.model';

@Component({
    selector: 'navbar-item',
    templateUrl: './navbar-item.component.html',
    styleUrls: ['navbar-item.component.scss'],
})
export class NavbarItemComponent {
    @Input() navbarItem: NavbarItem;
    @Input() navbarClass: string;
    @Output() click: EventEmitter<unknown> = new EventEmitter<unknown>();
}
