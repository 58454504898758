export enum ParameterTypeCodes {
    RC = 'RC',
    RC2 = 'RC2',
    AC = 'AC',
    AC2 = 'AC2',
    RAD = 'RAD',
    ARAD = 'ARAD',
    DIAM = 'DIAM',
    RMF = 'RMF',
    RZD1SCRN = 'RZD1SCRN',
    RZD2SCRN = 'RZD2SCRN',
    LZA1SCRN = 'LZA1SCRN',
    LZA2SCRN = 'LZA2SCRN',
    SAG = 'SAG',
    ECC = 'ECC',
    AECC = 'AECC',
    PEFA = 'PEFA',
    TORI = 'TORI',
    LCF = 'LCF',
    CYL = 'CYL',
    AX = 'AX',
    PRIS = 'PRIS',
    PROF = 'PROF',
    STAB = 'STAB',
    OZ = 'OZ',
    DIAMDIST = 'DIAMDIST',
    POW = 'POW',
    ADD = 'ADD',
}
