import { Component, OnInit, Input } from '@angular/core';
import { FitLensComponent } from '../fitlens.component';
import { OverrefractionParameter } from '@app/shared/models/overRefractionParameter.model';
import { lensInfoFromPreviousOrder } from '@app/shared/models/lensInfoFromPreviousOrder.model';
import { FittedLensParameter } from '@app/shared/models';

@Component({
    selector: 'previous-lens-params',
    templateUrl: 'previouslensparams.component.html',
    styleUrls: ['previouslensparams.component.scss'],
})
export class PreviousLensParamsComponent implements OnInit {
    @Input() fitlens: FitLensComponent;

    public infoFromPreviousOrder: lensInfoFromPreviousOrder;

    ngOnInit() {
        this.initializeInfoFromPreviousOrder().then();
    }

    public async initializeInfoFromPreviousOrder(): Promise<void> {
        if (!this.fitlens.isFitlensPage || !this.isFollowUp) {
            return;
        }

        const lens = await this.fitlens.lensFromPreviousOrderPromise();
        if (!lens) {
            return;
        }

        this.infoFromPreviousOrder = new lensInfoFromPreviousOrder();
        this.infoFromPreviousOrder.lensOptions = [];
        this.infoFromPreviousOrder.lensName = lens.LensDefinition.Name;

        for (const param of lens.FittedLensParameters) {
            const paramObj = Object.assign(new FittedLensParameter(), param);

            if (!paramObj.LensDefinitionParameter) {
                continue;
            }

            // Skip parameter
            let name = paramObj.LensDefinitionParameter.Name;
            if (paramObj.LensDefinitionParameter.IsHidden) {
                continue;
            }

            if (this.fitlens.showScleralCode() && name.includes('BCR')) {
                name = 'BCR';
            }

            this.infoFromPreviousOrder.lensOptions.push({
                name: name,
                value: paramObj.GenericValue,
                code: paramObj.LensDefinitionParameter.ParameterType.Code,
            });
        }

        this.fitlens.lensFromPreviousOrder = lens;
    }

    getOverrefractionInfo(): OverrefractionParameter[] {
        const result: OverrefractionParameter[] = [];
        if (this.fitlens.refractionMeasurement) {
            const addParam = (name: string, code: string, value: number) => {
                if (!value && value !== 0) {
                    return;
                }
                const param = new OverrefractionParameter();
                param.Name = name;
                param.Code = code;
                param.Value = value;
                result.push(param);
            };

            addParam('general.spherical', 'POW', this.fitlens.refractionMeasurement.Sphere);
            addParam('general.cilinder', 'CYL', this.fitlens.refractionMeasurement.Cylinder);
            addParam('general.axis', 'AX', this.fitlens.refractionMeasurement.Axis);
        }

        return result;
    }

    get showOverRefractionInfo(): boolean {
        return this.fitlens.options.ShowOverRefractionInfo;
    }

    get isFollowUp(): boolean {
        return this.fitlens.isRegularFollowUp || this.fitlens.isDreamLiteFollowUp;
    }

    get isDreamLiteFollowUp(): boolean {
        return this.fitlens.isDreamLiteFollowUp;
    }

    get lensSelectionEnabled(): boolean {
        return (
            !this.fitlens.isDreamLiteFollowUp &&
            !this.fitlens.showConvertToMcButton &&
            !this.fitlens.showConvertToMfButton &&
            !this.fitlens.convertedToMf
        );
    }
}
