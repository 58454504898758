import { LensDefinitionGeometryElement } from '@app/shared/models/lensDefinitionGeometryElement.model';

export class LensDefinitionGeometryAstigmaticSphere extends LensDefinitionGeometryElement {
    constructor() {
        super();
        this.$type = 'LensDefinitionGeometryAstigmaticSphere';
        this.InstanceType = 'AstigmaticSphere';
    }

    public AstigmaticSphereRadius: string;
    public AstigmaticSphereAstigmaticRadius: string;
    public AstigmaticSphereExcentricityStart: string;
    public AstigmaticSphereExcentricityEnd: string;
    public AstigmaticSphereAstigmaticExcentricityStart: string;
    public AstigmaticSphereAstigmaticExcentricityEnd: string;
    public AstigmaticSphereSemiDiameterStart: string;
    public AstigmaticSphereSemiDiameterEnd: string;
    public EdgeLiftZone: string;
    public EdgeLift: string;
    public SDiam1: string;
    public CubicEdgeLiftSDiam: string;
    public CubicEdgeLift: string;

    // CalculationResults
    public AstigmaticSphereRadiusResult: number;
    public AstigmaticSphereAstigmaticRadiusResult: number;
    public AstigmaticSphereExcentricityStartResult: number;
    public AstigmaticSphereExcentricityEndResult: number;
    public AstigmaticSphereAstigmaticExcentricityStartResult: number;
    public AstigmaticSphereAstigmaticExcentricityEndResult: number;
    public AstigmaticSphereSemiDiameterStartResult: number;
    public AstigmaticSphereSemiDiameterEndResult: number;
    public EdgeLiftZoneResult: number;
    public EdgeLiftResult: number;
    public SDiam1Result: number;
    public CubicEdgeLiftSDiamResult: number;
    public CubicEdgeLiftResult: number;
}
