import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, FormGroup, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { OverrefractionParameter } from '@app/shared/models/overRefractionParameter.model';
import { InputNumberRange } from '@app/shared/models/input-number-range.model';
import { RefractionFieldsConfiguration } from '@app/shared/helpers/refraction-fields-configuration';
import { LensDefinitionParameterNumberRange } from '@app/shared/models/lens-definition-parameter-range.model';
import { OverRefractionResult } from '@app/fitlens/models/OverrefractionResult.model';
import { RefractionFieldsConfigurationService } from '@app/core/services/refractionFieldsConfiguration.service';
import { SessionService } from '@app/shared/appservices/session.service';
import { FittedLensService } from '@app/core/services/api/fitted-lens.service';

interface OverrefractionForm {
    spheric: FormControl<number>;
    cylinder: FormControl<number>;
    axis: FormControl<number>;
    addition: FormControl<number>;
    vertex: FormControl<number>;
    stabilisation: FormControl<number>;
}

@Component({
    selector: 'over-refraction',
    templateUrl: 'over-refraction.component.html',
    styleUrls: ['over-refraction.component.scss'],
})
export class OverRefractionComponent implements OnInit {
    parentFormGroup: UntypedFormGroup;
    private readonly ComponentId = 'OverRefractionComponent';

    @Input() id = 'overrefraction';
    @Input() isDisabled = false;
    @Input() isreadonly = false;
    @Input() isRegularFollowUp = false;

    @Input() stabilisationDegree: number;
    @Output() stabilisationDegreeChange: EventEmitter<number> = new EventEmitter();

    @Output() isLoading: EventEmitter<boolean> = new EventEmitter();
    @Output() applyOverrefraction: EventEmitter<OverRefractionResult> = new EventEmitter();

    @Input() sessionStorageKeysOverrefraction: string[];
    @Output() sessionStorageKeysOverrefractionChange: EventEmitter<string[]> = new EventEmitter();

    formGroup: FormGroup<OverrefractionForm>;

    private refractionConfiguration: RefractionFieldsConfiguration;

    get formControls() {
        return this.formGroup.controls;
    }
    get stabilisationRanges() {
        return [new InputNumberRange(0, 180, 1)];
    }
    get stabilisationLineStyle() {
        return {
            transform: 'rotate(-' + this.stabilisationDegree + 'deg)',
        };
    }

    protected overrefractionParameters: OverrefractionParameter[];

    constructor(
        private readonly parent: FormGroupDirective,
        private readonly refractionConfigurationService: RefractionFieldsConfigurationService,
        private readonly sessionService: SessionService,
        private readonly fittedLensService: FittedLensService,
    ) {
        this.parentFormGroup = this.parent.form;
        this.refractionConfiguration = this.refractionConfigurationService.configuration;
    }

    ngOnInit(): void {
        this.setLoading(true);

        this.createForm();

        this.fittedLensService
            .getOverrefractionParameters()
            .pipe(
                finalize(() => {
                    this.setLoading(false);
                }),
            )
            .subscribe((result) => {
                this.overrefractionParameters = result;
                const value = this.sessionService.get(this.ComponentId + this.id);
                if (!value) {
                    this.setOverrefractionControls();
                } else {
                    this.setOverrefractionControlsSessionData(value);
                }
            });

        this.formControls['stabilisation'].valueChanges.subscribe((value) => {
            this.stabilisationDegreeChange.emit(value);
        });

        Object.keys(this.formControls).forEach((controleName) => {
            this.formControls[controleName].valueChanges.subscribe(() => {
                this.sessionSave();
            });
        });
    }

    createForm(): void {
        this.formGroup = new FormGroup<OverrefractionForm>({
            spheric: new FormControl(0, [Validators.required]),
            cylinder: new FormControl(0, [Validators.required]),
            axis: new FormControl(0, [Validators.required]),
            addition: new FormControl(0, [Validators.required]),
            vertex: new FormControl(0, [Validators.required]),
            stabilisation: new FormControl(this.stabilisationDegree, [Validators.required]),
        });

        this.formGroup.setParent(this.parentFormGroup);

        if (this.parentFormGroup.controls[this.id]) {
            this.parentFormGroup.removeControl(this.id);
        }

        this.parentFormGroup.addControl(this.id, this.formGroup);
    }

    setLoading(loading: boolean) {
        this.isLoading.emit(loading);
    }

    setOverrefractionControls(): void {
        this.formControls['spheric'].setValue(this.overrefractionParameters.find((orp) => orp.Code === 'POW').Value);
        this.formControls['cylinder'].setValue(this.overrefractionParameters.find((orp) => orp.Code === 'CYL').Value);
        this.formControls['axis'].setValue(this.overrefractionParameters.find((orp) => orp.Code === 'AX').Value);
        this.formControls['addition'].setValue(this.overrefractionParameters.find((orp) => orp.Code === 'ADD').Value);
        this.formControls['vertex'].setValue(this.overrefractionParameters.find((orp) => orp.Code === 'VERTD').Value);
        this.sessionSave();
    }

    setOverrefractionControlsSessionData(value: string): void {
        const sessionDataArray = JSON.parse(value);
        for (let i = 0; i < sessionDataArray.length; i++) {
            this.formControls[sessionDataArray[i][0]].setValue(sessionDataArray[i][1]);
        }
    }

    sessionSave(): void {
        const value = [
            ['spheric', this.formControls.spheric.value],
            ['cylinder', this.formControls.cylinder.value],
            ['axis', this.formControls.axis.value],
            ['addition', this.formControls.addition.value],
            ['vertex', this.formControls.vertex.value],
            ['stabilisation', this.formControls.stabilisation.value],
        ];
        this.sessionService.save(this.ComponentId + this.id, value);
        this.sessionStorageKeysOverrefraction.push(this.ComponentId + this.id);
    }

    maxLength(code: string): number {
        return this.refractionConfiguration.maxLength(code);
    }

    getRange(code: string): Array<LensDefinitionParameterNumberRange> {
        return this.refractionConfiguration.getRange(code);
    }

    overrefraction(): void {
        const result = new OverRefractionResult();

        result.Stabilization = this.formControls['stabilisation'].value;

        this.updateOverrefractionParametersArray();

        this.overrefractionParameters.forEach((orp: OverrefractionParameter) => {
            result.OverrefractionParameters.push(orp);
        });

        this.applyOverrefraction.emit(result);
        this.sessionService.remove(this.ComponentId + this.id);
        // (readonly will now be set true by the parent component until the component is reloaded)
    }

    updateOverrefractionParametersArray() {
        this.overrefractionParameters.find((orp) => orp.Code === 'POW').Value = this.formControls['spheric'].value;
        this.overrefractionParameters.find((orp) => orp.Code === 'ADD').Value = this.formControls['addition'].value;
        this.overrefractionParameters.find((orp) => orp.Code === 'CYL').Value = this.formControls['cylinder'].value;
        this.overrefractionParameters.find((orp) => orp.Code === 'AX').Value = this.formControls['axis'].value;
        this.overrefractionParameters.find((orp) => orp.Code === 'VERTD').Value = this.formControls['vertex'].value;
    }

    parameterName(code: string) {
        return this.overrefractionParameters?.find((orp) => orp.Code === code).Name;
    }

    parameterValue(code: string) {
        return this.overrefractionParameters?.find((orp) => orp.Code === code).Value;
    }
}
