import { Component, Input } from '@angular/core';
import { FeatureCategories, Features } from '@app/shared/enums';
import { Optician } from '@app/shared/models';

@Component({
    selector: 'optician-info',
    templateUrl: './optician-info.component.html',
})
export class OpticianInfoComponent {
    Features = Features;
    FeatureCategories = FeatureCategories;

    @Input() optician: Optician;
}
