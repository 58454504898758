import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/core/services/api/authentication.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { SessionService } from '@app/shared/appservices/session.service';
import { LocalStorageService } from '@app/shared/appservices/localstorage.service';
import { InitialNavigationService } from '@app/shared/appservices/initial-navigation.service';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { NotificationService } from '@app/core/services/wss/notification.service';
import { lastValueFrom } from 'rxjs';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    formGroup: UntypedFormGroup;
    loginError: string;

    get f() {
        return this.formGroup.controls;
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private authenticationService: AuthenticationService,
        private notificationService: NotificationService,
        private appState: AppStateService,
        private loaderService: LoaderService,
        private translate: TranslateService,
        private sessionService: SessionService,
        private localStorageService: LocalStorageService,
        private initialNavigationService: InitialNavigationService,
        public appConfigService: AppConfigService,
    ) {}

    ngOnInit() {
        this.createForm();

        const externalToken = this.route.snapshot.queryParamMap.get('externalToken');
        if (externalToken) {
            this.login(externalToken);
        }
    }

    createForm(): void {
        this.formGroup = this.fb.group({
            username: this.fb.control('', Validators.required),
            password: this.fb.control('', Validators.required),
        });
    }

    requestNewPassword() {
        const username = this.f['username'].value;
        this.router.navigate(['login', 'resetpassword', username]).then();
    }

    reselectRegion() {
        this.router.navigate(['/region']).then();
    }

    async login(externalToken?: string) {
        if (!this.formGroup.valid && !externalToken) {
            return;
        }

        this.loaderService.show();
        this.loginError = '';

        this.appState.clearUserData();
        this.sessionService.clearAll();
        this.localStorageService.clearAll();

        try {
            const authenticatedUser = externalToken
                ? await lastValueFrom(this.authenticationService.loginWithExternalToken(externalToken))
                : await lastValueFrom(
                      this.authenticationService.login(this.f['username'].value, this.f['password'].value),
                  );

            this.appState.authenticatedUser = authenticatedUser;
            await this.appState.loadCurrentAuthenticatedUserItems();
            this.appState.setTheme(authenticatedUser.CurrentApplicationTheme);
            this.appState.applyUserLanguage();
            this.notificationService.startConnection();

            await this.appState.loadPasswordExpiryInfo();

            if (this.route.snapshot.params.redirect) {
                this.router.navigateByUrl(this.route.snapshot.params.redirect).then();
            } else {
                await this.initialNavigationService.startInitialNavigationRoute();
            }
        } catch (error) {
            if (error.status === 400) {
                this.loginError = this.translate.instant('ErrorMessages.USERS_USER_LOGIN_FAILURE');

                if (!!error.error?.ModelState?.[''] && error.error?.ModelState['']?.$values?.length) {
                    this.loginError = this.translate.instant(error.error.ModelState[''].$values[0]);
                }
            } else {
                throw error;
            }
        } finally {
            this.loaderService.hide();
        }
    }
}
