import { InputTypes } from '../enums';
import { ParameterTypeTranslation } from './parameterTypeTranslation.model';

export class ParameterType {
    public Id: number;
    public Name: string;
    public Code: string;
    public SortOrder: number;
    public IsFluoImage: boolean;
    public IsOverrefraction: boolean;
    public ValueAlwaysNegative: boolean;
    public ExportCode: string;
    public InputTypeId: InputTypes;
    public Translations: ParameterTypeTranslation[];
}
