import { Optician } from './optician.model';
import { Proposal } from './proposal.model';

export class ProposalReceipt {
    public Id: number;
    public ProposalId: number;
    public CreatedByOpticianId: number;
    public Code: string;
    public Reference: string;
    public CreatedByOptician: Optician;
    public Proposal: Proposal;
}
