import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Language } from '@app/shared/models/language.model';
import { Observable } from 'rxjs';
import { ListSelectOption } from '@app/shared/models';
import { LanguageInfo } from '@app/shared/models/language-info.model';
import { LanguageTranslationPercentage } from '@app/shared/models/language-translation-percentage.model';
import { LanguageTranslationInfo } from '@app/shared/models/language-translation-info.model';

export const DEFAULT_LANGUAGE_ID = 2;

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private readonly basePath = 'api/languages';

    constructor(private readonly httpClient: HttpClient) {}

    public getLanguages(): Observable<ListSelectOption[]> {
        return this.httpClient.get<ListSelectOption[]>(`${this.basePath}/options`);
    }

    public getAllLanguages(): Observable<Language[]> {
        return this.httpClient.get<Language[]>(this.basePath);
    }

    public getTranslatorLanguages(): Observable<ListSelectOption[]> {
        return this.httpClient.get<ListSelectOption[]>(`${this.basePath}/translator-languages`);
    }

    public getLanguagesBySupportedVersion(version: number): Observable<Language[]> {
        const options = {
            params: new HttpParams().set('version', version),
        };

        return this.httpClient.get<Language[]>(`${this.basePath}/version`, options);
    }

    public getLanguage(languageId: number): Observable<Language> {
        return this.httpClient.get<Language>(`${this.basePath}/${languageId}`);
    }

    public saveLanguageTranslations(translations: Language): Observable<Language> {
        return this.httpClient.post<Language>(this.basePath, translations);
    }

    public getActiveLanguages(): Observable<ListSelectOption[]> {
        return this.httpClient.get<ListSelectOption[]>(`${this.basePath}/active`);
    }

    public getLanguageInfo(skip: number, take: number, search?: string): Observable<LanguageInfo[]> {
        let params = new HttpParams().set('skip', skip.toString()).set('take', take.toString());

        if (search) {
            params = params.set('search', search);
        }

        return this.httpClient.get<LanguageInfo[]>(`${this.basePath}/language-info`, { params });
    }

    public getLanguageTranslationInfo(languageId: number): Observable<LanguageTranslationInfo> {
        return this.httpClient.get<LanguageTranslationInfo>(`${this.basePath}/language-translation-info/${languageId}`);
    }

    public getLanguagePercentageCalculations(
        languageIds: number[],
        onlyActiveOrAssigned: boolean,
    ): Observable<LanguageTranslationPercentage[]> {
        const params = new HttpParams().set('onlyActiveOrAssigned', onlyActiveOrAssigned.toString());

        return this.httpClient.post<LanguageTranslationPercentage[]>(
            `${this.basePath}/percentage-calculations`,
            languageIds,
            { params },
        );
    }

    public setStatus(languageId: number, isActive: boolean): Observable<unknown> {
        return this.httpClient.put(`${this.basePath}/${languageId}/set-status?isActive=${isActive}`, {});
    }
}
