import { ListOption } from '@app/shared/models';
import { Feature } from './feature.model';

export class Distributor {
    public Id: number;
    public CompanyId: number;
    public Name: string;
    public Street: string;
    public HouseNumber: number;
    public HouseNumberSuffix: string;
    public PostalCode: string;
    public City: string;
    public CountryId: number;
    public StateId: number;
    public PoBox: string;
    public PoBoxPostalCode: string;
    public PoBoxCity: string;
    public PoBoxCountryId: number;
    public PoBoxStateId: number;
    public PhoneNumber: string;
    public FaxNumber: string;
    public WebSiteUrl: string;
    public TeamViewerUrl: string;
    public EmailAddress: string;
    public BrandingImageUrl: string;
    public PdfTemplateFilename: string;

    public Features: Feature[];
    public Products: ListOption[];
    public Opticians: ListOption[];
    public Countries: ListOption[];
}
