<div class="m-4">
    <h3>Credit Sample statistics</h3>
    <hr />

    <div class="table-responsive">
        <table class="table mb-1 datatable">
            <thead>
                <tr class="datatableheader">
                    <th>Optician</th>
                    <th>Intensity</th>
                    <th>Samples requested in current check</th>
                    <th>Last updated</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let statistic of statistics$ | async">
                    <td>{{ statistic.OpticianName }}</td>
                    <td>{{ statistic.Intensity }}</td>
                    <td>{{ statistic.NumberOfSamplesRequestedInCheck }}</td>
                    <td>{{ statistic.LastUpdated | date: 'short' }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
