import { Pipe, PipeTransform } from '@angular/core';
import { LensDefinitionParameterNumberRange, LensDefinitionParameterText, ListOption } from '../models';
import { FitLensComponent } from '@app/fitlens/fitlens.component';
import { LensDefinitionParameterRangeHelper } from '../helpers/lens-definition-parameter-range-helper';

@Pipe({
    name: 'lensDefinitionParameterListOptionSelector',
})
export class LensDefinitionParameterListOptionSelectorPipe implements PipeTransform {
    transform(
        items: (LensDefinitionParameterText | LensDefinitionParameterNumberRange)[],
        flc: FitLensComponent,
    ): ListOption[] {
        return items
            .filter<LensDefinitionParameterText>(this.isText)
            .filter((x) => LensDefinitionParameterRangeHelper.checkConditions(x, flc))
            .map((x) => new ListOption(x.Code, x.Name, x.Code));
    }

    isText(
        item: LensDefinitionParameterText | LensDefinitionParameterNumberRange,
    ): item is LensDefinitionParameterText {
        return item.$type === 'text';
    }
}
