import { BaseValidator } from '@app/shared/validators/BaseValidator';
import { UntypedFormArray, ValidationErrors } from '@angular/forms';
import { LensTypes, ParameterTypeCodes } from '@app/shared/enums';
import { FittedLens } from '@app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { FitLensComponent } from '@app/fitlens/fitlens.component';

export class ByopremiumRadDiamValidator extends BaseValidator {
    static readonly ProductArtNrs = [
        7430, //Byo Premium artikel
        7445, //Byo Premium KC artikel 7445
        7447, //Byo Premium KC ML artikel 7447
        7443, //Byo Premium MC artikel 7443
        7431, //Byo Premium ML artikel 7431
        7432, //Byo Premium Prisma artikel 7432
        7435, //Byo Premium T artikel 7435
        7446, //Byo Premium T KC artikel 7446
        7444, //Byo Premium T MC artikel 7444
        7436, //Byo Premium T ML artikel 7436
        7440, //Byo Premium T Zoom D artikel 7440
        7411, //Byo Premium T Zoom D SV artikel 7411
        7439, //Byo Premium T Zoom N artikel 7439
        7438, //Byo Premium Zoom D artikel 7438
        7412, //Byo Premium Zoom D SV artikel 7412
        7437, //Byo Premium Zoom N artikel 7437
    ];

    static showInfo(fittedLens: FittedLens): boolean {
        return (
            ByopremiumRadDiamValidator.ProductArtNrs.findIndex(
                (an) => an === +fittedLens.LensDefinition.Product.ArtNr,
            ) !== -1
        );
    }

    public static getWarningString(flc: FitLensComponent, translate: TranslateService): string {
        const params = flc.formGroup.controls['parameters'] as UntypedFormArray;
        const rad = params.value[flc.getFittedLensParameterIndex(ParameterTypeCodes.RAD)];

        if (!rad) {
            return null;
        }

        let diam = -1;

        switch (rad) {
            case 7.3:
                diam = 13.4;
                break;
            case 7.4:
                diam = 13.5;
                break;
            case 7.5:
                diam = 13.7;
                break;
            case 7.6:
                diam = 13.8;
                break;
            case 7.7:
                diam = 14.0;
                break;
            case 7.8:
                diam = 14.1;
                break;
            case 7.9:
                diam = 14.3;
                break;
            case 8.0:
                diam = 14.5;
                break;
            case 8.1:
                diam = 14.7;
                break;
            case 8.2:
                diam = 14.8;
                break;
            case 8.3:
                diam = 15.0;
                break;
        }

        if (diam === -1) {
            const warning = translate.instant('ErrorMessages.RAD_DIAM_GOOD_WARNING');
            return String(warning).replace('{}', rad.toString());
        } else {
            const warning = translate.instant('ErrorMessages.RAD_DIAM_BAD_WARNING');
            return String(warning).replace('{}', rad.toString()).replace('{}', diam.toString());
        }
    }

    static bindRadAndDiam(rad: number, diam: number): ValidationErrors {
        switch (rad) {
            case 7.3:
                return diam >= 13.4 ? { radDiamInvalid: true } : null;
            case 7.4:
                return diam >= 13.5 ? { radDiamInvalid: true } : null;
            case 7.5:
                return diam >= 13.7 ? { radDiamInvalid: true } : null;
            case 7.6:
                return diam >= 13.8 ? { radDiamInvalid: true } : null;
            case 7.7:
                return diam >= 14.0 ? { radDiamInvalid: true } : null;
            case 7.8:
                return diam >= 14.1 ? { radDiamInvalid: true } : null;
            case 7.9:
                return diam >= 14.3 ? { radDiamInvalid: true } : null;
            case 8.0:
                return diam >= 14.5 ? { radDiamInvalid: true } : null;
            case 8.1:
                return diam >= 14.7 ? { radDiamInvalid: true } : null;
            case 8.2:
                return diam >= 14.8 ? { radDiamInvalid: true } : null;
            case 8.3:
                return diam >= 15.0 ? { radDiamInvalid: true } : null;
        }
        return null;
    }

    static isArtNrInProductList(artNr: number): boolean {
        return !!ByopremiumRadDiamValidator.ProductArtNrs.find((nr) => nr === artNr);
    }

    bind(flc: FitLensComponent): ValidationErrors {
        if (flc.fittedLens.LensDefinition.LensTypeId !== LensTypes.Soft) {
            return null;
        }

        const artnr = flc.fittedLens.LensDefinition.Product.ArtNr;
        if (!ByopremiumRadDiamValidator.isArtNrInProductList(+artnr)) {
            return null;
        }

        const params = flc.formGroup.controls['parameters'] as UntypedFormArray;
        const rad = params.value[flc.getFittedLensParameterIndex(ParameterTypeCodes.RAD)];
        const diam = params.value[flc.getFittedLensParameterIndex(ParameterTypeCodes.DIAM)];

        return ByopremiumRadDiamValidator.bindRadAndDiam(rad, diam);
    }
}
