import { Injectable } from '@angular/core';
import { FeatureSetting } from '../models/featureSetting.model';
import { FeatureCategories, Features } from '../enums';
import { AppStateService } from './appState.service';

@Injectable()
export class FeatureCheckerService {
    constructor(private readonly appState: AppStateService) {}

    public check(featureSettings: FeatureSetting[]): boolean {
        const featureCheckers = {
            [FeatureCategories.Company]: (featureSetting: FeatureSetting) => this.companyFeatureChecker(featureSetting),
            [FeatureCategories.Distributor]: (featureSetting: FeatureSetting) =>
                this.distributorFeatureChecker(featureSetting),
            [FeatureCategories.Optician]: (featureSetting: FeatureSetting) =>
                this.opticianFeatureChecker(featureSetting),
        };

        return featureSettings.every((featureSetting) => {
            const featureChecker = featureCheckers[featureSetting.Category];
            return featureChecker ? featureSetting.IsEnabled === featureChecker(featureSetting) : false;
        });
    }

    private opticianFeatureChecker(featureSetting: FeatureSetting): boolean {
        if (featureSetting.OpticianToCheck) {
            return (
                featureSetting.OpticianToCheck.Features?.find((f) => f.Id === featureSetting.Feature)?.IsEnabled ??
                false
            );
        }

        return this.appState.isOpticianFeatureEnabled(featureSetting.Feature);
    }

    private distributorFeatureChecker(featureSetting: FeatureSetting): boolean {
        if (featureSetting.DistributorToCheck) {
            return (
                featureSetting.DistributorToCheck.Features?.find((f) => f.Id === featureSetting.Feature)?.IsEnabled ??
                false
            );
        }

        return this.appState.isDistributorFeatureEnabled(featureSetting.Feature);
    }

    public isCompanyFeatureEnabled(feature: Features): boolean {
        return this.appState.currentCompany?.Features?.some((f) => f.Id === feature && f.IsEnabled) ?? false;
    }

    private companyFeatureChecker(featureSetting: FeatureSetting): boolean {
        if (featureSetting.CompanyToCheck) {
            return (
                featureSetting.CompanyToCheck.Features?.find((f) => f.Id === featureSetting.Feature)?.IsEnabled ?? false
            );
        }

        return this.isCompanyFeatureEnabled(featureSetting.Feature);
    }
}
