import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { MyopieValues } from '@app/shared/models/myopieValues.model';
import { ClientService } from '@app/core/services/api/client.service';
import { LineChartComponent } from '@app/shared/components/charts/LineChart/line-chart.component';

@Component({
    selector: 'myopia-power',
    templateUrl: 'myopia-power.component.html',
})
export class MyopiaPowerComponent implements OnInit, AfterViewInit {
    @Input() clientId: number;

    @ViewChild('linechartod')
    lineChartOD: LineChartComponent;

    @ViewChild('linechartos')
    lineChartOS: LineChartComponent;

    hasODValues: boolean;
    hasOSValues: boolean;

    loading = false;
    datePipe: DatePipe;

    constructor(
        private appState: AppStateService,
        private clientService: ClientService,
    ) {
        this.datePipe = new DatePipe(appState.authenticatedUser.CurrentLanguageCode);
    }

    ngOnInit(): void {
        if (this.clientId) {
            this.loading = true;
        }
    }

    ngAfterViewInit(): void {
        if (this.clientId) {
            this.clientService.getMyopiaPowImage(this.clientId).subscribe((result) => {
                this.hasODValues = result.ODValues.length > 0;
                if (this.hasODValues) {
                    const datasetOD = this.getChartData(result.ODValues);

                    this.lineChartOD.setDataSet(datasetOD);
                    this.lineChartOD.initiateChart();
                    this.lineChartOD.setColorScheme(this.appState.authenticatedUser.CurrentApplicationTheme);
                    this.lineChartOD.chart.update();
                }

                this.hasOSValues = result.OSValues.length > 0;
                if (this.hasOSValues) {
                    const datasetOS = this.getChartData(result.OSValues);

                    this.lineChartOS.setDataSet(datasetOS);
                    this.lineChartOS.initiateChart();
                    this.lineChartOS.setColorScheme(this.appState.authenticatedUser.CurrentApplicationTheme);
                    this.lineChartOS.chart.update();
                }

                this.loading = false;
            });
        }
    }

    getChartData(values: MyopieValues[]) {
        const labels = [];
        const cycloData = [];
        const orthoKData = [];

        let hasCycloData = false;
        let hasOrthoKData = false;

        for (const value of values) {
            labels.push(this.datePipe.transform(value.Date, 'shortDate'));

            cycloData.push(value.Cyclo);
            if (value.Cyclo) {
                hasCycloData = true;
            }

            orthoKData.push(value.OrthoK);
            if (value.OrthoK) {
                hasOrthoKData = true;
            }
        }

        const datasets = [];
        if (hasCycloData) {
            datasets.push({
                label: 'Cyclo',
                data: cycloData,
                backgroundColor: ['#426ab3'],
                borderColor: ['#426ab3'],
                borderWidth: 1,
                fill: false,
            });
        }

        if (hasOrthoKData) {
            datasets.push({
                label: 'Dreamlite',
                data: orthoKData,
                backgroundColor: ['#00a2b2'],
                borderColor: ['#00a2b2'],
                borderWidth: 1,
                fill: false,
            });
        }

        return {
            labels: labels,
            datasets: datasets,
        };
    }
}
