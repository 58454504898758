import { Frame } from './frame.model';
import { OrderLineInfo2 } from './orderLineInfo2.model';

export class OrderInfo {
    Id: number;
    OpticianId: number;
    ClientId: number;
    OrderDate: Date;
    OrderReference: string;
    PriorityId: number;
    SkipOrderEvaluation: boolean;
    SupportOrderStatusId: number;
    OrderTypeId: number;
    ClientReference: string;
    ClientReference2: string;
    ProposalReceiptReference: string;
    ClientBirthDate: Date;
    OrderLines: OrderLineInfo2[];
    OrderStatusId: number; // contains the lowest status of orderLines
    IsLensOrder: boolean;
    IsSerialOrder: boolean;
    LeftOrderLineStatusId: number;
    RightOrderLineStatusId: number;
    LeftProductName: string;
    RightProductName: string;
    LeftOrderLineStatusName: string;
    RightOrderLineStatusName: string;
    LeftOrderLineQuantity: number;
    RightOrderLineQuantity: number;
    BatchProductName: string;
    BatchQuantity: number;
    HasNonMatchingOrderlineStatusus: boolean;
    Name: string;
    Street: string;
    HouseNumber: string;
    HouseNumberSuffix: string;
    PostCode: string;
    City: string;
    Country: string;
    HomeState: string;
    FrameId: number;
    GlassTreatmentId: number;
    Frame: Frame;
    TrackAndTraceLink: string;
    IsDirectToHome: boolean;
    SpareOrderId: number;
}
