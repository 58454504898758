import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterByKeyValue',
})
export class FilterByKeyValuePipe implements PipeTransform {
    transform<T, K extends keyof T>(items: T[], key: K, value: T[K]): T | undefined {
        if (!items || !key || value === undefined) {
            return undefined;
        }

        const foundItem = items.find((item) => item[key] === value);
        return foundItem ? foundItem : undefined;
    }
}
