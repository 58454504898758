import { BaseControl } from '@app/shared/components/inputs/base-control';
import { Component, ViewEncapsulation, Optional, AfterContentInit, Host, SkipSelf, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { RefractionMeasurement } from '../../../models';

@Component({
    selector: 'mpc-input-time',
    templateUrl: './input-time.component.html',
    styleUrls: ['./input-time.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputTimeComponent,
            multi: true,
        },
    ],
})
export class InputTimeComponent extends BaseControl implements AfterContentInit {
    @Input() previous: RefractionMeasurement;

    date: Date;
    public hours = '0';
    public minutes = '0';
    timeValidationError = false;
    timeValid: string;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        public controlContainer: ControlContainer,
    ) {
        super(controlContainer);
    }

    ngAfterContentInit() {
        if (this.previous) {
            this.date = this.previous.Performed;
            this.setTime();
        } else {
            this.date = new Date();
            this.hours = '' + this.date.getHours();
            this.minutes = '' + this.date.getMinutes();
        }

        this.addLeadingZeroes(true);
        this.addLeadingZeroes(false);
    }

    public setTime(): void {
        const dateUTC = new Date(this.date);
        this.hours = '' + dateUTC.getHours();
        this.minutes = '' + dateUTC.getMinutes();
    }

    validateInput(event, isHours: boolean): void {
        const value = isHours ? this.hours : this.minutes;

        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar) || value.toString().length + 1 > 2) {
            event.preventDefault();
        }
    }

    onBlur(isHours: boolean) {
        this.registerOnValidatorChange();
        this.addLeadingZeroes(isHours);
    }

    addLeadingZeroes(isHours: boolean): void {
        let newValue = String(isHours ? this.hours : this.minutes);

        const zeroes = 2 - newValue.toString().length;
        for (let i = 0; i < zeroes; i++) {
            newValue = '0' + newValue;
        }

        if (isHours) {
            this.hours = newValue;
            this.value = [this.hours, this.minutes];
        } else {
            this.minutes = newValue;
            this.value = [this.hours, this.minutes];
        }
    }

    isValid(): boolean {
        return !this.timeValidationError;
    }

    registerOnValidatorChange(): void {
        this.timeValidationError =
            Number(this.hours) < 0 || Number(this.hours) > 23 || Number(this.minutes) < 0 || Number(this.minutes) > 60;
    }

    refreshSubClasses(): void {
        this.timeValid = this.getTimeValid();
    }

    getTimeValid(): string {
        return this.timeValidationError ? 'invalid-time' : 'valid-time';
    }
}
