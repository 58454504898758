import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FluoImageRequest } from '@app/shared/models/fluoImageRequest.model';
import { FluoImageResponse } from '@app/shared/models/fluoImageResponse.model';

@Injectable({
    providedIn: 'root',
})
export class FluoImageService {
    private readonly basePath = 'api/fluo-images';

    constructor(private readonly httpClient: HttpClient) {}

    public getFluoImage(fluoImageRequest: FluoImageRequest): Observable<FluoImageResponse> {
        return this.httpClient.post<FluoImageResponse>(this.basePath, fluoImageRequest);
    }
}
