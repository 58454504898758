import { Distributor } from './distributor.model';
import { Feature } from './feature.model';
import { SupportRequest } from './supportRequest.model';

export class Optician {
    Id: number;
    DistributorId: number;
    Distributor: Distributor;
    Name: string;
    EmailAddress: string;
    PhoneNumber: string;
    ReferenceNr: string;
    OrthoKSeminarDate: Date;
    CrtCertificationDate: Date;
    City: string;
    SearchCode: string;
    SupportRequests: SupportRequest[];
    TopographicMeasurementRetentionHours: number;
    ReleaseHistoryReadDate: Date;
    PerosBranchNumber: string;
    IzyShapeLicenseKey: string;
    DistroptiekCode: string;
    DistroptiekLabCode: string;
    IsApproved: boolean;
    public Features: Feature[];
}
