import { Component, Input, ViewEncapsulation, OnInit, Optional, Host, SkipSelf, OnDestroy } from '@angular/core';
import {
    ControlContainer,
    UntypedFormGroup,
    UntypedFormBuilder,
    UntypedFormArray,
    Validators,
    ValidationErrors,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
} from '@angular/forms';
import { ListOption } from '@app/shared/models';
import { TranslationLanguage } from './translationlanguage.model';
import { Util } from '@app/shared/helpers/utility.helper';
import { BaseControl } from '@app/shared/components/inputs/base-control';
import { DEFAULT_LANGUAGE_ID, LanguageService } from '@app/core/services/api/language.service';
import { Subscription } from 'rxjs';
import { AppStateService } from '@app/shared/appservices/appState.service';

@Component({
    selector: 'mpc-input-text-language',
    templateUrl: './input-text-language.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputTextLanguageComponent,
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: InputTextLanguageComponent,
            multi: true,
        },
    ],
})
export class InputTextLanguageComponent extends BaseControl implements OnInit, OnDestroy {
    @Input() isTextarea = false;
    @Input() maxLength = 999;

    formGroup: UntypedFormGroup;
    selectedLanguageId: number = DEFAULT_LANGUAGE_ID;
    languages: ListOption[];
    translations: TranslationLanguage[] = [];
    subscriptions: Subscription[] = [];

    get languageValueControls(): UntypedFormArray {
        return this.formGroup.get('lang') as UntypedFormArray;
    }
    get formControls() {
        return this.formGroup.controls;
    }

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        public controlContainer: ControlContainer,
        private readonly fb: UntypedFormBuilder,
        private readonly languageService: LanguageService,
        private readonly appStateService: AppStateService,
    ) {
        super(controlContainer);

        this.formGroup = this.fb.group({
            languageId: this.fb.control(this.selectedLanguageId),
            lang: this.fb.array([]),
        });
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.subscriptions.push(
            this.formGroup.get('languageId').valueChanges.subscribe((languageId: number) => {
                this.selectedLanguageId = +languageId;
            }),
            this.formGroup.valueChanges.subscribe(() => {
                if (this.languages) {
                    // map the values back to an array with the updated values
                    const result = this.languageValueControls.value.map((val: string, i: number) => {
                        // assume DistributorId === null for the default value (we are not storing a distributor specific translation here)
                        const foundLang = this.translations.find(
                            (t: TranslationLanguage) =>
                                t.TranslateLanguageId === this.languages[i].Id && t.DistributorId === null,
                        );

                        if (foundLang) {
                            // update found translation with the new value.
                            foundLang.Value = val;
                            return foundLang;
                        }

                        // nothing found, create a new one.
                        return <TranslationLanguage>{
                            TranslateLanguageId: this.languages[i].Id,
                            Value: val,
                        };
                    });

                    this.onChange(result);
                    this.onTouched();
                }
            }),
            this.loadLanguages(),
        );
    }

    private loadLanguages(): Subscription {
        if (this.appStateService.isNewTranslationFeatureEnabled()) {
            return this.languageService.getActiveLanguages().subscribe((result: ListOption[]) => {
                this.languages = result; // save languages into array

                // create a control for each language
                this.languages.forEach((language) => {
                    const validators = [];

                    if (this.isRequired && language.Id == DEFAULT_LANGUAGE_ID) {
                        validators.push(Validators.required);
                    }

                    this.languageValueControls.push(this.fb.control('', validators));
                });
            });
        }

        return this.languageService.getLanguages().subscribe((result: ListOption[]) => {
            this.languages = result; // save languages into array

            // create a control for each language
            this.languages.forEach((language) => {
                const validators = [];

                if (this.isRequired && language.Id == DEFAULT_LANGUAGE_ID) {
                    validators.push(Validators.required);
                }

                this.languageValueControls.push(this.fb.control('', validators));
            });
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    writeValue(value: TranslationLanguage[]): void {
        if (Array.isArray(value)) {
            // store incoming translations
            this.translations = value;
        }

        // translate incoming items with the languages so that the values map to the correct TranslateLanguageId
        const languageValues = this.languages?.map((lang: ListOption) => {
            const foundLang = (value || []).find((t: TranslationLanguage) => t.TranslateLanguageId === lang.Id);
            return foundLang ? foundLang.Value : '';
        });

        // patch the form
        this.languageValueControls.patchValue(languageValues);
    }

    getId(index: number): string {
        return this.id + '_' + index;
    }

    validate(): ValidationErrors {
        Util.validateAllFormFields(this.formGroup);

        const formErrors = Util.getFormErrors(this.formGroup);

        if (formErrors) {
            return formErrors;
        }

        return null;
    }
}
