export class InputNumberRange {
    constructor(minimum: number, maximum: number, step: number, start = 0) {
        this.Minimum = minimum;
        this.Maximum = maximum;
        this.Step = step;
        this.Start = start;
    }

    Minimum: number;
    Maximum: number;
    Step: number;
    Start: number;
}
