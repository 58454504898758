<div class="mt-3 mb-4 mx-auto col-12 col-xl-10">
    <div class="title-border-bottom pb-2 d-flex">
        <h3 class="mb-0 align-self-center">
            {{ 'general.clientMaintenance' | translate }}
        </h3>

        <div class="d-flex ms-auto mb-1 align-items-right">
            <div class="me-2 btn" [ngClass]="selected('all')" (click)="onLenstypeFilter('all')">
                {{ 'general.all' | translate }}
            </div>

            <div
                *hasFeatureSetting="[
                    { Feature: Features.OrthoK, Category: FeatureCategories.Optician, IsEnabled: true }
                ]"
                class="me-2 btn"
                [ngClass]="selected('dreamlite')"
                (click)="onLenstypeFilter('dreamlite')">
                {{ 'lenstype.dreamlitereplacement' | translate: lensNames }}
            </div>
            <div
                *hasFeatureSetting="[
                    { Feature: Features.Myopie, Category: FeatureCategories.Optician, IsEnabled: true }
                ]"
                class="me-2 btn"
                [ngClass]="selected('MiSight')"
                (click)="onLenstypeFilter('MiSight')">
                {{ 'lenstype.MiSightreplacement' | translate: lensNames }}
            </div>
            <div
                *hasFeatureSetting="[{ Feature: Features.Soft, Category: FeatureCategories.Optician, IsEnabled: true }]"
                class="px-2 btn"
                [ngClass]="selected('byopremium')"
                (click)="onLenstypeFilter('byopremium')">
                {{ 'lenstype.byopremiumreplacement' | translate: lensNames }}
            </div>
        </div>
    </div>

    <div class="container-fluid mt-3 px-0 d-flex">
        <ng-container *ngIf="!loading">
            <table>
                <thead>
                    <tr>
                        <th class="th-reference">
                            <div class="d-flex">
                                {{ 'general.reference' | translate }}
                                <div class="ms-auto" (click)="onOrderBy('Reference')">
                                    <i *ngIf="isOrderByThis('Reference')" class="fas fa-caret-up fa-collapse-icon"></i>
                                    <i
                                        *ngIf="!isOrderByThis('Reference')"
                                        class="fas fa-caret-down fa-collapse-icon"></i>
                                </div>
                            </div>
                        </th>
                        <th class="th-reference2">
                            <div class="d-flex">
                                {{ 'general.reference2' | translate }}
                                <div class="ms-auto" (click)="onOrderBy('Reference2')">
                                    <i *ngIf="isOrderByThis('Reference2')" class="fas fa-caret-up fa-collapse-icon"></i>
                                    <i
                                        *ngIf="!isOrderByThis('Reference2')"
                                        class="fas fa-caret-down fa-collapse-icon"></i>
                                </div>
                            </div>
                        </th>
                        <th class="th-birthdate">
                            <div class="d-flex">
                                {{ 'general.birthdate' | translate }}
                                <div class="ms-auto" (click)="onOrderBy('BirthDate')">
                                    <i *ngIf="isOrderByThis('BirthDate')" class="fas fa-caret-up fa-collapse-icon"></i>
                                    <i
                                        *ngIf="!isOrderByThis('BirthDate')"
                                        class="fas fa-caret-down fa-collapse-icon"></i>
                                </div>
                            </div>
                        </th>
                        <th class="th-gender">
                            <div class="d-flex">
                                {{ 'general.gender' | translate }}
                                <div class="ms-auto" (click)="onOrderBy('GenderId')">
                                    <i *ngIf="isOrderByThis('GenderId')" class="fas fa-caret-up fa-collapse-icon"></i>
                                    <i
                                        *ngIf="!isOrderByThis('GenderId')"
                                        class="fas fa-caret-down fa-collapse-icon"></i>
                                </div>
                            </div>
                        </th>
                        <th class="th-orderdate">
                            <div class="d-flex">
                                {{ 'order.orderdate' | translate }}
                                <div class="ms-auto" (click)="onOrderBy('OrderDate')">
                                    <i *ngIf="isOrderByThis('OrderDate')" class="fas fa-caret-up fa-collapse-icon"></i>
                                    <i
                                        *ngIf="!isOrderByThis('OrderDate')"
                                        class="fas fa-caret-down fa-collapse-icon"></i>
                                </div>
                            </div>
                        </th>
                        <th class="th-lenstype">
                            <div class="d-flex">
                                {{ 'lens.lensType' | translate }}
                                <div class="ms-auto" (click)="onOrderBy('LensTypeId')">
                                    <i *ngIf="isOrderByThis('LensTypeId')" class="fas fa-caret-up fa-collapse-icon"></i>
                                    <i
                                        *ngIf="!isOrderByThis('LensTypeId')"
                                        class="fas fa-caret-down fa-collapse-icon"></i>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input
                                class="form-control"
                                type="text"
                                (change)="onFilter('Reference', $any($event.target).value)" />
                        </td>
                        <td>
                            <input
                                class="form-control"
                                type="text"
                                (change)="onFilter('Reference2', $any($event.target).value)" />
                        </td>
                        <td>
                            <input
                                class="form-control"
                                type="text"
                                (change)="onFilter('BirthDate', $any($event.target).value)" />
                        </td>
                        <td [formGroup]="formGroup">
                            <mpc-select
                                formControlName="gender"
                                [formControlOnly]="true"
                                [items]="genders"
                                (change)="onFilter('GenderId', $any($event.target).value)"
                                class="align-middle"></mpc-select>
                        </td>
                        <td>
                            <input
                                class="form-control"
                                type="text"
                                (change)="onFilter('OrderDate', $any($event.target).value)" />
                        </td>
                        <td [formGroup]="formGroup">
                            <mpc-select
                                formControlName="lens"
                                [formControlOnly]="true"
                                [items]="lenses"
                                (change)="onFilter('LensTypeId', $any($event.target).value)"
                                class="align-middle"></mpc-select>
                        </td>
                    </tr>

                    <tr class="empty-space"></tr>

                    <tr *ngFor="let client of clients" class="hover-effect">
                        <td class="m-1" [ngClass]="selectedLens !== 'all' ? 'select-option ps-4' : ''">
                            <input
                                *ngIf="selectedLens !== 'all'"
                                type="checkbox"
                                (change)="updateCheckedList($event, client.OrderId)" />
                            <span (click)="selectClient(client)">
                                {{ client.Reference }}
                            </span>
                        </td>
                        <td (click)="selectClient(client)" class="m-1">
                            {{ client.Reference2 }}
                        </td>
                        <td (click)="selectClient(client)" class="m-1">
                            {{ client.BirthDate | mpcdate }}
                        </td>
                        <td (click)="selectClient(client)" class="m-1">
                            {{ client.Gender }}
                        </td>
                        <td (click)="selectClient(client)">
                            {{ client.OrderDate | mpcdate }}
                        </td>
                        <td (click)="selectClient(client)">
                            {{ client.LensType }}
                        </td>
                    </tr>

                    <tr *ngIf="totalClients === 0">
                        <td colspan="6">
                            {{ 'searchclient.noclientsfound' | translate }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>

        <div *ngIf="loading" class="spinner-border spinner-border-sm mx-1"></div>
    </div>

    <ng-container *ngIf="!loading">
        <ng-container *ngTemplateOutlet="paging"></ng-container>
    </ng-container>

    <div *footer>
        <div class="d-flex align-items-center">
            <div>
                <a href="/">
                    <i class="fas fa-arrow-alt-circle-left me-1"></i>
                    {{ 'general.home' | translate }}
                </a>
            </div>

            <div class="ms-auto d-flex">
                <button
                    *ngIf="selectedLens !== 'all' && totalClients !== 0"
                    (click)="removeFromList()"
                    class="btn btn-proceed me-2"
                    [disabled]="checkedList.length === 0">
                    {{ 'general.replacementremovefromlist' | translate }}
                </button>
                <button (click)="exportClients()" class="btn btn-proceed">
                    {{ 'general.export' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #paging>
    <div class="d-flex mt-4">
        <button (click)="onPage('first')" [disabled]="clientInfoQueryCriteria.PageIndex === 0" class="align-middle">
            <h6 class="my-1"><<</h6>
        </button>
        <button (click)="onPage('previous')" [disabled]="clientInfoQueryCriteria.PageIndex === 0" class="align-middle">
            <h6 class="my-1"><</h6>
        </button>
        <button
            *ngFor="let page of pagesShown"
            (click)="onPage(page)"
            class="align-middle"
            [ngClass]="selectedPage(page)">
            <h6 class="my-1">{{ page + 1 }}</h6>
        </button>
        <button
            (click)="onPage('next')"
            [disabled]="clientInfoQueryCriteria.PageIndex === maxPage"
            class="align-middle">
            <h6 class="my-1">></h6>
        </button>
        <button
            (click)="onPage('last')"
            [disabled]="clientInfoQueryCriteria.PageIndex === maxPage"
            class="align-middle">
            <h6 class="my-1">>></h6>
        </button>
        <div class="ms-4 align-self-center">
            {{ itemsShownLowerBound }} - {{ itemsShownUpperBound }} {{ 'general.shown' | translate }},
            {{ totalClients }}
            {{ 'general.total' | translate }}
        </div>
    </div>
</ng-template>
