import { Component, Input, ViewEncapsulation, Optional, Host, SkipSelf } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseControl } from '@app/shared/components/inputs/base-control';

@Component({
    selector: 'mpc-input-textarea',
    templateUrl: './input-textarea.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputTextAreaComponent,
            multi: true,
        },
    ],
})
export class InputTextAreaComponent extends BaseControl {
    @Input() rows = 4;
    @Input() maxLength = 999;
    @Input() placeholder: string;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        public controlContainer: ControlContainer,
    ) {
        super(controlContainer);
    }

    writeValue(value: string): void {
        this.value = value || '';
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    propagateChange(event: any): void {
        this.onChange(event.currentTarget.value);
        this.onTouched();
    }
}
