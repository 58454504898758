import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Client, Optician } from '@app/shared/models';
import { ClientService } from '@app/core/services/api/client.service';
import { NavbarItem } from '@app/shared/models/navbarItem.model';
import { FittingService } from '@app/core/services/fitting.service';
import { OpticianService } from '@app/core/services/api/optician.service';
import { lastValueFrom } from 'rxjs';

@Component({
    templateUrl: './ps-clientmaintenance.component.html',
    styleUrls: ['ps-clientmaintenance.component.scss'],
})
export class PsClientMaintenanceComponent implements OnInit {
    client: Client;
    optician: Optician;
    tab = 0;
    loading = false;
    headeritems = [];

    constructor(
        private clientService: ClientService,
        private translateService: TranslateService,
        private fittingService: FittingService,
        public route: ActivatedRoute,
        public opticianService: OpticianService,
    ) {}

    async ngOnInit() {
        await this.loadHeaderData();
    }

    clientTabClass(tab: number): object {
        return {
            active: tab === this.tab,
            'title-border-bottom': tab === this.tab,
        };
    }

    switch($event): void {
        this.tab = this.headeritems.findIndex((h) => h.title === $event);
    }

    async loadHeaderData() {
        const id = +this.route.snapshot.paramMap.get('id');
        if (id) {
            this.client = await lastValueFrom(this.clientService.getById(id));
            this.optician = await lastValueFrom(this.opticianService.getById(this.client.OpticianId));
            this.fittingService.setClient(this.client);
            this.loading = false;

            this.headeritems = [
                {
                    title: this.client.Reference,
                    subtitle: this.optician.Name,
                    iconName: 'address-card',
                },
                {
                    title: this.translateService.instant('ps.measurements'),
                    imageName: 'measurement.svg',
                },
                {
                    title: this.translateService.instant('ps.lastorder'),
                    iconName: 'shopping-bag',
                },
                {
                    title: this.translateService.instant('ps.attachments'),
                    iconName: 'paperclip',
                },
                {
                    title: this.translateService.instant('ps.actions'),
                    iconName: 'cogs',
                },
            ] as NavbarItem[];
        }
    }
}
