<div>
    <table class="w-100 m-4">
        <thead>
            <tr>
                <th>Optician</th>
                <th>Return Percentage</th>
                <!-- <th>Product</th> -->
                <!-- <th>Product Group</th> -->
                <!-- <th>Lens Type</th> -->
                <th>Allowed Percentage</th>
                <th>Return Count</th>
                <th>Total Count</th>
                <!-- <th>Date</th> -->
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let cln of notifications" class="accented-background rounded mt-2">
                <td>{{ cln.Optician.Name }}</td>
                <td>{{ cln.ReturnsPercentage }}</td>
                <!-- TODO: FIX: Deze data wordt niet teruggegeven via de api -->
                <!-- <td>{{ cln.Product }}</td> -->
                <!-- <td>{{ cln.ProductGroup }}</td> -->
                <!-- <td>{{ cln.LensType }}</td> -->
                <td>{{ cln.PercentageBoundary }}</td>
                <td>{{ cln.ReturnCount }}</td>
                <td>{{ cln.OrderLineCount }}</td>
                <!-- <td>{{ cln.IsCreatedOn | mpcdate }}</td> -->
            </tr>
        </tbody>
    </table>
</div>
