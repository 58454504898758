export class QueryCriteria<T> {
    constructor(queryCriteria?: QueryCriteria<T>) {
        if (queryCriteria == null) {
            return;
        }

        this.UsePaging = queryCriteria.UsePaging;
        this.PageIndex = queryCriteria.PageIndex;
        this.PageSize = queryCriteria.PageSize;
        this.TotalItems = queryCriteria.TotalItems;
        this.OrderByField = queryCriteria.OrderByField;
        this.OrderByAscending = queryCriteria.OrderByAscending;
        this.FilterByFields = queryCriteria.FilterByFields;
        this.FilterBy = queryCriteria.FilterBy;
    }

    UsePaging: boolean;
    PageIndex: number;
    PageSize: number;
    TotalItems: number;

    OrderByField: string;
    OrderByAscending: boolean;

    FilterByFields: { [index: string]: string } = {};
    FilterBy: string;

    StartDate: string;
}
