import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AlertService } from '@app/shared/appservices/alert.service';
import { FileUploadComponent, FileUploadStatus } from '@app/shared/components/fileupload/fileupload.component';
import { ListOptionOptician } from '@app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { takeWhile } from 'rxjs/operators';
import { OpticianService } from '@app/core/services/api/optician.service';

@Component({
    selector: 'ps-client-import',
    templateUrl: './ps-client-import.component.html',
})
export class PsClientImportComponent implements OnInit {
    @ViewChild('fileUploader') fileUploadComponent: FileUploadComponent;

    public opticians: ListOptionOptician[] = [];
    public clientImportForm: UntypedFormGroup;

    constructor(
        public bsModalRef: BsModalRef,
        private readonly translate: TranslateService,
        private readonly opticianService: OpticianService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly alertService: AlertService,
    ) {}

    ngOnInit(): void {
        this.getAllOpticians();
        this.createForm();
    }

    createForm(): void {
        this.clientImportForm = this.formBuilder.group({
            id: 0,
        });
    }

    getAllOpticians(): void {
        this.opticianService.getOpticians().subscribe((opticians: ListOptionOptician[]) => {
            this.opticians = opticians;
        });
    }

    public uploadFiles(event: unknown): Promise<void> {
        if (this.clientImportForm.get('id').value <= 0) {
            this.alertService.error(this.translate.instant('import.opticians'));

            return;
        }

        if (event) {
            if (
                !this.fileUploadComponent ||
                !this.fileUploadComponent.files ||
                this.fileUploadComponent.files.length === 0
            ) {
                return Promise.resolve();
            }

            this.fileUploadComponent.startUpload(
                null,
                `api/Clients/ImportClient?opticianId=${this.clientImportForm.get('id').value}`,
            );

            return new Promise<void>((resolve) => {
                this.fileUploadComponent.uploadStatus
                    .pipe(takeWhile((u) => u === FileUploadStatus.Completed))
                    .subscribe(() => resolve());
            });
        }
    }
}
