import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WorkItem } from '@app/professional-service/models/WorkItem.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class WorkItemsService {
    private readonly basePath = 'api/work-items';

    constructor(private readonly httpClient: HttpClient) {}

    public getWorkItem(workItemId: number): Observable<WorkItem> {
        const options = {
            params: new HttpParams().set('workItemType', 'Order'),
        };

        return this.httpClient.get<WorkItem>(`${this.basePath}/${workItemId}`, options);
    }

    public updateSupporter(supportOrderId: number, supporterId: number): Observable<WorkItem> {
        const body = {
            SupportOrderId: supportOrderId,
            SupporterId: supporterId,
        };

        return this.httpClient.put<WorkItem>(this.basePath, body);
    }
}
