import { Observable } from 'rxjs';
import { FieldValueExpressionCalculator } from './field-value-expression.calculator';
import { SpheroCylinder } from '../models/SpheroCylinder.model';
import { FittedLens, FittedLensParameter } from '@app/shared/models';
import { Injectable } from '@angular/core';

@Injectable()
export class SoftFittedLensCalculator {
    constructor(public fieldValueExpressionCalculator: FieldValueExpressionCalculator) {}

    public recalculateFittedLens(fittedLens: FittedLens, updatedLensParameterTypeCode: string): Observable<FittedLens> {
        const fl = structuredClone(fittedLens);

        const rad = SoftFittedLensCalculator.getFittedLensParameter(fl, 'RAD');
        const diam = SoftFittedLensCalculator.getFittedLensParameter(fl, 'DIAM');
        const pow = SoftFittedLensCalculator.getFittedLensParameter(fl, 'POW');
        const cyl = SoftFittedLensCalculator.getFittedLensParameter(fl, 'CYL');
        const ax = SoftFittedLensCalculator.getFittedLensParameter(fl, 'AX');

        if (updatedLensParameterTypeCode === 'DIAM') {
            const sag10mm = (fl.TopographicMeasurement.CorneaMinSag + fl.TopographicMeasurement.CorneaMaxSag) / 2.0;
            const sdiam = diam.Value / 2.0;
            const angle = fl.TopographicMeasurement.CorneaAngleMean;
            const ta = Math.tan((angle / 180) * 3.14159);

            const x = 5.0;
            let xcorn = fl.RefractionMeasurement.CorneaDiameter / 2.0;
            const rt = Math.sqrt(4 * sag10mm * sag10mm + ta * ta * x * x - 4 * sag10mm * ta * x);
            const r = (x * rt) / (2 * sag10mm * ta) + (x * x) / (2 * sag10mm);
            const p = (r * r) / (x * x) - 1 / (ta * ta);
            if (xcorn > 11.5 / 2.0) {
                xcorn = 11.5 / 2.0;
            }
            const delta = Math.sqrt(r * r - p * x * x) / p - Math.sqrt(r * r - p * xcorn * xcorn) / p;
            const sag = sag10mm + delta + Math.tan((angle / 180) * 3.14159) * (sdiam - xcorn) + 0.4;
            const bcr = (sag * sag + sdiam * sdiam) / (2.0 * sag);
            rad.Value = this.fieldValueExpressionCalculator.adjustParameterValue(
                bcr,
                rad.LensDefinitionParameter.LensDefinitionParameterRanges,
            );
            fl.IsOptimalLensDefinition = null;
        }

        if (updatedLensParameterTypeCode === 'RAD') {
            fl.IsOptimalLensDefinition = null;
        }

        const refraction = new SpheroCylinder(
            fl.RefractionMeasurement.RefractionHA0Sphere,
            fl.RefractionMeasurement.RefractionHA0Cylinder,
            fl.RefractionMeasurement.RefractionHA0Ax,
        );

        const recept = new SpheroCylinder(0.0, 0.0, 0.0);
        if (pow != null) {
            recept.sphere = pow.Value;
            if (cyl != null && ax != null) {
                recept.cylinder = cyl.Value;
                recept.axis = ax.Value;
            }
        }

        const restrefractie = SoftFittedLensCalculator.substractSpheroCylinders(refraction, recept);
        fl.ResidualRefractionSphere = restrefractie.sphere;
        fl.ResidualRefractionCylinder = restrefractie.cylinder;
        fl.ResidualRefractionAx = restrefractie.axis;

        return this.fieldValueExpressionCalculator.recalculateFieldValueExpressions(fl, [updatedLensParameterTypeCode]);
    }

    private static getFittedLensParameter(fittedLens: FittedLens, code: string): FittedLensParameter {
        for (let i = 0; i < fittedLens.FittedLensParameters.length; i++) {
            const fittedLensParameter = fittedLens.FittedLensParameters[i];

            if (fittedLensParameter.LensDefinitionParameter.ParameterType.Code === code) {
                return fittedLensParameter;
            }
        }

        return null;
    }

    private static substractSpheroCylinders(sc1: SpheroCylinder, spherocyl2: SpheroCylinder): SpheroCylinder {
        const pi = 3.14159;

        // Invert sc2
        const sc2 = new SpheroCylinder(-spherocyl2.sphere, -spherocyl2.cylinder, spherocyl2.axis);

        // Convert first sphero cyl to power profile
        const m1 = sc1.sphere + sc1.cylinder / 2.0;
        let j = sc1.cylinder / 2.0;
        let axis = sc1.axis - 90.0;

        const j10 = j * Math.cos(((2.0 * axis) / 180.0) * pi);
        const j145 = j * Math.sin(((2.0 * axis) / 180.0) * pi);

        // Convert second sphero cyl to power profile
        const m2 = sc2.sphere + sc2.cylinder / 2.0;
        j = sc2.cylinder / 2.0;
        axis = sc2.axis - 90.0;

        const j20 = j * Math.cos(((2.0 * axis) / 180.0) * pi);
        const j245 = j * Math.sin(((2.0 * axis) / 180.0) * pi);

        // Add
        const m = m1 + m2;
        const j0 = j10 + j20;
        const j45 = j145 + j245;

        j = Math.sqrt(j0 * j0 + j45 * j45);
        if (j > 0.01) {
            axis = ((0.5 * Math.atan2(j45, j0)) / pi) * 180.0;
        } else {
            j = 0.0;
            axis = 0.0;
        }

        if (axis < 0.0) {
            axis = axis + 180.0;
        }

        // J alway positive, sphere max power. Therefore, cyl negative
        return new SpheroCylinder(m + j, -j * 2.0, axis);
    }
}
