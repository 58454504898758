import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProposalReceiptInfo } from '@app/shared/models/proposalReceiptInfo.model';
import { ProposalReceiptService } from '@app/core/services/api/proposal-receipt.service';
import { ReceiptDetailsDialogComponent } from '@app/features/receipt/components/receipt-details-dialog/receipt-details-dialog.component';
import { ListOption } from '@app/shared/models';

@Component({
    selector: 'receipt-status',
    templateUrl: 'receipt-status.component.html',
    styleUrls: ['receipt-status.component.scss'],
})
export class ReceiptStatusComponent implements OnInit {
    @Input() id = '';

    formGroup: UntypedFormGroup;

    get formControls() {
        return this.formGroup.controls;
    }

    receiptStatuses: ListOption[];
    loading = false;
    receipts$: Observable<ProposalReceiptInfo[]>;

    selectedReceipt: ProposalReceiptInfo;
    currentSortingColumn: string;

    sortConfig$ = new BehaviorSubject<{
        orderColumn: string | null;
    }>({
        orderColumn: 'IsCreatedOn',
    });

    constructor(
        private readonly fb: UntypedFormBuilder,
        public proposalReceiptService: ProposalReceiptService,
        public modalService: BsModalService,
    ) {}

    ngOnInit(): void {
        this.createForm();
        this.initializeReceiptStatuses();
        this.initializeReceipts();
    }

    private initializeReceiptStatuses(): void {
        this.receiptStatuses = [
            new ListOption(1, 'Ordered', 'ordered'),
            new ListOption(2, 'Not ordered', 'notordered'),
        ];
    }

    private initializeReceipts(): void {
        const searchTerm$ = this.formControls['search'].valueChanges.pipe(
            startWith(this.formControls['search'].value),
            debounceTime(500),
            distinctUntilChanged(),
            map((searchTerm: string) => searchTerm.trim()),
        );

        const receiptStatusFilter$ = this.formControls['selectedReceiptStatus'].valueChanges.pipe(
            startWith(this.formControls['selectedReceiptStatus'].value),
        );

        const getReceipts$ = (searchTerm: string) =>
            this.proposalReceiptService.getRecentProposalReceiptsForOptician(searchTerm);

        const receiptsData$ = searchTerm$.pipe(
            tap(() => (this.loading = true)),
            switchMap((searchTerm) => getReceipts$(searchTerm)),
            tap(() => (this.loading = false)),
        );

        this.receipts$ = combineLatest([receiptsData$, receiptStatusFilter$]).pipe(
            map(([receiptLines, receiptStatus]) => this.filterByReceiptStatus(receiptStatus, receiptLines)),
            switchMap((receiptLines) =>
                this.sortConfig$.pipe(
                    tap((filter) => {
                        if (filter.orderColumn) {
                            receiptLines = this.applySorting(receiptLines, filter.orderColumn);
                        }
                    }),
                    map(() => receiptLines),
                ),
            ),
        );
    }

    private filterByReceiptStatus(receiptStatus: number, receiptLines: ProposalReceiptInfo[]) {
        const lensTypeId = Number(receiptStatus);
        if (lensTypeId === 1) {
            receiptLines = receiptLines.filter((x) => x.IsOrdered === true);
        } else if (lensTypeId === 2) {
            receiptLines = receiptLines.filter((x) => x.IsOrdered === false);
        }

        return receiptLines;
    }

    createForm(): void {
        this.formGroup = this.fb.group({
            search: [''],
            selectedReceiptStatus: [''],
        });
    }

    receiptSelected(receipt: ProposalReceiptInfo) {
        this.selectedReceipt = receipt;

        const options: unknown = {
            initialState: {
                receipt: receipt,
            },
            class: 'receiptdetailsdialog',
        };

        this.modalService.show(ReceiptDetailsDialogComponent, options);
    }

    private applySorting(receiptLines: ProposalReceiptInfo[], sortColumn: string): ProposalReceiptInfo[] {
        return receiptLines.sort((a, b) => a[sortColumn] - b[sortColumn]);
    }

    applySort(event: MouseEvent, sortColumn: string): void {
        event.preventDefault();

        const currentSortingState = this.sortConfig$.value;

        if (currentSortingState.orderColumn !== sortColumn) {
            this.sortConfig$.next({
                orderColumn: sortColumn,
            });
            this.currentSortingColumn = sortColumn;
        }
    }
}
