import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { OrderService } from '@app/core/services/api/order.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { Order } from '../shared/models';
import { OrderTypes } from '@app/shared/enums';
import { Util } from '@app/shared/helpers/utility.helper';

@Component({
    selector: 'order-sent',
    templateUrl: 'order-sent.component.html',
    styleUrls: ['order-sent.component.scss'],
})
export class OrderSentComponent implements OnInit {
    @ViewChild('orderVideo') private orderVideo: ElementRef;
    orderId: number;
    order: Order;
    isCreditOrder = false;

    play = true;

    constructor(
        public orderService: OrderService,
        private route: ActivatedRoute,
        public router: Router,
        public loaderService: LoaderService,
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((routeParams) => {
            if (routeParams['id']) {
                if (isNaN(routeParams['id'])) {
                    return this.router.navigate(['/']);
                }

                this.orderId = +routeParams['id'];
                this.orderService.getById(this.orderId).subscribe((result) => {
                    this.order = result;
                    this.isCreditOrder = this.order.IsCreditOrderCreated;
                });
            }
        });
    }

    downloadPdf() {
        this.loaderService.show();
        this.orderService.downloadPdf(this.orderId).subscribe((file) => {
            Util.openBlobInBrowser(file, file.name);
        });
        this.loaderService.hide();
    }

    home(): void {
        this.router.navigate(['/']).then();
    }

    changePlayback(): void {
        if (this.play) {
            this.orderVideo.nativeElement.pause();
            this.play = false;
        } else {
            this.orderVideo.nativeElement.play();
            this.play = true;
        }
    }

    gotoOrderStatus(): void {
        this.router.navigate(['/orderstatus']).then();
    }

    showPdf(): boolean {
        if (!this.order) {
            return false;
        }

        return !(this.order && this.order.OrderTypeId === OrderTypes.PromotionMaterialOrder);
    }
}
