import { FittedLens } from '@app/shared/models';

export class LensParameterChange {
    previousFittedLens: FittedLens;
    newFittedLens: FittedLens;
    changedParameter: string;

    constructor(previousFittedLens: FittedLens, newFittedLens: FittedLens, changedParameter: string) {
        this.previousFittedLens = previousFittedLens;
        this.newFittedLens = newFittedLens;
        this.changedParameter = changedParameter;
    }
}
