import { Component, OnInit, Input } from '@angular/core';
import { Client, ListOption } from '@app/shared/models';
import { Features, FeatureCategories } from '@app/shared/enums';
import { GenderService } from '@app/core/services/api/gender.service';

@Component({
    selector: 'client-details',
    templateUrl: 'client-details.component.html',
    styleUrls: ['client-details.component.scss'],
})
export class ClientDetailsComponent implements OnInit {
    @Input() client: Client;
    @Input() twoColumns = false;
    @Input() showLabels = false;

    genders: ListOption[];

    FeatureCategories = FeatureCategories;
    Features = Features;

    constructor(private readonly genderService: GenderService) {}

    ngOnInit(): void {
        this.genderService.getGenders().subscribe((result: ListOption[]) => {
            this.genders = result;
        });
    }

    genderName(genderId: number): string {
        if (this.genders && genderId) {
            return this.genders.filter((g) => g.Id === genderId)[0].Name;
        }

        return '';
    }
}
