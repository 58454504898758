import { Component, OnInit, Input, DestroyRef, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { OrderService } from '@app/core/services/api/order.service';
import { OrderInfo } from '@app/shared/models/orderInfo.model';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OrderDetailsDialogComponent } from '@app/order/order-details/order-details-dialog.component';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { FeatureCategories, Features, OrderLineStatus, SupportOrderStatuses } from '@app/shared/enums';
import { SupportOrdersFilter } from './enums/support-orders-filter.enum';
import { OrderStatusSort } from './enums/order-status-sort.enum';
import { Company } from '@app/shared/models/company.model';
import { FeatureCheckerService } from '@app/shared/appservices/feature-checker.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'order-status',
    templateUrl: 'order-status.component.html',
    styleUrls: ['order-status.component.scss'],
})
export class OrderStatusComponent implements OnInit {
    @Input() id = '';

    public formGroup: UntypedFormGroup;

    public company: Company;
    public Features = Features;
    public FeatureCategories = FeatureCategories;

    public orders: OrderInfo[] = [];
    public supportOrders: OrderInfo[] = [];
    public ordersWithNewMessage$: Observable<OrderInfo[]>;

    public selectedOrder: OrderInfo;

    public isLoading = {
        orders: true,
        supportOrders: true,
        ordersWithNewMessage: true,
    };

    public isReloading = {
        orders: false,
        supportOrders: false,
    };

    public orderStatusSort = OrderStatusSort;
    public supportOrdersFilter = SupportOrdersFilter;
    public orderLineStatus = OrderLineStatus;

    public hideHeaderFilters = false;

    public currentSort = {
        Sort: OrderStatusSort.OrderDate,
        Name: 'orderstatus.sort.orderdate',
    };
    public currentSupportOrdersFilter: {
        Filter: SupportOrdersFilter;
        Name: string;
    } = null;
    public currentOrdersFilter: {
        Filter: OrderLineStatus;
        Name: string;
    } = null;

    private paginatedLoader$ = new BehaviorSubject<void>(undefined);
    private loader$ = new BehaviorSubject<void>(undefined);
    private searchTerm = '';
    private originalTake = 25;
    private skip = 0;
    private take = this.originalTake;
    private readonly destroyRef = inject(DestroyRef);

    get showCrediting(): boolean {
        return this.appStateService.isAutoCreditAllowed;
    }

    get formControls() {
        return this.formGroup.controls;
    }

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly orderService: OrderService,
        private readonly modalService: BsModalService,
        private readonly appStateService: AppStateService,
        private readonly featureCheckerService: FeatureCheckerService,
    ) {}

    public ngOnInit(): void {
        this.createForm();

        this.company = this.appStateService.currentCompany;
        this.hideHeaderFilters = this.featureCheckerService.isCompanyFeatureEnabled(Features.HideOrderStatusFields);

        this.paginatedLoader$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(() => (this.isReloading.orders = true)),
                switchMap(() =>
                    this.orderService.getRecentOrdersForOptician(
                        this.searchTerm,
                        this.currentOrdersFilter?.Filter,
                        this.currentSort.Sort == OrderStatusSort.OrderDate ? 'OrderDate' : 'ClientReference',
                        this.skip,
                        this.take,
                    ),
                ),
            )
            .subscribe((orders) => {
                this.orders = [...this.orders, ...orders];
                this.isLoading.orders = false;
                this.isReloading.orders = false;
            });

        this.loader$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(() => (this.isReloading.supportOrders = true)),
                switchMap(() =>
                    this.orderService.getRecentSupportOrdersForOptician(
                        this.searchTerm,
                        this.currentSupportOrdersFilter?.Filter == SupportOrdersFilter.WaitingForResponse
                            ? SupportOrderStatuses.WaitingForResponse
                            : this.currentSupportOrdersFilter?.Filter == SupportOrdersFilter.Pending
                              ? SupportOrderStatuses.New
                              : null,
                        this.currentSort.Sort == OrderStatusSort.OrderDate ? 'OrderDate' : 'ClientReference',
                    ),
                ),
            )
            .subscribe((supportOrders) => {
                this.supportOrders = supportOrders;
                this.isReloading.supportOrders = false;
                this.isLoading.supportOrders = false;
            });

        this.ordersWithNewMessage$ = this.loader$.pipe(
            switchMap(() =>
                this.orderService.getRecentOrdersWithNewMessageForOptician(
                    this.searchTerm,
                    this.currentOrdersFilter?.Filter,
                    this.currentSort.Sort == OrderStatusSort.OrderDate ? 'OrderDate' : 'ClientReference',
                ),
            ),
            tap(() => (this.isLoading.ordersWithNewMessage = false)),
        );

        fromEvent(window, 'scroll')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.onScroll();
            });

        this.formControls['search'].valueChanges
            .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => {
                if (result) {
                    const resultString = (result as string).trim();
                    this.searchTerm = this.searchTerm !== resultString ? resultString : this.searchTerm;
                } else {
                    this.searchTerm = '';
                }
                this.resetPagination();
            });
    }

    private createForm(): void {
        this.formGroup = this.fb.group({
            search: [''],
        });
    }

    private onScroll(): void {
        const bottomPixelOffset = 300;
        const isBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - bottomPixelOffset;

        if (isBottom && !this.isReloading.orders && this.take !== 0) {
            this.skip += this.take;
            this.paginatedLoader$.next();
        }
    }

    public loadMoreOrders(): void {
        this.onScroll();
    }

    public orderSelected(order: OrderInfo) {
        this.selectedOrder = order;

        const options = {
            initialState: {
                order: order,
                onOrderChanged: () => this.resetPagination(),
            },
            class: 'orderdetailsdialog',
        };

        const orderDetailsModalRef = this.modalService.show(OrderDetailsDialogComponent, options);
        const subscription = this.modalService.onHide.subscribe(() => {
            // refresh the orders if a note was marked as read
            if (orderDetailsModalRef.content.noteWasMarkedAsRead) {
                this.resetPagination();
            }
            subscription.unsubscribe();
        });
    }

    public setSorting(orderSort: OrderStatusSort): void {
        this.currentSort = {
            Sort: orderSort,
            Name: 'orderstatus.sort.' + OrderStatusSort[orderSort].toLowerCase(),
        };
        this.resetPagination();
    }

    public setSupportOrderFilter(supportOrdersFilter: SupportOrdersFilter): void {
        this.currentOrdersFilter = null;
        this.currentSupportOrdersFilter = {
            Filter: supportOrdersFilter,
            Name: 'orderlinestatus.' + SupportOrdersFilter[supportOrdersFilter].toLowerCase(),
        };
        this.resetPagination();
    }

    public setOrderFilter(orderLineStatus: OrderLineStatus): void {
        this.currentSupportOrdersFilter = null;
        this.currentOrdersFilter = {
            Filter: orderLineStatus,
            Name: 'orderlinestatus.' + OrderLineStatus[orderLineStatus].toLowerCase(),
        };
        this.resetPagination();
    }

    public clearFilter(): void {
        this.currentSupportOrdersFilter = null;
        this.currentOrdersFilter = null;
        this.resetPagination();
    }

    private resetPagination(): void {
        this.skip = 0;
        this.take = this.originalTake;

        this.orders = [];
        this.supportOrders = [];

        this.loader$.next();
        this.paginatedLoader$.next();
    }
}
