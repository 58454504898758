import { FittingService } from '@app/core/services/fitting.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmService } from '@app/shared/appservices/confirm.service';
import { Injectable } from '@angular/core';
import { AppStateService } from '@app/shared/appservices/appState.service';

@Injectable()
export class FittingUnsavedChangesGuard {
    constructor(
        private confirmService: ConfirmService,
        private fittingService: FittingService,
        private translateService: TranslateService,
        private appState: AppStateService,
    ) {}

    async canDeactivate(): Promise<boolean> {
        if (!this.appState.authenticatedUser) {
            // user logged out, so just navigate away
            return Promise.resolve(true);
        }

        const proposalOpticianId = this.fittingService.getOpticianId();

        if (!this.appState.isOptician || proposalOpticianId !== this.appState.authenticatedUser.CurrentOpticianId) {
            // user switched role or optician, so just navigate away
            return Promise.resolve(true);
        }

        if (!this.fittingService.isInSaveableView()) {
            return Promise.resolve(true);
        }

        if (this.fittingService.isSaveEnabled()) {
            // just save and go
            await this.fittingService.saveUnsavedChanges();
            return Promise.resolve(true);
        }

        // there are unsaved changes that will be lost
        // (save is disabled, so there are validation errors)
        // ask user what is to be done.
        return this.askConfirmation();
    }

    private askConfirmation(): Promise<boolean> {
        const title = this.translateService.instant('general.unsavedchangestitle');
        const text = this.translateService.instant('general.unsavedchangestext');

        return this.confirmService.show(title, text);
    }
}
