import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CartItemLineInfo } from '@app/shared/models/cartItemLineInfo.model';
import { Observable } from 'rxjs/internal/Observable';
import { Cart } from '@app/shared/models';
import { CartAddItem } from '@app/shared/models/cartAddItem.model';
import { UpdateCartItemQuantityRequest } from '@app/shared/models/updateCartItemQuantityRequest.model';
import { CartOrderResult } from '@app/shared/models/cartOrderResult.model';
import { FileDownloadService } from '@app/core/services/file-download.service';
import { UpdateCartItemProposal } from '@app/shared/models/updateCartItemProposal.model';

// TODO: Replace bodies with proper commands/ queries.
@Injectable({ providedIn: 'root' })
export class CartService {
    private readonly basePath = 'api/carts';

    constructor(
        private readonly httpClient: HttpClient,
        private readonly fileDownloadService: FileDownloadService,
    ) {}

    public getCart(): Observable<Cart> {
        return this.httpClient.get<Cart>(this.basePath);
    }

    public getCartItemLineInfo(): Observable<CartItemLineInfo[]> {
        return this.httpClient.get<CartItemLineInfo[]>(`${this.basePath}/items/line-info`);
    }

    public removeCartItem(cartItemId: number): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${this.basePath}/items/${cartItemId}`);
    }

    public addItemToCart(createOrder: CartAddItem): Observable<Cart> {
        return this.httpClient.post<Cart>(`${this.basePath}/items`, createOrder);
    }

    public updateCartItemProposal(item: UpdateCartItemProposal): Observable<Cart> {
        return this.httpClient.put<Cart>(`${this.basePath}/proposal-items`, item);
    }

    public updateCartItemQuantity(request: UpdateCartItemQuantityRequest): Observable<boolean> {
        return this.httpClient.put<boolean>(`${this.basePath}/items/quantity`, request);
    }

    public orderCart(): Observable<CartOrderResult> {
        return this.httpClient.post<CartOrderResult>(`${this.basePath}/order`, null);
    }

    public downloadPdf(groupOrderGuid: string, cartId: string, culture: string): Observable<File> {
        const options = {
            params: new HttpParams().set('groupOrderGuid', groupOrderGuid),
            headers: new HttpHeaders({ 'Client-Culture': culture }),
        };

        return this.fileDownloadService.downloadFile(`${this.basePath}/download/pdf`, options, `Order_${cartId}.pdf`);
    }

    public setCartReference(reference: string): Observable<boolean> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        };

        return this.httpClient.put<boolean>(`${this.basePath}/reference`, JSON.stringify(reference), options);
    }
}
