<div class="row">
    <div class="col-12 col-md-6">
        <div>
            <h4>{{ 'general.od' | translate }}</h4>
        </div>
        <div class="spinner-border spinner-border-sm m-auto" *ngIf="loading"></div>
        <div>
            <div class="p-2 rounded accented-background2">
                <mpc-linechart #linechartod [title]="'chart OD'"></mpc-linechart>
            </div>
            <div *ngIf="!hasODValues">
                {{ 'client.myopiaaxislengthgraphnotavailable' | translate }}
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6">
        <div>
            <h4>{{ 'general.os' | translate }}</h4>
        </div>
        <div class="spinner-border spinner-border-sm m-auto" *ngIf="loading"></div>
        <div>
            <div class="p-2 rounded accented-background2">
                <mpc-linechart #linechartos [title]="'chart OS'"></mpc-linechart>
            </div>
            <div *ngIf="!hasOSValues">
                {{ 'client.myopiaaxislengthgraphnotavailable' | translate }}
            </div>
        </div>
    </div>
</div>
