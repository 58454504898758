import { EyeliteMeasurement } from '@app/professional-service/models/eyeliteimport/EyeliteMeasurement.model';
import { EyeliteLenses } from '@app/professional-service/models/eyeliteimport/EyeliteLenses.model';
import { EyeliteNote } from '@app/professional-service/models/eyeliteimport/EyeliteNote.model';

export class EyeliteClient {
    constructor(eyeliteClient: EyeliteClient) {
        this.Name = eyeliteClient.Name;
        this.Firstname = eyeliteClient.Firstname;
        this.Address = eyeliteClient.Address;
        this.City = eyeliteClient.City;
        this.Zipcode = eyeliteClient.Zipcode;
        this.Phonenumber = eyeliteClient.Phonenumber;
        this.Details = eyeliteClient.Details;
        this.ReferenceId = eyeliteClient.ReferenceId;
        this.Profession = eyeliteClient.Profession;
        this.DateOfBirth = eyeliteClient.DateOfBirth;
        this.History = eyeliteClient.History;
        this.Lenstype = eyeliteClient.Lenstype;
        this.LenstypeSupplement = eyeliteClient.LenstypeSupplement;
        this.ActualLenstype = eyeliteClient.ActualLenstype;
        this.TopoClientNumber = eyeliteClient.TopoClientNumber;
        this.Gender = eyeliteClient.Gender;

        this.Measurements = new Array<EyeliteMeasurement>();
        for (let i = 0; i < eyeliteClient.Measurements.length; i++) {
            this.Measurements.push(new EyeliteMeasurement(eyeliteClient.Measurements[i]));
        }

        this.Lenses = new EyeliteLenses(eyeliteClient.Lenses);

        this.Notes = new Array<EyeliteNote>();
        for (let j = 0; j < eyeliteClient.Notes.length; j++) {
            this.Notes.push(new EyeliteNote(eyeliteClient.Notes[j]));
        }
    }

    public Name: string;
    public Firstname: string;
    public Address: string;
    public City: string;
    public Zipcode: string;
    public Phonenumber: string;
    public Details: string;
    public ReferenceId: string;
    public Profession: string;
    public DateOfBirth: string;
    public History: number;
    public Lenstype: number;
    public LenstypeSupplement: number;
    public ActualLenstype: number;
    public TopoClientNumber: string;
    public Gender: string;

    public Measurements: EyeliteMeasurement[];
    public Lenses: EyeliteLenses;

    public Notes: EyeliteNote[];
}
