import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DistributorManufacturer, ListOption, Manufacturer, Product, ProductDistributor } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    private readonly basePath = 'api/products';

    constructor(private readonly httpClient: HttpClient) {}

    public getAll(): Observable<Product[]> {
        return this.httpClient.get<Product[]>(this.basePath);
    }

    public getProductById(productId: number): Observable<Product> {
        return this.httpClient.get<Product>(`${this.basePath}/${productId}`);
    }

    public getProductByCode(productCode: string): Observable<Product> {
        return this.httpClient.get<Product>(`${this.basePath}/code/${productCode}`);
    }

    public save(product: Product): Observable<Product> {
        return this.httpClient.post<Product>(this.basePath, product);
    }

    public remove(productId: number): Observable<unknown> {
        return this.httpClient.delete<unknown>(`${this.basePath}/${productId}`);
    }

    public registerProductDownload(productId: number): Observable<unknown> {
        return this.httpClient.post<unknown>(`${this.basePath}/${productId}/register-download`, null);
    }

    public getAllProductsByProductGroupId(productGroupId: number): Observable<Product[]> {
        return this.httpClient.get<Product[]>(`${this.basePath}/product-groups/${productGroupId}`);
    }

    public getManufacturers(productId: number): Observable<Manufacturer[]> {
        return this.httpClient.get<Manufacturer[]>(`${this.basePath}/${productId}/manufacturers`);
    }

    public getDistributors(productId: number): Observable<ProductDistributor[]> {
        return this.httpClient.get<ProductDistributor[]>(`${this.basePath}/${productId}/distributors`);
    }

    public saveDistributors(productId: number, distributorManufacturers: DistributorManufacturer[]): Observable<void> {
        return this.httpClient.post<void>(`${this.basePath}/${productId}/distributors`, distributorManufacturers);
    }

    public getAllProducts(productType?: number): Observable<ListOption[]> {
        const options = productType ? { params: { productType: productType.toString() } } : undefined;

        return this.httpClient.get<ListOption[]>(`${this.basePath}/options`, options);
    }
}
