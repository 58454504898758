export enum FittingSteps {
    Client = 1,
    Measurement = 2,
    Refraction = 3,
    LensType = 4,
    FitLens = 5,
    Overview = 6,
    OrderSent = 7,
    Cancelled = 8,
    ReceiptCreated = 9,
}
