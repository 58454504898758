<div class="modal-header">
    <div class="measurementtabs d-flex m-2 m-auto">
        <div class="measurement-tab" (click)="onTab('topography')" [ngClass]="tabSelected('topography')">
            <a id="topographyLink">
                <h4>{{ 'general.topography' | translate }}</h4>
            </a>
        </div>
        <div
            *ngIf="showPowerProfile"
            class="measurement-tab ms-5"
            (click)="onTab('powerprofile')"
            [ngClass]="tabSelected('powerprofile')">
            <a id="powerProfileLink">
                <h4>{{ 'general.powerProfile' | translate }}</h4>
            </a>
        </div>
    </div>
</div>

<div class="modal-body" [ngClass]="editable ? 'overflow-hidden' : ''">
    <div *ngIf="!loading">
        <div *ngIf="tabOpened === 'topography'">
            <ng-container *ngTemplateOutlet="topography"></ng-container>
        </div>
        <div *ngIf="tabOpened === 'powerprofile'">
            <ng-container *ngTemplateOutlet="powerprofile"></ng-container>
        </div>
    </div>
</div>

<div class="modal-footer accented-background ps-0">
    <ng-container *ngTemplateOutlet="popupfooter"></ng-container>
</div>

<!-- Topography tab -->
<ng-template #topography>
    <div class="container-fluid p-0">
        <div class="row">
            <!-- Measurement edit form -->
            <div *ngIf="editable" class="col-12 h-100 edit-measurement">
                <div class="row no-gutters h-100">
                    <div class="col-6 col-lg-5 col-xl-4 modal-overlay px-3 pb-3 pt-2">
                        <h4 class="border-bottom mb-3 pb-2">
                            {{ 'general.edit' | translate }}
                        </h4>
                        <ng-container *ngTemplateOutlet="refractionForm"></ng-container>
                        <div class="row measurement-buttons pt-2">
                            <div class="col">
                                <button
                                    type="button"
                                    class="btn btn-success saveMeasurement"
                                    (click)="onRefractionSave()"
                                    [disabled]="!isValidForm()">
                                    {{ 'general.save' | translate }}
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-default cancelMeasurement"
                                    (click)="onEnableEdit(false)">
                                    {{ 'general.cancel' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Measurement data -->
            <ng-container *ngTemplateOutlet="refractionData"></ng-container>

            <!-- Measurement image -->
            <div
                [ngClass]="
                    refractionMeasurement.Id
                        ? editable
                            ? 'fade-element col-7 col-lg-8 col-xl-10'
                            : 'col-7 col-lg-8 col-xl-10'
                        : 'col-12'
                ">
                <div class="row">
                    <!-- Measurement image -->
                    <div class="col-12">
                        <measurement-image
                            #measurementImageComponent
                            id="measurement-image"
                            [eyeSide]="currentMeasurement.EyeSideId"
                            [imageOptions]="imageOptions"
                            [topographicMeasurement]="currentMeasurement"
                            [displayBaselineTag]="displayBaselineTag"></measurement-image>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="row mt-2 ms-0 me-0 justify-content-end"
            [ngClass]="editable ? 'fade-element' : ''"
            id="measurementOptions">
            <div
                class="accented-background rounded ps-1 pe-1 pt-1"
                [ngClass]="refractionMeasurement.Id ? 'col-12 col-lg-8 col-xl-10' : 'col-12'">
                <measurement-options
                    #measurementOptionsControl
                    [imageOptions]="imageOptions"
                    (imageOptionsChanged)="onOptionChange($event)"></measurement-options>
            </div>
        </div>
    </div>
</ng-template>

<!-- Power profile tab -->
<ng-template #powerprofile>
    <div class="container-fluid p-0">
        <div class="row">
            <!-- Measurement edit form -->
            <div *ngIf="editable" class="col-12 h-100 edit-measurement">
                <div class="row no-gutters h-100">
                    <div class="col-6 col-lg-5 col-xl-4 modal-overlay px-3 pb-3 pt-2">
                        <h4 class="border-bottom mb-3 pb-2">
                            {{ 'general.edit' | translate }}
                        </h4>
                        <ng-container *ngTemplateOutlet="refractionForm"></ng-container>
                        <div class="row measurement-buttons pt-2">
                            <div class="col">
                                <button
                                    type="button"
                                    class="btn btn-success saveMeasurement"
                                    (click)="onRefractionSave()"
                                    [disabled]="!isValidForm()">
                                    {{ 'general.save' | translate }}
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-default cancelMeasurement"
                                    (click)="onEnableEdit(false)">
                                    {{ 'general.cancel' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Measurement data -->
            <ng-container *ngTemplateOutlet="refractionData"></ng-container>

            <!-- Measurement image -->
            <div
                [ngClass]="
                    refractionMeasurement.Id
                        ? editable
                            ? 'fade-element col-7 col-lg-8 col-xl-10'
                            : 'col-7 col-lg-8 col-xl-10'
                        : 'col-12'
                ">
                <div class="row">
                    <!-- Measurement image -->
                    <div class="col-12">
                        <measurement-image
                            #powerprofileImageComponent
                            id="powerprofileimage"
                            [eyeSide]="currentMeasurement.EyeSideId"
                            [imageOptions]="powerprofileImageOptions"
                            [topographicMeasurement]="currentMeasurement"></measurement-image>
                    </div>
                </div>
            </div>
        </div>

        <div class="row accented-background rounded no-gutters mt-2 p-3" [formGroup]="formGroup">
            <div class="ms-auto col-4 col-lg-3 align-self-center">
                <mpc-input-number
                    formControlName="rotation"
                    id="{{ id }}-rotation"
                    [ranges]="[rotationRanges]"
                    [formatting]="{ signedNumber: false, decimalCount: 0 }"
                    [maxLength]="6"
                    [unit]="'units.degree'"
                    label="measurementoptions.rotation"
                    [customClass]="'my-0 mb-0'"
                    (click)="onApply()"
                    (change)="onApply()"></mpc-input-number>
            </div>
            <div class="col-4 col-lg-3 align-self-center">
                <mpc-input-number
                    formControlName="pupil"
                    id="{{ id }}-pupil"
                    [ranges]="[pupilDiamRanges]"
                    [formatting]="{ signedNumber: false, decimalCount: 2 }"
                    [maxLength]="6"
                    [unit]="'units.millimeter'"
                    label="measurementoptions.pupil"
                    [customClass]="'my-0 mb-0'"
                    (click)="onApply()"
                    (change)="onApply()"></mpc-input-number>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #refractionForm>
    <div [formGroup]="formGroup">
        <mpc-input-number
            formControlName="sphere"
            id="{{ id }}-sphere"
            [ranges]="ranges.sphereRanges"
            [formatting]="{ signedNumber: true, decimalCount: 2 }"
            [maxLength]="6"
            label="general.spherical"
            [unit]="'units.dioptry'"
            [customClass]="'my-0 mb-1'"></mpc-input-number>
        <mpc-input-number
            *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']"
            formControlName="cylinder"
            id="{{ id }}-cylinder"
            [ranges]="ranges.cylinderRanges"
            [formatting]="{ signedNumber: false, decimalCount: 2 }"
            [maxLength]="6"
            label="lens.geometry.cylinder"
            [unit]="'units.dioptry'"
            [customClass]="'my-0 mb-1'"></mpc-input-number>
        <mpc-input-number
            *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']"
            formControlName="axis"
            id="{{ id }}-axis"
            [ranges]="ranges.axisRanges"
            [formatting]="{ signedNumber: false, decimalCount: 0 }"
            [maxLength]="3"
            label="general.axis"
            [unit]="'units.degree'"
            [customClass]="'my-0 mb-1'"></mpc-input-number>
        <mpc-select
            formControlName="visualAcuitySineCorrectore"
            id="{{ id }}-visualAcuitySineCorrectore"
            [items]="visualAcuityCorrectoreValues"
            label="general.abbreviations.VASC"
            noSelectionLabel=""
            [customClass]="'my-0 mb-1'"></mpc-select>
        <mpc-select
            formControlName="visualAcuityCumCorrectore"
            id="{{ id }}-visualAcuityCumCorrectore"
            [items]="visualAcuityCorrectoreValues"
            label="general.abbreviations.VACC"
            [required]="visualAcutityRequired"
            noSelectionLabel=""
            [customClass]="'my-0 mb-1'"></mpc-select>
        <mpc-input-number
            formControlName="corneaDiameter"
            id="{{ id }}-corneaDiameter"
            [ranges]="ranges.corneaRanges"
            [formatting]="{ signedNumber: false, decimalCount: 2 }"
            [maxLength]="5"
            label="general.cornea"
            [unit]="'units.millimeter'"
            [customClass]="'my-0 mb-1'"></mpc-input-number>
        <mpc-input-number
            *ngIf="refractionMeasurement.RefractionOverLens !== null"
            formControlName="refractionOverLens"
            id="{{ id }}-refractionOverLens"
            [ranges]="ranges.refractionOverLensRanges"
            [formatting]="{ signedNumber: false, decimalCount: 2 }"
            [maxLength]="5"
            [info]="'general.refractionOverLensInfo' | translate"
            label="general.refractionOverLens"
            [unit]="'units.dioptry'"
            [customClass]="'my-0 mb-1'"></mpc-input-number>
        <mpc-input-number
            *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']"
            formControlName="nearadd"
            id="{{ id }}-nearAdd"
            [ranges]="ranges.additionRanges"
            [formatting]="{ signedNumber: false, decimalCount: 2 }"
            [maxLength]="5"
            label="lens.nearAdd"
            [unit]="'units.dioptry'"
            [customClass]="'my-0 mb-1'"></mpc-input-number>
        <mpc-input-time
            #timeComponent
            formControlName="performed"
            class="ms-auto"
            label="general.time"
            [previous]="refractionMeasurement"
            [breakpoint]="previous ? 'md' : ''"></mpc-input-time>
        <ng-container *ngIf="hasMyopie">
            <mpc-input-number
                formControlName="pupilDiameter"
                id="{{ id }}-pupilDiameter"
                [ranges]="ranges.pupilRanges"
                [formatting]="{ signedNumber: false, decimalCount: 2 }"
                [maxLength]="5"
                label="measurementoptions.pupil"
                [unit]="'units.millimeter'"
                [customClass]="'my-0 mb-1'"></mpc-input-number>
            <mpc-input-number
                formControlName="axisLength"
                id="{{ id }}-axisLength"
                [ranges]="ranges.lengthRanges"
                [formatting]="{ signedNumber: false, decimalCount: 2 }"
                [maxLength]="5"
                label="lens.axisLength"
                [unit]="'units.millimeter'"
                [customClass]="'my-0 mb-1'"></mpc-input-number>
            <mpc-input-number
                formControlName="cyclo"
                id="{{ id }}-cyclo"
                [ranges]="ranges.sphereRanges"
                [formatting]="{ signedNumber: false, decimalCount: 2 }"
                [maxLength]="5"
                label="general.cyclo"
                [customClass]="'my-0 mb-1'"></mpc-input-number>
        </ng-container>
    </div>
</ng-template>

<ng-template #popupfooter>
    <div class="row w-100">
        <div class="col-3 col-lg-2 p-0">
            <ng-container *ngIf="hasPrevious">
                <button type="button" class="previousMeasurement btn btn-primary" (click)="previous()">
                    <i class="fas fa-arrow-left"></i>
                    {{ previousMeasurement().Performed | mpcdate }}
                </button>
            </ng-container>
        </div>
        <div class="col-3 col-lg-2 p-0 current-measurement-date align-self-center">
            {{ currentMeasurement.Performed | mpcdate }}
            {{ currentMeasurement.Performed | mpctime }}
        </div>
        <div class="col-3 col-lg-2 p-0">
            <ng-container *ngIf="hasNext">
                <button type="button" class="nextMeasurement btn btn-primary" (click)="next()">
                    {{ nextMeasurement().Performed | mpcdate }}
                    <i class="fas fa-arrow-right"></i>
                </button>
            </ng-container>
        </div>

        <div class="col-3 col-lg-6 text-end">
            <button type="button" class="cancelMeasurement btn btn-default" (click)="bsModalRef.hide()">
                {{ 'general.cancel' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #refractionData>
    <div
        *ngIf="refractionMeasurement.Id"
        class="col-5 col-lg-4 col-xl-2 pe-0"
        [ngClass]="editable ? 'fade-element' : ''">
        <div class="row no-gutters h-100">
            <div class="col-12">
                <h4 class="border-bottom mb-3 pb-2">
                    {{ 'general.refractiondata' | translate }}
                </h4>
                <div class="row">
                    <div class="col">
                        {{ 'general.spherical' | translate }}
                    </div>
                    <div class="col">
                        <span id="refraction-sphere">
                            {{ refractionMeasurement.Sphere | numberFormat: '{"decimalCount": 2}' }}
                        </span>
                        {{ 'units.dioptry' | translate }}
                    </div>
                </div>
                <ng-container *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']">
                    <div class="row mt-2">
                        <div class="col">
                            {{ 'lens.geometry.cylinder' | translate }}
                        </div>
                        <div class="col">
                            <span id="refraction-cylinder">
                                {{ refractionMeasurement.Cylinder | numberFormat: '{"decimalCount": 2}' }}
                            </span>
                            {{ 'units.dioptry' | translate }}
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col">{{ 'general.axis' | translate }}</div>
                        <div class="col">
                            <span id="refraction-axis">
                                {{ refractionMeasurement.Axis }}
                            </span>
                            {{ 'units.degree' | translate }}
                        </div>
                    </div>
                </ng-container>

                <div class="row mt-2">
                    <div class="col">
                        {{ 'general.abbreviations.VASC' | translate }}
                    </div>
                    <div class="col">
                        <span id="refraction-visualAcuitySineCorrectore">
                            {{ refractionMeasurement.VisualAcuitySineCorrectoreValue }}
                        </span>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col">
                        {{ 'general.abbreviations.VACC' | translate }}
                    </div>
                    <div class="col">
                        {{ refractionMeasurement.VisualAcuityCumCorrectoreValue }}
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col">
                        {{ 'general.cornea' | translate }}
                    </div>
                    <div class="col">
                        <span id="refraction-corneaDiameter">
                            {{ refractionMeasurement.CorneaDiameter | numberFormat: '{"decimalCount": 2}' }}
                        </span>
                        {{ 'units.millimeter' | translate }}
                    </div>
                </div>
                <div class="row mt-2" *ngIf="this.refractionMeasurement.RefractionOverLens !== null">
                    <div class="col">
                        {{ 'general.refractionOverLens' | translate }}
                    </div>
                    <div class="col">
                        <span id="refraction-refractionOverLens">
                            {{ refractionMeasurement.RefractionOverLens | numberFormat: '{"decimalCount": 2}' }}
                        </span>
                        {{ 'units.dioptry' | translate }}
                    </div>
                </div>

                <ng-container *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']">
                    <div class="row mt-2">
                        <div class="col">{{ 'lens.nearAdd' | translate }}</div>
                        <div class="col">
                            <span id="refraction-nearAdd">
                                {{ refractionMeasurement.NearAdd | numberFormat: '{"decimalCount": 2}' }}
                            </span>
                            {{ 'units.dioptry' | translate }}
                        </div>
                    </div>
                </ng-container>

                <div class="row mt-2">
                    <div class="col">
                        {{ 'general.time' | translate }}
                    </div>
                    <div class="col">
                        {{ refractionMeasurement.Performed | mpctime }}
                    </div>
                </div>

                <ng-container *ngIf="hasMyopie">
                    <div class="row mt-2">
                        <div class="col">
                            {{ 'measurementoptions.pupil' | translate }}
                        </div>
                        <div class="col">
                            {{ refractionMeasurement.PupilDiameter | numberFormat: '{"decimalCount": 2}' }}
                            {{ 'units.millimeter' | translate }}
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            {{ 'lens.axisLength' | translate }}
                        </div>
                        <div class="col">
                            {{ refractionMeasurement.AxisLength | numberFormat: '{"decimalCount": 2}' }}
                            {{ 'units.millimeter' | translate }}
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            {{ 'general.cyclo' | translate }}
                        </div>
                        <div class="col">
                            {{ refractionMeasurement.Cyclo | numberFormat: '{"decimalCount": 2}' }}
                        </div>
                    </div>
                </ng-container>

                <div class="row measurement-buttons mt-3">
                    <div class="col">
                        <button type="button" class="btn btn-primary edit-measurement-btn" (click)="onEnableEdit(true)">
                            {{ 'general.edit' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
