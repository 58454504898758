import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppStateService } from '@app/shared/appservices/appState.service';

@Injectable()
export class DynamicsDistributorAccessGuard {
    constructor(private readonly appState: AppStateService) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const requiredFeature = route.routeConfig.data?.featureFlag;

        if (!requiredFeature) {
            return false;
        }
        return this.appState.isDistributorFeatureEnabled(requiredFeature);
    }
}
