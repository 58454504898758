import { Component, OnInit } from '@angular/core';
import { ClientService } from '@app/core/services/api/client.service';
import { ClientInfoQueryCriteria } from '@app/shared/models/ClientInfoQueryCriteria.model';
import { ClientInfo } from '@app/shared/models/ClientInfo.model';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ListOption } from '@app/shared/models';
import { ClientList } from '@app/shared/models/ClientList.model';
import { ProductGroupService } from '@app/core/services/api/product-group.service';
import { LensTypeProductGroupNames } from '@app/shared/models/lensTypeProductGroupNames.model';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { Util } from '@app/shared/helpers/utility.helper';
import { finalize } from 'rxjs/operators';
import { FeatureSetting } from '@app/shared/models/featureSetting.model';
import { Features, FeatureCategories, LensTypes, ReplacementGroup } from '@app/shared/enums';
import { GenderService } from '@app/core/services/api/gender.service';
import { LensService } from '@app/core/services/api/lens.service';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'client-maintenance',
    templateUrl: 'client-maintenance.component.html',
    styleUrls: ['client-maintenance.component.scss'],
})
export class ClientMaintenanceComponent implements OnInit {
    Features = Features;
    FeatureSetting = FeatureSetting;
    FeatureCategories = FeatureCategories;
    loading = true;
    totalClients: number;
    maxPage: number;
    pagesShown = [0, 1, 2, 3, 4];
    itemsShownLowerBound = 0;
    itemsShownUpperBound = 0;

    clients: ClientInfo[];
    clientInfoQueryCriteria: ClientInfoQueryCriteria;

    selectedLens = 'all';
    selectedDate = '';
    checkedList = [];

    reference: string;
    formGroup: UntypedFormGroup;
    genders: ListOption[];
    lenses: ListOption[];

    lensNames: LensTypeProductGroupNames;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly fb: UntypedFormBuilder,
        private readonly clientService: ClientService,
        private readonly productGroupService: ProductGroupService,
        private readonly loaderService: LoaderService,
        private readonly genderService: GenderService,
        private readonly lensService: LensService,
    ) {
        this.clientInfoQueryCriteria = new ClientInfoQueryCriteria();
        this.clientInfoQueryCriteria.PageIndex = 0;

        // when routing to the same component ngOnint isn't recalled
        // code below forces to reload component when routing to the same component
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
    }

    async ngOnInit(): Promise<void> {
        this.createForm();

        this.route.queryParams.subscribe((params) => {
            if (params.replacement) {
                this.selectedLens = params.replacement;
                this.selectedDate = params.date;
                this.loadClientReplacementNotifications(this.selectedLens);
            } else {
                this.loadClients();
            }
        });

        this.loadGenders();
        this.loadLensTypes();
        this.lensNames = await lastValueFrom(this.productGroupService.getLensTypeProductGroupNames());
    }

    createForm(): void {
        this.formGroup = this.fb.group({
            gender: [''],
            lens: [''],
        });
    }

    loadGenders(): void {
        this.genderService.getGenders().subscribe((result: ListOption[]) => {
            this.genders = result;
            this.genders.push(new ListOption(null, '', ''));
        });
    }

    loadLensTypes(): void {
        this.lensService.getAvailableLensTypes().subscribe((result: ListOption[]) => {
            if (this.selectedLens !== 'all') {
                result = result.filter((lens) => lens.Id === this.activeLensTypeId);
            }

            this.lenses = result;
            this.lenses.push(new ListOption(null, '', ''));
        });
    }

    get activeLensTypeId(): number {
        switch (this.selectedLens) {
            case 'dreamlite':
                return LensTypes.DreamLite;
            case 'byopremium':
            case 'MiSight':
                return LensTypes.Soft;
            default:
                return -1;
        }
    }

    loadClients(): void {
        this.selectedLens = 'all';
        this.clientInfoQueryCriteria.clear();
        this.clientService.getClientList(this.clientInfoQueryCriteria).subscribe((list) => this.setupClientList(list));
    }

    loadClientReplacementNotifications(lenstype: string): void {
        this.checkedList = [];
        let lenstypeId: number;
        let replacementGroup: ReplacementGroup;
        switch (lenstype) {
            case 'dreamlite': {
                lenstypeId = LensTypes.DreamLite;
                break;
            }
            case 'byopremium': {
                lenstypeId = LensTypes.Soft;
                replacementGroup = ReplacementGroup.BYOPREMIUM;
                break;
            }
            case 'MiSight': {
                lenstypeId = LensTypes.Soft;
                replacementGroup = ReplacementGroup.MISIGHT;
                break;
            }
            default: {
                return;
            }
        }
        if (this.selectedDate) {
            this.clientInfoQueryCriteria.StartDate = this.selectedDate;
        }

        this.clientInfoQueryCriteria.FilterByFields['LensTypeId'] = '' + lenstypeId;
        this.clientInfoQueryCriteria.FilterByFields['ReplacementGroup'] = replacementGroup;
        this.clientInfoQueryCriteria.clear();
        this.clientService
            .getClientReplacementNotifications(this.clientInfoQueryCriteria)
            .subscribe((list) => this.setupClientList(list));
    }

    setupClientList(list: ClientList): void {
        this.clients = list.Items;
        this.totalClients = list.TotalItems;
        this.maxPage = parseInt('' + this.totalClients / 50, 10);

        this.setPagesShown();
        this.loading = false;
    }

    selectClient(client: ClientInfo): void {
        this.router.navigate(['/client', client.Id]).then();
    }

    onPage(command: string | number): void {
        switch (command) {
            case 'first': {
                this.clientInfoQueryCriteria.PageIndex = 0;
                break;
            }
            case 'previous': {
                this.clientInfoQueryCriteria.PageIndex--;
                break;
            }
            case 'next': {
                this.clientInfoQueryCriteria.PageIndex++;
                break;
            }
            case 'last': {
                this.clientInfoQueryCriteria.PageIndex = this.maxPage;
                break;
            }
            default: {
                this.clientInfoQueryCriteria.PageIndex = parseInt(command?.toString(), 10);
            }
        }

        if (this.selectedLens === 'all') {
            this.loadClients();
        } else {
            this.loadClientReplacementNotifications(this.selectedLens);
        }

        this.setPagesShown();
    }

    setPagesShown(): void {
        if (this.totalClients > 5 * this.clientInfoQueryCriteria.PageSize) {
            this.pagesShown = [];
            this.pagesShown.push(this.clientInfoQueryCriteria.PageIndex - 2);
            this.pagesShown.push(this.clientInfoQueryCriteria.PageIndex - 1);
            this.pagesShown.push(this.clientInfoQueryCriteria.PageIndex);
            this.pagesShown.push(this.clientInfoQueryCriteria.PageIndex + 1);
            this.pagesShown.push(this.clientInfoQueryCriteria.PageIndex + 2);

            if (this.pagesShown[0] < 0) {
                this.pagesShown = [0, 1, 2, 3, 4];
            }

            if (this.pagesShown[0] > this.maxPage - 4) {
                this.pagesShown = [
                    this.maxPage - 4,
                    this.maxPage - 3,
                    this.maxPage - 2,
                    this.maxPage - 1,
                    this.maxPage,
                ];
            }
        } else {
            this.pagesShown = [];
            for (let i = 0; i * 50 < this.totalClients; i++) {
                this.pagesShown.push(i);
            }
        }

        this.itemsShownLowerBound = this.clientInfoQueryCriteria.PageIndex * this.clientInfoQueryCriteria.PageSize + 1;
        this.itemsShownUpperBound =
            this.clientInfoQueryCriteria.PageIndex * this.clientInfoQueryCriteria.PageSize + 50 > this.totalClients
                ? this.totalClients
                : this.clientInfoQueryCriteria.PageIndex * this.clientInfoQueryCriteria.PageSize + 50;
    }

    onFilter(filter: string, value: string): void {
        if (filter === 'LensTypeId' && value === 'null') {
            value = '';
        }

        this.clientInfoQueryCriteria.FilterByFields[filter] = value;
        this.loadClients();
    }

    exportClients(): void {
        this.loaderService.show();

        let exportLens = this.selectedLens;

        if (exportLens === 'all') {
            exportLens = 'regular';
        } else if (exportLens === 'byopremium') {
            exportLens = 'byo';
        }

        this.clientService
            .exportClients(exportLens)
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe((file) => {
                Util.openBlobInBrowser(file, file.name);
            });
    }

    selected(lenstype: string): string {
        return lenstype === this.selectedLens ? 'selected' : 'unselected';
    }

    onLenstypeFilter(lenstype: string): void {
        this.selectedLens = lenstype;
        if (lenstype === 'all') {
            this.router.navigate(['/clients']).then();
        } else {
            this.router
                .navigate(['/clients'], {
                    queryParams: { replacement: this.selectedLens },
                })
                .then();
        }
    }

    onOrderBy(filter): void {
        if (this.clientInfoQueryCriteria['OrderByField'] === filter) {
            this.clientInfoQueryCriteria['OrderByAscending'] = !this.clientInfoQueryCriteria['OrderByAscending'];
        } else {
            this.clientInfoQueryCriteria['OrderByField'] = filter;
            this.clientInfoQueryCriteria['OrderByAscending'] = false;
        }

        this.onLenstypeFilter(this.selectedLens);
    }

    isOrderByThis(filter: string): boolean {
        return (
            this.clientInfoQueryCriteria['OrderByField'] === filter && this.clientInfoQueryCriteria['OrderByAscending']
        );
    }

    selectedPage(page: number): string {
        return this.clientInfoQueryCriteria.PageIndex === page ? 'selectedPage' : '';
    }

    async removeFromList() {
        this.loaderService.show();
        await lastValueFrom(this.clientService.removeReplacementNotifications(this.checkedList));
        this.loadClientReplacementNotifications(this.selectedLens);
        this.loaderService.hide();
    }

    updateCheckedList(event, orderId: number): void {
        if (event.target.checked) {
            this.checkedList.push(orderId);
        } else {
            const orderIndex = this.checkedList.indexOf(orderId);
            if (orderIndex !== -1) {
                this.checkedList.splice(orderIndex, 1);
            }
        }
    }
}
