import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PsRoutingModule } from './ps-routing.module';
import { PsHomeComponent } from './home/ps-home.component';
import { PsComponent } from './ps.component';
import { SupportorderOverviewComponent } from './supportorderoverview/supportorder-overview.component';
import { SupportorderoverviewPOAttachmentComponent } from './supportorderoverview/supportorder-overview-po-attachment';
import { SupportorderStatsComponent } from './supportorderoverview/supportorder-stats.component';
import { PsSupportOrderComponent } from './supportorder/ps-supportorder.component';
import { PsHeaderComponent } from './supportorder/header/ps-header.component';
import { PsHeaderItemComponent } from './supportorder/header/ps-header-item.component';
import { PsAttachmentsComponent } from './supportorder/components/ps-attachments.component';
import { PsMeasurementsComponent } from '@app/professional-service/supportorder/components/ps-measurements/ps-measurements.component';
import { PsClientAndOpticianComponent } from './supportorder/components/ps-clientandoptician.component';
import { PsOrderComponent } from './supportorder/components/ps-order.component';
import { PsChatsComponent } from './supportorder/components/ps-chats.component';
import { FitLensModule } from '../fitlens/fitlens.module';
import { PsClientMaintenanceComponent } from './clientmaintenance/ps-clientmaintenance.component';
import { PsClientMaintenanceOverviewComponent } from './clientmaintenance/ps-clientmaintenance-overview.component';
import { PsClientMaintenanceDetailsComponent } from './clientmaintenance/components/ps-clientmaintenance-details.component';
import { PsClientActionsComponent } from './clientmaintenance/components/ps-clientactions.component';
import { PsClientLastOrderComponent } from './clientmaintenance/components/ps-clientlastorder.component';
import { PsClientAttachmentsComponent } from './clientmaintenance/components/ps-clientattachments.component';
import { OpticianInfoComponent } from './shared/optician-info/optician-info.component';
import { PsCreditLimitNotificationsComponent } from './returns/ps-creditlimitnotifications.component';
import { PsDreamliteTableComponent } from './supportorder/components/ps-dreamlite-table/ps-dreamlite-table.component';
import { PsEyeliteimportComponent } from './clientmaintenance/components/ps-eyeliteimport/ps-eyeliteimport.component';
import { DreamlitefixtypesDialogComponent } from './dialogs/dreamlitefixtypes-dialog.component';
import { DatePipeProxy } from '@app/shared/pipes/date.pipe';
import { ReOrderDialogComponent } from '@app/professional-service/dialogs/reorder-dialog.component';
import { ComplaintsOverviewComponent } from './complaintsoverview/complaintsoverview.component';
import { PsGrandvisionComponent } from './supportorder/components/ps-grandvision/ps-grandvision.component';
import { ComplaintComponent } from './complaint/complaint.component';
import { PsReturnsComponent } from './returns/ps-returns.component';
import { EyeliteProcessChangesGuard } from '@app/core/guards/eyelite-process-changes.guard';

@NgModule({
    imports: [
        CommonModule,
        PsRoutingModule,
        ModalModule,
        NgxDatatableModule,
        SharedModule,
        FitLensModule,
        TypeaheadModule.forRoot(),
    ],
    declarations: [
        PsComponent,
        PsSupportOrderComponent,
        PsHomeComponent,
        PsHeaderComponent,
        PsHeaderItemComponent,
        PsAttachmentsComponent,
        PsMeasurementsComponent,
        PsClientAndOpticianComponent,
        PsOrderComponent,
        PsChatsComponent,
        SupportorderOverviewComponent,
        SupportorderoverviewPOAttachmentComponent,
        SupportorderStatsComponent,
        PsClientMaintenanceComponent,
        PsClientMaintenanceOverviewComponent,
        PsClientMaintenanceDetailsComponent,
        PsClientActionsComponent,
        PsClientLastOrderComponent,
        PsClientAttachmentsComponent,
        OpticianInfoComponent,
        PsCreditLimitNotificationsComponent,
        PsDreamliteTableComponent,
        PsEyeliteimportComponent,
        DreamlitefixtypesDialogComponent,
        ReOrderDialogComponent,
        ComplaintsOverviewComponent,
        PsGrandvisionComponent,
        ComplaintComponent,
        PsReturnsComponent,
    ],
    providers: [DatePipeProxy, EyeliteProcessChangesGuard],
})
export class PsModule {}
