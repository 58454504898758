import { Component } from '@angular/core';
import { AppStateService } from '@app/shared/appservices/appState.service';

@Component({
    templateUrl: './ps-home.component.html',
    styles: [':host { width: 100%; }'],
})
export class PsHomeComponent {
    constructor(public appState: AppStateService) {}

    complaintsOverviewTileEnabled(): boolean {
        return this.appState.isPs;
    }

    scanTileEnabled(): boolean {
        return this.appState.isReturnsEnabled;
    }
}
