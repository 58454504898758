import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NumberFormatPipe } from '@app/shared/pipes/number.format.pipe';
import { TranslateService } from '@ngx-translate/core';
import { ListOption } from '@app/shared/models';

@Component({
    selector: 'mpc-model-label',
    templateUrl: './model-label.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ModelLabelComponent {
    _listoptions: ListOption[];
    _value = '';

    @Input() id;
    @Input() label = '';
    @Input() labelParams = {};
    @Input()
    set value(newValue: string | number) {
        this._value = newValue?.toString();
        this.setDisplayValue();
    }
    @Input() valueSelector: 'Code' | 'Name' = 'Name';

    @Input() info = '';
    @Input() infoPosition = 'left';
    @Input() fullheight = false;
    @Input() formatting: unknown;
    @Input() postfix = '';
    @Input() breakpoint = 'md'; // sm - md - lg - xl
    @Input() labelWidth = 4;
    @Input() customLabelControlClass: string;
    @Input() customControlClass: string;

    @Input()
    set listoptions(value: ListOption[]) {
        this._listoptions = value;
        this.setDisplayValue();
    }

    displayValue = '';
    myClass: string;
    labelClass: string[];
    controlClass: string[];

    constructor(
        public numberFormatter: NumberFormatPipe,
        public translateService: TranslateService,
    ) {}

    ngOnInit() {
        this.setDisplayValue();
        this.refreshSubClasses();
    }

    ngOnChanges() {
        this.refreshSubClasses();
    }

    setDisplayValue() {
        if (this._value !== null && this._value !== undefined) {
            const translatedPostfix = this.postfix ? this.translateService.instant(this.postfix) : '';

            let localValue = this._value;

            if (this._listoptions) {
                localValue = this.getValueFromList(this._listoptions, Number(localValue));
            }

            this.displayValue = this.numberFormatter.transform(localValue, this.formatting) + ' ' + translatedPostfix;
        } else {
            this.displayValue = '';
        }
    }

    getValueFromList(options: ListOption[], selectedId: number): string {
        const found = options.find((option) => option.Id === selectedId);

        if (this.valueSelector === 'Code') {
            return found?.Code ?? '';
        }

        return found?.Name ?? '';
    }

    refreshSubClasses() {
        this.myClass = this.getMyClass();
        this.labelClass = this.getLabelClass();
        this.controlClass = this.getControlClass();
    }

    getMyClass() {
        let result = '';

        if (this.fullheight) {
            result = 'fullHeight';
        }

        return result;
    }

    getLabelClass(): string[] {
        const result = [];

        if (this.breakpoint) {
            result.push(`col-${this.breakpoint}-${this.labelWidth}`);
        } else {
            result.push(`col-${this.labelWidth}`);
        }

        if (this.customLabelControlClass) {
            result.push(this.customLabelControlClass);
        }

        return result;
    }

    getControlClass(): string[] {
        const result = [];

        if (this.label) {
            if (this.breakpoint) {
                result.push(`col-${this.breakpoint}-${12 - this.labelWidth}`);
            } else {
                result.push(`col-${12 - this.labelWidth}`);
            }
        } else {
            result.push(`col-12`);
        }

        if (this.customControlClass) {
            result.push(this.customControlClass);
        }

        return result;
    }
}
