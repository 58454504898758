<div class="modal-header">
    <h4 class="modal-title m-auto">{{ title }}</h4>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-12" [innerHtml]="content"></div>
    </div>
</div>

<div class="modal-footer accented-background">
    <button class="btn btn-default ms-auto" (click)="close()">
        {{ 'general.close' | translate }}
    </button>
</div>
