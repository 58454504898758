import { Component, AfterViewInit, Input } from '@angular/core';
import { SupportOrderService } from '@app/core/services/api/support-order.service';
import { SupportOrderStatus } from '../models/SupportOrderStatus.model';
import { SupportorderOverviewComponent } from './supportorder-overview.component';
import { OrderService } from '@app/core/services/api/order.service';
import { SupportEventService } from '@app/core/services/supportEvent.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { Features, FeatureCategories, SupportOrderStatuses } from '@app/shared/enums';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'supportorder-stats',
    templateUrl: 'supportorder-stats.component.html',
    styleUrls: ['./supportorder-stats.component.scss'],
})
export class SupportorderStatsComponent implements AfterViewInit {
    supportorderstatusses = [];
    supportorderstatussesCount: unknown;

    Features = Features;
    FeatureCategories = FeatureCategories;

    myOrdersCount: number;
    ordersWithUnreadMessagesCount: number;
    blockedOrdersCount: number;
    perosEnabled = false;

    private isOverviewPerosSelected = false;
    private isOverviewBlockedOrdersSelected = false;
    private isOverviewMyOrdersSelected = false;
    private isOverviewOrdersWithUnreadMessagesSelected = false;
    private isOverviewDirectOrdersSelected = false;

    @Input() supportorderoverviewComponent: SupportorderOverviewComponent;

    constructor(
        public supportOrderService: SupportOrderService,
        public orderService: OrderService,
        private supportEventService: SupportEventService,
        public appState: AppStateService,
    ) {
        this.perosEnabled = this.appState.isDistributorFeatureEnabled(Features.Peros);
        this.supportEventService.blockedOrdersApproved$.subscribe(() => {
            lastValueFrom(orderService.getBlockedOrdersCount()).then((result) => {
                this.blockedOrdersCount = result;
            });
        });
    }

    async ngAfterViewInit() {
        this.supportorderstatusses = await lastValueFrom(this.supportOrderService.getSupportOrderStatuses());
        this.supportorderstatusses = this.supportorderstatusses.filter(
            (status) =>
                status.Id !== SupportOrderStatuses.Closed &&
                status.Id !== SupportOrderStatuses.ToGlobalSupport &&
                status.Id !== SupportOrderStatuses.GlobalSupportAnswered,
        );
        this.supportorderstatussesCount = await lastValueFrom(this.supportOrderService.getSupportOrderStatusCounts());

        this.loadAdditionalStatistics().then();
    }

    async loadAdditionalStatistics() {
        this.myOrdersCount = await lastValueFrom(this.supportOrderService.getMySupportOrdersCount());
        this.ordersWithUnreadMessagesCount = await lastValueFrom(this.supportOrderService.getUnreadMessagesCount());
        this.blockedOrdersCount = await lastValueFrom(this.orderService.getBlockedOrdersCount());
    }

    getCount(id: number): string {
        if (this.supportorderstatussesCount) {
            const count = this.supportorderstatussesCount[id.toString()];
            if (count !== null && count !== undefined) {
                return count.toString();
            }
        }
        return '';
    }

    getOrderStatusClass(id: number): string {
        return this.supportorderoverviewComponent.isSupportOrderStatusSelected(id) ? 'on' : '';
    }

    onSupportOrderStatus(supportOrderStatus: SupportOrderStatus): void {
        this.isOverviewPerosSelected = false;
        this.isOverviewBlockedOrdersSelected = false;
        this.isOverviewMyOrdersSelected = false;
        this.isOverviewOrdersWithUnreadMessagesSelected = false;
        this.isOverviewDirectOrdersSelected = false;
        this.supportorderoverviewComponent.onSortBySupportOrderStatus(supportOrderStatus);
    }

    onButtonDirectOrdersClick(): void {
        this.isOverviewPerosSelected = false;
        this.isOverviewBlockedOrdersSelected = false;
        this.isOverviewMyOrdersSelected = false;
        this.isOverviewOrdersWithUnreadMessagesSelected = false;
        this.isOverviewDirectOrdersSelected = !this.isOverviewDirectOrdersSelected;
        this.supportorderoverviewComponent.setOverviewDirectOrders();
    }

    onButtonMyOrdersClick(): void {
        this.isOverviewPerosSelected = false;
        this.isOverviewBlockedOrdersSelected = false;
        this.isOverviewMyOrdersSelected = !this.isOverviewMyOrdersSelected;
        this.isOverviewOrdersWithUnreadMessagesSelected = false;
        this.isOverviewDirectOrdersSelected = false;
        this.supportorderoverviewComponent.setOverviewMyOrders(this.isOverviewMyOrdersSelected);
    }

    onButtonUnreadMessagesClick(): void {
        this.isOverviewPerosSelected = false;
        this.isOverviewBlockedOrdersSelected = false;
        this.isOverviewMyOrdersSelected = false;
        this.isOverviewOrdersWithUnreadMessagesSelected = !this.isOverviewOrdersWithUnreadMessagesSelected;
        this.isOverviewDirectOrdersSelected = false;
        this.supportorderoverviewComponent.setOverviewOrdersWithUnreadMessages(
            this.isOverviewOrdersWithUnreadMessagesSelected,
        );
    }

    onButtonPerosClick(): void {
        this.isOverviewPerosSelected = !this.isOverviewPerosSelected;
        this.isOverviewBlockedOrdersSelected = false;
        this.isOverviewMyOrdersSelected = false;
        this.isOverviewOrdersWithUnreadMessagesSelected = false;
        this.isOverviewDirectOrdersSelected = false;
        this.supportorderoverviewComponent.setOverviewPeros(this.isOverviewPerosSelected);
    }

    onButtonBlockedOrdersClick(): void {
        this.isOverviewPerosSelected = false;
        this.isOverviewBlockedOrdersSelected = !this.isOverviewBlockedOrdersSelected;
        this.isOverviewMyOrdersSelected = false;
        this.isOverviewOrdersWithUnreadMessagesSelected = false;
        this.isOverviewDirectOrdersSelected = false;
        this.supportorderoverviewComponent.setOverviewBlockedOrders(this.isOverviewBlockedOrdersSelected);
    }

    getClassButtonPeros(): string {
        return this.isOverviewPerosSelected ? 'on' : '';
    }

    getClassButtonMyOrders(): string {
        return this.isOverviewMyOrdersSelected ? 'on' : '';
    }

    getClassButtonUnreadMessages(): string {
        return this.isOverviewOrdersWithUnreadMessagesSelected ? 'on' : '';
    }

    getClassButtonDirectOrders(): string {
        return this.isOverviewDirectOrdersSelected ? 'on' : '';
    }

    getClassButtonBlockedOrders(): string {
        return this.isOverviewBlockedOrdersSelected ? 'on' : '';
    }
}
