import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LensProfileImageRequest } from '@app/shared/models/lensProfileImageRequest.model';
import { LensProfileImageResponse } from '@app/shared/models/lensProfileImageResponse.model';

@Injectable({
    providedIn: 'root',
})
export class LensProfileImageService {
    private readonly basePath = 'api/lens-profile-images';

    constructor(private readonly httpClient: HttpClient) {}

    public getLensProfileImage(lensProfileImageRequest: LensProfileImageRequest): Observable<LensProfileImageResponse> {
        return this.httpClient.post<LensProfileImageResponse>(this.basePath, lensProfileImageRequest);
    }
}
