import { Material } from './material.model';
import { Color } from './color.model';

export class MaterialColor {
    Id: number;
    MaterialId: number;
    ColorId: number;
    EasyLookArticleNumber: string;
    Material: Material;
    Color: Color;
    SortOrder: number;
}
