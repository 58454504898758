export class UpdateCartItemQuantityRequest {
    CartItemId: number;
    Quantity: number;
    EyeSideId: number;

    constructor(cartItemId: number, quantity: number, eyeSideId: number) {
        this.CartItemId = cartItemId;
        this.Quantity = quantity;
        this.EyeSideId = eyeSideId;
    }
}
