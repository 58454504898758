import { LensDefinitionGeometryElement } from '@app/shared/models/lensDefinitionGeometryElement.model';

export class LensDefinitionGeometryEdgeHk1 extends LensDefinitionGeometryElement {
    constructor() {
        super();
        this.$type = 'LensDefinitionGeometryEdgeHk1';
        this.InstanceType = 'EdgeHk1';
    }
    public EdgeHk1Radius: string;
    public EdgeHk1AstigmaticRadius: string;
    public EdgeHk1Excentricity: string;
    public EdgeHk1AstigmaticExcentricity: string;
    public EdgeHk1Lpaz: string;
    public EdgeHk1Legde: string;
    public EdgeHk1Xpaz: string;
    public EdgeHk1SemiDiameterStart: string;
    public EdgeHk1SemiDiameterEnd: string;

    // CalculationResults
    public EdgeHk1RadiusResult: number;
    public EdgeHk1AstigmaticRadiusResult: number;
    public EdgeHk1ExcentricityResult: number;
    public EdgeHk1AstigmaticExcentricityResult: number;
    public EdgeHk1LpazResult: number;
    public EdgeHk1LegdeResult: number;
    public EdgeHk1XpazResult: number;
    public EdgeHk1SemiDiameterStartResult: number;
    public EdgeHk1SemiDiameterEndResult: number;
}
