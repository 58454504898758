import { LensDefinitionGeometryElement } from '@app/shared/models/lensDefinitionGeometryElement.model';

export class LensDefinitionGeometryEllips extends LensDefinitionGeometryElement {
    constructor() {
        super();
        this.$type = 'LensDefinitionGeometryEllips';
        this.InstanceType = 'Ellips';
    }

    public EllipsRadius: string;
    public EllipsAstigmaticRadius: string;
    public EllipsExcentricity: string;
    public EllipsAstigmaticExcentricity: string;
    public EllipsSemiDiameterStart: string;
    public EllipsSemiDiameterEnd: string;

    // CalculationResults
    public EllipsRadiusResult: number;
    public EllipsAstigmaticRadiusResult: number;
    public EllipsExcentricityResult: number;
    public EllipsAstigmaticExcentricityResult: number;
    public EllipsSemiDiameterStartResult: number;
    public EllipsSemiDiameterEndResult: number;
}
