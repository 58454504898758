import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AlertService {
    private subject = new Subject<void | object>();
    private keepAfterNavigationChange = false;

    constructor(
        private router: Router,
        private toastrService: ToastrService,
    ) {
        // clear alert message on route change
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next();
                }
            }
        });
    }

    success(message: string, keepAfterNavigationChange = false): void {
        if (message) {
            setTimeout(() => this.toastrService.success(message));
        }

        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message });
    }

    info(message: string, keepAfterNavigationChange = false): void {
        if (message) {
            setTimeout(() => this.toastrService.info(message));
        }

        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'default', text: message });
    }

    warning(message: string, keepAfterNavigationChange = false): void {
        if (message) {
            setTimeout(() => this.toastrService.warning(message));
        }

        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'warning', text: message });
    }

    error(message: string, keepAfterNavigationChange = false, title: string = undefined): void {
        if (message) {
            setTimeout(() => this.toastrService.error(message, title));
        }

        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message });
    }

    getMessage(): Observable<unknown> {
        return this.subject.asObservable();
    }
}
