import { FittedLens } from './fitted-lens.model';
import { Product } from './product.model';
import { Serial } from './serial.model';

export class OrderLine {
    public Id: number;
    public OrderId: number;
    public SerialId: number;
    public ArtNr: string;
    public ProductId: number;
    public PackagingQuantity: number;
    public Quantity: number;
    public FittedLensId: number;
    public EyeSideId: number;
    public OrderLineStatusId: number;
    public LensTypeId: number;
    public Enabled = true;
    public IsSpare: boolean;

    public FixTypeId: number;
    public FixTypeName: string;

    public CreditReasonId: number;
    public CreditExportDate: Date;
    public ReadyForCreditExport: boolean;
    public CreditDescription: string;

    public TrackAndTraceLink: string;

    // read only
    public OrderLineStatusCode: string;
    public OrderLineStatusName: string;
    public Name: string;
    public Description: string;
    public ShippedDate: Date;

    // relations
    public Product: Product;
    public FittedLens: FittedLens;
    public Serial: Serial;
}
