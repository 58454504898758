import { Injectable } from '@angular/core';
import { ProductGroup, Product, FittedLens, Client, Order, OrderLine } from '@app/shared/models';
import { SessionService } from '@app/shared/appservices/session.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ShopService {
    productGroup: ProductGroup;
    product: Product;
    _client: Client;
    leftFittedLens: FittedLens;
    rightFittedLens: FittedLens;
    orderLeft: Order;
    orderRight: Order;

    orderlines: OrderLine[];

    set client(value: Client) {
        this._client = value;
        this.sessionService.save('shop-client', value);
    }

    get client(): Client {
        return this._client;
    }

    constructor(
        public sessionService: SessionService,
        private router: Router,
    ) {
        this._client = this.sessionService.getAsObject<Client>('shop-client');
    }

    clear(): void {
        this.clearSelection();
        this._client = null;
        this.orderLeft = null;
        this.orderRight = null;
        this.sessionService.remove('shop-client');
    }

    clearSelection(): void {
        this.product = null;
        this.productGroup = null;
        this.leftFittedLens = null;
        this.rightFittedLens = null;
    }

    get isLensProduct(): boolean {
        return (this.leftFittedLens || this.rightFittedLens) && true;
    }

    async openShopWithOrder(orderLeft: Order, orderRight: Order) {
        this.orderLeft = orderLeft;
        this.orderRight = orderRight;
        this.router.navigate(['/shop']);
    }
}
