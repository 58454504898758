<div *ngIf="loading" class="spinner-border spinner-border-sm mx-1"></div>

<ng-container *ngIf="!loading">
    <div class="row" *ngIf="!eyeliteFileImport">
        <div class="col-sm-4 col-sm-offset-4">
            <label class="btn btn-secondary upload-button">
                <input type="file" name="file" ngFileSelect (change)="handleFileInput($any($event.target).files)" />
                <strong>{{ 'fileupload.dragorbrowseButton' | translate }}</strong>
            </label>
        </div>
    </div>

    <div *ngIf="eyeliteFileImport">
        <!-- Stats -->
        <div class="row">
            <div class="col stats">
                {{ 'eyeliteimport.createdon' | translate }}:
                <span>{{ eyeliteFileImport.IsCreatedOn }}</span>
            </div>

            <div class="col stats">
                {{ 'eyeliteimport.createdby' | translate }}:
                <span>{{ eyeliteFileImport.IsCreatedBy }}</span>
            </div>

            <div class="col stats">
                {{ 'eyeliteimport.numberofmeasurements' | translate }}:
                <span>{{ eyeliteFileImport.NumberOfMeasurements() }}</span>
            </div>

            <div class="col stats">
                {{ 'eyeliteimport.numberoforders' | translate }}:
                <span>{{ eyeliteFileImport.NumberOfOrders() }}</span>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col">
                <button type="button" class="btn btn-danger me-3" (click)="resetEyeliteImport()">
                    {{ 'eyeliteimport.reset-button' | translate }}
                </button>
                <button type="button" class="btn btn-success" (click)="processEyeliteImport()">
                    {{ 'eyeliteimport.processbutton' | translate }}
                </button>
            </div>
        </div>

        <!-- Measurements -->
        <div *ngIf="eyeliteFileImport.NumberOfMeasurements() > 0">
            <div class="row mt-4">
                <div class="col">
                    <h3>
                        {{ 'eyeliteimport.measurements' | translate }}
                        <div class="selectallwrapper" (click)="selectAllMeasurementsStateChanged()">
                            <div class="status">
                                <i *ngIf="allMeasurementsSelected" class="fas fa-check"></i>
                            </div>

                            {{ 'general.selectall' | translate }}
                        </div>
                    </h3>
                </div>
            </div>

            <div class="row px-2">
                <div class="col-3 p-2 mb-2" *ngFor="let measurement of eyeliteFileImport.Import.Client.Measurements">
                    <div
                        class="accented-background measurement-block p-3 position-relative h-100"
                        (click)="toggleMeasurementSelection(measurement)">
                        <h5>
                            <ng-container *ngIf="measurement.Eyeside === 100">
                                {{ 'general.os' | translate }}
                            </ng-container>
                            <ng-container *ngIf="measurement.Eyeside === 200">
                                {{ 'general.od' | translate }}
                            </ng-container>
                            - {{ measurement.Date }}
                        </h5>

                        <!-- Toggle icon -->
                        <div class="checkedIcon">
                            <i *ngIf="measurement.IsSelected" class="fas fa-check"></i>
                        </div>

                        <!-- Information -->
                        <div class="row">
                            <div class="col-12">
                                {{ 'general.topographer' | translate }}:
                                {{ measurement.TopographerName }}
                            </div>
                            <div class="col-12">
                                Sph:
                                {{ measurement.Power | numberFormat: '{"parameterType":"POW"}' }}
                                Cyl:
                                {{ measurement.Cyl | numberFormat: '{"parameterType":"CYL"}' }}
                                As:
                                {{ measurement.Axis | numberFormat: '{"parameterType":"AX"}' }}
                            </div>
                        </div>

                        <!-- Baseline -->
                        <div class="row mt-2" *ngIf="measurement.BaselineMeasurement === 1">
                            <div class="col">
                                {{ 'measurementoptiontype.baseline' | translate }}
                                <div class="checkedIcon checkedIconInline">
                                    <i class="fas fa-check"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Number of orders: RGP -->
        <div
            *ngIf="
                eyeliteFileImport.NumberOfOrders() > 0 || eyeliteFileImport.Import.Client.Lenses.RgpLenses.length > 0
            ">
            <div class="row mt-4">
                <div class="col">
                    <h3>
                        {{ 'lenstype.rgp' | translate }}
                        <div class="selectallwrapper" (click)="selectAllRgpLensesStateChanged()">
                            <div class="status">
                                <i *ngIf="allRgpLensesSelected" class="fas fa-check"></i>
                            </div>

                            {{ 'general.selectall' | translate }}
                        </div>
                    </h3>
                </div>
            </div>

            <div class="row px-2">
                <div class="col-3 p-2 mb-2" *ngFor="let lens of eyeliteFileImport.Import.Client.Lenses.RgpLenses">
                    <div
                        class="accented-background measurement-block p-3 position-relative h-100"
                        (click)="toggleLensSelection(lens)">
                        <h5>
                            <ng-container *ngIf="lens.EyeSideId === 1">{{ 'general.os' | translate }}</ng-container>
                            <ng-container *ngIf="lens.EyeSideId === 2">{{ 'general.od' | translate }}</ng-container>
                            - {{ lens.Date | date: 'short' }}
                        </h5>

                        <!-- Toggle icon -->
                        <div class="checkedIcon">
                            <i *ngIf="lens.IsSelected" class="fas fa-check"></i>
                        </div>

                        <!-- Information -->
                        <div class="row">
                            <div class="col-12">
                                {{ lens.ArticleName }} {{ lens.MaterialCode }}
                                {{ lens.Color }}
                            </div>
                            <div class="col-12 mt-2">
                                {{ 'general.quantity' | translate }}:
                                {{ lens.Quantity }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Number of orders: SOFT -->
        <div
            *ngIf="
                eyeliteFileImport.NumberOfOrders() > 0 || eyeliteFileImport.Import.Client.Lenses.SoftLenses.length > 0
            ">
            <div class="row mt-4">
                <div class="col">
                    <h3>
                        {{ 'lenstype.soft' | translate }}
                        <div class="selectallwrapper" (click)="selectAllSoftLensesStateChanged()">
                            <div class="status">
                                <i *ngIf="allSoftLensesSelected" class="fas fa-check"></i>
                            </div>

                            {{ 'general.selectall' | translate }}
                        </div>
                    </h3>
                </div>
            </div>

            <div class="row px-2">
                <div class="col-3 p-2 mb-2" *ngFor="let lens of eyeliteFileImport.Import.Client.Lenses.SoftLenses">
                    <div
                        class="accented-background measurement-block p-3 position-relative h-100"
                        (click)="toggleLensSelection(lens)">
                        <h5>
                            <ng-container *ngIf="lens.EyeSideId === 1">{{ 'general.os' | translate }}</ng-container>
                            <ng-container *ngIf="lens.EyeSideId === 2">{{ 'general.od' | translate }}</ng-container>
                            - {{ lens.Date | date: 'short' }}
                        </h5>

                        <!-- Toggle icon -->
                        <div class="checkedIcon">
                            <i *ngIf="lens.IsSelected" class="fas fa-check"></i>
                        </div>

                        <!-- Information -->
                        <div class="row">
                            <div class="col-12">
                                {{ lens.ArticleName }} {{ lens.MaterialCode }}
                                {{ lens.Color }}
                            </div>
                            <div class="col-12 mt-2">
                                {{ 'general.quantity' | translate }}:
                                {{ lens.Quantity }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Number of orders: Disposable -->
        <div
            *ngIf="
                eyeliteFileImport.NumberOfOrders() > 0 ||
                eyeliteFileImport.Import.Client.Lenses.DisposableLenses.length > 0
            ">
            <div class="row mt-4">
                <div class="col">
                    <h3>
                        {{ 'lens.disposable' | translate }}
                        <div class="selectallwrapper" (click)="selectAllDisposableLensesStateChanged()">
                            <div class="status">
                                <i *ngIf="allDisposableLensesSelected" class="fas fa-check"></i>
                            </div>

                            {{ 'general.selectall' | translate }}
                        </div>
                    </h3>
                </div>
            </div>

            <div class="row px-2">
                <div
                    class="col-3 p-2 mb-2"
                    *ngFor="let lens of eyeliteFileImport.Import.Client.Lenses.DisposableLenses">
                    <div
                        class="accented-background measurement-block p-3 position-relative h-100"
                        (click)="toggleLensSelection(lens)">
                        <h5>
                            <ng-container *ngIf="lens.EyeSideId === 1">{{ 'general.os' | translate }}</ng-container>
                            <ng-container *ngIf="lens.EyeSideId === 2">{{ 'general.od' | translate }}</ng-container>
                            - {{ lens.Date | date: 'short' }}
                        </h5>

                        <!-- Toggle icon -->
                        <div class="checkedIcon">
                            <i *ngIf="lens.IsSelected" class="fas fa-check"></i>
                        </div>

                        <!-- Information -->
                        <div class="row">
                            <div class="col-12">
                                {{ lens.ArticleName }} {{ lens.MaterialCode }}
                                {{ lens.Color }}
                            </div>
                            <div class="col-12 mt-2">
                                {{ 'general.quantity' | translate }}:
                                {{ lens.Quantity }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Number of orders: Dreamlite -->
        <div
            *ngIf="
                eyeliteFileImport.NumberOfOrders() > 0 ||
                eyeliteFileImport.Import.Client.Lenses.DreamLiteLenses.length > 0
            ">
            <div class="row mt-4">
                <div class="col">
                    <h3>
                        {{ 'lenstype.ortho-k' | translate }}
                        <div class="selectallwrapper" (click)="selectAllDreamLiteLensesStateChanged()">
                            <div class="status">
                                <i *ngIf="allDreamLiteLensesSelected" class="fas fa-check"></i>
                            </div>

                            {{ 'general.selectall' | translate }}
                        </div>
                    </h3>
                </div>
            </div>

            <div class="row px-2">
                <div class="col-3 p-2 mb-2" *ngFor="let lens of eyeliteFileImport.Import.Client.Lenses.DreamLiteLenses">
                    <div
                        class="accented-background measurement-block p-3 position-relative h-100"
                        (click)="toggleLensSelection(lens)">
                        <h5>
                            <ng-container *ngIf="lens.EyeSideId === 1">{{ 'general.os' | translate }}</ng-container>
                            <ng-container *ngIf="lens.EyeSideId === 2">{{ 'general.od' | translate }}</ng-container>
                            - {{ lens.Date }}
                        </h5>

                        <!-- Toggle icon -->
                        <div class="checkedIcon">
                            <i *ngIf="lens.IsSelected" class="fas fa-check"></i>
                        </div>

                        <!-- Information -->
                        <div class="row">
                            <div class="col-12">{{ lens.ArticleNumber }} {{ lens.MaterialNumber }}</div>
                            <div class="col-12 mt-2">{{ 'general.quantity' | translate }}: 1</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
