import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { WordpressApiService } from '@app/core/services/external/wordpress-api.service';
import { Post } from '@app/shared/models/post.model';

@Component({
    templateUrl: './screensaver.component.html',
    styleUrls: ['./screensaver.component.scss'],
    providers: [WordpressApiService],
    encapsulation: ViewEncapsulation.None,
})
export class ScreensaverComponent implements OnInit {
    screensaverItems: Post[];

    constructor(
        public modalService: BsModalService,
        public modalServiceRef: BsModalRef,
        public wordpressApiService: WordpressApiService,
    ) {}

    ngOnInit(): void {
        this.wordpressApiService.getScreensaverItems().subscribe((result) => {
            this.screensaverItems = result;
        });
    }
}
