import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageOptions } from '@app/shared/models/image-options.model';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { MeasurementReviewDialogComponent } from '@app/measurement/measurement-review-dialog.component';
import { HistoricalMeasurement } from '@app/shared/models/historical-measurement.model';
import { Client, ListOption, RefractionMeasurement } from '@app/shared/models';
import { DeleteTopographicMeasurementDialogComponent } from '@app/dialogs/delete-topographic-measurement-dialog/delete-topographic-measurement-dialog.component';
import { AppStateService } from '@app/shared/appservices/appState.service';
import IMeasurementHistoryComponent from '@app/measurement/measurement-history.interface';
import { VisualAcuityService } from '@app/core/services/api/visual-acuity.service';

@Component({
    selector: 'measurement-history-item',
    templateUrl: 'measurement-history-item.component.html',
    styleUrls: ['measurement-history-item.component.scss'],
})
export class MeasurementHistoryItemComponent implements OnInit {
    @Input() measurementHistory: IMeasurementHistoryComponent;
    @Input() historicalMeasurement: HistoricalMeasurement;
    @Input() imageOptions: ImageOptions;
    @Input() selectedRefractionMeasurement: RefractionMeasurement | undefined;
    @Input() inDeleteMode = false;
    @Input() displayBaselineTag = false;
    @Input() index: number;
    @Input() eyeSide: string;
    @Input() client: Client;

    @Output() measurementSelected: EventEmitter<RefractionMeasurement> = new EventEmitter();

    public visualAcuityCorrectoreValues: ListOption[];
    public isCollapsed = true;
    public hasRefractionData: boolean | undefined;
    public hasTopo: boolean | undefined;
    public translateTextForSource: string | undefined;
    public measurementType: string | undefined;

    private readonly VISUAL_ACUITY_TYPE_DECIMAL = 1;

    constructor(
        private readonly modalService: BsModalService,
        private readonly visualAcuityService: VisualAcuityService,
        private readonly appState: AppStateService,
    ) {}

    ngOnInit() {
        this.hasRefractionData = !!this.historicalMeasurement.refractionMeasurement;
        this.hasTopo = !!this.historicalMeasurement.topographicMeasurement;
        this.translateTextForSource = this.historicalMeasurement.refractionMeasurement?.CopiedTopographicMeasurement
            ? 'general.source.copied'
            : 'general.source.original';
        this.measurementType =
            this.hasRefractionData && this.historicalMeasurement.refractionMeasurement.IsOverrefraction
                ? 'measurement.type.followup'
                : 'measurement.type.normal';

        this.getVisualAquities();
        if (this.hasRefractionData) {
            if (
                this.historicalMeasurement.refractionMeasurement.VisualAcuitySineCorrectoreValueId &&
                this.visualAcuityCorrectoreValues
            ) {
                const value = this.visualAcuityCorrectoreValues.find(
                    (x) => x.Id === this.historicalMeasurement.refractionMeasurement.VisualAcuitySineCorrectoreValueId,
                );
                this.historicalMeasurement.refractionMeasurement.VisualAcuitySineCorrectoreValue = value.Name;
            }
        }
    }

    openReviewMeasurementDialog(): void {
        const imageOptions = structuredClone(this.imageOptions);
        imageOptions.ShowImageChoice = true;
        imageOptions.ShowImageTypes = false;

        const options: ModalOptions<MeasurementReviewDialogComponent> = {
            initialState: {
                currentMeasurement: this.historicalMeasurement.topographicMeasurement,
                refractionMeasurement: this.historicalMeasurement.refractionMeasurement,
                topoMeasurements: [],
                imageOptions: imageOptions,
                client: this.client,
            },
            class: 'measurement-review-dialog',
        };
        this.modalService.show(MeasurementReviewDialogComponent, options);
    }

    getVisualAquities(): void {
        const visualAcuityTypeId = this.appState.currentCompany?.VisualAcuityTypeId ?? this.VISUAL_ACUITY_TYPE_DECIMAL;
        this.visualAcuityService.getVisualAcuityValues(visualAcuityTypeId).subscribe((result) => {
            this.visualAcuityCorrectoreValues = result;
        });
    }

    deleteMeasurement(topographicMeasurementId: number | undefined, refractionMeasurementId: number | undefined): void {
        const options: ModalOptions<DeleteTopographicMeasurementDialogComponent> = {
            initialState: {
                measurementHistory: this.measurementHistory,
                topoMeasurementId: topographicMeasurementId,
                refracMeasurementId: refractionMeasurementId,
            },
            class: 'delete-topographic-measurement-dialog-component',
        };

        this.modalService.show(DeleteTopographicMeasurementDialogComponent, options);
    }

    markAsSelected(refractionMeasurement: RefractionMeasurement): void {
        this.measurementSelected.emit(refractionMeasurement);
    }
}
