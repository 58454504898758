export class EyeliteLens {
    constructor(eyeliteLens: EyeliteLens) {
        this.Id = eyeliteLens.Id;
        this.LenstypeHistory = eyeliteLens.LenstypeHistory;
        this.ArticleName = eyeliteLens.ArticleName;
        this.MaterialCode = eyeliteLens.MaterialCode;
        this.Color = eyeliteLens.Color;
        this.OrderReference = eyeliteLens.OrderReference;
        this.Status = eyeliteLens.Status;
        this.RadFlat = eyeliteLens.RadFlat;
        this.RadSteep = eyeliteLens.RadSteep;
        this.EccFlat = eyeliteLens.EccFlat;
        this.EccSteep = eyeliteLens.EccSteep;
        this.Diam = eyeliteLens.Diam;
        this.Oz = eyeliteLens.Oz;
        this.Sphere = eyeliteLens.Sphere;
        this.Cylinder = eyeliteLens.Cylinder;
        this.Axis = eyeliteLens.Axis;
        this.Addition = eyeliteLens.Addition;
        this.Prisma = eyeliteLens.Prisma;
        this.Stab = eyeliteLens.Stab;
        this.Trunc = eyeliteLens.Trunc;
        this.Date = eyeliteLens.Date;
        this.Engraving = eyeliteLens.Engraving;
        this.Quantity = eyeliteLens.Quantity;
        this.RadFlatMeasurement = eyeliteLens.RadFlatMeasurement;
        this.RadSteepMeasurement = eyeliteLens.RadSteepMeasurement;
        this.SphereMeasurement = eyeliteLens.SphereMeasurement;
        this.CylinderMeasurement = eyeliteLens.CylinderMeasurement;
        this.AxisMeasurement = eyeliteLens.AxisMeasurement;
        this.StabMeasurement = eyeliteLens.StabMeasurement;
        this.nmeet = eyeliteLens.nmeet;
        this.RadFlatOver = eyeliteLens.RadFlatOver;
        this.RadFleetOver = eyeliteLens.RadFleetOver;
        this.SphereOver = eyeliteLens.SphereOver;
        this.CylOver = eyeliteLens.CylOver;
        this.AxisOver = eyeliteLens.AxisOver;
        this.HaOver = eyeliteLens.HaOver;
        this.NOver = eyeliteLens.NOver;
        this.Tilt = eyeliteLens.Tilt;
        this.TiltAxis = eyeliteLens.TiltAxis;
        this.Rotation = eyeliteLens.Rotation;
        this.Profile = eyeliteLens.Profile;
        this.Trunc2 = eyeliteLens.Trunc2;

        this.IsSelected = false;
        this.EyeSideId = eyeliteLens.EyeSideId;
    }

    public Id: number;
    public LenstypeHistory: number;
    public ArticleName: string;
    public MaterialCode: string;
    public Color: string;
    public OrderReference: string;
    public Status: number;
    public RadFlat: number;
    public RadSteep: number;
    public EccFlat: number;
    public EccSteep: number;
    public Diam: number;
    public Oz: number;
    public Sphere: number;
    public Cylinder: number;
    public Axis: number;
    public Addition: number;
    public Prisma: number;
    public Stab: number;
    public Trunc: number;
    public Date: Date;
    public Engraving: string;
    public Quantity: number;
    public RadFlatMeasurement: number;
    public RadSteepMeasurement: number;
    public SphereMeasurement: number;
    public CylinderMeasurement: number;
    public AxisMeasurement: number;
    public StabMeasurement: number;
    public nmeet: number;
    public RadFlatOver: number;
    public RadFleetOver: number;
    public SphereOver: number;
    public CylOver: number;
    public AxisOver: number;
    public HaOver: number;
    public NOver: number;
    public Tilt: number;
    public TiltAxis: number;
    public Rotation: number;
    public Profile: number;
    public Trunc2: number;
    public IsSelected: boolean;
    public EyeSideId: number;
}
