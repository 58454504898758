import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BackendValidationError } from '../models/backendValidationError';

@Injectable()
export class UtilService {
    constructor(private readonly translate: TranslateService) {}

    public getServerErrors(response: HttpErrorResponse, withKey = true): string[] {
        const result: string[] = [];

        // HttpErrorResponse is typed, but something else might be sneaked inside the argument
        if (this.matchesBackendValidationError(response?.error)) {
            const modelState = (response.error as BackendValidationError).ModelState;

            if (modelState) {
                for (const key in modelState) {
                    if (modelState[key].$values) {
                        for (let i = 0; i < modelState[key].$values.length; i++) {
                            const errorKey = modelState[key].$values[i];
                            const errorMessage = this.translate.instant(errorKey);
                            if (key && withKey) {
                                result.push(key + ': ' + errorMessage);
                            } else {
                                result.push(errorMessage);
                            }
                        }
                    }
                }
            }
        }

        return result;
    }

    private matchesBackendValidationError(error: object): boolean {
        for (const key in BackendValidationError) {
            if (!error.hasOwnProperty(key)) {
                return false;
            }
        }

        return true;
    }
}
