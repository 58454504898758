import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EyeSides, Features, MeasurementImageModes } from '@app/shared/enums';
import { TopographicMeasurement } from '@app/shared/models/topographicMeasurement.model';
import { ImageOptions } from '@app/shared/models/image-options.model';
import { MeasurementImageComponent } from './measurement-image.component';
import { Client, ListOption, RefractionMeasurement } from '@app/shared/models';
import { GetTopographicMeasurementsByClientIdRequest } from '@app/shared/requestmodels';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from '@app/shared/appservices/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { PowerProfilePosition } from '@app/shared/models/topoImageOptions.model';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { MeasurementOptionsComponent } from '@app/measurement/measurement-options.component';
import { RefractionFieldsConfigurationService } from '@app/core/services/refractionFieldsConfiguration.service';
import { TopographicMeasurementService } from '@app/core/services/api/topographic-measurement.service';
import { UserSettingService } from '@app/core/services/api/user-setting.service';
import { VisualAcuityService } from '@app/core/services/api/visual-acuity.service';
import { RefractionMeasurementService } from '@app/core/services/api/refraction-measurement.service';
import { InputNumberRange } from '@app/shared/models/input-number-range.model';
import { InputTimeComponent } from '@app/shared/components/inputs';
import { RefractionFieldsConfiguration } from '@app/shared/helpers/refraction-fields-configuration';
import { lastValueFrom } from 'rxjs';

@Component({
    templateUrl: './measurement-review-dialog.component.html',
    styleUrls: ['measurement-review-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MeasurementReviewDialogComponent implements OnInit, AfterViewChecked {
    id = 'refraction';

    editable = false;
    formGroup: UntypedFormGroup;
    notEditableFormGroup: UntypedFormGroup;
    tabOpened = 'topography';
    loading = true;
    displayBaselineTag = true;

    ranges: RefractionFieldsConfiguration;

    @Input() currentRefractionMeasurement: RefractionMeasurement;
    @Input() currentMeasurement: TopographicMeasurement;
    @Input() refractionMeasurement: RefractionMeasurement;
    @Input() imageOptions: ImageOptions;
    @Input() topoMeasurements: TopographicMeasurement[];
    @Input() client: Client;

    powerprofileImageOptions: ImageOptions;

    @ViewChild(MeasurementOptionsComponent)
    measurementOptionsComponent: MeasurementOptionsComponent;
    @ViewChild('measurementImageComponent')
    measurementImageComponent: MeasurementImageComponent;
    @ViewChild('powerprofileImageComponent')
    powerprofileImageComponent: MeasurementImageComponent;
    @ViewChild('timeComponent')
    timeComponent: InputTimeComponent;

    visualAcuityCorrectoreValues: ListOption[];

    rotationRanges: InputNumberRange;
    pupilDiamRanges: InputNumberRange;

    private orgRefractionMeasurement: RefractionMeasurement;
    private baselineTopoId: number;

    showPowerProfile: boolean;
    hasMyopie: boolean;

    constructor(
        public bsModalRef: BsModalRef,
        private readonly topographicMeasurementService: TopographicMeasurementService,
        private readonly appState: AppStateService,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        private readonly fb: UntypedFormBuilder,
        private readonly userSettingService: UserSettingService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly refractionConfigurationService: RefractionFieldsConfigurationService,
        private readonly visualAcuityService: VisualAcuityService,
        private readonly refractionMeasurementService: RefractionMeasurementService,
    ) {
        this.ranges = this.refractionConfigurationService.configuration;
    }

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    async ngOnInit() {
        this.createForm();
        this.fillRanges();
        await this.getVisualAquities();
        await this.setImageOptions();

        if (this.refractionMeasurement == null) {
            await this.loadRefractionData();
        } else {
            this.orgRefractionMeasurement = this.refractionMeasurement;
        }

        this.patchMeasurement();

        this.setPowerprofileImageOptions();

        await this.loadTopoMeasurements();

        this.showPowerProfile = this.showPowerprofileTab();
        this.hasMyopie = this.client.Myopie;

        this.loading = false;
    }

    patchMeasurement(): void {
        if (this.formGroup && this.refractionMeasurement) {
            this.formGroup.patchValue({
                sphere: this.refractionMeasurement.Sphere,
                cylinder: this.refractionMeasurement.Cylinder,
                axis: this.refractionMeasurement.Axis,
                visualAcuitySineCorrectore: this.refractionMeasurement.VisualAcuitySineCorrectoreValueId,
                visualAcuityCumCorrectore: this.refractionMeasurement.VisualAcuityCumCorrectoreValueId,
                vertex: this.refractionMeasurement.Vertex,
                corneaDiameter: this.refractionMeasurement.CorneaDiameter,
                pupilDiameter: this.refractionMeasurement.PupilDiameter,
                axisLength: this.refractionMeasurement.AxisLength,
                cyclo: this.refractionMeasurement.Cyclo,
                pupil: this.refractionMeasurement?.PupilDiameter ?? 2,
                nearadd: this.refractionMeasurement?.NearAdd ?? 0,
                rotation: 0,
                performed: this.refractionMeasurement.Performed,
            });

            if (this.refractionMeasurement.RefractionOverLens !== null) {
                this.formGroup.patchValue({ refractionOverLens: this.refractionMeasurement.RefractionOverLens });
            }
        }

        if (this.refractionMeasurement.VisualAcuityCumCorrectoreValueId && this.visualAcuityCorrectoreValues) {
            const value = this.visualAcuityCorrectoreValues.find(
                (x) => x.Id === this.refractionMeasurement.VisualAcuityCumCorrectoreValueId,
            );
            this.refractionMeasurement.VisualAcuityCumCorrectoreValue = value.Name;
        }

        if (this.refractionMeasurement.VisualAcuitySineCorrectoreValueId && this.visualAcuityCorrectoreValues) {
            const value = this.visualAcuityCorrectoreValues.find(
                (x) => x.Id === this.refractionMeasurement.VisualAcuitySineCorrectoreValueId,
            );
            this.refractionMeasurement.VisualAcuitySineCorrectoreValue = value.Name;
        }
    }

    createForm(): void {
        const visualAcuityCumCorrectoreValidators = [];
        if (this.visualAcutityRequired) {
            visualAcuityCumCorrectoreValidators.push(Validators.required);
        }

        this.formGroup = this.fb.group({
            sphere: [, Validators.required],
            cylinder: [],
            axis: [],
            visualAcuitySineCorrectore: [],
            visualAcuityCumCorrectore: [, visualAcuityCumCorrectoreValidators],
            vertex: [],
            corneaDiameter: [],
            pupilDiameter: [],
            axisLength: [],
            cyclo: [],
            rotation: [],
            pupil: [],
            nearadd: [],
            performed: [],
            refractionOverLens: [],
        });
    }

    fillRanges(): void {
        this.rotationRanges = new InputNumberRange(0, 180, 5);
        this.pupilDiamRanges = new InputNumberRange(2, 9, 0.01);
    }

    async setImageOptions() {
        const us = await lastValueFrom(this.userSettingService.getUserSettings());

        this.imageOptions.topoImageOptions.UseNormalize = us.UseNormalize === 1;
        this.imageOptions.topoImageOptions.UseMapType = us.UseMapType === 1;
        this.imageOptions.topoImageOptions.UseMm = us.UseMm === 0;
        this.imageOptions.leftImageMode = MeasurementImageModes.Topo;
        this.imageOptions.rightImageMode = this.imageOptions.leftImageMode;
    }

    async getVisualAquities(): Promise<void> {
        const visualAcuityTypeId = this.appState.currentCompany?.VisualAcuityTypeId ?? 1;
        this.visualAcuityCorrectoreValues = await lastValueFrom(
            this.visualAcuityService.getVisualAcuityValues(visualAcuityTypeId),
        );
    }

    setPowerprofileImageOptions() {
        this.powerprofileImageOptions = structuredClone(this.imageOptions);
        this.powerprofileImageOptions.ShowImageChoice = false;
        this.powerprofileImageOptions.ShowImageTypes = false;
        this.powerprofileImageOptions.ImageChoice = this.powerprofileImageOptions.topoImageOptions.ImageChoice = 'diff';
        this.powerprofileImageOptions.topoImageOptions.UseMm = false;
        this.powerprofileImageOptions.topoImageOptions.ShowPowerProfile = !this.currentMeasurement.IsBaseLine;
        this.powerprofileImageOptions.topoImageOptions.PowerProfilePosition = PowerProfilePosition.Bottom;
    }

    onEnableEdit(bool: boolean): void {
        this.editable = bool;
        this.patchMeasurement();
    }

    async loadRefractionData(): Promise<void> {
        const ids = [this.currentMeasurement.Id];
        const refrResults = await lastValueFrom(
            this.refractionMeasurementService.getRefractionMeasurementsByTopographicMeasurementIds(ids),
        );
        this.refractionMeasurement = refrResults[0];

        if (!this.refractionMeasurement) {
            this.refractionMeasurement = new RefractionMeasurement();
        }
        this.orgRefractionMeasurement = this.refractionMeasurement;

        this.patchMeasurement();
    }

    isValidForm(): boolean {
        return this.timeComponent?.isValid();
    }

    onRefractionSave(): void {
        const result = this.readForm();
        this.refractionMeasurementService.updateRefractionMeasurement(result).subscribe(
            (response) => {
                this.refractionMeasurement = response;
                this.alertService.success(this.translateService.instant('general.saveSuccessful'));

                this.patchMeasurement();
                this.editable = false;
            },
            () => {
                this.alertService.success(this.translateService.instant('error.saveError'));
            },
        );
    }

    async onOptionChange(opts: ImageOptions): Promise<void> {
        if (opts.topoImageOptions.ImageChoice === 'baseline') {
            const baselineTopo = await lastValueFrom(
                this.refractionMeasurementService.getTopographicMeasurementById(this.baselineTopoId),
            );
            this.refractionMeasurement = baselineTopo.RefractionMeasurementModel;
        } else {
            this.refractionMeasurement = this.orgRefractionMeasurement;
        }
        this.measurementImageComponent.refresh(null);
    }

    onTab(tab: string): void {
        this.editable = false;
        this.tabOpened = tab;
    }

    onApply(): void {
        if (this.powerprofileImageComponent) {
            this.setPowerprofileImageOptions();
            this.powerprofileImageOptions.topoImageOptions.PowerProfileAxis = this.formGroup.value['rotation'];
            this.powerprofileImageOptions.topoImageOptions.PupilDiam = this.formGroup.value['pupil'];
            this.powerprofileImageComponent.imageOptions = this.powerprofileImageOptions;
            this.powerprofileImageComponent.refresh(null);
        }
    }

    readForm(): RefractionMeasurement {
        let result: RefractionMeasurement;

        if (this.refractionMeasurement) {
            result = structuredClone(this.refractionMeasurement);
        } else {
            result = new RefractionMeasurement();
        }

        result.Axis = this.formGroup.controls['axis'].value;
        result.AxisLength = this.formGroup.controls['axisLength'].value;
        result.VisualAcuityCumCorrectoreValueId = this.formGroup.controls['visualAcuityCumCorrectore'].value;
        result.VisualAcuitySineCorrectoreValueId = this.formGroup.controls['visualAcuitySineCorrectore'].value;
        result.CorneaDiameter = this.formGroup.controls['corneaDiameter'].value;
        result.Cyclo = this.formGroup.controls['cyclo'].value;
        result.Cylinder = this.formGroup.controls['cylinder'].value;
        result.PupilDiameter = this.formGroup.controls['pupilDiameter'].value;
        result.Sphere = this.formGroup.controls['sphere'].value;
        result.Vertex = this.formGroup.controls['vertex'].value;
        result.NearAdd = this.formGroup.controls['nearadd'].value;

        const date = new Date();
        date.setHours(Number(this.timeComponent.hours));
        date.setMinutes(Number(this.timeComponent.minutes));
        result.Performed = date;

        return result;
    }

    tabSelected(tab: string): string {
        return tab === this.tabOpened ? 'tabselected title-border-bottom' : '';
    }

    get visualAcutityRequired(): boolean {
        return this.appState.isCompanyFeatureEnabled(Features.RequireVisualAcuitity);
    }

    get currentTopoMeasurementIndex(): number {
        return this.topoMeasurements.findIndex((x) => x.Id === this.currentMeasurement.Id);
    }

    get hasPrevious(): boolean {
        return this.currentTopoMeasurementIndex < this.topoMeasurements.length - 1;
    }

    get hasNext(): boolean {
        return this.currentTopoMeasurementIndex > 0;
    }

    showPowerprofileTab(): boolean {
        return (
            this.topoMeasurements.length > 1 &&
            this.baselineTopoId &&
            this.baselineTopoId !== this.currentMeasurement.Id &&
            this.appState.isPowerProfileEnabled
        );
    }

    previous() {
        this.gotoTopoMeasurement(this.currentTopoMeasurementIndex + 1).then();
    }

    next() {
        this.gotoTopoMeasurement(this.currentTopoMeasurementIndex - 1).then();
    }

    private async gotoTopoMeasurement(index: number) {
        // if user was watching the baseline data instead of the current data,
        // reset to current data when navigating between images.
        if (this.imageOptions.topoImageOptions.ImageChoice === 'baseline') {
            this.imageOptions.topoImageOptions.ImageChoice = 'current';
            this.refractionMeasurement = this.orgRefractionMeasurement;
        }

        this.loading = true;
        this.editable = false;
        this.currentMeasurement = this.topoMeasurements[index];

        await this.loadRefractionData();

        this.showPowerProfile = this.showPowerprofileTab();
        this.refresh();
        this.loading = false;
    }

    refresh() {
        if (this.measurementImageComponent) {
            this.measurementImageComponent.refresh(null);
        }

        if (this.powerprofileImageComponent) {
            this.powerprofileImageComponent.refresh(null);
        }
    }

    previousMeasurement(): TopographicMeasurement {
        return this.topoMeasurements[this.currentTopoMeasurementIndex + 1];
    }

    nextMeasurement(): TopographicMeasurement {
        return this.topoMeasurements[this.currentTopoMeasurementIndex - 1];
    }

    async loadTopoMeasurements(): Promise<void> {
        if (!this.topoMeasurements || this.topoMeasurements.length === 0) {
            const request = new GetTopographicMeasurementsByClientIdRequest();
            request.clientId = this.client.Id;
            request.pageSize = 50;
            request.pageIndex = 0;
            request.loadLeftTopographicMeasurements = this.currentMeasurement.EyeSideId === EyeSides.Os;
            request.loadRightTopographicMeasurements = this.currentMeasurement.EyeSideId === EyeSides.Od;

            const topoResults = await lastValueFrom(
                this.topographicMeasurementService.getTopographicMeasurementsByClientId(request),
            );

            this.topoMeasurements =
                this.currentMeasurement.EyeSideId === EyeSides.Os
                    ? topoResults.LeftTopographicMeasurements
                    : topoResults.RightTopographicMeasurements;
        }

        const baselineTopo = this.topoMeasurements.find((x) => x.IsBaseLine);

        if (baselineTopo) {
            this.baselineTopoId = baselineTopo.Id;
        }

        this.displayBaselineTag = this.currentMeasurement?.IsBaseLine && this.refractionMeasurement?.IsBaseLine;
    }
}
