import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReOrder } from '@app/professional-service/models/ReOrder.model';
import { ListOption } from '@app/shared/models/listOption.model';
import { OrderService } from '@app/core/services/api/order.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { DistributorService } from '@app/core/services/api/distributor.service';
import { Util } from '@app/shared/helpers/utility.helper';
import { Features, OrderPriorities } from '@app/shared/enums';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'reorder-dialog',
    templateUrl: './reorder-dialog.component.html',
})
export class ReOrderDialogComponent implements OnInit {
    @Input() reOrder: ReOrder;

    formGroup: UntypedFormGroup;
    priorities: ListOption[];
    reOrderId: number;
    isOrdered: boolean;
    isBusy: boolean;
    isLoading = true;
    perosRequired: boolean;

    constructor(
        private readonly bsModalRef: BsModalRef,
        private readonly orderService: OrderService,
        private readonly router: Router,
        private readonly fb: UntypedFormBuilder,
        private readonly distributorService: DistributorService,
    ) {}

    async ngOnInit() {
        this.priorities = await lastValueFrom(this.orderService.getOrderPriorities());
        this.perosRequired = await this.checkPerosRequired();
        this.createForm();
        this.isLoading = false;
    }

    createForm(): void {
        if (this.perosRequired) {
            this.formGroup = this.fb.group({
                newNote: [],
                perosNumber: [
                    '',
                    [
                        Validators.required,
                        Validators.nullValidator,
                        Validators.minLength(11),
                        Validators.maxLength(11),
                        Validators.pattern('^(?=.*\\d)[\\d]{1,}$'),
                    ],
                ],
                poNumber: [],
                selectedPriority: [OrderPriorities.Normal],
            });
        } else {
            this.formGroup = this.fb.group({
                newNote: [],
                selectedPriority: [OrderPriorities.Normal],
            });
        }
    }

    reorder() {
        if (this.isBusy) {
            return;
        }

        this.isBusy = true;
        this.reOrder.Comments = this.formGroup.controls['newNote'].value;
        this.reOrder.SkipOrderEvaluation = true;
        this.reOrder.PriorityId = this.formGroup.controls['selectedPriority'].value;

        if (this.perosRequired) {
            this.reOrder.PerosOrderNumber = this.formGroup.controls['perosNumber'].value;
            this.reOrder.PoNumber = this.formGroup.controls['poNumber'].value;
        }

        this.orderService.reOrder(this.reOrder).subscribe((result) => {
            this.reOrderId = result.Id;
            this.isOrdered = true;
            this.isBusy = false;
        });
    }

    ok() {
        if (this.isBusy) {
            return;
        }

        this.bsModalRef.hide();
        this.router.navigate(['/ps/supportorderoverview']).then();
    }

    cancel() {
        this.bsModalRef.hide();
    }

    downloadOrderAsPdf() {
        this.isBusy = true;
        this.orderService.downloadPdf(this.reOrderId).subscribe((file) => {
            Util.openBlobInBrowser(file, file.name);
        });
        this.isBusy = false;
    }

    async checkPerosRequired(): Promise<boolean> {
        const distributor = await lastValueFrom(
            this.distributorService.getById(this.reOrder.Order.Optician.DistributorId),
        );

        const featureEnabled = distributor.Features.find((f) => f.Id === Features.Peros)?.IsEnabled ?? false;
        return featureEnabled && this.reOrder.Order.Optician.PerosBranchNumber != '';
    }
}
