import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['main.component.scss'],
})
export class MainComponent implements OnInit {
    public isHome: boolean;
    private readonly destroyRef = inject(DestroyRef);

    constructor(private readonly router: Router) {}

    public ngOnInit(): void {
        this.isHome = this.router.url === '/';
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe((event: NavigationEnd) => {
                this.isHome = event['url'] === '/';
            });
    }
}
