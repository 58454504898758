import { Injectable } from '@angular/core';
import { CartItem, Client, Proposal } from '@app/shared/models';
import { Router } from '@angular/router';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { SessionService } from '@app/shared/appservices/session.service';
import { LensTypes, FittingSteps, Features, Treatments } from '@app/shared/enums';
import { SaveFittingContinuationConfiguration } from '@app/fitting/saveFittingContinuationConfiguration';
import { FittingStateService } from './fitting.state.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FittingService {
    private readonly _state: FittingStateService;
    private maxStep = FittingSteps.ReceiptCreated;

    private continuationSaveConfig: SaveFittingContinuationConfiguration;

    constructor(
        private router: Router,
        public sessionService: SessionService,
        public appState: AppStateService,
        public translateService: TranslateService,
    ) {
        this._state = new FittingStateService(this.sessionService);
    }

    private get proposal(): Proposal {
        return this._state.proposal;
    }

    get state(): FittingStateService {
        return this._state;
    }

    get isFollowUp(): boolean {
        return this.proposal && this.proposal.ProposalType && this.proposal.ProposalType.Code === 'followup';
    }

    get isDreamLiteFollowUp(): boolean {
        return this.proposal && this.proposal.ProposalType && this.proposal.ProposalType.Code === 'dreamlitefollowup';
    }

    get hasTopoSelected(): boolean {
        // needs ternary construction because types are equal
        return !!(this.proposal.LeftTopographicMeasurement || this.proposal.RightTopographicMeasurement);
    }

    get receiptsOnly(): boolean {
        return this.appState.isOpticianFeatureEnabled(Features.IsReceiptsOnlyEnabled);
    }

    getClientId(): number {
        return this.state.client ? this.state.client.Id : null;
    }

    getLensTypeId(): number {
        return this.proposal.LensTypeId;
    }

    setClient(client: Client): void {
        this.state.client = client;
    }

    isGlassFitting(): boolean {
        return this.state.isGlassFitting === true;
    }

    setIsGlassFitting(isGlassFitting: boolean): void {
        this.state.isGlassFitting = isGlassFitting;
    }

    setIsCartItem(isCartItem: boolean): void {
        this.state.isCartItem = isCartItem;
    }

    isCartItem(): boolean {
        return this.state.isCartItem === true;
    }

    selectEyesSidesForRefractionStep(hasRightMeasurement: boolean, hasLeftMeasurement: boolean) {
        this._state.isRightEyeSideSelected = hasRightMeasurement;
        this._state.isLeftEyeSideSelected = hasLeftMeasurement;
    }

    // returns if the current page in the fitting process is a page
    // that has data that can be saved (and that may be dirty)
    isInSaveableView(): boolean {
        return !!this.continuationSaveConfig;
    }

    getOpticianId(): number {
        return this.proposal?.OpticianId;
    }

    canSelectLeftEye(step: FittingSteps): boolean {
        switch (step) {
            case FittingSteps.Measurement:
                if (this.isFollowUp || this.isDreamLiteFollowUp) {
                    return !!this.proposal.LeftPreviousOrderId;
                }
                return true;
            case FittingSteps.Refraction:
                if (this.isFollowUp) {
                    return !!this.proposal.LeftPreviousOrderId;
                }
                return !!this.proposal.LeftTopographicMeasurementId;

            case FittingSteps.FitLens:
                return !!this.proposal.LeftRefractionMeasurementId;
        }

        return true;
    }

    canSelectRightEye(step: FittingSteps): boolean {
        switch (step) {
            case FittingSteps.Measurement:
                if (this.isFollowUp || this.isDreamLiteFollowUp) {
                    return !!this.proposal.RightPreviousOrderId;
                }
                return true;
            case FittingSteps.Refraction:
                if (this.isFollowUp) {
                    return !!this.proposal.RightPreviousOrderId;
                }
                return !!this.proposal.RightTopographicMeasurementId;

            case FittingSteps.FitLens:
                return !!this.proposal.RightRefractionMeasurementId;
        }

        return true;
    }

    hasRefractionLeftMeasurement(): boolean {
        return this.proposal && !!this.proposal.LeftRefractionMeasurement;
    }
    hasRefractionRightMeasurement(): boolean {
        return this.proposal && !!this.proposal.RightRefractionMeasurement;
    }

    canSaveForThisStep(step: FittingSteps): boolean {
        return !!this.continuationSaveConfig && this._state.step === step;
    }

    isSaveEnabled(): boolean {
        return this.continuationSaveConfig.isSaveEnabled();
    }

    async saveUnsavedChanges() {
        // save te current step as the proposal step, so in case of continuation
        // the process returns to this step
        this.proposal.Step = this._state.step;
        await this.continuationSaveConfig.save();
    }

    async saveAndReset() {
        await this.saveUnsavedChanges();
        const returnToCart = this.isCartItem();
        this.clear();

        if (returnToCart) {
            this.router.navigate(['/cart']).then();
        } else {
            this.router.navigate(['/searchclient']).then();
        }
    }

    setProposalStepOnNextClick() {
        // save the next state in the proposal just before "nexting"
        // so in case of continuation, the process resumes at the next step.
        this.proposal.Step = this.getNextStep();
    }

    configureSaveForContinuation(config: SaveFittingContinuationConfiguration) {
        this.continuationSaveConfig = config;
    }

    clearSaveForContinationConfiguration() {
        this.continuationSaveConfig = null;
    }

    storeReceiptIdAndClearSaveConfiguration(receiptId: number) {
        this.continuationSaveConfig = null;
        this.state.receiptId = receiptId;
    }

    setFittingStep(url: string): void {
        // determine current step based on route
        if (url.startsWith('/measurement')) {
            this._state.step = FittingSteps.Measurement;
        } else if (url.startsWith('/refraction')) {
            this._state.step = FittingSteps.Refraction;
        } else if (url.startsWith('/lenstype')) {
            this._state.step = FittingSteps.LensType;
        } else if (url.startsWith('/fitlens') || url.startsWith('/fitglass')) {
            this._state.step = FittingSteps.FitLens;
        } else if (url.startsWith('/overview') || url.startsWith('/glassoverview')) {
            this._state.step = FittingSteps.Overview;
        } else {
            this._state.step = FittingSteps.Client;
        }
    }

    getFittingStep(): number {
        return this._state.step;
    }

    public gotoPreviousStep() {
        this.state.previousStep = this.state.step;

        switch (this._state.step) {
            case FittingSteps.Client:
                this.gotoStep(FittingSteps.Client);
                break;
            case FittingSteps.Measurement:
                this.gotoStep(FittingSteps.Client);
                break;
            case FittingSteps.Refraction:
                this.gotoStep(FittingSteps.Measurement);
                break;
            case FittingSteps.LensType:
                this.gotoStep(FittingSteps.Refraction);
                break;
            case FittingSteps.FitLens:
                if (this.isGlassFitting()) {
                    this.gotoStep(FittingSteps.Client);
                } else {
                    if (this.isDreamLiteFollowUp || this.isFollowUp) {
                        this.gotoStep(FittingSteps.Refraction);
                    } else {
                        this.gotoStep(FittingSteps.LensType);
                    }
                }
                break;
            case FittingSteps.Overview:
                if (this.state.isGlassFitting === true) {
                    this.gotoStep(FittingSteps.FitLens);
                } else {
                    if (this._state.isReorder) {
                        this.gotoStep(FittingSteps.Client);
                    } else if (this.isDreamLiteFollowUp) {
                        this.gotoStep(FittingSteps.FitLens);
                    } else if (this.appState.isExpertModeEnabled) {
                        this.gotoStep(FittingSteps.FitLens);
                    } else {
                        this.gotoStep(FittingSteps.LensType);
                    }
                }
                break;
        }
    }

    public gotoNextStep() {
        const nextStep = this.getNextStep();
        this.state.previousStep = this.state.step;
        this.gotoStep(nextStep);
    }

    private getNextStep(): FittingSteps {
        switch (this._state.step) {
            case FittingSteps.Client:
                if (this.state.isGlassFitting === true) {
                    return FittingSteps.FitLens;
                } else {
                    return FittingSteps.Measurement;
                }
            case FittingSteps.Measurement:
                return FittingSteps.Refraction;
            case FittingSteps.Refraction:
                if (this.isDreamLiteFollowUp || this.isFollowUp) {
                    return FittingSteps.FitLens;
                }
                return FittingSteps.LensType;
            case FittingSteps.LensType:
                if (this.proposal.LensTypeId === LensTypes.DreamLite && !this.appState.isExpertModeEnabled) {
                    return FittingSteps.Overview;
                }
                return FittingSteps.FitLens;
            case FittingSteps.FitLens:
                return FittingSteps.Overview;
            case FittingSteps.Overview:
                if (this.receiptsOnly) {
                    return FittingSteps.ReceiptCreated;
                }
                return FittingSteps.OrderSent;
            default:
                return this._state.step + (this._state.step < this.maxStep ? 1 : 0);
        }
    }

    public gotoStep(step: FittingSteps) {
        if (step < FittingSteps.Client) {
            step = FittingSteps.Measurement;
        }

        if (step > this.maxStep) {
            throw Error(`Cannot navigate to step ${step}`);
        }

        this._state.step = step;
        this.continuationSaveConfig = null; // must be set on each step again

        switch (step) {
            case FittingSteps.Client:
                if (this._state.client) {
                    this.router.navigate(['/client', this._state.client.Id]).then();
                } else {
                    this.router.navigate(['/searchclient']).then();
                }
                break;
            case FittingSteps.Measurement:
                this.router.navigate(['/measurement']).then();
                break;
            case FittingSteps.Refraction:
                this.router.navigate(['/refraction']).then();
                break;
            case FittingSteps.LensType:
                this.router.navigate(['/lenstype']).then();
                break;
            case FittingSteps.FitLens:
                if (this.state.isGlassFitting === true) {
                    this.router.navigate(['/fitglass']).then();
                } else {
                    this.router.navigate(['/fitlens']).then();
                }
                break;
            case FittingSteps.Overview:
                if (this.state.isGlassFitting === true) {
                    this.router.navigate(['/glassoverview']);
                } else {
                    this.router.navigate(['/overview']);
                }
                break;
            case FittingSteps.OrderSent:
                if (this.state.isGlassFitting === true) {
                    this.router.navigate(['/glassordersent', this._state.orderId]);
                } else {
                    this.router.navigate(['/lensordersent', this._state.orderId]);
                }
                break;
            case FittingSteps.ReceiptCreated:
                this.router.navigate(['/receipt/created', this._state.receiptId]);
        }
    }

    takeOverNewLensProposal(recalcLensesResult: Proposal) {
        this.proposal.LeftOpticianFittedLens = recalcLensesResult.LeftOpticianFittedLens;
        this.proposal.LeftOpticianFittedLensId = recalcLensesResult.LeftOpticianFittedLensId;
        this.proposal.RightOpticianFittedLens = recalcLensesResult.RightOpticianFittedLens;
        this.proposal.RightOpticianFittedLensId = recalcLensesResult.RightOpticianFittedLensId;
    }

    public setNewFit() {
        this._state.isLeftEyeSideSelected = true;
        this._state.isRightEyeSideSelected = true;
    }

    public setFollowUp(leftOrderSelected: boolean, rightOrderSelected: boolean) {
        this._state.isLeftEyeSideSelected = leftOrderSelected;
        this._state.isRightEyeSideSelected = rightOrderSelected;
    }

    public setReOrder(
        leftOrderSelected: boolean,
        rightOrderSelected: boolean,
        isSpareOrder: boolean,
        isAnnualSupplyFullfillmentOrder: boolean,
    ) {
        this._state.isLeftEyeSideSelected = leftOrderSelected;
        this._state.isRightEyeSideSelected = rightOrderSelected;
        this._state.isReorder = true;
        this._state.isSpareOrder = isSpareOrder;
        this._state.isAnnualSupplyFullfillmentOrder = isAnnualSupplyFullfillmentOrder;
    }

    public clear(): void {
        this.state.clear();
        this.continuationSaveConfig = null;
    }

    clearProposalForContinuation(): void {
        this.setProposal(null);
    }

    continueExistingProposal(): void {
        this.gotoStep(this.proposal.Step);
    }

    setProposal(proposal: Proposal): void {
        this._state.proposal = proposal;
    }

    setCartItem(item: CartItem): void {
        this._state.cartItem = item;
    }

    public editFromCart(proposal: Proposal): void {
        this.setProposal(proposal);
        this.setClient(proposal.Client);
        this.setIsCartItem(true);

        const step: FittingSteps = FittingSteps.FitLens;
        this.determineIsGlassFitting();
        this.setEyeSideSelectedParameters(step);
        this.gotoStep(FittingSteps.FitLens);
    }

    private determineIsGlassFitting(): void {
        if (this._state.proposal.LensTypeId == LensTypes.Glass) {
            this.setIsGlassFitting(true);
        } else {
            this.setIsGlassFitting(false);
        }
    }

    private setEyeSideSelectedParameters(step: FittingSteps) {
        this._state.isRightEyeSideSelected = this.canSelectRightEye(step);
        this._state.isLeftEyeSideSelected = this.canSelectLeftEye(step);
    }

    setProposalForContinuation(proposal: Proposal): void {
        this.setProposal(proposal);

        if (proposal == null) {
            return;
        }

        this.setEyeSideSelectedParameters(proposal.Step);
    }

    getGlassTreatmentName(glassTreatmentId: Treatments): string {
        switch (glassTreatmentId) {
            case Treatments.none:
                return this.translateService.instant('general.none');
            case Treatments.remoteEdging:
                return this.translateService.instant('fitglass.remoteEdging');
            case Treatments.remoteEdgingAndFitting:
                return this.translateService.instant('fitglass.remoteEdgingAndFitting');
        }
    }
}
