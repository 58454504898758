import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { FittingService } from '@app/core/services/fitting.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { RefractionMeasurement, ListOption } from '@app/shared/models';
import { InputTimeComponent } from '@app/shared/components/inputs';
import { FittingSteps, EyeSides, LensTypes, Features } from '@app/shared/enums';
import { RefractionFieldsConfigurationService } from '@app/core/services/refractionFieldsConfiguration.service';
import { VisualAcuityService } from '@app/core/services/api/visual-acuity.service';
import { RefractionFieldsConfiguration } from '@app/shared/helpers/refraction-fields-configuration';
import { InputNumberRange } from '@app/shared/models/input-number-range.model';

@Component({
    selector: 'refraction-details',
    templateUrl: 'refraction-details.component.html',
    styleUrls: ['refraction-details.component.scss'],
})
export class RefractionDetailsComponent implements OnInit, OnChanges {
    private _measurement: RefractionMeasurement;

    @ViewChild('timeComponent') timeComponent: InputTimeComponent;

    @Input() set measurement(measurement: RefractionMeasurement) {
        this._measurement = measurement;
        this.patchMeasurement();
    }

    get measurement(): RefractionMeasurement {
        return this._measurement;
    }

    @Input() id: string;
    @Input() hasMyopie = false;
    @Input() eyeSide: number;
    @Input() previous: RefractionMeasurement;
    @Input() showPrevious = true;
    @Input() disabled = false;
    @Input() showStabilisation: boolean;

    refractionMeasurement: RefractionMeasurement;
    visualAcuityCorrectoreValues: ListOption[];
    parentFormGroup: UntypedFormGroup;
    formGroup: UntypedFormGroup;
    config: RefractionFieldsConfiguration;
    stabilisationDegree = 0;

    get formControls() {
        return this.formGroup.controls;
    }
    get stabilisationRanges() {
        return [new InputNumberRange(0, 180, 1)];
    }
    get stabilisationLineStyle() {
        return {
            transform: 'rotate(-' + this.stabilisationDegree + 'deg)',
        };
    }
    get visualAcutityRequired(): boolean {
        if (this.appState.isCompanyFeatureEnabled(Features.RequireVisualAcuitity)) {
            const lensTypeId = this.fittingService.getLensTypeId();
            const exceptionTypes = [LensTypes.Med, LensTypes.MedPlus, LensTypes.Rgp, LensTypes.Soft];
            if (this.fittingService.isFollowUp && exceptionTypes.includes(lensTypeId)) {
                return false;
            }
            return true;
        }
        return false;
    }

    constructor(
        public fittingService: FittingService,
        private readonly parent: FormGroupDirective,
        private readonly fb: UntypedFormBuilder,
        private readonly appState: AppStateService,
        private readonly refractionConfigrationService: RefractionFieldsConfigurationService,
        private readonly visualAcuityService: VisualAcuityService,
    ) {
        this.config = this.refractionConfigrationService.configuration;
        this.getVisualAquities();
    }

    ngOnInit(): void {
        this.parentFormGroup = this.parent.form;
        this.createForm();
        this.initializeStabilisation();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.previous.currentValue) {
            if (this.showPrevious && this.formGroup) {
                if (!this.formGroup.controls['corneaDiameter'].value) {
                    this.formGroup.patchValue({
                        corneaDiameter: this.previous.CorneaDiameter,
                    });
                }
                if (
                    this.fittingService.isFollowUp &&
                    !this.fittingService.isDreamLiteFollowUp &&
                    !this.formGroup.controls['visualAcuityCumCorrectore'].value
                ) {
                    this.formGroup.patchValue({
                        visualAcuityCumCorrectore: this.previous.VisualAcuityCumCorrectoreValueId,
                    });
                }
            }
        }
    }

    private initializeStabilisation(): void {
        if (!this.showStabilisation) {
            return;
        }

        this.formControls['stabilisation'].valueChanges.subscribe((value) => {
            this.stabilisationDegree = value;
        });
    }

    patchMeasurement(): void {
        if (this.formGroup && this.measurement) {
            // during followup, the refraction values should be left empty if the refraction screen
            // is opened by clicking 'next', because this is an overrefraction and the values can't
            // be read from a generated refraction.
            // But when clicking 'previous', the refraction values should be filled in, because
            // the user filled them in manually.
            const isNextStepDuringFollowUp =
                this.fittingService.state.previousStep === FittingSteps.Measurement &&
                (this.fittingService.isDreamLiteFollowUp || this.fittingService.isFollowUp);

            if (isNextStepDuringFollowUp) {
                // the corneadiameter should still be pre-filled (because it doesn't change)
                this.formGroup.patchValue({
                    corneaDiameter: this.measurement.CorneaDiameter,
                });
            } else {
                this.formGroup.patchValue({
                    performed: this.measurement.Performed,
                    visualAcuitySineCorrectore: this.measurement.VisualAcuitySineCorrectoreValueId,
                    sphere: this.measurement.Sphere,
                    cylinder: this.measurement.Cylinder,
                    axis: this.measurement.Axis,
                    visualAcuityCumCorrectore: this.measurement.VisualAcuityCumCorrectoreValueId,
                    nearAdd: this.measurement.NearAdd,
                    refractionOverLens: this.measurement.RefractionOverLens,
                    corneaDiameter: this.measurement.CorneaDiameter,
                    pupilDiameter: this.measurement.PupilDiameter,
                    axisLength: this.measurement.AxisLength,
                    cyclo: this.measurement.Cyclo,
                    stabilisation: this.measurement.Stabilisation,
                    vertex: this.measurement.Vertex,
                });
                this.stabilisationDegree = this.measurement.Stabilisation;
            }
        }
    }

    getVisualAquities(): void {
        const visualAcuityTypeId = this.appState.currentCompany?.VisualAcuityTypeId ?? 1;
        this.visualAcuityService.getVisualAcuityValues(visualAcuityTypeId).subscribe((result) => {
            this.visualAcuityCorrectoreValues = result;
        });
    }

    createForm() {
        let updateValues = false;

        const fcValues = {};
        const refracKey = this.eyeSide === EyeSides.Od ? 'refraction-od' : 'refraction-os';

        if (this.parentFormGroup.controls[refracKey]) {
            for (const key of Object.keys(this.parentFormGroup.controls[refracKey]['controls'])) {
                fcValues[key] = this.parentFormGroup.controls[refracKey]['controls'][key].value;
            }

            updateValues = true;
        }

        const visualAcuityCumCorrectoreValidators = [];
        if (this.visualAcutityRequired) {
            visualAcuityCumCorrectoreValidators.push(Validators.required);
        }

        this.formGroup = this.fb.group({
            performed: [],
            visualAcuitySineCorrectore: [],
            sphere: [, [Validators.required]],
            cylinder: [],
            axis: [],
            visualAcuityCumCorrectore: [, visualAcuityCumCorrectoreValidators],
            nearAdd: [],
            refractionOverLens: [],
            corneaDiameter: [, [Validators.required]],
            stabilisation: [0],
            pupilDiameter: [],
            axisLength: [],
            cyclo: [],
            time: [],
            vertex: [],
        });

        if (updateValues) {
            for (const key of Object.keys(fcValues)) {
                this.formGroup.controls[key].patchValue(fcValues[key]);
            }
        } else {
            this.patchMeasurement();
        }

        this.formGroup.setParent(this.parentFormGroup);
        if (this.parentFormGroup.controls[this.id]) {
            this.parentFormGroup.removeControl(this.id);
        }
        this.parentFormGroup.addControl(this.id, this.formGroup);

        const axis = this.formControls['axis'];
        this.formControls['cylinder'].valueChanges.subscribe((value) => {
            axis.setValidators(value ? [Validators.required] : null);
            axis.updateValueAndValidity();
        });

        if (this.fittingService.isFollowUp && !this.fittingService.isDreamLiteFollowUp) {
            this.formControls['sphere'].clearValidators();
            this.formControls['sphere'].updateValueAndValidity();
        }

        if (
            (this.fittingService.isFollowUp || this.fittingService.isDreamLiteFollowUp) &&
            this.fittingService.state.previousStep === FittingSteps.Measurement
        ) {
            this.formControls['sphere'].patchValue(0);
        }

        if (this.fittingService.isFollowUp && this.fittingService.hasTopoSelected) {
            this.formControls['visualAcuityCumCorrectore'].clearValidators();
            this.formControls['visualAcuityCumCorrectore'].updateValueAndValidity();
        }

        this.loadVertex().then();
    }

    async getMeasurement(): Promise<RefractionMeasurement> {
        const result = new RefractionMeasurement();

        result.Id = this.measurement ? this.measurement.Id : 0;

        result.Axis = this.formControls['axis'].value;
        result.AxisLength = this.formControls['axisLength'].value;
        result.CorneaDiameter = this.formControls['corneaDiameter'].value;
        result.Cyclo = this.formControls['cyclo'].value;
        result.Cylinder = this.formControls['cylinder'].value;

        result.NearAdd = this.formControls['nearAdd'].value;
        result.RefractionOverLens = this.formControls['refractionOverLens'].value;
        result.PupilDiameter = this.formControls['pupilDiameter'].value;
        result.Sphere = this.formControls['sphere'].value;
        result.Vertex = this.formControls['vertex'].value;

        result.VisualAcuityCumCorrectoreValueId = this.formControls['visualAcuityCumCorrectore'].value;
        result.VisualAcuitySineCorrectoreValueId = this.formControls['visualAcuitySineCorrectore'].value;

        if (this.fittingService.isFollowUp && !this.fittingService.isDreamLiteFollowUp && !result.Sphere) {
            result.Sphere = 0;
        }

        if (this.showStabilisation) {
            result.Stabilisation = this.formControls['stabilisation'].value;
        }

        const date = new Date();
        date.setHours(Number(this.timeComponent.hours));
        date.setMinutes(Number(this.timeComponent.minutes));
        result.Performed = date;

        return result;
    }

    isValidTime(): boolean {
        return this.timeComponent.isValid();
    }

    async loadVertex() {
        let vertex = await this.appState.getVertex();

        if (vertex === null || vertex === 0) {
            vertex = 12; // default vertex value
        }

        this.formControls['vertex'].patchValue(vertex);
    }
}
