<div class="process-nav d-flex">
    <div *ngIf="!isGlassFitting" class="process-nav-inner mx-auto px-1 d-flex">
        <div class="process-nav-1 d-flex">
            <fitting-header-item
                [step]="1"
                [currentStep]="step"
                *ngIf="step >= 1"
                [iconName]="'user'"
                class="fitting-header-client"
                [title]="getReference() | translate"
                [subtitle]="getReference2()"
                (click)="onClient()"></fitting-header-item>
            <fitting-header-item
                [step]="2"
                [currentStep]="step"
                *ngIf="step >= 2"
                [imageName]="'measurement.svg'"
                [title]="'general.measurement' | translate"></fitting-header-item>
            <fitting-header-item
                [step]="3"
                [currentStep]="step"
                *ngIf="step >= 3"
                [imageName]="'foropter.svg'"
                [title]="'general.refraction' | translate"></fitting-header-item>
            <fitting-header-item
                [step]="4"
                [currentStep]="step"
                *ngIf="step >= 4"
                [imageName]="'lenstype.svg'"
                [title]="'general.lenstype' | translate"
                [subtitle]="lensType | translate"></fitting-header-item>
            <fitting-header-item
                [step]="5"
                [currentStep]="step"
                *ngIf="step >= 5"
                [imageName]="'fitlens.svg'"
                [title]="'general.fitlens' | translate"></fitting-header-item>
            <fitting-header-item
                [step]="6"
                [currentStep]="step"
                *ngIf="step >= 6"
                [iconName]="'shopping-bag'"
                [title]="'general.orderoverview' | translate"></fitting-header-item>
        </div>

        <div class="arrow"></div>

        <div class="process-nav-2 d-flex">
            <fitting-header-item
                [step]="1"
                [currentStep]="step"
                *ngIf="step < 1"
                [iconName]="'user'"
                [title]="getReference() | translate | truncate: 15"
                [subtitle]="getReference2()"></fitting-header-item>
            <fitting-header-item
                [step]="2"
                [currentStep]="step"
                *ngIf="step < 2"
                [imageName]="'measurement.svg'"
                [title]="'general.measurement' | translate"></fitting-header-item>
            <fitting-header-item
                [step]="3"
                [currentStep]="step"
                *ngIf="step < 3"
                [imageName]="'foropter.svg'"
                [title]="'general.refraction' | translate"></fitting-header-item>
            <fitting-header-item
                [step]="4"
                [currentStep]="step"
                *ngIf="step < 4"
                [imageName]="'lenstype.svg'"
                [title]="'general.lenstype' | translate"></fitting-header-item>
            <fitting-header-item
                [step]="5"
                [currentStep]="step"
                *ngIf="step < 5"
                [imageName]="'fitlens.svg'"
                [title]="'general.fitlens' | translate"></fitting-header-item>
            <fitting-header-item
                [step]="6"
                [currentStep]="step"
                *ngIf="step < 6"
                [iconName]="'shopping-bag'"
                [title]="'general.orderoverview' | translate"></fitting-header-item>
        </div>
    </div>

    <div *ngIf="isGlassFitting" class="process-nav-inner inner-glass mx-auto px-1 d-flex">
        <div class="process-nav-1 d-flex">
            <fitting-header-item
                [step]="1"
                [currentStep]="step"
                *ngIf="step >= 1"
                [iconName]="'user'"
                class="fitting-header-client"
                [title]="getReference() | translate"
                [subtitle]="getReference2()"
                (click)="onClient()"></fitting-header-item>
            <fitting-header-item
                [step]="5"
                [currentStep]="step"
                *ngIf="step >= 5"
                [imageName]="'fitlens.svg'"
                [title]="'general.fitglass' | translate"></fitting-header-item>
            <fitting-header-item
                [step]="6"
                [currentStep]="step"
                *ngIf="step >= 6"
                [iconName]="'shopping-bag'"
                [title]="'general.orderoverview' | translate"></fitting-header-item>
        </div>

        <div class="arrow"></div>

        <div class="process-nav-2 d-flex">
            <fitting-header-item
                [step]="1"
                [currentStep]="step"
                *ngIf="step < 1"
                [iconName]="'user'"
                [title]="getReference() | translate | truncate: 15"
                [subtitle]="getReference2()"></fitting-header-item>
            <fitting-header-item
                [step]="5"
                [currentStep]="step"
                *ngIf="step < 5"
                [imageName]="'fitlens.svg'"
                [title]="'general.fitglass' | translate"></fitting-header-item>
            <fitting-header-item
                [step]="6"
                [currentStep]="step"
                *ngIf="step < 6"
                [iconName]="'shopping-bag'"
                [title]="'general.orderoverview' | translate"></fitting-header-item>
        </div>
    </div>
</div>
