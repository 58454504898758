<div [formGroup]="formGroup" class="container-fluid pt-4 px-lg-4">
    <div class="mb-4 title-border-bottom">
        <h3 id="orderOverviewHeader" class="mt-auto">
            {{ 'general.orderoverview' | translate }}
        </h3>
    </div>

    <div class="row" *ngIf="proposal">
        <div class="col-12 col-md-8 offset-md-2">
            <div class="row">
                <div class="col-12 col-xl-6">
                    <div *ngIf="proposal.RightOpticianFittedLens && fitlensRightEyeSelected" class="row h-100">
                        <div class="col-12 pe-xl-2 mb-5">
                            <div class="row">
                                <div class="col-12 pt-3">
                                    <h1>{{ 'general.right' | translate }}</h1>
                                </div>
                            </div>
                            <div class="row lens-information accented-background rounded mx-0 pb-0 pt-3 h-100">
                                <div class="col-md-6">
                                    <mpc-model-label
                                        id="rightFitDate"
                                        label="{{ 'general.date' | translate }}"
                                        value="{{ proposal.ProposalDate | mpcdate }}"
                                        breakpoint=""></mpc-model-label>
                                    <mpc-model-label
                                        id="rightLensName"
                                        label="{{ 'general.glass' | translate }}"
                                        value="{{ proposal.RightOpticianFittedLens.LensDefinition.Name }}"
                                        breakpoint=""></mpc-model-label>
                                </div>
                                <div class="col-md-6">
                                    <ng-container
                                        *ngFor="let param of proposal.RightOpticianFittedLens.FittedLensParameters">
                                        <mpc-model-label
                                            *ngIf="!param.LensDefinitionParameter.IsHidden"
                                            label="{{ param.LensDefinitionParameter.Name }}"
                                            id="right-{{ param.LensDefinitionParameter.Name.replace(' ', '-') }}"
                                            [formatting]="getFormatObject(param)"
                                            [value]="param.Value"
                                            breakpoint=""></mpc-model-label>
                                    </ng-container>
                                    <mpc-model-label
                                        id="pupilDistanceRight"
                                        *ngIf="showFrameControls()"
                                        label="{{ 'fitglass.pupilDistanceRight' | translate }}"
                                        value="{{ pupilDistanceRight }}"
                                        breakpoint=""></mpc-model-label>
                                    <mpc-model-label
                                        id="ocularCenterRight"
                                        *ngIf="showFrameControls()"
                                        label="{{ 'fitglass.ocularCenterRight' | translate }}"
                                        value="{{ ocularCenterRight }}"
                                        breakpoint=""></mpc-model-label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-6">
                    <div *ngIf="proposal.LeftOpticianFittedLens && fitlensLeftEyeSelected" class="row h-100">
                        <div class="col-12 ps-xl-2 mb-5">
                            <div class="row">
                                <div class="col-12 pt-3">
                                    <h1>{{ 'general.left' | translate }}</h1>
                                </div>
                            </div>
                            <div class="row lens-information accented-background rounded mx-0 pb-0 pt-3 h-100">
                                <div class="col-md-6">
                                    <mpc-model-label
                                        id="leftFitDate"
                                        label="{{ 'general.date' | translate }}"
                                        value="{{ proposal.ProposalDate | mpcdate }}"
                                        breakpoint=""></mpc-model-label>
                                    <mpc-model-label
                                        id="leftLensName"
                                        label="{{ 'general.glass' | translate }}"
                                        value="{{ proposal.LeftOpticianFittedLens.LensDefinition.Name }}"
                                        breakpoint=""></mpc-model-label>
                                </div>
                                <div class="col-md-6">
                                    <ng-container
                                        *ngFor="let param of proposal.LeftOpticianFittedLens.FittedLensParameters">
                                        <mpc-model-label
                                            *ngIf="!param.LensDefinitionParameter.IsHidden"
                                            label="{{ param.LensDefinitionParameter.Name }}"
                                            id="left-{{ param.LensDefinitionParameter.Name.replace(' ', '-') }}"
                                            [formatting]="getFormatObject(param)"
                                            [value]="param.Value"
                                            breakpoint=""></mpc-model-label>
                                    </ng-container>
                                    <mpc-model-label
                                        id="pupilDistanceLeft"
                                        *ngIf="showFrameControls()"
                                        label="{{ 'fitglass.pupilDistanceLeft' | translate }}"
                                        value="{{ pupilDistanceLeft }}"
                                        breakpoint=""></mpc-model-label>
                                    <mpc-model-label
                                        id="ocularCenterLeft"
                                        *ngIf="showFrameControls()"
                                        label="{{ 'fitglass.ocularCenterLeft' | translate }}"
                                        value="{{ ocularCenterLeft }}"
                                        breakpoint=""></mpc-model-label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row pb-4 pt-4">
                <!--Show glass treatment options-->
                <div *ngIf="glassTreatment" [ngClass]="perosBranchNumber ? 'col-12 col-xl-6 mb-3' : 'col-12'">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="pb-2 title-border-bottom">
                                {{ 'fitglass.edgingService' | translate }}
                            </h2>
                        </div>
                    </div>
                    <div class="row">
                        <div [ngClass]="perosBranchNumber ? 'col-12' : 'col-12 col-xl-6'">
                            <mpc-model-label
                                id="glassTreatment"
                                label="{{ 'fitglass.edgingService' | translate }}"
                                value="{{ glassTreatment }}"
                                breakpoint=""></mpc-model-label>
                            <mpc-model-label
                                id="edgePolish"
                                *ngIf="showFrameControls()"
                                label="{{ 'general.edgePolish' | translate }}"
                                value="{{ edgePolish }}"
                                breakpoint=""></mpc-model-label>
                            <mpc-model-label
                                id="bevelType"
                                *ngIf="showFrameControls()"
                                label="{{ 'fitglass.bevelType' | translate }}"
                                value="{{ bevelType }}"
                                breakpoint=""></mpc-model-label>
                            <mpc-model-label
                                id="bevelPosition"
                                *ngIf="bevelPosition"
                                label="{{ 'fitglass.bevelPosition' | translate }}"
                                value="{{ bevelPosition }}"
                                breakpoint=""></mpc-model-label>
                            <mpc-model-label
                                id="frameBrand"
                                *ngIf="showFrameControls()"
                                label="{{ 'fitglass.frameBrand' | translate }}"
                                value="{{ frameBrand }}"
                                breakpoint=""></mpc-model-label>
                            <mpc-model-label
                                id="frameModel"
                                *ngIf="showFrameControls()"
                                label="{{ 'fitglass.frameModel' | translate }}"
                                value="{{ frameModel }}"
                                breakpoint=""></mpc-model-label>
                            <mpc-model-label
                                id="frameMaterial"
                                *ngIf="showFrameControls()"
                                label="{{ 'fitglass.frameType' | translate }}"
                                value="{{ frameMaterial }}"
                                breakpoint=""></mpc-model-label>
                            <mpc-model-label
                                id="frameColor"
                                *ngIf="showFrameControls()"
                                label="{{ 'fitglass.frameColor' | translate }}"
                                value="{{ frameColor }}"
                                breakpoint=""></mpc-model-label>
                            <mpc-model-label
                                id="frameSizeBoxA"
                                *ngIf="showFrameControls()"
                                label="{{ 'fitglass.frameSizeBoxA' | translate }}"
                                value="{{ frameSizeBoxA }}"
                                breakpoint=""></mpc-model-label>
                            <mpc-model-label
                                id="frameSizeBoxB"
                                *ngIf="showFrameControls()"
                                label="{{ 'fitglass.frameSizeBoxB' | translate }}"
                                value="{{ frameSizeBoxB }}"
                                breakpoint=""></mpc-model-label>
                            <mpc-model-label
                                id="frameDblDimension"
                                *ngIf="showFrameControls()"
                                label="{{ 'fitglass.frameDblDimension' | translate }}"
                                value="{{ frameDblDimension }}"
                                breakpoint=""></mpc-model-label>
                            <mpc-model-label
                                id="traceFile"
                                *ngIf="remoteEdgingSelected()"
                                label="{{ 'trace.fileAvailable' | translate }}"
                                value="{{ fileAvailable }}"
                                breakpoint=""></mpc-model-label>
                            <mpc-model-label
                                id="frameTracerSizeBoxA"
                                *ngIf="remoteEdgingSelected()"
                                label="{{ 'fitglass.frameTracerSizeBoxA' | translate }}"
                                value="{{ frameTracerSizeBoxA }}"
                                breakpoint=""></mpc-model-label>
                            <mpc-model-label
                                id="frameTracerSizeBoxB"
                                *ngIf="remoteEdgingSelected()"
                                label="{{ 'fitglass.frameTracerSizeBoxB' | translate }}"
                                value="{{ frameTracerSizeBoxB }}"
                                breakpoint=""></mpc-model-label>
                            <mpc-model-label
                                id="frameTracerDblDimension"
                                *ngIf="remoteEdgingSelected()"
                                label="{{ 'fitglass.frameTracerDblDimension' | translate }}"
                                value="{{ frameTracerDblDimension }}"
                                breakpoint=""></mpc-model-label>
                        </div>
                    </div>
                </div>

                <!--Show peros number-->
                <div *ngIf="perosBranchNumber" [ngClass]="glassTreatment ? 'col-12 col-xl-6' : 'col-12'">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="pb-2 title-border-bottom">
                                {{ 'orderoverview.perosordernumber' | translate }}
                            </h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mpc-input-text
                                formControlName="perosOrderNumber"
                                [id]="'perosOrderNumber'"
                                label="{{ 'orderoverview.perosordernumber' | translate }}"
                                [maxLength]="6"
                                prefix="{{ perosBranchNumber }}"></mpc-input-text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *footer class="d-flex align-items-center">
    <div>
        <a href="#" (click)="previous($event)">
            <i class="fas fa-arrow-alt-circle-left me-1"></i>
            {{ 'general.back' | translate }}
        </a>
    </div>
    <div class="ms-auto d-flex">
        <button
            *hasFeatureSetting="[
                {
                    Feature: Features.IsReceiptsOnlyEnabled,
                    Category: FeatureCategories.Optician,
                    IsEnabled: false
                }
            ]"
            type="button"
            id="send"
            class="btn btn-warning"
            [disabled]="!this.formGroup.valid"
            (click)="next()">
            {{ 'general.send' | translate }}
        </button>
        <button
            *hasFeatureSetting="[
                {
                    Feature: Features.IsReceiptsOnlyEnabled,
                    Category: FeatureCategories.Optician,
                    IsEnabled: true
                }
            ]"
            class="btn btn-primary"
            (click)="createReceipt()">
            {{ 'orderoverview.createreceipt' | translate }}
        </button>
    </div>
</div>
