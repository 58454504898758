import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { FitGlassComponent } from './fitglass.component';

@NgModule({
    imports: [CommonModule, SharedModule],
    declarations: [FitGlassComponent],
    exports: [FitGlassComponent],
    providers: [],
})
export class FitGlassModule {}
