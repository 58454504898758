<div class="modal-header">
    <h4 class="modal-title m-auto">{{ 'general.reorder' | translate }}</h4>
</div>

<ng-container *ngIf="isLoading; else reorderModal">
    <div class="w-full d-flex align-items-center p-5">
        <div class="spinner-border spinner-border-sm m-auto"></div>
    </div>
</ng-container>
<ng-template #reorderModal>
    <div class="modal-body" [formGroup]="formGroup">
        <div class="row pb-2">
            <div class="col-sm-12">
                <mpc-input-textarea
                    formControlName="newNote"
                    [id]="'newNote'"
                    label="clientnotes.addnote"
                    [placeholder]="'orderoverview.supportmessage' | translate"></mpc-input-textarea>
            </div>
        </div>
        <div class="row" *ngIf="perosRequired">
            <div class="col-sm-12">
                <mpc-input-text
                    formControlName="perosNumber"
                    [id]="'perosNumber'"
                    label="psReorder.perosNumber"
                    [maxLength]="20"></mpc-input-text>
            </div>
        </div>
        <div class="row mb-2" *ngIf="perosRequired">
            <div class="col-sm-12">
                <mpc-input-text
                    formControlName="poNumber"
                    [id]="'poNumber'"
                    label="psReorder.poNumber"
                    [maxLength]="20"></mpc-input-text>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <div class="form-group row">
                    <label class="col-form-label my-auto col-md-4">
                        {{ 'orderstatus.priorityorder' | translate }}:
                    </label>
                    <div ng-reflect-ng-class="col-md-8" class="col-md-8">
                        <div class="d-flex align-items-center flex-row">
                            <div *ngFor="let priority of priorities; index as i">
                                <div class="pt-2">
                                    <label>
                                        <input
                                            type="radio"
                                            [ngClass]="i > 0 ? 'ms-3' : ''"
                                            formControlName="selectedPriority"
                                            [value]="priority.Id" />
                                        {{ priority.Name }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div *ngIf="!isLoading" class="modal-footer accented-background">
    <button class="btn next ms-auto" *ngIf="isOrdered" [disabled]="isBusy" (click)="ok()">
        {{ 'general.ok' | translate }}
    </button>
    <button class="btn btn-secondary ms-2" *ngIf="isOrdered" [disabled]="isBusy" (click)="downloadOrderAsPdf()">
        {{ 'general.downloadpdf' | translate }}
    </button>
    <button class="btn btn-primary ms-2" *ngIf="!isOrdered" [disabled]="isBusy || !formGroup.valid" (click)="reorder()">
        {{ 'general.reorder' | translate }}
    </button>
    <button class="btn btn-secondary ms-2" *ngIf="!isOrdered" [disabled]="isBusy" (click)="cancel()">
        {{ 'general.cancel' | translate }}
    </button>
</div>
