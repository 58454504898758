import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-delete-cart-item',
    templateUrl: './delete-cart-item.component.html',
})
export class DeleteCartItemComponent {
    @Input() deleteItem: () => Promise<void>;

    constructor(public bsModalRef: BsModalRef) {}

    close(): void {
        this.bsModalRef.hide();
    }

    delete(): void {
        this.deleteItem();
        this.close();
    }
}
