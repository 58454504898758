import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'multiple-baselines-warning-dialog',
    templateUrl: 'multiple-baselines-warning-dialog.component.html',
})
export class MultipleBaselinesWarningDialogComponent {
    @Input() onContinue: () => void;
    @Input() onCancel: () => void;

    constructor(public modalref: BsModalRef) {}

    cancel(): void {
        this.onCancel();
        this.modalref.hide();
    }

    continue(): void {
        this.onContinue();
        this.modalref.hide();
    }
}
