import { OverrefractionParameter } from '@app/shared/models/overRefractionParameter.model';

export class OverRefractionResult {
    constructor() {
        this.OverrefractionParameters = [];
    }

    Stabilization: number;
    OverrefractionParameters: OverrefractionParameter[];
}
