export interface CartItem {
    Id: number;
    ClientId: number | null;
    ProductId: number | null;
    Quantity: number | null;
    AddSpareOrder: boolean | null;
    IsSpareOrder: boolean | null;
    LeftPreviousOrderId: number | null;
    RightPreviousOrderId: number | null;
    HasWarranty: boolean | null;
    ProposalId: number | null;
}
