import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FittingService } from '@app/core/services/fitting.service';
import { Router } from '@angular/router';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { ProposalService } from '@app/core/services/api/proposal.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subscription, lastValueFrom } from 'rxjs';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { FittedLensOptions } from '@app/shared/models/fittedLensOptions.model';
import {
    EyeSides,
    LensTypes,
    FittingSteps,
    Features,
    ProductGroups,
    MeasurementImageModes,
    FeatureCategories,
} from '@app/shared/enums';
import { Proposal, FittedLens, Client } from '@app/shared/models';
import { FitLensComponent } from '@app/fitlens/fitlens.component';
import { FitlensResetDialogComponent } from '@app/fitlens/dialogs/fitlens-reset-dialog.component';
import { ResetResult } from '@app/fitlens/models/resetResult.model';
import { MeasurementImageComponent } from '@app/measurement/measurement-image.component';
import { FittedLensChange } from '@app/fitlens/models/fittedLensChange.model';
import { LensParameterChange } from '@app/fitlens/models/lensParameterChange.model';
import { ImageOptions } from '@app/shared/models/image-options.model';
import { FittingEventService } from '@app/core/services/fittingEvent.service';
import { FittedLensService } from '@app/core/services/api/fitted-lens.service';
import { SaveFittingContinuationConfiguration } from './saveFittingContinuationConfiguration';
import { AlertService } from '../shared/appservices/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@app/core/services/api/user.service';
import { CopyLensHelper } from '@app/shared/helpers/copyLensHelper';
import { PopupComponent } from '../shared/popup/popup.component';
import { PopupButton } from '../shared/models/popupButton.model';
import { UtilService } from '@app/shared/appservices/util.service';
import { PopupConfig } from '@app/shared/models/popupConfig.model';
import { FeatureCheckerService } from '@app/shared/appservices/feature-checker.service';
import { UserSettingService } from '@app/core/services/api/user-setting.service';

@Component({
    selector: 'fitting-fitlens',
    templateUrl: 'fitting-fitlens.component.html',
})
export class FittingFitLensComponent implements OnInit, OnDestroy {
    formGroup: UntypedFormGroup;
    get formControls() {
        return this.formGroup.controls;
    }

    @ViewChild('leftFitLens') set lfl(value: FitLensComponent) {
        this.leftFitLensControl = value;
        this.initCopyLensHelper();
    }
    @ViewChild('rightFitLens') set rfl(value: FitLensComponent) {
        this.rightFitLensControl = value;
        this.initCopyLensHelper();
    }
    @ViewChild('leftMeasurementImage')
    private leftMeasurementImageControl: MeasurementImageComponent;
    @ViewChild('rightMeasurementImage')
    private rightMeasurementImageControl: MeasurementImageComponent;

    sessionStorageKeysFittingFitLens: string[] = [];

    private leftFitLensControl: FitLensComponent;
    private rightFitLensControl: FitLensComponent;

    eyeSides = EyeSides;
    measurementImageModes = MeasurementImageModes;

    leftFittedLens: FittedLens;
    rightFittedLens: FittedLens;
    initialLeftFittedLens: FittedLens;
    initialRightFittedLens: FittedLens;
    imageOptions: ImageOptions;

    leftEyeSelectedChangedSubscription: Subscription;
    rightEyeSelectedChangedSubscription: Subscription;
    fittedLensChangedSubscription: Subscription;
    lensParameterChangedSubscription: Subscription;

    leftFitLensLoading = false;
    rightFitLensLoading = false;
    leftMeasurementImageLoading = false;
    rightMeasurementImageLoading = false;

    leftFittedLensOptions: FittedLensOptions;
    rightFittedLensOptions: FittedLensOptions;

    leftConvertedToMultiFocal = false;
    rightConvertedToMultiFocal = false;

    copyLensHelper: CopyLensHelper;
    allowDirectOrderEnabled: boolean;

    private lensesFromPreviousOrdersPromise: Promise<FittedLens[]>;

    isSupportOrderEnabled = false;
    blockDirectOrder = false;

    get isWarrantyExchange(): boolean {
        return this.proposal.WarrantyExchange;
    }

    get isFitLens(): boolean {
        return !(this.isDreamLiteFollowUp || this.isRegularFollowUp) && !this.isWarrantyExchange;
    }

    get isFollowUp(): boolean {
        return (this.isDreamLiteFollowUp || this.isRegularFollowUp) && !this.isWarrantyExchange;
    }

    get isDreamLiteFollowUp(): boolean {
        return this.fittingService.isDreamLiteFollowUp;
    }

    get isRegularFollowUp(): boolean {
        return this.fittingService.isFollowUp;
    }

    get fitlensRightEyeSelected(): boolean {
        return this.fittingService.state.isRightEyeSideSelected;
    }
    get fitlensLeftEyeSelected(): boolean {
        return this.fittingService.state.isLeftEyeSideSelected;
    }

    get canSelectLeftEye(): boolean {
        return this.fittingService.canSelectLeftEye(FittingSteps.FitLens);
    }
    get canSelectRightEye(): boolean {
        return this.fittingService.canSelectRightEye(FittingSteps.FitLens);
    }

    get client(): Client {
        return this.fittingService.state.client;
    }

    get proposal(): Proposal {
        return this.fittingService.state.proposal;
    }

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly fittingService: FittingService,
        private readonly featureCheckerService: FeatureCheckerService,
        public modalService: BsModalService,
        public router: Router,
        public appState: AppStateService,
        public appConfig: AppConfigService,
        public proposalService: ProposalService,
        public loaderService: LoaderService,
        public fittingEventService: FittingEventService,
        private fittedlensService: FittedLensService,
        public alertService: AlertService,
        public translateService: TranslateService,
        public userService: UserService,
        public userSettingService: UserSettingService,
        private utilService: UtilService,
    ) {
        if (this.fittingService.getClientId() === 0 || !this.fittingService.state.proposal) {
            router.navigate(['/']).then();
            return;
        }

        this.copyLensHelper = new CopyLensHelper();
        this.copyLensHelper.leftEyeSelected = this.fittingService.state.isLeftEyeSideSelected;
        this.copyLensHelper.rightEyeSelected = this.fittingService.state.isRightEyeSideSelected;

        this.initializeLensesFromPreviousOrder();
        this.createFittedLenses();

        this.initialLeftFittedLens = structuredClone(this.leftFittedLens);
        this.initialRightFittedLens = structuredClone(this.rightFittedLens);

        this.prepareFittedLensOptions();
        this.prepareImageOptions();
    }

    async ngOnInit(): Promise<void> {
        this.fittingService.setFittingStep(this.router.url);
        this.setDefaultQuantity();
        this.createForm();

        this.fittingService.configureSaveForContinuation(
            new SaveFittingContinuationConfiguration(
                () => this.orderAllowed() && this.isNextEnabled(),
                () => this.save(true),
            ),
        );

        this.rightEyeSelectedChangedSubscription = this.formControls['modify-od'].valueChanges.subscribe((result) => {
            this.fittingService.state.isRightEyeSideSelected = result;
            this.copyLensHelper.rightEyeSelected = result;
            if (!result && this.rightFitLensControl) {
                this.rightFitLensControl.formGroup.disable();
                this.rightFitLensControl.formGroup.disable(); // twice because somehow once is not enough sometimes
            }
        });

        this.leftEyeSelectedChangedSubscription = this.formControls['modify-os'].valueChanges.subscribe((result) => {
            this.fittingService.state.isLeftEyeSideSelected = result;
            this.copyLensHelper.leftEyeSelected = result;
            if (!result && this.leftFitLensControl) {
                this.leftFitLensControl.formGroup.disable();
                this.leftFitLensControl.formGroup.disable(); // twice because somehow once is not enough sometimes
            }
        });

        this.fittedLensChangedSubscription = this.fittingEventService.fittedLensChanged.subscribe((result) => {
            this.fittedLensChanged(result);
            this.blockDirectOrder = false;
        });

        this.lensParameterChangedSubscription = this.fittingEventService.lensParameterChanged.subscribe((result) => {
            this.lensParameterChanged(result);
            this.blockDirectOrder = false;
        });

        this.userSettingService.themeSwitchCallback = () => {
            if (this.leftMeasurementImageControl) {
                this.leftMeasurementImageControl.imageOptions = this.imageOptions;
                this.leftMeasurementImageControl.refresh(this.leftFittedLens);
            }

            if (this.rightMeasurementImageControl) {
                this.rightMeasurementImageControl.imageOptions = this.imageOptions;
                this.rightMeasurementImageControl.refresh(this.rightFittedLens);
            }
        };

        this.allowDirectOrderEnabled = this.appState.isOpticianFeatureEnabled(Features.AllowDirectOrders);

        this.supportOrderIsEnabled();
    }

    private initCopyLensHelper(): void {
        this.copyLensHelper.rightFitLensComponent = this.rightFitLensControl;
        this.copyLensHelper.leftFitLensComponent = this.leftFitLensControl;
    }

    private initializeLensesFromPreviousOrder(): void {
        if (!this.fittingService.isFollowUp && !this.fittingService.isDreamLiteFollowUp) {
            return;
        }

        this.lensesFromPreviousOrdersPromise = lastValueFrom(
            this.fittedlensService.getFromOrders(this.proposal.RightPreviousOrderId, this.proposal.LeftPreviousOrderId),
        );
    }

    lensFromPreviousOrderPromise(eyeSide: EyeSides): () => Promise<FittedLens> {
        return () =>
            new Promise((resolve, reject) => {
                if (!this.fittingService.isFollowUp && !this.fittingService.isDreamLiteFollowUp) {
                    resolve(null);
                }

                this.lensesFromPreviousOrdersPromise
                    .then((lenses) => resolve(lenses.find((x) => x.EyeSideId === eyeSide)))
                    .catch((err) => reject(err));
            });
    }

    prepareFittedLensOptions(): void {
        this.leftFittedLensOptions = new FittedLensOptions(
            EyeSides.Os,
            this.proposal,
            this.isFollowUp,
            null,
            this.appState,
        );
        this.rightFittedLensOptions = new FittedLensOptions(
            EyeSides.Od,
            this.proposal,
            this.isFollowUp,
            null,
            this.appState,
        );
    }

    prepareImageOptions(): void {
        this.imageOptions = new ImageOptions(this.proposal.LensTypeId, this.proposal.Id);

        this.updateImageOptionsProductGroup(EyeSides.Os);
        this.updateImageOptionsProductGroup(EyeSides.Od);
    }

    ngOnDestroy(): void {
        this.sessionStorageKeysFittingFitLens.forEach((key) => {
            if (sessionStorage.getItem(key)) {
                sessionStorage.removeItem(key);
            }
        });

        this.userSettingService.themeSwitchCallback = null;

        if (this.rightEyeSelectedChangedSubscription) {
            this.rightEyeSelectedChangedSubscription.unsubscribe();
        }
        if (this.leftEyeSelectedChangedSubscription) {
            this.leftEyeSelectedChangedSubscription.unsubscribe();
        }
        if (this.fittedLensChangedSubscription) {
            this.fittedLensChangedSubscription.unsubscribe();
        }
        if (this.lensParameterChangedSubscription) {
            this.lensParameterChangedSubscription.unsubscribe();
        }
    }

    createForm(): void {
        this.formGroup = this.fb.group({
            'modify-od': [
                this.fittingService.hasRefractionRightMeasurement() && this.fittingService.state.isRightEyeSideSelected,
            ],
            'modify-os': [
                this.fittingService.hasRefractionLeftMeasurement() && this.fittingService.state.isLeftEyeSideSelected,
            ],
        });
    }

    private createFittedLenses(): void {
        if (this.fittingService.state.isLeftEyeSideSelected && !this.proposal.LeftOpticianFittedLens) {
            this.proposal.LeftOpticianFittedLens = new FittedLens();
            this.proposal.LeftOpticianFittedLens.TopographicMeasurementId = this.proposal.LeftTopographicMeasurementId;
            this.proposal.LeftOpticianFittedLens.TopographicMeasurement = this.proposal.LeftTopographicMeasurement;
            this.proposal.LeftOpticianFittedLens.RefractionMeasurementId = this.proposal.LeftRefractionMeasurementId;
            this.proposal.LeftOpticianFittedLens.RefractionMeasurement = this.proposal.LeftRefractionMeasurement;
            this.proposal.LeftOpticianFittedLens.OpticianId = this.proposal.OpticianId;
        }

        if (this.fittingService.state.isRightEyeSideSelected && !this.proposal.RightOpticianFittedLens) {
            this.proposal.RightOpticianFittedLens = new FittedLens();
            this.proposal.RightOpticianFittedLens.TopographicMeasurementId =
                this.proposal.RightTopographicMeasurementId;
            this.proposal.RightOpticianFittedLens.TopographicMeasurement = this.proposal.RightTopographicMeasurement;
            this.proposal.RightOpticianFittedLens.RefractionMeasurementId = this.proposal.RightRefractionMeasurementId;
            this.proposal.RightOpticianFittedLens.RefractionMeasurement = this.proposal.RightRefractionMeasurement;
            this.proposal.RightOpticianFittedLens.OpticianId = this.proposal.OpticianId;
        }

        this.leftFittedLens = this.proposal.LeftOpticianFittedLens;
        this.rightFittedLens = this.proposal.RightOpticianFittedLens;
    }

    toggleRightEye(): void {
        this.fittingService.state.isRightEyeSideSelected = !this.fittingService.state.isRightEyeSideSelected;
        if (!this.fittingService.state.isRightEyeSideSelected && this.rightFitLensControl) {
            this.rightFitLensControl.formGroup.disable();
        }
    }

    toggleLeftEye(): void {
        this.fittingService.state.isLeftEyeSideSelected = !this.fittingService.state.isLeftEyeSideSelected;
        if (!this.fittingService.state.isLeftEyeSideSelected && this.leftFitLensControl) {
            this.leftFitLensControl.formGroup.disable();
        }
    }

    hasLeftMeasurement(): boolean {
        return !!this.fittingService.state.proposal.LeftRefractionMeasurement;
    }

    hasRightMeasurement(): boolean {
        return !!this.fittingService.state.proposal.RightRefractionMeasurement;
    }

    previous($event: MouseEvent): void {
        $event.preventDefault();
        this.fittingService.gotoPreviousStep();
    }

    hasLeftLens(): boolean {
        return (
            this.proposal &&
            this.proposal.LeftOpticianFittedLens &&
            this.proposal.LeftOpticianFittedLens.LensDefinitionId &&
            this.formControls['modify-os'].value === true
        );
    }

    hasRightLens(): boolean {
        return (
            this.proposal &&
            this.proposal.RightOpticianFittedLens &&
            this.proposal.RightOpticianFittedLens.LensDefinitionId &&
            this.formControls['modify-od'].value === true
        );
    }

    orderAllowed(): boolean {
        return !this.formGroup.invalid && (this.hasLeftLens() || this.hasRightLens());
    }

    handleFitLensLoading(eyeSide: EyeSides, loading: boolean): void {
        if (eyeSide === EyeSides.Os) {
            this.leftFitLensLoading = loading;
        } else if (eyeSide === EyeSides.Od) {
            this.rightFitLensLoading = loading;
        }
    }

    handleMeasurementImageLoading(eyeSide: EyeSides, loading: boolean): void {
        if (eyeSide === EyeSides.Os) {
            this.leftMeasurementImageLoading = loading;
        } else if (eyeSide === EyeSides.Od) {
            this.rightMeasurementImageLoading = loading;
        }
    }

    multiFocalAdditionRequired(eyeSide: EyeSides): boolean {
        if (eyeSide === EyeSides.Os && this.leftFitLensControl && this.leftFitLensControl.multiFocalAdditionRequired) {
            return true;
        } else
            return (
                eyeSide === EyeSides.Od &&
                this.rightFitLensControl &&
                this.rightFitLensControl.multiFocalAdditionRequired
            );
    }

    get loading(): boolean {
        return this.leftFitLensLoading || this.rightFitLensLoading;
    }

    isNextEnabled(): boolean {
        return this.formGroup.valid && !this.loading;
    }

    orderContainsMultifocalLens(): boolean {
        if (this.proposal.LeftOpticianFittedLens && this.leftFitLensControl && this.leftFitLensControl.isMultifocal) {
            return true;
        }

        return (
            this.proposal.RightOpticianFittedLens && this.rightFitLensControl && this.rightFitLensControl.isMultifocal
        );
    }

    orderContainsNotOrderableLens(): boolean {
        if (
            this.leftFittedLens &&
            this.fitlensLeftEyeSelected &&
            this.leftFittedLens.LensDefinition.Product.MaxQuantity === 0
        ) {
            return true;
        }

        return (
            this.rightFittedLens &&
            this.fitlensRightEyeSelected &&
            this.rightFittedLens.LensDefinition.Product.MaxQuantity === 0
        );
    }

    directOrderIsEnabled(): boolean {
        if (this.orderContainsNotOrderableLens()) {
            return false;
        }
        if (this.orderContainsMultifocalLens() && this.isDreamLiteFollowUp) {
            return false;
        }
        return this.isNextEnabled();
    }

    get isCartItem(): boolean {
        return this.fittingService.isCartItem();
    }

    supportOrderIsEnabled(): void {
        const isFeatureChecked = this.featureCheckerService.check([
            {
                Feature: Features.BlockOrders,
                Category: FeatureCategories.Optician,
                IsEnabled: false,
            },
            {
                Feature: Features.IsReceiptsOnlyEnabled,
                Category: FeatureCategories.Optician,
                IsEnabled: false,
            },
        ]);

        this.isSupportOrderEnabled = !this.isCartItem && isFeatureChecked;
    }

    get validEccentricity(): boolean {
        if (!this.loading && !this.isFollowUp) {
            let invalid = false;
            const checkEyeSides = [];
            const checkEccentricityFields = ['Eccentricity0', 'Eccentricity180', 'Eccentricity270'];

            if (this.hasLeftLens()) {
                checkEyeSides.push('leftFittedLens');
            }

            if (this.hasRightLens()) {
                checkEyeSides.push('rightFittedLens');
            }

            checkEyeSides.forEach((eyeside) => {
                const eyesideObject: FittedLens = this[eyeside];
                if (this.proposal.LensTypeId === LensTypes.DreamLite && eyesideObject.TopographicMeasurementId) {
                    checkEccentricityFields.forEach((key) => {
                        const eccentricityValue = eyesideObject.TopographicMeasurement[key];
                        if (eccentricityValue < 0 || eccentricityValue > 1) {
                            invalid = true;
                        }
                    });
                }
            });

            return !invalid;
        }

        return true;
    }

    get validACValues(): boolean {
        if (!this.loading && !this.isFollowUp) {
            const leftOpticianFittedLens = this.leftFitLensControl ? this.leftFitLensControl.readFittedLens() : null;
            const rightOpticianFittedLens = this.rightFitLensControl ? this.rightFitLensControl.readFittedLens() : null;

            return !(!this.validACValue(leftOpticianFittedLens) || !this.validACValue(rightOpticianFittedLens));
        } else {
            return true;
        }
    }

    validACValue(eyesideObject: FittedLens): boolean {
        if (!eyesideObject || !eyesideObject.FittedLensParameters || this.proposal.LensTypeId !== LensTypes.DreamLite) {
            return true;
        }

        const acValue = eyesideObject.FittedLensParameters.find(
            (x) => x.LensDefinitionParameter.ParameterType.Code === 'AC',
        );
        if (acValue == null) {
            return true;
        }

        const acValueToSteep = 7.2;
        return acValue.Value > acValueToSteep;
    }

    get orderHasEmptyValues(): boolean {
        let hasEmptyValues = false;
        const leftOpticianFittedLens = this.leftFitLensControl ? this.leftFitLensControl.readFittedLens() : null;
        const rightOpticianFittedLens = this.rightFitLensControl ? this.rightFitLensControl.readFittedLens() : null;

        if (leftOpticianFittedLens) {
            leftOpticianFittedLens.FittedLensParameters.forEach((param) => {
                if (param.GenericValue === undefined) {
                    hasEmptyValues = true;
                }
            });
        }

        if (rightOpticianFittedLens) {
            rightOpticianFittedLens.FittedLensParameters.forEach((param) => {
                if (param.GenericValue === undefined) {
                    hasEmptyValues = true;
                }
            });
        }

        return hasEmptyValues;
    }

    async next(supportOrder: boolean) {
        if (supportOrder && this.orderHasEmptyValues) {
            const options: ModalOptions<PopupConfig> = {
                initialState: {
                    popupText: 'fitlens.emptyfields',
                    buttons: [new PopupButton('general.close', 'btn-default', null)],
                },
                class: 'modal-dialog',
            };
            this.modalService.show(PopupComponent, options);
            return;
        }

        this.fittingService.setProposalStepOnNextClick();
        const saveResult = await this.save(supportOrder);

        try {
            await lastValueFrom(this.proposalService.eligibleForWarranty(saveResult));
            this.fittingService.clearSaveForContinationConfiguration();
            this.fittingService.setProposal(saveResult);
            this.fittingService.gotoNextStep();
        } catch (error) {
            const errors = this.utilService.getServerErrors(error, false);
            this.blockDirectOrder = true;

            const options: ModalOptions<PopupConfig> = {
                initialState: {
                    popupText: errors[0],
                    buttons: [new PopupButton('general.close', 'btn-default', null)],
                },
                class: 'modal-dialog',
            };
            this.modalService.show(PopupComponent, options);
        }

        this.loaderService.hide();
    }

    private setDefaultQuantity() {
        if (this.proposal.LeftOpticianFittedLens && !this.proposal.LeftProposalOptions.Quantity) {
            this.proposal.LeftProposalOptions.Quantity = 1;
        }

        if (this.proposal.RightOpticianFittedLens && !this.proposal.RightProposalOptions.Quantity) {
            this.proposal.RightProposalOptions.Quantity = 1;
        }
    }

    private save(supportOrder: boolean): Promise<Proposal> {
        this.loaderService.show();

        const prop = this.proposal;
        prop.OrderWithSupport = supportOrder;

        // read the adjusted lenses from the FitLens component
        prop.LeftOpticianFittedLens = this.leftFitLensControl ? this.leftFitLensControl.readFittedLens() : null;
        prop.RightOpticianFittedLens = this.rightFitLensControl ? this.rightFitLensControl.readFittedLens() : null;

        prop.LeftOpticianFittedLensId = prop.LeftOpticianFittedLens ? prop.LeftOpticianFittedLens.Id : null;
        prop.RightOpticianFittedLensId = prop.RightOpticianFittedLens ? prop.RightOpticianFittedLens.Id : null;

        if (prop.LeftOpticianFittedLens) {
            prop.LeftProposalOptions.Quantity = this.leftFitLensControl.readQuantity();
        }

        if (prop.RightOpticianFittedLens) {
            prop.RightProposalOptions.Quantity = this.rightFitLensControl.readQuantity();
        }

        return lastValueFrom(this.proposalService.saveOpticianFittedLenses(prop));
    }

    async startResetFitlensModal() {
        const result = await this.resetFitlensModal();

        if (!result || !result.reset) {
            return;
        }

        this.loaderService.show();
        await this.resetFitLens(result);
        this.loaderService.hide();
    }

    resetFitlensModal(): Promise<ResetResult> {
        const options: unknown = {
            initialState: {
                isRightEyeSideSelected: this.fittingService.state.isRightEyeSideSelected,
                isLeftEyeSideSelected: this.fittingService.state.isLeftEyeSideSelected,
            },
            class: 'modal-md',
        };
        const fitLensResetModalRef = this.modalService.show(FitlensResetDialogComponent, options);

        return new Promise<ResetResult>((resolve) => {
            const subscription = this.modalService.onHide.subscribe(() => {
                resolve(fitLensResetModalRef.content.result);
                subscription.unsubscribe();
            });
        });
    }

    private async resetFitLens(result: ResetResult) {
        let resetOd = true;
        let resetOs = true;

        if (result.eyeSide === EyeSides.Od) {
            resetOs = false;
        }
        if (result.eyeSide === EyeSides.Os) {
            resetOd = false;
        }

        const proposal = await lastValueFrom(
            this.proposalService.resetOpticianFittedLenses({
                proposalId: this.proposal.Id,
                resetOd,
                resetOs,
            }),
        );

        this.fittingService.setProposal(proposal);

        if (resetOs) {
            this.leftFittedLens = proposal.LeftOpticianFittedLens;
            if (this.leftFitLensControl) {
                this.leftFitLensControl.reset(this.leftFittedLens);
            }
            if (this.leftMeasurementImageControl) {
                this.leftMeasurementImageControl.refresh(this.leftFittedLens);
            }
        }

        if (resetOd) {
            this.rightFittedLens = proposal.RightOpticianFittedLens;
            if (this.rightFitLensControl) {
                this.rightFitLensControl.reset(this.rightFittedLens);
            }
            if (this.rightMeasurementImageControl) {
                this.rightMeasurementImageControl.refresh(this.rightFittedLens);
            }
        }
    }

    fittedLensChanged(fittedLensChange: FittedLensChange): void {
        if (fittedLensChange.newFittedLens.EyeSideId === EyeSides.Os) {
            this.leftFittedLens = fittedLensChange.newFittedLens;

            this.updateImageOptionsProductGroup(this.leftFittedLens.EyeSideId);

            if (this.leftMeasurementImageControl && this.imageOptions.leftImageMode !== MeasurementImageModes.Topo) {
                this.leftMeasurementImageControl.refresh(this.leftFittedLens);
            }
        } else if (fittedLensChange.newFittedLens.EyeSideId === EyeSides.Od) {
            this.rightFittedLens = fittedLensChange.newFittedLens;

            this.updateImageOptionsProductGroup(this.rightFittedLens.EyeSideId);

            if (this.rightMeasurementImageControl && this.imageOptions.rightImageMode !== MeasurementImageModes.Topo) {
                this.rightMeasurementImageControl.refresh(this.rightFittedLens);
            }
        }
    }

    updateImageOptionsProductGroup(eyeSide: EyeSides): void {
        let productGroupCode = '';

        if (eyeSide === EyeSides.Os && this.leftFittedLens) {
            if (this.leftFittedLens.LensDefinition && this.leftFittedLens.LensDefinition.Product) {
                productGroupCode = this.leftFittedLens.LensDefinition.Product.ProductGroup.Code;
            }

            if (
                this.imageOptions.leftImageMode === MeasurementImageModes.Fluo ||
                this.imageOptions.leftImageMode === MeasurementImageModes.LensProfile
            ) {
                this.imageOptions.leftImageMode = ProductGroups.isScleralProductGroup(productGroupCode)
                    ? MeasurementImageModes.LensProfile
                    : MeasurementImageModes.Fluo;
            }
        } else if (eyeSide === EyeSides.Od && this.rightFittedLens) {
            if (this.rightFittedLens.LensDefinition && this.rightFittedLens.LensDefinition.Product) {
                productGroupCode = this.rightFittedLens.LensDefinition.Product.ProductGroup.Code;
            }

            if (
                this.imageOptions.rightImageMode === MeasurementImageModes.Fluo ||
                this.imageOptions.rightImageMode === MeasurementImageModes.LensProfile
            ) {
                this.imageOptions.rightImageMode = ProductGroups.isScleralProductGroup(productGroupCode)
                    ? MeasurementImageModes.LensProfile
                    : MeasurementImageModes.Fluo;
            }
        }
    }

    lensParameterChanged(lensParameterChange: LensParameterChange): void {
        // BR: topo image should only be refreshed when DIAM parameter was changed on a soft lens

        if (lensParameterChange.newFittedLens.EyeSideId === EyeSides.Os) {
            const shouldRefreshTopoImage =
                this.imageOptions.leftImageMode === MeasurementImageModes.HeightMap &&
                lensParameterChange.changedParameter === 'DIAM' &&
                (this.proposal.LensTypeId === LensTypes.Soft || this.proposal.LensTypeId === LensTypes.Rgp);

            this.leftFittedLens = lensParameterChange.newFittedLens;

            if (
                this.leftMeasurementImageControl &&
                (shouldRefreshTopoImage ||
                    this.imageOptions.leftImageMode === MeasurementImageModes.Fluo ||
                    this.imageOptions.leftImageMode === MeasurementImageModes.LensProfile)
            ) {
                this.leftMeasurementImageControl.refresh(this.leftFittedLens);
            }
        } else if (lensParameterChange.newFittedLens.EyeSideId === EyeSides.Od) {
            const shouldRefreshTopoImage =
                this.imageOptions.rightImageMode === MeasurementImageModes.HeightMap &&
                lensParameterChange.changedParameter === 'DIAM' &&
                (this.proposal.LensTypeId === LensTypes.Soft || this.proposal.LensTypeId === LensTypes.Rgp);

            this.rightFittedLens = lensParameterChange.newFittedLens;

            if (
                this.rightMeasurementImageControl &&
                (shouldRefreshTopoImage ||
                    this.imageOptions.rightImageMode === MeasurementImageModes.Fluo ||
                    this.imageOptions.rightImageMode === MeasurementImageModes.LensProfile)
            ) {
                this.rightMeasurementImageControl.refresh(this.rightFittedLens);
            }
        }
    }

    get showImageOptions(): boolean {
        return (
            this.proposal &&
            (this.fittingService.state.isRightEyeSideSelected || this.fittingService.state.isLeftEyeSideSelected)
        );
    }

    imageModeChanged(measurementImageMode: MeasurementImageModes): void {
        this.imageOptions.leftImageMode = measurementImageMode;

        if (this.leftMeasurementImageControl) {
            this.leftMeasurementImageControl.imageOptions = this.imageOptions;
            this.leftMeasurementImageControl.refresh(this.leftFittedLens);
        }

        this.imageOptions.rightImageMode = measurementImageMode;
        if (this.rightMeasurementImageControl) {
            this.rightMeasurementImageControl.imageOptions = this.imageOptions;
            this.rightMeasurementImageControl.refresh(this.rightFittedLens);
        }
    }

    getImageHeader(): string {
        switch (this.imageOptions.leftImageMode) {
            case MeasurementImageModes.Topo:
                return 'general.topography';
            case MeasurementImageModes.HeightMap:
                return 'measurementoptions.heightmap';
            case MeasurementImageModes.LensProfile:
                return 'general.topography';
            case MeasurementImageModes.Fluo:
                return 'measurementoptions.fluo';
        }
    }

    imageOptionsChanged(imageOptions: ImageOptions): void {
        this.imageOptions = imageOptions;

        if (this.leftMeasurementImageControl) {
            this.leftMeasurementImageControl.imageOptions = this.imageOptions;
            this.leftMeasurementImageControl.refresh(this.leftFittedLens);
        }

        if (this.rightMeasurementImageControl) {
            this.rightMeasurementImageControl.imageOptions = this.imageOptions;
            this.rightMeasurementImageControl.refresh(this.rightFittedLens);
        }
    }

    alertDirectOrderDisabled(): void {
        this.alertService.info(this.translateService.instant('info.disableDirectOrder'));
    }
}
