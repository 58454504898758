<div class="m-4">
    <div>
        <div *ngIf="currentStep === 0">
            <ng-container *ngTemplateOutlet="serviceScanComplaint"></ng-container>
        </div>
        <div *ngIf="currentStep === 1">
            <ng-container *ngTemplateOutlet="qaReturnDetail"></ng-container>
        </div>
    </div>
</div>

<ng-template #serviceScanComplaint>
    <div class="d-flex flex-sm-row">
        <form [formGroup]="formGroup" class="col-12 col-sm-9 col-xl-6 p-4" (ngSubmit)="search()">
            <div class="title-border-bottom mb-2">
                <h3>{{ 'service.scanComplaint.pageTitle' | translate }}</h3>
            </div>

            <div class="form-group row mb-2">
                <label class="col-4 col-form-label">{{ 'service.barcode' | translate }}</label>
                <div class="col-6">
                    <input
                        #barcode
                        class="form-control"
                        name="barcode"
                        id="barcode"
                        formControlName="barcode"
                        placeholder="0-0000000-000000"
                        required />
                </div>
                <div class="col-2">
                    <button
                        type="submit"
                        class="btn-block btn btn-primary"
                        [disabled]="!(formGroup.valid && barcodeSearchControl.dirty)">
                        {{ 'general.search' | translate }}
                    </button>
                </div>
            </div>

            <div class="spinner-border spinner-border-sm mt-4 ms-4" *ngIf="loading"></div>
            <div *ngIf="!serviceData">
                {{ 'general.nodataavailable' | translate }}
            </div>
            <ng-container *ngIf="serviceData">
                <mpc-input-text
                    formControlName="serviceRequestNumber"
                    label="{{ 'service.lensServiceRequest' | translate }}"></mpc-input-text>
                <mpc-input-text
                    formControlName="opticianName"
                    label="{{ 'general.optician' | translate }}"></mpc-input-text>
            </ng-container>
        </form>
    </div>
    <div *ngIf="serviceRequestHasTypeComplication()" class="quarantine-warning">
        <i class="fa fa-exclamation-triangle"></i>
        {{ 'service.desinfectAndQuarantineLens' | translate }}
    </div>

    <div *footer>
        <div class="d-flex align-items-center">
            <a [routerLink]="['/']" class="col-10">
                <i class="fas fa-arrow-alt-circle-left me-1"></i>
                {{ 'general.home' | translate }}
            </a>
            <div class="ms-auto" *ngIf="!isReadOnly">
                <div *ngIf="userIsInRolQualityAssurance">
                    <button
                        id="MarkAsBeingHandled"
                        class="btn good ms-2"
                        (click)="changeStatus(serviceStatuses.QaHandling)">
                        {{ 'service.markAsQaHandling' | translate }}
                    </button>
                </div>
                <div *ngIf="userIsInRolLogistics">
                    <button
                        class="btn good ms-2"
                        id="MarkAsReceived"
                        [routerLink]="['/']"
                        (click)="changeStatus(serviceStatuses.Received)">
                        {{ 'service.markAsReceived' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #qaReturnDetail>
    <div class="row">
        <qa-process-complaint
            [selectedReturn]="serviceData"
            [saveRedirectRoute]="'/qualityassurance/scan'"></qa-process-complaint>
    </div>
</ng-template>
