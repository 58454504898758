import { Injectable } from '@angular/core';
import { HubProxyService } from '@app/core/services/wss/hubproxy.service';
import { AuthenticationService } from '@app/core/services/api/authentication.service';
import { Router } from '@angular/router';
import { AppStateService } from './appState.service';
import { SessionService } from './session.service';
import { LocalStorageService } from './localstorage.service';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class LogoutService {
    constructor(
        private router: Router,
        private appState: AppStateService,
        private authenticationService: AuthenticationService,
        private hubProxyService: HubProxyService,
        private sessionService: SessionService,
        private localStorageService: LocalStorageService,
    ) {}

    async logout(): Promise<void> {
        this.hubProxyService.closeConnection();
        try {
            lastValueFrom(this.authenticationService.logout());
        } catch (_) {}

        this.appState.clearUserData();
        this.sessionService.clearAll();
        this.localStorageService.clearAll();
        this.appState.closeModals$.emit();
        this.router.navigate(['/login']).then();
    }
}
