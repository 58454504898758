import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Serial } from '@app/shared/models/serial.model';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { TranslateService } from '@ngx-translate/core';
import { Attachment, ListOption, ListSelectOption } from '@app/shared/models';
import { HttpErrorResponse } from '@angular/common/http';
import { ReturnQualityAssuranceModel } from '@app/shared/models/ReturnQualityAssuranceModel';
import { ReturnQaLensDefinition } from '@app/shared/models/ReturnQaLensDefinition';
import { ReturnQaConclusion } from '@app/shared/models/returnQaConclusion.model';
import { ServiceStatus } from '@app/shared/enums';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploadComponent } from '@app/shared/components/fileupload/fileupload.component';
import { AttachmentService } from '@app/core/services/api/attachment.service';
import { Util } from '@app/shared/helpers/utility.helper';
import { AlertService } from '@app/shared/appservices/alert.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { ServiceRequest } from '@app/shared/models/service-request.model';
import { Observable, Subscription, lastValueFrom, map, startWith } from 'rxjs';
import { ColorService } from '@app/core/services/api/color.service';
import { MaterialsService } from '@app/core/services/api/material.service';
import { QaService } from '@app/core/services/api/qa.service';
import { ReturnService } from '@app/core/services/api/return.service';
import { SurfaceService } from '@app/core/services/api/surface.service';
import { EdgeService } from '@app/core/services/api/edge.service';
import { ReturnType } from '@app/shared/models/returnType.model';

@Component({
    selector: 'qa-process-complaint',
    templateUrl: 'qa-process-complaint.component.html',
})
export class QaProcessComplaintComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('fileUploader', { static: false }) fileUploadComponent: FileUploadComponent;
    parameterData: unknown;
    measurementsFormGroup: UntypedFormGroup;
    lensPropertyFormGroup: UntypedFormGroup;
    serialData: Serial;
    lensDefinition: ReturnQaLensDefinition;
    returnQaConclusion: ReturnQaConclusion;
    serialNumber: string;
    returnStatus: string;
    returnCategories: ListOption[];
    returnTypes: ReturnType[];
    filteredReturnTypes$: Observable<ReturnType[]>;
    returnId: number;
    userName: string;
    surfaceOptions: ListSelectOption[];
    selectedSurfaceOptionId: number;
    edgeOptions: ListSelectOption[];
    materialOptions: ListSelectOption[];
    colorOptions: ListSelectOption[];
    opticIsClearOptions: ListSelectOption[];
    productionErrorOptions: ListSelectOption[];
    returnDateNow = new Date().toLocaleDateString();
    orderShippingDate = '';
    attachments = new Array<Attachment>();
    loading = false;
    private measurementFormControlsSub = new Subscription();
    lensDefinitionHasValues = false;
    isReadOnly = false;
    defaultSaveRedirectRoute = '/';

    pow = 'pow';
    oz = 'oz';
    add = 'add';
    bcr2 = 'bcr2';
    notch = 'notch';
    bcr = 'bcr';
    prism = 'prism';
    sag = 'sag';
    trunc = 'trunc';
    diam = 'diam';
    ax = 'ax';
    cmd = 'cmd';
    stab = 'stab';
    cyl = 'cyl';
    readingSegment = 'readingSegment';

    measurementsStringArray = [
        this.pow,
        this.oz,
        this.add,
        this.bcr2,
        this.bcr,
        this.prism,
        this.sag,
        this.diam,
        this.ax,
        this.cmd,
        this.stab,
        this.cyl,
    ];

    lensThicknesses = ['-0.06', '-0.03', '+0.00', '+0.03', '+0.06'];

    @Input() inputReturnId: number;
    @Input() selectedReturn: ServiceRequest;
    @Input() useLimitedInfoView = false;
    @Input() saveRedirectRoute?: string;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly edgeService: EdgeService,
        private readonly translate: TranslateService,
        private readonly appState: AppStateService,
        private readonly router: Router,
        private readonly activeRoute: ActivatedRoute,
        private readonly attachmentService: AttachmentService,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        private readonly loaderService: LoaderService,
        private readonly colorService: ColorService,
        private readonly materialsService: MaterialsService,
        private readonly qaService: QaService,
        private readonly returnService: ReturnService,
        private readonly surfaceService: SurfaceService,
    ) {
        this.returnId = this.activeRoute.snapshot.params['returnId'];
    }
    ngOnDestroy(): void {
        this.measurementFormControlsSub.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedReturn?.previousValue !== changes.selectedReturn?.currentValue) {
            this.selectedReturn = changes.selectedReturn.currentValue;
        }
    }

    async ngOnInit() {
        if (this.inputReturnId) {
            this.returnId = this.inputReturnId;
        } else if (this.selectedReturn) {
            this.returnId = this.selectedReturn.Id;
            if (this.selectedReturn.OrderLine?.ShippedDate != null) {
                this.orderShippingDate = new Date(this.selectedReturn.OrderLine.ShippedDate).toLocaleDateString();
            }
        } else {
            this.returnId = this.activeRoute.snapshot.params['returnId'];
        }

        this.returnTypes = await lastValueFrom(this.returnService.getReturnTypes());
        this.returnCategories = await lastValueFrom(this.returnService.getReturnCategories());

        this.userName = this.appState.authenticatedUser.Name;
        await this.loadQualityAssuranceData();
    }

    createMeasurementsForm() {
        const regexPattern = /^[\+-]?((\d*)?(\.)?(\d*)?)?$/;
        this.measurementsFormGroup = this.fb.group({
            pow: ['', [Validators.pattern(regexPattern)]],
            oz: ['', [Validators.pattern(regexPattern)]],
            add: ['', [Validators.pattern(regexPattern)]],
            bcr2: ['', [Validators.pattern(regexPattern)]],
            notch: [''],
            bcr: ['', [Validators.pattern(regexPattern)]],
            cyl: ['', [Validators.pattern(regexPattern)]],
            prism: ['', [Validators.pattern(regexPattern)]],
            sag: ['', [Validators.pattern(regexPattern)]],
            trunc: [''],
            diam: ['', [Validators.pattern(regexPattern)]],
            ax: ['', [Validators.pattern(regexPattern)]],
            cmd: ['', [Validators.pattern(regexPattern)]],
            stab: ['', [Validators.pattern(regexPattern)]],
            readingSegment: [''],
            additional: [''],
            productionError: [''],
            files: [''],
            returnCategory: [''],
            returnType: [''],
        });

        this.measurementsFormGroupValueChangeSubs();

        if (this.useLimitedInfoView) {
            this.measurementsFormGroup.disable();
        }
    }

    private measurementsFormGroupValueChangeSubs() {
        this.measurementsStringArray.forEach((measurement) => {
            this.measurementFormControlsSub.add(
                this.measurementsFormGroup.controls[measurement].valueChanges.subscribe((x: string) => {
                    this.updateCommaWithPoint(measurement, x);
                }),
            );
        });
    }

    updateCommaWithPoint(formControl: string, stringToReplaceComma: string) {
        if (stringToReplaceComma?.toString().includes(',')) {
            stringToReplaceComma = stringToReplaceComma?.replace(/,/gi, '.') ?? '';
            this.measurementsFormGroup.controls[formControl].patchValue(stringToReplaceComma);
        }
    }

    createLensPropertyForm() {
        this.lensPropertyFormGroup = this.fb.group({
            surfaces: [],
            edges: [],
            material: [],
            color: [],
            opticIsClear: [],
        });
        if (this.useLimitedInfoView) {
            this.lensPropertyFormGroup.disable();
        }
    }

    async loadQualityAssuranceData() {
        this.loading = true;

        try {
            await this.getReturnQaCheckData(this.returnId).then(async (result) => {
                this.serialNumber = result.ReturnQualityAssuranceConclusion.SerialNumber;
                this.lensDefinition = result.LensDefinition;
                this.lensDefinitionHasValues = this.checkLensDefValues();
                this.returnQaConclusion = result.ReturnQualityAssuranceConclusion;
                this.attachments = result.ReturnQualityAssuranceConclusion.Attachments;

                await this.getAndSetRadioButtonOptionsFromDb();
                this.createMeasurementsForm();
                this.createLensPropertyForm();
                if (this.returnQaConclusion.ReturnId !== 0) {
                    this.fillSavedQaMeasurements();
                }
            });
        } finally {
            this.loading = false;
        }

        this.measurementsFormGroup.controls.returnCategory.setValue(this.selectedReturn.ReturnCategoryId);
        const filterReturnTypesByCategory = (returnCategoryId: number) =>
            this.returnTypes.filter((returnType) => returnType.ReturnCategoryId === +returnCategoryId);

        this.filteredReturnTypes$ = this.measurementsFormGroup.controls.returnCategory.valueChanges.pipe(
            startWith(this.measurementsFormGroup.controls.returnCategory.value),
            map(filterReturnTypesByCategory),
        );
    }

    checkLensDefValues(): boolean {
        return (
            this.lensDefinition.Add !== null ||
            this.lensDefinition.Ax !== null ||
            this.lensDefinition.Bcr2 !== null ||
            this.lensDefinition.Oz !== null ||
            this.lensDefinition.Diam !== null ||
            this.lensDefinition.Bcr !== null ||
            this.lensDefinition.Pow !== null ||
            this.lensDefinition.Cyl !== null ||
            this.lensDefinition.Cmd !== null ||
            this.lensDefinition.Prism !== null ||
            this.lensDefinition.Sag !== null ||
            this.lensDefinition.Stab !== null ||
            this.lensDefinition.LensThicknessId !== null
        );
    }

    getReturnStatusName() {
        return this.selectedReturn.ReturnStatusName;
    }

    async getReturnQaCheckData(returnId: number): Promise<ReturnQualityAssuranceModel> {
        return lastValueFrom(this.qaService.getByReturnId(returnId));
    }

    async getAndSetRadioButtonOptionsFromDb() {
        const includedIdList = [
            'BOSTONES',
            'BOSTONEO',
            'BOSTONXO',
            'EQUA2FULLSCLERAL',
            'BOSTONXO2FULLSCLERAL',
            'CENTO',
            'CLASSIC',
            'COMFORT',
            'EXTRA',
            'ULTIMA',
            'GM3',
            'SOFT68',
            'CD78',
        ];
        await lastValueFrom(this.materialsService.getMaterials())
            .then((res) => {
                this.materialOptions = [];
                includedIdList.forEach((orderedMaterial) => {
                    const material = res.find((material) => material.Code === orderedMaterial);
                    if (material) {
                        this.materialOptions.push(material);
                    }
                });

                if (this.returnQaConclusion?.MaterialId !== null) {
                    this.materialOptions.find((x) => x.Id === this.returnQaConclusion.MaterialId).Selected = true;
                }
            })
            .catch((err: HttpErrorResponse) => console.log('unable to retrieve materialData: ' + err.status));

        await lastValueFrom(this.colorService.getColors())
            .then((res) => {
                this.colorOptions = res;
                if (this.returnQaConclusion?.ColorId !== null) {
                    this.colorOptions.find((x) => x.Id === this.returnQaConclusion.ColorId).Selected = true;
                }
            })
            .catch((err: HttpErrorResponse) => console.log('unable to retrieve colorData: ' + err.status));

        await lastValueFrom(this.edgeService.getEdges())
            .then((res) => {
                this.edgeOptions = res;
                if (this.returnQaConclusion.Edges) {
                    this.edgeOptions.forEach((edge) => {
                        edge.Selected = this.returnQaConclusion.Edges.some((e) => e.Id === edge.Id);
                    });
                }
            })
            .catch((err: HttpErrorResponse) => console.log('unable to retrieve EdgeData: ' + err.status));

        await lastValueFrom(this.surfaceService.getSurfaces())
            .then((res) => {
                this.surfaceOptions = res;
                if (this.returnQaConclusion.Surfaces) {
                    this.surfaceOptions.forEach((surface) => {
                        surface.Selected = this.returnQaConclusion.Surfaces.some((e) => e.Id === surface.Id);
                    });
                }
            })
            .catch((err: HttpErrorResponse) => console.log('unable to retrieve SurfaceData: ' + err.status));

        this.opticIsClearOptions = [
            {
                Id: 1,
                Name: this.translate.instant('general.yes'),
                Code: 'true',
                Selected: this.returnQaConclusion.IsOpticClear === true,
            },
            {
                Id: 2,
                Name: this.translate.instant('general.no'),
                Code: 'false',
                Selected: this.returnQaConclusion.IsOpticClear === false,
            },
            {
                Id: 3,
                Name: this.translate.instant('general.notApplicable'),
                Code: 'null',
                Selected: false,
            },
        ];

        this.productionErrorOptions = [
            {
                Id: 1,
                Name: this.translate.instant('general.yes'),
                Code: 'true',
                Selected: this.returnQaConclusion.IsProductionError === true,
            },
            {
                Id: 2,
                Name: this.translate.instant('general.no'),
                Code: 'false',
                Selected: this.returnQaConclusion.IsProductionError === false,
            },
        ];
    }

    fillSavedQaMeasurements() {
        const formGroupControls = this.measurementsFormGroup.controls;
        const powControl = formGroupControls[this.pow];
        const ozControl = formGroupControls[this.oz];
        const addControl = formGroupControls[this.add];
        const bcr2Control = formGroupControls[this.bcr2];
        const notchControl = formGroupControls[this.notch];
        const bcrControl = formGroupControls[this.bcr];
        const prismControl = formGroupControls[this.prism];
        const sagControl = formGroupControls[this.sag];
        const truncControl = formGroupControls[this.trunc];
        const diamControl = formGroupControls[this.diam];
        const axControl = formGroupControls[this.ax];
        const cmdControl = formGroupControls[this.cmd];
        const stabControl = formGroupControls[this.stab];
        const cylControl = formGroupControls[this.cyl];
        const readingSegmentControl = formGroupControls[this.readingSegment];
        const additionalControl = formGroupControls['additional'];
        const returnCategoryControl = formGroupControls['returnCategory'];
        const returnTypeControl = formGroupControls['returnType'];

        powControl.patchValue(this.returnQaConclusion.Pow);
        ozControl.patchValue(this.returnQaConclusion.Oz);
        addControl.patchValue(this.returnQaConclusion.Add);
        bcr2Control.patchValue(this.returnQaConclusion.Bcr2);
        notchControl.patchValue(this.returnQaConclusion.Notch);
        bcrControl.patchValue(this.returnQaConclusion.Bcr);
        prismControl.patchValue(this.returnQaConclusion.Prism);
        sagControl.patchValue(this.returnQaConclusion.Sag);
        truncControl.patchValue(this.returnQaConclusion.Trunc);
        diamControl.patchValue(this.returnQaConclusion.Diam);
        axControl.patchValue(this.returnQaConclusion.Ax);
        cmdControl.patchValue(this.returnQaConclusion.Cmd);
        stabControl.patchValue(this.returnQaConclusion.Stab);
        cylControl.patchValue(this.returnQaConclusion.Cyl);
        additionalControl.patchValue(this.returnQaConclusion?.Additional);
        readingSegmentControl.patchValue(this.returnQaConclusion?.ReadingSegment);
        returnCategoryControl.patchValue(this.selectedReturn?.ReturnCategoryId);
        returnTypeControl.patchValue(this.selectedReturn?.ReturnTypeId);
    }

    getOptionalRadioButtonValue(radioButtonControl: AbstractControl, isOptional = true): number {
        if (radioButtonControl.value !== null) {
            if (isOptional && radioButtonControl.value.Id > 0) {
                return radioButtonControl.value.Id;
            } else {
                if (radioButtonControl.value.Id !== 0) {
                    return radioButtonControl.value.Id;
                }
            }
        }

        return null;
    }

    getIsOpticalClearRadioButtonValue(): boolean {
        const isOpticClearControlValue = this.lensPropertyFormGroup.controls['opticIsClear'].value;

        if (isOpticClearControlValue != null) {
            return isOpticClearControlValue.Id === 1;
        }

        return null;
    }

    getIsProductionErrorRadioButtonValue(): boolean {
        const isProductionErrorControlValue = this.measurementsFormGroup.controls['productionError'].value;

        if (isProductionErrorControlValue != null) {
            return isProductionErrorControlValue.Id === 1;
        }

        return null;
    }

    saveRequest(completeRequest: boolean) {
        this.loaderService.show();
        const measurementsFG = this.measurementsFormGroup.controls;
        const lensPropertyFG = this.lensPropertyFormGroup.controls;

        this.returnQaConclusion.Pow = parseFloat(measurementsFG[this.pow].value);
        this.returnQaConclusion.Bcr = parseFloat(measurementsFG[this.bcr].value);
        this.returnQaConclusion.Diam = parseFloat(measurementsFG[this.diam].value);
        this.returnQaConclusion.Oz = parseFloat(measurementsFG[this.oz].value);
        this.returnQaConclusion.Cyl = parseFloat(measurementsFG[this.cyl].value);
        this.returnQaConclusion.Ax = parseFloat(measurementsFG[this.ax].value);
        this.returnQaConclusion.Add = parseFloat(measurementsFG[this.add].value);
        this.returnQaConclusion.Prism = parseFloat(measurementsFG[this.prism].value);
        this.returnQaConclusion.Cmd = parseFloat(measurementsFG[this.cmd].value);
        this.returnQaConclusion.Bcr2 = parseFloat(measurementsFG[this.bcr2].value);
        this.returnQaConclusion.Sag = parseFloat(measurementsFG[this.sag].value);
        this.returnQaConclusion.Stab = parseFloat(measurementsFG[this.stab].value);
        this.returnQaConclusion.Notch = measurementsFG[this.notch].value;
        this.returnQaConclusion.Trunc = measurementsFG[this.trunc].value;

        this.returnQaConclusion.ReturnId = this.selectedReturn.Id;
        this.returnQaConclusion.Username = this.userName;
        this.returnQaConclusion.Additional = measurementsFG['additional'].value;
        this.returnQaConclusion.ReadingSegment = measurementsFG[this.readingSegment].value;

        this.selectedReturn.ReturnCategoryId = measurementsFG['returnCategory'].value;
        this.selectedReturn.ReturnTypeId = measurementsFG['returnType'].value;

        this.returnQaConclusion.IsOpticClear =
            this.getIsOpticalClearRadioButtonValue() ?? this.returnQaConclusion.IsOpticClear;

        this.returnQaConclusion.IsProductionError =
            this.getIsProductionErrorRadioButtonValue() ?? this.returnQaConclusion.IsProductionError;

        this.returnQaConclusion.MaterialId =
            this.getOptionalRadioButtonValue(lensPropertyFG['material'], false) ?? this.returnQaConclusion.MaterialId;

        this.returnQaConclusion.ColorId =
            this.getOptionalRadioButtonValue(lensPropertyFG['color'], false) ?? this.returnQaConclusion.ColorId;

        this.returnQaConclusion.Surfaces = this.selectedSurfaces();
        this.returnQaConclusion.Edges = this.selectedEdges();

        this.fileUploadComponent.options.concurrency = 1;

        const formData: FormData = new FormData();
        formData.append('returnQaConclusion', JSON.stringify(this.returnQaConclusion));

        for (const file of this.fileUploadComponent.files) {
            formData.append(file.name, file.nativeFile, file.nativeFile.name);
        }
        this.qaService.save(formData).subscribe(
            () => {
                if (completeRequest) {
                    this.selectedReturn.ReturnStatusId = ServiceStatus.PsHandling;
                }
                lastValueFrom(this.returnService.saveServiceRequest(this.selectedReturn)).then();
                this.alertService.success(this.translateService.instant('general.saveSuccessful'));
                this.loaderService.hide();
                this.router.navigateByUrl(this.defaultSaveRedirectRoute, { skipLocationChange: true }).then(() => {
                    this.router.navigateByUrl(this.saveRedirectRoute);
                });
            },
            (error) => {
                this.alertService.error(this.translateService.instant(error.error.Message));
            },
        );
    }

    selectedEdges(): ListSelectOption[] {
        return this.lensPropertyFormGroup.controls['edges'].value
            ? this.lensPropertyFormGroup.controls['edges'].value
            : [];
    }

    selectedSurfaces(): ListSelectOption[] {
        return this.lensPropertyFormGroup.controls['surfaces'].value
            ? this.lensPropertyFormGroup.controls['surfaces'].value
            : [];
    }

    downloadAttachment(attachment: Attachment) {
        this.attachmentService.downloadAttachment(attachment.Id, attachment.MimeType).subscribe((result: Blob) => {
            Util.openBlobInBrowser(result, attachment.FileName);
        });
    }

    deleteAttachment(attachment: Attachment) {
        this.attachmentService.removeAttachment(attachment.Id).subscribe(
            () => {
                this.alertService.success(this.translateService.instant('general.removeSuccessful'));
                this.attachments = this.attachments.filter((a: Attachment) => a.Id !== attachment.Id);
            },
            (error) => {
                this.alertService.error(this.translateService.instant(error.error.Message));
            },
        );
    }
}
