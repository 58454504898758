import { TranslateService } from '@ngx-translate/core';
import { ConfirmService } from '@app/shared/appservices/confirm.service';
import { Injectable } from '@angular/core';
import { AppStateService } from '@app/shared/appservices/appState.service';

import { Observable, map, of, from } from 'rxjs';
import { EyeliteImportService } from '@app/professional-service/clientmaintenance/components/ps-eyeliteimport/eyeliteimport.service';

@Injectable()
export class EyeliteProcessChangesGuard {
    constructor(
        private readonly confirmService: ConfirmService,
        private readonly translateService: TranslateService,
        private readonly appState: AppStateService,
        private readonly eyeliteImportService: EyeliteImportService,
    ) {}

    public canDeactivate(): Observable<boolean> {
        if (!this.appState.authenticatedUser) {
            // user logged out, so just navigate away
            return of(true);
        }

        if (!this.appState.isPs && !this.appState.isDistributorSupport) {
            // user switched role, so just navigate away
            return of(true);
        }

        if (!this.eyeliteImportService.getEyeliteFileImport()) {
            // no eyelite file import, so just navigate away
            return of(true);
        }

        return this.askConfirmation().pipe(
            map((result) => {
                if (result) {
                    this.eyeliteImportService.clearEyeliteFileImport();
                }
                return result;
            }),
        );
    }

    private askConfirmation(): Observable<boolean> {
        return from(
            this.confirmService.show(
                this.translateService.instant('general.unprocessed-changes-title'),
                this.translateService.instant('general.unprocessed-changes-text'),
            ),
        );
    }
}
