<ng-container *ngIf="receipts$ | async; let receipts">
    <div class="container-fluid receiptstatuscontainer mt-4 px-lg-4 flex-fill">
        <div *ngIf="loading; else content"><div class="spinner-breceipt spinner-breceipt-sm m-auto"></div></div>

        <ng-template #content>
            <div class="title-breceipt-bottom mb-1">
                <h3 class="mt-auto">{{ 'receiptstatuspage.title' | translate }}</h3>
            </div>

            <div *ngIf="loading" class="spinner-breceipt spinner-breceipt-sm mx-1"></div>

            <div *ngIf="!loading" class="allReceipts pb-2">
                <div class="d-flex flex-column flex-md-row sticky-element">
                    <div class="me-2">
                        <h4>{{ 'receiptstatuspage.tableheader' | translate }}</h4>
                    </div>
                    <div class="ms-md-auto" *ngIf="receipts.length > 0">
                        <div class="d-inline-block">
                            <i class="fas fa-circle receipt-notordered fg"></i>
                            <span class="label ms-1 me-3">
                                {{ 'receiptstatus.notordered' | translate }}
                            </span>
                        </div>
                        <div class="d-inline-block">
                            <i class="fas fa-circle receipt-ordered fg"></i>
                            <span class="label ms-1 me-3">{{ 'receiptstatus.ordered' | translate }}</span>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters" *ngIf="receipts.length > 0">
                    <div
                        *ngFor="let receipt of receipts; let i = index"
                        class="col-12 col-sm-6 col-md-4 col-xl-3 px-sm-2 py-1 px-xl-3 py-xl-2">
                        <receipt-status-item
                            [id]="id + '-receipt-' + i"
                            [receipt]="receipt"
                            [isSelected]="selectedReceipt && selectedReceipt.Id === receipt.Id"
                            (receiptSelected$)="receiptSelected($event)"></receipt-status-item>
                    </div>
                </div>
                <div *ngIf="receipts.length === 0">{{ 'receiptstatuspage.noresults' | translate }}</div>
            </div>
        </ng-template>
    </div>

    <div class="container-fluid d-flex align-items-center px-lg-4 receiptstatusfilterbar" [formGroup]="formGroup">
        <div class="flex-fill">
            <div class="input-group search">
                <input
                    type="text"
                    class="form-control"
                    id="receiptstatus-search"
                    formControlName="search"
                    placeholder="Find a receipt with the client name, date or code" />
                <div class="input-group-append">
                    <div class="input-group-text breceipt-left-0">
                        <i class="fas fa-search"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="ms-3 ms-lg-4 d-none d-md-flex align-items-center sort">
            <div class="me-2 d-none d-lg-block">
                <h5 class="mb-0">{{ 'general.sort' | translate }}</h5>
            </div>
            <div class="btn-group w-100 dropup">
                <button
                    type="button"
                    id="receiptstatus-sort"
                    class="btn btn-secondary d-flex justify-content-between align-items-center dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    {{ currentSortingColumn | translate }}
                </button>
                <div class="w-100 dropdown-menu" aria-labelledby="receiptstatus-sort">
                    <div class="dropdown-item" id="sort-receiptreference" (click)="applySort($event, 'Reference')">
                        <i class="fas fa-check" *ngIf="currentSortingColumn === 'Reference'"></i>
                        {{ 'receipt.reference' | translate }}
                    </div>
                    <div class="dropdown-item" id="sort-receiptdate" (click)="applySort($event, 'IsCreatedOn')">
                        <i class="fas fa-check" *ngIf="currentSortingColumn === 'IsCreatedOn'"></i>
                        {{ 'general.date' | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="ms-3 ms-lg-4 d-none d-md-flex align-items-center">
            <mpc-select
                formControlName="selectedReceiptStatus"
                [id]="'selectedReceiptStatus'"
                [items]="receiptStatuses"
                label=""
                noSelectionLabel="select status"
                [labelWidth]="0"
                [focus]></mpc-select>
        </div>
    </div>
</ng-container>
<div *footer>
    <a [routerLink]="['/']">
        <i class="fas fa-arrow-alt-circle-left me-1"></i>
        {{ 'general.home' | translate }}
    </a>
</div>
