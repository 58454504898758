import { RefractionMeasurement } from './refractionmeasurement.model';

export class CalculateDreamLiteResidualRefractionRequest {
    public OpticianId: number;
    public LeftTopographicMeasurementId: number;
    public LeftRefractionMeasurement: RefractionMeasurement;
    public LeftMaterialColorId: number;
    public RightTopographicMeasurementId: number;
    public RightRefractionMeasurement: RefractionMeasurement;
    public RightMaterialColorId: number;
}
