import { Component, Input, ViewEncapsulation, Optional, Host, SkipSelf } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseControl } from '@app/shared/components/inputs/base-control';
import { ListSelectOption } from '@app/shared/models/listSelectOption.model';

@Component({
    selector: 'mpc-input-radio-list',
    styles: ['.flex-flow-row-wrap{ flex-flow: row wrap;}'],
    templateUrl: './input-radio-list.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputRadioListComponent,
            multi: true,
        },
    ],
})
export class InputRadioListComponent extends BaseControl {
    @Input() maxItemsFirstRow = 0;
    @Input() transkey = '';
    @Input() direction = 'horizontal'; // horizontal / vertical
    @Input() set options(options: ListSelectOption[]) {
        if (options) {
            this._options = structuredClone(options);
            this.matchOptions();
        }
    }

    _options: ListSelectOption[];
    directionClass: string;
    innerControlClass: string[];

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        public controlContainer: ControlContainer,
    ) {
        super(controlContainer);
    }

    writeValue(value: ListSelectOption): void {
        if (value) {
            this.value = structuredClone(value);
            this.matchOptions();
        }
    }

    matchOptions() {
        if (this.value && this._options) {
            const value = this.value as ListSelectOption;

            this._options = this._options.map(
                (o) =>
                    ({
                        Id: o.Id,
                        Code: o.Code,
                        Name: o.Name,
                        Selected: o.Id === value.Id,
                    }) as ListSelectOption,
            );

            this.value = this._options.find((o) => o.Selected);
        }
    }

    onSelect(item: ListSelectOption): void {
        if (item && this._options && this._options.length > 0) {
            const checkedOption = this._options.find((o) => o.Id === item.Id);
            checkedOption.Selected = !checkedOption.Selected;

            this._options.forEach((o) => {
                o.Selected = o.Id === item.Id && item.Selected;
            });

            this.value = this._options.find((o) => o.Selected);
            this.onChange(this.value);
        }

        this.onTouched();
    }

    refreshSubClasses(): void {
        this.controlClass = this.getControlClass();
        this.innerControlClass = this.getInnerControlClass();
        this.directionClass = this.getDirectionClass();
    }

    getControlClass(): string[] {
        const result = super.getControlClass();

        if (this.direction === 'horizontal') {
            result.push('d-flex');
        }

        return result;
    }

    getDirectionClass(): string {
        if (this.direction === 'horizontal') {
            if (this.maxItemsFirstRow > 0) {
                return 'd-flex flex-flow-row-wrap';
            } else {
                return 'd-flex';
            }
        }

        return '';
    }

    getInnerControlClass(): string[] {
        const classNames = [];

        if (this.direction === 'horizontal') {
            classNames.push('me-2');
        }

        return classNames;
    }
}
