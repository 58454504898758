<div
    *ngIf="receipt"
    class="d-flex orderstatusitem rounded"
    [ngClass]="[receipt.IsOrdered ? 'receipt-ordered' : 'receipt-notordered', isSelected ? 'selected' : '']"
    (click)="click(receipt)">
    <div class="statusbar"></div>
    <div class="flex-fill px-2 py-1 d-flex flex-column content">
        <div class="d-flex align-items-center">
            <div id="{{ id }}-title" class="text-truncate font-weight-bold me-1 order-title">
                <span *ngIf="receipt.Client">
                    {{ receipt.Client.Reference ?? receipt.Client.Reference2 }}
                </span>
            </div>
            <div class="ms-auto text-nowrap">
                <span id="{{ id }}-iscreatedon" class="date small">{{ receipt.IsCreatedOn | mpcdate }}</span>
            </div>
        </div>
        <div class="text-truncate">
            <div *ngIf="receipt.RightProductName">
                <span class="badge rounded-pill bg-secondary eyesidebadge">{{ 'general.od' | translate }}</span>
                &nbsp;
                <span id="{{ id }}-rightproduct">{{ receipt.RightProductName }}</span>
            </div>
            <div *ngIf="receipt.LeftProductName">
                <span class="badge rounded-pill bg-secondary eyesidebadge">{{ 'general.os' | translate }}</span>
                &nbsp;
                <span id="{{ id }}-leftproduct">{{ receipt.LeftProductName }}</span>
            </div>
        </div>
    </div>
</div>
