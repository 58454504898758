import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FittingService } from '@app/core/services/fitting.service';
import { Client, ListSelectOption } from '../shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ClientService } from '@app/core/services/api/client.service';
import { ClientSearchRequest } from '../shared/requestmodels';
import { Router } from '@angular/router';
import { Subscription, lastValueFrom } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'fitting-merge-client',
    templateUrl: 'fitting-merge-client.component.html',
})
export class FittingMergeClientComponent implements OnInit, OnDestroy {
    formGroup: UntypedFormGroup;

    @Input() mergeFromClient: Client;
    mergeIntoClient: Client;

    clientSearchResult = [];

    myopiaOptions: ListSelectOption[] = [
        new ListSelectOption(1, 'general.yes', ''),
        new ListSelectOption(0, 'general.no', ''),
    ];
    genderOptions: ListSelectOption[] = [
        new ListSelectOption(2, 'general.female', ''),
        new ListSelectOption(1, 'general.male', ''),
    ];

    searchFormChanged: Subscription;

    constructor(
        public fittingService: FittingService,
        public bsModalRef: BsModalRef,
        private readonly fb: UntypedFormBuilder,
        private readonly clientService: ClientService,
        private readonly router: Router,
    ) {}

    ngOnInit(): void {
        this.createForm();

        this.searchFormChanged = this.formGroup.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            this.search();
        });
    }

    ngOnDestroy(): void {
        this.searchFormChanged.unsubscribe();
    }

    createForm(): void {
        this.formGroup = this.fb.group({
            reference: ['', [Validators.required, Validators.maxLength(100)]],
            reference2: ['', Validators.maxLength(100)],
            birthDate: [[]],
            myopia: [[]],
            gender: [],
        });
    }

    search(): void {
        this.readForm();

        const clientSearchRequest = this.readForm();
        this.clientService.search(clientSearchRequest).subscribe((clients) => {
            this.clientSearchResult = clients;
        });
    }

    readForm(): ClientSearchRequest {
        const clientSearchRequest = new ClientSearchRequest();

        clientSearchRequest.Client = new Client();
        clientSearchRequest.Client.Reference = this.formGroup.controls['reference'].value;
        clientSearchRequest.Client.Reference2 = this.formGroup.controls['reference2'].value;
        clientSearchRequest.Client.BirthDateDay = this.formGroup.controls['birthDate'].value['day'];
        clientSearchRequest.Client.BirthDateMonth = this.formGroup.controls['birthDate'].value['month'];
        clientSearchRequest.Client.BirthDateYear = this.formGroup.controls['birthDate'].value['year'];
        clientSearchRequest.Client.Myopie =
            this.formGroup.controls['myopia'].value && this.formGroup.controls['myopia'].value.length > 0
                ? this.formGroup.controls['myopia'].value[0].Id
                : null;
        clientSearchRequest.Client.GenderId =
            this.formGroup.controls['gender'].value && this.formGroup.controls['gender'].value.length > 0
                ? this.formGroup.controls['gender'].value[0].Id
                : null;

        clientSearchRequest.CurrentPage = 0;
        clientSearchRequest.PageSize = 100;

        return clientSearchRequest;
    }

    onMergeTargetSelect(target: Client): void {
        this.mergeIntoClient = target;
    }

    async mergeClients(): Promise<void> {
        await lastValueFrom(this.clientService.mergeClients(this.mergeFromClient.Id, this.mergeIntoClient.Id));
        this.bsModalRef.hide();
        this.router.navigate(['/searchclient']).then();
    }
}
