<div class="flex-fill d-flex flex-column p-2">
    <div class="flex-fill d-flex flex-column flex-md-row justify-content-between lensconfiguration">
        <div class="mb-2" [ngClass]="lensConfigTabClass(lensConfigurationTabs.Spherical)">
            <h3 *ngIf="lensConfigTab === lensConfigurationTabs.Spherical">
                {{ 'general.spherical' | translate }}
            </h3>
            <a
                *ngIf="lensConfigTab !== lensConfigurationTabs.Spherical"
                href="#"
                id="lensconfig-spherical"
                (click)="clickLensConfigTab($event, lensConfigurationTabs.Spherical)">
                <h3>{{ 'general.spherical' | translate }}</h3>
            </a>
        </div>
        <div class="mb-2" [ngClass]="lensConfigTabClass(lensConfigurationTabs.Toric)">
            <h3 *ngIf="lensConfigTab === lensConfigurationTabs.Toric">
                {{ 'general.toric' | translate }}
            </h3>
            <a
                *ngIf="lensConfigTab !== lensConfigurationTabs.Toric"
                href="#"
                id="lensconfig-toric"
                (click)="clickLensConfigTab($event, lensConfigurationTabs.Toric)">
                <h3>{{ 'general.toric' | translate }}</h3>
            </a>
        </div>
        <div class="mb-2" [ngClass]="lensConfigTabClass(lensConfigurationTabs.MultiFocal)">
            <h3 *ngIf="lensConfigTab === lensConfigurationTabs.MultiFocal">
                {{ 'general.multifocal' | translate }}
            </h3>
            <a
                *ngIf="lensConfigTab !== lensConfigurationTabs.MultiFocal"
                href="#"
                id="lensconfig-multifocal"
                (click)="clickLensConfigTab($event, lensConfigurationTabs.MultiFocal)">
                <h3>{{ 'general.multifocal' | translate }}</h3>
            </a>
        </div>
        <div class="mb-2" [ngClass]="lensConfigTabClass(lensConfigurationTabs.MultiFocalToric)">
            <h3 *ngIf="lensConfigTab === lensConfigurationTabs.MultiFocalToric">
                {{ 'general.multifocaltoric' | translate }}
            </h3>
            <a
                *ngIf="lensConfigTab !== lensConfigurationTabs.MultiFocalToric"
                href="#"
                id="lensconfig-multifocaltoric"
                (click)="clickLensConfigTab($event, lensConfigurationTabs.MultiFocalToric)">
                <h3>{{ 'general.multifocaltoric' | translate }}</h3>
            </a>
        </div>
    </div>

    <div class="flex-fill">
        <div *ngIf="lensDefinitionsLoading" class="spinner-border spinner-border-sm m-2"></div>

        <ng-container *ngIf="!lensDefinitionsLoading">
            <div *ngFor="let ld of filteredLensDefinitions; let i = index">
                <div class="lensdefinition">
                    <div *ngIf="ld.IsStandardLens">
                        <span class="me-1">
                            <i class="fas fa-check ld-check"></i>
                        </span>
                        <span class="ld-name" id="ld-name-{{ i }}">{{ ld.Name }}</span>
                    </div>
                    <div *ngIf="!ld.IsStandardLens" class="form-check">
                        <input
                            type="checkbox"
                            id="ld-cb-{{ i }}"
                            class="form-check-input"
                            value="{{ ld.Id }}"
                            [checked]="isLensDefinitionChecked(ld)"
                            (change)="toggleLensDefinition($event, ld)" />
                        <label id="ld-name-{{ i }}" for="ld-cb-{{ i }}" class="form-check-label ld-name">
                            {{ ld.Name }}
                        </label>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
