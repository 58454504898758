<div class="flex-fill d-flex flex-column flex-sm-row">
    <div class="flex-sm-grow-1 p-4 searchclient-left">
        <div class="col-12 col-xl-10 offset-xl-2">
            <form [formGroup]="formGroup">
                <div class="title-border-bottom mb-2">
                    <h3>{{ 'searchclient.searchclients' | translate }}</h3>
                </div>
                <mpc-input-text
                    formControlName="reference"
                    [id]="'reference'"
                    label="general.references"></mpc-input-text>
                <mpc-input-date
                    formControlName="birthDate"
                    id="birthDate"
                    label="general.birthdate"
                    info="{{ 'general.birthdate.info' | translate }}"
                    infoPosition="right"
                    [allowIncompleteDate]="true"></mpc-input-date>
                <mpc-input-checkbox-list
                    formControlName="gender"
                    id="gender"
                    label="general.gender"
                    [options]="genders"
                    mode="single"
                    info="{{ 'general.gender.info' | translate }}"
                    infoPosition="right"></mpc-input-checkbox-list>
                <mpc-input-checkbox-list
                    *hasFeatureSetting="[
                        {
                            Feature: Features.Myopie,
                            Category: FeatureCategories.Optician,
                            IsEnabled: true
                        }
                    ]"
                    formControlName="myopia"
                    id="myopia"
                    label="general.myopia"
                    [options]="myopiaOptions"
                    mode="single"></mpc-input-checkbox-list>
                <div class="form-group row">
                    <div
                        class="col-12 col-lg-8"
                        *ngIf="formGroup.invalid"
                        popover="{{ 'searchclient.addnewclient.info' | translate }}"
                        triggers="mouseenter:mouseleave">
                        <ng-container *ngTemplateOutlet="addNewClientButton"></ng-container>
                    </div>
                    <div class="col-12 col-lg-8" *ngIf="!formGroup.invalid">
                        <ng-container *ngTemplateOutlet="addNewClientButton"></ng-container>
                    </div>
                    <ng-template #addNewClientButton>
                        <button
                            type="button"
                            id="addNewClient"
                            [disabled]="formGroup.invalid"
                            class="btn btn-block procornea-green"
                            [ngClass]="formGroup.invalid ? 'disabled' : ''"
                            (click)="addNewClient()">
                            <i class="fas fa-plus-circle"></i>
                            {{ 'searchclient.addnewclient' | translate }}
                        </button>
                    </ng-template>
                </div>
                <div class="form-group row">
                    <div class="col-12 col-lg-8">
                        <button
                            type="button"
                            id="manageClient"
                            class="btn procornea-green btn-block"
                            (click)="gotoClients()">
                            <i class="fas fa-wrench"></i>
                            {{ 'searchclient.manageclients' | translate }}
                        </button>

                        <div *ngIf="lensesForReplacement && lensNames" class="mt-2 lensesForReplacement">
                            <span class="badge rounded-pill bg-danger">
                                {{
                                    lensesForReplacementList.DREAMLITE +
                                        lensesForReplacementList.BYO +
                                        lensesForReplacementList.MiSight
                                }}
                            </span>
                            {{ 'searchclient.replacement.thereare' | translate }}
                            <a
                                *ngIf="lensesForReplacementList.DREAMLITE > 0"
                                [routerLink]="['/clients']"
                                [queryParams]="{ replacement: 'dreamlite' }">
                                {{ lensesForReplacementList.DREAMLITE }} {{ lensNames.dreamliteName }}
                            </a>
                            <span
                                *ngIf="
                                    lensesForReplacementList.DREAMLITE > 0 &&
                                    lensesForReplacementList.MiSight > 0 &&
                                    lensesForReplacementList.BYO === 0
                                ">
                                {{ 'searchclient.replacement.and' | translate }}
                            </span>
                            <span
                                *ngIf="
                                    lensesForReplacementList.MiSight > 0 &&
                                    lensesForReplacementList.MiSight > 0 &&
                                    lensesForReplacementList.BYO > 0
                                ">
                                ,
                            </span>
                            <a
                                *ngIf="lensesForReplacementList.MiSight > 0"
                                [routerLink]="['/clients']"
                                [queryParams]="{ replacement: 'MiSight' }">
                                {{ lensesForReplacementList.MiSight }} MiSight
                            </a>
                            <span *ngIf="lensesForReplacementList.DREAMLITE > 0 && lensesForReplacementList.BYO > 0">
                                {{ 'searchclient.replacement.and' | translate }}
                            </span>
                            <a
                                *ngIf="lensesForReplacementList.BYO > 0"
                                [routerLink]="['/clients']"
                                [queryParams]="{ replacement: 'byopremium' }">
                                {{ lensesForReplacementList.BYO }} {{ lensNames.byoName }}
                            </a>
                            {{ 'searchclient.replacement.eligibleforreplacement' | translate }}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="flex-sm-grow-1 p-4 d-flex flex-column accented-background searchclient-results">
        <div class="col-12 col-xl-10 flex-sm-grow-1 d-flex flex-column">
            <div class="d-flex title-border-bottom" [class.collapsed]="isSearchResultsCollapsed">
                <div>
                    <h3>{{ 'general.searchresults' | translate }}</h3>
                </div>
                <div
                    class="ms-auto"
                    (click)="isSearchResultsCollapsed = !isSearchResultsCollapsed"
                    [attr.aria-expanded]="!isSearchResultsCollapsed"
                    aria-controls="searchResults">
                    <span class="collapse-label pe-2" *ngIf="!isSearchResultsCollapsed">
                        {{ 'general.viewless' | translate }}
                    </span>
                    <span class="collapse-label pe-2" *ngIf="isSearchResultsCollapsed">
                        {{ 'general.viewmore' | translate }}
                    </span>
                    <i *ngIf="isSearchResultsCollapsed" class="fas fa-caret-down fa-2x fa-collapse-icon"></i>
                    <i *ngIf="!isSearchResultsCollapsed" class="fas fa-caret-up fa-2x fa-collapse-icon"></i>
                </div>
            </div>
            <div
                id="searchResults"
                [collapse]="isSearchResultsCollapsed"
                class="flex-grow-1"
                [ngClass]="searchResultsClass()">
                <div class="spinner-border spinner-border-sm my-2" *ngIf="isSearching()"></div>
                <div class="mt-2" *ngIf="!isSearching() && !hasSearchResults()">
                    <span *ngIf="isSearched" id="noclientsfound">
                        {{ 'searchclient.noclientsfound' | translate }}
                    </span>
                    <span *ngIf="!isSearched" class="default-message">
                        {{ 'searchclient.noclientsfoundyet' | translate }}
                    </span>
                </div>
                <div class="flex-sm-grow-1 d-flex pb-3">
                    <cdk-virtual-scroll-viewport autosize id="searchresults" class="searchresults w-100" itemSize="50">
                        <div
                            id="searchresult-{{ i }}"
                            class="clientsearchresult px-1 py-1 my-1 d-flex"
                            *cdkVirtualFor="let client of clientSearchDataSource; let i = index"
                            (click)="gotoClient(client)">
                            <div class="d-flex align-items-center me-3">
                                <div class="client-icon p-2 d-flex align-items-center">
                                    <i class="fas fa-user fa-2x mx-auto"></i>
                                </div>
                            </div>
                            <div *ngIf="client">
                                <span id="csr-reference-{{ i }}" class="csr-reference" *ngIf="client.Reference">
                                    <strong>{{ client.Reference }}</strong>
                                    <br />
                                </span>
                                <span id="csr-reference2-{{ i }}" class="csr-reference2" *ngIf="client.Reference2">
                                    {{ client.Reference2 }}
                                    <br />
                                </span>
                                <span id="csr-birthdate-{{ i }}" class="csr-birthdate" *ngIf="client.BirthDate">
                                    {{ client.BirthDate | mpcdate }}
                                </span>
                            </div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </div>
            </div>
            <div class="d-flex title-border-bottom">
                <div>
                    <h3>
                        {{ 'searchclient.recentlyopenedclients' | translate }}
                    </h3>
                </div>
                <div
                    class="ms-auto"
                    (click)="isRecentlyOpendClientsCollapsed = !isRecentlyOpendClientsCollapsed"
                    [attr.aria-expanded]="!isRecentlyOpendClientsCollapsed"
                    aria-controls="recentlyOpenedClients">
                    <span class="collapse-label pe-2" *ngIf="!isRecentlyOpendClientsCollapsed">
                        {{ 'general.viewless' | translate }}
                    </span>
                    <span class="collapse-label pe-2" *ngIf="isRecentlyOpendClientsCollapsed">
                        {{ 'general.viewmore' | translate }}
                    </span>
                    <i *ngIf="isRecentlyOpendClientsCollapsed" class="fas fa-caret-down fa-2x fa-collapse-icon"></i>
                    <i *ngIf="!isRecentlyOpendClientsCollapsed" class="fas fa-caret-up fa-2x fa-collapse-icon"></i>
                </div>
            </div>
            <div id="recentlyOpenedClients" [collapse]="isRecentlyOpendClientsCollapsed">
                <div class="spinner-border spinner-border-sm my-2" *ngIf="loadingRecentlyOpenedClients"></div>
                <div
                    id="recentlyopenedclients"
                    *ngIf="!loadingRecentlyOpenedClients && recentlyOpenedClients && recentlyOpenedClients.length > 0">
                    <div
                        id="roc-searchresult-{{ i }}"
                        class="recentlyopenedclientsresult px-1 py-1 my-1 d-flex"
                        *ngFor="let client of recentlyOpenedClients; let i = index"
                        (click)="gotoClient(client)">
                        <div class="d-flex align-items-center me-3">
                            <div class="client-icon p-2 d-flex align-items-center">
                                <i class="fas fa-user fa-2x mx-auto"></i>
                            </div>
                        </div>
                        <div>
                            <span id="roc-reference-{{ i }}" class="roc-reference" *ngIf="client.Reference">
                                <strong>{{ client.Reference }}</strong>
                                <br />
                            </span>
                            <span id="roc-reference2-{{ i }}" class="roc-reference2" *ngIf="client.Reference2">
                                {{ client.Reference2 }}
                                <br />
                            </span>
                            <span id="roc-birthdate-{{ i }}" class="roc-clientbirthdate">
                                {{ client.BirthDate | mpcdate }}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="
                        !loadingRecentlyOpenedClients && (!recentlyOpenedClients || recentlyOpenedClients.length === 0)
                    ">
                    {{ 'searchclient.norecentlyopenedclientsfound' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>

<div *footer>
    <a [routerLink]="['/']">
        <i class="fas fa-arrow-alt-circle-left me-1"></i>
        {{ 'general.home' | translate }}
    </a>
</div>
