import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FittedLensParameter, LensDefinitionParameterNumberRange } from '@app/shared/models';
import { InputNumberRange } from '@app/shared/models/input-number-range.model';
import { FittingEventService } from '@app/core/services/fittingEvent.service';
import { notchDataSheet } from '@app/fitlens/datasheet/notchDatasheet';
import { FittedLensChange } from '@app/fitlens/models/fittedLensChange.model';
import { LensNotchResult } from '@app/fitlens/models/lensNotchResult.model';
import { LensParameterChange } from '@app/fitlens/models/lensParameterChange.model';
import { EyeSides } from '@app/shared/enums';

@Component({
    selector: 'lens-notch',
    templateUrl: 'lens-notch.component.html',
    styleUrls: ['lens-notch.component.scss'],
})
export class LensNotchComponent implements OnInit, AfterViewInit, OnChanges {
    notchForm: FormGroup;
    changesSaved = true;
    hasNotch = false;

    @Input()
    public initialLensParameters: FittedLensParameter[];

    @Input()
    public eyeSide: string;

    @Input()
    public stabilizationIsDisabled = false;
    @Input()
    public stabilization: number;

    @Input()
    public isReadOnly = false;

    @Output()
    stabilizationChange: EventEmitter<number> = new EventEmitter();

    @Output()
    applyLensNotch: EventEmitter<LensNotchResult> = new EventEmitter<LensNotchResult>();

    @Output()
    applyLensNotchReadOnly: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('canvas', { static: false })
    public canvas: ElementRef<HTMLCanvasElement>;
    private context: CanvasRenderingContext2D & {
        roundRect?: (
            x: number,
            y: number,
            w: number,
            h: number,
            radii?: number | DOMPointInit | (number | DOMPointInit)[],
        ) => void;
    };

    positionRange: LensDefinitionParameterNumberRange[] = [];
    recessDepthRange: LensDefinitionParameterNumberRange[] = [];
    recessChordRange: LensDefinitionParameterNumberRange[] = [];
    stabilisationRanges = [new InputNumberRange(0, 180, 1)];

    constructor(
        private readonly fb: FormBuilder,
        private readonly fittingEventService: FittingEventService,
    ) {}

    ngOnInit(): void {
        this.isReadOnly = false;
        this.positionRange =
            <LensDefinitionParameterNumberRange[]>(
                this.initialLensParameters.find(
                    (flp) => flp.LensDefinitionParameter.ParameterType.Code == 'NOTCHPOSITION',
                )?.LensDefinitionParameter.LensDefinitionParameterRanges
            ) ?? [];
        this.recessDepthRange =
            <LensDefinitionParameterNumberRange[]>(
                this.initialLensParameters.find((flp) => flp.LensDefinitionParameter.ParameterType.Code == 'NOTCHDEPTH')
                    ?.LensDefinitionParameter.LensDefinitionParameterRanges
            ) ?? [];
        this.recessChordRange =
            <LensDefinitionParameterNumberRange[]>(
                this.initialLensParameters.find((flp) => flp.LensDefinitionParameter.ParameterType.Code == 'NOTCHCHORD')
                    ?.LensDefinitionParameter.LensDefinitionParameterRanges
            ) ?? [];

        const notch = {
            diameter: new FormControl<number>(this.getLensParameter(this.initialLensParameters, 'DIAM')),
            position: new FormControl<number>(this.getLensParameter(this.initialLensParameters, 'NOTCHPOSITION'), {
                validators: [
                    Validators.min(this.positionRange[0].Minimum),
                    Validators.max(this.positionRange[0].Maximum),
                ],
            }),
            recessDepth: new FormControl<number>(this.getLensParameter(this.initialLensParameters, 'NOTCHDEPTH'), {
                validators: [
                    Validators.min(this.recessDepthRange[0].Minimum),
                    Validators.max(this.recessDepthRange[0].Maximum),
                ],
            }),
            recessChord: new FormControl<number>(this.getLensParameter(this.initialLensParameters, 'NOTCHCHORD'), {
                validators: [
                    Validators.min(this.recessChordRange[0].Minimum),
                    Validators.max(this.recessChordRange[0].Maximum),
                ],
            }),
            stabilization: new FormControl(this.stabilization, [Validators.required]),
        };

        this.notchForm = this.fb.group(notch);
        this.hasNotch = this.checkHasNotch();

        Object.keys(this.notchForm.controls).forEach((key) => {
            this.notchForm.controls[key].valueChanges.subscribe((value) => {
                this.changesSaved = false;
                this.hasNotch = this.checkHasNotch();
                this.redrawCanvas();

                if (key === 'stabilization') {
                    this.stabilizationChange.emit(value);
                }
            });
        });

        this.fittingEventService.fittedLensChanged.subscribe((fittedLensChange: FittedLensChange) => {
            this.updateLensDiameter(fittedLensChange.newFittedLens.FittedLensParameters);
        });

        this.fittingEventService.lensParameterChanged.subscribe((fittedLensChange: LensParameterChange) => {
            this.updateLensDiameter(fittedLensChange.newFittedLens.FittedLensParameters);
        });
    }

    ngAfterViewInit(): void {
        this.context = this.canvas.nativeElement.getContext('2d');
        this.redrawCanvas();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.stabilization) {
            this.notchForm?.patchValue({ stabilization: changes.stabilization.currentValue });
        }
    }

    applyLensNotchParameters(): void {
        const lensNotchResult = new LensNotchResult();
        lensNotchResult.position = this.notchForm.controls['position'].value;
        lensNotchResult.recessChord = parseFloat(this.notchForm.controls['recessChord'].value);
        lensNotchResult.recessDepth = parseFloat(this.notchForm.controls['recessDepth'].value);

        this.applyLensNotchReadOnly.emit(this.isReadOnly);
        this.applyLensNotch.emit(lensNotchResult);
        this.changesSaved = true;
    }

    removeLensNotchParameters(): void {
        this.notchForm.controls['position'].patchValue(0, { emitEvent: false });
        this.notchForm.controls['recessChord'].patchValue(0, { emitEvent: false });
        this.notchForm.controls['recessDepth'].patchValue(0, { emitEvent: false });
        this.notchForm.controls['stabilization'].patchValue(0, { emitEvent: false });
        this.hasNotch = false;
        this.isReadOnly = false;
        this.applyLensNotchParameters();
        this.redrawCanvas();
    }

    private redrawCanvas(): void {
        const notchCanvasWidth = this.canvas.nativeElement.width;
        const notchCanvasHeight = this.canvas.nativeElement.height;

        // center position constants
        const centerXOffset = this.eyeSide.toLowerCase() === EyeSides[EyeSides.Os].toLowerCase() ? -22 : 22;
        const pixelsPerMM = 8;
        const position = 360 - this.notchForm.controls['position'].value;
        const diameter = parseFloat(this.notchForm.controls['diameter'].value);

        const cX = notchCanvasWidth / 2 + centerXOffset;
        const cY = notchCanvasHeight / 2 - 22;
        const cR = (diameter * pixelsPerMM) / 2;

        // clear canvas
        this.context.clearRect(0, 0, notchCanvasWidth, notchCanvasHeight);

        // guard
        if (!this.hasNotch) {
            return;
        }

        const openNotchAngleLens_A = this.calculateAndDrawNotch(diameter, position, pixelsPerMM, cR, cX, cY);

        const mainArcFirstStart = position - openNotchAngleLens_A;
        const mainArcFirstEnd = position - openNotchAngleLens_A / 2;
        const mainArcSecondStart = position + openNotchAngleLens_A / 2;
        const mainArcSecondEnd = mainArcFirstStart - 1;

        this.drawGapBetweenMainLensAndNotch(cX, cY, cR, mainArcFirstStart, mainArcFirstEnd);
        this.drawMainLens(cX, cY, cR, mainArcSecondStart, mainArcSecondEnd);
        this.drawStabilization(cX, cY, cR - 20);
    }

    private calculateAndDrawNotch(
        diameter: number,
        position: number,
        pixelsPerMM: number,
        cR: number,
        cX: number,
        cY: number,
    ) {
        const recessChord = parseFloat(this.notchForm.controls['recessChord'].value);
        const recessDepth = parseFloat(this.notchForm.controls['recessDepth'].value);

        let depthCorrection = 1;
        switch (recessDepth) {
            case 0.5:
                depthCorrection = 3;
                break;
            case 0.7:
                depthCorrection = 5;
                break;
        }

        const angleNotch = notchDataSheet[0][notchDataSheet[depthCorrection].indexOf(recessChord)];

        let chordCompensationValue = 0;
        switch (recessChord) {
            case 3:
                chordCompensationValue = 1;
                break;
            case 3.5:
                chordCompensationValue = 2;
                break;
        }

        let depthCompensationValue = 0;
        switch (recessDepth) {
            case 0.5:
                depthCompensationValue = 1;
                break;
            case 0.7:
                depthCompensationValue = 2;
                break;
        }

        const compensationForChordOnNotchA = (chordCompensationValue + 1) * 0.05 + 0.05;
        const compensationForDepthOnNotchA = depthCompensationValue * 0.07;
        const distanceFromCenter =
            diameter / 2 - recessDepth + angleNotch / 2 - compensationForChordOnNotchA + compensationForDepthOnNotchA;

        const distantanceFromCenterX = (distanceFromCenter * Math.cos((position / 180) * Math.PI) * 100) / 100;
        const distantanceFromCenterY = (distanceFromCenter * Math.sin((position / 180) * Math.PI) * 100) / 100;

        const openNotchAngleLens_A = Math.round(
            ((Math.asin((recessChord * pixelsPerMM) / 2 / cR) * 180) / Math.PI) * 2,
        );

        const openNotchAngleFromNotch_A =
            ((Math.asin((recessChord * pixelsPerMM) / 2 / (angleNotch * pixelsPerMM)) * 180) / Math.PI) * 2;

        const positionFromNotch_A = position - 180 < 0 ? 360 - Math.abs(position - 180) : position - 180;

        // notch
        this.context.beginPath();
        this.context.arc(
            cX + distantanceFromCenterX * pixelsPerMM,
            cY + distantanceFromCenterY * pixelsPerMM,
            (angleNotch / 2) * pixelsPerMM,
            ((positionFromNotch_A - openNotchAngleFromNotch_A) / 180) * Math.PI,
            ((positionFromNotch_A + openNotchAngleFromNotch_A) / 180) * Math.PI,
        );
        this.context.strokeStyle = '#000';
        this.context.lineWidth = 1.4;
        this.context.stroke();

        return openNotchAngleLens_A;
    }

    private drawGapBetweenMainLensAndNotch(
        cX: number,
        cY: number,
        cR: number,
        mainArcFirstStart: number,
        mainArcFirstEnd: number,
    ) {
        this.context.beginPath();
        this.context.strokeStyle = '000';
        this.context.lineWidth = 1.4;
        this.context.arc(cX, cY, cR, mainArcFirstStart / 180 + 1 * Math.PI, (mainArcFirstEnd / 180) * Math.PI);
        this.context.stroke();
    }

    private drawMainLens(cX: number, cY: number, cR: number, mainArcSecondStart: number, mainArcSecondEnd: number) {
        this.context.beginPath();
        this.context.strokeStyle = '000';
        this.context.lineWidth = 1.4;
        this.context.arc(cX, cY, cR, (mainArcSecondStart / 180) * Math.PI, (mainArcSecondEnd / 180) * Math.PI);
        this.context.stroke();
        this.context.save();
    }

    private drawStabilization(cX: number, cY: number, cR: number) {
        this.context.beginPath();
        this.context.arc(cX, cY, cR, 0, 2 * Math.PI);
        this.context.save();

        this.context.translate(cX, cY);
        this.context.rotate(-((this.stabilization / 180) * Math.PI));

        const rectWidth = 24;
        const rectHeight = 4;
        const rectHalfWidth = rectWidth / 2;
        const rectY = rectHeight / 2;
        const borderRadius = 5;

        this.context.beginPath();
        this.context.fillStyle = '#0095ad';
        this.context.strokeStyle = '#000';
        this.context.lineWidth = 2;

        this.drawRoundRect(cR - rectHalfWidth, -rectY, rectWidth, rectHeight, borderRadius);
        this.drawRoundRect(-cR - rectHalfWidth, -rectY, rectWidth, rectHeight, borderRadius);

        this.context.restore();
    }

    private drawRoundRect(x: number, y: number, w: number, h: number, r: number) {
        this.context.beginPath();
        this.context.roundRect(x, y, w, h, r);
        this.context.stroke();
        this.context.fill();
        this.context.closePath();
    }

    private updateLensDiameter(flp: FittedLensParameter[]): void {
        this.notchForm.controls['diameter'].patchValue(this.getLensParameter(flp, 'DIAM'));
    }

    private getLensParameter(flp: FittedLensParameter[], code: string): number {
        const parameter = flp.find(
            (flp: FittedLensParameter) => flp.LensDefinitionParameter.ParameterType.Code === code,
        );

        const parameterRanges = <LensDefinitionParameterNumberRange[]>(
            parameter?.LensDefinitionParameter.LensDefinitionParameterRanges
        );

        if (parameter?.Value > parameterRanges[0].Maximum || parameter?.Value < parameterRanges[0].Minimum) {
            return undefined;
        }

        return parameter?.Value && parameter.Value != 0.0 ? parameter.Value : 0;
    }

    private checkHasNotch(): boolean {
        return (
            this.notchForm.controls['diameter'].value !== 0 &&
            this.notchForm.controls['recessChord'].value !== 0 &&
            this.notchForm.controls['recessDepth'].value !== 0
        );
    }

    setLensNotchReadOnlyAndApplyNotch() {
        this.isReadOnly = !this.isReadOnly;
        this.applyLensNotchParameters();
    }
}
