import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { ChangePassword } from '@app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from '@app/shared/appservices/alert.service';
import { UtilService } from '@app/shared/appservices/util.service';
import { AccountService } from '@app/core/services/api/account.service';

interface PasswordFormGroup {
    password: FormControl<string | null>;
    passwordNew: FormControl<string | null>;
    passwordRepeat: FormControl<string | null>;
}

@Component({
    selector: 'password-settings',
    templateUrl: 'password-settings.component.html',
    styleUrls: ['password-settings.component.scss'],
})
export class PasswordSettingsComponent implements OnInit {
    formGroupPassword: FormGroup<PasswordFormGroup>;

    passwordUpdateError: string;
    passwordExpiryMessage: string;

    showPasswords = false;
    passwordLoading = false;

    constructor(
        private readonly appState: AppStateService,
        private readonly translate: TranslateService,
        private readonly alertService: AlertService,
        private readonly utilService: UtilService,
        private readonly accountService: AccountService,
    ) {}

    ngOnInit(): void {
        this.createPasswordForm();
        this.checkPasswordExpiration();
    }

    createPasswordForm(): void {
        const passwordValidationRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(.{8,50})/;

        this.formGroupPassword = new FormGroup<PasswordFormGroup>({
            password: new FormControl('', [Validators.pattern(passwordValidationRegex)]),
            passwordNew: new FormControl('', [Validators.pattern(passwordValidationRegex)]),
            passwordRepeat: new FormControl('', [Validators.pattern(passwordValidationRegex)]),
        });

        this.formGroupPassword.addValidators(
            this.matchValidator(
                this.formGroupPassword.get('passwordNew'),
                this.formGroupPassword.get('passwordRepeat'),
            ),
        );

        this.formGroupPassword.addValidators(
            this.noMatchValidator(this.formGroupPassword.get('password'), this.formGroupPassword.get('passwordNew')),
        );
    }

    changePassword(): void {
        this.passwordLoading = true;

        const changePassword = new ChangePassword();
        changePassword.UserId = this.appState.authenticatedUser.UserId;
        changePassword.CurrentPassword = this.formGroupPassword.get('password').value;
        changePassword.NewPassword = this.formGroupPassword.get('passwordNew').value;
        changePassword.RepeatNewPassword = this.formGroupPassword.get('passwordRepeat').value;

        this.accountService
            .changePassword(changePassword)
            .pipe(
                catchError((e) => {
                    if (e.status === 400) {
                        const errors = this.utilService.getServerErrors(e);
                        if (errors && errors.length > 0) {
                            errors.forEach((errorMessage: string) => {
                                this.passwordUpdateError = errorMessage;
                            });
                        } else {
                            this.passwordUpdateError = this.translate.instant('settings.passwordsavefail');
                        }
                        this.passwordLoading = false;
                        return EMPTY;
                    }
                    this.passwordLoading = false;
                    return throwError(e);
                }),
            )
            .subscribe(() => {
                this.alertService.success(this.translate.instant('general.saveSuccessful'));
                this.passwordLoading = false;
            });
    }

    private checkPasswordExpiration(): void {
        this.passwordExpiryMessage = '';
        if (this.appState.passwordExpiryInfo && this.appState.passwordExpiryInfo.PasswordNeverExpires === false) {
            if (this.appState.passwordExpiryInfo.DaysUntilExpiry <= 0) {
                this.passwordExpiryMessage = this.translate.instant('passwordExpiry.isExpired', {
                    daysUntilExpiry: this.appState.passwordExpiryInfo.DaysUntilExpiry,
                });
            } else if (this.appState.passwordExpiryInfo.DaysUntilExpiry <= 14) {
                this.passwordExpiryMessage = this.translate.instant('passwordExpiry.willExpire', {
                    daysUntilExpiry: this.appState.passwordExpiryInfo.DaysUntilExpiry,
                });
            }
        }
    }

    private matchValidator(control: AbstractControl, controlTwo: AbstractControl): ValidatorFn {
        return () => {
            return control.value !== controlTwo.value
                ? { matching_error: this.translate.instant('changepassword.passwordsdonotmatch') }
                : null;
        };
    }

    private noMatchValidator(control: AbstractControl, controlTwo: AbstractControl): ValidatorFn {
        return () => {
            return control.value === controlTwo.value && control.value
                ? { matching_error: this.translate.instant('changePassword.passwordCannotBeTheSame') }
                : null;
        };
    }
}
