export class OrderLineInfo {
    Id: number;
    SerialNumbers: string;
    OrderId: number;
    OpticianId: number;
    ClientId: number;
    OrderDate: Date;
    EyeSideId: number;
    EyeSideCode: number;
    ProductName: string;
    MaterialId: number;
    MaterialName: string;
    ColorName: string;
    MaterialAndColorName: string;
    LensTypeId: number;
    OrderLineStatusId: number;
    Quantity: number;
    InvoicedDate: Date;
    ShippedDate: Date;
    FixTypeName: string;
    LensEngravingName: string;
    ReturnIds: number[];

    Pow: number;
    Add: number;
    Prof: number;
    Cyl: number;
    Ax: number;
    Rad: number;
    Arad: number;
    Rc: number;
    Ac: number;
    Rc2: number;
    Ac2: number;
    Diam: number;
    Rzd1Scrn: number;
    Rzd2Scrn: number;
    Lza1Scrn: number;
    Lza2Scrn: number;
    Stab: number;
    Pris: number;
    Oz: number;
    Diamdist: number;
    Ecc: number;
    Aecc: number;

    TrackAndTraceLink: string;
    WarrantyExchangeAllowed: boolean;
    IsWarrantyApplied: boolean;
    IsSpare: boolean;
}
