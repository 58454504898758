import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleAuthGuard } from '@app/core/guards';
import { Roles } from '@app/shared/enums';
import { HomeComponent } from './pages/home/home.component';
import { TranslationsComponent } from './pages/translations/translations.component';

const routes: Routes = [
    {
        path: '',
        children: [
            { path: '', component: HomeComponent },
            { path: 'translations', component: TranslationsComponent },
        ],
        canActivate: [RoleAuthGuard],
        data: {
            roles: [Roles.Translator, Roles.Administrator],
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [],
})
export class TranslatorRoutingModule {}
