import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FittingService } from '@app/core/services/fitting.service';

@Component({
    selector: 'fitting-process',
    templateUrl: 'fitting.component.html',
    styleUrls: ['fitting.component.scss'],
})
export class FittingComponent implements OnInit {
    constructor(
        private readonly fittingService: FittingService,
        public location: Location,
    ) {}

    ngOnInit(): void {
        // this ensures the right step is set when the browser back or forward buttons are used
        this.location.subscribe((result) => {
            this.fittingService.setFittingStep(result.url);
        });
    }

    onRouterOutletActivate() {
        // Each time a new component is placed in the router-outlet it will be scrolled to top
        window.scrollTo(0, 0);
    }
}
