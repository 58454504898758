import { HttpParams } from '@angular/common/http';

export class HttpUtil {
    public static mapObjectToHttpParams(obj: unknown, recursive = false, params?: HttpParams): HttpParams {
        params = params ?? new HttpParams();

        for (const key of Object.keys(obj)) {
            const value = obj[key];

            if (recursive && value instanceof Object) {
                params = this.mapObjectToHttpParams(value, true, params);
            } else if (value !== null && value !== undefined && Number.isNaN(value) === false) {
                params = this.appendValueToParams(params, key, value);
            }
        }

        return params;
    }

    private static appendValueToParams(params: HttpParams, key: string, value: unknown): HttpParams {
        if (Array.isArray(value)) {
            value.forEach((item) => {
                params = params.append(key, item.toString());
            });
            return params;
        } else if (value instanceof Date) {
            return params.append(key, this.convertToISOString(value));
        } else {
            return params.append(key, value.toString());
        }
    }

    private static convertToISOString(date: Date): string {
        return date.toISOString();
    }
}
