<div class="d-flex justify-content-center" *ngIf="loading">
    <div class="spinner-border spinner-border-sm"></div>
</div>

<div class="w-100 p-4" *ngIf="!loading">
    <div class="title-border-bottom">
        <h1>{{ 'ps.attachments' | translate }}</h1>
    </div>

    <div class="col-12 mt-4 px-0">
        <h3>{{ 'ps.externalattachments' | translate }}</h3>

        <div *ngIf="attachments" class="row px-1">
            <div *ngFor="let attach of externalAttachments" class="col-6 col-md-4 col-lg-3 p-2">
                <div class="accented-background rounded p-2">
                    <div class="w-100">
                        <h4>{{ attach.FileName }}</h4>
                        <br />
                        {{ attach.UploadedOn | mpcdate }}
                    </div>
                    <div class="w-100">
                        <img alt="attachment" [src]="attach.ImageData" />
                    </div>
                </div>
                <div class="w-100">
                    <div class="btn btn-default" (click)="downloadAttachment(attach)">
                        {{ 'general.download' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr />

    <div class="col-12 px-0">
        <h3>{{ 'ps.internalattachments' | translate }}</h3>

        <div *ngIf="attachments" class="row px-1">
            <div *ngFor="let attach of internalAttachments" class="col-6 col-md-4 col-lg-3 p-2">
                <div class="accented-background rounded p-2">
                    <div class="w-100">
                        <h4>{{ attach.FileName }}</h4>
                        <br />
                        {{ attach.UploadedOn | mpcdate }}
                    </div>
                    <div class="w-100">
                        <img alt="attachment" [src]="attach.ImageData" />
                    </div>
                </div>
                <div class="w-100">
                    <div class="btn btn-default" (click)="downloadAttachment(attach)">
                        {{ 'general.download' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr />

    <div class="col-12 mt-4 px-0">
        <div class="d-flex flex-grow-1 p-2 fileupload-wrapper">
            <file-upload
                #fileUploader
                [showUploadButton]="true"
                (uploadButtonPressed)="uploadFiles($event)"
                (uploadComplete)="uploadComplete()"></file-upload>
        </div>
    </div>
</div>
