import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartOrderComponent, CartOverviewComponent } from '@app/features/cart/pages';

const routes: Routes = [
    {
        path: '',
        children: [
            { path: '', component: CartOverviewComponent },
            { path: 'order', component: CartOrderComponent },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CartRoutingModule {}
