import { Component, Input, ViewEncapsulation, Optional, Host, SkipSelf } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseControl } from '@app/shared/components/inputs/base-control';

@Component({
    selector: 'mpc-input-slider',
    templateUrl: './input-slider.component.html',
    styleUrls: ['./input-slider.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputSliderComponent,
            multi: true,
        },
    ],
})
export class InputSliderComponent extends BaseControl {
    @Input() offset = true;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        public controlContainer: ControlContainer,
    ) {
        super(controlContainer);
    }

    writeValue(value: boolean): void {
        this.value = value || false;
    }

    propagateChange(): void {
        this.value = !this.value;
        this.onChange(this.value);
        this.onTouched();
    }
}
