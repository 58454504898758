import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nullWithDefault',
})
export class NullWithDefaultPipe implements PipeTransform {
    transform(value: unknown, defaultText = 'N/A'): unknown {
        if (typeof value === 'undefined' || value === null) {
            return defaultText;
        }

        return value;
    }
}
