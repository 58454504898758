export class ClientInfoQueryCriteria {
    FilterByFields = {
        LensTypeId: '',
        ProductGroupCode: '',
    };
    OrderByField = '';
    OrderByAscending = true;
    PageIndex = 0;
    PageSize = 50;
    TotalItems = 0;
    UsePaging = true;
    StartDate: string;

    clear() {
        for (const key in this.FilterByFields) {
            if (key !== 'LensTypeId' && (this.FilterByFields[key] === '' || this.FilterByFields[key] === 'null')) {
                delete this.FilterByFields[key];
            }
        }
    }
}
