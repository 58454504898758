import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FittedLensOverrefraction } from '@app/shared/models/fittedLensOverRefraction.model';
import {
    FittedLens,
    RecalculateFieldValueExpressions,
    CreateFittedLensBasedOnPreviousOrderRequest,
} from '@app/shared/models';
import { DreamLiteFittedLensInfo } from '@app/professional-service/models/DreamLiteFittedLensInfo.model';
import { ChangeLensTypeResponse } from '@app/shared/models/changeLensTypeResponse.model';
import { LensTypes } from '@app/shared/enums';
import { OverrefractionParameter } from '@app/shared/models/overRefractionParameter.model';

@Injectable({
    providedIn: 'root',
})
export class FittedLensService {
    private readonly basePath = 'api/fitted-lenses';

    constructor(private readonly httpClient: HttpClient) {}

    public changeLensType(
        leftFittedLens: FittedLens,
        rightFittedLens: FittedLens,
        lensTypeId: LensTypes,
    ): Observable<ChangeLensTypeResponse> {
        const body = {
            leftFittedLens,
            rightFittedLens,
            lensTypeId,
        };

        return this.httpClient.post<ChangeLensTypeResponse>(`${this.basePath}/change-lens-type`, body);
    }

    public changeLensDefinitionInFittedLens(
        lensDefinitionId: number,
        proposalTypeId: number,
        fittedLens: FittedLens,
    ): Observable<FittedLens> {
        const options = {
            params: new HttpParams()
                .set('lensDefinitionId', lensDefinitionId.toString())
                .set('proposalTypeId', proposalTypeId.toString()),
        };

        return this.httpClient.post<FittedLens>(
            `${this.basePath}/change-lensdefinition-in-fitted-lens`,
            fittedLens,
            options,
        );
    }

    public recalculateResidualRefraction(fittedLens: FittedLens): Observable<FittedLens> {
        return this.httpClient.post<FittedLens>(`${this.basePath}/recalculate-residual-refraction`, fittedLens);
    }

    public createFittedLens(lensDefinitionId: number): Observable<FittedLens> {
        const options = {
            params: new HttpParams().set('lensDefinitionId', lensDefinitionId),
        };

        return this.httpClient.post<FittedLens>(`${this.basePath}/create`, null, options);
    }

    public calculateOverrefraction(fittedLensOverrefraction: FittedLensOverrefraction): Observable<FittedLens> {
        return this.httpClient.post<FittedLens>(`${this.basePath}/calculate-over-refraction`, fittedLensOverrefraction);
    }

    public getFromOrders(rightOrderId: number, leftOrderId: number) {
        let httpParams = new HttpParams();
        if (rightOrderId) {
            httpParams = httpParams.set('rightOrderId', rightOrderId.toString());
        }

        if (leftOrderId) {
            httpParams = httpParams.set('leftOrderId', leftOrderId.toString());
        }

        const options = {
            params: httpParams,
        };

        return this.httpClient.get<FittedLens[]>(`${this.basePath}/from-orders`, options);
    }

    public getDreamLiteFittedLenses(eyeSideId: number, clientId: number): Observable<DreamLiteFittedLensInfo[]> {
        const options = {
            params: new HttpParams()
                .set('eyeSideId', eyeSideId)
                .set('clientId', clientId)
                .set('includeFictionalLenses', 'true'),
        };

        return this.httpClient.get<DreamLiteFittedLensInfo[]>(`${this.basePath}/dream-lite`, options);
    }

    public recalculateFieldValueExpressions(
        fittedLens: FittedLens,
        changedParameterCodes: string[],
    ): Observable<FittedLens> {
        // Should this be a interface instead of a class?
        const model = new RecalculateFieldValueExpressions();
        model.FittedLensModel = fittedLens;
        model.ChangedParameterCodes = changedParameterCodes;

        return this.httpClient.post<FittedLens>(`${this.basePath}/recalculate-field-value-expression`, model);
    }

    public deleteFittedLensConcept(fittedLensId: number): Observable<void> {
        return this.httpClient.delete<void>(`${this.basePath}/${fittedLensId}/concept`);
    }

    public getFittedLens(fittedLensId: number): Observable<FittedLens> {
        return this.httpClient.get<FittedLens>(`${this.basePath}/${fittedLensId}`);
    }

    public createConceptOfFittedLens(fittedLens: FittedLens, conceptOfFittedLensId: number): Observable<FittedLens> {
        const body = {
            fittedLens,
            conceptOfFittedLensId,
        };

        return this.httpClient.post<FittedLens>(`${this.basePath}/concept`, body);
    }

    public recalculateFittedLensUsingBaseLineMeasurements(fittedLensId: number): Observable<FittedLens> {
        return this.httpClient.post<FittedLens>(
            `${this.basePath}/recalculate-fitted-lens-using-baseline-measurements`,
            fittedLensId,
        );
    }

    public createFittedLensBasedOnPreviousOrder(
        requestModel: CreateFittedLensBasedOnPreviousOrderRequest,
    ): Observable<FittedLens> {
        return this.httpClient.post<FittedLens>(`${this.basePath}/create-by-previous-order`, requestModel);
    }

    public getOverrefractionParameters(): Observable<OverrefractionParameter[]> {
        return this.httpClient.get<OverrefractionParameter[]>(`${this.basePath}/over-refraction-parameters`);
    }
}
