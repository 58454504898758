<div class="modal-header" *ngIf="title">
    <h4 class="modal-title m-auto" id="confirmtitle">{{ title }}</h4>
</div>
<div class="modal-body text-center">
    <p id="confirmmessage">{{ message }}</p>
</div>
<div class="modal-footer accented-background w-100 justify-content-center">
    <div>
        <button type="button" id="confirmYes" class="btn btn-primary" (click)="confirm()">
            {{ 'general.yes' | translate }}
        </button>
        <button type="button" id="confirmNo" class="btn btn-default ms-1" (click)="decline()">
            {{ 'general.no' | translate }}
        </button>
    </div>
</div>
