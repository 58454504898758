import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TopographicMeasurement, RefractionMeasurement, ListOption, Client } from '@app/shared/models';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { MeasurementReviewDialogComponent } from './measurement-review-dialog.component';
import { ImageOptions } from '@app/shared/models/image-options.model';
import { TopographicMeasurementService } from '@app/core/services/api/topographic-measurement.service';

@Component({
    selector: 'measurement-item',
    templateUrl: 'measurement-item.component.html',
    styleUrls: ['measurement-item.component.scss'],
})
export class MeasurementItemComponent {
    @Input() id: string;
    @Input() measurement: TopographicMeasurement;
    @Input() refractionMeasurement: RefractionMeasurement;
    @Input() showRefractionData = false;
    @Input() isSelected = false;
    @Input() inDeleteMode = false;
    @Input() imageOptions: ImageOptions;
    @Input() measurements: Array<TopographicMeasurement>;
    @Input() displayBaselineTag = false;
    @Input() client: Client;

    @Output() measurementClicked$: EventEmitter<TopographicMeasurement> = new EventEmitter();
    @Output() measurementDeleted$: EventEmitter<TopographicMeasurement> = new EventEmitter();

    @Input() visualAcuityCorrectoreValues: ListOption[];

    constructor(
        private readonly topographicMeasurementService: TopographicMeasurementService,
        private readonly loaderService: LoaderService,
        private readonly modalService: BsModalService,
    ) {
        if (!this.imageOptions) {
            this.imageOptions = new ImageOptions(null);
            this.imageOptions.ImageChoice = 'current';
        }
    }

    measurementItemClass(): string[] {
        const result: string[] = [];
        if (this.isSelected) {
            result.push('selected');
        }
        if (this.inDeleteMode) {
            result.push('inDeleteMode');
        }

        return result;
    }

    getVisualAcuityCorrectoreValue(id: number): string {
        const value = this.visualAcuityCorrectoreValues.find((v) => v.Id === id);
        return value ? value.Name : '';
    }

    deleteMeasurement(): void {
        this.measurementDeleted$.emit(this.measurement);

        this.loaderService.show();
        this.topographicMeasurementService.deleteMeasurements([this.measurement.Id]).subscribe(() => {
            this.measurementDeleted$.emit(this.measurement);
            this.loaderService.hide();
        });
    }

    clickMeasurementImage(event: MouseEvent): void {
        this.openReviewMeasurementDialog();
        event.stopPropagation();
    }

    clickMeasurement(): void {
        this.measurementClicked$.emit(this.measurement);
    }

    openReviewMeasurementDialog(): void {
        const imageOptions = structuredClone(this.imageOptions);
        imageOptions.ShowImageChoice = true;
        imageOptions.ShowImageTypes = false;

        const options: unknown = {
            initialState: {
                currentMeasurement: this.measurement,
                refractionMeasurement: this.refractionMeasurement,
                topoMeasurements: this.measurements,
                imageOptions: imageOptions,
                client: this.client,
            },
            class: 'measurement-review-dialog',
        };

        this.modalService.show(MeasurementReviewDialogComponent, options);
    }
}
