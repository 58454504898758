<div [formGroup]="formGroupAccountSettings" class="content-section section-accountsettings rounded mt-2">
    <div class="formgroup mt-2 mb-3">
        <mpc-select
            *ngIf="appState.authenticatedUser.Roles.length > 1"
            formControlName="role"
            [id]="'role'"
            [translationKey]="'settings.roles.'"
            [items]="roles"
            label="settings.role"></mpc-select>

        <mpc-select
            *ngIf="showDistributors"
            formControlName="distributor"
            [id]="'distributor'"
            [items]="distributors"
            label="general.distributor"></mpc-select>

        <div *ngIf="showCurrentOptician" class="form-group row">
            <div class="col-form-label col-md-4 my-auto">
                <label for="currentOptician" [ngClass]="currentOpticianHasErrorsClass">
                    {{ 'settings.currentoptician' | translate }}
                </label>
            </div>
            <div class="col-md-8">
                <input
                    #opticanTypeAheadInput
                    id="currentOptician"
                    formControlName="currentOptician"
                    [typeahead]="opticians"
                    (typeaheadOnSelect)="setOptician($event)"
                    [typeaheadOptionsLimit]="4"
                    class="form-control"
                    typeaheadOptionField="Name" />
            </div>
        </div>

        <mpc-select
            formControlName="language"
            [id]="'language'"
            [items]="languages"
            label="settings.language"></mpc-select>

        <div *ngIf="showCurrentOptician && !!agreementLicense" class="form-group row">
            <div class="col-form-label col-md-4 my-auto">
                <a href="{{ agreementLicense.FilePath }}" target="_blank" rel="noopener noreferrer">
                    {{ 'general.agreementLicense' | translate }}
                </a>
            </div>
            <div class="col-md-8 my-auto">
                <span>
                    {{ 'general.signedOn' | translate }}
                    {{ formGroupAccountSettings.controls['licenseAgreement'].value | mpcdate }}
                </span>
            </div>
        </div>

        <div
            *hasFeatureSetting="[
                {
                    Feature: Features.IsTrainingToOrderRequired,
                    Category: FeatureCategories.Distributor,
                    IsEnabled: true
                }
            ]">
            <div *ngIf="showCurrentOptician && authenticatedUser.TrainingCompleteApprovedOn" class="form-group row">
                <div class="col-form-label col-md-4 my-auto">
                    {{ 'settings.trainingCompleteApprovedOn' | translate }}
                </div>
                <div class="col-md-8 my-auto">
                    <span>
                        {{ 'settings.confirmedOn' | translate }}:
                        {{ formGroupAccountSettings.controls['trainingCompleteApprovedOn'].value | mpcdate }}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex">
        <div class="me-auto"></div>
        <div *ngIf="accountSettingsLoading" class="spinner-border spinner-border-sm m-2 align-self-start"></div>
        <div>
            <button
                type="button"
                id="saveUserSettings"
                class="btn btn-success"
                [disabled]="!formGroupAccountSettings || formGroupAccountSettings.invalid || accountSettingsLoading"
                (click)="saveUserSettings()">
                {{ 'general.save' | translate }}
            </button>
        </div>
    </div>
</div>
