import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AppStateService } from '@app/shared/appservices/appState.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { Post } from '@app/shared/models/post.model';
import { WordpressApiService } from '@app/core/services/external/wordpress-api.service';

@Component({
    selector: 'release-notes-dialog',
    templateUrl: 'release-notes-dialog.component.html',
    providers: [WordpressApiService],
})
export class ReleaseNotesDialogComponent implements OnInit, OnDestroy {
    closeModals: Subscription;
    releaseNotes: Post[];

    constructor(
        public appState: AppStateService,
        public bsModalRef: BsModalRef,
        private wordpressApiService: WordpressApiService,
    ) {}

    async ngOnInit() {
        this.releaseNotes = await lastValueFrom(this.wordpressApiService.getReleaseNotes());
        this.closeModals = this.appState.closeModals$.subscribe(() => {
            this.bsModalRef.hide();
        });
    }

    ngOnDestroy(): void {
        if (this.closeModals) {
            this.closeModals.unsubscribe();
        }
    }
}
