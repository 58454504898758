<h3>{{ 'general.optician' | translate }}</h3>
<table>
    <tr>
        <td class="td-icon"><i class="fas fa-male"></i></td>
        <td #inputelement>{{ optician.Name }}</td>
    </tr>
    <tr>
        <td class="td-icon">
            <i class="fas fa-envelope"></i>
            <br />
        </td>
        <td>{{ optician.EmailAddress }}</td>
    </tr>
    <tr>
        <td class="td-icon"><i class="fas fa-mobile-alt"></i></td>
        <td>{{ optician.PhoneNumber }}</td>
    </tr>
    <tr>
        <td class="td-icon"><i class="fas fa-store-alt"></i></td>
        <td>{{ optician.City }}</td>
    </tr>
    <tr
        *hasFeatureSetting="[
            {
                Feature: Features.AutoCreditAllowed,
                Category: FeatureCategories.Optician,
                IsEnabled: true
            },
            {
                Feature: Features.OpticianLensOrderWarranty,
                Category: FeatureCategories.Company,
                IsEnabled: false
            }
        ]">
        <td class="td-icon"><i class="fas fa-exchange-alt"></i></td>
        <td>{{ 'general.creditordersAllowed' | translate }}</td>
    </tr>
    <tr
        *hasFeatureSetting="[
            {
                Feature: Features.Myopie,
                Category: FeatureCategories.Optician,
                IsEnabled: true
            }
        ]">
        <td class="td-icon"><i class="fas fa-eye"></i></td>
        <td>{{ 'general.myopia' | translate }}</td>
    </tr>
</table>
