import { Directive, HostListener } from '@angular/core';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Directive({
    selector: '[stop-propagation]',
})
export class StopPropagationDirective {
    @HostListener('click', ['$event'])
    public onClick(event: any): void {
        event.stopPropagation();
        event.preventDefault();
    }
}
