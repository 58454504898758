import { Pipe, PipeTransform } from '@angular/core';
import { ParameterTableDisplayHelper } from '../helpers/parameterTableDisplayHelper';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
    name: 'parameterTranslate',
})
export class ParameterTranslatorPipe extends TranslatePipe implements PipeTransform {
    transform(value: string, ...args: unknown[]): unknown {
        const parameterCode = value;
        const format = ParameterTableDisplayHelper.lookup(parameterCode);

        return super.transform(format.tableTranslation, args);
    }
}
