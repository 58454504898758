import { FitLensComponent } from '@app/fitlens/fitlens.component';
import { UntypedFormArray } from '@angular/forms';
import { LensDefinitionParameterNumberRange, LensDefinitionParameterRange, ListOption } from '../models';
import { TranslateService } from '@ngx-translate/core';

export class LensDefinitionParameterRangeHelper {
    private static readonly regex = /{([^}]+)}/g;

    public static checkConditions(parameterRange: LensDefinitionParameterRange, flc: FitLensComponent): boolean {
        let result = true;

        if (!flc) {
            return result;
        }

        const params = flc.formGroup.controls['parameters'] as UntypedFormArray;

        for (const condition of parameterRange.Conditions ?? []) {
            let expression = condition.Expression;
            const matches = condition.Expression.match(this.regex) ?? [];

            for (const match of matches) {
                const parameterCode = match.replace('{', '').replace('}', '');
                const parameterValue = params.value[flc.getFittedLensParameterIndex(parameterCode)];

                expression = expression.replace(match, parameterValue?.toString());
            }

            try {
                const resultCondition = eval(`"use strict";(${expression})`);
                result = result && !!resultCondition;
            } catch (e) {
                result = false;
            }
        }

        return result;
    }

    public static validateExpression(
        expression: string,
        parameters: ListOption[],
        translationService: TranslateService,
    ): string {
        if (!expression) {
            return;
        }

        const matches = expression.match(this.regex) ?? [];
        const missingParams = [];

        for (const match of matches) {
            const parameterCode = match.replace('{', '').replace('}', '');
            const parameterValue = parameters.find((p) => p.Code === parameterCode);

            if (!parameterValue) {
                missingParams.push(parameterCode);
                continue;
            }

            expression = expression.replace(match, '0');
        }

        if (missingParams.length > 0) {
            const parameterCodes = missingParams.join(', ');
            return translationService.instant('parameters-condition-parameter-not-found', { parameterCodes });
        }

        if (expression) {
            try {
                eval(`"use strict";(${expression})`);
            } catch (e) {
                return translationService.instant('parameters-condition-invalid');
            }
        }
    }

    public static humanizeExpression(
        x: LensDefinitionParameterNumberRange,
        flc: FitLensComponent,
        translationService: TranslateService,
    ): string {
        let humanizedString = '';

        for (const condition of x.Conditions) {
            const matches = condition.Expression.match(this.regex) ?? [];

            for (const match of matches) {
                const parameterCode = match.replace('{', '').replace('}', '');
                const parameterName = flc.fittedLensParameters.find(
                    (p) => p.LensDefinitionParameter.ParameterType.Code === parameterCode,
                ).LensDefinitionParameter.Name;
                const expression = condition.Expression.replace(parameterCode, parameterName)
                    .replace('{', '')
                    .replace('}', '');

                humanizedString += LensDefinitionParameterRangeHelper.translateExpressionToHumanReadable(
                    expression,
                    translationService,
                );
            }
        }

        if (humanizedString.length == 0) {
            return humanizedString;
        }

        return (humanizedString +=
            '. ' +
            translationService.instant('parameters-condition-valid-ranges', {
                minimum: x.Minimum,
                maximum: x.Maximum,
            }));
    }

    public static translateExpressionToHumanReadable(expression: string, translationService: TranslateService): string {
        if (!expression) {
            return;
        }

        const transform = (expression: string) => {
            const operators = {
                '<': 'parameters-condition-<',
                '>': 'parameters-condition->',
                '<=': 'parameters-condition-<=',
                '>=': 'parameters-condition->=',
                '&&': 'parameters-condition-&&',
                '||': 'parameters-condition-||',
                '==': 'parameters-condition-==',
                '!=': 'parameters-condition-!=',
                '+': 'parameters-condition-+',
                '-': 'parameters-condition--',
                '*': 'parameters-condition-*',
                '/': 'parameters-condition-/',
                '%': 'parameters-condition-%',
                '!': 'parameters-condition-!',
            };

            const humanReadable = expression
                .split(' ')
                .map((item: string) => translationService.instant(operators[item] || item))
                .join(' ');

            return humanReadable;
        };

        return transform(expression);
    }
}
