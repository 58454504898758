<div class="container-fluid mt-4 px-lg-4">
    <div class="col-xl-10 offset-xl-1">
        <div class="row mb-2">
            <div class="col-12">
                <ps-attachments [client]="client"></ps-attachments>
            </div>
        </div>
    </div>
</div>

<div *footer class="row"></div>
