import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    FormGroupDirective,
    UntypedFormArray,
    FormArray,
} from '@angular/forms';
import { Subscription, lastValueFrom, of } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LoaderService } from '@app/shared/appservices/loader.service';
import {
    FittedLens,
    DreamLiteWizardOpticianInput,
    ListOption,
    MaterialColor,
    Client,
    FittedLensParameter,
    NumberFormatOptions,
    TopographicMeasurement,
    LensDefinitionParameter,
    RefractionMeasurement,
    LensDefinitionParameterText,
    LensDefinitionParameterNumberRange,
} from '@app/shared/models';
import { AppStateService } from '@app/shared/appservices/appState.service';
import {
    LensTypes,
    EyeSides,
    Features,
    InputTypes,
    LensFilters,
    ProductGroups,
    ProductGroupCodes,
    ParameterTypeCodes,
} from '@app/shared/enums';
import { LensDefinitionListOption } from '@app/shared/models/lensDefinitionListOption.model';
import { FittedLensService } from '@app/core/services/api/fitted-lens.service';
import { NumberFormatPipe } from '@app/shared/pipes/number.format.pipe';
import { LensParameterChange } from '@app/fitlens/models/lensParameterChange.model';
import { FittedLensChange } from '@app/fitlens/models/fittedLensChange.model';
import { FittingEventService } from '@app/core/services/fittingEvent.service';
import { DreamLiteWizardFixType } from '@app/shared/models/dreamLiteWizardFixType.model';
import { InputNumberRange } from '@app/shared/models/input-number-range.model';
import { ProductService } from '@app/core/services/api/product.service';
import { MeasurementReviewDialogComponent } from '@app/measurement/measurement-review-dialog.component';
import { GetTopographicMeasurementsByClientIdRequest } from '@app/shared/requestmodels';
import { OverRefractionResult } from '@app/fitlens/models/OverrefractionResult.model';
import { FittedLensOverrefraction } from '@app/shared/models/fittedLensOverRefraction.model';
import { Router } from '@angular/router';
import { LensDefinitionParameterUnit } from '@app/shared/models/LensDefinitionParameterUnit';
import { ProductGroupService } from '@app/core/services/api/product-group.service';
import { LensTypeProductGroupNames } from '@app/shared/models/lensTypeProductGroupNames.model';
import { ScleralCodeGenerator } from '@app/fitlens/scripts/ScleralCodeGenerator';
import { OverrefractionParameter } from '@app/shared/models/overRefractionParameter.model';
import { RefractionMeasurementHa0Helper } from '@app/refraction/RefractionMeasurementHa0Helper';
import { DreamliteRc2ValueShouldBeLessOrEqualToAc2Validator } from '@app/fitlens/validators/dreamliteRc2ValueShouldBeLessOrEqualToAc2Validator';
import { DreamliteRcValueShouldBeLessOrEqualToAcValidator } from '@app/fitlens/validators/dreamliteRcValueShouldBeLessOrEqualToAcValidator';
import { ToricityShouldBeValidValidator } from '@app/fitlens/validators/toricityShouldBeValidValidator';
import { FittedLensOptions } from '@app/shared/models/fittedLensOptions.model';
import { CopyLensData } from '@app/shared/models/copyData';
import { QuantityChange } from '@app/fitlens/models/quantityChange.model';
import { LensNotchResult } from '@app/fitlens//models/lensNotchResult.model';
import { CrtRzdValidator } from '@app/fitlens/validators/crtRzdValidator';
import { CrtLzaValidator } from '@app/fitlens/validators/crtLzaValidator';
import { DreamliteBcrValueShouldMoreThanAcValidator } from '@app/fitlens/validators/dreamliteBcrValueShouldMoreThanAcValidator';
import { ByopremiumRadDiamValidator } from '@app/fitlens/validators/byopremiumRadDiamValidator';
import { BaseValidator } from '@app/shared/validators/BaseValidator';
import { DreamLiteWizardService } from '@app/core/services/api/dreamlite-wizard.service';
import { LensService } from '@app/core/services/api/lens.service';
import { MaterialsService } from '@app/core/services/api/material.service';
import { TopographicMeasurementService } from '@app/core/services/api/topographic-measurement.service';
import { SessionService } from '@app/shared/appservices/session.service';
import {
    RgpFittedLensCalculator,
    SoftFittedLensCalculator,
    MedFittedLensCalculator,
    FieldValueExpressionCalculator,
} from '@app/fitlens/calculators';
import { ImageOptions } from '@app/shared/models/image-options.model';

@Component({
    selector: 'fit-lens',
    templateUrl: 'fitlens.component.html',
    styleUrls: ['fitlens.component.scss'],
})
export class FitLensComponent implements OnInit, OnDestroy {
    @ViewChild('residualrefraction')
    residualRefraction: import('./components/residualrefraction.component').ResidualRefractionComponent;

    @ViewChild('previouslensparams')
    previousLensParams: import('./components/previouslensparams.component').PreviousLensParamsComponent;

    @ViewChild('dreamliteoptions')
    dreamliteOptions: import('./components/dreamlite-options/dreamliteoptions.component').DreamliteOptionsComponent;

    @ViewChild('lensselection')
    lensselection: import('./components/lensselection.component').LensSelectionComponent;

    @ViewChild('dreamliteclearance')
    dreamliteclearance: import('./components/dreamlite-clearance/dreamlite-clearance.component').DreamliteClearanceComponent;

    //#region "properties"
    @Input() id: string;
    @Input() initialFittedLens: FittedLens = null;
    @Input() lensFromPreviousOrderPromise: () => Promise<FittedLens>;
    @Input() options: FittedLensOptions;
    @Input() client: Client;
    @Input() isDreamLiteFollowUp = false;
    @Input() isRegularFollowUp = false;
    @Input() topoMeasurement: TopographicMeasurement;
    @Input() previousMeasurementId: number;
    @Input() refractionMeasurement: RefractionMeasurement;
    @Input() showCopyButton: boolean;
    @Input() quantity = 1;

    @Input() validators: BaseValidator[] = [];

    @Input('fittedLens')
    set inFl(val: FittedLens) {
        this.fittedLens = val;

        if (this.initialized) {
            this.refresh().then();
        }
    }
    get inFl() {
        return this.fittedLens;
    }

    @Input() sessionStorageKeysFitlens: string[];
    @Output() sessionStorageKeysFitlensChange = new EventEmitter<string[]>();

    @Output() isLoading: EventEmitter<boolean> = new EventEmitter();
    @Output() onCopyLensInfo: EventEmitter<unknown> = new EventEmitter();

    get formControls() {
        return this.formGroup.controls;
    }

    formGroup: UntypedFormGroup;
    materialColors: MaterialColor[];
    appliedFixTypes: DreamLiteWizardFixType[];

    lensFilters = LensFilters;
    lensTypes = LensTypes;
    productGroupCodes = ProductGroupCodes;
    lensNames: LensTypeProductGroupNames;
    doPulse: boolean;
    inputTypes = InputTypes;

    multiFocalAdditionRequired = false;
    isOverrefractionExpanded = false;
    isOverrefractionReadonly = false;
    isLensNotchExpanded = false;
    isLensNotchReadonly = false;
    notchAvailable = false;
    configurationDisabled = false;
    initialized = false;
    isPs = false;
    isDistributorSupport = false;

    stabilization: number;
    lastFocusedElementId: string;

    filterProductGroups: {
        [Key: string]: boolean;
    } = {};

    public fittedLens: FittedLens;
    private unmodifiedFittedLens: FittedLens;
    private fixTypes: DreamLiteWizardFixType[];
    private fixTypeRanges: { [code: string]: InputNumberRange[] };
    private parentFormGroup: UntypedFormGroup;

    private colors: ListOption[];
    private materials: ListOption[];

    private lensDefinitionChanged: Subscription;
    private materialChanged: Subscription;
    private colorChanged: Subscription;
    private parametersChanged: Subscription;
    private fittedLensChanged: Subscription;
    private lensParameterChanged: Subscription;

    private lensParametersChangedSkipped: string[] = [];

    private lensFilter: LensFilters = LensFilters.all;
    private productGroupFilter = '';

    private lensDefinitions: LensDefinitionListOption[];
    private filteredLensDefinitions: LensDefinitionListOption[];
    public fittedLensParameters: FittedLensParameter[];
    private lensEngravings: ListOption[];
    private lensThickness: ListOption[];

    scleralCode: string;

    public loading = false;
    protected calculating = false;
    private initialLensFilterSet = false;

    private lessOrMuchFixType: DreamLiteWizardFixType;
    private adjustClearanceFixType: DreamLiteWizardFixType;
    private ozFixType: DreamLiteWizardFixType;
    private lateralFixType: DreamLiteWizardFixType;
    private highRidingFixType: DreamLiteWizardFixType;
    private lowRidingFixType: DreamLiteWizardFixType;
    public myopieFixtype: DreamLiteWizardFixType;
    public multiFocalFixType: DreamLiteWizardFixType;
    public dreamLiteFixType: DreamLiteWizardFixType;
    private dreamLiteFixTypeMC: DreamLiteWizardFixType;
    multiFocalEffectFixType: DreamLiteWizardFixType;

    public lensFromPreviousOrder: FittedLens;

    private readonly ComponentId = 'fitlensComponent';

    protected initialVisibleLensParameterValues: FittedLensParameter[];
    protected changedVisibleLensDefinitionParameterIds: number[];

    //#endregion "properties"

    constructor(
        public appState: AppStateService,
        private readonly parent: FormGroupDirective,
        private readonly fb: UntypedFormBuilder,
        private readonly productGroupService: ProductGroupService,
        private readonly materialService: MaterialsService,
        private readonly loaderService: LoaderService,
        private readonly fittedLensService: FittedLensService,
        private readonly numberFormatPipe: NumberFormatPipe,
        private readonly rgpFittedLensCalculator: RgpFittedLensCalculator,
        private readonly softFittedLensCalculator: SoftFittedLensCalculator,
        private readonly medFittedLensCalculator: MedFittedLensCalculator,
        private readonly fittingEventService: FittingEventService,
        private readonly productService: ProductService,
        private readonly fieldValueExpressionCalculator: FieldValueExpressionCalculator,
        private readonly modalService: BsModalService,
        private readonly topographicMeasurementService: TopographicMeasurementService,
        private readonly router: Router,
        private readonly dreamLiteWizardService: DreamLiteWizardService,
        private readonly lensService: LensService,
        private readonly sessionService: SessionService,
    ) {}

    //#region "Lifecycle hooks"
    async ngOnInit(): Promise<void> {
        this.parentFormGroup = this.parent.form;
        this.createForm();

        this.isPs = this.appState.isPs;
        this.isDistributorSupport = this.appState.isDistributorSupport;

        this.fixTypeRanges = {};
        this.appliedFixTypes = [];

        this.colors = new Array<ListOption>();
        this.materials = new Array<ListOption>();

        if (this.isDreamLiteFollowUp) {
            await this.loadFixTypes();
        }

        const engraveData = await lastValueFrom(this.lensService.getLensEngravings(this.options.EyeSideId));
        if (engraveData) {
            if (this.showScleralCode()) {
                this.lensEngravings = engraveData.filter((le) => le.Code === 'DOT' || le.Code === 'NONE');
            } else {
                this.lensEngravings = engraveData;
            }
        }

        this.lensThickness = await lastValueFrom(this.lensService.getLensThickness());

        this.lensDefinitionChanged = this.formControls['lensDefinitionId'].valueChanges
            .pipe(debounceTime(500))
            .subscribe(async (value) => {
                await this.changeLensDefinition(value as number);
                this.checkNotchAvailable();
                this.checkFilterGroups();
            });

        this.materialChanged = this.formControls['materialId'].valueChanges.subscribe((value) => {
            this.handleMaterialChange();
            this.loadColors(Number(value), null);
        });

        this.colorChanged = this.formControls['colorId'].valueChanges.subscribe(() => {
            this.handleMaterialChange();
        });

        await this.refresh();

        if (!this.sessionService.get(this.ComponentId + this.id + this.lensTypeId)) {
            this.lensNames = await lastValueFrom(this.productGroupService.getLensTypeProductGroupNames());
            this.refreshParameters();
        } else {
            const valueJson = this.sessionService.get(this.ComponentId + this.id + this.lensTypeId);
            const value = JSON.parse(valueJson);

            this.formControls['parameters'].patchValue(value);
        }

        this.parametersChanged = this.formControls['parameters'].valueChanges.subscribe(() => {
            this.configurationDisabled = true;
            const key = this.ComponentId + this.id + this.lensTypeId;
            this.sessionService.save(key, this.formControls['parameters'].value);
            if (!this.sessionStorageKeysFitlens.includes(key)) {
                this.sessionStorageKeysFitlens.push(key);
            }

            this.addChangedVisibleLensParameterId();
        });

        this.fittedLensChanged = this.fittingEventService.fittedLensChanged.subscribe(() => {
            if (this.showScleralCode()) {
                this.scleralCode = ScleralCodeGenerator.generate(
                    this.fittedLens.LensDefinition.Product.ProductGroup.Code,
                    this.fittedLens,
                );
            }
            this.checkNotchAvailable();
            this.checkFilterGroups();
        });

        this.lensParameterChanged = this.fittingEventService.lensParameterChanged.subscribe(() => {
            if (this.showScleralCode()) {
                this.scleralCode = ScleralCodeGenerator.generate(
                    this.fittedLens.LensDefinition.Product.ProductGroup.Code,
                    this.fittedLens,
                );
            }
        });

        this.checkNotchAvailable();
        this.checkFilterGroups();

        this.initialized = true;
    }

    ngOnDestroy(): void {
        if (this.lensDefinitionChanged) {
            this.lensDefinitionChanged.unsubscribe();
        }
        if (this.materialChanged) {
            this.materialChanged.unsubscribe();
        }
        if (this.colorChanged) {
            this.colorChanged.unsubscribe();
        }
        if (this.parametersChanged) {
            this.parametersChanged.unsubscribe();
        }
        if (this.fittedLensChanged) {
            this.fittedLensChanged.unsubscribe();
        }
        if (this.lensParameterChanged) {
            this.lensParameterChanged.unsubscribe();
        }
    }
    //#endregion "Lifecycle hooks"

    //#region "Methods"

    // Refreshes the control based on the inputted fitted lens
    public async refresh(): Promise<void> {
        if (!this.fittedLens) {
            return;
        }

        this.unmodifiedFittedLens = structuredClone(this.fittedLens);
        this.fixManualCorrection();

        const lensDefinitionId = this.fittedLens.LensDefinitionId ? this.fittedLens.LensDefinitionId : 0;
        const opticianId =
            this.fittedLens && this.fittedLens.OpticianId
                ? this.fittedLens.OpticianId
                : this.appState.currentOptician.Id;

        if (this.options.ProposalTypeId) {
            this.lensService
                .getLensDefinitions(opticianId, this.lensTypeId, lensDefinitionId)
                .subscribe(async (result) => {
                    this.lensDefinitions = result;
                    this.filteredLensDefinitions = this.getFilteredLensDefinitions();

                    // if there is no fittedLens, or there is no lens definition, select the first lens in the list
                    if (!this.fittedLens || !lensDefinitionId) {
                        await this.selectFirstLens(false);
                    }

                    this.checkFilterGroups();
                });
        } else {
            this.productService
                .getAllProductsByProductGroupId(this.fittedLens.LensDefinition.Product.ProductGroupId)
                .subscribe(async (result) => {
                    this.lensDefinitions = result.map((p) => {
                        return {
                            Id: p.Id,
                            Name: p.Name,
                            Code: p.Code,
                            FamilyId: p.ProductGroupId,
                            Myopie: p.LensDefinition.Myopie,
                            ProductGroupCode: p.ProductGroup ? p.ProductGroup.Code : p.ProductGroup,
                            IsMultifocal: p.LensDefinition.IsMultifocal,
                            IsMultifocalToric: p.LensDefinition.IsMultifocalToric,
                            IsSpherical: p.LensDefinition.IsSpherical,
                            IsStandardLens: p.LensDefinition.IsStandardLens,
                            IsToric: p.LensDefinition.IsToric,
                        } as LensDefinitionListOption;
                    });

                    this.filteredLensDefinitions = this.getFilteredLensDefinitions();

                    // if there is no fittedLens, or there is no lens definition, select the first lens in the list
                    if (!this.fittedLens || !lensDefinitionId) {
                        await this.selectFirstLens(false);
                    }

                    this.checkFilterGroups();
                });
        }

        if (lensDefinitionId) {
            await this.changeLensDefinition(lensDefinitionId);
        }

        if (!this.initialLensFilterSet && this.fittedLens && this.fittedLens.LensDefinition) {
            this.setInitialLensFilterAndProductGroup();
        }
    }

    createForm(): void {
        this.formGroup = this.fb.group({
            lensFilter: [LensFilters.all],
            lensDefinitionId: [this.fittedLens ? this.fittedLens.LensDefinitionId : null, [Validators.required]],
            materialId: [
                this.fittedLens && this.fittedLens.MaterialColor ? this.fittedLens.MaterialColor.MaterialId : '',
            ],
            colorId: [this.fittedLens && this.fittedLens.MaterialColor ? this.fittedLens.MaterialColor.ColorId : ''],
            lensEngravingId: [this.fittedLens ? this.fittedLens.LensEngravingId : ''],
            lensThicknessId: [this.fittedLens ? this.fittedLens.LensThicknessId : ''],
            parameters: this.fb.array([]),
        });

        // TODO: Remove validators and add them to the parameters
        const validators = [
            () => new DreamliteRcValueShouldBeLessOrEqualToAcValidator().bind(this),
            () => new DreamliteRc2ValueShouldBeLessOrEqualToAc2Validator().bind(this),
            () => new DreamliteBcrValueShouldMoreThanAcValidator().bind(this),
            // () => new DreamliteBcrValueShouldMoreThanAc2Validator().bind(this),
            () => new ToricityShouldBeValidValidator().bind(this),
            () => new ByopremiumRadDiamValidator().bind(this),
            () => new CrtRzdValidator().bind(this),
            () => new CrtLzaValidator().bind(this),
            //Bind any additional validators from the this.validators array and map them to an arrow function
            ...this.validators?.map((validator) => () => validator.bind(this)),
        ];

        this.formGroup.setValidators(validators);

        if (this.canShowMarkings) {
            this.formControls['lensEngravingId'].setValidators([Validators.required]);
            this.formControls['lensEngravingId'].updateValueAndValidity();
        }

        if (this.canShowThickness) {
            this.formControls['lensThicknessId'].setValidators([Validators.required]);
            this.formControls['lensThicknessId'].updateValueAndValidity();
        }

        this.formGroup.setParent(this.parentFormGroup);
        this.addToParentGroup();
    }

    removeFromParentGroup(): void {
        if (this.parentFormGroup.controls[this.id]) {
            this.parentFormGroup.removeControl(this.id);
        }
    }

    addToParentGroup(): void {
        this.removeFromParentGroup();
        this.parentFormGroup.addControl(this.id, this.formGroup);
    }

    controlHasFormError(flp: FittedLensParameter): boolean {
        let hasError = false;

        if (this.formGroup.errors) {
            const code = flp.LensDefinitionParameter.ParameterType.Code;

            if (
                (code === ParameterTypeCodes.RC || code === ParameterTypeCodes.AC) &&
                this.formGroup.errors.dreamliteRcValueShouldBeLessOrEqualThanAc
            ) {
                hasError = true;
            }
            if (
                (code === ParameterTypeCodes.RC2 || code === ParameterTypeCodes.AC2) &&
                this.formGroup.errors.dreamliteRc2ValueShouldBeLessOrEqualThanAc2
            ) {
                hasError = true;
            }
            if (
                (code === ParameterTypeCodes.RAD || code === ParameterTypeCodes.RAD) &&
                this.formGroup.errors.toricityShouldBeValid
            ) {
                hasError = true;
            }
            if (
                (code === ParameterTypeCodes.RAD || code === ParameterTypeCodes.DIAM) &&
                this.formGroup.errors.radDiamInvalid
            ) {
                hasError = true;
            }
            if (
                (code === ParameterTypeCodes.RAD || code === ParameterTypeCodes.AC) &&
                this.formGroup.errors.dreamliteBcrValueShouldMoreThanAc
            ) {
                hasError = true;
            }
            if (
                (code === ParameterTypeCodes.RZD1SCRN || code === ParameterTypeCodes.RZD2SCRN) &&
                this.formGroup.errors.crtRzdValidator
            ) {
                hasError = true;
            }
            if (
                (code === ParameterTypeCodes.LZA1SCRN || code === ParameterTypeCodes.LZA2SCRN) &&
                this.formGroup.errors.crtLzaValidator
            ) {
                hasError = true;
            }
        }

        return hasError;
    }

    // selects the first lensdefinition in the filtered list of lens definitions (filtered by productgroup & lens filter)
    async selectFirstLens(tryKeepProductGroup: boolean): Promise<void> {
        let firstLensDefinition: LensDefinitionListOption;

        if (
            tryKeepProductGroup &&
            this.fittedLens &&
            this.fittedLens.LensDefinition &&
            this.fittedLens.LensDefinition.Product &&
            this.fittedLens.LensDefinition.Product.ProductGroup
        ) {
            firstLensDefinition = this.filteredLensDefinitions.find(
                (ld) =>
                    ld.ProductGroupCode === this.fittedLens.LensDefinition.Product.ProductGroup.Code &&
                    ld.Frontoric === this.fittedLens.LensDefinition.Frontoric &&
                    ld.FitTypeId === this.fittedLens.LensDefinition.FitTypeId,
            );

            if (!firstLensDefinition) {
                firstLensDefinition = this.filteredLensDefinitions.find(
                    (ld) =>
                        ld.ProductGroupCode === this.fittedLens.LensDefinition.Product.ProductGroup.Code &&
                        ld.Frontoric === this.fittedLens.LensDefinition.Frontoric,
                );
            }

            if (!firstLensDefinition) {
                firstLensDefinition = this.filteredLensDefinitions.find(
                    (ld) => ld.ProductGroupCode === this.fittedLens.LensDefinition.Product.ProductGroup.Code,
                );
            }
        }

        if (!firstLensDefinition) {
            firstLensDefinition = this.filteredLensDefinitions?.length ? this.filteredLensDefinitions[0] : null;
        }

        const lensDefinitionId = firstLensDefinition ? firstLensDefinition.Id : null;

        await this.changeLensDefinition(lensDefinitionId);
    }

    updateFields(): void {
        if (this.fittedLens) {
            this.setMaterialColors(this.fittedLens.MaterialColorId);

            // update the controls are set to the correct values
            this.lensselection.formControls['lensDefinitionId'].patchValue(this.fittedLens.LensDefinitionId, {
                emitEvent: false,
            });

            this.formControls['lensDefinitionId'].patchValue(this.fittedLens.LensDefinitionId, { emitEvent: false });

            this.lensselection.formControls['lensEngravingId'].patchValue(this.fittedLens.LensEngravingId, {
                emitEvent: false,
            });
            this.lensselection.formControls['lensThicknessId'].patchValue(this.fittedLens.LensThicknessId, {
                emitEvent: false,
            });

            // update the lens parameter fields
            this.resetParameterFields();

            if (this.fittedLens.RefractionMeasurement) {
                RefractionMeasurementHa0Helper.calcCorneaNulDis(this.fittedLens.RefractionMeasurement);
            }

            if (this.lensFilter && this.lensFilter !== LensFilters.all) {
                const lensDefinition = this.lensDefinitions?.find((ld) => ld.Id === this.fittedLens.LensDefinitionId);
                if (lensDefinition) {
                    if (lensDefinition.IsSpherical) {
                        this.lensFilter = LensFilters.spherical;
                    } else if (lensDefinition.IsToric) {
                        this.lensFilter = LensFilters.toric;
                    } else if (lensDefinition.IsMultifocal) {
                        this.lensFilter = LensFilters.multiFocal;
                    } else if (lensDefinition.IsMultifocalToric) {
                        this.lensFilter = LensFilters.multiFocalToric;
                    }
                    this.filteredLensDefinitions = this.getFilteredLensDefinitions();
                } else {
                    this.lensFilter = LensFilters.all;
                }
            }
        }
    }

    // changes the lens definition for the fitted lens
    async changeLensDefinition(lensDefinitionId: number): Promise<void> {
        if (this.fittedLens) {
            const previousFittedLens = structuredClone(this.fittedLens);

            // this function is used in the result of changeLensDefinitionInFittedLens, it updates the fields on the screen
            // with the correct values

            const hasProduct = this.fittedLens.LensDefinition && this.fittedLens.LensDefinition.Product;
            const noUpdate = !lensDefinitionId || lensDefinitionId === this.fittedLens.LensDefinitionId;
            if (noUpdate && hasProduct) {
                this.fittedLens.LensDefinitionId = lensDefinitionId;

                this.updateFields();
            } else {
                this.setLoading(true);
                this.sessionService.remove(this.ComponentId + this.id + this.lensTypeId);
                this.lensParametersChangedSkipped = [];

                const fittedLens = structuredClone(this.unmodifiedFittedLens);
                fittedLens.LensDefinitionId = lensDefinitionId;

                if (this.options.ProposalTypeId) {
                    const result = await lastValueFrom(
                        this.fittedLensService.changeLensDefinitionInFittedLens(
                            lensDefinitionId,
                            this.options.ProposalTypeId,
                            fittedLens,
                        ),
                    );

                    // update the current fitted lens with the values from the results
                    if (result.LensDefinition) {
                        this.fittedLens.LensDefinition = result.LensDefinition;
                    }

                    this.fittedLens.Id = result.Id;
                    this.fittedLens.DreamLiteClearance = result.DreamLiteClearance;
                    this.fittedLens.DreamLiteClearanceAst = result.DreamLiteClearanceAst;
                    this.fittedLens.FittedLensParameters = result.FittedLensParameters;
                    this.fittedLens.LensDefinitionId = result.LensDefinitionId;
                    this.fittedLens.MaterialColorId = result.MaterialColorId;
                    this.fittedLens.ResidualRefractionSphere = result.ResidualRefractionSphere;
                    this.fittedLens.ResidualRefractionCylinder = result.ResidualRefractionCylinder;
                    this.fittedLens.ResidualRefractionAx = result.ResidualRefractionAx;

                    if (result.MaterialColor) {
                        this.fittedLens.MaterialColor = result.MaterialColor;
                    }

                    // set the lens filters (product group & lens filter) if they are not yet set
                    if (!this.initialLensFilterSet && this.fittedLens.LensDefinition) {
                        this.setInitialLensFilterAndProductGroup();
                    }

                    this.isOverrefractionReadonly = false;
                } else {
                    const result = await lastValueFrom(this.fittedLensService.createFittedLens(lensDefinitionId));
                    result.FittedLensParameters.filter((flp) => !flp.CalculationValid).forEach((flp) => {
                        flp.Value = null;
                        flp.TextValue = null;
                    });

                    this.fittedLens = result;
                }

                this.updateFields();
                this.fittedLens.EyeSideId = this.options.EyeSideId;
                this.fittingEventService.fittedLensChanged.emit(
                    new FittedLensChange(previousFittedLens, this.fittedLens),
                );
                this.isOverrefractionReadonly = false;
                this.setLoading(false);
                this.setInitialVisibleLensParameterValues();
            }
        }
    }

    fixManualCorrection() {
        this.fittedLens.ManualCorrectionSphere = -this.fittedLens.ResidualRefractionSphere;
        this.fittedLens.ManualCorrectionCyl = -this.fittedLens.ResidualRefractionCylinder;
        this.fittedLens.ManualCorrectionAx = this.fittedLens.ResidualRefractionAx;
    }

    // Clear the parameters from the form and re-add them
    resetParameterFields(): void {
        const parameters = this.formControls['parameters'] as UntypedFormArray;
        this.fittedLensParameters = this.getFittedLensParameters();

        // re-generate the parameter controls
        parameters.controls.splice(0, parameters.controls.length); // clear all controls

        this.visibleLensParameters.forEach((flp) => {
            const fc = this.fb.control(
                flp.GenericValue,
                flp.LensDefinitionParameter.IsReadOnly || flp.LensDefinitionParameter.IsHidden
                    ? []
                    : [Validators.required],
            );

            if (this.options.Recalculate) {
                // @TODO: Removed .pipe(debounceTime(500) since it was causing issues
                // We have to refactor the recalculate trigger #8104
                fc.valueChanges.subscribe(() => {
                    this.recalculateLensParameter(flp.LensDefinitionParameter.ParameterType.Code);
                });
            } else {
                fc.valueChanges.pipe(debounceTime(500)).subscribe(() => {
                    const previousFittedLens = structuredClone(this.fittedLens);
                    const currentFittedLens = this.readFittedLens();
                    this.fittedLens = currentFittedLens;
                    this.updateParameterFields();

                    let paramsValid = true;
                    for (let i = 0; i < currentFittedLens.FittedLensParameters.length; i++) {
                        const param = currentFittedLens.FittedLensParameters[i];
                        if (
                            param.Value === null &&
                            !param.LensDefinitionParameter.IsReadOnly &&
                            !param.LensDefinitionParameter.IsHidden &&
                            param.LensDefinitionParameter.ParameterType.InputTypeId === InputTypes.Number
                        ) {
                            paramsValid = false;
                        }
                    }

                    if (!this.lensParametersChangedSkipped.includes(flp.LensDefinitionParameter.ParameterType.Code)) {
                        this.lensParametersChangedSkipped.push(flp.LensDefinitionParameter.ParameterType.Code);
                    }

                    if (paramsValid) {
                        for (let i = 0; i < currentFittedLens.FittedLensParameters.length; i++) {
                            const param = currentFittedLens.FittedLensParameters[i];
                            if (param.Value === null) {
                                param.Value = 0.0;
                            }
                        }

                        this.calculating = true;
                        this.fieldValueExpressionCalculator
                            .recalculateFieldValueExpressions(currentFittedLens, this.lensParametersChangedSkipped)
                            .pipe(
                                finalize(() => {
                                    this.finalizeRecalculate();
                                }),
                            )
                            .subscribe((result) => {
                                this.fittedLens = result;
                                this.lensParametersChangedSkipped = [];
                                this.fittingEventService.lensParameterChanged.emit(
                                    new LensParameterChange(
                                        previousFittedLens,
                                        this.fittedLens,
                                        flp.LensDefinitionParameter.ParameterType.Code,
                                    ),
                                );
                                this.updateParameterFields();
                            });
                    }
                });
            }

            parameters.push(fc);
        });

        this.configurationDisabled = false;
    }

    resetFocus() {
        const lastFocusedElement = document.getElementById(this.lastFocusedElementId) as HTMLInputElement;
        if (lastFocusedElement) {
            lastFocusedElement.focus();
            lastFocusedElement?.select();
        }
    }

    // Update the form controls with values from current fittedLens
    updateParameterFields(): void {
        const parameters = this.formControls['parameters'] as UntypedFormArray;
        this.fittedLensParameters = this.getFittedLensParameters();

        // patch the existing parameter controls
        this.visibleLensParameters.forEach((flp: FittedLensParameter, index: number) => {
            if (parameters.controls[index].value !== flp.GenericValue) {
                parameters.controls[index].patchValue(flp.GenericValue, {
                    emitEvent: false,
                });
            }
        });
    }

    // returns if the lens filter should be visible
    lensFilterEnabled(lensFilter: LensFilters): boolean {
        if (
            (lensFilter === LensFilters.multiFocal || lensFilter === LensFilters.multiFocalToric) &&
            this.client &&
            this.client.Myopie
        ) {
            return false;
        }

        let result = true;

        if (
            this.fittedLens &&
            this.fittedLens.LensDefinition &&
            this.fittedLens.LensDefinition.LensTypeId === LensTypes.Med
        ) {
            switch (this.productGroupFilter) {
                case ProductGroupCodes.SOFT:
                    result = lensFilter === LensFilters.spherical || lensFilter === LensFilters.all;
                    break;
                case ProductGroupCodes.KC:
                    result =
                        lensFilter === LensFilters.spherical ||
                        lensFilter === LensFilters.toric ||
                        lensFilter === LensFilters.all;
                    break;
                case ProductGroupCodes.EMIN:
                    result = lensFilter === LensFilters.spherical || lensFilter === LensFilters.all;
                    break;
                default:
                    if (ProductGroups.isScleralProductGroup(this.productGroupFilter)) {
                        result =
                            lensFilter === LensFilters.spherical ||
                            lensFilter === LensFilters.toric ||
                            lensFilter === LensFilters.multiFocal ||
                            lensFilter === LensFilters.multiFocalToric ||
                            lensFilter === LensFilters.all;
                    } else {
                        result = true;
                    }
            }
        }

        return result && this.filterHasAtLeastOneLenstype(lensFilter);
    }

    filterHasAtLeastOneLenstype(lensFilter: LensFilters): boolean {
        let found = null;

        switch (lensFilter) {
            case LensFilters.multiFocal:
                found = this.lensDefinitions?.find((ld) => ld.IsMultifocal);
                break;
            case LensFilters.toric:
                found = this.lensDefinitions?.find((ld) => ld.IsToric);
                break;
            case LensFilters.multiFocalToric:
                found = this.lensDefinitions?.find((ld) => ld.IsMultifocalToric);
                break;
            case LensFilters.spherical:
                found = this.lensDefinitions?.find((ld) => ld.IsSpherical);
                break;
            default:
                return true;
        }

        return found != null;
    }

    lensDefinitionFilter(item: LensDefinitionListOption): boolean {
        // hide myopia products for non-myopia clients
        if (item.Myopie) {
            if (!this.appState.isMyopiaEnabled) {
                return false;
            }
            if (this.client && !this.client.Myopie) {
                return false;
            }
        } else {
            if (
                this.client &&
                this.client.Myopie &&
                this.appState.isMyopiaEnabled &&
                this.lensTypeId !== LensTypes.DreamLite &&
                this.lensTypeId !== LensTypes.Glass
            ) {
                return false;
            }
        }

        // no filtering on dreamlite & glass, return true
        if (this.lensTypeId === LensTypes.DreamLite || this.lensTypeId === LensTypes.Glass) {
            return true;
        }

        let result = true;

        switch (this.lensFilter) {
            case LensFilters.spherical:
                result = item.IsSpherical;
                break;
            case LensFilters.toric:
                result = item.IsToric;
                break;
            case LensFilters.multiFocal:
                result = item.IsMultifocal;
                break;
            case LensFilters.multiFocalToric:
                result = item.IsMultifocalToric;
                break;
            default:
                break;
        }

        result =
            result &&
            (this.productGroupFilter === '' ||
                this.productGroupFilter === item.ProductGroupCode ||
                (this.productGroupFilter === ProductGroupCodes.SCLERAL &&
                    ProductGroups.isScleralProductGroup(item.ProductGroupCode))) &&
            !(item.IsOnlyManualMeasurementsLens && !this.topoMeasurement?.IsManual);

        return result;
    }

    showScleralCode(): boolean {
        return (
            this.fittedLens &&
            this.fittedLens.LensDefinition &&
            this.fittedLens.LensDefinition.Product &&
            this.fittedLens.LensDefinition.Product.ProductGroup &&
            ProductGroups.isScleralProductGroup(this.fittedLens.LensDefinition.Product.ProductGroup.Code)
        );
    }

    // returns the fittedLens with the current values as entered in the form
    readFittedLens(): FittedLens {
        const fittedLens = structuredClone(this.fittedLens);

        if (this.materialColors) {
            // Lookup the material color in the list by the chosen color and material
            const materialColor = this.lensselection.materialColor;
            fittedLens.MaterialColorId = materialColor ? materialColor.Id : null;
            fittedLens.MaterialColor = materialColor;
        }

        fittedLens.LensEngravingId = this.lensselection.lensEngravingId;
        fittedLens.LensThicknessId = this.lensselection.lensThicknessId;

        const parameters = this.formControls['parameters'] as UntypedFormArray;

        // get the values from the visible controls and put them back into the lensParameters array
        this.visibleLensParameters.forEach((flp: FittedLensParameter, i: number) => {
            flp.GenericValue = parameters.controls[i].value;
        });

        fittedLens.FittedLensParameters = this.fittedLensParameters;

        this.fixManualCorrection();

        return fittedLens;
    }

    readQuantity(): number {
        return this.quantity;
    }

    recalculateLensParameter(parameterTypeCode: string): void {
        if (this.formGroup.invalid || this.calculating) {
            return;
        }

        if (!this.isDreamLiteFollowUp && this.fittedLens && this.fittedLens.LensDefinition) {
            this.calculating = true;
            const previousFittedLens = structuredClone(this.fittedLens);
            const currentFittedLens = this.readFittedLens();

            switch (currentFittedLens.LensDefinition.LensTypeId) {
                case LensTypes.Rgp:
                    this.rgpFittedLensCalculator
                        .recalculateFittedLens(currentFittedLens, this.fittedLens, parameterTypeCode)
                        .pipe(
                            finalize(() => {
                                this.finalizeRecalculate();
                            }),
                        )
                        .subscribe((result) => {
                            this.fittedLens = result;
                            this.fittingEventService.lensParameterChanged.emit(
                                new LensParameterChange(previousFittedLens, this.fittedLens, parameterTypeCode),
                            );
                            this.updateParameterFields();
                        });

                    break;
                case LensTypes.Soft:
                    this.softFittedLensCalculator
                        .recalculateFittedLens(currentFittedLens, parameterTypeCode)
                        .pipe(
                            finalize(() => {
                                this.finalizeRecalculate();
                            }),
                        )
                        .subscribe((result) => {
                            this.fittedLens = result;

                            this.fittingEventService.lensParameterChanged.emit(
                                new LensParameterChange(previousFittedLens, this.fittedLens, parameterTypeCode),
                            );
                            this.updateParameterFields();
                        });

                    break;
                case LensTypes.Crt:
                    this.fieldValueExpressionCalculator
                        .recalculateFieldValueExpressions(currentFittedLens, [parameterTypeCode])
                        .pipe(
                            finalize(() => {
                                this.finalizeRecalculate();
                            }),
                        )
                        .subscribe((result) => {
                            this.fittedLens = result;
                            this.fittingEventService.lensParameterChanged.emit(
                                new LensParameterChange(previousFittedLens, this.fittedLens, parameterTypeCode),
                            );
                            this.updateParameterFields();
                        });
                    break;
                case LensTypes.Med:
                case LensTypes.MedPlus:
                    if (
                        currentFittedLens.MaterialColor &&
                        currentFittedLens.LensDefinition.Product.ProductGroup.Code !== ProductGroupCodes.SOFT
                    ) {
                        this.medFittedLensCalculator
                            .recalculateFittedLens(currentFittedLens, this.fittedLens, parameterTypeCode)
                            .pipe(
                                finalize(() => {
                                    this.finalizeRecalculate();
                                }),
                            )
                            .subscribe((result) => {
                                this.fittedLens = result;

                                this.fittingEventService.lensParameterChanged.emit(
                                    new LensParameterChange(previousFittedLens, this.fittedLens, parameterTypeCode),
                                );
                                this.updateParameterFields();
                            });
                    } else {
                        this.calculating = false;
                    }
                    break;
                default:
                    this.fittedLensService
                        .recalculateResidualRefraction(currentFittedLens)
                        .pipe(
                            finalize(() => {
                                this.finalizeRecalculate();
                            }),
                        )
                        .subscribe((lens) => {
                            this.fittedLens.ResidualRefractionSphere = lens.ResidualRefractionSphere;
                            this.fittedLens.ResidualRefractionCylinder = lens.ResidualRefractionCylinder;
                            this.fittedLens.ResidualRefractionAx = lens.ResidualRefractionAx;
                            this.fittedLens.FittedLensParameters = currentFittedLens.FittedLensParameters;
                            this.fittingEventService.lensParameterChanged.emit(
                                new LensParameterChange(previousFittedLens, this.fittedLens, parameterTypeCode),
                            );
                        });
                    break;
            }
        } else if (this.fittedLens && this.fittedLens.LensDefinition) {
            const previousFittedLens = structuredClone(this.fittedLens);
            const currentFittedLens = this.readFittedLens();
            this.fittingEventService.lensParameterChanged.emit(
                new LensParameterChange(previousFittedLens, currentFittedLens, parameterTypeCode),
            );
        }
    }

    reset(fittedLens: FittedLens): void {
        this.fittedLens = fittedLens;

        this.initialLensFilterSet = false;

        if (this.isDreamLiteFollowUp) {
            this.dreamliteOptions.reset();
        }

        this.appliedFixTypes = [];
        this.multiFocalAdditionRequired = false;

        if (this.isOverrefractionExpanded) {
            // reset the over refraction stuff (collapsing the panel will reset the control)
            this.isOverrefractionExpanded = false;
            this.isOverrefractionReadonly = false;
        }

        if (this.isLensNotchExpanded) {
            this.isLensNotchExpanded = false;
            this.isLensNotchReadonly = false;
        }

        this.refresh().then();
    }

    applyFixTypes(fixTypeForm: UntypedFormGroup): void {
        let reset = false;
        const fixTypesToApply = [];

        // read the form controls and check which fix types have been checked
        Object.keys(fixTypeForm.controls).forEach((key) => {
            const val = fixTypeForm.controls[key].value;
            let fixTypeToApply: DreamLiteWizardFixType;

            if ((val !== '0' && val !== '' && val !== false) || val === true) {
                switch (key) {
                    case 'lessOrMuchEffect':
                        this.lessOrMuchFixType.CorrectionValue = val;
                        fixTypeToApply = this.lessOrMuchFixType;
                        break;
                    case 'adjustClearance':
                        this.adjustClearanceFixType.CorrectionValue = val;
                        fixTypeToApply = this.adjustClearanceFixType;
                        break;
                    case 'fixTypeSetOz':
                        this.ozFixType.IsApplied = true;
                        fixTypeToApply = this.ozFixType;
                        break;
                    case 'fixTypeLateral':
                        this.lateralFixType.IsApplied = true;
                        fixTypeToApply = this.lateralFixType;
                        break;
                    case 'fixTypeLow':
                        this.lowRidingFixType.IsApplied = true;
                        fixTypeToApply = this.lowRidingFixType;
                        break;
                    case 'fixTypeHigh':
                        this.highRidingFixType.IsApplied = true;
                        fixTypeToApply = this.highRidingFixType;
                        break;
                    case 'toMyopie':
                        fixTypeToApply = this.myopieFixtype;
                        break;
                    case 'convertDreamLite':
                        fixTypeToApply = this.dreamLiteFixType;
                        reset = true;
                        break;
                    case 'convertDreamLiteMC':
                        fixTypeToApply = this.dreamLiteFixTypeMC;
                        reset = true;
                        break;
                    case 'toMultiFocal':
                        this.multiFocalFixType.CorrectionValue = this.dreamliteOptions.multiFocalAddition;
                        fixTypeToApply = this.multiFocalFixType;
                        break;
                    case 'multiFocalAddition':
                        this.multiFocalEffectFixType.CorrectionValue = this.dreamliteOptions.multiFocalAddition;
                        fixTypeToApply = this.multiFocalEffectFixType;
                        break;
                }

                if (fixTypeToApply) {
                    fixTypesToApply.push(fixTypeToApply);
                }
            }
        });

        const dreamLiteWizardInput = new DreamLiteWizardOpticianInput();
        dreamLiteWizardInput.FixTypesToApply = fixTypesToApply;
        dreamLiteWizardInput.FittedLens = this.unmodifiedFittedLens;

        this.calculateLensParametersForOptician(dreamLiteWizardInput);

        if (reset) {
            this.dreamliteOptions.reset();
        }
    }

    calculateLensParametersForOptician(dreamLiteWizardInput: DreamLiteWizardOpticianInput) {
        this.calculating = true;

        this.dreamLiteWizardService
            .calculateLensParametersForOptician(dreamLiteWizardInput)
            .pipe(
                finalize(() => {
                    this.calculating = false;
                }),
            )
            .subscribe((result) => {
                this.appliedFixTypes = dreamLiteWizardInput.FixTypesToApply;

                const currentFittedLensId = Number(this.formControls['lensDefinitionId'].value);

                if (result.Id !== currentFittedLensId) {
                    this.formControls['lensDefinitionId'].patchValue(result.LensDefinitionId, { emitEvent: false });
                }

                this.fittedLens = result;
                this.resetParameterFields();
                return of({});
            });
    }

    // As the material color changes we need to recalculate the lens in certain cases
    handleMaterialChange(): void {
        if (this.fittedLens && this.fittedLens.LensDefinition) {
            const currentFittedLens = this.readFittedLens();
            if (this.fittedLens.LensDefinition.LensTypeId === LensTypes.Rgp) {
                if (this.options.Recalculate) {
                    this.fittedLens = this.rgpFittedLensCalculator.recalculateMaterialChanged(
                        currentFittedLens,
                        this.fittedLens.MaterialColor,
                    );
                }
            }
            this.emitFittedLensChanges();
        }
    }

    handleThicknessChange(): void {
        this.emitFittedLensChanges();
    }

    handleLensEngravingChange(): void {
        this.emitFittedLensChanges();
    }

    handleQuantityChange(quantity: number): void {
        this.quantity = quantity;
        this.fittingEventService.quantityChanged.emit(new QuantityChange(this.fittedLens.EyeSideId, quantity));
    }

    emitFittedLensChanges(): void {
        if (this.fittedLens && this.fittedLens.LensDefinition) {
            const previousFittedLens = structuredClone(this.fittedLens);
            const currentFittedLens = this.readFittedLens();

            this.fittingEventService.fittedLensChanged.emit(
                new FittedLensChange(previousFittedLens, currentFittedLens),
            );
        }
    }

    async loadFixTypes(): Promise<void> {
        const result = await lastValueFrom(
            this.dreamLiteWizardService.getAllFixTypes(this.fittedLens.LensDefinitionId),
        );

        this.fixTypes = new Array<DreamLiteWizardFixType>();
        this.fixTypeRanges = {};

        result.forEach((item) => {
            item.Visible = true;
            item.DegreeValue = 2;
            item.CorrectionValue = 0;
            item.IsApplied = false;

            if (item.ShowCorrection) {
                //alleen bij sagitta/Adjustclearance input number
                const rangeModel = new InputNumberRange(item.MinValue, item.MaxValue, item.Step);
                this.fixTypeRanges[item.Code] = new Array<InputNumberRange>(rangeModel);
            }

            const fixTypes = [
                'HighRiding',
                'LowRiding',
                'LateralDecentration',
                'LessOrMuchEffect',
                'AdjustClearance',
                'SetOZ',
                'ToMyopie',
                'ToMultifocal',
                'MultiFocalEffect',
                'ConvertDreamlite',
                'ConvertDreamliteMC',
            ];

            if (fixTypes.find((i) => item.Code === i) != null) {
                switch (item.Code) {
                    case 'LessOrMuchEffect':
                        item.Visible = false;
                        this.lessOrMuchFixType = structuredClone(item);
                        break;
                    case 'AdjustClearance':
                        this.adjustClearanceFixType = structuredClone(item);
                        break;
                    case 'SetOZ':
                        item.Visible = false;
                        this.ozFixType = structuredClone(item);
                        break;
                    case 'HighRiding':
                        this.highRidingFixType = structuredClone(item);
                        break;
                    case 'LowRiding':
                        this.lowRidingFixType = structuredClone(item);
                        break;
                    case 'LateralDecentration':
                        this.lateralFixType = structuredClone(item);
                        break;
                    case 'ToMyopie':
                        this.myopieFixtype = structuredClone(item);
                        break;
                    case 'ConvertDreamlite':
                        this.dreamLiteFixType = structuredClone(item);
                        break;
                    case 'ConvertDreamliteMC':
                        this.dreamLiteFixTypeMC = structuredClone(item);
                        break;
                    case 'ToMultifocal':
                        this.multiFocalFixType = structuredClone(item);
                        break;
                    case 'MultiFocalEffect':
                        this.multiFocalEffectFixType = structuredClone(item);
                        break;
                }

                this.fixTypes.push(item);
            } else {
                item.Visible = false;
            }
        });
    }

    clickMeasurementImage(event: MouseEvent, measurement: TopographicMeasurement): void {
        event.stopPropagation();

        this.loaderService.show();

        const requestModel = new GetTopographicMeasurementsByClientIdRequest();
        requestModel.clientId = this.client.Id;
        requestModel.pageSize = 999;
        requestModel.pageIndex = 0;
        requestModel.loadLeftTopographicMeasurements = measurement.EyeSideId === EyeSides.Os;
        requestModel.loadRightTopographicMeasurements = measurement.EyeSideId === EyeSides.Od;

        this.topographicMeasurementService
            .getTopographicMeasurementsByClientId(requestModel)
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe((result) => {
                const topoMeasurements =
                    measurement.EyeSideId === EyeSides.Od
                        ? result.RightTopographicMeasurements
                        : result.LeftTopographicMeasurements;
                this.showMeasurementReviewDialog(measurement, topoMeasurements);
            });
    }

    toggleOverrefraction(): void {
        // don't allow overrefraction to be collapsed when the values have been used in the calculation
        if (this.isOverrefractionReadonly) {
            return;
        }

        this.isOverrefractionExpanded = !this.isOverrefractionExpanded;
    }

    toggleLensNotch(): void {
        if (this.isLensNotchReadonly) {
            return;
        }

        this.isLensNotchExpanded = !this.isLensNotchExpanded;
    }

    async applyOverrefraction(overrefractionResult: OverRefractionResult): Promise<void> {
        this.calculating = true;

        const overrefraction = new FittedLensOverrefraction();
        overrefraction.OverrefractionParameters = overrefractionResult.OverrefractionParameters;
        overrefraction.Stabilization = overrefractionResult.Stabilization;

        const fittedLens = this.readFittedLens();

        overrefraction.InitialFittedLens = structuredClone(this.fittedLens);
        overrefraction.CalculatedFittedLens = structuredClone(fittedLens);
        overrefraction.OverrefractionParameters.push(await this.getVertexParameter());
        const result = await lastValueFrom(this.fittedLensService.calculateOverrefraction(overrefraction));
        result.RefractionMeasurement = fittedLens.RefractionMeasurement;
        result.TopographicMeasurement = fittedLens.TopographicMeasurement;
        this.fittedLens = result;
        this.updateFields();

        this.fittingEventService.fittedLensChanged.emit(
            new FittedLensChange(overrefraction.InitialFittedLens, this.fittedLens),
        );

        this.isOverrefractionReadonly = true;
        this.calculating = false;
    }

    async applyLensNotch(lnr: LensNotchResult): Promise<void> {
        const updateLensParameter = function (
            fittedLensParameters: FittedLensParameter[],
            code: string,
            value: number,
        ) {
            const flp = fittedLensParameters.find(
                (flp: FittedLensParameter) => flp.LensDefinitionParameter.ParameterType.Code === code,
            );
            if (!!flp) {
                flp.Value = value;
            }
        };

        updateLensParameter(this.fittedLensParameters, 'NOTCHPOSITION', lnr.position);
        updateLensParameter(this.fittedLensParameters, 'NOTCHCHORD', lnr.recessChord);
        updateLensParameter(this.fittedLensParameters, 'NOTCHDEPTH', lnr.recessDepth);
    }

    applyLensNotchReadOnly(lensNotchReadOnly: boolean): void {
        this.isLensNotchReadonly = lensNotchReadOnly;
    }

    showMeasurementReviewDialog(measurement: TopographicMeasurement, topoMeasurements: TopographicMeasurement[]): void {
        const imageOptions = structuredClone(this.diffMapImageOptions);
        imageOptions.ShowImageChoice = true;
        imageOptions.ShowImageTypes = false;

        const options: unknown = {
            initialState: {
                currentMeasurement: measurement,
                topoMeasurements: topoMeasurements,
                imageOptions: imageOptions,
                client: this.client,
            },
            class: 'measurement-review-dialog',
        };

        this.modalService.show(MeasurementReviewDialogComponent, options);
    }

    lensHasParameterWithCode(code: string): boolean {
        if (this.fittedLens.FittedLensParameters) {
            return (
                this.fittedLens.FittedLensParameters.find(
                    (l) => l.LensDefinitionParameter.ParameterType.Code === code,
                ) != null
            );
        }
        return false;
    }

    productGroupFilterClass(productGroupCode: string): string {
        return this.productGroupFilter === productGroupCode ? 'active' : '';
    }

    checkFilterGroups(): void {
        const checkProductGroupCode = (filter: ProductGroupCodes) =>
            this.lensDefinitions?.some((ld: LensDefinitionListOption) => ld.ProductGroupCode?.includes(filter)) ??
            false;

        this.filterProductGroups[ProductGroupCodes.KC] = checkProductGroupCode(ProductGroupCodes.KC);
        this.filterProductGroups[ProductGroupCodes.EMIN] = checkProductGroupCode(ProductGroupCodes.EMIN);
        this.filterProductGroups[ProductGroupCodes.SCLERAL] = checkProductGroupCode(ProductGroupCodes.SCLERAL);
        this.filterProductGroups[ProductGroupCodes.SOFT] = checkProductGroupCode(ProductGroupCodes.SOFT);
    }

    lensFilterClass(lensFilter: LensFilters): string {
        return (lensFilter === this.lensFilter ? 'active' : '') + this.classForLensType();
    }

    classForLensType(): string {
        if (this.fittedLens && this.fittedLens.LensDefinition) {
            switch (this.fittedLens.LensDefinition.LensTypeId) {
                case LensTypes.DreamLite:
                    return ' lensType Dreamlite';
                case LensTypes.Soft:
                    return ' lensType Soft';
                case LensTypes.Rgp:
                    return ' lensType Rgp';
                case LensTypes.Med:
                case LensTypes.MedPlus:
                    return ' lensType Med';
                default:
                    return ' ';
            }
        }
    }

    sagittaOk(): boolean {
        if (
            this.fittedLens &&
            this.fittedLens.LensDefinition &&
            this.fittedLens.LensDefinition.LensTypeId === LensTypes.Soft
        ) {
            return (
                this.fittedLens.IsOptimalLensDefinition &&
                this.fittedLens.LensDefinition.Disposable &&
                this.fittedLens.DisposableLensCalculated
            );
        }
        return false;
    }

    sagittaIdeal(): boolean {
        if (
            this.fittedLens &&
            this.fittedLens.LensDefinition &&
            this.fittedLens.LensDefinition.LensTypeId === LensTypes.Soft
        ) {
            return this.fittedLens.IsOptimalLensDefinition && !this.fittedLens.LensDefinition.Disposable;
        }
        return false;
    }

    sagittaNotIdeal(): boolean {
        if (
            this.fittedLens &&
            this.fittedLens.LensDefinition &&
            this.fittedLens.LensDefinition.LensTypeId === LensTypes.Soft
        ) {
            return (
                this.fittedLens.IsOptimalLensDefinition &&
                this.fittedLens.LensDefinition.Disposable &&
                !this.fittedLens.DisposableLensCalculated
            );
        }
    }
    //#endregion "Helper methods"

    copyLensInfo(): void {
        this.onCopyLensInfo.emit();
    }

    async receiveCopy(copyData: CopyLensData): Promise<void> {
        this.doPulse = true;
        setTimeout(() => (this.doPulse = false), 1000);

        if (copyData.lensDefinitionId !== this.fittedLens.LensDefinitionId) {
            await this.changeLensDefinition(copyData.lensDefinitionId);
        }
        this.lensselection.formControls['materialId'].patchValue(copyData.materialId);
        this.lensselection.formControls['colorId'].patchValue(copyData.colorId);

        const params = copyData.parameters;
        const controls = this.formGroup.controls['parameters']['controls'];

        for (let i = 0; i < controls.length; i++) {
            const key = controls.length - i - 1;
            controls[key].patchValue(params[key], { emitEvent: true });
        }
    }

    // general

    // lens filters

    // lens preivous params

    // getters and setters
    get canCopy(): boolean {
        return !this.calculating && this.showCopyButton;
    }

    get isMultifocal(): boolean {
        const lensDef = this.fittedLens.LensDefinition;
        return lensDef.IsMultifocal || lensDef.IsMultifocalToric;
    }

    get overrefractionAvailable(): boolean {
        return (
            !this.appState.isCompanyFeatureEnabled(Features.HideOverrefraction) && this.options.ShowOverRefractionButton
        );
    }

    get showResidualRefraction(): boolean {
        return !this.appState.isCompanyFeatureEnabled(Features.HideResidualRefraction) && !this.isDreamLiteFollowUp;
    }

    async getVertexParameter(): Promise<OverrefractionParameter> {
        const vertexparam = new OverrefractionParameter();
        vertexparam.Id = 24;
        vertexparam.Name = 'Vertex';
        vertexparam.Code = 'VERTD';
        vertexparam.Value = await this.appState.getVertex();
        return vertexparam;
    }

    getUnit(ldp: LensDefinitionParameter): string {
        return LensDefinitionParameterUnit.getUnit(ldp);
    }

    get sagitta(): string {
        let result = '';
        if (this.sagittaOk()) {
            result = 'fitlens.sagittaok';
        } else if (this.sagittaIdeal()) {
            result = 'fitlens.sagittaideal';
        } else if (this.sagittaNotIdeal()) {
            result = 'fitlens.sagittanotideal';
        }
        return result;
    }

    get sagittaClass(): string {
        let result = '';
        if (this.sagittaOk() || this.sagittaIdeal()) {
            result = 'text-success';
        } else if (this.sagittaNotIdeal()) {
            result = 'text-warning';
        }
        return result;
    }

    get lensSelectionEnabled(): boolean {
        return (
            this.isPs ||
            this.isDistributorSupport ||
            (!this.isDreamLiteFollowUp &&
                !this.showConvertToMcButton &&
                !this.showConvertToMfButton &&
                !this.convertedToMf)
        );
    }

    get lensTypeId(): number {
        return this.options && this.options.LensTypeId ? this.options.LensTypeId : this.fittedLens.FittedLensTypeId;
    }

    get isFitlensPage(): boolean {
        const regExForSupportPageWithFitlens = new RegExp('^/professional-service/supportorder/[0-9]+$');
        return this.router.url === '/fitlens' || regExForSupportPageWithFitlens.test(this.router.url);
    }

    get isWebshop(): boolean {
        return this.router.url === '/shop';
    }

    getFittedLensParameterValue(fittedLens: FittedLens, parameterTypeName: string): string {
        const parameter =
            fittedLens &&
            fittedLens.FittedLensParameters &&
            fittedLens.FittedLensParameters.find(
                (flp) =>
                    flp.LensDefinitionParameter &&
                    flp.LensDefinitionParameter.ParameterType &&
                    flp.LensDefinitionParameter.ParameterType.Code === parameterTypeName,
            );

        if (parameter) {
            return this.numberFormatPipe.transform(parameter.Value, {
                parameterType: parameterTypeName,
            });
        }

        return '';
    }

    get showConvertToMcButton(): boolean {
        if (
            this.isDreamLiteFollowUp &&
            this.client.Myopie &&
            !this.appliedFixTypes.find((ft) => ft.Code === 'ToMyopie')
        ) {
            const lensDefinitionId = Number(this.formControls['lensDefinitionId'].value);

            switch (lensDefinitionId) {
                case 27: // Dreamlite
                case 28: // DreamLite nachtlenzen TRX
                case 62: // Dreamlite Zoom
                case 63: // Dreamlite Zoom TRX
                    return true;
            }
            return false;
        }
        return false;
    }

    get showConvertToMfButton(): boolean {
        if (
            this.isDreamLiteFollowUp &&
            !this.client.Myopie &&
            !this.appliedFixTypes.find((ft) => ft.Code === 'ToMultifocal')
        ) {
            const lensDefinitionId = Number(this.formControls['lensDefinitionId'].value);

            switch (lensDefinitionId) {
                case 27: // Dreamlite
                case 28: // DreamLite nachtlenzen TRX
                    return true;
            }
            return false;
        }
        return false;
    }

    get showConvertToDreamliteButton(): boolean {
        if (this.isDreamLiteFollowUp && this.isDreamLite) {
            const lensDefinitionId = Number(this.formControls['lensDefinitionId'].value);

            switch (lensDefinitionId) {
                case 300: // DreamLite MC
                case 301: // Dreamlite MCT
                    return true;
            }
        }

        return false;
    }

    get showConvertToDreamliteMCButton(): boolean {
        if (this.isDreamLiteFollowUp && this.isDreamLite) {
            const lensDefinitionId = Number(this.formControls['lensDefinitionId'].value);
            switch (lensDefinitionId) {
                case 27: // Dreamlite
                case 28: // DreamLite nachtlenzen TRX
                    return true;
            }
        }

        return false;
    }

    getOriginalValue(flp: FittedLensParameter): number {
        if (!this.isDreamLiteFollowUp) {
            return null;
        }

        const orgLens = this.lensFromPreviousOrder;
        if (!orgLens) {
            return null;
        }

        const code = flp.LensDefinitionParameter.ParameterType.Code;

        const orgParam = orgLens.FittedLensParameters.find(
            (x) => x.LensDefinitionParameter.ParameterType.Code === code,
        );
        if (!orgParam) {
            return null;
        }

        return orgParam.Value;
    }

    get convertedToMf(): boolean {
        return !!this.appliedFixTypes.find((ft) => ft.Code === 'ToMultifocal');
    }

    get calculatedAddition(): number {
        if ((this.isPs || this.isDistributorSupport) && this.isDreamLite && this.isMultifocal) {
            if (this.formControls) {
                let rad = 0.0;
                let radMf = 0.0;
                const parameters = this.formControls['parameters'] as UntypedFormArray;

                this.visibleLensParameters.forEach((flp: FittedLensParameter, i: number) => {
                    if (flp.LensDefinitionParameter.ParameterType.Code === ParameterTypeCodes.RAD) {
                        rad = parameters.controls[i].value;
                    }
                    if (flp.LensDefinitionParameter.ParameterType.Code === ParameterTypeCodes.RMF) {
                        radMf = parameters.controls[i].value;
                    }
                });

                if (rad > 0 && radMf > 0) {
                    return 337.5 / radMf - 337.5 / rad;
                }
            }
        }
        return 0;
    }

    //#region "Helper methods"
    get diffMapImageOptions(): ImageOptions {
        const imageOptions = new ImageOptions(null);
        imageOptions.ShouldOpenDialog = true;
        imageOptions.ImageChoice = 'diff';
        imageOptions.topoImageOptions.ImageChoice = imageOptions.ImageChoice;
        imageOptions.topoImageOptions.UseAxis = true;
        imageOptions.topoImageOptions.UseMm = true;
        imageOptions.topoImageOptions.UseNormalize = false;
        return imageOptions;
    }

    getFittedLensParameterIndex(code: string): number {
        for (let i = 0; i < this.visibleLensParameters.length; i++) {
            if (this.visibleLensParameters[i].LensDefinitionParameter.ParameterType.Code === code) {
                return i;
            }
        }

        return null;
    }

    setMaterialColors(trySelectMaterialColorId: number): void {
        if (
            this.options.ShowMaterialField &&
            this.fittedLens.LensDefinition &&
            this.fittedLens.LensDefinition.Product
        ) {
            // get the material colors list
            // the material colors are based on the product group of the lens definition
            this.materialService
                .getMaterialColorsByProductGroupId(this.fittedLens.LensDefinition.Product.ProductGroupId)
                .subscribe((result) => {
                    this.materialColors = result;
                    const materials = this.materialColors.map(
                        (mc) => new ListOption(mc.Material.Id, mc.Material.Name, mc.Material.Code),
                    );

                    const uniqueMaterials = materials.filter(
                        (m, i) => i === materials.findIndex((other) => m.Id === other.Id),
                    );
                    this.materials = uniqueMaterials.sort((a, b) => a.Name.localeCompare(b.Name));

                    const materialColor = this.materialColors.find((mc) => mc.Id === trySelectMaterialColorId);

                    // determine selected material
                    const firstMaterial = this.materials?.length ? this.materials[0] : null;
                    const selectedMaterialId = materialColor
                        ? materialColor.MaterialId
                        : firstMaterial
                          ? firstMaterial.Id
                          : null;

                    this.lensselection.formControls['materialId'].patchValue(selectedMaterialId, { emitEvent: false });
                    this.loadColors(selectedMaterialId, materialColor ? materialColor.ColorId : null);
                });
        } else {
            this.materialColors = null;
            this.materials = null;
            this.lensselection.formControls['materialId'].patchValue(null, {
                emitEvent: false,
            });
            this.lensselection.formControls['colorId'].patchValue(null, {
                emitEvent: false,
            });
        }
    }

    loadColors(materialId: number, trySelectColorId: number): void {
        if (this.options.ShowMaterialField) {
            // filter colors on material and determine selected color
            const filteredColors = this.materialColors
                .filter((mc) => mc.MaterialId === materialId)
                .map((mc) => new ListOption(mc.Color.Id, mc.Color.Name, mc.Color.Code));

            const uniqueFilteredColors = [...new Set(filteredColors)];
            this.colors = uniqueFilteredColors.sort((a, b) => a.Name.localeCompare(b.Name));

            const firstColor = this.colors?.length ? this.colors[0] : null;
            const selectedColorId = trySelectColorId ? trySelectColorId : firstColor ? firstColor.Id : null;
            this.lensselection.formControls['colorId'].patchValue(selectedColorId, { emitEvent: false });
        } else {
            this.lensselection.formControls['colorId'].patchValue(null, {
                emitEvent: false,
            });
        }
    }

    get visibleLensParameters(): FittedLensParameter[] {
        const visableParameters = this.fittedLensParameters?.filter((flp) => {
            const isVisible =
                flp.LensDefinitionParameter.ParameterType.Code === 'RMF'
                    ? this.isPs
                    : !flp.LensDefinitionParameter.IsHidden;

            return isVisible || this.options.ShowHiddenParams;
        });

        return visableParameters ?? [];
    }

    // this function combines the official lensdefinition parameters (fittedLens.LensDefinition.LensDefinition)
    // with the already present parameter values (fittedLens.FittedLensParameters)
    getFittedLensParameters(): FittedLensParameter[] {
        if (this.fittedLens && this.fittedLens.LensDefinition) {
            const result = this.fittedLens.LensDefinition.LensDefinitionParameters.map((p) => {
                const flp = new FittedLensParameter();
                flp.FittedLensId = this.fittedLens.Id;
                flp.LensDefinitionParameterId = p.Id;
                flp.LensDefinitionParameter = p;
                flp.LensDefinitionParameter.NumberFormatOptions = new NumberFormatOptions();
                flp.LensDefinitionParameter.NumberFormatOptions.parameterType =
                    flp.LensDefinitionParameter.ParameterType.Code;

                if (this.fittedLens.FittedLensParameters) {
                    const v = this.fittedLens.FittedLensParameters.find((lp) => lp.LensDefinitionParameterId === p.Id);
                    if (v) {
                        flp.Id = v.Id;
                        flp.Value = v.Value;
                        flp.TextValue = v.TextValue;
                    }
                }

                return flp;
            });

            return result.sort(
                (a, b) =>
                    a.LensDefinitionParameter.ParameterType.SortOrder -
                    b.LensDefinitionParameter.ParameterType.SortOrder,
            );
        }
    }

    get canShowMarkings(): boolean {
        return this.options && this.options.ShowMarkingsField && this.lensTypeId !== LensTypes.Soft;
    }

    get canShowThickness(): boolean {
        return this.options && this.options.ShowThicknessField && this.lensTypeId !== LensTypes.DreamLite;
    }

    get isLensDefinitionDisposable(): boolean {
        return this.fittedLens && this.fittedLens.LensDefinition && this.fittedLens.LensDefinition.Disposable;
    }

    get isByoComfort(): boolean {
        return this.fittedLens.LensDefinition.Product.ProductGroup.Id === +ProductGroupCodes.BYOCOMFORT;
    }

    get isByoPromedics(): boolean {
        return this.fittedLens.LensDefinition.Product.ProductGroup.Id === +ProductGroupCodes.BYOPROMEDICS;
    }

    get isDreamLite(): boolean {
        return this.lensTypeId === LensTypes.DreamLite;
    }

    async setProductGroupFilter(productGroupCode: string): Promise<void> {
        if (productGroupCode !== this.productGroupFilter) {
            this.productGroupFilter = productGroupCode;
            this.lensFilter = LensFilters.all;
            this.filteredLensDefinitions = this.getFilteredLensDefinitions();

            if (productGroupCode) {
                this.selectFirstLens(true).then();
            } else if (!this.filteredLensDefinitions || this.filteredLensDefinitions.length === 0) {
                await this.changeLensDefinition(null);
            }
        }
    }

    async setLensFilter(lensFilter: LensFilters): Promise<void> {
        this.lensFilter = lensFilter;
        this.filteredLensDefinitions = this.getFilteredLensDefinitions();
        this.lensselection.formControls['lensDefinitionId'].setValue(null);

        if (lensFilter !== LensFilters.all) {
            this.selectFirstLens(true).then();
        } else if (!this.filteredLensDefinitions || this.filteredLensDefinitions.length === 0) {
            await this.changeLensDefinition(null);
        }
    }

    // returns the lens definitions filtered by product group & lens filter
    getFilteredLensDefinitions(): LensDefinitionListOption[] {
        if (this.lensDefinitions) {
            return this.lensDefinitions.filter((ld) => this.lensDefinitionFilter(ld));
        }
    }

    setLoading(loading: boolean) {
        this.loading = loading;
        this.isLoading.emit(loading);
    }

    // set the lens filter and product group filters
    setInitialLensFilterAndProductGroup(): void {
        if (this.fittedLens.LensDefinition) {
            // product group filter
            if (
                this.options.EnableProductGroupFilter &&
                this.fittedLens.LensDefinition.Product &&
                (this.fittedLens.LensDefinition.LensTypeId === LensTypes.Med ||
                    this.fittedLens.LensDefinition.LensTypeId === LensTypes.MedPlus)
            ) {
                if (ProductGroups.isScleralProductGroup(this.fittedLens.LensDefinition.Product.ProductGroup.Code)) {
                    this.productGroupFilter = ProductGroupCodes.SCLERAL;
                } else {
                    switch (this.fittedLens.LensDefinition.Product.ProductGroup.Code) {
                        case ProductGroupCodes.EMIN:
                            this.productGroupFilter = ProductGroupCodes.EMIN;
                            break;
                        case ProductGroupCodes.KC:
                            this.productGroupFilter = ProductGroupCodes.KC;
                            break;
                        case ProductGroupCodes.SOFT:
                            this.productGroupFilter = ProductGroupCodes.SOFT;
                            break;
                        default:
                            this.productGroupFilter = '';
                            break;
                    }
                }
            } else {
                this.productGroupFilter = '';
            }

            // lensfilter type
            if (this.fittedLens.LensDefinition.IsSpherical) {
                this.lensFilter = LensFilters.spherical;
            } else if (this.fittedLens.LensDefinition.IsToric) {
                this.lensFilter = LensFilters.toric;
            } else if (this.fittedLens.LensDefinition.IsMultifocal) {
                this.lensFilter = LensFilters.multiFocal;
            } else if (this.fittedLens.LensDefinition.IsMultifocalToric) {
                this.lensFilter = LensFilters.multiFocalToric;
            } else {
                this.lensFilter = LensFilters.all;
            }
        } else {
            this.lensFilter = LensFilters.all;
            this.productGroupFilter = '';
        }
        this.initialLensFilterSet = true;
    }

    get getUnmodifiedFittedLens(): FittedLens {
        return this.unmodifiedFittedLens;
    }

    get formDisabled() {
        return this.calculating || this.loading;
    }

    get getMaterials() {
        return this.materials;
    }

    get getColors() {
        return this.colors;
    }

    get getMaterialColors() {
        return this.materialColors;
    }

    get getLensEngravings() {
        return this.lensEngravings;
    }

    get getLensThickness() {
        return this.lensThickness;
    }

    get radDiamError(): boolean {
        return this.formGroup.errors ? this.formGroup.errors['RadDiamError'] : false;
    }

    setColors(colors: ListOption[]): void {
        this.colors = colors;
    }

    get areFormGroupsValid(): boolean {
        return this.formGroup.valid && this.lensselection.lensSelectionFormGroup.valid;
    }

    get isConfigurationDisabled(): boolean {
        return this.configurationDisabled;
    }

    get byoComfortWarning(): string {
        if (this.isWebshop) {
            return '';
        }

        if (
            this.fittedLens.LensDefinition.LensTypeId === LensTypes.Soft &&
            (this.isByoComfort || this.isByoPromedics) &&
            this.fittedLens.RefractionMeasurement.NearAdd &&
            this.fittedLens.RefractionMeasurement.NearAdd !== 0 &&
            this.fittedLens.RefractionMeasurement.Cylinder &&
            this.fittedLens.RefractionMeasurement.Cylinder !== 0
        ) {
            return 'warning.byocomfortnozoomandcylinder';
        }

        return '';
    }

    get eyeSide(): string {
        return this.fittedLens.EyeSideId == EyeSides.Od ? 'od' : 'os';
    }

    private checkNotchAvailable(): void {
        if (this.appState.isCompanyFeatureEnabled(Features.LensNotch)) {
            this.notchAvailable = this.checkNotchRequiredParameters();
        }
    }

    private checkNotchRequiredParameters(): boolean {
        if (!!this.fittedLensParameters) {
            let result = true;
            const requiredParameters = ['NOTCHPOSITION', 'NOTCHCHORD', 'NOTCHDEPTH'];

            requiredParameters.forEach((requiredParameter: string) => {
                const exists = this.fittedLensParameters.find(
                    (flp: FittedLensParameter) => flp.LensDefinitionParameter.ParameterType.Code === requiredParameter,
                );
                if (!exists) {
                    result = false;
                }
            });

            return result;
        }

        return false;
    }

    refreshParameters() {
        for (const lensParameter of this.visibleLensParameters) {
            lensParameter.LensDefinitionParameter.LensDefinitionParameterRanges = [
                ...lensParameter.LensDefinitionParameter.LensDefinitionParameterRanges,
            ] as LensDefinitionParameterText[] | LensDefinitionParameterNumberRange[];
        }

        const formArray = this.formGroup.get('parameters') as FormArray;
        const values = formArray.value;

        values.forEach((value: unknown, index: string | number) => {
            formArray.controls[index].patchValue(value);
        });
    }

    onStabilizationChange(stabilization: number) {
        this.stabilization = stabilization;
    }

    onInputEvent() {
        this.refreshParameters();
    }

    onInputFocus($event: Event) {
        this.lastFocusedElementId = ($event.target as HTMLInputElement).id;
    }

    onInputBlur($event: Event) {
        if (this.lastFocusedElementId === ($event.target as HTMLInputElement).id && !this.calculating) {
            this.lastFocusedElementId = null;
        }
    }

    finalizeRecalculate() {
        this.calculating = false;
        setTimeout(() => {
            this.resetFocus();
        }, 0);
    }

    private setInitialVisibleLensParameterValues(): void {
        this.initialVisibleLensParameterValues = JSON.parse(JSON.stringify(this.visibleLensParameters));
        this.changedVisibleLensDefinitionParameterIds = [];
    }

    private addChangedVisibleLensParameterId(): void {
        if (!this.fittedLens.LensDefinition.Product.ShowSystemSuggestedValuesMessage) {
            return;
        }

        if (!this.initialVisibleLensParameterValues) {
            this.setInitialVisibleLensParameterValues();
        }

        this.visibleLensParameters.forEach((flp) => {
            this.initialVisibleLensParameterValues?.forEach((ivlp) => {
                const isSameLensDefinitionParameter = flp.LensDefinitionParameterId === ivlp.LensDefinitionParameterId;
                const parameterValueIsChanged = ivlp.Value !== flp.Value || ivlp.TextValue !== flp.TextValue;
                const isParameterChangedBefore = this.changedVisibleLensDefinitionParameterIds.includes(
                    flp.LensDefinitionParameterId,
                );

                if (isSameLensDefinitionParameter && !isParameterChangedBefore && parameterValueIsChanged) {
                    this.changedVisibleLensDefinitionParameterIds.push(flp.LensDefinitionParameterId);
                }
            });
        });
    }
}
