import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UpdateGrandVisionOrder } from '@app/professional-service/models/UpdateGrandvisionOrder.model';
import { AlertService } from '@app/shared/appservices/alert.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { Order } from '@app/shared/models';
import { OrderService } from '@app/core/services/api/order.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ps-grandvision',
    templateUrl: './ps-grandvision.component.html',
})
export class PsGrandvisionComponent implements OnInit {
    @Input() order: Order;

    formGroup: UntypedFormGroup;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly loaderService: LoaderService,
        private readonly alertService: AlertService,
        private readonly orderService: OrderService,
        private readonly translateService: TranslateService,
    ) {}

    public ngOnInit(): void {
        this.createForm();
    }

    private createForm(): void {
        this.formGroup = this.fb.group({
            perosOrderNumber: [
                this.order.PerosOrderNumber,
                [
                    Validators.required,
                    Validators.nullValidator,
                    Validators.minLength(11),
                    Validators.maxLength(11),
                    Validators.pattern('^(?=.*\\d)[\\d]{1,}$'),
                ],
            ],
            poOrderNumber: [this.order.PoNumber],
        });
    }

    public save(): void {
        if (this.formGroup.invalid) {
            return;
        }

        this.loaderService.show();

        const updateGrandVisionOrder = new UpdateGrandVisionOrder();
        updateGrandVisionOrder.OrderId = this.order.Id;
        updateGrandVisionOrder.PerosNumber = this.formGroup.get('perosOrderNumber').value;
        updateGrandVisionOrder.PoNumber = this.formGroup.get('poOrderNumber').value;

        this.orderService.updateGrandvisionOrder(updateGrandVisionOrder).subscribe(
            () => {
                this.loaderService.hide();
                this.alertService.success(this.translateService.instant('general.saveSuccessful'));
            },
            () => {
                this.loaderService.hide();
                this.alertService.error(this.translateService.instant('error.saveError'));
            },
        );
    }

    public isOrdered(): boolean {
        return this.order.ReadyForMiddlewareExport || !!this.order.MiddlewareExportDate;
    }
}
