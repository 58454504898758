<div class="process-nav-ps process-nav-1 d-flex">
    <div class="process-nav-inner d-flex mx-auto px-1">
        <div class="w-100 d-flex">
            <div *ngIf="supporters" [formGroup]="supporterFormGroup" class="supporter">
                <div class="select-form">
                    <mpc-select
                        formControlName="supporter"
                        id="supporter"
                        [formControlOnly]="true"
                        [items]="supporters"
                        label=""
                        noSelectionLabel=""
                        [breakpoint]="showPrevious ? 'md' : ''"
                        (change)="updateSupporter()"></mpc-select>
                </div>
                <div *ngIf="supportOrderStatus">
                    {{ 'supportoverview.SupportOrderStatusCode' | translate }}:
                    {{ supportOrderStatus.Name }}
                    <img alt="priority" *ngIf="order.PriorityId === 2" [src]="priorityImageSource" />
                </div>
            </div>
            <div class="supporter-divider divider"></div>

            <div class="flex-grow-1 d-flex"></div>

            <ng-container *ngIf="order">
                <ps-header-item
                    [title]="this.LongClientReference"
                    [subtitle]="order.Optician.Name"
                    [className]="currentPage === 'clientAndOptician' ? 'current' : ''"
                    iconName="address-card"
                    (click)="onHeaderItem('clientAndOptician')"></ps-header-item>

                <div class="flex-grow-1 d-flex"></div>
            </ng-container>
            <ps-header-item
                [title]="'ps.order' | translate"
                [className]="currentPage === 'order' ? 'current' : ''"
                iconName="shopping-bag"
                (click)="onHeaderItem('order')"></ps-header-item>

            <div class="flex-grow-1 d-flex"></div>
            <ps-header-item
                [title]="'ps.measurements' | translate"
                [className]="currentPage === 'measurements' ? 'current' : ''"
                imageName="measurement.svg"
                (click)="onHeaderItem('measurements')"></ps-header-item>

            <div class="flex-grow-1 d-flex"></div>
            <ps-header-item
                [title]="'ps.attachments' | translate"
                [className]="currentPage === 'attachments' ? 'current' : ''"
                iconName="paperclip"
                (click)="onHeaderItem('attachments')"></ps-header-item>
        </div>
    </div>
</div>
