import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '@app/shared/models';

@Pipe({
    name: 'downloadableProduct',
})
export class DownloadableProductPipe implements PipeTransform {
    transform(product?: Product) {
        return product && product.DownloadUrl && product.DownloadUrl !== '';
    }
}
