<div class="container-fluid mt-3 mb-4 px-lg-4 flex-fill">
    <div class="row">
        <div class="col-2 mt-3">
            <h3 class="mt-auto">
                {{ 'ps.complaintsoverview.lensservicerequest' | translate }}
            </h3>
            <div
                class="servicerequestbtn mb-2 AwaitingProduct accented-background"
                (click)="onClickType(returnStatuses.AwaitingProduct)"
                [ngClass]="checkIfSelected(returnStatuses.AwaitingProduct)">
                {{ 'ps.complaintsoverview.lensservicerequest.awaitingProduct' | translate }}
            </div>

            <div
                class="servicerequestbtn mb-2 Received accented-background"
                (click)="onClickType(returnStatuses.Received)"
                [ngClass]="checkIfSelected(returnStatuses.Received)">
                {{ 'ps.complaintsoverview.lensservicerequest.Received' | translate }}
            </div>

            <div
                class="servicerequestbtn mb-2 QaHandling accented-background"
                (click)="onClickType(returnStatuses.QaHandling)"
                [ngClass]="checkIfSelected(returnStatuses.QaHandling)">
                {{ 'ps.complaintsoverview.lensservicerequest.qaHandling' | translate }}
            </div>

            <div
                class="servicerequestbtn mb-2 PsHandling accented-background"
                (click)="onClickType(returnStatuses.PsHandling)"
                [ngClass]="checkIfSelected(returnStatuses.PsHandling)">
                {{ 'ps.complaintsoverview.lensservicerequest.psHandling' | translate }}
            </div>

            <div
                class="servicerequestbtn mb-2 Handled accented-background"
                (click)="onClickType(returnStatuses.Handled)"
                [ngClass]="checkIfSelected(returnStatuses.Handled)">
                {{ 'ps.complaintsoverview.lensservicerequest.Handled' | translate }}
            </div>

            <div class="mt-4 mb-3">
                <h3 class="mt-auto">
                    {{ 'ps.complaintsoverview.creditrequest' | translate }}
                </h3>
            </div>

            <div
                class="servicerequestbtn mb-2 credits accented-background"
                (click)="onClickCreditType(1)"
                [ngClass]="creditTypeSelected === 1 ? 'on' : ''">
                {{ 'ps.complaintsoverview.creditrequest.credits' | translate }}
            </div>

            <div
                class="servicerequestbtn credits_completed accented-background"
                (click)="onClickCreditType(2)"
                [ngClass]="creditTypeSelected === 2 ? 'on' : ''">
                {{ 'ps.complaintsoverview.creditrequest.credits_completed' | translate }}
            </div>
        </div>

        <div class="col-10">
            <complaints-overview-table
                [returnStatusIds]="returnStatusIds"
                [creditCategory]="creditCategory"
                (selectedReturn)="receiveReturnParameters($event)"
                (redirectToReturnSelected)="receiveReturnRedirect($event)"></complaints-overview-table>
        </div>
    </div>
</div>

<div *footer>
    <div class="d-flex align-items-center">
        <a [routerLink]="['/']" class="col-9">
            <i class="fas fa-arrow-alt-circle-left me-1"></i>
            {{ 'general.home' | translate }}
        </a>
        <div class="col-3">
            <app-return-history
                [selectedReturn]="returnSelected"
                [redirectToSelectedReturn]="redirectReturnSelected"></app-return-history>
        </div>
    </div>
</div>
