import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { ProposalReceiptService } from '@app/core/services/api/proposal-receipt.service';
import { Observable } from 'rxjs';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { ProposalReceipt } from '@app/shared/models';
import { Util } from '@app/shared/helpers/utility.helper';

@Component({
    selector: 'receipt-created',
    templateUrl: 'receipt-created.component.html',
    styleUrls: ['receipt-created.component.scss'],
})
export class ReceiptCreatedComponent implements OnInit {
    proposalReceipt$: Observable<ProposalReceipt>;

    constructor(
        public proposalReceiptService: ProposalReceiptService,
        private route: ActivatedRoute,
        public router: Router,
        public loaderService: LoaderService,
    ) {}

    ngOnInit(): void {
        this.proposalReceipt$ = this.route.params.pipe(
            map((params) => +params['id']),
            tap((id) => {
                if (isNaN(id)) {
                    this.router.navigate(['/']);
                }
            }),
            filter((id) => !isNaN(id)),
            switchMap((id) => this.proposalReceiptService.getById(id)),
        );
    }

    downloadPdf(proposalReceiptId: number) {
        this.loaderService.show();
        this.proposalReceiptService
            .downloadPdf(proposalReceiptId)
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe((file) => {
                Util.openBlobInBrowser(file, file.name);
            });
    }

    home(): void {
        this.router.navigate(['/']).then();
    }
}
