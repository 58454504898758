import { FittedLens, FittedLensParameter } from '../models';

export class GetValueOfLensParameter {
    static Execute(fittedLens: FittedLens, paramCode: string): FittedLensParameter {
        const index = fittedLens.LensDefinition.LensDefinitionParameters.findIndex(
            (p) => p.ParameterType.Code === paramCode,
        );
        return fittedLens.FittedLensParameters[index];
    }
}
