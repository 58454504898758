import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { ListOption, ReturnType, SerialOrder } from '@app/shared/models';
import { ServiceRequest } from '@app/shared/models/service-request.model';
import { TranslateService } from '@ngx-translate/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { forkJoin, lastValueFrom, Observable, of } from 'rxjs';
import { ColorService } from '@app/core/services/api/color.service';
import { EyeService } from '@app/core/services/api/eye.service';
import { OpticianService } from '@app/core/services/api/optician.service';
import { ReturnService } from '@app/core/services/api/return.service';
import { SerialService } from '@app/core/services/api/serial.service';
import { MaterialsService } from '@app/core/services/api/material.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface SearchForm {
    searchTerm: FormControl<string | null>;
}

interface ReturnForm {
    serialNumber: FormControl<string | null>;
    product: FormControl<string | null>;
    eyeSide: FormControl<number | null>;
    material: FormControl<number | null>;
    optician: FormControl<string | null>;
    opticianId: FormControl<number | null>;
    reference: FormControl<string | null>;
    clientReference: FormControl<string | null>;
    color: FormControl<number | null>;
    returnCategory: FormControl<number | null>;
    returnType: FormControl<number | null>;
    description: FormControl<string | null>;
}

@Component({
    selector: 'app-logistic-manual-request',
    templateUrl: './logistic-manual-request.component.html',
    styleUrls: ['./logistic-manual-request.component.scss'],
})
export class LogisticManualRequestComponent implements OnInit {
    public materials: ListOption[];
    public eyeSides: ListOption[];
    public colors: ListOption[];
    public opticians: ListOption[];
    public returnCategories: ListOption[];
    public returnTypes: ListOption[];
    public allReturnTypes: ReturnType[];

    public loading = false;
    public validSerialNumber = false;

    public searchForm: FormGroup<SearchForm>;
    public returnForm: FormGroup<ReturnForm>;

    private readonly destroyRef = inject(DestroyRef);

    constructor(
        private readonly router: Router,
        private readonly returnService: ReturnService,
        private readonly materialService: MaterialsService,
        private readonly loaderService: LoaderService,
        private readonly translate: TranslateService,
        private readonly colorService: ColorService,
        private readonly eyeService: EyeService,
        private readonly opticianService: OpticianService,
        private readonly serialService: SerialService,
    ) {}

    public ngOnInit(): void {
        this.createForm();
        forkJoin([
            this.loadMaterials(),
            this.loadEyeSides(),
            this.loadColors(),
            this.loadOpticians(),
            this.loadReturnCategories(),
            this.loadReturnTypes(),
        ]).subscribe();
    }

    private createForm(): void {
        this.searchForm = new FormGroup<SearchForm>({
            searchTerm: new FormControl('', [Validators.required]),
        });

        this.returnForm = new FormGroup<ReturnForm>({
            serialNumber: new FormControl('', [Validators.required]),
            product: new FormControl('', [Validators.required]),
            eyeSide: new FormControl(0, [Validators.required]),
            material: new FormControl(0, [Validators.required]),
            optician: new FormControl('', [Validators.required]),
            opticianId: new FormControl(0, [Validators.required]),
            reference: new FormControl('', [Validators.required]),
            clientReference: new FormControl(''),
            color: new FormControl(0, [Validators.required]),
            returnCategory: new FormControl(0, [Validators.required]),
            returnType: new FormControl(0, [Validators.required]),
            description: new FormControl('', [Validators.required]),
        });

        this.returnForm.controls.returnCategory.valueChanges.subscribe((result) => {
            this.returnTypes = [];
            this.allReturnTypes
                .filter((f) => f.ReturnCategoryId === +result)
                .forEach((type) => {
                    this.returnTypes.push(new ListOption(type.Id, type.Name, type.Code));
                });
            this.returnForm.controls.returnType.setValue(this.returnTypes[0].Id);
        });
    }

    async sendServiceRequest(): Promise<void> {
        this.loaderService.showMessage(this.translate.instant('service.SendingRequest'));
        try {
            const sr = new ServiceRequest();
            const optician = this.opticians.find((o) => o.Id === +this.returnForm.controls.opticianId.value);
            const material = this.materials.find((m) => m.Id === +this.returnForm.controls.material.value);
            const color = this.colors.find((c) => c.Id === +this.returnForm.controls.color.value);
            const eyeSide = this.eyeSides.find((e) => e.Id === +this.returnForm.controls.eyeSide.value);

            sr.ClientReference = this.returnForm.controls.reference.value;
            sr.ClientReference2 = this.returnForm.controls.clientReference.value;
            sr.SerialNumber = this.returnForm.controls.serialNumber.value;

            if (color) {
                sr.ColorId = color.Id;
                sr.ColorName = color.Name;
            }

            if (material) {
                sr.MaterialId = material.Id;
                sr.MaterialName = material.Name;
            }

            sr.EyeSideId = eyeSide && eyeSide.Id > 0 ? eyeSide.Id : null;

            sr.ProductName = this.returnForm.controls.product.value;

            sr.OpticianId = optician.Id;
            sr.OpticianName = optician.Name;
            sr.ReturnCategoryId = this.returnCategories.find(
                (rc) => rc.Id === +this.returnForm.controls.returnCategory.value,
            ).Id;
            sr.ReturnCategoryName = this.returnCategories.find(
                (rc) => rc.Id === +this.returnForm.controls.returnCategory.value,
            ).Name;
            sr.ReturnTypeId = this.returnTypes.find((rt) => rt.Id === +this.returnForm.controls.returnType.value).Id;
            sr.ReturnTypeName = this.returnTypes.find(
                (rt) => rt.Id === +this.returnForm.controls.returnType.value,
            ).Name;
            sr.Description = this.returnForm.controls.description.value;

            const request = await lastValueFrom(this.returnService.saveServiceRequest(sr));
            if (request) {
                await this.router.navigate(['/logistics/newrequest/download/' + request.Id]);
            }
        } finally {
            this.loaderService.hide();
        }
    }

    loadMaterials(): Observable<ListOption[]> {
        return this.materialService
            .getMaterials()
            .pipe(tap((result) => (this.materials = result.map((mat) => new ListOption(mat.Id, mat.Name, mat.Code)))));
    }

    loadEyeSides(): Observable<ListOption[]> {
        return this.eyeService.getEyeSides().pipe(
            tap((result) => {
                this.eyeSides = [new ListOption(0, '', 'No')];

                const t = result.map((side) => new ListOption(side.Id, side.Name, side.Code));
                this.eyeSides.push(...t);
            }),
        );
    }

    loadColors(): Observable<ListOption[]> {
        return this.colorService
            .getColors()
            .pipe(
                tap(
                    (result) => (this.colors = result.map((color) => new ListOption(color.Id, color.Name, color.Code))),
                ),
            );
    }

    loadOpticians(): Observable<ListOption[]> {
        return this.opticianService.getOpticians().pipe(
            map((result) => result.map((optician) => new ListOption(optician.Id, optician.Name, optician.Code))),
            tap((result) => (this.opticians = result)),
        );
    }

    loadReturnCategories(): Observable<ListOption[]> {
        return this.returnService
            .getReturnCategories()
            .pipe(
                tap(
                    (result) =>
                        (this.returnCategories = result.map(
                            (category) => new ListOption(category.Id, category.Name, category.Code),
                        )),
                ),
            );
    }

    async loadReturnTypes() {
        this.allReturnTypes = await lastValueFrom(this.returnService.getReturnTypes());
    }

    private resetReturnsForm() {
        this.returnForm.controls.serialNumber.setValue('');
        this.returnForm.controls.product.setValue('');
        this.returnForm.controls.eyeSide.setValue(0);
        this.returnForm.controls.material.setValue(0);
        this.returnForm.controls.optician.setValue('');
        this.returnForm.controls.opticianId.setValue(0);
        this.returnForm.controls.reference.setValue('');
        this.returnForm.controls.clientReference.setValue('');
        this.returnForm.controls.color.setValue(0);
        this.returnForm.controls.returnCategory.setValue(1);
        this.returnForm.controls.returnType.setValue(0);
        this.returnForm.controls.description.setValue('');
    }

    searchSerialNumber() {
        this.loading = true;
        this.resetReturnsForm();
        const serialNumber = this.searchForm.controls.searchTerm.value;

        if (serialNumber.length > 0) {
            this.serialService
                .findBySerialOrderNumber(serialNumber)
                .pipe(
                    takeUntilDestroyed(this.destroyRef),
                    finalize(() => (this.loading = false)),
                    catchError((err) => of('error', err)),
                )
                .subscribe((result) => {
                    if (result) {
                        this.validSerialNumber = true;
                        this.updateForm(result);
                    } else {
                        this.validSerialNumber = false;
                    }
                });
        }
        this.loading = false;
    }

    private updateForm(order: SerialOrder): void {
        const searchTerm = this.searchForm.controls.searchTerm.value;
        if (order && order.SerialNumbers) {
            this.returnForm.controls.serialNumber.setValue(
                order.SerialNumbers.find((serialNumber) => serialNumber === searchTerm),
            );
        }

        if (order && order.Product && order.Product.ProductName) {
            this.returnForm.controls.product.setValue(order.Product.ProductName);
        }

        if (order && order.Material) {
            this.returnForm.controls.material.setValue(order.Material.MaterialId);
            this.returnForm.controls.color.setValue(order.Material.ColorId);
        }

        if (order && order.EyeSideId) {
            this.returnForm.controls.eyeSide.setValue(order.EyeSideId);
        }

        if (order && order.Optician && order.Optician.OpticianId) {
            this.returnForm.controls.optician.setValue(order.Optician.OpticianName);
            this.returnForm.controls.opticianId.setValue(order.Optician.OpticianId);
        }

        if (order && order.Reference) {
            this.returnForm.controls.reference.setValue(order.Reference);
        }

        if (order && order.Client && !order.Reference) {
            this.returnForm.controls.reference.setValue(order.Client.ClientReference);
        }
        if (order && order.Client && order.Reference) {
            this.returnForm.controls.clientReference.setValue(order.Client.ClientReference);
        }

        this.returnForm.controls.returnCategory.setValue(
            this.returnCategories.find((rc) => rc.Code === 'COMPLAINT').Id,
        );

        this.returnForm.controls.returnType.setValue(this.returnTypes.find((rt) => rt.Code === 'OTHER').Id);
    }

    setOptician(match: TypeaheadMatch): void {
        this.returnForm.controls.opticianId.patchValue(match.item.Id);
        this.returnForm.controls.optician.patchValue(match.item.Name);
    }
}
