<div class="accented-background p-3 mt-2">
    <h6>{{ 'ps.dreamlitehistory' | translate }}</h6>

    <div class="w-100">
        <div class="dreamlitehistory mt-1">
            <table>
                <thead>
                    <th>{{ 'general.date' | translate }}</th>
                    <th>{{ 'fitlens.radius' | translate }}</th>
                    <th>{{ 'general.abbreviations.RMF' | translate }}</th>
                    <th>{{ 'general.abbreviations.RC' | translate }}</th>
                    <th>{{ 'general.abbreviations.AC' | translate }}</th>
                    <th>{{ 'general.symbols.diameter' | translate }}</th>
                    <th>{{ 'general.abbreviations.ECC' | translate }}</th>
                    <th>{{ 'general.abbreviations.POW' | translate }}</th>
                    <th>{{ 'general.abbreviations.OZ' | translate }}</th>
                    <th>{{ 'general.abbreviations.RC2' | translate }}</th>
                    <th>{{ 'general.abbreviations.AC2' | translate }}</th>
                    <th>{{ 'general.abbreviations.ECC2' | translate }}</th>
                    <th *ngIf="hasConceptOfFittedLenses">&nbsp;</th>
                </thead>
                <tr
                    *ngFor="let dl of dreamLites"
                    (click)="selectDreamLiteLens(dl)"
                    [ngClass]="getClassForDreamLiteLens(dl)">
                    <td>{{ getDreamLiteFittedLensInfoOrderDate(dl) }}</td>
                    <td>
                        {{ dl.Rad | numberFormat: '{ "decimalCount": "2"}' }}
                    </td>
                    <td>
                        {{ dl.Rmf | numberFormat: '{ "decimalCount": "2"}' }}
                    </td>
                    <td>
                        {{ dl.Rc | numberFormat: '{ "decimalCount": "2"}' }}
                    </td>
                    <td>
                        {{ dl.Ac | numberFormat: '{ "decimalCount": "2"}' }}
                    </td>
                    <td>
                        {{ dl.Diam | numberFormat: '{ "decimalCount": "2"}' }}
                    </td>
                    <td>
                        {{ dl.Ecc | numberFormat: '{ "decimalCount": "2"}' }}
                    </td>
                    <td>
                        {{ dl.Pow | numberFormat: '{ "decimalCount" : "2", "signedNumber":"true"}' }}
                    </td>
                    <td>
                        {{ dl.Oz | numberFormat: '{ "decimalCount": "2"}' }}
                    </td>
                    <td>
                        {{ dl.Rc2 | numberFormat: '{ "decimalCount": "2"}' }}
                    </td>
                    <td>
                        {{ dl.Ac2 | numberFormat: '{ "decimalCount": "2"}' }}
                    </td>
                    <td>
                        {{ dl.Aecc | numberFormat: '{ "decimalCount": "2"}' }}
                    </td>
                    <td *ngIf="dl.ConceptOfFittedLensId !== null">
                        <span class="fas fa-trash" (click)="deleteFictionalDreamLiteLens(dl)"></span>
                    </td>
                </tr>
            </table>
        </div>

        <div class="mt-3">
            <button class="btn btn-default" (click)="openFixTypesDialog()">
                {{ 'dreamlitetable.fixtypes' | translate }}
            </button>
            <button class="ms-2 btn btn-default" (click)="addFictionalDreamLiteLens()">
                {{ 'dreamlitetable.add' | translate }}
            </button>
            <button class="ms-2 btn btn-default" (click)="reset()">
                {{ 'dreamlitetable.reset' | translate }}
            </button>
        </div>
    </div>
</div>
