import { Component } from '@angular/core';
import { ServiceStatus } from '@app/shared/enums';
import { ServiceRequest } from '@app/shared/models/service-request.model';

@Component({
    selector: 'app-logistic-complaints-overview',
    templateUrl: './logistic-overview.component.html',
    styleUrls: ['./logistic-overview.component.scss'],
})
export class LogisticOverviewComponent {
    returnStatuses = ServiceStatus;
    returnStatusIds = [this.returnStatuses.AwaitingProduct];
    returnSelected: ServiceRequest;
    redirectReturnSelected = false;

    receiveReturnParameters($event: ServiceRequest) {
        this.returnSelected = $event;
    }

    receiveReturnRedirect($event: boolean) {
        this.redirectReturnSelected = $event;
    }

    onClickType(key: number) {
        this.returnStatusIds = [key];
    }

    checkIfSelected(statusId: number): string {
        return this.returnStatusIds.includes(statusId) ? 'on' : '';
    }
}
