import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FittingService } from '@app/core/services/fitting.service';
import { ClientNote } from '@app/shared/models/ClientNote.model';
import { LocalStorageService } from '@app/shared/appservices/localstorage.service';
import { Client } from '@app/shared/models';
import { LocalClientNotes } from '@app/client/models/LocalClientNotes.model';
import { ClientService } from '@app/core/services/api/client.service';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'client-notes',
    templateUrl: 'client-notes.component.html',
    styleUrls: ['client-notes.component.scss'],
})
export class ClientNotesComponent implements OnInit {
    @Input() client: Client;
    @Input() showInputfield = true;
    @Input() inputPosition = 'bottom';

    formGroup: UntypedFormGroup;
    notes: ClientNote[];
    message = '';
    controller = '';
    loading = true;

    constructor(
        private readonly fittingService: FittingService,
        private readonly localStorage: LocalStorageService,
        private readonly clientService: ClientService,
    ) {}

    ngOnInit(): void {
        this.loadNotes().then();
        this.loading = false;
    }

    get canSave(): boolean {
        return !!this.message && !!this.controller;
    }

    public saveToLocalStorage(): void {
        const dataToSave = new LocalClientNotes();
        dataToSave.message = this.message;
        dataToSave.controller = this.controller;

        this.localStorage.save(this.getLocalStorageDataKey(), dataToSave);
    }

    public restoreFromLocalStorage(): void {
        const continuationData = this.localStorage.getAsObject<LocalClientNotes>(this.getLocalStorageDataKey());

        if (continuationData) {
            // do this in a timeout, because otherwise an ExpressionChangedAfterItHasBeenCheckedError occurs
            setTimeout(() => {
                this.message = continuationData.message;
                this.controller = continuationData.controller;
            }, 100);
        }
    }

    private getLocalStorageDataKey(): string {
        return `fitting-clientnotes-data-${this.fittingService.state.proposal.Id}`;
    }

    async saveNote() {
        const cn = new ClientNote();

        cn.ClientId = this.fittingService.getClientId();
        cn.Message = this.message;
        cn.AuthorName = this.controller;

        await lastValueFrom(this.clientService.saveNote(cn));

        this.loadNotes().then();
        this.message = '';
        this.controller = '';
    }

    async loadNotes() {
        if (this.client) {
            this.notes = await lastValueFrom(this.clientService.getClientNotesByClientId(this.client.Id));
        }
    }
}
