import { AppStateService } from './appState.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Roles } from '../enums';
import { AlertService } from './alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class InitialNavigationService {
    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly alertService: AlertService,
        private readonly appState: AppStateService,
        private readonly translate: TranslateService,
    ) {}

    async startInitialNavigationRoute(): Promise<void> {
        const routeUrl = this.determineRoute();
        await this.router.navigate([routeUrl]);
    }

    private determineRoute(): string {
        const fromQueryParams = this.route.snapshot.queryParams['returnUrl'];
        if (fromQueryParams) {
            return fromQueryParams;
        }

        // TODO: Move to different service
        switch (this.appState.authenticatedUser.CurrentRoleId) {
            case Roles.Optician:
                return '/';
            case Roles.Administrator:
            case Roles.DistributorAdmin:
            case Roles.LensConfigurationAdmin:
            case Roles.Marketing:
                return '/manage';
            case Roles.Sales:
                return '/support';
            case Roles.Ps:
            case Roles.DistributorSupport:
                return '/ps';
            case Roles.QualityAssurance:
                return '/qualityassurance';
            case Roles.Logistics:
                return '/logistics';
            case Roles.Translator:
                return '/translator';
        }

        // found nothing
        this.appState.closeModals$.emit();
        this.alertService.error(this.translate.instant('general.accessdenied'), true);
        return '/login';
    }
}
