<div class="d-flex justify-content-end">
    <ng-container>
        <button
            type="button"
            id="downloadReturn"
            [disabled]="!returnSelected"
            (click)="downloadPdf()"
            class="btn btn-info me-1 buttonWidth">
            {{ 'general.downloadpdf' | translate }}
        </button>
        <button
            type="button"
            id="statusSummary"
            [disabled]="!returnSelected"
            (click)="openModal('status-summary-modal')"
            class="btn btn-info me-1 buttonWidth">
            {{ 'complaintsoverview.history.statusChanges' | translate }}
        </button>
        <button type="button" id="startScan" (click)="routerLink()" class="btn btn-info me-1 buttonWidth">
            {{ processButtonText | translate }}
        </button>
    </ng-container>
</div>

<mpc-modal id="status-summary-modal">
    <h3>{{ 'complaintsoverview.history' | translate }}</h3>
    <div class="tab">
        <button
            class="tablinks"
            [ngClass]="{ active: getSelectedTab(statusChangesTab) }"
            (click)="selectTab(statusChangesTab)">
            {{ 'complaintsoverview.history.statusChanges' | translate }}
        </button>
        <button class="tablinks" [ngClass]="{ active: getSelectedTab(qaTab) }" (click)="selectTab(qaTab)">
            {{ 'complaintsoverview.history.qa' | translate }}
        </button>
        <button class="tablinks" [ngClass]="{ active: getSelectedTab(psTab) }" (click)="selectTab(psTab)">
            {{ 'complaintsoverview.history.ps' | translate }}
        </button>
    </div>
    <div id="statusChanges" *ngIf="getSelectedTab(statusChangesTab)">
        <app-status-change [returnStatuses]="returnStatuses"></app-status-change>
    </div>

    <div id="QA" *ngIf="getSelectedTab(qaTab)">
        <app-qa-change [returnQAConclusion]="returnQAConclusion"></app-qa-change>
    </div>

    <div id="PS" *ngIf="getSelectedTab(psTab)">
        <app-ps-change [returnConclusionChanges]="returnConclusionChanges"></app-ps-change>
    </div>
</mpc-modal>
