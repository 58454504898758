<div class="form-group row">
    <div *ngIf="label !== null" class="col">
        <h4>{{ label | translate: labelParams }}</h4>
    </div>
    <div *ngFor="let o of _options; let i = index" class="col-md-12">
        <div class="row">
            <label class="col-md-4 col-form-label">{{ o.Name | translate }}</label>
            <div class="col-md-8">
                <label class="switch">
                    <input
                        type="checkbox"
                        [value]="o.Selected ?? false"
                        [checked]="o.Selected"
                        [id]="id + '-' + i"
                        [name]="id + '-' + i"
                        (click)="propagateChange()"
                        (change)="onSelect(o)"
                        [disabled]="isDisabled" />
                    <span id="{{ id }}_span" class="switchSlider"></span>
                </label>
            </div>
        </div>
    </div>
</div>
