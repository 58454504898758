import { DatePipe } from '@angular/common';
import { Component, Input, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { EyeSides } from '@app/shared/enums';
import { RefractionMeasurement } from '@app/shared/models';
import { ClientService } from '@app/core/services/api/client.service';
import { LineChartComponent } from '@app/shared/components/charts/LineChart/line-chart.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'myopia-axis-length',
    templateUrl: 'myopia-axis-length.component.html',
})
export class MyopiaAxisLengthComponent implements OnInit, AfterViewInit {
    @Input() clientId: number;

    @ViewChild('linechartod')
    lineChartOD: LineChartComponent;

    @ViewChild('linechartos')
    lineChartOS: LineChartComponent;

    hasODValues: boolean;
    hasOSValues: boolean;

    loading = false;
    datePipe: DatePipe;

    constructor(
        private appState: AppStateService,
        private clientService: ClientService,
        private translateService: TranslateService,
    ) {
        this.datePipe = new DatePipe(appState.authenticatedUser.CurrentLanguageCode);
    }

    ngOnInit(): void {
        if (this.clientId) {
            this.loading = true;
        }
    }

    ngAfterViewInit(): void {
        if (this.clientId) {
            this.clientService.getMyopiaAxisLength(this.clientId).subscribe((result) => {
                const resultOD = result.filter((i) => {
                    return i.EyeSideId === EyeSides.Od;
                });

                this.hasODValues = resultOD.length > 0;
                if (this.hasODValues) {
                    const datasetOD = this.getChartData(resultOD);

                    this.lineChartOD.setDataSet(datasetOD);
                    this.lineChartOD.initiateChart();
                    this.lineChartOD.setColorScheme(this.appState.authenticatedUser.CurrentApplicationTheme);
                    this.lineChartOD.chart.update();
                }

                const resultOS = result.filter((i) => {
                    return i.EyeSideId === EyeSides.Os;
                });

                this.hasOSValues = resultOS.length > 0;
                if (this.hasOSValues) {
                    const datasetOS = this.getChartData(resultOS);

                    this.lineChartOS.setDataSet(datasetOS);
                    this.lineChartOS.initiateChart();
                    this.lineChartOS.setColorScheme(this.appState.authenticatedUser.CurrentApplicationTheme);
                    this.lineChartOS.chart.update();
                }

                this.loading = false;
            });
        }
    }

    getChartData(refractionMeasurements: RefractionMeasurement[]) {
        const labels = [];
        const datasets = {
            data: [],
        };

        for (const refractionMeasurement of refractionMeasurements) {
            labels.push(this.datePipe.transform(refractionMeasurement.Performed, 'shortDate'));
            datasets.data.push(refractionMeasurement.AxisLength);
        }

        return {
            labels: labels,
            datasets: [
                {
                    label: this.translateService.instant('general.myopiaaxislength'),
                    data: datasets.data,
                    backgroundColor: ['#0095ad'],
                    borderColor: ['#0095ad'],
                    borderWidth: 1,
                    fill: false,
                },
            ],
        };
    }
}
