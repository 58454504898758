<div [formGroup]="glassFrameFormGroup">
    <div class="spinner-border spinner-border-sm" *ngIf="loading"></div>
    <ng-container *ngIf="!loading">
        <div class="row">
            <div class="col-12">
                <div class="pt-2 d-flex align-items-center title-border-bottom">
                    <div>
                        <h4>{{ 'fitglass.edgingService' | translate }}</h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-xl-6">
                        <div class="form-group row mt-3 mb-3" *ngIf="glassTreatments.length !== 0">
                            <label class="col-form-label my-auto col-4">
                                {{ 'fitglass.edgingService' | translate }}
                            </label>
                            <div class="col-8 mt-1">
                                <mpc-select
                                    formControlName="glassTreatment"
                                    id="glassTreatment"
                                    [items]="glassTreatments"
                                    [formControlOnly]="true"
                                    (change)="changeGlassTreatment()"></mpc-select>
                                <mpc-model-label
                                    *ngIf="glassTreatments.length === 1"
                                    id="glassTreatmentLabel"
                                    [value]="glassTreatments[0].Name"
                                    formControlOnly="true"></mpc-model-label>
                            </div>
                        </div>

                        <div class="form-group row mt-3" *ngIf="showFrameControls()">
                            <div class="col-12">
                                <mpc-input-text
                                    formControlName="frameBrand"
                                    [id]="'frameBrand'"
                                    label="fitglass.frameBrand"
                                    [maxLength]="100"
                                    (change)="changeFrameBrand()"></mpc-input-text>
                            </div>
                        </div>

                        <div class="form-group row mb-2" *ngIf="showFrameControls()">
                            <div class="col-12">
                                <mpc-input-text
                                    formControlName="frameModel"
                                    [id]="'frameModel'"
                                    label="fitglass.frameModel"
                                    [maxLength]="100"
                                    (change)="changeFrameModel()"></mpc-input-text>
                            </div>
                        </div>

                        <div class="form-group row mb-2" *ngIf="showFrameControls() && frameMaterials.length !== 0">
                            <div class="col-12">
                                <mpc-select
                                    *ngIf="frameMaterials.length > 1"
                                    formControlName="frameMaterial"
                                    id="frameMaterial"
                                    [items]="frameMaterials"
                                    label="fitglass.frameType"
                                    (change)="changeFrameMaterial()"></mpc-select>
                            </div>
                        </div>

                        <div class="form-group row mb-2" *ngIf="showFrameControls()">
                            <div class="col-12">
                                <mpc-input-text
                                    formControlName="frameColor"
                                    [id]="'frameColor'"
                                    label="fitglass.frameColor"
                                    [maxLength]="50"
                                    (change)="changeFrameColor()"></mpc-input-text>
                            </div>
                        </div>

                        <div class="form-group row mb-2" *ngIf="showFrameControls()">
                            <label
                                class="col-form-label col-4"
                                [ngClass]="{
                                    error: formControls['frameSizeBoxA'].errors
                                }">
                                {{ 'fitglass.frameSizeBoxA' | translate }}
                                <i
                                    class="fa fa-question-circle ms-1"
                                    triggers="mouseenter:mouseleave"
                                    [popover]="infoImagePdOc"
                                    placement="right"></i>
                            </label>
                            <div class="col-8 mt-1">
                                <mpc-input-number
                                    formControlName="frameSizeBoxA"
                                    id="frameSizeBoxA"
                                    [formControlOnly]="true"
                                    [formatting]="{
                                        signedNumber: false,
                                        decimalCount: 1
                                    }"
                                    [maxLength]="4"
                                    [ranges]="[frameSizeBoxARange]"
                                    [unit]="'units.millimeter'"
                                    label="fitglass.frameSizeBoxA"
                                    (change)="changeframeSizeBoxA()"></mpc-input-number>
                            </div>
                        </div>

                        <div class="form-group row mb-2" *ngIf="showFrameControls()">
                            <label
                                class="col-form-label col-4"
                                [ngClass]="{
                                    error: formControls['frameSizeBoxB'].errors
                                }">
                                {{ 'fitglass.frameSizeBoxB' | translate }}
                                <i
                                    class="fa fa-question-circle ms-1"
                                    triggers="mouseenter:mouseleave"
                                    [popover]="infoImagePdOc"
                                    placement="right"></i>
                            </label>
                            <div class="col-8 mt-1">
                                <mpc-input-number
                                    formControlName="frameSizeBoxB"
                                    id="frameSizeBoxB"
                                    [formControlOnly]="true"
                                    [formatting]="{
                                        signedNumber: false,
                                        decimalCount: 1
                                    }"
                                    [maxLength]="4"
                                    [ranges]="[frameSizeBoxBRange]"
                                    [unit]="'units.millimeter'"
                                    (change)="changeframeSizeBoxB()"></mpc-input-number>
                            </div>
                        </div>

                        <div class="form-group row mb-2" *ngIf="showFrameControls()">
                            <label
                                class="col-form-label col-4"
                                [ngClass]="{
                                    error: formControls['frameDblDimension'].errors
                                }">
                                {{ 'fitglass.frameDblDimension' | translate }}
                                <i
                                    class="fa fa-question-circle ms-1"
                                    triggers="mouseenter:mouseleave"
                                    [popover]="infoImagePdOc"
                                    placement="right"></i>
                            </label>
                            <div class="col-8 mt-1">
                                <mpc-input-number
                                    formControlName="frameDblDimension"
                                    id="frameDblDimension"
                                    [formControlOnly]="true"
                                    [formatting]="{
                                        signedNumber: false,
                                        decimalCount: 1
                                    }"
                                    [maxLength]="4"
                                    [ranges]="[frameDblDimensionRange]"
                                    [unit]="'units.millimeter'"
                                    (change)="changeframeDblDimension()"></mpc-input-number>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-xl-6">
                        <div class="form-group row mb-2" *ngIf="showFrameControls()">
                            <label
                                class="col-form-label col-4"
                                [ngClass]="{
                                    error: formControls['pupilDistanceRight'].errors
                                }">
                                {{ 'fitglass.pupilDistanceRight' | translate }}
                                <i
                                    class="fa fa-question-circle ms-1"
                                    triggers="mouseenter:mouseleave"
                                    [popover]="infoImagePdOc"
                                    placement="right"></i>
                            </label>
                            <div class="col-8 mt-1">
                                <mpc-input-number
                                    formControlName="pupilDistanceRight"
                                    id="pupilDistanceRight"
                                    [formControlOnly]="true"
                                    [formatting]="{
                                        signedNumber: false,
                                        decimalCount: 1
                                    }"
                                    [maxLength]="4"
                                    [ranges]="[pupilDistanceRange]"
                                    [unit]="'units.millimeter'"
                                    (change)="changePupilDistanceRight()"></mpc-input-number>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-xl-6 ps-xl-2">
                        <div class="form-group row mb-2" *ngIf="showFrameControls()">
                            <label
                                class="col-form-label col-4"
                                [ngClass]="{
                                    error: formControls['pupilDistanceLeft'].errors
                                }">
                                {{ 'fitglass.pupilDistanceLeft' | translate }}
                                <i
                                    class="fa fa-question-circle ms-1"
                                    triggers="mouseenter:mouseleave"
                                    [popover]="infoImagePdOc"
                                    placement="right"></i>
                            </label>
                            <div class="col-8 mt-1">
                                <mpc-input-number
                                    formControlName="pupilDistanceLeft"
                                    id="pupilDistanceLeft"
                                    [formControlOnly]="true"
                                    [formatting]="{
                                        signedNumber: false,
                                        decimalCount: 1
                                    }"
                                    [maxLength]="4"
                                    [ranges]="[pupilDistanceRange]"
                                    [unit]="'units.millimeter'"
                                    (change)="changePupilDistanceLeft()"></mpc-input-number>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-xl-6">
                        <div class="form-group row mb-2" *ngIf="showFrameControls()">
                            <label
                                class="col-form-label col-4"
                                [ngClass]="{
                                    error: formControls['ocularCenterRight'].errors
                                }">
                                {{ 'fitglass.ocularCenterRight' | translate }}
                                <i
                                    class="fa fa-question-circle ms-1"
                                    triggers="mouseenter:mouseleave"
                                    [popover]="infoImagePdOc"
                                    placement="right"></i>
                            </label>
                            <div class="col-8 mt-1 mb-2">
                                <mpc-input-number
                                    formControlName="ocularCenterRight"
                                    id="ocularCenterRight"
                                    [formControlOnly]="true"
                                    [formatting]="{
                                        signedNumber: false,
                                        decimalCount: 1
                                    }"
                                    [maxLength]="4"
                                    [ranges]="[ocularCenterRange]"
                                    [unit]="'units.millimeter'"
                                    (change)="changeOcularCenterRight()"></mpc-input-number>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-xl-6 ps-xl-2">
                        <div class="form-group row mb-2" *ngIf="showFrameControls()">
                            <label
                                class="col-form-label col-4"
                                [ngClass]="{
                                    error: formControls['ocularCenterLeft'].errors
                                }">
                                {{ 'fitglass.ocularCenterLeft' | translate }}
                                <i
                                    class="fa fa-question-circle ms-1"
                                    triggers="mouseenter:mouseleave"
                                    [popover]="infoImagePdOc"
                                    placement="right"></i>
                            </label>
                            <div class="col-8 mt-1">
                                <mpc-input-number
                                    formControlName="ocularCenterLeft"
                                    id="ocularCenterLeft"
                                    [formControlOnly]="true"
                                    [formatting]="{
                                        signedNumber: false,
                                        decimalCount: 1
                                    }"
                                    [maxLength]="4"
                                    [ranges]="[ocularCenterRange]"
                                    [unit]="'units.millimeter'"
                                    (change)="changeOcularCenterLeft()"></mpc-input-number>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-xl-6">
                        <div class="form-group row mb-1" *ngIf="showFrameControls() && bevelTypes.length !== 0">
                            <div class="col-12">
                                <mpc-select
                                    *ngIf="showFrameControls()"
                                    formControlName="bevelType"
                                    id="bevelType"
                                    [items]="bevelTypes"
                                    label="fitglass.bevelType"
                                    (change)="changeBevelType()"></mpc-select>
                            </div>
                        </div>

                        <div class="form-group row mb-2" *ngIf="showFrameControls()">
                            <div class="col-12">
                                <mpc-input-slider
                                    formControlName="edgePolish"
                                    [id]="'edgePolish'"
                                    label="general.edgePolish"
                                    (change)="changeEdgePolish()"></mpc-input-slider>
                            </div>
                        </div>

                        <div class="form-group row" *ngIf="getCurrentBevelPosition()">
                            <label class="col-form-label col-4">{{ 'fitglass.bevelPosition' | translate }}</label>
                            <div class="col-8 mt-1">
                                <mpc-model-label
                                    id="bevelPosition"
                                    [value]="getCurrentBevelPosition()"></mpc-model-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-xl-6" *ngIf="remoteEdgingSelected()">
                <div class="pt-2 mb-1 title-border-top d-flex w-100">
                    <h4 class="pt-1">{{ 'fitglass.traceData' | translate }}</h4>
                    <div class="ms-auto">
                        <button
                            type="button"
                            class="btn btn-primary"
                            (click)="connect()"
                            [disabled]="connection.state !== 'Disconnected'">
                            {{ 'general.connect' | translate }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger ms-2"
                            (click)="disconnect()"
                            [disabled]="connection.state !== 'Connected'">
                            {{ 'general.disconnect' | translate }}
                        </button>
                    </div>
                </div>

                <div class="form-group row mb-2" *ngIf="isManualTraceUploadAvailable()">
                    <div class="col-12">
                        <mpc-input-slider
                            formControlName="traceBrowse"
                            [id]="'traceBrowse'"
                            label="general.manualUpload"
                            (change)="changeManualTraceUpload()"></mpc-input-slider>
                    </div>
                </div>

                <div class="form-group row mb-3" *ngIf="isTraceFileBrowseAvailable()">
                    <label class="col-form-label col-4">{{ 'general.tracer' | translate }}</label>
                    <div class="col-8">
                        <mpc-select
                            formControlName="tracerName"
                            id="tracerName"
                            [items]="tracers"
                            [formControlOnly]="true"
                            (change)="changeTracerName()"></mpc-select>
                    </div>
                </div>

                <div class="form-group row mb-2" *ngIf="isTraceFileBrowseAvailable()">
                    <label class="col-form-label col-4">{{ 'fileupload.dragorbrowseButton' | translate }}</label>
                    <div class="col-8">
                        <input type="file" (change)="processTraceFile($any($event.target).files)" />
                    </div>
                </div>

                <div class="form-group row mb-1" *ngIf="traceUrl">
                    <label class="col-form-label col-4">{{ 'trace.file' | translate }}</label>
                    <div class="col-8 d-flex align-items-center">
                        <a href="{{ traceUrl }}" target="_blank">
                            {{ traceName }}
                            <i class="fas fa-external-link-alt ms-1"></i>
                        </a>
                    </div>
                </div>

                <div class="form-group row mb-2" *ngIf="showTraceControls()">
                    <label
                        class="col-form-label col-4"
                        [ngClass]="{
                            error: formControls['frameTracerSizeBoxA'].errors
                        }">
                        {{ 'fitglass.frameTracerSizeBoxA' | translate }}
                    </label>
                    <div class="col-8 mt-1">
                        <mpc-input-number
                            formControlName="frameTracerSizeBoxA"
                            id="frameTracerSizeBoxA"
                            [formControlOnly]="true"
                            [isDisabled]="true"
                            [formatting]="{
                                signedNumber: false,
                                decimalCount: 1
                            }"
                            [maxLength]="4"
                            [ranges]="[traceSizeBoxARange]"
                            [unit]="'units.millimeter'"
                            (change)="changeFrameTracerSizeBoxA()"></mpc-input-number>
                    </div>
                </div>
                <div class="form-group row mb-2" *ngIf="showTraceControls()">
                    <label
                        class="col-form-label col-4"
                        [ngClass]="{
                            error: formControls['frameTracerSizeBoxB'].errors
                        }">
                        {{ 'fitglass.frameTracerSizeBoxB' | translate }}
                    </label>
                    <div class="col-8 mt-1">
                        <mpc-input-number
                            formControlName="frameTracerSizeBoxB"
                            id="frameTracerSizeBoxB"
                            [formControlOnly]="true"
                            [isDisabled]="true"
                            [formatting]="{
                                signedNumber: false,
                                decimalCount: 1
                            }"
                            [maxLength]="4"
                            [ranges]="[traceSizeBoxBRange]"
                            [unit]="'units.millimeter'"
                            (change)="changeFrameTracerSizeBoxB()"></mpc-input-number>
                    </div>
                </div>
                <div class="form-group row mb-2" *ngIf="showTraceControls()">
                    <label
                        class="col-form-label col-4"
                        [ngClass]="{
                            error: formControls['frameTracerDblDimension'].errors
                        }">
                        {{ 'fitglass.frameTracerDblDimension' | translate }}
                    </label>
                    <div class="col-8 mt-1">
                        <mpc-input-number
                            formControlName="frameTracerDblDimension"
                            id="frameTracerDblDimension"
                            [formControlOnly]="true"
                            [isDisabled]="true"
                            [formatting]="{
                                signedNumber: false,
                                decimalCount: 1
                            }"
                            [maxLength]="4"
                            [ranges]="[traceDblDimensionRange]"
                            [unit]="'units.millimeter'"
                            (change)="changeFrameTracerDblDimension()"></mpc-input-number>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<ng-template #infoImagePdOc>
    <img alt="edging" src="assets/images/{{ edgingInfoImageName() }}" />
</ng-template>
