<div class="modal-body" style="text-align: center">
    <h4 class="modal-title m-auto">{{ 'deletecartitem.title' | translate }}</h4>
    <p style="margin: 0 auto">{{ 'deletecartitem.note' | translate }}</p>
</div>

<div class="modal-footer justify-content-center">
    <button class="btn btn-danger" (click)="close()">
        {{ 'general.cancel' | translate }}
    </button>
    <button class="btn btn-success" (click)="delete()">
        {{ 'deletecartitem.removebutton' | translate }}
    </button>
</div>
