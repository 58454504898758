import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReturnQaConclusion } from '@app/shared/models/returnQaConclusion.model';
import { ReturnQualityAssuranceModel } from '@app/shared/models/ReturnQualityAssuranceModel';
import { ReturnQAConclusionChanges } from '@app/shared/models/returnQualityAssuranceConclusionChanges.model';

@Injectable({
    providedIn: 'root',
})
export class QaService {
    private readonly basePath = 'api/quality-assurances';

    constructor(private readonly httpClient: HttpClient) {}

    public getByReturnId(returnId: number): Observable<ReturnQualityAssuranceModel> {
        return this.httpClient.get<ReturnQualityAssuranceModel>(`${this.basePath}/conclusions/returns/${returnId}`);
    }

    public save(returnQAConclusion: FormData): Observable<ReturnQaConclusion> {
        return this.httpClient.post<ReturnQaConclusion>(`${this.basePath}/conclusions/returns`, returnQAConclusion);
    }

    public findAllReturnQAChangesByReturnID(returnId: number): Observable<ReturnQAConclusionChanges[]> {
        return this.httpClient.get<ReturnQAConclusionChanges[]>(
            `${this.basePath}/conclusions/returns/${returnId}/changes`,
        );
    }
}
