import { Component, Input, ViewEncapsulation, Optional, Host, SkipSelf } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseControl } from '@app/shared/components/inputs/base-control';

@Component({
    selector: 'mpc-input-checkbox',
    templateUrl: './input-checkbox.component.html',
    styleUrls: ['./input-checkbox.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputCheckboxComponent,
            multi: true,
        },
    ],
})
export class InputCheckboxComponent extends BaseControl {
    @Input() offset = true;
    @Input() asButton = false; // Show checkbox as button
    @Input() formGroupRow = true;
    @Input() labelControlStyle = false; // [label | checkbox] style instead of [empty | checkbox label]

    myClass: string[];
    formGroupRowClass: string;
    customCheckboxClass: string;
    customLabelClass: string;
    customInputClass: string;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        public controlContainer: ControlContainer,
    ) {
        super(controlContainer);
    }

    writeValue(value: boolean): void {
        this.value = value || false;
    }

    propagateChange(): void {
        this.onChange(this.value);
        this.onTouched();
    }

    refreshSubClasses(): void {
        this.myClass = this.getMyClass();
        this.formGroupRowClass = this.getFormGroupRowClass();
        this.customCheckboxClass = this.getCustomCheckboxClass();
        this.customLabelClass = this.getCustomLabelClass();
        this.customInputClass = this.getCustomInputClass();
    }

    getCustomInputClass(): string {
        return this.asButton ? 'btn-check' : '';
    }

    getMyClass(): string[] {
        const result: string[] = [];

        if (!this.formGroupRow) {
            result.push('px-0');
        }

        if (this.asButton) {
            result.push('col-12');
            return result;
        }

        if (!this.formGroupRow) {
            result.push(this.offset ? 'col-md-8 offset-md-4 m-0 pe-0' : 'col-12');
        } else {
            result.push(this.offset ? 'col-md-8 offset-md-4' : 'col-12');
        }

        return result;
    }

    // Label is used by bootstrap to have the checkbox appear differently
    getCustomLabelClass(): string {
        const active = this.value === true ? 'active' : '';
        const isDisabled = this.isDisabled ? 'disabled' : '';
        return this.asButton
            ? 'btn btn-secondary btn-sm checkboxbutton ' + active + ' ' + isDisabled
            : 'custom-control-label';
    }

    // Custom control hides the HTML checkbox
    getCustomCheckboxClass(): string {
        return this.asButton ? '' : 'form-check';
    }

    getFormGroupRowClass(): string {
        if (this.formGroupRow) {
            return 'form-group row';
        }

        return '';
    }
}
