import { Component, OnInit, Input } from '@angular/core';
import { Client, OrderWithComplexData, Proposal } from '@app/shared/models';
import { OrderService } from '@app/core/services/api/order.service';
import { ProposalService } from '@app/core/services/api/proposal.service';
import { SupportOrderService } from '@app/core/services/api/support-order.service';
import { SupportOrderStatus } from '../../models/SupportOrderStatus.model';
import IPsSupportOrderComponent from '@app/professional-service/supportorder/ps-supportorder.interface';
import { WorkItem } from '@app/professional-service/models/WorkItem.model';
import { Observable, lastValueFrom, of } from 'rxjs';

@Component({
    selector: 'ps-clientlastorder',
    templateUrl: './ps-clientlastorder.component.html',
    styleUrls: ['ps-clientlastorder.component.scss'],
})
export class PsClientLastOrderComponent implements IPsSupportOrderComponent, OnInit {
    @Input() client: Client;

    loading = true;
    order: OrderWithComplexData;
    proposal: Proposal;
    supportOrderStatuses: SupportOrderStatus[];

    workItem: WorkItem;
    sessionStorageKeys: string[] = [];

    constructor(
        private orderService: OrderService,
        private proposalService: ProposalService,
        private supportOrderService: SupportOrderService,
    ) {}

    async ngOnInit() {
        const lastorderid = await lastValueFrom(this.orderService.getLastOrderIdForClient(this.client.Id));
        this.supportOrderStatuses = await lastValueFrom(this.supportOrderService.getSupportOrderStatuses());
        if (lastorderid) {
            this.order = await lastValueFrom(this.orderService.getByIdWithComplexData(lastorderid));

            if (this.order.ProposalId) {
                this.proposal = await lastValueFrom(this.proposalService.getById(this.order.ProposalId));
            }
        }
        this.loading = false;
    }

    public getProposal(): Proposal {
        return this.proposal;
    }

    public getOrder(): OrderWithComplexData {
        return this.order;
    }

    public getClient(): Client {
        return this.client;
    }

    public getSupportOrderStatus(): SupportOrderStatus {
        if (this.order && this.supportOrderStatuses) {
            return this.supportOrderStatuses.find((sos) => sos.Id === this.order.SupportOrderStatusId);
        }

        return null;
    }

    getSelectedSupporter(): number {
        return 0;
    }

    loadOrder(): Observable<OrderWithComplexData> {
        return of(null);
    }
}
