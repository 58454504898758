export class TranslationLanguage {
    constructor(translateLanguageId: number, value: string, id = 0, distributorId: number = null) {
        this.Id = id;
        this.DistributorId = distributorId;
        this.TranslateLanguageId = translateLanguageId;
        this.Value = value;
    }

    Id: number;
    DistributorId: number;
    TranslateLanguageId: number;
    Value: string;
}
