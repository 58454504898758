import { Client } from './client.model';
import { Frame } from './frame.model';
import { ProposalReceiptLineInfo } from './proposalReceiptLineInfo.model';

export class ProposalReceiptInfo {
    Id: number;
    IsOrdered: boolean;
    CreatedByOpticianId: number;
    IsCreatedOn: Date;
    ShippedDate: Date;
    Reference: string;
    Client: Client;
    Code: string;
    LeftProductName: string;
    RightProductName: string;
    FrameId: number;
    GlassTreatmentId: number;
    Frame: Frame;
    ProposalReceiptLines: ProposalReceiptLineInfo[];
}
