import { ParameterTypeCodes } from '../enums';

interface ParameterFormat {
    numberFormat?: string;
    tableTranslation?: string;
}

export class ParameterTableDisplayHelper {
    private static readonly parameterLookupTable: {
        [key in string]: ParameterFormat;
    } = {
        ['default']: {
            numberFormat: '{ "decimalCount": "2"}',
            tableTranslation: null,
        },
        [ParameterTypeCodes.DIAM]: {
            tableTranslation: 'general.symbols.diameter',
        },
        [ParameterTypeCodes.POW]: {
            numberFormat: '{ "decimalCount": "2", "signedNumber": "true"}',
            tableTranslation: 'general.abbreviations.SPH',
        },
        [ParameterTypeCodes.ADD]: {
            tableTranslation: 'general.abbreviations.ADD',
        },
        [ParameterTypeCodes.SAG]: {
            tableTranslation: 'general.abbreviations.SAG',
        },
        [ParameterTypeCodes.RAD]: {
            tableTranslation: 'general.abbreviations.BCR',
        },
        [ParameterTypeCodes.ARAD]: {
            tableTranslation: 'general.abbreviations.BCR2',
        },
        [ParameterTypeCodes.RC]: {
            tableTranslation: 'general.abbreviations.RC',
        },
        [ParameterTypeCodes.RC2]: {
            tableTranslation: 'general.abbreviations.RC2',
        },
        [ParameterTypeCodes.AC]: {
            tableTranslation: 'general.abbreviations.AC',
        },
        [ParameterTypeCodes.AC2]: {
            tableTranslation: 'general.abbreviations.AC2',
        },
        [ParameterTypeCodes.ECC]: {
            tableTranslation: 'general.abbreviations.ECC',
        },
        [ParameterTypeCodes.AECC]: {
            tableTranslation: 'general.abbreviations.ECC2',
        },
        [ParameterTypeCodes.PEFA]: {
            tableTranslation: 'general.abbreviations.PEFA',
        },
        [ParameterTypeCodes.TORI]: {
            tableTranslation: 'general.abbreviations.TORI',
        },
        [ParameterTypeCodes.LCF]: {
            tableTranslation: 'general.abbreviations.LCF',
        },
        [ParameterTypeCodes.CYL]: {
            tableTranslation: 'general.abbreviations.CYL',
        },
        [ParameterTypeCodes.AX]: {
            tableTranslation: 'general.abbreviations.AX',
        },
        [ParameterTypeCodes.PRIS]: {
            tableTranslation: 'general.abbreviations.PRIS',
        },
        [ParameterTypeCodes.PROF]: {
            tableTranslation: 'general.abbreviations.PROF',
        },
        [ParameterTypeCodes.STAB]: {
            tableTranslation: 'general.abbreviations.STAB',
        },
        [ParameterTypeCodes.OZ]: {
            tableTranslation: 'general.abbreviations.OZ',
        },
        [ParameterTypeCodes.DIAMDIST]: {
            tableTranslation: 'general.abbreviations.DIAMDIST',
        },
    };

    public static lookup = (parameterCode: string) => {
        const defaultFormat = this.parameterLookupTable['default'];
        const format = this.parameterLookupTable[parameterCode] || defaultFormat;

        format.numberFormat ??= defaultFormat.numberFormat;
        format.tableTranslation ??= defaultFormat.tableTranslation;

        return format;
    };
}
