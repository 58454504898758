<div *ngIf="!loading" class="d-flex p-2" [formGroup]="formGroup">
    <div>
        <h6>{{ 'measurementoptions.type' | translate }}:</h6>
        <div class="justify-content-start">
            <mpc-input-radio-list
                formControlName="imageType"
                [formControlOnly]="true"
                [options]="imageTypeFormOptions"
                id="imageType"></mpc-input-radio-list>
        </div>
    </div>

    <div class="ms-2">
        <div>
            <h6>{{ 'measurementoptions.options' | translate }}:</h6>
        </div>
        <div class="d-flex">
            <div class="">
                <mpc-input-radio-list
                    formControlName="useNormalize"
                    [formControlOnly]="true"
                    [options]="normalizeFormOptions"
                    id="useNormalize"></mpc-input-radio-list>
            </div>
            <div>|</div>
            <div class="ms-2">
                <mpc-input-radio-list
                    formControlName="useMapType"
                    [formControlOnly]="true"
                    [options]="mapTypeFormOptions"
                    id="useMapType"></mpc-input-radio-list>
            </div>
            <div>|</div>
            <div class="ms-2">
                <mpc-input-radio-list
                    formControlName="useMm"
                    [formControlOnly]="true"
                    [options]="mmDptFormOptions"
                    id="useMm"></mpc-input-radio-list>
            </div>
        </div>
    </div>
</div>
