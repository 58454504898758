import { Roles } from '../enums';
import { AuthenticatedUser } from '.';

export class UserSetting {
    public Id: number;
    public CurrentLanguageId: number;
    public CurrentApplicationTheme: string;
    public CurrentOpticianId: number;
    public CurrentRoleId: Roles;
    public VertexDistance: number;
    public UsePrefilledDreamLiteAgreement: boolean;
    public DoSendStatusReminderEmails: boolean;
    public DistributorId: number;
    public LicenseAgreementApprovedOn: Date | null;
    public TrainingCompleteApprovedOn: Date | null;
    public UseImageType: number;
    public UseNormalize: number;
    public UseMapType: number;
    public UseMm: number;

    public static getUserSettingFromUser(authenticatedUser: AuthenticatedUser): UserSetting {
        const settings = new UserSetting();
        settings.Id = authenticatedUser.UserId;
        settings.CurrentApplicationTheme = authenticatedUser.CurrentApplicationTheme;
        settings.CurrentLanguageId = authenticatedUser.CurrentLanguageId;
        settings.CurrentOpticianId = authenticatedUser.CurrentOpticianId;
        settings.CurrentRoleId = authenticatedUser.CurrentRoleId;
        settings.LicenseAgreementApprovedOn = authenticatedUser.LicenseAgreementApprovedOn;
        settings.TrainingCompleteApprovedOn = authenticatedUser.TrainingCompleteApprovedOn;

        return settings;
    }
}
