import { Input, Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import IMeasurementHistoryComponent from '@app/measurement/measurement-history.interface';
import { TopographicMeasurementService } from '@app/core/services/api/topographic-measurement.service';
import { RefractionMeasurementService } from '@app/core/services/api/refraction-measurement.service';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'delete-topographic-measurement-dialog',
    templateUrl: 'delete-topographic-measurement-dialog.component.html',
})
export class DeleteTopographicMeasurementDialogComponent {
    @Input() measurementHistory: IMeasurementHistoryComponent;
    @Input() topoMeasurementId: number;
    @Input() refracMeasurementId: number;

    public deleteFailed = false;

    constructor(
        public bsModalRef: BsModalRef,
        private readonly topographicMeasurementService: TopographicMeasurementService,
        private readonly refractionMeasurementService: RefractionMeasurementService,
    ) {}

    delete(): void {
        if (this.topoMeasurementId) {
            lastValueFrom(this.topographicMeasurementService.deleteMeasurements([this.topoMeasurementId]))
                .then(() => {
                    this.measurementHistory.deleteHistoricMeasurement();
                    this.close();
                })
                .catch(() => {
                    this.deleteFailed = true;
                });
        } else if (this.refracMeasurementId) {
            lastValueFrom(this.refractionMeasurementService.deleteRefractionMeasurement(this.refracMeasurementId))
                .then(() => {
                    this.measurementHistory.deleteHistoricMeasurement();
                    this.close();
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this.deleteFailed = true;
                });
        }
    }

    close(): void {
        this.bsModalRef.hide();
    }
}
