import { Distributor, Manufacturer, Product } from '@app/shared/models';
import { WarrantyStatus } from '@app/shared/enums';

export class DistributorProductSetting {
    public DistributorId: number;
    public ProductId: number;
    public ManufacturerId: number;
    public Visible: boolean;
    public Distributor: Distributor;
    public Product: Product;
    public Manufacturer: Manufacturer;
    public IsSpareEnabled: boolean;
    public IsAnnualSupplyEnabled: boolean;
    public WarrantyStatus: WarrantyStatus;
}
