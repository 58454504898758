export class TopoImageOptions {
    ImageChoice = 'current';
    UseAxis = false;
    UseMm = true;
    UseNormalize = false;
    UseNfit = false;
    UseTilt = false;
    UseOverRefraction = false;
    UseSimK = true;
    UseMapType = false; // Axial (false) - tangent (true)

    PowerProfileAxis: number;
    PupilDiam: number;
    ShowPowerProfile: boolean;
    PowerProfilePosition: PowerProfilePosition;
    ImagePixelWidth: number;
    ImagePixelHeight: number;
}

export enum PowerProfilePosition {
    Bottom = 0,
    Right = 1,
}
