import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortclass',
})
export class SortClassPipe implements PipeTransform {
    transform(value: string, currentSortColumn: string, currentSortOrder: string) {
        if (value === currentSortColumn) {
            return currentSortOrder === 'desc' ? 'fa fa-sort-down' : 'fa fa-sort-up';
        } else {
            return 'fa fa-sort';
        }
    }
}
