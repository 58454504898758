import { LensDefinition } from './lens-definition.model';
import { ProductTranslation } from './productTranslation.model';
import { DataSheet } from './dataSheet.model';
import { UserProductSetting } from './userProductSetting.model';
import { Manufacturer, ProductGroup } from '@app/shared/models';
import { WarrantyStatus } from '../enums';

export class Product {
    Id: number;
    Code: string;
    ArtNr: string;
    ProductGroupId: number;
    ProductGroup: ProductGroup;
    RankingOrder: number;
    Name: string;
    Content: string;
    Description: string;
    BoxQuantity: number;
    AvailableForDirectOrder: boolean;
    AvailableInProcess: boolean;
    AvailableForCart: boolean;
    AvailableForWarranty: boolean;
    AvailableForSpare: boolean;
    AvailableForAnnualSupply: boolean;
    ShowSystemSuggestedValuesMessage: boolean;
    PictureUrl: string;
    DownloadUrl: string;
    IsStandardLens: boolean;
    MaxQuantity: number;
    IsPromotion: boolean;
    IsForStockOrder: boolean;

    IsSpareEnabled: boolean;
    IsAnnualSupplyEnabled: boolean;
    WarrantyStatus: WarrantyStatus;

    ProductTranslations: ProductTranslation[];
    LensDefinition: LensDefinition;
    DataSheets: DataSheet[];
    UserProductSetting: UserProductSetting;
    Manufacturers: Manufacturer[];
    CreditordersAllowed: boolean;
    OnlyManualMeasurements: boolean;
}
