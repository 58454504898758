import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { AuthRoutingModule } from './auth.routing.module';
import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AuthComponent } from './auth.component';

@NgModule({
    imports: [AuthRoutingModule, CommonModule, SharedModule],
    declarations: [AuthComponent, LoginComponent, ResetPasswordComponent],
})
export class AuthModule {}
