<div [formGroup]="formGroup">
    <div class="row m-0">
        <div class="subjectivemeasurement p-3 rounded-left" [ngClass]="showPrevious ? 'col-7 col-lg-8' : 'col-12'">
            <ng-container *ngIf="!disabled">
                <div class="columnheader d-flex align-items-center clearfix">
                    <div class="d-flex align-items-center">
                        <div class="text-nowrap me-3">
                            <h4>
                                {{ 'general.subjectivemeasurement' | translate }}
                            </h4>
                        </div>
                    </div>
                </div>

                <hr />

                <mpc-select
                    formControlName="visualAcuitySineCorrectore"
                    id="{{ id }}-visualAcuitySineCorrectore"
                    [items]="visualAcuityCorrectoreValues"
                    label="general.abbreviations.VASC"
                    info="{{ 'information.va-sc' | translate }}"
                    [infoPosition]="'right'"
                    noSelectionLabel=""
                    [breakpoint]="showPrevious ? 'md' : ''"></mpc-select>
                <mpc-input-number
                    formControlName="sphere"
                    id="{{ id }}-sphere"
                    [ranges]="config.sphereRanges"
                    [formatting]="{ signedNumber: true, decimalCount: 2 }"
                    [maxLength]="6"
                    label="general.spherical"
                    [breakpoint]="showPrevious ? 'md' : ''"
                    [unit]="'units.dioptry'"></mpc-input-number>
                <mpc-input-number
                    *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']"
                    formControlName="cylinder"
                    id="{{ id }}-cylinder"
                    [ranges]="config.cylinderRanges"
                    [formatting]="{ signedNumber: false, decimalCount: 2 }"
                    [maxLength]="6"
                    label="lens.geometry.cylinder"
                    [breakpoint]="showPrevious ? 'md' : ''"
                    [unit]="'units.dioptry'"></mpc-input-number>
                <mpc-input-number
                    *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']"
                    formControlName="axis"
                    id="{{ id }}-axis"
                    [ranges]="config.axisRanges"
                    [formatting]="{ signedNumber: false, decimalCount: 0 }"
                    [maxLength]="3"
                    label="general.axis"
                    [breakpoint]="showPrevious ? 'md' : ''"
                    [unit]="'units.degree'"></mpc-input-number>
                <mpc-select
                    formControlName="visualAcuityCumCorrectore"
                    id="{{ id }}-visualAcuityCumCorrectore"
                    [items]="visualAcuityCorrectoreValues"
                    [required]="visualAcutityRequired"
                    label="general.abbreviations.VACC"
                    info="{{ 'information.va-cc' | translate }}"
                    [infoPosition]="'right'"
                    noSelectionLabel=""
                    [breakpoint]="showPrevious ? 'md' : ''"></mpc-select>
                <mpc-input-number
                    *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']"
                    formControlName="nearAdd"
                    id="{{ id }}-nearAdd"
                    [ranges]="config.additionRanges"
                    [formatting]="{ signedNumber: true, decimalCount: 2 }"
                    [maxLength]="6"
                    label="lens.nearAdd"
                    [breakpoint]="showPrevious ? 'md' : ''"
                    [unit]="'units.dioptry'"></mpc-input-number>
                <mpc-input-number
                    *ngIf="fittingService.isDreamLiteFollowUp"
                    formControlName="refractionOverLens"
                    id="{{ id }}-refractionOverLens"
                    [ranges]="config.refractionOverLensRanges"
                    [formatting]="{ signedNumber: true, decimalCount: 2 }"
                    [info]="'general.refractionOverLensInfo' | translate"
                    [maxLength]="6"
                    label="lens.refractionOverLens"
                    [breakpoint]="showPrevious ? 'md' : ''"
                    [unit]="'units.dioptry'"></mpc-input-number>
                <mpc-input-number
                    formControlName="corneaDiameter"
                    id="{{ id }}-corneaDiameter"
                    [ranges]="config.corneaRanges"
                    [formatting]="{ signedNumber: false, decimalCount: 2 }"
                    [maxLength]="5"
                    label="general.cornea"
                    [breakpoint]="showPrevious ? 'md' : ''"
                    [unit]="'units.millimeter'"></mpc-input-number>
                <mpc-input-number
                    formControlName="pupilDiameter"
                    id="{{ id }}-pupilDiameter"
                    [ranges]="config.pupilRanges"
                    [formatting]="{ signedNumber: false, decimalCount: 2 }"
                    [maxLength]="5"
                    label="lens.pupilDiameter"
                    [breakpoint]="showPrevious ? 'md' : ''"
                    [unit]="'units.millimeter'"></mpc-input-number>
                <mpc-input-number
                    *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']"
                    formControlName="vertex"
                    id="{{ id }}-vertex"
                    [ranges]="config.vertexRanges"
                    [formatting]="{ signedNumber: false, decimalCount: 2 }"
                    [maxLength]="5"
                    label="general.vertex"
                    [breakpoint]="showPrevious ? 'md' : ''"
                    [unit]="'units.millimeter'"></mpc-input-number>
                <mpc-input-time
                    #timeComponent
                    formControlName="time"
                    class="ms-auto"
                    label="general.time"
                    [previous]="measurement"
                    [breakpoint]="showPrevious ? 'md' : ''"></mpc-input-time>

                <ng-container *ngIf="showStabilisation">
                    <mpc-input-number
                        formControlName="stabilisation"
                        id="{{ id }}-stabilisation"
                        [ranges]="stabilisationRanges"
                        [formatting]="{ signedNumber: false, decimalCount: 0 }"
                        label="fitlens.stabilisation"></mpc-input-number>
                    <div class="position-relative lens col-md-8 offset-md-4">
                        <div class="stabilisationLine" [ngStyle]="stabilisationLineStyle"></div>
                    </div>
                </ng-container>

                <ng-container *ngIf="hasMyopie">
                    <div class="d-flex align-items-center clearfix mt-4">
                        <div class="float-left me-2">
                            <h4>
                                {{ 'general.objectivemeasurement' | translate }}
                            </h4>
                        </div>
                    </div>
                    <hr />

                    <mpc-input-number
                        formControlName="axisLength"
                        id="{{ id }}-axisLength"
                        [ranges]="config.lengthRanges"
                        [formatting]="{ signedNumber: false, decimalCount: 2 }"
                        [maxLength]="5"
                        label="lens.axisLength"
                        [breakpoint]="showPrevious ? 'md' : ''"
                        [unit]="'units.millimeter'"></mpc-input-number>
                    <mpc-input-number
                        formControlName="cyclo"
                        id="{{ id }}-cyclo"
                        [ranges]="config.sphereRanges"
                        [formatting]="{ signedNumber: false, decimalCount: 2 }"
                        [maxLength]="5"
                        label="general.cyclo"
                        [breakpoint]="showPrevious ? 'md' : ''"
                        [unit]="'units.dioptry'"></mpc-input-number>
                </ng-container>
            </ng-container>

            <!-- Readonly -->
            <ng-container *ngIf="disabled">
                <div class="columnheader clearfix d-flex align-items-center">
                    <h4>{{ 'general.subjectivemeasurement' | translate }}</h4>
                    <span class="ms-auto">{{ measurement.Performed | mpcdate }}</span>
                    <span class="ms-2">{{ measurement.Performed | mpctime }}</span>
                </div>
                <hr />

                <mpc-model-label
                    id="{{ id }}-visualAcuitySineCorrectore"
                    [value]="measurement.VisualAcuitySineCorrectoreValueId"
                    [listoptions]="visualAcuityCorrectoreValues"
                    [formatting]="{ decimalCount: 2 }"
                    label="general.abbreviations.VASC"
                    [breakpoint]="'sm'"></mpc-model-label>

                <mpc-model-label
                    id="{{ id }}-sphere"
                    [value]="measurement.Sphere"
                    [formatting]="{ parameterType: 'POW' }"
                    [postfix]="'units.dioptry'"
                    [fullheight]="true"
                    label="lens.geometry.sphere"
                    [breakpoint]="'sm'"></mpc-model-label>

                <mpc-model-label
                    id="{{ id }}-cylinder"
                    [value]="measurement.Cylinder"
                    [formatting]="{ decimalCount: 2, signedNumber: true }"
                    [postfix]="'units.dioptry'"
                    [fullheight]="true"
                    label="lens.geometry.cylinder"
                    [breakpoint]="'sm'"></mpc-model-label>

                <mpc-model-label
                    id="{{ id }}-axis"
                    [value]="measurement.Axis"
                    [formatting]="{ parameterType: 'AX' }"
                    [postfix]="'units.degree'"
                    [fullheight]="true"
                    label="general.axis"
                    [breakpoint]="'sm'"></mpc-model-label>

                <mpc-model-label
                    id="{{ id }}-visualAcuityCumCorrectore"
                    [value]="measurement.VisualAcuityCumCorrectoreValueId"
                    [listoptions]="visualAcuityCorrectoreValues"
                    [formatting]="{ decimalCount: 2 }"
                    label="general.abbreviations.VACC"
                    [breakpoint]="'sm'"></mpc-model-label>

                <mpc-model-label
                    id="{{ id }}-nearAdd"
                    [value]="measurement.NearAdd"
                    [formatting]="{ parameterType: 'ADD' }"
                    [postfix]="'units.dioptry'"
                    [fullheight]="true"
                    label="lens.nearAdd"
                    [breakpoint]="'sm'"></mpc-model-label>

                <mpc-model-label
                    *ngIf="fittingService.isDreamLiteFollowUp"
                    id="{{ id }}-refractionOverLens"
                    [value]="measurement.RefractionOverLens"
                    [formatting]="{ parameterType: 'ADD' }"
                    [postfix]="'units.millimeter'"
                    [fullheight]="true"
                    label="lens.refractionOverLens"
                    [breakpoint]="'sm'"></mpc-model-label>

                <mpc-model-label
                    id="{{ id }}-corneaDiameter"
                    [value]="measurement.CorneaDiameter"
                    [formatting]="{ decimalCount: 2 }"
                    [postfix]="'units.millimeter'"
                    [fullheight]="true"
                    label="general.cornea"
                    [breakpoint]="'sm'"></mpc-model-label>

                <mpc-model-label
                    id="{{ id }}-pupilDiameter"
                    [value]="measurement.PupilDiameter"
                    [formatting]="{ decimalCount: 2 }"
                    [postfix]="'units.millimeter'"
                    [fullheight]="true"
                    label="lens.pupilDiameter"
                    [breakpoint]="'sm'"></mpc-model-label>

                <mpc-model-label
                    value="{{ measurement.Performed | mpcdate }} &nbsp; {{ measurement.Performed | mpctime }}"
                    [fullheight]="true"
                    label="general.time"
                    [breakpoint]="'sm'"></mpc-model-label>

                <ng-container *ngIf="hasMyopie">
                    <div class="d-flex align-items-center clearfix mt-4">
                        <div class="float-left me-2">
                            <h4>
                                {{ 'general.objectivemeasurement' | translate | uppercase }}
                            </h4>
                        </div>
                    </div>
                    <hr />

                    <mpc-model-label
                        id="{{ id }}-axisLength"
                        [value]="measurement.AxisLength"
                        [formatting]="{ decimalCount: 2 }"
                        [postfix]="'units.millimeter'"
                        [fullheight]="true"
                        label="lens.axisLength"
                        [breakpoint]="'sm'"></mpc-model-label>
                    <mpc-model-label
                        id="{{ id }}-cyclo"
                        [value]="measurement.Cyclo"
                        [formatting]="{ decimalCount: 2 }"
                        [postfix]="'units.dioptry'"
                        [fullheight]="true"
                        label="general.cyclo"
                        [breakpoint]="'sm'"></mpc-model-label>
                </ng-container>
            </ng-container>
        </div>

        <!-- Previous measurement -->
        <div *ngIf="showPrevious && previous" class="col-5 col-lg-4 py-3 ps-0 rounded-right">
            <div class="columnheader clearfix d-flex align-items-center">
                <h4>{{ 'measurement.previous' | translate }}</h4>
            </div>
            <hr />
            <mpc-model-label
                id="prev-{{ id }}-visualAcuitySineCorrectoreValue"
                [value]="previous.VisualAcuitySineCorrectoreValueId"
                [listoptions]="visualAcuityCorrectoreValues"
                [formatting]="{ decimalCount: 2 }"
                [fullheight]="true"></mpc-model-label>
            <mpc-model-label
                id="prev-{{ id }}-sphere"
                [value]="previous.Sphere"
                [formatting]="{ parameterType: 'POW' }"
                [postfix]="'general.units.DPT'"
                [fullheight]="true"></mpc-model-label>
            <mpc-model-label
                id="prev-{{ id }}-cylinder"
                [value]="previous.Cylinder"
                [formatting]="{ decimalCount: 2, signedNumber: true }"
                [postfix]="'general.units.DPT'"
                [fullheight]="true"></mpc-model-label>
            <mpc-model-label
                id="prev-{{ id }}-axis"
                [value]="previous.Axis"
                [formatting]="{ parameterType: 'AX' }"
                [postfix]="'general.symbols.degrees'"
                [fullheight]="true"></mpc-model-label>
            <mpc-model-label
                id="prev-{{ id }}-visualAcuityCumCorrectore"
                [value]="previous.VisualAcuityCumCorrectoreValueId"
                [listoptions]="visualAcuityCorrectoreValues"
                [formatting]="{ decimalCount: 2 }"
                [fullheight]="true"></mpc-model-label>
            <mpc-model-label
                id="prev-{{ id }}-nearAdd"
                [value]="previous.NearAdd"
                [formatting]="{ parameterType: 'ADD' }"
                [postfix]="'general.units.DPT'"
                [fullheight]="true"></mpc-model-label>
            <mpc-model-label
                *ngIf="fittingService.isDreamLiteFollowUp"
                id="prev-{{ id }}-refractionOverLens"
                [value]="previous.RefractionOverLens"
                [formatting]="{ parameterType: 'ADD' }"
                [postfix]="'general.units.MM'"
                [fullheight]="true"></mpc-model-label>
            <mpc-model-label
                id="prev-{{ id }}-corneaDiameter"
                [value]="previous.CorneaDiameter"
                [formatting]="{ decimalCount: 2 }"
                [postfix]="'general.units.MM'"
                [fullheight]="true"></mpc-model-label>
            <mpc-model-label
                id="prev-{{ id }}-pupilDiameter"
                [value]="previous.PupilDiameter"
                [formatting]="{ decimalCount: 2 }"
                [postfix]="'general.units.MM'"
                [fullheight]="true"></mpc-model-label>
            <mpc-model-label
                *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']"
                id="prev-{{ id }}-vertex"
                [value]="previous.Vertex"
                [formatting]="{ signedNumber: false, decimalCount: 2 }"
                [postfix]="'general.units.MM'"
                [fullheight]="true"></mpc-model-label>

            <mpc-model-label
                value="{{ previous.Performed | mpcdate }} &nbsp; {{ previous.Performed | mpctime }}"
                [fullheight]="true"></mpc-model-label>

            <ng-container *ngIf="!disabled && showStabilisation">
                <mpc-model-label
                    id="prev-{{ id }}-stabilisation"
                    [value]="previous.Stabilisation"
                    [formatting]="{ decimalCount: 0 }"
                    [fullheight]="true"></mpc-model-label>
                <div class="position-relative lens-placeholder col-md-8 offset-md-4"></div>
            </ng-container>

            <ng-container *ngIf="hasMyopie">
                <div class="d-flex align-items-center clearfix mt-4">
                    <div class="float-left text-truncate">
                        <h4>
                            {{ 'general.objectivemeasurement' | translate | uppercase }}
                        </h4>
                    </div>
                </div>
                <hr />

                <mpc-model-label
                    id="prev-{{ id }}-axisLength"
                    [value]="previous.AxisLength"
                    [formatting]="{ decimalCount: 2 }"
                    [postfix]="'general.units.MM'"
                    [fullheight]="true"></mpc-model-label>
                <mpc-model-label
                    id="prev-{{ id }}-cyclo"
                    [value]="previous.Cyclo"
                    [formatting]="{ decimalCount: 2 }"
                    [postfix]="'general.units.DPT'"
                    [fullheight]="true"></mpc-model-label>
            </ng-container>
        </div>
    </div>
</div>
