<div class="modal-header">
    <h4 class="modal-title m-auto">
        {{ 'settings.licenseagreementdialogtitle' | translate }}
    </h4>
</div>
<div class="modal-body">
    <div class="mb-3">
        {{
            'settings.licensecontent'
                | translate
                    : {
                          phoneNumber: company?.PhoneNumber,
                          mailAddress: company?.EmailAddress
                      }
        }}
    </div>

    <div [formGroup]="formGroup" class="form-group">
        <div
            formArrayName="datasheets"
            *ngFor="let agreement of dataSheetControls; let i = index"
            class="d-flex align-items-center">
            <div>
                <mpc-input-checkbox
                    [isDisabled]="isApproved(agreementDataSheets[i])"
                    [formControlName]="i"
                    [id]="agreementDataSheets[i].Id.toString()"></mpc-input-checkbox>
            </div>
            <div class="ms-3 pb-2">
                <a href="{{ agreementDataSheets[i].FilePath }}" target="_blank">{{ agreementDataSheets[i].Name }}</a>
            </div>
            <div class="ms-3 pb-2" id="agreedon_{{ i }}" *ngIf="isApproved(agreementDataSheets[i])">
                ({{ 'settings.licenseagreedon' | translate }} {{ approvedDate(agreementDataSheets[i]) | mpcdate }})
            </div>
        </div>
    </div>
</div>
<div class="modal-footer accented-background d-flex justify-content-start">
    <button type="button" id="agree" class="btn btn-success" [disabled]="formGroup.invalid" (click)="agree()">
        {{ 'general.agree' | translate }}
    </button>
    <div class="position-absolute d-flex align-items-center rounded img-overlay img-loading" *ngIf="loading">
        <div class="spinner-border spinner-border-sm m-auto"></div>
    </div>
    <button type="button" id="decline" class="btn btn-danger" (click)="decline()">
        {{ 'general.decline' | translate }}
    </button>
</div>
