import { Component } from '@angular/core';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { AppStateService } from '@app/shared/appservices/appState.service';

@Component({
    selector: 'app-environment',
    templateUrl: './environment.component.html',
    styleUrl: './environment.component.scss',
})
export class EnvironmentComponent {
    constructor(
        private readonly appConfigService: AppConfigService,
        private readonly appStateService: AppStateService,
    ) {}

    getEnvironment(): string {
        if (this.appConfigService.appConfig.environment && !this.appStateService.isGuiTest) {
            return this.appConfigService.appConfig.environment;
        }

        return '';
    }
}
