import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ReturnConclusion } from '@app/shared/models/ReturnConclusion.model';
import { Observable } from 'rxjs';
import { ListOption, QueryResult, ReturnType } from '@app/shared/models';
import { ServiceRequest } from '@app/shared/models/service-request.model';
import { ReturnsPerRoleQueryCriteria } from '@app/shared/models/ReturnsPerRoleQueryCriteria';
import { FileDownloadService } from '../file-download.service';
import { ReturnStatusChange } from '@app/shared/models/returnStatusChange';
import { ReturnConclusionChanges } from '@app/shared/models/returnConclusionChanges.model';
import { ReturnAndReturnConclusion } from '@app/shared/models/returnAndReturnConclusion.model';
import { ReturnFilterModel } from '@app/shared/models/return-list-filter.model';
import { HttpUtil } from '@app/shared/utilities/http.util';

@Injectable({
    providedIn: 'root',
})
export class ReturnService {
    private readonly basePath = 'api/returns';

    constructor(
        private readonly httpClient: HttpClient,
        private readonly fileDownloadService: FileDownloadService,
    ) {}

    public savePSReturnConclusion(returnConclusion: ReturnConclusion): Observable<ReturnConclusion> {
        return this.httpClient.post<ReturnConclusion>(`${this.basePath}/conclusions`, returnConclusion);
    }

    public getReturnResults(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/results/options`);
    }

    public getReturnCredits(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/credits/options`);
    }

    public getReturnCategories(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/categories/options`);
    }

    public getReturnTypes(): Observable<ReturnType[]> {
        return this.httpClient.get<ReturnType[]>(`${this.basePath}/types/options`);
    }

    public getReturnStatuses(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/statuses/options`);
    }

    public getDynamicsDispositions(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/dynamics/dispositions/options`);
    }

    public getDynamicsReturnReasons(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/dynamics/reasons/options`);
    }

    public getAllReturnsWithCriteria(filter: ReturnsPerRoleQueryCriteria): Observable<QueryResult<ServiceRequest>> {
        const params: ReturnFilterModel = {
            Skip: filter.PageIndex,
            Take: filter.PageSize,
            ReturnNumber: filter.FilterByFields.ReturnNumber,
            InProgressDate: filter.FilterByFields.InProgressDate,
            SerialNumber: filter.FilterByFields.SerialNumber,
            OpticianName: filter.FilterByFields.OpticianName,
            ClientReference: filter.FilterByFields.ClientReference,
            ClientReference2: filter.FilterByFields.ClientReference2,
            EyeSideName: filter.FilterByFields.EyeSideName,
            ProductName: filter.FilterByFields.ProductName,
            ReturnCategoryName: filter.FilterByFields.ReturnCategoryName,
            IsChangedByUserName: filter.FilterByFields.IsChangedByUserName,
            SortingName: filter.OrderByField,
            IsAscending: filter.OrderByAscending,
            CreditCategory: filter.creditCategory,
            ReturnStatusIds: filter.returnStatusIds,
        };

        const options = {
            params: HttpUtil.mapObjectToHttpParams(params),
        };

        return this.httpClient.get<QueryResult<ServiceRequest>>(this.basePath, options);
    }

    public getAll(): Observable<ServiceRequest[]> {
        return this.httpClient.get<ServiceRequest[]>(`${this.basePath}/recent`);
    }

    public saveServiceRequest(request: ServiceRequest): Observable<ServiceRequest> {
        return this.httpClient.post<ServiceRequest>(this.basePath, request);
    }

    public downloadPdf(returnId: number): Observable<File> {
        return this.fileDownloadService.downloadFile(`${this.basePath}/${returnId}/download/pdf`);
    }

    public findAllReturnStatusChangesByReturnID(returnId: number): Observable<ReturnStatusChange[]> {
        return this.httpClient.get<ReturnStatusChange[]>(`${this.basePath}/${returnId}/statuses/changes`);
    }

    public findAllReturnConclusionChangesByReturnID(returnId: number): Observable<ReturnConclusionChanges[]> {
        return this.httpClient.get<ReturnConclusionChanges[]>(`${this.basePath}/${returnId}/conclusions/change`);
    }

    public getReturnAndReturnConclusion(returnId: number): Observable<ReturnAndReturnConclusion> {
        return this.httpClient.get<ReturnAndReturnConclusion>(`${this.basePath}/${returnId}/with-conclusion`);
    }

    public getById(returnId: number): Observable<ServiceRequest> {
        return this.httpClient.get<ServiceRequest>(`${this.basePath}/${returnId}`);
    }

    public findReturnByBarcode(barcode: string): Observable<ServiceRequest | null> {
        const options = {
            params: new HttpParams().set('barcode', barcode),
        };

        return this.httpClient.get<ServiceRequest>(`${this.basePath}/barcode`, options);
    }

    public findReturnIdBySerialNumberBeforeDate(serialNumber: string, beforeReturnDate: Date): Observable<unknown> {
        const options = {
            params: new HttpParams()
                .set('SerialNumber', serialNumber)
                .set('BeforeReturnDate', !beforeReturnDate ? new Date().toISOString() : beforeReturnDate.toString()),
        };

        return this.httpClient.get(`${this.basePath}/find-id-by-serial`, options);
    }
}
