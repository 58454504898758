import { UntypedFormArray, ValidationErrors } from '@angular/forms';
import { BaseValidator } from '@app/shared/validators/BaseValidator';
import { LensTypes } from '@app/shared/enums';

export class PowerAndCylinderNotBothZeroValidator extends BaseValidator {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    bind(obj: any): ValidationErrors {
        if (obj.formGroup && obj.fittedLensParameters && obj.fittedLens.FittedLensTypeId === LensTypes.Glass) {
            const parameters = obj.formGroup.controls['parameters'] as UntypedFormArray;

            const powerControl = parameters.controls[obj.getFittedLensParameterIndex('POW')];
            const cylinderControl = parameters.controls[obj.getFittedLensParameterIndex('CYL')];

            if (powerControl && cylinderControl && powerControl.value === 0 && cylinderControl.value === 0) {
                return { powerAndCylinderNotBothZeroError: true };
            }
        }
        return null;
    }
}
