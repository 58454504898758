export class SerialOrder {
    EyeSideId: number | null;
    OrderLineId: number | null;
    Reference: string | null;
    OrderDate: Date;

    SerialNumbers: string[];

    Client: Client | null;
    Product: Product;
    Material: Material | null;
    Optician: Optician | null;
}

class Client {
    ClientId: number;
    ClientReference: string;
}

class Product {
    ProductId: number | null;
    ProductName: string | null;
    ArticleNumber: string;
}

class Material {
    MaterialId: number;
    MaterialName: string;
    ColorId: number;
    ColorName: string;
}

class Optician {
    OpticianId: number;
    OpticianName: string;
}
