<div *ngIf="measurement" id="{{ id }}" class="measurementitemcontainer p-2 d-flex" [ngClass]="measurementItemClass()">
    <div *ngIf="inDeleteMode" class="d-flex align-items-center me-2">
        <button
            id="{{ id }}-deleteMeasurement"
            class="btn btn-danger btn-pill p-2 deleteMeasurementButton"
            (click)="deleteMeasurement()">
            <i icon="fas fa-trash fa-lg"></i>
            {{ 'general.delete' | translate }}
        </button>
    </div>
    <div class="measurementitem flex-fill" (click)="clickMeasurement()">
        <div class="">
            <div class="row">
                <div [ngClass]="showRefractionData ? 'col-4' : 'col-7 col-md-8'">
                    <div class="row mb-2" *ngIf="!showRefractionData">
                        <div class="col-12">
                            <span id="{{ id }}-title">
                                <strong>{{ 'general.topographicmeasurement' | translate }}</strong>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 text-nowrap">
                            <span class="bold">{{ 'general.date' | translate }}</span>
                        </div>
                        <div class="col-8">
                            <span id="{{ id }}-performed-date">{{ measurement.Performed | mpcdate }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 text-nowrap">
                            <span class="bold">{{ 'general.time' | translate }}</span>
                        </div>
                        <div class="col-8">
                            <span id="{{ id }}-performed-time">{{ measurement.Performed | mpctime }}</span>
                        </div>
                    </div>
                    <div *ngIf="!measurement.IsManual" class="row">
                        <div class="col-4 text-nowrap">
                            <span class="bold">{{ 'general.topographer' | translate }}</span>
                        </div>
                        <div class="col-8">
                            <span id="{{ id }}-topographer">
                                {{ measurement.TopographerModel }}
                                <ng-container *ngIf="!measurement.IsManual && measurement.TopographerModel">
                                    &nbsp;-&nbsp;
                                </ng-container>
                                <ng-container *ngIf="!measurement.IsManual && measurement.TopographerName">
                                    {{ measurement.TopographerName }}
                                </ng-container>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="!measurement.ClientId" class="row">
                        <div class="col-4 text-nowrap">
                            <span class="bold">{{ 'general.reference' | translate }}</span>
                        </div>
                        <div class="col-8">
                            <span id="{{ id }}-client">
                                <ng-container *ngIf="measurement.ClientReference">
                                    {{ measurement.ClientReference }}
                                </ng-container>
                                <ng-container *ngIf="!measurement.ClientReference">
                                    {{ measurement.ClientLastName }} {{ measurement.ClientFirstName }}
                                </ng-container>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="showRefractionData" class="row mt-md-2">
                        <div class="col-4 text-nowrap">
                            <span class="bold">{{ 'measurement.simkflat' | translate }}</span>
                        </div>
                        <div class="col-8">
                            <span>
                                {{ measurement.SimKRadiusFlat | numberFormat: '{ "decimalCount": 2 }' }}
                                {{ 'units.millimeter' | translate }} &#64;{{
                                    measurement.SimKAxisFlat | numberFormat: '{ "decimalCount": 0 }'
                                }}°
                            </span>
                        </div>
                    </div>
                    <div *ngIf="showRefractionData" class="row">
                        <div class="col-4 text-nowrap">
                            <span class="bold">{{ 'measurement.simksteep' | translate }}</span>
                        </div>
                        <div class="col-8">
                            <span>
                                {{ measurement.SimKRadiusSteep | numberFormat: '{ "decimalCount": 2 }' }}
                                {{ 'units.millimeter' | translate }} &#64;{{
                                    measurement.SimKAxisSteep | numberFormat: '{ "decimalCount": 0 }'
                                }}°
                            </span>
                        </div>
                    </div>
                    <div *ngIf="showRefractionData" class="row">
                        <div class="col-4 text-nowrap">
                            <span class="bold">{{ 'measurement.cornealcylinder' | translate }}</span>
                        </div>
                        <div class="col-8">
                            <span>
                                {{ measurement.CornealCylinder | numberFormat: '{ "decimalCount": 2 }' }}
                                {{ 'units.millimeter' | translate }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center" [ngClass]="showRefractionData ? 'col-4' : 'col-5 col-md-4'">
                    <div
                        class="d-flex flex-column position-relative"
                        id="{{ id }}_measurementImage"
                        (click)="clickMeasurementImage($event)">
                        <topo-image-thumbnail
                            [measurement]="measurement"
                            [imageOptions]="imageOptions"></topo-image-thumbnail>
                        <span *ngIf="measurement.IsManual" class="manual-measurement-badge badge badge-outline">
                            {{ measurement.TopographerName | slice: 0 : 1 | uppercase }}
                        </span>
                    </div>
                </div>

                <div *ngIf="showRefractionData" class="col-4 refractiondata">
                    <div *ngIf="refractionMeasurement">
                        <div class="row">
                            <div class="col-4 text-nowrap">
                                <span class="bold">{{ 'general.abbreviations.VASC' | translate }}</span>
                            </div>
                            <div class="col-8">
                                <span>
                                    {{
                                        getVisualAcuityCorrectoreValue(
                                            refractionMeasurement.VisualAcuitySineCorrectoreValueId
                                        ) | numberFormat: '{ "decimalCount": 2 }'
                                    }}
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-nowrap">
                                <span class="bold">{{ 'lens.geometry.sphere' | translate }}</span>
                            </div>
                            <div class="col-8">
                                <span>
                                    {{ refractionMeasurement.Sphere | numberFormat: '{"parameterType": "POW"}' }}
                                    {{ 'units.dioptry' | translate }}
                                </span>
                            </div>
                        </div>
                        <div
                            class="row"
                            *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']">
                            <div class="col-4 text-nowrap">
                                <span class="bold">{{ 'lens.geometry.cylinder' | translate }}</span>
                            </div>
                            <div class="col-8">
                                <span>
                                    {{
                                        refractionMeasurement.Cylinder
                                            | numberFormat: '{ "decimalCount": 2, "signedNumber": true }'
                                    }}
                                    {{ 'units.dioptry' | translate }}
                                </span>
                            </div>
                        </div>
                        <div
                            class="row"
                            *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']">
                            <div class="col-4 text-nowrap">
                                <span class="bold">{{ 'general.axis' | translate }}</span>
                            </div>
                            <div class="col-8">
                                <span>
                                    {{ refractionMeasurement.Axis | numberFormat: '{"parameterType": "AX"}' }}
                                    {{ 'units.degree' | translate }}
                                </span>
                            </div>
                        </div>
                        <div
                            class="row"
                            *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']">
                            <div class="col-4 text-nowrap">
                                <span class="bold">{{ 'lens.nearAdd' | translate }}</span>
                            </div>
                            <div class="col-8">
                                <span>
                                    {{ refractionMeasurement.NearAdd | numberFormat: '{"parameterType": "ADD"}' }}
                                    {{ 'units.dioptry' | translate }}
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-nowrap">
                                <span class="bold">{{ 'general.cornea' | translate }}</span>
                            </div>
                            <div class="col-8">
                                <span>
                                    {{ refractionMeasurement.CorneaDiameter | numberFormat: '{ "decimalCount": 2 }' }}
                                    {{ 'units.millimeter' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
