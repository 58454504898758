import { Directive, OnInit, TemplateRef } from '@angular/core';
import { FooterService } from '../appservices/footer.service';

@Directive({
    selector: '[footer]',
})
export class FooterDirective implements OnInit {
    constructor(
        private readonly footerService: FooterService,
        private readonly ref: TemplateRef<unknown>,
    ) {}

    ngOnInit(): void {
        this.footerService.setContents(this.ref);
    }
}
