import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticatedUser } from '@app/shared/models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private readonly basePath = 'api/auth';

    constructor(private readonly httpClient: HttpClient) {}

    public login(username: string, password: string): Observable<AuthenticatedUser> {
        const body = { username, password };
        return this.httpClient.post<AuthenticatedUser>(`${this.basePath}/login`, body);
    }

    public loginWithExternalToken(externalToken: string): Observable<AuthenticatedUser> {
        const body = { externalToken };
        return this.httpClient.post<AuthenticatedUser>(`${this.basePath}/login/external-token`, body);
    }

    public refreshToken(accessToken: string, refreshToken: string): Observable<AuthenticatedUser> {
        const body = { accessToken, refreshToken };
        return this.httpClient.post<AuthenticatedUser>(`${this.basePath}/token/refresh`, body);
    }

    public validateLogin(): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.basePath}/login/validate`, null);
    }

    public logout(): Observable<void> {
        return this.httpClient.post<void>(`${this.basePath}/logout`, null);
    }
}
