import { FormArray, ValidationErrors } from '@angular/forms';
import { BaseValidator } from '@app/shared/validators/BaseValidator';
import { LensTypes, ParameterTypeCodes } from '@app/shared/enums';
import { FitLensComponent } from '../fitlens.component';

export class CrtRzdValidator extends BaseValidator {
    bind(obj: FitLensComponent): ValidationErrors {
        if (obj.lensTypeId === LensTypes.Crt) {
            const params = obj.formGroup.controls['parameters'] as FormArray;

            const rzd1 = params.controls[obj.getFittedLensParameterIndex(ParameterTypeCodes.RZD1SCRN)];
            const rzd2 = params.controls[obj.getFittedLensParameterIndex(ParameterTypeCodes.RZD2SCRN)];

            if (rzd1?.value != null && rzd2?.value != null && rzd1.value > rzd2.value) {
                return { crtRzdValidator: true };
            }
        }
    }
}
