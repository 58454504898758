import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState, IHttpConnectionOptions } from '@microsoft/signalr';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { AppStateService } from '@app/shared/appservices/appState.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    private hubConnection: HubConnection;

    public newTranslationsAvailable: EventEmitter<string> = new EventEmitter();

    constructor(
        private zone: NgZone,
        private appConfig: AppConfigService,
        public appState: AppStateService,
    ) {}

    public initialize() {
        const signalrEndPoint = this.appConfig.apiEndpoint;
        const options: IHttpConnectionOptions = {
            accessTokenFactory: () => this.appState.authenticatedUser?.AccessToken ?? '',
        };

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(signalrEndPoint + 'signalr/notification-hub', options)
            .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 60000, 60000, 60000, 60000, 60000])
            .build();
    }

    public startConnection() {
        if (this.hubConnection.state == HubConnectionState.Disconnected) {
            this.zone.runOutsideAngular(() => {
                this.hubConnection.start().catch((err) => console.log('Error while starting connection: ' + err));
            });
        }
    }

    public registerTranslationNotifications(): void {
        this.hubConnection.on('TranslationReceive', (data: string) => {
            this.newTranslationsAvailable.emit(data);
        });
    }
}
