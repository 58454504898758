<div class="modal-body">
    <div class="row p-2">
        {{ 'dialog.deletetopographicmeasurement' | translate }}
    </div>
</div>
<div class="modal-footer">
    <div class="ms-auto d-flex">
        <div *ngIf="deleteFailed === false">
            <button class="btn btn-danger" (click)="delete()">
                {{ 'general.delete' | translate }}
            </button>
        </div>
        <div *ngIf="deleteFailed === true">
            {{ 'dialog.deletetopographicmessage' | translate }}
        </div>
        <button class="btn btn-default ms-2" (click)="close()">
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</div>
