import { Proposal } from '@app/shared/models';
import { FileMetadata } from './fileMetadata.model';

export class CreateOrder {
    public Comments: string;
    public SkipOrderEvaluation: boolean;
    public Proposal: Proposal;
    public SupportMessage: string;
    public TransformedToMultiFocal: boolean;
    public PerosOrderNumber: string;
    public IsSpareOrder: boolean;
    public AddSpareOrder: boolean;
    public IsAnnualSupplyFullfillmentOrder: boolean;
    public DirectToHome: unknown;
    public Attachments: FileMetadata[];
}
