import { Pipe, PipeTransform } from '@angular/core';
import { Order } from '@app/shared/models/order.model';
import { OrderTypes } from '@app/shared/enums/index';
import { OrderInfo } from '../models/orderInfo.model';

@Pipe({
    name: 'supportOrder',
})
export class SupportOrderPipe implements PipeTransform {
    transform(order?: Order | OrderInfo) {
        return order && order.OrderTypeId === OrderTypes.SupportOrder;
    }
}
