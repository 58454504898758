import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticatedUser } from '@app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@app/core/services/api/authentication.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { AlertService } from '@app/shared/appservices/alert.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { AccessRights } from '@app/shared/models/accessRights';
import { Features } from '@app/shared/enums';

@Injectable()
export class AccessRightsAuthGuard {
    get CurrentAuthenticatedUser(): AuthenticatedUser {
        return this.appState.authenticatedUser;
    }

    constructor(
        public router: Router,
        public appState: AppStateService,
        public alertService: AlertService,
        public translateService: TranslateService,
        public authenticationService: AuthenticationService,
        public loaderService: LoaderService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        if (this.appState.currentOptician && this.appState.currentDistributor) {
            return of(this.checkAccessRights(route));
        } else {
            this.redirectToLogin();
            return of(false);
        }
    }

    checkAccessRights(route: ActivatedRouteSnapshot): boolean {
        let result = true;

        if (route.data['accessRights']) {
            const accessRights = route.data['accessRights'] as AccessRights;
            if (accessRights.webshop) {
                result = result && this.appState.isWebshopEnabled;
            }

            if (accessRights.returns) {
                result = result && this.appState.isReturnsEnabled;
            }

            if (accessRights.orderReceipts) {
                result = result && this.appState.isOpticianFeatureEnabled(Features.IsOrderWithReceiptEnabled);
            }
        }

        if (!result) {
            this.alertService.error(this.translateService.instant('general.accessdenied'), true);
            this.router.navigate(['login']).then();
        }

        return result;
    }

    private redirectToLogin(): void {
        this.alertService.error(this.translateService.instant('general.accessdenied'), true);
        this.router.navigate(['login']).then();
    }
}
