import { Injectable } from '@angular/core';

@Injectable()
export class DateService {
    diffInDays(date1: Date, date2: Date): number {
        const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
        const diffInTime = date2.getTime() - date1.getTime();
        return Math.ceil(diffInTime / oneDay);
    }

    isValidDate(date: Date): boolean {
        return date instanceof Date && !isNaN(date.getTime());
    }

    createDateFromString(dateString: string): Date {
        // Remove dashes
        const dateParts = dateString.split('-');

        // Split string by dashes into day mont year
        const day = dateParts[0];
        const month = dateParts[1];
        const year = dateParts[2];

        // Create new Date (note: month is 0-indexed, so subtract 1)
        return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    }

    isSameDay(date1: Date, date2: Date): boolean {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    }
}
