import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, Input, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { Client } from '@app/shared/models';
import { OrderInfo } from '@app/shared/models/orderInfo.model';
import { OrderLineStatusChange } from '@app/shared/models/orderLineStatusChange.model';
import { UpdateOrderLineStatus } from '@app/shared/models/updateOrderLineStatus.model';
import { ClientService } from '@app/core/services/api/client.service';
import { OrderService } from '@app/core/services/api/order.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OrderLineStatus, Roles, Treatments, Features, FeatureCategories, OrderTypes } from '@app/shared/enums';
import { ChangeOrderStatusDialogComponent } from './components/change-order-status-dialog/change-order-status-dialog.component';
import { Util } from '@app/shared/helpers/utility.helper';
import { Observable, catchError, finalize, switchMap, tap } from 'rxjs';
import { Company } from '@app/shared/models/company.model';
import { FeatureCheckerService } from '@app/shared/appservices/feature-checker.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'order-details-dialog',
    templateUrl: 'order-details-dialog.component.html',
    styleUrls: ['order-details-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OrderDetailsDialogComponent implements OnInit {
    @Input() order: OrderInfo;
    @Input() onOrderChanged: (order: OrderInfo) => void;

    public client: Client;
    public clientLoading = false;
    public clientMessage: string | null = null;
    public noteWasMarkedAsRead = false;

    public shippedDate: Date;

    public company: Company;

    public Features = Features;
    public FeatureCategories = FeatureCategories;

    public showClientCommunication = false;

    public orderStatusTranslationKey = '';
    public directToHomeTranslations = {};

    public Treatments = Treatments;

    public hasEdgingServiceRemoteEdging: boolean;
    public hasEdgingServiceRemoteEdgingAndFitting: boolean;
    public hasEdgingService: boolean;

    public hideHeaderFilters = false;

    private readonly destroyRef = inject(DestroyRef);

    get canUserChangeOrderStatus(): boolean {
        return this.appState.hasRole(Roles.Administrator) || this.appState.hasRole(Roles.Ps);
    }

    constructor(
        public readonly bsModalRef: BsModalRef,
        private readonly modalService: BsModalService,
        private readonly appState: AppStateService,
        private readonly clientService: ClientService,
        private readonly orderService: OrderService,
        private readonly loaderService: LoaderService,
        private readonly translateService: TranslateService,
        private readonly featureCheckerService: FeatureCheckerService,
    ) {}

    public ngOnInit(): Promise<void> {
        if (!this.order) {
            this.bsModalRef.hide();
            return;
        }

        this.showClientCommunication = !!this.order;
        this.orderStatusTranslationKey = 'orderlinestatus.' + OrderLineStatus[this.order.OrderStatusId].toLowerCase();

        this.hasEdgingServiceRemoteEdging = this.order?.GlassTreatmentId === Treatments.remoteEdging;
        this.hasEdgingServiceRemoteEdgingAndFitting =
            this.order?.GlassTreatmentId === Treatments.remoteEdgingAndFitting;
        this.hasEdgingService =
            this.order?.GlassTreatmentId === Treatments.remoteEdging ||
            this.order.GlassTreatmentId === Treatments.remoteEdgingAndFitting;

        if (this.order.IsDirectToHome) {
            this.directToHomeTranslations = {
                Name: this.order.Name,
                Street: this.order.Street,
                HouseNumber: this.order.HouseNumber,
                HouseNumberSuffix: this.order.HouseNumberSuffix,
                PostCode: this.order.PostCode,
                City: this.order.City,
                Country: this.order.Country,
                HomeState: this.order.HomeState,
            };
        }

        this.hideHeaderFilters =
            this.featureCheckerService.isCompanyFeatureEnabled(Features.HideOrderStatusFields) &&
            this.order.OrderTypeId !== OrderTypes.SupportOrder;

        if (!this.order.ClientId) {
            return;
        }

        this.clientLoading = true;
        this.clientService
            .getById(this.order.ClientId)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap((client) => (this.client = client)),
                catchError((error) => {
                    if (error instanceof HttpErrorResponse && error.status === 404) {
                        return this.clientService.getDeletedClientReference(this.order.ClientId).pipe(
                            tap(
                                (clientReference) =>
                                    (this.clientMessage = this.translateService.instant('orderhistory.clientDeleted', {
                                        Reference: clientReference,
                                    })),
                            ),
                        );
                    }
                }),
                tap(() => (this.clientLoading = false)),
            )
            .subscribe();
    }

    public downloadPdf(): void {
        this.loaderService.show();

        this.orderService
            .downloadPdf(this.order.Id)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                finalize(() => this.loaderService.hide()),
            )
            .subscribe((file) => {
                Util.openBlobInBrowser(file, file.name);
            });
    }

    public downloadShipmentFramePdf(): void {
        this.loaderService.show();

        this.orderService
            .downloadShipmentFramePdf(this.order.Id)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                finalize(() => this.loaderService.hide()),
            )
            .subscribe((file) => {
                Util.openBlobInBrowser(file, file.name);
            });
    }

    public noteMarkedAsRead(): void {
        this.noteWasMarkedAsRead = true;
    }

    public openChangeOrderStatusDialog(): void {
        this.modalService.show(ChangeOrderStatusDialogComponent, {
            initialState: {
                currentOrderStatus: this.order.OrderStatusId,
                onClose: (result: OrderLineStatus) => this.saveNewOrderLineStatus(result),
            },
            class: 'modal-half',
        });
    }

    private saveNewOrderLineStatus(status: OrderLineStatus): Observable<unknown> {
        const request = new UpdateOrderLineStatus();
        request.OrderLineStatusChanges = this.order.OrderLines.map(
            (ol) =>
                <OrderLineStatusChange>{
                    Batches: null,
                    OrderLineId: ol.Id,
                    StatusId: status,
                },
        );

        return this.orderService.updateOrderLineStatus(request).pipe(
            switchMap(() => {
                const orderId = this.order.Id;
                this.order = null;

                return this.orderService.getOrderInfoById(orderId);
            }),
            tap((order: OrderInfo) => {
                this.order = order;

                if (this.onOrderChanged) {
                    this.onOrderChanged(this.order);
                }
            }),
        );
    }
}
