import { Component, ViewChild } from '@angular/core';
import { Attachment } from '@app/shared/models';
import { FileUploadComponent, FileUploadStatus } from '@app/shared/components/fileupload/fileupload.component';
import { takeWhile } from 'rxjs/operators';
import { AlertService } from '@app/shared/appservices/alert.service';

@Component({
    selector: 'supportorder-overview-po-attachment',
    templateUrl: 'supportorder-overview-po-attachment.html',
})
export class SupportorderoverviewPOAttachmentComponent {
    @ViewChild('fileUploader') fileUploadComponent: FileUploadComponent;
    attachments: Attachment[];
    loading = true;

    constructor(public alertService: AlertService) {}

    uploadFiles(event): Promise<void> {
        if (event) {
            if (
                !this.fileUploadComponent ||
                !this.fileUploadComponent.files ||
                this.fileUploadComponent.files.length === 0
            ) {
                return Promise.resolve();
            }

            const uploadFormData = {
                IsInternal: true,
            };

            this.fileUploadComponent.startUpload(uploadFormData, 'api/import/grandvision');

            return new Promise<void>((resolve) => {
                this.fileUploadComponent.uploadStatus
                    .pipe(takeWhile((u) => u === FileUploadStatus.Completed))
                    .subscribe(() => resolve());
            });
        }
    }

    uploadResult(resultMessage: string) {
        this.alertService.info(resultMessage);
    }
}
