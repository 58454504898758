export class EyelitePoint {
    constructor(eyelitePoint: EyelitePoint) {
        this.Rho = eyelitePoint.Rho;
        this.Alpha = eyelitePoint.Alpha;
        this.Height = eyelitePoint.Height;
    }

    public Rho: number;
    public Alpha: number;
    public Height: number;
}
