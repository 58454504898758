import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { RegisterOpticianRequest } from '@app/shared/models/registerOpticianRequest.model';
import { OpticianService } from '@app/core/services/api/optician.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';

interface OpticianRegistrationForm {
    OpticianName: FormControl<string>;
    City: FormControl<string>;
    OpticianEmail: FormControl<string>;
    OpticianPhone: FormControl<string>;
    VatNumber: FormControl<string>;
    PersonalName: FormControl<string>;
    UserEmail: FormControl<string>;
    UserName: FormControl<string>;
    ReceiptReference: FormControl<string>;
    ReceiptCode: FormControl<string>;
}

@Component({
    selector: 'app-register-optician',
    templateUrl: './register-optician.component.html',
    styleUrls: ['./register-optician.component.scss'],
})
export class RegisterOpticianComponent implements OnInit {
    formGroup: FormGroup<OpticianRegistrationForm>;
    registrationError: string;
    isSubmitted = false;

    constructor(
        private loaderService: LoaderService,
        private translate: TranslateService,
        private router: Router,
        private opticianService: OpticianService,
    ) {}

    ngOnInit(): void {
        this.createForm();
    }

    createForm(): void {
        const requiredValidators = Validators.required;

        this.formGroup = new FormGroup<OpticianRegistrationForm>({
            OpticianName: new FormControl('', requiredValidators),
            City: new FormControl('', requiredValidators),
            OpticianEmail: new FormControl('', requiredValidators),
            OpticianPhone: new FormControl('', requiredValidators),
            VatNumber: new FormControl('', requiredValidators),
            PersonalName: new FormControl('', requiredValidators),
            UserEmail: new FormControl('', requiredValidators),
            UserName: new FormControl('', requiredValidators),
            ReceiptReference: new FormControl('', requiredValidators),
            ReceiptCode: new FormControl('', requiredValidators),
        });
    }

    register() {
        this.isSubmitted = true;

        this.loaderService.show();
        this.registrationError = '';

        if (!this.formGroup.valid) {
            this.loaderService.hide();
            return;
        }

        const newRegistration = new RegisterOpticianRequest();
        newRegistration.OpticianName = this.formGroup.controls['OpticianName'].value;
        newRegistration.City = this.formGroup.controls['City'].value;
        newRegistration.OpticianEmail = this.formGroup.controls['OpticianEmail'].value;
        newRegistration.OpticianPhone = this.formGroup.controls['OpticianPhone'].value;
        newRegistration.VatNumber = this.formGroup.controls['VatNumber'].value;
        newRegistration.PersonalName = this.formGroup.controls['PersonalName'].value;
        newRegistration.UserEmail = this.formGroup.controls['UserEmail'].value;
        newRegistration.UserName = this.formGroup.controls['UserName'].value;
        newRegistration.ReceiptReference = this.formGroup.controls['ReceiptReference'].value;
        newRegistration.ReceiptCode = this.formGroup.controls['ReceiptCode'].value;

        this.opticianService
            .register(newRegistration)
            .pipe(
                catchError((error) => {
                    if (error.status === 400) {
                        const errorCode = error.error.errors.RegistrationForm[0];
                        this.registrationError = this.translate.instant(errorCode);
                    } else {
                        this.registrationError = 'Registration failed. Please try again or contact support.';
                    }
                    return throwError(error);
                }),
                finalize(() => this.loaderService.hide()),
            )
            .subscribe(() => this.router.navigate(['receipt/register/success']));
    }

    get formControls() {
        return this.formGroup.controls;
    }
}
