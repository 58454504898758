import { EyeliteImport } from '@app/professional-service/models/eyeliteimport/EyeliteImport.model';

export class EyeliteFileImport {
    constructor(eyeliteFileImport: EyeliteFileImport) {
        this.IsCreatedOn = eyeliteFileImport.IsCreatedOn;
        this.IsCreatedBy = eyeliteFileImport.IsCreatedBy;
        this.Import = new EyeliteImport(eyeliteFileImport.Import);
    }

    public IsCreatedOn: Date;
    public IsCreatedBy: string;

    public Import: EyeliteImport;

    public NumberOfMeasurements(): number {
        if (this.Import != null) {
            return this.Import.Client.Measurements.length;
        }

        return 0;
    }

    public NumberOfOrders(): number {
        if (this.Import != null) {
            const rgpLenses = this.Import.Client.Lenses.RgpLenses.length;
            const softLenses = this.Import.Client.Lenses.SoftLenses.length;
            const disposableLenses = this.Import.Client.Lenses.DisposableLenses.length;
            const dreamLiteLenses = this.Import.Client.Lenses.DreamLiteLenses.length;

            return rgpLenses + softLenses + disposableLenses + dreamLiteLenses;
        }

        return 0;
    }
}
