import { Injectable, EventEmitter } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticatedUser, Optician, Distributor, DataSheet } from '@app/shared/models';
import { LocalStorageService } from './localstorage.service';
import { DatasheetTypes, Features, Roles } from '../enums';
import { OpticianService } from '@app/core/services/api/optician.service';
import { UserService } from '@app/core/services/api/user.service';
import { CompanyService } from '@app/core/services/api/company.service';
import { Company } from '../models/company.model';
import { PasswordExpiryInfo } from '../models/passwordExpiryInfo.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, iif, lastValueFrom, of, tap } from 'rxjs';
import { UserSettingService } from '@app/core/services/api/user-setting.service';
import { AppConfigService } from './appConfig.service';
import { DataSheetService } from '@app/core/services/api/datasheet.service';

@Injectable({
    providedIn: 'root',
})
export class AppStateService {
    private readonly jwtHelper = new JwtHelperService();
    private readonly userBroadcastChannel = new BroadcastChannel('user-sharing-channel');
    public closeModals$: EventEmitter<void> = new EventEmitter();
    public isMobile = false;
    public isTablet = false;

    public licenseDatasheets: Array<DataSheet> = [];
    public availableDatasheets = {
        userRegulations: false,
        licenseAgreement: false,
        privacyPolicy: false,
    };

    public mobileMax = '(max-width: 1023px)';
    public tabletMin = '(min-width: 1024px)';
    public tabletMax = '(max-width: 1367px)';

    public authenticatedUser$: BehaviorSubject<AuthenticatedUser> = new BehaviorSubject<AuthenticatedUser>(null);
    private _authenticatedUser: AuthenticatedUser | null;
    private _currentOptician: Optician;
    private _currentDistributor: Distributor;
    private _currentCompany: Company;
    private _passwordExpiryInfo: PasswordExpiryInfo;

    private _isGuiTest = false;
    private _screensaverTimeoutInMs: number | null = null;

    constructor(
        private readonly localstorageService: LocalStorageService,
        private readonly opticianService: OpticianService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly translateService: TranslateService,
        private readonly userService: UserService,
        private readonly dataSheetService: DataSheetService,
        private readonly companyService: CompanyService,
        private readonly userSettingService: UserSettingService,
        private readonly appConfigService: AppConfigService,
    ) {
        this.breakpointObserver
            .observe([this.mobileMax, this.tabletMin, this.tabletMax])
            .subscribe((state: BreakpointState) => {
                if (state.breakpoints[this.mobileMax]) {
                    this.isMobile = true;
                    this.isTablet = false;
                } else if (state.breakpoints[this.tabletMin] && state.breakpoints[this.tabletMax]) {
                    this.isMobile = false;
                    this.isTablet = true;
                } else {
                    this.isMobile = false;
                    this.isTablet = false;
                }
            });

        const authenticatedUser = this.localstorageService.getAsObject<AuthenticatedUser>('authenticatedUser');
        if (
            authenticatedUser &&
            authenticatedUser.RefreshToken &&
            !this.jwtHelper.isTokenExpired(authenticatedUser.RefreshToken)
        ) {
            this.authenticatedUser = authenticatedUser;
        }

        const url: URL = new URL(window.location.href);
        const urlParams: URLSearchParams = url.searchParams;
        const urlScreensaverTimeoutParam = urlParams.get('guitest_screensaver_timeout');

        this._isGuiTest = window.location.href.indexOf('guitest') > -1;
        this._screensaverTimeoutInMs = urlScreensaverTimeoutParam ? Number(urlScreensaverTimeoutParam) : null;

        this.addBroadcastChannelListener();
    }

    private addBroadcastChannelListener() {
        this.userBroadcastChannel.addEventListener('message', (event) => {
            if (this.authenticatedUser?.Timestamp !== event.data?.Timestamp) {
                this.authenticatedUser = event.data;
            }
        });
    }

    set authenticatedUser(user: AuthenticatedUser) {
        const isChanged =
            user?.Timestamp > (this._authenticatedUser?.Timestamp ?? null) ||
            (user == null && this._authenticatedUser != null);

        if (user) {
            this._authenticatedUser = new AuthenticatedUser(user);
            this.authenticatedUser$.next(this._authenticatedUser);
        } else {
            this._authenticatedUser = null;
            this.authenticatedUser$.next(null);
        }

        if (isChanged) {
            this.localstorageService.save('authenticatedUser', this._authenticatedUser);
            this.userBroadcastChannel.postMessage(this._authenticatedUser);
        }
    }

    get authenticatedUser(): AuthenticatedUser | null {
        return this._authenticatedUser;
    }

    get isAuthenticated(): boolean {
        return this._authenticatedUser !== null;
    }

    set currentOptician(optician: Optician) {
        this._currentOptician = optician;
    }

    get currentOptician(): Optician {
        return this._currentOptician;
    }

    set currentDistributor(distributor: Distributor) {
        this._currentDistributor = distributor;
    }

    get currentDistributor(): Distributor {
        return this._currentDistributor;
    }

    set currentCompany(company: Company) {
        this._currentCompany = company;
    }

    get currentCompany(): Company {
        return this._currentCompany;
    }

    set passwordExpiryInfo(passwordExpiryInfo: PasswordExpiryInfo) {
        let passwordExpiryInformation: PasswordExpiryInfo;

        if (passwordExpiryInfo) {
            passwordExpiryInformation = new PasswordExpiryInfo(passwordExpiryInfo);
        } else {
            passwordExpiryInformation = null;
        }

        this._passwordExpiryInfo = passwordExpiryInformation;
    }

    get passwordExpiryInfo(): PasswordExpiryInfo {
        return this._passwordExpiryInfo;
    }

    public async loadPasswordExpiryInfo(): Promise<PasswordExpiryInfo> {
        if (this.authenticatedUser) {
            const result = await lastValueFrom(this.userService.getPasswordExpiryInfo(this.authenticatedUser.UserId));
            this.passwordExpiryInfo = result;
            return result;
        }
    }

    public async loadCurrentAuthenticatedUserItems(): Promise<void> {
        this.currentOptician = await lastValueFrom(
            this.opticianService.getById(this.authenticatedUser.CurrentOpticianId),
        );
        this.currentDistributor = this.currentOptician.Distributor;
        this.currentCompany = await lastValueFrom(this.companyService.getById(this.currentDistributor.CompanyId));
    }

    public setTheme(theme: string): void {
        document.body.classList.remove(this.authenticatedUser.CurrentApplicationTheme + '-theme');
        document.body.classList.add(theme + '-theme');

        window['switchTheme'](theme);
        this.authenticatedUser.CurrentApplicationTheme = theme;
        this.localstorageService.save('authenticatedUser', this.authenticatedUser);
    }

    public applyUserLanguage(): void {
        // set the language to the user's preferred language
        const userLang = this.authenticatedUser.CurrentLanguageCode;

        if (userLang !== this.translateService.currentLang) {
            this.translateService.use(userLang);
        }
    }

    public clearUserData(): void {
        this.authenticatedUser = null;
        this.currentDistributor = null;
        this.currentOptician = null;
    }

    get theme() {
        return this.authenticatedUser ? this.authenticatedUser.CurrentApplicationTheme : 'dark';
    }

    //#region "Role helpers"
    get isOptician(): boolean {
        return (
            this.authenticatedUser &&
            this.authenticatedUser.CurrentRoleId === Roles.Optician &&
            this.hasRole(Roles.Optician)
        );
    }

    get isAdmin(): boolean {
        return (
            this.authenticatedUser &&
            this.authenticatedUser.CurrentRoleId === Roles.Administrator &&
            this.hasRole(Roles.Administrator)
        );
    }

    get isDistributorAdmin(): boolean {
        return (
            this.authenticatedUser &&
            this.authenticatedUser.CurrentRoleId === Roles.DistributorAdmin &&
            this.hasRole(Roles.DistributorAdmin)
        );
    }

    get isDistributorSupport(): boolean {
        return (
            this.authenticatedUser &&
            this.authenticatedUser.CurrentRoleId === Roles.DistributorSupport &&
            this.hasRole(Roles.DistributorSupport)
        );
    }

    get isLensConfigurationAdmin(): boolean {
        return (
            this.authenticatedUser &&
            this.authenticatedUser.CurrentRoleId === Roles.LensConfigurationAdmin &&
            this.hasRole(Roles.LensConfigurationAdmin)
        );
    }

    get isPs(): boolean {
        return this.authenticatedUser && this.authenticatedUser.CurrentRoleId === Roles.Ps && this.hasRole(Roles.Ps);
    }

    get isSales(): boolean {
        return (
            this.authenticatedUser && this.authenticatedUser.CurrentRoleId === Roles.Sales && this.hasRole(Roles.Sales)
        );
    }

    public hasRole(role: Roles): boolean {
        return (
            this.authenticatedUser &&
            this.authenticatedUser.Roles &&
            this.authenticatedUser.Roles.filter((r) => r.Id === role).length > 0
        );
    }
    //#endregion "Role helpers"

    //#region "Additional rights helpers"
    get isMyopiaEnabled(): boolean {
        if (this.isOptician) {
            return this.isOpticianFeatureEnabled(Features.Myopie);
        }
        return this.authenticatedUser && this.isDistributorFeatureEnabled(Features.Myopie);
    }

    get isClientMaintenanceEnabled(): boolean {
        if (this.isOptician) {
            return this.isOpticianFeatureEnabled(Features.ClientMaintenance);
        }
        return this.authenticatedUser && this.isDistributorFeatureEnabled(Features.ClientMaintenance);
    }

    get isAutoCreditAllowed(): boolean {
        if (this.isOptician) {
            return this.isOpticianFeatureEnabled(Features.AutoCreditAllowed);
        }
        return this.authenticatedUser && this.isDistributorFeatureEnabled(Features.AutoCreditAllowed);
    }

    get isExpertModeEnabled(): boolean {
        if (this.isOptician) {
            return this.isOpticianFeatureEnabled(Features.ExpertMode);
        }
        return (
            this.isPs ||
            this.isDistributorSupport ||
            (this.authenticatedUser && this.isDistributorFeatureEnabled(Features.ExpertMode))
        );
    }

    get isLensTypeMedEnabled(): boolean {
        if (this.isOptician) {
            return this.isOpticianFeatureEnabled(Features.LensTypeMed);
        }
        return this.authenticatedUser && this.isDistributorFeatureEnabled(Features.LensTypeMedPlus);
    }

    get isLensTypeMedPlusEnabled(): boolean {
        if (this.isOptician) {
            return this.isOpticianFeatureEnabled(Features.LensTypeMedPlus);
        }
        return this.authenticatedUser && this.isDistributorFeatureEnabled(Features.LensTypeMedPlus);
    }

    get isPowerProfileEnabled(): boolean {
        if (this.isOptician) {
            return this.isOpticianFeatureEnabled(Features.PowerProfile);
        }
        return this.authenticatedUser && this.isDistributorFeatureEnabled(Features.PowerProfile);
    }

    get isReturnsEnabled(): boolean {
        if (this.isOptician) {
            return this.isOpticianFeatureEnabled(Features.ReturnsAllowed);
        }
        return this.authenticatedUser && this.isDistributorFeatureEnabled(Features.ReturnsAllowed);
    }

    get isWebshopEnabled(): boolean {
        return this.isOpticianFeatureEnabled(Features.Webshop);
    }

    get isOrderReceiptsEnabled(): boolean {
        return this.isOpticianFeatureEnabled(Features.IsOrderWithReceiptEnabled);
    }

    get getPerosBranchNumber(): string {
        return this.currentOptician ? this.currentOptician.PerosBranchNumber : null;
    }

    public isCompanyFeatureEnabled(feature: Features): boolean {
        if (this.currentCompany) {
            const feat = this.currentCompany.Features.find((f) => f.Id === feature);

            if (feat != null) {
                return feat.IsEnabled;
            }
        }
        return false;
    }

    public isDistributorFeatureEnabled(feature: Features): boolean {
        if (this.currentDistributor) {
            const feat = this.currentDistributor.Features.find((f) => f.Id === feature);

            if (feat != null) {
                return feat.IsEnabled;
            }
        }
        return false;
    }

    public isOpticianFeatureEnabled(feature: Features): boolean {
        if (this.currentOptician) {
            const feat = this.currentOptician.Features.find((f) => f.Id === feature);

            if (feat != null) {
                return feat.IsEnabled;
            }
        }
        return false;
    }

    public isNewTranslationFeatureEnabled(): boolean {
        return this.appConfigService.appConfig.isNewTranslationFeatureEnabled ?? false;
    }

    //#endregion "Additional rights helpers"

    public onUserSettingChange(user: AuthenticatedUser) {
        user.AccessToken = this.authenticatedUser.AccessToken;
        user.RefreshToken = this.authenticatedUser.RefreshToken;
        this.authenticatedUser = user;

        if (user.CurrentOpticianName) {
            this.currentOptician.Name = decodeURIComponent(user.CurrentOpticianName);
        }
        if (user.CurrentOpticianId) {
            this.currentOptician.Id = user.CurrentOpticianId;
        }
    }

    get isGuiTest(): boolean {
        return this._isGuiTest;
    }

    get screensaverTimeoutInMs(): number | null {
        return this._screensaverTimeoutInMs;
    }

    public setVertexSetting(vertex: number) {
        this.localstorageService.save('vertex', vertex);
    }

    public async getVertex(): Promise<number> {
        if (this.localstorageService.get('vertex')) {
            return +this.localstorageService.get('vertex');
        } else {
            const usersetting = await lastValueFrom(this.userSettingService.getUserSettings());
            this.setVertexSetting(usersetting.VertexDistance);
            return usersetting.VertexDistance;
        }
    }

    public async reloadCurrentCompany(): Promise<Company> {
        const company = await lastValueFrom(this.companyService.getById(this._currentCompany.Id));
        this.currentCompany = company;
        return company;
    }

    public getLicenseAgreements(): Observable<Array<DataSheet>> {
        if (this._authenticatedUser) {
            return iif(
                () => this.licenseDatasheets.length === 0,
                this.dataSheetService.getAgreementDataSheets().pipe(
                    tap((res: DataSheet[]) => {
                        this.licenseDatasheets = res;

                        this.availableDatasheets.licenseAgreement = this.licenseDatasheets.some(
                            (d) => d.Code === DatasheetTypes.LicenseAgreement,
                        );

                        this.availableDatasheets.userRegulations = this.licenseDatasheets.some(
                            (d) => d.Code === DatasheetTypes.UserRegulations,
                        );

                        this.availableDatasheets.privacyPolicy = this.licenseDatasheets.some(
                            (d) => d.Code === DatasheetTypes.PrivacyPolicy,
                        );
                    }),
                ),
                of(this.licenseDatasheets),
            );
        }

        return of([]);
    }
}
