<div class="flex-fill position-relative image-wrapper">
    <div *ngIf="fittedLens && heightMapImage">
        <div *ngIf="!heightMapImage.ImageData">
            {{ 'general.imagenotavailable' | translate }}
        </div>
        <div class="rounded d-flex justify-content-center" *ngIf="heightMapImage.ImageData">
            <div>
                <img
                    alt="height-map"
                    [src]="heightMapImage.ImageData"
                    class="img-fluid measurement-image"
                    id="{{ id }}-image-heightmap"
                    (click)="onHeightMapHoverOrClick($event)" />
            </div>
            <canvas class="d-none" id="{{ id }}-image-heightmap-canvas"></canvas>
        </div>
        <div class="d-flex" *ngIf="heightMapImage.ImageData">
            <div class="legend height">
                {{ 'fitlens.sagitta' | translate }}:
                {{ height | numberFormat: '{ "decimalCount": "0"}' }}
            </div>
        </div>
    </div>

    <div *ngIf="fittedLens && fluoImage">
        <div *ngIf="!fluoImage.ImageData">
            {{ 'general.imagenotavailable' | translate }}
        </div>
        <div class="rounded d-flex justify-content-center" *ngIf="fluoImage.ImageData">
            <div>
                <img
                    alt="imagedata"
                    [src]="fluoImage.ImageData"
                    class="img-fluid measurement-image"
                    id="{{ id }}-image-fluo"
                    (click)="onFluoHoverOrClick($event)" />
            </div>
            <canvas class="d-none" id="{{ id }}-image-fluo-canvas"></canvas>
        </div>
        <div class="d-flex" *ngIf="fluoImage.ImageData">
            <div class="legend thickness">
                {{ 'general.lensThickness' | translate }}:
                {{ fluoThickness | numberFormat: '{ "decimalCount": "0"}' }}
                &micro;m
            </div>
        </div>
    </div>

    <div *ngIf="topoImage">
        <div *ngIf="!topoImage.ImageData">
            {{ 'general.imagenotavailable' | translate }}
        </div>
        <div class="position-relative rounded d-flex justify-content-center" *ngIf="topoImage.ImageData">
            <div
                *ngIf="topographicMeasurement && topographicMeasurement.IsBaseLine && displayBaselineTag"
                class="position-absolute baseline">
                <span class="badge badge-outline mx-auto mt-2">
                    {{ 'general.baseline' | translate }}
                    <i class="fas fa-check-circle"></i>
                </span>
            </div>
            <div>
                <img
                    alt="topo"
                    [src]="topoImage.ImageData"
                    class="img-fluid measurement-image"
                    id="{{ id }}-image-topo"
                    (click)="onTopoHoverOrClick($event)" />
            </div>
            <canvas class="d-none" id="{{ id }}-image-topo-canvas"></canvas>
        </div>
        <div class="d-flex" *ngIf="topoImage.ImageData">
            <div class="legend radiusandsphere" *ngIf="imageOptions.topoImageOptions.UseMm">
                {{ 'fitlens.radius' | translate }}:
                {{ radius | numberFormat: '{ "decimalCount": "2"}' }}
                <span *ngIf="radius">{{ 'units.millimeter' | translate }}</span>
            </div>
            <div class="legend radiusandsphere" *ngIf="!imageOptions.topoImageOptions.UseMm">
                {{ 'lens.geometry.sphere' | translate }}:
                {{ radius | numberFormat: '{ "decimalCount": "1"}' }}
                <span *ngIf="radius">{{ 'units.dioptry' | translate }}</span>
            </div>
            <div
                class="legend evalue"
                *ngIf="imageOptions.topoImageOptions.UseMm && imageOptions.ImageChoice !== 'diff'">
                {{ 'fitlens.evalue' | translate }}: {{ eValue }}
            </div>
        </div>
    </div>

    <div *ngIf="fittedLens && lensProfileImage">
        <div class="p-2 rounded" *ngIf="lensProfileImage.ImageData">
            <img
                alt="lens profile"
                [src]="lensProfileImage.ImageData"
                class="img-fluid measurement-image"
                id="{{ id }}-image-lensprofile" />
        </div>
    </div>

    <div class="position-absolute d-flex align-items-center rounded img-overlay img-loading" *ngIf="loading">
        <div class="spinner-border spinner-border-sm m-auto"></div>
    </div>

    <div class="position-absolute d-flex align-items-center rounded img-overlay img-error" *ngIf="imageError">
        <div class="m-auto">
            <span class="text-danger"><i class="fa fa-exclamation-triangle"></i></span>
            {{ imageErrorMessage | translate }}
        </div>
    </div>
</div>
