import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListOption } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class EyeService {
    private readonly basePath = 'api/eyes';

    constructor(private readonly httpClient: HttpClient) {}

    public getEyeSides(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/sides/options`);
    }
}
