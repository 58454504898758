<div class="d-flex mt-4">
    <div class="pagination">
        <button (click)="onClickPage('first')" [disabled]="pageIndex === 0" class="align-middle">
            <h6 class="my-1"><<</h6>
        </button>
        <button (click)="onClickPage('previous')" [disabled]="pageIndex === 0" class="align-middle">
            <h6 class="my-1"><</h6>
        </button>
        <button
            *ngFor="let page of pagesShown"
            (click)="onClickPage(page)"
            class="align-middle"
            [ngClass]="this.pageIndex === page ? 'selectedPage' : ''">
            <h6 class="my-1">{{ page + 1 }}</h6>
        </button>
        <button (click)="onClickPage('next')" [disabled]="pageIndex + 1 === maxPage" class="align-middle">
            <h6 class="my-1">></h6>
        </button>
        <button (click)="onClickPage('last')" [disabled]="pageIndex + 1 === maxPage" class="align-middle">
            <h6 class="my-1">>></h6>
        </button>
    </div>
    <div class="ms-4 align-self-center">
        {{ itemsShownLowerBound }} - {{ itemsShownUpperBound }} {{ 'general.shown' | translate }}, {{ totalReturns }}
        {{ 'general.total' | translate }}
    </div>
</div>
