import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DreamLiteFittedLensInfo } from '@app/professional-service/models/DreamLiteFittedLensInfo.model';
import { DreamlitefixtypesDialogComponent } from '@app/professional-service/dialogs/dreamlitefixtypes-dialog.component';
import { FittedLens } from '@app/shared/models/fitted-lens.model';
import { TranslateService } from '@ngx-translate/core';
import { OrderLineStatus } from '@app/shared/enums';
import { DatePipeProxy } from '@app/shared/pipes/date.pipe';

@Component({
    selector: 'ps-dreamlite-table',
    templateUrl: './ps-dreamlite-table.component.html',
    styleUrls: ['./ps-dreamlite-table.component.scss'],
})
export class PsDreamliteTableComponent implements OnInit {
    @Input() dreamLites: DreamLiteFittedLensInfo[];
    @Input() fittedLens: FittedLens;
    @Output() onLensDeleted: EventEmitter<DreamLiteFittedLensInfo> = new EventEmitter();
    @Output() onLensSelected: EventEmitter<DreamLiteFittedLensInfo> = new EventEmitter();
    @Output() onReset = new EventEmitter();
    @Output() onFictionalLensAdded = new EventEmitter();
    @Output() onDreamLiteFixtypeResult: EventEmitter<FittedLens> = new EventEmitter();

    hasConceptOfFittedLenses = false;

    private selectedDreamLiteFittedLensInfo: DreamLiteFittedLensInfo;

    constructor(
        private modalService: BsModalService,
        private translate: TranslateService,
        private mpcdate: DatePipeProxy,
    ) {}

    ngOnInit() {
        if (this.dreamLites && this.dreamLites.length > 0) {
            const dreamLite = this.dreamLites.find((dl) => dl.ConceptOfFittedLensId !== null);
            this.hasConceptOfFittedLenses = !!dreamLite;
        }
    }

    deleteFictionalDreamLiteLens(dreamLiteFittedLensInfo: DreamLiteFittedLensInfo): void {
        this.onLensDeleted.emit(dreamLiteFittedLensInfo);
    }

    selectDreamLiteLens(dreamLiteFittedLensInfo: DreamLiteFittedLensInfo): void {
        this.selectedDreamLiteFittedLensInfo = dreamLiteFittedLensInfo;
        this.onLensSelected.emit(dreamLiteFittedLensInfo);
    }

    addFictionalDreamLiteLens(): void {
        this.onFictionalLensAdded.emit();
    }

    openFixTypesDialog(): void {
        const options: unknown = {
            initialState: {
                fittedLens: this.fittedLens,
            },
            class: 'modal-half',
        };
        const dreamLiteFixTypesModalRef = this.modalService.show(DreamlitefixtypesDialogComponent, options);
        const subscription = this.modalService.onHide.subscribe(() => {
            if (!dreamLiteFixTypesModalRef.content.isCancelled) {
                this.fittedLens = dreamLiteFixTypesModalRef.content.fittedLens;
                this.onDreamLiteFixtypeResult.emit(this.fittedLens);
            }
            subscription.unsubscribe();
        });
    }

    reset(): void {
        this.onReset.emit();
    }

    isDreamLiteLensSelected(dreamLiteFittedLensInfo: DreamLiteFittedLensInfo): boolean {
        if (dreamLiteFittedLensInfo && this.selectedDreamLiteFittedLensInfo) {
            return dreamLiteFittedLensInfo.Id === this.selectedDreamLiteFittedLensInfo.Id;
        }
        return false;
    }

    getClassForDreamLiteLens(dreamLiteFittedLensInfo: DreamLiteFittedLensInfo): string {
        return this.isDreamLiteLensSelected(dreamLiteFittedLensInfo) ? ' selected' : '';
    }

    getDreamLiteFittedLensInfoOrderDate(dreamLiteFittedLensInfo: DreamLiteFittedLensInfo): string {
        if (dreamLiteFittedLensInfo.MiddlewareExportDate === null && dreamLiteFittedLensInfo.ReadyForMiddlewareExport) {
            return this.translate.instant('orderlinestatus.pending');
        }
        if (dreamLiteFittedLensInfo.OrderLineStatusId === OrderLineStatus.Cancelled) {
            return this.translate.instant('orderlinestatus.cancelled');
        }
        if (dreamLiteFittedLensInfo.OrderDate != null && dreamLiteFittedLensInfo.MiddlewareExportDate != null) {
            return this.mpcdate.transform(dreamLiteFittedLensInfo.OrderDate);
        }
        return this.translate.instant('general.advice');
    }
}
