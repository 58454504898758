import { LensDefinitionGeometryElement } from '@app/shared/models/lensDefinitionGeometryElement.model';

export class LensDefinitionGeometryPolyModel extends LensDefinitionGeometryElement {
    constructor() {
        super();
        this.$type = 'LensDefinitionGeometryPoly';
        this.InstanceType = 'Poly';
    }
    public PolySemiDiameterStart: string;
    public PolySemiDiameterEnd: string;
    public PolyCoeffA: string;
    public PolyCoeffB: string;
    public PolyCoeffC: string;
    public PolyCoeffD: string;

    public PolyCoeffA2: string;
    public PolyCoeffB2: string;
    public PolyCoeffC2: string;
    public PolyCoeffD2: string;

    //CalculationResults
    public PolySemiDiameterStartResult: number;
    public PolySemiDiameterEndResult: number;
    public PolyCoeffAResult: number;
    public PolyCoeffBResult: number;
    public PolyCoeffCResult: number;
    public PolyCoeffDResult: number;

    public PolyCoeffA2Result: number;
    public PolyCoeffB2Result: number;
    public PolyCoeffC2Result: number;
    public PolyCoeffD2Result: number;
}
