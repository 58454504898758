import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-register-optician-success',
    templateUrl: './register-optician-success.component.html',
    styleUrls: ['./register-optician-success.component.scss'],
})
export class RegisterOptcianSuccessComponent {
    constructor(private router: Router) {}

    goToLogin(): void {
        this.router.navigate(['login']);
    }
}
