import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupportOrderStatus } from '@app/professional-service/models/SupportOrderStatus.model';
import { SupportOrderQueryCriteria } from '@app/professional-service/models/SupportOrderQueryCriteria.model';
import { SupportOrderList } from '@app/professional-service/models/SupportOrderList.model';
import { SupportOrderListFilter } from '@app/shared/models/support-order-list-filter.model';
import { HttpUtil } from '@app/shared/utilities/http.util';

@Injectable({
    providedIn: 'root',
})
export class SupportOrderService {
    private readonly basePath = 'api/support-orders';

    constructor(private readonly httpClient: HttpClient) {}

    public getSupportOrders(filter: SupportOrderQueryCriteria): Observable<SupportOrderList> {
        const body: SupportOrderListFilter = {
            Id: filter.FilterByFields.Id,
            Skip: filter.PageIndex,
            Take: filter.PageSize,
            BirthDate: filter.FilterByFields.BirthDate,
            ClientReference: filter.FilterByFields.ClientReference,
            ClientReference2: filter.FilterByFields.ClientReference2,
            Date: filter.FilterByFields.Date,
            LensTypeCode: filter.FilterByFields.LensTypeCode,
            Message: filter.FilterByFields.Message,
            OpticianName: filter.FilterByFields.OpticianName,
            OrderReference: filter.FilterByFields.OrderReference,
            PerosOrderNumber: filter.FilterByFields.PerosOrderNumber,
            ProposalTypeName: filter.FilterByFields.ProposalTypeName,
            SupporterName: filter.FilterByFields.SupporterName,
            ShowMyItemsOnly: filter.ShowMyItemsOnly,
            SupportOrderStatusId: filter.SupportOrderStatusId,
            SortingName: filter.OrderByField,
            IsAscending: filter.OrderByAscending,
            ShowSupportOrdersWithNewMessageOnly: filter.ShowSupportOrdersWithNewMessageOnly,
        };

        const options = {
            params: HttpUtil.mapObjectToHttpParams(body),
        };

        return this.httpClient.get<SupportOrderList>(this.basePath, options);
    }

    public getSupportOrderStatuses(): Observable<SupportOrderStatus[]> {
        return this.httpClient.get<SupportOrderStatus[]>(`${this.basePath}/status/options`);
    }

    public getSupportOrderStatusCounts(): Observable<unknown> {
        return this.httpClient.get(`${this.basePath}/status/counts`);
    }

    public getSupportOrderRequiresAttentionCount(): Observable<number> {
        return this.httpClient.get<number>(`${this.basePath}/count/requires-attention`);
    }

    public getMySupportOrdersCount(): Observable<number> {
        return this.httpClient.get<number>(`${this.basePath}/count/my`);
    }

    public getUnreadMessagesCount(): Observable<number> {
        return this.httpClient.get<number>(`${this.basePath}/count/new-messages`);
    }
}
