import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EyeSides } from '@app/shared/enums';
import { ResetResult } from '../models/resetResult.model';

@Component({
    selector: 'fitlens-reset-dialog',
    templateUrl: './fitlens-reset-dialog.component.html',
})
export class FitlensResetDialogComponent {
    @Input() isRightEyeSideSelected = true;
    @Input() isLeftEyeSideSelected = true;

    eyeSides = EyeSides;
    result: ResetResult;

    constructor(public bsModalRef: BsModalRef) {}

    reset(eyeSide: EyeSides): void {
        this.result = { reset: true, eyeSide: eyeSide };
        this.bsModalRef.hide();
    }
}
