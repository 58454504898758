export class EyeliteNote {
    constructor(eyeliteNote: EyeliteNote) {
        this.Note = eyeliteNote.Note;
        this.Date = eyeliteNote.Date;
        this.Status = eyeliteNote.Status;
    }

    public Note: string;
    public Date: string;
    public Status: number;
}
