import { Component, EmbeddedViewRef, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { Roles } from '@app/shared/enums';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { FooterService } from '@app/shared/appservices/footer.service';

@Component({
    selector: 'main-footer',
    templateUrl: 'main-footer.component.html',
})
export class MainFooterComponent implements OnInit {
    current: EmbeddedViewRef<unknown> | null = null;
    roles = Roles;

    @ViewChild('vcr', { static: true, read: ViewContainerRef })
    vcr: ViewContainerRef;

    constructor(
        public appState: AppStateService,
        public footerService: FooterService,
    ) {}

    ngOnInit(): void {
        this.footerService.contents.subscribe((ref) => {
            if (this.current !== null) {
                this.current.destroy();
                this.current = null;
            }
            if (ref === null) {
                return;
            }
            this.current = this.vcr.createEmbeddedView(ref);
        });
    }
}
