import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, lastValueFrom, Observable } from 'rxjs';
import { switchMap, finalize } from 'rxjs/operators';
import { ClientService } from '@app/core/services/api/client.service';
import {
    Client,
    LensesFollowUpRequest,
    CreateLensesReOrderProposalRequest,
    Proposal,
    Order,
    CartItem,
    OrderHistoryLine,
} from '@app/shared/models';
import { FittingService } from '@app/core/services/fitting.service';
import { ProposalService } from '@app/core/services/api/proposal.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { ConfirmService } from '@app/shared/appservices/confirm.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { OrderService } from '@app/core/services/api/order.service';
import {
    ImageChoice,
    LensTypes,
    EyeSides,
    Features,
    FeatureCategories,
    FittingSteps,
    OrderLineStatus,
    OrderTypes,
    Roles,
    WarrantyStatus,
} from '@app/shared/enums';
import { MeasurementHistoryComponent } from '@app/measurement/measurement-history/measurement-history.component';
import { ShopService } from '@app/core/services/shop.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OrderLinesComponent } from '@app/order/order-lines/order-lines.component';
import { ProductService } from '@app/core/services/api/product.service';
import { MultipleBaselinesWarningDialogComponent } from '../../dialogs/multiple-baselines-warning-dialog.component';
import { Product } from '@app/shared/models/product.model';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { AlertService } from '@app/shared/appservices/alert.service';
import { CreateSpectaclesReOrderProposalRequest } from '@app/shared/models/createSpectaclesReOrderProposalRequest.model';
import { CartStorageService } from '@app/core/services/cart-storage.service';
import { TopographicMeasurementService } from '@app/core/services/api/topographic-measurement.service';
import { FeatureSetting } from '@app/shared/models/featureSetting.model';
import { FeatureCheckerService } from '@app/shared/appservices/feature-checker.service';
import { DateService } from '@app/shared/appservices/date.service';

@Component({
    selector: 'fitting-client',
    templateUrl: 'fitting-client.component.html',
    styleUrls: ['fitting-client.component.scss'],
})
export class FittingClientComponent implements OnInit {
    @ViewChild('measurementhistory') measurementHistoryControl: MeasurementHistoryComponent;
    @ViewChild('orderlines') orderLinesControl: OrderLinesComponent;

    Features = Features;
    FeatureCategories = FeatureCategories;

    public unfinishedProposal: Proposal = null;

    tab: number;
    leftLensOrderId: number;
    rightLensOrderId: number;
    selectedLensfilterType: string;
    selectedOrderLines: OrderHistoryLine[] = [];
    imageChoice = ImageChoice;
    loading = true;
    clientId: number;

    isLensInStock = true;

    showInternalNotes = false;
    inDeleteMode = false;

    orderBlockedNotification = false;
    orderBlockedText = '';

    isGlassFittingAllowed = false;
    isAvailableForAnnualSupplyFullfillment = false;
    isFeatureAnnualSupplyEnabled = false;

    opticianBlockedOrderFeature: FeatureSetting = {
        Feature: Features.BlockOrders,
        Category: FeatureCategories.Optician,
        IsEnabled: true,
    };

    private productCodeSpectacles: string;

    clientTabClass(tab: number): object {
        return {
            active: tab === this.tab,
            'title-border-bottom': tab === this.tab,
        };
    }

    constructor(
        public appState: AppStateService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly fittingService: FittingService,
        private readonly modalService: BsModalService,
        private readonly appConfigService: AppConfigService,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        private readonly clientService: ClientService,
        private readonly proposalService: ProposalService,
        private readonly loaderService: LoaderService,
        private readonly translate: TranslateService,
        private readonly confirmService: ConfirmService,
        private readonly featureCheckerService: FeatureCheckerService,
        private readonly orderService: OrderService,
        private readonly shopService: ShopService,
        private readonly productService: ProductService,
        private readonly topographicMeasurementService: TopographicMeasurementService,
        private readonly cartStorageService: CartStorageService,
        private readonly dateService: DateService,
    ) {}

    public ngOnInit(): void {
        this.tab = this.appState.isOpticianFeatureEnabled(Features.IsReceiptsOnlyEnabled) ? 7 : 1;
        this.isOpticianBlocked();
        this.productCodeSpectacles = this.appConfigService.appConfig.productCodeSpectacles;
        this.fittingService.clear();
        this.fittingService.setFittingStep(this.router.url);

        this.route.params
            .pipe(
                switchMap((routeParams: Params) => {
                    if (isNaN(routeParams['id'])) {
                        return this.router.navigate(['/']);
                    }

                    this.clientId = +routeParams['id'];
                    const registerClientOpened = this.clientService.registerClientOpened(this.clientId);

                    const clientObs = this.clientService.getById(this.clientId);
                    return forkJoin([clientObs, registerClientOpened]);
                }),
            )
            .subscribe((result) => {
                this.fittingService.setClient(result[0] as Client);
                this.loading = false;
            });

        this.checkForInternalNotes();
        this.shopService.clear();

        if (this.isFitlensAllowed) {
            this.checkForContinuation();
        }

        this.setGlassFittingAllowed();
        this.isFeatureAnnualSupplyEnabled = this.appState.isCompanyFeatureEnabled(Features.OpticianLensOrderWarranty);
    }

    clickTab($event: MouseEvent, tab: number) {
        $event.preventDefault();
        this.tab = tab;
    }

    get reorderButtonText(): string {
        if (this.isFeatureOrderingSparesEnabled && this.canSelectedOrderLinesBeUsedForSpareOrder()) {
            return this.translateService.instant('general.orderSpare');
        }

        return this.translateService.instant('general.reorder');
    }

    get dreamliteFollowUpAllowed(): boolean {
        return this.appState.currentOptician != null && this.appState.currentOptician.OrthoKSeminarDate != null;
    }

    setGlassFittingAllowed(): void {
        this.isGlassFittingAllowed = this.appState.isOpticianFeatureEnabled(Features.Glass);
    }

    get client(): Client {
        return this.fittingService.state.client;
    }

    onSelectedOrderLinesChanged(selectedOrderLines: OrderHistoryLine[]): void {
        this.selectedOrderLines = selectedOrderLines;

        const right = selectedOrderLines.find((x) => x.FittedLens.EyeSideId === EyeSides.Od);
        this.rightLensOrderId = right != null ? right.OrderId : null;
        const left = selectedOrderLines.find((x) => x.FittedLens.EyeSideId === EyeSides.Os);
        this.leftLensOrderId = left != null ? left.OrderId : null;

        // When all of the selectedOrderLines have their product available, we can update the lens in stock status
        this.isLensInStock = this.selectedOrderLines.every(
            (x) =>
                x.Product != null &&
                (x.Product.AvailableForDirectOrder || x.Product.AvailableInProcess) &&
                x.Product.MaxQuantity > 0,
        );

        const today = new Date();

        this.isAvailableForAnnualSupplyFullfillment =
            this.selectedOrderLines?.length > 0
                ? this.selectedOrderLines.every(
                      (o) =>
                          o.IsAnnualSupplyApplied &&
                          !o.IsAnnualSupplyFulfilled &&
                          o.OrderLineStatusId === OrderLineStatus.Shipped &&
                          o.ShippedDate &&
                          this.dateService.diffInDays(new Date(o.ShippedDate), today) <= 180,
                  )
                : false;
    }

    get isFeatureWarrantyEnabled(): boolean {
        return this.appState.isCompanyFeatureEnabled(Features.OpticianLensOrderWarranty);
    }

    get isFeatureOrderingSparesEnabled(): boolean {
        return this.appState.isCompanyFeatureEnabled(Features.OrderingSpares);
    }

    get isFitlensAllowed(): boolean {
        return this.appState.isCompanyFeatureEnabled(Features.EnableFitLens);
    }

    get isReOrderEnabled(): boolean {
        if (!this.canSelectedOrderLinesBeUsedForNewOrder()) {
            return false;
        }

        const hasWebshopOrderLines = this.selectedOrderLines.find((x) => x.OrderTypeId === OrderTypes.WebshopOrder);
        if (hasWebshopOrderLines) {
            const orderTypeIds = this.selectedOrderLines.map((x) => x.OrderTypeId);
            const uniqueOrderTypeIds = Array.from(new Set(orderTypeIds));

            if (uniqueOrderTypeIds.length > 1) {
                return false;
            }
        }

        return true;
    }

    private areSpectaclesOrderlinesSelected(): boolean {
        return (
            this.selectedOrderLines && this.selectedOrderLines.some((x) => x.FittedLens.LensTypeId === LensTypes.Glass)
        );
    }

    private canSelectedOrderLinesBeUsedForNewOrder(): boolean {
        if (!this.selectedOrderLines || this.selectedOrderLines.length === 0) {
            return false;
        }

        const containsSpares = this.selectedOrderLines.some((ol) => ol.IsSpare);

        if (containsSpares) {
            return false;
        }

        if (
            this.featureCheckerService.check([
                {
                    Feature: Features.HideOrderStatusFields,
                    Category: FeatureCategories.Company,
                    IsEnabled: false,
                    CompanyToCheck: this.appState.currentCompany,
                },
            ])
        ) {
            const areShippedOrInvoiced = this.selectedOrderLines.every(
                (ol) =>
                    ol.OrderLineStatusId === OrderLineStatus.Shipped ||
                    ol.OrderLineStatusId === OrderLineStatus.Invoiced,
            );

            if (!areShippedOrInvoiced) {
                return false;
            }
        }

        const areDifferentLensTypes =
            this.selectedOrderLines.length > 1 &&
            this.selectedOrderLines[0].FittedLens.LensTypeId !== this.selectedOrderLines[1].FittedLens.LensTypeId;

        if (areDifferentLensTypes) {
            return false;
        }

        if (this.areSpectaclesOrderlinesSelected() && this.selectedOrderLines.length > 1) {
            const differentSpectaclesOrderIds =
                this.selectedOrderLines[0].OrderId !== this.selectedOrderLines[1].OrderId;
            if (differentSpectaclesOrderIds) {
                return false;
            }
        }

        return true;
    }

    private canSelectedOrderLinesBeUsedForWarranty(): boolean {
        if (this.selectedOrderLines.length == 0) {
            return false;
        }

        if (this.selectedOrderLines.some((x) => x.WarrantyExchangeAllowed === false)) {
            return false;
        }

        const warrantyProductNotAllowed = this.selectedOrderLines.some(
            (ol) => ol.Product.WarrantyStatus === WarrantyStatus.Disabled,
        );
        if (warrantyProductNotAllowed) {
            return false;
        }

        return true;
    }

    private canSelectedOrderLinesBeUsedForSpareOrder(): boolean {
        if (!this.canSelectedOrderLinesBeUsedForNewOrder()) {
            return false;
        }

        if (this.areSpectaclesOrderlinesSelected()) {
            return false;
        }

        const spareProductNotAllowed = this.selectedOrderLines.some((ol) => !ol.Product.IsSpareEnabled);
        if (spareProductNotAllowed) {
            return false;
        }

        const spareOrderOrderDateThresholdDays = 90;
        const currentDate = new Date();

        this.selectedOrderLines.forEach((orderline) => {
            const lensOrderDate = new Date(orderline.OrderDate);
            const lensOrderDateDaysAgo = this.dateService.diffInDays(lensOrderDate, currentDate);
            if (lensOrderDateDaysAgo > spareOrderOrderDateThresholdDays) {
                return false;
            }
        });

        return true;
    }

    get isFollowUpEnabled(): boolean {
        if (
            !this.canSelectedOrderLinesBeUsedForNewOrder() ||
            (this.isFeatureWarrantyEnabled && !this.canSelectedOrderLinesBeUsedForWarranty())
        ) {
            return false;
        }

        const hasWebshopOrderLines = this.selectedOrderLines.find((x) => x.OrderTypeId === OrderTypes.WebshopOrder);
        if (hasWebshopOrderLines) {
            return false;
        }

        const hasDoneSeminar = this.appState.currentOptician.OrthoKSeminarDate !== null;
        if (!hasDoneSeminar) {
            // don't allow ortho-k and medical lenses if optician didn't follow the seminar
            const hasOrthokOrMedLenses = this.selectedOrderLines.find(
                (ol) => [LensTypes.DreamLite, LensTypes.Med, LensTypes.MedPlus].indexOf(ol.FittedLens.LensTypeId) > -1,
            );
            if (hasOrthokOrMedLenses) {
                return false;
            }
        }

        return true;
    }

    get isMyopiaActive() {
        if (!this.client) {
            return false;
        }

        return this.client.Myopie && this.appState.isOpticianFeatureEnabled(Features.Myopie);
    }

    get isMarkAsBaseLensVisible(): boolean {
        if (!this.selectedOrderLines || this.selectedOrderLines.length === 0) {
            return false;
        }

        const hasSelectedOrthokLenses = !!this.selectedOrderLines.find(
            (x) => x.FittedLens.LensTypeId === LensTypes.DreamLite,
        );
        return this.isMyopiaActive && hasSelectedOrthokLenses;
    }

    // will be true in the following case:
    // - user selected 1 od lens and/or 1 os lens
    // - all selected lenses should be part of a shipped order
    get isMarkAsMcBaseLensAllowed(): boolean {
        const hasSelectedUnshippedItems = this.selectedOrderLines.find(
            (x) => x.OrderLineStatusId !== OrderLineStatus.Shipped,
        );

        if (hasSelectedUnshippedItems) {
            return false;
        }

        const odLensCount = this.selectedOrderLines.filter((x) => x.FittedLens?.EyeSideId === EyeSides.Od).length;
        const osLensCount = this.selectedOrderLines.filter((x) => x.FittedLens?.EyeSideId === EyeSides.Os).length;

        if (odLensCount > 1 || osLensCount > 1) {
            return false;
        }

        if (odLensCount === 0 && osLensCount === 0) {
            return false;
        }

        const hasSelectedNonOrthokLenses = this.selectedOrderLines.find(
            (x) => x.FittedLens.LensTypeId !== LensTypes.DreamLite,
        );

        return !hasSelectedNonOrthokLenses;
    }

    async markAsMcBaseLens() {
        this.loaderService.show();

        const leftLensOrderId = this.leftLensOrderId;
        const rightLensOrderId = this.rightLensOrderId;

        try {
            await lastValueFrom(
                this.orderService.updateOrderNulLenzen({
                    leftLensOrderId,
                    rightLensOrderId,
                }),
            );
            this.orderLinesControl.refresh();
        } finally {
            this.loaderService.hide();
        }
    }

    onSelectedLensfilterChanged(lensType: string) {
        this.selectedLensfilterType = lensType;
    }

    previous($event: MouseEvent): void {
        $event.preventDefault();
        this.fittingService.setClient(null);
        this.fittingService.gotoPreviousStep();
    }

    onNewFitClick(): void {
        this.topographicMeasurementService
            .hasMultipleTopographicMeasurementBaseLines(this.clientId)
            .subscribe((response) => {
                if (response) {
                    const modalOptions: unknown = {
                        initialState: {
                            onContinue: () => {
                                this.tab = 2;
                            },
                            onCancel: () => {
                                this.newFit();
                            },
                        },
                        class: '',
                    };

                    this.modalService.show(MultipleBaselinesWarningDialogComponent, modalOptions);
                } else {
                    this.newFit();
                }
            });
    }

    newFit(): void {
        this.startFitting();
    }

    onGlassFitClick(): void {
        this.checkActiveSpectaclesProduct().then((result) => {
            if (result === false) {
                this.alertService.error(this.translateService.instant('info.activateSpectaclesProduct'));
                return;
            }
            this.fittingService.setIsGlassFitting(true);
            this.startFitting();
        });
    }

    startFitting(): void {
        this.loaderService.show();

        this.proposalService
            .deleteUnfinishedProposal(this.clientId)
            .pipe(
                switchMap(() => this.proposalService.startWithClient(this.fittingService.state.client.Id)),
                finalize(() => {
                    this.loaderService.hide();
                }),
            )
            .subscribe((result) => {
                this.fittingService.setProposal(result);
                this.fittingService.setNewFit();
                this.fittingService.gotoNextStep();
            });
    }

    async followUp(): Promise<void> {
        this.loaderService.show();

        const warrantyInCart = await this.checkForBlockingOrder(
            this.fittingService.getClientId(),
            this.leftLensOrderId,
            this.rightLensOrderId,
            (p) => p.HasWarranty,
        );
        if (warrantyInCart) {
            this.orderBlockedText = 'ErrorMessages.WARRANTY_ALREADY_IN_CART';
            this.orderBlockedNotification = true;
            this.loaderService.hide();
        } else {
            this.createFollowUpProposal()
                .pipe(
                    finalize(() => {
                        this.loaderService.hide();
                    }),
                )
                .subscribe((result) => {
                    this.fittingService.setProposal(result);
                    this.fittingService.setFollowUp(this.leftLensOrderId != null, this.rightLensOrderId != null);
                    this.fittingService.gotoNextStep();
                });
        }
    }

    onFullfillAnnualSupply() {
        this.loaderService.show();
        const request = new CreateLensesReOrderProposalRequest();
        request.LeftLensOrderId = this.leftLensOrderId;
        request.RightLensOrderId = this.rightLensOrderId;
        request.ClientId = this.fittingService.getClientId();

        this.proposalService
            .createLensesReOrderProposal(request)
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe((result) => {
                this.fittingService.setProposal(result);
                this.fittingService.setReOrder(!!this.leftLensOrderId, !!this.rightLensOrderId, false, true);
                this.fittingService.gotoStep(FittingSteps.Overview);
            });
    }

    private createFollowUpProposal(): Observable<Proposal> {
        const isSpectaclesOrder = this.selectedOrderLines[0].FittedLens.LensTypeId === LensTypes.Glass;

        if (isSpectaclesOrder) {
            this.fittingService.setIsGlassFitting(true);
            return this.proposalService.createSpectaclesFollowUpProposal(this.selectedOrderLines[0].OrderId);
        } else {
            const request = new LensesFollowUpRequest();

            request.LeftLensOrderId = this.leftLensOrderId;
            request.RightLensOrderId = this.rightLensOrderId;

            const isDreamliteFollowUp = this.selectedOrderLines.every(
                (ol) => ol.FittedLens.LensTypeId === LensTypes.DreamLite,
            );

            if (this.isFeatureWarrantyEnabled && this.canSelectedOrderLinesBeUsedForWarranty()) {
                request.WarrantyExchange = true;
            }

            if (isDreamliteFollowUp) {
                return this.proposalService.createDreamLiteFollowUpProposal(request);
            }

            return this.proposalService.createLensesFollowUpProposal(request);
        }
    }

    async reOrder(): Promise<void> {
        if (this.selectedOrderLines.length === 0) {
            return;
        }

        this.loaderService.show();

        if (this.selectedOrderLines[0].OrderTypeId === OrderTypes.WebshopOrder) {
            const orderlines = [...this.selectedOrderLines];
            this.reorderWebshopOrder(orderlines);
        } else if (this.selectedOrderLines[0].FittedLens.LensTypeId === LensTypes.Glass) {
            this.fittingService.setIsGlassFitting(true);
            const request = new CreateSpectaclesReOrderProposalRequest();

            request.OrderId = this.selectedOrderLines[0].OrderId;
            request.ClientId = this.fittingService.getClientId();

            this.proposalService
                .createSpectaclesReOrderProposal(request)
                .pipe(finalize(() => this.loaderService.hide()))
                .subscribe((result) => {
                    this.fittingService.setProposal(result);
                    this.fittingService.setReOrder(!!this.leftLensOrderId, !!this.rightLensOrderId, false, false);
                    this.fittingService.gotoStep(FittingSteps.Overview);
                });
        } else {
            const isSpareOrder = this.isFeatureOrderingSparesEnabled
                ? this.canSelectedOrderLinesBeUsedForSpareOrder()
                : false;

            const request = new CreateLensesReOrderProposalRequest();
            request.LeftLensOrderId = this.leftLensOrderId;
            request.RightLensOrderId = this.rightLensOrderId;
            request.ClientId = this.fittingService.getClientId();

            const spareInCart = await this.checkForBlockingOrder(
                request.ClientId,
                this.leftLensOrderId,
                this.rightLensOrderId,
                (p) => p.IsSpareOrder,
            );

            if (spareInCart) {
                this.orderBlockedText = 'ErrorMessages.SPARE_ALREADY_IN_CART';
                this.orderBlockedNotification = true;
            } else {
                this.proposalService
                    .createLensesReOrderProposal(request)
                    .pipe(finalize(() => this.loaderService.hide()))
                    .subscribe((result) => {
                        this.fittingService.setProposal(result);
                        this.fittingService.setReOrder(
                            !!this.leftLensOrderId,
                            !!this.rightLensOrderId,
                            isSpareOrder,
                            false,
                        );
                        this.fittingService.gotoStep(FittingSteps.Overview);
                    });
            }
        }
    }

    editClient(): void {
        this.router.navigate(['/client/edit', this.fittingService.getClientId()]);
    }

    async toggleMyopia(): Promise<void> {
        const myopia = !this.fittingService.state.client.Myopie;
        const message = this.translate.instant(
            myopia ? 'client.enablemyopiaconfirmation' : 'client.disablemyopiaconfirmation',
        );

        const result = await this.confirmService.show('', message);

        if (result) {
            this.loaderService.show();
            this.clientService
                .saveMyopia(this.fittingService.getClientId(), myopia)
                .pipe(
                    finalize(() => {
                        this.loaderService.hide();
                    }),
                )
                .subscribe(() => {
                    this.fittingService.state.client.Myopie = myopia;
                });
        }
    }

    get measurementHistoryReloading(): boolean {
        if (this.measurementHistoryControl) {
            return this.measurementHistoryControl.isReloading;
        }

        return false;
    }

    get currentImageChoice(): ImageChoice {
        if (this.measurementHistoryControl) {
            return this.measurementHistoryControl.currentImageChoice;
        }

        return ImageChoice.Current;
    }

    setImageChoice(imageChoice: ImageChoice): void {
        if (this.measurementHistoryControl) {
            this.measurementHistoryControl.setImageChoice(imageChoice);
        }
    }

    get isMarkAsBaselineEnabled(): boolean {
        if (!this.measurementHistoryControl) {
            return false;
        }

        return (
            !this.measurementHistoryControl.isLoading && this.measurementHistoryControl.isRegularTopoMeasurementSelected
        );
    }

    markAsBaseline(): void {
        this.measurementHistoryControl.markAsBaseline();
    }

    gotoWebshop(): void {
        this.shopService.client = this.fittingService.state.client;
        this.router.navigate(['/shop']);
    }

    checkForInternalNotes() {
        const role = this.appState.authenticatedUser.Roles.filter((filter) => filter.Id === Roles.Ps);
        if (role.length > 0) {
            this.showInternalNotes = true;
        }
    }

    private checkForContinuation(): void {
        this.proposalService.continueProposal(this.clientId).subscribe((proposal) => {
            if (proposal && proposal.Id) {
                this.unfinishedProposal = proposal;
            }
        });
    }

    public fittingContinue(flag: boolean): void {
        if (this.fittingService.state.client) {
            if (flag) {
                if (this.fittingService.state.proposal?.LensTypeId === LensTypes.Glass) {
                    this.fittingService.setIsGlassFitting(true);
                }

                this.fittingService.setProposalForContinuation(this.unfinishedProposal);
                this.fittingService.continueExistingProposal();
            } else {
                this.proposalService.deleteUnfinishedProposal(this.fittingService.state.client.Id).subscribe(() => {
                    this.fittingService.clearProposalForContinuation();
                });
            }
        }

        this.unfinishedProposal = null;
    }

    isProductAvailable(product: Product): boolean {
        return product && (product.AvailableForDirectOrder || product.AvailableInProcess) && product.MaxQuantity !== 0;
    }

    async reorderWebshopOrder(orderlines: OrderHistoryLine[]) {
        this.shopService.client = this.fittingService.state.client;

        const orderLeftId = orderlines.filter((x) => x.FittedLens?.EyeSideId === EyeSides.Os).map((x) => x.OrderId)[0];
        const orderRightId = orderlines.filter((x) => x.FittedLens?.EyeSideId === EyeSides.Od).map((x) => x.OrderId)[0];

        let orderLeft: Order;

        if (orderLeftId) {
            orderLeft = await lastValueFrom(this.orderService.getByIdWithComplexData(orderLeftId));
        }

        let orderRight: Order;
        if (orderRightId) {
            if (orderRightId === orderLeftId) {
                orderRight = orderLeft;
            } else {
                orderRight = await lastValueFrom(this.orderService.getByIdWithComplexData(orderRightId));
            }
        }

        this.shopService.openShopWithOrder(orderLeft, orderRight);
    }

    isOpticianBlocked(): void {
        if (this.featureCheckerService.check([this.opticianBlockedOrderFeature])) {
            const message = this.translate.instant('order.opticianBlockedAlert');
            this.alertService.warning(message, true);
        }
    }

    async checkActiveSpectaclesProduct(): Promise<boolean> {
        try {
            const product = await lastValueFrom(this.productService.getProductByCode(this.productCodeSpectacles));

            return !!(product && product.LensDefinition);
        } catch (ex) {
            return false;
        }
    }

    async checkForBlockingOrder(
        clientId: number,
        leftPreviousOrderId: number,
        rightPreviousOrderId: number,
        additionalPredicate: (p: CartItem) => boolean,
    ): Promise<boolean> {
        const cart = await this.cartStorageService.loadFromServer();
        return cart.Items.some(
            (cartItem) =>
                additionalPredicate(cartItem) &&
                cartItem.ClientId === clientId &&
                (cartItem.LeftPreviousOrderId === leftPreviousOrderId ||
                    cartItem.RightPreviousOrderId === rightPreviousOrderId),
        );
    }

    closeSpareOrderBlockedNotification(): void {
        this.orderBlockedNotification = false;
    }
}
