<div class="row form-group py-1" [ngClass]="customClass">
    <label *ngIf="label" class="my-auto" [ngClass]="labelClass">
        {{ label | translate: labelParams }}
        <i
            *ngIf="info"
            class="fa fa-question-circle"
            popover="{{ info }}"
            placement="right"
            triggers="mouseenter:mouseleave"></i>
    </label>
    <div [ngClass]="controlClass">
        <span id="{{ id }}" class="me-1">{{ displayValue }}</span>
        <span *ngIf="unit && displayValue !== ''">{{ unit | translate }}</span>
    </div>
</div>
