<div class="modal-header">
    <h4 class="modal-title m-auto">Tour for: {{ title }}</h4>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-12">
            A product tour web is a website that showcases the features and benefits of a product, software, or service.
            It usually consists of interactive elements that guide the user through the website and explain how the
            product works and what value it provides. A product tour web can help with user onboarding, activation,
            retention, and conversion.
        </div>
    </div>
</div>

<div class="modal-footer accented-background">
    <button class="btn never-show-btn" (click)="neverShow()">Never show</button>
    <button class="btn btn-default ms-auto" (click)="nexTime()">Remind later</button>
    <button class="btn btn-proceed ms-2" (click)="startTour()">Start tour</button>
</div>
