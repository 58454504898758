import { Injectable, NgZone } from '@angular/core';
import { AppStateService } from './appState.service';
import { BsModalService, ModalOptions, BsModalRef } from 'ngx-bootstrap/modal';
import { ScreensaverComponent } from '@app/core/components/screen-saver/screensaver.component';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Injectable()
export class ScreensaverTriggerService {
    private readonly screensaverTimeoutMinutes = 10;

    private timeoutMs: number;
    private timeoutSubscription: number;
    private userActivitySubscription: () => void;
    private screensaverPopup: BsModalRef;

    constructor(
        private readonly appState: AppStateService,
        private readonly modalService: BsModalService,
        private readonly zone: NgZone,
    ) {}

    initialize() {
        this.timeoutMs =
            this.appState.screensaverTimeoutInMs == null
                ? this.screensaverTimeoutMinutes * 1000 * 60
                : this.appState.screensaverTimeoutInMs;

        this.restartScreensaverTimer();

        // run all events subscriptions outside the angular zone,
        // because otherwise each mousemove, keypress, etc. will trigger change detection
        // across the entire application
        this.zone.runOutsideAngular(() => this.subscribeToUserActivity());
    }

    private subscribeToUserActivity() {
        const userActivityEvents = [
            fromEvent(document, 'click'),
            fromEvent(document, 'mousemove'),
            fromEvent(document, 'keydown'),
        ];

        userActivityEvents.forEach((event) => {
            event.pipe(throttleTime(500)).subscribe(() => {
                this.restartScreensaverTimer();
            });
        });
    }

    private restartScreensaverTimer(): void {
        if (this.screensaverPopup) {
            this.zone.run(() => this.screensaverPopup.hide());
            this.screensaverPopup = null;
        }

        this.stopScreensaverTimer();

        this.zone.runOutsideAngular(() => {
            this.timeoutSubscription = window.setTimeout(() => this.triggerScreensaver(), this.timeoutMs);
        });
    }

    private stopScreensaverTimer(): void {
        window.clearTimeout(this.timeoutSubscription);
    }

    private triggerScreensaver() {
        // screensaver over a modal is not supported at this time.
        if (this.modalService.getModalsCount() !== 0 || !this.appState.currentOptician) {
            this.restartScreensaverTimer();
            return;
        }

        this.zone.run(() => this.showScreensaverDialog());
    }

    private showScreensaverDialog(): void {
        const options: ModalOptions = { class: 'screensaver my-0' };
        this.screensaverPopup = this.modalService.show(ScreensaverComponent, options);
    }

    dispose() {
        this.stopScreensaverTimer();
        window.document.removeEventListener('click', this.userActivitySubscription);
        window.document.removeEventListener('mousemove', this.userActivitySubscription);
        window.document.removeEventListener('keydown', this.userActivitySubscription);
    }
}
