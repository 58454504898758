import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    CreateProposalReceiptOrder,
    ProposalReceipt,
    ProposalReceiptInfo,
    ProposalReceiptOrder,
} from '@app/shared/models';
import { FileDownloadService } from '@app/core/services/file-download.service';
import { FindProposalReceiptResponse, ProposalReceiptHistoryLine } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class ProposalReceiptService {
    private readonly basePath = 'api/proposal-receipts';

    constructor(
        private readonly httpClient: HttpClient,
        private fileDownloadService: FileDownloadService,
    ) {}

    public createProposalReceipt(proposalId: number): Observable<ProposalReceipt> {
        return this.httpClient.post<ProposalReceipt>(this.basePath, proposalId);
    }

    public getById(proposalReceiptId: number): Observable<ProposalReceipt> {
        return this.httpClient.get<ProposalReceipt>(`${this.basePath}/${proposalReceiptId}`);
    }

    public find(reference: string, code: string): Observable<FindProposalReceiptResponse> {
        const options = {
            params: new HttpParams().set('Reference', reference).set('Code', code),
        };

        return this.httpClient.get<FindProposalReceiptResponse>(`${this.basePath}/find`, options);
    }

    public getRecentProposalReceiptsForOptician(searchString: string): Observable<ProposalReceiptInfo[]> {
        const options = {
            params: new HttpParams().set('searchstring', searchString),
        };

        return this.httpClient.get<ProposalReceiptInfo[]>(`${this.basePath}/recent`, options);
    }

    public getProposalReceiptOrdersForOptician(): Observable<ProposalReceiptOrder[]> {
        return this.httpClient.get<ProposalReceiptOrder[]>(`${this.basePath}/orders`);
    }

    public downloadPdf(proposalReceiptId: number, culture?: string): Observable<File> {
        const options = {
            params: new HttpParams().set('proposalReceiptId', proposalReceiptId),
            headers: new HttpHeaders({ 'Client-Culture': culture }),
        };

        return this.fileDownloadService.downloadFile(
            `${this.basePath}/download/pdf`,
            options,
            `receipt_${proposalReceiptId}.pdf`,
        );
    }

    public downloadShipmentFramePdf(proposalReceiptId: number): Observable<File> {
        const options = {
            params: new HttpParams().set('proposalReceiptId', proposalReceiptId).set('isFrameOrderShipment', 'true'),
        };

        return this.fileDownloadService.downloadFile(
            `${this.basePath}/download/pdf`,
            options,
            `receiptShipmentFrame_${proposalReceiptId}.pdf`,
        );
    }

    public claimProposalReceipt(createProposalReceiptOrder: CreateProposalReceiptOrder): Observable<void> {
        return this.httpClient.post<void>(`${this.basePath}/claim`, createProposalReceiptOrder);
    }

    public getProposalReceiptLineHistory(clientId: number): Observable<ProposalReceiptHistoryLine[]> {
        const options = {
            params: new HttpParams().set('clientId', clientId),
        };

        return this.httpClient.get<ProposalReceiptHistoryLine[]>(`${this.basePath}/lines/history`, options);
    }
}
