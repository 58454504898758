import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AttachmentService } from '@app/core/services/api/attachment.service';
import { Attachment, Client } from '@app/shared/models';
import { PsSupportOrderComponent } from '../ps-supportorder.component';
import { FileUploadComponent, FileUploadStatus } from '@app/shared/components/fileupload/fileupload.component';
import { takeWhile } from 'rxjs/operators';
import { Util } from '@app/shared/helpers/utility.helper';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'ps-attachments',
    templateUrl: './ps-attachments.component.html',
})
export class PsAttachmentsComponent implements OnInit {
    @ViewChild('fileUploader') fileUploadComponent: FileUploadComponent;

    @Input() psSupportOrderComponent: PsSupportOrderComponent;
    @Input() client: Client;

    attachments: Attachment[];
    loading = true;

    constructor(private attachmentsService: AttachmentService) {}

    ngOnInit() {
        this.getAttachments().then();
    }

    async getAttachments() {
        this.attachments = await lastValueFrom(this.attachmentsService.getAllAttachmentsForClient(this.client.Id));
        this.loading = false;
    }

    uploadFiles(event): Promise<void> {
        if (event) {
            if (
                !this.fileUploadComponent ||
                !this.fileUploadComponent.files ||
                this.fileUploadComponent.files.length === 0
            ) {
                return Promise.resolve();
            }

            const uploadFormData = {
                IsInternal: true,
            };

            if (this.psSupportOrderComponent) {
                uploadFormData['SupportOrderId'] = this.psSupportOrderComponent.getWorkItemId();
            } else {
                uploadFormData['ClientId'] = this.client.Id;
            }

            this.fileUploadComponent.startUpload(uploadFormData, 'api/attachments');

            return new Promise<void>((resolve) => {
                this.fileUploadComponent.uploadStatus
                    .pipe(takeWhile((u) => u === FileUploadStatus.Completed))
                    .subscribe(() => resolve());
            });
        }
    }

    uploadComplete(): void {
        this.getAttachments().then();
    }

    get internalAttachments(): Attachment[] {
        return this.attachments.filter((a) => a.IsInternal === true);
    }

    get externalAttachments(): Attachment[] {
        return this.attachments.filter((a) => a.IsInternal === false);
    }

    get orderId(): number {
        return this.psSupportOrderComponent.getOrder().Id;
    }

    downloadAttachment(attachment: Attachment) {
        this.attachmentsService.downloadAttachment(attachment.Id, attachment.MimeType).subscribe((result: Blob) => {
            Util.openBlobInBrowser(result, attachment.FileName);
        });
    }
}
