import { Pipe, PipeTransform } from '@angular/core';
import { Language } from '../models/language.model';

@Pipe({
    name: 'allowedLanguageInVersionOne',
    pure: false,
})
export class AllowedLanguageInVersionOnePipe implements PipeTransform {
    transform(language: Language): boolean {
        return !language ? false : language.Code === 'nl-NL' || language.Code === 'en-GB';
    }
}
