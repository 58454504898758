import { FittedLens } from '@app/shared/models';
import { ScleralTable } from '../models/scleralTable.model';
import { ProductGroupCodes } from '@app/shared/enums';

export class ScleralCodeGenerator {
    static MiniscleralTable: ScleralTable;
    static SensoScleraEasyTable: ScleralTable;
    static PTVersionsTable: ScleralTable;
    static FullScleralTable: ScleralTable;

    static orderOfLabels = ['SAG', 'RAD', 'PEFA', 'DIAM', 'TORI', 'ScleraOpening', 'Blanching'];

    static orderOfKeysMS = [
        'W',
        '1',
        'X',
        '2',
        'Y',
        '3',
        'Z',
        '4',
        'A',
        '5',
        'B',
        '6',
        'C',
        '7',
        'D',
        '8',
        'E',
        '9',
        'F',
        'P',
        'G',
        'Q',
        'H',
        'R',
        'I',
        'S',
        'J',
        'T',
        'K',
        'L',
        'M',
        'N',
        'O',
    ];

    static orderOfKeysPT = [
        'L',
        'M',
        'N',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
    ];

    static orderOfKeysFS = [
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '0',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
    ];

    static generate(productGroupCode: string, lens: FittedLens): string {
        const { table, labelOrder } = this.getTable(productGroupCode);
        let code = '';

        for (const key of labelOrder) {
            const param = lens.FittedLensParameters.find((x) => x.LensDefinitionParameter.ParameterType.Code === key);

            if (param) {
                const i = table[key].findIndex((x) => x === param.Value);

                if (this.orderOfKeysMS[i]) {
                    code += this.orderOfKeysMS[i];
                } else {
                    code += '?';
                }
            }
        }
        return code;
    }

    private static getTable(code: string) {
        switch (code) {
            case ProductGroupCodes.SCLERALMINI: // mini sclera
                return {
                    table: this.MiniscleralTable ? this.MiniscleralTable : this.generateMiniscleralTable(),
                    labelOrder: ['SAG', 'RAD', 'PEFA', 'DIAM', 'TORI'],
                };
            case ProductGroupCodes.SCLERALEASY: // senso scleral easy
                return {
                    table: this.SensoScleraEasyTable ? this.SensoScleraEasyTable : this.generateSensoScleraEasyTable(),
                    labelOrder: ['SAG', 'RAD', 'PEFA', 'DIAM', 'TORI'],
                };
            //case 'FullScleral': bewust code in commentaar.
            //    return (this.FullScleralTable ? this.FullScleralTable : () => { this.generateFullScleralTable(); return this.FullScleralTable });
            //case 'PTVersions':
            //    return (this.PTVersionsTable ? this.PTVersionsTable : () => { this.generatePTVersionsTable(); return this.PTVersionsTable });
            default:
                console.error('this med lens type is not correct. ' + code);
                return { table: null, labelOrder: null };
        }
    }

    // generating specific tables
    private static generateMiniscleralTable(): ScleralTable {
        this.MiniscleralTable = new ScleralTable();

        for (const label of this.orderOfLabels) {
            this.MiniscleralTable[label] = new Array(this.orderOfKeysMS.length);
        }

        this.fillRange(this.MiniscleralTable['SAG'], this.orderOfKeysMS, 'W', 1, this.createRange(0.25, 7.25, 0.25, 2));
        this.fillRange(this.MiniscleralTable['RAD'], this.orderOfKeysMS, 'Y', 2, this.createRange(7.0, 9.4, 0.2, 2));
        this.fillRange(this.MiniscleralTable['PEFA'], this.orderOfKeysMS, 'Y', 2, this.createRange(-8, 4, 1));
        this.fillRange(this.MiniscleralTable['PEFA'], this.orderOfKeysMS, 'L', 1, this.createRange(5, 8, 1));
        this.fillRange(this.MiniscleralTable['DIAM'], this.orderOfKeysMS, 'X', 2, this.createRange(14.8, 18.0, 0.4, 1));
        this.fillRange(this.MiniscleralTable['TORI'], this.orderOfKeysMS, 'A', 2, this.createRange(1, 8, 1));

        return this.MiniscleralTable;
    }

    private static generateSensoScleraEasyTable(): ScleralTable {
        this.SensoScleraEasyTable = new ScleralTable();

        for (const label of this.orderOfLabels) {
            this.SensoScleraEasyTable[label] = new Array(this.orderOfKeysMS.length);
        }

        this.fillRange(
            this.SensoScleraEasyTable['SAG'],
            this.orderOfKeysMS,
            'W',
            1,
            this.createRange(0.25, 4.75, 0.25, 2),
        );
        this.fillRange(
            this.SensoScleraEasyTable['RAD'],
            this.orderOfKeysMS,
            'A',
            2,
            this.createRange(7.4, 8.8, 0.2, 2),
        );
        this.fillRange(this.SensoScleraEasyTable['PEFA'], this.orderOfKeysMS, 'C', 2, this.createRange(-4, 4, 1));
        this.fillRange(this.SensoScleraEasyTable['PEFA'], this.orderOfKeysMS, 'L', 1, this.createRange(5, 6, 1));
        this.fillRange(
            this.SensoScleraEasyTable['DIAM'],
            this.orderOfKeysMS,
            'X',
            1,
            this.createRange(14.8, 14.8, 0.4, 1),
        );
        this.fillRange(this.SensoScleraEasyTable['TORI'], this.orderOfKeysMS, 'A', 2, this.createRange(1, 3, 1));

        return this.SensoScleraEasyTable;
    }

    // Helper functions
    private static createRange(start: number, finish: number, step = 1, decimals = 0): number[] {
        const numbers: number[] = [];
        for (let num = start; num <= finish; num += step) {
            const newnum = Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
            numbers.push(newnum);
        }
        return numbers;
    }

    private static fillRange(
        tablerow: number[],
        keys: string[],
        startingKey: string,
        step: number,
        range: number[],
    ): void {
        const start = keys.findIndex((x) => x === startingKey);

        for (let i = 0; i < range.length; i++) {
            tablerow[start + i * step] = range[i];
        }
    }
}
