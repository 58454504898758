import { Component, DestroyRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, takeWhile } from 'rxjs/operators';
import { FittingService } from '@app/core/services/fitting.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import {
    Client,
    CartAddItem,
    UpdateCartItemProposal,
    CreateOrder,
    FittedLens,
    FittedLensParameter,
    ListOption,
    Proposal,
    CartItem,
} from '@app/shared/models';
import { ProposalReceiptService } from '@app/core/services/api/proposal-receipt.service';
import { OrderService } from '@app/core/services/api/order.service';
import { FileUploadComponent, FileUploadStatus } from '@app/shared/components/fileupload/fileupload.component';
import { ProposalOptions } from '@app/shared/models/proposalOptions.model';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { SaveFittingContinuationConfiguration } from './saveFittingContinuationConfiguration';
import { LocalStorageService } from '@app/shared/appservices/localstorage.service';
import { LensTypeProductGroupNames } from '@app/shared/models/lensTypeProductGroupNames.model';
import { ProductGroupService } from '@app/core/services/api/product-group.service';
import { FittedLensService } from '@app/core/services/api/fitted-lens.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TipsDialogComponent } from '@app/dialogs/tips-dialog.component';
import { GetValueOfLensParameter } from '@app/shared/helpers/GetValueOfLensParameter';
import { AlertService } from '@app/shared/appservices/alert.service';
import { OrderInfo } from '@app/shared/models/orderInfo.model';
import { Features, FeatureCategories, WarrantyStatus, EyeSides, LensTypes } from '@app/shared/enums';
import { DistributorService } from '@app/core/services/api/distributor.service';
import { PostalCodeValidator } from '@app/shared/validators/postalCodeValidator';
import { Warranty } from '@app/shared/models/warranty.model';
import { CartService } from '@app/core/services/api/cart.service';
import { ShopService } from '@app/core/services/shop.service';
import { CartStorageService } from '@app/core/services/cart-storage.service';
import { localStoredLensInfo } from '@app/shared/models/localStoredLensInfo.model';
import { Observable, lastValueFrom, of } from 'rxjs';
import { UploadOutput } from 'ngx-uploader';
import { FileMetadata } from '@app/shared/models/fileMetadata.model';
import { ByopremiumRadDiamValidator } from '@app/fitlens/validators/byopremiumRadDiamValidator';
import { OrderTrainingDialogComponent } from '@app/shared/components/order-training-dialog/order-training-dialog.component';
import { CountryService } from '@app/core/services/api/country.service';
import { ProposalAnnualSupply } from '@app/shared/models/proposal-annual-supply.model';
import { InputTypes } from '@app/shared/enums';
import { InputNumberRange } from '@app/shared/models/input-number-range.model';
import { FeatureSetting } from '@app/shared/models/featureSetting.model';
import { FeatureCheckerService } from '@app/shared/appservices/feature-checker.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'fitting-overview',
    templateUrl: 'fitting-overview.component.html',
    styleUrls: ['fitting-overview.component.scss'],
})
export class FittingOverviewComponent implements OnInit, OnDestroy {
    @ViewChild('fileUploader') fileUploadComponent: FileUploadComponent;

    Features = Features;
    FeatureCategories = FeatureCategories;
    inputTypes = InputTypes;

    formGroup: UntypedFormGroup;
    directToHomeFormGroup: UntypedFormGroup;
    isWarrantyAllowed: boolean;
    isSpareOrderAllowed: boolean;

    get formControls() {
        return this.formGroup.controls;
    }

    lensTypes = LensTypes;
    lensNames: LensTypeProductGroupNames;

    leftQuantityRange: InputNumberRange;
    rightQuantityRange: InputNumberRange;

    countries: ListOption[];
    unitedStatesStates: ListOption[];
    clientsLastOrder: OrderInfo;
    wereLensParamsChangedByOptician: boolean;
    showTips = false;
    showStates = false;

    invalidOrderKey: string;
    invalidOrder = false;

    leftLensParameters: FittedLensParameter[] = [];
    rightLensParameters: FittedLensParameter[] = [];

    isAnnualSupplyDisabled = false;
    hasLeftProductAnnualSupplyEnabled = false;
    leftAnnualSupply: ProposalAnnualSupply;
    hasRightProductAnnualSupplyEnabled = false;
    rightAnnualSupply: ProposalAnnualSupply;

    isAnnualSupplyFullfillmentOrder = false;

    cartItem: CartItem;
    hasFitLensAddToCartButton = false;
    private isFinished: boolean;
    isLeftForcedWarrantyEnabled: boolean;
    isRightForcedWarrantyEnabled: boolean;

    opticianBlockedOrderFeature: FeatureSetting = {
        Feature: Features.BlockOrders,
        Category: FeatureCategories.Optician,
        IsEnabled: true,
    };

    private readonly destroyRef = inject(DestroyRef);

    get directToHomeWarning(): boolean {
        return this.isCartItem || !!this.directToHomeFormGroup.controls.approved.value;
    }

    get directToHomeDisabled(): boolean {
        return this.isCartItem;
    }

    get warranty(): Warranty {
        return this.appState.currentCompany.Warranty;
    }

    get visualAcuityTypeId(): number {
        return this.appState.currentCompany.VisualAcuityTypeId;
    }

    get client(): Client {
        return this.fittingService.state.client;
    }

    get proposal(): Proposal {
        return this.fittingService.state.proposal;
    }

    get fitlensRightEyeSelected(): boolean {
        return this.fittingService.state.isRightEyeSideSelected;
    }

    get fitlensLeftEyeSelected(): boolean {
        return this.fittingService.state.isLeftEyeSideSelected;
    }

    get isSpareOrder(): boolean {
        return !!this.fittingService.state.isSpareOrder;
    }

    get isFeatureOrderingSparesEnabled(): boolean {
        return this.appState.isCompanyFeatureEnabled(Features.OrderingSpares) && !this.proposal.WarrantyExchange;
    }

    constructor(
        private readonly fittingService: FittingService,
        private readonly fittedLensService: FittedLensService,
        private readonly productGroupService: ProductGroupService,
        private readonly countryService: CountryService,
        private readonly distributorService: DistributorService,
        private readonly featureCheckerService: FeatureCheckerService,
        private readonly shopService: ShopService,
        private readonly cartStorageService: CartStorageService,
        public fb: UntypedFormBuilder,
        public router: Router,
        public appState: AppStateService,
        public loaderService: LoaderService,
        public orderService: OrderService,
        public proposalReceiptService: ProposalReceiptService,
        public cartOrderService: CartService,
        public translate: TranslateService,
        private localStorage: LocalStorageService,
        public alertService: AlertService,
        public modalService: BsModalService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.fittingService.setFittingStep(this.router.url);

        if (!this.client || !this.proposal) {
            this.router.navigate(['/']);
        }

        this.leftQuantityRange = new InputNumberRange(1, this.leftMaxQuantity, 1, 1);
        this.rightQuantityRange = new InputNumberRange(1, this.rightMaxQuantity, 1, 1);

        this.loadCountries();
        this.loadUnitedStatesStates();

        this.createForm();
        this.resetAnnualSupplyModels();
        this.createDirectToHomeForm();

        // this component can't really save the continuation state to the server,
        // b/c that would create an order which would be processed by procornea right away.
        // So in this step we'll save to the local storage and hope the optician uses the same browser
        // to continue after saving. Also, it is limited to this login, because MyProcornea will
        // clear localstorage on logout and login. Which is good for privacy reasons.
        /* eslint-disable @typescript-eslint/no-empty-function */
        this.fittingService.configureSaveForContinuation(
            new SaveFittingContinuationConfiguration(
                () => this.isNextEnabled(),
                async () => {}, // save will always be called in ngOnDestroy
            ),
        );

        this.initializeChangedParamsWarning(); // no need to await this one
        this.lensNames = await lastValueFrom(this.productGroupService.getLensTypeProductGroupNames());
        if (this.isCartItem) {
            await this.setCartItem();
        }
        this.isOpticianBlocked();
        this.checkOrderIsValid();
        this.hasFitLensAddToCartButton = this.appState.isCompanyFeatureEnabled(Features.AddFitLensToCart);

        this.leftLensParameters = this.proposal.LeftOpticianFittedLens
            ? this.FilterLensParameters(this.proposal.LeftOpticianFittedLens)
            : [];
        this.rightLensParameters = this.proposal.RightOpticianFittedLens
            ? this.FilterLensParameters(this.proposal.RightOpticianFittedLens)
            : [];

        this.isLeftForcedWarrantyEnabled =
            this.proposal?.LeftOpticianFittedLens?.LensDefinition.Product.WarrantyStatus === WarrantyStatus.Forced;

        this.isRightForcedWarrantyEnabled =
            this.proposal?.RightOpticianFittedLens?.LensDefinition.Product.WarrantyStatus === WarrantyStatus.Forced;

        this.checkWarranty();
        this.checkAnnualSupply();

        this.isSpareOrderAllowed =
            this.checkIsSpareOrderAllowed() && !(this.isLeftForcedWarrantyEnabled || this.isRightForcedWarrantyEnabled);
    }

    ngOnDestroy(): void {
        if (!this.isFinished) {
            this.saveToLocalStorage();
        }
    }

    private async setCartItem(): Promise<void> {
        const cart = await this.cartStorageService.loadFromServer();
        const item = cart.Items.find((c) => c.ProposalId === this.proposal.Id);
        if (item != null) {
            this.formGroup.controls.agreeOrderSpareLenses.setValue(item.AddSpareOrder);
            this.cartItem = item;
        }

        this.directToHomeFormGroup.controls.approved.disable();
    }

    private async initializeChangedParamsWarning(): Promise<void> {
        if (!this.fittingService.isDreamLiteFollowUp) {
            return;
        }

        const lensesFromPrevOrder = await lastValueFrom(
            this.fittedLensService.getFromOrders(this.proposal.RightPreviousOrderId, this.proposal.LeftPreviousOrderId),
        );

        const prevLeftLens = lensesFromPrevOrder.find((x) => x.EyeSideId === EyeSides.Os);
        const prevRightLens = lensesFromPrevOrder.find((x) => x.EyeSideId === EyeSides.Od);

        const leftLens = this.fitlensLeftEyeSelected ? this.proposal.LeftOpticianFittedLens : null;
        const rightLens = this.fitlensRightEyeSelected ? this.proposal.RightOpticianFittedLens : null;

        let wereParamsChanged = false;
        if (leftLens && prevLeftLens) {
            wereParamsChanged = this.detectParamChanges(leftLens, prevLeftLens);
        }
        if (rightLens && prevRightLens) {
            wereParamsChanged = wereParamsChanged || this.detectParamChanges(rightLens, prevRightLens);
        }
        this.wereLensParamsChangedByOptician = wereParamsChanged;
    }

    private detectParamChanges(lens: FittedLens, prev: FittedLens): boolean {
        for (let i = 0; i < lens.FittedLensParameters.length; i++) {
            const param = lens.FittedLensParameters[i];
            const code = param.LensDefinitionParameter.ParameterType.Code;
            const prevParam = prev.FittedLensParameters.find(
                (x) => x.LensDefinitionParameter && x.LensDefinitionParameter.ParameterType.Code === code,
            );

            if (!prevParam || param.GenericValue !== prevParam.GenericValue) {
                return true;
            }
        }

        return false;
    }

    private saveToLocalStorage(): Promise<void> {
        const dataToSave: localStoredLensInfo = {
            leftQuantity: this.getLeftQuantity(),
            rightQuantity: this.getRightQuantity(),
            supportMessage: this.getSupportMessage(),
            perosOrderNumber: this.getPerosOrderNumber(),
        };

        this.localStorage.save(this.getLocalStorageDataKey(), dataToSave);

        return Promise.resolve();
    }

    private getLocalStorageDataKey(): string {
        return `fitting-overview-data-${this.proposal.Id}`;
    }

    private resetAnnualSupplyModels(): void {
        this.isAnnualSupplyFullfillmentOrder = !!this.fittingService.state.isAnnualSupplyFullfillmentOrder;

        this.leftAnnualSupply = {
            IsAnnualSupplyEnabled: this.isAnnualSupplyFullfillmentOrder,
            IsAnnualSupplyFulfilled: this.isAnnualSupplyFullfillmentOrder,
        };
        this.rightAnnualSupply = {
            IsAnnualSupplyEnabled: this.isAnnualSupplyFullfillmentOrder,
            IsAnnualSupplyFulfilled: this.isAnnualSupplyFullfillmentOrder,
        };

        if (this.isAnnualSupplyFullfillmentOrder) {
            this.formGroup.controls.agreeOrderSpareLenses.disable();
            this.formGroup.controls.leftLensWarranty.disable();
            this.formGroup.controls.rightLensWarranty.disable();
        }
    }

    loadCountries(): void {
        this.distributorService.getDistributorCountries(this.appState.currentDistributor.Id).subscribe((countries) => {
            if (countries !== undefined || countries !== null) {
                this.countries = new Array<ListOption>();
                for (const dc of countries) {
                    const listOption = new ListOption(dc.Id, dc.Name, dc.Code);
                    this.countries.push(listOption);
                }
            } else {
                this.countryService.getCountries().subscribe((defaultCountries) => {
                    this.countries = new Array<ListOption>();
                    for (const country of defaultCountries) {
                        const listOption = new ListOption(country.Id, country.Name, country.Code);
                        this.countries.push(listOption);
                    }
                });
            }
        });
    }

    loadUnitedStatesStates(): void {
        this.countryService.getStates().subscribe((unitedStatesStates) => {
            this.unitedStatesStates = new Array<ListOption>();
            for (const state of unitedStatesStates) {
                const listOption = new ListOption(state.Id, state.Name, state.Code);
                this.unitedStatesStates.push(listOption);
            }
        });
    }

    get leftMaxQuantity(): number {
        if (
            this.proposal &&
            this.proposal.LeftOpticianFittedLens &&
            this.proposal.LeftOpticianFittedLens.LensDefinition &&
            this.proposal.LeftOpticianFittedLens.LensDefinition.Product
        ) {
            return this.proposal.LeftOpticianFittedLens.LensDefinition.Product.MaxQuantity;
        }

        return 1;
    }

    checkWarranty(): void {
        const warrantyAllowed =
            this.appState.isCompanyFeatureEnabled(Features.OpticianLensOrderWarranty) &&
            !this.proposal.WarrantyExchange &&
            !this.isSpareOrder &&
            !this.appState.isOpticianFeatureEnabled(Features.IsReceiptsOnlyEnabled);

        this.isWarrantyAllowed =
            warrantyAllowed &&
            ((this.proposal?.LeftOpticianFittedLens?.LensDefinition.Product.AvailableForWarranty &&
                this.proposal?.LeftOpticianFittedLens?.LensDefinition.Product.WarrantyStatus !==
                    WarrantyStatus.Disabled) ??
                warrantyAllowed) &&
            ((this.proposal?.RightOpticianFittedLens?.LensDefinition.Product.AvailableForWarranty &&
                this.proposal?.RightOpticianFittedLens?.LensDefinition.Product.WarrantyStatus !==
                    WarrantyStatus.Disabled) ??
                warrantyAllowed);
    }

    checkAnnualSupply(): void {
        this.hasLeftProductAnnualSupplyEnabled =
            (this.proposal?.LeftOpticianFittedLens?.LensDefinition.Product.AvailableForAnnualSupply &&
                this.proposal?.LeftOpticianFittedLens?.LensDefinition.Product.IsAnnualSupplyEnabled) ??
            false;

        this.hasRightProductAnnualSupplyEnabled =
            (this.proposal?.RightOpticianFittedLens?.LensDefinition.Product.AvailableForAnnualSupply &&
                this.proposal?.RightOpticianFittedLens?.LensDefinition.Product.IsAnnualSupplyEnabled) ??
            false;
    }

    private checkIsSpareOrderAllowed(): boolean {
        const spareAllowed =
            this.isFeatureOrderingSparesEnabled &&
            !this.isSpareOrder &&
            !this.appState.isOpticianFeatureEnabled(Features.IsReceiptsOnlyEnabled);

        // When left/right not exists, take feature and spare order as default value (happens when you only select one side)
        return (
            spareAllowed &&
            ((this.proposal?.LeftOpticianFittedLens?.LensDefinition.Product.AvailableForSpare &&
                this.proposal?.LeftOpticianFittedLens?.LensDefinition.Product.IsSpareEnabled) ??
                spareAllowed) &&
            ((this.proposal?.RightOpticianFittedLens?.LensDefinition.Product.AvailableForSpare &&
                this.proposal?.RightOpticianFittedLens?.LensDefinition.Product.IsSpareEnabled) ??
                spareAllowed)
        );
    }

    get rightMaxQuantity(): number {
        if (
            this.proposal &&
            this.proposal.RightOpticianFittedLens &&
            this.proposal.RightOpticianFittedLens.LensDefinition &&
            this.proposal.RightOpticianFittedLens.LensDefinition.Product
        ) {
            return this.proposal.RightOpticianFittedLens.LensDefinition.Product.MaxQuantity;
        }

        return 1;
    }

    get perosBranchNumber(): string {
        return this.appState.getPerosBranchNumber;
    }

    createForm(): void {
        const leftValidators = [];
        if (this.fitlensLeftEyeSelected) {
            leftValidators.push(Validators.required);
        }

        const rightValidators = [];
        if (this.fitlensRightEyeSelected) {
            rightValidators.push(Validators.required);
        }

        let perosOrderNumberValidators = [];
        if (this.perosBranchNumber) {
            perosOrderNumberValidators = [
                Validators.required,
                Validators.maxLength(6),
                Validators.pattern('^(?=.*\\d)[\\d]{1,}$'),
            ];
        }
        const continuationData = this.localStorage.getAsObject<localStoredLensInfo>(this.getLocalStorageDataKey());

        this.formGroup = this.fb.group({
            rightQuantity: [this.proposal.RightProposalOptions.Quantity, rightValidators],
            rightLensWarranty: [this.proposal.RightLensWarranty],
            leftQuantity: [this.proposal.LeftProposalOptions.Quantity, leftValidators],
            leftLensWarranty: [this.proposal.LeftLensWarranty],
            supportmessage: [continuationData ? continuationData.supportMessage : ''],
            perosOrderNumber: [continuationData ? continuationData.perosOrderNumber : '', perosOrderNumberValidators],
            agreeOrderSpareLenses: [],
        });
    }

    onWarrantyValueChange() {
        if (this.formGroup.controls.leftLensWarranty.value || this.formGroup.controls.rightLensWarranty.value) {
            this.toggleSpareValue(false);
        } else {
            this.toggleSpareValue();
        }
    }

    onSpareValueChange() {
        if (this.formGroup.controls.agreeOrderSpareLenses.value) {
            this.toggleWarranty(false);
        } else {
            this.toggleWarranty();
        }
    }

    toggleSpareValue(enable = true) {
        if (enable) {
            this.formGroup.controls.agreeOrderSpareLenses.enable();
        } else {
            this.formGroup.controls.agreeOrderSpareLenses.setValue(false);
            this.formGroup.controls.agreeOrderSpareLenses.disable();
        }

        this.formGroup.controls.agreeOrderSpareLenses.updateValueAndValidity();
    }

    toggleWarranty(enable = true) {
        if (enable) {
            this.formGroup.controls.leftLensWarranty.enable();
            this.formGroup.controls.rightLensWarranty.enable();
        } else {
            this.formGroup.controls.leftLensWarranty.setValue(false);
            this.formGroup.controls.rightLensWarranty.setValue(false);
            this.formGroup.controls.leftLensWarranty.disable();
            this.formGroup.controls.rightLensWarranty.disable();
        }

        this.formGroup.controls.leftLensWarranty.updateValueAndValidity();
        this.formGroup.controls.rightLensWarranty.updateValueAndValidity();
    }

    createDirectToHomeForm() {
        this.directToHomeFormGroup = this.fb.group({
            approved: [],
            name: { value: '', disabled: true },
            street: { value: '', disabled: true },
            houseNumber: { value: '', disabled: true },
            houseNumberSuffix: { value: '', disabled: true },
            postalCode: { value: '', disabled: true },
            city: { value: '', disabled: true },
            country: { value: '', disabled: true },
            homeState: { value: '', disabled: true },
        });

        this.directToHomeFormGroup.controls.approved.valueChanges.subscribe((result: boolean) => {
            result = result && !this.directToHomeDisabled;
            if (result) {
                this.directToHomeFormGroup.controls.name.enable();
                this.directToHomeFormGroup.controls.street.enable();
                this.directToHomeFormGroup.controls.houseNumber.enable();
                this.directToHomeFormGroup.controls.houseNumberSuffix.enable();
                this.directToHomeFormGroup.controls.postalCode.enable();
                this.directToHomeFormGroup.controls.city.enable();
                this.directToHomeFormGroup.controls.country.enable();
                this.directToHomeFormGroup.controls.homeState.enable();

                this.directToHomeFormGroup.controls.name.setValidators([Validators.required, this.trimWhitespace]);
                this.directToHomeFormGroup.controls.street.setValidators([Validators.required, this.trimWhitespace]);
                this.directToHomeFormGroup.controls.houseNumber.setValidators([
                    Validators.required,
                    this.trimWhitespace,
                    Validators.pattern(/^[0-9]{1,4}$/),
                ]);
                this.directToHomeFormGroup.controls.houseNumberSuffix.setValidators([this.trimWhitespace]);
                this.directToHomeFormGroup.controls.postalCode.setValidators([
                    Validators.required,
                    this.trimWhitespace,
                    () => new PostalCodeValidator(this.countries).bind(this.directToHomeFormGroup),
                ]);
                this.directToHomeFormGroup.controls.city.setValidators([Validators.required, this.trimWhitespace]);
                this.directToHomeFormGroup.controls.country.setValidators([Validators.required]);
                this.setDefaultCountry();
                this.prefillDirectToHome().then();
                this.setStateControlVisibility();
            } else {
                this.directToHomeFormGroup.controls.name.disable();
                this.directToHomeFormGroup.controls.street.disable();
                this.directToHomeFormGroup.controls.houseNumber.disable();
                this.directToHomeFormGroup.controls.houseNumberSuffix.disable();
                this.directToHomeFormGroup.controls.postalCode.disable();
                this.directToHomeFormGroup.controls.city.disable();
                this.directToHomeFormGroup.controls.country.disable();
                this.directToHomeFormGroup.controls.homeState.disable();

                this.directToHomeFormGroup.controls.name.clearValidators();
                this.directToHomeFormGroup.controls.street.clearValidators();
                this.directToHomeFormGroup.controls.houseNumber.clearValidators();
                this.directToHomeFormGroup.controls.houseNumberSuffix.clearValidators();
                this.directToHomeFormGroup.controls.postalCode.clearValidators();
                this.directToHomeFormGroup.controls.city.clearValidators();
                this.directToHomeFormGroup.controls.country.clearValidators();
                this.directToHomeFormGroup.controls.homeState.clearValidators();
            }

            this.directToHomeFormGroup.controls.name.updateValueAndValidity();
            this.directToHomeFormGroup.controls.street.updateValueAndValidity();
            this.directToHomeFormGroup.controls.houseNumber.updateValueAndValidity();
            this.directToHomeFormGroup.controls.houseNumberSuffix.updateValueAndValidity();
            this.directToHomeFormGroup.controls.postalCode.updateValueAndValidity();
            this.directToHomeFormGroup.controls.city.updateValueAndValidity();
            this.directToHomeFormGroup.controls.country.updateValueAndValidity();
            this.directToHomeFormGroup.controls.homeState.updateValueAndValidity();
        });
    }

    trimWhitespace: ValidatorFn = (control: UntypedFormControl) => {
        if (control.value.startsWith(' ')) {
            control.setValue(control.value.trim());
            control.updateValueAndValidity();
        }
        return null;
    };

    get lensTypeImage(): string {
        switch (this.proposal.LensTypeId) {
            case LensTypes.Crt:
            case LensTypes.DreamLite:
                return 'lens-dreamlite.svg';
            case LensTypes.Med:
                return 'lens-med.svg';
            case LensTypes.MedPlus:
                return 'lens-med-plus.svg';
            case LensTypes.Rgp:
                return 'lens-rgp.svg';
            case LensTypes.Soft:
                return 'lens-soft.svg';
            default:
                return '';
        }
    }

    get lensTypeTitle(): string {
        switch (this.proposal.LensTypeId) {
            case LensTypes.Crt:
            case LensTypes.DreamLite:
                return 'lenstype.ortho-k';
            case LensTypes.Med:
                return 'lenstype.med';
            case LensTypes.MedPlus:
                return 'lenstype.med-plus';
            case LensTypes.Rgp:
                return 'lenstype.rgp';
            case LensTypes.Soft:
                return 'lenstype.soft';
            default:
                return '';
        }
    }

    getFormatObject(param: FittedLensParameter): object {
        return {
            parameterType: param.LensDefinitionParameter.ParameterType.Code,
        };
    }

    previous($event: MouseEvent): void {
        $event.preventDefault();
        this.fittingService.gotoPreviousStep();
    }

    get lensTypeMessageClass(): string {
        if (this.appState.theme === 'light') {
            return 'bg-light text-dark';
        }
        return 'bg-dark text-light';
    }

    get leftMaterialColorName(): string {
        if (
            this.proposal &&
            this.proposal.LeftOpticianFittedLens &&
            this.proposal.LeftOpticianFittedLens.MaterialColor
        ) {
            return (
                this.proposal.LeftOpticianFittedLens.MaterialColor.Material.Name +
                ' ' +
                this.proposal.LeftOpticianFittedLens.MaterialColor.Color.Name
            );
        }
        return '';
    }

    get isCartItem(): boolean {
        return this.fittingService.isCartItem();
    }

    get rightMaterialColorName(): string {
        if (
            this.proposal &&
            this.proposal.RightOpticianFittedLens &&
            this.proposal.RightOpticianFittedLens.MaterialColor
        ) {
            return (
                this.proposal.RightOpticianFittedLens.MaterialColor.Material.Name +
                ' ' +
                this.proposal.RightOpticianFittedLens.MaterialColor.Color.Name
            );
        }
        return '';
    }

    isNextEnabled(): boolean {
        // Extra check for direct to home form
        if (this.directToHomeFormGroup.controls.approved.value) {
            if (!this.directToHomeFormGroup.valid) {
                return false;
            }
        }
        return this.formGroup.valid && !this.invalidOrder;
    }

    isAddToCartEnabled(): boolean {
        if (this.directToHomeFormGroup.controls.approved.value) {
            return false;
        }

        if (
            !this.proposal.LeftOpticianFittedLens?.LensDefinition?.Product?.AvailableForCart &&
            !this.proposal.RightOpticianFittedLens?.LensDefinition?.Product?.AvailableForCart
        ) {
            return false;
        }

        return true;
    }

    private getLeftQuantity(): number {
        if (this.proposal.LeftOpticianFittedLens) {
            return Number(this.formControls['leftQuantity'].value);
        }
        return 0;
    }

    private getRightQuantity(): number {
        if (this.proposal.RightOpticianFittedLens) {
            return Number(this.formControls['rightQuantity'].value);
        }
        return 0;
    }

    private getSupportMessage(): string {
        return this.formGroup.controls['supportmessage'].value;
    }

    private getPerosOrderNumber(): string {
        return (
            ('00000' + this.perosBranchNumber).slice(-5) +
            ('000000' + this.formGroup.controls['perosOrderNumber'].value).slice(-6)
        );
    }

    async addToCart(): Promise<void> {
        this.loaderService.show();

        const cartOrder = new CartAddItem();

        if (this.shopService.client) {
            cartOrder.ClientId = this.shopService.client.Id;
        }

        cartOrder.Proposal = structuredClone(this.proposal);
        cartOrder.Proposal.LeftOpticianFittedLens = this.fitlensLeftEyeSelected
            ? cartOrder.Proposal.LeftOpticianFittedLens
            : null;
        cartOrder.Proposal.RightOpticianFittedLens = this.fitlensRightEyeSelected
            ? cartOrder.Proposal.RightOpticianFittedLens
            : null;

        if (this.isLeftForcedWarrantyEnabled) {
            cartOrder.Proposal.LeftLensWarranty = true;
        } else {
            cartOrder.Proposal.LeftLensWarranty = this.formGroup.controls['leftLensWarranty'].value ?? false;
        }

        if (this.isRightForcedWarrantyEnabled) {
            cartOrder.Proposal.RightLensWarranty = true;
        } else {
            cartOrder.Proposal.RightLensWarranty = this.formGroup.controls['rightLensWarranty'].value ?? false;
        }

        if (cartOrder.Proposal.LeftOpticianFittedLens) {
            cartOrder.Proposal.LeftProposalOptions = new ProposalOptions();
            cartOrder.Proposal.LeftProposalOptions.Quantity = this.getLeftQuantity();
        }
        if (cartOrder.Proposal.RightOpticianFittedLens) {
            cartOrder.Proposal.RightProposalOptions = new ProposalOptions();
            cartOrder.Proposal.RightProposalOptions.Quantity = this.getRightQuantity();
        }

        if (this.isFeatureOrderingSparesEnabled) {
            cartOrder.IsSpareOrder = this.isSpareOrder;
            cartOrder.AddSpareOrder = this.formGroup.controls['agreeOrderSpareLenses'].value ?? false;
        }

        try {
            const cart = await lastValueFrom(this.cartOrderService.addItemToCart(cartOrder));
            this.cartStorageService.saveShoppingCart(cart);
            this.fittingService.clear();
            this.router.navigate(['/cart']);
        } catch (error) {
            let message = error.message;
            if (!!error.error?.ModelState?.['Items'] && error.error?.ModelState['Items']?.$values?.length) {
                message = this.translate.instant(error.error.ModelState['Items'].$values[0]);
            }

            this.alertService.error(message);
        } finally {
            this.loaderService.hide();
        }
    }

    async createReceipt(): Promise<void> {
        this.proposalReceiptService.createProposalReceipt(this.proposal.Id).subscribe(
            (receipt) => {
                this.fittingService.storeReceiptIdAndClearSaveConfiguration(receipt.Id);
                this.loaderService.hide();
                this.fittingService.gotoNextStep();
                this.fittingService.clear();
                this.isFinished = true;
                this.localStorage.remove(this.getLocalStorageDataKey());
            },
            (error) => {
                this.handleOrderCreationError(error);
            },
        );
    }

    async next(): Promise<void> {
        if (
            this.appState.isDistributorFeatureEnabled(Features.IsTrainingToOrderRequired) &&
            !this.appState.authenticatedUser.TrainingCompleteApprovedOn
        ) {
            return this.showTrainingCompletedRequest(async () => await this.next());
        }

        this.loaderService.show();

        const createOrder = new CreateOrder();
        createOrder.Proposal = structuredClone(this.proposal);

        createOrder.Proposal.LeftOpticianFittedLens = this.fitlensLeftEyeSelected
            ? createOrder.Proposal.LeftOpticianFittedLens
            : null;
        createOrder.Proposal.RightOpticianFittedLens = this.fitlensRightEyeSelected
            ? createOrder.Proposal.RightOpticianFittedLens
            : null;

        createOrder.SkipOrderEvaluation = !createOrder.Proposal.OrderWithSupport;

        if (createOrder.Proposal.OrderWithSupport) {
            createOrder.SupportMessage = this.getSupportMessage();
        }

        if (createOrder.Proposal.LeftOpticianFittedLens) {
            createOrder.Proposal.LeftProposalOptions = new ProposalOptions();
            createOrder.Proposal.LeftProposalOptions.Quantity = this.getLeftQuantity();
        }
        if (createOrder.Proposal.RightOpticianFittedLens) {
            createOrder.Proposal.RightProposalOptions = new ProposalOptions();
            createOrder.Proposal.RightProposalOptions.Quantity = this.getRightQuantity();
        }

        if (this.perosBranchNumber) {
            createOrder.PerosOrderNumber = this.getPerosOrderNumber();
        }

        if (this.isAnnualSupplyFullfillmentOrder) {
            createOrder.IsAnnualSupplyFullfillmentOrder = this.isAnnualSupplyFullfillmentOrder;
        }

        if (this.isFeatureOrderingSparesEnabled) {
            createOrder.IsSpareOrder = this.isSpareOrder;
            createOrder.AddSpareOrder = this.formGroup.controls['agreeOrderSpareLenses'].value ?? false;
        }

        if (this.directToHomeFormGroup.controls.approved.value) {
            createOrder.DirectToHome = {
                name: this.directToHomeFormGroup.controls.name.value.trim(),
                street: this.directToHomeFormGroup.controls.street.value.trim(),
                houseNumber: this.directToHomeFormGroup.controls.houseNumber.value.trim(),
                houseNumberSuffix: this.directToHomeFormGroup.controls.houseNumberSuffix.value.trim(),
                postCode: this.directToHomeFormGroup.controls.postalCode.value.trim(),
                city: this.directToHomeFormGroup.controls.city.value.trim(),
                country: this.getCountryCodeById(Number(this.directToHomeFormGroup.controls.country.value)),
                homeState: this.getUnitedStatesStateCodeById(
                    Number(this.directToHomeFormGroup.controls.homeState.value),
                ),
            };
        }

        if (this.isLeftForcedWarrantyEnabled) {
            createOrder.Proposal.LeftLensWarranty = true;
        } else {
            createOrder.Proposal.LeftLensWarranty = this.formGroup.controls['leftLensWarranty'].value ?? false;
        }

        if (this.isRightForcedWarrantyEnabled) {
            createOrder.Proposal.RightLensWarranty = true;
        } else {
            createOrder.Proposal.RightLensWarranty = this.formGroup.controls['rightLensWarranty'].value ?? false;
        }

        createOrder.Proposal.RightAnnualSupply = this.rightAnnualSupply;
        createOrder.Proposal.LeftAnnualSupply = this.leftAnnualSupply;

        if (this.fileUploadComponent && this.fileUploadComponent.files) {
            createOrder.Attachments = this.fileUploadComponent.files.map(
                (file) =>
                    <FileMetadata>{
                        fileName: file.name,
                        size: file.size,
                        contentType: file.type,
                    },
            );
        }

        this.orderService
            .createOrder(createOrder)
            .pipe(switchMap((order) => this.uploadFiles(order.Id).pipe(map(() => order))))
            .subscribe(
                (order) => {
                    this.fittingService.state.orderId = order.Id;
                    this.fittingService.clearSaveForContinationConfiguration();

                    this.loaderService.hide();
                    this.fittingService.gotoNextStep();
                    this.fittingService.clear();

                    this.isFinished = true;
                    this.localStorage.remove(this.getLocalStorageDataKey());
                },
                (error) => {
                    this.handleOrderCreationError(error);
                },
            );
    }

    handleOrderCreationError(error) {
        const errorText = 'orderoverview.ordererror';

        if (error.error && error.error.errors) {
            const errors = error.error.errors;

            Object.entries(errors)
                .flatMap(([, values]) => <string[]>values)
                .map((x) => this.replaceWithTranslationCode(x))
                .forEach((errorString) => {
                    this.alertService.error(this.translate.instant(errorString));
                });
        } else {
            this.alertService.error(this.translate.instant(errorText));
        }

        this.loaderService.hide();
    }

    replaceWithTranslationCode(text: string): string {
        if (text === 'ErrorMessages.PEROS_ORDERNUMBER_EXISTS') {
            return 'orderoverview.perosordernumberexists';
        }

        return text;
    }

    hasAddToShoppingCartButton(): boolean {
        if (
            !this.proposal.LeftOpticianFittedLens?.LensDefinition?.Product?.AvailableForCart &&
            !this.proposal.RightOpticianFittedLens?.LensDefinition?.Product?.AvailableForCart
        ) {
            return false;
        }

        return (
            !this.IsSupportOrder &&
            this.hasFitLensAddToCartButton &&
            !this.isCartItem &&
            !this.appState.isOpticianFeatureEnabled(Features.IsReceiptsOnlyEnabled)
        );
    }

    hasSendButton(): boolean {
        return !this.isCartItem && !this.appState.isOpticianFeatureEnabled(Features.IsReceiptsOnlyEnabled);
    }

    async saveAndReturnToCart(): Promise<void> {
        const cartOrder = new UpdateCartItemProposal();
        cartOrder.Id = this.cartItem.Id;
        cartOrder.AddSpareOrder = this.formGroup.controls['agreeOrderSpareLenses'].value ?? false;

        if (this.isLeftForcedWarrantyEnabled) {
            cartOrder.LeftLensWarranty = true;
        } else {
            cartOrder.LeftLensWarranty = this.formGroup.controls['leftLensWarranty'].value ?? false;
        }

        if (this.isRightForcedWarrantyEnabled) {
            cartOrder.RightLensWarranty = true;
        } else {
            cartOrder.RightLensWarranty = this.formGroup.controls['rightLensWarranty'].value ?? false;
        }

        cartOrder.LeftQuantity = this.getLeftQuantity();
        cartOrder.RightQuantity = this.getRightQuantity();
        const cart = await lastValueFrom(this.cartOrderService.updateCartItemProposal(cartOrder));
        this.cartStorageService.saveShoppingCart(cart);
        await this.fittingService.saveAndReset();
    }

    get IsSupportOrder(): boolean {
        return this.proposal.OrderWithSupport;
    }

    private uploadFiles(orderId: number): Observable<FileUploadStatus> {
        if (
            !this.fileUploadComponent ||
            !this.fileUploadComponent.files ||
            this.fileUploadComponent.files.length === 0
        ) {
            return of(FileUploadStatus.Completed);
        }

        this.loaderService.showMessage(this.translate.instant('orderoverview.uploadingfiles'));

        const uploadFormData = {
            SupportOrderId: orderId,
            IsInternal: false,
        };

        this.fileUploadComponent.startUpload(uploadFormData, 'api/attachments');
        return this.fileUploadComponent.uploadStatus.pipe(takeWhile((u) => u === FileUploadStatus.Completed));
    }

    public onAttachmentsUploaded(event: UploadOutput): void {
        const responseStatus = event.file.responseStatus;

        if (responseStatus == 400 || responseStatus == 500) {
            this.alertService.warning(
                this.translate.instant('orderoverview.support.attachments.upload-failed-with-order-placed'),
                true,
            );
        }
    }

    get tipsKey(): string {
        if (this.fittingService.isDreamLiteFollowUp) {
            return 'orderoverview.tips-dreamlitefollowup';
        }

        if (this.fittingService.isFollowUp) {
            if (this.proposal.LensType.Id === LensTypes.Rgp) {
                return 'orderoverview.tips-rgpfollowup';
            }
            if (this.proposal.LensType.Id === LensTypes.Soft) {
                return 'orderoverview.tips-softfollowup';
            }
        }

        return 'orderoverview.tips-fitting';
    }

    getCountryCodeById(countryId: number): string {
        const country = this.countries.find((c) => c.Id === countryId);

        if (country) {
            return country.Code;
        }
        return '';
    }

    getUnitedStatesStateCodeById(stateId: number): string {
        const unitedStatesState = this.unitedStatesStates.find((c) => c.Id === stateId);

        if (unitedStatesState) {
            return unitedStatesState.Code;
        }
        return '';
    }

    getCountryIdByCode(countryCode: string): number {
        const country = this.countries.find((c) => c.Code === countryCode);

        if (country) {
            return country.Id;
        }
        return 0;
    }

    getUnitedStatesStateIdByCode(stateCode: string): number {
        const unitedStatesState = this.unitedStatesStates.find((c) => c.Code === stateCode);

        if (unitedStatesState) {
            return unitedStatesState.Id;
        }
        return 0;
    }

    openTips() {
        const key = this.tipsKey;
        const options: unknown = {
            initialState: {
                title: this.translate.instant(key + '-title'),
                content: this.translate.instant(key),
            },
            class: 'modal-half',
        };
        this.modalService.show(TipsDialogComponent, options);
    }

    checkOrderIsValid(): void {
        if (this.proposal.LensTypeId === LensTypes.Soft) {
            if (
                this.proposal.LeftOpticianFittedLens &&
                ByopremiumRadDiamValidator.isArtNrInProductList(
                    +this.proposal.LeftOpticianFittedLens.LensDefinition.Product.ArtNr,
                )
            ) {
                const rad = GetValueOfLensParameter.Execute(this.proposal.LeftOpticianFittedLens, 'RAD').Value;
                const diam = GetValueOfLensParameter.Execute(this.proposal.LeftOpticianFittedLens, 'DIAM').Value;

                if (ByopremiumRadDiamValidator.bindRadAndDiam(rad, diam)) {
                    this.invalidOrderKey = 'order.byopremiumraddiamvalidator';
                    this.invalidOrder = true;
                    return;
                }
            }

            if (
                this.proposal.RightOpticianFittedLens &&
                ByopremiumRadDiamValidator.isArtNrInProductList(
                    +this.proposal.RightOpticianFittedLens.LensDefinition.Product.ArtNr,
                )
            ) {
                const rad = GetValueOfLensParameter.Execute(this.proposal.RightOpticianFittedLens, 'RAD').Value;
                const diam = GetValueOfLensParameter.Execute(this.proposal.RightOpticianFittedLens, 'DIAM').Value;

                if (ByopremiumRadDiamValidator.bindRadAndDiam(rad, diam)) {
                    this.invalidOrderKey = 'order.byopremiumraddiamvalidator';
                    this.invalidOrder = true;
                    return;
                }
            }
        } else {
            this.invalidOrderKey = '';
            this.invalidOrder = false;
        }
    }

    isOpticianBlocked(): void {
        if (this.featureCheckerService.check([this.opticianBlockedOrderFeature])) {
            const message = this.translate.instant('order.opticianBlockedAlert');
            this.alertService.warning(message, true);
        }
    }

    setStateControlVisibility(): void {
        if (this.getCountryCodeById(Number(this.directToHomeFormGroup.controls.country.value)) === 'USA') {
            this.showStates = true;
            this.directToHomeFormGroup.controls.homeState.setValidators([Validators.required]);
            this.directToHomeFormGroup.controls.houseNumber.clearValidators();
            this.directToHomeFormGroup.controls.houseNumberSuffix.clearValidators();
            this.directToHomeFormGroup.controls.houseNumber.updateValueAndValidity();
            this.directToHomeFormGroup.controls.houseNumberSuffix.updateValueAndValidity();
        } else {
            this.showStates = false;
            this.directToHomeFormGroup.controls.homeState.reset();
            this.directToHomeFormGroup.controls.homeState.clearValidators();
        }
        this.directToHomeFormGroup.controls.homeState.updateValueAndValidity();
    }

    async prefillDirectToHome(): Promise<void> {
        this.loaderService.show();
        const orderId = await lastValueFrom(this.orderService.getLastOrderIdForClient(this.client.Id));
        if (orderId) {
            this.clientsLastOrder = await lastValueFrom(this.orderService.getOrderInfoById(orderId));
            if (this.clientsLastOrder.IsDirectToHome) {
                this.directToHomeFormGroup.controls.name.setValue(this.clientsLastOrder.Name);
                this.directToHomeFormGroup.controls.street.setValue(this.clientsLastOrder.Street);
                this.directToHomeFormGroup.controls.postalCode.setValue(this.clientsLastOrder.PostCode);
                this.directToHomeFormGroup.controls.city.setValue(this.clientsLastOrder.City);
                if (this.countries.find((c) => c.Code === this.clientsLastOrder.Country)) {
                    this.directToHomeFormGroup.controls.country.setValue(
                        this.getCountryIdByCode(this.clientsLastOrder.Country),
                    );
                } else {
                    this.directToHomeFormGroup.controls.country.setValue('');
                }
                if (this.showStates) {
                    this.directToHomeFormGroup.controls.homeState.setValue(
                        this.getUnitedStatesStateIdByCode(this.clientsLastOrder.HomeState),
                    );
                }
                if (this.showHouseNumber) {
                    this.directToHomeFormGroup.controls.houseNumber.setValue(this.clientsLastOrder.HouseNumber);
                    this.directToHomeFormGroup.controls.houseNumberSuffix.setValue(
                        this.clientsLastOrder.HouseNumberSuffix,
                    );
                }
            }
        }
        this.loaderService.hide();
    }

    setDefaultCountry(): void {
        this.directToHomeFormGroup.controls.country.setValue(this.appState.currentDistributor.CountryId);
    }

    get showHouseNumber(): boolean {
        if (this.countries) {
            return this.getCountryCodeById(Number(this.directToHomeFormGroup.controls.country.value)) !== 'USA';
        }

        return true;
    }

    private FilterLensParameters(fittedLens: FittedLens): FittedLensParameter[] {
        const lensParameters = fittedLens.FittedLensParameters.filter((flp) => !flp.LensDefinitionParameter.IsHidden);

        if (this.HasNotchParameters(fittedLens)) {
            const notchParameters = ['NOTCHDEPTH', 'NOTCHCHORD', 'NOTCHPOSITION'];

            for (const parameter in notchParameters) {
                const flp = fittedLens.FittedLensParameters.find(
                    (flp) => flp.LensDefinitionParameter.ParameterType.Code === notchParameters[parameter],
                );

                if (flp) {
                    lensParameters.push(flp);
                }
            }
        }

        return lensParameters;
    }

    private HasNotchParameters(fittedLens: FittedLens): boolean {
        const requiredNotchParameters = ['NOTCHDEPTH', 'NOTCHCHORD'];

        for (const parameter in requiredNotchParameters) {
            const flp = fittedLens.FittedLensParameters.find(
                (flp) => flp.LensDefinitionParameter.ParameterType.Code === requiredNotchParameters[parameter],
            );

            if (flp && !flp.Value) {
                return false;
            }
        }

        return true;
    }

    showTrainingCompletedRequest(onConfirmed: () => void): void {
        const trainingDialog = this.modalService.show(OrderTrainingDialogComponent, {
            class: 'modal-xl',
        });

        trainingDialog.content.confirm.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            onConfirmed();
        });
    }
}
