import { Product } from './product.model';
import { DataSheetTranslation } from './dataSheetTranslation.model';

export class DataSheet {
    public Id: number;
    public Keywords: string;
    public Myopie: boolean;
    // readonly
    public Name: string;
    public FilePath: string;
    public Code: string;

    // navigation
    public DataSheetTranslations: DataSheetTranslation[];
    public Products: Product[];
}
