import { NumberFormatOptions } from '@app/shared/models/numberFormatOptions.model';

export class NumberFormatLogic {
    private decimalSeparators: string[] = ['.', ','];
    private decimalsMultiplier = 1;
    private valid = true;
    private numberFormatOptions: NumberFormatOptions;

    public isValid(): boolean {
        return this.valid;
    }

    constructor(numberFormatOptions: NumberFormatOptions) {
        this.config(numberFormatOptions);
    }

    public execute(data: string): string {
        this.valid = true;

        data = this.decimalSeparatorFunction(data);
        data = this.decimalsRoundFunction(data);

        if (this.numberFormatOptions.signedNumber) {
            data = this.signedNumberFunction(data);
        }

        if (data && this.numberFormatOptions.onlyPositiveNumbers) {
            this.valid = parseFloat(data) >= 0;
        }

        return data;
    }

    private config = (newNumberFormatOptions?: NumberFormatOptions) => {
        this.numberFormatOptions = new NumberFormatOptions(newNumberFormatOptions);

        if (this.numberFormatOptions.parameterType != null) {
            switch (this.numberFormatOptions.parameterType) {
                case 'AX':
                    this.numberFormatOptions.signedNumber = false;
                    this.numberFormatOptions.decimalCount = 0;
                    this.numberFormatOptions.decimalSeparator = '.';
                    break;

                case 'ADD':
                case 'VERTD':
                case 'POW':
                    this.numberFormatOptions.signedNumber = true;
                    this.numberFormatOptions.decimalCount = 2;
                    this.numberFormatOptions.decimalSeparator = '.';
                    break;

                case 'CYL':
                default:
                    this.numberFormatOptions.signedNumber = false;
                    this.numberFormatOptions.decimalCount = 2;
                    this.numberFormatOptions.decimalSeparator = '.';
                    break;
            }
        }

        if (this.numberFormatOptions.signedNumber == null) {
            this.numberFormatOptions.signedNumber = false;
        }

        if (this.numberFormatOptions.decimalCount == null) {
            this.numberFormatOptions.decimalCount = 0;
        }

        if (this.numberFormatOptions.decimalSeparator == null) {
            this.numberFormatOptions.decimalSeparator = '.';
        }

        this.decimalsMultiplier = Math.pow(10, this.numberFormatOptions.decimalCount);
        if (this.decimalsMultiplier === 0) {
            this.decimalsMultiplier = 1;
        }
    };

    private decimalsRoundFunction = (data: string) => {
        const floatRegexp = new RegExp(
            '^([\\+\\-]?\\d+(\\' + this.numberFormatOptions.decimalSeparator + '\\d+)?(e\\-\\d*)?)$',
        );

        if (data === null || data === undefined || data === '') {
            return data;
        }
        if (floatRegexp.test(data)) {
            return parseFloat(
                (Math.round(parseFloat(data) * this.decimalsMultiplier) / this.decimalsMultiplier).toString(),
            ).toFixed(this.numberFormatOptions.decimalCount);
        } else {
            this.valid = false;
            return data;
        }
    };

    private decimalSeparatorFunction = (data: string) => {
        if (data === null || data === undefined || data === '') {
            return data;
        }
        data = data.toString();
        for (let i = 0; i < this.decimalSeparators.length; i++) {
            if (this.decimalSeparators[i] !== this.numberFormatOptions.decimalSeparator) {
                data = data.replace(this.decimalSeparators[i], this.numberFormatOptions.decimalSeparator);
            }
        }
        return data;
    };

    private signedNumberFunction = (data: string) => {
        if (data === null || data === undefined || data === '') {
            return data;
        }

        if (data.length > 0 && data[0] !== '+' && data[0] !== '-') {
            data = '+' + data;
        }
        return data;
    };
}
