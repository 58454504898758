export class NumberFormatOptions {
    public signedNumber: boolean;
    public decimalCount: number;
    public decimalSeparator: string;
    public onlyPositiveNumbers: boolean;
    // this is only for fittedlensparameters or lensdefinitionparameters
    public parameterType: string;

    constructor(numberFormatOptions?: NumberFormatOptions) {
        if (numberFormatOptions != null) {
            this.signedNumber = numberFormatOptions.signedNumber;
            this.decimalCount = numberFormatOptions.decimalCount;
            this.decimalSeparator = numberFormatOptions.decimalSeparator;
            this.parameterType = numberFormatOptions.parameterType;
            this.onlyPositiveNumbers = numberFormatOptions.onlyPositiveNumbers;
        }
    }
}
