<div [formGroup]="formGroup" class="container-fluid pt-4 px-lg-4">
    <div *hasFeatureSetting="[opticianBlockedOrderFeature]">
        <div class="alert alert-warning alert-dismissible fade show" role="alert">
            {{ 'order.opticianBlockedAlert' | translate }}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="mb-4 title-border-bottom">
        <h3 id="orderOverviewHeader" class="mt-auto">
            {{ 'general.orderoverview' | translate }}
        </h3>
    </div>
    <div class="row" *ngIf="proposal">
        <div class="col-md-2 lenstype-wrapper d-flex justify-content-center justify-content-md-end">
            <div class="flex-column me-3">
                <div class="d-flex justify-content-center" *ngIf="lensTypeImage">
                    <div class="circle">
                        <img alt="lens type" class="lenstypeIcon" src="assets/images/{{ lensTypeImage }}" />
                    </div>
                </div>
                <div *ngIf="lensTypeTitle" class="d-flex justify-content-center mt-3">
                    <h4>{{ lensTypeTitle | translate }}</h4>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="row">
                <div class="col-12 col-xl-6">
                    <div *ngIf="proposal.RightOpticianFittedLens && fitlensRightEyeSelected" class="row">
                        <ng-template
                            *ngTemplateOutlet="
                                tplLens;
                                context: {
                                    side: 'right',
                                    sideType: 'od',
                                    opticianFittedLens: proposal.RightOpticianFittedLens,
                                    lensParameters: rightLensParameters,
                                    materialColorName: rightMaterialColorName,
                                    maxQuantity: rightMaxQuantity,
                                    quantityRange: rightQuantityRange,
                                    isForcedWarrantyEnabled: isRightForcedWarrantyEnabled,
                                    hasProductAnnualSupplyEnabled: hasRightProductAnnualSupplyEnabled,
                                    annualSupply: rightAnnualSupply
                                }
                            "></ng-template>
                    </div>
                </div>

                <div class="col-12 col-xl-6">
                    <div *ngIf="proposal.LeftOpticianFittedLens && fitlensLeftEyeSelected" class="row h-100">
                        <ng-template
                            *ngTemplateOutlet="
                                tplLens;
                                context: {
                                    side: 'left',
                                    sideType: 'os',
                                    opticianFittedLens: proposal.LeftOpticianFittedLens,
                                    lensParameters: leftLensParameters,
                                    materialColorName: leftMaterialColorName,
                                    maxQuantity: leftMaxQuantity,
                                    quantityRange: leftQuantityRange,
                                    isForcedWarrantyEnabled: isLeftForcedWarrantyEnabled,
                                    hasProductAnnualSupplyEnabled: hasLeftProductAnnualSupplyEnabled,
                                    annualSupply: leftAnnualSupply
                                }
                            "></ng-template>
                    </div>
                </div>
            </div>

            <ng-template
                #tplLens
                let-side="side"
                let-sideType="sideType"
                let-opticianFittedLens="opticianFittedLens"
                let-lensParameters="lensParameters"
                let-materialColorName="materialColorName"
                let-maxQuantity="maxQuantity"
                let-quantityRange="quantityRange"
                let-isForcedWarrantyEnabled="isForcedWarrantyEnabled"
                let-hasProductAnnualSupplyEnabled="hasProductAnnualSupplyEnabled"
                let-annualSupply="annualSupply">
                <div class="col-12 ps-xl-2 mb-5">
                    <div class="row">
                        <div class="col-12 pt-3">
                            <h1>{{ 'general.' + sideType | translate }}</h1>
                        </div>
                    </div>
                    <div class="row lens-information accented-background rounded mx-0 pb-0 pt-3 mb-3">
                        <div class="col-md-6">
                            <mpc-model-label
                                id="{{ side }}FitDate"
                                label="{{ 'general.date' | translate }}"
                                value="{{ proposal.ProposalDate | mpcdate }}"
                                breakpoint=""></mpc-model-label>
                            <mpc-model-label
                                id="{{ side }}LensName"
                                label="{{ 'general.lens' | translate }}"
                                value="{{ opticianFittedLens.LensDefinition.Name }}"
                                breakpoint=""></mpc-model-label>
                            <mpc-model-label
                                id="{{ side }}MaterialName"
                                *ngIf="!opticianFittedLens.LensDefinition.Disposable"
                                label="{{ 'general.material' | translate }}"
                                value="{{ materialColorName }}"
                                breakpoint=""></mpc-model-label>
                            <ng-container
                                *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']">
                                <mpc-model-label
                                    id="{{ side }}Markings"
                                    *ngIf="proposal.LensTypeId !== 2"
                                    label="{{ 'fitlens.lensengraving' | translate }}"
                                    value="{{ opticianFittedLens.LensEngraving.Name }}"
                                    breakpoint=""></mpc-model-label>
                            </ng-container>
                            <mpc-input-number
                                *ngIf="maxQuantity > 1 && !proposal.WarrantyExchange"
                                [formControlName]="side + 'Quantity'"
                                [id]="side + 'Quantity'"
                                [ranges]="[quantityRange]"
                                [maxLength]="2"
                                [label]="'general.quantity'"></mpc-input-number>
                        </div>

                        <div class="col-md-6">
                            <ng-container *ngFor="let param of lensParameters">
                                <mpc-model-label
                                    label="{{ param.LensDefinitionParameter.Name }}"
                                    id="{{ side }}-{{ param.LensDefinitionParameter.Name.replace(' ', '-') }}"
                                    [formatting]="
                                        (param.LensDefinitionParameter.ParameterType
                                        | validateInputType: inputTypes.Text)
                                            ? null
                                            : getFormatObject(param)
                                    "
                                    [value]="
                                        (param.LensDefinitionParameter.ParameterType
                                        | validateInputType: inputTypes.Number)
                                            ? param.Value
                                            : param.TextValue
                                    "
                                    breakpoint=""></mpc-model-label>
                            </ng-container>
                        </div>
                    </div>

                    <div *ngIf="isForcedWarrantyEnabled; else warrantyOptional">
                        <div class="row accented-background rounded mx-0 p-3">
                            <div class="col-md-12">
                                <label class="label" for="{{ side }}LensWarranty">
                                    {{ 'general.warranty.forcedWarranty' | translate }}

                                    {{
                                        'general.warranty.disclaimer'
                                            | translate
                                                : {
                                                      amountOfLenses: warranty.AmountOfLenses,
                                                      amountOfDays: warranty.AmountOfDays
                                                  }
                                    }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <ng-template #warrantyOptional>
                        <ng-container *ngIf="isWarrantyAllowed">
                            <div class="row">
                                <div class="col-12">
                                    <h1>
                                        {{ 'general.warranty.add' | translate }}
                                    </h1>
                                </div>
                            </div>
                            <div
                                class="row accented-background rounded mx-0 p-3"
                                [ngClass]="hasProductAnnualSupplyEnabled ? 'mb-3' : ''">
                                <div class="col-md-12">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            id="{{ side }}LensWarranty"
                                            (change)="onWarrantyValueChange()"
                                            [formControlName]="side + 'LensWarranty'" />
                                        <label class="form-check-label" for="{{ side }}LensWarranty">
                                            {{
                                                'general.warranty.disclaimer'
                                                    | translate
                                                        : {
                                                              amountOfLenses: warranty.AmountOfLenses,
                                                              amountOfDays: warranty.AmountOfDays
                                                          }
                                            }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-template>

                    <ng-container *ngIf="hasProductAnnualSupplyEnabled">
                        <app-annual-supply-confirmation
                            id="{{ sideType }}"
                            [disabled]="isAnnualSupplyDisabled || isAnnualSupplyFullfillmentOrder"
                            [annualSupply]="annualSupply"></app-annual-supply-confirmation>
                    </ng-container>
                </div>
            </ng-template>

            <div class="row my-1" *ngIf="perosBranchNumber">
                <div class="col-12">
                    <div class="row title-border-top mx-0 mt-4">
                        <div class="col-12 col-xl-6 px-0 pt-3">
                            <mpc-input-text
                                formControlName="perosOrderNumber"
                                [id]="'perosOrderNumber'"
                                label="{{ 'orderoverview.perosordernumber' | translate }}"
                                [maxLength]="6"
                                prefix="{{ perosBranchNumber }}"></mpc-input-text>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row my-1 mt-4" *ngIf="proposal.LensTypeId === lensTypes.DreamLite && proposal.OrderWithSupport">
                <div class="col-12">
                    <div class="card card-body text-left text-md-center rounded" [ngClass]="lensTypeMessageClass">
                        {{ 'orderoverview.dreamlitemessage' | translate: lensNames }}
                    </div>
                </div>
            </div>

            <ng-container *ngIf="isSpareOrderAllowed">
                <div class="row">
                    <div class="col">
                        <div class="title-border-bottom"></div>
                        <h1 class="mt-3">
                            <i class="far fa-clone me-2"></i>
                            {{ 'orderoverview.orderSpareLenses' | translate }}
                        </h1>
                    </div>
                </div>
                <div class="row accented-background rounded mx-0 p-3">
                    <div class="col-md-12">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="agreeOrderSpareLenses"
                                (change)="onSpareValueChange()"
                                [formControlName]="'agreeOrderSpareLenses'" />
                            <label class="form-check-label" for="agreeOrderSpareLenses">
                                {{ 'orderoverview.agreeOrderSpareLenses' | translate }}
                            </label>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Direct to home -->
            <form
                *hasFeatureSetting="[
                    {
                        Feature: Features.IsReceiptsOnlyEnabled,
                        Category: FeatureCategories.Optician,
                        IsEnabled: false
                    },
                    {
                        Feature: Features.DirectToHome,
                        Category: FeatureCategories.Distributor,
                        IsEnabled: true
                    }
                ]"
                [formGroup]="directToHomeFormGroup"
                class="directToHome">
                <div class="row mt-4">
                    <div class="col">
                        <div class="title-border-bottom"></div>
                        <h1 class="mt-3">
                            <i class="fas fa-mail-bulk me-2"></i>
                            {{ 'orderoverview.directToHome.title' | translate }}
                        </h1>
                    </div>
                </div>

                <div class="row mt-2 mb-4">
                    <div
                        class="col-12 col-lg-6 mb-4 mb-lg-0"
                        innerHTML="{{ 'orderoverview.directToHome.text' | translate }}"></div>
                    <div class="col-12 col-lg-6">
                        <div class="mb-3">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    [disabled]="directToHomeDisabled"
                                    id="directToHome"
                                    formControlName="approved" />
                                <label class="form-check-label" [for]="directToHomeDisabled ? 'none' : 'directToHome'">
                                    {{ 'orderoverview.directToHome.approve' | translate }}
                                </label>
                                <div *ngIf="directToHomeWarning" class="row mt-1 text-warning">
                                    {{ 'orderoverview.directToHome.cartwarning' | translate }}
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="{{ 'orderoverview.directToHome.name' | translate }}"
                                formControlName="name" />
                        </div>

                        <div class="form-group">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="{{ 'orderoverview.directToHome.street' | translate }}"
                                formControlName="street" />
                        </div>

                        <div class="form-group" *ngIf="showHouseNumber">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="{{ 'orderoverview.directToHome.houseNumber' | translate }}"
                                formControlName="houseNumber" />
                        </div>

                        <div class="form-group" *ngIf="showHouseNumber">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="{{ 'orderoverview.directToHome.houseNumberAddition' | translate }}"
                                formControlName="houseNumberSuffix" />
                        </div>

                        <div class="form-group">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="{{ 'orderoverview.directToHome.postalCode' | translate }}"
                                formControlName="postalCode" />
                        </div>

                        <div class="form-group">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="{{ 'orderoverview.directToHome.city' | translate }}"
                                formControlName="city" />
                        </div>

                        <div class="form-group">
                            <mpc-select
                                formControlName="country"
                                id="countrySelect"
                                [items]="countries"
                                label=""
                                noSelectionLabel="orderoverview.directToHome.country"
                                (change)="setStateControlVisibility()"></mpc-select>
                        </div>

                        <div class="form-group" *ngIf="showStates">
                            <mpc-select
                                formControlName="homeState"
                                id="stateSelect"
                                [items]="unitedStatesStates"
                                label=""
                                noSelectionLabel="orderoverview.directToHome.state"></mpc-select>
                        </div>
                        <div class="form-group">
                            <div
                                *ngIf="
                                    directToHomeFormGroup.controls['postalCode'].invalid &&
                                    (directToHomeFormGroup.controls['postalCode'].dirty ||
                                        directToHomeFormGroup.controls['postalCode'].touched)
                                "
                                class="alert alert-danger">
                                {{ 'orderoverview.directToHome.error.postalCode' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col">
                        <div class="title-border-bottom"></div>
                    </div>
                </div>
            </form>
            <!-- End of direct to home -->

            <div class="row my-1 mt-4 mb-3" *ngIf="proposal.OrderWithSupport">
                <div class="col-12 d-flex">
                    <div class="px-3 pt-2 pb-3 btn-default support-message-btn d-flex align-items-center">
                        {{ 'orderoverview.supportmessage' | translate }}
                    </div>

                    <div class="px-3 py-2 ms-auto btn-warning support-tips-btn" (click)="openTips()">
                        <i class="fas fa-clipboard-check"></i>
                        {{ 'orderoverview.tips' | translate }}
                    </div>
                </div>

                <div class="col-12 support-textarea my-1">
                    <mpc-input-textarea
                        formControlName="supportmessage"
                        [id]="'supportmessage'"
                        [placeholder]="'orderoverview.supportmessage' | translate"
                        [formControlOnly]="true"></mpc-input-textarea>
                </div>

                <div class="col-12">
                    <div class="d-flex flex-grow-1 p-2 fileupload-wrapper">
                        <file-upload #fileUploader (onOutput)="onAttachmentsUploaded($event)"></file-upload>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *footer class="d-flex align-items-center">
    <div>
        <a href="#" (click)="previous($event)">
            <i class="fas fa-arrow-alt-circle-left me-1"></i>
            {{ 'general.back' | translate }}
        </a>
    </div>
    <div class="ms-auto d-flex">
        <div class="me-2 mt-1">
            {{ invalidOrderKey | translate }}
        </div>
        <button
            *hasFeatureSetting="[
                {
                    Feature: Features.IsReceiptsOnlyEnabled,
                    Category: FeatureCategories.Optician,
                    IsEnabled: true
                }
            ]"
            class="btn btn-primary"
            (click)="createReceipt()">
            {{ 'orderoverview.createreceipt' | translate }}
        </button>
        <button *ngIf="isCartItem" class="btn btn-success mx-1" (click)="saveAndReturnToCart()">
            {{ 'general.save' | translate }}
        </button>
        <button
            *ngIf="hasAddToShoppingCartButton()"
            type="button"
            id="add-to-cart"
            [disabled]="!isAddToCartEnabled()"
            class="btn btn-primary"
            (click)="addToCart()">
            <i class="fas fa-shopping-cart mx-1"></i>
            {{ 'orderoverview.addtoshoppingcart' | translate }}
        </button>
        <button
            *ngIf="hasSendButton()"
            type="button"
            id="send"
            class="btn btn-warning mx-1"
            [disabled]="!isNextEnabled()"
            (click)="next()">
            {{ 'general.send' | translate }}
        </button>
    </div>
</div>
