import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegionSelectedGuard } from '@app/core/guards/region-selected.guard';
import { CurrentRoleAuthGuard } from '@app/core/guards/current-role.auth.guard';
import { Roles } from '@app/shared/enums';
import { AccessRightsAuthGuard } from '@app/core/guards/access-rights.auth.guard';
import { LicenseAuthGuard } from '@app/core/guards/license.auth.guard';
import { AccessRights } from '@app/shared/models/accessRights';
import { LayoutRegisterComponent } from './pages/layout-register/layout-register.component';
import { ReceiptCreatedComponent } from './pages/receipt-created/receipt-created.component';
import { ReceiptOrderSentComponent } from './pages/receipt-order-sent/receipt-order-sent.component';
import { ReceiptOrderComponent } from './pages/receipt-order/receipt-order.component';
import { ReceiptStatusComponent } from './pages/receipt-status/receipt-status.component';
import { RegisterOptcianSuccessComponent } from './pages/register-optician-success/register-optician-success.component';
import { RegisterOpticianComponent } from './pages/register-optician/register-optician.component';
import { ReceiptComponent } from './receipt.component';

const routes: Routes = [
    {
        path: '',
        component: ReceiptComponent,
        children: [
            {
                path: 'created/:id',
                component: ReceiptCreatedComponent,
                canActivate: [RegionSelectedGuard, CurrentRoleAuthGuard],
                data: { role: Roles.Optician },
            },
            {
                path: 'order',
                component: ReceiptOrderComponent,
                canActivate: [RegionSelectedGuard, CurrentRoleAuthGuard, LicenseAuthGuard, AccessRightsAuthGuard],
                data: {
                    role: Roles.Optician,
                    accessRights: { orderReceipts: true } as AccessRights,
                },
            },
            {
                path: 'order-sent/:id',
                component: ReceiptOrderSentComponent,
                canActivate: [RegionSelectedGuard, CurrentRoleAuthGuard],
                data: { role: Roles.Optician },
            },
            {
                path: 'order-sent',
                component: ReceiptOrderSentComponent,
                canActivate: [RegionSelectedGuard, CurrentRoleAuthGuard],
                data: { role: Roles.Optician },
            },
            {
                path: 'status',
                component: ReceiptStatusComponent,
                canActivate: [RegionSelectedGuard, CurrentRoleAuthGuard, LicenseAuthGuard],
                data: { role: Roles.Optician },
            },
            {
                path: 'register',
                component: LayoutRegisterComponent,
                children: [
                    {
                        path: 'optician',
                        component: RegisterOpticianComponent,
                    },
                    {
                        path: 'success',
                        component: RegisterOptcianSuccessComponent,
                    },
                ],
                canActivate: [RegionSelectedGuard],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ReceiptRoutingModule {}
