import { Component, OnInit } from '@angular/core';
import { FittingService } from '@app/core/services/fitting.service';
import { ProposalService } from '@app/core/services/api/proposal.service';
import { LensType, Proposal, Client, RefractionMeasurement } from '@app/shared/models';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { LensTypes } from '@app/shared/enums';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { Router } from '@angular/router';
import { RestRefraction } from '@app/shared/models/restrefraction.model';
import { SaveFittingContinuationConfiguration } from './saveFittingContinuationConfiguration';
import { Observable, lastValueFrom } from 'rxjs';
import { ProductGroupService } from '@app/core/services/api/product-group.service';
import { LensTypeProductGroupNames } from '@app/shared/models/lensTypeProductGroupNames.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RestRefractionWarningDialogComponent } from '../dialogs/rest-refraction-warning-dialog.component';
import { CalculateDreamLiteResidualRefractionRequest } from '@app/shared/models/calculateDreamLiteResidualRefractionRequest.model';
import { FileDownloadService } from '@app/core/services/file-download.service';
import { Util } from '@app/shared/helpers/utility.helper';
import { HttpParams } from '@angular/common/http';
import { DocumentService } from '@app/core/services/api/document.service';
import { LensTypeMeasurementStatus } from '@app/shared/enums/lens-type-measurement-status.enum';

@Component({
    selector: 'fitting-lenstype',
    templateUrl: 'fitting-lenstype.component.html',
    styleUrls: ['fitting-lenstype.component.scss'],
})
export class FittingLensTypeComponent implements OnInit {
    private static readonly restRefractionWarningThreshold = -0.75;

    LensTypeMeasurementStatus = LensTypeMeasurementStatus;
    lensTypes = LensTypes;
    availableLensTypes: LensType[];
    availableLensTypesInitialized = false;
    loadingLensTypes = false;
    loadingDreamliteRestRefraction = false;
    anyLensTypeAvailable = false;

    dreamLiteDisabled = false;
    dreamLiteSuitable = false;
    dreamLiteCalculationSuitable = false;

    hasManualCalculation = false;

    crtDisabled = false;

    selectableLenses: {
        [Key: number]: {
            isDisabled: boolean;
            isSelectable: boolean;
            lensTypeMeasurementStatus: LensTypeMeasurementStatus;
        };
    } = {};

    private minimumDreamliteSphere = -5;
    private maximumDreamliteSphere = -0.75;
    private minimumDreamliteCylinder = -2.5;
    private maximumDreamliteCylinder = 0.0;

    private minimumCrtSphere = -5.5;
    private maximumCrtSphere = -0.5;
    private minimumCrtCylinder = -1.75;
    private maximumCrtCylinder = 0.0;

    leftRestRefraction: RestRefraction;
    rightRestRefraction: RestRefraction;

    lensNames: LensTypeProductGroupNames;

    get client(): Client {
        return this.fittingService.state.client;
    }

    get proposal(): Proposal {
        return this.fittingService.state.proposal;
    }

    constructor(
        private readonly fittingService: FittingService,
        private readonly productGroupService: ProductGroupService,
        private readonly fileService: FileDownloadService,
        private readonly documentService: DocumentService,
        public router: Router,
        public appState: AppStateService,
        public appConfig: AppConfigService,
        public proposalService: ProposalService,
        public loaderService: LoaderService,
        public modalService: BsModalService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.fittingService.setFittingStep(this.router.url);

        // the proposal is not really "saveable" on this step, because the previous step has just
        // saved the proposal, and this step is only the selection of a lens type, which directly
        // leads to the next step. So if an optician clicks "save" on this step, just pretend to be saving.
        this.fittingService.configureSaveForContinuation(
            new SaveFittingContinuationConfiguration(
                () => true,
                () => Promise.resolve(),
            ),
        );

        if (!this.client || !this.proposal) {
            this.router.navigate(['/']);
        } else {
            this.loadingLensTypes = true;
            this.proposalService.getAvailableLensTypes(this.client.Id).subscribe((result) => {
                this.availableLensTypesInitialized = true;
                this.availableLensTypes = result;
                this.hasManualCalculation = this.checkHasManualCalculation();
                this.setSelectableLenses();
                this.calcDreamLiteRestRefractions();
                this.loadingLensTypes = false;
            });
        }

        this.lensNames = await lastValueFrom(this.productGroupService.getLensTypeProductGroupNames());
    }

    get isMyopie(): boolean {
        return this.proposal.Client.Myopie;
    }

    public downloadAgreement(type: string) {
        this.loaderService.show();

        let fileRequest: Observable<File>;

        const options = {
            params: new HttpParams().set('clientId', this.fittingService.getClientId().toString()),
        };

        const clientId = this.fittingService.getClientId();

        if (type === 'dreamlite') {
            fileRequest = this.fileService.downloadFile(`api/Documents/DownloadDreamliteAgreement/`, options);

            fileRequest = this.documentService.downloadDreamLiteAgreement(clientId);
        }

        if (type === 'misight') {
            fileRequest = this.fileService.downloadFile(`api/Documents/DownloadMiSightAgreement/`, options);
        }

        fileRequest.subscribe((file) => {
            Util.openBlobInBrowser(file, file.name);
        });

        this.loaderService.hide();
    }

    setSelectableLenses(): void {
        this.selectableLenses[LensTypes.DreamLite] = {
            isDisabled:
                this.availableLensTypes.find((lt) => lt.Id === LensTypes.DreamLite)?.LensTypeMeasurementStatus ===
                    LensTypeMeasurementStatus.AllManual && !this.hasManualCalculation,
            isSelectable:
                this.availableLensTypes &&
                this.availableLensTypes.filter((lt) => lt.Id === LensTypes.DreamLite).length > 0,
            lensTypeMeasurementStatus: this.availableLensTypes.find((lt) => lt.Id === LensTypes.DreamLite)
                ?.LensTypeMeasurementStatus,
        };

        this.selectableLenses[LensTypes.Soft] = {
            isDisabled:
                this.availableLensTypes.find((lt) => lt.Id === LensTypes.Soft)?.LensTypeMeasurementStatus ===
                    LensTypeMeasurementStatus.AllManual && !this.hasManualCalculation,
            isSelectable:
                this.availableLensTypes && this.availableLensTypes.filter((lt) => lt.Id === LensTypes.Soft).length > 0,
            lensTypeMeasurementStatus: this.availableLensTypes.find((lt) => lt.Id === LensTypes.Soft)
                ?.LensTypeMeasurementStatus,
        };

        this.selectableLenses[LensTypes.Rgp] = {
            isDisabled:
                this.availableLensTypes.find((lt) => lt.Id === LensTypes.Rgp)?.LensTypeMeasurementStatus ===
                    LensTypeMeasurementStatus.AllManual && !this.hasManualCalculation,
            isSelectable:
                this.fittingService.state.client &&
                !this.fittingService.state.client.Myopie &&
                this.availableLensTypes &&
                this.availableLensTypes.filter((lt) => lt.Id === LensTypes.Rgp).length > 0,
            lensTypeMeasurementStatus: this.availableLensTypes.find((lt) => lt.Id === LensTypes.Rgp)
                ?.LensTypeMeasurementStatus,
        };

        this.selectableLenses[LensTypes.Med] = {
            isDisabled:
                this.availableLensTypes.find((lt) => lt.Id === LensTypes.Med)?.LensTypeMeasurementStatus ===
                    LensTypeMeasurementStatus.AllManual && !this.hasManualCalculation,
            isSelectable:
                this.fittingService.state.client &&
                !this.fittingService.state.client.Myopie &&
                this.appState.isLensTypeMedEnabled &&
                this.availableLensTypes &&
                this.availableLensTypes.filter((lt) => lt.Id === LensTypes.Med).length > 0,
            lensTypeMeasurementStatus: this.availableLensTypes.find((lt) => lt.Id === LensTypes.Med)
                ?.LensTypeMeasurementStatus,
        };
        this.selectableLenses[LensTypes.MedPlus] = {
            isDisabled:
                this.availableLensTypes.find((lt) => lt.Id === LensTypes.MedPlus)?.LensTypeMeasurementStatus ===
                    LensTypeMeasurementStatus.AllManual && !this.hasManualCalculation,
            isSelectable:
                this.fittingService.state.client &&
                !this.fittingService.state.client.Myopie &&
                this.appState.isLensTypeMedPlusEnabled &&
                this.availableLensTypes &&
                this.availableLensTypes.filter((lt) => lt.Id === LensTypes.MedPlus).length > 0,
            lensTypeMeasurementStatus: this.availableLensTypes.find((lt) => lt.Id === LensTypes.MedPlus)
                ?.LensTypeMeasurementStatus,
        };

        this.selectableLenses[LensTypes.Crt] = {
            isDisabled:
                this.availableLensTypes &&
                this.availableLensTypes.find((lt) => lt.Id === LensTypes.Crt)?.LensTypeMeasurementStatus ===
                    LensTypeMeasurementStatus.AllManual &&
                !this.hasManualCalculation,
            isSelectable:
                this.availableLensTypes && this.availableLensTypes.filter((lt) => lt.Id === LensTypes.Crt).length > 0,
            lensTypeMeasurementStatus: this.availableLensTypes.find((lt) => lt.Id === LensTypes.Crt)
                ?.LensTypeMeasurementStatus,
        };

        this.anyLensTypeAvailable = Object.keys(this.selectableLenses).some((key) => {
            return this.selectableLenses[key];
        });

        this.dreamLiteSuitable = this.topoAvailable();
        this.dreamLiteCalculationSuitable = this.checkDreamLiteCalculationSuitable();
        this.dreamLiteDisabled =
            !(this.appState.currentOptician && this.appState.currentOptician.OrthoKSeminarDate != null) ||
            !this.dreamLiteCalculationSuitable ||
            !this.dreamLiteSuitable;

        this.crtDisabled = !this.checkCrtCalculationSuitable();
    }

    topoAvailable(): boolean {
        if (!this.proposal) {
            return false;
        }

        const leftOk =
            this.proposal.LeftRefractionMeasurement &&
            this.proposal.LeftTopographicMeasurement &&
            !this.proposal.LeftTopographicMeasurement.IsManual;
        const rightOk =
            this.proposal.RightRefractionMeasurement &&
            this.proposal.RightTopographicMeasurement &&
            !this.proposal.RightTopographicMeasurement.IsManual;

        if (this.proposal.LeftTopographicMeasurement && this.proposal.LeftTopographicMeasurement.IsManual) {
            return false;
        }

        if (this.proposal.RightTopographicMeasurement && this.proposal.RightTopographicMeasurement.IsManual) {
            return false;
        }

        return leftOk || rightOk;
    }

    checkDreamLiteCalculationSuitable(): boolean {
        return this.checkCalculationSuitable(
            this.minimumDreamliteSphere,
            this.maximumDreamliteSphere,
            this.minimumDreamliteCylinder,
            this.maximumDreamliteCylinder,
        );
    }

    private checkHasManualCalculation(): boolean {
        return (
            !!this.fittingService.state.proposal.RightTopographicMeasurement?.IsManual &&
            !!this.fittingService.state.proposal.LeftTopographicMeasurement?.IsManual
        );
    }

    private checkCrtCalculationSuitable(): boolean {
        return this.checkCalculationSuitable(
            this.minimumCrtSphere,
            this.maximumCrtSphere,
            this.minimumCrtCylinder,
            this.maximumCrtCylinder,
        );
    }

    private checkCalculationSuitable(
        minSphere: number,
        maxSphere: number,
        minCylinder: number,
        maxCylinder: number,
    ): boolean {
        if (!this.proposal) {
            return false;
        }

        const leftIsEnabled = this.leftSideEnabled();
        const rightIsEnabled = this.rightSideEnabled();
        let leftOk = true;
        let rightOk = true;

        if (leftIsEnabled) {
            leftOk = this.checkRefractionMeasurement(
                this.proposal.LeftRefractionMeasurement,
                minSphere,
                maxSphere,
                minCylinder,
                maxCylinder,
            );
        }

        if (rightIsEnabled) {
            rightOk = this.checkRefractionMeasurement(
                this.proposal.RightRefractionMeasurement,
                minSphere,
                maxSphere,
                minCylinder,
                maxCylinder,
            );
        }

        return (leftIsEnabled || rightIsEnabled) && leftOk && rightOk;
    }

    private checkRefractionMeasurement(
        refractionMeasurement: RefractionMeasurement | undefined,
        minSphere: number,
        maxSphere: number,
        minCylinder: number,
        maxCylinder: number,
    ): boolean {
        if (refractionMeasurement) {
            const sphere = refractionMeasurement.Sphere;
            const cyl = refractionMeasurement.Cylinder;
            return sphere >= minSphere && sphere <= maxSphere && cyl >= minCylinder && cyl <= maxCylinder;
        }
        return true; // No refraction measurement, so it's considered ok
    }

    private rightSideEnabled(): boolean {
        return (
            this.proposal &&
            this.proposal.RightRefractionMeasurement != null &&
            this.fittingService.state.isRightEyeSideSelected
        );
    }

    private leftSideEnabled(): boolean {
        return (
            this.proposal &&
            this.proposal.LeftRefractionMeasurement != null &&
            this.fittingService.state.isLeftEyeSideSelected
        );
    }

    private calcDreamLiteRestRefractions(): void {
        if (
            !(this.selectableLenses[LensTypes.DreamLite] && this.dreamLiteSuitable && this.dreamLiteCalculationSuitable)
        ) {
            return;
        }

        this.loadingDreamliteRestRefraction = true;

        const request = new CalculateDreamLiteResidualRefractionRequest();
        request.OpticianId = this.proposal.OpticianId;
        request.LeftTopographicMeasurementId = this.proposal.LeftTopographicMeasurementId;
        request.LeftRefractionMeasurement = this.proposal.LeftRefractionMeasurement;
        request.LeftMaterialColorId = this.proposal.LeftOpticianFittedLens?.MaterialColorId;
        request.RightTopographicMeasurementId = this.proposal.RightTopographicMeasurementId;
        request.RightRefractionMeasurement = this.proposal.RightRefractionMeasurement;
        request.RightMaterialColorId = this.proposal.RightOpticianFittedLens?.MaterialColorId;

        this.proposalService.calculateDreamLiteRestrefraction(request).subscribe((result) => {
            this.rightRestRefraction = result.RightResidualRefraction;
            this.leftRestRefraction = result.LeftResidualRefraction;
            this.loadingDreamliteRestRefraction = false;
        });
    }

    previous($event: MouseEvent): void {
        $event.preventDefault();
        this.fittingService.gotoPreviousStep();
    }

    async next($event: MouseEvent, lensTypeId: LensTypes): Promise<void> {
        $event.preventDefault();

        if (this.selectableLenses[lensTypeId].isDisabled) {
            return;
        }

        if (
            lensTypeId === LensTypes.DreamLite &&
            (this.loadingDreamliteRestRefraction || !(await this.isAbleToContinueDreamLite()))
        ) {
            return;
        }

        if (lensTypeId === LensTypes.Crt && !(await this.isAbleToContinueCrt())) {
            return;
        }

        this.loaderService.show();

        this.fittingService.setProposalStepOnNextClick();
        const savedProposal = await this.saveLensType(lensTypeId);

        this.fittingService.clearSaveForContinationConfiguration();
        this.fittingService.setProposal(savedProposal);

        this.fittingService.gotoNextStep();
        this.loaderService.hide();
    }

    private async isAbleToContinueDreamLite(): Promise<boolean> {
        if (this.hasTooMuchRestRefraction()) {
            return await this.showRestRefractionWarning();
        }

        return (
            this.selectableLenses[LensTypes.DreamLite] && this.dreamLiteCalculationSuitable && this.dreamLiteSuitable
        );
    }

    private async isAbleToContinueCrt(): Promise<boolean> {
        if (this.hasTooMuchRestRefraction()) {
            return await this.showRestRefractionWarning();
        }

        return this.selectableLenses[LensTypes.Crt] && !this.crtDisabled;
    }

    private hasTooMuchRestRefraction(): boolean {
        const isTooNegative = (refr: number | null) =>
            refr != null && refr < FittingLensTypeComponent.restRefractionWarningThreshold;

        return (
            isTooNegative(this.leftRestRefraction?.ResidualRefractionCylinder) ||
            isTooNegative(this.rightRestRefraction?.ResidualRefractionCylinder) ||
            isTooNegative(this.leftRestRefraction?.ResidualRefractionSphere) ||
            isTooNegative(this.rightRestRefraction?.ResidualRefractionSphere)
        );
    }

    private saveLensType(lensTypeId: LensTypes): Promise<Proposal> {
        const proposal = structuredClone(this.fittingService.state.proposal);
        proposal.LensTypeId = lensTypeId;

        let obs: Observable<Proposal>;
        if (lensTypeId === LensTypes.DreamLite) {
            obs = this.proposalService.saveDreamLiteLensTypeAndCalculateOpticianFittedLenses(proposal);
        } else {
            obs = this.proposalService.saveLensType(proposal);
        }
        return lastValueFrom(obs);
    }

    private showRestRefractionWarning(): Promise<boolean> {
        return new Promise((resolve) => {
            const modalOptions: unknown = {
                initialState: {
                    leftRestRefraction: this.leftRestRefraction,
                    rightRestRefraction: this.rightRestRefraction,
                    onCancel: () => resolve(false),
                    onContinue: () => resolve(true),
                },
                class: 'rest-cylinder-warning-dialog',
            };
            this.modalService.show(RestRefractionWarningDialogComponent, modalOptions);
        });
    }
}
