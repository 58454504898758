import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { LogisticRoutingModule } from './logistic-routing.module';
import { LogisticComponent } from '@app/logistic/pages/home/logistic.component';
import { LogisticOverviewComponent } from '@app/logistic/pages/overview/logistic-overview.component';
import { LogisticManualRequestComponent } from './pages/manualRequest/logistic-manual-request.component';
import { LogisticNewRequestDownloadFormComponent } from './pages/download-form/logistic-newrequest-download-form.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

@NgModule({
    imports: [TypeaheadModule.forRoot(), CommonModule, SharedModule, LogisticRoutingModule],
    declarations: [
        LogisticComponent,
        LogisticOverviewComponent,
        LogisticManualRequestComponent,
        LogisticNewRequestDownloadFormComponent,
    ],
    providers: [],
})
export class LogisticModule {}
