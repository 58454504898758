import { SharedModule } from '@app/shared/shared.module';
import { CartRoutingModule } from './cart.routing.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    CartItemLensTableComponent,
    CartItemProductTableComponent,
    DeleteCartItemComponent,
} from '@app/features/cart/components';
import { CartOrderComponent, CartOverviewComponent } from '@app/features/cart/pages';
import { CartService } from '@app/core/services/api/cart.service';

@NgModule({
    imports: [CommonModule, CartRoutingModule, SharedModule],
    declarations: [
        CartOverviewComponent,
        CartOverviewComponent,
        CartOrderComponent,
        DeleteCartItemComponent,
        CartItemLensTableComponent,
        CartItemProductTableComponent,
    ],
    providers: [CartService],
})
export class CartModule {}
