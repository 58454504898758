import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User, UserSetting, AuthenticatedUser, ListOption, ListSelectOption } from '@app/shared/models';
import { PasswordExpiryInfo } from '@app/shared/models/passwordExpiryInfo.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private readonly basePath = 'api/users';

    public languageChangeCallback: () => void;

    constructor(private readonly httpClient: HttpClient) {}

    public getUserById(userId: number): Observable<User> {
        return this.httpClient.get<User>(`${this.basePath}/${userId}`);
    }

    public save(user: User): Observable<User> {
        return this.httpClient.post<User>(this.basePath, user);
    }

    public saveWithLanguage(user: User, langId: number): Observable<User> {
        const options = {
            params: new HttpParams().set('languageId', langId),
        };

        return this.httpClient.post<User>(this.basePath, user, options);
    }

    public remove(userId: number): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${this.basePath}/${userId}`);
    }

    public languageChanged(): void {
        if (this.languageChangeCallback) {
            this.languageChangeCallback();
        }
    }

    public getPasswordExpiryInfo(userId: number): Observable<PasswordExpiryInfo> {
        return this.httpClient.get<PasswordExpiryInfo>(`${this.basePath}/${userId}/password-expiry-info`);
    }

    public approveAgreements(datasheetCodes: string[]): Observable<AuthenticatedUser> {
        return this.postAndMapAuthenticatedUser(`${this.basePath}/approve-agreements`, datasheetCodes);
    }

    public getSupporterUsers(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/in-support-role/options`);
    }

    public getUsers(): Observable<ListSelectOption[]> {
        return this.httpClient.get<ListSelectOption[]>(`${this.basePath}/options`);
    }

    public getUsersByOpticianId(opticianId: number): Observable<ListOption[]> {
        const options = {
            params: new HttpParams().set('opticianId', opticianId.toString()),
        };

        return this.httpClient.get<ListOption[]>(`${this.basePath}/options`, options);
    }

    private postAndMapAuthenticatedUser(url: string, data: unknown = {}): Observable<AuthenticatedUser> {
        return this.httpClient.post<UserSetting>(url, data, { observe: 'response' }).pipe(
            map((response) => {
                const authenticatedUser = JSON.parse(response.headers.get('authenticated-user')) as AuthenticatedUser;

                if (authenticatedUser) {
                    return authenticatedUser;
                }
            }),
        );
    }
}
