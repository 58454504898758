import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { Features, FeatureCategories, OrderLineStatus } from '@app/shared/enums';
import { Observable } from 'rxjs';

@Component({
    selector: 'change-order-status-dialog',
    templateUrl: './change-order-status-dialog.component.html',
    styleUrls: ['change-order-status-dialog.component.scss'],
})
export class ChangeOrderStatusDialogComponent {
    Features = Features;
    FeatureCategories = FeatureCategories;

    @Input() currentOrderStatus: OrderLineStatus;
    @Input() onClose: (newStatus: OrderLineStatus) => Observable<unknown>;

    constructor(
        private readonly bsModalRef: BsModalRef,
        private readonly loaderService: LoaderService,
    ) {}

    public setStatus(newStatus: OrderLineStatus) {
        this.loaderService.show();

        this.onClose(newStatus).subscribe(() => {
            this.loaderService.hide();
            this.close();
        });
    }

    public close(): void {
        this.bsModalRef.hide();
    }
}
