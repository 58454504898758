import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Roles } from '@app/shared/enums';
import { QaOverviewComponent, QaBarcodeScanComponent, QaComponent } from '@app/features/quality-assurance/pages';
import { QaProcessComplaintComponent } from '@app/shared/components/qa-process-complaint/qa-process-complaint.component';
import { RoleAuthGuard } from '@app/core/guards/role.auth.guard';

const routes: Routes = [
    {
        path: '',
        children: [
            { path: '', component: QaComponent },
            { path: 'overview', component: QaOverviewComponent },
            { path: 'scan', component: QaBarcodeScanComponent },
            { path: ':returnId', component: QaProcessComplaintComponent },
        ],
        canActivate: [RoleAuthGuard],
        data: {
            roles: [Roles.QualityAssurance],
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [],
})
export class QaRoutingModule {}
