import { RefractionMeasurement } from '@app/shared/models/refractionmeasurement.model';

export class TopographicMeasurement {
    Id: number;
    OpticianId: number;
    ClientId: number;
    EyeSideId: number;
    Performed: Date;
    TopographerModel: string;
    TopographerName: string;
    SimKAxisFlat: number;
    SimKRadiusFlat: number;
    SimKAxisSteep: number;
    SimKRadiusSteep: number;
    CornealCylinder: number;
    MeasurementAngle: number;
    EccentricityRadius0: number;
    EccentricityRadius180: number;
    EccentricityRadius90: number;
    EccentricityRadius270: number;
    Eccentricity0: number;
    Eccentricity180: number;
    Eccentricity90: number;
    Eccentricity270: number;
    ConicFitCurvature: number;
    ConicFitConstant: number;
    ConicFitTiltX: number;
    ConicFitTiltY: number;
    ConicFitCenterX: number;
    ConicFitCenterY: number;
    ConicFitCenterZ: number;
    ConicFitCurvatureStandardDeviation: number;
    ConicFitConstantStandardDeviation: number;
    ConicFitStandardDeviation: number;
    ConicFitIsValid: boolean;
    BiConicFitCurvature1: number;
    BiConicFitCurvature2: number;
    BiConicFitConstant1: number;
    BiConicFitConstant2: number;
    BiConicFitTilt1: number;
    BiConicFitTilt2: number;
    BiConicFitCenterX: number;
    BiConicFitCenterY: number;
    BiConicFitCenterZ: number;
    BiConicFitAxisDegree: number;
    BiConicFitToricLift: number;
    BiConicFitCurvatureStandardDeviation1: number;
    BiConicFitCurvatureStandardDeviation2: number;
    BiConicFitConstantStandardDeviation1: number;
    BiConicFitConstantStandardDeviation2: number;
    BiConicFitStandardDeviation: number;
    BiConicFitIsValid: boolean;
    ZernikeFitNormalizationRadius: number;
    ZernikeFitOrder: number;
    ZernikeFitRadius: number;
    ZernikeFitConicConstant: number;
    ZernikeFitAxisFlatDegrees: number;
    ZernikeFitRadiusFlat: number;
    ZernikeFitRadiusSteep: number;
    ZernikeFitStandardDeviation: number;
    ZernikeFitMaximumError: number;
    ZernikeFitIsValid: boolean;
    ClientFirstName: string;
    ClientLastNamePrefix: string;
    ClientLastName: string;
    ClientBirthDate: string;
    ClientGender: string;
    ClientReference: string;
    IsManual: boolean;
    IsBaseLine: boolean;
    Base64Thumbnail: string;
    CorneaMaxSag: number;
    CorneaPhiMaxSag: number;
    CorneaRxMaxSag: number;
    CorneaMinSag: number;
    CorneaPhiMinSag: number;
    CorneaRxMinSag: number;
    CorneaAngleMin: number;
    CorneaAngleMax: number;
    CorneaAngleMean: number;
    CorneaUsedCalculationDiameter: number;

    // client side

    IsMarked: boolean;
    IsMarkedForDeletion: boolean;

    RefractionMeasurements: RefractionMeasurement[];
}
