import { FittedLens } from '@app/shared/models/fitted-lens.model';
import { FittedLensParameter } from '@app/shared/models/fitted-lens-parameter.model';
import { NumberFormatPipe } from '@app/shared/pipes/number.format.pipe';

export class DreamLiteFittedLensInfo {
    public Id: number;
    public ConceptOfFittedLensId: number;
    public OrderId: number;
    public OpticianId: number;
    public OrderDate: Date;
    public ReadyForMiddlewareExport: boolean;
    public MiddlewareExportDate: Date;
    public OrderLineStatusId: number;
    public ClientId: number;
    public EyeSideId: number;
    public FitTypeId: number;
    public FixTypeId: number;

    public Rad: number;
    public Rc: number;
    public Rc2: number;
    public Ac: number;
    public Ac2: number;
    public Ecc: number;
    public Aecc: number;
    public Rmf: number;
    public Diam: number;
    public Pow: number;
    public Oz: number;
    public Add: number;
    public ReturnIds: number[];

    copyFittedLensParameterValuesFromFittedLens(fittedLens: FittedLens, numberFormatPipe: NumberFormatPipe): void {
        fittedLens.FittedLensParameters.forEach((parameter: FittedLensParameter) => {
            const parameterTypeName = parameter.LensDefinitionParameter.ParameterType.Code;
            switch (parameterTypeName) {
                case 'RAD':
                    this.Rad = numberFormatPipe.transform(parameter.Value, {
                        parameterType: parameterTypeName,
                    });
                    break;
                case 'RC':
                    this.Rc = numberFormatPipe.transform(parameter.Value, {
                        parameterType: parameterTypeName,
                    });
                    break;
                case 'AC':
                    this.Ac = numberFormatPipe.transform(parameter.Value, {
                        parameterType: parameterTypeName,
                    });
                    break;
                case 'ECC':
                    this.Ecc = numberFormatPipe.transform(parameter.Value, {
                        parameterType: parameterTypeName,
                    });
                    break;
                case 'OZ':
                    this.Oz = numberFormatPipe.transform(parameter.Value, {
                        parameterType: parameterTypeName,
                    });
                    break;
                case 'RC2':
                    this.Rc2 = numberFormatPipe.transform(parameter.Value, {
                        parameterType: parameterTypeName,
                    });
                    break;
                case 'AC2':
                    this.Ac2 = numberFormatPipe.transform(parameter.Value, {
                        parameterType: parameterTypeName,
                    });
                    break;
                case 'AECC':
                    this.Aecc = numberFormatPipe.transform(parameter.Value, { parameterType: parameterTypeName });
                    break;
                case 'RMF':
                    this.Rmf = numberFormatPipe.transform(parameter.Value, {
                        parameterType: parameterTypeName,
                    });
                    break;
                case 'DIAM':
                    this.Diam = numberFormatPipe.transform(parameter.Value, { parameterType: parameterTypeName });
                    break;
                case 'POW':
                    this.Pow = numberFormatPipe.transform(parameter.Value, {
                        parameterType: parameterTypeName,
                    });
                    break;
                case 'ADD':
                    this.Add = numberFormatPipe.transform(parameter.Value, {
                        parameterType: parameterTypeName,
                    });
                    break;
            }
        });
    }
}
