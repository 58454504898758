import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { CartOrderResult } from '@app/shared/models/cartOrderResult.model';
import { CartService } from '@app/core/services/api/cart.service';
import { CartStorageService } from '@app/core/services/cart-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Util } from '@app/shared/helpers/utility.helper';
import { finalize } from 'rxjs/operators';
import { Features, FeatureCategories } from '@app/shared/enums';
import { AlertService } from '@app/shared/appservices/alert.service';

@Component({
    selector: 'app-cart-order',
    templateUrl: './cart-order.component.html',
    styleUrls: ['./cart-order.component.scss'],
})
export class CartOrderComponent implements OnInit {
    Features = Features;
    FeatureCategories = FeatureCategories;
    play = true;
    orderComplete = false;
    header: string;
    firstMessage: string;
    secondMessage: string;
    goToStartScreenInfo = 'ordersent.goToStartScreenInfo';
    cartGroupOrderGuid: string;
    cartId: number;

    @ViewChild('orderVideo')
    private orderVideo: ElementRef;

    constructor(
        private alertService: AlertService,
        private translate: TranslateService,
        private cartStorageService: CartStorageService,
        private cartService: CartService,
        private loaderService: LoaderService,
        private router: Router,
    ) {
        this.header = 'ordersent.pageHeader';
        this.firstMessage = 'ordersent.processingInfo';
        this.secondMessage = 'features.cart.processing.loading';
    }

    ngOnInit() {
        this.cartStorageService.clearShoppingCart();

        this.alertService.info(
            this.translate.instant('cart.ordering.message'),
            this.translate.instant('cart.ordering.title'),
        );

        this.cartService.orderCart().subscribe((result: CartOrderResult) => {
            if (result.Error) {
                this.header = this.translate.instant('cart.ordering.error');
                this.firstMessage = this.translate.instant(result.ErrorMessage);
                this.secondMessage = '';
                this.alertService.error(this.firstMessage, false, this.header);
            } else {
                this.orderComplete = true;
                this.firstMessage = 'ordersent.processingInfo';
                this.secondMessage = 'ordersent.progressReviewInfo';
                this.cartGroupOrderGuid = result.GroupOrder;
                this.cartId = result.CartId;
                this.alertService.success(this.translate.instant('cart.ordering.success'));
            }
        });
    }

    downloadPdf() {
        this.loaderService.show();
        this.cartService
            .downloadPdf(this.cartGroupOrderGuid, this.cartId.toString(), navigator.language)
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe((file) => {
                Util.openBlobInBrowser(file, file.name);
            });
    }

    home(): void {
        this.router.navigate(['/']).then();
    }

    gotoOrderStatus(): void {
        this.router.navigate(['/orderstatus']).then();
    }

    changePlayback(): void {
        if (this.play) {
            this.orderVideo.nativeElement.pause();
            this.play = false;
        } else {
            this.orderVideo.nativeElement.play();
            this.play = true;
        }
    }
}
