<table class="ps-datatable mt-2">
    <thead>
        <tr>
            <th *ngFor="let field of requestData">
                {{ 'complaintsoverview.returnsummary.' + field | translate }}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let rt of returnConclusionChanges" class="table_entry">
            <td class="m-1">{{ rt.ReturnId }}</td>
            <td class="m-1">{{ rt.IsCreatedOn }}</td>
            <td class="m-1">{{ rt.IsCreatedByUserName }}</td>
            <td class="m-1">{{ rt.Conclusion }}</td>
            <td class="m-1">{{ rt.ReturnResultId }}</td>
            <td class="m-1">{{ rt.ReturnCreditId }}</td>
        </tr>
    </tbody>
</table>
