import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Client } from '@app/shared/models';
import { switchMap, finalize, catchError } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ClientService } from '@app/core/services/api/client.service';
import { of, throwError, EMPTY } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ClientEditComponent } from '@app/client/client-edit.component';
import { TranslateService } from '@ngx-translate/core';
import { FittingService } from '@app/core/services/fitting.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { AlertService } from '@app/shared/appservices/alert.service';
import { UtilService } from '@app/shared/appservices/util.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FittingMergeClientComponent } from './fitting-merge-client.component';
import { FittingDeleteClientComponent } from './fitting-delete-client.component';

@Component({
    selector: 'fitting-client-edit',
    templateUrl: 'fitting-client-edit.component.html',
})
export class FittingClientEditComponent implements OnInit {
    formGroup: UntypedFormGroup;

    @ViewChild('editClient') editClientComponent: ClientEditComponent;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly fittingService: FittingService,
        public router: Router,
        public route: ActivatedRoute,
        public loaderService: LoaderService,
        public alertService: AlertService,
        public translate: TranslateService,
        public clientService: ClientService,
        public utilService: UtilService,
        public modalService: BsModalService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.fittingService.setFittingStep(this.router.url);

        if (!this.client) {
            // no client is present on the fittingservice, fetch it from the querystring (if available)
            this.route.params
                .pipe(
                    switchMap((routeParams: Params) => {
                        if (routeParams['id']) {
                            return this.clientService.getById(+routeParams['id']);
                        }
                        return of(null);
                    }),
                )
                .subscribe((client: Client) => {
                    this.fittingService.setClient(client);
                });
        }

        this.createForm();
        this.changeDetector.detectChanges();
    }

    createForm(): void {
        this.formGroup = this.fb.group({});
    }

    get client(): Client {
        return this.fittingService.state.client;
    }

    get hasExistingClient(): boolean {
        return this.fittingService.getClientId() > 0;
    }

    save(): void {
        this.loaderService.show();

        this.editClientComponent
            .save()
            .pipe(
                finalize(() => {
                    this.loaderService.hide();
                }),
                catchError((e) => {
                    const errors = this.utilService.getServerErrors(e);
                    if (errors && errors.length > 0) {
                        errors.forEach((errorMessage: string) => {
                            this.alertService.error(this.translate.instant(errorMessage));
                        });
                        return EMPTY;
                    } else {
                        return throwError(e);
                    }
                }),
            )
            .subscribe((result) => {
                if (result) {
                    this.fittingService.setClient(result);
                    this.alertService.success(this.translate.instant('general.saveSuccessful'));
                    this.router.navigate(['/client', this.fittingService.getClientId()]).then();
                }
            });
    }

    deleteClient(): void {
        this.modalService.show(FittingDeleteClientComponent);
    }

    mergeClient(): void {
        const options: unknown = {
            initialState: {
                mergeFromClient: this.editClientComponent.client,
            },
            class: 'modal-full',
        };

        this.modalService.show(FittingMergeClientComponent, options);
    }
}
