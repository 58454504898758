<div *ngIf="!formControlOnly" class="form-group row">
    <label for="{{ id }}" class="col-form-label" [ngClass]="labelClass">
        <i *ngIf="info" class="fa fa-question-circle" popover="{{ info }}" triggers="mouseenter:mouseleave"></i>
        {{ label | translate: labelParams }}
    </label>
    <div [ngClass]="controlClass">
        <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
    </div>
</div>

<div *ngIf="formControlOnly">
    <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
</div>

<ng-template #controlTemplate>
    <label class="switch">
        <input
            type="checkbox"
            value="{{ value }}"
            checked="{{ value ? 'checked' : '' }}"
            [id]="id"
            [name]="id"
            (click)="propagateChange()"
            [disabled]="isDisabled" />
        <span id="{{ id }}_span" class="switchSlider"></span>
    </label>
</ng-template>
