import { Pipe, PipeTransform } from '@angular/core';
import {
    LensDefinitionParameterNumberRange,
    LensDefinitionParameterText,
} from '../models/lens-definition-parameter-range.model';
import { FitLensComponent } from '@app/fitlens/fitlens.component';
import { LensDefinitionParameterRangeHelper } from '../helpers/lens-definition-parameter-range-helper';
import { TranslateService } from '@ngx-translate/core';
import { LensDefinitionParameter } from '../models';

@Pipe({
    name: 'humanizeExpression',
})
export class HumanizeExpressionPipe implements PipeTransform {
    constructor(private readonly translationService: TranslateService) {}

    public transform(item: LensDefinitionParameter, flc: FitLensComponent): string {
        const ranges = <(LensDefinitionParameterText | LensDefinitionParameterNumberRange)[]>(
            item.LensDefinitionParameterRanges
        );
        const humanized = ranges
            .filter<LensDefinitionParameterNumberRange>(this.isNumberRange)
            .filter((x) => LensDefinitionParameterRangeHelper.checkConditions(x, flc))
            .map((x) => LensDefinitionParameterRangeHelper.humanizeExpression(x, flc, this.translationService));

        return humanized.join('\n');
    }

    private isNumberRange(
        item: LensDefinitionParameterText | LensDefinitionParameterNumberRange,
    ): item is LensDefinitionParameterNumberRange {
        return item.$type === 'number-range';
    }
}
