<div class="horizontal-scroll">
    <table class="ps-datatable mt-2">
        <thead>
            <tr>
                <th *ngFor="let field of requestData">
                    {{ field | translate }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let rt of returnQAConclusion" class="table_entry">
                <td class="m-1">{{ rt.ReturnId }}</td>
                <td class="m-1">{{ rt.IsCreatedOn }}</td>
                <td class="m-1">{{ rt.IsCreatedByUserName }}</td>
                <td class="m-1">{{ rt.Pow }}</td>
                <td class="m-1">{{ rt.Bcr }}</td>
                <td class="m-1">{{ rt.Diam }}</td>
                <td class="m-1">{{ rt.Oz }}</td>
                <td class="m-1">{{ rt.Cyl }}</td>
                <td class="m-1">{{ rt.Ax }}</td>
                <td class="m-1">{{ rt.Add }}</td>
                <td class="m-1">{{ rt.Prism }}</td>
                <td class="m-1">{{ rt.Cmd }}</td>
                <td class="m-1">{{ rt.Bcr2 }}</td>
                <td class="m-1">{{ rt.Sag }}</td>
                <td class="m-1">{{ rt.Stab }}</td>
                <td class="m-1">{{ rt.Notch }}</td>
                <td class="m-1">{{ rt.Trunc }}</td>
                <td class="m-1">{{ rt.IsOpticClear }}</td>
                <td class="m-1">{{ rt.Additional }}</td>
                <td class="m-1">{{ rt.InternalConclusion }}</td>
                <td class="m-1">{{ rt.MaterialId }}</td>
                <td class="m-1">{{ rt.ColorId }}</td>
                <td class="m-1">{{ rt.SurfaceId }}</td>
                <td class="m-1">{{ rt.EdgeId }}</td>
            </tr>
        </tbody>
    </table>
</div>
