import { NumberFormatOptions } from './numberFormatOptions.model';
import { ParameterType } from './parameterType.model';
import {
    LensDefinitionParameterNumberRange,
    LensDefinitionParameterText,
} from './lens-definition-parameter-range.model';
import { LensDefinitionParameterTranslation } from './lensDefinitionParameterTranslation.model';

export class LensDefinitionParameter {
    constructor() {
        this.ParameterType = new ParameterType();
        this.LensDefinitionParameterRanges = [];
        this.Translations = new Array<LensDefinitionParameterTranslation>();
        this.NumberFormatOptions = new NumberFormatOptions();
    }

    public Id: number;
    public ParameterTypeId: number;
    public IsReadOnly: boolean;
    public IsHidden: boolean;
    public ColumnIndex: number;
    public ExportCode: string;
    public ExportToERP: boolean;
    public RequireValidValue: boolean;
    public SetInitialClosestRangeValue: boolean;

    public LensDefinitionId: number;
    public DefaultValueExpression: string;
    public CalculateValueExpression: string;
    public LensDefinitionParameterRanges: LensDefinitionParameterText[] | LensDefinitionParameterNumberRange[];
    public Translations: LensDefinitionParameterTranslation[];
    public ParameterType: ParameterType;

    public CollectionIndex: number;
    public DefaultValueExpressionResult: number;
    public CalculateValueExpressionResult: number;
    public Name: string;
    public NumberFormatOptions: NumberFormatOptions;
    public RankingOrder: number;
}
