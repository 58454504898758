import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import { FitLensComponent } from '@app/fitlens/fitlens.component';
import { EyeSides } from '@app/shared/enums';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DreamLiteWizardFixType, FittedLensParameter, TopographicMeasurement } from '@app/shared/models';
import { InputNumberRange } from '@app/shared/models/input-number-range.model';
import { GetTopographicMeasurementsByClientIdRequest } from '@app/shared/requestmodels';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { finalize, debounceTime } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MeasurementReviewDialogComponent } from '@app/measurement/measurement-review-dialog.component';
import { DreamLiteWizardService } from '@app/core/services/api/dreamlite-wizard.service';
import { TopographicMeasurementService } from '@app/core/services/api/topographic-measurement.service';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'dreamlite-options',
    templateUrl: 'dreamliteoptions.component.html',
    styleUrls: ['dreamliteoptions.component.scss'],
})
export class DreamliteOptionsComponent implements OnInit {
    @Input() fitlens: FitLensComponent;
    @Input() disableConfiguration: boolean;
    @Input() fittedLensParameters: FittedLensParameter[];

    formGroupFixType: UntypedFormGroup;
    multiFocalAdditionRequired = false;
    selectedRidingFixType: 'low' | 'high' | null = null;
    isOzFixTypeDisabled = false;

    private fixTypes: DreamLiteWizardFixType[];
    protected fixTypeRanges: { [code: string]: InputNumberRange[] };

    lessOrMuchFixType: DreamLiteWizardFixType;
    adjustClearanceFixType: DreamLiteWizardFixType;
    ozFixType: DreamLiteWizardFixType;
    lateralFixType: DreamLiteWizardFixType;
    highRidingFixType: DreamLiteWizardFixType;
    lowRidingFixType: DreamLiteWizardFixType;
    dreamliteFixType: DreamLiteWizardFixType;
    dreamliteFixTypeMC: DreamLiteWizardFixType;
    additionParameterTypeRange: InputNumberRange;

    constructor(
        public appState: AppStateService,
        private readonly fb: UntypedFormBuilder,
        private readonly dreamLiteWizardService: DreamLiteWizardService,
        private readonly loaderService: LoaderService,
        private readonly topographicMeasurementService: TopographicMeasurementService,
        private readonly modalService: BsModalService,
    ) {}

    async ngOnInit() {
        this.createForm();
        await this.loadFixTypes();

        this.additionParameterTypeRange = new InputNumberRange(0.75, 1.5, 0.25);
    }

    ngOnChanges(changes: SimpleChange): void {
        if (changes['fittedLensParameters']?.currentValue) {
            this.isOzFixTypeDisabled = !changes['fittedLensParameters'].currentValue.some(
                (x) => x.LensDefinitionParameter.ParameterType.Code === 'OZ',
            );
        }
    }

    createForm(): void {
        this.formGroupFixType = this.fb.group({
            lessOrMuchEffect: ['0'],
            adjustClearance: ['0'],
            fixTypeSetOz: [''],
            fixTypeLateral: [''],
            fixTypeLow: [''],
            fixTypeHigh: [''],
            toMyopie: [''],
            convertDreamLite: [''],
            convertDreamLiteMC: [''],
            toMultiFocal: [''],
            multiFocalAddition: [
                this.getMultifocalAdditionValue(),
                this.multiFocalAdditionRequired ? Validators.required : null,
            ],
        });

        // Hook up the fixtype control changes with a debounce
        Object.keys(this.formGroupFixType.controls).forEach((key) => {
            this.formGroupFixType.controls[key].valueChanges.pipe(debounceTime(500)).subscribe(() => {
                this.fitlens.applyFixTypes(this.formGroupFixType);
            });
        });
    }

    getMultifocalAdditionValue(): number {
        let nearAdd = this.fitlens.refractionMeasurement.NearAdd;

        if (nearAdd > 1.5) {
            nearAdd = 1.5;
        }

        return nearAdd;
    }

    async loadFixTypes(): Promise<void> {
        const result = await lastValueFrom(
            this.dreamLiteWizardService.getAllFixTypes(this.fitlens.fittedLens.LensDefinitionId),
        );

        this.fixTypes = new Array<DreamLiteWizardFixType>();
        this.fixTypeRanges = {};

        result.forEach((item) => {
            item.Visible = true;
            item.DegreeValue = 2;
            item.CorrectionValue = 0;
            item.IsApplied = false;

            if (item.ShowCorrection) {
                //alleen bij sagitta/Adjustclearance input number
                const rangeModel = new InputNumberRange(item.MinValue, item.MaxValue, item.Step);
                this.fixTypeRanges[item.Code] = new Array<InputNumberRange>(rangeModel);
            }

            const fixTypes = [
                'HighRiding',
                'LowRiding',
                'LateralDecentration',
                'LessOrMuchEffect',
                'AdjustClearance',
                'SetOZ',
                'ToMyopie',
                'ToMultifocal',
                'ConvertDreamlite',
                'ConvertDreamliteMC',
            ];

            if (fixTypes.find((i) => item.Code === i) != null) {
                switch (item.Code) {
                    case 'LessOrMuchEffect':
                        item.Visible = false;
                        this.lessOrMuchFixType = structuredClone(item);
                        break;
                    case 'AdjustClearance':
                        this.adjustClearanceFixType = structuredClone(item);
                        break;
                    case 'SetOZ':
                        item.Visible = false;
                        this.ozFixType = structuredClone(item);
                        break;
                    case 'HighRiding':
                        this.highRidingFixType = structuredClone(item);
                        break;
                    case 'LowRiding':
                        this.lowRidingFixType = structuredClone(item);
                        break;
                    case 'LateralDecentration':
                        this.lateralFixType = structuredClone(item);
                        break;
                    case 'ConvertDreamlite':
                        this.dreamliteFixType = structuredClone(item);
                        break;
                    case 'ConvertDreamliteMC':
                        this.dreamliteFixTypeMC = structuredClone(item);
                        break;
                }

                this.fixTypes.push(item);
            } else {
                item.Visible = false;
            }
        });
    }

    clickMeasurementImage(event: MouseEvent, measurement: TopographicMeasurement): void {
        event.stopPropagation();

        this.loaderService.show();

        const requestModel = new GetTopographicMeasurementsByClientIdRequest();
        requestModel.clientId = this.fitlens.client.Id;
        requestModel.pageSize = 999;
        requestModel.pageIndex = 0;
        requestModel.loadLeftTopographicMeasurements = measurement.EyeSideId === EyeSides.Os;
        requestModel.loadRightTopographicMeasurements = measurement.EyeSideId === EyeSides.Od;

        this.topographicMeasurementService
            .getTopographicMeasurementsByClientId(requestModel)
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe((result) => {
                const topoMeasurements =
                    measurement.EyeSideId === EyeSides.Od
                        ? result.RightTopographicMeasurements
                        : result.LeftTopographicMeasurements;
                this.showMeasurementReviewDialog(measurement, topoMeasurements);
            });
    }

    showMeasurementReviewDialog(measurement: TopographicMeasurement, topoMeasurements: TopographicMeasurement[]): void {
        const imageOptions = structuredClone(this.fitlens.diffMapImageOptions);
        imageOptions.ShowImageChoice = true;
        imageOptions.ShowImageTypes = false;

        const options: unknown = {
            initialState: {
                currentMeasurement: measurement,
                topoMeasurements: topoMeasurements,
                imageOptions: imageOptions,
                client: this.fitlens.client,
            },
            class: 'measurement-review-dialog',
        };

        this.modalService.show(MeasurementReviewDialogComponent, options);
    }

    reset(): void {
        this.formGroupFixType.controls['lessOrMuchEffect'].patchValue('0', {
            emitEvent: false,
        });
        this.formGroupFixType.controls['adjustClearance'].patchValue('0', {
            emitEvent: false,
        });
        this.formGroupFixType.controls['fixTypeSetOz'].patchValue('', {
            emitEvent: false,
        });
        this.formGroupFixType.controls['fixTypeLateral'].patchValue('', {
            emitEvent: false,
        });
        this.formGroupFixType.controls['fixTypeLow'].patchValue('', {
            emitEvent: false,
        });
        this.formGroupFixType.controls['fixTypeHigh'].patchValue('', {
            emitEvent: false,
        });
        this.formGroupFixType.controls['toMyopie'].patchValue('', {
            emitEvent: false,
        });
        this.formGroupFixType.controls['convertDreamLite'].patchValue('', {
            emitEvent: false,
        });
        this.formGroupFixType.controls['convertDreamLiteMC'].patchValue('', {
            emitEvent: false,
        });
        this.formGroupFixType.controls['toMultiFocal'].patchValue('', {
            emitEvent: false,
        });
        this.formGroupFixType.controls['multiFocalAddition'].patchValue(this.getMultifocalAdditionValue(), {
            emitEvent: false,
        });
    }

    convertToMc(): void {
        // this will trigger the valueChanges action, which will execute applyFixTypes
        this.formGroupFixType.controls['toMyopie'].patchValue(true);
    }

    convertToMf(): void {
        // this will trigger the valueChanges action, which will execute applyFixTypes
        this.formGroupFixType.controls['fixTypeSetOz'].patchValue(false);
        this.formGroupFixType.controls['toMultiFocal'].patchValue(true);
        this.fitlens.multiFocalAdditionRequired = true;
        this.formGroupFixType.controls['multiFocalAddition'].setValidators(Validators.required);
        this.formGroupFixType.controls['multiFocalAddition'].updateValueAndValidity();
        this.formGroupFixType.controls['multiFocalAddition'].patchValue(this.getMultifocalAdditionValue());
    }

    convertToDreamlite(): void {
        this.formGroupFixType.controls['convertDreamLite'].patchValue(true);
    }

    convertToDreamliteMC(): void {
        this.formGroupFixType.controls['convertDreamLiteMC'].patchValue(true);
    }

    setSelectedRidingFixType(type: 'low' | 'high'): void {
        this.selectedRidingFixType = this.selectedRidingFixType === type ? null : type;
    }

    get multiFocalAddition(): number {
        return Number(this.formGroupFixType.controls['multiFocalAddition'].value);
    }

    get id(): string {
        return this.fitlens.id;
    }

    get isMultifocal(): boolean {
        return this.fitlens.isMultifocal;
    }

    get isDreamLiteFollowUp(): boolean {
        return this.fitlens.isDreamLiteFollowUp;
    }

    get isFixTypeRangesReady(): boolean {
        return this.fixTypeRanges && Object.keys(this.fixTypeRanges).length !== 0;
    }
}
