import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { NavbarItem } from '@app/shared/models/navbarItem.model';

@Component({
    selector: 'mpc-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    @Input() navbaritems: NavbarItem[];
    @Output() switchPage: EventEmitter<unknown> = new EventEmitter();

    active = 0;
    navbaritemTitle: string;

    ngOnInit(): void {
        this.navbaritemTitle = this.navbaritems.length > 0 ? this.navbaritems[0].title : '';
    }

    onNavbarItem(navbaritem, index): void {
        this.active = index;
        this.navbaritemTitle = navbaritem.title;
        this.switchPage.emit(this.navbaritemTitle);
    }
}
