<div [formGroup]="lensSelectionFormGroup">
    <mpc-select
        *ngIf="lensSelectionEnabled"
        formControlName="lensDefinitionId"
        [markLabelAsTouched]="fitlens.isDreamLiteFollowUp"
        id="{{ id }}-lensDefinition"
        [items]="filteredLensDefinitions"
        label="general.lens"
        [labelWidth]="2"
        [isDisabled]="formDisabled"
        (change)="changeLensDefinition()"></mpc-select>

    <mpc-model-label
        *ngIf="
            !lensSelectionEnabled &&
            !(showConvertToDreamliteButton || showConvertToDreamliteMCButton) &&
            !(showConvertToMcButton || showConvertToMfButton)
        "
        label="general.lens"
        [value]="fittedLens.LensDefinition.Name"
        [labelWidth]="2"></mpc-model-label>

    <div class="spinner-border spinner-border-sm" *ngIf="loading"></div>

    <div *ngIf="!loading">
        <div
            *ngIf="
                !lensSelectionEnabled &&
                (showConvertToMcButton || showConvertToMfButton || showConvertToDreamliteButton)
            "
            class="row align-items-center">
            <div class="col-12 col-sm-2">
                {{ 'general.lens' | translate }}
            </div>
            <div class="col-12 col-sm-10">
                <div class="d-flex align-items-center">
                    <div>{{ fitlens.fittedLens.LensDefinition.Name }}</div>
                    <div class="ps-2 ms-auto mx-md-auto">
                        <button
                            *ngIf="showConvertToMcButton && fitlens.myopieFixtype"
                            class="btn btn-default"
                            [id]="id + '-converttomc'"
                            (click)="onConvertToMc()">
                            {{ fitlens.myopieFixtype.Name }}
                        </button>
                        <button
                            *ngIf="showConvertToMfButton && fitlens.multiFocalFixType"
                            class="btn btn-default"
                            [id]="id + '-converttomf'"
                            (click)="onConvertToMf()"
                            popover="{{ getSupportPopupText() }}"
                            placement="top"
                            triggers="mouseenter:mouseleave">
                            <i class="fa fa-question-circle"></i>
                            {{ fitlens.multiFocalFixType.Name }}
                        </button>
                        <button
                            *ngIf="showConvertToDreamliteButton"
                            class="btn btn-default"
                            [id]="id + '-converttodreamlite'"
                            (click)="onConverToDreamlite()">
                            {{ fitlens.dreamLiteFixType?.Name }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="fittedLens && lensSelectionEnabled">
            <div
                class="form-group row"
                *ngIf="options.ShowMaterialField && !isLensDefinitionDisposable && materials.length !== 0">
                <label class="col-form-label col-sm-2">{{ 'general.material' | translate }}</label>
                <div class="col-sm-5">
                    <mpc-select
                        *ngIf="materials.length > 1"
                        formControlName="materialId"
                        id="{{ id }}-material"
                        [items]="materials"
                        [formControlOnly]="true"
                        [isDisabled]="formDisabled"
                        (change)="changeMaterial($event)"></mpc-select>

                    <mpc-model-label
                        *ngIf="materials.length === 1"
                        id="{{ id }}-material"
                        [value]="materials[0].Name"
                        formControlOnly="true"></mpc-model-label>
                </div>
                <div class="col-sm-5 ps-sm-1 mt-1 mt-sm-0">
                    <mpc-select
                        formControlName="colorId"
                        id="{{ id }}-color"
                        [items]="colors"
                        [formControlOnly]="true"
                        [isDisabled]="formDisabled"
                        (change)="changeColor()"></mpc-select>
                </div>
            </div>

            <ng-container *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']">
                <div class="form-group row" *ngIf="canShowMarkings">
                    <label
                        class="col-form-label col-sm-2"
                        [ngClass]="{
                            error: formControls['lensEngravingId'].errors
                        }">
                        {{ 'fitlens.lensengraving' | translate }}
                    </label>
                    <div class="col-sm-5">
                        <mpc-select
                            formControlName="lensEngravingId"
                            id="{{ id }}-lensEngraving"
                            [items]="lensEngravings"
                            [allowNoSelection]="true"
                            [formControlOnly]="true"
                            [isDisabled]="formDisabled"
                            (change)="changeLensEngraving()"></mpc-select>
                    </div>
                </div>
            </ng-container>

            <div class="form-group row" *ngIf="canShowThickness">
                <label
                    class="col-form-label col-sm-2"
                    [ngClass]="{
                        error: formControls['lensThicknessId'].errors
                    }">
                    {{ 'general.lensThickness' | translate }}
                </label>
                <div class="col-sm-5">
                    <mpc-select
                        formControlName="lensThicknessId"
                        id="{{ id }}-lensThickness"
                        [items]="lensThickness"
                        [formControlOnly]="true"
                        [isDisabled]="formDisabled"
                        (change)="changeThickness()"></mpc-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="maxQuantity > 1 && !isWebshop">
                <label class="col-form-label col-sm-2">{{ 'general.quantity' | translate }}</label>
                <div class="col-sm-10">
                    <mpc-input-number
                        formControlName="quantity"
                        [formControlOnly]="true"
                        id="{{ id }}-quantity"
                        [ranges]="[quantityRange]"
                        [maxLength]="2"
                        [label]="'general.quantity'"
                        (change)="changeQuantity()"></mpc-input-number>
                </div>
            </div>
        </div>
    </div>
</div>
