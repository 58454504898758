<div class="modal-header">
    <h4 class="modal-title m-auto">{{ 'fitlens.resettitle' | translate }}</h4>
</div>
<div class="modal-body">
    <div>
        <span>{{ 'fitlens.resetcontent' | translate }}</span>
    </div>
</div>
<div class="modal-footer accented-background d-flex justify-content-start">
    <button type="button" id="cancel" class="btn btn-default align-self-start" (click)="bsModalRef.hide()">
        {{ 'general.close' | translate }}
    </button>
    <div class="ms-auto">
        <button
            *ngIf="isRightEyeSideSelected"
            type="button"
            id="reset-od"
            class="btn btn-primary"
            (click)="reset(eyeSides.Od)">
            {{ 'general.od' | translate }}
        </button>
        <button
            *ngIf="isLeftEyeSideSelected"
            type="button"
            id="reset-os"
            class="btn btn-primary mx-1"
            (click)="reset(eyeSides.Os)">
            {{ 'general.os' | translate }}
        </button>
        <button
            *ngIf="isRightEyeSideSelected && isLeftEyeSideSelected"
            type="button"
            id="reset-both"
            class="btn btn-primary"
            (click)="reset(null)">
            {{ 'general.both' | translate }}
        </button>
    </div>
</div>
