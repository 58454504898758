import { Injectable } from '@angular/core';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { RefractionFieldsConfiguration } from '@app/shared/helpers/refraction-fields-configuration';

@Injectable()
export class RefractionFieldsConfigurationService {
    private _configuration: RefractionFieldsConfiguration;

    constructor(private appStateService: AppStateService) {
        switch (this.appStateService.currentCompany.RefractionConfiguration) {
            case 'RefractionConfiguration1':
                this._configuration = RefractionFieldsConfiguration.deviatedCorneaSettings();
            default:
                this._configuration = RefractionFieldsConfiguration.defaultRanges();
        }
    }

    get configuration(): RefractionFieldsConfiguration {
        return this._configuration;
    }
}
