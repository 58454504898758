import { FittedLens, LensDefinitionParameterNumberRange, LensDefinitionParameterRange } from '@app/shared/models';
import { FittedLensService } from '@app/core/services/api/fitted-lens.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class FieldValueExpressionCalculator {
    constructor(private fittedLensService: FittedLensService) {}

    public recalculateFieldValueExpressions(
        fittedLens: FittedLens,
        changedParameterCodes: string[],
    ): Observable<FittedLens> {
        return this.fittedLensService.recalculateFieldValueExpressions(fittedLens, changedParameterCodes).pipe(
            switchMap((result) => {
                fittedLens.FittedLensParameters.forEach((flp) => {
                    const param = result.FittedLensParameters.find(
                        (rpr) =>
                            rpr.LensDefinitionParameter.ParameterType.Code ===
                            flp.LensDefinitionParameter.ParameterType.Code,
                    );

                    if (param) {
                        flp.Value = param.Value;
                        flp.TextValue = param.TextValue;
                    }
                });

                return of(fittedLens);
            }),
        );
    }

    public static toFixed(number: number, precision: number) {
        const multiplier = Math.pow(10, precision);
        return Math.round(number * multiplier) / multiplier;
    }

    public adjustParameterValue(value: number, ranges: LensDefinitionParameterRange[]): number {
        const numberRanges = ranges as LensDefinitionParameterNumberRange[];
        const closestValues: number[] = [];
        let ii: number;

        for (ii = 0; ii < numberRanges.length; ii++) {
            const max = numberRanges[ii].Maximum;
            const min = numberRanges[ii].Minimum;
            const step = numberRanges[ii].Step;

            if (value > max) {
                closestValues.push(max);
            } else if (value < min) {
                closestValues.push(min);
            } else {
                const rounded = FieldValueExpressionCalculator.toFixed(
                    min + Math.round((value - min) / step) * step,
                    2,
                );
                closestValues.push(rounded);
            }
        }

        let closestValue = 999;
        let mindiff = -1;

        for (ii = 0; ii < closestValues.length; ii++) {
            const val = closestValues[ii];
            const diff = Math.abs(value - val);

            if (ii === 0 || diff < mindiff) {
                closestValue = val;
                mindiff = diff;
            }
        }

        return closestValue;
    }
}
