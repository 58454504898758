<div class="form-group row" *ngIf="!formControlOnly">
    <label for="{{ id }}" class="col-form-label" [ngClass]="labelClass">
        <i *ngIf="info" class="fa fa-question-circle" popover="{{ info }}" triggers="mouseenter:mouseleave"></i>
        {{ label | translate: labelParams }}
    </label>
    <div [ngClass]="controlClass" class="mt-1">
        <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
    </div>
</div>

<div *ngIf="formControlOnly" [ngClass]="directionClass">
    <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
</div>

<ng-template #controlTemplate>
    <ng-container *ngFor="let o of _options; let i = index">
        <div class="form-check" [ngClass]="innerControlClass">
            <input
                type="radio"
                id="{{ id }}-{{ i }}"
                name="{{ id }}-group"
                class="form-check-input"
                [checked]="o.Selected"
                [value]="o.Id"
                (change)="onSelect(o)"
                [disabled]="isDisabled" />
            <label *ngIf="transkey" id="labelfor-{{ id }}-{{ i }}" class="form-check-label" for="{{ id }}-{{ i }}">
                {{ transkey + '.' + o.Code | translate }}
            </label>
            <label *ngIf="!transkey" id="labelfor-{{ id }}-{{ i }}" class="form-check-label" for="{{ id }}-{{ i }}">
                {{ o.Name }}
            </label>
        </div>
        <div *ngIf="maxItemsFirstRow > 0 && i === maxItemsFirstRow" style="width: 100%; margin-top: 6px"></div>
    </ng-container>
</ng-template>
