<div [formGroup]="formGroup" class="row">
    <mpc-select
        formControlName="languageId"
        [id]="id + 'languageId'"
        [items]="languages"
        label="general.language"
        focus></mpc-select>

    <div formArrayName="lang" class="col-12">
        <div *ngFor="let lv of languageValueControls.controls; index as i">
            <div *ngIf="selectedLanguageId === languages[i].Id">
                <ng-container *ngIf="isTextarea; else textbox">
                    <mpc-input-textarea
                        [formControlName]="i"
                        [id]="getId(i)"
                        [label]="(label | translate) + ' (' + languages[i].Name + ')'"
                        [maxLength]="maxLength"
                        [isDisabled]="isDisabled"></mpc-input-textarea>
                </ng-container>
                <ng-template #textbox>
                    <mpc-input-text
                        [formControlName]="i"
                        [id]="getId(i)"
                        [label]="(label | translate) + ' (' + languages[i].Name + ')'"
                        [maxLength]="maxLength"
                        [isDisabled]="isDisabled"
                        [info]="info"
                        [infoPosition]="infoPosition"></mpc-input-text>
                </ng-template>
            </div>
        </div>
    </div>
</div>
