import { BaseValidator } from '@app/shared/validators/BaseValidator';
import { ValidationErrors, FormArray } from '@angular/forms';
import { LensTypes, ParameterTypeCodes } from '@app/shared/enums';
import { FitLensComponent } from '../fitlens.component';

export class DreamliteRc2ValueShouldBeLessOrEqualToAc2Validator extends BaseValidator {
    bind(obj: FitLensComponent): ValidationErrors {
        if (obj.formGroup && obj.fittedLensParameters && obj.lensTypeId === LensTypes.DreamLite) {
            const params = obj.formGroup.controls['parameters'] as FormArray;

            const ac2 = params.controls[obj.getFittedLensParameterIndex(ParameterTypeCodes.AC2)];
            const rc2 = params.controls[obj.getFittedLensParameterIndex(ParameterTypeCodes.RC2)];

            if (ac2 && rc2 && ac2.value <= rc2.value) {
                return { dreamliteRc2ValueShouldBeLessOrEqualThanAc2: true };
            }
        }

        return null;
    }
}
