import { LensDefinitionGeometryElement } from '@app/shared/models/lensDefinitionGeometryElement.model';

export class LensDefinitionGeometryLine extends LensDefinitionGeometryElement {
    constructor() {
        super();
        this.$type = 'LensDefinitionGeometryLine';
        this.InstanceType = 'Line';
    }

    public LineSemiDiameterStart: string;
    public LineSemiDiameterEnd: string;

    // CalculationResults
    public LineSemiDiameterStartResult: number;
    public LineSemiDiameterEndResult: number;
}
