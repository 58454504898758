import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticatedUser } from '@app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from '@app/core/services/api/authentication.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { AlertService } from '@app/shared/appservices/alert.service';
import { LoaderService } from '@app/shared/appservices/loader.service';

@Injectable()
export class CurrentRoleAuthGuard {
    redirectUrl = null;
    get CurrentAuthenticatedUser(): AuthenticatedUser {
        return this.appState.authenticatedUser;
    }

    constructor(
        public router: Router,
        public appState: AppStateService,
        public alertService: AlertService,
        public translateService: TranslateService,
        public authenticationService: AuthenticationService,
        public loaderService: LoaderService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.redirectUrl = state.url ? state.url : null;

        if (this.appState.authenticatedUser && this.appState.authenticatedUser.CurrentRoleId) {
            return of(this.checkCurrentRole(route));
        } else {
            if (this.appState.authenticatedUser && this.appState.authenticatedUser.AccessToken) {
                this.loaderService.show();

                return this.authenticationService.validateLogin().pipe(
                    switchMap((result: boolean) => {
                        this.loaderService.hide();

                        if (result) {
                            return of(this.checkCurrentRole(route));
                        } else {
                            this.redirectToLogin();
                            return of(false);
                        }
                    }),
                );
            } else {
                this.redirectToLogin();
                return of(false);
            }
        }
    }

    checkCurrentRole(route: ActivatedRouteSnapshot): boolean {
        let result = false;

        if (
            this.appState.authenticatedUser.CurrentRoleId &&
            this.appState.authenticatedUser.Roles &&
            this.appState.authenticatedUser.Roles.length > 0 &&
            route.data['role']
        ) {
            const roleId = route.data['role'] as number;
            result = this.appState.authenticatedUser.CurrentRoleId === roleId;
        }

        if (!result) {
            this.redirectToLogin();
        }

        return result;
    }

    private redirectToLogin(): void {
        let extraParams = {};
        if (this.redirectUrl) {
            extraParams = { redirect: this.redirectUrl };
        }
        this.router.navigate(['login', extraParams]).then();
    }
}
