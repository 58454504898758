<div id="measurement-histories" [formGroup]="formGroup">
    <div *ngIf="appState.isMobile" class="my-2">
        <div class="spinner-border spinner-border-sm" *ngIf="isLoading"></div>

        <tabset [justified]="true" *ngIf="!isLoading">
            <tab id="tab-od" class="p-2">
                <ng-template tabHeading>
                    <h4 class="text-left">{{ 'general.od' | translate }}</h4>
                </ng-template>
                <div *ngIf="!isLoading">
                    <ng-container *ngTemplateOutlet="measurementsOD"></ng-container>
                </div>
            </tab>
            <tab id="tab-os" class="p-2">
                <ng-template tabHeading>
                    <h4 class="text-left">{{ 'general.os' | translate }}</h4>
                </ng-template>
                <div *ngIf="!isLoading">
                    <ng-container *ngTemplateOutlet="measurementsOS"></ng-container>
                </div>
            </tab>
        </tabset>
    </div>

    <div *ngIf="!appState.isMobile">
        <div class="spinner-border spinner-border-sm" *ngIf="isLoading"></div>
        <div *ngIf="!isLoading" class="row">
            <div class="col-6 pe-2">
                <div class="pb-1">
                    <h4>{{ 'general.od' | translate }}</h4>
                </div>
                <ng-container *ngTemplateOutlet="measurementsOD"></ng-container>
            </div>
            <div class="col-6 ps-2">
                <div class="pb-1">
                    <h4>{{ 'general.os' | translate }}</h4>
                </div>
                <ng-container *ngTemplateOutlet="measurementsOS"></ng-container>
            </div>
        </div>
    </div>

    <ng-template #measurementsOD>
        <div *ngIf="(rightHistoricalMeasurements$ | async).length > 0; else noMeasurementsOD">
            <div class="mb-2" *ngFor="let hm of rightHistoricalMeasurements$ | async; let i = index">
                <measurement-history-item
                    id="tm-od-{{ i }}"
                    [index]="i"
                    [eyeSide]="'od'"
                    [measurementHistory]="this"
                    [historicalMeasurement]="hm"
                    [imageOptions]="imageOptions"
                    [inDeleteMode]="inDeleteMode"
                    [selectedRefractionMeasurement]="rightSelectedRefractionMeasurement"
                    [displayBaselineTag]="IsBaseLine(hm)"
                    [client]="client"
                    (measurementSelected)="rightMeasurementSelected($event)"></measurement-history-item>
            </div>
        </div>
        <ng-template #noMeasurementsOD>
            <div class="my-2">
                {{ 'measurement.nomeasurements' | translate }}
            </div>
        </ng-template>
    </ng-template>

    <ng-template #measurementsOS>
        <div *ngIf="(leftHistoricalMeasurements$ | async).length > 0; else noMeasurementsOS">
            <div class="mb-2" *ngFor="let hm of leftHistoricalMeasurements$ | async; let i = index">
                <measurement-history-item
                    id="tm-os-{{ i }}"
                    [index]="i"
                    [eyeSide]="'os'"
                    [measurementHistory]="this"
                    [historicalMeasurement]="hm"
                    [imageOptions]="imageOptions"
                    [inDeleteMode]="inDeleteMode"
                    [selectedRefractionMeasurement]="leftSelectedRefractionMeasurement"
                    [displayBaselineTag]="IsBaseLine(hm)"
                    [client]="client"
                    (measurementSelected)="leftMeasurementSelected($event)"></measurement-history-item>
            </div>
        </div>
        <ng-template #noMeasurementsOS>
            <div class="my-2">
                {{ 'measurement.nomeasurements' | translate }}
            </div>
        </ng-template>
    </ng-template>
</div>
