import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { Treatments } from '@app/shared/enums';
import { ProposalReceiptInfo } from '@app/shared/models/proposalReceiptInfo.model';
import { ProposalReceiptService } from '@app/core/services/api/proposal-receipt.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Util } from '@app/shared/helpers/utility.helper';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'receipt-details-dialog',
    templateUrl: './receipt-details-dialog.component.html',
    styleUrls: ['receipt-details-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ReceiptDetailsDialogComponent {
    @Input() receipt: ProposalReceiptInfo;

    constructor(
        public bsModalRef: BsModalRef,
        public loaderService: LoaderService,
        public translateService: TranslateService,
        public proposalReceiptService: ProposalReceiptService,
    ) {}

    downloadPdf() {
        this.loaderService.show();
        this.proposalReceiptService
            .downloadPdf(this.receipt.Id)
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe((file) => {
                Util.openBlobInBrowser(file, file.name);
            });
    }

    downloadShipmentFramePdf() {
        this.loaderService.show();
        this.proposalReceiptService
            .downloadShipmentFramePdf(this.receipt.Id)
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe((file) => {
                Util.openBlobInBrowser(file, file.name);
            });
    }

    getStatusTranslationKey(): string {
        return this.receipt.IsOrdered ? 'ordered' : 'not ordered';
    }

    getGlassTreatmentName(glassTreatmentId: Treatments): string {
        switch (glassTreatmentId) {
            case Treatments.none:
                return this.translateService.instant('general.none');
            case Treatments.remoteEdging:
                return this.translateService.instant('fitglass.remoteEdging');
            case Treatments.remoteEdgingAndFitting:
                return this.translateService.instant('fitglass.remoteEdgingAndFitting');
        }
    }

    hasEdgingServiceNone(): boolean {
        return this.receipt?.GlassTreatmentId === Treatments.none;
    }

    hasEdgingServiceRemoteEdging(): boolean {
        return this.receipt?.GlassTreatmentId === Treatments.remoteEdging;
    }

    hasEdgingServiceRemoteEdgingAndFitting(): boolean {
        return this.receipt?.GlassTreatmentId === Treatments.remoteEdgingAndFitting;
    }

    hasEdgingService(): boolean {
        return (
            this.receipt?.GlassTreatmentId === Treatments.remoteEdging ||
            this.receipt.GlassTreatmentId === Treatments.remoteEdgingAndFitting
        );
    }
}
