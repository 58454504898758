<div class="modal-header">
    <h4 class="modal-title m-auto">
        {{ 'shop.trainingCompleteDisclaimerTitle' | translate }}
    </h4>
</div>

<div class="modal-body px-2">
    <p>
        {{ 'shop.trainingCompleteDisclaimer' | translate }}
    </p>
</div>

<div class="modal-footer accented-background justify-content-between">
    <div class="ms-auto">
        <button type="button" (click)="onConfirm()" class="btn btn-success">
            {{ 'general.ok' | translate }}
        </button>

        <button type="button" (click)="onClose()" class="btn btn-danger">
            {{ 'general.close' | translate }}
        </button>
    </div>
</div>
