import { LensDefinitionGeometryElement } from '@app/shared/models/lensDefinitionGeometryElement.model';

export class LensDefinitionGeometryEdgeTan extends LensDefinitionGeometryElement {
    constructor() {
        super();
        this.$type = 'LensDefinitionGeometryEdgeTan';
        this.InstanceType = 'EdgeTan';
    }

    public EdgeTanRadius: string;
    public EdgeTanAstigmaticRadius: string;
    public EdgeTanSemiDiameterEnd: string;

    // CalculationResults
    public EdgeTanRadiusResult: number;
    public EdgeTanAstigmaticRadiusResult: number;
    public EdgeTanSemiDiameterEndResult: number;
}
