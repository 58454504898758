export class LensReplacements {
    DREAMLITE: number;
    BYO: number;
    MiSight: number;

    constructor() {
        this.DREAMLITE = 0;
        this.BYO = 0;
        this.MiSight = 0;
    }
}
