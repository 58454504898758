export class Serial {
    Id: number;

    Number: string;

    OpticianReference: string;

    CustomerName: string;

    Reference: string;

    ArticleNumber: string;

    ArticleName: string;

    MaterialColorCode: string;

    MaterialColorName: string;

    OrderDate: Date;

    AllowanceCode: string;

    AllowanceCode2: string;

    AllowanceName: string;

    AllowanceName2: string;

    SalesOrderNumber: number;

    Pow: number;
    Add: number;
    Prof: number;
    Cyl: number;
    As: number;
    Rad1: number;
    Rad2: number;
    Rc: number;
    Ac: number;
    Exc: number;
    E1: number;
    Rc2: number;
    Ac2: number;
    E2: number;
    Diam: number;
    Trun: number;
    Stab: number;
    Pris: number;
    Oz: number;
    Ver: number;
    BcrAdd: number;
    Seg: number;
    Perifa: number;
    Perifa2: number;
    EInf: number;
    Sag: number;
    Tori: number;
    Zf: number;
    Lf: number;
    SclRad1: number;
    SclRad2: number;
    Sagmq: number;
    Exch: number;
    Excv: number;
    Type: number;

    ProductType: string;
}
