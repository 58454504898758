export class SaveFittingContinuationConfiguration {
    constructor(
        private canSaveAction: () => boolean,
        private saveStepAction: () => Promise<unknown>,
    ) {}

    isSaveEnabled(): boolean {
        return this.canSaveAction();
    }

    async save() {
        await this.saveStepAction();
    }
}
