<div class="spinner-border spinner-border-sm" *ngIf="loading"></div>

<ng-container *ngIf="!loading">
    <div *ngFor="let note of notes" class="mb-2 row">
        <div class="mb-3 col-12 col-lg-5">
            <strong>{{ note.Name }}, {{ note.IsCreatedOn | mpcdate }} {{ note.IsCreatedOn | date: 'H:mm' }}</strong>
            <div class="notes-message">{{ note.Message }}</div>
        </div>
    </div>
</ng-container>
