<div class="form-group row" *ngIf="!formControlOnly" [ngClass]="customClass">
    <label *ngIf="label" for="{{ id }}" class="col-form-label my-auto" [ngClass]="labelClass">
        <i
            *ngIf="info && infoPosition === 'left'"
            class="fa fa-question-circle"
            popover="{{ info }}"
            triggers="mouseenter:mouseleave"></i>
        {{ label | translate: labelParams }}
        <i
            *ngIf="info && infoPosition === 'right'"
            class="fa fa-question-circle"
            popover="{{ info }}"
            triggers="mouseenter:mouseleave"></i>
    </label>
    <div [ngClass]="controlClass" class="d-flex align-items-center">
        <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
    </div>
</div>

<div *ngIf="formControlOnly">
    <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
</div>

<ng-template #controlTemplate>
    <div class="input-group">
        <select
            #select
            class="form-control"
            [id]="id"
            [(ngModel)]="value"
            (change)="propagateChange(value)"
            [attr.required]="isRequired"
            [disabled]="isDisabled"
            tabindex="0">
            <option *ngIf="noSelectionLabel || allowNoSelection" value="">
                {{ noSelectionLabel | translate }}
            </option>
            <option *ngFor="let item of items; trackBy: customTrackBy" [value]="item.Id">
                {{ translationKey + item.Name | translate }}
            </option>
        </select>
        <div class="input-group-addon select-dropdown-icon" [ngClass]="isDisabled ? 'blocked' : 'open'">
            <i id="downIcon" class="fas fa-chevron-down me-1"></i>
        </div>
    </div>
</ng-template>
