import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EyeSideTuple, RefractionMeasurement, GetTopographicMeasurementByIdResult } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class RefractionMeasurementService {
    private readonly basePath = 'api/refraction-measurements';

    constructor(private readonly httpClient: HttpClient) {}

    public getTopographicMeasurementById(id: number): Observable<GetTopographicMeasurementByIdResult> {
        const options = {
            params: new HttpParams().set('Id', id.toString()),
        };

        return this.httpClient.get<GetTopographicMeasurementByIdResult>(
            `${this.basePath}/get-by-topographic-measurement`,
            options,
        );
    }

    public getRefractionMeasurementsByTopographicMeasurementIds(
        topographicMeasurementIds: number[],
    ): Observable<RefractionMeasurement[]> {
        const options = {
            params: new HttpParams().set('Ids', topographicMeasurementIds.toString()),
        };

        return this.httpClient.get<RefractionMeasurement[]>(
            `${this.basePath}/get-by-topographic-measurements`,
            options,
        );
    }

    public getPreviousRefractionMeasurementsOfClient(
        proposalId: number,
    ): Observable<EyeSideTuple<RefractionMeasurement>> {
        const options = {
            params: new HttpParams().set('proposalId', proposalId.toString()),
        };

        return this.httpClient.get<EyeSideTuple<RefractionMeasurement>>(`${this.basePath}/previous`, options);
    }

    public updateRefractionMeasurement(
        refractionMeasurement: RefractionMeasurement,
    ): Observable<RefractionMeasurement> {
        return this.httpClient.post<RefractionMeasurement>(`${this.basePath}/update`, refractionMeasurement);
    }

    public deleteRefractionMeasurement(refracId: number): Observable<void> {
        return this.httpClient.delete<void>(`${this.basePath}/${refracId}`);
    }
}
