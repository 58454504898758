<div *ngIf="!loading && lensDefinition">
    <div *ngIf="!useLimitedInfoView">
        <div class="m-4 d-flex justify-content-between">
            <div class="col-md-4 accented-background">
                <div class="m-2">
                    <div class="row">
                        <label class="col">{{ 'service.serialnumber' | translate }}:</label>
                        <p class="col">{{ serialNumber }}</p>
                    </div>
                    <div class="row">
                        <label class="col">{{ 'general.client' | translate }}:</label>
                        <p class="col">{{ returnQaConclusion.Client }}</p>
                    </div>
                    <div class="row">
                        <label class="col">{{ 'general.lens' | translate }}:</label>
                        <p class="col">{{ returnQaConclusion.Lens }}</p>
                    </div>
                    <div class="row">
                        <label class="col">{{ 'general.orderShippingDate' | translate }}:</label>
                        <p class="col">{{ orderShippingDate }}</p>
                    </div>
                </div>
            </div>

            <div class="col-md-4 accented-background">
                <div class="m-2">
                    <div class="row">
                        <label class="col">{{ 'general.qacontroller' | translate }}:</label>
                        <p class="col">{{ userName }}</p>
                    </div>
                    <div class="row">
                        <label class="col">{{ 'general.serviceRequestDate' | translate }}:</label>
                        <p class="col">{{ returnDateNow }}</p>
                    </div>
                    <div class="row">
                        <label class="col">{{ 'complaint.status' | translate }}:</label>
                        <p class="col">{{ getReturnStatusName() }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row m-4" *ngIf="lensDefinitionHasValues">
            <div class="me-3">
                <h3>{{ 'general.Menu.Lensparameters' | translate }}</h3>
            </div>
            <span *ngIf="lensDefinition.Pow">
                {{ 'general.abbreviations.POW' | translate | uppercase }}:
                {{ lensDefinition.Pow | nullWithDefault: '' }}
            </span>
            <span *ngIf="lensDefinition.Bcr">
                {{ 'general.abbreviations.BCR' | translate | uppercase }}:
                {{ lensDefinition.Bcr | nullWithDefault: '' }}
            </span>
            <span *ngIf="lensDefinition.Diam">
                {{ 'general.abbreviations.DIAM' | translate | uppercase }}:
                {{ lensDefinition.Diam | nullWithDefault: '' }}
            </span>
            <span *ngIf="lensDefinition.Oz">
                {{ 'general.abbreviations.OZ' | translate | uppercase }}: {{ lensDefinition.Oz | nullWithDefault: '' }}
            </span>
            <span *ngIf="lensDefinition.Cyl">
                {{ 'general.abbreviations.CYL' | translate | uppercase }}:
                {{ lensDefinition.Cyl | nullWithDefault: '' }}
            </span>
            <span *ngIf="lensDefinition.Ax">
                {{ 'general.abbreviations.AX' | translate | uppercase }}: {{ lensDefinition.Ax | nullWithDefault: '' }}
            </span>
            <span *ngIf="lensDefinition.Add">
                {{ 'general.abbreviations.ADD' | translate | uppercase }}:
                {{ lensDefinition.Add | nullWithDefault: '' }}
            </span>
            <span *ngIf="lensDefinition.Prism">
                {{ 'general.abbreviations.PRIS' | translate | uppercase }}:
                {{ lensDefinition.Prism | nullWithDefault: '' }}
            </span>
            <span *ngIf="lensDefinition.Sag">
                {{ 'general.abbreviations.SAG' | translate | uppercase }}:
                {{ lensDefinition.Sag | nullWithDefault: '' }}
            </span>
            <span *ngIf="lensDefinition.Stab">
                {{ 'general.abbreviations.STAB' | translate | uppercase }}:
                {{ lensDefinition.Stab | nullWithDefault: '' }}
            </span>
            <span *ngIf="lensDefinition.LensThicknessId">
                {{ 'general.lensThickness' | translate | uppercase }}:
                {{ lensThicknesses[lensDefinition.LensThicknessId] | nullWithDefault: '' }}
            </span>
        </div>

        <div class="row m-4" *ngIf="lensDefinition.Material || lensDefinition.Color">
            <div class="me-3">
                <h3>{{ 'general.lensMaterial' | translate }}</h3>
            </div>
            <span *ngIf="lensDefinition.Material">{{ lensDefinition.Material | nullWithDefault: '' }}</span>
            <span>{{ 'general.color' | translate }} : {{ lensDefinition.Color | nullWithDefault: '' }}</span>
        </div>

        <hr />
    </div>

    <div [formGroup]="measurementsFormGroup" class="m-4">
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="form-group row mt-1 mb-1">
                    <label class="col-4 font-weight-bold">{{ 'service.lensservice' | translate }}</label>
                    <div class="col-4">
                        <mpc-select
                            formControlName="returnCategory"
                            [id]="'returnCategories'"
                            [formControlOnly]="true"
                            [items]="returnCategories"></mpc-select>
                    </div>
                </div>
                <div class="form-group row mt-1 mb-1" *ngIf="filteredReturnTypes$ | async; let filteredTypes">
                    <label class="col-4 font-weight-bold">{{ 'general.type' | translate }}</label>
                    <div class="col-4">
                        <mpc-select
                            formControlName="returnType"
                            [id]="'returnTypes'"
                            [formControlOnly]="true"
                            [items]="filteredTypes"></mpc-select>
                    </div>
                </div>
                <div class="form-group row mt-1 mb-1">
                    <label class="col-4 font-weight-bold">{{ 'general.description' | translate }}</label>
                    <div class="col-8">
                        {{ selectedReturn.Description }}
                    </div>
                </div>
            </div>
        </div>

        <hr />

        <h3 *ngIf="!useLimitedInfoView">{{ 'service.qualityControlMeasurementsTitle' | translate }}</h3>
        <div class="row">
            <div class="col-4">
                <div class="form-group row mb-2">
                    <label
                        class="col-form-label col-3"
                        [ngClass]="{ error: measurementsFormGroup.controls['bcr'].errors }">
                        {{ 'general.abbreviations.BCR' | translate | uppercase }}
                    </label>
                    <div class="col-6 mt-1">
                        <mpc-input-text formControlName="bcr" [id]="'bcr'" [formControlOnly]="true"></mpc-input-text>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="form-group row mb-2">
                    <label
                        class="col-form-label col-3"
                        [ngClass]="{ error: measurementsFormGroup.controls['bcr2'].errors }">
                        {{ 'general.abbreviations.BCR2' | translate | uppercase }}
                    </label>
                    <div class="col-6 mt-1">
                        <mpc-input-text formControlName="bcr2" [id]="'bcr2'" [formControlOnly]="true"></mpc-input-text>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group row mb-2">
                    <label
                        class="col-form-label col-3"
                        [ngClass]="{ error: measurementsFormGroup.controls['sag'].errors }">
                        {{ 'general.abbreviations.SAG' | translate | uppercase }}
                    </label>
                    <div class="col-6 mt-1">
                        <mpc-input-text formControlName="sag" [id]="'sag'" [formControlOnly]="true"></mpc-input-text>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <div class="form-group row mb-2">
                    <label
                        class="col-form-label col-3"
                        [ngClass]="{ error: measurementsFormGroup.controls['pow'].errors }">
                        {{ 'general.abbreviations.POW' | translate | uppercase }}
                    </label>
                    <div class="col-6 mt-1">
                        <mpc-input-text [id]="'pow'" formControlName="pow" [formControlOnly]="true"></mpc-input-text>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group row mb-2">
                    <label
                        class="col-form-label col-3"
                        [ngClass]="{ error: measurementsFormGroup.controls['oz'].errors }">
                        {{ 'general.abbreviations.OZ' | translate | uppercase }}
                    </label>
                    <div class="col-6 mt-1">
                        <mpc-input-text formControlName="oz" [id]="'oz'" [formControlOnly]="true"></mpc-input-text>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group row mb-2">
                    <label
                        class="col-form-label col-3"
                        [ngClass]="{ error: measurementsFormGroup.controls['readingSegment'].errors }">
                        {{ 'general.abbreviations.ReadingSegment' | translate | uppercase }}
                    </label>
                    <div class="col-6 mt-1">
                        <mpc-input-text
                            formControlName="readingSegment"
                            [id]="'readingSegment'"
                            [formControlOnly]="true"></mpc-input-text>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <div class="form-group row mb-2">
                    <label
                        class="col-form-label col-3"
                        [ngClass]="{ error: measurementsFormGroup.controls['cyl'].errors }">
                        {{ 'general.abbreviations.CYL' | translate | uppercase }}
                    </label>
                    <div class="col-6 mt-1">
                        <mpc-input-text formControlName="cyl" [id]="'cyl'" [formControlOnly]="true"></mpc-input-text>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group row mb-2">
                    <label
                        class="col-form-label col-3"
                        [ngClass]="{ error: measurementsFormGroup.controls['ax'].errors }">
                        {{ 'general.abbreviations.AX' | translate | uppercase }}
                    </label>
                    <div class="col-6 mt-1">
                        <mpc-input-text formControlName="ax" [id]="'ax'" [formControlOnly]="true"></mpc-input-text>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group row mb-2">
                    <label
                        class="col-form-label col-3"
                        [ngClass]="{ error: measurementsFormGroup.controls['prism'].errors }">
                        {{ 'general.abbreviations.PRIS' | translate | uppercase }}
                    </label>
                    <div class="col-6 mt-1">
                        <mpc-input-text
                            formControlName="prism"
                            [id]="'prism'"
                            [formControlOnly]="true"></mpc-input-text>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <div class="form-group row mb-2">
                    <label
                        class="col-form-label col-3"
                        [ngClass]="{ error: measurementsFormGroup.controls['add'].errors }">
                        {{ 'general.abbreviations.ADD' | translate | uppercase }}
                    </label>
                    <div class="col-6 mt-1">
                        <mpc-input-text formControlName="add" [id]="'add'" [formControlOnly]="true"></mpc-input-text>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group row mb-2">
                    <label
                        class="col-form-label col-3"
                        [ngClass]="{ error: measurementsFormGroup.controls['stab'].errors }">
                        {{ 'general.abbreviations.STAB' | translate | uppercase }}
                    </label>
                    <div class="col-6 mt-1">
                        <mpc-input-text formControlName="stab" [id]="'stab'" [formControlOnly]="true"></mpc-input-text>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group row mb-2">
                    <label
                        class="col-form-label col-3"
                        [ngClass]="{ error: measurementsFormGroup.controls['notch'].errors }">
                        {{ 'general.abbreviations.NOTCH' | translate | uppercase }}
                    </label>
                    <div class="col-6 mt-1">
                        <mpc-input-text
                            formControlName="notch"
                            [id]="'notch'"
                            [formControlOnly]="true"></mpc-input-text>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <div class="form-group row mb-2">
                    <label
                        class="col-form-label col-3"
                        [ngClass]="{ error: measurementsFormGroup.controls['diam'].errors }">
                        {{ 'general.abbreviations.DIAM' | translate | uppercase }}
                    </label>
                    <div class="col-6 mt-1">
                        <mpc-input-text formControlName="diam" [id]="'diam'" [formControlOnly]="true"></mpc-input-text>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="form-group row mb-2">
                    <label
                        class="col-form-label col-3"
                        [ngClass]="{ error: measurementsFormGroup.controls['cmd'].errors }">
                        {{ 'general.abbreviations.CMD' | translate | uppercase }}
                    </label>
                    <div class="col-6 mt-1">
                        <mpc-input-text formControlName="cmd" [id]="'cmd'" [formControlOnly]="true"></mpc-input-text>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="form-group row mb-2">
                    <label
                        class="col-form-label col-3"
                        [ngClass]="{ error: measurementsFormGroup.controls['trunc'].errors }">
                        {{ 'general.abbreviations.TRUNC' | translate | uppercase }}
                    </label>
                    <div class="col-6 mt-1">
                        <mpc-input-text
                            formControlName="trunc"
                            [id]="'trunc'"
                            [formControlOnly]="true"></mpc-input-text>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr />

    <div [formGroup]="lensPropertyFormGroup" class="m-4">
        <div class="row">
            <div class="col-8">
                <div class="form-group row mb-2">
                    <label class="col-form-label col-2">{{ 'general.surface' | translate }}</label>
                    <div class="col-10 mt-1">
                        <mpc-input-checkbox-list
                            formControlName="surfaces"
                            controlClass=""
                            [options]="surfaceOptions"
                            id="surfaces"
                            [formControlOnly]="true"></mpc-input-checkbox-list>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <div class="form-group row mb-2">
                    <label class="col-form-label col-2">{{ 'general.opticClear' | translate }}</label>
                    <div class="col-10 mt-1">
                        <mpc-input-radio-list
                            formControlName="opticIsClear"
                            [options]="opticIsClearOptions"
                            id="opticIsClear"
                            [formControlOnly]="true"></mpc-input-radio-list>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <div class="form-group row mb-2">
                    <label class="col-form-label col-2">{{ 'general.edge' | translate }}</label>
                    <div class="col-10 mt-1">
                        <mpc-input-checkbox-list
                            formControlName="edges"
                            controlClass=""
                            [options]="edgeOptions"
                            id="edges"
                            [formControlOnly]="true"></mpc-input-checkbox-list>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <div class="form-group row mb-2">
                    <label class="col-form-label col-2">{{ 'general.material' | translate }}</label>
                    <div class="col-10 mt-1">
                        <mpc-input-radio-list
                            formControlName="material"
                            [maxItemsFirstRow]="9"
                            [options]="materialOptions"
                            [label]="'general.material'"
                            id="materials"
                            [formControlOnly]="true"></mpc-input-radio-list>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <div class="form-group row mb-2">
                    <label class="col-form-label col-2">{{ 'general.color' | translate }}</label>
                    <div class="col-10 mt-1">
                        <mpc-input-radio-list
                            formControlName="color"
                            [options]="colorOptions"
                            [label]="'general.color'"
                            id="colors"
                            [formControlOnly]="true"></mpc-input-radio-list>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [formGroup]="measurementsFormGroup" class="m-4">
        <div class="row">
            <div class="col-8">
                <div class="form-group row mb-2">
                    <label class="col-form-label col-2">
                        {{ 'complaintsoverview.returnsummary.Additional' | translate }}
                    </label>
                    <div class="col-10 mt-1">
                        <mpc-input-textarea formControlName="additional" [formControlOnly]="true"></mpc-input-textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <div class="form-group row mb-2">
                    <label class="col-form-label col-2">{{ 'general.productionError' | translate }}</label>
                    <div class="col-10 mt-1">
                        <mpc-input-radio-list
                            formControlName="productionError"
                            [options]="productionErrorOptions"
                            id="productionError"
                            [formControlOnly]="true"></mpc-input-radio-list>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="attachments && !useLimitedInfoView" class="row">
            <div *ngFor="let attachment of attachments" class="p-2">
                <div class="d-flex align-items-center">
                    <div class="btn btn-default" (click)="downloadAttachment(attachment)">
                        {{ attachment.FileName }}
                        <i class="fas fa-download"></i>
                    </div>
                    <i
                        class="fas fa-trash-alt ms-2 text-danger"
                        style="cursor: pointer"
                        (click)="deleteAttachment(attachment)"></i>
                </div>
            </div>
        </div>

        <file-upload #fileUploader *ngIf="!useLimitedInfoView"></file-upload>
    </div>
    <div *ngIf="!useLimitedInfoView">
        <div *footer>
            <div *ngIf="lensDefinition" class="d-flex align-items-center">
                <div *ngIf="!isReadOnly" class="ms-auto d-flex flex-row">
                    <div>
                        <button id="saveAndClose" type="submit" class="btn good ms-2" (click)="saveRequest(false)">
                            {{ 'general.saveAndClose' | translate }}
                        </button>
                    </div>
                    <div>
                        <button
                            id="complete"
                            type="submit"
                            class="btn good ms-2"
                            [disabled]="measurementsFormGroup.invalid || lensPropertyFormGroup.invalid"
                            (click)="saveRequest(true)">
                            {{ 'general.complete' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
