export class Attachment {
    Id: number;
    FileName: string;
    UploadedOn: Date;
    MimeType: string;
    ImageData: string;
    IsInternal: boolean;

    isImage() {
        return this.MimeType.indexOf('image/') === 0;
    }
}
