import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RegionService } from '@app/core/services/region.service';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';

@Component({
    selector: 'app-region-selector',
    templateUrl: './region-selector.component.html',
    styleUrls: ['region-selector.component.scss'],
})
export class RegionSelectorComponent {
    constructor(
        private readonly regionService: RegionService,
        private readonly appConfigService: AppConfigService,
        private readonly router: Router,
    ) {}

    setRegion(region: string) {
        if (this.appConfigService.setAppEndpoint(region)) {
            this.regionService.saveRegion(region);
        }

        this.router.navigate(['/']).then();
    }
}
