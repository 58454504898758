import { LensTypes, MeasurementImageModes } from '../enums';
import { TopoImageOptions } from './topoImageOptions.model';
import { FluoImageOptions } from './fluoImageOptions.model';
import { LensProfileImageOptions } from './lensProfileImageOptions.model';
import { HeightMapImageOptions } from './heightMapImageOptions.model';

export class ImageOptions {
    leftImageMode: MeasurementImageModes;
    rightImageMode: MeasurementImageModes;

    UseAxis = true;
    ShouldOpenDialog = true;
    ShowImageTypes = true;
    ImageChoice = 'current';
    ShowImageChoice = false;

    topoImageOptions: TopoImageOptions;
    heightMapImageOptions: HeightMapImageOptions;
    fluoImageOptions: FluoImageOptions;
    lensProfileImageOptions: LensProfileImageOptions;

    constructor(
        public lensTypeId: LensTypes = null,
        public proposalId = 0,
    ) {
        switch (lensTypeId) {
            case LensTypes.DreamLite:
                // TODO: only diff for dreamlite follow-up. In that case there should also be baseline topo measurements
                // this.ImageChoice = 'diff';
                this.leftImageMode = MeasurementImageModes.Topo;
                this.rightImageMode = MeasurementImageModes.Topo;
                break;
            case LensTypes.Soft:
                this.leftImageMode = MeasurementImageModes.HeightMap;
                this.rightImageMode = MeasurementImageModes.HeightMap;
                break;
            case LensTypes.Rgp:
            case LensTypes.Med:
            case LensTypes.MedPlus:
            case LensTypes.Crt:
                this.leftImageMode = MeasurementImageModes.Fluo;
                this.rightImageMode = MeasurementImageModes.Fluo;
                break;
            default:
                this.leftImageMode = MeasurementImageModes.Topo;
                this.rightImageMode = MeasurementImageModes.Topo;
                break;
        }

        this.topoImageOptions = new TopoImageOptions();
        this.heightMapImageOptions = new HeightMapImageOptions();
        this.fluoImageOptions = new FluoImageOptions();
        this.lensProfileImageOptions = new LensProfileImageOptions();
    }
}
