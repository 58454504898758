import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LoaderService {
    status: Subject<LoaderMessage> = new Subject<LoaderMessage>();
    info: LoaderMessage;

    constructor(public translate: TranslateService) {
        this.info = new LoaderMessage();
    }

    show() {
        this.info.isLoading = true;
        this.translate.get('general.loading').subscribe((result) => {
            this.info.message = result;
            this.status.next(this.info);
        });
    }

    showMessage(message: string) {
        this.info.isLoading = true;
        this.info.message = message;

        this.status.next(this.info);
    }

    hide() {
        this.info.isLoading = false;

        this.status.next(this.info);
    }
}

export class LoaderMessage {
    isLoading = false;
    message = '';
}
