import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, lastValueFrom } from 'rxjs';
import { ListOption } from '@app/shared/models';
import { LensTypes, ParameterTypeCodes } from '@app/shared/enums';
import { Observable } from 'rxjs';
import { map, tap, startWith, switchMap } from 'rxjs/operators';
import { ProposalReceiptHistoryLine } from '@app/shared/models';
import { ProposalReceiptService } from '@app/core/services/api/proposal-receipt.service';
import { LensService } from '@app/core/services/api/lens.service';

@Component({
    selector: 'receipt-history',
    templateUrl: 'receipt-history.component.html',
    styleUrls: ['receipt-history.component.scss'],
})
export class ReceiptHistoryComponent implements OnInit {
    readonly parameterOrder: string[] = [
        ParameterTypeCodes.POW,
        ParameterTypeCodes.CYL,
        ParameterTypeCodes.AX,
        ParameterTypeCodes.DIAM,
        ParameterTypeCodes.RAD,
    ];

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly proposalReceiptService: ProposalReceiptService,
        private readonly lensService: LensService,
    ) {}

    LensTypes = LensTypes;
    selectedReceiptLines: ProposalReceiptHistoryLine[] = [];

    @Output() selectedLensFilterChanged$: EventEmitter<string> = new EventEmitter();

    @Input() clientId: number;

    formGroup: UntypedFormGroup;
    allLensTypes: ListOption[];
    orderedLensTypes: ListOption[];
    hasMultipleLensTypes: boolean;
    hasResult: boolean;
    loadingData = false;

    filter$ = new BehaviorSubject<{
        orderDirection: string;
        orderColumn: string | null;
    }>({
        orderDirection: 'desc',
        orderColumn: 'CreatedDate',
    });

    historyTable$: Observable<{
        headerParameters: {
            code: string;
        }[];
        data: ProposalReceiptHistoryLine[];
    }>;

    private refresher$ = new BehaviorSubject<boolean>(false);

    get formControls() {
        return this.formGroup.controls;
    }

    get selectedLensTypeId() {
        return this.formControls['selectedLensType'] ? Number(this.formControls['selectedLensType'].value) : 0;
    }

    async ngOnInit() {
        this.createForm();

        this.selectedReceiptLines = new Array<ProposalReceiptHistoryLine>();
        this.loadingData = true;
        await Promise.all([this.getAllLensTypes()]);
        this.initializeTable();
    }

    private createForm(): void {
        this.formGroup = this.fb.group({
            selectedLensType: [''],
        });
    }

    async refresh(): Promise<void> {
        this.refresher$.next(true);
    }

    private initializeTable(): void {
        const lensType$ = this.formControls['selectedLensType'].valueChanges.pipe(
            startWith(this.formControls['selectedLensType'].value),
            tap((lensType) => this.selectedLensFilterChanged$.emit(lensType)),
        );

        const historyReceipts$ = this.refresher$.pipe(
            tap(() => (this.loadingData = true)),
            switchMap((isRefreshing: boolean) =>
                this.proposalReceiptService.getProposalReceiptLineHistory(this.clientId).pipe(
                    tap((ReceiptLines) => this.updateLensTypes(ReceiptLines, isRefreshing)),
                    tap(() => (this.loadingData = false)),
                ),
            ),
        );

        //Reconstruct the table when the Receipt lines or the lens type filter changes
        this.historyTable$ = combineLatest([historyReceipts$, lensType$]).pipe(
            //Filter the Receipt lines by the selected lens type
            map(([receiptLines, lensType]) => this.filterByLensType(lensType, receiptLines)),
            //Apply sorting
            switchMap((receiptLines) =>
                this.filter$.pipe(
                    tap((filter) => {
                        if (filter.orderColumn && filter.orderDirection) {
                            receiptLines = this.applySorting(receiptLines, filter.orderColumn, filter.orderDirection);
                        }
                    }),
                    map(() => receiptLines),
                ),
            ),
            //Reconstruct the table
            map((receiptLines) => ({
                headerParameters: this.createHeaderParameters(receiptLines),
                data: receiptLines,
            })),
        );
    }

    private filterByLensType(lensType: number, receiptLines: ProposalReceiptHistoryLine[]) {
        const lensTypeId = Number(lensType);
        if (lensTypeId > 0) {
            receiptLines = receiptLines.filter((x) => x.FittedLens?.LensTypeId === lensTypeId);
        }
        this.clearSelection();
        return receiptLines;
    }

    private clearSelection() {
        this.selectedReceiptLines = new Array<ProposalReceiptHistoryLine>();
    }

    private updateLensTypes(ReceiptLines: ProposalReceiptHistoryLine[], isRefreshing: boolean) {
        const orderedLensTypeIds = [...new Set(ReceiptLines.map((x) => x.FittedLens?.LensTypeId))];

        //Only show the already ordered unique lenstypes in the dropdown list
        this.orderedLensTypes = this.allLensTypes.filter((x) => orderedLensTypeIds.includes(x.Id));
        this.hasMultipleLensTypes = this.orderedLensTypes.length > 1;

        //Select the most recently ordered lenstype as the default, if this is the initial loading of the list
        if (!isRefreshing && ReceiptLines && ReceiptLines.length > 0) {
            const ol = this.getLatestReceiptLine(ReceiptLines);
            this.formControls['selectedLensType'].setValue(ol.FittedLens?.LensTypeId);
        }
    }

    private createHeaderParameters(ReceiptLines: ProposalReceiptHistoryLine[]) {
        const uniqueParameters = [
            ...new Map(ReceiptLines.flatMap((x) => x.FittedLens.Parameters).map((x) => [x.Code, x])).values(),
        ];

        return uniqueParameters
            .sort((a, b) => {
                const aIndex = this.parameterOrder.indexOf(ParameterTypeCodes[a.Code]);
                const bIndex = this.parameterOrder.indexOf(ParameterTypeCodes[b.Code]);
                if (aIndex === -1 && bIndex === -1) {
                    return a.SortOrder - b.SortOrder;
                }
                if (aIndex === -1) {
                    return 1;
                }
                if (bIndex === -1) {
                    return -1;
                }
                return aIndex - bIndex;
            })
            .map((param) => {
                return {
                    code: param.Code,
                };
            });
    }

    private applySorting(
        ReceiptLines: ProposalReceiptHistoryLine[],
        orderColumn: string,
        orderDirection: string,
    ): ProposalReceiptHistoryLine[] {
        const keys = orderColumn.split('.');

        return ReceiptLines.sort((a, b) => {
            let valA = a[keys[0]] ?? 0;
            let valB = b[keys[0]] ?? 0;

            if (keys[0] === 'FittedLens' && keys[1] === 'Parameters') {
                const paramA = a.FittedLens.Parameters.find((x) => x.Code === keys[2]);
                const paramB = b.FittedLens.Parameters.find((x) => x.Code === keys[2]);
                valA = paramA?.Value ?? 0;
                valB = paramB?.Value ?? 0;
            } else {
                for (let i = 1; i < keys.length; i++) {
                    valA = valA[keys[i]];
                    valB = valB[keys[i]];
                }
            }

            if (valA < valB || !valA) {
                return orderDirection === 'asc' ? -1 : 1;
            }
            if (valA > valB || !valB) {
                return orderDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    private async getAllLensTypes() {
        this.allLensTypes = await lastValueFrom(this.lensService.getLensTypes());
    }

    getReceiptLineStatusClass(receiptLine: ProposalReceiptHistoryLine): string {
        if (receiptLine.IsOrdered) {
            return 'receipt-ordered';
        }

        return 'receipt-notordered';
    }

    private getLatestReceiptLine(ReceiptLines: ProposalReceiptHistoryLine[]): ProposalReceiptHistoryLine {
        const sorted = ReceiptLines.sort((a: ProposalReceiptHistoryLine, b: ProposalReceiptHistoryLine): number => {
            return new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime();
        });
        return sorted[0];
    }

    toggleReceiptLine(item: ProposalReceiptHistoryLine) {
        if (this.isReceiptLineSelected(item)) {
            this.selectedReceiptLines = this.selectedReceiptLines.filter((x) => x.Index !== item.Index);
        } else {
            switch (this.selectedReceiptLines.length) {
                case 0:
                    this.selectedReceiptLines.push(item);
                    break;
                case 1:
                    if (this.selectedReceiptLines[0].FittedLens?.EyeSideId === item.FittedLens?.EyeSideId) {
                        this.selectedReceiptLines[0] = item;
                    } else {
                        this.selectedReceiptLines.push(item);
                    }
                    break;
                case 2:
                    if (this.selectedReceiptLines[0].FittedLens?.EyeSideId === item.FittedLens?.EyeSideId) {
                        this.selectedReceiptLines[0] = item;
                    } else if (this.selectedReceiptLines[1].FittedLens?.EyeSideId === item.FittedLens?.EyeSideId) {
                        this.selectedReceiptLines[1] = item;
                    }
                    break;
            }
        }
    }

    isReceiptLineSelected(receiptLine: ProposalReceiptHistoryLine): boolean {
        return this.selectedReceiptLines.some((ol) => ol.Index === receiptLine.Index);
    }

    getClassForReceiptLine(receiptLine: ProposalReceiptHistoryLine): string {
        return (
            this.getReceiptLineStatusClass(receiptLine) + (this.isReceiptLineSelected(receiptLine) ? ' selected' : '')
        );
    }

    getSortClass(columnName: string): string {
        const currentFilter = this.filter$.value;
        if (columnName === currentFilter.orderColumn) {
            return currentFilter.orderDirection === 'desc' ? 'fa fa-sort-down' : 'fa fa-sort-up';
        } else {
            return 'fa fa-sort';
        }
    }

    applySort(event: MouseEvent, columnName: string): void {
        event.preventDefault();

        const toggleSortingDirection = (direction: string) => (direction === 'asc' ? 'desc' : 'asc');
        const currentSortingState = this.filter$.value;

        if (currentSortingState.orderColumn === columnName) {
            // The table is already sorted by this column, so flip the sorting direction
            const newDirection = toggleSortingDirection(currentSortingState.orderDirection);

            this.filter$.next({
                ...currentSortingState,
                orderDirection: newDirection,
            });
        } else {
            // The table is not yet sorted by this column, so set the sorting state accordingly
            this.filter$.next({
                orderColumn: columnName,
                orderDirection: 'asc',
            });
        }
    }
}
