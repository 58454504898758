import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from './modal.component';

@NgModule({
    imports: [TranslateModule],
    declarations: [ModalComponent],
    exports: [ModalComponent],
})
export class MpcModalModule {}
