import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { AppStateService } from '@app/shared/appservices/appState.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private appConfigService: AppConfigService,
        private appState: AppStateService,
        private locale: string,
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.startsWith('api/')) {
            if (
                request.url.startsWith('api/') &&
                this.appConfigService.appConfig &&
                this.appConfigService.apiEndpoint
            ) {
                request = request.clone({
                    url: this.appConfigService.apiEndpoint + request.url,
                    setHeaders: {
                        Timezone: this.appConfigService.appTimezoneOffset.toString(),
                        'Client-Culture': this.appState?.authenticatedUser?.CurrentLanguageCode ?? this.locale,
                    },
                });
            }
        }

        return next.handle(request);
    }
}
