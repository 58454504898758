import { Component } from '@angular/core';
import { Client } from '@app/shared/models';
import { FittingService } from '@app/core/services/fitting.service';
import { LensTypes } from '@app/shared/enums';
import { Router } from '@angular/router';

@Component({
    selector: 'fitting-header',
    templateUrl: './fitting-header.component.html',
    styleUrls: ['fitting-header.component.scss'],
})
export class FittingHeaderComponent {
    get step(): number {
        return this.fittingService.state.step;
    }

    get client(): Client {
        return this.fittingService.state.client;
    }

    get isGlassFitting(): boolean {
        return this.fittingService.isGlassFitting();
    }

    constructor(
        private router: Router,
        private readonly fittingService: FittingService,
    ) {}

    getReference(): string {
        if (this.client && this.client.Reference) {
            return this.client.Reference;
        }
        return 'general.client';
    }

    getReference2(): string {
        if (this.client && this.client.Reference2) {
            return this.client.Reference2;
        }
        return '';
    }

    get lensType(): string {
        let result = '';
        if (this.fittingService.state.proposal) {
            switch (this.fittingService.state.proposal.LensTypeId) {
                case LensTypes.Crt:
                case LensTypes.DreamLite:
                    result = 'lenstype.ortho-k';
                    break;
                case LensTypes.Med:
                    result = 'lenstype.med';
                    break;
                case LensTypes.MedPlus:
                    result = 'lenstype.med-plus';
                    break;
                case LensTypes.Rgp:
                    result = 'lenstype.rgp';
                    break;
                case LensTypes.Soft:
                    result = 'lenstype.soft';
                    break;
                default:
                    break;
            }
        }
        return result;
    }

    onClient(): void {
        if (this.client) {
            this.router.navigate(['/client/' + this.fittingService.state.client.Id]).then();
        }
    }
}
