<div class="container">
    <div class="row">
        <div
            id="topographic-measurement-container"
            class="measurementitemcontainer rounded p-3 no-caret col-8"
            [ngClass]="{
                selected:
                    historicalMeasurement?.topographicMeasurement?.Id ===
                        selectedRefractionMeasurement?.TopographicMeasurementId &&
                    historicalMeasurement?.topographicMeasurement
            }">
            <h3 class="measurement-type">{{ measurementType | translate }}</h3>

            <div class="row">
                <div class="col-4">
                    <ng-container *ngIf="hasTopo">
                        <div class="row">
                            <div class="col-5">{{ 'general.date' | translate }}:</div>
                            <div class="col-7">
                                {{ historicalMeasurement.topographicMeasurement.Performed | mpcdate }}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-5">{{ 'general.time' | translate }}:</div>
                            <div class="col-7">
                                {{ historicalMeasurement.topographicMeasurement.Performed | mpctime }}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-5">{{ 'general.source' | translate }}:</div>
                            <div class="col-7">
                                {{ translateTextForSource | translate }}
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-5">{{ 'measurement.simkflat' | translate }}:</div>
                            <div class="col-7">
                                {{ historicalMeasurement.topographicMeasurement.SimKRadiusFlat }}
                                {{ 'units.millimeter' | translate }}
                                &#64;
                                {{
                                    historicalMeasurement.topographicMeasurement.SimKAxisFlat
                                        | numberFormat: '{"decimalCount": 0}'
                                }}
                                {{ 'units.degree' | translate }}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-5">{{ 'measurement.simksteep' | translate }}:</div>
                            <div class="col-7">
                                {{ historicalMeasurement.topographicMeasurement.SimKRadiusSteep }}
                                {{ 'units.millimeter' | translate }}
                                &#64;
                                {{
                                    historicalMeasurement.topographicMeasurement.SimKAxisSteep
                                        | numberFormat: '{"decimalCount": 0}'
                                }}
                                {{ 'units.degree' | translate }}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-5">{{ 'measurement.cornealcylinder' | translate }}:</div>
                            <div class="col-7">
                                {{
                                    historicalMeasurement.topographicMeasurement.CornealCylinder
                                        | numberFormat: '{"decimalCount": 2}'
                                }}
                                {{ 'units.dioptry' | translate }}
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="col-8 d-flex justify-content-center">
                    <div *ngIf="hasTopo">
                        <topo-image-thumbnail
                            class="topo-image-thumbnail"
                            [measurement]="historicalMeasurement.topographicMeasurement"
                            [imageOptions]="imageOptions"
                            (click)="openReviewMeasurementDialog()"></topo-image-thumbnail>
                        <span
                            *ngIf="historicalMeasurement.topographicMeasurement.IsManual"
                            class="manual-measurement-badge-relative badge badge-outline">
                            {{
                                historicalMeasurement.topographicMeasurement.TopographerName | slice: 0 : 1 | uppercase
                            }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div *ngIf="inDeleteMode && historicalMeasurement?.topographicMeasurement?.Id" class="col-1">
                    <button
                        stop-propagation
                        id="{{ historicalMeasurement.topographicMeasurement.Id }}-deleteMeasurement"
                        class="btn btn-danger btn-pill p-2 deleteMeasurementButton"
                        (click)="deleteMeasurement(historicalMeasurement.topographicMeasurement.Id, undefined)">
                        <i icon="fas fa-trash fa-lg"></i>
                        {{ 'general.delete' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div
            id="tm-{{ eyeSide }}-{{ index }}-rf"
            class="refraction-measurement-container measurementitemcontainer rounded no-caret p-3 col-4"
            (click)="markAsSelected(historicalMeasurement.refractionMeasurement)"
            [ngClass]="{
                selected:
                    selectedRefractionMeasurement?.Id === historicalMeasurement?.refractionMeasurement?.Id &&
                    historicalMeasurement?.refractionMeasurement
            }">
            <ng-container
                *ngIf="
                    historicalMeasurement?.topographicMeasurement &&
                        displayBaselineTag &&
                        historicalMeasurement.topographicMeasurement.IsBaseLine;
                    else emptyH3
                ">
                <h3 class="baseline badge badge-outline badge-colors">
                    {{ 'general.baseline' | translate }}
                    <i class="fas fa-check-circle"></i>
                </h3>
            </ng-container>
            <ng-template #emptyH3>
                <h3>&nbsp;</h3>
            </ng-template>

            <div class="row">
                <ng-container *ngIf="hasRefractionData">
                    <div class="row">
                        <div class="col-5">{{ 'general.refraction' | translate }}:</div>
                        <div class="col-7">
                            {{ historicalMeasurement.refractionMeasurement.Performed | mpcdate }}
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-5">{{ 'general.time' | translate }}:</div>
                        <div class="col-7">
                            {{ historicalMeasurement.refractionMeasurement.Performed | mpctime }}
                        </div>
                    </div>

                    <div
                        class="row"
                        *ngIf="historicalMeasurement.refractionMeasurement.VisualAcuitySineCorrectoreValue">
                        <div class="col-5">{{ 'general.abbreviations.VASC' | translate }}:</div>
                        <div class="col-7">
                            {{ historicalMeasurement.refractionMeasurement.VisualAcuitySineCorrectoreValue }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">{{ 'lens.geometry.sphere' | translate }}:</div>
                        <div class="col-7">
                            {{
                                historicalMeasurement.refractionMeasurement.Sphere | numberFormat: '{"decimalCount": 2}'
                            }}
                            {{ 'units.dioptry' | translate }}
                        </div>
                    </div>

                    <div class="row" *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']">
                        <div class="col-5">{{ 'lens.geometry.cylinder' | translate }}:</div>
                        <div class="col-7">
                            {{
                                historicalMeasurement.refractionMeasurement.Cylinder
                                    | numberFormat
                                        : '{"decimalCount":
                            2}'
                            }}
                            {{ 'units.dioptry' | translate }}
                        </div>
                    </div>

                    <div class="row" *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']">
                        <div class="col-5">{{ 'general.axis' | translate }}:</div>
                        <div class="col-7">
                            {{ historicalMeasurement.refractionMeasurement.Axis }}
                            {{ 'units.degree' | translate }}
                        </div>
                    </div>
                    <ng-container *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']">
                        <div class="row" *ngIf="historicalMeasurement.refractionMeasurement.NearAdd">
                            <div class="col-5">{{ 'lens.nearAdd' | translate }}:</div>
                            <div class="col-7">
                                {{
                                    historicalMeasurement.refractionMeasurement.NearAdd
                                        | numberFormat: '{"decimalCount": 2}'
                                }}
                                {{ 'units.dioptry' | translate }}
                            </div>
                        </div>
                    </ng-container>
                    <div class="row">
                        <div class="col-5">{{ 'lens.diamcornea' | translate }}:</div>
                        <div class="col-7">
                            {{
                                historicalMeasurement.refractionMeasurement.CorneaDiameter
                                    | numberFormat: '{"decimalCount": 2}'
                            }}
                            {{ 'units.millimeter' | translate }}
                        </div>
                    </div>
                </ng-container>

                <div class="row">
                    <div *ngIf="inDeleteMode && historicalMeasurement?.refractionMeasurement?.Id" class="col-1">
                        <button
                            stop-propagation
                            id="{{ historicalMeasurement.refractionMeasurement.Id }}-deleteMeasurement"
                            class="btn btn-danger btn-pill p-2 deleteMeasurementButton"
                            (click)="deleteMeasurement(undefined, historicalMeasurement.refractionMeasurement.Id)">
                            <i icon="fas fa-trash fa-lg"></i>
                            {{ 'general.delete' | translate }}
                        </button>
                    </div>
                </div>
            </div>

            <div
                *ngIf="historicalMeasurement?.topographicMeasurement?.RefractionMeasurements.length > 0"
                class="measurement-history">
                <button
                    stop-propagation
                    type="button"
                    class="btn btn-secondary measurement-history-btn"
                    (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed"
                    aria-controls="measurement-history">
                    {{ 'measurement.historicmeasurements' | translate }}
                    <i *ngIf="isCollapsed; else elseCollapsed" class="fa fa-angle-right" aria-hidden="true"></i>
                    <ng-template #elseCollapsed><i class="fa fa-angle-down" aria-hidden="true"></i></ng-template>
                </button>
            </div>
        </div>
    </div>
</div>

<div id="measurement-history" [collapse]="isCollapsed" [isAnimated]="true">
    <div *ngFor="let refraction of historicalMeasurement?.topographicMeasurement?.RefractionMeasurements">
        <div
            class="measurementitemcontainer rounded p-3 no-caret mt-2"
            [ngClass]="{ selected: selectedRefractionMeasurement?.Id === refraction.Id }"
            (click)="markAsSelected(refraction)">
            <div class="row">
                <div class="col">{{ 'general.refraction' | translate }}:</div>
                <div class="col">
                    {{ refraction.Performed | mpcdate }}
                </div>

                <div *ngIf="historicalMeasurement.refractionMeasurement.VisualAcuitySineCorrectoreValue" class="col">
                    {{ 'general.abbreviations.VASC' | translate }}:
                </div>
                <div *ngIf="historicalMeasurement.refractionMeasurement.VisualAcuitySineCorrectoreValue" class="col">
                    {{ refraction.VisualAcuitySineCorrectoreValue }}
                </div>

                <div class="col">{{ 'lens.geometry.sphere' | translate }}:</div>
                <div class="col">
                    {{ refraction.Sphere | numberFormat: '{"decimalCount": 2}' }}
                    {{ 'units.dioptry' | translate }}
                </div>

                <div *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']" class="col">
                    {{ 'general.axis' | translate }}:
                </div>
                <div *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']" class="col">
                    {{ refraction.Axis }}
                    {{ 'units.degree' | translate }}
                </div>
            </div>

            <div class="row">
                <div class="col">{{ 'general.time' | translate }}:</div>
                <div class="col">
                    {{ refraction.Performed | mpctime }}
                </div>

                <div *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']" class="col">
                    {{ 'lens.geometry.cylinder' | translate }}:
                </div>
                <div *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']" class="col">
                    {{ refraction.Cylinder | numberFormat: '{"decimalCount": 2}' }}
                    {{ 'units.dioptry' | translate }}
                </div>

                <ng-container *excludeFromCustomTemplateIdentifierFitlens="['CustomTemplateIdentifierFitlens1']">
                    <div *ngIf="historicalMeasurement.refractionMeasurement.NearAdd" class="col">
                        {{ 'lens.nearAdd' | translate }}:
                    </div>

                    <div *ngIf="historicalMeasurement.refractionMeasurement.NearAdd" class="col">
                        {{ refraction.NearAdd | numberFormat: '{"decimalCount": 2}' }}
                        {{ 'units.dioptry' | translate }}
                    </div>
                </ng-container>

                <div class="col">{{ 'lens.diamcornea' | translate }}:</div>
                <div class="col">
                    {{ refraction.CorneaDiameter | numberFormat: '{"decimalCount": 2}' }}
                    {{ 'units.millimeter' | translate }}
                </div>
            </div>

            <div class="row">
                <div *ngIf="inDeleteMode" class="col-1">
                    <button
                        stop-propagation
                        id="{{ historicalMeasurement.topographicMeasurement.Id }}-deleteMeasurement"
                        class="btn btn-danger btn-pill p-2 deleteMeasurementButton"
                        (click)="deleteMeasurement(undefined, refraction.Id)">
                        <i icon="fas fa-trash fa-lg"></i>
                        {{ 'general.delete' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
