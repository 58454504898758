<div class="p-2 fileupload d-flex flex-column flex-md-row">
    <div
        class="flex-even d-flex flex-wrap align-items-center drop-container"
        ngFileDrop
        [options]="options"
        (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput">
        <div *ngIf="!addingToQueue" class="w-100 mx-auto p-2 text-center">
            {{ 'fileupload.dragorbrowse' | translate }}
        </div>
        <div *ngIf="!addingToQueue" class="w-100 mx-auto px-2 pb-2 text-center">
            <label class="btn btn-secondary upload-button">
                <input
                    type="file"
                    ngFileSelect
                    [options]="options"
                    (uploadOutput)="onUploadOutput($event)"
                    [uploadInput]="uploadInput"
                    multiple />
                <strong>{{ 'fileupload.dragorbrowseButton' | translate }}</strong>
            </label>
        </div>
        <div *ngIf="addingToQueue" class="w-100 mx-auto p-2 text-center">
            <div *ngIf="addingToQueue" class="spinner-border spinner-border-sm"></div>
            {{ 'fileupload.uploading' | translate }}
        </div>
    </div>
    <div class="mt-2 ms-md-3 mt-md-0 table-responsive flex-even files" *ngIf="files && files.length > 0">
        <table class="table table-sm table-borderless d-block">
            <ng-container *ngFor="let file of files; let i = index">
                <tr>
                    <td class="col-action">
                        <i class="fas fa-check ps-1" *ngIf="file.responseStatus === 200"></i>
                        <button
                            id="file-delete-{{ file.id }}"
                            class="btn btn-default btn-pill p-1 px-2 deleteFileButton"
                            (click)="removeFile(file.id)"
                            *ngIf="file.responseStatus !== 200">
                            <i class="fas fa-times"></i>
                        </button>
                    </td>
                    <td class="col-name">
                        <span
                            *ngIf="
                                file.progress.status === fileUploadStatus.Done && file.responseStatus !== 200;
                                else filename
                            "
                            class="text-warning">
                            {{ file.name }}:
                            <span *ngIf="file.responseStatus === 400; else general">
                                <div *ngFor="let error of file.response.errors | keyvalue">
                                    <div *ngFor="let message of $any(error).value; let i = index">
                                        {{ error.value[i] | translate }}
                                    </div>
                                </div>
                            </span>
                            <ng-template #general>
                                {{ 'error.generic' | translate }}
                            </ng-template>
                        </span>
                        <ng-template #filename>
                            <span id="file-name-{{ i }}" class="center-block">{{ file.name }}</span>
                        </ng-template>
                    </td>
                    <td class="col-size">
                        <span id="file-size-{{ i }}" class="center-block"></span>
                    </td>
                </tr>
                <tr>
                    <td colspan="3" *ngIf="file.progress.status === 1">
                        <progressbar
                            [max]="100"
                            [value]="file.progress.data.percentage"
                            type="info"
                            [striped]="false"
                            [animate]="true">
                            {{ file.progress.data.percentage }}%
                        </progressbar>
                    </td>
                </tr>
            </ng-container>
        </table>

        <button
            *ngIf="showUploadButton"
            [disabled]="hasNoPendingFiles()"
            class="btn btn-default p-1 px-3"
            (click)="uploadFiles()">
            {{ 'fileupload.upload' | translate }}
        </button>
    </div>
</div>
