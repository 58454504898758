import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@app/shared/appservices/alert.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { Roles, Features, FeatureCategories } from '@app/shared/enums';
import { ListOption, ListSelectOption, UserSetting } from '@app/shared/models';
import { InputNumberRange } from '@app/shared/models/input-number-range.model';
import { OpticianSetting } from '@app/shared/models/opticianSetting.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin, lastValueFrom } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { SettingsDialogService } from '@app/core/components/settings-dialog/services/settings-dialog.service';
import { LensTypeProductGroupNames } from '@app/shared/models/lensTypeProductGroupNames.model';
import { UserSettingService } from '@app/core/services/api/user-setting.service';
import { OpticianService } from '@app/core/services/api/optician.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface SettingsFormGroup {
    vertex: FormControl<number | null>;
    topographicMeasurementRetentionHours: FormControl<number | null>;
    replacementNotifications: FormControl<boolean>;
    sendStatusReminderEmail: FormControl<boolean>;
    usePrefilledDreamLiteAgreement: FormControl<boolean>;
    imageType: FormControl<ListOption>;
    useNormalize: FormControl<ListOption>;
    useMapType: FormControl<ListOption>;
    useMm: FormControl<ListOption>;
}

@Component({
    selector: 'settings',
    templateUrl: 'settings.component.html',
    styleUrls: ['settings.component.scss'],
})
export class SettingsComponent implements OnInit {
    formGroupSettings: FormGroup<SettingsFormGroup>;
    userSetting: UserSetting;
    opticianSetting: OpticianSetting;

    settingsLoading = false;
    languages: ListOption[];
    opticians: ListOption[];
    distributors: ListOption[];

    imageTypes: ListSelectOption[] = [];
    mmDptTypes: ListSelectOption[] = [];
    normalizeOptions: ListSelectOption[] = [];
    mapTypeOptions: ListSelectOption[] = [];

    lensNames: LensTypeProductGroupNames;

    protected readonly Features = Features;
    protected readonly FeatureCategories = FeatureCategories;
    private readonly destroyRef = inject(DestroyRef);

    constructor(
        private readonly settingsDialogService: SettingsDialogService,
        private readonly appState: AppStateService,
        private readonly loaderService: LoaderService,
        private readonly translate: TranslateService,
        private readonly alertService: AlertService,
        private readonly userSettingService: UserSettingService,
        private readonly opticianService: OpticianService,
    ) {}

    ngOnInit(): void {
        this.settingsLoading = true;

        this.createSettingsForm();

        this.loadTopoSettings()
            .pipe(
                switchMap(() => this.loadSettings()),
                takeUntilDestroyed(this.destroyRef),
                finalize(() => (this.settingsLoading = false)),
            )
            .subscribe();

        this.settingsDialogService.getLensTypeProductGroupNames().subscribe((x) => {
            this.lensNames = x;
        });
    }

    createSettingsForm(): void {
        this.formGroupSettings = new FormGroup<SettingsFormGroup>({
            vertex: new FormControl(1, [Validators.required, Validators.maxLength(5)]),
            topographicMeasurementRetentionHours: new FormControl(2, [Validators.required, Validators.maxLength(3)]),
            replacementNotifications: new FormControl(),
            sendStatusReminderEmail: new FormControl(),
            usePrefilledDreamLiteAgreement: new FormControl(),
            imageType: new FormControl(),
            useNormalize: new FormControl(),
            useMapType: new FormControl(),
            useMm: new FormControl(),
        });
    }

    loadTopoSettings() {
        const requests = [
            this.settingsDialogService
                .getMeasurementUnitOptions()
                .pipe(tap((mmDptTypes) => (this.mmDptTypes = mmDptTypes))),
            this.settingsDialogService.getImageTypeOptions().pipe(tap((imageTypes) => (this.imageTypes = imageTypes))),
            this.settingsDialogService
                .getNormalizeOptions()
                .pipe(tap((normalizeOptions) => (this.normalizeOptions = normalizeOptions))),
            this.settingsDialogService
                .getMapTypeOptions()
                .pipe(tap((mapTypeOptions) => (this.mapTypeOptions = mapTypeOptions))),
        ];

        return forkJoin(requests);
    }

    loadSettings() {
        const requests: Observable<unknown>[] = [];

        const userSettingsRequest = this.settingsDialogService.getUserSettings().pipe(
            tap((userSettings) => {
                this.userSetting = userSettings;

                this.formGroupSettings.controls['vertex'].patchValue(this.userSetting.VertexDistance);

                this.formGroupSettings.controls['usePrefilledDreamLiteAgreement'].patchValue(
                    this.userSetting.UsePrefilledDreamLiteAgreement,
                );

                this.formGroupSettings.controls['sendStatusReminderEmail'].patchValue(
                    this.userSetting.DoSendStatusReminderEmails,
                );

                this.formGroupSettings.controls['imageType'].patchValue(
                    this.imageTypes.find((it) => it.Id === this.userSetting.UseImageType),
                );

                this.formGroupSettings.controls['useMm'].patchValue(
                    this.mmDptTypes.find((it) => it.Id === this.userSetting.UseMm),
                );

                this.formGroupSettings.controls['useMapType'].patchValue(
                    this.mapTypeOptions.find((it) => it.Id === this.userSetting.UseMapType),
                );

                this.formGroupSettings.controls['useNormalize'].patchValue(
                    this.normalizeOptions.find((it) => it.Id === this.userSetting.UseNormalize),
                );
            }),
        );

        requests.push(userSettingsRequest);

        if (this.appState.currentOptician.Id) {
            const opticianSettingsRequest = this.settingsDialogService.getOpticianSettings().pipe(
                tap((opticianSettings) => {
                    this.opticianSetting = opticianSettings;

                    if (this.opticianSetting) {
                        this.formGroupSettings.controls['topographicMeasurementRetentionHours'].patchValue(
                            this.opticianSetting.TopographicMeasurementRetentionHours,
                        );

                        this.formGroupSettings.controls['replacementNotifications'].patchValue(
                            this.opticianSetting.ReplacementNotifications,
                        );
                    }
                }),
            );

            requests.push(opticianSettingsRequest);
        }

        return forkJoin(requests);
    }

    async saveSettings(): Promise<void> {
        this.loaderService.show();

        if (!this.formGroupSettings || this.formGroupSettings.invalid || this.settingsLoading) {
            this.loaderService.hide();
            return;
        }

        const requests: Promise<unknown>[] = [];

        const userSetting = { ...this.userSetting };
        userSetting.VertexDistance = this.formGroupSettings.controls['vertex'].value;
        userSetting.UsePrefilledDreamLiteAgreement =
            this.formGroupSettings.controls['usePrefilledDreamLiteAgreement'].value;
        userSetting.DoSendStatusReminderEmails = this.formGroupSettings.controls['sendStatusReminderEmail'].value;

        if (this.formGroupSettings.controls['imageType'].value) {
            userSetting.UseImageType = this.formGroupSettings.controls['imageType'].value.Id;
        }
        if (this.formGroupSettings.controls['useNormalize'].value) {
            userSetting.UseNormalize = this.formGroupSettings.controls['useNormalize'].value.Id;
        }
        if (this.formGroupSettings.controls['useMapType'].value) {
            userSetting.UseMapType = this.formGroupSettings.controls['useMapType'].value.Id;
        }
        if (this.formGroupSettings.controls['useMm'].value) {
            userSetting.UseMm = this.formGroupSettings.controls['useMm'].value.Id;
        }

        requests.push(lastValueFrom(this.userSettingService.saveUserSettings(userSetting)));

        const opticianSetting = { ...this.opticianSetting };

        if (this.appState.currentOptician.Id) {
            opticianSetting.TopographicMeasurementRetentionHours =
                this.formGroupSettings.controls['topographicMeasurementRetentionHours'].value;
            opticianSetting.ReplacementNotifications =
                this.formGroupSettings.controls['replacementNotifications'].value;
            requests.push(lastValueFrom(this.opticianService.saveOpticianSettings(opticianSetting)));
        }

        await Promise.all(requests);

        this.loaderService.hide();
        this.alertService.success(this.translate.instant('general.saveSuccessful'));
    }

    get vertexRange(): InputNumberRange {
        return new InputNumberRange(8, 16, 1);
    }

    get topographicMeasurementRetentionHoursRange(): InputNumberRange {
        return new InputNumberRange(1, 100, 1);
    }

    get opticianOptionsActive(): boolean {
        return this.appState.authenticatedUser.CurrentRoleId === Roles.Optician;
    }
}
