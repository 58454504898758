<form [formGroup]="formGroup">
    <div class="spinner-border spinner-border-sm mt-1" *ngIf="loadingOrderlines"></div>

    <div class="row align-items-center my-1">
        <div *ngIf="hasMultipleLensTypes" class="col-12 col-sm-6 col-md-5 col-lg-3 col-xl-2 pt-2">
            <mpc-select
                formControlName="selectedLensType"
                [id]="'selectedLensType'"
                [items]="orderedLensTypes"
                label=""
                noSelectionLabel="lenstype.selectlenstype"
                [labelWidth]="0"
                [focus]></mpc-select>
        </div>

        <ng-container
            *hasFeatureSetting="[
                {
                    Feature: features.HideOrderStatusFields,
                    Category: featureCategories.Company,
                    IsEnabled: false,
                    CompanyToCheck: company
                }
            ]">
            <div class="col-12 col-lg-9 col-xl-10 d-flex" [ngClass]="{ 'col-lg-12 col-xl-12': !hasMultipleLensTypes }">
                <div class="ms-lg-auto">
                    <div class="d-inline-block">
                        <i class="fas fa-circle evaluation fg"></i>
                        <span class="label ms-1 mr-3">{{ 'orderlinestatus.evaluation' | translate }}</span>
                    </div>
                    <div class="d-inline-block">
                        <i class="fas fa-circle production fg"></i>
                        <span class="label ms-1 mr-3">{{ 'orderlinestatus.production' | translate }}</span>
                    </div>
                    <div class="d-inline-block">
                        <i class="fas fa-circle shipped fg"></i>
                        <span class="label ms-1 mr-3">{{ 'orderlinestatus.shipped' | translate }}</span>
                    </div>
                    <div class="d-inline-block">
                        <i class="fas fa-circle invoiced fg"></i>
                        <span class="label ms-1 mr-3">{{ 'orderlinestatus.invoiced' | translate }}</span>
                    </div>
                    <div class="d-inline-block">
                        <i class="fas fa-circle cancelled fg"></i>
                        <span class="label ms-1 mr-3">{{ 'orderlinestatus.cancelled' | translate }}</span>
                    </div>
                    <div class="d-inline-block" *ngIf="showCrediting">
                        <i class="fas fa-circle credited fg"></i>
                        <span class="label ms-1">{{ 'orderlinestatus.credited' | translate }}</span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="orderLinesTable$ | async; let orderLine">
        <div class="table-responsive">
            <table class="table mb-1 datatable">
                <thead>
                    <tr class="orderline">
                        <th class="orderlinestatus">&nbsp;</th>
                        <th>
                            <ng-container *ngIf="!orderId; else noOrderDate">
                                <a href="#" (click)="applySort($event, 'OrderDate')">
                                    {{ 'general.date' | translate }}
                                    <div
                                        class="ms-1 sorticon"
                                        [ngClass]="sortClasses['OrderDate'] ?? 'fa fa-sort'"
                                        aria-hidden="true"></div>
                                </a>
                            </ng-container>
                            <ng-template #noOrderDate>
                                {{ 'general.date' | translate }}
                            </ng-template>
                        </th>
                        <th>
                            <ng-container *ngIf="!orderId; else noOrderProductName">
                                <a href="#" (click)="applySort($event, 'Product.Name')">
                                    {{ 'general.product' | translate }}
                                    <div
                                        class="ms-1 sorticon"
                                        [ngClass]="sortClasses['Product.Name'] ?? 'fa fa-sort'"
                                        aria-hidden="true"></div>
                                </a>
                            </ng-container>
                            <ng-template #noOrderProductName>
                                {{ 'general.product' | translate }}
                            </ng-template>
                        </th>
                        <th>
                            <ng-container *ngIf="!orderId; else noOrderMaterialName">
                                <a href="#" (click)="applySort($event, 'FittedLens.Material.Name')">
                                    {{ 'general.material' | translate }}
                                    <div
                                        class="ms-1 sorticon"
                                        [ngClass]="sortClasses['FittedLens.Material.Name'] ?? 'fa fa-sort'"
                                        aria-hidden="true"></div>
                                </a>
                            </ng-container>
                            <ng-template #noOrderMaterialName>
                                {{ 'general.material' | translate }}
                            </ng-template>
                        </th>
                        <th>
                            <ng-container *ngIf="!orderId; else noOrderEyeSideId">
                                <a href="#" (click)="applySort($event, 'FittedLens.EyeSideId')">
                                    {{ 'general.eyesides' | translate }}
                                    <div
                                        class="ms-1 sorticon"
                                        [ngClass]="sortClasses['FittedLens.EyeSide'] ?? 'fa fa-sort'"
                                        aria-hidden="true"></div>
                                </a>
                            </ng-container>
                            <ng-template #noOrderEyeSideId>
                                {{ 'general.eyesides' | translate }}
                            </ng-template>
                        </th>
                        <th class="hidden-column">
                            <ng-container *ngIf="!orderId; else noOrderSerialNumber">
                                <a href="#" (click)="applySort($event, 'SerialNumbers')">
                                    {{ 'general.serialnumber' | translate }}
                                    <div
                                        class="ms-1 sorticon"
                                        [ngClass]="sortClasses['SerialNumbers'] ?? 'fa fa-sort'"
                                        aria-hidden="true"></div>
                                </a>
                            </ng-container>
                            <ng-template #noOrderSerialNumber>
                                {{ 'general.serialnumber' | translate }}
                            </ng-template>
                        </th>
                        <ng-container
                            *hasFeatureSetting="[
                                {
                                    Feature: features.HideOrderStatusFields,
                                    Category: featureCategories.Company,
                                    IsEnabled: false,
                                    CompanyToCheck: company
                                }
                            ]">
                            <th class="hidden-column">
                                <ng-container *ngIf="!orderId; else noOrderShippedDate">
                                    <a href="#" (click)="applySort($event, 'ShippedDate')">
                                        {{ 'general.shippedDate' | translate }}
                                        <div
                                            class="ms-1 sorticon"
                                            [ngClass]="sortClasses['ShippedDate'] ?? 'fa fa-sort'"
                                            aria-hidden="true"></div>
                                    </a>
                                </ng-container>
                                <ng-template #noOrderShippedDate>
                                    {{ 'general.shippedDate' | translate }}
                                </ng-template>
                            </th>
                        </ng-container>
                        <th *ngIf="isFeatureWarrantyEnabled">
                            <ng-container *ngIf="!orderId; else noOrderWarranty">
                                <a href="#" (click)="applySort($event, 'IsWarrantyApplied')">
                                    {{ 'general.warranty' | translate }}
                                    <div
                                        class="ms-1 sorticon"
                                        [ngClass]="sortClasses['IsWarrantyApplied'] ?? 'fa fa-sort'"
                                        aria-hidden="true"></div>
                                </a>
                            </ng-container>
                            <ng-template #noOrderWarranty>
                                {{ 'general.warranty' | translate }}
                            </ng-template>
                        </th>
                        <th *ngIf="isFeatureAnnualSupplyEnabled">
                            <ng-container *ngIf="!orderId; else noOrderAnnualSupply">
                                <a href="#" (click)="applySort($event, 'IsAnnualSupplyApplied')">
                                    {{ 'general.annual-supply' | translate }}
                                    <div
                                        class="ms-1 sorticon"
                                        [ngClass]="sortClasses['IsAnnualSupplyApplied'] ?? 'fa fa-sort'"
                                        aria-hidden="true"></div>
                                </a>
                            </ng-container>
                            <ng-template #noOrderAnnualSupply>
                                {{ 'general.annual-supply' | translate }}
                            </ng-template>
                        </th>
                        <th *ngIf="isFeatureOrderingSparesEnabled">
                            <ng-container *ngIf="!orderId; else noOrderSpare">
                                <a href="#" (click)="applySort($event, 'IsSpare')">
                                    {{ 'general.spare' | translate }}
                                    <div
                                        class="ms-1 sorticon"
                                        [ngClass]="sortClasses['IsSpare'] ?? 'fa fa-sort'"
                                        aria-hidden="true"></div>
                                </a>
                            </ng-container>
                            <ng-template #noOrderSpare>
                                {{ 'general.spare' | translate }}
                            </ng-template>
                        </th>
                        <ng-container *ngFor="let parameter of orderLine.headerParameters">
                            <th>
                                <ng-container *ngIf="!orderId; else noOrderParameter">
                                    <a href="#" (click)="applySort($event, 'FittedLens.Parameters.' + parameter.code)">
                                        {{ (parameter.code | parameterTranslate) ?? parameter.code }}
                                        <div
                                            class="ms-1 sorticon"
                                            [ngClass]="
                                                sortClasses['FittedLens.Parameters.' + parameter.code] ?? 'fa fa-sort'
                                            "
                                            aria-hidden="true"></div>
                                    </a>
                                </ng-container>
                                <ng-template #noOrderParameter>
                                    {{ (parameter.code | parameterTranslate) ?? parameter.code }}
                                </ng-template>
                            </th>
                        </ng-container>
                        <th>
                            <ng-container *ngIf="!orderId; else noOrderQuantity">
                                <a href="#" (click)="applySort($event, 'Quantity')">
                                    {{ 'general.quantity' | translate }}
                                    <div
                                        class="ms-1 sorticon"
                                        [ngClass]="sortClasses['Quantity'] ?? 'fa fa-sort'"
                                        aria-hidden="true"></div>
                                </a>
                            </ng-container>
                            <ng-template #noOrderQuantity>
                                {{ 'general.quantity' | translate }}
                            </ng-template>
                        </th>
                        <th class="hidden-column" *ngIf="selectedLensTypeId !== lensTypes.DreamLite">
                            <ng-container *ngIf="!orderId; else noOrderMRK">
                                <a href="#" (click)="applySort($event, 'FittedLens.Engraving')">
                                    {{ 'general.abbreviations.MRK' | translate }}
                                    <div
                                        class="ms-1 sorticon"
                                        [ngClass]="sortClasses['FittedLens.Engraving'] ?? 'fa fa-sort'"
                                        aria-hidden="true"></div>
                                </a>
                            </ng-container>
                            <ng-template #noOrderMRK>
                                {{ 'general.abbreviations.MRK' | translate }}
                            </ng-template>
                        </th>
                        <th *ngIf="anyTrackAndTraceLink">
                            {{ 'general.trackAndTrace' | translate }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of orderLine.data; index as i">
                        <ng-container
                            [ngTemplateOutlet]="tableRow"
                            [ngTemplateOutletContext]="{
                                headers: orderLine.headerParameters,
                                item: item,
                                index: i
                            }"></ng-container>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div *ngIf="!loadingOrderlines && orderLine.data.length === 0">
            <span>{{ 'general.orderlinehistorynoresults' | translate }}</span>
        </div>
    </ng-container>
</form>

<ng-template #tableRow let-headers="headers" let-item="item" let-i="index" let-child="child">
    <tr
        id="orderlinerow_{{ i }}"
        class="orderlinerow"
        (click)="toggleOrderLine(item)"
        [ngClass]="selectedOrderLines.includes(item) ? 'selected' : ''">
        <ng-container *ngIf="child; else isChild">
            <td class="orderlinestatus p-1">
                <i class="fas fa-level-up-alt fa-rotate-90 fg" [ngClass]="orderLineStatusClasses[item.Id]"></i>
            </td>
        </ng-container>
        <ng-template #isChild>
            <td class="orderlinestatus bg" [ngClass]="orderLineStatusClasses[item.Id]">&nbsp;</td>
        </ng-template>

        <td class="dateColumn">{{ item.OrderDate | mpcdate }}</td>
        <td id="productname_{{ i }}">
            <i
                triggers="mouseenter:mouseleave"
                popoverClass="popover"
                popover="{{ 'orderoverview.directToHome.title' | translate }}"
                class="fas fa-mail-bulk mr-2"
                *ngIf="item.IsDirectToHome"></i>
            <i *ngIf="isMyopiaActive && item.IsBaseLine" class="fas fa-thumbtack is-baseline"></i>
            {{ item.Product?.Name }}
            <i *ngIf="item.OrderTypeId === 4" class="fas fa-shopping-bag"></i>
        </td>
        <td class="materialColumn">
            <ng-container *ngIf="item.FittedLens?.Material; let material">
                {{ material.Name + ' ' + material.Color }}
            </ng-container>
        </td>
        <td>
            <ng-container *ngIf="item.FittedLens?.EyeSideId === 1">
                {{ 'general.os' | translate }}
            </ng-container>
            <ng-container *ngIf="item.FittedLens?.EyeSideId === 2">
                {{ 'general.od' | translate }}
            </ng-container>
        </td>
        <td class="hidden-column">
            <div>
                {{
                    item.SerialNumbers && item.SerialNumbers !== 'null' ? (item.SerialNumbers | addSpacesToCommas) : ''
                }}
                <a
                    class="returnIcon"
                    id="service-request-{{ returnId }}"
                    *ngFor="let returnId of item.ReturnIds"
                    (click)="showReturnDetails(returnId)">
                    <img
                        src="assets/images/return.svg"
                        alt="{{ returnId }}"
                        title="{{ 'return.return' | translate }} for return: {{ returnId }}" />
                </a>
            </div>
        </td>
        <ng-container
            *hasFeatureSetting="[
                {
                    Feature: features.HideOrderStatusFields,
                    Category: featureCategories.Company,
                    IsEnabled: false,
                    CompanyToCheck: company
                }
            ]">
            <td class="hidden-column">
                {{ item.ShippedDate | mpcdate }}
            </td>
        </ng-container>
        <td *ngIf="isFeatureWarrantyEnabled" class="text-center">
            <i
                *ngIf="item.WarrantyExchangeAllowed; else elseBlock"
                triggers="mouseenter:mouseleave"
                popoverClass="popover"
                popover="{{ 'warranty.selected.yes' | translate }}"
                class="far fa-check-circle"></i>
            <ng-template #elseBlock>
                <i
                    triggers="mouseenter:mouseleave"
                    popoverClass="popover"
                    popover="{{ 'warranty.selected.no' | translate }}"
                    class="far fa-times-circle"></i>
            </ng-template>
            <i
                *ngIf="item.IsWarrantyApplied"
                triggers="mouseenter:mouseleave"
                popoverClass="popover"
                popover="{{ 'warranty.exchange' | translate }}"
                class="fas fa-exchange-alt"></i>
        </td>
        <td *ngIf="isFeatureAnnualSupplyEnabled" class="text-center">
            <i
                *ngIf="item.IsAnnualSupplyApplied; else elseBlock"
                triggers="mouseenter:mouseleave"
                popoverClass="popover"
                popover="{{
                    (item.IsAnnualSupplyFulfilled ? 'annual-supply.selected.fulfilled' : 'annual-supply.selected.yes')
                        | translate
                }}"
                class="far fa-check-circle"></i>
            <ng-template #elseBlock>
                <i
                    triggers="mouseenter:mouseleave"
                    popoverClass="popover"
                    popover="{{ 'annual-supply.selected.no' | translate }}"
                    class="far fa-times-circle"></i>
            </ng-template>
        </td>
        <td *ngIf="isFeatureOrderingSparesEnabled" class="text-center">
            <i
                triggers="mouseenter:mouseleave"
                popoverClass="popover"
                popover="{{ 'general.spare' | translate }}"
                class="far fa-clone"
                *ngIf="item.IsSpare"></i>
        </td>

        <ng-container *ngFor="let head of headers">
            <td id="{{ (head.code | lowercase) + '_' + i }}">
                <ng-container *ngFor="let parameter of item.FittedLens?.Parameters">
                    <ng-container *ngIf="parameter.Code === head.code">
                        {{ parameter.Value | parameterFormatter: head.code }}
                    </ng-container>
                </ng-container>
            </td>
        </ng-container>

        <td id="quantity_{{ i }}">{{ item.Quantity }}</td>
        <td class="hidden-column" *ngIf="selectedLensTypeId !== lensTypes.DreamLite">
            {{ item.FittedLens?.Engraving }}
        </td>
        <td *ngIf="anyTrackAndTraceLink">
            <i
                stop-propagation
                *ngIf="trackAndTraceLinks[item.Id]"
                (click)="openTrackAndTraceTab(item)"
                class="fas fa-external-link-alt ms-1"></i>
        </td>
    </tr>

    <ng-container *ngIf="item.Child">
        <ng-container
            [ngTemplateOutlet]="tableRow"
            [ngTemplateOutletContext]="{
                headers: headers,
                item: item.Child,
                index: i + '' + i,
                child: true
            }"></ng-container>
    </ng-container>
</ng-template>
