import { Component } from '@angular/core';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { UserSettingService } from '@app/core/services/api/user-setting.service';

@Component({
    selector: 'theme-switch',
    templateUrl: './theme-switch.component.html',
    styleUrls: ['theme-switch.component.scss'],
})
export class ThemeSwitchComponent {
    get IsDarkTheme(): boolean {
        return this.appState.authenticatedUser && this.appState.authenticatedUser.CurrentApplicationTheme === 'dark';
    }

    constructor(
        public appState: AppStateService,
        public userSettingService: UserSettingService,
    ) {}

    toggleTheme(isCurrentDark: boolean) {
        const newTheme = isCurrentDark ? 'light' : 'dark';

        this.userSettingService.changeTheme(newTheme).subscribe(() => {
            this.appState.setTheme(newTheme);
        });
    }
}
