import { FormArray, ValidationErrors } from '@angular/forms';
import { BaseValidator } from '@app/shared/validators/BaseValidator';
import { LensTypes, ParameterTypeCodes } from '@app/shared/enums';
import { FitLensComponent } from '../fitlens.component';

export class CrtLzaValidator extends BaseValidator {
    bind(obj: FitLensComponent): ValidationErrors {
        if (obj.lensTypeId === LensTypes.Crt) {
            const params = obj.formGroup.controls['parameters'] as FormArray;

            const lza1 = params.controls[obj.getFittedLensParameterIndex(ParameterTypeCodes.LZA1SCRN)];
            const lza2 = params.controls[obj.getFittedLensParameterIndex(ParameterTypeCodes.LZA2SCRN)];

            if (lza1?.value != null && lza2?.value != null && lza1.value > lza2.value) {
                return { crtLzaValidator: true };
            }
        }
    }
}
