import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PopupButton } from '../models/popupButton.model';

@Component({
    selector: 'popup-component',
    templateUrl: 'popup.component.html',
})
export class PopupComponent {
    @Input() popupText: string;
    @Input() buttons = [];

    constructor(public bsModalRef: BsModalRef) {}

    onClick(button: PopupButton): void {
        if (button.action) {
            button.action();
        }
        this.close();
    }

    close(): void {
        this.bsModalRef.hide();
    }
}
