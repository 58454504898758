<div *ngIf="order" class="d-flex orderstatusitem rounded" [ngClass]="classNames" (click)="click(order)">
    <div class="statusbar"></div>
    <div class="flex-fill px-2 py-1 d-flex flex-column content">
        <div *ngIf="orderStatusItemType === orderStatusTypes.LensOrder">
            <ng-container *ngTemplateOutlet="lensOrder"></ng-container>
        </div>
        <div *ngIf="orderStatusItemType === orderStatusTypes.DirectOrder">
            <ng-container *ngTemplateOutlet="directOrder"></ng-container>
        </div>
        <div *ngIf="orderStatusItemType === orderStatusTypes.StockOrder">
            <ng-container *ngTemplateOutlet="stockOrder"></ng-container>
        </div>
        <div *ngIf="orderStatusItemType === orderStatusTypes.SerialOrder">
            <ng-container *ngTemplateOutlet="serialOrder"></ng-container>
        </div>
        <div class="mt-auto align-self-end d-flex">
            <div *ngIf="order.HasNonMatchingOrderlineStatusus" class="ms-1">
                <i
                    id="{{ id }}-difforderstatus"
                    class="fa fa-exclamation-triangle"
                    popover="{{ 'orderstatus.differentorderstatuses' | translate }}"
                    triggers="mouseenter:mouseleave"></i>
            </div>
            <div *ngIf="order.PriorityId === orderPriorities.Urgent" class="ms-1">
                <i
                    id="{{ id }}-priority"
                    class="fa fa-exclamation-circle"
                    popover="{{ 'orderstatus.priorityorder' | translate }}"
                    triggers="mouseenter:mouseleave"></i>
            </div>
            <div *ngIf="order.Name" class="ms-1">
                <i
                    id="{{ id }}-directtohome"
                    class="fas fa-mail-bulk"
                    popoverClass="popover"
                    popover="{{ 'orderoverview.directToHome.title' | translate }}"
                    triggers="mouseenter:mouseleave"></i>
            </div>
        </div>
    </div>
</div>

<ng-template #lensOrder>
    <div class="d-flex align-items-center">
        <div id="{{ id }}-title" class="text-truncate font-weight-bold me-1 order-title">
            <span *ngIf="order.ClientReference">{{ order.ClientReference }}</span>
            <span *ngIf="!order.ClientReference && order.ClientReference2">{{ order.ClientReference2 }}</span>
            <span *ngIf="!order.ClientReference && !order.ClientReference2 && order.ProposalReceiptReference">
                {{ order.ProposalReceiptReference }}
            </span>
            <span
                *ngIf="
                    !order.ClientReference &&
                    !order.ClientReference2 &&
                    !order.ProposalReceiptReference &&
                    order.OrderReference
                ">
                {{ order.OrderReference }}
            </span>

            <span
                *ngIf="
                    !order.ClientReference &&
                    !order.ClientReference2 &&
                    !order.OrderReference &&
                    !order.ProposalReceiptReference
                ">
                {{ 'general.order' | translate }}
            </span>
        </div>
        <div class="ms-auto text-nowrap">
            <span id="{{ id }}-orderdate" class="date small">{{ order.OrderDate | mpcdate }}</span>
        </div>
    </div>
    <div class="text-truncate">
        <span class="badge rounded-pill bg-secondary eyesidebadge">{{ 'general.od' | translate }}</span>
        &nbsp;
        <span id="{{ id }}-rightproduct">{{ order.RightProductName }}</span>
        <span *ngIf="order.RightOrderLineStatusId === orderLineStatus.Cancelled">
            &nbsp;({{ order.RightOrderLineStatusName }})
        </span>
        <br />
        <span class="badge rounded-pill bg-secondary eyesidebadge">{{ 'general.os' | translate }}</span>
        &nbsp;
        <span id="{{ id }}-leftproduct">{{ order.LeftProductName }}</span>
        <span *ngIf="order.LeftOrderLineStatusId === orderLineStatus.Cancelled">
            ({{ order.LeftOrderLineStatusName }})
        </span>
    </div>
</ng-template>

<ng-template #directOrder>
    <div class="d-flex align-items-center">
        <div id="{{ id }}-title" class="text-truncate font-weight-bold me-1 order-title">
            <span *ngIf="order.ClientReference">{{ order.ClientReference }}</span>
            <span *ngIf="!order.ClientReference && order.ClientReference2">{{ order.ClientReference2 }}</span>
            <span *ngIf="!order.ClientReference && !order.ClientReference2 && order.OrderReference">
                {{ order.OrderReference }}
            </span>
            <span *ngIf="!order.ClientReference && !order.ClientReference2 && !order.OrderReference">
                {{ 'general.order' | translate }}
            </span>
        </div>
        <div class="ms-auto text-nowrap">
            <span id="{{ id }}-orderdate" class="date small">{{ order.OrderDate | mpcdate }}</span>
        </div>
    </div>
    <div *ngIf="order.BatchProductName" class="text-truncate">
        <div id="{{ id }}-product">{{ order.BatchProductName }}</div>
    </div>
</ng-template>

<ng-template #stockOrder>
    <div class="d-flex align-items-center">
        <div id="{{ id }}-title" class="text-truncate font-weight-bold me-1 order-title">
            {{ 'general.order' | translate }}
        </div>
        <div class="ms-auto text-nowrap">
            <span id="{{ id }}-orderdate" class="date small">{{ order.OrderDate | mpcdate }}</span>
        </div>
    </div>
    <div id="{{ id }}-title" class="text-truncate order-title">
        <span *ngIf="order.ClientReference">{{ order.ClientReference }}</span>
        <span *ngIf="!order.ClientReference && order.ClientReference2">{{ order.ClientReference2 }}</span>
        <span *ngIf="!order.ClientReference && !order.ClientReference2 && order.OrderReference">
            {{ order.OrderReference }}
        </span>
    </div>
    <div *ngIf="order.OrderLines && order.OrderLines.length > 1" class="text-truncate">
        <span id="{{ id }}-numberofproducts">{{ 'orderstatus.numberofproducts' | translate }}:</span>
        &nbsp;
        <span [id]="id + '-quantity'">{{ order.OrderLines.length }}</span>
    </div>
    <div *ngIf="order.BatchProductName && (!order.BatchQuantity || order.BatchQuantity === 1)">
        <div id="{{ id }}-product'">{{ order.BatchProductName }}</div>
    </div>
</ng-template>

<ng-template #serialOrder>
    <div class="d-flex align-items-center">
        <div id="{{ id }}-title" class="text-truncate ont-weight-bold me-1 order-title">
            {{ 'orderstatus.serialnumberorder' | translate }}
        </div>
        <div class="ms-auto text-nowrap">
            <span id="{{ id }}-orderdate" class="date small">{{ order.OrderDate | mpcdate }}</span>
        </div>
    </div>
    <div id="{{ id }}-reference" class="text-truncate">
        <span *ngIf="order.OrderReference">{{ order.OrderReference }}</span>
    </div>
    <div *ngIf="order.IsSerialOrder && order.BatchProductName" class="d-block text-truncate">
        <div id="{{ id }}-product'">{{ order.BatchProductName }}</div>
    </div>
</ng-template>
