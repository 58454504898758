import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProposalAnnualSupply } from '@app/shared/models/proposal-annual-supply.model';

@Component({
    selector: 'app-annual-supply-confirmation',
    templateUrl: './annual-supply-confirmation.component.html',
    styleUrls: ['./annual-supply-confirmation.component.scss'],
})
export class AnnualSupplyConfirmationComponent {
    @Input() id: string;
    @Input() annualSupply: ProposalAnnualSupply;
    @Input() disabled: boolean;
    @Output() change = new EventEmitter<void>();

    onChange() {
        if (!this.annualSupply.IsAnnualSupplyEnabled) {
            this.annualSupply.IsAnnualSupplyFulfilled = false;
        }

        this.change.emit();
    }
}
