<password-expiry></password-expiry>
<div class="container-fluid cards home-nav">
    <div class="row pt-5">
        <div class="col-lg-10 offset-lg-1 px-0">
            <div class="row d-flex justify-content-center">
                <div class="col-3">
                    <div class="card" id="supportorderoverview" [routerLink]="['/ps/supportorderoverview']">
                        <div class="card-body d-flex flex-column align-items-center justify-content-center">
                            <div class="icon">
                                <i class="fas fa-envelope-open-text fa-5x"></i>
                            </div>
                            <div class="title">
                                {{ 'ps.supportorderoverview' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="card" id="clientmaintenanceoverview" [routerLink]="['/ps/clientmaintenanceoverview']">
                        <div class="card-body d-flex flex-column align-items-center justify-content-center">
                            <div class="icon">
                                <i class="fas fa-users fa-5x"></i>
                            </div>
                            <div class="title">
                                {{ 'ps.clientmaintenance' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3" *ngIf="complaintsOverviewTileEnabled()">
                    <div class="card" id="complaintsoverview" [routerLink]="['/ps/complaintsoverview']">
                        <div class="card-body d-flex flex-column align-items-center justify-content-center">
                            <div class="icon">
                                <i class="fa fa-reply fa-5x"></i>
                            </div>
                            <div class="title">
                                {{ 'service.complaintsOverview' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3" *ngIf="scanTileEnabled()">
                    <div class="card" [routerLink]="['/service/scan']">
                        <div class="card-body d-flex flex-column align-items-center justify-content-center">
                            <div class="icon">
                                <i class="fa fa-barcode fa-5x"></i>
                            </div>
                            <div class="title">
                                {{ 'general.startScan' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
