<div
    *ngIf="displayPasswordExpiryMessage"
    [ngClass]="
        displayPasswordExpiryWarning
            ? 'alert alert-danger passwordExpiryMessage'
            : 'alert alert-info passwordExpiryMessage'
    "
    role="alert">
    {{ 'passwordExpiry.willExpire' | translate: { daysUntilExpiry: daysUntilExpiry } }}
    {{ 'passwordExpiry.changePasswordInformation' | translate: { daysUntilExpiry: daysUntilExpiry } }}
    <a class="settingsLink" href="#" (click)="showSettings($event)">
        {{ 'general.settings' | translate | lowercase }}.
    </a>
    {{ 'passwordExpiry.resetPasswordInformation' | translate }}
    <a class="settingsLink" href="/login/resetpassword">{{ 'general.passwordResetPage' | translate | lowercase }}.</a>
    <span *ngIf="displayPasswordExpiryWarning">
        {{ 'passwordExpiry.warning' | translate }}
    </span>
</div>
