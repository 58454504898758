<div class="form-group row" [ngClass]="myClass">
    <label *ngIf="label" class="col-form-label" [ngClass]="labelClass">
        <i
            *ngIf="info && infoPosition === 'left'"
            class="fa fa-question-circle"
            popover="{{ info }}"
            triggers="mouseenter:mouseleave"></i>
        {{ label | translate: labelParams }}
        <i
            *ngIf="info && infoPosition === 'right'"
            class="fa fa-question-circle"
            popover="{{ info }}"
            triggers="mouseenter:mouseleave"></i>
    </label>
    <div [ngClass]="controlClass">
        <div [id]="id">{{ displayValue }}</div>
    </div>
</div>
