<div class="container-fluid d-flex">
    <div class="row order-container p-0">
        <div
            class="col-xl-6 col-md-8 col-12 p-4 m-0 message-container"
            *ngIf="proposalReceipt$ | async; let proposalReceipt">
            <div class="row">
                <div class="col-xl-7 col-md-7 col-12 message">
                    <h1 class="pageHeader">
                        {{ 'receiptcreated.pageHeader' | translate }}
                    </h1>
                    <div class="mt-3">
                        {{ 'ordersent.processingInfo' | translate }}
                    </div>

                    <div class="row">
                        <div class="col-5 font-weight-bold">{{ 'receipt.reference' | translate }}:</div>
                        <div class="col-7">
                            <span id="Reference">{{ proposalReceipt.Reference }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5 font-weight-bold">{{ 'receipt.code' | translate }}:</div>
                        <div class="col-7">
                            <span id="Code">{{ proposalReceipt.Code }}</span>
                        </div>
                    </div>

                    <div class="mt-2">
                        {{ 'ordersent.goToStartScreenInfo' | translate }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-7 col-6">
                    <button
                        type="button"
                        id="downloadPdf"
                        class="btn btn-info mt-4 w-100"
                        (click)="downloadPdf(proposalReceipt.Id)">
                        {{ 'general.downloadpdf' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *footer class="d-flex">
    <div>
        <a [routerLink]="['/']">
            <i class="fas fa-arrow-alt-circle-left me-1"></i>
            {{ 'general.home' | translate }}
        </a>
    </div>
</div>
