<ng-container [formGroup]="formGroup">
    <div class="row">
        <div class="col-12" (click)="hideSlider()">
            <div class="c-pointer bold">
                {{ 'fitlens.clearance' | translate }}
            </div>
            <div class="range-slider" *ngIf="showSlider">
                <input
                    class="range-slider_range"
                    type="range"
                    min="-30"
                    max="30"
                    step="1"
                    formControlName="clearance" />
                <span class="range-slider_value">{{ formGroup.controls['clearance'].value | number: '1.0-2' }}</span>

                <div class="range-slider_dots">
                    <div class="dot dot_first" (click)="setClearanceValue(-30)">-30</div>
                    <div class="dot dot_first_1" (click)="setClearanceValue(-25)">-25</div>
                    <div class="dot dot_first_2" (click)="setClearanceValue(-20)">-20</div>
                    <div class="dot dot_first_3" (click)="setClearanceValue(-15)">-15</div>
                    <div class="dot dot_first_4" (click)="setClearanceValue(-10)">-10</div>
                    <div class="dot dot_first_5" (click)="setClearanceValue(-8)">-8</div>
                    <div class="dot dot_first_6" (click)="setClearanceValue(-5)">-5</div>
                    <div class="dot dot_middle" (click)="setClearanceValue(0)">0</div>
                    <div class="dot dot_last_1" (click)="setClearanceValue(5)">5</div>
                    <div class="dot dot_last_2" (click)="setClearanceValue(10)">10</div>
                    <div class="dot dot_last_3" (click)="setClearanceValue(15)">15</div>
                    <div class="dot dot_last_4" (click)="setClearanceValue(20)">20</div>
                    <div class="dot dot_last_5" (click)="setClearanceValue(25)">25</div>
                    <div class="dot dot_last" (click)="setClearanceValue(30)">30</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="isToric()">
        <div class="col-12">
            <div class="range-slider" *ngIf="showSlider">
                <input
                    class="range-slider_range"
                    type="range"
                    min="-30"
                    max="30"
                    step="1"
                    formControlName="clearanceAst" />
                <span class="range-slider_value">{{ formGroup.controls['clearanceAst'].value | number: '1.0-2' }}</span>

                <div class="range-slider_dots">
                    <div class="dot dot_first" (click)="setClearanceAstValue(-30)">-30</div>
                    <div class="dot dot_first_1" (click)="setClearanceAstValue(-25)">-25</div>
                    <div class="dot dot_first_2" (click)="setClearanceAstValue(-20)">-20</div>
                    <div class="dot dot_first_3" (click)="setClearanceAstValue(-15)">-15</div>
                    <div class="dot dot_first_4" (click)="setClearanceAstValue(-10)">-10</div>
                    <div class="dot dot_first_5" (click)="setClearanceAstValue(-8)">-8</div>
                    <div class="dot dot_first_6" (click)="setClearanceAstValue(-5)">-5</div>
                    <div class="dot dot_middle" (click)="setClearanceAstValue(0)">0</div>
                    <div class="dot dot_last_1" (click)="setClearanceAstValue(5)">5</div>
                    <div class="dot dot_last_2" (click)="setClearanceAstValue(10)">10</div>
                    <div class="dot dot_last_3" (click)="setClearanceAstValue(15)">15</div>
                    <div class="dot dot_last_4" (click)="setClearanceAstValue(20)">20</div>
                    <div class="dot dot_last_5" (click)="setClearanceAstValue(25)">25</div>
                    <div class="dot dot_last" (click)="setClearanceAstValue(30)">30</div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
