import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { OrderService } from '@app/core/services/api/order.service';
import { Util } from '@app/shared/helpers/utility.helper';
import { finalize } from 'rxjs/operators';
import { FeatureCategories, Features } from '@app/shared/enums';

@Component({
    selector: 'receipt-order-sent',
    templateUrl: 'receipt-order-sent.component.html',
    styleUrls: ['receipt-order-sent.component.scss'],
})
export class ReceiptOrderSentComponent implements OnInit {
    orderId: number;
    Features = Features;
    FeatureCategories = FeatureCategories;

    constructor(
        private route: ActivatedRoute,
        public router: Router,
        public loaderService: LoaderService,
        public appState: AppStateService,
        public orderService: OrderService,
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((routeParams) => {
            if (!isNaN(routeParams['id'])) {
                this.orderId = +routeParams['id'];
            }
        });
    }

    downloadPdf() {
        this.loaderService.show();
        this.orderService
            .downloadPdf(this.orderId, navigator.language)
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe((file) => {
                Util.openBlobInBrowser(file, file.name);
            });
    }

    home(): void {
        this.router.navigate(['/']);
    }

    gotoOrderStatus(): void {
        this.router.navigate(['/orderstatus']);
    }
}
