<div class="step d-flex step-{{ className }}">
    <div class="step-inner p-2 d-flex align-items-center">
        <div class="d-flex align-items-center mx-auto">
            <div class="step-icon d-flex align-items-center">
                <i *ngIf="iconName" class="fa fa-2x" [ngClass]="'fa-' + iconName"></i>
                <img alt="header" *ngIf="imageName" class="headericon" src="assets/images/{{ imageName }}" />
            </div>

            <div class="ms-2 d-none d-md-block">
                <div class="text-truncate" *ngIf="title" title="{{ title }}">
                    {{ title | truncate: 50 }}
                </div>
                <div class="text-truncate" *ngIf="subtitle" title="{{ subtitle }}">
                    <strong>{{ subtitle | truncate: 50 }}</strong>
                </div>
            </div>
        </div>

        <div class="divider align-self-center ms-4"></div>
    </div>
</div>
