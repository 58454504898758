import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    CreateOrder,
    CreateDirectOrder,
    OrderLineInfo,
    Order,
    CreateProposalReceiptOrder,
    ListOption,
} from '@app/shared/models';
import { GetOrderLineInfoRequest, UpdateOrderMarkMcBaseLensesRequest } from '@app/shared/requestmodels';
import { FileDownloadService } from '@app/core/services/file-download.service';
import { OrderInfo } from '@app/shared/models/orderInfo.model';
import { UpdateOrderLineStatus } from '@app/shared/models/updateOrderLineStatus.model';
import { UpdateOrder } from '@app/professional-service/models/UpdateOrder.model';
import { ApproveOrder } from '@app/professional-service/models/ApproveOrder.model';
import { CancelOrder } from '@app/professional-service/models/CancelOrder.model';
import { OrderInfoMinimalQueryCriteria } from '@app/shared/models/orderInfoMinimalQueryCriteria.model';
import { QueryResult } from '@app/shared/models/queryResult.model';
import { OrderInfoMinimal } from '@app/shared/models/orderInfoMinimal.model';
import { ReOrder } from '@app/professional-service/models/ReOrder.model';
import { UpdateOrderStatus } from '@app/shared/models/updateOrderStatus.model';
import { UpdateGrandVisionOrder } from '@app/professional-service/models/UpdateGrandvisionOrder.model';
import { OrderWithComplexData } from '@app/shared/models/orderWithComplexData.model';
import { UpdateOrderResponse } from '@app/professional-service/models/UpdateOrderResponse.model';
import { OrderLineStatus, OrderStatuses, SupportOrderStatuses } from '@app/shared/enums';
import { OrderListFilter } from '@app/shared/models/order-list-filter.model';
import { HttpUtil } from '@app/shared/utilities/http.util';
import { OrderLines } from '@app/shared/models/order-lines.model';

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    private readonly basePath = 'api/orders';

    constructor(
        private readonly httpClient: HttpClient,
        private fileDownloadService: FileDownloadService,
    ) {}

    public createOrder(createOrder: CreateOrder): Observable<Order> {
        return this.httpClient.post<Order>(this.basePath, createOrder);
    }

    public createWebshopOrder(createDirectOrder: CreateDirectOrder): Observable<Order> {
        return this.httpClient.post<Order>(`${this.basePath}/direct`, createDirectOrder);
    }

    public createSerialOrder(serialId: number, reference: string): Observable<number> {
        const body = {
            SerialId: serialId,
            Reference: reference,
        };

        return this.httpClient.post<number>(`${this.basePath}/serial`, body);
    }

    public createOrderFromProposalReceipt(createProposalReceiptOrder: CreateProposalReceiptOrder): Observable<number> {
        return this.httpClient.post<number>(`${this.basePath}/proposal-receipt`, createProposalReceiptOrder);
    }

    public getById(orderId: number): Observable<Order> {
        return this.httpClient.get<Order>(`${this.basePath}/${orderId}`);
    }

    public getByIdWithComplexData(orderId: number): Observable<OrderWithComplexData> {
        return this.httpClient.get<OrderWithComplexData>(`${this.basePath}/${orderId}/with-lenses`);
    }

    public getOrderInfoById(orderId: number): Observable<OrderInfo> {
        return this.httpClient.get<OrderInfo>(`${this.basePath}/${orderId}/info`);
    }

    public getLastOrderIdForClient(clientId: number): Observable<number> {
        return this.httpClient.get<number>(`${this.basePath}/client/${clientId}/last-order-id`);
    }

    public downloadPdf(orderId: number, culture?: string): Observable<File> {
        const options = {
            params: new HttpParams().set('orderId', orderId),
            headers: culture ? new HttpHeaders().set('Client-Culture', culture) : new HttpHeaders(),
        };

        return this.fileDownloadService.downloadFile(`${this.basePath}/download/pdf`, options, `order_${orderId}.pdf`);
    }

    public downloadShipmentFramePdf(orderId: number): Observable<File> {
        const options = {
            params: new HttpParams().set('orderId', orderId).set('isFrameOrderShipment', true),
        };

        return this.fileDownloadService.downloadFile(
            `${this.basePath}/download/pdf`,
            options,
            `orderShipmentFrame_${orderId}.pdf`,
        );
    }

    public getRecentOrdersForOptician(
        searchString: string,
        orderFilter: OrderLineStatus | null,
        sortBy: string,
        skip?: number,
        take?: number,
    ): Observable<OrderInfo[]> {
        const options = {
            params: new HttpParams()
                .set('searchstring', searchString)
                .set('orderFilter', orderFilter ?? '')
                .set('sortBy', sortBy)
                .set('skip', skip ?? 0)
                .set('take', take ?? 0),
        };

        return this.httpClient.get<OrderInfo[]>(`${this.basePath}/recent`, options);
    }

    public getRecentSupportOrdersForOptician(
        searchString: string,
        supportOrderFilter: SupportOrderStatuses | null,
        sortBy: string,
        skip?: number,
        take?: number,
    ): Observable<OrderInfo[]> {
        const options = {
            params: new HttpParams()
                .set('searchstring', searchString)
                .set('supportOrderFilter', supportOrderFilter ?? '')
                .set('sortBy', sortBy)
                .set('skip', skip ?? 0)
                .set('take', take ?? 0),
        };

        return this.httpClient.get<OrderInfo[]>(`${this.basePath}/recent/support`, options);
    }

    public getRecentOrdersWithNewMessageForOptician(
        searchString: string,
        orderFilter: OrderLineStatus | null,
        sortBy: string,
        skip?: number,
        take?: number,
    ): Observable<OrderInfo[]> {
        const options = {
            params: new HttpParams()
                .set('searchstring', searchString)
                .set('orderFilter', orderFilter ?? '')
                .set('sortBy', sortBy)
                .set('skip', skip ?? 0)
                .set('take', take ?? 0),
        };

        return this.httpClient.get<OrderInfo[]>(`${this.basePath}/recent/new-messages`, options);
    }

    public getOrderLines(clientId?: number, orderId?: number): Observable<OrderLines[]> {
        const options = {
            params: null,
        };
        if (clientId) {
            options.params = new HttpParams().set('clientId', clientId.toString());
        } else if (orderId) {
            options.params = new HttpParams().set('orderId', orderId.toString());
        }

        return this.httpClient.get<OrderLines[]>(`${this.basePath}/lines`, options);
    }

    public getOrderLineInfo(requestModel: GetOrderLineInfoRequest): Observable<OrderLineInfo[]> {
        const options = {
            params: HttpUtil.mapObjectToHttpParams(requestModel),
        };

        return this.httpClient.get<OrderLineInfo[]>(`${this.basePath}/lines/info`, options);
    }

    public updateOrderNulLenzen(requestModel: UpdateOrderMarkMcBaseLensesRequest): Observable<unknown> {
        return this.httpClient.put(`${this.basePath}/mark-as-base-line`, requestModel);
    }

    public updateOrderLineStatus(requestModel: UpdateOrderLineStatus): Observable<unknown> {
        return this.httpClient.post(`${this.basePath}/lines/update-status`, requestModel);
    }

    public updateOrder(updateOrder: UpdateOrder): Observable<UpdateOrderResponse> {
        return this.httpClient.put<UpdateOrderResponse>(this.basePath, updateOrder);
    }

    public approveOrder(approveOrder: ApproveOrder): Observable<Order> {
        return this.httpClient.post<Order>(`${this.basePath}/approve`, approveOrder);
    }

    public cancelOrder(cancelOrder: CancelOrder): Observable<Order> {
        return this.httpClient.post<Order>(`${this.basePath}/cancel`, cancelOrder);
    }

    public find(queryCriteria: OrderInfoMinimalQueryCriteria): Observable<QueryResult<OrderInfoMinimal>> {
        const orderListFilter: OrderListFilter = {
            Skip: queryCriteria.PageIndex,
            Take: queryCriteria.PageSize,
            IsAscending: queryCriteria.OrderByAscending,
            TotalQuantity: Number(queryCriteria.FilterByFields.TotalQuantity),
            OpticianName: queryCriteria.FilterByFields.OpticianName,
            ClientReference: queryCriteria.FilterByFields.ClientReference,
            ClientReference2: queryCriteria.FilterByFields.ClientReference2,
            LensTypeCode: queryCriteria.FilterByFields.LensTypeCode,
            ProposalTypeName: queryCriteria.FilterByFields.ProposalTypeName,
            Message: queryCriteria.FilterByFields.Message,
            OrderReference: queryCriteria.FilterByFields.OrderReference,
            PerosOrderNumber: queryCriteria.FilterByFields.PerosOrderNumber,
            Date: queryCriteria.FilterByFields.Date,
            BirthDate: queryCriteria.FilterByFields.BirthDate,
            ShowNoPoOrderEntered: queryCriteria.ShowNoPoOrderEntered,
            ShowBlockedOrder: queryCriteria.ShowBlockedOrder,
        };

        const options = {
            params: HttpUtil.mapObjectToHttpParams(orderListFilter),
        };

        return this.httpClient.get<QueryResult<OrderInfoMinimal>>(`${this.basePath}`, options);
    }

    public reOrder(requestModel: ReOrder): Observable<Order> {
        return this.httpClient.post<Order>(`${this.basePath}/reorder`, requestModel);
    }

    public updateOrderStatus(requestModel: UpdateOrderStatus): Observable<void> {
        return this.httpClient.put<void>(`${this.basePath}/update-status`, requestModel);
    }

    public getBlockedOrdersCount(): Observable<number> {
        const options = {
            params: new HttpParams().set('orderStatus', OrderStatuses.Blocked),
        };

        return this.httpClient.get<number>(`${this.basePath}/count`, options);
    }

    public exportOrders(orderIds: number[]): Promise<void> {
        return this.fileDownloadService.downloadFileWithBody(`${this.basePath}/spectaris/download`, orderIds);
    }

    public updateGrandvisionOrder(model: UpdateGrandVisionOrder): Observable<unknown> {
        return this.httpClient.put(`${this.basePath}/grandvision`, model);
    }

    public getOrderPriorities(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/priorities/options`);
    }
}
