import { Component, Input } from '@angular/core';
import { Client } from '@app/shared/models';

@Component({
    selector: 'ps-clientattachments',
    templateUrl: './ps-clientattachments.component.html',
})
export class PsClientAttachmentsComponent {
    @Input() client: Client;
}
