<div class="form-group row" *ngIf="!formControlOnly">
    <label for="{{ id }}" class="col-form-label my-auto" [ngClass]="labelClass">
        {{ label | translate: labelParams }}
    </label>
    <div [ngClass]="controlClass">
        <div class="d-flex align-items-center flex-row">
            <input
                class="form-control input-time p-1"
                type="number"
                min="0"
                max="23"
                (keypress)="validateInput($event, true)"
                [value]="hours"
                (input)="hours = $any($event.target).value"
                (blur)="onBlur(true)"
                [ngClass]="timeValid" />
            <div class="p-1">:</div>
            <input
                class="form-control input-time p-1"
                type="number"
                min="0"
                max="59"
                (keypress)="validateInput($event, false)"
                [value]="minutes"
                (input)="minutes = $any($event.target).value"
                (blur)="onBlur(false)"
                [ngClass]="timeValid" />
        </div>
    </div>
</div>
