import { Material } from '@app/shared/models';
import { ProductGroupTranslation } from '@app/shared/models';

export class ProductGroup {
    public Id: number;
    public Code: string;
    public Name: string;
    public Description: string;
    public ContainsProducts: boolean;
    public IsRgpLensGroup: boolean;
    public IsPromotion: boolean;
    public SortOrder: number;
    public Layout: number;
    public PictureUrl: string;
    public ParentId: number;
    public Parent: ProductGroup;
    public Childs: ProductGroup[];
    public ProductGroupTranslations: ProductGroupTranslation[];
    public Materials: Material[];
}
