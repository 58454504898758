import { Client } from './client.model';
import { Optician } from './optician.model';
import { User } from './user.model';
import { OrderLine } from './orderLine.model';
import { Frame } from './frame.model';
import { Treatments } from '@app/shared/enums';

export class Order {
    Id: number;
    UserId: number;
    OpticianId: number;
    ClientId: number;
    ProposalId: number;
    OrderReference: string;
    MiddlewareExportDate: Date;
    PriorityId: number;
    ReadyForMiddlewareExport: boolean;
    SupporterId: number;
    SkipOrderEvaluation: boolean;
    SupportOrderStatusId: number;
    IsCreditOrderCreated: boolean;
    PerosOrderNumber: string;
    PoNumber: string;
    OrderTypeId: number;
    OrderStatusId: number;
    SpareOrderId: number;
    FrameId: number;
    OrderDate: Date;
    GlassTreatmentId: Treatments;
    Frame: Frame;
    Client: Client;
    Optician: Optician;
    Supporter: User;
    OrderLines: OrderLine[];
    Name: string;
    PostCode: string;
    City: string;
    HomeState: string;
    Country: string;
    Street: string;
    HouseNumber: string;
    HouseNumberSuffix: string;
    IsDirectToHome: boolean;
}
