import { Component, OnInit, Input } from '@angular/core';
import { ClientService } from '@app/core/services/api/client.service';
import { MoveClientToDifferentOpticianRequest } from '@app/shared/models/moveClientToDifferentOpticianRequest.model';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ListOption } from '@app/shared/models/listOption.model';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { AlertService } from '@app/shared/appservices/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Client } from '@app/shared/models/client.model';
import { PsClientMaintenanceComponent } from '../ps-clientmaintenance.component';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { finalize } from 'rxjs/operators';
import { Util } from '@app/shared/helpers/utility.helper';
import { OpticianService } from '@app/core/services/api/optician.service';

@Component({
    selector: 'ps-clientactions',
    templateUrl: './ps-clientactions.component.html',
})
export class PsClientActionsComponent implements OnInit {
    @Input() client: Client;

    formGroupOpticians: UntypedFormGroup;

    public selectedOpticianId: number;
    public opticians: ListOption[];

    constructor(
        private readonly clientService: ClientService,
        private readonly opticianService: OpticianService,
        private readonly appState: AppStateService,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        private readonly fb: UntypedFormBuilder,
        private readonly clientMaintenance: PsClientMaintenanceComponent,
        private readonly loaderService: LoaderService,
    ) {}

    async ngOnInit() {
        this.loadOpticians();
        this.createOpticiansForm();
    }

    createOpticiansForm(): void {
        this.formGroupOpticians = this.fb.group({
            currentOptician: [''],
        });
    }

    moveClientToDifferentOptician = (): void => {
        const request = new MoveClientToDifferentOpticianRequest(this.client.Id, this.selectedOpticianId);
        this.clientService.moveClientToDifferentOptician(request).subscribe(() => {
            this.reloadOpticianInfo();
            this.alertService.success(
                this.translateService.instant('moveclient.moveClientToDifferentOpticianSucceededMessage'),
            );
        });
    };

    loadOpticians(): void {
        this.opticianService.getOpticiansByUserName(this.appState.authenticatedUser.UserName).subscribe((result) => {
            this.opticians = result
                .map((op) => new ListOption(op.Id, op.FullName, op.Code))
                .sort((a, b) => a.Name.localeCompare(b.Name));
        });
    }

    setOptician(match: TypeaheadMatch): void {
        this.selectedOpticianId = match.item.Id;
    }

    reloadOpticianInfo() {
        this.clientMaintenance.loadHeaderData().then();
    }

    exportToZip(): void {
        this.loaderService.show();

        this.clientService
            .exportToZip(this.client.Id)
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe((file) => {
                Util.openBlobInBrowser(file, file.name);
            });
    }
}
