import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    CreateLensesReOrderProposalRequest,
    DreamLiteFollowUpRequest,
    LensesFollowUpRequest as LensesFollowUpRequest,
    Proposal,
    LensType,
} from '@app/shared/models/index';
import { ResetOpticianFittedLensesRequest } from '@app/shared/models/resetOpticianFittedLensesRequest.model';
import { CreateSpectaclesReOrderProposalRequest } from '@app/shared/models';
import { CalculateDreamLiteResidualRefractionRequest } from '@app/shared/models/calculateDreamLiteResidualRefractionRequest.model';
import { CalculateDreamLiteResidualRefractionResponse } from '@app/shared/models/calculateDreamLiteResidualRefractionResponse.model';

@Injectable({
    providedIn: 'root',
})
export class ProposalService {
    private readonly basePath = 'api/proposals';

    constructor(private readonly httpClient: HttpClient) {}

    public startWithClient(clientId: number): Observable<Proposal> {
        return this.httpClient.get<Proposal>(`${this.basePath}/start-with-client/${clientId}`);
    }

    public continueProposal(clientId: number): Observable<Proposal> {
        const options = {
            params: new HttpParams().set('clientId', clientId),
        };

        return this.httpClient.get<Proposal>(`${this.basePath}/continuation`, options);
    }

    public deleteUnfinishedProposal(clientId: number): Observable<Proposal> {
        const options = {
            params: new HttpParams().set('clientId', clientId.toString()),
        };

        return this.httpClient.delete<Proposal>(`${this.basePath}/continuation/delete`, options);
    }

    public getAvailableLensTypes(clientId: number): Observable<LensType[]> {
        const options = {
            params: new HttpParams().set('clientId', clientId.toString()),
        };
        return this.httpClient.get<LensType[]>(`${this.basePath}/get-available-lens-types`, options);
    }

    public saveRefractionMeasurements(proposal: Proposal): Observable<Proposal> {
        return this.httpClient.post<Proposal>(`${this.basePath}/save-refraction-measurements`, proposal);
    }

    public recalculateFollowUpLenses(proposal: Proposal): Observable<Proposal> {
        return this.httpClient.post<Proposal>(`${this.basePath}/recalculate-follow-up-lenses`, proposal);
    }

    public calculateDreamLiteRestrefraction(
        request: CalculateDreamLiteResidualRefractionRequest,
    ): Observable<CalculateDreamLiteResidualRefractionResponse> {
        return this.httpClient.post<CalculateDreamLiteResidualRefractionResponse>(
            `${this.basePath}/calculate-dreamlite-residual-refraction`,
            request,
        );
    }

    public saveDreamLiteLensTypeAndCalculateOpticianFittedLenses(proposal: Proposal): Observable<Proposal> {
        return this.httpClient.post<Proposal>(
            `${this.basePath}/save-dreamlite-lens-type-and-calculate-optician-fitted-lenses`,
            proposal,
        );
    }

    public saveLensType(proposal: Proposal): Observable<Proposal> {
        return this.httpClient.post<Proposal>(`${this.basePath}/change-lens-type`, proposal);
    }

    public resetOpticianFittedLenses(request: ResetOpticianFittedLensesRequest): Observable<Proposal> {
        return this.httpClient.post<Proposal>(`${this.basePath}/optician-fitted-lenses/reset`, request);
    }

    public saveOpticianFittedLenses(proposal: Proposal): Observable<Proposal> {
        return this.httpClient.post<Proposal>(`${this.basePath}/save-optician-fitted-lenses`, proposal);
    }

    public saveGlassFittedLenses(proposal: Proposal): Observable<Proposal> {
        return this.httpClient.post<Proposal>(`${this.basePath}/save-glass-fitted-lenses`, proposal);
    }

    public createLensesFollowUpProposal(request: LensesFollowUpRequest): Observable<Proposal> {
        return this.httpClient.post<Proposal>(`${this.basePath}/create-lenses-follow-up-proposal`, request);
    }

    public createSpectaclesFollowUpProposal(orderId: number): Observable<Proposal> {
        return this.httpClient.post<Proposal>(`${this.basePath}/spectacles-follow-up`, orderId);
    }

    public createDreamLiteFollowUpProposal(request: DreamLiteFollowUpRequest): Observable<Proposal> {
        return this.httpClient.post<Proposal>(`${this.basePath}/create-dreamlite-follow-up-proposal`, request);
    }

    public createLensesReOrderProposal(request: CreateLensesReOrderProposalRequest): Observable<Proposal> {
        return this.httpClient.post<Proposal>(`${this.basePath}/lenses-reorder`, request);
    }

    public createSpectaclesReOrderProposal(request: CreateSpectaclesReOrderProposalRequest): Observable<Proposal> {
        return this.httpClient.post<Proposal>(`${this.basePath}/spectacles-reorder`, request);
    }

    public getById(proposalId: number): Observable<Proposal> {
        return this.httpClient.get<Proposal>(`${this.basePath}/${proposalId}`);
    }

    public eligibleForWarranty(proposal: Proposal): Observable<void> {
        return this.httpClient.post<void>(`${this.basePath}/eligible-for-warranty`, proposal);
    }

    public saveTopographicMeasurements(proposal: Proposal): Observable<Proposal> {
        return this.httpClient.post<Proposal>(`${this.basePath}/save-topographic-measurements`, proposal);
    }
}
