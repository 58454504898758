import { LensAlignment } from './lensAlignment.model';
import { TiltMethods } from '../enums';

export class FluoImageOptions {
    UseAxis = true;
    TiltMethod: TiltMethods.none;
    HasFrontoric = false;

    LeftLensAlignment = new LensAlignment();
    RightLensAlignment = new LensAlignment();
}
