import { Component, OnInit, OnDestroy } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { WordpressApiService } from '@app/core/services/external/wordpress-api.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Post } from '@app/shared/models/post.model';

@Component({
    templateUrl: 'read-more-dialog.component.html',
    providers: [WordpressApiService],
})
export class ReadMoreDialogComponent implements OnInit, OnDestroy {
    closeModals: Subscription;
    id: number;
    loadingPost = false;
    post: Post;

    constructor(
        public appState: AppStateService,
        public bsModalRef: BsModalRef,
        public wordpressApiService: WordpressApiService,
    ) {}

    ngOnInit(): void {
        this.closeModals = this.appState.closeModals$.subscribe(() => {
            this.bsModalRef.hide();
        });

        this.wordpressApiService
            .getPost(this.id)
            .pipe(
                finalize(() => {
                    this.loadingPost = false;
                }),
            )
            .subscribe((result) => {
                this.post = result;
            });
    }

    ngOnDestroy(): void {
        if (this.closeModals) {
            this.closeModals.unsubscribe();
        }
    }
}
