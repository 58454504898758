<div class="m-4">
    <div class="title-border-bottom">
        <h3>
            {{ 'service.newrequest' | translate }} -
            {{ 'service.pdfdownloadpage' | translate }}
        </h3>
    </div>
    <div class="row justify-content-center mt-4">
        <div class="col-6">
            <div>
                <b>{{ 'service.pdfdownloadtext1' | translate }}</b>
                <br />
                {{ 'service.pdfdownloadtext2' | translate }}
            </div>
        </div>
    </div>
</div>

<div *footer>
    <div class="d-flex w-100 footer">
        <div class="ms-auto">
            <button class="btn procornea-green" (click)="downloadPdf()">
                {{ 'service.download' | translate }}
            </button>
        </div>
    </div>
</div>
