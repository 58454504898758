<div class="container-fluid p-0 user-select-none">
    <div class="row">
        <div class="col-12">
            <h1>
                {{ 'general.add-annual-supply' | translate }}
            </h1>
        </div>
    </div>
    <div class="row accented-background rounded mx-0 p-3">
        <div class="col-md-12">
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="{{ id }}-IsAnnualSupplyEnabled"
                    [disabled]="disabled"
                    (change)="onChange()"
                    [(ngModel)]="annualSupply.IsAnnualSupplyEnabled" />
                <label class="form-check-label" for="{{ id }}-IsAnnualSupplyEnabled">
                    {{ 'general.add-annual-supply-disclaimer' | translate }}
                </label>
            </div>
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    [disabled]="!annualSupply.IsAnnualSupplyEnabled || disabled"
                    id="{{ id }}-IsAnnualSupplyFulfilled"
                    [(ngModel)]="annualSupply.IsAnnualSupplyFulfilled" />
                <label class="form-check-label" for="{{ id }}-IsAnnualSupplyFulfilled">
                    {{ 'general.add-annual-supply-fullfill-disclaimer' | translate }}
                </label>
            </div>
        </div>
    </div>
</div>
