import { Role } from './role.model';
import { Optician } from './optician.model';
import { Language } from './language.model';

export class User {
    public Id: number;
    public DistributorId: number;
    public UserName: string;
    public EmailAddress: string;
    public Name: string;
    public Opticians: Optician[];
    public TranslatorLanguages: Language[];
    public Roles: Role[];
    public IsDeactivated: boolean;
    public PasswordExpiryDate: Date;
    public PasswordNeverExpires: boolean;
    public CanUseExternalLogin: boolean;
}
