import { Component, Input } from '@angular/core';

@Component({
    selector: 'ps-header-item',
    templateUrl: './ps-header-item.component.html',
    styleUrls: ['ps-header-item.component.scss'],
})
export class PsHeaderItemComponent {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() iconName: string;
    @Input() imageName: string;
    @Input() className: string;

    constructor() {
        // Do nothing
    }
}
