import { OrderLine } from '@app/shared/models';

export class CreateDirectOrder {
    public OrderLines: OrderLine[];
    public ClientId: number;
    public OrderReference: string;
    public Comments: string;
    public IsStockOrder: boolean;
    public DirectToHome: unknown;
}
