import {
    Component,
    Input,
    ViewEncapsulation,
    Optional,
    AfterViewInit,
    ViewChild,
    ElementRef,
    Host,
    SkipSelf,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ListOption } from '@app/shared/models';
import { BaseControl } from '@app/shared/components/inputs/base-control';

@Component({
    selector: 'mpc-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SelectComponent,
            multi: true,
        },
    ],
})
export class SelectComponent extends BaseControl implements AfterViewInit {
    @ViewChild('select') selectElement: ElementRef;

    _focus = false;

    @Input() noSelectionLabel = '';
    @Input() allowNoSelection = false;

    @Input() translationKey = '';
    @Input() items: Array<ListOption>;

    @Input()
    get focus() {
        return this._focus;
    }
    set focus(value: boolean | string) {
        this._focus = value != null && `${value}` !== 'false';
    }

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        public controlContainer: ControlContainer,
    ) {
        super(controlContainer);
    }

    writeValue(value: string): void {
        this.value = value || '';
    }

    propagateChange(value: string): void {
        this.onChange(value);
        this.onTouched();
    }

    ngAfterViewInit(): void {
        if (this._focus) {
            setTimeout(() => {
                this.selectElement.nativeElement.focus();
            }, 0);
        }
    }

    customTrackBy(index: number): number {
        return index;
    }
}
