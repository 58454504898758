<div class="container-fluid d-flex">
    <div class="row order-container p-0">
        <div class="col-xl-6 col-md-8 col-12 p-4 m-0 message-container">
            <div class="row">
                <div class="col-xl-8 col-md-8 col-12 message">
                    <h1 class="orderPageHeader">{{ pageHeader | translate }}</h1>

                    <div *ngIf="isCreditOrder" class="mt-3 p-3 border">
                        <i class="fas fa-info-circle"></i>
                        {{ 'ordersent.iscreditorder' | translate }}
                    </div>
                    <ng-container
                        *hasFeatureSetting="[
                            {
                                Feature: Features.BlockOrders,
                                Category: FeatureCategories.Optician,
                                IsEnabled: false
                            }
                        ]">
                        <div class="mt-3">
                            {{ processingInfo | translate }}
                        </div>
                        <div class="mt-2">
                            {{ progressReviewInfo | translate }}
                        </div>
                        <div class="mt-2">
                            {{ goToStartScreenInfo | translate }}
                        </div>
                    </ng-container>
                    <ng-container
                        *hasFeatureSetting="[
                            {
                                Feature: Features.BlockOrders,
                                Category: FeatureCategories.Optician,
                                IsEnabled: true
                            }
                        ]">
                        <div class="mt-3">
                            {{ 'ordersent.blocked' | translate }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-7 col-6">
                    <button type="button" id="next" class="btn btn-info mt-4 w-100" (click)="downloadPdf()">
                        {{ 'general.downloadpdf' | translate }}
                    </button>
                    <button type="button" id="next" class="btn btn-default mt-4 w-100" (click)="gotoOrderStatus()">
                        {{ 'ordersent.orderstatus' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-md-4 d-flex video-controls">
            <button type="button" id="next" class="ms-auto btn" (click)="changePlayback()">
                <i *ngIf="play" class="fas fa-pause"></i>
                <i *ngIf="!play" class="fas fa-play"></i>
            </button>
        </div>
        <div class="col-12 p-0 background">
            <video autoplay loop #orderVideo>
                <source src="assets/videos/mypro-bestelpagina.mp4" type="video/mp4" />
            </video>
        </div>
    </div>
</div>
<div *footer class="d-flex">
    <div>
        <a [routerLink]="['/']">
            <i class="fas fa-arrow-alt-circle-left me-1"></i>
            {{ 'general.home' | translate }}
        </a>
    </div>
</div>
