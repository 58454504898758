import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListOption } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class VisualAcuityService {
    private readonly basePath = 'api/visual-acuities';

    constructor(private readonly httpClient: HttpClient) {}

    public getVisualAcuityTypes(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/types/options`);
    }

    public getVisualAcuityValues(visualAcuityTypeId: number): Observable<ListOption[]> {
        const options = {
            params: new HttpParams().set('id', visualAcuityTypeId.toString()),
        };

        return this.httpClient.get<ListOption[]>(`${this.basePath}/values/options`, options);
    }
}
