<div class="registrationWrapper flex-fill d-flex flex-grow-1">
    <div class="form ms-4 mt-5">
        <form class="mt-5" [formGroup]="formGroup" (ngSubmit)="register()">
            <div class="logo-visavy">
                <img src="assets/images/logo-visavy.svg" alt="Visavy" />
            </div>

            <div class="header">
                <h1>{{ 'registeroptician.title' | translate }}</h1>
            </div>
            <div class="description">
                {{ 'registeroptician.description' | translate }}
            </div>

            <div class="header">
                <h2>{{ 'registeroptician.opticiansection.header' | translate }}</h2>
            </div>

            <div class="row" [ngClass]="{ 'has-error': isSubmitted && !formControls.OpticianName.valid }">
                <div class="col-md-12">
                    <h3>{{ 'general.name' | translate }}</h3>
                    <input
                        type="text"
                        class="transparent-input"
                        id="OpticianName"
                        name="OpticianName"
                        formControlName="OpticianName" />
                </div>
            </div>
            <div class="row" [ngClass]="{ 'has-error': isSubmitted && !formControls.City.valid }">
                <div class="col-md-12">
                    <h3>{{ 'general.city' | translate }}</h3>
                    <input type="text" class="transparent-input" id="City" name="City" formControlName="City" />
                </div>
            </div>

            <div class="row" [ngClass]="{ 'has-error': isSubmitted && !formControls.OpticianEmail.valid }">
                <div class="col-md-12">
                    <h3>{{ 'general.emailAddress' | translate }}</h3>
                    <input
                        type="text"
                        class="transparent-input"
                        id="OpticianEmail"
                        name="OpticianEmail"
                        formControlName="OpticianEmail" />
                </div>
            </div>

            <div class="row" [ngClass]="{ 'has-error': isSubmitted && !formControls.OpticianPhone.valid }">
                <div class="col-md-12">
                    <h3>{{ 'general.phoneNumber' | translate }}</h3>
                    <input
                        type="text"
                        class="transparent-input"
                        id="OpticianPhone"
                        name="OpticianPhone"
                        formControlName="OpticianPhone" />
                </div>
            </div>

            <div class="row" [ngClass]="{ 'has-error': isSubmitted && !formControls.VatNumber.valid }">
                <div class="col-md-12">
                    <h3>{{ 'general.vatnumber' | translate }}</h3>
                    <input
                        type="text"
                        class="transparent-input"
                        id="VatNumber"
                        name="VatNumber"
                        formControlName="VatNumber" />
                </div>
            </div>

            <div class="header">
                <h2>{{ 'registeroptician.usersection.header' | translate }}</h2>
            </div>
            <div class="row" [ngClass]="{ 'has-error': isSubmitted && !formControls.PersonalName.valid }">
                <div class="col-md-12">
                    <h3>{{ 'registeroptician.usersection.header' | translate }}</h3>
                    <input
                        type="text"
                        class="transparent-input"
                        id="PersonalName"
                        name="PersonalName"
                        formControlName="PersonalName" />
                </div>
            </div>
            <div class="row" [ngClass]="{ 'has-error': isSubmitted && !formControls.UserName.valid }">
                <div class="col-md-12">
                    <h3>{{ 'login.username' | translate }}</h3>
                    <input
                        type="text"
                        class="transparent-input"
                        id="UserName"
                        name="UserName"
                        formControlName="UserName" />
                </div>
            </div>
            <div class="row" [ngClass]="{ 'has-error': isSubmitted && !formControls.UserEmail.valid }">
                <div class="col-md-12">
                    <h3>{{ 'general.emailAddress' | translate }}</h3>
                    <input
                        type="text"
                        class="transparent-input"
                        id="UserEmail"
                        name="UserEmail"
                        formControlName="UserEmail" />
                </div>
            </div>
            <div class="header">
                <h2>{{ 'registeroptician.receiptsection.header' | translate }}</h2>
                <div
                    class="row"
                    [ngClass]="{
                        'has-error': isSubmitted && !formControls.ReceiptReference.valid
                    }">
                    <div class="col-md-12">
                        <h3>{{ 'receipt.reference' | translate }}</h3>
                        <input
                            type="text"
                            class="transparent-input"
                            id="ReceiptReference"
                            name="ReceiptReference"
                            formControlName="ReceiptReference" />
                    </div>
                </div>
                <div class="row" [ngClass]="{ 'has-error': isSubmitted && !formControls.ReceiptCode.valid }">
                    <div class="col-md-12">
                        <h3>{{ 'receipt.code' | translate }}</h3>
                        <input
                            type="text"
                            class="transparent-input"
                            id="ReceiptCode"
                            name="ReceiptCode"
                            formControlName="ReceiptCode" />
                    </div>
                </div>
            </div>
            <div class="row registrationError" *ngIf="registrationError">
                <div class="col-md-12">
                    <label class="text-danger">{{ registrationError }}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-7 col-sm-12">
                    <button id="login" [disabled]="formGroup.invalid" class="btn btn-primary btn-block">
                        {{ 'registeroptician.submitbutton' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
