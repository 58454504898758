import { QueryCriteria } from '@app/shared/models/queryCriteria.model';
import { ServiceStatus } from '@app/shared/enums';
import { ReturnInfoMinimal } from './returnsInfoMinimal';

export class ReturnsPerRoleQueryCriteria extends QueryCriteria<ReturnInfoMinimal> {
    constructor(returnQueryCriteria?: ReturnsPerRoleQueryCriteria) {
        super(returnQueryCriteria);
    }

    public returnStatusIds: number[];
    public creditCategory: boolean;

    public setDefault(): void {
        this.OrderByAscending = true;
        this.OrderByField = '';
        this.PageIndex = 0;
        this.PageSize = 50;
        this.UsePaging = true;
        this.returnStatusIds = [ServiceStatus.AwaitingProduct];
        this.creditCategory = false;
    }
}
