<div class="container notch-container">
    <div class="flex flex-row">
        <div class="flex flex-col">
            <div>
                <div class="canvas-container">
                    <img class="notch-image" [src]="'/assets/lens-notch-eye-' + eyeSide + '.png'" />
                    <canvas #canvas class="notch-canvas" width="240" height="240">
                        {{ 'notch.no-preview' | translate }}
                    </canvas>
                </div>
            </div>
            <div *ngIf="!changesSaved" class="col text-warning p-0">{{ 'notch.notSaved' | translate }}</div>
        </div>
        <div class="flex flex-col">
            <div class="flex flex-grow flex-col" [formGroup]="notchForm" *ngIf="!isReadOnly; else readOnly">
                <mpc-input-number
                    formControlName="position"
                    [id]="'position'"
                    [ranges]="positionRange"
                    label="notch.position"
                    [breakpoint]="'sm'"></mpc-input-number>

                <mpc-input-number
                    formControlName="recessDepth"
                    [id]="'recessDepth'"
                    [ranges]="recessDepthRange"
                    label="notch.recessDepth"
                    [breakpoint]="'sm'"></mpc-input-number>

                <mpc-input-number
                    formControlName="recessChord"
                    [id]="'recessChord'"
                    [ranges]="recessChordRange"
                    label="notch.recessChord"
                    [breakpoint]="'sm'"></mpc-input-number>

                <mpc-input-number
                    formControlName="stabilization"
                    [id]="'stabilisation'"
                    [ranges]="stabilisationRanges"
                    label="fitlens.stabilisation"
                    [isDisabled]="stabilizationIsDisabled"
                    [breakpoint]="'sm'"></mpc-input-number>

                <div class="flex notch-buttons flex-row">
                    <button class="btn btn-warning" (click)="removeLensNotchParameters()" [disabled]="!hasNotch">
                        {{ 'general.delete' | translate }}
                    </button>
                    <button
                        class="btn btn-default ms-2"
                        (click)="setLensNotchReadOnlyAndApplyNotch()"
                        [disabled]="!hasNotch || changesSaved || notchForm.invalid">
                        {{ 'general.apply' | translate }}
                    </button>
                </div>
            </div>

            <ng-template #readOnly>
                <div class="position">
                    <span id="position-title">
                        {{ 'notch.position' | translate }}
                    </span>
                    <span id="position-value">
                        {{ notchForm.controls['position'].value }}
                    </span>
                </div>
                <div class="recessDepth">
                    <span id="recessDepth-title">
                        {{ 'notch.recessDepth' | translate }}
                    </span>
                    <span id="recessDepth-value">
                        {{ notchForm.controls['recessDepth'].value }}
                    </span>
                </div>
                <div class="recessChord">
                    <span id="recessChord-title">
                        {{ 'notch.recessChord' | translate }}
                    </span>
                    <span id="recessChord-value">
                        {{ notchForm.controls['recessChord'].value }}
                    </span>
                </div>

                <div class="stabilisation">
                    <span id="stabilisation-title">
                        {{ 'fitlens.stabilisation' | translate }}
                    </span>
                    <span id="stabilisation-value">
                        {{ notchForm.controls['stabilization'].value }}
                    </span>
                </div>

                <div class="flex notch-buttons">
                    <button class="btn btn-default ms-2" (click)="removeLensNotchParameters()">
                        {{ 'general.reset' | translate }}
                    </button>
                </div>
            </ng-template>
        </div>
    </div>
</div>
