<div class="process-nav-ps process-nav-1 d-flex">
    <div class="process-nav-inner d-flex mx-auto px-1">
        <div class="w-100 d-flex">
            <ng-container *ngFor="let navbaritem of navbaritems; let i = index">
                <div class="flex-grow-1 d-flex"></div>
                <navbar-item
                    [navbarItem]="navbaritem"
                    [navbarClass]="i === active ? 'step-current' : 'step-future'"
                    (click)="onNavbarItem(navbaritem, i)"></navbar-item>
            </ng-container>
        </div>
    </div>
</div>
