import { Component, OnInit, Input } from '@angular/core';
import { FitLensComponent } from '../fitlens.component';
import { FittedLens, ListOption, MaterialColor } from '@app/shared/models';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LensTypes, LensFilters } from '@app/shared/enums';
import { FittedLensOptions } from '@app/shared/models/fittedLensOptions.model';
import { InputNumberRange } from '@app/shared/models/input-number-range.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'lens-selection',
    templateUrl: 'lensselection.component.html',
})
export class LensSelectionComponent implements OnInit {
    @Input() fitlens: FitLensComponent;
    @Input() quantity = 1;

    quantityRange: InputNumberRange;

    public lensSelectionFormGroup: UntypedFormGroup;

    constructor(
        private readonly fb: UntypedFormBuilder,
        public translate: TranslateService,
    ) {}

    ngOnInit() {
        this.quantityRange = new InputNumberRange(1, this.maxQuantity, 1, 1);
        this.createForm();
    }

    createForm(): void {
        this.lensSelectionFormGroup = this.fb.group({
            quantity: [this.quantity],
            lensFilter: [LensFilters.all],
            lensDefinitionId: [this.fittedLens ? this.fittedLens.LensDefinitionId : null, [Validators.required]],
            materialId: [
                this.fittedLens && this.fittedLens.MaterialColor ? this.fittedLens.MaterialColor.MaterialId : '',
                [Validators.required],
            ],
            colorId: [
                this.fittedLens && this.fittedLens.MaterialColor ? this.fittedLens.MaterialColor.ColorId : '',
                [Validators.required],
            ],
            lensEngravingId: [this.fittedLens ? this.fittedLens.LensEngravingId : ''],
            lensThicknessId: [this.fittedLens ? this.fittedLens.LensThicknessId : ''],
            parameters: this.fb.array([]),
            lensName: this.fittedLens.LensDefinition.Name,
        });

        if (this.canShowMarkings) {
            this.formControls['lensEngravingId'].setValidators([Validators.required]);
            this.formControls['lensEngravingId'].updateValueAndValidity();
        }

        if (this.canShowThickness) {
            this.formControls['lensThicknessId'].setValidators([Validators.required]);
            this.formControls['lensThicknessId'].updateValueAndValidity();
        }
    }

    get maxQuantity(): number {
        if (this.fittedLens.LensDefinition && this.fittedLens.LensDefinition.Product) {
            return this.fittedLens.LensDefinition.Product.MaxQuantity;
        }

        return 1;
    }

    changeLensDefinition(): void {
        this.formControls['quantity'].setValue(1);
        this.formControls['quantity'].updateValueAndValidity();
        this.quantityRange = new InputNumberRange(1, this.maxQuantity, 1, 1);

        this.fitlens.changeLensDefinition(this.formControls['lensDefinitionId'].value);
    }

    changeMaterial($event): void {
        this.setColors($event.target.value);
        this.fitlens.handleMaterialChange();
    }

    changeColor(): void {
        this.fitlens.handleMaterialChange();
    }

    changeThickness(): void {
        this.fitlens.handleThicknessChange();
    }

    changeLensEngraving(): void {
        this.fitlens.handleLensEngravingChange();
    }

    changeQuantity(): void {
        this.fitlens.handleQuantityChange(this.formControls['quantity'].value);
    }

    setColors(materialId: number): void {
        if (this.options.ShowMaterialField) {
            const allcolors = this.fitlens.getMaterialColors;
            const filteredcolors = allcolors
                .filter((c) => c.MaterialId === +materialId)
                .map((mc) => new ListOption(mc.Color.Id, mc.Color.Name, mc.Color.Code));

            this.fitlens.setColors(filteredcolors);

            if (filteredcolors.length > 0) {
                this.formControls['colorId'].patchValue(filteredcolors[0].Id, {
                    emitEvent: false,
                });
            }
        } else {
            this.formControls['colorId'].patchValue(null, { emitEvent: false });
        }
    }

    onConvertToMc(): void {
        this.fitlens.dreamliteOptions.convertToMc();
    }

    onConverToDreamlite(): void {
        this.fitlens.dreamliteOptions.convertToDreamlite();
    }

    onConverToDreamliteMC(): void {
        this.fitlens.dreamliteOptions.convertToDreamliteMC();
    }

    onConvertToMf(): void {
        this.fitlens.dreamliteOptions.convertToMf();
    }

    get canShowMarkings(): boolean {
        return (
            this.fitlens.options && this.fitlens.options.ShowMarkingsField && this.fitlens.lensTypeId !== LensTypes.Soft
        );
    }

    get canShowThickness(): boolean {
        return (
            this.fitlens.options &&
            this.fitlens.options.ShowThicknessField &&
            this.fitlens.lensTypeId !== LensTypes.DreamLite
        );
    }

    get colors(): ListOption[] {
        return this.fitlens.getColors;
    }

    get filteredLensDefinitions() {
        return this.fitlens.getFilteredLensDefinitions();
    }

    get fittedLens(): FittedLens {
        return this.fitlens.fittedLens;
    }

    getSupportPopupText(): string {
        const supportPopupText = this.translate.instant('fitlens.orderthroughsupport');
        return String(supportPopupText).replace('{{dreamliteName}}', this.fitlens.fittedLens.LensDefinition.Name);
    }
    get formControls() {
        return this.lensSelectionFormGroup.controls;
    }

    get formDisabled(): boolean {
        return this.fitlens.formDisabled;
    }

    get id(): string {
        return this.fitlens.id;
    }

    get isLensDefinitionDisposable(): boolean {
        return this.fittedLens && this.fittedLens.LensDefinition && this.fittedLens.LensDefinition.Disposable;
    }

    get lensDefinitionId(): number {
        return +this.formControls['lensDefinitionId'].value;
    }

    get lensEngravingId(): number {
        return +this.formControls['lensEngravingId'].value;
    }

    get lensThicknessId(): number {
        return +this.formControls['lensThicknessId'].value;
    }

    get lensEngravings() {
        return this.fitlens.getLensEngravings;
    }

    get lensThickness() {
        return this.fitlens.getLensThickness;
    }

    get lensSelectionEnabled(): boolean {
        if (this.fitlens.isPs || this.fitlens.isDistributorSupport) {
            return true;
        }

        if (
            !this.fitlens.isDreamLiteFollowUp &&
            !this.fitlens.showConvertToMcButton &&
            !this.fitlens.showConvertToMfButton &&
            !this.fitlens.convertedToMf
        ) {
            return true;
        }

        return false;
    }

    get loading(): boolean {
        return this.fitlens.loading;
    }

    get materialColor(): MaterialColor {
        return this.fitlens.materialColors.find(
            (mc) =>
                mc.MaterialId === Number(this.formControls['materialId'].value) &&
                mc.ColorId === Number(this.formControls['colorId'].value),
        );
    }

    get materials(): ListOption[] {
        return this.fitlens.getMaterials;
    }

    get options(): FittedLensOptions {
        return this.fitlens.options;
    }

    get showConvertToMcButton(): boolean {
        return this.fitlens.showConvertToMcButton;
    }

    get showConvertToMfButton(): boolean {
        return this.fitlens.showConvertToMfButton;
    }

    get showConvertToDreamliteButton(): boolean {
        return this.fitlens.showConvertToDreamliteButton;
    }

    get showConvertToDreamliteMCButton(): boolean {
        return this.fitlens.showConvertToDreamliteMCButton;
    }

    get isWebshop(): boolean {
        return this.fitlens.isWebshop;
    }
}
