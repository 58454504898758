import { InputTypes } from '../enums';
import { LensDefinitionParameter } from './lens-definition-parameter.model';

export class FittedLensParameter {
    public Id: number;
    public FittedLensId: number;
    public LensDefinitionParameterId: number;
    public Value: number;
    public TextValue: string;
    public CalculationValid: boolean;
    public LensDefinitionParameter: LensDefinitionParameter;

    get GenericValue() {
        return this.isNumber ? this.Value : this.TextValue;
    }

    set GenericValue(value: string | number) {
        if (this.isNumber) {
            this.Value = value as number;
        } else {
            this.TextValue = value as string;
        }
    }

    get isNumber() {
        return this.LensDefinitionParameter.ParameterType.InputTypeId === InputTypes.Number;
    }
}
