import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { LensDefinitionParameterNumberRange, UserSetting } from '@app/shared/models';
import { TopographicManualCalculation } from '@app/shared/models/topographicManualCalculation.model';
import { TranslateService } from '@ngx-translate/core';
import { MeasurementUnits } from '@app/shared/enums';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { UserSettingService } from '@app/core/services/api/user-setting.service';
import { lastValueFrom } from 'rxjs';
@Component({
    selector: 'measurement-manual',
    templateUrl: 'measurement-manual.component.html',
})
export class MeasurementManualComponent implements OnInit {
    @Input() id: string;
    @Input() eyeSide: number;
    @Input() manualCalculation: TopographicManualCalculation;

    loading = true;
    showAxisAngleFlatField = false;
    formGroup: UntypedFormGroup;
    generalUnitToUse: string;
    mmStart = 7.857;
    mmMinimum = 5.0;
    mmMaximum = 10.0;
    mmStep = 0.05;

    dptMaximum = this.convertMmToDptOrDptToMm(this.mmMinimum); // 67.5  dpt using minimum mm
    dptMinimum = this.convertMmToDptOrDptToMm(this.mmMaximum); // 33.75 dpt using maximum mm
    dptStart = this.convertMmToDptOrDptToMm(this.mmStart);

    userSettings: UserSetting;
    validationTextInfo = '';

    get formControls() {
        return this.formGroup.controls;
    }

    rZeroParameterTypeRange: Array<LensDefinitionParameterNumberRange>;
    axisAngleParameterTypeRange: Array<LensDefinitionParameterNumberRange>;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly userSettingService: UserSettingService,
        private readonly translate: TranslateService,
        private readonly appState: AppStateService,
    ) {}

    async ngOnInit() {
        const existingCalculation = !!this.manualCalculation;
        if (!existingCalculation) {
            this.manualCalculation = new TopographicManualCalculation();
        }
        this.createForm();
        this.userSettings = await lastValueFrom(this.userSettingService.getUserSettings());
        if (this.userSettings.UseMm === MeasurementUnits.DPT) {
            if (existingCalculation) {
                this.setFormValuesToDpt();
            }
            this.setToDpt();
        }

        this.fillRanges();
        this.loading = false;
    }

    setFormValuesToDpt() {
        this.manualCalculation.RZeroFlat = this.convertMmToDptOrDptToMm(this.manualCalculation.RZeroFlat);
        this.manualCalculation.RZeroSteep = this.convertMmToDptOrDptToMm(this.manualCalculation.RZeroSteep);
        this.formControls['rZeroFlat'].setValue(this.manualCalculation.RZeroFlat);
        this.formControls['rZeroSteep'].setValue(this.manualCalculation.RZeroSteep);
    }

    createForm() {
        const axisAngleFlatValidators = [];
        if (this.appState.currentCompany.ManualCalculationConfiguration !== 'ManualCalculationConfiguration1') {
            axisAngleFlatValidators.push(Validators.required);
            this.showAxisAngleFlatField = true;
        }

        this.formGroup = this.fb.group({
            rZeroFlat: [this.manualCalculation.RZeroFlat, Validators.required],
            rZeroSteep: [this.manualCalculation.RZeroSteep, Validators.required],
            axisAngleFlat: [this.manualCalculation.AxisAngle, axisAngleFlatValidators],
        });

        this.generalUnitToUse = this.translate.instant('general.units.MM');
        this.validationTextInfo = this.translate.instant('measurement.rzerovalidation');
        this.formGroup.setValidators(this.rSteepSmallerOrEqualTorFlat);
    }

    setToDpt() {
        this.generalUnitToUse = this.translate.instant('general.units.DPT');
        this.validationTextInfo = this.translate.instant('measurement.rzerovalidation.dpt');
        this.formGroup.setValidators(this.rSteepLargerOrEqualTorFlat);
    }

    rSteepLargerOrEqualTorFlat(control: AbstractControl): ValidationErrors {
        const flat = control.get('rZeroFlat');
        const steep = control.get('rZeroSteep');

        if (flat && steep && flat.value <= steep.value) {
            // no errors
            return null;
        }

        return { rSteepSmallerOrEqualTorFlat: true };
    }

    rSteepSmallerOrEqualTorFlat(control: AbstractControl): ValidationErrors {
        const flat = control.get('rZeroFlat');
        const steep = control.get('rZeroSteep');

        if (flat && steep && flat.value >= steep.value) {
            // no errors
            return null;
        }

        return { rSteepSmallerOrEqualTorFlat: true };
    }

    fillRanges(): void {
        this.rZeroParameterTypeRange = new Array<LensDefinitionParameterNumberRange>();
        this.axisAngleParameterTypeRange = new Array<LensDefinitionParameterNumberRange>();

        const lensDefToPush =
            this.userSettings.UseMm === MeasurementUnits.DPT
                ? new LensDefinitionParameterNumberRange(this.dptMinimum, this.dptMaximum, this.mmStep, this.dptStart)
                : new LensDefinitionParameterNumberRange(this.mmMinimum, this.mmMaximum, this.mmStep, this.mmStart);

        this.rZeroParameterTypeRange.push(lensDefToPush);
        this.axisAngleParameterTypeRange.push(new LensDefinitionParameterNumberRange(0, 180, 1));
    }

    getManualCalculation(): TopographicManualCalculation {
        this.manualCalculation.RZeroFlat = Number(this.formControls['rZeroFlat'].value);
        this.manualCalculation.RZeroSteep = Number(this.formControls['rZeroSteep'].value);
        this.manualCalculation.AxisAngle = Number(this.formControls['axisAngleFlat'].value);
        if (this.userSettings.UseMm === MeasurementUnits.DPT) {
            this.manualCalculation.RZeroFlat = this.convertMmToDptOrDptToMm(this.manualCalculation.RZeroFlat);
            this.manualCalculation.RZeroSteep = this.convertMmToDptOrDptToMm(this.manualCalculation.RZeroSteep);
        }
        return this.manualCalculation;
    }

    convertMmToDptOrDptToMm(valueToConvert: number): number {
        /**
         * 1 Diopter = 337.5 milimeter
         * 1 milimeter = 337.5 diopter
         *
         * 337.5 / mm = diopter
         * 337.5 / diopter = mm
         */
        return 337.5 / valueToConvert;
    }
}
