import { Injectable } from '@angular/core';
import { CreditSetting } from '@app/shared/models/creditSetting';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreditSampleStatistic } from '@app/shared/models/creditSampleStatistic';
import { CreditLimitNotification } from '@app/shared/models/creditLimitNotification';

@Injectable({
    providedIn: 'root',
})
export class CreditService {
    private readonly basePath = 'api/credits';

    constructor(private readonly httpClient: HttpClient) {}

    public saveCreditSettings(creditSettings: CreditSetting[]): Observable<CreditSetting[]> {
        return this.httpClient.post<CreditSetting[]>(`${this.basePath}/settings`, creditSettings);
    }

    public getCreditSettings(): Observable<CreditSetting[]> {
        return this.httpClient.get<CreditSetting[]>(`${this.basePath}/settings`);
    }

    public getCreditLimitNotifications(): Observable<CreditLimitNotification[]> {
        return this.httpClient.get<CreditLimitNotification[]>(`${this.basePath}/limit-notifications`);
    }

    public getCreditCheckStatistics(): Observable<CreditSampleStatistic[]> {
        return this.httpClient.get<CreditSampleStatistic[]>(`${this.basePath}/sample-statistics`);
    }
}
