import { BaseValidator } from '@app/shared/validators/BaseValidator';
import { FitTypes, ParameterTypeCodes } from '@app/shared/enums';
import { UntypedFormArray, ValidationErrors } from '@angular/forms';
import { FitLensComponent } from '../fitlens.component';

export class ToricityShouldBeValidValidator extends BaseValidator {
    bind(obj: FitLensComponent): ValidationErrors {
        if (obj.formGroup && obj.fittedLensParameters && obj.fittedLens.LensDefinition.FitTypeId === FitTypes.It) {
            const params = obj.formGroup.controls['parameters'] as UntypedFormArray;

            const bcrVlak = params.controls[obj.getFittedLensParameterIndex(ParameterTypeCodes.RAD)];
            const bcrSteil = params.controls[obj.getFittedLensParameterIndex(ParameterTypeCodes.ARAD)];

            let toricityIsValid = true;

            if (bcrVlak != null && bcrSteil != null) {
                let delta = 0;

                if (bcrVlak.value > bcrSteil.value) {
                    delta = bcrVlak.value - bcrSteil.value;
                    toricityIsValid = Math.round(delta * 100) < 25;
                } else {
                    // bcrVlak must be higher then bcrSteil
                    toricityIsValid = true;
                }

                if (toricityIsValid) {
                    return { toricityShouldBeValid: true };
                }
            }
            return null;
        }
    }
}
