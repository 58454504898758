import { FittedLens } from './fitted-lens.model';
import { Proposal } from './proposal.model';

export class CartAddItem {
    Proposal: Proposal;
    ProductId: number;
    Quantity: number;
    ClientId: number;
    LeftFittedLensQuantity: number;
    LeftFittedLensId: number;
    LeftFittedLens: FittedLens;
    RightFittedLensQuantity: number;
    RightFittedLensId: number;
    RightFittedLens: FittedLens;
    Reference: string;
    IsSpareOrder: boolean;
    AddSpareOrder: boolean;
}
