import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Client, RefractionMeasurement } from '@app/shared/models/index';
import { ClientSearchRequest } from '@app/shared/requestmodels/index';
import { MyopiePowChart } from '@app/shared/models/myopiaPowChart.model';
import { ClientInfoQueryCriteria } from '@app/shared/models/ClientInfoQueryCriteria.model';
import { ClientList } from '@app/shared/models/ClientList.model';
import { FileDownloadService } from '@app/core/services/file-download.service';
import { FindClientsRequest } from '@app/shared/models/findClientsRequest.model';
import { FindClientsResponse } from '@app/shared/models/findClientsResponse.model';
import { MoveClientToDifferentOpticianRequest } from '@app/shared/models/moveClientToDifferentOpticianRequest.model';
import { ClientListFilter } from '@app/shared/models/client-list-filter.model';
import { ClientNote } from '@app/shared/models/ClientNote.model';
import { HttpUtil } from '@app/shared/utilities/http.util';

// TODO: Replace bodies with proper commands/ queries.
@Injectable({
    providedIn: 'root',
})
export class ClientService {
    private readonly basePath = 'api/clients';

    constructor(
        private readonly httpClient: HttpClient,
        private readonly fileDownloadService: FileDownloadService,
    ) {}

    public getById(clientId: number): Observable<Client> {
        return this.httpClient.get<Client>(`${this.basePath}/${clientId}`);
    }

    public search(clientSearchRequest: ClientSearchRequest): Observable<Client[]> {
        const options = {
            params: HttpUtil.mapObjectToHttpParams(clientSearchRequest, true),
        };

        return this.httpClient.get<Client[]>(`${this.basePath}/search`, options);
    }

    public save(client: Client): Observable<Client> {
        return this.httpClient.post<Client>(`${this.basePath}`, client);
    }

    public registerClientOpened(clientId: number): Observable<unknown> {
        return this.httpClient.post<unknown>(`${this.basePath}/${clientId}/opened`, null);
    }

    public getRecentlyOpenedClients(): Observable<Client[]> {
        return this.httpClient.get<Client[]>(`${this.basePath}/recently-opened`);
    }

    public saveMyopia(clientId: number, myopia: boolean): Observable<void> {
        const body = { clientId, myopia };

        return this.httpClient.post<void>(`${this.basePath}/myopia`, body);
    }

    public getMyopiaAxisLength(clientId: number): Observable<RefractionMeasurement[]> {
        return this.httpClient.get<RefractionMeasurement[]>(`${this.basePath}/${clientId}/myopia-axis-length`);
    }

    public getMyopiaPowImage(clientId: number): Observable<MyopiePowChart> {
        return this.httpClient.get<MyopiePowChart>(`${this.basePath}/${clientId}/myopia-pow-chart`);
    }

    public getClientList(clientInfoQueryCriteria: ClientInfoQueryCriteria): Observable<ClientList> {
        const clientListFilter = this.mapClientInfoQueryCriteriaToClientListFilter(clientInfoQueryCriteria);
        clientListFilter.ShowNotifications = false;

        const options = {
            params: HttpUtil.mapObjectToHttpParams(clientListFilter),
        };

        return this.httpClient.get<ClientList>(this.basePath, options);
    }

    public getClientReplacementNotifications(clientInfoQueryCriteria: ClientInfoQueryCriteria): Observable<ClientList> {
        const clientListFilter = this.mapClientInfoQueryCriteriaToClientListFilter(clientInfoQueryCriteria);
        clientListFilter.ShowNotifications = true;

        const options = {
            params: HttpUtil.mapObjectToHttpParams(clientListFilter),
        };

        return this.httpClient.get<ClientList>(this.basePath, options);
    }

    public getClientReplacementNotificationsCount(): Observable<number> {
        return this.httpClient.get<number>(`${this.basePath}/replacement-notification-count`);
    }

    public getClientReplacementNotificationsForClientPage(): Observable<unknown> {
        return this.httpClient.get(`${this.basePath}/notification-count`);
    }

    public removeClient(id: number): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${this.basePath}/${id}`);
    }

    public removeReplacementNotifications(orderIds: number[]): Observable<unknown> {
        return this.httpClient.post(`${this.basePath}/block-replacement-notifications`, orderIds);
    }

    public mergeClients(mergeFromClientId: number, mergeIntoClientId: number): Observable<boolean> {
        const body = { mergeFromClientId, mergeIntoClientId };

        return this.httpClient.post<boolean>(`${this.basePath}/merge`, body);
    }

    public exportClients(lensType: string): Observable<File> {
        return this.fileDownloadService.downloadFile(`${this.basePath}/export/` + lensType);
    }

    public find(findClientsRequest: FindClientsRequest): Observable<FindClientsResponse[]> {
        let params = new HttpParams();
        Object.keys(findClientsRequest).forEach((key) => {
            if (findClientsRequest[key]) {
                params = params.set(key, findClientsRequest[key]);
            }
        });

        return this.httpClient.get<FindClientsResponse[]>(`${this.basePath}/find`, {
            params,
        });
    }

    public moveClientToDifferentOptician(request: MoveClientToDifferentOpticianRequest): Observable<unknown> {
        return this.httpClient.post(`${this.basePath}/move-to-different-optician`, request);
    }

    public exportToZip(clientId: number): Observable<File> {
        return this.fileDownloadService.downloadFile(`${this.basePath}/${clientId}/export`);
    }

    public getDeletedClientReference(clientId: number): Observable<HttpResponse<string>> {
        return this.httpClient.get<HttpResponse<string>>(`${this.basePath}/${clientId}/reference`);
    }

    public saveNote(clientNote: ClientNote): Observable<ClientNote> {
        return this.httpClient.post<ClientNote>(`${this.basePath}/notes`, clientNote);
    }

    public getClientNotesByClientId(clientId: number): Observable<ClientNote[]> {
        const options = {
            params: new HttpParams().set('clientId', clientId.toString()),
        };

        return this.httpClient.get<ClientNote[]>(`${this.basePath}/notes`, options);
    }

    // TODO: Work with correct filter model.
    private mapClientInfoQueryCriteriaToClientListFilter(
        clientInfoQueryCriteria: ClientInfoQueryCriteria,
    ): ClientListFilter {
        return {
            ClientReferenceNumber: clientInfoQueryCriteria.FilterByFields['Reference'],
            ClientReferenceName: clientInfoQueryCriteria.FilterByFields['Reference2'],
            DateOfBirth: clientInfoQueryCriteria.FilterByFields['BirthDate'],
            OrderDate: clientInfoQueryCriteria.FilterByFields['OrderDate'],
            GenderId: clientInfoQueryCriteria.FilterByFields['GenderId'],
            LensTypeId:
                clientInfoQueryCriteria.FilterByFields['LensTypeId'].length > 0
                    ? Number(clientInfoQueryCriteria.FilterByFields['LensTypeId'])
                    : undefined,
            ShowNotifications: clientInfoQueryCriteria.FilterByFields['ShowNotifications'],
            Skip: clientInfoQueryCriteria.PageIndex,
            Take: clientInfoQueryCriteria.PageSize,
            IsAscending: clientInfoQueryCriteria.OrderByAscending,
        };
    }
}
