<div
    [formGroup]="formGroupFixType"
    *ngIf="isDreamLiteFollowUp"
    class="diagnosis form-group flex-fill"
    [ngClass]="{ rounded: !appState.isMobile }">
    <div class="row pb-2 pt-2" *ngIf="isFixTypeRangesReady">
        <div class="col-7 position-relative">
            <div *ngIf="disableConfiguration" class="disabledConfig">
                {{ 'fitlens.configurationdisabled' | translate }}
                <i
                    class="fa fa-question-circle ms-1"
                    triggers="mouseenter:mouseleave"
                    popover="{{ 'fitlens.configurationdisabledextra' | translate }}"></i>
            </div>

            <div *ngIf="isMultifocal">
                <mpc-input-number
                    info="{{ 'fitlens.multifocaladittion' | translate }}"
                    formControlName="multiFocalAddition"
                    [id]="id + '-multiFocalAddition'"
                    [ranges]="[additionParameterTypeRange]"
                    label="{{ 'lens.nearAdd' | translate }}"
                    [labelWidth]="6"
                    [breakpoint]="'sm'"></mpc-input-number>
            </div>
            <div>
                <mpc-input-number
                    formControlName="lessOrMuchEffect"
                    [id]="id + '-lessOrMuchEffect'"
                    [ranges]="fixTypeRanges['LessOrMuchEffect']"
                    label="{{ lessOrMuchFixType ? lessOrMuchFixType.Name : '' }}"
                    [labelWidth]="6"
                    [breakpoint]="'sm'"></mpc-input-number>
            </div>
            <div>
                <mpc-input-number
                    formControlName="adjustClearance"
                    [id]="id + '-adjustClearance'"
                    [ranges]="fixTypeRanges['AdjustClearance']"
                    label="{{ adjustClearanceFixType ? adjustClearanceFixType.Name : '' }}"
                    [labelWidth]="6"
                    [breakpoint]="'sm'"></mpc-input-number>
            </div>
            <div class="mt-4">
                <h6>{{ 'general.configuration' | translate }}</h6>
            </div>
            <div class="d-flex mt-3 flex-wrap">
                <mpc-input-checkbox
                    formControlName="fixTypeSetOz"
                    [asButton]="true"
                    [id]="id + '-fixTypeSetOz'"
                    [isDisabled]="isOzFixTypeDisabled"
                    label="{{ ozFixType ? ozFixType.Name : '' }}"></mpc-input-checkbox>
                <mpc-input-checkbox
                    formControlName="fixTypeLateral"
                    [asButton]="true"
                    [id]="id + '-fixTypeLateral'"
                    label="{{ lateralFixType ? lateralFixType.Name : '' }}"
                    class="ms-3"></mpc-input-checkbox>
            </div>
            <div class="d-flex flex-wrap">
                <mpc-input-checkbox
                    formControlName="fixTypeLow"
                    [asButton]="true"
                    [id]="id + '-fixTypeLow'"
                    [isDisabled]="selectedRidingFixType === 'high'"
                    (change)="setSelectedRidingFixType('low')"
                    label="{{ lowRidingFixType ? lowRidingFixType.Name : '' }}"></mpc-input-checkbox>
                <mpc-input-checkbox
                    formControlName="fixTypeHigh"
                    [asButton]="true"
                    [id]="id + '-fixTypeHigh'"
                    [isDisabled]="selectedRidingFixType === 'low'"
                    (change)="setSelectedRidingFixType('high')"
                    label="{{ highRidingFixType ? highRidingFixType.Name : '' }}"
                    class="ms-3"></mpc-input-checkbox>
            </div>
        </div>
        <div class="col-5 d-flex justify-content-center">
            <div class="top-buffer padding-bottom" ng-if="!isLoading">
                <div class="block-header h5">
                    {{ 'measurement.diffmap' | translate }}
                </div>
                <div class="measurementimage" (click)="clickMeasurementImage($event, fitlens.topoMeasurement)">
                    <topo-image-thumbnail
                        [measurement]="fitlens.topoMeasurement"
                        [previousMeasurementId]="fitlens.previousMeasurementId"
                        [imageOptions]="fitlens.diffMapImageOptions"></topo-image-thumbnail>
                </div>
            </div>
        </div>
    </div>
</div>
