import { Product } from './product.model';
import { LensDefinitionParameter } from './lens-definition-parameter.model';
import { LensDefinitionGeometryElement } from './lensDefinitionGeometryElement.model';

export class LensDefinition {
    constructor() {
        this.Product = new Product();
        this.LensDefinitionParameters = new Array<LensDefinitionParameter>();
        this.LensDefinitionGeometryElements = new Array<LensDefinitionGeometryElement>();
    }

    Id: number;
    Name: string;
    Code: string;
    LensTypeId: number;
    FitTypeId: number;
    Spherical: boolean;
    MultiFocal: boolean;
    Frontoric: boolean;
    MaterialModifiable: boolean;
    Disposable: boolean;
    Myopie: boolean;
    NaturalFitTypeId: number;
    CanUseNaturalFit: boolean;
    LeftDefaultMaterialColorId: number;
    RightDefaultMaterialColorId: number;
    LensDefinitionParameters: LensDefinitionParameter[];
    Product: Product;
    LensDefinitionGeometryElements: LensDefinitionGeometryElement[];
    IsStandardLens: boolean;
    IsSpherical: boolean;
    IsToric: boolean;
    IsMultifocal: boolean;
    IsMultifocalToric: boolean;
    IsHighAddition: boolean;
    IsInsideToric: boolean;
    IsFitLens: boolean;
    OriginalLensDefinitionIdForFitLens: number;
    IsActiveForUser: boolean;
}
