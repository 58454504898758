import { Component, Input, EventEmitter, Output, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TopographicMeasurement, FittedLens, RefractionMeasurement } from '@app/shared/models';
import { ImageOptions } from '@app/shared/models/image-options.model';
import { EyeSides, MeasurementImageModes, ProductGroups } from '@app/shared/enums';
import { FluoImageRequest } from '@app/shared/models/fluoImageRequest.model';
import { FluoImageResponse } from '@app/shared/models/fluoImageResponse.model';
import { HoverPositionCalculator } from '@app/shared/helpers/hoverposition.calculator';
import { HeightMapImageResponse } from '@app/shared/models/heightMapImageResponse.model';
import { LensProfileImageResponse } from '@app/shared/models/lensProfileImageResponse.model';
import { TopoImageResponse } from '@app/shared/models/topoImageResponse.model';
import { HeightMapImageRequest } from '@app/shared/models/heightMapImageRequest.model';
import { TopographicImage } from '@app/shared/models/topographicImage.model';
import { NumberFormatPipe } from '@app/shared/pipes/number.format.pipe';
import { LensProfileImageRequest } from '@app/shared/models/lensProfileImageRequest.model';
import { FluoImageService } from '@app/core/services/api/fluo-image.service';
import { HeigthMapService } from '@app/core/services/api/height-map.service';
import { LensProfileImageService } from '@app/core/services/api/lens-profile.service';
import { TopographicImageService } from '@app/core/services/api/topographic-image.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'measurement-image',
    templateUrl: 'measurement-image.component.html',
    styleUrls: ['measurement-image.component.scss'],
})
export class MeasurementImageComponent implements OnInit, OnDestroy {
    @Input() id: string;
    @Input() eyeSide: number;
    @Input() fittedLens: FittedLens;
    @Input() topographicMeasurement: TopographicMeasurement;
    @Input() refractionMeasurement: RefractionMeasurement;
    @Input() imageOptions: ImageOptions;
    @Input() displayBaselineTag = true;

    @Output() isLoading: EventEmitter<boolean> = new EventEmitter();
    @Output() topoImageChanged: EventEmitter<TopoImageResponse> = new EventEmitter();

    imageRequestSubscription: Subscription;

    fluoImage: FluoImageResponse;
    heightMapImage: HeightMapImageResponse;
    lensProfileImage: LensProfileImageResponse;
    topoImage: TopoImageResponse;

    imageError = false;
    imageErrorMessage = 'general.errorloadingimage';
    imageId: string;

    radius: string;
    fluoThickness: number;
    height: string;
    eValue: string;
    simKRadiusFlat: number;
    simKRadiusSteep: number;

    loading = false;

    private mousemoveUnsubscribeActions: (() => void)[] = [];

    constructor(
        private readonly zone: NgZone,
        private readonly topographicImageService: TopographicImageService,
        private readonly numberFormatPipe: NumberFormatPipe,
        private readonly fluoImageService: FluoImageService,
        private readonly heigthMapService: HeigthMapService,
        private readonly lensProfileImageService: LensProfileImageService,
    ) {}

    ngOnInit(): void {
        this.refresh(this.fittedLens);
    }

    ngOnDestroy(): void {
        if (this.imageRequestSubscription) {
            this.imageRequestSubscription.unsubscribe();
        }
        this.removeImageMouseEvents();
    }

    private initializeImageMouseEvents(): void {
        this.removeImageMouseEvents();
        // subscribe to mousemove events outside the angular zone, because otherwise
        // a change detection will be triggered for every mousemove event for all objects
        // of the entire application
        this.zone.runOutsideAngular(() => {
            this.subscribeTo(this.id + '-image-topo', 'mousemove', (ev) =>
                this.zone.run(() => this.onTopoHoverOrClick(ev)),
            );
            this.subscribeTo(this.id + '-image-topo', 'mouseout', () => this.zone.run(() => this.onTopoMouseOut()));
            this.subscribeTo(this.id + '-image-fluo', 'mousemove', (ev) =>
                this.zone.run(() => this.onFluoHoverOrClick(ev)),
            );
            this.subscribeTo(this.id + '-image-fluo', 'mouseout', () => this.zone.run(() => this.onFluoMouseOut()));
            this.subscribeTo(this.id + '-image-heightmap', 'mousemove', (ev) =>
                this.zone.run(() => this.onHeightMapHoverOrClick(ev)),
            );
            this.subscribeTo(this.id + '-image-heightmap', 'mouseout', () =>
                this.zone.run(() => this.onHeightMapMouseOut()),
            );
        });
    }

    private removeImageMouseEvents(): void {
        this.mousemoveUnsubscribeActions.forEach((u) => u());
        this.mousemoveUnsubscribeActions = [];
    }

    private subscribeTo(id: string, eventName: string, action: (ev: MouseEvent) => void) {
        const image = window.document.getElementById(id);

        if (!image) {
            return;
        }

        image.addEventListener(eventName, action);

        this.mousemoveUnsubscribeActions.push(() => image.removeEventListener(eventName, action));
    }

    refresh(refreshFittedLens: FittedLens): void {
        this.fittedLens = refreshFittedLens;
        let imageMode: MeasurementImageModes;

        switch (this.eyeSide) {
            case EyeSides.Os:
                imageMode = this.imageOptions.leftImageMode;
                break;
            case EyeSides.Od:
                imageMode = this.imageOptions.rightImageMode;
                break;
        }

        if (
            ProductGroups.isScleralProductGroup(this.fittedLens?.LensDefinition.Product.ProductGroup.Code) &&
            imageMode === MeasurementImageModes.Fluo
        ) {
            imageMode = MeasurementImageModes.LensProfile;
        }

        switch (imageMode) {
            case MeasurementImageModes.HeightMap:
                this.getHeightMapImage();
                break;
            case MeasurementImageModes.Topo:
                this.getTopoImage();
                break;
            case MeasurementImageModes.Fluo:
                this.getFluoImage();
                break;
            case MeasurementImageModes.LensProfile:
                this.getLensProfileImage();
                break;
        }
    }

    private setLoading(loading: boolean) {
        this.loading = loading;

        setTimeout(() => {
            this.isLoading.emit(loading);
        });

        if (!loading) {
            setTimeout(() => this.initializeImageMouseEvents(), 500);
        }
    }

    imageOptionsChanged(imageOptions: ImageOptions): void {
        this.imageOptions = imageOptions;
        this.refresh(this.fittedLens);
    }

    private getFluoImage(): void {
        if (this.imageRequestSubscription) {
            this.imageRequestSubscription.unsubscribe();
        }

        if (this.fittedLens && this.fittedLens.LensDefinitionId) {
            this.setLoading(true);
            this.imageError = false;

            this.imageOptions.fluoImageOptions.UseAxis = this.imageOptions.UseAxis;

            const imageRequest = new FluoImageRequest();
            imageRequest.FittedLens = this.fittedLens;

            if (
                this.topographicMeasurement &&
                this.topographicMeasurement.Id !== this.fittedLens.TopographicMeasurementId
            ) {
                imageRequest.FittedLens.TopographicMeasurementId = this.topographicMeasurement.Id;
                imageRequest.FittedLens.TopographicMeasurement = this.topographicMeasurement;
            }

            imageRequest.FluoImageOptions = this.imageOptions.fluoImageOptions;

            if (
                this.fittedLens.LensDefinition &&
                (this.fittedLens.LensDefinition.Frontoric || this.fittedLens.LensDefinition.IsInsideToric)
            ) {
                this.imageOptions.fluoImageOptions.HasFrontoric = true;
            }

            this.imageRequestSubscription = this.fluoImageService
                .getFluoImage(imageRequest)
                .pipe(
                    catchError(() => {
                        this.imageError = true;
                        return of(null);
                    }),
                )
                .subscribe((result) => {
                    this.fluoImage = result;

                    this.heightMapImage = null;
                    this.topoImage = null;
                    this.lensProfileImage = null;

                    this.setLoading(false);
                });
        } else if (this.fittedLens && !this.fittedLens.LensDefinitionId) {
            this.fluoImage = new FluoImageResponse();
        }
    }

    private getHeightMapImage(): void {
        if (this.imageRequestSubscription) {
            this.imageRequestSubscription.unsubscribe();
        }

        if (
            this.fittedLens &&
            this.imageOptions &&
            (this.topographicMeasurement || this.fittedLens.TopographicMeasurement) &&
            this.refractionMeasurement
        ) {
            this.setLoading(true);
            this.imageError = false;

            this.imageOptions.heightMapImageOptions.UseAxis = this.imageOptions.UseAxis;

            const imageRequest = new HeightMapImageRequest();
            imageRequest.TopographicMeasurementId = this.topographicMeasurement
                ? this.topographicMeasurement.Id
                : this.fittedLens.TopographicMeasurementId;
            imageRequest.HeightMapOptions = this.imageOptions.heightMapImageOptions;
            imageRequest.CorneaDiameter = this.refractionMeasurement.CorneaDiameter;
            imageRequest.FittedLens = this.fittedLens;

            this.imageRequestSubscription = this.heigthMapService
                .getHeightMapImage(imageRequest)
                .pipe(
                    catchError(() => {
                        this.imageError = true;
                        return of(null);
                    }),
                )
                .subscribe((result) => {
                    this.heightMapImage = result;

                    this.topoImage = null;
                    this.fluoImage = null;
                    this.lensProfileImage = null;

                    this.setLoading(false);
                });
        } else if (this.fittedLens) {
            this.heightMapImage = new HeightMapImageResponse();
        }
    }

    private getTopoImage(): void {
        if (this.imageRequestSubscription) {
            this.imageRequestSubscription.unsubscribe();
        }

        if ((this.topographicMeasurement || this.fittedLens.TopographicMeasurement) && this.imageOptions) {
            this.setLoading(true);
            this.imageError = false;

            this.imageOptions.topoImageOptions.UseAxis = this.imageOptions.UseAxis;

            const topographicImage = new TopographicImage();
            topographicImage.TopographicMeasurementId = this.topographicMeasurement
                ? this.topographicMeasurement.Id
                : this.fittedLens.TopographicMeasurementId;
            topographicImage.TopographicImageOptions = this.imageOptions.topoImageOptions;
            topographicImage.TopographicImageOptions.ImagePixelWidth = 400;
            topographicImage.TopographicImageOptions.ImagePixelHeight = 400;
            topographicImage.PreviousTopographicMeasurementId = this.topographicMeasurement
                ? this.topographicMeasurement.Id
                : this.fittedLens.TopographicMeasurementId;

            this.imageRequestSubscription = this.topographicImageService
                .createTopographicImage(topographicImage)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        const errorMessages = error.error.errors.Errors as Array<string>;

                        this.imageErrorMessage = errorMessages.includes('Invalid data to create image')
                            ? 'general.error-loading-image-invalid-data'
                            : 'general.errorloadingimage';

                        this.imageError = true;
                        return of(null);
                    }),
                )
                .subscribe((result) => {
                    this.topoImage = result;
                    this.redrawCanvas();

                    this.heightMapImage = null;
                    this.fluoImage = null;
                    this.lensProfileImage = null;

                    this.updateSimKRadius();

                    this.topoImageChanged.emit(this.topoImage);

                    this.setLoading(false);
                });
        }
    }

    private getLensProfileImage(): void {
        if (this.imageRequestSubscription) {
            this.imageRequestSubscription.unsubscribe();
        }

        if (this.fittedLens) {
            if (this.fittedLens.LensDefinitionId && this.fittedLens.TopographicMeasurementId) {
                this.setLoading(true);
                this.imageError = false;
                this.imageOptions.lensProfileImageOptions.AlignLens = this.imageOptions.UseAxis;

                const imageRequest = new LensProfileImageRequest();
                imageRequest.FittedLens = this.fittedLens;
                imageRequest.LensProfileImageOptions = this.imageOptions.lensProfileImageOptions;

                this.imageRequestSubscription = this.lensProfileImageService
                    .getLensProfileImage(imageRequest)
                    .pipe(
                        catchError(() => {
                            this.imageError = true;
                            return of(null);
                        }),
                    )
                    .subscribe((result) => {
                        this.lensProfileImage = result;

                        this.heightMapImage = null;
                        this.topoImage = null;
                        this.fluoImage = null;

                        this.setLoading(false);
                    });
            } else {
                this.lensProfileImage = new LensProfileImageResponse();
            }
        }
    }

    private redrawCanvas() {
        if (this.imageId) {
            const canvas = document.getElementById(this.imageId + '-canvas') as HTMLCanvasElement;
            canvas?.removeAttribute('mp-rendered');
        }
    }

    private getCanvasFor(image: HTMLImageElement): CanvasRenderingContext2D {
        const imageId = image.id;
        const canvas = document.getElementById(imageId + '-canvas') as HTMLCanvasElement;

        if (!canvas) {
            throw new Error('Canvas not found for image ' + imageId);
        }

        const ctx = canvas.getContext('2d', { willReadFrequently: true });

        if (!canvas.getAttribute('mp-rendered')) {
            this.imageId = imageId;
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0, image.width, image.height);
            canvas.setAttribute('mp-rendered', 'true');
        }

        return ctx;
    }

    private getPixelDataFromMousePosition(event: MouseEvent): Uint8ClampedArray {
        const image = <HTMLImageElement>event.target;
        const ctx = this.getCanvasFor(image);

        const posX = event.offsetX;
        const posY = event.offsetY;

        return ctx.getImageData(posX, posY, 1, 1).data;
    }

    public onFluoHoverOrClick(event: MouseEvent): void {
        const pixelData = this.getPixelDataFromMousePosition(event);

        //Get fluo thickness
        const key = '0,' + pixelData[1] + ',0';
        for (let i = 0; i < this.fluoImage.ColorDataMappings.length; i++) {
            if (this.fluoImage.ColorDataMappings[i].RgbValue === key) {
                this.fluoThickness = this.fluoImage.ColorDataMappings[i].Value;
            }
        }
    }

    private onFluoMouseOut(): void {
        this.fluoThickness = null;
    }

    public onHeightMapHoverOrClick(event: MouseEvent): void {
        const pixelData = this.getPixelDataFromMousePosition(event);

        //Get height
        const key = pixelData[0] + ',' + pixelData[1] + ',' + pixelData[2];

        for (let i = 0; i < this.heightMapImage.ColorDataMappings.length; i++) {
            if (this.heightMapImage.ColorDataMappings[i].RgbValue === key) {
                this.height = this.heightMapImage.ColorDataMappings[i].Value.toFixed(0) + ' µm';
            }
        }
    }

    private onHeightMapMouseOut(): void {
        this.height = '';
    }

    public onTopoHoverOrClick(event: MouseEvent): void {
        const pixelData = this.getPixelDataFromMousePosition(event);

        const posX = event.offsetX;
        const posY = event.offsetY;
        const calculater = new HoverPositionCalculator();
        const centerPointX = this.topoImage.CenterPoint[0];
        const centerPointY = this.topoImage.CenterPoint[1];
        const x = calculater.calculateCurrentXPositionRelativeToCenter(posX, this.topoImage.ImageSizeMm, centerPointX);
        const y = calculater.calculateCurrentYPositionRelativeToCenter(posY, this.topoImage.ImageSizeMm, centerPointY);

        this.radius = '';

        let currentRadius: number;

        //Get radius
        const key = pixelData[0] + ',' + pixelData[1] + ',' + pixelData[2];

        for (let i = 0; i < this.topoImage.ColorDataMappings.length; i++) {
            if (this.topoImage.ColorDataMappings[i].RgbValue === key) {
                currentRadius = this.topoImage.ColorDataMappings[i].Value;
                this.radius = currentRadius.toFixed(4);
            }
        }

        const mouseAngle = Math.atan2(y, x);
        const distanceToCenter = Math.sqrt(x * x + y * y);

        if (distanceToCenter < 2.5) {
            // E waarde dicht bij centrum niet nauwkeurig te bepalen en is over het algemeen ook niet interessant
            this.eValue = '';
        } else {
            const refAxis = (this.topographicMeasurement.SimKAxisFlat / 180.0) * Math.PI;
            //Get e-value
            const ch = 1 / this.simKRadiusFlat;
            const cv = 1 / this.simKRadiusSteep;
            const r0 = 1 / (ch + (cv - ch) * Math.sin(mouseAngle - refAxis) * Math.sin(mouseAngle - refAxis));

            const q = -(currentRadius * currentRadius - r0 * r0) / (distanceToCenter * distanceToCenter);
            let e: number;

            if (q > 0) {
                e = -Math.sqrt(q);
            } else {
                e = Math.sqrt(-q);
            }

            if (isNaN(e)) {
                this.eValue = '';
            } else {
                this.eValue = this.numberFormatPipe.transform(e, {
                    decimalCount: '2',
                });
            }
        }
    }

    private onTopoMouseOut(): void {
        this.radius = '';
        this.eValue = '';
    }

    private updateSimKRadius(): void {
        if (this.topographicMeasurement) {
            if (this.imageOptions.topoImageOptions.UseMm) {
                this.simKRadiusFlat = this.topographicMeasurement.SimKRadiusFlat;
                this.simKRadiusSteep = this.topographicMeasurement.SimKRadiusSteep;
            } else {
                this.simKRadiusFlat = 337.5 / this.topographicMeasurement.SimKRadiusFlat;
                this.simKRadiusSteep = 337.5 / this.topographicMeasurement.SimKRadiusSteep;
            }
        }
    }
}
