import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HistoricalMeasurements } from '@app/shared/models/historical-measurements';
import { TopographicManualCalculation } from '@app/shared/models/topographicManualCalculation.model';
import { TopographicManualCalculationWithMeasurement } from '@app/shared/models/TopographicManualCalculationWithMeasurement';
import { Proposal } from '@app/shared/models';
import { SaveBaseLineTopographicMeasurementsForClientRequest } from '@app/shared/models/SaveBaseLineTopographicMeasurementsForClientRequest.model';
import { TopographicMeasurements } from '@app/shared/models/topographicMeasurements.model';
import { GetTopographicMeasurementsByClientIdRequest } from '@app/shared/requestmodels';
import { HttpUtil } from '@app/shared/utilities/http.util';

@Injectable({
    providedIn: 'root',
})
export class TopographicMeasurementService {
    private readonly basePath = 'api/topographic-measurements';

    constructor(private readonly httpClient: HttpClient) {}

    public getAvailableTopographicMeasurements(): Observable<TopographicMeasurements> {
        return this.httpClient.get<TopographicMeasurements>(`${this.basePath}/available`);
    }

    public getTopographicMeasurementsByClientId(
        requestModel: GetTopographicMeasurementsByClientIdRequest,
    ): Observable<TopographicMeasurements> {
        const options = {
            params: HttpUtil.mapObjectToHttpParams(requestModel),
        };

        return this.httpClient.get<TopographicMeasurements>(`${this.basePath}/clients`, options);
    }

    public hasMultipleTopographicMeasurementBaseLines(clientId: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.basePath}/clients/${clientId}/has-multiple-base-lines`);
    }

    public deleteMeasurements(measurementIds: number[]): Observable<unknown> {
        const options = {
            body: measurementIds,
        };

        return this.httpClient.delete<unknown>(`${this.basePath}/delete`, options);
    }

    public removeTopographicMeasurementsFromClient(proposal: Proposal): Observable<Proposal> {
        return this.httpClient.post<Proposal>(
            `${this.basePath}/proposals/${proposal.Id}/remove-linked-from-client`,
            proposal,
        );
    }

    public saveBaseLineTopographicMeasurementsForClient(
        requestModel: SaveBaseLineTopographicMeasurementsForClientRequest,
    ): Observable<unknown> {
        return this.httpClient.post<unknown>(`${this.basePath}/clients/save-base-line`, requestModel);
    }

    public performTopographicManualCalculation(
        manualCalculation: TopographicManualCalculationWithMeasurement,
    ): Observable<TopographicManualCalculation> {
        return this.httpClient.post<TopographicManualCalculation>(
            `${this.basePath}/perform-manual-calculation`,
            manualCalculation,
        );
    }

    public getMeasurementHistoryByClientId(
        clientId: number,
        thumbnailImageChoice: string,
        skip: number,
        take: number,
    ): Observable<HistoricalMeasurements> {
        const options = {
            params: new HttpParams()
                .set('clientId', clientId)
                .set('thumbnailImage', thumbnailImageChoice)
                .set('skip', skip)
                .set('take', take),
        };

        return this.httpClient.get<HistoricalMeasurements>(`${this.basePath}/history`, options);
    }

    public importRandomTopos(opticianId?: number, numberOfTopos?: number): Observable<void> {
        const options = {
            params: new HttpParams().set('opticianId', opticianId).set('numberOfTopos', numberOfTopos),
        };

        return this.httpClient.get<void>(`${this.basePath}/test/import`, options);
    }
}
