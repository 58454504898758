import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { OpticianRoutingModule } from './optician.route.module';
import { HomeComponent } from './pages';

@NgModule({
    imports: [CommonModule, OpticianRoutingModule, SharedModule],
    declarations: [HomeComponent],
    providers: [],
})
export class OpticianModule {}
