import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FittedLensService } from '@app/core/services/api/fitted-lens.service';
import { ProductService } from '@app/core/services/api/product.service';
import { FittedLens } from '@app/shared/models/fitted-lens.model';
import { Client } from '@app/shared/models/client.model';
import { FittingEventService } from '@app/core/services/fittingEvent.service';
import { FittedLensParameter } from '@app/shared/models/fitted-lens-parameter.model';
import { LensDefinitionParameter, NumberFormatOptions } from '@app/shared/models';
import { LensDefinitionParameterUnit } from '@app/shared/models/LensDefinitionParameterUnit';
import { debounceTime } from 'rxjs/operators';
import { FieldValueExpressionCalculator } from '@app/fitlens/calculators/field-value-expression.calculator';
import { LensParameterChange } from '@app/fitlens/models/lensParameterChange.model';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { PowerAndCylinderNotBothZeroValidator } from '@app/fitglass/validators/powerAndCylinderNotBothZero';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'fit-glass',
    templateUrl: 'fitglass.component.html',
    styleUrls: ['fitglass.component.scss'],
})
export class FitGlassComponent implements OnInit, OnDestroy {
    //#region "properties"
    @Input() id: string;
    @Input() initialFittedLens: FittedLens = null;
    @Input() client: Client;

    @Input('fittedLens')
    set inFl(val: FittedLens) {
        this.fittedLens = val;
        if (this.initialized) {
            this.refresh().then();
        }
    }
    get inFl() {
        return this.fittedLens;
    }

    @Output() isLoading: EventEmitter<boolean> = new EventEmitter();

    get formControls() {
        return this.formGroup.controls;
    }

    formGroup: UntypedFormGroup;

    public fittedLens: FittedLens;
    private unmodifiedFittedLens: FittedLens;
    private parentFormGroup: UntypedFormGroup;

    public fittedLensParameters: FittedLensParameter[];

    private lensParametersChangedSkipped: string[] = [];

    private productCodeSpectacles: string;

    public loading = false;

    initialized = false;

    constructor(
        public appState: AppStateService,
        private readonly parent: FormGroupDirective,
        private readonly appConfigService: AppConfigService,
        private readonly fb: UntypedFormBuilder,
        private readonly fittedLensService: FittedLensService,
        private readonly translate: TranslateService,
        private readonly fittingEventService: FittingEventService,
        private readonly productService: ProductService,
        private readonly fieldValueExpressionCalculator: FieldValueExpressionCalculator,
    ) {}

    //#region "Lifecycle hooks"
    async ngOnInit(): Promise<void> {
        this.productCodeSpectacles = this.appConfigService.appConfig.productCodeSpectacles;
        this.parentFormGroup = this.parent.form;

        this.createForm();

        this.refresh().then();

        this.initialized = true;
    }

    ngOnDestroy(): void {
        // Do nothing
    }
    //#endregion "Lifecycle hooks"

    //#region "Methods"

    createForm(): void {
        this.formGroup = this.fb.group({
            parameters: this.fb.array([]),
        });

        this.formGroup.setValidators([() => new PowerAndCylinderNotBothZeroValidator().bind(this)]);

        this.formGroup.setParent(this.parentFormGroup);
        this.addToParentGroup();
    }

    // Refreshes the control based on the inputted fitted lens
    public async refresh(): Promise<void> {
        if (!this.fittedLens) {
            return;
        }

        this.unmodifiedFittedLens = structuredClone(this.fittedLens);

        this.productService.getProductByCode(this.productCodeSpectacles).subscribe((result) => {
            if (result && result.LensDefinition) {
                this.changeLensDefinition(result.LensDefinition.Id);
            }
        });
    }

    get areFormGroupsValid(): boolean {
        return this.formGroup.valid;
    }

    // Update the form controls with values from current fittedLens
    updateParameterFields(): void {
        const parameters = this.formControls['parameters'] as UntypedFormArray;
        this.fittedLensParameters = this.getFittedLensParameters();

        // patch the existing parameter controls
        this.visibleLensParameters.forEach((flp: FittedLensParameter, index: number) => {
            if (parameters.controls[index].value !== flp.GenericValue) {
                parameters.controls[index].patchValue(flp.GenericValue, {
                    emitEvent: false,
                });
            }
        });
    }

    updateControlsValidation(): void {
        const parameters = this.formControls['parameters'] as UntypedFormArray;

        this.visibleLensParameters.forEach((flp: FittedLensParameter, index: number) => {
            if (this.isLensDefinitionParameterRequired(flp.LensDefinitionParameter)) {
                parameters.controls[index].setValidators([Validators.required]);
            } else {
                parameters.controls[index].setValidators([]);
            }
        });
    }

    getUnit(ldp: LensDefinitionParameter): string {
        return LensDefinitionParameterUnit.getUnit(ldp);
    }

    parameterIsDisabled(ldp: LensDefinitionParameter): boolean {
        return ldp.ExportCode === 'DIAM';
    }

    get visibleLensParameters(): FittedLensParameter[] {
        return this.fittedLensParameters.filter((flp) => {
            return !flp.LensDefinitionParameter.IsHidden;
        });
    }

    public getFittedLensParameterIndex(code: string): number | null {
        for (let i = 0; i < this.visibleLensParameters.length; i++) {
            if (this.visibleLensParameters[i].LensDefinitionParameter.ParameterType.Code === code) {
                return i;
            }
        }
        return null;
    }

    // this function combines the official lensdefinition parameters (fittedLens.LensDefinition.LensDefinition)
    // with the already present parameter values (fittedLens.FittedLensParameters)
    getFittedLensParameters(): FittedLensParameter[] {
        if (this.fittedLens && this.fittedLens.LensDefinition) {
            const result = this.fittedLens.LensDefinition.LensDefinitionParameters.map((p) => {
                const flp = new FittedLensParameter();
                flp.FittedLensId = this.fittedLens.Id;
                flp.LensDefinitionParameterId = p.Id;
                flp.LensDefinitionParameter = p;
                flp.LensDefinitionParameter.NumberFormatOptions = new NumberFormatOptions();
                flp.LensDefinitionParameter.NumberFormatOptions.parameterType =
                    flp.LensDefinitionParameter.ParameterType.Code;

                if (this.fittedLens.FittedLensParameters) {
                    const v = this.fittedLens.FittedLensParameters.find((lp) => lp.LensDefinitionParameterId === p.Id);
                    if (v) {
                        flp.Id = v.Id;
                        flp.Value = v.Value;
                        flp.TextValue = v.TextValue;
                    }
                }
                return flp;
            });

            return result.sort(
                (a, b) =>
                    a.LensDefinitionParameter.ParameterType.SortOrder -
                    b.LensDefinitionParameter.ParameterType.SortOrder,
            );
        }
    }

    // changes the lens definition for the fitted lens
    async changeLensDefinition(lensDefinitionId: number): Promise<void> {
        if (this.fittedLens) {
            // this function is used in the result of changeLensDefinitionInFittedLens, it updates the fields on the screen
            // with the correct values

            const hasProduct = this.fittedLens.LensDefinition && this.fittedLens.LensDefinition.Product;
            const noUpdate = !lensDefinitionId || lensDefinitionId === this.fittedLens.LensDefinitionId;
            if (noUpdate && hasProduct) {
                this.fittedLens.LensDefinitionId = lensDefinitionId;
                this.resetParameterFields();
            } else {
                this.setLoading(true);
                this.lensParametersChangedSkipped = [];

                const fittedLens = structuredClone(this.unmodifiedFittedLens);
                fittedLens.LensDefinitionId = lensDefinitionId;

                const result = await lastValueFrom(this.fittedLensService.createFittedLens(lensDefinitionId));
                result.FittedLensParameters.filter((flp) => !flp.CalculationValid).forEach((flp) => {
                    flp.Value = null;
                });

                this.fittedLens = result;

                this.resetParameterFields();
                this.setLoading(false);
            }
        }
    }

    // Clear the parameters from the form and re-add them
    resetParameterFields(): void {
        const parameters = this.formControls['parameters'] as UntypedFormArray;
        this.fittedLensParameters = this.getFittedLensParameters();

        // re-generate the parameter controls
        parameters.controls.splice(0, parameters.controls.length); // clear all controls

        this.visibleLensParameters.forEach((flp) => {
            const isLensDefinitionParameterRequired = this.isLensDefinitionParameterRequired(
                flp.LensDefinitionParameter,
            );
            const fc = this.fb.control(
                flp.GenericValue,
                isLensDefinitionParameterRequired ? [Validators.required] : [],
            );

            fc.valueChanges.pipe(debounceTime(500)).subscribe(() => {
                const previousFittedLens = structuredClone(this.fittedLens);
                const currentFittedLens = this.readFittedLens();
                this.fittedLens = currentFittedLens;
                this.updateParameterFields();

                let paramsValid = true;
                for (let i = 0; i < currentFittedLens.FittedLensParameters.length; i++) {
                    const param = currentFittedLens.FittedLensParameters[i];
                    const isParameterRequired = this.isLensDefinitionParameterRequired(param.LensDefinitionParameter);
                    if (isParameterRequired === true && param.GenericValue === null) {
                        paramsValid = false;
                    }
                }

                if (!this.lensParametersChangedSkipped.includes(flp.LensDefinitionParameter.ParameterType.Code)) {
                    this.lensParametersChangedSkipped.push(flp.LensDefinitionParameter.ParameterType.Code);
                }

                if (paramsValid) {
                    for (let i = 0; i < currentFittedLens.FittedLensParameters.length; i++) {
                        const param = currentFittedLens.FittedLensParameters[i];
                        if (param.Value === null) {
                            param.Value = 0.0;
                        }
                    }
                    this.fieldValueExpressionCalculator
                        .recalculateFieldValueExpressions(currentFittedLens, this.lensParametersChangedSkipped)
                        .subscribe((result) => {
                            this.fittedLens = result;
                            this.lensParametersChangedSkipped = [];
                            this.fittingEventService.lensParameterChanged.emit(
                                new LensParameterChange(
                                    previousFittedLens,
                                    this.fittedLens,
                                    flp.LensDefinitionParameter.ParameterType.Code,
                                ),
                            );
                            this.updateParameterFields();
                        });
                }
                this.updateControlsValidation();
            });

            parameters.push(fc);
        });

        this.updateControlsValidation();
    }

    isLensDefinitionParameterRequired(lensDefinitionParameter: LensDefinitionParameter): boolean {
        if (lensDefinitionParameter.IsReadOnly || lensDefinitionParameter.IsHidden) {
            return false;
        }

        if (lensDefinitionParameter.ParameterType.Code === 'CYL') {
            return false;
        }

        if (lensDefinitionParameter.ParameterType.Code === 'AX') {
            const cylinderParameter = this.fittedLens.FittedLensParameters.find(
                (flp) => flp.LensDefinitionParameter.ParameterType.Code === 'CYL',
            );

            if (cylinderParameter) {
                if (cylinderParameter.GenericValue === null || cylinderParameter.GenericValue === 0) {
                    return false;
                }
            }
        }

        return true;
    }

    // returns the fittedLens with the current values as entered in the form
    readFittedLens(): FittedLens {
        const fittedLens = structuredClone(this.fittedLens);
        const parameters = this.formControls['parameters'] as UntypedFormArray;

        // get the values from the visible controls and put them back into the lensParameters array
        this.visibleLensParameters.forEach((flp: FittedLensParameter, i: number) => {
            flp.GenericValue = parameters.controls[i].value;
        });

        fittedLens.FittedLensParameters = this.fittedLensParameters;

        return fittedLens;
    }

    setLoading(loading: boolean) {
        this.loading = loading;
        this.isLoading.emit(loading);
    }

    removeFromParentGroup(): void {
        if (this.parentFormGroup.controls[this.id]) {
            this.parentFormGroup.removeControl(this.id);
        }
    }

    addToParentGroup(): void {
        this.removeFromParentGroup();
        this.parentFormGroup.addControl(this.id, this.formGroup);
    }

    controlHasFormError(fittedLensParameter: FittedLensParameter): boolean {
        let hasError = false;

        if (this.formGroup.errors) {
            const code = fittedLensParameter.LensDefinitionParameter.ParameterType.Code;
            if ((code === 'POW' || code === 'CYL') && this.formGroup.errors.powerAndCylinderNotBothZeroError) {
                hasError = true;
            }
        }
        return hasError;
    }

    getInfoTextForParameter(fittedLensParameter: FittedLensParameter): string {
        if (fittedLensParameter.LensDefinitionParameter && fittedLensParameter.LensDefinitionParameter.ParameterType) {
            const code = fittedLensParameter.LensDefinitionParameter.ParameterType.Code;

            if (
                (code === 'POW' && this.lensHasParameterWithCode('CYL')) ||
                (code === 'CYL' && this.lensHasParameterWithCode('POW'))
            ) {
                if (this.controlHasFormError(fittedLensParameter)) {
                    return this.translate.instant('validation.powerCylinderValidation');
                }
            }
        }

        return null;
    }

    lensHasParameterWithCode(code: string): boolean {
        if (this.fittedLens.FittedLensParameters) {
            return (
                this.fittedLens.FittedLensParameters.find(
                    (l) => l.LensDefinitionParameter.ParameterType.Code === code,
                ) != null
            );
        }
        return false;
    }
}
