import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    UntypedFormControl,
    UntypedFormArray,
    ValidationErrors,
    AbstractControl,
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject, Subscription, lastValueFrom } from 'rxjs';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { UserService } from '@app/core/services/api/user.service';
import { DataSheet, AuthenticatedUser } from '@app/shared/models';
import { DatasheetTypes } from '@app/shared/enums';
import { Company } from '@app/shared/models/company.model';

@Component({
    selector: 'license-dialog',
    templateUrl: './license-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LicenseDialogComponent implements OnInit, OnDestroy {
    closeModals: Subscription;
    agreementDataSheets: DataSheet[];
    approvedDataSheets: DataSheet[];
    user: AuthenticatedUser;
    formGroup: UntypedFormGroup;
    userAgreed = false;
    loading = false;
    licenseContent: string;
    company: Company;

    get formControls() {
        return this.formGroup.controls;
    }

    get dataSheetControls() {
        const dataSheets = this.formGroup.controls['datasheets'] as UntypedFormArray;
        return dataSheets.controls;
    }

    private closingSubject$: Subject<boolean>;

    constructor(
        public bsModalRef: BsModalRef,
        private readonly fb: UntypedFormBuilder,
        private readonly appState: AppStateService,
        private readonly userService: UserService,
    ) {
        this.closingSubject$ = new Subject();
        this.formGroup = this.fb.group(
            {
                datasheets: this.fb.array([]),
            },
            { validators: [this.allAgreedValidator] },
        );
    }

    ngOnInit(): void {
        this.user = this.appState.authenticatedUser;
        this.company = this.appState.currentCompany;
        this.getDataSheets();

        this.closeModals = this.appState.closeModals$.subscribe(() => {
            this.bsModalRef.hide();
        });
    }

    ngOnDestroy(): void {
        if (!this.userAgreed) {
            this.closingSubject$.next(false);
        }

        if (this.closeModals) {
            this.closeModals.unsubscribe();
        }
    }

    getDataSheets(): void {
        this.approvedDataSheets = new Array<DataSheet>();
        this.agreementDataSheets = this.appState.licenseDatasheets;
        this.agreementDataSheets.forEach((ds) => this.addDataSheet(ds));
    }

    approvedDate(ds: DataSheet): Date {
        if (ds.Code === DatasheetTypes.LicenseAgreement) {
            return this.user.LicenseAgreementApprovedOn;
        }
        if (ds.Code === DatasheetTypes.UserRegulations) {
            return this.user.UserRegulationsApprovedOn;
        }
        if (ds.Code === DatasheetTypes.PrivacyPolicy) {
            return this.user.VisavyPlatformPrivacyPolicyApprovedOn;
        }
        return null;
    }

    addDataSheet(ds: DataSheet) {
        const sheets = <UntypedFormArray>this.formControls['datasheets'];
        let isApproved = false;

        if (
            (ds.Code === DatasheetTypes.LicenseAgreement && this.user?.LicenseAgreementApprovedOn) ||
            (ds.Code === DatasheetTypes.UserRegulations && this.user?.UserRegulationsApprovedOn) ||
            (ds.Code === DatasheetTypes.PrivacyPolicy && this.user?.VisavyPlatformPrivacyPolicyApprovedOn)
        ) {
            isApproved = true;
            this.approvedDataSheets.push(ds);
        }

        const control = new UntypedFormControl(isApproved);

        sheets.push(control);
    }

    isApproved(ds: DataSheet): boolean {
        return this.approvedDataSheets.some((x) => x.Id === ds.Id);
    }

    allAgreedValidator(control: AbstractControl): ValidationErrors {
        if (control) {
            const dataSheets = control.get('datasheets') as UntypedFormArray;
            const found = dataSheets.controls.some((dataSheet) => dataSheet.value === false || dataSheet.value === '');

            return found ? { allAgreedValidator: true } : null;
        }

        return null;
    }

    async agree(): Promise<void> {
        this.loading = true;

        const user = await lastValueFrom(
            this.userService.approveAgreements(this.agreementDataSheets.map((d) => d.Code)),
        );

        this.appState.onUserSettingChange(user);
        this.loading = false;
        this.userAgreed = true;
        this.closingSubject$.next(true);
        this.bsModalRef.hide();
    }

    decline(): void {
        this.bsModalRef.hide();
        this.closingSubject$.next(false);
    }

    get onClose$(): Observable<boolean> {
        return this.closingSubject$.asObservable();
    }
}
