import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    Output,
    EventEmitter,
    OnDestroy,
    AfterViewInit,
    inject,
    DestroyRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Note, NoteMessage, NoteMessageOrigin } from '@app/shared/models';
import { NoteService } from '@app/core/services/api/note.service';
import { Attachment } from '@app/shared/models/attachment.model';
import { Roles } from '@app/shared/enums';
import { AttachmentService } from '@app/core/services/api/attachment.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { Util } from '@app/shared/helpers/utility.helper';
import { BehaviorSubject, Observable, Subject, combineLatest, concat, forkJoin, fromEvent, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeAll, scan, switchMap, tap } from 'rxjs/operators';
import { SessionService } from '@app/shared/appservices/session.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'client-communication',
    templateUrl: 'client-communication.component.html',
    styleUrls: ['client-communication.component.scss'],
})
export class ClientCommunicationComponent implements OnInit, AfterViewInit, OnDestroy {
    formGroup: UntypedFormGroup;
    private readonly ComponentId = 'clientCommunicationComponent';

    get formControls() {
        return this.formGroup.controls;
    }

    @Input() clientId: number;
    @Input() orderId: number;
    @Input() type: string = null;
    @Input() isReadOnly = true;
    @Input() showInternalNotes = false;
    @Input() showAdditionalResearchNotes = false;
    @Input() showOrderNotes = false;
    @Input() showAttachments = false;
    @Input() showClientNotes = false;
    @Input() addClassName = '';
    @Input() useAutoGrow = false;
    @Input() id: string;

    @Output() noteMarkedAsRead$: EventEmitter<number> = new EventEmitter();

    @Input() sessionStorageKeysClientCommunication: string[];
    @Output() sessionStorageKeysClientCommunicationChange: EventEmitter<string[]> = new EventEmitter();

    @ViewChild('clientCommunication') clientCommunication: ElementRef;
    @ViewChild('chatContainer', { static: true }) private chatContainer: ElementRef;

    messages$: Observable<NoteMessage[]>;

    savingNote = false;
    loading = false;

    private skip = 0;
    private take = 15;

    private pagination$ = new BehaviorSubject<{ skip: number; take: number; hasReachedTop: boolean }>({
        skip: this.skip,
        take: this.take,
        hasReachedTop: false,
    });
    private messageAdded$ = new Subject<NoteMessage>();
    private scrollPosition$ = new Observable<number>();

    private chatObserver: MutationObserver;
    private readonly destroyRef = inject(DestroyRef);

    constructor(
        private fb: UntypedFormBuilder,
        private appState: AppStateService,
        private noteService: NoteService,
        private attachmentService: AttachmentService,
        private sessionService: SessionService,
    ) {}

    public ngOnInit() {
        this.createForm();

        this.loadMessages();
        this.formControls.chatmessage.setValue(this.sessionService.get(this.ComponentId + this.id));
    }

    onBlurChatMessageText() {
        const textValue = (this.formControls.chatmessage.value as string)?.trim();
        if (textValue) {
            this.sessionService.save(this.ComponentId + this.id, textValue);
            this.sessionStorageKeysClientCommunication.push(this.ComponentId + this.id);
        } else {
            this.sessionService.remove(this.ComponentId + this.id);
        }
    }

    private loadMessages() {
        this.scrollPosition$ = fromEvent(this.chatContainer.nativeElement, 'scroll').pipe(
            map((event: Event) => (event.target as Element).scrollTop),
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef),
        );

        const chatHistory$ = this.getChatHistory();

        const newMessage$ = concat(
            of(null),
            this.messageAdded$.pipe(
                tap(() => {
                    const currentPage = this.pagination$.value;
                    this.pagination$.next({ ...currentPage, skip: currentPage.skip + 1 });
                }),
            ),
        );

        this.messages$ = combineLatest([
            chatHistory$,
            newMessage$.pipe(scan((prev, curr) => (curr ? [...prev, curr] : []), [])),
        ]).pipe(
            scan((_acc: NoteMessage[], [chatHistory, newMessages]: [NoteMessage[], NoteMessage[]]) => {
                return newMessages ? [...chatHistory, ...newMessages] : chatHistory;
            }, []),
        );
    }

    observeScrolling() {
        const chatContainer = this.chatContainer.nativeElement;
        let previousScrollHeight = 0;

        this.scrollPosition$
            .pipe(
                filter((scrollTop: number) => scrollTop === 0),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(() => {
                // Calculate the current scroll position relative to the bottom of the container
                previousScrollHeight = chatContainer.scrollHeight;
            });

        //Observes the chat container for changes in the DOM.
        //This is used to detect when new messages are added to the chat, so we can execute the appropriate scrolling logic.
        this.chatObserver = new MutationObserver((mutations: MutationRecord[]) => {
            if (mutations.length === 0) {
                return;
            }

            //chatContainer.children[0] is the loading indicator, so take the second element
            const firstMessageElement = chatContainer.children[1];
            const prepended = mutations[0].addedNodes[0].compareDocumentPosition(firstMessageElement) === 0;

            if (prepended) {
                //Keep the scroll position relative to the bottom of the container
                const newContentHeight = chatContainer.scrollHeight - previousScrollHeight;
                const newScrollTop = chatContainer.scrollTop + newContentHeight;

                chatContainer.scrollTop = newScrollTop;
                previousScrollHeight = chatContainer.scrollHeight;
            } else if (mutations.length === 1) {
                //Scroll to the bottom of the chatwindow when a new message is added
                this.scrollToBottom(false);
            } else {
                //Intantly scroll to the bottom of the chatwindow when the data is loaded for the first time
                this.scrollToBottom(true);
            }
        });

        this.chatObserver.observe(chatContainer, { childList: true });
    }

    private scrollToBottom(instant = false) {
        const chatContainer = this.chatContainer.nativeElement;

        chatContainer.scrollTo({
            top: chatContainer.scrollHeight,
            behavior: instant ? 'auto' : 'smooth',
        });
    }

    ngAfterViewInit(): void {
        this.observeScrolling();
    }

    getChatHistory(): Observable<NoteMessage[]> {
        const scrollTrigger$ = concat(
            of(0),
            this.scrollPosition$.pipe(
                filter((scrollTop: number) => scrollTop === 0),
                tap(() => (this.loading = true)),
                takeUntilDestroyed(this.destroyRef),
            ),
        );

        const page$ = scrollTrigger$.pipe(
            switchMap(() => {
                const currentPage = this.pagination$.value;

                const requests: Observable<NoteMessage[]>[] = [];
                this.showOrderNotes && this.orderId && requests.push(this.loadOrderMessages(currentPage.skip));
                this.showClientNotes && this.clientId && requests.push(this.loadClientMessages(currentPage.skip));
                this.showInternalNotes && this.clientId && requests.push(this.loadInternalMessages(currentPage.skip));

                return forkJoin(requests).pipe(
                    mergeAll(),
                    tap((noteMessages) => {
                        const hasReachedTop = noteMessages?.length < this.take;

                        const currentPage = this.pagination$.value;
                        const newSkip = hasReachedTop
                            ? currentPage.skip + noteMessages?.length ?? 0
                            : currentPage.skip + this.take;
                        const newPagination = { ...currentPage, skip: newSkip, hasReachedTop: hasReachedTop };
                        this.pagination$.next(newPagination);
                    }),
                    map((noteMessages) => this.sortNotesMessages(noteMessages)),
                );
            }),

            scan((prev: NoteMessage[], curr: NoteMessage[]) => [...curr, ...prev], []),
        );

        const attachments$: Observable<NoteMessage[]> =
            this.showAttachments && this.clientId ? this.loadAttachments() : of([]);

        return combineLatest([page$, attachments$]).pipe(
            map(([notes, attachments]) => {
                //Filter the attachments to only include those that are within the date range of the notes
                const firstDisplayedDate = notes[0] ? new Date(notes[0].createdOn) : null;
                const lastDisplayedDate = notes[notes.length - 1] ? new Date(notes[notes.length - 1].createdOn) : null;
                const hasReachedTop = this.pagination$.value.hasReachedTop;
                const filteredAttachments = attachments.filter((attachment) => {
                    const attachmentDate = new Date(attachment.createdOn);
                    return (
                        ((!firstDisplayedDate || attachmentDate >= firstDisplayedDate) &&
                            (!lastDisplayedDate || attachmentDate <= lastDisplayedDate)) ||
                        (firstDisplayedDate && hasReachedTop && attachmentDate < firstDisplayedDate)
                    );
                });

                // Merge the notes and attachments into a single array, sorted by createdOn date.
                const noteMessages = [...notes];
                filteredAttachments.forEach((attachment) => {
                    const index = noteMessages.findIndex((note) => attachment.createdOn < note.createdOn);
                    if (index === -1) {
                        noteMessages.push(attachment);
                    } else {
                        noteMessages.splice(index, 0, attachment);
                    }
                });
                return noteMessages;
            }),
            tap(() => (this.loading = false)),
        );
    }

    ngOnDestroy(): void {
        this.chatObserver.disconnect();
    }

    private createForm(): void {
        this.formGroup = this.fb.group({
            chatmessage: [''],
        });
    }

    private loadOrderMessages(skip: number = this.skip): Observable<NoteMessage[]> {
        return this.noteService.getOrderNotes(this.orderId, skip, this.take).pipe(
            tap((notes: Note[]) => this.markAsRead(notes)),
            map((notes: Note[]) => this.createMessagesFromNotes(notes)),
        );
    }

    private loadClientMessages(skip: number = this.skip): Observable<NoteMessage[]> {
        return this.noteService.getAllOrderNotesFromClient(this.clientId, skip, this.take).pipe(
            tap((notes: Note[]) => this.markAsRead(notes)),
            map((notes: Note[]) => this.createMessagesFromNotes(notes)),
        );
    }

    private loadInternalMessages(skip: number = this.skip): Observable<NoteMessage[]> {
        return this.noteService.getInternalNotes(this.clientId, skip, this.take).pipe(
            tap((notes: Note[]) => this.markAsRead(notes)),
            map((notes: Note[]) => this.createMessagesFromNotes(notes)),
        );
    }

    private loadAttachments() {
        return this.attachmentService.getAttachmentsForClient(this.clientId).pipe(
            map((attachments: Attachment[]) => this.groupAttachmentsPerFiveMinutes(attachments)),
            map((groupedAttachments: Attachment[][]) => this.createMessagesFromGroupedAttachments(groupedAttachments)),
        );
    }

    private groupAttachmentsPerFiveMinutes(attachments: Attachment[]): Attachment[][] {
        const fiveMinuteInterval = 5 * 60 * 1000;
        const groups = new Map<number, Attachment[]>();

        attachments.forEach((attachment: Attachment) => {
            const key = Math.floor(new Date(attachment.UploadedOn).getTime() / fiveMinuteInterval);
            const group = groups.get(key);

            if (group) {
                group.push(attachment);
            } else {
                groups.set(key, [attachment]);
            }
        });

        return Array.from(groups.values());
    }

    private sortNotesMessages(noteMessages: NoteMessage[]): NoteMessage[] {
        return noteMessages.sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime());
    }

    private markAsRead(notes: Note[]) {
        notes.forEach((note: Note) => {
            if (
                (!note.IsReadByOptician && this.appState.authenticatedUser.CurrentRoleId === Roles.Optician) ||
                (!note.IsReadByPs &&
                    (this.appState.authenticatedUser.CurrentRoleId === Roles.Ps ||
                        this.appState.authenticatedUser.CurrentRoleId === Roles.DistributorSupport))
            ) {
                this.noteService.markNoteAsRead(note.Id).subscribe(() => {
                    this.noteMarkedAsRead$.emit(note.Id);
                });
            }
        });
    }

    private createMessageFromNote(note: Note): NoteMessage {
        const isNoteFromSameRole = note.UserRoleId === this.appState.authenticatedUser.CurrentRoleId;

        return new NoteMessage(
            note.Message,
            note.IsCreatedOn,
            note.Name,
            isNoteFromSameRole ? NoteMessageOrigin.Sent : NoteMessageOrigin.Received,
        );
    }

    private createMessagesFromNotes(notes: Note[]): NoteMessage[] {
        const noteMessages: NoteMessage[] = [];

        notes.forEach((note: Note) => {
            const noteMessage = this.createMessageFromNote(note);
            noteMessages.push(noteMessage);
        });

        return noteMessages;
    }

    private createMessagesFromGroupedAttachments(groupedAttachments: Attachment[][]) {
        const noteMessages: NoteMessage[] = [];

        groupedAttachments.forEach((attachments) => {
            const noteMessage = this.createMessagesFromAttachments(attachments);
            noteMessages.push(noteMessage);
        });

        return noteMessages;
    }

    private createMessagesFromAttachments(attachments: Attachment[]): NoteMessage {
        const isSent = this.appState.authenticatedUser.CurrentRoleId === Roles.Optician;
        const noteMessage = new NoteMessage(
            null,
            attachments[0].UploadedOn,
            null,
            isSent ? NoteMessageOrigin.Sent : NoteMessageOrigin.Received,
        );
        noteMessage.attachments = attachments;

        return noteMessage;
    }

    public downloadAttachment(attachment: Attachment) {
        this.attachmentService.downloadAttachment(attachment.Id, attachment.MimeType).subscribe((result: Blob) => {
            Util.openBlobInBrowser(result, attachment.FileName);
        });
    }

    public send(event: Event): void {
        const chatValue = (this.formControls.chatmessage.value as string)?.trim();

        if (!chatValue) {
            return;
        }

        this.sessionService.remove(this.ComponentId + this.id);

        const saveNote$ = (value: string) => {
            const note = new Note();
            note.Message = value;
            note.UserRoleId = this.appState.authenticatedUser.CurrentRoleId;

            let saveNoteAction$: Observable<Note>;

            if (this.orderId) {
                note.OrderId = this.orderId;
                saveNoteAction$ = this.noteService.saveNoteForOrder(note);
            } else if (this.clientId) {
                note.ClientId = this.clientId;
                saveNoteAction$ = this.noteService.saveNoteForClient(note);
            }

            if (saveNoteAction$) {
                this.savingNote = true;
                this.formControls.chatmessage.disable();
                return saveNoteAction$.pipe(map((note: Note) => this.createMessageFromNote(note)));
            } else {
                return of(<NoteMessage>null);
            }
        };

        saveNote$(chatValue).subscribe((noteMessage: NoteMessage) => {
            this.messageAdded$.next(noteMessage);
            this.formControls.chatmessage.reset();
            this.formControls.chatmessage.enable();
            this.savingNote = false;

            if (this.useAutoGrow === true) {
                this.autoGrow(event, true);
            }
        });
    }

    protected autoGrow(event: Event, resetHeight: boolean): void {
        const element = event.target as Element;
        const baseHeight = +window.getComputedStyle(element, null).getPropertyValue('min-height').replace('px', '');
        const type = element.id.split('-')[0];
        const button = document.getElementById(type + '-sendNoteButton');
        const textarea = document.getElementById(type + '-newMessage');

        let height: number;

        if (resetHeight) {
            height = baseHeight;
        } else {
            height = element.scrollHeight;

            if (height > 3 * baseHeight) {
                height = 3 * baseHeight;
            }
        }

        button.style.height = height + 'px';
        textarea.style.height = height + 'px';

        this.calculateClientCommunicationHeight(height);
    }

    private calculateClientCommunicationHeight(height: number): void {
        if (this.clientCommunication) {
            this.clientCommunication.nativeElement.style.height =
                this.clientCommunication.nativeElement.parentNode.offsetHeight - height + 40 + 'px';
        }
    }
}
