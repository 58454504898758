import { Pipe, PipeTransform } from '@angular/core';
import {
    LensDefinitionParameterNumberRange,
    LensDefinitionParameterText,
} from '../models/lens-definition-parameter-range.model';
import { FitLensComponent } from '@app/fitlens/fitlens.component';
import { LensDefinitionParameterRangeHelper } from '../helpers/lens-definition-parameter-range-helper';

@Pipe({
    name: 'lensDefinitionParameterNumberRangeFilter',
})
export class LensDefinitionParameterNumberRangeFilterPipe implements PipeTransform {
    public transform(
        items: (LensDefinitionParameterText | LensDefinitionParameterNumberRange)[],
        flc: FitLensComponent,
    ): LensDefinitionParameterNumberRange[] {
        return items
            .filter<LensDefinitionParameterNumberRange>(this.isNumberRange)
            .filter((x) => LensDefinitionParameterRangeHelper.checkConditions(x, flc));
    }

    private isNumberRange(
        item: LensDefinitionParameterText | LensDefinitionParameterNumberRange,
    ): item is LensDefinitionParameterNumberRange {
        return item.$type === 'number-range';
    }
}
