import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Note } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class NoteService {
    private readonly basePath = 'api/notes';

    constructor(private readonly httpClient: HttpClient) {}

    public getOrderNotes(orderId: number, skip: number, take: number): Observable<Note[]> {
        const options = {
            params: new HttpParams().set('skip', skip.toString()).set('take', take.toString()),
        };

        return this.httpClient.get<Note[]>(`${this.basePath}/order/${orderId}`, options);
    }

    public getAllOrderNotesFromClient(clientId: number, skip: number, take: number): Observable<Note[]> {
        const options = {
            params: new HttpParams().set('skip', skip.toString()).set('take', take.toString()),
        };

        return this.httpClient.get<Note[]>(`${this.basePath}/client/${clientId}/order`, options);
    }

    public getInternalNotes(clientId: number, skip: number, take: number): Observable<Note[]> {
        const options = {
            params: new HttpParams().set('skip', skip.toString()).set('take', take.toString()),
        };

        return this.httpClient.get<Note[]>(`${this.basePath}/client/${clientId}/internal`, options);
    }

    public markNoteAsRead(noteId: number): Observable<unknown> {
        return this.httpClient.post<unknown>(`${this.basePath}/mark-as-read`, noteId);
    }

    public saveNoteForOrder(note: Note): Observable<Note> {
        return this.httpClient.post<Note>(`${this.basePath}/order`, note);
    }

    public saveNoteForClient(note: Note): Observable<Note> {
        return this.httpClient.post<Note>(`${this.basePath}/client`, note);
    }

    public getUnreadOpticianNoteOrderIds(): Observable<number[]> {
        return this.httpClient.get<number[]>(`${this.basePath}/optician/unread`);
    }
}
