import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent {
    constructor(private location: Location) {}

    back($event: MouseEvent): void {
        $event.preventDefault();
        this.location.back();
    }
}
