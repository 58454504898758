<div class="step d-flex" [ngClass]="getClass()">
    <div class="step-inner flex-grow-1 p-2 d-flex align-items-center">
        <div class="d-flex align-items-center mx-auto">
            <div class="step-icon d-flex align-items-center">
                <i *ngIf="iconName" class="fa fa-2x" [ngClass]="'fa-' + iconName"></i>
                <img *ngIf="imageName" class="headericon" src="assets/images/{{ imageName }}" alt="{{ title }}" />
            </div>
            <div class="ms-2 d-none d-md-block">
                <div class="text-truncate" *ngIf="title" title="{{ title }}">
                    {{ title | truncate: 15 }}
                </div>
                <div class="text-truncate" *ngIf="subtitle" title="{{ subtitle }}">
                    <strong>{{ subtitle | truncate: 15 }}</strong>
                </div>
            </div>
        </div>
        <div *ngIf="canSave()" class="ms-2 saveStep">
            <button
                id="saveStep_{{ step }}"
                class="btn btn-warning"
                [disabled]="!isSaveEnabled()"
                (click)="saveAndReset()">
                {{ 'general.save' | translate }}
            </button>
        </div>
        <div class="divider align-self-center ms-2"></div>
        <div class="divider-end d-none d-sm-block" *ngIf="step !== currentStep"></div>
    </div>
</div>
