<div class="modal-body">
    <div *ngIf="order" class="mb-4">
        <div class="row mb-4" *ngIf="order.IsDirectToHome">
            <div class="col-12">
                <div class="rounded-bottom rounded-top p-3 accented-background">
                    <i
                        class="fas fa-mail-bulk me-2"
                        triggers="mouseenter:mouseleave"
                        placement="bottom"
                        container="body"
                        popoverClass="popover"
                        popover="{{ 'orderoverview.directToHome.dialog.orderstatusinfo' | translate }}"></i>
                    <span
                        innerHTML="{{
                            'orderoverview.directToHome.dialog.orderstatustext' | translate: directToHomeTranslations
                        }}"></span>
                </div>
            </div>
        </div>

        <div class="row">
            <div *ngIf="order.ClientId || order.ClientReference2" class="col-6">
                <div class="title-border-bottom mb-1">
                    <h4>{{ 'general.client' | translate }}</h4>
                </div>
                <div *ngIf="clientMessage === null; else showClientMessage">
                    <div *ngIf="clientLoading" class="spinner-border spinner-border-sm my-2"></div>
                    <client-details
                        [client]="client"
                        [twoColumns]="true"
                        [showLabels]="true"
                        *ngIf="client && !clientLoading"></client-details>
                </div>
                <ng-template #showClientMessage>
                    <div class="alert alert-warning" role="alert">
                        {{ clientMessage }}
                    </div>
                </ng-template>
            </div>

            <div *ngIf="order.ProposalReceiptReference" class="col-6">
                <div class="title-border-bottom mb-1">
                    <h4>{{ 'general.ProposalReceipt' | translate }}</h4>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-5 font-weight-bold">{{ 'proposalReceipt.reference' | translate }}:</div>
                            <div class="col-7">
                                <span id="orderreference">{{ order.ProposalReceiptReference }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                [ngClass]="
                    (order.ClientId || order.ClientReference2) && order.GlassTreatmentId
                        ? 'col-3'
                        : !(order.ClientId || order.ProposalReceiptReference) && !order.GlassTreatmentId
                          ? 'col-12'
                          : 'col-6'
                ">
                <div class="title-border-bottom mb-1">
                    <h4>{{ 'general.order' | translate }}</h4>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-5 font-weight-bold">{{ 'order.orderdate' | translate }}:</div>
                            <div class="col-7">
                                <span id="orderdate">{{ order.OrderDate | mpcdate }}</span>
                            </div>
                        </div>

                        <ng-container *ngIf="!hideHeaderFilters">
                            <div class="row">
                                <div class="col-5 font-weight-bold">{{ 'landing.orderstatus' | translate }}:</div>
                                <div class="col-7">
                                    <span id="orderStatus" *ngIf="!canUserChangeOrderStatus">
                                        {{ orderStatusTranslationKey | translate }}
                                    </span>
                                    <a
                                        *ngIf="canUserChangeOrderStatus"
                                        (click)="openChangeOrderStatusDialog()"
                                        [routerLink]="[]"
                                        id="orderStatus">
                                        {{ orderStatusTranslationKey | translate }}
                                    </a>
                                </div>
                            </div>
                        </ng-container>

                        <div class="row" *ngIf="order.OrderStatusId === 3">
                            <div class="col-5 font-weight-bold">{{ 'general.shippedDate' | translate }}:</div>
                            <div class="col-7">
                                {{ shippedDate | mpcdate }}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-5 font-weight-bold">{{ 'order.reference' | translate }}:</div>
                            <div class="col-7">
                                <span id="orderreference">{{ order.OrderReference }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="order.GlassTreatmentId" class="col-3">
                <div class="title-border-bottom mb-1">
                    <h4>{{ 'general.frame' | translate }}</h4>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.edgingService' | translate }}:</div>
                            <div class="col-6">
                                <container-element [ngSwitch]="order.GlassTreatmentId">
                                    <ng-container *ngSwitchCase="Treatments.none">
                                        {{ 'general.none' | translate }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="Treatments.remoteEdging">
                                        {{ 'fitglass.remoteEdging' | translate }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="Treatments.remoteEdgingAndFitting">
                                        {{ 'fitglass.remoteEdgingAndFitting' | translate }}
                                    </ng-container>
                                </container-element>
                            </div>
                        </div>
                        <div *ngIf="hasEdgingServiceRemoteEdgingAndFitting" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.frameBrand' | translate }}:</div>
                            <div class="col-6">{{ order.Frame.Brand }}</div>
                        </div>
                        <div *ngIf="hasEdgingServiceRemoteEdgingAndFitting" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.frameModel' | translate }}:</div>
                            <div class="col-6">{{ order.Frame.Model }}</div>
                        </div>
                        <div *ngIf="hasEdgingServiceRemoteEdging" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.frameType' | translate }}:</div>
                            <div class="col-6">{{ order.Frame.Material }}</div>
                        </div>
                        <div *ngIf="hasEdgingServiceRemoteEdgingAndFitting" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.frameColor' | translate }}:</div>
                            <div class="col-6">{{ order.Frame.Color }}</div>
                        </div>
                        <div *ngIf="hasEdgingService" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.frameSizeBoxA' | translate }}:</div>
                            <div class="col-6">{{ order.Frame.SizeBoxA }}</div>
                        </div>
                        <div *ngIf="hasEdgingService" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.frameSizeBoxB' | translate }}:</div>
                            <div class="col-6">{{ order.Frame.SizeBoxB }}</div>
                        </div>
                        <div *ngIf="hasEdgingService" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.frameDblDimension' | translate }}:</div>
                            <div class="col-6">
                                {{ order.Frame.DblDimension }}
                            </div>
                        </div>
                        <div *ngIf="hasEdgingService" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.pupilDistanceLeft' | translate }}:</div>
                            <div class="col-6">
                                {{ order.Frame.PupilDistanceLeft }}
                            </div>
                        </div>
                        <div *ngIf="hasEdgingService" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.pupilDistanceRight' | translate }}:</div>
                            <div class="col-6">
                                {{ order.Frame.PupilDistanceRight }}
                            </div>
                        </div>
                        <div *ngIf="hasEdgingService" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.ocularCenterLeft' | translate }}:</div>
                            <div class="col-6">
                                {{ order.Frame.OcularCenterLeft }}
                            </div>
                        </div>
                        <div *ngIf="hasEdgingService" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.ocularCenterRight' | translate }}:</div>
                            <div class="col-6">
                                {{ order.Frame.OcularCenterRight }}
                            </div>
                        </div>
                        <div *ngIf="hasEdgingService" class="row">
                            <div class="col-6 font-weight-bold">{{ 'general.edgePolish' | translate }}:</div>
                            <div class="col-6">
                                {{ order.Frame.EdgePolish ? 'Y' : 'N' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 p-0">
        <div class="title-border-bottom">
            <h4>{{ 'general.order' | translate }}</h4>
        </div>
        <div class="rounded-bottom p-1 accented-background">
            <order-lines *ngIf="order" [orderId]="order.Id" (shippedDate)="(shippedDate)"></order-lines>
        </div>
    </div>

    <div class="mt-4" *ngIf="showClientCommunication">
        <div class="title-border-bottom">
            <h4>{{ 'general.communicationhistory' | translate }}</h4>
        </div>
        <client-communication
            [orderId]="order.Id"
            [showClientNotes]="false"
            [showOrderNotes]="true"
            [showAttachments]="true"
            [isReadOnly]="!(order | supportOrder)"
            (noteMarkedAsRead$)="noteMarkedAsRead()"></client-communication>
    </div>
</div>

<div class="modal-footer accented-background d-flex justify-content-between">
    <div *ngIf="order">
        <button type="button" id="downloadPdf" class="btn btn-primary" (click)="downloadPdf()">
            <i class="fa fa-file-pdf"></i>
            &nbsp;{{
                hasEdgingServiceRemoteEdgingAndFitting
                    ? ('general.downloadOrderConfirmationPdf' | translate)
                    : ('general.downloadpdf' | translate)
            }}
        </button>
    </div>
    <div *ngIf="hasEdgingServiceRemoteEdgingAndFitting">
        <button
            type="button"
            id="downloadFramePackingSlipPdf"
            class="btn btn-primary"
            (click)="downloadShipmentFramePdf()">
            <i class="fa fa-file-pdf"></i>
            &nbsp;{{ 'general.downloadFramePackingSlipPdf' | translate }}
        </button>
    </div>
    <button type="button" id="close" class="btn btn-default" (click)="bsModalRef.hide()">
        {{ 'general.close' | translate }}
    </button>
</div>
