import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { ProposalService } from '@app/core/services/api/proposal.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Client, FittedLens, Proposal } from '@app/shared/models';
import { FittingEventService } from '@app/core/services/fittingEvent.service';
import { AlertService } from '../shared/appservices/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@app/core/services/api/user.service';
import { PopupComponent } from '../shared/popup/popup.component';
import { PopupButton } from '../shared/models/popupButton.model';
import { FittingService } from '@app/core/services/fitting.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { SaveFittingContinuationConfiguration } from './saveFittingContinuationConfiguration';
import { FitLensComponent } from '@app/fitlens/fitlens.component';
import { Frame } from '@app/shared/models/frame.model';
import { BevelTypes, Treatments, EyeSides, LensTypes } from '@app/shared/enums';
import { PopupConfig } from '@app/shared/models/popupConfig.model';
import { UserSettingService } from '@app/core/services/api/user-setting.service';

@Component({
    selector: 'fitting-fitglass',
    templateUrl: 'fitting-fitglass.component.html',
})
export class GlassFittingFitGlassComponent implements OnInit, OnDestroy {
    formGroup: UntypedFormGroup;
    get formControls() {
        return this.formGroup.controls;
    }

    @ViewChild('glassframe')
    glassframe: import('./glassframe.component').GlassFrameComponent;

    @ViewChild('leftFitLens') set lfl(value: FitLensComponent) {
        this.leftFitLensControl = value;
    }
    @ViewChild('rightFitLens') set rfl(value: FitLensComponent) {
        this.rightFitLensControl = value;
    }

    private leftFitLensControl: FitLensComponent;
    private rightFitLensControl: FitLensComponent;

    eyeSides = EyeSides;

    leftFittedLens: FittedLens;
    rightFittedLens: FittedLens;
    initialLeftFittedLens: FittedLens;
    initialRightFittedLens: FittedLens;

    leftEyeSelectedChangedSubscription: Subscription;
    rightEyeSelectedChangedSubscription: Subscription;
    fittedLensChangedSubscription: Subscription;
    lensParameterChangedSubscription: Subscription;

    leftFitLensLoading = false;
    rightFitLensLoading = false;

    get fitlensRightEyeSelected(): boolean {
        return this.fittingService.state.isRightEyeSideSelected;
    }
    get fitlensLeftEyeSelected(): boolean {
        return this.fittingService.state.isLeftEyeSideSelected;
    }

    get client(): Client {
        return this.fittingService.state.client;
    }

    get proposal(): Proposal {
        return this.fittingService.state.proposal;
    }

    get glassTreatment(): number {
        return this.proposal ? this.proposal.GlassTreatmentId : null;
    }

    get frameBrand(): string {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.Brand : null;
    }

    get frameModel(): string {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.Model : null;
    }

    get frameMaterial(): string {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.Material : null;
    }

    get frameColor(): string {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.Color : null;
    }

    get pupilDistanceLeft(): number {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.PupilDistanceLeft : null;
    }

    get pupilDistanceRight(): number {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.PupilDistanceRight : null;
    }

    get ocularCenterLeft(): number {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.OcularCenterLeft : null;
    }

    get ocularCenterRight(): number {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.OcularCenterRight : null;
    }

    get frameSizeBoxA(): number {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.SizeBoxA : null;
    }

    get frameSizeBoxB(): number {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.SizeBoxB : null;
    }

    get frameDblDimension(): number {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.DblDimension : null;
    }

    get bevelType(): string {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.BevelType : null;
    }

    get bevelTypeId(): BevelTypes {
        return this.fittingService.state.selectedBevelType;
    }

    get bevelPosition(): number {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.BevelPosition : null;
    }

    get edgePolish(): boolean {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.EdgePolish : null;
    }

    get frameTracerSizeBoxA(): number {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.TracerSizeBoxA : null;
    }

    get frameTracerSizeBoxB(): number {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.TracerSizeBoxB : null;
    }

    get frameTracerDblDimension(): number {
        return this.proposal && this.proposal.Frame ? this.proposal.Frame.TracerDblDimension : null;
    }

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly fittingService: FittingService,
        public modalService: BsModalService,
        public router: Router,
        public appState: AppStateService,
        public appConfig: AppConfigService,
        public proposalService: ProposalService,
        public loaderService: LoaderService,
        public fittingEventService: FittingEventService,
        public alertService: AlertService,
        public translateService: TranslateService,
        public userService: UserService,
        public userSettingService: UserSettingService,
    ) {
        if (this.fittingService.getClientId() === 0 || !this.fittingService.state.proposal) {
            router.navigate(['/']).then();
            return;
        }

        this.fittingService.state.isLeftEyeSideSelected = true;
        this.fittingService.state.isRightEyeSideSelected = true;

        this.createFittedLensesForGlass();

        this.initialLeftFittedLens = structuredClone(this.leftFittedLens);
        this.initialRightFittedLens = structuredClone(this.rightFittedLens);
    }

    ngOnInit(): void {
        this.fittingService.setFittingStep(this.router.url);

        this.createForm();

        this.fittingService.configureSaveForContinuation(
            new SaveFittingContinuationConfiguration(
                () => this.orderAllowed() && this.isNextEnabled(),
                () => this.save(true),
            ),
        );

        this.rightEyeSelectedChangedSubscription = this.formControls['modify-od'].valueChanges.subscribe((result) => {
            this.fittingService.state.isRightEyeSideSelected = result;
            if (!result && this.rightFitLensControl) {
                this.rightFitLensControl.formGroup.disable();
                this.rightFitLensControl.formGroup.disable(); // twice because somehow once is not enough sometimes
            }
        });

        this.leftEyeSelectedChangedSubscription = this.formControls['modify-os'].valueChanges.subscribe((result) => {
            this.fittingService.state.isLeftEyeSideSelected = result;
            if (!result && this.leftFitLensControl) {
                this.leftFitLensControl.formGroup.disable();
                this.leftFitLensControl.formGroup.disable(); // twice because somehow once is not enough sometimes
            }
        });
    }

    ngOnDestroy(): void {
        this.userSettingService.themeSwitchCallback = null;

        if (this.rightEyeSelectedChangedSubscription) {
            this.rightEyeSelectedChangedSubscription.unsubscribe();
        }
        if (this.leftEyeSelectedChangedSubscription) {
            this.leftEyeSelectedChangedSubscription.unsubscribe();
        }
    }

    createForm(): void {
        this.formGroup = this.fb.group({
            'modify-od': [this.fittingService.state.isRightEyeSideSelected],
            'modify-os': [this.fittingService.state.isLeftEyeSideSelected],
        });
    }

    private createFittedLensesForGlass(): void {
        if (this.fittingService.state.isLeftEyeSideSelected && !this.proposal.LeftOpticianFittedLens) {
            this.proposal.LeftOpticianFittedLens = new FittedLens();
            this.proposal.LeftOpticianFittedLens.OpticianId = this.proposal.OpticianId;
        }

        if (this.fittingService.state.isRightEyeSideSelected && !this.proposal.RightOpticianFittedLens) {
            this.proposal.RightOpticianFittedLens = new FittedLens();
            this.proposal.RightOpticianFittedLens.OpticianId = this.proposal.OpticianId;
        }

        this.proposal.LensTypeId = LensTypes.Glass;

        if (!this.proposal.Frame) {
            this.proposal.Frame = new Frame();
        }

        this.leftFittedLens = this.proposal.LeftOpticianFittedLens;
        this.rightFittedLens = this.proposal.RightOpticianFittedLens;
    }

    toggleRightEye(): void {
        this.fittingService.state.isRightEyeSideSelected = !this.fittingService.state.isRightEyeSideSelected;
        if (!this.fittingService.state.isRightEyeSideSelected && this.rightFitLensControl) {
            this.rightFitLensControl.formGroup.disable();
        }
    }

    toggleLeftEye(): void {
        this.fittingService.state.isLeftEyeSideSelected = !this.fittingService.state.isLeftEyeSideSelected;
        if (!this.fittingService.state.isLeftEyeSideSelected && this.leftFitLensControl) {
            this.leftFitLensControl.formGroup.disable();
        }
    }

    previous($event: MouseEvent): void {
        $event.preventDefault();
        this.fittingService.gotoPreviousStep();
    }

    hasLeftLens(): boolean {
        return this.proposal && this.proposal.LeftOpticianFittedLens && this.formControls['modify-os'].value === true;
    }

    hasRightLens(): boolean {
        return this.proposal && this.proposal.RightOpticianFittedLens && this.formControls['modify-od'].value === true;
    }

    orderAllowed(): boolean {
        return !this.formGroup.invalid && (this.hasLeftLens() || this.hasRightLens());
    }

    handleFitLensLoading(eyeSide: EyeSides, loading: boolean): void {
        if (eyeSide === EyeSides.Os) {
            this.leftFitLensLoading = loading;
        } else if (eyeSide === EyeSides.Od) {
            this.rightFitLensLoading = loading;
        }
    }

    get loading(): boolean {
        return false;
    }

    isNextEnabled(): boolean {
        return this.orderAllowed() && this.glassframe.glassFrameFormGroup.valid && !this.loading;
    }

    directOrderIsEnabled(): boolean {
        if (this.fittingService.isCartItem()) {
            return false;
        }

        return this.isNextEnabled();
    }

    get orderHasEmptyValues(): boolean {
        return false;
    }

    async next(supportOrder: boolean) {
        if (supportOrder && this.orderHasEmptyValues) {
            const options: ModalOptions<PopupConfig> = {
                initialState: {
                    popupText: 'fitlens.emptyfields',
                    buttons: [new PopupButton('general.close', 'btn-default', null)],
                },
                class: 'modal-dialog',
            };
            this.modalService.show(PopupComponent, options);
            return;
        }

        if (this.proposal.Frame != null) {
            if (
                this.proposal.GlassTreatmentId === Treatments.remoteEdging ||
                this.proposal.GlassTreatmentId === Treatments.remoteEdgingAndFitting
            ) {
                this.proposal.Frame.EdgePinbevel = true;
            }
        }

        this.fittingService.setProposalStepOnNextClick();
        const saveResult = await this.save(supportOrder);
        this.fittingService.clearSaveForContinationConfiguration();
        this.fittingService.setProposal(saveResult);
        this.loaderService.hide();
        this.fittingService.gotoNextStep();
    }

    private save(supportOrder: boolean): Promise<Proposal> {
        this.loaderService.show();

        const prop = this.proposal;

        prop.OrderWithSupport = supportOrder;

        prop.LeftOpticianFittedLens = this.leftFitLensControl ? this.leftFitLensControl.readFittedLens() : null;
        prop.RightOpticianFittedLens = this.rightFitLensControl ? this.rightFitLensControl.readFittedLens() : null;

        prop.LeftOpticianFittedLensId = prop.LeftOpticianFittedLens ? prop.LeftOpticianFittedLens.Id : null;
        prop.RightOpticianFittedLensId = prop.RightOpticianFittedLens ? prop.RightOpticianFittedLens.Id : null;

        if (prop.GlassTreatmentId === Treatments.none) {
            prop.Frame = null;
        } else if (prop.GlassTreatmentId === Treatments.remoteEdgingAndFitting) {
            prop.Frame.TraceFile = null;
        }

        return lastValueFrom(this.proposalService.saveGlassFittedLenses(prop));
    }

    changeFrameMaterial(frameMaterial: string) {
        this.proposal.Frame.Material = frameMaterial;
    }

    changeGlassTreatment(glassTreatment: Treatments) {
        this.proposal.GlassTreatmentId = glassTreatment;
    }

    changeFrameBrand(frameBrand: string) {
        this.proposal.Frame.Brand = frameBrand;
    }

    changeFrameModel(frameModel: string) {
        this.proposal.Frame.Model = frameModel;
    }

    changeFrameColor(frameColor: string) {
        this.proposal.Frame.Color = frameColor;
    }

    changePupilDistanceLeft(pupilDistanceLeft: number) {
        this.proposal.Frame.PupilDistanceLeft = pupilDistanceLeft;
    }

    changePupilDistanceRight(pupilDistanceRight: number) {
        this.proposal.Frame.PupilDistanceRight = pupilDistanceRight;
    }

    changeOcularCenterLeft(ocularCenterLeft: number) {
        this.proposal.Frame.OcularCenterLeft = ocularCenterLeft;
    }

    changeOcularCenterRight(ocularCenterRight: number) {
        this.proposal.Frame.OcularCenterRight = ocularCenterRight;
    }

    changeFrameSizeBoxA(frameSizeBoxA: number) {
        this.proposal.Frame.SizeBoxA = frameSizeBoxA;
    }

    changeFrameSizeBoxB(frameSizeBoxB: number) {
        this.proposal.Frame.SizeBoxB = frameSizeBoxB;
    }

    changeFrameDblDimension(frameDblDimension: number) {
        this.proposal.Frame.DblDimension = frameDblDimension;
    }

    changeBevelType(bevelTypeId: BevelTypes, bevelTypeValue: string) {
        this.fittingService.state.selectedBevelType = bevelTypeId;
        this.proposal.Frame.BevelType = bevelTypeValue;
    }

    changeBevelPosition(bevelPosition: number) {
        this.proposal.Frame.BevelPosition = bevelPosition;
    }

    changeEdgePolish(edgePolish: boolean) {
        this.proposal.Frame.EdgePolish = edgePolish;
    }

    changeTraceFile(traceFile: string) {
        this.proposal.Frame.TraceFile = traceFile;
    }

    changeTracerName(tracerName: string) {
        this.proposal.Frame.TracerName = tracerName;
    }

    changeFrameTracerSizeBoxA(frameTracerSizeBoxA: number) {
        this.proposal.Frame.TracerSizeBoxA = frameTracerSizeBoxA;
    }

    changeFrameTracerSizeBoxB(frameTracerSizeBoxB: number) {
        this.proposal.Frame.TracerSizeBoxB = frameTracerSizeBoxB;
    }

    changeFrameTracerDblDimension(frameTracerDblDimension: number) {
        this.proposal.Frame.TracerDblDimension = frameTracerDblDimension;
    }
}
