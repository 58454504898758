<div class="mt-3 mb-4 mx-auto col-12 col-xl-10 offset-xl-1">
    <div class="title-border-bottom pb-2">
        <h3 class="mb-0 align-self-center">
            {{ 'ps.clientmaintenance' | translate }}
        </h3>
    </div>

    <div class="container-fluid mt-3 px-0">
        <div class="row">
            <div class="col-12">
                <table class="ps-datatable">
                    <thead>
                        <tr>
                            <th *ngFor="let field of searchfields">
                                {{ 'clientmaintenance.' + field | translate }}
                            </th>
                        </tr>
                    </thead>

                    <tbody *ngIf="findClientsResponse">
                        <tr [formGroup]="filterClientsForm">
                            <td *ngFor="let field of searchfields">
                                <input
                                    class="form-control"
                                    formControlName="{{ field }}"
                                    type="text"
                                    (change)="onFilterByField()" />
                            </td>
                        </tr>
                        <ng-container *ngIf="!loading">
                            <tr
                                *ngFor="let client of findClientsResponse"
                                (click)="onSelectClient(client)"
                                class="table_entry">
                                <td class="m-1">{{ client.Reference }}</td>
                                <td class="m-1">{{ client.Reference2 }}</td>
                                <td class="m-1">
                                    {{ client.BirthDate | mpcdate }}
                                </td>
                                <td class="m-1">
                                    {{ genderName(client.GenderId) }}
                                </td>
                                <td class="m-1">{{ client.OpticianName }}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <div class="spinner-border spinner-border-sm" *ngIf="loading"></div>
            </div>
        </div>
    </div>
</div>

<div *footer class="row">
    <div class="ms-auto d-flex">
        <div class="d-flex align-items-center">
            <button type="button" class="btn btn-proceed mx-1" id="importFromZip" (click)="importClient()">
                ZIP {{ 'manage.imports.title' | translate }}
            </button>
        </div>
    </div>
</div>
