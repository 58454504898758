import { NgModule } from '@angular/core';
import { DownloadableProductPipe } from './product.downloadable.pipe';
import { FilterPipe } from './filter.pipe';
import { TruncatePipe } from './truncate.pipe';
import { DatePipeProxy } from './date.pipe';
import { NumberFormatPipe } from './number.format.pipe';
import { TimePipeProxy } from './time.pipe';
import { SafePipe } from './safe.pipe';
import { SplitPipe } from './split.pipe';
import { AllowedLanguageInVersionOnePipe } from './allowed-language-in-version-one.pipe';
import { EmptyObjectPipe } from './empty-object.pipe';
import { NullWithDefaultPipe } from './null-with-default.pipe';
import { SupportOrderPipe } from '@app/shared/pipes/support-order.pipe';
import { SortClassPipe } from './sort-class.pipe';
import { SplitStringPipe } from './split-string.pipe';
import { InputTypeValidationPipe } from './input-type-validation.pipe';
import { ParameterFormatterPipe } from './parameter-formatter.pipe';
import { ParameterTranslatorPipe } from './parameter-translator.pipe';
import { AddSpacesToCommasPipe } from './add-spaces-to-commas.pipe';
import { LensDefinitionParameterNumberRangeFilterPipe } from './lens-definition-parameter-number-range-filter.pipe';
import { LensDefinitionParameterListOptionSelectorPipe } from './lens-definition-parameter-list-option-selector.pipe';
import { FilterByKeyValuePipe } from '@app/shared/pipes/filter-by-key-value.pipe';
import { NetAmountPipe } from '@app/shared/pipes/net-amount.pipe';
import { RangeCheckPipe } from './range-check.pipe';
import { HumanizeExpressionPipe } from './humanize-expression.Pipe';
import { IsInArrayPipe } from './is-in-array.pipe';

@NgModule({
    declarations: [
        DatePipeProxy,
        DownloadableProductPipe,
        FilterPipe,
        NumberFormatPipe,
        TimePipeProxy,
        TruncatePipe,
        InputTypeValidationPipe,
        SafePipe,
        SplitPipe,
        AllowedLanguageInVersionOnePipe,
        EmptyObjectPipe,
        NullWithDefaultPipe,
        SupportOrderPipe,
        SortClassPipe,
        SplitStringPipe,
        ParameterFormatterPipe,
        ParameterTranslatorPipe,
        LensDefinitionParameterNumberRangeFilterPipe,
        LensDefinitionParameterListOptionSelectorPipe,
        AddSpacesToCommasPipe,
        FilterByKeyValuePipe,
        NetAmountPipe,
        RangeCheckPipe,
        HumanizeExpressionPipe,
        IsInArrayPipe,
    ],
    exports: [
        DatePipeProxy,
        DownloadableProductPipe,
        FilterPipe,
        NumberFormatPipe,
        TimePipeProxy,
        TruncatePipe,
        InputTypeValidationPipe,
        SafePipe,
        SplitPipe,
        NullWithDefaultPipe,
        AllowedLanguageInVersionOnePipe,
        EmptyObjectPipe,
        SupportOrderPipe,
        SortClassPipe,
        SplitStringPipe,
        ParameterFormatterPipe,
        ParameterTranslatorPipe,
        LensDefinitionParameterNumberRangeFilterPipe,
        LensDefinitionParameterListOptionSelectorPipe,
        AddSpacesToCommasPipe,
        ParameterTranslatorPipe,
        FilterByKeyValuePipe,
        NetAmountPipe,
        RangeCheckPipe,
        HumanizeExpressionPipe,
        IsInArrayPipe,
    ],
})
export class PipesModule {}
