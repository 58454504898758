import { Component, EventEmitter, Output } from '@angular/core';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserSettingService } from '@app/core/services/api/user-setting.service';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'order-training-dialog',
    templateUrl: './order-training-dialog.component.html',
})
export class OrderTrainingDialogComponent {
    @Output() confirm: EventEmitter<unknown> = new EventEmitter();

    constructor(
        private readonly modalRef: BsModalRef,
        private readonly userSettingsService: UserSettingService,
        private readonly appState: AppStateService,
    ) {}

    async onConfirm() {
        const user = await lastValueFrom(this.userSettingsService.setTrainingCompleteConfirmation());
        this.appState.onUserSettingChange(user);

        this.confirm.emit();
        this.onClose();
    }

    onClose() {
        this.modalRef.hide();
    }
}
