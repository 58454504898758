import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ReceiptCreatedComponent } from './pages/receipt-created/receipt-created.component';
import { ReceiptOrderComponent } from './pages/receipt-order/receipt-order.component';
import { RegisterOpticianComponent } from './pages/register-optician/register-optician.component';
import { ReceiptDetailsDialogComponent } from './components/receipt-details-dialog/receipt-details-dialog.component';
import { ReceiptDetailsComponent } from './components/receipt-details/receipt-details.component';
import { ReceiptStatusItemComponent } from './components/receipt-status-item/receipt-status-item.component';
import { LayoutRegisterComponent } from './pages/layout-register/layout-register.component';
import { ReceiptOrderSentComponent } from './pages/receipt-order-sent/receipt-order-sent.component';
import { ReceiptStatusComponent } from './pages/receipt-status/receipt-status.component';
import { RegisterOptcianSuccessComponent } from './pages/register-optician-success/register-optician-success.component';
import { ReceiptRoutingModule } from './receipt.routing.module';
import { ReceiptComponent } from './receipt.component';

@NgModule({
    imports: [TypeaheadModule.forRoot(), CommonModule, SharedModule, ReceiptRoutingModule],
    declarations: [
        ReceiptComponent,
        ReceiptCreatedComponent,
        ReceiptOrderComponent,
        RegisterOpticianComponent,
        RegisterOptcianSuccessComponent,
        LayoutRegisterComponent,
        ReceiptOrderSentComponent,
        ReceiptStatusComponent,
        ReceiptStatusItemComponent,
        ReceiptDetailsDialogComponent,
        ReceiptDetailsComponent,
    ],
    providers: [],
})
export class ReceiptModule {}
