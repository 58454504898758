import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { TranslatorRoutingModule } from './translator.routing.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TranslatorComponent } from './translator.component';
import { HomeComponent } from './pages/home/home.component';
import { TranslationsComponent } from './pages/translations/translations.component';
import { NgxEditorModule } from 'ngx-editor';

@NgModule({
    imports: [CommonModule, TranslatorRoutingModule, ModalModule, NgxDatatableModule, SharedModule, NgxEditorModule],
    declarations: [TranslatorComponent, HomeComponent, TranslationsComponent],
})
export class TranslatorModule {}
