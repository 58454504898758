import { Component, Input, Output, HostBinding, EventEmitter } from '@angular/core';
import { FittingService } from '@app/core/services/fitting.service';

@Component({
    selector: 'fitting-header-item',
    templateUrl: './fitting-header-item.component.html',
    styleUrls: ['fitting-header-item.component.scss'],
})
export class FittingHeaderItemComponent {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() imageName: string;
    @Input() iconName: string;
    @Input() step: number;
    @Input() currentStep: number;

    @Output() click: EventEmitter<unknown> = new EventEmitter<unknown>();

    /**
     * Returns whether or not the host element should be stretched
     * for the current step.
     */
    @HostBinding('class.host-stretch') get hostStretch(): boolean {
        return +this.step === +this.currentStep;
    }

    constructor(private readonly fittingService: FittingService) {}

    getClass(): object {
        return {
            'step-past': +this.step < +this.currentStep,
            'step-current': +this.step === +this.currentStep,
            'step-future': +this.step > +this.currentStep,
        };
    }

    getTitleClass(): object {
        // show current step title on small devices and larger
        return {
            'd-sm-block': +this.step === +this.currentStep,
        };
    }

    onContinue(): void {
        this.fittingService.gotoStep(this.step);
    }

    canSave(): boolean {
        return this.fittingService.canSaveForThisStep(this.step);
    }

    isSaveEnabled(): boolean {
        return this.fittingService.isSaveEnabled();
    }

    saveAndReset(): void {
        this.fittingService.saveAndReset();
    }
}
