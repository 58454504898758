import { NgModule } from '@angular/core';
import { Router, Routes, RouterModule, Event, NavigationError, NavigationStart, NavigationEnd } from '@angular/router';
import {
    AccessRightsAuthGuard,
    CurrentRoleAuthGuard,
    DynamicsDistributorAccessGuard,
    FeatureFlagAuthGuard,
    FittingUnsavedChangesGuard,
    LicenseAuthGuard,
    RegionSelectedGuard,
    RoleRouteResolverGuard,
} from '@app/core/guards';
import { throwError } from 'rxjs';
import { PageNotFoundComponent } from '@app/core/components/page-not-found/page-not-found.component';
import { MainComponent } from '@app/layout/main/main.component';
import { environment } from '@env/environment';
import { Features, Roles } from '@app/shared/enums';
import { FittingClientComponent } from '@app/fitting/fitting-client/fitting-client.component';
import { FittingClientSearchComponent } from '@app/fitting/fitting-client-search.component';
import { FittingMeasurementComponent } from '@app/fitting/fitting-measurement.component';
import { FittingRefractionComponent } from '@app/fitting/fitting-refraction.component';
import { FittingLensTypeComponent } from '@app/fitting/fitting-lenstype.component';
import { FittingFitLensComponent } from '@app/fitting/fitting-fitlens.component';
import { FittingOverviewComponent } from '@app/fitting/fitting-overview.component';
import { FittingComponent } from '@app/fitting/fitting.component';
import { FittingClientEditComponent } from './fitting/fitting-client-edit.component';
import { FooterService } from '@app/shared/appservices/footer.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { OrderSentComponent } from '@app/order/order-sent.component';
import { LensOrderSentComponent } from '@app/order/lens-order-sent.component';
import { GlassOrderSentComponent } from '@app/order/glass-order-sent.component';
import { OrderStatusComponent } from '@app/order/order-status/order-status.component';
import { AccessRights } from '@app/shared/models/accessRights';
import { ClientMaintenanceComponent } from '@app/client/client-maintenance.component';
import { TestutilitiesComponent } from './testutilities/testutilities.component';
import { ListEmailComponent } from './testutilities/email/list-email.component';
import { GlassFittingFitGlassComponent } from '@app/fitting/fitting-fitglass.component';
import { FittingGlassOverviewComponent } from './fitting/fitting-glass-overview.component';

export const MAIN_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => import('@app/features/optician/optician.module').then((m) => m.OpticianModule),
        canActivate: [RegionSelectedGuard, RoleRouteResolverGuard, CurrentRoleAuthGuard],
        data: { role: Roles.Optician },
    },
    {
        path: 'translator',
        loadChildren: () => import('@app/features/translator/translator.module').then((m) => m.TranslatorModule),
        canActivate: [RegionSelectedGuard],
    },
    {
        path: 'manage',
        loadChildren: () => import('@app/features/manage/manage.module').then((m) => m.ManageModule),
        canActivate: [RegionSelectedGuard],
    },
    {
        path: 'ps',
        loadChildren: () => import('@app/professional-service/ps.module').then((m) => m.PsModule),
        canActivate: [RegionSelectedGuard],
    },
    {
        path: 'service',
        loadChildren: () => import('@app/features/service/service.module').then((m) => m.ServiceModule),
        canActivate: [RegionSelectedGuard],
    },
    {
        path: 'qualityassurance',
        loadChildren: () => import('@app/features/quality-assurance/qa.module').then((m) => m.QaModule),
        canActivate: [RegionSelectedGuard],
    },
    {
        path: 'logistics',
        loadChildren: () => import('@app/logistic/logistic.module').then((m) => m.LogisticModule),
        canActivate: [RegionSelectedGuard],
    },
    {
        path: 'orderstatus',
        component: OrderStatusComponent,
        canActivate: [RegionSelectedGuard, CurrentRoleAuthGuard, LicenseAuthGuard],
        data: { role: Roles.Optician },
    },
    {
        path: 'ordersent',
        component: OrderSentComponent,
        canActivate: [RegionSelectedGuard, CurrentRoleAuthGuard],
        data: { role: Roles.Optician },
    },
    {
        path: 'ordersent/:id',
        component: OrderSentComponent,
        canActivate: [RegionSelectedGuard, CurrentRoleAuthGuard],
        data: { role: Roles.Optician },
    },
    {
        path: 'lensordersent/:id',
        component: LensOrderSentComponent,
        canActivate: [RegionSelectedGuard, CurrentRoleAuthGuard],
        data: { role: Roles.Optician },
    },
    {
        path: 'glassordersent/:id',
        component: GlassOrderSentComponent,
        canActivate: [RegionSelectedGuard, CurrentRoleAuthGuard],
        data: { role: Roles.Optician },
    },
    {
        path: 'clients',
        component: ClientMaintenanceComponent,
        canActivate: [RegionSelectedGuard, CurrentRoleAuthGuard],
        data: { role: Roles.Optician },
    },
    {
        path: 'cart',
        loadChildren: () => import('@app/features/cart/cart.module').then((m) => m.CartModule),
        canActivate: [RegionSelectedGuard],
    },
    {
        path: 'dynamics',
        loadChildren: () => import('@app/dynamics/dynamics.module').then((m) => m.DynamicsModule),
        canActivate: [RegionSelectedGuard, CurrentRoleAuthGuard, DynamicsDistributorAccessGuard],
        data: { role: Roles.Optician, featureFlag: Features.ShowDynamics },
    },
    {
        path: 'test-utilities/list-email',
        component: ListEmailComponent,
        canActivate: [RegionSelectedGuard],
    },
    {
        path: 'test-utilities',
        component: TestutilitiesComponent,
        canActivate: [RegionSelectedGuard],
    },
    {
        path: 'shop',
        loadChildren: () => import('@app/features/shop/shop.module').then((m) => m.ShopModule),
        canActivate: [RegionSelectedGuard, CurrentRoleAuthGuard, LicenseAuthGuard, AccessRightsAuthGuard],
        data: {
            role: Roles.Optician,
            accessRights: { webshop: true } as AccessRights,
        },
    },
    {
        path: 'receipt',
        loadChildren: () => import('@app/features/receipt/receipt.module').then((m) => m.ReceiptModule),
        canActivate: [RegionSelectedGuard],
    },
    {
        path: '',
        component: FittingComponent,
        children: [
            { path: 'searchclient', component: FittingClientSearchComponent },
            { path: 'client/new', component: FittingClientEditComponent },
            { path: 'client/edit/:id', component: FittingClientEditComponent },
            { path: 'client/:id', component: FittingClientComponent },
            {
                path: 'measurement',
                component: FittingMeasurementComponent,
                canActivate: [FeatureFlagAuthGuard],
                data: { featureFlag: Features.EnableFitLens },
            },
            { path: 'refraction', component: FittingRefractionComponent },
            { path: 'lenstype', component: FittingLensTypeComponent },
            { path: 'fitlens', component: FittingFitLensComponent },
            { path: 'fitglass', component: GlassFittingFitGlassComponent },
            { path: 'overview', component: FittingOverviewComponent },
            { path: 'glassoverview', component: FittingGlassOverviewComponent },
        ],
        canActivate: [RegionSelectedGuard, CurrentRoleAuthGuard],
        canDeactivate: [FittingUnsavedChangesGuard],
        data: { role: Roles.Optician },
    },
];

const appRoutes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('@app/features/auth/auth.module').then((m) => m.AuthModule),
        canActivate: [RegionSelectedGuard],
    },
    {
        path: 'region',
        loadChildren: () =>
            import('@app/features/region-selector/region-selector.module').then((m) => m.RegionSelectorModule),
    },
    {
        path: '',
        component: MainComponent,
        children: MAIN_ROUTES,
        canActivate: [LicenseAuthGuard],
    },
    {
        path: '**',
        component: MainComponent,
        children: [{ path: '**', component: PageNotFoundComponent }],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {})],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {
    // eslint-disable-next-line
    constructor(
        private readonly router: Router,
        private readonly footerService: FooterService,
        private readonly loaderService: LoaderService,
    ) {
        this.router.events.subscribe((event: Event) => {
            if (event) {
                if (event instanceof NavigationStart) {
                    if (!environment.production) {
                        console.log(`Router navigate to: ${event.url}`);
                    }
                }

                if (event instanceof NavigationEnd) {
                    this.footerService.clearContents();
                    this.loaderService.hide();
                }

                if (event instanceof NavigationError) {
                    throwError(`Error navigating to: ${event.error}`);
                    this.loaderService.hide();
                }
            }
        });
    }
}
