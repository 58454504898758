<div class="modal-header">
    <h4 class="modal-title m-auto" id="confirmtitle">
        {{ 'home.releasenotes' | translate }}
    </h4>
</div>
<div class="modal-body">
    <p id="intromessage">
        {{ 'home.releasenotes.intro' | translate }}
    </p>

    <div class="d-flex align-items-center rounded img-overlay img-loading" *ngIf="!releaseNotes">
        <div class="spinner-border spinner-border-sm m-auto"></div>
    </div>

    <ng-container *ngIf="releaseNotes && releaseNotes.length > 0">
        <ng-container *ngFor="let note of releaseNotes">
            <p class="mb-0">
                <strong>{{ note.title }}</strong>
            </p>
            <div [innerHTML]="note.excerpt"></div>
        </ng-container>
    </ng-container>
</div>
