import { Injectable } from '@angular/core';
@Injectable()
export class SessionService {
    getAsObject<T>(key: string): T {
        const value = sessionStorage.getItem(key);
        if (value != null) {
            try {
                return JSON.parse(value) as T;
            } catch {
                return null;
            }
        }
        return null;
    }

    get(key: string): string {
        return sessionStorage.getItem(key);
    }

    getKeys(): string[] {
        const result = [];
        for (let i = 0; i < sessionStorage.length; i++) {
            result.push(sessionStorage.key(i));
        }
        return result;
    }

    save(key: string, data: unknown): unknown {
        const stringData = typeof data === 'string' ? data : JSON.stringify(data);
        sessionStorage.setItem(key, stringData);
        return this;
    }

    remove(key: string): void {
        sessionStorage.removeItem(key);
    }

    clearAll(): void {
        sessionStorage.clear();
    }
}
