<div class="mt-2">
    <table class="ps-datatable">
        <thead>
            <tr>
                <th *ngFor="let field of headerFields" (click)="onSortByField(field)">
                    {{ 'complaintsoverview.' + field | translate }}
                    <i
                        *ngIf="field === currentAscendingField && !currentIsAscending"
                        class="fas fa-caret-up ms-auto"></i>
                    <i
                        *ngIf="
                            field !== currentAscendingField || (field === currentAscendingField && currentIsAscending)
                        "
                        class="fas fa-caret-down ms-auto"></i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="returns">
            <tr [formGroup]="formGroup">
                <td *ngFor="let field of headerFields">
                    <input
                        *ngIf="field !== 'ReturnCategoryName' && field !== 'EyeSideName'"
                        class="form-control"
                        formControlName="{{ field }}"
                        type="text" />

                    <select
                        *ngIf="field === 'EyeSideName'"
                        name="{{ field }}"
                        class="form-control"
                        formControlName="{{ field }}">
                        <option value=""></option>
                        <option value="{{ eyeSide.Os }}">
                            {{ 'complaintsoverview.eyeside.left' | translate }}
                        </option>
                        <option value="{{ eyeSide.Od }}">
                            {{ 'complaintsoverview.eyeside.right' | translate }}
                        </option>
                    </select>
                    <select
                        *ngIf="field === 'ReturnCategoryName'"
                        name="{{ field }}"
                        class="form-control"
                        formControlName="{{ field }}">
                        <option value=""></option>
                        <option value="{{ returnCategoryName.Credit }}">
                            {{ 'complaintsoverview.category.credit' | translate }}
                        </option>
                        <option value="{{ returnCategoryName.Measure }}">
                            {{ 'complaintsoverview.category.service' | translate }}
                        </option>
                        <option value="{{ returnCategoryName.Complaint }}">
                            {{ 'complaintsoverview.category.complaint' | translate }}
                        </option>
                    </select>
                </td>
            </tr>
        </tbody>
        <div class="spinner-border spinner-border-sm mt-4 ms-4" *ngIf="loading"></div>
        <tbody *ngIf="returns && !loading">
            <tr
                *ngFor="let returnItem of returns"
                class="table_entry"
                [ngClass]="{
                    selected: returnItem.ReturnNumber === SelectedReturnNumber
                }"
                (click)="selectReturn(returnItem)"
                (dblclick)="doubleClick(returnItem)">
                <td class="m-1">{{ returnItem.ReturnNumber }}</td>
                <td class="m-1">{{ returnItem.InProgressDate | mpcdate }}</td>
                <td class="m-1">{{ returnItem.SerialNumber }}</td>
                <td class="m-1">{{ returnItem.OpticianName }}</td>
                <td class="m-1">{{ returnItem.ClientReference }}</td>
                <td class="m-1">{{ returnItem.ClientReference2 }}</td>
                <td class="m-1">{{ returnItem.EyeSideName }}</td>
                <td class="m-1">{{ returnItem.ProductName }}</td>
                <td class="m-1">{{ returnItem.ReturnCategoryName }}</td>
                <td class="m-1">{{ returnItem.IsChangedByUserName }}</td>
            </tr>
        </tbody>
    </table>
    <app-paging
        [totalReturns]="totalReturnCount"
        [pageReturnCount]="pageReturnCount"
        [pageSize]="returnQueryCriteria.PageSize"
        (pageIndexEmitter)="receivePagingParameters($event)"></app-paging>
</div>
