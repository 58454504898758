import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mpcdate',
})
export class DatePipeProxy implements PipeTransform {
    public transform(value: Date, pattern = 'shortDate'): string {
        const currentLang = navigator.language;
        const ngPipe = new DatePipe(currentLang);

        if (pattern == 'shortDate') {
            switch (currentLang) {
                case 'en':
                case 'en-US':
                case 'en-CA':
                    pattern = 'MM/dd/yyyy'; // Default pattern has only 2 digits for year
                    break;
                case 'nl':
                case 'nl-NL':
                case 'de':
                case 'de-DE':
                case 'en-GB':
                default: // Default pattern has only 2 digits for year
                    pattern = 'dd-MM-yyyy';
                    break;
            }
        }

        if (value && value.toString() !== 'Invalid Date') {
            try {
                return ngPipe.transform(value, pattern);
            } catch (error) {
                console.warn(`Locale ${currentLang} is not included, fallback to english`);
                return new DatePipe('en-EN').transform(value, pattern);
            }
        }
        return '';
    }
}
