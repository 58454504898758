import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ConfirmComponent } from '@app/shared/components/confirm/confirm.component';

@Injectable()
export class ConfirmService {
    private modalRef: BsModalRef;

    constructor(private modalService: BsModalService) {}

    public show(title: string, message: string): Promise<boolean> {
        const options: unknown = {
            initialState: { title, message },
            class: 'modal-md',
        };
        this.modalRef = this.modalService.show(ConfirmComponent, options);
        return (this.modalRef.content as ConfirmComponent).onClosePromise;
    }
}
