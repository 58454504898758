export class Post {
    public id: number;
    public title: string;
    public excerpt: string;
    public categories: Array<number>;
    public featuredImage: string;
    public content: string;

    constructor() {
        this.featuredImage = '../../../assets/images/procornea-building.jpeg';
    }
}
