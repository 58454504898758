import { Component, OnInit, Input, Output, EventEmitter, DestroyRef, inject } from '@angular/core';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, tap } from 'rxjs/operators';
import { ListOption, ListSelectOption, UserSetting } from '@app/shared/models';
import { ImageOptions } from '@app/shared/models/image-options.model';
import { LensTypes, MeasurementImageModes } from '@app/shared/enums';
import { TranslateService } from '@ngx-translate/core';
import { InputNumberRange } from '@app/shared/models/input-number-range.model';
import { UserSettingService } from '@app/core/services/api/user-setting.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'measurement-image-options',
    templateUrl: 'measurement-image-options.component.html',
})
export class MeasurementImageOptionsComponent implements OnInit {
    @Input() id = '';
    @Input() imageOptions: ImageOptions;
    @Input() disabled = false;

    @Output() imageOptionsChanged: EventEmitter<ImageOptions> = new EventEmitter();
    @Output() imageModeChanged: EventEmitter<MeasurementImageModes> = new EventEmitter();

    public parentFormGroup: UntypedFormGroup;
    public formGroup: UntypedFormGroup;
    get formControls() {
        return this.formGroup.controls;
    }

    public loading = true;
    public imageTypes: ListSelectOption[] = [];
    public imageChoices: ListSelectOption[] = [];
    public mmDptTypes: ListSelectOption[] = [];
    public normalizeOptions: ListSelectOption[] = [];
    public axitangOptions: ListSelectOption[] = [];
    public rotationRange: InputNumberRange;
    public pupilDiamRange: InputNumberRange;
    public currentImageMode: MeasurementImageModes;
    public MeasurementImageModes = MeasurementImageModes;

    private readonly destroyRef = inject(DestroyRef);

    constructor(
        private readonly parent: FormGroupDirective,
        private readonly fb: UntypedFormBuilder,
        private readonly translate: TranslateService,
        private readonly userSettingService: UserSettingService,
    ) {}

    public ngOnInit(): void {
        this.parentFormGroup = this.parent.form;

        this.createFormOptions();
        this.createForm();

        this.formControls['imageType'].valueChanges
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result: ListOption) => {
                this.selectImageType(result);
            });

        this.formGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500)).subscribe(() => {
            this.updateImageOptions();
        });

        this.userSettingService
            .getUserSettings()
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap((userSettings: UserSetting) => {
                    this.formGroup.patchValue({
                        imageChoices: this.imageChoices.find((x) => x.Id === userSettings.UseImageType),
                        useNormalize: this.normalizeOptions[userSettings.UseNormalize],
                        useMapType: this.axitangOptions[userSettings.UseMapType],
                        useMm: this.mmDptTypes[userSettings.UseMm],
                    });
                }),
            )
            .subscribe();

        this.createListOfImageTypes();
        this.updateImageOptions();

        this.loading = false;
    }

    private createFormOptions(): void {
        this.mmDptTypes.push(
            new ListSelectOption(0, this.translate.instant('general.units.MM'), 'mm'),
            new ListSelectOption(1, this.translate.instant('general.units.DPT'), 'dpt'),
        );

        this.imageChoices.push(
            new ListSelectOption(1, this.translate.instant('measurement.current'), 'current'),
            new ListSelectOption(2, this.translate.instant('measurement.diff'), 'diff'),
            new ListSelectOption(3, this.translate.instant('general.baseline'), 'baseline'),
        );

        this.normalizeOptions.push(
            new ListSelectOption(0, this.translate.instant('measurementoptions.absolute'), 'false'),
            new ListSelectOption(1, this.translate.instant('measurementoptions.normalize'), 'true'),
        );

        this.axitangOptions.push(
            new ListSelectOption(0, this.translate.instant('measurementoptions.axial'), 'false'),
            new ListSelectOption(1, this.translate.instant('measurementoptions.tangential'), 'true'),
        );

        this.rotationRange = new InputNumberRange(0, 180, 5);
        this.pupilDiamRange = new InputNumberRange(2, 9, 0.05);
    }

    private createForm(): void {
        this.formGroup = this.fb.group({
            imageType: [this.imageOptions.leftImageMode],
            imageChoice: [this.imageChoices[0].Id],
            useAxis: [this.imageOptions.UseAxis],
            useNormalize: [this.normalizeOptions[0].Id],
            useMapType: [this.axitangOptions[0].Id],
            useMm: [this.mmDptTypes[0].Id],
            useSimK: [this.imageOptions.topoImageOptions.UseSimK],
            powerProfileAxis: [],
            pupilDiam: [],
        });

        this.formGroup.setParent(this.parentFormGroup);

        if (this.parentFormGroup.controls[this.id]) {
            this.parentFormGroup.removeControl(this.id);
        }

        this.parentFormGroup.addControl(this.id, this.formGroup);
    }

    private createListOfImageTypes(): void {
        this.imageTypes = [];

        switch (this.imageOptions.lensTypeId) {
            case LensTypes.Rgp:
            case LensTypes.Med:
            case LensTypes.MedPlus:
            case LensTypes.Crt:
            case LensTypes.DreamLite:
                this.imageTypes.push(
                    new ListSelectOption(
                        MeasurementImageModes.Fluo,
                        this.translate.instant('measurementoptions.fluo'),
                        MeasurementImageModes.Fluo.toString(),
                    ),
                );
                this.imageTypes.push(
                    new ListSelectOption(
                        MeasurementImageModes.Topo,
                        this.translate.instant('measurementoptions.topo'),
                        MeasurementImageModes.Topo.toString(),
                    ),
                );
                this.imageTypes.push(
                    new ListSelectOption(
                        MeasurementImageModes.HeightMap,
                        this.translate.instant('measurementoptions.heightmap'),
                        MeasurementImageModes.HeightMap.toString(),
                    ),
                );
                break;
            default:
                this.imageTypes.push(
                    new ListSelectOption(
                        MeasurementImageModes.Topo,
                        this.translate.instant('measurementoptions.topo'),
                        MeasurementImageModes.Topo.toString(),
                    ),
                );
                this.imageTypes.push(
                    new ListSelectOption(
                        MeasurementImageModes.HeightMap,
                        this.translate.instant('measurementoptions.heightmap'),
                        MeasurementImageModes.HeightMap.toString(),
                    ),
                );
                break;
        }

        this.currentImageMode = this.imageOptions.leftImageMode;

        if (this.currentImageMode === MeasurementImageModes.LensProfile) {
            this.currentImageMode = MeasurementImageModes.Fluo;
        }

        this.formControls['imageType'].patchValue(new ListOption(this.currentImageMode, '', ''), { emitEvent: false });
    }

    public selectImageType(selectedImageType: ListOption): void {
        this.currentImageMode = selectedImageType.Id;
        this.imageModeChanged.emit(this.currentImageMode as MeasurementImageModes);
    }

    private updateImageOptions(): void {
        this.imageOptions.UseAxis = this.formControls['useAxis'].value;
        this.imageOptions.ImageChoice = this.imageChoices.find(
            (x) => x.Id === Number(this.formControls['imageChoice'].value),
        ).Code;

        this.imageOptions.topoImageOptions.ImageChoice = this.imageOptions.ImageChoice;
        this.imageOptions.topoImageOptions.UseNormalize = this.formControls['useNormalize'].value.Code === 'true';
        this.imageOptions.topoImageOptions.UseMapType = this.formControls['useMapType'].value.Code === 'true';
        this.imageOptions.topoImageOptions.UseMm = this.formControls['useMm'].value.Code === 'mm';
        this.imageOptions.topoImageOptions.UseSimK = this.formControls['useSimK'].value;
        this.imageOptions.topoImageOptions.PowerProfileAxis = this.formControls['powerProfileAxis'].value;
        this.imageOptions.topoImageOptions.PupilDiam = this.formControls['pupilDiam'].value;

        this.imageOptionsChanged.emit(this.imageOptions);
    }
}
