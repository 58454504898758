<div class="container-fluid mt-4 px-lg-4" [formGroup]="formGroup">
    <div class="col-xl-10 offset-xl-1">
        <div *ngIf="opticianHasManualOnlyLens" class="alert alert-info" role="alert">
            {{ 'measurement.manualCalculationOnlyLensInfo' | translate }}
        </div>
        <div class="row mb-2">
            <div class="col-6">
                <h3 class="mt-auto">{{ 'general.measurement' | translate }}</h3>
            </div>
            <div class="col-6 d-flex">
                <div class="ms-auto">
                    <div class="form-check">
                        <input
                            type="checkbox"
                            formControlName="showHistoricMeasurements"
                            id="showHistoricMeasurements"
                            name="showHistoricMeasurements"
                            class="form-check-input" />
                        <label
                            id="labelfor-showHistoricMeasurements"
                            for="showHistoricMeasurements"
                            class="form-check-label">
                            {{ 'measurement.showhistoricmeasurements' | translate }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- NOT MOBILE -->
    <div class="col-xl-10 offset-xl-1">
        <div class="row" *ngIf="!appState.isMobile">
            <div *ngIf="showOD" class="col-6 pe-2">
                <div class="d-flex py-2 title-border-top">
                    <div>
                        <h4>{{ 'general.od' | translate }}</h4>
                    </div>
                    <div class="ms-auto" *ngIf="isManualCalculationAllowed">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                id="rightManualCalculationSelected"
                                name="rightManualCalculationSelected"
                                class="form-check-input"
                                [(ngModel)]="rightManualCalculationSelected"
                                [ngModelOptions]="{ standalone: true }" />
                            <label
                                id="labelfor-rightManualCalculationSelected"
                                for="rightManualCalculationSelected"
                                class="form-check-label">
                                {{ 'lens.manualCalculation' | translate }}
                            </label>
                        </div>
                    </div>
                </div>
                <ng-container *ngTemplateOutlet="measurementsOD"></ng-container>
            </div>
            <div *ngIf="showOS" class="col-6 ps-2" [ngClass]="{ 'offset-6': !showOD }">
                <div class="d-flex py-2 title-border-top">
                    <h4>{{ 'general.os' | translate }}</h4>
                    <div class="ms-auto" *ngIf="isManualCalculationAllowed">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                id="leftManualCalculationSelected"
                                name="leftManualCalculationSelected"
                                class="form-check-input"
                                [(ngModel)]="leftManualCalculationSelected"
                                [ngModelOptions]="{ standalone: true }" />
                            <label
                                id="labelfor-leftManualCalculationSelected"
                                for="leftManualCalculationSelected"
                                class="form-check-label">
                                {{ 'lens.manualCalculation' | translate }}
                            </label>
                        </div>
                    </div>
                </div>
                <ng-container *ngTemplateOutlet="measurementsOS"></ng-container>
            </div>
        </div>
    </div>

    <!-- MOBILE -->
    <div *ngIf="appState.isMobile" class="mb-2">
        <tabset [justified]="true">
            <tab *ngIf="showOD" id="tab-od">
                <ng-template tabHeading>
                    <h4>{{ 'general.od' | translate }}</h4>
                    <div>
                        <div class="form-check" (click)="toggleMobileManual(eyeSides.Od)">
                            <input
                                type="checkbox"
                                id="rightManualCalculationSelected"
                                name="rightManualCalculationSelected"
                                class="form-check-input"
                                [(ngModel)]="rightManualCalculationSelected"
                                [ngModelOptions]="{ standalone: true }" />
                            <label
                                id="labelfor-rightManualCalculationSelected"
                                for="rightManualCalculationSelected"
                                class="form-check-label">
                                {{ 'lens.manualCalculation' | translate }}
                            </label>
                        </div>
                    </div>
                </ng-template>
                <div class="p-2">
                    <ng-container *ngTemplateOutlet="measurementsOD"></ng-container>
                </div>
            </tab>
            <tab *ngIf="showOS" id="tab-os">
                <ng-template tabHeading>
                    <h4>{{ 'general.os' | translate }}</h4>
                    <div>
                        <div class="form-check" (click)="toggleMobileManual(eyeSides.Os)">
                            <input
                                type="checkbox"
                                id="leftManualCalculationSelected"
                                name="leftManualCalculationSelected"
                                class="form-check-input"
                                [(ngModel)]="leftManualCalculationSelected"
                                [ngModelOptions]="{ standalone: true }" />
                            <label
                                id="labelfor-leftManualCalculationSelected"
                                for="leftManualCalculationSelected"
                                class="form-check-label">
                                {{ 'lens.manualCalculation' | translate }}
                            </label>
                        </div>
                    </div>
                </ng-template>
                <div class="p-2">
                    <ng-container *ngTemplateOutlet="measurementsOS"></ng-container>
                </div>
            </tab>
        </tabset>
    </div>

    <ng-template #measurementsOD>
        <div class="spinner-border spinner-border-sm" *ngIf="loadingMeasurements"></div>
        <div *ngIf="rightManualCalculationSelected">
            <measurement-manual
                #rightManualCalculationComponent
                [manualCalculation]="rightManualCalculation"
                [eyeSide]="eyeSides.Od"
                [id]="'rightManual'"></measurement-manual>
        </div>
        <div *ngIf="!rightManualCalculationSelected">
            <div
                *ngIf="
                    !loadingMeasurements && measurements && rightMeasurements && rightMeasurements.length > 0;
                    else noMeasurementsOD
                ">
                <div class="my-2" *ngFor="let tm of rightMeasurements; let i = index">
                    <measurement-item
                        id="tm-od-{{ i }}"
                        [measurement]="tm"
                        [measurements]="rightMeasurements"
                        [isSelected]="measurementIsSelected(tm.Id)"
                        [inDeleteMode]="inDeleteMode"
                        [imageOptions]="imageOptions"
                        [displayBaselineTag]="IsBaseLine(tm)"
                        [client]="fittingService.state.client"
                        (measurementClicked$)="rightMeasurementClicked($event)"
                        (measurementDeleted$)="measurementDeleted($event)"></measurement-item>
                </div>
            </div>
        </div>
        <ng-template #noMeasurementsOD>
            <div class="my-2" *ngIf="!loadingMeasurements">
                {{ 'measurement.nomeasurements' | translate }}
            </div>
        </ng-template>
    </ng-template>

    <ng-template #measurementsOS>
        <div class="spinner-border spinner-border-sm" *ngIf="loadingMeasurements"></div>
        <div *ngIf="leftManualCalculationSelected">
            <measurement-manual
                #leftManualCalculationComponent
                [manualCalculation]="leftManualCalculation"
                [eyeSide]="eyeSides.Os"
                [id]="'leftManual'"></measurement-manual>
        </div>
        <div *ngIf="!leftManualCalculationSelected">
            <div
                *ngIf="
                    !loadingMeasurements && measurements && leftMeasurements && leftMeasurements.length > 0;
                    else noMeasurementsOS
                ">
                <div class="my-2" *ngFor="let tm of leftMeasurements; let i = index">
                    <measurement-item
                        id="tm-os-{{ i }}"
                        [measurement]="tm"
                        [measurements]="leftMeasurements"
                        [isSelected]="measurementIsSelected(tm.Id)"
                        [inDeleteMode]="inDeleteMode"
                        [imageOptions]="imageOptions"
                        [displayBaselineTag]="IsBaseLine(tm)"
                        [client]="fittingService.state.client"
                        (measurementClicked$)="leftMeasurementClicked($event)"
                        (measurementDeleted$)="measurementDeleted($event)"></measurement-item>
                </div>
            </div>
        </div>
        <ng-template #noMeasurementsOS>
            <div class="my-2" *ngIf="!loadingMeasurements">
                {{ 'measurement.nomeasurements' | translate }}
            </div>
        </ng-template>
    </ng-template>
</div>

<div *footer class="d-flex align-items-center">
    <div>
        <a href="#" (click)="previous($event)">
            <i class="fas fa-arrow-alt-circle-left me-1"></i>
            {{ 'general.client' | translate }}
        </a>
        <button
            *ngIf="!doShowHistoricMeasurements"
            type="button"
            id="toggleDeleteMeasurements"
            class="btn ms-4"
            popover="{{ 'toggleDeleteMeasurements' | translate }}"
            triggers="mouseenter:mouseleave"
            [ngClass]="{
                'btn-danger': inDeleteMode,
                'btn-secondary': !inDeleteMode
            }"
            (click)="this.inDeleteMode = !this.inDeleteMode">
            <i class="fas fa-times-circle fa-lg"></i>
        </button>
    </div>
    <div class="ms-auto d-flex">
        <div *ngIf="fittingService.isFollowUp" class="mt-1 me-2">
            {{ 'measurement.notoposneeded' | translate }}
        </div>
        <button type="button" id="next" class="btn btn-proceed" [disabled]="!isNextEnabled" (click)="next()">
            {{ 'general.next' | translate }}
        </button>
    </div>
</div>
