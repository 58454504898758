import { Features, FeatureCategories } from '../enums';
import { Company } from './company.model';
import { Distributor } from './distributor.model';
import { Optician } from './optician.model';

export class FeatureSetting {
    public Feature: Features;
    public Category: FeatureCategories;
    public IsEnabled: boolean;
    public OpticianToCheck?: Optician;
    public DistributorToCheck?: Distributor;
    public CompanyToCheck?: Company;
}
