import { Pipe, PipeTransform } from '@angular/core';
import { NumberFormatLogic } from '@app/shared/helpers/number.format.logic';
import { NumberFormatOptions } from '@app/shared/models/numberFormatOptions.model';

@Pipe({
    name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    transform(value: any, numberFormat: unknown): any {
        if ((value || value === 0) && numberFormat) {
            let numberFormatOptions: NumberFormatOptions;

            if (numberFormat instanceof String || typeof numberFormat === 'string') {
                numberFormatOptions = <NumberFormatOptions>JSON.parse(numberFormat.toString());
            } else {
                numberFormatOptions = <NumberFormatOptions>numberFormat;
            }

            const numberFormattingLogic = new NumberFormatLogic(numberFormatOptions);

            value = numberFormattingLogic.execute(value);
        }

        return value;
    }
}
