import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ChangePassword } from '@app/shared/models';

// TODO: Replace bodies with proper commands/ queries.
@Injectable({ providedIn: 'root' })
export class AccountService {
    private readonly basePath = 'api/accounts';

    constructor(private readonly httpClient: HttpClient) {}

    public changePassword(changePassword: ChangePassword): Observable<unknown> {
        return this.httpClient.post(`${this.basePath}/change-password`, changePassword);
    }

    public resetPassword(userName: string, newPassword: string): Observable<boolean> {
        const body = { userName, newPassword };

        return this.httpClient.post<boolean>(`${this.basePath}/reset-password`, body);
    }

    public activatePassword(token: string): Observable<boolean> {
        const body = { token };

        return this.httpClient.post<boolean>(`${this.basePath}/activate-password`, body);
    }
}
