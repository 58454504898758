<app-environment />

<div class="layout-region">
    <div class="region-select-container">
        <h1>Choose your region</h1>

        <div class="map">
            <svg viewBox="0 0 491.49 257.8">
                <g class="australia">
                    <path
                        d="M481.63,187.97c-0.32-0.17-0.31-0.9-0.51-1.35c-1.23-2.73,1.36-6.76-2.88-8.47c-0.08-0.03-0.12-0.43-0.03-0.58
		c1.26-2.28-0.58-3.74-1.51-6.12c-0.58,0.95-0.85,1.36-1.09,1.79c-1.6,2.83-1.41,6.18-2.57,9.13c-1.17,2.98-1.71,3.1-4.32,1.2
		c-1.23-0.9-2.46-1.79-3.66-2.73c-0.66-0.52-1.41-1.68-0.3-1.83c2.75-0.36,0.64-2.89,2.02-3.84c0.39-0.27-0.57-1.42-0.92-1.12
		c-2.48,2.14-5.36-3.66-7.63,0.44c-2.7-0.26-3.31,2.04-4.38,3.67c-0.63,0.95-1.38,2.03-2.3,1.03c-2.99-3.24-4.74-0.7-6.33,1.32
		c-2.15,2.72-5.63,4.18-6.97,7.64c-0.2,0.52-1.11,0.89-1.76,1.09c-1.95,0.59-4.04,0.82-5.89,1.63c-2.1,0.93-4.9,1.87-5.77,3.62
		c-1.47,2.96-2.78,6.27-1.93,10.01c0.81,3.56,1.14,7.3-0.54,10.82c-0.38,0.79-2.14,1.04-1.07,2.38c1.03,1.29,2.54,2.06,4.09,1.27
		c2.89-1.49,5.83-2.39,9.11-2.04c0.43,0.05,0.99-0.27,1.34-0.59c3.44-3.13,7.93-3.05,12.08-3.71c2.51-0.4,5.23,0.65,5.41,3.62
		c0.13,2.2,1.22,2.59,2.76,3.1c1.64,0.55,3.38,0.95,2.45,3.52c-0.51,1.4,0.69,2.39,1.98,2.59c3.29,0.51,6.66,1.83,9.85-0.42
		c1.46-1.03,3.79-0.08,4.83-2.18c0.92-1.86,2.46-3.25,3.85-4.76c2.96-3.22,6.46-5.93,8.38-10.09c1.26-2.74,1.27-5.68,2.06-8.47
		C485.48,196.66,486.28,190.48,481.63,187.97z M456.31,218.08c-0.31,0.05-0.58-0.19-0.34-0.53c0.15-0.21,0.46-0.33,0.72-0.45
		c0.02-0.01,0.18,0.27,0.32,0.47C456.76,217.76,456.56,218.04,456.31,218.08z" />
                    <path
                        d="M460.38,157.32c-0.05,0.85,1,1.69,2.72,1.93c2.57,0.36,5.59,0.96,7.17,3.98c1,1.92-2.44,2.85-0.77,4.2
		c1.03,0.84,3.21-1.14,4.32,0.98c0.09,0.17,0.32,0.39,0.46,0.37c1.53-0.19,3.21,1.97,4.65-0.52c1.52-2.63,3.31-2.69,4.7,0
		c1.67,3.23,4.4,3.2,7.86,3.57c-1.52-1.71-2.72-2.74-3.87-3.83c-1.32-1.25-2.44-2.96-1.25-5.02c-2.81-1.36-3.91-4.81-7.25-5.63
		c-2.37-0.58-4.7-1.51-6.88-2.6c-2.13-1.07-3.7-0.57-5.24,0.95c-0.54,0.52-0.83,1.74-1.81,1.23c-0.79-0.41-1.13-1.43-1.18-2.37
		c-0.18-3.07-2.23-2.96-4.39-2.55c-0.75,0.14-1.46,0.53-2.64,0.97C458.64,154.39,460.53,154.93,460.38,157.32z" />
                    <path
                        d="M465.22,230.2c-3.83-1.69-3.26,1.54-3.76,3.34c-0.48,1.7,0.51,2.29,2.06,1.73c2.33-0.84,3.8-2.53,4.7-5.02
		C466.97,229.76,465.93,230.52,465.22,230.2z" />
                </g>
                <g class="america" (click)="setRegion('us')">
                    <title>America</title>
                    <g class="southamerica">
                        <path
                            d="M172.85,161.1c-4.24-1.05-6.83-5.59-11.78-5.13c-2.82,0.26-4.48-3.19-7.09-3.28
		c-4.98-0.17-5.91-3.96-7.21-7.32c-1.56-4.04-3.75-5.97-8.15-5.89c-1.99,0.03-3.57-0.77-4.5-2.16c-1.57-2.35-4.34-3.41-5.77-5.67
		c-1.07-1.7-2.87-2.03-3.68-1.29c-2.38,2.16-5.05,0.33-6.39-0.29c-2.33-1.09-3.95-2.93-6.27-0.4c-0.72-1.17,1.53-1.57,0.29-2.44
		c-0.8-0.56-1.46,0.24-2.07,0.82c-0.76,0.73-1.83,0.81-2.8,1.12c-1.25,0.41-2.32,0.9-2.88,2.41c-0.82,2.2-2.19,3.77-4.57,1.32
		c-0.44-0.45-1.13-0.44-1.75-0.23c0.01,0.34,0.03,0.69,0.04,1.03c1.95,1.19,2.36,3.42,2.63,5.24c0.38,2.55,0.87,5.24-1.78,7.59
		c-1.79,1.59-3.68,3.87-3.99,6.48c-0.29,2.46,0.9,5.01-0.64,7.48c-0.33,0.53,0.09,2.05,0.66,2.52c3.91,3.22,5.07,8.1,7.5,12.19
		c1.8,3.02,3.17,6.21,6.67,7.6c4.97,1.97,7.06,5.75,6.65,10.87c-0.8,9.96,2.85,19.77,0.95,29.77c-0.11,0.58,0.52,1.3,0.79,1.97
		c1.39,3.44,1.45,7.18,2.73,10.72c1.16,3.2,0.5,3.75,2.01,5.92c0.19,0.27,0.23,0.9,0.05,1.1c-1.01,1.16-0.23,2.13,0.26,3.09
		c2.99,5.82,7.62,9.66,14,11.25c2.18,0.55,4.3,0.5,6.15-1.31c-0.82-0.3-1.48-0.55-2.15-0.78c-3.01-1.04-5.02-3.4-6.28-6
		c-0.74-1.52,0.66-3.55,1.7-5.2c0.71-1.13,0.21-2.07-1.17-2.56c-1.9-0.68-4.05-1.46-0.76-3.38c0.59-0.34,0.52-1,0.69-1.57
		c0.69-2.28,1.03-4.36-1.62-5.65c0,0,0.07-0.16,0.18-0.42c1.09-0.35,3.61,1.42,3.38-1.14c-0.24-2.64,1.04-2.84,2.85-3.04
		c2.28-0.25,3.91-1.3,4.23-3.7c0.21-1.56-1.81-2.15-1.82-4.13c5.11,1.83,6.08-2.12,7.81-5.37c0.44-0.82,0.6-1.85,1.29-2.75
		c1.43-1.86,3.76-4.2,2.69-7.11c-0.96-2.6,0.53-4.08,2.19-5.1c1.67-1.02,3.41-2.27,5.57-2.32c2-0.04,3-1.26,3.74-3.02
		c1.86-4.39,2.93-8.89,2.45-13.69c-0.13-1.25,0.3-2.24,0.99-3.38c1.45-2.36,3.13-4.54,4.63-6.85
		C174.88,166.87,174.55,161.52,172.85,161.1z" />
                        <path
                            d="M146.86,250.27c-0.19,0.18-0.09,1.05-0.03,1.06c1.45,0.24,3.03,1.08,4.6-1.29
		C149.61,250.03,148.24,248.94,146.86,250.27z" />
                    </g>
                    <g class="northamerica">
                        <path
                            d="M227.47,2.12c-5.46,1.39-10.25-1.6-15.42-1.96c-13.25-0.93-26.35,2.43-39.69,0.81
		c-10.11-1.23-20.31,0.3-30.38,1.82c-1.73,0.26-4.61,0.37-4.71,2.1c-0.15,2.59,2.72,2,4.53,2.29c0.08,0.01,0.15,0.1,0.47,0.34
		c-2.39,1.42-4.87,1.55-7.36,1.25c-1.7-0.21-3.6-1.52-4.31,1.36c-0.05,0.19-1.03,0.39-1.07,0.31c-1.52-2.8-3.67-1.25-5.69-0.93
		c-0.87,0.14-2.27-1.18-2.58,0.29c-0.3,1.45,1.66,1.03,2.46,1.7c0.67,0.55,1.57,0.33,2.38,0.2c0.73-0.12,1.77-0.81,1.77,0.65
		c0,1.02-1.11,1.03-1.82,1.33c-0.82,0.34-1.13-1.37-2.1-0.58c-0.82,0.06-1.64,0.12-2.47,0.17c-0.54-0.1-1.02-0.01-1.38,0.44
		c-0.71,0.17-0.6,1.35-1.53,1.24c-0.48-0.59-0.97-0.79-1.47-0.04c0,0,0,0,0,0c-0.6,0.33-0.46,0.54,0.1,0.7
		c0.86,0.95,1.56,1.98,3.13,2.01c0.76,0.01,0.03,0.75-0.03,1.16c-0.42,0.41-0.84,0.83-1.26,1.24c-0.34-0.05-0.69-0.11-1.03-0.16
		c-0.33-0.45-0.66-0.45-0.99,0.01c-0.56,0.26-1.12,0.52-1.68,0.79c-1.24-0.01-0.8,1.17-1.23,1.74c0,0-0.01,0-0.01,0
		c-0.3,0.18-0.6,0.36-0.89,0.54c-1.97-0.31-4.22,0.77-5.87-1.17l0,0c0,0,0,0,0,0c0.78-0.61,1.68,0,2.5-0.2
		c0.7,0.35,1.28,0.2,1.74-0.44c0,0,0.04-0.02,0.04-0.02c0.66-0.38,1.32-0.76,1.98-1.14c0.55-0.33,0.68-0.89,0.11-1.11
		c-2.27-0.9-1.02-2.11-0.27-3.13c0.45-0.62,1.23-0.99,1.86-1.47c0.4-0.04,0.55-0.36,0.35-0.6c-0.58-0.67-1.23-0.48-1.83,0.02
		c-0.18-0.04-0.3,0.02-0.36,0.19c-0.7,0.14-1.48-0.14-2.08,0.43c-0.86,0.04-1.31,0.57-1.58,1.31c-0.2-0.02-0.39-0.05-0.59-0.07
		c-0.13-0.21-0.27-0.42-0.4-0.63c-0.53-0.69-1-0.47-1.46,0.09c-0.39-0.01-0.78-0.02-1.18-0.03c-0.84-0.34-1.69-0.68-2.53-1.01
		c-0.82-1.01-1.87-1.28-3.16-1.49c-4.4-0.7-8.12,0.72-11.62,3.16c-0.4,0-0.9,0.03-0.82,0.54c0.05,0.3,0.5,0.53,0.77,0.79
		c0.66,1.37,1.67,0.38,2.53,0.38c0.68,0.1,1.32,0.04,1.83-0.5c0.6-0.2,1.21-0.39,1.81-0.59c0.04,0.41,0.08,0.82,0.12,1.23
		c-0.92,0.31-0.37,1.26-0.77,1.79c-0.76,0.16-0.58,0.71-0.52,1.22c0.98-0.26,1.9-0.05,2.8,0.26c0.3,0.1,0.6,0.22,0.89,0.34
		c1.19,0.46,2.38,0.91,3.71,0.52c0.17-0.18,0.36-0.32,0.55-0.38c-0.19,0.07-0.38,0.2-0.55,0.38c-1.32,0.4-2.51-0.05-3.71-0.52
		c-0.3-0.12-0.6-0.23-0.89-0.34c-0.9-0.31-1.81-0.52-2.8-0.26c-0.11,0.14-0.15,0.3-0.14,0.48c-1.87,0.1-3.54-0.74-5.32-1.1
		c-0.51-0.45-1.03-0.43-1.55-0.02c-0.35,0.05-0.7,0.09-1.05,0.14c-0.17-0.1-0.35-0.16-0.55-0.16c-0.5-1.06-1.02-0.44-1.54,0.01
		c-0.16-0.05-0.28-0.01-0.38,0.13c-0.85,0.03-0.64-0.99-1.23-1.23c-0.97-0.53-1.54,0.74-2.43,0.56c-0.47-0.67-0.97-0.53-1.49-0.07
		c-0.55,0.08-1.1,0.16-1.65,0.24c-0.31,0.13-0.61,0.25-0.92,0.38c-0.59-0.19-1.11-0.05-1.58,0.31c-0.59-0.04-1.17-0.08-1.76-0.11
		c-0.42,0.02-0.84,0.05-1.25,0.07c-1.01-0.06-1.8,0.48-2.6,1c-0.29-0.01-0.58-0.01-0.88-0.02c-0.29-0.16-0.57-0.31-0.86-0.47
		c-2.26-1.13-4.65-1.64-7.18-1.38c-0.61-0.44-1.3-0.49-2.02-0.41c-1.16-0.07-2.31-0.14-3.47-0.21c-0.28-0.13-0.56-0.26-0.84-0.38
		c-0.69-0.48-1.45-0.41-2.21-0.25c-0.3-0.15-0.6-0.3-0.89-0.45c-0.84-0.66-1.66-0.38-2.49,0.02c-0.56-0.08-1.06,0.03-1.47,0.44
		c-0.95,0.07-1.9,0.14-2.85,0.21c-0.53-0.09-0.99,0.04-1.37,0.43c-0.77,0.07-1.55,0.15-2.32,0.22C39.28,19,39,19.13,38.72,19.26
		c-1.51,0.03-2.71,0.97-4.08,1.42c-0.33,0.14-0.65,0.27-0.98,0.41c-1.38-0.09-2.72,0-3.93,0.78c-0.16,0.05-0.33,0.1-0.49,0.14
		c0.12,0.16,0.24,0.32,0.36,0.48c-0.01,0-0.01,0-0.01-0.01c0.15,0.57,0.3,1.13,0.45,1.7c-0.1,0.48-0.19,0.96-0.29,1.44
		c-0.22-0.01-0.4,0.07-0.54,0.24c-0.28-0.11-0.56-0.21-0.85-0.32c-0.78-1.26-1.65-0.56-2.5-0.1c-0.57,0.07-1.13,0.14-1.7,0.21
		c-0.31,0.13-0.62,0.26-0.93,0.38c-0.51,0.06-1.02,0.12-1.53,0.19c-0.35-0.06-0.63,0.01-0.75,0.4c-0.52,0.32-0.47,0.83-0.43,1.34
		c0.75,1.3,1.96,0.5,2.96,0.65c0.48,0.4,0.97,0.59,1.47,0.03c1.11,0,0.84,0.55,0.44,1.14c-0.99,0.35-2.18-0.23-3.05,0.65
		c-0.22,0.05-0.45,0.11-0.67,0.16c-0.87,0.03-1.8-0.33-2.56,0.4c-2.38,1.11-5.31,1.26-6.68,4.1c-0.68,1.41-0.4,1.84,1.1,1.55
		c0.25,0.18,0.5,0.36,0.74,0.54c-0.19,0.21-0.38,0.42-0.57,0.63c-0.41,0.36-1.67,0.7-0.19,1.19c0.79,0.26,1.76,0.01,2.64-0.01
		c0.21,0,0.43,0,0.64,0c0.2,0.04,0.4,0.05,0.61,0.04c-0.09,0.77-0.84,0.95-1.26,1.43c-0.2,0.14-0.4,0.27-0.61,0.41c0,0,0,0,0,0h0
		l0,0l0,0c-2.29,0.77-4.64,1.36-6.74,2.6C5.59,43.55,2.87,45.1,0,46.23c2.75,0.23,5.25-1.54,8.04-0.58c0.6,0.21,0.62-0.64,0.74-1.11
		c2.81-0.72,5.54-1.68,8.14-2.99c0.68-0.16,1.79-0.65,1.97-0.42c1.78,2.3,3.16,0.32,4.65-0.32c1.95-0.4,1.51-2.55,2.63-3.57
		c0.56-0.02,1.12-0.05,1.68-0.07c0,0.02,0,0.03,0,0.04l0,0v0l0.05-0.05c0,0,0,0,0,0l0,0c0.72,1.13,1.49,0.76,2.26,0.3
		c0.1-0.02,0.21-0.08,0.35-0.21c1.59-0.24,3.17-0.49,4.76-0.73c0.37,0.03,0.63-0.1,0.69-0.49c0.77-0.49,1.6-0.01,2.39-0.15
		c0.75,0.52,1.51,0.98,2.48,0.62c0.61,0,1.21,0.01,1.82,0.01l0.28,0.1l0.3-0.02c0.33,0.41,0.75,0.56,1.27,0.5
		c0.64,0.85,1.28,1.7,1.91,2.55c0,0.21,0,0.42,0,0.63c-0.11,0.15-0.14,0.32-0.09,0.5c-1.39,4.01-0.91,5,2.49,5.11
		c-0.13,0.94-1.67,1.15-1.23,2.39c0.04,0.73-0.11,1.52,0.55,2.09c-0.01,0.22-0.01,0.45-0.02,0.67c-0.56,0.4-0.59,0.94-0.37,1.53
		c-0.12,0.82-1.11,0.58-1.42,1.16c-0.62,0.28-0.44,0.59-0.03,0.91c2.48,2.41,1.36,4.81-0.01,7.21c-2.05,3.59-5.47,6.3-6.7,10.41
		c-0.15,0.21-0.3,0.42-0.45,0.64c-0.05,0.62-0.1,1.24-0.16,1.85c-0.67,0.64-0.62,1.3-0.03,1.95c0.06,1.01,0.11,2.01,0.17,3.02
		c-0.21,0.97-0.34,1.92,0.4,2.75c-0.16,1.06-0.07,1.87,1.21,2.31c1.12,0.38,2.05,1.2,2.63,2.31c0.02,1.36-0.37,2.77,0.49,4.03
		c-0.37,1.62,0.07,2.95,1.39,3.98c-0.07,0.6-0.14,1.2-0.21,1.79c-0.18-0.02-0.35,0.03-0.49,0.14c-1.07,0.37-0.67,0.8-0.05,1.23
		c0.91,0.81,1.83,1.62,2.74,2.43c-0.05,0.59-0.11,1.17-0.16,1.76c-0.4,2.09,2.18,2.14,2.55,3.7c0.39,0.78,0.78,0.84,1.19,0.01
		c0.69-1.85-1.32-2.78-1.48-4.34c0.18-0.72,0.17-1.39-0.43-1.93c0.06-0.68-0.09-1.29-0.67-1.73c-0.18-0.28-0.36-0.57-0.54-0.86
		c0.08-0.54,0.16-1.09,0.24-1.63l-0.14,0.02c0.05-0.01,0.14-0.02,0.14-0.02c0.36-0.02,0.72-0.04,1.08-0.07
		c1.51,1.31,2.24,3.19,3.44,4.73c-0.64,0.51-0.68,0.98,0.1,1.39c1.82,2.63,5.03,4.83,4.14,8.46c-0.6,2.45,0.2,3.73,1.93,4.82
		c3.33,2.11,6.63,4.29,10.51,5.34c0.85,0.8,1.69,0.81,2.54-0.01c0.21-0.05,0.41-0.1,0.62-0.15c0.81,0.05,1.62,0.09,2.43,0.14
		c1.88,2.71,4.38,4.51,7.66,5.01c1.82,0.28,2.92,1.39,3.76,2.79c1.21,2.03,2,4.07,4.16,5.72c1.37,1.05,3.03,1.86,4.42,2.84
		c3.16,2.23,1.49-2.68,3.39-2.75c0.06,0,0.12-0.15,0.18-0.24c-0.01-0.34-0.03-0.69-0.04-1.03c-3.84,1.2-5.81,0.53-6.74-2.38
		c-0.64-1.98,0.44-3.81,0.77-5.68c0.58-3.29-0.55-4.87-3.91-4.54c-1.42,0.14-2.79,0.37-4.35-0.02c1.19-2.86,2.13-5.74,3.74-8.33
		c0.56-0.9,1.09-1.85-0.33-2.43c-1.75-0.71-5.28,0.52-5.75,2.27c-0.98,3.6-4.05,3.58-6.63,3.78c-2.8,0.21-3.36-2.45-4.12-4.57
		c-0.9-2.49-0.4-4.83,0.58-7.15c0.47-1.13,1.03-2.25,1.3-3.43c0.9-3.92,6.24-7.27,10.14-6.03c1.31,0.42,2.56,0.45,3.85,0.56
		c0,0,0,0,0,0c0.21,0.23,0.43,0.3,0.64,0.02c0.02-0.03-0.2-0.23-0.31-0.34c0.09-1.5,1.11-1.62,2.34-1.81c2-0.32,3.72,1.14,5.45,0.96
		c2.52-0.26,2.73,1.14,2.37,2.66c-0.46,1.92,0.1,3.42,1.04,4.92c0.35,0.56,0.4,1.53,1.37,1.43c1.03-0.1,1.2-1.1,1.39-1.81
		c0.54-2.02,0.37-4.11-0.02-6.11c-0.55-2.81,0.44-4.73,2.7-6.3c2.18-1.52,4.37-2.98,6.75-4.2c1.16-0.59,2.56-1.17,2.81-2.86
		c0.64-4.38,4.01-6.56,7.22-8.91c1.77-1.29,5.52-0.24,5.08-4.25c-0.03-0.3,0.64-0.82,1.08-1c2.12-0.87,4.09-2.25,6.52-2.18
		c0,0.91-0.4,2.7,1.13,1.82c2.87-1.66,6.18-2.1,9-3.79c0.96-0.57,0.96-1.34,0.56-2.04c-0.5-0.86-1-0.04-1.44,0.26
		c-1.68,1.14-3.13-0.09-4.3-0.8c-1.12-0.67,0.26-1.66,0.73-2.32c1.16-1.62,0.28-1.94-1.11-2.04c-0.79-0.06-1.59,0.46-2.45-0.07
		c2.37-2.45,5.42-1.61,8.08-1.39c2.97,0.24,5.27-1.49,8.24-2.29c-1.49,2.93-4.38,4.02-5.49,6.73c2.75,1.13,5.94-0.67,8.2,1.93
		c0.46,0.53,1.58-0.62,1.49-1.06c-0.35-1.67,1.47-4.26-1.92-4.8c-0.7-0.11-1.22-0.53-0.44-1.85c1.04-1.75,3.36-4.37,0.49-6.27
		c-3.6-2.38-3.51-6.01-4.25-9.46c-0.13-0.63-0.01-1.43-0.71-1.88c-1.44,0.99-2.85,1.99-4.3,2.92c-0.69,0.45-1.46,0.77-2.25,0.07
		c-0.73-0.65-0.77-1.41-0.4-2.23c0.24-0.54,0.71-1.02,0.33-1.69c-2.01-3.57-8.86-3.84-10.81-0.33c-0.83,1.5-1.41,3.07-3.08,3.87
		c-0.37,0.17-0.62,0.53-0.3,0.98c2.05,2.9-0.11,4.39-2.23,4.99c-2.94,0.83-4.32,2.48-4.75,5.33c-0.19,1.21-1.13,1.92-2.3,2.21
		c-1.36,0.33-1.21-1.1-1.84-1.63c0.05-0.19,0.11-0.37,0.18-0.56c0.44-0.09,1.21-0.12,1.24-0.29c0.1-0.52-0.43-0.73-0.88-0.89
		c0.29-0.73,0.55-1.47,0.87-2.19c0.44-1,0.09-1.29-0.9-1.07c-0.43-0.5-1-0.47-1.57-0.4c-2.67-0.16-3.87-3.29-6.62-3.27
		c-0.49-0.44-0.96-0.27-1.42,0.06c-0.53-0.22-0.47-0.73-0.58-1.15c0.45-0.61,1.04-1.22-0.03-1.8c-1.06-1.86,0.73-2.4,1.63-3.16
		c3.15-2.65,7.23-3.62,10.71-5.69c0.69,0.12,1.3-0.01,1.81-0.52c0.22-0.05,0.44-0.09,0.66-0.14c0.66,0.39,0.64,1.62,1.78,1.44
		c0.5,0.88,1.04,0.64,1.59,0.04c1.19,0.14,2.07-1.19,3.34-0.69c0.85,0.95,1.71,0.9,2.57,0c-0.25-1.7-1.95-2.07-2.87-3.15
		c0.55-0.19,1.33,0.33,1.64-0.55c0,0,0.11-0.13,0.11-0.13l-0.07-0.16c0.2-0.02,0.38-0.1,0.53-0.24c1.37-0.11,2.61-0.54,3.65-1.46
		c0.15-0.16,0.29-0.31,0.44-0.47c1.24-1.41,1.56-3.9,4.25-3.63c0.88,0.55-0.49,1.97,0.85,2.34c0,0.02,0,0.03,0.01,0.05
		c0.02,0.01,0.04,0.01,0.06,0.01c0.28,1.1,0.41,2.37,2.14,1.55c1.12-0.53,2.27-1,3.4-1.49c1.36,1.38-0.12,1.61-0.87,2
		c-1.12,0.59-2.41,0.92-2.87,2.32c-0.21-0.07-0.4-0.04-0.58,0.09c-0.66,0.05-1.32,0.11-1.98,0.16c-0.84-0.41-1.65-0.47-2.36,0.28
		c-0.22,0.13-0.43,0.26-0.65,0.39c-0.85,0.49-0.89,0.93,0.08,1.31c0.36,0.11,0.67,0.09,0.82-0.32c0.65,0.81,1.24,0.26,1.83-0.08
		c1.07,0.16,2.19,0.17,2.93,1.16c0.13,0.23,0.26,0.46,0.39,0.69c0.09,0.19,0.17,0.37,0.26,0.56c0.15,0.22,0.3,0.44,0.45,0.65
		c0,0-0.01,0-0.01,0c0.49,0.74,1.25,0.22,1.86,0.4c1.47,0.45,3.08,2.74,4.29-0.36c0.05-0.14,0.76-0.03,1.17-0.03
		c0.39,0.63,0.75,0.92,1.03-0.07c2.84-1.91-0.06-3.57-0.23-5.35c0.21-0.26,0.41-0.52,0.62-0.78c1.08,0.15,1.23,1.31,1.97,1.83
		c0.57,0.72,1.17,0.86,1.79,0.05c1.21-0.64,2.62-0.94,3.58-2.03c0.48-0.1,0.64-0.27,0.15-0.6c0,0.01-0.01,0.03-0.01,0.04
		c-0.25-0.19-0.49-0.38-0.74-0.57c-0.95-1.72-3.44-1.26-4.33-3.06c1.99-0.51,1.96-1.81,0.58-2.66c-1.08-0.66-2.16-1.73-3.65-1.6
		c-0.94,0-1.35-0.84-2.03-1.26c-0.39-0.52-0.31-1.35-1.06-1.64c-0.55-0.09-1.11-0.18-1.66-0.27c-0.71-0.44-1.42-0.57-2.11,0.03
		c-0.18-0.07-0.36-0.06-0.53,0.04c-0.86-0.6-1.74-0.78-2.63-0.07c-0.55,0.02-1.1,0.03-1.65,0.05c-1.27-1.06-2.54-0.77-3.82-0.03
		c-0.72,0.07-1.44,0.15-2.16,0.22l-0.06-0.05c-0.03,0.5-0.38,0.51-0.74,0.51c-0.05,0.15-0.13,0.28-0.23,0.39
		c-0.11,0.13-0.23,0.24-0.37,0.34c0.14-0.1,0.27-0.21,0.37-0.34c0.1-0.11,0.17-0.25,0.23-0.39l0,0l0,0c0.36,0,0.71-0.01,0.74-0.52
		c0,0,0.06,0.05,0.06,0.05c-0.25-1.43,0.83-1.29,1.68-1.42c3.05,0,6.1,0.08,9.15-0.02c1.42-0.05,3.14,0.19,3.72-1.77
		c0.03-0.2,0.05-0.41,0.08-0.61c0.64,0.02,1.19,0.6,1.95-0.02c2.75-2.25,6.31-2.68,9.48-3.98c0.12,0.13,0.23,0.26,0.35,0.38
		c-0.63,2.67,0.89,3.55,3.15,3.65c1.84,0.08,3.69-0.1,5.53-0.05c3.76,0.11,4.71,1.43,3.52,5.09c-0.46,1.42,0.56,3.21-1.26,4.35
		c-0.51,0.32,0.2,0.96,0.92,1.08c0.82,0.14,1.72-0.45,2.73,0.42c-3.27,0.29-5.63,2.2-5.29,4.96c0.47,3.83-0.16,8.58,5.66,10.05
		c2.22,0.56,3.56,0.72,4.56-1.09c1.57-2.85,4.69-4.21,6.29-7.01c0.48-0.84,1.61-1.31,2.71-1.18c2.29,0.26,4.35-0.62,6.02-1.86
		c2.17-1.61,4.51-2.21,7.06-2.48c2.56-0.28,5.04-0.96,7.37-2.02c1.83-0.83,4.21-1.36,3.65-4.34c-0.08-0.4,0.28-1.1,0.65-1.34
		c1.98-1.28,5.13-1.45,4.59-5.02c3.06-0.91,0.55-2.02,0.15-2.97C220.57,4.3,224.28,3.93,227.47,2.12z M28.72,36.32
		c-0.07,0.06-0.14,0.14-0.22,0.24c-0.08,0.03-0.17,0.06-0.25,0.08c-0.08,0.03-0.17,0.05-0.24,0.1c0.07-0.04,0.15-0.07,0.24-0.1
		c0.08-0.03,0.17-0.05,0.25-0.08C28.58,36.45,28.66,36.38,28.72,36.32c0.14-0.12,0.25-0.18,0.35-0.18
		C28.97,36.14,28.86,36.19,28.72,36.32z M45.94,92.86c0.07,0.11,0.16,0.23,0.28,0.34c0,0,0,0,0,0
		C46.1,93.09,46.01,92.97,45.94,92.86z M45.76,92.3c0,0.13,0.03,0.26,0.09,0.39C45.79,92.56,45.76,92.43,45.76,92.3z M106.38,21.16
		c0.3,0.2,0.61,0.39,0.91,0.59c0,0,0,0,0,0C106.99,21.55,106.68,21.35,106.38,21.16z M142.24,21.51c0.06-0.16,0.11-0.33,0.16-0.5
		c0.09-0.35,0.17-0.72,0.27-1.07h0c-0.1,0.35-0.17,0.72-0.27,1.07C142.35,21.18,142.3,21.35,142.24,21.51z M130.16,17.48
		c0.25,0.12,0.49,0.24,0.74,0.37C130.65,17.73,130.41,17.6,130.16,17.48c-0.04-0.25-0.04-0.46-0.01-0.64
		C130.12,17.03,130.12,17.24,130.16,17.48z M132.43,20.72c0.09-0.08,0.17-0.15,0.24-0.23C132.59,20.56,132.51,20.64,132.43,20.72z
		 M132.76,20.38c0.09-0.11,0.14-0.23,0.14-0.34C132.9,20.15,132.85,20.26,132.76,20.38z M130.22,16.58c0.16-0.4,0.5-0.65,0.88-0.88
		C130.72,15.93,130.38,16.18,130.22,16.58z" />
                        <path
                            d="M119.12,10.75c-1.69-1.91-3.21-2.51-4.94-0.62c-0.77,0.85-1.47-0.13-2.1-0.12c-1.77,0.04-1.28-2.6-3.2-2.29
		c-2.98,0.48-5.87,1.21-8.96,2.11C106.34,13.06,112.57,13.14,119.12,10.75z" />
                        <path
                            d="M91.83,108.87c0.79,0.47,1.36-0.18,1.97-0.42c1.72-0.67,7.14,0.22,7.96,1.82c1.83,3.58,4.49,2.67,7.65,1.87
		C100.09,105.28,94.4,104.29,91.83,108.87z" />
                        <path
                            d="M116.34,113.18c-0.96-0.69-2.05-0.89-3.2-0.75c-0.84,0.11-2.58-0.32-2.38,0.29c0.84,2.66-1.28,1.96-2.46,2.68
		c2.56,0.72,5.07,1.81,7.64,0.41c0.82-0.45,2.26,1.07,2.47-0.64C118.56,114,117.14,113.75,116.34,113.18z" />
                        <path
                            d="M130.99,6.89c0.2-0.45,0-0.86-0.38-1c-1.87-0.68-3.83-1.69-5.41,0.84c1.52,0.33,2.89,0.65,4.27,0.9
		C130.09,7.74,130.69,7.56,130.99,6.89z" />
                        <path d="M120.74,6.28c-2.85-0.42-4.74,0.07-6.55,1.69C116.7,8.77,118.4,7.66,120.74,6.28z" />
                    </g>
                </g>
                <g class="africa">
                    <path
                        d="M307.51,126.48c0,0.01,0,0.02,0,0.03c0.01,0.01,0.02,0.01,0.03,0.02
		C307.54,126.52,307.51,126.48,307.51,126.48z" />
                    <path
                        d="M321.05,129.3c-0.01-1.03-0.31-1.58-1.48-1.03c-2.43,1.13-5.12,1.29-7.68,1.9c-3.3,0.78-4.44-0.23-4.37-3.67
		c-4.5-3.14-7.28-7.74-10.22-12.19c-0.06-0.08-0.13-0.18-0.14-0.27c-0.7-6.55-5.58-11.31-7.47-17.35c-1.7-1.54-1.98-3.56-1.98-5.69
		c-0.79-1.08-2.01-1.26-3.03-0.81c-4.49,1.94-8.3-0.5-12.11-1.99c-3.39-1.33-4.72-1.03-5.36,2.55c-0.17,0.92-0.79,1.89-1.52,1.39
		c-1.91-1.32-4.73-0.71-6.12-3.05c-0.75-1.27-2.13-1.56-3.47-1.58c-1.64-0.03-2.8-1.04-4.14-1.7c-0.71-0.35-1.13-0.78-0.4-1.25
		c2.2-1.42,0.63-3.31,0.25-4.58c-0.52-1.72-2.37-0.6-3.74-0.53c-5.57,0.3-11.1,0.2-16.26,3.17c-1.34,0.77-4.24,1.24-5.75-1.14
		l-0.3,0.04c0,0-0.3,0-0.3,0c-1.07,2.26-2.46,4.14-4.77,5.4c-1.58,0.86-2.38,2.61-2.4,4.41c-0.04,2.56-1.55,4.16-3.56,4.9
		c-2.38,0.88-3.65,2.82-4.62,4.62c-1.36,2.51-3.08,4.8-4.27,7.4c-0.35,0.76-0.85,1.63-0.41,2.33c1.95,3.16,1.51,6.55,0.2,9.52
		c-1.32,3-0.33,5.48,0.38,8.16c0.13,0.51-0.08,1.08,0.9,1.26c1.72,0.31,3.11,1.87,3.41,3.2c0.82,3.65,3.92,4.99,6.16,7.23
		c2.15,2.15,4.31,3.35,7.34,1.66c0.82-0.46,2.09-0.57,3-0.3c3.24,0.96,5.95-0.43,8.73-1.62c0.66-0.28,1.34-0.56,2.03-0.68
		c2.33-0.39,4.56-0.54,5.41,2.46c0.34,1.19,1.27,1.32,2.45,1.25c4.67-0.29,5.36,0.74,4.54,5.52c-0.48,2.83-1.96,5.77,0.95,8.44
		c4.68,4.3,5.03,10.4,6.14,16.13c0.2,1.01-0.34,1.88-0.86,2.82c-2.29,4.13-3.63,8.33-1.01,13.01c1.68,3,3.13,6.11,3.28,9.78
		c0.13,3,1.17,5.86,2.88,8.6c1.44,2.31,3.23,4.82,2.57,8.15c-0.4,2.01,1.74,3.26,3.92,2.86c1.7-0.31,3.32-1.08,5.1-0.93
		c3.4,0.29,6.38-0.85,8.55-3.35c3.2-3.68,7.48-6.81,7.45-12.48c0-0.68,0.73-0.93,1.24-1.26c3.38-2.16,4.1-3.88,3.06-7.75
		c-0.3-1.11-0.87-2.15,0.18-3.12c1.57-1.45,2.99-3.16,4.77-4.26c4.89-3.02,6.45-9.07,4.04-14.47c-1.3-2.92-2.4-6.44-1.31-9.51
		c1.86-5.26,5.44-9.67,9.81-13.05c5.72-4.43,7.89-10.76,10.33-17.04C321.22,130.38,321.06,129.81,321.05,129.3z" />
                    <path
                        d="M311.42,181.54c-3,0.95-3.6,3.05-3.04,5.73c0.31,1.5,0.28,2.88-0.65,4.1c-1.22,1.6-1.33,3.23-0.89,5.2
		c0.39,1.76,0.33,3.72,2.61,3.87c2.13,0.14,3.57-1,4.23-3.2c1.46-4.9,3.13-9.74,4.82-14.55c1.07-3.05,0.65-5.39-0.72-8.25
		C315.53,177.07,314.71,180.5,311.42,181.54z" />
                </g>
                <g class="asia">
                    <path
                        d="M436.75,140.16c-1.38-0.63-1.9-2.01-2.82-3.04c-4.47,4.88-6.01,8.04-12.77,10.43
		c-0.31,0.11-0.56,0.59-0.71,0.95c-1.12,2.66,2.15,8.83,5.05,9.17c1.67,0.2,3.23-0.05,4.8,1.11c0.57,0.42,2.85,0.19,2.89-2.01
		c0.02-0.97,0.12-2.02,0.74-2.93c1.71-2.48,3.63-4.89,1.72-8.22c-0.66-1.14,0.15-2.25,0.93-3.29
		C436.95,141.83,438.26,140.85,436.75,140.16z" />
                    <path
                        d="M440.19,156.91c0.99,2.05,1.97,3.81,3.68,5.08c0.84-1.09,1.34-2.09,0.36-3.31c-1.82-2.27-2.16-4.4,0.88-6.26
		c-1.12-0.02-2.14-0.12-2.85,0.3c-2,1.17-2.49,0.02-2.83-1.58c1.75-1.3,3.73-0.57,5.59-0.55c1.95,0.02,2.97-0.68,3.02-3.07
		c-1.6,2.45-3.66,1.53-5.55,1.15c-2.18-0.44-3.2,0.53-3.66,2.47c0.09,2.56-3.03,4.77-0.77,7.59c0.73,0.91-1.09,3.26,0.98,3.09
		c1.66-0.13,0.58-2.44,0.93-3.73C440.04,157.81,440.07,157.52,440.19,156.91z" />
                    <path
                        d="M415.66,165.16c4.51,1.38,9.07,2.5,13.82,2.57c4.55,1.86,9.05,0.2,13.57,0.48c0-0.28,0-0.57,0-0.85
		c-4.52-0.28-9.04-0.56-13.56-0.85c-1.65,0.15-0.59-2.28-2.22-2.28c-4.28-0.01-8.46-0.73-12.55-1.99
		C413.95,163.55,413.77,164.59,415.66,165.16z" />
                    <path
                        d="M414.71,162.25c0.01-0.02,0.02-0.04,0.03-0.05l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c-0.01,0-0.02,0-0.04,0
		c-0.01,0.02-0.01,0.03-0.02,0.05C414.69,162.24,414.7,162.24,414.71,162.25z" />
                    <path
                        d="M438.31,123.87c1.66,3.33,1.1,3.61,3.48,1.42c1.09,0.45,1.34,1.35,1.27,2.41c-0.01,0.22-0.01,0.44-0.02,0.66
		c-0.55,0.06-1.33-0.33-1.52,0.54c-0.36,1.62,1.23,2.55,1.57,4.02c0.26,1.13,1.47-0.45,2.33-0.3c0.63,0.11,1.19-1.01,2,0.06
		c-0.79,0.95-1.45,1.76-3.01,1.9c-1.17,0.1-2.46,0.96-2.2,2.69c2.27-0.55,2.2-0.5,3.75,1.55c0.59,0.78,1.53,1.95,2.61,1.18
		c2.41-1.74,1.66-7.25-1.05-8.68c-0.37,0.04-0.73,0.08-1.1,0.11l0,0c0.37-0.04,0.73-0.08,1.1-0.11c0.1-1.93,1.25-4.31-2.04-4.81
		c0,0,0,0,0,0c-0.11-0.04-0.23-0.06-0.35-0.05c-0.06-0.49-0.13-0.97-0.19-1.46c0,0-0.03-0.08-0.03-0.08
		c0.25-1.79-1.39-0.97-2.02-1.24c-1.98-0.84-3.38-1.62-1.98-4.03c0.65-1.11-0.14-2.16-0.32-3.23c-0.24-1.45-1.51-1.31-2.37-1.32
		c-1.12-0.01-0.95,1.15-0.95,1.9C437.27,119.33,437.11,121.45,438.31,123.87z" />
                    <path
                        d="M338.09,5.08c3.25,0.11,6.34,1.61,9.71,1.08c-1.01-1.69-6.9-3.86-8.88-3.33c-0.39,0.1-0.8,0.26-1.1,0.51
		C337.02,4.03,336.95,5.04,338.09,5.08z" />
                    <path
                        d="M370.58,132.13c-0.98,1.84-0.86,3.61-0.53,5.4c0.21,1.13,0.49,2.34,2.08,1.86c1.37-0.41,1.93-1.4,1.46-2.82
		C373.02,134.84,372.24,133.25,370.58,132.13z" />
                    <path
                        d="M444.23,170.65c0.17,0.25,0.33,0.51,0.5,0.76c2.06-1.23,4.12-2.46,6.75-4.02
		C447.66,166.85,445.65,168.22,444.23,170.65z" />
                    <path
                        d="M453.15,152.55c1.52-1.35,1.17-4.15-0.63-5.3c-0.97,1.34-0.5,2.95-0.83,4.42c-0.13,0.56,0,1.04,0.64,1.12
		C452.59,152.82,452.96,152.72,453.15,152.55z" />
                    <path
                        d="M453.34,156.6c-0.51,0.14-1.05,0.39-0.95,0.96c0.12,0.69,0.71,1.04,1.35,0.67c1.27-0.74,2.51,0.05,4.45,0.03
		C456.61,156.21,455.07,156.12,453.34,156.6z" />
                    <path d="M436.47,132.04c1.48-0.74,2.24-1.47,0.85-2.89C436.8,130.06,436.28,130.77,436.47,132.04z" />
                    <path
                        d="M444.6,167.25c0.17,0.02,0.33,0.05,0.5,0.07c0.02-0.09,0.04-0.19,0.06-0.28c-0.17-0.02-0.33-0.05-0.5-0.06
		C444.64,166.98,444.62,167.16,444.6,167.25z" />
                    <path
                        d="M435.85,132.59c-0.29,0.2-0.59,0.4-0.88,0.61c-0.54,0.38-1.41,1.03-0.85,1.47c0.71,0.56,0.92-0.53,1.21-1.06
		c0,0,0,0,0,0C435.65,133.35,436.3,133.26,435.85,132.59z" />
                    <path
                        d="M482.82,28.85c-0.07-0.15-0.14-0.29-0.21-0.44c0.18-2.32-1.19-3.1-3.21-3.63c-2.57-0.68-5.22-0.69-7.81-1.5
		c-6.44-2-12.99-3.7-19.82-3.94c-2.05-0.07-4.03,1.52-6.13,0.2c-0.15-0.09-0.55,0.32-0.83,0.32c-3.04-0.05-6.29,0.89-9.07-0.54
		c-2.6-1.35-5.18-1.82-7.97-1.64c-2.49,0.16-4.99,0.08-7.27-0.81c-3.9-1.53-7.98-1.45-12.01-1.81c-0.83-2.54-2.66-1.83-4.66-1.35
		c2.03,0.48,2.13,1.68,1.58,3.16c-1.02,0.01-2.07-0.11-3.07,0.05c-3.79,0.62-7.23,0.72-10.46-2.24c-1.72-1.58-4.94-1.76-7.02-1.01
		c-4.65,1.67-8.86-0.18-13.2-0.71c-2.57-0.31-3.3-4.26-6.62-3.95c-2.3,0.21-4.62,0.04-6.89-0.7c-2.09-0.68-4.29-1.51-6.04,0.96
		c-0.36,0.51-1.58,0.75-2.3,0.6c-5.83-1.21-10.27,2.9-15.5,3.93c-0.15,0.03-0.4,0.3-0.37,0.34c1.13,2.36-0.72,1.52-1.44,1.18
		c-1.41-0.66-1.72,1.83-2.74,0.97c-2.03-1.71-2.01,0.17-2.52,1.2c-0.92-0.01-1-0.67-1.25-1.39c-0.55-1.51-2.18-1.81-3.43-2.11
		c-1.33-0.32-1.03,1.61-1.63,2.07c-2.05,1.56,0.78,3.19-0.3,4.63c1.62,2.9-0.45,4-2.54,4.78c-2.28,0.85-2.7,1.92-1.54,4.08
		c1.31,2.44,2,5.13,2.04,7.94c0.01,1.05,0.2,2.01,0.93,2.83c1.1,1.24,1.25,2.39-0.59,3.05c-1.27,0.46-1.22,1.19-0.56,2.1
		c0.64,0.89,1.27,2.23,2.61,1.27c2.22-1.6,2.69-1,2.76,1.51c0.05,1.99,2.2,2.92,3.41,4.59c-2.91,1.49-5.65,0.23-8.2-0.42
		c-2.56-0.65-4.85,0.1-7.25,0.31c-1.5,0.13-1.37,1.42-0.94,2.36c0.84,1.81,1.89,3.53,2.86,5.29c0.69,0.16,1.41,0.24,2.06,0.5
		c0.75,0.3,0.93,1.06,0.95,1.78c0.02,0.85-0.64,0.81-1.27,0.91c-2.35,0.37-2.94,1.78-1,3.12c3.52,2.44,3.17,7.02,5.91,9.85
		c0.52,0.54,1.07,2,0.29,2.83c-0.86,0.91-2.18,1.02-3.43,0.67c-4.16-1.15-4.76-2.36-3.46-6.96c-2.45-0.54-4.56-1.77-6.56-3.22
		c-3.2-2.33-7.03-2.77-10.73-3.61c0.53,1.12,1.74,1.37,2.61,2.05c0.66,0.52,1.3,1.1,1.11,2.03c-0.2,0.98-1.11,1.13-1.9,1.27
		c-2.07,0.35-4.14,0.15-5.99-0.77c-2.73-1.36-5.29-1.27-7.86,0.26c-1.19,0.71-2.55,0.57-3.84,0.77c-1.3,0.65-2.64,1.12-4.12,1.08
		c0.5,4.83,1.98,7.77,5.23,7.58c2.83-0.17,5.63,1.77,8.45-0.02c0.84-0.53,1.84-0.39,2.23,1.11c0.99,3.89-1.25,8.69-4.6,9.43
		c0,2.13,0.29,4.15,1.98,5.69c2.15-0.18,3.38,0.92,4.29,2.37c0.15,0.24,0.29,0.49,0.43,0.75c-0.14-0.26-0.28-0.51-0.43-0.75
		c-0.91-1.44-2.14-2.54-4.29-2.36c1.89,6.04,6.77,10.79,7.47,17.35c0.01,0.09,0.08,0.19,0.14,0.27c2.95,4.46,5.73,9.06,10.25,12.21
		c0-0.01-0.01-0.03-0.01-0.04c1.23,0.18,2.33-0.01,3.52-0.53c4.5-1.96,8.79-4.2,13.05-6.7c4.05-2.37,7-5.04,9.09-9
		c1.32-2.5,0.91-4.12-1.8-5.02c-2.38-0.8-3.32-2.16-3.09-4.53c0.26-0.02,0.52-0.04,0.77-0.07c0.9,0.71,1.92,1.03,3.07,1.27
		c3.66,0.75,7.34,0.67,11,0.28c1.2-0.13,2.02,0.15,2.62,1.05c1.05,1.58,2.53,2.62,4.12,3.57c0.26,0.15,0.69-0.13,0.55,0.81
		c-0.34,2.29,2.1,3.82,4.24,2.74c1.19-0.6,1.48-0.3,1.55,0.75c0.58,8.22,5.32,14.94,8.06,22.38c0.59,1.6,1.56,2.62,3.3,0.58
		c0.94-1.09,2.04-2.25,2.14-3.46c0.2-2.51,1.15-5.02,0.39-7.6c-0.23-0.76-0.44-1.51,0.38-2.22c3.78-3.28,7.38-6.74,10.1-11.01
		c0.21-0.33,0.89-0.4,1.37-0.5c3.42-0.69,6.89-1.48,8.4,3.08c0.05,0.16,0.38,0.21,0.48,0.37c1.1,1.75,2.97,3.09,2.81,5.51
		c-0.05,0.72-0.65,1.67,0.52,2.12c0.99,0.38,1.83,0.05,2.49-0.69c1.28-1.41,2.11-0.91,2.47,0.66c0.61,2.65,1.99,5.01,2.17,7.89
		c0.21,3.56-0.35,7.57,3.08,10.29c0.44,0.35,0.82,1.02,0.87,1.57c0.22,2.52,1.68,4.53,2.66,6.73c-1.34,0.09-2.11-0.77-3.09-1.55
		c-2.39-1.93-3.95-5.31-7.85-4.83c-0.44,0.95,0.23,1.56,0.78,2.1c3.9,3.79,6.4,8.47,9.04,13.16c1.64,2.91,3.32,6.78,7.92,6.5
		c0.83-1.69-0.47-4.09,1.83-5.43c0.25-0.14-0.44-2.4-1.89-2.47c-2.16-0.1-4.44-3.24-3.78-5.48c1.24-4.19,0.12-7.9-3.19-10.6
		c-4.41-3.6-5.27-7.43-2.84-12.89c1.01,1.97,3.79,1.83,4.53,4.21c0.61,1.94,4.33,1.63,3.26,5.1c2.69-1.69,4.12-4.01,6.46-5.28
		c1.07-0.58,1.03-2.14,0.87-3.11c-0.44-2.72-1.13-5.4-3.59-7.22c-1.05-0.78-1.92-1.84-2.73-2.88c-1.86-2.39-1.52-3.76,1.04-5.85
		c2.27-1.86,3.39-0.34,4.55,1.61c0.59-1.7,1.7-2.27,3.12-2.77c2.45-0.85,5.02-1.52,7.18-3.09c2.4-1.74,4.04-4,4.34-7
		c0.23-2.31,3.96-4.81-0.29-6.94c0.98-3.8-2.41-5.38-4.34-7.17c-2.1-1.95-1.3-2.89-0.1-4.39c0.47-0.58,1.66-0.5,1.51-1.87
		c-2.71-1.03-6.32,0.49-8.59-2.73c1.3-0.54,2.13-1.65,3-2.72c0.4-0.48,0.76-1.04,1.49-0.83c0.81,0.23,0.56,0.91,0.6,1.51
		c0.11,1.49,0.38,2.17,2.13,1.03c1.75-1.15,3.8-1.38,4.37,1.56c0.13,0.66,0.36,1.28,1.04,1.35c2.9,0.32,3.25,2.76,3.78,4.78
		c0.45,1.72,0.98,2.31,2.7,1.62c1.58-0.64,2.9-1.28,1.79-3.33c-1.15-2.12-2.77-3.78-4.7-5.2c-0.56-0.41-1.82-0.91-0.78-1.82
		c1.27-1.11,1.13-2.44,1.35-3.85c0.18-1.13,0.85-2.43,2.57-1.76c1.76,0.68,2.96-0.29,3.38-1.72c1.31-4.4,2.93-8.88-0.54-13.13
		c-0.4-0.49-0.93-1.14-0.15-1.9c2.53,1.3,3.27,4.12,5.11,5.98c1.16,2.06,2.33,4.13,3.49,6.19c0.37,1.1,1.8,2.04,0.93,3.32
		c-1.09,1.61-0.18,2.48,0.7,3.71c1.37,1.93,2.72,4.05,2.18,6.58c-0.44,2.08-3.77,2.01-3.79,4.61c0,0.04-0.77,0.13-1.17,0.1
		c-2.51-0.19-3.89,1.35-5.11,3.21c-0.78,1.19,0.4,5.05,1.72,5.46c0.62,0.19,1.04-0.06,1.02-0.69c-0.12-3.82,3.21-2.95,5.22-3.9
		c2.19-1.04,4.21-2.43,6.63-2.98c0.2-0.05,0.48-0.8,0.36-1.07c-1.55-3.68-0.53-8.41-4.5-11.11c-0.56-0.38-1.26-0.67-1.2-1.54
		c0.74-0.44,1.38,0.08,2.07,0.14c1.54,0.14,3.33-1.92,3.16-3.43c-0.06-0.55,0.14-1-0.75-0.66c-2.59,0.98-4.4-1.02-6.52-1.84
		c-0.25-0.31-0.76-0.85-0.72-0.88c1.9-2.02-0.27-2.57-1.25-3.57c-0.49-0.5-0.87-1.09-1.3-1.64c-0.03-0.59-0.03-1.17,0.69-1.38
		c0.35,0.35,1.01,0.91,1.19,0.46c0.22-0.55-0.61-0.81-1.19-0.94c-2.5-2.56-4.98-5.14-7.5-7.67c-0.38-0.38-0.94-1.21-1.44-0.74
		c-2.36,2.2-4.62-2.09-6.9-0.02c-0.71-2.46-2.81-1.42-4.61-1.56c0.33-0.66,0.67-1.08,0.76-1.55c0.93-5.32,3.91-7.5,9.32-6.41
		c1.43,0.29,3.08-1.2,4.43-0.15c1.77,1.37,3.37,0.78,5.77,0.23c-2.59-1.02-2.41-2.69-1.41-3.87c0.86-1.02,2.58-0.96,3.63,0.01
		c1.44,1.33,2.53,1.38,3.71-0.18c0.24-0.31,0.65-0.53,1.07-0.24c0.44,0.3,0.89,0.74,0.58,1.26c-0.83,1.39-0.17,3.23-1.07,4.41
		c-1.6,2.09-0.69,3.74,0.67,5.12c2.66,2.7,5.55,5.18,8.36,7.73c0.72,0.66,1.66,0.95,1.74-0.35c0.12-1.96,0.32-4.17,0.08-5.85
		c-0.28-2.04-1.41-5.08-4.46-5.66c-1.7-0.32-1.77-1.78-1.75-2.84c0.03-1.16,1.38-0.82,2.25-1.05c1.66-0.44,3.24-0.95,4.91-0.15
		c0.63,0.3,0.9-0.12,1.15-0.61c1.31-2.57,3.47-3.72,6.53-3.4c-0.82-1.41-2.06-1.51-2.96-2.13c-0.85-0.59-2.39-0.75-1.82-2.35
		c0.58-1.63,1.95-0.69,2.91-0.62c2.54,0.2,4.77,1.95,7.45,1.59l0.15-0.16c-0.06,0.06-0.15,0.16-0.15,0.16
		C482.55,28.69,482.68,28.77,482.82,28.85z M329.41,25.63c2.53-3.93-2.11-5.21-2.14-8.1c2.31,0.89,3.31,3.11,4.58,4.92
		C333.02,24.11,331.46,24.86,329.41,25.63z M391.06,50.42c1.35-2.16,1.61-3.78,1.54-5.7C394.62,47.51,394.43,48.56,391.06,50.42z" />
                    <path
                        d="M290.21,25.81c-0.06-0.06-0.13-0.12-0.19-0.18c-0.07,0.09-0.14,0.17-0.21,0.26c0.06,0.03,0.13,0.06,0.19,0.1
		C290.08,25.93,290.15,25.87,290.21,25.81z" />
                </g>
                <g class="europe" (click)="setRegion('eu')">
                    <title>Europe</title>
                    <path
                        d="M323.73,48.23c-0.07-2.52-0.54-3.11-2.76-1.51c-1.34,0.96-1.96-0.38-2.61-1.27c-0.66-0.91-0.71-1.64,0.56-2.1
		c1.84-0.66,1.69-1.8,0.59-3.05c-0.73-0.83-0.92-1.78-0.93-2.83c-0.03-2.81-0.73-5.5-2.04-7.94c-1.16-2.16-0.74-3.24,1.54-4.08
		c2.09-0.78,4.17-1.88,2.54-4.78c-3.51-0.88-6.97-1.36-10.38,0.53c-0.93,0.52-2.33,0.87-3.28,0.57c-3.32-1.06-5.86,0.32-8.39,2.1
		c-1.23,0.87-2.24,0.64-2.08-0.47c0.33-2.34-1.48-1.71-2.28-1.59c-1.65,0.25-0.39,1.39-0.28,2.12c0.06,0.43,0.77,0.7,0.34,1.4
		c-1.53-0.42-3.77,0.81-4.33-2.05c-0.08-0.4-1.21-0.59-1.86-0.89c-3.19-1.46-6.68-1.89-10.01-2.88c-1.98-0.59-3.72-1.62-5.96-1.79
		c-3.36-0.26-6.5,0.61-9.48,1.47c-2.27,0.65-5.22,0.83-6.78,3.3l-0.04-0.04c0.77,0.89,0.68,0.82,0.04,1.67
		c-2.73,3.63-6.44,5.92-10.41,7.92c-1.62,0.82-2.12,4.11-0.96,6.24c1,1.82,2.67,2.44,4.35,1.15c2.31-1.78,3.63-1.28,4.44,1.35
		c0.25,0.8,1.64,1.23,0.83,2.32c-0.26,0.35-0.97,0.66-1.36,0.55c-1.1-0.31-1.1-1.38-1.26-2.31c-0.04-0.24-0.26-0.44-0.34-0.56
		c-3.06,0.56-3.83,2.28-2.7,4.92c0.62,1.46,0.19,1.85-1.36,2.09c-1.41,0.22-3.36-0.17-4.09,1.51c-0.91,2.11-2.77,2.84-4.54,3.79
		c-1.25,3.16-4.88,2.03-6.96,3.81c-0.59,0.5-2.35-0.36-2.67,0.81c-0.42,1.56,1.55,1.19,2.23,1.97c0.32,0.37,0.82,0.58,1.16,0.93
		c1.59,1.6,1.51,3.47,0.78,5.41c-0.79,2.1-2.43,0.87-3.71,0.87c-1.74,0.01-3.48-0.31-5.21-0.27c-1.49,0.03-3.34,0.09-3.02,2.36
		c0.28,2.02-0.2,3.78-0.78,5.68c-0.52,1.7-0.58,4.38,1.5,4.7c1.81,0.28,2.68,1.06,3.65,2.19c0,0,0.3,0,0.3,0l0.3-0.04
		c0.83-1.13,2.05-1.2,3.3-1.32c3.17-0.3,4.96-2.04,5.47-5.23c0.21-1.32,1.1-2.24,2.08-2.93c1.53-1.06,3.69-1.36,3.61-3.99
		c-0.02-0.75,1.24-0.92,1.81-0.65c2.24,1.04,3.96,0,5.62-1.21c1.35-0.99,2.52-0.59,3.05,0.65c1.06,2.49,3.27,3.48,5.29,4.84
		c1.76,1.19,4.34,1.9,3.79,4.92c-1.57,1-3.49,0.45-5.35,0.89c2.68,2.93,3.31,2.95,5.08,0.49c0.22-0.3,0.62-0.47,0.94-0.7
		c0.66-0.52,1.41-1.3,1.21-1.99c-0.71-2.5,0.7-1.9,2.31-1.29c-0.85-2.34-3.04-3.35-4.35-3.75c-2.58-0.79-2.94-3.32-4.83-4.47
		c-0.74-0.45-0.77-1.58-0.19-2.2c1.02-1.1,1.22,0.31,1.76,0.74c2.29,1.81,4.37,3.9,7.22,4.91c0.92,0.33,1.69,0.96,1.72,2.27
		c0.08,3.21,2.4,5.43,3.94,7.96c0.36,0.6,1.72,2.31,2.46-0.19c0.22-0.73,1.21-1.23,1.88-1.88c-0.57-1.32-2.42-1.86-2.47-3.61
		c1.72,0.08,2.92-2.97,4.98-0.82c1.48,0.04,2.82-0.43,4.12-1.08c-0.41-1.29-2.43-1.92-1.65-3.66c1.04-2.28,1.87-4.77,3.76-6.47
		c0.87-0.78,2.65,0.32,2.87,0.88c1.2,3.12,3.06,2.66,5.28,1.35c-0.1-0.85-1.81-0.71-1.38-1.67c0.79-1.75,2.79-1.36,4.82-2.31
		c-2.59,3.69-1.29,5.48,2.18,6.27c3.7,0.84,7.53,1.28,10.73,3.61c2,1.46,4.11,2.68,6.56,3.22c-1.28-2.5-3.97-3.87-4.96-6.74
		c-0.57-1.67-0.75-2.49,0.83-3.43c1.38-0.82,2.48-2.09,4.09-2.54c-0.96-1.76-2.01-3.48-2.86-5.29c-0.43-0.93-0.57-2.22,0.94-2.36
		c2.4-0.21,4.69-0.96,7.25-0.31c2.55,0.65,5.29,1.9,8.2,0.42C325.94,51.15,323.79,50.22,323.73,48.23z M273.53,37.19
		c-4.81-0.03-6.44,1.27-7.16,6.05c-0.33,2.21-1.06,3.47-3.54,2.65c-0.8-0.26-1.69-0.22-2.37,0.26c-1.83,1.27-3.63,0.71-5.46,0.11
		c-0.31-0.1-0.9,0.26-0.84-0.43c0.04-0.49,0.2-1.28,0.75-1.13c1.57,0.45,2.36-1,3.59-1.26c1.58-0.34,1.41-1.54,1.96-2.64
		c1.3-2.57,2.61-4.36,0.63-7.45c-0.94-1.46,6.6-6.73,9.05-6.35c-0.39,1.26-1.26,2.25-2.37,2.62c-3.49,1.15-1.87,3.68-1.34,5.5
		c0.57,1.93,2.54,1.56,4.21,1.29c1.85-0.29,3.69-1.24,5.72-0.36C274.81,37.19,274.81,37.19,273.53,37.19z M289.41,27.42
		c-0.09,0.92-0.89,0.57-1.41,0.46c-1.02-0.2-1.66-0.32-2.86,0.39c-2.02,1.2-1.95-1.39-2.73-2.84c2.26,0.23,4.07,0.48,5.89,0.57
		C289.36,26.05,289.47,26.76,289.41,27.42z M290.01,25.98c-0.06-0.03-0.13-0.06-0.19-0.1c0.07-0.09,0.14-0.17,0.21-0.26
		c0.06,0.06,0.13,0.12,0.19,0.18C290.15,25.87,290.08,25.93,290.01,25.98z" />
                    <path
                        d="M238.25,49.38c-1.75-0.77-1.96-2.49-3.04-3.62c-0.94-0.98-2.78-2.16-1.26-4.36c0.48-0.69-2.4-1.75-0.1-3.79
		c-7.68,3.45-6.19,2.72-5.94,7.61c-2.01-1.25-3.07,0.53-4.57,1.05c-2.98,1.02-0.78,3.82-2.31,5.14c1.52,1.37,2.85,0.02,4.26-0.29
		c1.53-0.33,2.29-1.13,2.26-2.74c-0.02-1.04,0.75-1.75,1.55-2.12c0.67-0.31,1.6-0.42,2.18,0.49c0.68,1.08,0.16,1.4-0.86,1.6
		c-1.45,0.29-0.43,2.11-1.55,2.64c1.51,1.61-0.33,2.37-1.45,3.84c4.01-1.04,7.49-1.52,10.98-1.81
		C237.02,51.83,239.89,50.11,238.25,49.38z" />
                    <path
                        d="M238.39,53.04c0.02,0.02,0.03,0.03,0.05,0.05c0.01-0.01,0.03-0.02,0.04-0.02c0.01-0.01,0.01-0.02,0.02-0.04
		C238.47,53.03,238.43,53.03,238.39,53.04z" />
                    <path
                        d="M258.31,9.28c2.06-2.89,4.46-1.96,6.99-1.02c0.4,0.15,1.03,0,1.15-0.65c-0.44-1.02-1.94-0.61-2.32-1.76
		c1.57-0.41,3.38,0.28,4.99-1.56c-5.9-1.13-11.33,0.35-16.79,0.34c-0.92,0-0.8,0.78-0.6,1.36C252.03,6.85,257.91,9.85,258.31,9.28z" />
                    <path
                        d="M301.04,15.83c-0.3,0.51-0.28,0.86,0.22,1.29c1.25,1.08,5.02,1.68,6.99,1.01c-3.31-2.26-3.31-2.97-0.13-5.48
		c1.41-1.11,3.06-1.58,4.77-1.96c1.44-0.32,3.04-0.27,4.86-2.1c-4.4,0.13-8.01,0.88-11.58,1.65
		C303.21,10.87,302.36,13.6,301.04,15.83z" />
                    <path
                        d="M219.86,26.85c-0.5-1.24-1.45-1.79-3.13-1.64c-2.46,0.22-4.95,1.75-7.53,0.08c-0.76-0.49-1.82,0.28-2.28,1.08
		c-0.4,0.7,0.03,1.25,0.54,2c1.3,1.92,3.14,1.8,4.7,2.07c2.26,0.09,4.02-0.81,5.83-1.46C218.89,28.67,220.5,28.42,219.86,26.85z" />
                    <path
                        d="M305.36,3.84c-2.78-1.67-5.58-2.16-8.49-0.51c-0.58,0.33-1.31,1.24-1.86,0.71c-2.04-1.93-4.25-0.19-6.34-0.36
		C294.22,4.82,299.79,4.45,305.36,3.84z" />
                    <path
                        d="M249.29,71.66c-1.72-0.01-1.28,1.08-1.13,1.8c0.04,1.04-0.79,2.32,0.74,2.32c1.44,0,1.52-1.35,1.58-2.48
		C250.54,72.43,250.47,71.67,249.29,71.66z" />
                </g>
            </svg>
        </div>
    </div>
</div>
