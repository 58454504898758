import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EyeliteFileImport } from '@app/professional-service/models/eyeliteimport/eyelite-file-import.model';
import { ProcessEyeliteImportRequest } from '@app/professional-service/models/eyeliteimport';

@Injectable({
    providedIn: 'root',
})
export class EyeliteService {
    private readonly baseUrl = 'api/eyelites/imports';

    constructor(private readonly httpClient: HttpClient) {}

    public processImport(request: ProcessEyeliteImportRequest): Observable<void> {
        return this.httpClient.post<void>(`${this.baseUrl}/process`, request);
    }

    public uploadEyeliteExportFile(formData: FormData): Observable<EyeliteFileImport> {
        return this.httpClient.post<EyeliteFileImport>(`${this.baseUrl}/upload`, formData);
    }
}
