import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-paging',
    templateUrl: './paging.component.html',
})
export class PagingComponent implements OnInit, OnChanges {
    @Input()
    totalReturns: number;

    @Input()
    pageReturnCount: number;

    @Input()
    pageSize: number;

    @Output() pageIndexEmitter = new EventEmitter<number>();

    pageIndex = 0;
    maxPage: number;
    pagesShown = [0, 1, 2, 3, 4];
    itemsShownLowerBound = 0;
    itemsShownUpperBound = 0;

    PostData() {
        this.pageIndexEmitter.emit(this.pageIndex);
        this.setPagesShown();
    }

    constructor() {
        // Do nothing
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.totalReturns?.previousValue !== changes.totalReturns?.currentValue) {
            this.pageIndex = 0;
        }
        this.setPagesShown();
    }

    ngOnInit(): void {
        // Do nothing
    }

    onClickPage(command: string | number): void {
        switch (command) {
            case 'first': {
                this.pageIndex = 0;
                this.PostData();
                break;
            }
            case 'previous': {
                this.pageIndex--;
                this.PostData();
                break;
            }
            case 'next': {
                this.pageIndex++;
                this.PostData();
                break;
            }
            case 'last': {
                this.pageIndex = this.maxPage - 1;
                this.PostData();
                break;
            }
            default: {
                this.pageIndex = parseInt(command?.toString(), 10);
                this.PostData();
            }
        }
    }

    setPagesShown(): void {
        this.maxPage = Math.ceil(this.totalReturns / this.pageSize);
        if (this.totalReturns > 5 * this.pageSize) {
            this.pagesShown = [];
            this.pagesShown.push(this.pageIndex - 2);
            this.pagesShown.push(this.pageIndex - 1);
            this.pagesShown.push(this.pageIndex);
            this.pagesShown.push(this.pageIndex + 1);
            this.pagesShown.push(this.pageIndex + 2);

            if (this.pagesShown[0] < 0) {
                this.pagesShown = [0, 1, 2, 3, 4];
            }

            if (this.pagesShown[0] > this.maxPage - 5) {
                this.pagesShown = [
                    this.maxPage - 5,
                    this.maxPage - 4,
                    this.maxPage - 3,
                    this.maxPage - 2,
                    this.maxPage - 1,
                ];
            }
        } else {
            this.pagesShown = [];
            for (let i = 0; i < this.maxPage; i++) {
                this.pagesShown.push(i);
            }
        }
        this.itemsShownLowerBound = this.pageIndex * this.pageSize + 1;
        this.itemsShownUpperBound =
            this.pageReturnCount >= this.pageSize
                ? this.itemsShownLowerBound + this.pageSize - 1
                : this.itemsShownLowerBound + this.pageReturnCount - 1;
    }
}
