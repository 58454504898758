import { Component, OnInit, Input } from '@angular/core';
import { NoteService } from '@app/core/services/api/note.service';
import { Note } from '@app/shared/models';
import { Client } from '@app/shared/models';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'internal-notes',
    templateUrl: 'internal-notes.component.html',
})
export class InternalNotesComponent implements OnInit {
    @Input() client: Client;

    private skip = 0;
    private take = Number.MAX_SAFE_INTEGER;

    notes: Note[];
    loading = true;

    constructor(public noteService: NoteService) {}

    async ngOnInit() {
        if (this.client) {
            this.notes = await lastValueFrom(this.noteService.getInternalNotes(this.client.Id, this.skip, this.take));
            this.notes = this.notes.sort(
                (a, b) => new Date(b.IsCreatedOn).getTime() - new Date(a.IsCreatedOn).getTime(),
            );
        }

        this.loading = false;
    }
}
