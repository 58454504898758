import { Component, Input, ViewEncapsulation, Optional, Host, SkipSelf } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseControl } from '@app/shared/components/inputs/base-control';
import { ListOption } from '@app/shared/models';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
    selector: 'mpc-typeahead',
    templateUrl: './input-typeahead.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputTypeAheadComponent,
            multi: true,
        },
    ],
})
export class InputTypeAheadComponent extends BaseControl {
    @Input() placeholder: string;
    @Input() typeaheadSource: ListOption[];

    selectedOption = new ListOption(0, '', '');

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        public controlContainer: ControlContainer,
    ) {
        super(controlContainer);
    }

    writeValue(value: ListOption): void {
        if (value != null) {
            this.value = value;
            this.selectedOption = value;
        }
    }

    selectValue(match: TypeaheadMatch): void {
        this.selectedOption = match.item;
    }
}
