import { Component, Input, ViewEncapsulation, Optional, OnInit, Host, SkipSelf } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { BaseControl } from '@app/shared/components/inputs/base-control';
import { InputDate } from '@app/shared/components/inputs/input-date/inputDate.model';

@Component({
    selector: 'mpc-input-date',
    templateUrl: './input-date.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputDateComponent,
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: InputDateComponent,
            multi: true,
        },
    ],
})
export class InputDateComponent extends BaseControl implements Validator, OnInit {
    @Input() allowIncompleteDate = false;
    @Input() required = false;
    @Input() maxYear = new Date().getFullYear();

    day: number = null;
    month: number = null;
    year: number = null;

    prevDay: number = null;
    prevMonth: number = null;
    prevYear: number = null;

    dayMonthYear = true;
    monthDayYear = false;

    monthDayYearRegions = ['en', 'en-US', 'en-CA'];

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        public controlContainer: ControlContainer,
    ) {
        super(controlContainer);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.determainDateFormat();
    }

    writeValue(value: InputDate): void {
        if (value) {
            this.prevDay = this.day;
            this.prevMonth = this.month;
            this.prevYear = this.year;

            this.day = value.day;
            this.month = value.month;
            this.year = value.year;
        } else {
            this.day = null;
            this.month = null;
            this.year = null;
        }
    }

    validateInput(event: KeyboardEvent, newVal: number): boolean {
        if (newVal === undefined || newVal === null) {
            return true;
        }
        // prevent invalid characters, only digits are allowed
        const pattern = /[0-9\+\-\ ]+|[\b]/;

        const keycode_backspace = 'Backspace';
        const keycode_delete = 'Delete';

        const patternTest = pattern.test(event.key) || pattern.test(newVal.toString());

        if (event.key !== keycode_backspace && event.key !== keycode_delete && !patternTest) {
            event.preventDefault();
            return false;
        }
        return true;
    }

    changeDay($event: KeyboardEvent) {
        if (this.validateInput($event, this.day) && this.day !== this.prevDay) {
            this.prevDay = this.day;
            this.propagateChange();
        }
    }

    changeMonth($event: KeyboardEvent) {
        if (this.validateInput($event, this.month) && this.month !== this.prevMonth) {
            this.prevMonth = this.month;
            this.propagateChange();
        }
    }

    changeYear($event: KeyboardEvent) {
        if (this.validateInput($event, this.year) && this.year !== this.prevYear) {
            this.prevYear = this.year;
            this.propagateChange();
        }
    }

    propagateChange(): void {
        const d = this.day ? Number(this.day) : null;
        const m = this.month ? Number(this.month) : null;
        const y = this.year ? Number(this.year) : null;

        const value = { day: d, month: m, year: y } as InputDate;
        this.onChange(value);

        this.onTouched();
    }

    validate(): ValidationErrors {
        const invalidReturnValue = { invalidDate: true };

        if (!this.day && !this.month && !this.year) {
            if (this.required) {
                return invalidReturnValue;
            } else {
                return {};
            }
        }

        const someFieldsFilled = !!this.day || !!this.month || !!this.year;
        const notAllFieldsFilled = !this.day || !this.month || !this.year;

        if (!this.allowIncompleteDate && someFieldsFilled && notAllFieldsFilled) {
            // not all 3 fields filled
            return invalidReturnValue;
        }

        if ((this.day && isNaN(this.day)) || (this.month && isNaN(this.month)) || (this.year && isNaN(this.year))) {
            // must be valid number
            return invalidReturnValue;
        }

        const d = Number(this.day);
        const m = Number(this.month);
        const y = Number(this.year);

        if (m && (m < 1 || m > 12)) {
            // month must be 1 - 12
            return invalidReturnValue;
        }

        if (y && (y < 1900 || y > this.maxYear)) {
            // validate year
            return invalidReturnValue;
        }

        let daysInMonth = 31;
        if (m && y) {
            // if there is a month and year, determine how many days in that month
            switch (m) {
                case 2:
                    daysInMonth = (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28;
                    break;
                case 4:
                case 6:
                case 9:
                case 11:
                    daysInMonth = 30;
                    break;
                default:
                    daysInMonth = 31;
                    break;
            }
        }

        if (d && (d < 1 || d > daysInMonth)) {
            // validate day
            return invalidReturnValue;
        }

        // when code reaches this point there are no errors
        return {};
    }

    determainDateFormat(): void {
        const currentLang = navigator.language;
        if (this.monthDayYearRegions.includes(currentLang)) {
            this.dayMonthYear = false;
            this.monthDayYear = true;
        } else {
            this.dayMonthYear = true;
            this.monthDayYear = false;
        }
    }
}
