import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserSetting, AuthenticatedUser, ListSelectOption } from '@app/shared/models';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserSettingService {
    private readonly basePath = 'api/user-settings';

    public themeSwitchCallback: () => void;

    constructor(private readonly httpClient: HttpClient) {}

    public changeTheme(theme: string): Observable<UserSetting> {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json'),
        };

        return this.httpClient.post<UserSetting>(`${this.basePath}/theme`, JSON.stringify(theme), options).pipe(
            tap(() => {
                if (this.themeSwitchCallback) {
                    this.themeSwitchCallback();
                }
            }),
        );
    }

    public getUserSettings(): Observable<UserSetting> {
        return this.httpClient.get<UserSetting>(this.basePath);
    }

    public getMeasurementUnitOptions(): Observable<ListSelectOption[]> {
        return this.httpClient.get<ListSelectOption[]>(`${this.basePath}/measurement-units/options`);
    }

    public getImageTypeOptions(): Observable<ListSelectOption[]> {
        return this.httpClient.get<ListSelectOption[]>(`${this.basePath}/image-types/options`);
    }

    public getNormalizeOptions(): Observable<ListSelectOption[]> {
        return this.httpClient.get<ListSelectOption[]>(`${this.basePath}/normalize-setting/options`);
    }

    public getMapTypeOptions(): Observable<ListSelectOption[]> {
        return this.httpClient.get<ListSelectOption[]>(`${this.basePath}/map-types/options`);
    }

    public saveUserSettings(userSettings: UserSetting): Observable<AuthenticatedUser> {
        return this.postAndMapAuthenticatedUser(this.basePath, userSettings);
    }

    public setTrainingCompleteConfirmation(): Observable<AuthenticatedUser> {
        return this.postAndMapAuthenticatedUser(`${this.basePath}/training-complete-confirmation`);
    }

    private postAndMapAuthenticatedUser(url: string, data: unknown = {}): Observable<AuthenticatedUser> {
        return this.httpClient.post<UserSetting>(url, data, { observe: 'response' }).pipe(
            map((response) => {
                const authenticatedUser = JSON.parse(response.headers.get('authenticated-user')) as AuthenticatedUser;

                if (authenticatedUser) {
                    return authenticatedUser;
                }
            }),
        );
    }
}
