import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ListOption } from '@app/shared/models';
import { OrderService } from '@app/core/services/api/order.service';
import {
    LensTypes,
    OrderLineStatus,
    EyeSides,
    Features,
    ParameterTypeCodes,
    FeatureCategories,
} from '@app/shared/enums';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { ServiceDialogComponent } from '@app/features/service/components/dialogs/service-dialog/service-dialog.component';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map, tap, startWith, switchMap } from 'rxjs/operators';
import { LensService } from '@app/core/services/api/lens.service';
import { Company } from '@app/shared/models/company.model';
import { FeatureCheckerService } from '@app/shared/appservices/feature-checker.service';
import { OrderLines } from '@app/shared/models/order-lines.model';

@Component({
    selector: 'order-lines',
    templateUrl: 'order-lines.component.html',
    styleUrls: ['order-lines.component.scss'],
})
export class OrderLinesComponent implements OnInit {
    @Input() clientId: number;
    @Input() orderId: number;
    @Input() isMyopiaActive: boolean;
    @Output() selectedOrderLinesChanged$: EventEmitter<OrderLines[]> = new EventEmitter();
    @Output() selectedLensFilterChanged$: EventEmitter<string> = new EventEmitter();

    @Output() shippedDateChanged: () => void;

    readonly parameterOrder: string[] = [
        ParameterTypeCodes.POW,
        ParameterTypeCodes.CYL,
        ParameterTypeCodes.AX,
        ParameterTypeCodes.DIAM,
        ParameterTypeCodes.RAD,
    ];

    public lensTypes = LensTypes;
    public selectedOrderLines: OrderLines[] = [];

    public formGroup: UntypedFormGroup;
    public orderedLensTypes: ListOption[];

    public hasMultipleLensTypes: boolean;
    public anyTrackAndTraceLink = false;
    public loadingOrderlines = false;
    public isFeatureWarrantyEnabled = false;
    public isFeatureAnnualSupplyEnabled = false;

    public orderLineStatusClasses = {};
    public trackAndTraceLinks = {};
    public sortClasses = {};

    public company: Company;
    public features = Features;
    public featureCategories = FeatureCategories;

    public orderLinesTable$: Observable<{
        headerParameters: {
            code: string;
        }[];
        data: OrderLines[];
    }>;

    private refresher$ = new BehaviorSubject<boolean>(false);
    private shouldAutoSelectOrderLines = true;
    private allLensTypes: ListOption[];

    private filter$ = new BehaviorSubject<{
        orderDirection: string;
        orderColumn: string | null;
    }>({
        orderDirection: 'desc',
        orderColumn: 'OrderDate',
    });

    get formControls() {
        return this.formGroup.controls;
    }

    get selectedLensTypeId() {
        return this.formControls['selectedLensType'] ? Number(this.formControls['selectedLensType'].value) : 0;
    }

    get isFeatureOrderingSparesEnabled(): boolean {
        return this.appStateService.isCompanyFeatureEnabled(Features.OrderingSpares);
    }

    get showCrediting(): boolean {
        return this.appStateService.isAutoCreditAllowed;
    }

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly modalService: BsModalService,
        private readonly orderService: OrderService,
        private readonly appStateService: AppStateService,
        private readonly lensService: LensService,
        private readonly featureCheckerService: FeatureCheckerService,
    ) {}

    public ngOnInit(): void {
        this.createForm();
        this.selectedOrderLines = new Array<OrderLines>();
        this.loadingOrderlines = true;

        this.lensService.getLensTypes().subscribe((lensTypes) => {
            this.allLensTypes = lensTypes;
            this.initializeTable();
            this.initializeFeatures();
        });
    }

    private selectNewestShippedOrderlines(orderLines: OrderLines[]): void {
        this.selectedOrderLines = new Array<OrderLines>();

        const shippedOrderLines = orderLines.filter(
            (orderLine) => orderLine.OrderLineStatusId === OrderLineStatus.Shipped,
        );
        const leftEyeOrderLines = shippedOrderLines.filter(
            (orderLine) => orderLine.FittedLens?.EyeSideId === EyeSides.Os,
        );
        const rightEyeOrderLines = shippedOrderLines.filter(
            (orderLine) => orderLine.FittedLens?.EyeSideId === EyeSides.Od,
        );

        const newestLeftEyeOrderLine = this.getLatestOrderLine(leftEyeOrderLines);
        const newestRightEyeOrderLine = this.getLatestOrderLine(rightEyeOrderLines);

        if (newestLeftEyeOrderLine) {
            this.selectedOrderLines.push(newestLeftEyeOrderLine);
        }

        if (newestRightEyeOrderLine) {
            this.selectedOrderLines.push(newestRightEyeOrderLine);
        }

        this.selectedOrderLinesChanged$.emit(this.selectedOrderLines);
    }

    private createForm(): void {
        this.formGroup = this.fb.group({
            selectedLensType: [''],
        });
    }

    public refresh(): void {
        this.refresher$.next(true);
    }

    private initializeTable(): void {
        const lensType$ = this.formControls['selectedLensType'].valueChanges.pipe(
            startWith(this.formControls['selectedLensType'].value),
            tap((lensType) => this.selectedLensFilterChanged$.emit(lensType)),
        );

        let orderLines$: Observable<OrderLines[]>;

        if (this.orderId) {
            orderLines$ = this.refresher$.pipe(
                tap(() => (this.loadingOrderlines = true)),
                switchMap((isRefreshing: boolean) =>
                    this.orderService.getOrderLines(null, this.orderId).pipe(
                        tap((orderLines) => this.updateLensTypes(orderLines, isRefreshing)),
                        tap(() => (this.loadingOrderlines = false)),
                    ),
                ),
            );
        } else {
            orderLines$ = this.refresher$.pipe(
                tap(() => (this.loadingOrderlines = true)),
                switchMap((isRefreshing: boolean) =>
                    this.orderService.getOrderLines(this.clientId, null).pipe(
                        tap((orderLines) => this.updateLensTypes(orderLines, isRefreshing)),
                        tap(() => (this.loadingOrderlines = false)),
                    ),
                ),
            );
        }

        //Reconstruct the table when the order lines or the lens type filter changes
        this.orderLinesTable$ = combineLatest([orderLines$, lensType$]).pipe(
            //Filter the order lines by the selected lens type
            map(([orderLines, lensType]) => this.filterByLensType(lensType, orderLines)),
            //Check if there are any track and trace links
            tap((orderLines) => {
                this.anyTrackAndTraceLink = orderLines.some(
                    (x) => x.OrderLineStatusId === OrderLineStatus.Shipped && x.TrackAndTraceLink !== null,
                );
            }),
            // Set classes for order line statuses in {} for use in template
            tap((orderLines) => {
                this.resolveOrderLineStatusClasses(orderLines);
                this.resolveTrackAndTraceLinks(orderLines);
            }),
            //Auto select the order lines if setting is enabled
            tap((orderLines: OrderLines[]) => this.autoSelectOrderLines(orderLines)),
            //Apply sorting
            switchMap((orderLines) =>
                this.filter$.pipe(
                    tap((filter) => {
                        if (filter.orderColumn && filter.orderDirection) {
                            orderLines = this.applySorting(orderLines, filter.orderColumn, filter.orderDirection);
                        }
                    }),
                    map(() => orderLines),
                ),
            ),
            //Reconstruct the table
            map((orderLines) => ({
                headerParameters: this.createHeaderParameters(orderLines),
                data: orderLines,
            })),
        );
    }

    private filterByLensType(lensType: number, orderLines: OrderLines[]) {
        const lensTypeId = Number(lensType);
        if (lensTypeId > 0) {
            orderLines = orderLines.filter((x) => x.FittedLens?.LensTypeId === lensTypeId);
        }
        this.clearSelection();
        return orderLines;
    }

    private clearSelection() {
        this.selectedOrderLinesChanged$.emit((this.selectedOrderLines = new Array<OrderLines>()));
    }

    private autoSelectOrderLines(orderLines: OrderLines[]) {
        if (this.shouldAutoSelectOrderLines) {
            this.selectNewestShippedOrderlines(orderLines);
        }
    }

    private updateLensTypes(orderLines: OrderLines[], isRefreshing: boolean) {
        const orderedLensTypeIds = [...new Set(orderLines.map((x) => x.FittedLens?.LensTypeId))];

        //Only show the already ordered unique lenstypes in the dropdown list
        this.orderedLensTypes = this.allLensTypes.filter((x) => orderedLensTypeIds.includes(x.Id));
        this.hasMultipleLensTypes = this.orderedLensTypes.length > 1;

        //Select the most recently ordered lenstype as the default, if this is the initial loading of the list
        if (!isRefreshing && orderLines && orderLines.length > 0) {
            const ol = this.getLatestOrderLine(orderLines);
            this.formControls['selectedLensType'].setValue(ol.FittedLens?.LensTypeId);
        }
    }

    private createHeaderParameters(orderLines: OrderLines[]) {
        const uniqueParameters = [
            ...new Map(
                orderLines
                    .filter((x) => x.FittedLens)
                    .flatMap((x) => x.FittedLens.Parameters)
                    .map((x) => [x.Code, x]),
            ).values(),
        ];

        return uniqueParameters
            .sort((a, b) => {
                const aIndex = this.parameterOrder.indexOf(ParameterTypeCodes[a.Code]);
                const bIndex = this.parameterOrder.indexOf(ParameterTypeCodes[b.Code]);
                if (aIndex === -1 && bIndex === -1) {
                    return a.SortOrder - b.SortOrder;
                }
                if (aIndex === -1) {
                    return 1;
                }
                if (bIndex === -1) {
                    return -1;
                }
                return aIndex - bIndex;
            })
            .map((param) => {
                return {
                    code: param.Code,
                };
            });
    }

    private applySorting(orderLines: OrderLines[], orderColumn: string, orderDirection: string): OrderLines[] {
        const keys = orderColumn.split('.');

        return orderLines.sort((a, b) => {
            let valA = a[keys[0]] ?? 0;
            let valB = b[keys[0]] ?? 0;

            if (keys[0] === 'FittedLens' && keys[1] === 'Parameters') {
                const paramA = a.FittedLens.Parameters.find((x) => x.Code === keys[2]);
                const paramB = b.FittedLens.Parameters.find((x) => x.Code === keys[2]);
                valA = paramA?.Value ?? 0;
                valB = paramB?.Value ?? 0;
            } else {
                for (let i = 1; i < keys.length; i++) {
                    valA = valA[keys[i]];
                    valB = valB[keys[i]];
                }
            }

            if (valA < valB || !valA) {
                return orderDirection === 'asc' ? -1 : 1;
            }
            if (valA > valB || !valB) {
                return orderDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    private getLatestOrderLine(orderLines: OrderLines[]): OrderLines {
        const sorted = orderLines.sort((a: OrderLines, b: OrderLines): number => {
            return new Date(b.OrderDate).getTime() - new Date(a.OrderDate).getTime();
        });
        return sorted[0];
    }

    public toggleOrderLine(item: OrderLines) {
        this.shouldAutoSelectOrderLines = false;

        if (this.isOrderLineSelected(item)) {
            this.selectedOrderLines = this.selectedOrderLines.filter((x) => x.Id !== item.Id);
        } else {
            switch (this.selectedOrderLines.length) {
                case 0:
                    this.selectedOrderLines.push(item);
                    break;
                case 1:
                    if (this.selectedOrderLines[0].FittedLens?.EyeSideId === item.FittedLens?.EyeSideId) {
                        this.selectedOrderLines[0] = item;
                    } else {
                        this.selectedOrderLines.push(item);
                    }
                    break;
                case 2:
                    if (this.selectedOrderLines[0].FittedLens?.EyeSideId === item.FittedLens?.EyeSideId) {
                        this.selectedOrderLines[0] = item;
                    } else if (this.selectedOrderLines[1].FittedLens?.EyeSideId === item.FittedLens?.EyeSideId) {
                        this.selectedOrderLines[1] = item;
                    }
                    break;
            }
        }

        this.selectedOrderLinesChanged$.emit(this.selectedOrderLines);
    }

    private resolveOrderLineStatusClasses(orderLines: OrderLines[]): void {
        for (const orderLine of orderLines) {
            if (orderLine.Child != null) {
                this.resolveOrderLineStatusClasses([orderLine.Child]);
            }

            if (
                this.featureCheckerService.check([
                    {
                        Feature: Features.HideOrderStatusFields,
                        Category: FeatureCategories.Company,
                        IsEnabled: true,
                        CompanyToCheck: this.appStateService.currentCompany,
                    },
                ])
            ) {
                this.orderLineStatusClasses[orderLine.Id] = 'no-filter';
                continue;
            }

            this.orderLineStatusClasses[orderLine.Id] = OrderLineStatus[orderLine.OrderLineStatusId].toLowerCase();
        }
    }

    private isOrderLineSelected(orderLine: OrderLines): boolean {
        return this.selectedOrderLines.some((ol) => ol.Id === orderLine.Id);
    }

    private resolveTrackAndTraceLinks(orderLines: OrderLines[]): void {
        for (const orderLine of orderLines) {
            if (
                orderLine.TrackAndTraceLink !== null &&
                orderLine.TrackAndTraceLink.trim() !== '' &&
                !orderLine.TrackAndTraceLink.startsWith('null') &&
                orderLine.OrderLineStatusId === OrderLineStatus.Shipped
            ) {
                this.trackAndTraceLinks[orderLine.Id] = orderLine.TrackAndTraceLink;
            }
        }
    }

    public openTrackAndTraceTab(item: OrderLines): void {
        let trackAndTraceLink = item.TrackAndTraceLink;

        const protocolPattern = /^((http|https):\/\/)/;
        if (!protocolPattern.test(trackAndTraceLink)) {
            trackAndTraceLink = 'http://' + trackAndTraceLink;
        }

        window.open(trackAndTraceLink, '_blank');
    }

    public showReturnDetails(returnId: number) {
        const options: ModalOptions<ServiceDialogComponent> = {
            initialState: {
                serviceId: returnId,
            },
            class: 'modal-half',
        };

        this.modalService.show(ServiceDialogComponent, options);
    }

    private getSortClass(columnName: string): string {
        const currentFilter = this.filter$.value;

        if (columnName === currentFilter.orderColumn) {
            return currentFilter.orderDirection === 'desc' ? 'fa fa-sort-down' : 'fa fa-sort-up';
        } else {
            return 'fa fa-sort';
        }
    }

    public applySort(event: MouseEvent, columnName: string): void {
        event.preventDefault();

        const toggleSortingDirection = (direction: string) => (direction === 'asc' ? 'desc' : 'asc');
        const currentSortingState = this.filter$.value;

        if (currentSortingState.orderColumn === columnName) {
            // The table is already sorted by this column, so flip the sorting direction
            const newDirection = toggleSortingDirection(currentSortingState.orderDirection);

            this.filter$.next({
                ...currentSortingState,
                orderDirection: newDirection,
            });
        } else {
            // The table is not yet sorted by this column, so set the sorting state accordingly
            this.filter$.next({
                orderColumn: columnName,
                orderDirection: 'asc',
            });
        }

        // Set the sorting classes for the table headers
        this.sortClasses[columnName] = this.getSortClass(columnName);
    }

    private initializeFeatures(): void {
        this.isFeatureWarrantyEnabled = this.appStateService.isCompanyFeatureEnabled(
            Features.OpticianLensOrderWarranty,
        );

        this.isFeatureAnnualSupplyEnabled = this.appStateService.isCompanyFeatureEnabled(Features.AnnualSupply);
    }
}
