<div class="d-flex flex-wrap smaller" [formGroup]="formGroup">
    <div
        class="px-1 d-flex flex-column align-items-start overrefraction-parameter mt-2"
        [ngClass]="{ 'pe-3': isreadonly }">
        <div class="spheric-title">
            {{ parameterName('POW') }}
        </div>
        <div class="spheric-value">
            <mpc-input-number
                *ngIf="!isreadonly"
                [formControlName]="'spheric'"
                id="spheric-parameter"
                [maxLength]="maxLength('POW')"
                [formControlOnly]="true"
                [formatting]="{
                    parameterType: 'POW'
                }"
                [ranges]="getRange('POW')"
                [isDisabled]="isDisabled"></mpc-input-number>
            <span *ngIf="isreadonly" id="spheric-parameter">
                {{
                    parameterValue('POW')
                        | numberFormat
                            : {
                                  parameterType: 'POW'
                              }
                }}
            </span>
        </div>
    </div>
    <div
        class="px-1 d-flex flex-column align-items-start overrefraction-parameter mt-2"
        [ngClass]="{ 'pe-3': isreadonly }">
        <div class="cylinder-title">
            {{ parameterName('CYL') }}
        </div>
        <div class="cylinder-value">
            <mpc-input-number
                *ngIf="!isreadonly"
                [formControlName]="'cylinder'"
                id="cylinder-parameter"
                [maxLength]="maxLength('CYL')"
                [formControlOnly]="true"
                [formatting]="{
                    parameterType: 'CYL'
                }"
                [ranges]="getRange('CYL')"
                [isDisabled]="isDisabled"></mpc-input-number>
            <span *ngIf="isreadonly" id="cylinder-parameter">
                {{
                    parameterValue('CYL')
                        | numberFormat
                            : {
                                  parameterType: 'CYL'
                              }
                }}
            </span>
        </div>
    </div>
    <div
        class="px-1 d-flex flex-column align-items-start overrefraction-parameter mt-2"
        [ngClass]="{ 'pe-3': isreadonly }">
        <div class="axis-title">
            {{ parameterName('AX') }}
        </div>
        <div class="axis-value">
            <mpc-input-number
                *ngIf="!isreadonly"
                [formControlName]="'axis'"
                id="axis-parameter"
                [maxLength]="maxLength('AX')"
                [formControlOnly]="true"
                [formatting]="{
                    parameterType: 'AX'
                }"
                [ranges]="getRange('AX')"
                [isDisabled]="isDisabled"></mpc-input-number>
            <span *ngIf="isreadonly" id="axis-parameter">
                {{
                    parameterValue('AX')
                        | numberFormat
                            : {
                                  parameterType: 'AX'
                              }
                }}
            </span>
        </div>
    </div>
    <div
        class="px-1 d-flex flex-column align-items-start overrefraction-parameter mt-2"
        [ngClass]="{ 'pe-3': isreadonly }">
        <div class="addition-title">
            {{ parameterName('ADD') }}
        </div>
        <div class="addition-value">
            <mpc-input-number
                *ngIf="!isreadonly"
                [formControlName]="'addition'"
                id="addition-parameter"
                [maxLength]="maxLength('ADD')"
                [formControlOnly]="true"
                [formatting]="{
                    parameterType: 'ADD'
                }"
                [ranges]="getRange('ADD')"
                [isDisabled]="isDisabled"></mpc-input-number>
            <span *ngIf="isreadonly" id="addition-parameter">
                {{
                    parameterValue('ADD')
                        | numberFormat
                            : {
                                  parameterType: 'ADD'
                              }
                }}
            </span>
        </div>
    </div>
    <div
        class="px-1 d-flex flex-column align-items-start overrefraction-parameter mt-2"
        [ngClass]="{ 'pe-3': isreadonly }">
        <div class="vertex-title">
            {{ parameterName('VERTD') }}
        </div>
        <div class="vertex-value">
            <mpc-input-number
                *ngIf="!isreadonly"
                [formControlName]="'vertex'"
                id="vertex-parameter"
                [maxLength]="maxLength('VERTD')"
                [formControlOnly]="true"
                [formatting]="{
                    parameterType: 'VERTD'
                }"
                [ranges]="getRange('VERTD')"
                [isDisabled]="isDisabled"></mpc-input-number>
            <span *ngIf="isreadonly" id="vertex-parameter">
                {{
                    parameterValue('VERTD')
                        | numberFormat
                            : {
                                  parameterType: 'VERTD'
                              }
                }}
            </span>
        </div>
    </div>
    <div class="d-flex flex-wrap smaller stabilisation mt-2">
        <div
            class="px-1 d-flex flex-column align-items-start overrefraction-parameter"
            [ngClass]="{ 'pe-3': isreadonly }">
            <div class="paramtitle" [ngClass]="{ 'text-danger': formGroup.get('stabilisation').errors }">
                {{ 'fitlens.stabilisation' | translate }}
            </div>
            <div>
                <mpc-input-number
                    *ngIf="!isreadonly"
                    [formControlName]="'stabilisation'"
                    id="{{ id }}-stabilisation"
                    [formControlOnly]="true"
                    [formatting]="{ signedNumber: false, decimalCount: 0 }"
                    [ranges]="stabilisationRanges"
                    [isDisabled]="isDisabled"></mpc-input-number>

                <span *ngIf="isreadonly" id="{{ id }}-stabilisation">
                    {{ formGroup.get('stabilisation').value | numberFormat: { signedNumber: false, decimalCount: 0 } }}
                </span>
            </div>
        </div>
        <div class="p-1 ps-2">
            <div class="position-relative lens">
                <div class="stabilisationLine" [ngStyle]="stabilisationLineStyle"></div>
            </div>
        </div>
    </div>
    <div *ngIf="!isreadonly" class="flex-fill d-flex">
        <div class="ms-auto pt-2">
            <button
                id="applyOverrefractionButton"
                class="btn btn-default"
                (click)="overrefraction()"
                [disabled]="isDisabled || formGroup.get('stabilisation').errors">
                {{ 'general.apply' | translate }}
            </button>
        </div>
    </div>
</div>
