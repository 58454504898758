import { LensDefinitionParameter } from '.';

export class LensDefinitionParameterUnit {
    public static paramErrorsGiven: string[] = [];

    public static getUnit(ldp: LensDefinitionParameter): string {
        let exportCode = ldp.ExportCode;

        if (!exportCode) {
            exportCode = ldp.ParameterType.ExportCode;
        }

        switch (exportCode.toUpperCase()) {
            case 'POW':
            case 'ADD':
            case 'CYL':
                return 'units.dioptry';

            case 'DIAM':
            case 'RAD1':
            case 'RAD2':
            case 'AC':
            case 'AC2':
            case 'RC':
            case 'RC2':
            case 'OZ':
            case 'VERTEDEEL':
            case 'SAG':
            case 'BCR_ADD':
                return 'units.millimeter';

            case 'AS':
            case 'STAB':
            case 'SEG':
                return 'units.degree';

            case 'E1':
            case 'E2':
            case 'E_INF':
            case 'E2_INF':
            case 'EXC':
            case 'EXCH':
            case 'EXCV':
            case 'EINF':
            case 'EIN2':
            case 'PROF':
            case 'TRUN':
            case 'PEFA':
            case 'PFI':
                return '';

            default: {
                if (!this.paramErrorsGiven.find((x) => x === ldp.Name)) {
                    this.paramErrorsGiven.push(ldp.Name);
                    console.log(ldp.Name + " doesn't not exist in the lens definition parameter unit list.");
                }

                return '';
            }
        }
    }
}
