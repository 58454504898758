<div class="sticky-element">
    <div class="col-12 px-0">
        <div class="d-flex justify-content-between">
            <h3>{{ 'ps.measurements' | translate }}</h3>

            <div class="d-flex align-items-center">
                <div *ngIf="measurementHistoryReloading" class="spinner-border spinner-border-sm mx-1"></div>

                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label
                        class="btn btn-secondary"
                        [ngClass]="{
                            active: currentImageChoice === imageChoice.Current
                        }"
                        (click)="setImageChoice(imageChoice.Current)">
                        <input
                            type="radio"
                            name="options"
                            class="btn-check"
                            id="measurement-current"
                            autocomplete="off" />
                        {{ 'measurement.current' | translate }}
                        <i class="fas fa-check" *ngIf="currentImageChoice === imageChoice.Current"></i>
                    </label>
                    <label
                        class="btn btn-secondary"
                        [ngClass]="{
                            active: currentImageChoice === imageChoice.Diff
                        }"
                        (click)="setImageChoice(imageChoice.Diff)">
                        <input type="radio" name="options" class="btn-check" id="measurement-diff" autocomplete="off" />
                        {{ 'measurement.diff' | translate }}
                        <i class="fas fa-check" *ngIf="currentImageChoice === imageChoice.Diff"></i>
                    </label>
                </div>

                <div class="ms-2">
                    <button class="btn btn-primary" [disabled]="!isMarkAsBaselineEnabled" (click)="markAsBaseline()">
                        {{ 'measurement.markasbaseline' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<measurement-history #measurementhistory [client]="client"></measurement-history>
