<div class="container-fluid d-flex flex-column flex-fill mt-4 px-0">
    <div class="row clientinfo mx-0">
        <!--Client information-->
        <div class="col-12 col-md-3 d-flex flex-column px-3 px-lg-4 mb-3">
            <div class="title-border-bottom mb-1">
                <h3>{{ 'general.client' | translate }}</h3>
            </div>
            <div>
                <client-details [client]="client"></client-details>
            </div>
        </div>

        <!--Optician information -->
        <div class="col-12 col-md-3 d-flex flex-column px-3 px-lg-4 mb-3" *ngIf="optician">
            <div class="title-border-bottom mb-1">
                <h3>{{ 'general.optician' | translate }}</h3>
            </div>
            <table>
                <tr>
                    <td class="td-icon"><i class="fas fa-male"></i></td>
                    <td #inputelement>{{ optician.Name }}</td>
                </tr>
                <tr>
                    <td class="td-icon">
                        <i class="fas fa-envelope"></i>
                        <br />
                    </td>
                    <td>{{ optician.EmailAddress }}</td>
                </tr>
                <tr>
                    <td class="td-icon"><i class="fas fa-mobile-alt"></i></td>
                    <td>{{ optician.PhoneNumber }}</td>
                </tr>
                <tr>
                    <td class="td-icon"><i class="fas fa-store-alt"></i></td>
                    <td>{{ optician.City }}</td>
                </tr>
                <tr
                    *hasFeatureSetting="[
                        {
                            Feature: Features.AutoCreditAllowed,
                            Category: FeatureCategories.Optician,
                            IsEnabled: true
                        },
                        {
                            Feature: Features.OpticianLensOrderWarranty,
                            Category: FeatureCategories.Company,
                            IsEnabled: false
                        }
                    ]">
                    <td class="td-icon"><i class="fas fa-exchange-alt"></i></td>
                    <td>{{ 'general.creditordersAllowed' | translate }}</td>
                </tr>
                <tr
                    *hasFeatureSetting="[
                        {
                            Feature: Features.Myopie,
                            Category: FeatureCategories.Optician,
                            IsEnabled: true
                        }
                    ]">
                    <td class="td-icon"><i class="fas fa-eye"></i></td>
                    <td>{{ 'general.myopia' | translate }}</td>
                </tr>
            </table>
        </div>

        <!--Communication-->
        <div class="col-12 col-md-6 mt-3 mt-md-0 d-flex flex-column px-3 px-md-4 mb-3">
            <div class="title-border-bottom communication-area">
                <h3>
                    <ng-container *ngIf="contents === 'externalCommunication'">
                        {{ 'ps.ExternalCommunication' | translate }}
                    </ng-container>
                    <ng-container *ngIf="contents === 'internalCommunication'">
                        {{ 'ps.InternalCommunication' | translate }}
                    </ng-container>
                    <ng-container *ngIf="contents === 'notes'">{{ 'clientnotes.notes' | translate }}</ng-container>

                    <div class="btn-group btn-group-sm" role="group">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            [ngClass]="contents === 'externalCommunication' ? 'disabled' : ''"
                            (click)="setContent('externalCommunication')">
                            {{ 'ps.ExternalCommunication' | translate }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary"
                            [ngClass]="contents === 'internalCommunication' ? 'disabled' : ''"
                            (click)="setContent('internalCommunication')">
                            {{ 'ps.InternalCommunication' | translate }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary"
                            [ngClass]="contents === 'notes' ? 'disabled' : ''"
                            (click)="setContent('notes')">
                            {{ 'clientnotes.notes' | translate }}
                        </button>
                    </div>
                </h3>
            </div>
            <div class="flex-grow-1">
                <ng-container *ngIf="client">
                    <client-communication
                        *ngIf="contents === 'externalCommunication'"
                        [clientId]="client.Id"
                        [orderId]="lastOrderId"
                        [showClientNotes]="true"
                        [showOrderNotes]="false"
                        [showAttachments]="true"
                        [isReadOnly]="isExternalCommunicationReadOnly"></client-communication>
                    <client-communication
                        *ngIf="contents === 'internalCommunication'"
                        [clientId]="client.Id"
                        [showInternalNotes]="true"
                        [showClientNotes]="false"
                        [showOrderNotes]="false"
                        [showAttachments]="false"
                        [isReadOnly]="false"></client-communication>
                    <client-notes
                        *ngIf="contents === 'notes'"
                        [client]="client"
                        [showInputfield]="false"></client-notes>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="flex-fill px-3 px-lg-4 mt-3 pt-3 d-flex flex-column accented-background">
        <div class="clienttabs mb-1">
            <div class="client-tab me-4 mb-1" [ngClass]="clientTabClass(1)">
                <h3>{{ 'general.orderhistory' | translate }}</h3>
                <a href="#" id="orderhistoryLink" (click)="clickTab($event, 1)">
                    <h3>{{ 'general.orderhistory' | translate }}</h3>
                </a>
            </div>
            <div class="client-tab me-4 mb-1" [ngClass]="clientTabClass(3)" *ngIf="!!client && client.Myopie">
                <h3>{{ 'general.myopiapower' | translate }}</h3>
                <a href="#" id="myopiapowerLink" (click)="clickTab($event, 3)">
                    <h3>{{ 'general.myopiapower' | translate }}</h3>
                </a>
            </div>
            <div class="client-tab me-4 mb-1" [ngClass]="clientTabClass(4)" *ngIf="!!client && client.Myopie">
                <h3>{{ 'general.myopiaaxislength' | translate }}</h3>
                <a href="#" id="myopiaaxislengthLink" (click)="clickTab($event, 4)">
                    <h3>{{ 'general.myopiaaxislength' | translate }}</h3>
                </a>
            </div>
        </div>

        <div id="orderlines" class="flex-fill d-flex" *ngIf="tab === 1 && !!client && !loading">
            <order-lines
                #orderlines
                class="w-100"
                [clientId]="client.Id"
                [isMyopiaActive]="client.Myopie"
                (selectedLensFilterChanged$)="onSelectedLensfilterChanged($event)"
                (selectedOrderLinesChanged$)="onSelectedOrderLinesChanged($event)"></order-lines>
        </div>
        <div id="myopiapower" class="col-12 px-0" *ngIf="tab === 3 && !!client">
            <myopia-power [clientId]="client.Id"></myopia-power>
        </div>
        <div id="myopiaaxislength" class="col-12 px-0" *ngIf="tab === 4 && !!client && client.Myopie">
            <myopia-axis-length [clientId]="client.Id"></myopia-axis-length>
        </div>
    </div>
</div>

<div *footer class="row">
    <div class="ms-auto d-flex justify-content-end">
        <div class="d-flex align-items-center">
            <button
                type="button"
                class="btn btn-proceed mx-1"
                id="reOrder"
                [disabled]="!isReOrderEnabled || !isLensInStock"
                (click)="reOrder()">
                {{ 'general.reorder' | translate }}
            </button>
        </div>
    </div>
</div>
