<ng-container *ngIf="loading; else list">
    <div class="container mt-4 d-flex justify-content-center">
        <div class="spinner-border spinner-border-sm me-2"></div>
    </div>
</ng-container>
<ng-template #list>
    <div
        *ngIf="lensItems.length > 0 || productItems.length > 0; else empty"
        class="cart-container flex-fill px-3 px-lg-4 pt-3 d-flex flex-column">
        <cart-item-lens-table
            class="cart-data mb-4"
            *ngIf="lensItems.length > 0"
            [items]="lensItems"
            [itemColors]="lensColors"
            (deleteItemEvent)="deleteItem($event)"
            (updateItemQuantityEvent)="updateItemQuantity($event)"></cart-item-lens-table>
        <cart-item-product-table
            *ngIf="productItems.length > 0"
            class="cart-data"
            [items]="productItems"
            [itemColors]="productColors"
            (deleteItemEvent)="deleteItem($event)"
            (updateItemQuantityEvent)="updateItemQuantity($event)"></cart-item-product-table>
        <div
            class="col-12 offset-md-4 col-md-8 offset-xl-7 col-xl-5 rounded orderreferencepanel"
            [formGroup]="formGroup">
            <div class="m-2 p-2 pt-3">
                <mpc-input-text
                    formControlName="reference"
                    [id]="'orderreference'"
                    label="order.reference"
                    [breakpoint]="'sm'"
                    [maxLength]="50"></mpc-input-text>
            </div>
        </div>
    </div>

    <ng-template #empty>
        <div class="container mt-4 d-flex justify-content-center">
            {{ 'orderoverview.empty' | translate }}
        </div>
    </ng-template>
</ng-template>

<div *footer class="d-flex align-items-center">
    <div>
        <a href="#" (click)="moreProducts($event)">
            <i class="fas fa-arrow-alt-circle-left me-1"></i>
            {{ 'shoppingcart.showmoreproducts' | translate }}
        </a>
    </div>
    <div class="ms-auto">
        <button type="button" id="order" class="btn btn-success" [disabled]="!hasCartItems" (click)="orderNow()">
            <i class="fas fa-shopping-bag"></i>
            {{ 'general.toorder' | translate }}
        </button>
    </div>
</div>
