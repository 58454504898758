import { Component, Input, OnInit } from '@angular/core';
import { PsSupportOrderComponent } from '../ps-supportorder.component';
import { Client, Optician, Order } from '@app/shared/models';

@Component({
    selector: 'ps-client-and-optician',
    templateUrl: './ps-clientandoptician.component.html',
    styleUrls: ['ps-clientandoptician.component.scss'],
})
export class PsClientAndOpticianComponent implements OnInit {
    @Input() psSupportOrderComponent: PsSupportOrderComponent;

    client: Client;
    optician: Optician;
    order: Order;

    constructor() {
        // Do nothing
    }

    ngOnInit() {
        this.client = this.psSupportOrderComponent.getClient();
        this.optician = this.psSupportOrderComponent.getOptician();
        this.order = this.psSupportOrderComponent.getOrder();
    }
}
