export class ProposalReceiptHistoryLine {
    Index: number;
    ProposalReceiptId: number;
    CreatedDate: Date;
    IsOrdered: boolean;
    Quantity: number;
    FittedLens: FittedLens | null;
}

interface Product {
    Id: number;
    Name: string;
}

interface FittedLens {
    Id: number;
    EyeSideId: number;
    LensTypeId: number | null;
    Engraving: string | null;
    Parameters: FittedLensParameter[];
    Material: Material | null;
    Product: Product | null;
}

interface Material {
    Name: string | null;
    Color: string | null;
}

interface FittedLensParameter {
    Id: number;
    Code: string;
    Value: number;
    SortOrder: number;
}
