<div class="topoImageThumbnail" *ngIf="imageData">
    <div class="position-relative d-flex">
        <div *ngIf="imageOptions && imageOptions.ShouldOpenDialog" class="position-absolute zoom">
            <i class="fas fa-search-plus"></i>
        </div>
        <img
            [src]="imageData"
            class="img-fluid"
            alt="img-{{ measurement.Performed | mpcdate }} {{ measurement.Performed | mpctime }}" />
    </div>
</div>
