import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EyeliteFileImport } from '@app/professional-service/models/eyeliteimport/eyelite-file-import.model';
import { EyeliteMeasurement, ProcessEyeliteImportRequest } from '@app/professional-service/models/eyeliteimport';
import { AlertService } from '@app/shared/appservices/alert.service';
import { Client } from '@app/shared/models/client.model';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { EyeliteImportService } from './eyeliteimport.service';
import { EyeliteService } from '@app/core/services/api/eyelite.service';

@Component({
    selector: 'ps-eyeliteimport',
    templateUrl: './ps-eyeliteimport.component.html',
    styleUrls: ['./ps-eyeliteimport.component.scss'],
})
export class PsEyeliteimportComponent implements OnInit {
    constructor(
        private alertService: AlertService,
        private translate: TranslateService,
        private loaderService: LoaderService,
        private eyeliteImportService: EyeliteImportService,
        private eyeliteService: EyeliteService,
    ) {}

    @Input() client: Client;

    public loading = false;
    private fileToUpload: File = null;
    public eyeliteFileImport: EyeliteFileImport;

    public allMeasurementsSelected = false;

    public allRgpLensesSelected = false;
    public allSoftLensesSelected = false;
    public allDisposableLensesSelected = false;
    public allDreamLiteLensesSelected = false;

    ngOnInit() {
        this.eyeliteFileImport = this.eyeliteImportService.getEyeliteFileImport();
    }

    public handleFileInput(files: FileList): void {
        this.loaderService.show();
        this.fileToUpload = files.item(0);
        if (this.fileToUpload) {
            const formData: FormData = new FormData();
            formData.append('file', this.fileToUpload, this.fileToUpload.name);

            this.eyeliteService.uploadEyeliteExportFile(formData).subscribe((result: EyeliteFileImport) => {
                if (result) {
                    this.eyeliteFileImport = new EyeliteFileImport(result);
                    this.eyeliteImportService.setEyeliteFileImport(this.eyeliteFileImport);
                }
                this.loaderService.hide();
            });
        }
    }

    public resetEyeliteImport(): void {
        this.eyeliteFileImport = null;
        this.eyeliteImportService.clearEyeliteFileImport();
    }

    public selectAllMeasurementsStateChanged(): void {
        this.allMeasurementsSelected = !this.allMeasurementsSelected;
        for (let i = 0; i < this.eyeliteFileImport.Import.Client.Measurements.length; i++) {
            const measurement = this.eyeliteFileImport.Import.Client.Measurements[i];
            measurement.IsSelected = this.allMeasurementsSelected;
        }
    }

    public selectAllRgpLensesStateChanged(): void {
        this.allRgpLensesSelected = !this.allRgpLensesSelected;
        for (let i = 0; i < this.eyeliteFileImport.Import.Client.Lenses.RgpLenses.length; i++) {
            const lens = this.eyeliteFileImport.Import.Client.Lenses.RgpLenses[i];
            lens.IsSelected = this.allRgpLensesSelected;
        }
    }

    public selectAllSoftLensesStateChanged(): void {
        this.allSoftLensesSelected = !this.allSoftLensesSelected;
        for (let i = 0; i < this.eyeliteFileImport.Import.Client.Lenses.SoftLenses.length; i++) {
            const lens = this.eyeliteFileImport.Import.Client.Lenses.SoftLenses[i];
            lens.IsSelected = this.allSoftLensesSelected;
        }
    }

    public selectAllDisposableLensesStateChanged(): void {
        this.allDisposableLensesSelected = !this.allDisposableLensesSelected;
        for (let i = 0; i < this.eyeliteFileImport.Import.Client.Lenses.DisposableLenses.length; i++) {
            const lens = this.eyeliteFileImport.Import.Client.Lenses.DisposableLenses[i];
            lens.IsSelected = this.allDisposableLensesSelected;
        }
    }

    public selectAllDreamLiteLensesStateChanged(): void {
        this.allDreamLiteLensesSelected = !this.allDreamLiteLensesSelected;
        for (let i = 0; i < this.eyeliteFileImport.Import.Client.Lenses.DreamLiteLenses.length; i++) {
            const lens = this.eyeliteFileImport.Import.Client.Lenses.DreamLiteLenses[i];
            lens.IsSelected = this.allDreamLiteLensesSelected;
        }
    }

    public toggleMeasurementSelection(measurement: EyeliteMeasurement): void {
        measurement.IsSelected = !measurement.IsSelected;

        let allMeasurementsAreSelected = true;

        for (let i = 0; i < this.eyeliteFileImport.Import.Client.Measurements.length; i++) {
            const currentMeasurement = this.eyeliteFileImport.Import.Client.Measurements[i];

            if (currentMeasurement.IsSelected === false) {
                allMeasurementsAreSelected = false;
                break;
            }
        }

        this.allMeasurementsSelected = allMeasurementsAreSelected;
    }

    public toggleLensSelection(lens: { IsSelected: boolean }): void {
        lens.IsSelected = !lens.IsSelected;
    }

    public processEyeliteImport(): void {
        this.loading = true;
        const request = new ProcessEyeliteImportRequest();
        request.ClientId = this.client.Id;
        request.FileImport = this.eyeliteFileImport;

        this.eyeliteService.processImport(request).subscribe(
            () => {
                this.eyeliteFileImport = null;
                this.eyeliteImportService.clearEyeliteFileImport();
                this.loading = false;
                this.alertService.success(this.translate.instant('eyeliteimport.importresultsucces'));
            },
            () => {
                this.loading = false;
                this.alertService.error(this.translate.instant('eyeliteimport.importresulterror'));
            },
        );
    }
}
