<div class="current-lens">
    <div class="row" *ngIf="infoFromPreviousOrder">
        <div class="col-2">{{ 'general.currentLens' | translate }}:</div>

        <div class="col-10">
            <span *ngIf="lensSelectionEnabled">{{ infoFromPreviousOrder.lensName }}:</span>
            <span *ngFor="let option of infoFromPreviousOrder.lensOptions; let i = index">
                {{ option.name }}
                {{ option.value | numberFormat: '{"parameterType":"' + option.code + '"}' }}
            </span>
        </div>
    </div>

    <div *ngIf="showOverRefractionInfo && !isDreamLiteFollowUp">
        <hr />
        <div class="row">
            <div class="col-2">{{ 'fitlens.overrefraction' | translate }}:</div>

            <div class="col-10">
                <span *ngFor="let param of getOverrefractionInfo(); let i = index">
                    {{ param.Name | translate }}
                    {{ param.Value | numberFormat: '{"parameterType":"' + param.Code + '"}' }}
                </span>
            </div>
        </div>
    </div>
</div>
