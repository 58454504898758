export class ReturnConclusion {
    Id: number;
    ReturnId: number;
    Conclusion: string;
    InternalConclusion: string;
    ReturnStatusId: number;
    Vigilance: boolean;
    ReturnResultId?: number;
    ReturnCreditId?: number;
    DynamicsDispositionId?: number;
    DynamicsReturnReasonId?: number;
    ReturnCategoryId: number;
    ReturnTypeId: number;
    ReturnResultName?: string;
    ReturnCreditName?: string;
}
