import { Component, OnInit, Input, DestroyRef, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { switchMap, tap } from 'rxjs/operators';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { ImageOptions } from '@app/shared/models/image-options.model';
import { HistoricalMeasurement } from '@app/shared/models/historical-measurement.model';
import { EyeSides, ImageChoice } from '@app/shared/enums';
import { Client, RefractionMeasurement, TopographicMeasurement } from '@app/shared/models';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { SaveBaseLineTopographicMeasurementsForClientRequest } from '@app/shared/models/SaveBaseLineTopographicMeasurementsForClientRequest.model';
import { HistoricalMeasurements } from '@app/shared/models/historical-measurements';
import { TopographicMeasurementService } from '@app/core/services/api/topographic-measurement.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'measurement-history',
    templateUrl: 'measurement-history.component.html',
})
export class MeasurementHistoryComponent implements OnInit {
    public formGroup: UntypedFormGroup;
    get formControls() {
        return this.formGroup.controls;
    }

    @Input() client: Client;
    @Input() inDeleteMode: boolean;

    public loader$ = new BehaviorSubject<void>(undefined);
    public rightHistoricalMeasurements$ = new BehaviorSubject<HistoricalMeasurement[]>([]);
    public leftHistoricalMeasurements$ = new BehaviorSubject<HistoricalMeasurement[]>([]);

    public currentImageChoice = ImageChoice.Current;
    public imageOptions: ImageOptions;
    public rightSelectedRefractionMeasurement: RefractionMeasurement;
    public leftSelectedRefractionMeasurement: RefractionMeasurement;

    public isLoading = false;
    public isReloading = false;
    private isReloadingImages = false;

    private originalTake = 5;
    private skip = 0;
    private take = this.originalTake;

    private readonly destroyRef = inject(DestroyRef);

    constructor(
        public appState: AppStateService,
        private readonly fb: UntypedFormBuilder,
        private readonly topographicMeasurementService: TopographicMeasurementService,
        private readonly loaderService: LoaderService,
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.imageOptions = new ImageOptions();
        this.imageOptions.ShowImageChoice = true;

        const currentPage$ = this.loader$.pipe(
            tap(() => (this.isReloading = true)),
            tap(
                () =>
                    (this.imageOptions.ImageChoice =
                        this.currentImageChoice === ImageChoice.Current ? 'current' : 'diff'),
            ),
            switchMap(() =>
                this.topographicMeasurementService.getMeasurementHistoryByClientId(
                    this.client.Id,
                    this.imageOptions.ImageChoice,
                    this.skip,
                    this.take,
                ),
            ),
            tap(() => {
                if (this.isReloadingImages) {
                    this.skip = this.take;
                }

                this.take = this.originalTake;
                this.isLoading = false;
                this.isReloading = false;
                this.loaderService.hide();
            }),
            takeUntilDestroyed(this.destroyRef),
        );

        currentPage$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((response) => {
            this.refreshHistoricalMeasurements(response);
            this.isReloadingImages = false;
        });

        fromEvent(window, 'scroll')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                const bottomPixelOffset = 300;
                const isBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - bottomPixelOffset;

                if (isBottom && !this.isReloading && this.take !== 0) {
                    this.skip += this.take;
                    this.loader$.next();
                }
            });

        this.createForm();
    }

    createForm(): void {
        this.formGroup = this.fb.group({});
    }

    setImageChoice(imageChoice: ImageChoice): void {
        if (imageChoice !== this.currentImageChoice) {
            this.currentImageChoice = imageChoice;
            this.reloadLoadedInMeasurements();
        }
    }

    public rightMeasurementSelected(refractionMeasurement: RefractionMeasurement): void {
        this.rightSelectedRefractionMeasurement =
            this.rightSelectedRefractionMeasurement === refractionMeasurement ? undefined : refractionMeasurement;
    }

    public leftMeasurementSelected(refractionMeasurement: RefractionMeasurement): void {
        this.leftSelectedRefractionMeasurement =
            this.leftSelectedRefractionMeasurement === refractionMeasurement ? undefined : refractionMeasurement;
    }

    public deleteHistoricMeasurement(): void {
        this.reloadLoadedInMeasurements();
    }

    markAsBaseline(): void {
        this.loaderService.show();

        const requestModel = new SaveBaseLineTopographicMeasurementsForClientRequest();
        requestModel.clientId = this.client.Id;

        if (
            this.leftSelectedRefractionMeasurement?.Id &&
            this.leftSelectedRefractionMeasurement?.TopographicMeasurementId
        ) {
            requestModel.leftBaseLineTopographicMeasurementId =
                this.leftSelectedRefractionMeasurement.TopographicMeasurementId;
            requestModel.leftBaseLineRefractionMeasurementId = this.leftSelectedRefractionMeasurement.Id;
        }

        if (
            this.rightSelectedRefractionMeasurement?.Id &&
            this.rightSelectedRefractionMeasurement?.TopographicMeasurementId
        ) {
            requestModel.rightBaseLineTopographicMeasurementId =
                this.rightSelectedRefractionMeasurement.TopographicMeasurementId;
            requestModel.rightBaseLineRefractionMeasurementId = this.rightSelectedRefractionMeasurement.Id;
        }

        this.topographicMeasurementService.saveBaseLineTopographicMeasurementsForClient(requestModel).subscribe(() => {
            this.reloadLoadedInMeasurements();

            const element = document.getElementById('measurement-histories');
            const domRect = element.getBoundingClientRect();
            const extraTopPixelOffsets = 150;
            const top = domRect.top + window.scrollY - extraTopPixelOffsets;
            window.scrollTo({ top, behavior: 'smooth' });
        });
    }

    private reloadLoadedInMeasurements() {
        this.take = this.take + this.skip;
        this.skip = 0;
        this.isReloadingImages = true;
        this.loader$.next();
    }

    public IsBaseLine(hm: HistoricalMeasurement): boolean {
        return hm.topographicMeasurement?.IsBaseLine && hm.refractionMeasurement?.IsBaseLine;
    }

    get isRegularTopoMeasurementSelected(): boolean {
        return (
            MeasurementHistoryComponent.hasRegularTopoMeasurement(this.leftSelectedRefractionMeasurement) ||
            MeasurementHistoryComponent.hasRegularTopoMeasurement(this.rightSelectedRefractionMeasurement)
        );
    }

    private static hasRegularTopoMeasurement(refractionMeasurement: RefractionMeasurement): boolean {
        if (!refractionMeasurement?.Id || !refractionMeasurement?.TopographicMeasurementId) {
            return false;
        }

        return !!refractionMeasurement.IsOverrefraction === false;
    }

    private refreshHistoricalMeasurements(historicalMeasurement: HistoricalMeasurements): void {
        const historicalMeasurements: HistoricalMeasurement[] = [];

        historicalMeasurement.TopographicMeasurements.forEach((topographicMeasurement: TopographicMeasurement) => {
            const refractionMeasurement = topographicMeasurement.RefractionMeasurements[0];

            // then remove the refractionMeasurement from the topographicMeasurement.RefractionMeasurements
            topographicMeasurement.RefractionMeasurements = topographicMeasurement.RefractionMeasurements.filter(
                (x) => x.Id !== refractionMeasurement.Id,
            );

            historicalMeasurements.push({
                refractionMeasurement: refractionMeasurement,
                topographicMeasurement: topographicMeasurement,
                eyeSideId: topographicMeasurement?.EyeSideId ?? null,
                performedDate: new Date(topographicMeasurement?.Performed),
            });
        });

        // Do the same for refractionMeasurements without topographicMeasurement
        historicalMeasurement.RefractionMeasurements.forEach((refractionMeasurement: RefractionMeasurement) => {
            historicalMeasurements.push({
                refractionMeasurement: refractionMeasurement,
                topographicMeasurement: null,
                eyeSideId: refractionMeasurement?.EyeSideId ?? null,
                performedDate: new Date(refractionMeasurement?.Performed),
            });
        });

        // Push next to the right and left historicalMeasurements$ BehaviorSubjects and filter by eyeside
        const filteredRightHistoricalMeasurements = historicalMeasurements.filter((x) => x.eyeSideId === EyeSides.Od);
        const rightHistoricalMeasurements = this.isReloadingImages
            ? filteredRightHistoricalMeasurements
            : [...this.rightHistoricalMeasurements$.getValue(), ...filteredRightHistoricalMeasurements];

        const filteredLeftHistoricalMeasurements = historicalMeasurements.filter((x) => x.eyeSideId === EyeSides.Os);
        const leftHistoricalMeasurements = this.isReloadingImages
            ? filteredLeftHistoricalMeasurements
            : [...this.leftHistoricalMeasurements$.getValue(), ...filteredLeftHistoricalMeasurements];

        this.rightHistoricalMeasurements$.next(rightHistoricalMeasurements);
        this.leftHistoricalMeasurements$.next(leftHistoricalMeasurements);
    }
}
