<div [formGroup]="formGroup">
    <div class="row">
        <div class="col-12 col-sm-9 col-xl-6">
            <mpc-input-text
                formControlName="reference"
                [id]="'reference'"
                label="general.reference"
                placeholder="general.reference"
                [maxLength]="100"
                info="{{ 'general.reference.info' | translate }}"
                infoPosition="right"></mpc-input-text>
            <mpc-input-text
                formControlName="reference2"
                [id]="'reference2'"
                label="general.reference2"
                placeholder="general.reference2"
                [maxLength]="100"
                info="{{ 'general.reference2.info' | translate }}"
                infoPosition="right"></mpc-input-text>
            <mpc-input-date
                formControlName="birthDate"
                id="birthDate"
                label="general.birthdate"
                info="{{ 'general.birthdate.info' | translate }}"
                infoPosition="right"></mpc-input-date>
            <mpc-input-checkbox-list
                formControlName="gender"
                id="gender"
                label="general.gender"
                [options]="genders"
                mode="single"
                info="{{ 'general.gender.info' | translate }}"
                infoPosition="right"
                breakpoint="sm"></mpc-input-checkbox-list>
            <mpc-input-checkbox-list
                *hasFeatureSetting="[
                    {
                        Feature: Features.Myopie,
                        Category: FeatureCategories.Optician,
                        IsEnabled: true
                    }
                ]"
                formControlName="myopia"
                id="myopia"
                label="general.myopia"
                [options]="myopiaOptions"
                mode="single"></mpc-input-checkbox-list>

            <div
                *hasFeatureSetting="[
                    {
                        Feature: Features.ExportByCustomerErpReference,
                        Category: FeatureCategories.Optician,
                        IsEnabled: true
                    }
                ]">
                <mpc-input-text
                    formControlName="erpreference"
                    [id]="'erpreference'"
                    label="client.erpreference"
                    placeholder="client.erpreference"
                    [maxLength]="20"></mpc-input-text>
                <div class="form-group row">
                    <label class="col-form-label col-md-4 my-auto" [ngClass]="addressValidationLabelClass">
                        {{ 'general.address' | translate }}
                    </label>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-9 pe-sm-1">
                                <mpc-input-text
                                    formControlName="street"
                                    [id]="'street'"
                                    label="general.street"
                                    placeholder="general.street"
                                    [formControlOnly]="true"
                                    [maxLength]="100"></mpc-input-text>
                            </div>
                            <div class="col-sm-3 ps-sm-0 pt-1 pt-sm-0">
                                <mpc-input-text
                                    formControlName="housenumber"
                                    [id]="'housenumber'"
                                    label="general.housenumber"
                                    placeholder="general.housenumber"
                                    [formControlOnly]="true"
                                    [maxLength]="20"></mpc-input-text>
                            </div>
                        </div>
                    </div>
                </div>
                <mpc-input-text
                    formControlName="phonenumber"
                    [id]="'phonenumber'"
                    label="general.phoneNumber"
                    placeholder="general.phoneNumber"
                    [maxLength]="20"></mpc-input-text>
            </div>
        </div>
    </div>
</div>
