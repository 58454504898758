import { InputNumberRange } from './input-number-range.model';

export interface LensDefinitionParameterRange {
    readonly $type: 'number-range' | 'text';
    Id: number;
    LensDefinitionParameterId: number;
    Conditions: LensDefinitionParameterRangeCondition[];
}

export class LensDefinitionParameterNumberRange implements LensDefinitionParameterRange, InputNumberRange {
    public readonly $type = 'number-range';

    constructor(minimum: number, maximum: number, step: number, start = 0) {
        this.Minimum = minimum;
        this.Maximum = maximum;
        this.Step = step;
        this.Start = start;
    }

    public Id: number;
    public LensDefinitionParameterId: number;
    public Minimum: number;
    public Maximum: number;
    public Step: number;
    public Start: number;
    public Conditions: LensDefinitionParameterRangeCondition[];
}

export class LensDefinitionParameterText implements LensDefinitionParameterRange {
    public readonly $type = 'text';

    public Id: number;
    public LensDefinitionParameterId: number;
    public Code: string;
    public Name: string;
    public Conditions: LensDefinitionParameterRangeCondition[];
}

export interface LensDefinitionParameterRangeCondition {
    Id: number;
    LensDefinitionParameterRangeId: number;
    Expression: string;
}
