import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HeightMapImageRequest } from '@app/shared/models/heightMapImageRequest.model';
import { HeightMapImageResponse } from '@app/shared/models/heightMapImageResponse.model';

@Injectable({
    providedIn: 'root',
})
export class HeigthMapService {
    private readonly basePath = 'api/height-maps';

    constructor(private readonly httpClient: HttpClient) {}

    public getHeightMapImage(heightMapImageRequest: HeightMapImageRequest): Observable<HeightMapImageResponse> {
        return this.httpClient.post<HeightMapImageResponse>(this.basePath, heightMapImageRequest);
    }
}
