import { LensDefinition } from './lens-definition.model';
import { FittedLensParameter } from './fitted-lens-parameter.model';
import { TopographicMeasurement } from './topographicMeasurement.model';
import { RefractionMeasurement } from './refractionmeasurement.model';
import { MaterialColor } from './materialColor.model';
import { LensEngraving } from './lensEngraving.model';

export class FittedLens {
    Id: number;
    OpticianId: number;
    ClientId: number;
    FittedLensTypeId: number;
    TopographicMeasurementId: number;
    RefractionMeasurementId: number;
    EyeSideId: number;
    LensDefinitionId: number;
    MaterialColorId: number;
    FitDate: Date;
    LensEngravingId: number;
    DreamLiteClearance: number;
    DreamLiteClearanceAst: number;
    LensThicknessId: number;
    FittedLensParameters: FittedLensParameter[];
    ConceptOfFittedLensId: number;
    IsManualCalculation: boolean;

    // readonly
    LensDefinition: LensDefinition;
    MaterialColor: MaterialColor;
    LensEngraving: LensEngraving;
    TopographicMeasurement: TopographicMeasurement;
    RefractionMeasurement: RefractionMeasurement;

    // temp prop
    ResidualRefractionSphere: number;
    ResidualRefractionCylinder: number;
    ResidualRefractionAx: number;

    ManualCorrectionSphere: number;
    ManualCorrectionCyl: number;
    ManualCorrectionAx: number;

    IsOptimalLensDefinition: boolean;
    DisposableLensCalculated: boolean;

    IsCreatedOn: Date;
    IsCreatedByUserName: string;
}
