import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Post } from '@app/shared/models/post.model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { WordPressItem } from '@app/shared/models/wordPress.model';

@Injectable()
export class WordpressApiService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly appConfig: AppConfigService,
        private readonly appState: AppStateService,
    ) {}

    getNewsItems(): Observable<Post[]> {
        if (!this.appConfig.wordpressApiEndpoint) {
            return of([] as Post[]);
        }

        return this.httpClient
            .get(this.appConfig.wordpressApiEndpoint + 'posts?per_page=3' + this.wordpressTagFilter('news'))
            .pipe(
                switchMap((result: WordPressItem[]) => {
                    const posts = result.map((newsItem: WordPressItem) => {
                        const post = new Post();
                        post.id = newsItem.id;
                        post.title = newsItem.title.rendered;
                        post.excerpt = newsItem.excerpt.rendered;
                        post.categories = newsItem.categories;
                        post.content = newsItem.content.rendered;
                        return post;
                    });

                    return of(posts);
                }),
            );
    }

    getCalendarItems(): Observable<Post[]> {
        if (!this.appConfig.wordpressApiEndpoint) {
            return of([] as Post[]);
        }

        return this.httpClient
            .get(this.appConfig.wordpressApiEndpoint + 'posts?per_page=3' + this.wordpressTagFilter('calendar'))
            .pipe(
                switchMap((result: WordPressItem[]) => {
                    const posts = result.map((calendarItem: WordPressItem) => {
                        const post = new Post();
                        post.id = calendarItem.id;
                        post.title = calendarItem.title.rendered;
                        post.excerpt = calendarItem.excerpt.rendered;
                        post.categories = calendarItem.categories;
                        post.content = calendarItem.content.rendered;
                        return post;
                    });

                    return of(posts);
                }),
            );
    }

    getScreensaverItems(): Observable<Post[]> {
        if (!this.appConfig.wordpressApiEndpoint) {
            return of([] as Post[]);
        }

        return this.httpClient
            .get(this.appConfig.wordpressApiEndpoint + 'posts?per_page=5' + this.wordpressTagFilter('screensaver'))
            .pipe(
                switchMap((result: WordPressItem[]) => {
                    const posts = result.map((screenSaverItem: WordPressItem) => {
                        const post = new Post();
                        post.id = screenSaverItem.id;
                        post.title = screenSaverItem.title.rendered;
                        post.excerpt = screenSaverItem.excerpt.rendered;
                        post.categories = screenSaverItem.categories;
                        if (screenSaverItem.better_featured_image != null) {
                            post.featuredImage = screenSaverItem.better_featured_image.source_url;
                        } else if (
                            screenSaverItem.featured_media_src_url &&
                            screenSaverItem.featured_media_src_url !== ''
                        ) {
                            post.featuredImage = screenSaverItem.featured_media_src_url;
                        }
                        return post;
                    });

                    return of(posts);
                }),
            );
    }

    getPost(id: number): Observable<Post> {
        return this.httpClient.get(this.appConfig.wordpressApiEndpoint + 'posts/' + id).pipe(
            switchMap((result: WordPressItem) => {
                const post = new Post();
                post.id = result.id;
                post.title = result.title.rendered;
                post.content = result.content.rendered;
                return of(post);
            }),
        );
    }

    getReleaseNotes(): Observable<Post[]> {
        return this.httpClient
            .get(
                this.appConfig.wordpressApiEndpoint +
                    'posts?per_page=100' +
                    this.wordpressTagFilter('releasenotes', false),
            )
            .pipe(
                switchMap((result: WordPressItem[]) => {
                    const posts = result.map((releaseNoteItem) => {
                        const post = new Post();
                        post.id = releaseNoteItem.id;
                        post.title = releaseNoteItem.title.rendered;
                        post.excerpt = releaseNoteItem.content.rendered;
                        post.categories = releaseNoteItem.categories;
                        return post;
                    });

                    return of(posts);
                }),
            );
    }

    wordpressTagFilter(requestType: string, addDistributorTag = true): string {
        const language = this.appState.authenticatedUser.CurrentLanguageCode.split('-')[0];

        let tag: string;
        if (!addDistributorTag || !this.appState.currentDistributor) {
            tag = requestType + '_' + language;
        } else {
            tag = this.appState.currentDistributor.Id + '_' + requestType + '_' + language;
        }

        return '&filter[tag]=' + tag.toLowerCase();
    }
}
