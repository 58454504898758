<div class="container-fluid mt-4 px-3">
    <div class="row">
        <div class="col-md-12">
            <h1>{{ 'pagenotfound.title' | translate }}</h1>
            <p>{{ 'pagenotfound.content' | translate }}</p>
        </div>
    </div>
</div>

<div *footer>
    <a href="#" (click)="back($event)">
        <i class="fas fa-arrow-alt-circle-left me-1"></i>
        {{ 'general.back' | translate }}
    </a>
</div>
