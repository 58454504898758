import { Component, OnInit } from '@angular/core';
import { EmailService } from '@app/core/services/api/email.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { EmailModel } from '@app/shared/models';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-list-email',
    templateUrl: './list-email.component.html',
    styleUrls: ['./list-email.component.scss'],
})
export class ListEmailComponent implements OnInit {
    emailMessages: EmailModel[];

    constructor(
        private readonly loaderService: LoaderService,
        private readonly testService: EmailService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.loaderService.show();
        this.emailMessages = await lastValueFrom(this.testService.listEmails());
        this.loaderService.hide();
    }

    getLocalTime(dt: Date): Date {
        const utc = new Date(dt);
        return new Date(utc.getTime() + utc.getTimezoneOffset() * 60000);
    }

    showContents(msg: EmailModel) {
        const emailWindow = window.open('');
        emailWindow.document.body.innerHTML = msg.Body;
    }
}
