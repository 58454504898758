export class PopupButton {
    label: string;
    className: string;
    action: () => void;

    constructor(label: string, className: string, action?: () => void) {
        this.label = label;
        this.className = className;
        this.action = action;
    }
}
