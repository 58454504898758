import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'confirm-x',
    templateUrl: './confirm.component.html',
})
export class ConfirmComponent {
    public title: string;
    public message: string;

    private readonly promise: Promise<boolean>;
    resolve: (value?: boolean | PromiseLike<boolean>) => void;

    constructor(private bsModalRef: BsModalRef) {
        this.promise = new Promise<boolean>((resolve) => (this.resolve = resolve));
    }

    confirm(): void {
        this.resolve(true);
        this.bsModalRef.hide();
    }

    decline(): void {
        this.resolve(false);
        this.bsModalRef.hide();
    }

    get onClosePromise(): Promise<boolean> {
        return this.promise;
    }
}
