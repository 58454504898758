<div class="modal-body">
    {{ 'ps.workitemalreadyassigned' | translate }}
</div>

<div class="modal-footer text-center">
    <div class="btn-block">
        <button class="btn btn-default col-3" (click)="onBack()">
            {{ 'general.back' | translate }}
        </button>
        <button class="btn btn-default col-3" (click)="onContinue()">
            {{ 'ps.continue' | translate }}
        </button>
        <button class="btn btn-default" (click)="onContinueOnMyName()">
            {{ 'ps.continueonmyname' | translate }}
        </button>
    </div>
</div>
