import { Injectable } from '@angular/core';
import { Cart } from '@app/shared/models';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { CartService } from './api/cart.service';

@Injectable()
export class CartStorageService {
    public cart$: BehaviorSubject<Cart>;

    constructor(private cartService: CartService) {
        this.cart$ = new BehaviorSubject<Cart>({ Items: [] } as Cart);
    }

    public saveShoppingCart(cart: Cart): void {
        this.cart$.next(cart);
    }

    public async loadFromServer(): Promise<Cart> {
        const cart = await lastValueFrom(this.cartService.getCart());
        this.cart$.next(cart);
        return cart;
    }

    public clearShoppingCart(): void {
        this.cart$.next({ Items: [] } as Cart);
    }
}
