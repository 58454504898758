import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GlobalErrorHandler } from '@app/shared/helpers/global-error-handler.directive';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { NumberFormatPipe } from '@app/shared/pipes/number.format.pipe';
import { ConfirmComponent } from '@app/shared/components/confirm/confirm.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ToastrModule } from 'ngx-toastr';
import { LabelComponent } from '@app/shared/components/label/label.component';
import { ModelLabelComponent } from '@app/shared/components/label/model-label.component';
import { LoaderComponent } from '@app/shared/components/loader/loader.component';
import { PageNotFoundComponent } from '@app/core/components/page-not-found/page-not-found.component';
import { FooterDirective } from '@app/shared/directives/footer.directive';
import { StopPropagationDirective } from '@app/shared/directives/stop-propagation.directive';
import { ExcludeFromCustomTemplateIdentifierFitlensDirective } from '@app/shared/directives/exclude-from-customtemplate-identifierfitlens.directive';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule, TimepickerComponent } from 'ngx-bootstrap/timepicker';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { CurrentRoleAuthGuard } from '@app/core/guards/current-role.auth.guard';
import { RoleAuthGuard } from '@app/core/guards/role.auth.guard';
import { UtilService } from '@app/shared/appservices/util.service';
import { AlertService } from '@app/shared/appservices/alert.service';
import { FooterService } from '@app/shared/appservices/footer.service';
import { ConfirmService } from '@app/shared/appservices/confirm.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { ProductDataSheetsComponent } from '@app/product/product-data-sheets.component';
import { TopoImageThumbnailComponent } from '@app/measurement/topo-image-thumbnail/topo-image-thumbnail.component';
import { FieldValueExpressionCalculator } from '@app/fitlens/calculators/field-value-expression.calculator';
import { RgpFittedLensCalculator } from '@app/fitlens/calculators/rgp-fitted-lens.calculator';
import { SoftFittedLensCalculator } from '@app/fitlens/calculators/soft-fitted-lens.calculator';
import { MedFittedLensCalculator } from '@app/fitlens/calculators/med-fitted-lens.calculator';
import { FittingEventService } from '@app/core/services/fittingEvent.service';
import { SupportEventService } from '@app/core/services/supportEvent.service';
import { ProductService } from '@app/core/services/api/product.service';
import { AccessRightsAuthGuard } from '@app/core/guards/access-rights.auth.guard';
import { OverRefractionComponent } from '@app/fitlens/components/over-refraction.component';
import { MeasurementImageComponent } from '@app/measurement/measurement-image.component';
import { MeasurementImageOptionsComponent } from '@app/measurement/measurement-image-options.component';
import { MeasurementItemComponent } from '@app/measurement/measurement-item.component';
import { FileUploadComponent } from '@app/shared/components/fileupload/fileupload.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { ClientMaintenanceComponent } from '@app/client/client-maintenance.component';
import { ClientDetailsComponent } from '@app/client/client-details.component';
import { MyopiaPowerComponent } from '@app/client/myopia-power.component';
import { MyopiaAxisLengthComponent } from '@app/client/myopia-axis-length.component';
import { ClientNotesComponent } from '@app/client/client-notes.component';
import { InternalNotesComponent } from '@app/client/internal-notes.component';
import { MeasurementHistoryComponent } from '@app/measurement/measurement-history/measurement-history.component';
import { MeasurementHistoryItemComponent } from '@app/measurement/measurement-history-item/measurement-history-item.component';
import { NavbarComponent } from '@app/shared/components/navbar/navbar.component';
import { NavbarItemComponent } from '@app/shared/components/navbar/navbar-item.component';
import { ApplicationInsightsService } from '@app/core/services/external/application-insights.service';
import { LineChartComponent } from '@app/shared/components/charts/LineChart/line-chart.component';
import { PagingComponent } from '@app/shared/components/paging/paging.component';
import { ComplaintsOverviewTableComponent } from '@app/shared/components/complaints-overview-table/complaints-overview-table.component';
import { RouterModule } from '@angular/router';
import { MpcModalModule } from '@app/shared/components/modal/modal.module';
import { StatusChangeComponent } from '@app/shared/components/complaints-overview-table/status-change/status-change.component';
import { QaChangeComponent } from '@app/shared/components/complaints-overview-table/qa-change/qa-change.component';
import { PsChangeComponent } from '@app/shared/components/complaints-overview-table/ps-change/ps-change.component';
import { ReturnHistoryComponent } from '@app/shared/components/complaints-overview-table/return-history/return-history.component';
import { PasswordExpiryComponent } from '@app/shared/components/password-expiry/password-expiry.component';
import { FeatureFlagAuthGuard } from '@app/core/guards/feature-flag.auth.guard';
import { RegionSelectedGuard } from '@app/core/guards/region-selected.guard';
import { ClientCommunicationComponent } from '@app/client/client-communication/client-communication.component';
import { HasFeatureSettingDirective } from './directives/has-feature-setting.directive';
import {
    InputCheckboxComponent,
    InputCheckboxListComponent,
    InputDateComponent,
    InputNumberComponent,
    InputRadioListComponent,
    InputSliderComponent,
    InputSliderListComponent,
    InputTextAreaComponent,
    InputTextComponent,
    InputTextLanguageComponent,
    InputTimeComponent,
    InputTypeAheadComponent,
    SelectComponent,
} from './components/inputs';
import { QaProcessComplaintComponent } from './components/qa-process-complaint/qa-process-complaint.component';
import { ShowMenuItemDirective } from './directives/show-menu-item.directive';
import { FeatureCheckerService } from './appservices/feature-checker.service';
import { DateService } from './appservices/date.service';
import { DynamicsDistributorAccessGuard } from '@app/core/guards';
import { EnvironmentComponent } from '@app/shared/components/environment/environment.component';
import { OrderLinesComponent } from '@app/order/order-lines/order-lines.component';

@NgModule({
    declarations: [
        FooterDirective,
        StopPropagationDirective,
        ExcludeFromCustomTemplateIdentifierFitlensDirective,
        HasFeatureSettingDirective,
        ShowMenuItemDirective,
        ConfirmComponent,
        LoaderComponent,
        PageNotFoundComponent,
        InputCheckboxComponent,
        InputSliderComponent,
        InputNumberComponent,
        InputTextComponent,
        InputTextAreaComponent,
        InputTextLanguageComponent,
        InputTypeAheadComponent,
        InputTimeComponent,
        LabelComponent,
        ModelLabelComponent,
        SelectComponent,
        InputDateComponent,
        InputRadioListComponent,
        InputCheckboxListComponent,
        InputSliderListComponent,
        ProductDataSheetsComponent,
        TopoImageThumbnailComponent,
        OverRefractionComponent,
        MeasurementImageComponent,
        MeasurementImageOptionsComponent,
        MeasurementItemComponent,
        ClientDetailsComponent,
        FileUploadComponent,
        ClientNotesComponent,
        InternalNotesComponent,
        MeasurementHistoryComponent,
        MeasurementHistoryItemComponent,
        ClientMaintenanceComponent,
        MyopiaPowerComponent,
        MyopiaAxisLengthComponent,
        LineChartComponent,
        ClientCommunicationComponent,
        NavbarComponent,
        NavbarItemComponent,
        PagingComponent,
        ComplaintsOverviewTableComponent,
        StatusChangeComponent,
        ReturnHistoryComponent,
        QaChangeComponent,
        PsChangeComponent,
        PasswordExpiryComponent,
        QaProcessComplaintComponent,
        EnvironmentComponent,
        OrderLinesComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ModalModule.forRoot(),
        PopoverModule.forRoot(),
        TabsModule.forRoot(),
        ProgressbarModule.forRoot(),
        CollapseModule.forRoot(),
        ReactiveFormsModule,
        TranslateModule,
        ToastrModule.forRoot({
            onActivateTick: true,
            timeOut: 5000,
            closeButton: true,
            preventDuplicates: true,
            positionClass: 'toast-bottom-right',
        }),
        PipesModule,
        NgxUploaderModule,
        TimepickerModule.forRoot(),
        TypeaheadModule.forRoot(),
        RouterModule,
        MpcModalModule,
    ],
    exports: [
        PipesModule,
        CommonModule,
        ModalModule,
        MyopiaPowerComponent,
        MyopiaAxisLengthComponent,
        LineChartComponent,
        PopoverModule,
        CollapseModule,
        TabsModule,
        FormsModule,
        TranslateModule,
        ReactiveFormsModule,
        FooterDirective,
        StopPropagationDirective,
        ExcludeFromCustomTemplateIdentifierFitlensDirective,
        HasFeatureSettingDirective,
        ShowMenuItemDirective,
        LoaderComponent,
        PageNotFoundComponent,
        InputCheckboxComponent,
        InputSliderComponent,
        InputNumberComponent,
        InputTextComponent,
        InputTextAreaComponent,
        InputTextLanguageComponent,
        InputTypeAheadComponent,
        InputTimeComponent,
        LabelComponent,
        ModelLabelComponent,
        SelectComponent,
        InputDateComponent,
        InputRadioListComponent,
        InputCheckboxListComponent,
        InputSliderListComponent,
        TimepickerComponent,
        ProductDataSheetsComponent,
        OverRefractionComponent,
        TopoImageThumbnailComponent,
        MeasurementImageComponent,
        MeasurementImageOptionsComponent,
        MeasurementItemComponent,
        FileUploadComponent,
        ClientMaintenanceComponent,
        ClientDetailsComponent,
        ClientNotesComponent,
        InternalNotesComponent,
        MeasurementHistoryComponent,
        MeasurementHistoryItemComponent,
        ClientCommunicationComponent,
        NavbarComponent,
        NavbarItemComponent,
        PagingComponent,
        ComplaintsOverviewTableComponent,
        StatusChangeComponent,
        ReturnHistoryComponent,
        PasswordExpiryComponent,
        QaProcessComplaintComponent,
        EnvironmentComponent,
        OrderLinesComponent,
    ],
    providers: [NumberFormatPipe],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                TranslateService,
                {
                    provide: ErrorHandler,
                    useClass: GlobalErrorHandler,
                },
                CurrentRoleAuthGuard,
                FeatureFlagAuthGuard,
                RoleAuthGuard,
                AccessRightsAuthGuard,
                RegionSelectedGuard,
                DynamicsDistributorAccessGuard,
                UtilService,
                AlertService,
                DateService,
                FooterService,
                ConfirmService,
                FeatureCheckerService,
                LoaderService,
                FieldValueExpressionCalculator,
                RgpFittedLensCalculator,
                SoftFittedLensCalculator,
                MedFittedLensCalculator,
                FittingEventService,
                SupportEventService,
                ProductService,
                ApplicationInsightsService,
            ],
        };
    }
}
