import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { RegionService } from '@app/core/services/region.service';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';

@Injectable()
export class RegionSelectedGuard {
    constructor(
        private readonly router: Router,
        private appConfig: AppConfigService,
        private readonly regionService: RegionService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (!this.regionService.regionChosen()) {
            const region = route.queryParamMap.get('region');
            if (region && this.regionService.saveRegion(region)) {
                this.appConfig.setAppEndpoint(this.regionService.getRegion());
                return true;
            }

            this.redirectToRegionSelector();
            return false;
        }

        return true;
    }

    private redirectToRegionSelector(): void {
        this.router.navigate(['region']).then();
    }
}
