<div class="modal-body">
    <div class="title-border-bottom">
        <h4>{{ 'order.changeorderstatus' | translate }}</h4>
    </div>
    <div class="orderlinestatuses">
        <div [ngClass]="{ selected: currentOrderStatus === 1 }" (click)="setStatus(1)">
            <i class="fas fa-circle evaluation fg"></i>
            <span class="label ms-1 me-3">{{ 'orderlinestatus.evaluation' | translate }}</span>
        </div>
        <div [ngClass]="{ selected: currentOrderStatus === 2 }" (click)="setStatus(2)">
            <i class="fas fa-circle production fg"></i>
            <span class="label ms-1 me-3">{{ 'orderlinestatus.production' | translate }}</span>
        </div>
        <div [ngClass]="{ selected: currentOrderStatus === 3 }" (click)="setStatus(3)">
            <i class="fas fa-circle shipped fg"></i>
            <span class="label ms-1 me-3">{{ 'orderlinestatus.shipped' | translate }}</span>
        </div>
        <div [ngClass]="{ selected: currentOrderStatus === 6 }" (click)="setStatus(6)">
            <i class="fas fa-circle invoiced fg"></i>
            <span class="label ms-1 me-3">{{ 'orderlinestatus.invoiced' | translate }}</span>
        </div>
        <div [ngClass]="{ selected: currentOrderStatus === 4 }" (click)="setStatus(4)">
            <i class="fas fa-circle cancelled fg"></i>
            <span class="label ms-1 me-3">{{ 'orderlinestatus.cancelled' | translate }}</span>
        </div>
        <div
            *hasFeatureSetting="[
                {
                    Feature: Features.OpticianLensOrderWarranty,
                    Category: FeatureCategories.Company,
                    IsEnabled: false
                }
            ]"
            [ngClass]="{ selected: currentOrderStatus === 5 }"
            (click)="setStatus(5)">
            <i class="fas fa-circle credited fg"></i>
            <span class="label ms-1">{{ 'orderlinestatus.credited' | translate }}</span>
        </div>
    </div>
</div>
<div class="modal-footer accented-background d-flex">
    <button type="button" id="close" class="btn btn-default" (click)="close()">
        {{ 'general.close' | translate }}
    </button>
</div>
