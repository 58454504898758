import { Component, AfterViewInit } from '@angular/core';
import { LoaderService, LoaderMessage } from '@app/shared/appservices/loader.service';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'mpc-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.scss'],
})
export class LoaderComponent implements AfterViewInit {
    isLoading = false;
    message = '';

    constructor(private loaderService: LoaderService) {}

    ngAfterViewInit(): void {
        this.loaderService.status.pipe(debounceTime(1)).subscribe((result: LoaderMessage) => {
            this.isLoading = result.isLoading;
            this.message = result.message;
        });
    }
}
