<div class="modal-body rounded-top p-3">
    <h1>Import client</h1>

    <div class="row">
        <div class="col">
            <form [formGroup]="clientImportForm">
                <div class="row">
                    <div class="col">
                        <mpc-select
                            formControlName="id"
                            [id]="'id'"
                            [items]="opticians"
                            label="general.optician"
                            noSelectionLabel="userMaintenance.searchOptician"
                            focus></mpc-select>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <file-upload #fileUploader [showUploadButton]="true" (uploadButtonPressed)="uploadFiles($event)"></file-upload>
</div>

<div class="modal-footer accented-background d-flex">
    <div class="ms-auto d-flex">
        <div class="d-flex align-items-center">
            <button type="button" id="close" class="btn btn-default me-auto mt-auto" (click)="bsModalRef.hide()">
                {{ 'general.close' | translate }}
            </button>
        </div>
    </div>
</div>
