import { Component, OnInit } from '@angular/core';
import { CartService } from '@app/core/services/api/cart.service';
import { CartStorageService } from '@app/core/services/cart-storage.service';
import { CartItemLineInfo } from '@app/shared/models/cartItemLineInfo.model';
import { DeleteCartItemComponent } from '@app/features/cart/components';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UpdateCartItemQuantityRequest } from '@app/shared/models/updateCartItemQuantityRequest.model';
import { InputNumberRange } from '@app/shared/models/input-number-range.model';
import { Router } from '@angular/router';
import { generateColor } from './gradient-color';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';

@Component({
    templateUrl: './cart-overview.component.html',
})
export class CartOverviewComponent implements OnInit {
    formGroup: FormGroup;
    productItems: CartItemLineInfo[] = [];
    productColors: string[] = [];
    lensItems: CartItemLineInfo[] = [];
    lensColors: string[] = [];
    processingCart = false;
    loading = true;

    get hasCartItems(): boolean {
        if (this.productItems.length > 0 || this.lensItems.length > 0) {
            return true;
        }
        return false;
    }

    constructor(
        private readonly fb: FormBuilder,
        public cartService: CartService,
        public cartStorageService: CartStorageService,
        protected modalService: BsModalService,
        private router: Router,
    ) {}

    async ngOnInit() {
        this.formGroup = this.fb.group({
            reference: ['', Validators.maxLength(50)],
        });

        await this.fetchItems();
        await this.cartStorageService.loadFromServer();
    }

    async fetchItems() {
        this.loading = true;
        const items = await lastValueFrom(this.cartService.getCartItemLineInfo());

        items.forEach((c) => {
            c.QuantityRange = new InputNumberRange(1, c.MaxQuantity, 1, 1);
        });

        let rowColorIndex = 0;
        const lensItems = items.filter((c) => c.CartItemType !== 'Product');
        const gradientColors = generateColor(
            '#014380', // congress-blue
            '#f8f8f8', // alabaster-white
            [...new Set(lensItems.map((item) => item.CartItemId))].length + 1,
        );
        this.productItems = items
            .filter((c) => c.CartItemType === 'Product')
            .map((c) => {
                if (!!!this.lensColors[c.CartItemId]) {
                    this.productColors[c.CartItemId] = rowColorIndex % 2 === 0 ? 'even' : 'odd';
                    rowColorIndex++;
                }
                return c;
            });
        rowColorIndex = 0;
        this.lensItems = lensItems.map((c) => {
            if (!!!this.lensColors[c.CartItemId]) {
                this.lensColors[c.CartItemId] = gradientColors[rowColorIndex];
                rowColorIndex++;
            }
            return c;
        });

        this.loading = false;
    }

    orderNow(): void {
        const reference = this.formGroup.controls['reference'].value;

        if (!!reference) {
            this.cartService
                .setCartReference(reference)
                .pipe(finalize(() => this.router.navigate(['/cart/order'])))
                .subscribe();
        } else {
            this.router.navigate(['/cart/order']);
        }
    }

    deleteItem(item: CartItemLineInfo) {
        this.openDeleteItemDialog(item.CartItemId);
    }

    openDeleteItemDialog(itemId: number): void {
        this.modalService.show(DeleteCartItemComponent, {
            initialState: {
                deleteItem: () => this.removeItem(itemId),
            },
            class: 'modal-half',
        });
    }

    private async removeItem(itemId: number): Promise<void> {
        await lastValueFrom(this.cartService.removeCartItem(itemId));
        await this.cartStorageService.loadFromServer();
        await this.fetchItems();
    }

    async updateItemQuantity(updateRequest: UpdateCartItemQuantityRequest): Promise<void> {
        if (updateRequest.Quantity === 0) {
            this.openDeleteItemDialog(updateRequest.CartItemId);
        } else {
            await lastValueFrom(this.cartService.updateCartItemQuantity(updateRequest));
            await this.cartStorageService.loadFromServer();
        }
    }

    moreProducts(event: MouseEvent): void {
        event.preventDefault();
        this.router.navigate(['/shop']).then();
    }
}
