<h3 style="text-align: center">{{ 'orderoverview.lenses' | translate }}</h3>

<form [formGroup]="mainFormGroup">
    <ng-container>
        <div class="table-responsive">
            <table class="table mb-1 datatable">
                <thead>
                    <tr class="header">
                        <th>&nbsp;</th>
                        <th>
                            <a (click)="applySort($event, 'ClientName')">
                                {{ 'general.client' | translate }}
                                <div
                                    class="ms-1 sorticon"
                                    [ngClass]="'ClientName' | sortclass: currentSortColumn : currentSortOrder"
                                    aria-hidden="true"></div>
                            </a>
                        </th>
                        <th>
                            <a (click)="applySort($event, 'EyeSideCode')">
                                {{ 'general.eyesides' | translate }}
                                <div
                                    class="ms-1 sorticon"
                                    [ngClass]="'EyeSideCode' | sortclass: currentSortColumn : currentSortOrder"
                                    aria-hidden="true"></div>
                            </a>
                        </th>
                        <th>
                            <a (click)="applySort($event, 'ProductName')">
                                {{ 'general.product' | translate }}
                                <div
                                    class="ms-1 sorticon"
                                    [ngClass]="'ProductName' | sortclass: currentSortColumn : currentSortOrder"
                                    aria-hidden="true"></div>
                            </a>
                        </th>
                        <th>
                            <a (click)="applySort($event, 'MaterialAndColorName')">
                                {{ 'general.material' | translate }}
                                <div
                                    class="ms-1 sorticon"
                                    [ngClass]="'MaterialAndColorName' | sortclass: currentSortColumn : currentSortOrder"
                                    aria-hidden="true"></div>
                            </a>
                        </th>
                        <th>
                            <a (click)="applySort($event, 'Warranty')">
                                {{ 'general.warranty' | translate }}
                                <div
                                    class="ms-1 sorticon"
                                    [ngClass]="'Warranty' | sortclass: currentSortColumn : currentSortOrder"
                                    aria-hidden="true"></div>
                            </a>
                        </th>
                        <th>
                            <a (click)="applySort($event, 'Spare')">
                                {{ 'general.spare' | translate }}
                                <div
                                    class="ms-1 sorticon"
                                    [ngClass]="'Spare' | sortclass: currentSortColumn : currentSortOrder"
                                    aria-hidden="true"></div>
                            </a>
                        </th>

                        <ng-container *ngFor="let parameter of headerParameters">
                            <th>
                                <a href="#" (click)="applySort($event, 'FittedLens.Parameters.' + parameter.code)">
                                    {{ (parameter.code | parameterTranslate) ?? parameter.code }}
                                    <div class="ml-1 sorticon" aria-hidden="true"></div>
                                </a>
                            </th>
                        </ng-container>

                        <th class="quanity-column">{{ 'general.quantity' | translate }}</th>
                        <th class="edit-column">&nbsp;</th>
                        <th class="renmove-column">&nbsp;</th>
                    </tr>
                </thead>

                <tbody formArrayName="itemsFormArray">
                    <tr *ngFor="let item of items; index as i" [formGroupName]="i">
                        <td
                            class="first-column"
                            [ngStyle]="{ 'background-color': '#' + itemColors[items[i].CartItemId] }"></td>
                        <td>{{ item.ClientName }}</td>
                        <td>{{ item.EyeSideCode }}</td>
                        <td>{{ item.ProductName }}</td>
                        <td class="materialColumn">
                            {{ item.MaterialAndColorName }}
                        </td>
                        <td
                            *hasFeatureSetting="[
                                {
                                    Feature: Features.OpticianLensOrderWarranty,
                                    Category: FeatureCategories.Company,
                                    IsEnabled: true
                                }
                            ]"
                            class="text-center">
                            <i
                                *ngIf="item.Warranty"
                                triggers="mouseenter:mouseleave"
                                popoverClass="popover"
                                popover="{{ 'warranty.selected.yes' | translate }}"
                                class="far fa-check-circle"></i>
                        </td>
                        <td
                            *hasFeatureSetting="[
                                {
                                    Feature: Features.OrderingSpares,
                                    Category: FeatureCategories.Company,
                                    IsEnabled: true
                                }
                            ]"
                            class="text-center">
                            <i
                                *ngIf="item.IsSpareOrder || item.AddSpareOrder"
                                triggers="mouseenter:mouseleave"
                                popoverClass="popover"
                                popover="{{ 'general.spare' | translate }}"
                                class="far fa-clone"></i>
                        </td>

                        <ng-container *ngFor="let head of headerParameters">
                            <td id="{{ (head.code | lowercase) + '_' + i }}">
                                <ng-container *ngFor="let parameter of item.FittedLensParameters">
                                    <ng-container
                                        *ngIf="parameter.LensDefinitionParameter.ParameterType.Code === head.code">
                                        {{
                                            (parameter.LensDefinitionParameter.ParameterType
                                            | validateInputType: inputTypes.Number)
                                                ? (parameter.Value | parameterFormatter: head.code)
                                                : parameter.TextValue
                                        }}
                                    </ng-container>
                                </ng-container>
                            </td>
                        </ng-container>

                        <td>
                            <mpc-input-number
                                *ngIf="item.MaxQuantity > 1"
                                [formControlName]="'quantity'"
                                [id]="'quantity'"
                                [ranges]="[item.QuantityRange]"
                                [maxLength]="2"
                                [breakpoint]="''"
                                [formControlOnly]="true"></mpc-input-number>
                        </td>
                        <td>
                            <i
                                *ngIf="item.ProposalId && item.ProposalTypeCode !== 'reorder'"
                                class="fas fa-pencil-alt edit-lens"
                                (click)="edit(item)"
                                title="{{ 'cart.overview.edit_lens' | translate }}"></i>
                        </td>
                        <td>
                            <i
                                class="fas fa-trash-alt delete-lens"
                                (click)="deleteItem(item)"
                                title="{{ 'shoppingcart.remove' | translate }}"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</form>
