<div class="container-fluid mt-3 mb-4 px-lg-4 flex-fill">
    <div *ngIf="!loading">
        <div class="title-border-bottom mt-2">
            <h3>
                {{ 'general.servicerequest' | translate }}
                {{ activeServiceRequest.ReturnNumber }}
            </h3>
        </div>

        <div class="row title-border-bottom mb-1">
            <div class="col-12 col-sm-6">
                <div class="form-group row mt-2 mb-1">
                    <label class="col-4 font-weight-bold">{{ 'general.serialnumberlong' | translate }}</label>
                    <div class="col-8" id="serialnumber">
                        {{ activeServiceRequest.SerialNumber }}
                    </div>
                </div>
                <div class="form-group row mt-1 mb-1">
                    <label class="col-4 font-weight-bold">{{ 'general.product' | translate }}</label>
                    <div class="col-8">
                        {{ activeServiceRequest.ProductName }}
                    </div>
                </div>
                <div class="form-group row mt-1 mb-1">
                    <label class="col-4 font-weight-bold">{{ 'general.material' | translate }}</label>
                    <div class="col-8">
                        {{ activeServiceRequest.MaterialName }}
                    </div>
                </div>
                <div class="form-group row mt-1 mb-1">
                    <label class="col-4 font-weight-bold">{{ 'general.eyesides' | translate }}</label>
                    <div class="col-8">
                        {{ activeServiceRequest.EyeSideName }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group row mt-2 mb-1">
                    <label class="col-4 font-weight-bold">{{ 'general.optician' | translate }}</label>
                    <div class="col-8">
                        {{ activeServiceRequest.OpticianName }}
                    </div>
                </div>
                <div class="form-group row mt-2 mb-1">
                    <label class="col-4 font-weight-bold">{{ 'general.orderShippingDate' | translate }}</label>
                    <div class="col-8">
                        {{ orderShippedDate }}
                    </div>
                </div>
                <div class="form-group row mt-1 mb-1" *ngIf="activeServiceRequest.ClientReference">
                    <label class="col-4 font-weight-bold">{{ 'general.yourreference' | translate }}</label>
                    <div class="col-8">
                        {{ activeServiceRequest.ClientReference }}
                    </div>
                </div>
                <div class="form-group row mt-1 mb-1" *ngIf="activeServiceRequest.ClientReference2">
                    <label class="col-4 font-weight-bold">{{ 'general.reference2' | translate }}</label>
                    <div class="col-8">
                        {{ activeServiceRequest.ClientReference2 }}
                    </div>
                </div>
            </div>
        </div>

        <form [formGroup]="serviceRequestFormGroup">
            <div class="row title-border-bottom">
                <div class="col-12 col-sm-6">
                    <div class="form-group row mt-1 mb-1">
                        <label class="col-4 font-weight-bold">{{ 'service.lensservice' | translate }}</label>
                        <div class="col-4">
                            <mpc-select
                                formControlName="returnCategory"
                                [id]="'returnCategories'"
                                [formControlOnly]="true"
                                [items]="returnCategories"></mpc-select>
                        </div>
                    </div>
                    <div class="form-group row mt-1 mb-1" *ngIf="filteredReturnTypes$ | async; let filteredTypes">
                        <label class="col-4 font-weight-bold">{{ 'general.type' | translate }}</label>
                        <div class="col-4">
                            <mpc-select
                                formControlName="returnType"
                                [id]="'returnTypes'"
                                [formControlOnly]="true"
                                [items]="filteredTypes"></mpc-select>
                        </div>
                    </div>
                    <div class="form-group row mt-1 mb-1">
                        <label class="col-4 font-weight-bold">{{ 'general.description' | translate }}</label>
                        <div class="col-8">
                            {{ activeServiceRequest.Description }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row title-border-bottom mb-1">
                <div class="col-12 mt-3">
                    <div
                        id="toggleQualityControlMeasurement"
                        class="d-flex"
                        (click)="toggleQualityControlMeasurement()">
                        <h3 class="pb-0">
                            {{ 'service.qualityControlMeasurementsTitle' | translate }}
                        </h3>
                        <div>
                            <i
                                *ngIf="!isQualityControlMeasurementExpanded"
                                class="fas fa-caret-down fa-2x fa-collapse-icon caret"></i>
                            <i
                                *ngIf="isQualityControlMeasurementExpanded"
                                class="fas fa-caret-up fa-2x fa-collapse-icon caret"></i>
                        </div>
                    </div>
                    <div *ngIf="isQualityControlMeasurementExpanded">
                        <qa-process-complaint
                            #qcmeasurement
                            [selectedReturn]="activeServiceRequest"
                            [inputReturnId]="returnId"
                            [useLimitedInfoView]="true"></qa-process-complaint>
                    </div>
                </div>
            </div>
            <div class="row title-border-bottom mb-1">
                <div class="col-12">
                    <div class="form-group row mt-1 mb-1">
                        <label class="col-2 font-weight-bold">{{ 'complaint.internalFinding' | translate }}</label>
                        <div class="col-6">
                            <mpc-input-textarea
                                formControlName="internalFinding"
                                [id]="'internalFinding'"
                                [formControlOnly]="true"
                                [rows]="4"
                                style="width: 100%"></mpc-input-textarea>
                        </div>
                    </div>
                    <div class="form-group row mt-1 mb-1">
                        <label class="col-2 font-weight-bold">{{ 'service.conclusion' | translate }}</label>
                        <div class="col-6">
                            <mpc-input-textarea
                                formControlName="conclusion"
                                [id]="'conclusion'"
                                [formControlOnly]="true"
                                [maxLength]="400"
                                [rows]="4"
                                style="width: 100%"></mpc-input-textarea>
                        </div>
                        <label class="col-form-label col-1">{{ 'service.vigilance' | translate }}</label>
                        <div class="col-3 mt-1">
                            <mpc-input-slider
                                formControlName="vigilance"
                                [id]="'vigilance'"
                                [formControlOnly]="true"></mpc-input-slider>
                        </div>
                    </div>
                    <div class="form-group row mt-1 mb-1">
                        <label class="col-2 font-weight-bold">{{ 'service.result' | translate }}</label>
                        <div class="col-2">
                            <mpc-select
                                formControlName="returnResult"
                                [id]="'returnResults'"
                                [formControlOnly]="true"
                                [items]="returnResults"></mpc-select>
                        </div>
                    </div>
                    <div class="form-group row mt-1 mb-1">
                        <label class="col-2 font-weight-bold">{{ 'complaint.status' | translate }}</label>
                        <div class="col-2">
                            <mpc-select
                                formControlName="returnStatus"
                                [id]="'returnStatuses'"
                                [formControlOnly]="true"
                                [items]="returnStatuses"></mpc-select>
                        </div>
                    </div>
                    <div class="form-group row mt-1 mb-2">
                        <label class="col-2 font-weight-bold">{{ 'service.crediting' | translate }}</label>
                        <div class="col-2">
                            <mpc-select
                                formControlName="returnCredit"
                                [id]="'returnCredits'"
                                [formControlOnly]="true"
                                [items]="returnCredits"></mpc-select>
                        </div>
                    </div>
                    <div class="form-group row mt-1 mb-1">
                        <div class="col-md-6">
                            <mpc-select
                                formControlName="dynamicsDisposition"
                                [id]="'dynamicsDisposition'"
                                [items]="dynamicsDispositions"
                                label="service.dynamics.disposition"></mpc-select>
                        </div>
                    </div>
                    <div class="form-group row mt-1 mb-1">
                        <div class="col-md-6">
                            <mpc-select
                                formControlName="dynamicsReturnReason"
                                [id]="'dynamicsReturnReason'"
                                [items]="dynamicsReturnReasons"
                                label="service.dynamics.reason"></mpc-select>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="row mt-1">
            <div class="col-12 col-sm-6">
                <div class="form-group row mt-1 mb-1">
                    <label class="col-4 font-weight-bold">{{ 'service.inprogressdate' | translate }}</label>
                    <div class="col-8">
                        {{ activeServiceRequest.InProgressDate | mpcdate }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group row mt-1 mb-1">
                    <label class="col-4 font-weight-bold">{{ 'service.supporter' | translate }}</label>
                    <div class="col-8">
                        {{ activeServiceRequest.IsChangedByUserName }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *footer>
    <div class="d-flex w-100 footer">
        <div class="d-flex align-items-center">
            <a (click)="gotoOverview()" class="c-pointer">
                <i class="fas fa-arrow-alt-circle-left me-1"></i>
                {{ 'service.returntooverview' | translate }}
            </a>
        </div>

        <div class="ms-auto d-flex">
            <div class="ms-auto me-2 flex-row">
                <button
                    *ngIf="isNavigateToClientAvailable"
                    type="button"
                    class="btn btn-secondary mx-2"
                    id="navigateToClient"
                    (click)="navigateToClient()">
                    {{ 'general.client' | translate }}
                </button>

                <div class="btn-group dropup mx-2">
                    <button
                        type="button"
                        class="btn btn-primary"
                        id="saveServiceRequest"
                        [disabled]="!formChanged"
                        (click)="save(false)">
                        {{ 'general.save' | translate }}
                    </button>
                    <button
                        type="button"
                        id="menuToggle"
                        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"></button>

                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="menuToggle">
                        <button [disabled]="!formChanged" (click)="saveAndDownloadPdf()" class="dropdown-item">
                            {{ 'general.save' | translate }} & {{ 'general.downloadpdf' | translate }}
                        </button>
                        <button [disabled]="formChanged" (click)="downloadPdf()" class="dropdown-item">
                            {{ 'general.downloadpdf' | translate }}
                        </button>
                        <div class="dropdown-divider"></div>
                        <button [disabled]="!formChanged" (click)="reset()" class="dropdown-item">
                            {{ 'general.reset' | translate }}
                        </button>
                    </div>
                </div>

                <button
                    type="button"
                    class="btn btn-primary mx-1"
                    id="closeServiceRequest"
                    [disabled]="!canCloseServiceRequest"
                    (click)="save(true)">
                    {{ 'service.closeServiceRequest' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
