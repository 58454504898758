<div class="d-flex flex-column">
    <div class="ms-md-auto mb-1">
        <div class="d-inline-block">
            <i class="fas fa-circle receipt-notordered fg"></i>
            <span class="label ms-1 me-3">{{ 'receiptstatus.notordered' | translate }}</span>
        </div>
        <div class="d-inline-block">
            <i class="fas fa-circle receipt-ordered fg"></i>
            <span class="label ms-1 me-3">{{ 'receiptstatus.ordered' | translate }}</span>
        </div>
    </div>

    <div>
        <div class="table-responsive">
            <table class="table mb-1 datatable" *ngIf="receiptLines && receiptLines.length > 0">
                <thead>
                    <tr class="datatableheader">
                        <th class="orderlinestatus">&nbsp;</th>
                        <th>{{ 'general.product' | translate }}</th>
                        <th>{{ 'general.material' | translate }}</th>
                        <th>{{ 'general.eyesides' | translate }}</th>
                        <ng-container *ngFor="let parameter of headerParameterCodes">
                            <th>{{ (parameter | parameterTranslate) ?? parameter }}</th>
                        </ng-container>
                        <th>{{ 'general.quantity' | translate }}</th>
                        <th *ngIf="appState.isExpertModeEnabled">
                            {{ 'general.abbreviations.FIX' | translate }}
                        </th>
                        <th *ngIf="hasMarking">
                            {{ 'general.abbreviations.MRK' | translate }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="receiptlinerow_{{ i }}" class="orderlinerow" *ngFor="let item of receiptLines; index as i">
                        <td class="orderlinestatus bg" [ngClass]="getReceiptLineStatusClass()">&nbsp;</td>
                        <td id="productname_{{ i }}">
                            {{ item.ProductName }}
                        </td>
                        <td>{{ item.MaterialAndColorName }}</td>
                        <td>{{ item.EyeSideCode }}</td>

                        <ng-container *ngFor="let code of headerParameterCodes">
                            <td id="{{ (code | lowercase) + '_' + i }}">
                                <ng-container *ngFor="let parameter of item.FittedLensParameters">
                                    <ng-container *ngIf="parameter.Code === code">
                                        {{ parameter.Value | parameterFormatter: code }}
                                    </ng-container>
                                </ng-container>
                            </td>
                        </ng-container>

                        <td id="quantity_{{ i }}">{{ item.Quantity }}</td>
                        <td *ngIf="appState.isExpertModeEnabled">
                            {{ item.FitTypeName }}
                        </td>
                        <td *ngIf="hasMarking">
                            {{ item.LensEngravingName }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
