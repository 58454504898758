import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { ReturnAndReturnConclusion } from '@app/shared/models/returnAndReturnConclusion.model';
import { Util } from '@app/shared/helpers/utility.helper';
import { ReturnService } from '@app/core/services/api/return.service';
import { lastValueFrom } from 'rxjs';

@Component({
    templateUrl: 'service-dialog.component.html',
    styleUrls: ['service-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ServiceDialogComponent implements OnInit {
    @Input() serviceId: number;
    serviceData: ReturnAndReturnConclusion;

    loading = true;
    serialNumberAlreadyReturned = false;

    constructor(
        public bsModalRef: BsModalRef,
        private loaderService: LoaderService,
        private returnService: ReturnService,
    ) {}

    async ngOnInit() {
        if (!this.serviceId) {
            this.bsModalRef.hide();
        }

        this.serviceData = await lastValueFrom(this.returnService.getReturnAndReturnConclusion(this.serviceId));
        const existingReturnId = await lastValueFrom(
            this.returnService.findReturnIdBySerialNumberBeforeDate(
                this.serviceData.Return.SerialNumber,
                this.serviceData.Return.ReturnDate,
            ),
        );
        this.serialNumberAlreadyReturned = existingReturnId && this.serviceData.Return.Id !== existingReturnId;
        this.loading = false;
    }

    downloadPdf() {
        this.loaderService.show();
        this.returnService.downloadPdf(this.serviceData.Return.Id).subscribe((file) => {
            Util.openBlobInBrowser(file, file.name);
        });
        this.loaderService.hide();
    }
}
