import { LensDefinitionGeometryElement } from '@app/shared/models/lensDefinitionGeometryElement.model';

export class LensDefinitionGeometrySphere extends LensDefinitionGeometryElement {
    constructor() {
        super();
        this.$type = 'LensDefinitionGeometrySphere';
        this.InstanceType = 'Sphere';
    }

    public SphereRadius: string;
    public SphereAstigmaticRadius: string;
    public SphereSemiDiameterStart: string;
    public SphereSemiDiameterEnd: string;

    // CalculationResults
    public SphereRadiusResult: number;
    public SphereAstigmaticRadiusResult: number;
    public SphereSemiDiameterStartResult: number;
    public SphereSemiDiameterEndResult: number;
}
