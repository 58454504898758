import { DestroyRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { Roles } from '@app/shared/enums';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

// TODO: Create better method to show/hide menu items
@Directive({
    selector: '[showMenuItem]',
})
export class ShowMenuItemDirective implements OnInit {
    @Input('showMenuItem') menuItemName: string;

    public roles = Roles;
    public currentRoleId: number;
    public isNewTranslationFeatureEnabled: boolean;

    private readonly destroyRef = inject(DestroyRef);

    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
        private appStateService: AppStateService,
    ) {}

    public ngOnInit() {
        this.isNewTranslationFeatureEnabled = this.appStateService.isNewTranslationFeatureEnabled();

        this.appStateService.authenticatedUser$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user) => {
            this.currentRoleId = user?.CurrentRoleId;
            this.updateView();
        });
    }

    private updateView() {
        if (this.showMenuItem()) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

    private showMenuItem(): boolean {
        switch (this.menuItemName) {
            case 'MenuLensConfigurationAdminDivider':
            case 'MenuLenses':
            case 'MenuLensParameters':
                return this.currentRoleId === this.roles.LensConfigurationAdmin;
            case 'MenuProducts':
            case 'MenuProductGroups':
            case 'MenuDatasheets':
                return (
                    this.currentRoleId === this.roles.Administrator ||
                    this.currentRoleId === this.roles.LensConfigurationAdmin ||
                    this.currentRoleId === this.roles.Marketing
                );
            case 'MenuAdministratorDistributorAdminDivider':
            case 'MenuOpticians':
            case 'MenuUsers':
                return (
                    this.currentRoleId === this.roles.Administrator ||
                    this.currentRoleId === this.roles.DistributorAdmin
                );
            case 'MenuLanguages':
                return this.currentRoleId === this.roles.Administrator && !this.isNewTranslationFeatureEnabled;
            case 'MenuLanguagesNew':
            case 'MenuTranslation':
            case 'MenuTranslationCategories':
                return this.currentRoleId === this.roles.Administrator && this.isNewTranslationFeatureEnabled;
            case 'MenuAdministratorDivider':
            case 'MenuDistributors':
            case 'MenuManufacturers':
            case 'MenuCompanies':
            case 'MenuDistributorCustomizations':
            case 'MenuCreditSettings':
            case 'MenuQualityAssurance':
            case 'MenuImport':
            case 'MaintenanceJobs':
                return this.currentRoleId === this.roles.Administrator;
            default:
                return false;
        }
    }
}
