<div class="col-12 h-100 flex-box">
    <div class="p-2 row">
        <div class="col-4 mt-3" *ngIf="client">
            <h3>{{ 'general.client' | translate }}</h3>
            <table>
                <tr>
                    <td class="td-icon"><i class="fas fa-user"></i></td>
                    <td>{{ client.Reference }}, {{ client.Reference2 }}</td>
                </tr>
                <tr>
                    <td class="td-icon"><i class="fas fa-user"></i></td>
                    <td>{{ client.Reference }}</td>
                </tr>
                <tr *ngIf="client.GenderId === 2">
                    <td class="td-icon"><i class="fas fa-female"></i></td>
                    <td>{{ 'general.female' | translate }}</td>
                </tr>
                <tr *ngIf="client.GenderId !== 2">
                    <td class="td-icon"><i class="fas fa-male"></i></td>
                    <td>{{ 'general.male' | translate }}</td>
                </tr>
                <tr>
                    <td class="td-icon">
                        <i class="fas fa-baby-carriage"></i>
                    </td>
                    <td>{{ client.BirthDate | date }}</td>
                </tr>
            </table>
        </div>

        <div class="col-4 mt-3" *ngIf="optician">
            <optician-info [optician]="optician"></optician-info>
        </div>
        <div class="col-4 mt-3" *ngIf="order">
            <div *ngIf="order.IsDirectToHome">
                <h3>
                    <i class="fas fa-mail-bulk me-2"></i>
                    {{ 'orderoverview.directToHome.title' | translate }}
                </h3>
                <table>
                    <tr>
                        <td>
                            {{ order.Name }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ order.Street }} {{ order.HouseNumber }}
                            {{ order.HouseNumberSuffix }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ order.PostCode }} {{ order.City }}</td>
                    </tr>
                    <tr>
                        <td>{{ order.HomeState }} {{ order.Country }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="flex-grow container-box accented-background2">
        <div id="orderlines" class="p-4 flex-fill d-flex" *ngIf="!!client">
            <order-lines
                #orderlines
                class="w-100"
                [clientId]="client.Id"
                [isMyopiaActive]="client.Myopie"></order-lines>
        </div>
    </div>
</div>
