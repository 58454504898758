export enum DreamLiteWizardFixTypes {
    none = 0,
    highRiding = 1,
    lowRiding = 2,
    horizontalDisplacement = 3,
    effect = 4,
    binding = 5,
    increaseOz = 6,
    fixedEcc = 7,
    toMultifocal = 8,
    multiFocalEffect = 9,
    flattenAc = 10,
    adjustClearance = 11,
    setOZ = 12,
    toMyopie = 13,
    convertDreamlite = 15,
    convertDreamliteMC = 16,
}
