import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { RegionService } from '@app/core/services/region.service';

export function AppFactory(
    appConfigService: AppConfigService,
    appStateService: AppStateService,
    regionService: RegionService,
) {
    return async () => {
        await appConfigService.loadConfiguration();

        try {
            // Since the appconfig (check for visavy) is loaded from configuration, read region settings a bit later
            regionService.readRegionFromStorage();

            if (
                regionService.regionChosen() &&
                appConfigService.setAppEndpoint(regionService.getRegion()) &&
                appStateService.isAuthenticated
            ) {
                // Company data requires a authenticated user and a backend url (based on region settings)
                await appStateService.loadCurrentAuthenticatedUserItems();
            }
        } catch (error) {
            appStateService.clearUserData();
        }
    };
}
