<div class="form-group row" *ngIf="!formControlOnly">
    <label for="{{ id }}" class="col-form-label" [ngClass]="labelClass">
        <i *ngIf="info" class="fa fa-question-circle" popover="{{ info }}" triggers="mouseenter:mouseleave"></i>
        {{ label | translate: labelParams }}
    </label>
    <div [ngClass]="controlClass">
        <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
    </div>
</div>

<div *ngIf="formControlOnly">
    <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
</div>

<ng-template #controlTemplate>
    <div class="d-flex align-items-center flex-row">
        <input
            #myInput
            [attr.id]="id ? id : null"
            value="{{ selectedOption.Name }}"
            class="form-control"
            (focus)="hasFocus = true"
            (blur)="hasFocus = false"
            [typeahead]="typeaheadSource"
            typeaheadOptionField="Name"
            [typeaheadLatinize]="false"
            (typeaheadOnSelect)="selectValue($event)"
            [attr.required]="isRequired"
            placeholder="{{ placeholder | translate }}"
            [disabled]="isDisabled" />
    </div>
</ng-template>
