export enum Roles {
    Administrator = 1,
    Optician = 2,
    Ps = 3,
    LensConfigurationAdmin = 4,
    Sales = 5,
    DistributorAdmin = 7,
    DistributorSupport = 8,
    QualityAssurance = 9,
    Logistics = 10,
    Translator = 11,
    Marketing = 12,
}
