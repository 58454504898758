import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TopographicImage } from '@app/shared/models/topographicImage.model';
import { TopoImageResponse } from '@app/shared/models/topoImageResponse.model';

@Injectable({
    providedIn: 'root',
})
export class TopographicImageService {
    private readonly basePath = 'api/topographic-images';

    constructor(private readonly httpClient: HttpClient) {}

    public createTopographicImage(topographicImage: TopographicImage): Observable<TopoImageResponse> {
        return this.httpClient.post<TopoImageResponse>(this.basePath, topographicImage);
    }

    public createTopographicThumbnailImage(topographicImage: TopographicImage): Observable<string> {
        return this.httpClient.post<string>(`${this.basePath}/thumbnail`, topographicImage, {
            responseType: 'text' as 'json',
        });
    }
}
