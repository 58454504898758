import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'emptyObject',
    pure: false,
})
export class EmptyObjectPipe implements PipeTransform {
    transform(obj: unknown): boolean {
        return Object.keys(obj).length > 0;
    }
}
