<div class="container-fluid mt-4 px-lg-4 flex-fill" [formGroup]="formGroup">
    <div class="mb-2">
        <h3 *ngIf="isFitLens" class="mt-auto">
            {{ 'general.fitlens' | translate }}
        </h3>
        <h3 *ngIf="isFollowUp" class="mt-auto">
            {{ 'general.followup' | translate }}
        </h3>
        <h3 *ngIf="isWarrantyExchange" class="mt-auto">
            {{ 'general.warranty' | translate }}
        </h3>
    </div>

    <!-- MOBILE -->
    <div *ngIf="appState.isMobile" class="mb-2">
        <tabset [justified]="true">
            <tab id="tab-od" *ngIf="canSelectRightEye">
                <ng-template tabHeading>
                    <div class="d-flex align-items-center">
                        <div>
                            <h4>{{ 'general.od' | translate }}</h4>
                        </div>
                        <div class="ms-auto hidewheninactive modify">
                            <div class="form-check" (click)="toggleRightEye()">
                                <input
                                    type="checkbox"
                                    id="modify-od"
                                    name="modify-od"
                                    [checked]="fitlensRightEyeSelected"
                                    class="form-check-input" />
                                <label id="labelfor-modify-od" for="modify-od" class="form-check-label">
                                    {{ 'general.modify' | translate }}
                                </label>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <div class="p-3">
                    <ng-container *ngTemplateOutlet="fitlensOD"></ng-container>
                </div>
                <div *ngIf="!isDreamLiteFollowUp" class="p-3">
                    <div class="mb-2 title-border-bottom">
                        <h3 class="mt-auto">
                            {{ getImageHeader() | translate }}
                        </h3>
                    </div>
                    <ng-container *ngTemplateOutlet="measurementImageOD"></ng-container>
                    <div class="p-3 mt-3 rounded accented-background2">
                        <ng-container *ngTemplateOutlet="measurementImageOptions"></ng-container>
                    </div>
                </div>
            </tab>
            <tab id="tab-os" *ngIf="canSelectLeftEye">
                <ng-template tabHeading>
                    <div class="d-flex align-items-center">
                        <div>
                            <h4>{{ 'general.os' | translate }}</h4>
                        </div>
                        <div class="ms-auto hidewheninactive modify">
                            <div class="form-check" (click)="toggleLeftEye()">
                                <input
                                    type="checkbox"
                                    [checked]="fitlensLeftEyeSelected"
                                    id="modify-os"
                                    name="modify-os"
                                    class="form-check-input" />
                                <label id="labelfor-modify-os" for="modify-os" class="form-check-label">
                                    {{ 'general.modify' | translate }}
                                </label>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <div class="p-3">
                    <ng-container *ngTemplateOutlet="fitlensOS"></ng-container>
                </div>
                <div class="p-3">
                    <div class="pt-1 mb-2 title-border-bottom">
                        <h3 class="mt-auto">
                            {{ getImageHeader() | translate }}
                        </h3>
                    </div>
                    <ng-container *ngTemplateOutlet="measurementImageOS"></ng-container>
                    <div class="p-1 mt-3 rounded accented-background">
                        <ng-container *ngTemplateOutlet="measurementImageOptions"></ng-container>
                    </div>
                </div>
            </tab>
        </tabset>
    </div>

    <!-- OTHER DEVICES MD XL -->
    <div *ngIf="!appState.isMobile" class="row mb-2 mx-0">
        <div class="col-6 p-0 pe-2 pe-xl-4 d-flex">
            <div *ngIf="canSelectRightEye" class="flex-fill d-flex flex-column">
                <div class="pt-2 title-border-top d-flex align-items-center">
                    <div>
                        <h4>{{ 'general.od' | translate }}</h4>
                    </div>
                    <div class="ms-auto">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                formControlName="modify-od"
                                id="modify-od"
                                name="modify-od"
                                class="form-check-input" />
                            <label id="labelfor-modify-od" for="modify-od" class="form-check-label">
                                {{ 'general.modify' | translate }}
                            </label>
                        </div>
                    </div>
                </div>
                <ng-container *ngTemplateOutlet="fitlensOD"></ng-container>
            </div>
        </div>
        <div class="col-6 p-0 ps-2 ps-xl-4 d-flex">
            <div *ngIf="canSelectLeftEye" class="flex-fill d-flex flex-column">
                <div class="pt-2 title-border-top d-flex align-items-center">
                    <div>
                        <h4>{{ 'general.os' | translate }}</h4>
                    </div>
                    <div class="ms-auto">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                formControlName="modify-os"
                                id="modify-os"
                                name="modify-os"
                                class="form-check-input" />
                            <label id="labelfor-modify-os" for="modify-os" class="form-check-label">
                                {{ 'general.modify' | translate }}
                            </label>
                        </div>
                    </div>
                </div>
                <ng-container *ngTemplateOutlet="fitlensOS"></ng-container>
            </div>
        </div>
        <!-- TOPOGRAPHY OD -->
        <div *ngIf="!isDreamLiteFollowUp" class="col-6 p-0 pe-xl-4 mt-2 d-flex">
            <div class="flex-fill d-flex flex-column" *ngIf="hasRightMeasurement() && fitlensRightEyeSelected">
                <div class="pt-2 title-border-bottom d-flex">
                    <div>
                        <h3 class="mt-auto">
                            {{ getImageHeader() | translate }}
                        </h3>
                    </div>
                    <div class="ms-auto me-1" [hidden]="!rightMeasurementImageLoading">
                        <i class="fas fa-calculator pulsating"></i>
                    </div>
                </div>
                <div class="flex-fill d-flex p-2 p-xl-3 accented-background2 measurementimage">
                    <ng-container *ngTemplateOutlet="measurementImageOD"></ng-container>
                </div>
            </div>
        </div>

        <!-- TOPOGRAPHY OS -->
        <div *ngIf="!isDreamLiteFollowUp" class="col-6 p-0 ps-xl-4 mt-2 d-flex">
            <div class="flex-fill d-flex flex-column" *ngIf="hasLeftMeasurement() && fitlensLeftEyeSelected">
                <div class="pt-2 title-border-bottom d-flex">
                    <div>
                        <h3 class="mt-auto">
                            {{ getImageHeader() | translate }}
                        </h3>
                    </div>
                    <div class="ms-auto me-1" [hidden]="!leftMeasurementImageLoading">
                        <i class="fas fa-calculator pulsating"></i>
                    </div>
                </div>
                <div class="flex-fill d-flex p-2 p-xl-3 accented-background2 measurementimage">
                    <ng-container *ngTemplateOutlet="measurementImageOS"></ng-container>
                </div>
            </div>
        </div>
        <!-- IMAGE -->
        <div
            class="col-12 mx-0 my-3 p-0 rounded accented-background2"
            *ngIf="
                !isDreamLiteFollowUp &&
                ((hasLeftMeasurement() && fitlensLeftEyeSelected) || (hasRightMeasurement() && fitlensRightEyeSelected))
            ">
            <div class="p-1 rounded-bottom" *ngIf="showImageOptions">
                <ng-container *ngTemplateOutlet="measurementImageOptions"></ng-container>
            </div>
        </div>
    </div>

    <!-- TEMPLATES -->
    <ng-template #fitlensOD>
        <div *ngIf="proposal && fitlensRightEyeSelected && rightFittedLens" class="flex-fill d-flex">
            <fit-lens
                #rightFitLens
                id="fitlens-od"
                (onCopyLensInfo)="copyLensHelper.onCopyLensInfo(eyeSides.Od)"
                [showCopyButton]="copyLensHelper.showCopyButton"
                [fittedLens]="rightFittedLens"
                [initialFittedLens]="initialRightFittedLens"
                (isLoading)="handleFitLensLoading(eyeSides.Od, $event)"
                [options]="rightFittedLensOptions"
                [lensFromPreviousOrderPromise]="lensFromPreviousOrderPromise(eyeSides.Od)"
                [client]="client"
                [isDreamLiteFollowUp]="isDreamLiteFollowUp"
                [isRegularFollowUp]="isRegularFollowUp"
                [topoMeasurement]="proposal.RightTopographicMeasurement"
                [refractionMeasurement]="proposal.RightRefractionMeasurement"
                [previousMeasurementId]="client.RightBaseLineTopographicMeasurementId"
                [quantity]="proposal.RightProposalOptions.Quantity"
                [(sessionStorageKeysFitlens)]="sessionStorageKeysFittingFitLens"></fit-lens>
        </div>
    </ng-template>

    <ng-template #fitlensOS>
        <div *ngIf="proposal && fitlensLeftEyeSelected && leftFittedLens" class="flex-fill d-flex">
            <fit-lens
                #leftFitLens
                id="fitlens-os"
                (onCopyLensInfo)="copyLensHelper.onCopyLensInfo(eyeSides.Os)"
                [showCopyButton]="copyLensHelper.showCopyButton"
                [fittedLens]="leftFittedLens"
                [initialFittedLens]="initialLeftFittedLens"
                (isLoading)="handleFitLensLoading(eyeSides.Os, $event)"
                [options]="leftFittedLensOptions"
                [lensFromPreviousOrderPromise]="lensFromPreviousOrderPromise(eyeSides.Os)"
                [client]="client"
                [isDreamLiteFollowUp]="isDreamLiteFollowUp"
                [isRegularFollowUp]="isRegularFollowUp"
                [topoMeasurement]="proposal.LeftTopographicMeasurement"
                [refractionMeasurement]="proposal.LeftRefractionMeasurement"
                [previousMeasurementId]="client.LeftBaseLineTopographicMeasurementId"
                [quantity]="proposal.LeftProposalOptions.Quantity"
                [(sessionStorageKeysFitlens)]="sessionStorageKeysFittingFitLens"></fit-lens>
        </div>
    </ng-template>

    <ng-template #measurementImageOD>
        <div *ngIf="proposal && fitlensRightEyeSelected" class="flex-fill d-flex">
            <measurement-image
                #rightMeasurementImage
                id="measurementimage-od"
                [eyeSide]="eyeSides.Od"
                [fittedLens]="rightFittedLens"
                [imageOptions]="imageOptions"
                [topographicMeasurement]="proposal.RightTopographicMeasurement"
                [refractionMeasurement]="proposal.RightRefractionMeasurement"
                (isLoading)="handleMeasurementImageLoading(eyeSides.Os, $event)"></measurement-image>
        </div>
    </ng-template>

    <ng-template #measurementImageOS>
        <div *ngIf="proposal && fitlensLeftEyeSelected" class="flex-fill d-flex">
            <measurement-image
                #leftMeasurementImage
                id="measurementimage-os"
                [eyeSide]="eyeSides.Os"
                [fittedLens]="leftFittedLens"
                [imageOptions]="imageOptions"
                [topographicMeasurement]="proposal.LeftTopographicMeasurement"
                [refractionMeasurement]="proposal.LeftRefractionMeasurement"
                (isLoading)="handleMeasurementImageLoading(eyeSides.Od, $event)"></measurement-image>
        </div>
    </ng-template>

    <ng-template #measurementImageOptions>
        <measurement-image-options
            id="imageOptions"
            [disabled]="leftMeasurementImageLoading"
            [imageOptions]="imageOptions"
            (imageOptionsChanged)="imageOptionsChanged($event)"
            (imageModeChanged)="imageModeChanged($event)"></measurement-image-options>
    </ng-template>
</div>
<div *footer class="d-flex align-items-center">
    <div>
        <a href="#" (click)="previous($event)">
            <i class="fas fa-arrow-alt-circle-left me-1"></i>
            <span *ngIf="!isFollowUp">{{ 'general.lenstype' | translate }}</span>
            <span *ngIf="isFollowUp">{{ 'general.refraction' | translate }}</span>
        </a>
        <button
            type="button"
            id="reset"
            class="btn btn-secondary ms-2"
            [disabled]="loading"
            (click)="startResetFitlensModal()">
            {{ 'general.reset' | translate }}
        </button>
    </div>

    <div class="ms-auto d-flex align-items-center">
        <div id="warningMessages" *ngIf="!loading">
            <div *ngIf="!orderAllowed()" class="ms-auto me-2 row">
                <span class="col-12 align-self-center">{{ 'fitlens.cannotorder' | translate }}</span>
            </div>

            <div *ngIf="orderAllowed && !validEccentricity" class="ms-auto me-2 row">
                <span class="col-12 align-self-center">{{ 'fitlens.invalideccentricity' | translate }}</span>
            </div>
            <div *ngIf="orderAllowed && !validACValues" class="ms-auto me-2 row" id="AcToSteepWarning">
                <span class="col-12 align-self-center">{{ 'fitlens.invalidAcToSteep' | translate }}</span>
            </div>
        </div>
        <button *ngIf="isSupportOrderEnabled" type="button" id="support" class="btn btn-warning" (click)="next(true)">
            {{ 'general.support' | translate }}
        </button>
        <ng-container *ngIf="allowDirectOrderEnabled">
            <button
                *ngIf="directOrderIsEnabled()"
                type="button"
                id="directorder"
                class="btn btn-success ms-1"
                [disabled]="blockDirectOrder"
                (click)="next(false)">
                {{ 'general.directorder' | translate }}
            </button>
            <button
                *ngIf="!directOrderIsEnabled()"
                type="button"
                id="directorderdisabled"
                class="btn btn-default disabled ms-1"
                (click)="alertDirectOrderDisabled()">
                {{ 'general.directorder' | translate }}
            </button>
        </ng-container>
    </div>
</div>
