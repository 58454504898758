<div
    id="loadingoverlay"
    class="flex-fill d-flex flex-column align-items-center loadingoverlay"
    [ngClass]="this.isLoading ? 'isVisible' : 'isInvisible'">
    <div class="loading my-auto py-4 px-5">
        <div class="message text-center pb-4" *ngIf="message">
            {{ message }}
        </div>
        <div class="d-flex">
            <div class="icon mx-auto d-inline-block">
                <i class="fas fa-eye fa-5x"></i>
            </div>
        </div>
    </div>
</div>
