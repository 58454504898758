<div [formGroup]="formGroupSettings" class="p-2 no-caret">
    <h3>{{ 'settings.settings' | translate | uppercase }}</h3>

    <div *ngIf="settingsLoading" class="spinner-border spinner-border-sm m-2"></div>
    <br />

    <ng-container *ngIf="!settingsLoading && opticianOptionsActive">
        <mpc-input-number
            [formControlName]="'vertex'"
            [id]="'vertex'"
            [ranges]="[vertexRange]"
            [formatting]="{ signedNumber: false, decimalCount: 2 }"
            [maxLength]="5"
            [labelWidth]="7"
            [label]="'general.vertex'"
            [unit]="'units.millimeter'"></mpc-input-number>

        <mpc-input-number
            [formControlName]="'topographicMeasurementRetentionHours'"
            [id]="'topographicMeasurementRetentionHours'"
            [info]="'general.topographicmeasurementretentionhours.info' | translate"
            [ranges]="[topographicMeasurementRetentionHoursRange]"
            [formatting]="{ signedNumber: false, decimalCount: 0 }"
            [maxLength]="3"
            [labelWidth]="7"
            [label]="'general.topographicmeasurementretentionhours'"
            [unit]="'units.hours'"></mpc-input-number>

        <mpc-input-checkbox
            [formControlName]="'replacementNotifications'"
            [id]="'replacementNotifications'"
            [labelWidth]="7"
            [labelControlStyle]="true"
            [label]="'general.replacementnotifications'"></mpc-input-checkbox>

        <mpc-input-checkbox
            [formControlName]="'sendStatusReminderEmail'"
            [id]="'sendStatusReminderEmail'"
            [labelWidth]="7"
            [labelControlStyle]="true"
            [label]="'general.sendstatusreminderemail'"></mpc-input-checkbox>

        <mpc-input-checkbox
            *hasFeatureSetting="[
                {
                    Feature: Features.OrthoK,
                    Category: FeatureCategories.Optician,
                    IsEnabled: true
                }
            ]"
            [formControlName]="'usePrefilledDreamLiteAgreement'"
            [id]="'usePrefilledDreamLiteAgreement'"
            [labelWidth]="7"
            [labelControlStyle]="true"
            [label]="'general.useprefilleddreamliteagreement'"
            [labelParams]="lensNames"></mpc-input-checkbox>
    </ng-container>

    <div class="mt-2">
        <h3>{{ 'settings.toposettings' | translate | uppercase }}</h3>
    </div>

    <div *ngIf="settingsLoading" class="spinner-border spinner-border-sm m-2"></div>
    <br />

    <ng-container *ngIf="!settingsLoading">
        <div class="mt-2">
            <mpc-input-radio-list
                formControlName="imageType"
                [id]="'imageType'"
                [options]="imageTypes"
                [label]="'settings.types'"></mpc-input-radio-list>
        </div>

        <div class="mt-2">
            <mpc-input-radio-list
                formControlName="useNormalize"
                [id]="'-useNormalize'"
                [options]="normalizeOptions"
                [label]="'settings.options'"></mpc-input-radio-list>
        </div>

        <div class="mt-2">
            <mpc-input-radio-list
                formControlName="useMapType"
                [id]="'useMapType'"
                [options]="mapTypeOptions"></mpc-input-radio-list>
        </div>

        <div class="mt-2">
            <mpc-input-radio-list formControlName="useMm" [id]="'useMm'" [options]="mmDptTypes"></mpc-input-radio-list>
        </div>
    </ng-container>
</div>
