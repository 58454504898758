import { BaseValidator } from '@app/shared/validators/BaseValidator';
import { LensTypes, ParameterTypeCodes } from '@app/shared/enums';
import { FormArray, ValidationErrors } from '@angular/forms';
import { FitLensComponent } from '../fitlens.component';

export class DreamliteRcValueShouldBeLessOrEqualToAcValidator extends BaseValidator {
    bind(obj: FitLensComponent): ValidationErrors {
        if (obj.formGroup && obj.fittedLensParameters && obj.lensTypeId === LensTypes.DreamLite) {
            const params = obj.formGroup.controls['parameters'] as FormArray;

            const ac = params.controls[obj.getFittedLensParameterIndex(ParameterTypeCodes.AC)];
            const rc = params.controls[obj.getFittedLensParameterIndex(ParameterTypeCodes.RC)];

            if (ac && rc && ac.value <= rc.value) {
                return { dreamliteRcValueShouldBeLessOrEqualThanAc: true };
            }
        }

        return null;
    }
}
