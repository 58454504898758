import { EyelitePoint } from '@app/professional-service/models/eyeliteimport/EyelitePoint.model';

export class EyeliteMeasurement {
    constructor(eyeliteMeasurement: EyeliteMeasurement) {
        this.Eyeside = eyeliteMeasurement.Eyeside;
        this.CorneaDistance = eyeliteMeasurement.CorneaDistance;
        this.CorneaDiameter = eyeliteMeasurement.CorneaDiameter;
        this.Addition = eyeliteMeasurement.Addition;
        this.Visus = eyeliteMeasurement.Visus;
        this.Power = eyeliteMeasurement.Power;
        this.Cyl = eyeliteMeasurement.Cyl;
        this.Axis = eyeliteMeasurement.Axis;
        this.Date = eyeliteMeasurement.Date;
        this.TopographerName = eyeliteMeasurement.TopographerName;
        this.Confirmed = eyeliteMeasurement.Confirmed;
        this.BaselineMeasurement = eyeliteMeasurement.BaselineMeasurement;

        this.CylindricalCoordinates = new Array<EyelitePoint>();
        for (let i = 0; i < eyeliteMeasurement.CylindricalCoordinates.length; i++) {
            this.CylindricalCoordinates.push(new EyelitePoint(eyeliteMeasurement.CylindricalCoordinates[i]));
        }

        this.IsSelected = false;
    }

    public Eyeside: number;
    public CorneaDistance: number;
    public CorneaDiameter: number;
    public Addition: number;
    public Visus: number;
    public Power: number;
    public Cyl: number;
    public Axis: number;
    public Date: string;
    public TopographerName: string;
    public Confirmed: number;
    public BaselineMeasurement: number;
    public CylindricalCoordinates: EyelitePoint[];
    public IsSelected: boolean;
}
