<div class="modal-header">
    <h4 class="modal-title m-auto">{{ 'client.deleteclient' | translate }}</h4>
</div>

<div class="modal-body">
    {{ 'client.deleteclientconfirmation' | translate }}
</div>

<div class="modal-footer">
    <div class="ms-auto">
        <button class="btn btn-danger" (click)="delete()" id="deleteclientpopup">
            {{ 'client.deleteclient' | translate }}
        </button>
        <button class="btn btn-default ms-2" (click)="bsModalRef.hide()">
            {{ 'general.close' | translate }}
        </button>
    </div>
</div>
