import { FormArray, ValidationErrors } from '@angular/forms';
import { BaseValidator } from '@app/shared/validators/BaseValidator';
import { LensTypes, ParameterTypeCodes } from '@app/shared/enums';
import { FitLensComponent } from '@app/fitlens/fitlens.component';

export class DreamliteBcrValueShouldMoreThanAcValidator extends BaseValidator {
    bind(obj: FitLensComponent): ValidationErrors {
        if (obj.formGroup && obj.fittedLensParameters && obj.lensTypeId === LensTypes.DreamLite) {
            const params = obj.formGroup.controls['parameters'] as FormArray;

            const bcr = params.controls[obj.getFittedLensParameterIndex(ParameterTypeCodes.RAD)];
            const ac = params.controls[obj.getFittedLensParameterIndex(ParameterTypeCodes.AC)];

            if (bcr && ac && bcr.value < ac.value + 0.3) {
                return { dreamliteBcrValueShouldMoreThanAc: true };
            }
        } else {
            return null;
        }
    }
}

//Support does not have any safety margin
export class DreamliteSupportBcrValueShouldMoreThanAcValidator extends DreamliteBcrValueShouldMoreThanAcValidator {
    bind(obj: FitLensComponent): ValidationErrors {
        if (obj.formGroup && obj.fittedLensParameters && obj.lensTypeId === LensTypes.DreamLite) {
            const params = obj.formGroup.controls['parameters'] as FormArray;

            const bcr = params.controls[obj.getFittedLensParameterIndex(ParameterTypeCodes.RAD)];
            const ac = params.controls[obj.getFittedLensParameterIndex(ParameterTypeCodes.AC)];

            if (bcr && ac && bcr.value <= ac.value) {
                //should be no margin for support
                return { dreamliteSupportBcrValueShouldMoreThanAc: true };
            }
        } else {
            return null;
        }
    }
}
