import { LensDefinitionParameterNumberRange } from '../models';

export class RefractionFieldsConfiguration {
    sphereRanges: Array<LensDefinitionParameterNumberRange>;
    cylinderRanges: Array<LensDefinitionParameterNumberRange>;
    additionRanges: Array<LensDefinitionParameterNumberRange>;
    axisRanges: Array<LensDefinitionParameterNumberRange>;
    vertexRanges: Array<LensDefinitionParameterNumberRange>;
    corneaRanges: Array<LensDefinitionParameterNumberRange>;
    pupilRanges: Array<LensDefinitionParameterNumberRange>;
    lengthRanges: Array<LensDefinitionParameterNumberRange>;
    refractionOverLensRanges: Array<LensDefinitionParameterNumberRange>;

    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    private constructor() {}

    maxLength(code: string): number {
        if (code === 'VERTD') {
            return 3;
        }
        return 6;
    }

    getRange(code: string): Array<LensDefinitionParameterNumberRange> {
        switch (code) {
            case 'POW': // sferisch
                return this.sphereRanges;

            case 'CYL': // cylinder
                return this.cylinderRanges;

            case 'AX': // as
                return this.axisRanges;

            case 'VERTD': // vertex
                return this.vertexRanges;

            case 'ADD': // additie
                return this.additionRanges;
        }
    }

    public static defaultRanges(): RefractionFieldsConfiguration {
        const self = new RefractionFieldsConfiguration();
        self.sphereRanges = new Array(new LensDefinitionParameterNumberRange(-30, 30, 0.25));
        self.cylinderRanges = new Array(new LensDefinitionParameterNumberRange(-12, 0, 0.25));
        self.additionRanges = new Array(new LensDefinitionParameterNumberRange(0, 3.5, 0.25));
        self.axisRanges = new Array(new LensDefinitionParameterNumberRange(0, 180, 1));
        self.vertexRanges = new Array(new LensDefinitionParameterNumberRange(8, 16, 1));
        self.corneaRanges = new Array(new LensDefinitionParameterNumberRange(8, 15, 0.1, 11.8));
        self.pupilRanges = new Array(new LensDefinitionParameterNumberRange(2, 9, 0.05));
        self.lengthRanges = new Array(new LensDefinitionParameterNumberRange(20, 35, 0.05, 23));
        self.refractionOverLensRanges = new Array(new LensDefinitionParameterNumberRange(-4, 4, 0.25));
        return self;
    }

    public static deviatedCorneaSettings(): RefractionFieldsConfiguration {
        const self = new RefractionFieldsConfiguration();
        self.sphereRanges = new Array(new LensDefinitionParameterNumberRange(-30, 30, 0.25));
        self.cylinderRanges = new Array(new LensDefinitionParameterNumberRange(-12, 0, 0.25));
        self.additionRanges = new Array(new LensDefinitionParameterNumberRange(0, 3.5, 0.25));
        self.axisRanges = new Array(new LensDefinitionParameterNumberRange(0, 180, 1));
        self.vertexRanges = new Array(new LensDefinitionParameterNumberRange(8, 16, 1));
        self.corneaRanges = new Array(new LensDefinitionParameterNumberRange(8, 15, 0.01, 11.8));
        self.pupilRanges = new Array(new LensDefinitionParameterNumberRange(2, 9, 0.05));
        self.lengthRanges = new Array(new LensDefinitionParameterNumberRange(20, 35, 0.05, 23));
        self.refractionOverLensRanges = new Array(new LensDefinitionParameterNumberRange(-4, 4, 0.25));
        return self;
    }
}
