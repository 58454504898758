import { Component } from '@angular/core';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { AlertService } from '@app/shared/appservices/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { Router } from '@angular/router';
import { TopographicMeasurementService } from '@app/core/services/api/topographic-measurement.service';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-testutilities',
    templateUrl: './testutilities.component.html',
    styleUrls: ['./testutilities.component.scss'],
})
export class TestutilitiesComponent {
    constructor(
        private readonly appState: AppStateService,
        private readonly alertService: AlertService,
        private readonly loaderService: LoaderService,
        private readonly router: Router,
        private readonly translate: TranslateService,
        private readonly testService: TopographicMeasurementService,
    ) {}

    opticianId: number;
    opticianName: string;
    numberOfTopos: number;

    ngOnInit() {
        this.opticianName = this.appState.currentOptician.Name;
    }

    recentEmails() {
        this.router.navigate(['test-utilities', 'list-email']);
    }

    async generateTopoImages() {
        this.loaderService.show();
        await lastValueFrom(this.testService.importRandomTopos());
        const text = this.translate.instant('testutilities.topo-simulation-success', { opticien: this.opticianName });
        this.loaderService.hide();
        this.alertService.success(text);
    }

    async importRandomTopos() {
        if (this.opticianId && this.numberOfTopos) {
            this.loaderService.show();
            await lastValueFrom(this.testService.importRandomTopos(this.opticianId, this.numberOfTopos));
            const text = this.translate.instant('testutilities.topo-simulation-success', { opticien: this.opticianId });
            this.loaderService.hide();
            this.alertService.success(text);
        }
    }
}
