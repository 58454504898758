import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DreamLiteWizardFixType, DreamLiteWizardOpticianInput, FittedLens } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class DreamLiteWizardService {
    private readonly basePath = 'api/dreamlite-wizard';

    constructor(private readonly httpClient: HttpClient) {}

    public getAllFixTypes(lensDefinitionId: number): Observable<DreamLiteWizardFixType[]> {
        const options = {
            params: new HttpParams().set('lensDefinitionId', lensDefinitionId),
        };

        return this.httpClient.get<DreamLiteWizardFixType[]>(`${this.basePath}/fix-types/options`, options);
    }

    public calculateLensParametersForOptician(input: DreamLiteWizardOpticianInput): Observable<FittedLens> {
        return this.httpClient.post<FittedLens>(`${this.basePath}/calculate-lens-parameters-for-optician`, input);
    }

    public getDreamLiteClearance(input: FittedLens): Observable<FittedLens> {
        return this.httpClient.post<FittedLens>(`${this.basePath}/get-clearance`, input);
    }

    public adjustDreamLiteClearance(
        fittedLens: FittedLens,
        newClearance: number,
        astig: boolean,
    ): Observable<FittedLens> {
        const body = {
            fittedLens,
            newClearance,
            astig,
        };

        return this.httpClient.post<FittedLens>(`${this.basePath}/adjust-clearance`, body);
    }
}
