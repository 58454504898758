export class SupportOrderQueryCriteria {
    public ShowMyItemsOnly: boolean;
    public ShowSupportOrdersWithNewMessageOnly: boolean;
    public ShowPerosOrdersOnly: boolean;
    public ShowBlockedOrdersOnly: boolean;

    public SupportOrderStatusId: number;
    public ClientName: string;
    public OpticianName: string;

    public UsePaging: boolean;
    public PageIndex: number;
    public PageSize: number;
    public TotalItems: number;

    public OrderByField: string;
    public OrderByAscending: boolean;

    public FilterByFields: { [index: string]: string } = {};
    public FilterBy: string;

    public StartDate: string;

    public setDefault(): void {
        this.OrderByAscending = true;
        this.OrderByField = 'Date';
        this.PageIndex = 0;
        this.PageSize = 50;
        this.ShowMyItemsOnly = false;
        this.ShowSupportOrdersWithNewMessageOnly = false;
        this.UsePaging = true;
        this.ShowPerosOrdersOnly = false;
    }
}
