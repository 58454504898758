<div class="container-fluid flex-fill mt-4 px-xl-4 d-flex flex-column">
    <div class="mb-2 d-flex title-border-bottom">
        <h3>{{ 'general.lenstype' | translate }}</h3>
    </div>
    <div class="spinner-border spinner-border-sm" *ngIf="loadingLensTypes"></div>
    <div class="row">
        <div class="col-12 mt-3" *ngIf="!loadingLensTypes && availableLensTypesInitialized && !anyLensTypeAvailable">
            {{ 'lenstype.nolenstypesavailable' | translate }}
        </div>
    </div>
    <div class="w-100 d-flex flex-fill mx-auto lenstypecontainer">
        <div
            *ngIf="!loadingLensTypes && anyLensTypeAvailable"
            class="w-100 d-flex flex-column flex-md-row align-self-center">
            <div
                id="lenstypeDreamlite"
                class="flex-even mb-3 mx-md-1 mx-lg-2 p-2 pb-3 d-flex flex-column lenstype dreamlite"
                *ngIf="selectableLenses[lensTypes.DreamLite].isSelectable"
                [ngClass]="{
                    disabled:
                        !selectableLenses[lensTypes.DreamLite].isSelectable ||
                        !dreamLiteCalculationSuitable ||
                        !dreamLiteSuitable ||
                        selectableLenses[lensTypes.DreamLite].isDisabled
                }"
                (click)="next($event, lensTypes.DreamLite)">
                <div class="p-1 d-flex logo">
                    <div class="mx-auto">
                        <img alt="lens type" class="lenstypeIcon" src="assets/images/lens-dreamlite.svg" />
                    </div>
                </div>
                <div class="text-center my-1 title">
                    <h4>{{ 'lenstype.ortho-k' | translate }}</h4>
                </div>
                <div class="text-center description">
                    <ng-container *ngIf="isMyopie">
                        {{ 'lenstype.dreamlitedescriptionmc' | translate: lensNames }}
                    </ng-container>
                    <ng-container *ngIf="!isMyopie">
                        {{ 'lenstype.dreamlitedescription' | translate: lensNames }}
                    </ng-container>
                </div>
                <div class="text-center" *ngIf="loadingDreamliteRestRefraction">
                    <div class="spinner-border spinner-border-sm m-2"></div>
                </div>
                <div class="text-center mt-2 p-3 mt-md-auto" *ngIf="!selectableLenses[lensTypes.DreamLite]">
                    <div class="lens-type-not-allowed">
                        <small>{{ 'lenstype.dreamlitenotallowed' | translate: lensNames }}</small>
                    </div>
                </div>
                <div
                    class="text-center mt-2 p-3 mt-md-auto"
                    *ngIf="selectableLenses[lensTypes.DreamLite].isSelectable && !dreamLiteSuitable">
                    <div id="dreamlitenotallowedTopography" class="lens-type-not-allowed">
                        <small>{{ 'lenstype.dreamlitenotopography' | translate: lensNames }}</small>
                    </div>
                </div>
                <div
                    class="text-center mt-2 p-3 mt-md-auto"
                    *ngIf="
                        selectableLenses[lensTypes.DreamLite].isSelectable &&
                        dreamLiteSuitable &&
                        !dreamLiteCalculationSuitable
                    ">
                    <div class="lens-type-not-allowed">
                        <small>
                            {{
                                'lenstype.lenstype-not-suitable-parameters'
                                    | translate: { lensType: lensNames?.dreamliteName ?? '' }
                            }}
                        </small>
                    </div>
                </div>

                <ng-container [ngSwitch]="selectableLenses[lensTypes.DreamLite].lensTypeMeasurementStatus">
                    <ng-container *ngSwitchCase="LensTypeMeasurementStatus.Mixed">
                        <ng-container
                            *ngIf="!hasManualCalculation"
                            [ngTemplateOutlet]="measurementStatusMixed"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="LensTypeMeasurementStatus.AllManual">
                        <ng-container
                            *ngIf="!hasManualCalculation"
                            [ngTemplateOutlet]="measurementStatusManualOnly"></ng-container>
                    </ng-container>
                </ng-container>

                <div
                    stop-propagation
                    class="text-center mx-2 mt-md-4 agreement"
                    *ngIf="selectableLenses[lensTypes.DreamLite] && dreamLiteCalculationSuitable && dreamLiteSuitable">
                    <button type="button" class="btn" (click)="downloadAgreement('dreamlite')">
                        {{ 'general.agreement' | translate }}
                    </button>
                </div>
            </div>

            <div
                id="lenstypeSoft"
                class="flex-even mb-3 mx-md-1 mx-lg-2 p-2 pb-3 d-flex flex-column lenstype soft"
                [ngClass]="{
                    disabled: selectableLenses[lensTypes.Soft].isDisabled
                }"
                *ngIf="selectableLenses[lensTypes.Soft].isSelectable"
                (click)="next($event, lensTypes.Soft)">
                <div class="p-1 d-flex logo">
                    <div class="mx-auto">
                        <img alt="lens type" class="lenstypeIcon" src="assets/images/lens-soft.svg" />
                    </div>
                </div>
                <div class="text-center my-1 title">
                    <h4>{{ 'lenstype.soft' | translate }}</h4>
                </div>
                <div class="text-center description">
                    <ng-container *ngIf="isMyopie">
                        {{ 'lenstype.softdescriptionmc' | translate: lensNames }}
                    </ng-container>
                    <ng-container *ngIf="!isMyopie">
                        {{ 'lenstype.softdescription' | translate: lensNames }}
                    </ng-container>
                </div>

                <ng-container [ngSwitch]="selectableLenses[lensTypes.Soft].lensTypeMeasurementStatus">
                    <ng-container *ngSwitchCase="LensTypeMeasurementStatus.Mixed">
                        <ng-container
                            *ngIf="!hasManualCalculation"
                            [ngTemplateOutlet]="measurementStatusMixed"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="LensTypeMeasurementStatus.AllManual">
                        <ng-container
                            *ngIf="!hasManualCalculation"
                            [ngTemplateOutlet]="measurementStatusManualOnly"></ng-container>
                    </ng-container>
                </ng-container>
                <div *ngIf="isMyopie" class="text-center mx-2 mt-md-auto agreement">
                    <button stop-propagation type="button" class="btn me-1" (click)="downloadAgreement('misight')">
                        {{ 'lenstype.misightagreement' | translate }}
                    </button>
                </div>
            </div>

            <div
                id="lenstypeRgp"
                class="flex-even mb-3 mx-md-1 mx-lg-2 p-2 pb-3 d-flex flex-column lenstype rgp"
                [ngClass]="{
                    disabled: selectableLenses[lensTypes.Rgp].isDisabled
                }"
                *ngIf="selectableLenses[lensTypes.Rgp].isSelectable"
                (click)="next($event, lensTypes.Rgp)">
                <div class="p-1 d-flex logo">
                    <div class="mx-auto">
                        <img alt="lens-type" class="lenstypeIcon" src="assets/images/lens-rgp.svg" />
                    </div>
                </div>
                <div class="text-center my-1 title">
                    <h4>{{ 'lenstype.rgp' | translate }}</h4>
                </div>
                <div class="text-center description">
                    {{ 'lenstype.rgpdescription' | translate: lensNames }}
                </div>

                <ng-container [ngSwitch]="selectableLenses[lensTypes.Rgp].lensTypeMeasurementStatus">
                    <ng-container *ngSwitchCase="LensTypeMeasurementStatus.Mixed">
                        <ng-container
                            *ngIf="!hasManualCalculation"
                            [ngTemplateOutlet]="measurementStatusMixed"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="LensTypeMeasurementStatus.AllManual">
                        <ng-container
                            *ngIf="!hasManualCalculation"
                            [ngTemplateOutlet]="measurementStatusManualOnly"></ng-container>
                    </ng-container>
                </ng-container>
            </div>

            <div
                id="lenstypeMed"
                class="flex-even mb-3 mx-md-1 mx-lg-2 p-2 pb-3 d-flex flex-column lenstype med"
                [ngClass]="{
                    disabled: selectableLenses[lensTypes.Med].isDisabled
                }"
                *ngIf="selectableLenses[lensTypes.Med].isSelectable"
                (click)="next($event, lensTypes.Med)">
                <div class="p-1 d-flex logo">
                    <div class="mx-auto">
                        <img alt="lens type" class="lenstypeIcon" src="assets/images/lens-med.svg" />
                    </div>
                </div>
                <div class="text-center my-1 title">
                    <h4>{{ 'lenstype.med' | translate }}</h4>
                </div>
                <div class="text-center description">
                    {{ 'lenstype.meddescription' | translate: lensNames }}
                </div>

                <ng-container [ngSwitch]="selectableLenses[lensTypes.Med].lensTypeMeasurementStatus">
                    <ng-container *ngSwitchCase="LensTypeMeasurementStatus.Mixed">
                        <ng-container
                            *ngIf="!hasManualCalculation"
                            [ngTemplateOutlet]="measurementStatusMixed"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="LensTypeMeasurementStatus.AllManual">
                        <ng-container
                            *ngIf="!hasManualCalculation"
                            [ngTemplateOutlet]="measurementStatusManualOnly"></ng-container>
                    </ng-container>
                </ng-container>
            </div>

            <div
                id="lenstypeMedPlus"
                class="flex-even mb-3 mx-md-1 mx-lg-2 p-2 pb-3 d-flex flex-column lenstype med"
                [ngClass]="{
                    disabled: selectableLenses[lensTypes.MedPlus].isDisabled
                }"
                *ngIf="selectableLenses[lensTypes.MedPlus].isSelectable"
                (click)="next($event, lensTypes.MedPlus)">
                <div class="p-1 d-flex logo">
                    <div class="mx-auto">
                        <img alt="lens type" class="lenstypeIcon" src="assets/images/lens-med-plus.svg" />
                    </div>
                </div>
                <div class="text-center my-1 title">
                    <h4>{{ 'lenstype.med-plus' | translate }}</h4>
                </div>
                <div class="text-center description">
                    {{ 'lenstype.medplusdescription' | translate }}
                </div>

                <ng-container [ngSwitch]="selectableLenses[lensTypes.MedPlus].lensTypeMeasurementStatus">
                    <ng-container *ngSwitchCase="LensTypeMeasurementStatus.Mixed">
                        <ng-container
                            *ngIf="!hasManualCalculation"
                            [ngTemplateOutlet]="measurementStatusMixed"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="LensTypeMeasurementStatus.AllManual">
                        <ng-container
                            *ngIf="!hasManualCalculation"
                            [ngTemplateOutlet]="measurementStatusManualOnly"></ng-container>
                    </ng-container>
                </ng-container>
            </div>

            <div
                id="lenstypeCrt"
                class="flex-even mb-3 mx-md-1 mx-lg-2 p-2 pb-3 d-flex flex-column lenstype crt"
                [ngClass]="{
                    disabled: crtDisabled || selectableLenses[lensTypes.Crt].isDisabled
                }"
                *ngIf="selectableLenses[lensTypes.Crt].isSelectable"
                (click)="next($event, lensTypes.Crt)">
                <div class="p-1 d-flex logo">
                    <div class="mx-auto">
                        <img alt="lens-type" class="lenstypeIcon" src="assets/images/lens-dreamlite.svg" />
                    </div>
                </div>
                <div class="text-center my-1 title">
                    <h4>{{ 'lenstype.crt' | translate }}</h4>
                </div>
                <div class="text-center description">
                    {{ 'lenstype.crtdescription' | translate }}
                </div>

                <div class="text-center mt-2 p-3 mt-md-auto" *ngIf="crtDisabled">
                    <div class="lens-type-not-allowed">
                        <small>
                            {{
                                'lenstype.lenstype-not-suitable-parameters'
                                    | translate: { lensType: ('lenstype.crt' | translate) }
                            }}
                        </small>
                    </div>
                </div>

                <ng-container [ngSwitch]="selectableLenses[lensTypes.Crt].lensTypeMeasurementStatus">
                    <ng-container *ngSwitchCase="LensTypeMeasurementStatus.Mixed">
                        <ng-container
                            *ngIf="!hasManualCalculation"
                            [ngTemplateOutlet]="measurementStatusMixed"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="LensTypeMeasurementStatus.AllManual">
                        <ng-container
                            *ngIf="!hasManualCalculation"
                            [ngTemplateOutlet]="measurementStatusManualOnly"></ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    <div *footer>
        <div class="d-flex align-items-center">
            <div>
                <a href="#" (click)="previous($event)">
                    <i class="fas fa-arrow-alt-circle-left me-1"></i>
                    <span>{{ 'general.refractiondata' | translate }}</span>
                </a>
            </div>
        </div>
    </div>
</div>

<ng-template #measurementStatusMixed>
    <div class="text-center mt-2 p-3 mt-md-auto">
        <div class="lens-measurement-status-mixed">
            <small>
                {{ 'lenstype.measurementStatusMixed' | translate }}
            </small>
        </div>
    </div>
</ng-template>

<ng-template #measurementStatusManualOnly>
    <div class="text-center mt-2 p-3 mt-md-auto">
        <div class="lens-measurement-status-manual-only">
            <small>
                {{ 'lenstype.measurementStatusManualOnly' | translate }}
            </small>
        </div>
    </div>
</ng-template>
