import { EyeSides } from '@app/shared/enums';

export class QuantityChange {
    eyeSide: EyeSides;
    newQuantity: number;

    constructor(eyeSide: EyeSides, newQuantity: number) {
        this.eyeSide = eyeSide;
        this.newQuantity = newQuantity;
    }
}
