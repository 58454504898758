import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListSelectOption } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class EdgeService {
    private readonly basePath = 'api/edges';

    constructor(private readonly httpClient: HttpClient) {}

    public getEdges(): Observable<ListSelectOption[]> {
        return this.httpClient.get<ListSelectOption[]>(`${this.basePath}/options`);
    }
}
