import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslationCategory } from '@app/shared/models/translation-category.model';

@Injectable({
    providedIn: 'root',
})
export class TranslationCategoryService {
    private readonly basePath = 'api/translation-categories';

    constructor(private readonly httpClient: HttpClient) {}

    public getTranslationCategories(): Observable<TranslationCategory[]> {
        return this.httpClient.get<TranslationCategory[]>(`${this.basePath}`);
    }

    public updateTranslationCategory(category: TranslationCategory): Observable<TranslationCategory> {
        return this.httpClient.put<TranslationCategory>(`${this.basePath}`, category);
    }
}
