import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Client, Optician, OrderHistoryLine, OrderWithComplexData } from '@app/shared/models';
import { ClientService } from '@app/core/services/api/client.service';
import { EyeSides, LensTypes, OrderLineStatus, OrderTypes, FeatureCategories, Features } from '@app/shared/enums';
import { OpticianService } from '@app/core/services/api/optician.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { ShopService } from '@app/core/services/shop.service';
import { ProposalService } from '@app/core/services/api/proposal.service';
import { OrderService } from '@app/core/services/api/order.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ReOrderDialogComponent } from '@app/professional-service/dialogs/reorder-dialog.component';
import { ReOrder } from '@app/professional-service/models/ReOrder.model';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { forkJoin, lastValueFrom, Observable } from 'rxjs';

@Component({
    selector: 'ps-clientmaintenance-details',
    templateUrl: './ps-clientmaintenance-details.component.html',
    styleUrls: ['ps-clientmaintenance-details.component.scss'],
})
export class PsClientMaintenanceDetailsComponent implements OnInit {
    Features = Features;
    FeatureCategories = FeatureCategories;
    client: Client;
    optician: Optician;
    tab = 1;
    loading = false;
    selectedLensfilterType: string;
    selectedOrderLines: OrderHistoryLine[];
    leftLensOrderId: number;
    rightLensOrderId: number;
    lastOrderId: number;
    isExternalCommunicationReadOnly: boolean;
    isLensInStock: boolean;
    contents = 'externalCommunication';

    constructor(
        private clientService: ClientService,
        private opticianService: OpticianService,
        private modalService: BsModalService,
        public proposalService: ProposalService,
        public loaderService: LoaderService,
        public shopService: ShopService,
        public orderService: OrderService,
        public appState: AppStateService,
        public route: ActivatedRoute,
    ) {}

    async ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.loadClient(+params.get('id'));
        });
    }

    async loadClient(id: number) {
        this.client = await lastValueFrom(this.clientService.getById(id));
        await this.loadOptician(this.client.OpticianId);
        await this.loadLastOrder(id);
    }

    async loadOptician(id: number) {
        this.optician = await lastValueFrom(this.opticianService.getById(id));
    }

    async loadLastOrder(clientId: number) {
        this.lastOrderId = await lastValueFrom(this.orderService.getLastOrderIdForClient(clientId));
        this.isExternalCommunicationReadOnly = this.lastOrderId === null;
    }

    onSelectedLensfilterChanged(lensType: string) {
        this.selectedLensfilterType = lensType;
    }

    onSelectedOrderLinesChanged(selectedOrderLines: OrderHistoryLine[]): void {
        this.selectedOrderLines = selectedOrderLines;

        const right = this.selectedOrderLines.find((x) => x.FittedLens.EyeSideId === EyeSides.Od);
        this.rightLensOrderId = right != null ? right.OrderId : null;
        const left = this.selectedOrderLines.find((x) => x.FittedLens.EyeSideId === EyeSides.Os);
        this.leftLensOrderId = left != null ? left.OrderId : null;

        this.updateLensInStock();
    }

    updateLensInStock(): void {
        // When all of the selectedOrderLines have their product available, we can update the lens in stock status
        this.isLensInStock = this.selectedOrderLines.every(
            (x) =>
                x.Product != null &&
                (x.Product.AvailableForDirectOrder || x.Product.AvailableInProcess) &&
                x.Product.MaxQuantity > 0,
        );
    }

    clientTabClass(tab: number): object {
        return {
            active: tab === this.tab,
            'title-border-bottom': tab === this.tab,
        };
    }

    clickTab($event: MouseEvent, tab: number) {
        $event.preventDefault();
        this.tab = tab;
    }

    setContent(content) {
        this.contents = content;
    }

    get isReOrderEnabled(): boolean {
        if (!this.canSelectedOrderLinesBeUsedForNewOrder()) {
            return false;
        }

        const hasWebshopOrderLines = this.selectedOrderLines.find((x) => x.OrderTypeId === OrderTypes.WebshopOrder);
        if (hasWebshopOrderLines) {
            const orderTypeIds = this.selectedOrderLines.map((x) => x.OrderTypeId);
            const uniqueOrderTypeIds = Array.from(new Set(orderTypeIds));

            if (uniqueOrderTypeIds.length > 1) {
                return false;
            }
        }

        return true;
    }

    private canSelectedOrderLinesBeUsedForNewOrder(): boolean {
        if (!this.selectedOrderLines || this.selectedOrderLines.length === 0) {
            return false;
        }

        const containsSpares = this.selectedOrderLines.some((ol) => ol.IsSpare);

        if (containsSpares) {
            return false;
        }

        const areShipped = this.selectedOrderLines.every((ol) => ol.OrderLineStatusId === OrderLineStatus.Shipped);

        if (!areShipped) {
            return false;
        }

        const areDifferentLensTypes =
            this.selectedOrderLines.length > 1 &&
            this.selectedOrderLines[0].FittedLens.LensTypeId !== this.selectedOrderLines[1].FittedLens.LensTypeId;

        if (areDifferentLensTypes) {
            return false;
        }

        if (this.areSpectaclesOrderlinesSelected() && this.selectedOrderLines.length > 1) {
            const differentSpectaclesOrderIds =
                this.selectedOrderLines[0].OrderId !== this.selectedOrderLines[1].OrderId;
            if (differentSpectaclesOrderIds) {
                return false;
            }
        }

        return true;
    }

    private areSpectaclesOrderlinesSelected(): boolean {
        return (
            this.selectedOrderLines && this.selectedOrderLines.some((x) => x.FittedLens.LensTypeId === LensTypes.Glass)
        );
    }

    reOrder(): void {
        const requestModel = new ReOrder();
        const ordersRequests: Observable<OrderWithComplexData>[] = [];

        if (this.leftLensOrderId) {
            ordersRequests.push(this.orderService.getByIdWithComplexData(this.leftLensOrderId));
        }
        if (this.rightLensOrderId) {
            ordersRequests.push(this.orderService.getByIdWithComplexData(this.rightLensOrderId));
        }

        forkJoin(ordersRequests).subscribe((orders) => {
            orders.forEach((order) => {
                requestModel.Order = order;
                if (this.rightLensOrderId === order.Id) {
                    requestModel.RightFittedLens = order.RightFittedLens;
                }
                if (this.leftLensOrderId === order.Id) {
                    requestModel.LeftFittedLens = order.LeftFittedLens;
                }
            });
            const options: unknown = {
                initialState: {
                    reOrder: requestModel,
                },
                class: 'modal-half',
            };
            this.modalService.show(ReOrderDialogComponent, options);
        });
    }
}
