<div class="supportorder flex-fill">
    <ng-container *ngIf="!loading">
        <ps-header #psHeader [psSupportOrderComponent]="this"></ps-header>

        <div class="supportorder-page d-flex">
            <div class="w-100 h-100">
                <div class="p-4" *ngIf="currentPage === 'attachments'">
                    <ps-attachments [psSupportOrderComponent]="this" [client]="getClient()"></ps-attachments>
                </div>

                <div class="h-100" *ngIf="currentPage === 'clientAndOptician'">
                    <ps-client-and-optician [psSupportOrderComponent]="this"></ps-client-and-optician>
                </div>

                <div class="p-4" *ngIf="currentPage === 'measurements'">
                    <ps-measurements [client]="getClient()"></ps-measurements>
                </div>

                <div class="p-4" *ngIf="currentPage === 'order'">
                    <div class="form-group row horizontal-chats">
                        <div class="col-6">
                            <client-communication
                                [clientId]="getClient().Id"
                                [orderId]="order.Id"
                                [showClientNotes]="true"
                                [id]="'ExternalNotes'"
                                [useAutoGrow]="true"
                                [showAttachments]="true"
                                [isReadOnly]="false"
                                addClassName="ps-chat"
                                [(sessionStorageKeysClientCommunication)]="sessionStorageKeys"
                                type="{{ 'ps.ExternalCommunication' | translate }}"></client-communication>
                        </div>
                        <div class="col-6">
                            <client-communication
                                [clientId]="getClient().Id"
                                [showInternalNotes]="true"
                                [id]="'InternalNotes'"
                                [useAutoGrow]="true"
                                [isReadOnly]="false"
                                addClassName="ps-chat"
                                [(sessionStorageKeysClientCommunication)]="sessionStorageKeys"
                                type="{{ 'ps.InternalCommunication' | translate }}"></client-communication>
                        </div>
                    </div>
                    <ps-order
                        [psSupportOrderComponent]="this"
                        [(sessionStorageKeysOrder)]="sessionStorageKeys"></ps-order>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="container mt-4 d-flex justify-content-center" *ngIf="loading">
        <div class="spinner-border spinner-border-sm"></div>
    </div>
</div>
