<password-expiry></password-expiry>
<div class="container mt-4">
    <div class="row">
        <div class="col-md-12 text-center">
            <ng-container *ngIf="appState.authenticatedUser">
                <h2 id="welcome">
                    {{ 'home.welcome' | translate }}
                    {{ appState.authenticatedUser.Name }}
                </h2>
            </ng-container>
        </div>
    </div>
</div>
<div class="m-4">
    <div class="container-fluid cards home-nav">
        <div class="row">
            <div class="col-lg-10 offset-lg-1 px-0">
                <div class="d-flex justify-content-center">
                    <div class="col-4">
                        <div id="serviceRequest" class="card" routerLink="/translator/translations">
                            <div class="card-body d-flex flex-column align-items-center justify-content-center">
                                <div class="icon">
                                    <i class="fa fa-language fa-5x"></i>
                                </div>
                                <div class="title">
                                    {{ 'translator.pageTranslate' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
