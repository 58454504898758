import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addSpacesToCommas',
})
export class AddSpacesToCommasPipe implements PipeTransform {
    transform(str?: string): string {
        return str ? str.replace(/,/g, ', ') : '';
    }
}
