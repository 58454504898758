import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'splitString',
})
export class SplitStringPipe implements PipeTransform {
    transform(string: string, divider: string): string[] {
        return string.split(divider);
    }
}
