import {
    Component,
    Input,
    ViewEncapsulation,
    Optional,
    Host,
    SkipSelf,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseControl } from '@app/shared/components/inputs/base-control';
import { NumberFormatPipe } from '@app/shared/pipes/number.format.pipe';
import { RangeLogic } from './range.logic';
import { InputNumberRange } from '@app/shared/models/input-number-range.model';

@Component({
    selector: 'mpc-input-number',
    templateUrl: './input-number.component.html',
    styleUrls: ['./input-number.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputNumberComponent,
            multi: true,
        },
    ],
})
export class InputNumberComponent extends BaseControl implements OnInit {
    @Input() maxLength = 999;
    @Input() useAutoFill = false;
    @Input() unit: string;

    protected inputNumberRanges: InputNumberRange[];
    @Input('ranges')
    set ranges(rangesValue: InputNumberRange[]) {
        this.inputNumberRanges = rangesValue;
        this.initRangeValues();
    }

    @Input() min: number;
    @Input() max: number;
    @Input() stepSize: number;

    @Input() formatting: unknown;
    @Input() originalValue: number;

    modified = false; // Indicates if the original value has been changed
    formattedValue: string;
    step = 1;
    doShowPopover = false;
    rangeValues = new Array<number>();
    numberLabelClass: string[] = [];

    @Input() numberOfDecimals = 2;

    @Output() change = new EventEmitter();
    @Output() focusEvent = new EventEmitter();

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        public controlContainer: ControlContainer,
        public numberFormatter: NumberFormatPipe,
    ) {
        super(controlContainer);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initRangeValues();
    }

    initRangeValues(): void {
        this.rangeValues.splice(0);

        for (let i = 0; this.inputNumberRanges && i < this.inputNumberRanges.length; i++) {
            const range: InputNumberRange = this.inputNumberRanges[i];

            for (let j = range.Minimum; j <= range.Maximum; j = Math.round((j + range.Step) * 100) / 100) {
                this.rangeValues.push(j);
            }
        }

        this.rangeValues = this.rangeValues.sort((a, b) => {
            if (a === Infinity) {
                return 1;
            }
            if (isNaN(a)) {
                return -1;
            }
            return a - b;
        });

        this.rangeValues = [...new Set(this.rangeValues)];
    }

    writeValue(value: number): void {
        if (!this.originalValue) {
            this.originalValue = value;
        }

        this.changeAndFormat(value, false);
    }

    validateInput(event: KeyboardEvent): void {
        const pattern = this.inputNumberRanges && this.inputNumberRanges.length > 0 ? /[0-9\+\-\.\,\ ]/ : /[0-9\+\-\ ]/;
        if (!pattern.test(event.key)) {
            event.preventDefault();
        }
    }

    increment(): void {
        this.refreshValue(+1);
        this.change.emit(this);
    }

    decrement(): void {
        this.refreshValue(-1);
        this.change.emit(this);
    }

    public refreshValue(n: number): void {
        let value = Number(this.value);

        if (!value || isNaN(this.value)) {
            value = this.getRangeStartOrAverage();
        }

        const currentIndex = this.rangeValues.indexOf(value);
        value = this.rangeValues[currentIndex];

        if (currentIndex + n >= 0 && currentIndex + n <= this.rangeValues.length - 1) {
            value = this.rangeValues[currentIndex + n];
        }

        if (this.inputNumberRanges && this.inputNumberRanges.length > 0) {
            const logic = new RangeLogic(this.inputNumberRanges, this.numberOfDecimals);
            value = logic.execute(value);
        }

        this.changeAndFormat(value, true);
    }

    changeAndFormat(value: number, emitValue: boolean): void {
        if (this.value !== value && emitValue) {
            this.onChange(value);
        }

        this.value = value;
        this.formattedValue = this.numberFormatter.transform(this.value, this.formatting);
        this.modified = this.originalValue !== this.value;
    }

    getRangeStartOrAverage() {
        if (this.value === 0) {
            return 0;
        }

        // input specific average values
        switch (this.formControlName) {
            case 'axis':
                return 180;
            case 'cylinder':
                return 0;
        }

        if (this.inputNumberRanges && this.inputNumberRanges.length > 0) {
            if (!!this.inputNumberRanges[0].Start) {
                let startRange = this.inputNumberRanges[0].Start;

                // Make sure the start value is within the given parameter ranges, there might be a rounding issue when it is calculated mm to dpt
                let rounding = 100;
                do {
                    if (this.rangeValues.indexOf(startRange) !== -1) {
                        return startRange;
                    }

                    rounding = rounding / 10;

                    // Strip decimals and round to 0.5
                    startRange = Math.round(startRange * rounding * 0.5) / rounding / 0.5;
                } while (rounding >= 1);
            }

            if ('Minimum' in this.inputNumberRanges[0] && 'Maximum' in this.inputNumberRanges[0]) {
                // return average between minimum and maximum
                const avg =
                    this.inputNumberRanges[0].Minimum +
                    (this.inputNumberRanges[0].Maximum - this.inputNumberRanges[0].Minimum) / 2.0;
                // find closest match in range
                return this.rangeValues.reduce((prev, curr) => {
                    return Math.abs(curr - avg) < Math.abs(prev - avg) ? curr : prev;
                });
            }
        }

        return 0;
    }

    refreshSubClasses(): void {
        this.numberLabelClass = this.getNumberLabelClass();
    }

    // 'Override' for input number control type to mark as touched
    getNumberLabelClass(): string[] {
        const result = this.labelClass;

        if (this.modified && this.markLabelAsTouched) {
            result.push('inputNumberTouched ');
        }

        return result;
    }

    onChangeInput($event: Event) {
        this.formattedValue = ($event.target as HTMLInputElement).value;
        let newValueString = this.formattedValue?.toString().trim() ?? '';
        newValueString = newValueString.replace(/,/g, '.');
        if (isNaN(parseFloat(newValueString)) || newValueString === '') {
            this.changeAndFormat(null, true);
            this.change.emit(this);
            return;
        }

        const value = Number(newValueString);
        let newValue: number;

        if (this.inputNumberRanges && this.inputNumberRanges.length > 0) {
            const logic = new RangeLogic(this.inputNumberRanges, this.numberOfDecimals);
            const changedValue = logic.execute(value);
            if (changedValue !== value) {
                this.doShowPopover = true;

                setTimeout(() => {
                    this.doShowPopover = false;
                }, 3000);
            }
            newValue = changedValue;
        } else {
            newValue = value;
        }

        this.changeAndFormat(newValue, true);
        this.change.emit(this);
    }

    onInputFocus($event: Event): void {
        this.focusEvent.emit($event);
    }
}
