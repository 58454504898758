import { Pipe, PipeTransform } from '@angular/core';
import { InputTypes } from '../enums';
import { ParameterType } from '../models';

@Pipe({
    name: 'validateInputType',
})
export class InputTypeValidationPipe implements PipeTransform {
    transform(parameter: ParameterType, inputType: InputTypes) {
        return parameter.InputTypeId === inputType;
    }
}
