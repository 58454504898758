import { Input, OnInit, Output, EventEmitter, Component, DestroyRef, inject } from '@angular/core';
import { CartItemLineInfo } from '@app/shared/models/cartItemLineInfo.model';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UpdateCartItemQuantityRequest } from '@app/shared/models/updateCartItemQuantityRequest.model';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    template: '',
})
export abstract class CartItemBaseTableComponent implements OnInit {
    @Input() items: CartItemLineInfo[];
    @Input() itemColors: string[];
    @Output() deleteItemEvent = new EventEmitter<CartItemLineInfo>();
    @Output() updateItemQuantityEvent = new EventEmitter<UpdateCartItemQuantityRequest>();

    public mainFormGroup: FormGroup;
    public currentSortColumn = '';
    public currentSortOrder = '';

    private readonly destroyRef = inject(DestroyRef);

    constructor(
        protected readonly fb: FormBuilder,
        protected appStateService: AppStateService,
    ) {}

    async ngOnInit() {
        this.renderForm(this.items);
    }

    private renderForm(data: CartItemLineInfo[]): void {
        this.mainFormGroup = this.fb.group({
            itemsFormArray: this.fb.array(this.getRowFormGroups(data)),
        });
    }

    private getRowFormGroups(data: CartItemLineInfo[]): FormGroup[] {
        const rowFormGroups = [];

        data.forEach((cartLine: CartItemLineInfo) => {
            const rowFormGroup = new FormGroup({
                quantity: new FormControl(cartLine.Quantity),
            });

            const quantityControl = rowFormGroup.controls['quantity'];
            quantityControl.patchValue(cartLine.Quantity);
            quantityControl.setValidators(Validators.required);
            quantityControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((x) => {
                this.updateItemQuantity(cartLine, x);
            });

            rowFormGroups.push(rowFormGroup);
        });

        return rowFormGroups;
    }

    applySort(event: MouseEvent, columnName: string): void {
        event.preventDefault();

        if (this.currentSortColumn === columnName) {
            // Previous sort was on same column, change sortorder
            this.currentSortOrder = this.currentSortOrder === 'desc' ? 'asc' : 'desc';
        } else {
            this.currentSortOrder = 'desc'; // Default descending, largest on top
        }
        this.currentSortColumn = columnName;

        if (this.currentSortOrder === 'asc') {
            this.items = this.items.sort((a, b) => {
                return a[this.currentSortColumn] - b[this.currentSortColumn];
            });
        } else {
            this.items = this.items.sort((a, b) => {
                return b[this.currentSortColumn] - a[this.currentSortColumn];
            });
        }
    }

    deleteItem(value: CartItemLineInfo) {
        this.deleteItemEvent.emit(value);
    }

    updateItemQuantity(cartItem: CartItemLineInfo, newValue: number) {
        const request = new UpdateCartItemQuantityRequest(cartItem.CartItemId, newValue, cartItem.EyeSideId);
        this.updateItemQuantityEvent.emit(request);
    }
}
