import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileDownloadService } from '../file-download.service';

@Injectable({
    providedIn: 'root',
})
export class DocumentService {
    private readonly basePath = 'api/documents/';

    constructor(private readonly fileDownloadService: FileDownloadService) {}

    public downloadDreamLiteAgreement(clientId: number): Observable<File> {
        const options = {
            params: new HttpParams().set('clientId', clientId),
        };

        return this.fileDownloadService.downloadFile(`${this.basePath}/download/dream-lite-agreement`, options);
    }

    public downloadMiSightAgreement(clientId: number): Observable<File> {
        const options = {
            params: new HttpParams().set('clientId', clientId),
        };

        return this.fileDownloadService.downloadFile(`${this.basePath}/download/mi-sight-agreement`, options);
    }
}
