<div *ngIf="!loading" [formGroup]="formGroup">
    <div>
        <h4>{{ 'lens.manualCalculation' | translate }}</h4>
    </div>
    <div>
        <mpc-input-number
            formControlName="rZeroFlat"
            id="{{ id }}-rZeroFlat"
            [ranges]="rZeroParameterTypeRange"
            [formatting]="{ signedNumber: false, decimalCount: 2 }"
            [maxLength]="4"
            label="measurement.rzeroflat"
            [breakpoint]="'md'"
            [info]="validationTextInfo"
            [controlHasFormError]="formGroup.errors && formGroup.errors.rSteepSmallerOrEqualTorFlat"
            [unit]="generalUnitToUse"></mpc-input-number>
        <mpc-input-number
            formControlName="rZeroSteep"
            id="{{ id }}-rZeroSteep"
            [ranges]="rZeroParameterTypeRange"
            [formatting]="{ signedNumber: false, decimalCount: 2 }"
            [maxLength]="4"
            label="measurement.rzerosteep"
            [breakpoint]="'md'"
            [info]="validationTextInfo"
            [controlHasFormError]="formGroup.errors && formGroup.errors.rSteepSmallerOrEqualTorFlat"
            [unit]="generalUnitToUse"></mpc-input-number>
        <mpc-input-number
            *ngIf="showAxisAngleFlatField"
            formControlName="axisAngleFlat"
            id="{{ id }}-axisAngleFlat"
            [ranges]="axisAngleParameterTypeRange"
            [formatting]="{ signedNumber: false }"
            [maxLength]="3"
            label="measurement.axisflat"
            [breakpoint]="'md'"
            [unit]="'units.degree'"></mpc-input-number>
    </div>
</div>
