import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, AbstractControl } from '@angular/forms';

@Injectable()
export class Util {
    public static validityClass(formControl: AbstractControl): unknown {
        if (formControl) {
            return {
                'is-invalid': formControl.invalid && (formControl.dirty || formControl.touched),
                'is-valid': formControl.valid && (formControl.dirty || formControl.touched),
            };
        }
        return '';
    }

    public static validateAllFormFields(form: UntypedFormGroup | UntypedFormArray) {
        Object.keys(form.controls).forEach((field) => {
            const control = form.get(field);
            if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
                this.validateAllFormFields(control);
            } else if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            }
        });
    }

    public static getFormErrors(form: UntypedFormGroup | UntypedFormArray): { [key: string]: unknown } | null {
        let hasError = false;
        const result = Object.keys(form.controls).reduce(
            (acc, key) => {
                const control = form.get(key);
                const errors =
                    control instanceof UntypedFormGroup || control instanceof UntypedFormArray
                        ? this.getFormErrors(control)
                        : control.errors;
                if (errors) {
                    acc[key] = errors;
                    hasError = true;
                }
                return acc;
            },
            {} as { [key: string]: unknown },
        );
        return hasError ? result : null;
    }

    public static hasRequiredField(abstractControl: AbstractControl): boolean {
        if (abstractControl.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        return false;
    }

    public static openBlobInBrowser(data: Blob, filename: string): void {
        const dataUrl = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = filename;

        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            }),
        );

        setTimeout(function () {
            // For Firefox, it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(dataUrl);
        }, 100);
    }
}
