import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { WorkItemsService } from '@app/core/services/api/work-items.service';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'workitem-already-assigned-dialog',
    templateUrl: 'workitem-already-assigned-dialog.component.html',
})
export class WorkitemAlreadyAssignedDialogComponent {
    @Input() onClose: (id) => Promise<unknown>;
    @Input() userId: number;
    @Input() orderId: number;

    constructor(
        private readonly bsModalRef: BsModalRef,
        private readonly workitemsService: WorkItemsService,
        private readonly router: Router,
    ) {}

    onBack(): void {
        this.router.navigate(['/ps/supportorderoverview']).then();
        this.bsModalRef.hide();
    }

    onContinue() {
        this.bsModalRef.hide();
    }

    async onContinueOnMyName() {
        await lastValueFrom(this.workitemsService.updateSupporter(this.orderId, this.userId));

        await this.onClose(this.userId);
        this.bsModalRef.hide();
    }
}
