<div class="container-fluid mt-4 px-lg-4">
    <div class="col-xl-10 offset-xl-1">
        <div class="row mb-2">
            <div class="col-12">
                <h3 class="mt-auto">{{ 'ps.lastorder' | translate }}</h3>
            </div>
        </div>

        <ps-order
            *ngIf="!loading && order"
            [psSupportOrderComponent]="this"
            [(sessionStorageKeysOrder)]="sessionStorageKeys"></ps-order>
        <div *ngIf="loading" class="row">
            <div class="col-12">
                <div class="spinner-border spinner-border-sm mx-1"></div>
            </div>
        </div>
    </div>
</div>
