export class HoverPositionCalculator {
    calculateCurrentXPositionRelativeToCenter(offset: number, imageSizeMm: number, centerPoint: number): number {
        //Get position of mouse in relation to center
        let position: number;
        position = offset - centerPoint;
        //calculate to mm
        const calculationFactor = centerPoint / (imageSizeMm / 2);
        position = position / calculationFactor;
        return position;
    }

    calculateCurrentYPositionRelativeToCenter(offset: number, imageSizeMm: number, centerPoint: number): number {
        //Get position of mouse in relation to center
        let position: number;
        position = centerPoint - offset;
        //calculate to mm
        const calculationFactor = centerPoint / (imageSizeMm / 2);
        position = position / calculationFactor;
        return position;
    }

    calculateCurrentAngle(x: number, y: number): number {
        //Calculate angle
        let angle = (Math.atan2(y, x) / Math.PI) * 180.0;
        if (angle < 0) {
            angle = angle + 360.0;
        }
        return angle;
    }

    calculateDistanceToCenter(x: number, y: number) {
        const total = Math.pow(Math.abs(x), 2) + Math.pow(Math.abs(y), 2);

        return Math.sqrt(total);
    }
}
