import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'netAmount',
})
export class NetAmountPipe implements PipeTransform {
    transform(orderDetails: { LineAmount: number; SalesQty: number }): number {
        if (orderDetails && orderDetails.LineAmount && orderDetails.SalesQty) {
            return orderDetails.LineAmount / orderDetails.SalesQty;
        }
        return 0;
    }
}
