import { Component, Input } from '@angular/core';
import { ShopService } from '@app/core/services/shop.service';
import { DataSheet } from '@app/shared/models';

@Component({
    selector: 'product-data-sheets',
    templateUrl: 'product-data-sheets.component.html',
})
export class ProductDataSheetsComponent {
    @Input() Id: string;
    @Input() productDataSheets: DataSheet[];

    constructor(public shopService: ShopService) {}
}
