import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ListOption } from '@app/shared/models';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceRequest } from '@app/shared/models/service-request.model';
import { ReturnConclusion } from '@app/shared/models/ReturnConclusion.model';
import { AlertService } from '@app/shared/appservices/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmService } from '@app/shared/appservices/confirm.service';
import { ServiceStatus } from '@app/shared/enums';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { finalize, map, startWith } from 'rxjs/operators';
import { ServiceType } from '@app/shared/enums';
import { ReturnType } from '@app/shared/models/returnType.model';
import { Observable, lastValueFrom } from 'rxjs';
import { Util } from '@app/shared/helpers/utility.helper';
import { QaProcessComplaintComponent } from '@app/shared/components/qa-process-complaint/qa-process-complaint.component';
import { ReturnService } from '@app/core/services/api/return.service';

interface ServiceRequestFormGroup {
    internalFinding: FormControl<string | null>;
    returnStatus: FormControl<number>;
    returnResult: FormControl<number>;
    returnCredit: FormControl<number>;
    dynamicsDisposition: FormControl<number>;
    dynamicsReturnReason: FormControl<number>;
    conclusion: FormControl<string | null>;
    vigilance: FormControl<boolean>;
    returnCategory: FormControl<number>;
    returnType: FormControl<number>;
}

@Component({
    selector: 'app-complaint',
    templateUrl: './complaint.component.html',
    styleUrls: ['./complaint.component.scss'],
})
export class ComplaintComponent implements OnInit {
    ServiceType = ServiceType;

    returnId: number;
    serviceRequestFormGroup: FormGroup<ServiceRequestFormGroup>;
    returnStatuses: ListOption[];
    returnResults: ListOption[];
    returnCredits: ListOption[];
    returnCategories: ListOption[];
    returnTypes: ReturnType[];
    dynamicsDispositions: ListOption[];
    dynamicsReturnReasons: ListOption[];
    filteredReturnTypes$: Observable<ReturnType[]>;
    formChanged: boolean;
    isNavigateToClientAvailable: boolean;
    isServiceRequestCompleted: boolean;

    loading = true;
    isQualityControlMeasurementExpanded = false;
    canCloseServiceRequest = false;
    orderShippedDate = '';

    public activeServiceRequest: ServiceRequest;
    public returnConclusion: ReturnConclusion;

    @ViewChild('qcmeasurement')
    qaProcessComplaintComponent: QaProcessComplaintComponent;

    constructor(
        private route: ActivatedRoute,
        private readonly router: Router,
        private readonly alertService: AlertService,
        private readonly confirmService: ConfirmService,
        private readonly translateService: TranslateService,
        private readonly loaderService: LoaderService,
        private readonly returnService: ReturnService,
    ) {}

    async ngOnInit() {
        this.returnId = +this.route.snapshot.paramMap.get('id');
        if (!this.returnId) {
            this.gotoOverview();
        }

        this.createForms();

        await lastValueFrom(this.returnService.getReturnAndReturnConclusion(this.returnId)).then((result) => {
            this.activeServiceRequest = result.Return;
            this.returnConclusion = result.ReturnConclusion;
            if (this.activeServiceRequest.OrderLine?.ShippedDate != null) {
                this.orderShippedDate = new Date(this.activeServiceRequest.OrderLine.ShippedDate).toLocaleDateString();
            }
        });

        const statuses = await lastValueFrom(this.returnService.getReturnStatuses());
        this.returnStatuses = statuses.filter((s) => s.Id !== ServiceStatus.Feedback);
        this.returnResults = await lastValueFrom(this.returnService.getReturnResults());
        this.returnCredits = await lastValueFrom(this.returnService.getReturnCredits());
        this.returnCategories = await lastValueFrom(this.returnService.getReturnCategories());
        this.returnTypes = await lastValueFrom(this.returnService.getReturnTypes());
        this.dynamicsDispositions = await lastValueFrom(this.returnService.getDynamicsDispositions());
        this.dynamicsReturnReasons = await lastValueFrom(this.returnService.getDynamicsReturnReasons());

        this.loadControlValues();
        this.isNavigateToClientAvailable = !!(this.activeServiceRequest && this.activeServiceRequest.ClientId);
        this.isServiceRequestCompleted = this.activeServiceRequest?.ReturnStatusId === ServiceStatus.Handled;
        this.canCloseServiceRequest = !this.isServiceRequestCompleted && this.serviceRequestFormGroup.valid;
        this.loading = false;
    }

    async save(closeServiceRequest: boolean, redirectToOverview = true) {
        this.loaderService.show();
        this.setReturnConclusionVariables();

        if (closeServiceRequest) {
            this.loaderService.hide();
            const pageTitle = this.translateService.instant('service.closeServiceRequest');
            const confirmMessage = this.translateService.instant('service.closeServiceRequestConfirmMessage');
            const isConfirmed = await this.confirmService.show(pageTitle, confirmMessage);

            if (isConfirmed) {
                this.loaderService.show();
                this.returnConclusion.ReturnStatusId = ServiceStatus.Handled;
            }
        }

        const saveReturnConclusionResult = await this.saveReturnConclusion();
        this.loaderService.hide();
        if (saveReturnConclusionResult) {
            this.alertService.success(this.translateService.instant('general.saveSuccessful'));
            if (redirectToOverview) {
                this.gotoOverview();
            }
        }
    }

    async saveReturnConclusion(): Promise<boolean> {
        await lastValueFrom(this.returnService.savePSReturnConclusion(this.returnConclusion)).then(
            () => {
                return true;
            },
            (error) => {
                this.alertService.error(this.translateService.instant(error.error.Message));
                return false;
            },
        );

        return true;
    }

    gotoOverview() {
        this.router.navigate(['/ps/complaintsoverview']).then();
    }

    createForms(): void {
        this.serviceRequestFormGroup = new FormGroup<ServiceRequestFormGroup>({
            internalFinding: new FormControl(''),
            returnStatus: new FormControl(0),
            returnResult: new FormControl(0),
            returnCredit: new FormControl(0),
            dynamicsDisposition: new FormControl<number>(null, [Validators.required]),
            dynamicsReturnReason: new FormControl<number>(null, [Validators.required]),
            conclusion: new FormControl(''),
            vigilance: new FormControl(false),
            returnCategory: new FormControl<number>(0),
            returnType: new FormControl<number>(0),
        });

        this.serviceRequestFormGroup.valueChanges.subscribe(() => {
            this.formChanged = !!this.serviceRequestFormGroup && this.serviceRequestFormGroup.dirty;
            this.canCloseServiceRequest = !this.isServiceRequestCompleted && this.serviceRequestFormGroup.valid;
        });
    }

    reset() {
        this.ngOnInit().then();
    }

    loadControlValues(): void {
        this.serviceRequestFormGroup.patchValue({
            internalFinding: this.activeServiceRequest.InternalConclusion,
            returnStatus: this.activeServiceRequest.ReturnStatusId,
            vigilance: this.activeServiceRequest.Vigilance,
            returnCategory: this.activeServiceRequest.ReturnCategoryId,
            returnType: this.activeServiceRequest.ReturnTypeId,
        });

        this.serviceRequestFormGroup.controls.returnCategory.setValue(this.activeServiceRequest.ReturnCategoryId);

        if (this.returnConclusion) {
            this.serviceRequestFormGroup.patchValue({
                conclusion: this.returnConclusion.Conclusion,
                returnResult: this.returnConclusion.ReturnResultId,
                returnCredit: this.returnConclusion.ReturnCreditId,
                dynamicsDisposition: this.returnConclusion.DynamicsDispositionId,
                dynamicsReturnReason: this.returnConclusion.DynamicsReturnReasonId,
            });
        }

        const filterReturnTypesByCategory = (returnCategoryId: number) =>
            this.returnTypes.filter((returnType) => returnType.ReturnCategoryId === +returnCategoryId);

        this.filteredReturnTypes$ = this.serviceRequestFormGroup.controls.returnCategory.valueChanges.pipe(
            startWith(this.serviceRequestFormGroup.controls.returnCategory.value),
            map(filterReturnTypesByCategory),
        );
    }

    setReturnConclusionVariables(): void {
        if (this.returnConclusion == null) {
            this.returnConclusion = new ReturnConclusion();
            this.returnConclusion.ReturnId = this.returnId;
        }

        this.returnConclusion.Conclusion = this.serviceRequestFormGroup.controls['conclusion'].value;
        this.returnConclusion.InternalConclusion = this.serviceRequestFormGroup.controls['internalFinding'].value;
        this.returnConclusion.ReturnStatusId = Number(this.serviceRequestFormGroup.controls['returnStatus'].value);
        this.returnConclusion.Vigilance = !!this.serviceRequestFormGroup.controls['vigilance'].value;

        this.returnConclusion.ReturnResultId =
            this.serviceRequestFormGroup.controls['returnResult'].value != null
                ? Number(this.serviceRequestFormGroup.controls['returnResult'].value)
                : null;
        this.returnConclusion.ReturnCreditId =
            this.serviceRequestFormGroup.controls['returnCredit'].value != null
                ? Number(this.serviceRequestFormGroup.controls['returnCredit'].value)
                : null;

        this.returnConclusion.DynamicsDispositionId = this.serviceRequestFormGroup.controls['dynamicsDisposition'].value
            ? Number(this.serviceRequestFormGroup.controls['dynamicsDisposition'].value)
            : null;

        this.returnConclusion.DynamicsReturnReasonId = this.serviceRequestFormGroup.controls['dynamicsReturnReason']
            .value
            ? Number(this.serviceRequestFormGroup.controls['dynamicsReturnReason'].value)
            : null;

        this.returnConclusion.ReturnCategoryId = +this.serviceRequestFormGroup.controls.returnCategory.value;
        this.returnConclusion.ReturnTypeId = +this.serviceRequestFormGroup.controls.returnType.value;
    }

    toggleQualityControlMeasurement(): void {
        this.isQualityControlMeasurementExpanded = !this.isQualityControlMeasurementExpanded;
    }

    public navigateToClient() {
        const url = this.router
            .createUrlTree([`ps/clientmaintenance/${this.activeServiceRequest.ClientId}`])
            .toString();
        window.open(url, '_blank');
    }

    downloadPdf() {
        this.loaderService.show();
        this.returnService
            .downloadPdf(this.returnId)
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe((file) => {
                Util.openBlobInBrowser(file, file.name);
            });
        this.loaderService.hide();
    }

    async saveAndDownloadPdf(): Promise<void> {
        await this.save(false, false);
        this.loaderService.show();
        this.returnService
            .downloadPdf(this.returnId)
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe((file) => {
                Util.openBlobInBrowser(file, file.name);
            });
        this.loaderService.hide();
        this.gotoOverview();
    }
}
