import {
    FittedLens,
    FittedLensParameter,
    LensDefinitionParameterNumberRange,
    LensDefinitionParameterRange,
    MaterialColor,
} from '@app/shared/models';
import { FieldValueExpressionCalculator } from './field-value-expression.calculator';
import { SpheroCylinder } from '../models/SpheroCylinder.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class RgpFittedLensCalculator {
    constructor(public fieldValueExpressionCalculator: FieldValueExpressionCalculator) {}

    public recalculateMaterialChanged(currentFittedLens: FittedLens, originalMaterialColor: MaterialColor): FittedLens {
        const fittedLens = structuredClone(currentFittedLens);

        const rad = this.getFittedLensParameter(fittedLens, 'RAD');
        const arad = this.getFittedLensParameter(fittedLens, 'ARAD');

        if (rad != null && arad != null) {
            // Oude sterke binnenkant ast meridiaan
            const ntear = 1.336;

            const nlens = originalMaterialColor.Material.RefractiveIndex;
            const nlensNew = fittedLens.MaterialColor.Material.RefractiveIndex;
            const cylast = ((nlens - ntear) * 1000.0) / -arad.Value - ((nlens - ntear) * 1000.0) / -rad.Value;
            const cylastNew = ((nlensNew - ntear) * 1000.0) / -arad.Value - ((nlensNew - ntear) * 1000.0) / -rad.Value;
            const inducedRefChange = new SpheroCylinder(0.0, 0.0, 0.0);

            inducedRefChange.cylinder = cylastNew - cylast;
            inducedRefChange.axis = fittedLens.TopographicMeasurement.SimKAxisFlat;
            inducedRefChange.sphere = 0.0;

            const residualRef = new SpheroCylinder(0.0, 0.0, 0.0);
            residualRef.sphere = fittedLens.ResidualRefractionSphere;
            residualRef.cylinder = fittedLens.ResidualRefractionCylinder;
            residualRef.axis = fittedLens.ResidualRefractionAx;

            const res = RgpFittedLensCalculator.substractSpheroCylinders(residualRef, inducedRefChange);
            fittedLens.ResidualRefractionCylinder = res.cylinder;
            fittedLens.ResidualRefractionAx = res.axis;
            fittedLens.ResidualRefractionSphere = res.sphere;
        }
        return fittedLens;
    }

    public recalculateFittedLens(
        currentFittedLens: FittedLens,
        originalFittedLens: FittedLens,
        updatedLensParameterTypeCode: string,
    ): Observable<FittedLens> {
        const fittedLens = structuredClone(currentFittedLens);
        const rad = this.getFittedLensParameter(originalFittedLens, 'RAD');
        const radNew = this.getFittedLensParameter(fittedLens, 'RAD');
        const arad = this.getFittedLensParameter(originalFittedLens, 'ARAD');
        const aradNew = this.getFittedLensParameter(fittedLens, 'ARAD');
        const pow = this.getFittedLensParameter(originalFittedLens, 'POW');
        const powNew = this.getFittedLensParameter(fittedLens, 'POW');
        const cyl = this.getFittedLensParameter(originalFittedLens, 'CYL');
        const cylNew = this.getFittedLensParameter(fittedLens, 'CYL');
        const ax = this.getFittedLensParameter(originalFittedLens, 'AX');
        const axNew = this.getFittedLensParameter(fittedLens, 'AX');
        const nlens = fittedLens.MaterialColor.Material.RefractiveIndex;
        const flatAxis = fittedLens.TopographicMeasurement.SimKAxisFlat;
        const ntear = 1.336;

        let residualRefr = new SpheroCylinder(
            originalFittedLens.ResidualRefractionSphere,
            originalFittedLens.ResidualRefractionCylinder,
            originalFittedLens.ResidualRefractionAx,
        );

        let manualCorrection = new SpheroCylinder(
            originalFittedLens.ManualCorrectionSphere,
            originalFittedLens.ManualCorrectionCyl,
            originalFittedLens.ManualCorrectionAx,
        );

        const inducedRefr = new SpheroCylinder(0.0, 0.0, 0.0);

        if (updatedLensParameterTypeCode === 'RAD') {
            inducedRefr.sphere = -(ntear - 1.0) * (1000 / +radNew.Value - 1000 / +rad.Value);

            if (arad != null) {
                inducedRefr.cylinder = -(ntear - nlens) * (1000 / +rad.Value - 1000 / +radNew.Value);
                inducedRefr.axis = flatAxis;
            } else {
                inducedRefr.cylinder = 0.0;
                inducedRefr.axis = 0.0;
            }
        }

        if (updatedLensParameterTypeCode === 'ARAD') {
            inducedRefr.cylinder = -(ntear - nlens) * (1000 / +aradNew.Value - 1000 / +arad.Value);
            inducedRefr.axis = flatAxis;
        }

        if (updatedLensParameterTypeCode === 'RAD' || updatedLensParameterTypeCode === 'ARAD') {
            residualRefr = RgpFittedLensCalculator.addSpheroCylinders(residualRefr, manualCorrection);
            const inducedResidualRefraction = RgpFittedLensCalculator.addSpheroCylinders(residualRefr, inducedRefr);

            const recept = new SpheroCylinder(+pow.Value, 0.0, 0.0);
            if (cyl != null && ax != null) {
                if (arad == null) {
                    recept.cylinder = +cyl.Value;
                    recept.axis = +ax.Value;
                } else {
                    // Stabilisatie op vlakke meridiaan
                    recept.cylinder = +cyl.Value;
                    recept.axis = +ax.Value + flatAxis;
                    if (recept.axis > 180.0) {
                        recept.axis = recept.axis - 180.0;
                    }
                }
            }

            const receptNew = RgpFittedLensCalculator.addSpheroCylinders(recept, inducedResidualRefraction);
            powNew.Value = this.adjustParameterValue(
                receptNew.sphere,
                powNew.LensDefinitionParameter.LensDefinitionParameterRanges,
            );
            receptNew.sphere = +powNew.Value;

            if (cylNew != null && axNew != null) {
                cylNew.Value = this.adjustParameterValue(
                    receptNew.cylinder,
                    cylNew.LensDefinitionParameter.LensDefinitionParameterRanges,
                );

                receptNew.cylinder = +cylNew.Value;
                if (arad == null) {
                    axNew.Value = this.adjustParameterValue(
                        receptNew.axis,
                        axNew.LensDefinitionParameter.LensDefinitionParameterRanges,
                    );

                    receptNew.axis = +axNew.Value;
                } else {
                    let axisMod180 = receptNew.axis - flatAxis;

                    if (axisMod180 < 0.0) {
                        axisMod180 += 180.0;
                    }
                    axNew.Value = this.adjustParameterValue(
                        axisMod180,
                        axNew.LensDefinitionParameter.LensDefinitionParameterRanges,
                    );

                    receptNew.axis = +axNew.Value + flatAxis;
                }
            } else {
                receptNew.cylinder = 0.0;
                receptNew.axis = 0.0;
            }

            const correction = RgpFittedLensCalculator.substractSpheroCylinders(receptNew, recept);
            let newResidualRefraction = RgpFittedLensCalculator.substractSpheroCylinders(
                inducedResidualRefraction,
                correction,
            );
            newResidualRefraction = RgpFittedLensCalculator.substractSpheroCylinders(
                newResidualRefraction,
                manualCorrection,
            );

            fittedLens.ResidualRefractionSphere = newResidualRefraction.sphere;
            fittedLens.ResidualRefractionCylinder = newResidualRefraction.cylinder;
            fittedLens.ResidualRefractionAx = newResidualRefraction.axis;
        }

        if (
            updatedLensParameterTypeCode === 'POW' ||
            updatedLensParameterTypeCode === 'CYL' ||
            updatedLensParameterTypeCode === 'AX'
        ) {
            const recept0 = new SpheroCylinder(+pow.Value, 0.0, 0.0);
            if (cyl != null && ax != null) {
                if (arad == null) {
                    recept0.cylinder = +cyl.Value;
                    recept0.axis = +ax.Value;
                } else {
                    recept0.cylinder = +cyl.Value;
                    recept0.axis = +ax.Value + flatAxis;
                }
            }

            const recept2 = new SpheroCylinder(+powNew.Value, 0.0, 0.0);
            if (cylNew != null && axNew != null) {
                if (arad == null) {
                    recept2.cylinder = +cylNew.Value;
                    recept2.axis = +axNew.Value;
                } else {
                    recept2.cylinder = +cylNew.Value;
                    recept2.axis = +axNew.Value + flatAxis;
                }
            }

            const cor = RgpFittedLensCalculator.substractSpheroCylinders(recept2, recept0);
            manualCorrection = RgpFittedLensCalculator.addSpheroCylinders(manualCorrection, cor);

            fittedLens.ManualCorrectionSphere = manualCorrection.sphere;
            fittedLens.ManualCorrectionCyl = manualCorrection.cylinder;
            fittedLens.ManualCorrectionAx = manualCorrection.axis;

            const newResidu = RgpFittedLensCalculator.substractSpheroCylinders(residualRefr, cor);

            fittedLens.ResidualRefractionSphere = newResidu.sphere;
            fittedLens.ResidualRefractionCylinder = newResidu.cylinder;
            fittedLens.ResidualRefractionAx = newResidu.axis;
        }
        return this.fieldValueExpressionCalculator.recalculateFieldValueExpressions(fittedLens, [
            updatedLensParameterTypeCode,
        ]);
    }

    private static addSpheroCylinders(sc1: SpheroCylinder, sc2: SpheroCylinder): SpheroCylinder {
        const pi = 3.14159;

        // Convert first sphero cyl to power profile
        const m1 = sc1.sphere + sc1.cylinder / 2.0;
        let j = sc1.cylinder / 2.0;
        let axis = sc1.axis - 90.0;

        const j10 = j * Math.cos(((2.0 * axis) / 180.0) * pi);
        const j145 = j * Math.sin(((2.0 * axis) / 180.0) * pi);

        // Convert second sphero cyl to power profile
        const m2 = sc2.sphere + sc2.cylinder / 2.0;
        j = sc2.cylinder / 2.0;
        axis = sc2.axis - 90.0;

        const j20 = j * Math.cos(((2.0 * axis) / 180.0) * pi);
        const j245 = j * Math.sin(((2.0 * axis) / 180.0) * pi);

        // Add
        const m = m1 + m2;
        const j0 = j10 + j20;
        const j45 = j145 + j245;

        j = Math.sqrt(j0 * j0 + j45 * j45);
        if (j > 0.01) {
            axis = ((0.5 * Math.atan2(j45, j0)) / pi) * 180.0;
        } else {
            j = 0.0;
            axis = 0.0;
        }

        if (axis < 0.0) {
            axis += 180.0;
        }

        // J always positive, sphere max power. Therefore, cyl negative
        return new SpheroCylinder(m + j, -j * 2.0, axis);
    }

    private static substractSpheroCylinders(sc1: SpheroCylinder, spherocyl2: SpheroCylinder): SpheroCylinder {
        const pi = 3.14159;

        // Invert sc2
        const sc2 = new SpheroCylinder(-spherocyl2.sphere, -spherocyl2.cylinder, spherocyl2.axis);

        // Convert first sphero cyl to power profile
        const m1 = sc1.sphere + sc1.cylinder / 2.0;
        let j = sc1.cylinder / 2.0;
        let axis = sc1.axis - 90.0;

        const j10 = j * Math.cos(((2.0 * axis) / 180.0) * pi);
        const j145 = j * Math.sin(((2.0 * axis) / 180.0) * pi);

        // Convert second sphero cyl to power profile
        const m2 = sc2.sphere + sc2.cylinder / 2.0;
        j = sc2.cylinder / 2.0;
        axis = sc2.axis - 90.0;

        const j20 = j * Math.cos(((2.0 * axis) / 180.0) * pi);
        const j245 = j * Math.sin(((2.0 * axis) / 180.0) * pi);

        // Add
        const m = m1 + m2;
        const j0 = j10 + j20;
        const j45 = j145 + j245;

        j = Math.sqrt(j0 * j0 + j45 * j45);
        if (j > 0.01) {
            axis = ((0.5 * Math.atan2(j45, j0)) / pi) * 180.0;
        } else {
            j = 0.0;
            axis = 0.0;
        }

        if (axis < 0.0) {
            axis += 180.0;
        }

        // J alway positive, sphere max power. Therefore cyl negative
        return new SpheroCylinder(m + j, -j * 2.0, axis);
    }

    private adjustParameterValue(value: number, ranges: LensDefinitionParameterRange[]): number {
        const numberRanges = ranges as LensDefinitionParameterNumberRange[];
        const closestValues: number[] = [];
        let ii: number;

        for (ii = 0; ii < numberRanges.length; ii++) {
            const max = numberRanges[ii].Maximum;
            const min = numberRanges[ii].Minimum;
            const step = numberRanges[ii].Step;

            if (value > max) {
                closestValues.push(max);
            } else if (value < min) {
                closestValues.push(min);
            } else {
                const rounded = min + Math.round((value - min) / step) * step;
                closestValues.push(rounded);
            }
        }

        let closestValue = 999;
        let mindiff = -1;

        for (ii = 0; ii < closestValues.length; ii++) {
            const val = closestValues[ii];

            const diff = Math.abs(value - val);
            if (ii === 0 || diff < mindiff) {
                closestValue = val;
                mindiff = diff;
            }
        }

        return closestValue;
    }

    private getFittedLensParameter(fittedLens: FittedLens, code: string): FittedLensParameter {
        for (let i = 0; i < fittedLens.FittedLensParameters.length; i++) {
            const fittedLensParameter = fittedLens.FittedLensParameters[i];

            if (fittedLensParameter.LensDefinitionParameter.ParameterType.Code === code) {
                return fittedLensParameter;
            }
        }

        return null;
    }
}
