import { Injectable } from '@angular/core';
import { REGION_STORAGE_KEY } from '../constants/regions';

@Injectable()
export class LocalStorageService {
    getAsObject<T>(key: string): T {
        const content = localStorage.getItem(key);
        if (content && content !== 'undefined') {
            return JSON.parse(content) as T;
        }
        return null;
    }

    get(key: string): string {
        return localStorage.getItem(key);
    }

    save(key: string, data: unknown): void {
        const stringData = typeof data === 'string' ? data : JSON.stringify(data);
        localStorage.setItem(key, stringData);
    }

    remove(key: string): void {
        localStorage.removeItem(key);
    }

    clearAll(): void {
        // Region setting needs to be kept, the rest can be cleared.
        const region = this.get(REGION_STORAGE_KEY);
        localStorage.clear();
        this.save(REGION_STORAGE_KEY, region);
    }
}
