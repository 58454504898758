<div class="container-fluid orderstatuscontainer mt-4 px-lg-4 flex-fill">
    <div class="title-border-bottom mb-1">
        <h3 class="mt-auto">{{ 'orderstatus.pagetitle' | translate }}</h3>
    </div>

    <ng-container *ngIf="ordersWithNewMessage$ | async as ordersWithNewMessage">
        <div *ngIf="ordersWithNewMessage && ordersWithNewMessage.length > 0" class="my-2 unreadsupportorders">
            <div class="d-flex flex-column flex-md-row sticky-element">
                <div class="me-2">
                    <h4>{{ 'orderstatus.neworders' | translate }}</h4>
                </div>
                <div class="ms-md-auto">
                    <ng-container *ngTemplateOutlet="legend1"></ng-container>
                </div>
            </div>
            <div class="row no-gutters">
                <ng-container *ngFor="let order of ordersWithNewMessage; let i = index">
                    <div class="col-12 col-sm-6 col-md-4 col-xl-3 px-sm-2 py-1 px-xl-3 py-xl-2">
                        <order-status-item
                            [id]="id + '-messageorder-' + i"
                            [order]="order"
                            [isSelected]="selectedOrder && selectedOrder.Id === order.Id"
                            [hasNewMessage]="true"
                            (orderSelected$)="orderSelected($event)"></order-status-item>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <div class="allorders pb-2">
        <div class="d-flex flex-column flex-md-row sticky-element">
            <div class="me-2">
                <h4>{{ 'orderstatus.supportorders' | translate }}</h4>
            </div>

            <div
                *ngIf="isLoading.supportOrders || isReloading.supportOrders"
                class="spinner-border spinner-border-sm mx-1"></div>

            <ng-container *ngIf="supportOrders && supportOrders.length > 0">
                <div class="ms-md-auto">
                    <ng-container *ngTemplateOutlet="legend1"></ng-container>
                </div>
            </ng-container>
        </div>
        <ng-container *ngIf="supportOrders && supportOrders.length > 0">
            <div class="row no-gutters">
                <ng-container *ngFor="let order of supportOrders; let i = index">
                    <div class="col-12 col-sm-6 col-md-4 col-xl-3 px-sm-2 py-1 px-xl-3 py-xl-2">
                        <order-status-item
                            [id]="id + '-supportorder-' + i"
                            [order]="order"
                            [isSelected]="selectedOrder && selectedOrder.Id === order.Id"
                            (orderSelected$)="orderSelected($event)"></order-status-item>
                    </div>
                </ng-container>
            </div>
            <ng-container *ngIf="!supportOrders || supportOrders.length === 0">
                {{ 'general.noordersfound' | translate }}
            </ng-container>
        </ng-container>
    </div>

    <div class="allorders pb-2">
        <div class="d-flex flex-column flex-md-row sticky-element">
            <div class="me-2">
                <h4>{{ 'general.orders' | translate }}</h4>
            </div>

            <div *ngIf="isLoading.orders" class="spinner-border spinner-border-sm mx-1"></div>

            <ng-container *ngIf="orders && orders.length > 0">
                <div class="ms-md-auto">
                    <ng-container *ngIf="!hideHeaderFilters">
                        <ng-container *ngTemplateOutlet="legend2"></ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="orders && orders.length > 0">
            <div class="row no-gutters">
                <ng-container *ngFor="let order of orders; let i = index">
                    <div class="col-12 col-sm-6 col-md-4 col-xl-3 px-sm-2 py-1 px-xl-3 py-xl-2">
                        <order-status-item
                            [id]="id + '-order-' + i"
                            [order]="order"
                            [isSelected]="selectedOrder && selectedOrder.Id === order.Id"
                            [ignoreSupportOrderStatus]="true"
                            (orderSelected$)="orderSelected($event)"></order-status-item>
                    </div>
                </ng-container>
                <div style="display: flex; justify-content: right">
                    <a href="javascript:void(0);" (click)="loadMoreOrders()">
                        {{ 'general.loadMore' | translate }}
                    </a>
                </div>
            </div>
            <ng-container *ngIf="!orders || orders.length === 0">
                {{ 'general.noordersfound' | translate }}
            </ng-container>
        </ng-container>
    </div>
</div>

<ng-template #legend1>
    <div class="d-inline-block">
        <i class="fas fa-circle pending fg"></i>
        <span class="label ms-1 me-3">{{ 'orderlinestatus.pending' | translate }}</span>
    </div>
    <div class="d-inline-block">
        <i class="fas fa-circle waiting fg"></i>
        <span class="label ms-1">{{ 'orderlinestatus.waiting' | translate }}</span>
    </div>
</ng-template>

<ng-template #legend2>
    <div class="d-inline-block">
        <i class="fas fa-circle evaluation fg"></i>
        <span class="label ms-1 me-3">{{ 'orderlinestatus.evaluation' | translate }}</span>
    </div>
    <div class="d-inline-block">
        <i class="fas fa-circle production fg"></i>
        <span class="label ms-1 me-3">{{ 'orderlinestatus.production' | translate }}</span>
    </div>
    <div class="d-inline-block">
        <i class="fas fa-circle shipped fg"></i>
        <span class="label ms-1 me-3">{{ 'orderlinestatus.shipped' | translate }}</span>
    </div>
    <!--
    @TODO (1/2): Check if still needed, seems like business changed mind ...
    <div class="d-inline-block">
        <i class="fas fa-circle invoiced fg"></i>
        <span class="label ms-1 me-3">{{ 'orderlinestatus.invoiced' | translate }}</span>
    </div>
    -->
    <div class="d-inline-block">
        <i class="fas fa-circle cancelled fg"></i>
        <span class="label ms-1 me-3">{{ 'orderlinestatus.cancelled' | translate }}</span>
    </div>
    <div class="d-inline-block" *ngIf="showCrediting">
        <i class="fas fa-circle credited fg"></i>
        <span class="label ms-1">{{ 'orderlinestatus.credited' | translate }}</span>
    </div>
</ng-template>

<div class="container-fluid d-flex align-items-center px-lg-4 orderstatusfilterbar" [formGroup]="formGroup">
    <div class="flex-fill">
        <div class="input-group search">
            <input
                type="text"
                class="form-control"
                id="orderstatus-search"
                formControlName="search"
                placeholder="{{ 'orderstatus.searchplaceholder' | translate }}" />
            <div class="input-group-append">
                <div class="input-group-text border-start-0">
                    <i class="fas fa-search"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="ms-3 ms-lg-4 d-none d-md-flex align-items-center sort">
        <div class="me-2 d-none d-lg-block">
            <h5 class="mb-0">{{ 'general.sort' | translate }}</h5>
        </div>
        <div class="btn-group w-100 dropup">
            <button
                type="button"
                id="orderstatus-sort"
                class="btn btn-secondary d-flex justify-content-between align-items-center dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                {{ currentSort.Name | translate }}
            </button>
            <div class="w-100 dropdown-menu" aria-labelledby="orderstatus-sort">
                <div
                    class="dropdown-item"
                    id="sort-clientreference"
                    (click)="setSorting(orderStatusSort.ClientReference)">
                    <i class="fas fa-check" *ngIf="currentSort.Sort === orderStatusSort.ClientReference"></i>
                    {{ 'orderstatus.sort.clientreference' | translate }}
                </div>
                <div class="dropdown-item" id="sort-orderdate" (click)="setSorting(orderStatusSort.OrderDate)">
                    <i class="fas fa-check" *ngIf="currentSort.Sort === orderStatusSort.OrderDate"></i>
                    {{ 'orderstatus.sort.orderdate' | translate }}
                </div>
            </div>
        </div>
    </div>

    <div class="ms-3 ms-lg-4 d-none d-md-flex align-items-center filter">
        <div class="me-2 d-none d-lg-block">
            <h5 class="mb-0">{{ 'general.filter' | translate }}</h5>
        </div>
        <div class="btn-group w-100 dropup">
            <button
                type="button"
                id="orderstatus-filter"
                class="btn btn-secondary d-flex justify-content-between align-items-center dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                {{ currentSupportOrdersFilter?.Name | translate }}
                {{ currentOrdersFilter?.Name | translate }}
                {{ !currentSupportOrdersFilter && !currentOrdersFilter ? ('general.none' | translate) : '' }}
            </button>
            <div class="w-100 dropdown-menu" aria-labelledby="orderstatus-filter">
                <div
                    class="dropdown-item"
                    id="filter-waiting"
                    (click)="setSupportOrderFilter(supportOrdersFilter.WaitingForResponse)">
                    <i
                        *ngIf="currentSupportOrdersFilter?.Filter === supportOrdersFilter.WaitingForResponse"
                        class="fas fa-check"></i>
                    {{ 'orderlinestatus.waiting' | translate }}
                </div>
                <div
                    class="dropdown-item"
                    id="filter-pending"
                    (click)="setSupportOrderFilter(supportOrdersFilter.Pending)">
                    <i
                        *ngIf="currentSupportOrdersFilter?.Filter === supportOrdersFilter.Pending"
                        class="fas fa-check"></i>
                    {{ 'orderlinestatus.pending' | translate }}
                </div>

                <ng-container *ngIf="!hideHeaderFilters">
                    <div class="dropdown-divider"></div>
                    <div
                        class="dropdown-item"
                        id="filter-evaluation"
                        (click)="setOrderFilter(orderLineStatus.Evaluation)">
                        <i *ngIf="currentOrdersFilter?.Filter === orderLineStatus.Evaluation" class="fas fa-check"></i>
                        {{ 'orderlinestatus.evaluation' | translate }}
                    </div>
                    <div
                        class="dropdown-item"
                        id="filter-production"
                        (click)="setOrderFilter(orderLineStatus.Production)">
                        <i *ngIf="currentOrdersFilter?.Filter === orderLineStatus.Production" class="fas fa-check"></i>
                        {{ 'orderlinestatus.production' | translate }}
                    </div>
                    <div class="dropdown-item" id="filter-shipped" (click)="setOrderFilter(orderLineStatus.Shipped)">
                        <i *ngIf="currentOrdersFilter?.Filter === orderLineStatus.Shipped" class="fas fa-check"></i>
                        {{ 'orderlinestatus.shipped' | translate }}
                    </div>
                    <div
                        class="dropdown-item"
                        id="filter-cancelled"
                        (click)="setOrderFilter(orderLineStatus.Cancelled)">
                        <i *ngIf="currentOrdersFilter?.Filter === orderLineStatus.Cancelled" class="fas fa-check"></i>
                        {{ 'orderlinestatus.cancelled' | translate }}
                    </div>
                    <div class="dropdown-item" id="filter-credited" (click)="setOrderFilter(orderLineStatus.Credited)">
                        <i *ngIf="currentOrdersFilter?.Filter === orderLineStatus.Credited" class="fas fa-check"></i>
                        {{ 'orderlinestatus.credited' | translate }}
                    </div>

                    <!--
                    @TODO (2/2): Check if still needed, seems like business changed mind ...
                    <div class="dropdown-item" id="filter-invoiced" (click)="setOrderFilter(orderLineStatus.Invoiced)">
                        <i *ngIf="currentOrdersFilter?.Filter === orderLineStatus.Invoiced" class="fas fa-check"></i>
                        {{ 'orderlinestatus.invoiced' | translate }}
                    </div>
                    -->
                    <div class="dropdown-divider"></div>

                    <div class="dropdown-item" id="filter-none" (click)="clearFilter()">
                        <i *ngIf="!currentSupportOrdersFilter && !currentOrdersFilter" class="fas fa-check"></i>
                        {{ 'general.none' | translate }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<div *footer>
    <a [routerLink]="['/']">
        <i class="fas fa-arrow-alt-circle-left me-1"></i>
        {{ 'general.home' | translate }}
    </a>

    <div *ngIf="isReloading.orders" class="spinner-border spinner-border-sm mx-1"></div>
</div>
