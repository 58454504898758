import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription, lastValueFrom } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { Client, EyeSideTuple, Proposal, RefractionMeasurement, TopographicMeasurement } from '@app/shared/models';
import { AlertService } from '@app/shared/appservices/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { FittingService } from '@app/core/services/fitting.service';
import { ProposalService } from '@app/core/services/api/proposal.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { EyeSides, FittingSteps, ImageTypes } from '@app/shared/enums';
import { RefractionDetailsComponent } from '@app/refraction/refraction-details.component';
import { ImageOptions } from '@app/shared/models/image-options.model';
import { MeasurementReviewDialogComponent } from '@app/measurement/measurement-review-dialog.component';
import { GetTopographicMeasurementsByClientIdRequest } from '@app/shared/requestmodels';
import { SaveFittingContinuationConfiguration } from './saveFittingContinuationConfiguration';
import { PopupComponent } from '../shared/popup/popup.component';
import { PopupButton } from '../shared/models/popupButton.model';
import { ClientNotesComponent } from '../client/client-notes.component';
import { PopupConfig } from '@app/shared/models/popupConfig.model';
import { TopographicMeasurementService } from '@app/core/services/api/topographic-measurement.service';
import { UserSettingService } from '@app/core/services/api/user-setting.service';
import { RefractionMeasurementService } from '@app/core/services/api/refraction-measurement.service';

@Component({
    selector: 'fitting-refraction',
    templateUrl: 'fitting-refraction.component.html',
    styleUrls: ['fitting-refraction.component.scss'],
})
export class FittingRefractionComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('refractionDetailsOs')
    refractionDetailsOs: RefractionDetailsComponent;
    @ViewChild('refractionDetailsOd')
    refractionDetailsOd: RefractionDetailsComponent;
    @ViewChild('clientNotes') clientNotes: ClientNotesComponent;

    formGroup: UntypedFormGroup;
    leftPrevious: RefractionMeasurement;
    rightPrevious: RefractionMeasurement;
    previousMeasurements: EyeSideTuple<RefractionMeasurement>;
    eyesides = EyeSides;
    imageOptions: ImageOptions;
    rightTopoMeasurements: TopographicMeasurement[];
    leftTopoMeasurements: TopographicMeasurement[];

    rightEyeSelectedChanged: Subscription;
    leftEyeSelectedChanged: Subscription;

    get client(): Client {
        return this.fittingService.state.client;
    }

    get proposal(): Proposal {
        return this.fittingService.state.proposal;
    }

    get formControls() {
        return this.formGroup.controls;
    }

    get isFollowUp(): boolean {
        return this.fittingService.isDreamLiteFollowUp || this.fittingService.isFollowUp;
    }
    get isRegularFollowUp(): boolean {
        return this.fittingService.isFollowUp;
    }

    get isDreamLiteFollowUp(): boolean {
        return this.fittingService.isDreamLiteFollowUp;
    }

    get refractionRightEyeSelected(): boolean {
        return this.fittingService.state.isRightEyeSideSelected;
    }

    get refractionLeftEyeSelected(): boolean {
        return this.fittingService.state.isLeftEyeSideSelected;
    }

    get canSelectLeftEye(): boolean {
        return this.fittingService.canSelectLeftEye(FittingSteps.Refraction);
    }

    get canSelectRightEye(): boolean {
        return this.fittingService.canSelectRightEye(FittingSteps.Refraction);
    }

    get showPreviousMeasurement(): boolean {
        return this.proposal.ProposalType.Code !== 'newproposal';
    }

    constructor(
        public appState: AppStateService,
        public fittingService: FittingService,
        private readonly fb: UntypedFormBuilder,
        private readonly router: Router,
        private readonly proposalService: ProposalService,
        private readonly loaderService: LoaderService,
        private readonly topographicMeasurementService: TopographicMeasurementService,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        private readonly modalService: BsModalService,
        private readonly userSettingService: UserSettingService,
        private readonly refractionMeasurementService: RefractionMeasurementService,
    ) {}

    async ngOnInit() {
        this.fittingService.setFittingStep(this.router.url);

        if (!this.client || !this.proposal) {
            this.router.navigate(['/']).then();
        }

        this.createForm();

        this.fittingService.configureSaveForContinuation(
            new SaveFittingContinuationConfiguration(
                () => this.isNextEnabled(),
                () => this.saveForContinuation(),
            ),
        );

        this.rightEyeSelectedChanged = this.formControls['modify-od'].valueChanges.subscribe((result) => {
            this.fittingService.state.isRightEyeSideSelected = result;
            if (!result && this.refractionDetailsOd) {
                this.refractionDetailsOd.formGroup.disable();
            }
        });

        this.leftEyeSelectedChanged = this.formControls['modify-os'].valueChanges.subscribe((result) => {
            this.fittingService.state.isLeftEyeSideSelected = result;
            if (!result && this.refractionDetailsOs) {
                this.refractionDetailsOs.formGroup.disable();
            }
        });

        this.loadRefractionData();
        await this.setImageOptions();
    }

    ngAfterViewInit(): void {
        // get temp data that was saved in case of continuation.
        this.clientNotes.restoreFromLocalStorage();
    }

    ngOnDestroy(): void {
        this.rightEyeSelectedChanged.unsubscribe();
        this.leftEyeSelectedChanged.unsubscribe();
    }

    async setImageOptions() {
        const us = await lastValueFrom(this.userSettingService.getUserSettings());
        const imageOptions = new ImageOptions(null);

        imageOptions.topoImageOptions.UseNormalize = !!us.UseNormalize;
        imageOptions.topoImageOptions.UseMapType = !!us.UseMapType;
        imageOptions.topoImageOptions.UseMm = !!us.UseMm;
        imageOptions.ImageChoice =
            us.UseImageType === ImageTypes.Current
                ? 'current'
                : us.UseImageType === ImageTypes.Diff
                  ? 'diff'
                  : us.UseImageType === ImageTypes.BaseLine
                    ? 'baseline'
                    : 'current';

        if (this.isDreamLiteFollowUp) {
            imageOptions.ImageChoice = 'diff';
            imageOptions.topoImageOptions.ImageChoice = 'diff';
            imageOptions.topoImageOptions.UseNormalize = false;
        }

        this.imageOptions = imageOptions;
    }

    // called from the mobile view
    toggleRightEye(): void {
        this.fittingService.state.isRightEyeSideSelected = !this.fittingService.state.isRightEyeSideSelected;
        if (!this.fittingService.state.isRightEyeSideSelected && this.refractionDetailsOd) {
            this.refractionDetailsOd.formGroup.disable();
        }
    }

    // called from the mobile view
    toggleLeftEye(): void {
        this.fittingService.state.isLeftEyeSideSelected = !this.fittingService.state.isLeftEyeSideSelected;
        if (!this.fittingService.state.isLeftEyeSideSelected && this.refractionDetailsOs) {
            this.refractionDetailsOs.formGroup.disable();
        }
    }

    loadRefractionData(): void {
        if (this.proposal) {
            if (
                this.proposal.RightRefractionMeasurement === null &&
                this.fittingService.state.originalRightRefractionMeasurement !== null
            ) {
                this.proposal.RightRefractionMeasurement = this.fittingService.state.originalRightRefractionMeasurement;
            }

            if (
                this.proposal.LeftRefractionMeasurement === null &&
                this.fittingService.state.originalLeftRefractionMeasurement !== null
            ) {
                this.proposal.LeftRefractionMeasurement = this.fittingService.state.originalLeftRefractionMeasurement;
            }

            this.refractionMeasurementService
                .getPreviousRefractionMeasurementsOfClient(this.proposal.Id)
                .subscribe((result) => {
                    this.leftPrevious = result.LeftEyeSideValue;
                    this.rightPrevious = result.RightEyeSideValue;
                });
        }
    }

    createForm(): void {
        this.formGroup = this.fb.group({
            'modify-od': [this.fittingService.state.isRightEyeSideSelected],
            'modify-os': [this.fittingService.state.isLeftEyeSideSelected],
        });
    }

    hasMyopie(): boolean {
        return this.client.Myopie && this.appState.isMyopiaEnabled;
    }

    atLeastOneMeasurementSelected(): boolean {
        return this.fittingService.state.isRightEyeSideSelected || this.fittingService.state.isLeftEyeSideSelected;
    }

    previous($event: MouseEvent): void {
        $event.preventDefault();
        this.fittingService.gotoPreviousStep();
    }

    isNextEnabled(): boolean {
        return !this.formGroup.invalid && this.atLeastOneMeasurementSelected() && this.isValidTime;
    }

    private saveForContinuation(): Promise<Proposal> {
        if (this.clientNotes.canSave) {
            // unsaved notes: save to local storage to restore on continuation.
            // We use localstorage here because the alternative is asking the optician
            // lots of questions. Let's hope the optician uses the same browser
            // to continue after saving. Also, it is limited to this login, because MyProcornea will
            // clear localstorage on logout and login. Which is good for privacy reasons.
            this.clientNotes.saveToLocalStorage();
        }

        return this.save(false);
    }

    private async save(isSaveCheckup: boolean): Promise<Proposal> {
        this.loaderService.show();

        const proposal = structuredClone(this.proposal);
        if (this.fittingService.state.isRightEyeSideSelected && this.refractionDetailsOd) {
            proposal.RightRefractionMeasurement = await this.refractionDetailsOd.getMeasurement();
            proposal.RightRefractionMeasurement.IsOverrefraction = this.isFollowUp;
        } else {
            proposal.RightRefractionMeasurement = null;
        }

        if (this.fittingService.state.isLeftEyeSideSelected && this.refractionDetailsOs) {
            proposal.LeftRefractionMeasurement = await this.refractionDetailsOs.getMeasurement();
            proposal.LeftRefractionMeasurement.IsOverrefraction = this.isFollowUp;
        } else {
            proposal.LeftRefractionMeasurement = null;
        }

        const shouldRecalcWithOverRefraction = this.isRegularFollowUp && !isSaveCheckup;

        const saveRefractionResultProposal = await lastValueFrom(
            this.proposalService.saveRefractionMeasurements(proposal),
        );
        if (!shouldRecalcWithOverRefraction) {
            return saveRefractionResultProposal;
        } else {
            // in case of a regular follow up, the lens needs to be recalculated
            // using the over refraction values before the next step.
            return await lastValueFrom(this.proposalService.recalculateFollowUpLenses(proposal));
        }
    }

    async next(isSaveCheckup: boolean) {
        const canProceed = await this.showClientNotesWarningPopupIfDirty();
        if (!canProceed) {
            return;
        }

        if (isSaveCheckup) {
            // no further action needed after saving checkup, and this proposal
            // can't be continued.
            this.proposal.Step = FittingSteps.Cancelled;
        } else {
            this.fittingService.setProposalStepOnNextClick();
        }

        const saveProposalResult = await this.save(isSaveCheckup);

        this.fittingService.clearSaveForContinationConfiguration();

        if (isSaveCheckup) {
            this.router.navigate(['/']).then();
            this.alertService.success(this.translateService.instant('general.saveSuccessful'));
            this.loaderService.hide();
        } else {
            this.fittingService.setProposal(saveProposalResult);

            if (this.isRegularFollowUp) {
                this.fittingService.takeOverNewLensProposal(saveProposalResult);
            }

            this.fittingService.gotoNextStep();
            this.loaderService.hide();
        }
    }

    clickMeasurementImage(event: MouseEvent, measurement: TopographicMeasurement): void {
        event.stopPropagation();

        if (!this.leftTopoMeasurements || !this.rightTopoMeasurements) {
            this.loaderService.show();

            const requestModel = new GetTopographicMeasurementsByClientIdRequest();
            requestModel.clientId = this.fittingService.getClientId();
            requestModel.pageSize = 999;
            requestModel.pageIndex = 0;
            requestModel.loadLeftTopographicMeasurements = true;
            requestModel.loadRightTopographicMeasurements = true;

            this.topographicMeasurementService
                .getTopographicMeasurementsByClientId(requestModel)
                .pipe(finalize(() => this.loaderService.hide()))
                .subscribe((result) => {
                    this.leftTopoMeasurements = result.LeftTopographicMeasurements || [];
                    this.rightTopoMeasurements = result.RightTopographicMeasurements || [];

                    this.showMeasurementReviewDialog(measurement);
                });
        } else {
            this.showMeasurementReviewDialog(measurement);
        }
    }

    showMeasurementReviewDialog(measurement: TopographicMeasurement): void {
        const imageOptions = structuredClone(this.imageOptions);
        imageOptions.ShowImageChoice = true;
        imageOptions.ShowImageTypes = false;

        const options: ModalOptions<MeasurementReviewDialogComponent> = {
            initialState: {
                currentMeasurement: measurement,
                topoMeasurements:
                    measurement.EyeSideId === this.eyesides.Od ? this.rightTopoMeasurements : this.leftTopoMeasurements,
                imageOptions: imageOptions,
                refractionMeasurement:
                    measurement.EyeSideId === this.eyesides.Od ? this.rightPrevious : this.leftPrevious,
                client: this.client,
            },
            class: 'measurement-review-dialog',
        };

        this.modalService.show(MeasurementReviewDialogComponent, options);
    }

    get isValidTime(): boolean {
        if (this.refractionDetailsOd && !this.refractionDetailsOd.isValidTime()) {
            return false;
        }
        return !(this.refractionDetailsOs && !this.refractionDetailsOs.isValidTime());
    }

    private showClientNotesWarningPopupIfDirty(): Promise<boolean> {
        if (!this.clientNotes.canSave) {
            return Promise.resolve(true);
        }

        // there is an unsaved note. Ask questions.
        return new Promise((resolve) => {
            const options: ModalOptions<PopupConfig> = {
                initialState: {
                    popupText: 'warning.clientnotesnotempty',
                    buttons: [
                        new PopupButton('general.yes', 'btn-primary', async () => {
                            await this.clientNotes.saveNote();
                            resolve(true);
                        }),
                        new PopupButton('general.no', 'btn-primary', () => resolve(true)),
                        new PopupButton('general.cancel', 'btn-default', () => resolve(false)),
                    ],
                },
                class: 'modal-dialog',
            };
            this.modalService.show(PopupComponent, options);
        });
    }
}
