import { Order } from '@app/shared/models/order.model';
import { FittedLens } from '@app/shared/models/fitted-lens.model';

export class ReOrder {
    Order: Order;
    RightFittedLens: FittedLens;
    LeftFittedLens: FittedLens;
    Comments: string;
    SkipOrderEvaluation: boolean;
    PriorityId: number;
    PerosOrderNumber: string;
    PoNumber: string;
}
