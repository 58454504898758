import { Component, DestroyRef, OnDestroy, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { AuthenticatedUser, Cart, CartItem } from '@app/shared/models';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { CartStorageService } from '@app/core/services/cart-storage.service';
import { LogoutService } from '@app/shared/appservices/logout.service';
import { filter } from 'rxjs/operators';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { SettingsDialogComponent } from '@app/core/components/settings-dialog/settings-dialog.component';
import { Features, FeatureCategories, Roles } from '@app/shared/enums';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'main-header',
    templateUrl: './main-header.component.html',
    styleUrls: ['main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit, OnDestroy {
    public maintenanceMenuVisible = false;

    public roles = Roles;
    public Features = Features;
    public FeatureCategories = FeatureCategories;
    public teamViewerUrl: string;

    public shoppingCart: Cart;
    public shoppingCartSize: number;
    public shoppingCartClass: string;

    public isHome: boolean;
    public currentRoleId: number;
    public currentAuthenticatedUser: AuthenticatedUser;
    private readonly destroyRef = inject(DestroyRef);

    constructor(
        public router: Router,
        public appState: AppStateService,
        public modalService: BsModalService,
        public cartStorageService: CartStorageService,
        private appConfigService: AppConfigService,
        private logoutService: LogoutService,
    ) {}

    async ngOnInit() {
        this.appState.authenticatedUser$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user) => {
            this.currentRoleId = user?.CurrentRoleId;
            this.currentAuthenticatedUser = user;

            this.maintenanceMenuVisible =
                this.currentRoleId === this.roles.Administrator ||
                this.currentRoleId === this.roles.LensConfigurationAdmin ||
                this.currentRoleId === this.roles.DistributorAdmin ||
                this.currentRoleId === this.roles.Marketing;
        });

        this.isHome = this.checkIsHome();
        if (this.isHome) {
            window.addEventListener('scroll', this.onWindowScroll.bind(this));
        }

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe((event: NavigationEnd) => {
                this.isHome = event['url'] === '/';
            });

        this.cartStorageService.loadFromServer();
        this.cartStorageService.cart$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((cart) => {
            this.shoppingCart = cart;

            this.shoppingCartSize =
                this.shoppingCart?.Items.reduce((amount, item: CartItem) => {
                    return amount + item.Quantity;
                }, 0) ?? 0;

            this.shoppingCartClass =
                this.shoppingCart === null || this.shoppingCart.Items.length === 0
                    ? 'shoppingBasket_disabled'
                    : 'shoppingBasket';
        });

        this.appConfigService.apiEndpointChanges$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async () => {
            await this.cartStorageService.loadFromServer();
        });

        this.teamViewerUrl = this.appState.currentDistributor.TeamViewerUrl;
    }

    public ngOnDestroy(): void {
        window.removeEventListener('scroll', this.onWindowScroll);
    }

    public gotoHome() {
        this.router.navigate(['/']);
    }

    public showSettings() {
        const options: ModalOptions = { class: 'settingsDialog' };
        this.modalService.show(SettingsDialogComponent, options);
    }

    public gotoShoppingBasket(): void {
        if (this.shoppingCart?.Items.length > 0) {
            this.router.navigate(['/cart']);
        }
    }

    public gotoService(): void {
        this.router.navigate(['/service']);
    }

    public gotoComplaint(): void {
        this.router.navigate(['/service/request/complaint']);
    }

    public logout() {
        this.logoutService.logout();
    }

    private checkIsHome(): boolean {
        return this.router.url === '/';
    }

    private onWindowScroll(): void {
        if (this.checkIsHome()) {
            this.isHome = window.scrollY <= 30;
        }
    }
}
