<!-- Show current glass -->
<div [formGroup]="formGroup" *ngIf="fittedLens" class="flex-fill d-flex flex-column">
    <!-- LENSPARAMETERS -->
    <div class="flex-fill" [ngClass]="{ 'p-3 rounded lensparameters': !appState.isMobile }">
        <div *ngIf="!loading && fittedLens">
            <div formArrayName="parameters" class="form-group row">
                <div class="col-sm-8">
                    <ng-container *ngFor="let paramControl of formGroup.get('parameters')['controls']; let i = index">
                        <div *ngIf="visibleLensParameters[i].LensDefinitionParameter.ColumnIndex === 0">
                            <mpc-label
                                *ngIf="visibleLensParameters[i].LensDefinitionParameter.IsReadOnly"
                                [formControlName]="i"
                                id="{{ id }}-parameter-{{
                                    visibleLensParameters[i].LensDefinitionParameter.ParameterType.Code
                                }}"
                                [label]="visibleLensParameters[i].LensDefinitionParameter.Name"
                                [formatting]="visibleLensParameters[i].LensDefinitionParameter.NumberFormatOptions"
                                breakpoint=""></mpc-label>

                            <mpc-input-number
                                *ngIf="!visibleLensParameters[i].LensDefinitionParameter.IsReadOnly"
                                [formControlName]="i"
                                id="{{ id }}-parameter-{{
                                    visibleLensParameters[i].LensDefinitionParameter.ParameterType.Code
                                }}"
                                [ranges]="
                                    visibleLensParameters[i].LensDefinitionParameter.LensDefinitionParameterRanges
                                        | lensDefinitionParameterNumberRangeFilter: null
                                "
                                [formatting]="visibleLensParameters[i].LensDefinitionParameter.NumberFormatOptions"
                                [isDisabled]="
                                    loading || parameterIsDisabled(visibleLensParameters[i].LensDefinitionParameter)
                                "
                                [maxLength]="5"
                                [label]="visibleLensParameters[i].LensDefinitionParameter.Name"
                                breakpoint=""
                                [info]="getInfoTextForParameter(visibleLensParameters[i])"
                                [controlHasFormError]="controlHasFormError(visibleLensParameters[i])"
                                [unit]="getUnit(visibleLensParameters[i].LensDefinitionParameter)"></mpc-input-number>
                        </div>
                    </ng-container>
                </div>

                <div class="col-sm-8">
                    <ng-container *ngFor="let paramControl of formGroup.get('parameters')['controls']; let i = index">
                        <div *ngIf="visibleLensParameters[i].LensDefinitionParameter.ColumnIndex === 1">
                            <mpc-label
                                *ngIf="visibleLensParameters[i].LensDefinitionParameter.IsReadOnly"
                                [formControlName]="i"
                                id="{{ id }}-parameter-{{
                                    visibleLensParameters[i].LensDefinitionParameter.ParameterType.Code
                                }}"
                                [label]="visibleLensParameters[i].LensDefinitionParameter.Name"
                                [formatting]="visibleLensParameters[i].LensDefinitionParameter.NumberFormatOptions"
                                breakpoint=""></mpc-label>

                            <mpc-input-number
                                *ngIf="!visibleLensParameters[i].LensDefinitionParameter.IsReadOnly"
                                [formControlName]="i"
                                id="{{ id }}-parameter-{{
                                    visibleLensParameters[i].LensDefinitionParameter.ParameterType.Code
                                }}"
                                [ranges]="
                                    visibleLensParameters[i].LensDefinitionParameter.LensDefinitionParameterRanges
                                        | lensDefinitionParameterNumberRangeFilter: null
                                "
                                [formatting]="visibleLensParameters[i].LensDefinitionParameter.NumberFormatOptions"
                                [isDisabled]="
                                    loading || parameterIsDisabled(visibleLensParameters[i].LensDefinitionParameter)
                                "
                                [maxLength]="5"
                                [label]="visibleLensParameters[i].LensDefinitionParameter.Name"
                                breakpoint=""
                                [info]="getInfoTextForParameter(visibleLensParameters[i])"
                                [controlHasFormError]="controlHasFormError(visibleLensParameters[i])"
                                [unit]="getUnit(visibleLensParameters[i].LensDefinitionParameter)"></mpc-input-number>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
