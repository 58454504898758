<div class="chats">
    <div class="chat-wrapper">
        <h3>{{ 'ps.chats' | translate }}</h3>

        <!-- External notes -->
        <div class="notepanel">
            <div class="notecontainer">
                <client-communication
                    [clientId]="client.Id"
                    [orderId]="order.Id"
                    [showClientNotes]="true"
                    [id]="'ExternalNotes'"
                    [useAutoGrow]="true"
                    [showAttachments]="true"
                    [isReadOnly]="false"
                    addClassName="ps-chat"
                    type="{{ 'ps.ExternalCommunication' | translate }}"></client-communication>
            </div>
        </div>

        <!-- Internal notes -->
        <div class="notepanel">
            <div class="notecontainer">
                <client-communication
                    [clientId]="client.Id"
                    [showInternalNotes]="true"
                    [id]="'InternalNotes'"
                    [useAutoGrow]="true"
                    [isReadOnly]="false"
                    addClassName="ps-chat"
                    type="{{ 'ps.InternalCommunication' | translate }}"></client-communication>
            </div>
        </div>
    </div>
</div>
