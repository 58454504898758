import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { LogoutService } from '@app/shared/appservices/logout.service';
import { AuthenticationService } from '@app/core/services/api/authentication.service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AuthenticatedUser } from '@app/shared/models';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private readonly jwtHelper = new JwtHelperService();
    private isRefreshBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private refreshBehaviorSubject: BehaviorSubject<unknown> = new BehaviorSubject<unknown>(null);

    constructor(
        private appState: AppStateService,
        private authService: AuthenticationService,
        private logoutService: LogoutService,
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (this.appState.authenticatedUser) {
            request = this.addTokenHeader(request, this.appState.authenticatedUser.AccessToken);
        }

        return this.validateTokenBefore(request).pipe(
            switchMap((request) => next.handle(request)),
            switchMap((event) => this.validateTokenWWWAuthenticate(event)),
            catchError((error: unknown) => this.validateTokenAfter401(request, next, error)),
        );
    }

    private validateTokenBefore(request: HttpRequest<unknown>): Observable<HttpRequest<unknown>> {
        if (
            request.url.includes('api/') &&
            !request.url.includes('auth/login') &&
            !request.url.includes('auth/logout') &&
            !request.url.includes('auth/token/refresh') &&
            this.appState.authenticatedUser &&
            this.jwtHelper.isTokenExpired(this.appState.authenticatedUser?.AccessToken)
        ) {
            return this.tryRefreshToken().pipe(
                switchMap(() => {
                    request = this.addTokenHeader(request, this.appState.authenticatedUser?.AccessToken);
                    return of(request);
                }),
            );
        }

        return of(request);
    }

    private validateTokenWWWAuthenticate(event: HttpEvent<unknown>): Observable<HttpEvent<unknown>> {
        if (event instanceof HttpResponse && event.headers.has('www-authenticate')) {
            return this.tryRefreshToken().pipe(switchMap(() => of(event)));
        }

        return of(event);
    }

    private validateTokenAfter401(
        request: HttpRequest<unknown>,
        next: HttpHandler,
        error: unknown,
    ): Observable<HttpEvent<unknown>> {
        if (
            error instanceof HttpErrorResponse &&
            error.status === 401 &&
            !request.url.includes('auth/login') &&
            !request.url.includes('auth/logout') &&
            !request.url.includes('auth/token/refresh') &&
            this.jwtHelper.isTokenExpired(this.appState.authenticatedUser?.AccessToken)
        ) {
            return this.tryRefreshToken().pipe(
                switchMap(() => {
                    request = this.addTokenHeader(request, this.appState.authenticatedUser?.AccessToken);
                    return next.handle(request);
                }),
            );
        }

        return throwError(error);
    }

    private tryRefreshToken(): Observable<string> {
        const accessToken = this.appState.authenticatedUser?.AccessToken;
        const refreshToken = this.appState.authenticatedUser?.RefreshToken;

        if (accessToken && refreshToken && !this.isRefreshBehaviorSubject.getValue()) {
            this.isRefreshBehaviorSubject.next(true);
            this.refreshBehaviorSubject.next(null);

            return this.authService.refreshToken(accessToken, refreshToken).pipe(
                switchMap((result: AuthenticatedUser) => {
                    this.appState.authenticatedUser = result;
                    this.refreshBehaviorSubject.next(result.AccessToken);

                    return of(result.AccessToken);
                }),
                catchError((err) => {
                    this.logoutService.logout();
                    return throwError(err);
                }),
                finalize(() => {
                    this.isRefreshBehaviorSubject.next(false);
                }),
            );
        }

        return this.refreshBehaviorSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap((token: string) => {
                return of(token);
            }),
        );
    }

    private addTokenHeader(request: HttpRequest<unknown>, token: string) {
        return request.clone({ setHeaders: { Authorization: token } });
    }
}
