import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppStateService } from '../../shared/appservices/appState.service';

@Injectable()
export class FeatureFlagAuthGuard {
    constructor(private readonly appState: AppStateService) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const requiredFeature = route.routeConfig.data?.featureFlag;

        // No feature given, just forbid it something like that wont happen on accident (:
        if (!requiredFeature) {
            return false;
        }
        return this.appState.isCompanyFeatureEnabled(requiredFeature);
    }
}
