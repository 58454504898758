import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TourPages } from '@app/features/product-tour/enums/tourPages.enum';
import { driver } from 'driver.js';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { FeatureCategories, Features, Roles } from '@app/shared/enums';
import { ProductTourDialogComponent } from '@app/features/product-tour/components/product-tour-dialog/product-tour-dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProductTourService } from '@app/features/product-tour/services/product-tour.service';
import { FeatureCheckerService } from '@app/shared/appservices/feature-checker.service';

@Component({
    selector: 'app-product-tour',
    templateUrl: './product-tour.component.html',
    styleUrls: ['./product-tour.component.scss'],
})
export class ProductTourComponent implements OnInit {
    activeTourPage: TourPages;
    isTourButtonActive = false;

    constructor(
        private appState: AppStateService,
        private router: Router,
        private translateService: TranslateService,
        private modalService: BsModalService,
        private tourService: ProductTourService,
        private featureCheckerService: FeatureCheckerService,
    ) {}

    ngOnInit(): void {
        if (this.tourService.isTourAvailable() && this.appState.authenticatedUser.CurrentRoleId === Roles.Optician) {
            // Prepare product tour for initial/entry route
            this.prepareTourSettingsForPage(this.getFirstPartOfUrl(this.router.url));

            // Detect url changes to prepare next product tour
            this.router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe((event: NavigationEnd) => {
                    this.prepareTourSettingsForPage(this.getFirstPartOfUrl(event.url));
                });
        }
    }

    prepareTourSettingsForPage(url: string): void {
        this.isTourButtonActive = false;

        switch (url) {
            case '':
                this.activeTourPage = TourPages.Home;
                break;
            case 'shop':
                this.activeTourPage = TourPages.Webshop;
                break;
            default:
                this.activeTourPage = null;
        }

        if (this.activeTourPage) {
            this.isTourButtonActive = true;
            this.showTourDialog();
        }
    }

    showTourDialog(): void {
        if (this.activeTourPage === TourPages.Home && this.tourService.showTourModal()) {
            this.modalService.show(ProductTourDialogComponent, {
                initialState: {
                    title: this.activeTourPage,
                    onNeverShow: () => {
                        this.tourService.markTourAsDone(new Date());
                        this.highlightTourButton();
                    },
                    onStartTour: () => {
                        this.tourService.markTourAsDone(new Date());
                        this.startProductTour();
                    },
                    onReminderLater: () => {
                        const nextReminder = new Date();
                        nextReminder.setDate(nextReminder.getDate() + 3);
                        this.tourService.setTourReminder(nextReminder);
                    },
                },
            });
        }
    }

    highlightTourButton(): void {
        driver({
            allowClose: true,
            showButtons: ['close'],
            steps: [
                {
                    element: '#startProductTour',
                    popover: {
                        title: this.translateService.instant('product-tour.tour-button-title'),
                        description: this.translateService.instant('product-tour.tour-button-content'),
                    },
                },
            ],
        }).drive();
    }

    startProductTour(): void {
        let driverSteps = [];

        if (this.activeTourPage === TourPages.Home) {
            driverSteps.push({
                element: '#clients.card',
                popover: {
                    title: 'Customers',
                    description: 'You can add new customers, manage customer data and start an order.',
                },
            });

            if (
                this.appState.currentOptician.IsApproved &&
                this.appState.isOpticianFeatureEnabled(Features.Webshop) &&
                this.appState.isDistributorFeatureEnabled(Features.WebshopTile)
            ) {
                driverSteps.push({
                    element: '#webshop.card',
                    popover: {
                        title: 'Webshop',
                        description: 'Easy order and re-order lenses, accessories and useful marketing materials.',
                    },
                });
            }

            driverSteps.push({
                element: '#orderStatus.card',
                popover: { title: 'Order status', description: 'Simply check your orders.' },
            });

            driverSteps.push({
                element: '#navbarSupportedContent',
                popover: {
                    description:
                        'The header is a fixed element and consists of useful buttons: Change your settings if desired.',
                },
            });

            driverSteps.push({
                element: '.shoppingBasket',
                popover: { description: 'Go to your shopping cart.' },
            });

            driverSteps.push({
                element: 'theme-switch',
                popover: { description: 'Select your preference: light/dark switch.' },
            });

            if (
                this.featureCheckerService.check([
                    {
                        Feature: Features.ReturnsAllowed,
                        Category: FeatureCategories.Optician,
                        IsEnabled: true,
                    },
                ])
            ) {
                driverSteps.push({
                    element: '#serviceLink',
                    popover: { description: 'Submit a credit request or complaint via “Service request”.' },
                });
            }

            driverSteps.push({
                element: '.teamviewer-panel',
                popover: {
                    description:
                        'Connecting TeamViewer if you need support of the Professional Services team (works only when you have someone of Professional Services on the phone).',
                },
            });

            driverSteps.push({
                element: '.content .row',
                popover: {
                    description:
                        'Stay up to date with the latest news and agenda items to see what is happening in your region.',
                },
            });

            driverSteps.push({
                element: 'main-footer',
                popover: {
                    description:
                        'The footer contains links to all important information, such as the Terms & Conditions, FAQ and Contact.',
                },
            });
        }

        if (this.activeTourPage === TourPages.Webshop) {
            driverSteps = [
                {
                    element: '.logo-visavy',
                    popover: { title: 'Visavy', description: 'State of the art Fitting tool ;)' },
                },
            ];
        }

        driver({
            allowClose: true,
            showProgress: true,
            showButtons: ['next', 'previous', 'close'],
            nextBtnText: this.translateService.instant('general.next'),
            prevBtnText: this.translateService.instant('general.previous'),
            doneBtnText: this.translateService.instant('general.close'),
            steps: driverSteps,
        }).drive();
    }

    private getFirstPartOfUrl(url: string): string {
        return url.substring(1).split('/')[0];
    }
}
