<password-expiry></password-expiry>

<div class="m-4">
    <div class="container-fluid cards home-nav">
        <div class="row">
            <div class="col-lg-10 offset-lg-1 px-0">
                <div class="row d-flex justify-content-center">
                    <div class="col-4">
                        <div id="serviceRequest" class="card" routerLink="/logistics/manualrequest">
                            <div class="card-body d-flex flex-column align-items-center justify-content-center">
                                <div class="icon">
                                    <i class="fas fa-concierge-bell fa-5x"></i>
                                </div>
                                <div class="title">
                                    {{ 'logistics.manualrequest' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div id="complaintOverview" class="card" routerLink="/logistics/overview">
                            <div class="card-body d-flex flex-column align-items-center justify-content-center">
                                <div class="icon">
                                    <i class="fa fa-reply fa-5x"></i>
                                </div>
                                <div class="title">
                                    {{ 'service.complaintsOverview' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div id="serial" class="card" routerLink="/service/scan">
                            <div class="card-body d-flex flex-column align-items-center justify-content-center">
                                <div class="icon">
                                    <i class="fa fa-barcode fa-5x"></i>
                                </div>
                                <div class="title">
                                    {{ 'service.barcodescan' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
