import { Injectable, NgZone } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';

@Injectable()
export class ApplicationInsightsService {
    private readonly appInsights: ApplicationInsights;
    private readonly roleInstance: string;
    private readonly roleName: string;

    constructor(
        private zone: NgZone,
        appConfigService: AppConfigService,
    ) {
        if (appConfigService.appConfig.APPINSIGHTS_CONNECTIONSTRING) {
            this.appInsights = new ApplicationInsights({
                config: {
                    connectionString: appConfigService.appConfig.APPINSIGHTS_CONNECTIONSTRING,
                    enableAutoRouteTracking: true,
                    enableUnhandledPromiseRejectionTracking: true,
                    enableAjaxErrorStatusText: true,
                    autoTrackPageVisitTime: true,
                    enableCorsCorrelation: true,
                    correlationHeaderExcludedDomains: ['news.myprocornea.com', 'test.ikkijkverder.nl'],
                },
            });

            this.roleInstance = appConfigService.appConfig.APPINSIGHTS_ROLEINSTANCE_FRONTEND;
            this.roleName = appConfigService.appConfig.APPINSIGHTS_ROLENAME_FRONTEND;
        }
    }

    loadAppInsights() {
        if (this.appInsights) {
            // https://github.com/Microsoft/ApplicationInsights-JS/issues/535
            this.zone.runOutsideAngular(() => {
                this.appInsights.loadAppInsights();
                this.appInsights.addTelemetryInitializer((envelope) => {
                    if (this.roleInstance) {
                        envelope.tags['ai.cloud.roleInstance'] = this.roleInstance;
                    }

                    if (this.roleName) {
                        envelope.tags['ai.cloud.role'] = this.roleName;
                    }
                });
            });

            const zone = this.zone;
            (function (xhr, ...args) {
                const open = xhr.open;
                xhr.open = function (method: string, url: string | URL) {
                    return zone.runOutsideAngular(() => open.apply(this, [method, url, ...args]));
                };
            })(XMLHttpRequest.prototype);
        }
    }

    trackException(error: unknown) {
        if (this.appInsights) {
            this.appInsights.trackException(error);
        }
    }
}
