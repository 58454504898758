import { Component, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProposalReceiptInfo } from '@app/shared/models/proposalReceiptInfo.model';

@Component({
    selector: 'receipt-status-item',
    templateUrl: 'receipt-status-item.component.html',
    styleUrls: ['receipt-status-item.component.scss'],
})
export class ReceiptStatusItemComponent {
    @Input() id = '';
    @Input() receipt: ProposalReceiptInfo;
    @Input() isSelected = false;

    @Output() receiptSelected$: EventEmitter<ProposalReceiptInfo> = new EventEmitter();

    constructor(public translate: TranslateService) {}

    click(receipt: ProposalReceiptInfo): void {
        this.receiptSelected$.emit(receipt);
    }
}
