<div class="row">
    <div class="col-12">
        <ng-container *ngFor="let sos of supportorderstatusses">
            <div
                [id]="'Button-' + sos.Code"
                [class]="'accented-background col-12 mb-2 supportorder ' + sos.Code"
                [ngClass]="getOrderStatusClass(sos.Id)"
                (click)="onSupportOrderStatus(sos)">
                <span>{{ getCount(sos.Id) }}</span>
                {{ 'ps-supportorder-overview-stats.' + sos.Code | translate }}
            </div>
        </ng-container>

        <div
            id="Button-peros"
            *ngIf="perosEnabled"
            class="accented-background col-12 mt-4 mb-2 supportorder my-items"
            [ngClass]="getClassButtonPeros()"
            (click)="onButtonPerosClick()">
            <span></span>
            {{ 'ps-supportorder-overview-stats.peros' | translate }}
        </div>

        <div
            *hasFeatureSetting="[
                {
                    Feature: Features.UseOrderBlocking,
                    Category: FeatureCategories.Distributor,
                    IsEnabled: true
                }
            ]"
            id="Button-blocked"
            class="accented-background col-12 mt-4 mb-2 supportorder my-items"
            [ngClass]="getClassButtonBlockedOrders()"
            (click)="onButtonBlockedOrdersClick()">
            <span>{{ blockedOrdersCount }}</span>
            {{ 'ps-supportorder-overview-stats.BLOCKED' | translate }}
        </div>

        <div
            id="Button-myitems"
            class="accented-background col-12 mt-4 mb-2 supportorder my-items"
            [ngClass]="getClassButtonMyOrders()"
            (click)="onButtonMyOrdersClick()">
            <span>{{ myOrdersCount }}</span>
            {{ 'ps-supportorder-overview-stats.myitems' | translate }}
        </div>

        <div
            id="Button-Unread"
            class="accented-background col-12 mb-2 supportorder new-messages"
            [ngClass]="getClassButtonUnreadMessages()"
            (click)="onButtonUnreadMessagesClick()">
            <span>{{ ordersWithUnreadMessagesCount }}</span>
            {{ 'ps-supportorder-overview-stats.unreadmessages' | translate }}
        </div>

        <div
            id="Button-DirectOrder"
            class="accented-background col-12 supportorder direct-orders"
            [ngClass]="getClassButtonDirectOrders()"
            (click)="onButtonDirectOrdersClick()">
            <span>&nbsp;</span>
            {{ 'ps-supportorder-overview-stats.directorders' | translate }}
        </div>
    </div>
</div>
