<div [formGroup]="formGroupPassword" class="content-section rounded mb-2">
    <div class="title mb-4 mt-1">
        <h6>
            {{ 'changepassword.changepassword' | translate | uppercase }}
        </h6>
    </div>

    <div class="formgroup mb-3">
        <mpc-input-text
            [inputType]="showPasswords ? 'text' : 'password'"
            formControlName="password"
            [id]="'password'"
            label="changepassword.password"
            placeholder="changepassword.password"
            [labelWidth]="5"></mpc-input-text>
        <!-- These fields have no ID to prevent Chrome from showing the suggested password list -->
        <mpc-input-text
            [inputType]="showPasswords ? 'text' : 'password'"
            formControlName="passwordNew"
            [customControlClass]="'col-md-7 passwordNew'"
            [info]="'ErrorMessages.USERS_USER_INVALID_PASSWORD_STRENGTH' | translate"
            label="changepassword.newpassword"
            placeholder="changepassword.newpassword"
            [labelWidth]="5"
            [maxLength]="25"></mpc-input-text>
        <mpc-input-text
            [inputType]="showPasswords ? 'text' : 'password'"
            formControlName="passwordRepeat"
            [customControlClass]="'col-md-7 passwordRepeat'"
            [info]="'ErrorMessages.USERS_USER_INVALID_PASSWORD_STRENGTH' | translate"
            label="changepassword.repeatnewpassword"
            placeholder="changepassword.repeatnewpassword"
            [labelWidth]="5"
            [maxLength]="25"></mpc-input-text>
    </div>

    <div class="d-flex">
        <div class="me-auto">
            <span id="passwordmessage" class="text-danger">
                {{ formGroupPassword.errors ? formGroupPassword.errors.matching_error : passwordUpdateError }}
            </span>
        </div>
        <div *ngIf="passwordLoading" class="spinner-border spinner-border-sm m-2 align-self-start"></div>
        <div>
            <button
                type="button"
                id="savePassword"
                class="btn btn-success"
                [disabled]="formGroupPassword.invalid || passwordLoading"
                (click)="changePassword()">
                {{ 'general.save' | translate }}
            </button>
        </div>
    </div>
</div>
