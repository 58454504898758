<div class="row" *ngIf="client">
    <div [ngClass]="twoColumns ? 'col-6' : 'col-12'">
        <div class="row" *ngIf="client.Reference">
            <div class="col font-weight-bold" *ngIf="showLabels">{{ 'general.reference' | translate }}:</div>
            <div class="col">
                <span id="reference">{{ client.Reference }}</span>
            </div>
        </div>

        <div class="row" *ngIf="client.Reference2">
            <div class="col font-weight-bold" *ngIf="showLabels">{{ 'general.yourreference' | translate }}:</div>
            <div class="col">
                <span id="reference2">{{ client.Reference2 }}</span>
            </div>
        </div>

        <div class="row" *ngIf="client.GenderId">
            <div class="col font-weight-bold" *ngIf="showLabels">{{ 'general.gender' | translate }}:</div>
            <div class="col">
                <span id="gender">{{ genderName(client.GenderId) }}</span>
            </div>
        </div>

        <div class="row" *ngIf="client.BirthDate">
            <div class="col font-weight-bold" *ngIf="showLabels">{{ 'general.birthdate' | translate }}:</div>
            <div class="col">
                <span id="birthdate">{{ client.BirthDate | mpcdate }}</span>
            </div>
        </div>
    </div>

    <div [ngClass]="twoColumns ? 'col-6' : 'col-12'">
        <div
            *hasFeatureSetting="[
                {
                    Feature: Features.ExportByCustomerErpReference,
                    Category: FeatureCategories.Optician,
                    IsEnabled: true
                }
            ]">
            <div class="row" *ngIf="client.ErpReference">
                <div class="col font-weight-bold" *ngIf="showLabels">{{ 'client.erpreference' | translate }}:</div>
                <div class="col">
                    <span id="erpreference">{{ client.ErpReference }}</span>
                </div>
            </div>

            <div class="row" *ngIf="client.Street || client.Housenumber">
                <div class="col font-weight-bold" *ngIf="showLabels">{{ 'general.address' | translate }}:</div>
                <div class="col">
                    <span id="street" class="me-1">{{ client.Street }}</span>
                    <span id="housenumber">{{ client.Housenumber }}</span>
                </div>
            </div>

            <div class="row" *ngIf="client.Phonenumber">
                <div class="col font-weight-bold" *ngIf="showLabels">{{ 'general.phoneNumber' | translate }}:</div>
                <div class="col">
                    <span id="phonenumber">{{ client.Phonenumber }}</span>
                </div>
            </div>
        </div>

        <div
            class="row"
            *hasFeatureSetting="[
                {
                    Feature: Features.Myopie,
                    Category: FeatureCategories.Optician,
                    IsEnabled: true
                }
            ]">
            <div *ngIf="client.Myopie">
                <div class="col font-weight-bold" *ngIf="showLabels">
                    {{ 'lens.myopie' | translate }}
                </div>
                <div class="col">
                    <span id="myopiaactive">
                        <strong>{{ 'client.myopiaactive' | translate }}</strong>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
