export class DreamLiteWizardFixType {
    Id: number;
    Code: string;
    Name: string;
    ShowDegree: boolean;
    ShowCorrection: boolean;
    DegreeValue: number;
    CorrectionValue: number;
    Enabled: boolean;
    Visible: boolean;
    MinValue: number;
    MaxValue: number;
    Step: number;
    Rule: string;
    IsApplied: boolean;
}
