<div *ngIf="isHome" class="glow"></div>
<nav id="nav" class="ms-auto navbar navbar-expand-lg navbar-dark d-flex" [ngClass]="isHome ? 'home' : 'main'">
    <div class="ms-2 ms-lg-4 d-flex">
        <a stop-propagation id="AppTitle" class="navbar-brand show-as-clickable" (click)="gotoHome()">
            <div class="cooperVision-logo"></div>
            <div class="logo-visavy">
                <img src="assets/images/logo-visavy.svg" alt="Visavy" />
            </div>
        </a>
    </div>

    <div class="text-truncate align-self-center" *ngIf="currentRoleId === roles.Administrator">
        <h2 class="text-truncate">{{ currentAuthenticatedUser.Name }}</h2>
    </div>

    <div class="text-truncate align-self-center" *ngIf="currentRoleId === roles.Logistics">
        <h2 class="text-truncate">
            {{ 'main-header-role-logged-in.Logistics' | translate }}
        </h2>
    </div>

    <div class="text-truncate align-self-center" *ngIf="currentRoleId === roles.QualityAssurance">
        <h2 class="text-truncate">
            {{ 'main-header-role-logged-in.QualityAssurance' | translate }}
        </h2>
    </div>

    <div class="text-truncate align-self-center" *ngIf="currentRoleId === roles.Ps">
        <h2 class="text-truncate">
            {{ 'main-header-role-logged-in.Support' | translate }}
        </h2>
    </div>

    <div class="text-truncate align-self-center" *ngIf="currentRoleId === roles.Optician && appState.currentOptician">
        <h2 *ngIf="!isHome" class="text-truncate opticianName">
            {{ appState.currentOptician.Name }}
        </h2>
        <h2 *ngIf="isHome" class="text-truncate opticianName">
            {{ appState.currentOptician.Name }}
        </h2>
    </div>

    <div *ngIf="appState.isGuiTest" id="testRandomClick"></div>

    <div class="ms-auto d-flex">
        <button
            class="navbar-toggler navbar-toggler-right collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div
            class="collapse navbar-collapse"
            id="navbarSupportedContent"
            data-bs-target="#navbarSupportedContent"
            data-bs-toggle="collapse">
            <ul class="navbar-nav my-lg-auto p-3" id="menuItems">
                <ng-container *ngIf="currentRoleId === roles.Optician">
                    <li
                        class="nav-item py-1"
                        *hasFeatureSetting="[
                            {
                                Feature: Features.ShowDynamics,
                                Category: FeatureCategories.Distributor,
                                IsEnabled: true
                            }
                        ]">
                        <div class="dropdown">
                            <a
                                id="Orders"
                                class="nav-link dropdown-toggle"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <i class="fa fa-shopping-cart fa-lg"></i>
                                {{ 'general.orders' | translate }}
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="Orders">
                                <li>
                                    <a
                                        id="OrderHistory"
                                        [routerLink]="['/dynamics/order-history']"
                                        class="nav-item dropdown-item"
                                        href="#">
                                        {{ 'general.Menu.Orderhistory' | translate }}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        id="ReturnsAuthorization"
                                        [routerLink]="['/dynamics/returns-authorization']"
                                        class="nav-item dropdown-item"
                                        href="#">
                                        {{ 'general.Menu.Returnauthorization' | translate }}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        id="Shipments"
                                        [routerLink]="['/dynamics/shipments']"
                                        class="nav-item dropdown-item"
                                        href="#">
                                        {{ 'general.Menu.Shipments' | translate }}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        id="Statements"
                                        [routerLink]="['/dynamics/statements']"
                                        class="nav-item dropdown-item"
                                        href="#">
                                        {{ 'general.Menu.Statements' | translate }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ng-container>
                <li class="nav-item py-1" *ngIf="maintenanceMenuVisible">
                    <div class="dropdown">
                        <a
                            id="MenuMaintenance"
                            class="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i class="fa fa-cog fa-lg"></i>
                            {{ 'general.Menu.Maintenance' | translate }}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="MenuMaintenance">
                            <li *showMenuItem="'MenuLenses'">
                                <a id="MenuLenses" [routerLink]="['/manage/lenses']" class="nav-item dropdown-item">
                                    {{ 'general.Menu.Lenses' | translate }}
                                </a>
                            </li>
                            <div *showMenuItem="'MenuLensConfigurationAdminDivider'" class="dropdown-divider"></div>
                            <li *showMenuItem="'MenuDatasheets'">
                                <a
                                    id="MenuDatasheets"
                                    [routerLink]="['/manage/datasheets']"
                                    class="nav-item dropdown-item">
                                    {{ 'general.Menu.Datasheets' | translate }}
                                </a>
                            </li>
                            <li *showMenuItem="'MenuLensParameters'">
                                <a
                                    id="MenuLensParameters"
                                    [routerLink]="['/manage/lensparameters']"
                                    class="nav-item dropdown-item">
                                    {{ 'general.Menu.Lensparameters' | translate }}
                                </a>
                            </li>
                            <li *showMenuItem="'MenuProducts'">
                                <a id="MenuProducts" [routerLink]="['/manage/products']" class="nav-item dropdown-item">
                                    {{ 'general.Menu.Products' | translate }}
                                </a>
                            </li>
                            <li *showMenuItem="'MenuProductGroups'">
                                <a
                                    id="MenuProductGroups"
                                    [routerLink]="['/manage/productgroups']"
                                    class="nav-item dropdown-item">
                                    {{ 'general.Menu.ProductGroups' | translate }}
                                </a>
                            </li>
                            <div
                                *showMenuItem="'MenuAdministratorDistributorAdminDivider'"
                                class="dropdown-divider"></div>
                            <li *showMenuItem="'MenuCompanies'">
                                <a
                                    id="MenuCompanies"
                                    [routerLink]="['/manage/companies']"
                                    class="nav-item dropdown-item">
                                    {{ 'general.Menu.Companies' | translate }}
                                </a>
                            </li>
                            <li *showMenuItem="'MenuDistributors'">
                                <a
                                    id="MenuDistributors"
                                    [routerLink]="['/manage/distributors']"
                                    class="nav-item dropdown-item">
                                    {{ 'general.Menu.Distributors' | translate }}
                                </a>
                            </li>
                            <li *showMenuItem="'MenuManufacturers'">
                                <a
                                    id="MenuManufacturers"
                                    [routerLink]="['/manage/manufacturers']"
                                    class="nav-item dropdown-item">
                                    {{ 'general.Menu.Manufacturers' | translate }}
                                </a>
                            </li>
                            <li *showMenuItem="'MenuOpticians'">
                                <a
                                    id="MenuOpticians"
                                    [routerLink]="['/manage/opticians']"
                                    class="nav-item dropdown-item">
                                    {{ 'general.Menu.Opticians' | translate }}
                                </a>
                            </li>
                            <li *showMenuItem="'MenuUsers'">
                                <a [routerLink]="['/manage/users']" class="nav-item dropdown-item menuUsers">
                                    {{ 'general.Menu.Users' | translate }}
                                </a>
                            </li>
                            <li *showMenuItem="'MenuImport'">
                                <a [routerLink]="['/manage/import']" class="nav-item dropdown-item menuImport">
                                    {{ 'general.Menu.Import' | translate }}
                                </a>
                            </li>
                            <div *showMenuItem="'MenuAdministratorDivider'" class="dropdown-divider"></div>
                            <li *showMenuItem="'MenuCreditSettings'">
                                <a
                                    [routerLink]="['/manage/creditsettings']"
                                    class="nav-item dropdown-item menuCreditSettings">
                                    {{ 'general.Menu.creditsettings' | translate }}
                                </a>
                            </li>
                            <li *showMenuItem="'MenuDistributorCustomizations'">
                                <a
                                    [routerLink]="['/manage/distributorcustomizations']"
                                    class="nav-item dropdown-item menuDistributorCustomizations">
                                    {{ 'general.Menu.DistributorCustomizations' | translate }}
                                </a>
                            </li>
                            <div *showMenuItem="'MenuAdministratorDivider'" class="dropdown-divider"></div>
                            <li *showMenuItem="'MenuLanguages'">
                                <a [routerLink]="['/manage/languages']" class="nav-item dropdown-item menuLanguages">
                                    {{ 'general.Menu.Languages' | translate }}
                                </a>
                            </li>
                            <li *showMenuItem="'MenuLanguagesNew'">
                                <a [routerLink]="['/manage/languages-new']" class="nav-item dropdown-item">
                                    {{ 'general.Menu.Languages' | translate }}
                                </a>
                            </li>
                            <li *showMenuItem="'MenuTranslationCategories'">
                                <a [routerLink]="['/manage/translation-categories']" class="nav-item dropdown-item">
                                    {{ 'general.Menu.TranslationCategories' | translate }}
                                </a>
                            </li>
                            <li *showMenuItem="'MenuTranslation'">
                                <a [routerLink]="['/translator']" class="nav-item dropdown-item">
                                    {{ 'translator.pageTranslate' | translate }}
                                </a>
                            </li>
                            <div *showMenuItem="'MenuAdministratorDivider'" class="dropdown-divider"></div>
                            <li *showMenuItem="'MaintenanceJobs'">
                                <a [routerLink]="['/manage/maintenance-jobs']" class="nav-item dropdown-item">
                                    {{ 'maintenanceJobs.pageTitle' | translate }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item py-1">
                    <a
                        stop-propagation
                        id="homeLink"
                        class="nav-link"
                        (click)="gotoHome()"
                        href="#"
                        data-toggle="collapse"
                        data-target="#navbarCollapse">
                        <i class="fas fa-home fa-lg"></i>
                        {{ 'general.home' | translate }}
                    </a>
                </li>
                <li class="nav-item py-1">
                    <a
                        stop-propagation
                        class="nav-link settingsLink"
                        (click)="showSettings()"
                        href="#"
                        data-toggle="collapse"
                        data-target="#navbarCollapse">
                        <i class="fas fa-cog fa-lg"></i>
                        {{ 'general.settings' | translate }}
                    </a>
                </li>
                <ng-container *ngIf="currentRoleId === roles.Optician">
                    <li
                        class="nav-item py-1"
                        *hasFeatureSetting="[
                            {
                                Feature: Features.ReturnsAllowed,
                                Category: FeatureCategories.Optician,
                                IsEnabled: true
                            }
                        ]">
                        <a
                            id="serviceLink"
                            class="nav-link"
                            (click)="gotoService()"
                            href="#"
                            onclick="return false;"
                            data-toggle="collapse"
                            data-target="#navbarCollapse">
                            <i class="fas fa fa-concierge-bell fa-lg"></i>
                            {{ 'general.servicerequest' | translate }}
                        </a>
                    </li>
                </ng-container>
                <ng-container *ngIf="currentRoleId === roles.Optician">
                    <li
                        class="nav-item py-1"
                        *hasFeatureSetting="[
                            {
                                Feature: Features.ComplaintsAllowed,
                                Category: FeatureCategories.Optician,
                                IsEnabled: true
                            }
                        ]">
                        <a
                            id="complaintLink"
                            class="nav-link"
                            (click)="gotoComplaint()"
                            href="#"
                            onclick="return false;"
                            data-toggle="collapse"
                            data-target="#navbarCollapse">
                            <i class="fas fa fa-concierge-bell fa-lg"></i>
                            {{ 'service.complaint' | translate }}
                        </a>
                    </li>
                </ng-container>
                <li class="nav-item py-1" *ngIf="currentAuthenticatedUser">
                    <a
                        stop-propagation
                        id="logoutLink"
                        class="nav-link"
                        (click)="logout()"
                        href="#"
                        data-toggle="collapse"
                        data-target="#navbarCollapse">
                        <i class="fas fa-sign-out-alt fa-lg"></i>
                        {{ 'general.logout' | translate }}
                    </a>
                </li>
                <li class="nav-item py-1" *ngIf="!currentAuthenticatedUser">
                    <a id="loginLink" [routerLink]="['/login']" class="nav-link" href="#">
                        <i class="fas fa-sign-in-alt fa-lg"></i>
                        {{ 'general.login' | translate }}
                    </a>
                </li>
            </ul>
        </div>

        <ul class="navbar-nav flex-row align-items-center">
            <li role="separator" class="divider">&nbsp;</li>

            <li class="nav-item align-self-center">
                <div class="position-relative teamviewer-panel">
                    <a target="_blank" href="{{ teamViewerUrl }}">
                        <img alt="teamViewer" class="teamviewer" src="assets/images/teamviewer.png" />
                    </a>
                </div>
            </li>

            <li class="nav-item align-self-center" *ngIf="currentRoleId === roles.Optician">
                <a stop-propagation class="shoppingBasket" (click)="gotoShoppingBasket()" [ngClass]="shoppingCartClass">
                    <div class="faButton position-relative">
                        <i class="fas fa-shopping-cart fa-lg"></i>
                        <div *ngIf="shoppingCartSize > 0" class="position-absolute shoppingcartcount">
                            <span class="badge rounded-pill bg-danger m-0">
                                {{ shoppingCartSize }}
                            </span>
                        </div>
                    </div>
                </a>
            </li>

            <li class="nav-item align-self-center ms-2">
                <theme-switch></theme-switch>
            </li>
        </ul>
    </div>
</nav>
