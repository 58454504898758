export class Frame {
    Id: number;
    Material: string;
    Brand: string;
    Model: string;
    Color: string;
    PupilDistanceLeft: number;
    PupilDistanceRight: number;
    OcularCenterLeft: number;
    OcularCenterRight: number;
    SizeBoxA: number;
    SizeBoxB: number;
    DblDimension: number;
    BevelType: string;
    BevelPosition: number;
    EdgePolish: boolean;
    EdgePinbevel: boolean;
    TraceFile: string;
    TracerName: string;
    TracerSizeBoxA: number;
    TracerSizeBoxB: number;
    TracerDblDimension: number;
}
