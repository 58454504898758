import { Component, Input, EventEmitter, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { OrderInfo } from '@app/shared/models/orderInfo.model';
import { TranslateService } from '@ngx-translate/core';
import { OrderTypes, OrderLineStatus, OrderPriorities, SupportOrderStatuses, Features } from '@app/shared/enums';
import { OrderStatusItemType } from '@app/shared/models/orderStatusItemType.model';
import { FeatureCheckerService } from '@app/shared/appservices/feature-checker.service';

@Component({
    selector: 'order-status-item',
    templateUrl: 'order-status-item.component.html',
    styleUrls: ['order-status-item.component.scss'],
})
export class OrderStatusItemComponent implements OnInit, OnChanges {
    @Input() id = '';
    @Input() order: OrderInfo;
    @Input() isSelected = false;
    @Input() hasNewMessage = false;
    @Input() ignoreSupportOrderStatus = false;

    @Output() orderSelected$: EventEmitter<OrderInfo> = new EventEmitter();

    public orderStatusTypes = OrderStatusItemType;
    public orderLineStatus = OrderLineStatus;
    public orderPriorities = OrderPriorities;
    public classNames: string[] = [];
    public orderStatusItemType: OrderStatusItemType;

    constructor(
        public readonly translate: TranslateService,
        private readonly featureCheckerService: FeatureCheckerService,
    ) {}

    public ngOnInit(): void {
        this.classNames = this.resolveClassNames();
        this.orderStatusItemType = this.resolveItemType();

        if (this.isSelected) {
            this.classNames.push('selected');
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.isSelected.currentValue === false) {
            this.classNames = this.classNames.filter((c) => c !== 'selected');
        }
    }

    public click(order: OrderInfo): void {
        this.classNames.push('selected');
        this.orderSelected$.emit(order);
    }

    private resolveItemType(): OrderStatusItemType {
        if (!this.order) {
            return;
        }

        if (this.order.OrderTypeId === OrderTypes.StockOrder) {
            return OrderStatusItemType.StockOrder;
        }
        if (this.order.IsSerialOrder) {
            return OrderStatusItemType.SerialOrder;
        }

        if (this.order.IsLensOrder) {
            return OrderStatusItemType.LensOrder;
        } else {
            return OrderStatusItemType.DirectOrder;
        }
    }

    private resolveClassNames(): string[] {
        const result = [];

        if (!this.order) {
            return result;
        }

        if (this.isSelected) {
            result.push('selected');
        }

        if (this.hasNewMessage) {
            result.push('waiting');
            return result;
        }

        if (this.order.OrderTypeId === OrderTypes.SupportOrder && !this.ignoreSupportOrderStatus) {
            result.push(SupportOrderStatuses[this.order.SupportOrderStatusId].toLowerCase());
            return result;
        }

        if (this.featureCheckerService.isCompanyFeatureEnabled(Features.HideOrderStatusFields)) {
            result.push('no-filter');
            return result;
        } else {
            result.push(OrderLineStatus[this.order.OrderStatusId].toLowerCase());
            return result;
        }
    }
}
