import { Component, Input, ViewEncapsulation, Optional, Host, SkipSelf } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseControl } from '@app/shared/components/inputs/base-control';
import { ListSelectOption } from '@app/shared/models/listSelectOption.model';

@Component({
    selector: 'mpc-input-slider-list',
    templateUrl: './input-slider-list.component.html',
    styleUrls: ['./input-slider-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputSliderListComponent,
            multi: true,
        },
    ],
})
export class InputSliderListComponent extends BaseControl {
    _options: ListSelectOption[];

    @Input() set options(options: ListSelectOption[]) {
        if (options) {
            this._options = structuredClone(options);
            this.matchOptions();
        }
    }
    @Input() mode = 'multiple';
    @Input() direction = 'vertical';

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        public controlContainer: ControlContainer,
    ) {
        super(controlContainer);
    }

    writeValue(value: ListSelectOption[]): void {
        if (value) {
            this.value = structuredClone(value);
            this.matchOptions();
        }
    }

    matchOptions(): void {
        if (this.value && this._options) {
            const value = this.value as ListSelectOption[];

            this._options = this._options.map(
                (o) =>
                    ({
                        Id: o.Id,
                        Code: o.Code,
                        Name: o.Name,
                        Selected: value.findIndex((v) => v.Id === o.Id) >= 0,
                    }) as ListSelectOption,
            );

            this.value = this._options.filter((o) => o.Selected);
        }
    }

    onSelect(item: ListSelectOption): void {
        if (item && this._options && this._options.length > 0) {
            const checkedOption = this._options.find((o) => o.Id === item.Id);
            checkedOption.Selected = !checkedOption.Selected;
            this.value = this._options.filter((o) => o.Selected);
            this.onChange(this.value);
        }

        this.onTouched();
    }

    propagateChange(): void {
        this.value = !this.value;
        this.onChange(this.value);
        this.onTouched();
    }
}
