export class InputDate {
    constructor(year: number, month: number, day: number) {
        this.year = year;
        this.month = month;
        this.day = day;
    }

    day: number;
    month: number;
    year: number;

    public static from(value: unknown): InputDate {
        let day: number;
        let month: number;
        let year: number;

        // check what kind of input we have
        if (value instanceof Date) {
            const d = value as Date;
            day = d.getDate();
            month = d.getMonth() + 1;
            year = d.getFullYear();
        } else if (value instanceof InputDate) {
            const idp = value as InputDate;
            day = idp.day;
            month = idp.month;
            year = idp.year;
        } else if (typeof value === 'string') {
            const d = new Date(value as string);
            day = d.getDate();
            month = d.getMonth() + 1;
            year = d.getFullYear();
        } else if (value instanceof Array && value.length === 3) {
            day = value[0];
            month = value[1];
            year = value[2];
        }

        if (year && month && day) {
            return new InputDate(year, month, day);
        }

        return null;
    }
}
