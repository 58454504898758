import { Component } from '@angular/core';
import { CartItemLineInfo } from '@app/shared/models/cartItemLineInfo.model';
import { FormBuilder } from '@angular/forms';
import { ProposalService } from '@app/core/services/api/proposal.service';
import { FittingService } from '@app/core/services/fitting.service';
import { CartItemBaseTableComponent } from '@app/features/cart/components';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { Features, FeatureCategories, ParameterTypeCodes, InputTypes } from '@app/shared/enums';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'cart-item-lens-table',
    templateUrl: './cart-item-lens-table.component.html',
    styleUrls: ['./cart-item-lens-table.component.scss'],
})
export class CartItemLensTableComponent extends CartItemBaseTableComponent {
    FeatureCategories = FeatureCategories;
    Features = Features;
    inputTypes = InputTypes;

    headerParameters: { code: string }[] = [];

    constructor(
        protected fb: FormBuilder,
        protected appStateService: AppStateService,
        private proposalService: ProposalService,
        private fittingService: FittingService,
        private loaderService: LoaderService,
    ) {
        super(fb, appStateService);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.headerParameters = this.createHeaderParameters(this.items);
    }

    public async edit(item: CartItemLineInfo): Promise<void> {
        this.loaderService.show();
        const prop = await lastValueFrom(this.proposalService.getById(item.ProposalId));
        this.fittingService.editFromCart(prop);
        this.loaderService.hide();
    }

    readonly parameterOrder: string[] = [
        ParameterTypeCodes.POW,
        ParameterTypeCodes.CYL,
        ParameterTypeCodes.AX,
        ParameterTypeCodes.DIAM,
        ParameterTypeCodes.RAD,
    ];

    private createHeaderParameters(orderLines: CartItemLineInfo[]) {
        const uniqueParameters = [
            ...new Map(
                orderLines
                    .filter((x) => x.FittedLensParameters)
                    .flatMap((x) => x.FittedLensParameters)
                    .map((x) => [x.LensDefinitionParameter.ParameterType.Code, x]),
            ).values(),
        ];

        return uniqueParameters
            .sort((a, b) => {
                const aIndex = this.parameterOrder.indexOf(
                    ParameterTypeCodes[a.LensDefinitionParameter.ParameterType.Code],
                );
                const bIndex = this.parameterOrder.indexOf(
                    ParameterTypeCodes[b.LensDefinitionParameter.ParameterType.Code],
                );
                if (aIndex === -1 && bIndex === -1) {
                    return (
                        a.LensDefinitionParameter.ParameterType.SortOrder -
                        b.LensDefinitionParameter.ParameterType.SortOrder
                    );
                }
                if (aIndex === -1) {
                    return 1;
                }
                if (bIndex === -1) {
                    return -1;
                }
                return aIndex - bIndex;
            })
            .map((param) => {
                return {
                    code: param.LensDefinitionParameter.ParameterType.Code,
                };
            });
    }
}
