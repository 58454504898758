import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-tour-dialog',
    templateUrl: './product-tour-dialog.component.html',
    styleUrls: ['./product-tour-dialog.component.scss'],
})
export class ProductTourDialogComponent {
    @Input() title: string;

    @Input() onStartTour: () => void;
    @Input() onNeverShow: () => void;
    @Input() onReminderLater: () => void;

    constructor(private modalRef: BsModalRef) {}

    startTour(): void {
        this.onStartTour();
        this.modalRef.hide();
    }

    neverShow(): void {
        this.onNeverShow();
        this.modalRef.hide();
    }

    nexTime(): void {
        this.onReminderLater();
        this.modalRef.hide();
    }
}
