import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isInArray',
})
export class IsInArrayPipe implements PipeTransform {
    transform(array: unknown[], id: unknown): boolean {
        return array?.includes(id);
    }
}
