import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { AppConfig } from '@app/shared/models';
import { Observable, Subscriber, lastValueFrom } from 'rxjs';
import { REGION_EU, REGION_US } from '../constants/regions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root',
})
export class AppConfigService implements OnDestroy {
    public apiEndpoint = '';
    public appTimezoneOffset = 0;
    public wordpressApiEndpoint = '';
    public apiEndpointChanges$: Observable<string>;
    public appConfig: AppConfig;

    private apiEndpointSubscribers: Subscriber<string>[] = [];

    constructor(
        private location: Location,
        private readonly httpClient: HttpClient,
    ) {
        this.apiEndpointChanges$ = new Observable<string>((subscriber: Subscriber<string>) => {
            this.apiEndpointSubscribers.push(subscriber);

            return () => {
                this.apiEndpointSubscribers = this.apiEndpointSubscribers.filter((sub) => sub !== subscriber);
            };
        }).pipe(takeUntilDestroyed());
    }

    ngOnDestroy(): void {
        this.apiEndpointSubscribers.forEach((sub) => sub.complete());
    }

    async loadConfiguration(): Promise<void> {
        this.appConfig = await lastValueFrom(
            this.httpClient.get<AppConfig>(this.location.prepareExternalUrl('/assets/config.json')),
        );
    }

    public setAppEndpoint(region: string): boolean {
        switch (region) {
            case REGION_US:
                this.apiEndpoint = this.appConfig.regions.americas.apiEndpoint;
                this.wordpressApiEndpoint = this.appConfig.regions.americas.wordpressEndpoint;
                break;
            case REGION_EU:
                this.apiEndpoint = this.appConfig.regions.emea.apiEndpoint;
                this.wordpressApiEndpoint = this.appConfig.regions.emea.wordpressEndpoint;
                break;
            default:
                return false;
        }

        this.appTimezoneOffset = -(new Date().getTimezoneOffset() / 60);
        this.apiEndpointSubscribers.forEach((sub) => sub.next(this.apiEndpoint));
        return true;
    }
}
