import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { ServiceRequest } from '@app/shared/models/service-request.model';
import { AlertService } from '@app/shared/appservices/alert.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { Roles, ServiceStatus, ReturnType } from '@app/shared/enums';
import { filter, map, switchMap, finalize, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, lastValueFrom } from 'rxjs';
import { ReturnService } from '@app/core/services/api/return.service';

@Component({
    selector: 'barcode-scan',
    templateUrl: './qa-barcode-scan.component.html',
    styleUrls: ['qa-barcode-scan.component.scss'],
})
export class QaBarcodeScanComponent implements OnInit, AfterViewInit {
    @ViewChild('barcode') barcodefield: ElementRef;
    formGroup: UntypedFormGroup;
    serviceData: ServiceRequest;
    userRole: Roles;
    serviceRequestId: number;
    isReadOnly = true;
    serviceStatuses = ServiceStatus;
    loading: boolean;
    currentStep = 0;

    barcodeSearchControl!: FormControl<string>;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly returnService: ReturnService,
        private readonly alertService: AlertService,
        private readonly appState: AppStateService,
        private readonly route: ActivatedRoute,
        private readonly translate: TranslateService,
    ) {}

    ngOnInit() {
        this.createForm();
        this.loading = false;
        this.userRole = this.appState.authenticatedUser.CurrentRoleId;

        this.route.queryParams
            .pipe(
                filter((params) => params['barcode']),
                map((params) => params['barcode']),
                tap((barcode) => {
                    this.barcodeSearchControl.patchValue(barcode, {
                        emitEvent: false,
                        onlySelf: true,
                    });
                }),
                switchMap((barcode) => this.searchBarcode(barcode)),
            )
            .subscribe();
    }

    ngAfterViewInit(): void {
        this.barcodefield.nativeElement.focus();
    }

    autofillForm(): void {
        this.formGroup.controls['serviceRequestNumber'].patchValue(this.serviceData.ReturnNumber);
        this.formGroup.controls['opticianName'].patchValue(this.serviceData.OpticianName);

        this.isReadOnly = true;

        const returnStatusId = this.serviceData.ReturnStatusId;
        if (
            (this.userRole === Roles.QualityAssurance && returnStatusId === ServiceStatus.Received) ||
            (this.userRole === Roles.Logistics && returnStatusId === ServiceStatus.AwaitingProduct)
        ) {
            this.isReadOnly = false;
        }
    }

    createForm(): void {
        this.formGroup = this.fb.group({
            barcode: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d+)\-(\d+)\-(\d+)$/)])],
            serviceRequestNumber: [''],
            opticianName: [''],
        });
        this.formGroup.disable();

        this.barcodeSearchControl = this.formGroup.controls['barcode'] as FormControl<string>;
        this.barcodeSearchControl.enable();
    }

    serviceRequestHasTypeComplication(): boolean {
        return this.serviceData && this.serviceData.ReturnTypeId === ReturnType.Complication;
    }

    search() {
        const barcode: string = this.barcodeSearchControl.value;
        this.loading = true;
        this.searchBarcode(barcode)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe();
    }

    searchBarcode(barcode: string): Observable<ServiceRequest | null> {
        this.barcodeSearchControl.markAsPristine();
        return this.returnService.findReturnByBarcode(barcode).pipe(
            tap((request) => {
                this.serviceData = request;

                if (this.serviceData) {
                    this.autofillForm();
                }
            }),
        );
    }

    gotoStep(step: number) {
        this.currentStep = step;
    }

    async changeStatus(serviceStatus: number) {
        this.serviceData.ReturnStatusId = serviceStatus;
        lastValueFrom(this.returnService.saveServiceRequest(this.serviceData)).then(() => {
            if (
                this.serviceData.ReturnTypeId === ReturnType.Complication &&
                serviceStatus === this.serviceStatuses.Received
            ) {
                this.alertService.info(this.translate.instant('service.desinfectAndQuarantineLens'), true);
            }
            if (serviceStatus === this.serviceStatuses.QaHandling) {
                this.gotoStep(1);
            }
        });
    }

    get userIsInRolQualityAssurance(): boolean {
        return this.userRole === Roles.QualityAssurance;
    }

    get userIsInRolLogistics(): boolean {
        return this.userRole === Roles.Logistics;
    }
}
