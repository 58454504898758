import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EmailModel } from '@app/shared/models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EmailService {
    private readonly basePath = 'api/emails';

    constructor(private readonly httpClient: HttpClient) {}

    public listEmails(): Observable<EmailModel[]> {
        return this.httpClient.get<EmailModel[]>(this.basePath);
    }
}
