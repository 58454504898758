import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceStatus, Roles } from '@app/shared/enums';
import { ReturnStatusChange } from '@app/shared/models/returnStatusChange';
import { ServiceRequest } from '@app/shared/models/service-request.model';
import { ModalService } from '../../modal/modal.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { ReturnConclusionChanges } from '@app/shared/models/returnConclusionChanges.model';
import { ReturnQAConclusionChanges } from '@app/shared/models/returnQualityAssuranceConclusionChanges.model';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { Util } from '@app/shared/helpers/utility.helper';
import { QaService } from '@app/core/services/api/qa.service';
import { ReturnService } from '@app/core/services/api/return.service';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-return-history',
    templateUrl: './return-history.component.html',
    styleUrls: ['./return-history.component.scss'],
})
export class ReturnHistoryComponent implements OnChanges {
    returnSelected: boolean;
    showModal: boolean;
    returnStatuses: Array<ReturnStatusChange>;
    returnConclusionChanges: Array<ReturnConclusionChanges>;
    returnQAConclusion: Array<ReturnQAConclusionChanges>;
    tabNames = new Map<string, boolean>();
    startScan = 'general.startScan';
    openRequest = 'general.openRequest';
    processButtonText = this.startScan;

    statusChangesTab = 'StatusChangesTab';
    qaTab = 'QaTab';
    psTab = 'PsTab';

    @Input()
    selectedReturn: ServiceRequest;

    @Input()
    redirectToSelectedReturn: boolean;

    @Output()
    returnscan = new EventEmitter<boolean>();

    constructor(
        private readonly appState: AppStateService,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly returnService: ReturnService,
        private readonly loaderService: LoaderService,
        private readonly qaService: QaService,
    ) {}

    openModal(id: string) {
        this.selectTab(this.statusChangesTab);
        this.modalService.open(id);
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    downloadPdf() {
        this.loaderService.show();
        this.returnService.downloadPdf(this.selectedReturn.Id).subscribe((file) => {
            Util.openBlobInBrowser(file, file.name);
        });
        this.loaderService.hide();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.redirectToSelectedReturn?.currentValue) {
            this.redirectToSelectedReturn = true;
            this.routerLink();
        } else {
            this.redirectToSelectedReturn = false;
        }
        if (changes.selectedReturn?.previousValue !== changes.selectedReturn?.currentValue) {
            this.selectedReturn = changes.selectedReturn?.currentValue;
        }
        if (this.selectedReturn === undefined || this.selectedReturn === null) {
            this.returnSelected = false;
        } else {
            this.returnSelected = true;
            switch (this.selectedReturn.ReturnStatus.Id) {
                case ServiceStatus.AwaitingProduct:
                    this.processButtonText = this.startScan;
                    break;
                case ServiceStatus.Received:
                    this.processButtonText = this.startScan;
                    break;
                case ServiceStatus.QaHandling:
                    this.processButtonText = this.openRequest;
                    break;
                case ServiceStatus.PsHandling:
                    this.processButtonText = this.openRequest;
                    break;
                case ServiceStatus.Handled:
                    this.processButtonText = this.openRequest;
                    break;
                default:
                    this.processButtonText = this.startScan;
                    break;
            }
        }
    }

    selectTab(tabId: string) {
        switch (tabId) {
            case this.statusChangesTab:
                this.changeTabNames(true, false, false);
                lastValueFrom(this.returnService.findAllReturnStatusChangesByReturnID(this.selectedReturn.Id)).then(
                    (r) => (this.returnStatuses = r),
                );
                break;
            case this.qaTab:
                this.changeTabNames(false, true, false);
                lastValueFrom(this.qaService.findAllReturnQAChangesByReturnID(this.selectedReturn.Id)).then(
                    (r) => (this.returnQAConclusion = r),
                );
                break;
            case this.psTab:
                this.changeTabNames(false, false, true);
                lastValueFrom(this.returnService.findAllReturnConclusionChangesByReturnID(this.selectedReturn.Id)).then(
                    (r) => (this.returnConclusionChanges = r),
                );
                break;
        }
    }

    changeTabNames(status: boolean, qa: boolean, ps: boolean) {
        this.tabNames.set(this.statusChangesTab, status);
        this.tabNames.set(this.qaTab, qa);
        this.tabNames.set(this.psTab, ps);
    }

    getSelectedTab(tabId: string): boolean {
        return this.tabNames.get(tabId);
    }

    routerLink(): void {
        let barcode = '';
        const serviceScan = '/service/scan';

        if (
            this.selectedReturn?.ReturnCategoryId !== undefined &&
            this.selectedReturn?.OpticianId !== undefined &&
            this.selectedReturn?.OpticianReturnNumber !== undefined
        ) {
            barcode =
                this.selectedReturn.ReturnCategoryId +
                '-' +
                this.selectedReturn.OpticianId.toString().padStart(7, '0') +
                '-' +
                this.selectedReturn.OpticianReturnNumber.toString().padStart(6, '0');

            const currentRoleId = this.appState?.authenticatedUser?.CurrentRoleId;
            const selectedReturnStatusId = this.selectedReturn.ReturnStatusId;

            const toBarcodeScreen =
                (selectedReturnStatusId === ServiceStatus.AwaitingProduct && currentRoleId === Roles.Logistics) ||
                (selectedReturnStatusId === ServiceStatus.Received && currentRoleId === Roles.QualityAssurance);

            const toQaVariableScreen =
                selectedReturnStatusId === ServiceStatus.QaHandling && currentRoleId === Roles.QualityAssurance;

            const toPsConclusionScreen = currentRoleId === Roles.Ps;

            if (toBarcodeScreen) {
                this.router.navigateByUrl(serviceScan + '/?barcode=' + barcode).then();
            } else if (toQaVariableScreen) {
                //returns false to the qa-complaints-overview.component.ts component changes to show QA variable Screen component
                if (!this.redirectToSelectedReturn) {
                    this.returnscan.emit(false);
                }
            } else if (toPsConclusionScreen) {
                this.router.navigateByUrl('/ps/complaint/' + this.selectedReturn.Id).then();
            } else {
                this.router.navigateByUrl(serviceScan).then();
            }
        } else {
            this.router.navigateByUrl(serviceScan).then();
        }
    }
}
