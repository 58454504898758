<div class="container-fluid manualrequestcontainer mt-4 px-lg-4 flex-fill">
    <div class="title-border-bottom mb-1">
        <h3 class="mt-auto">{{ 'logistics.manualrequest' | translate }}</h3>
    </div>

    <div class="col-6 border form-card mt-2 pb-2">
        <form [formGroup]="searchForm" (ngSubmit)="searchSerialNumber()">
            <div class="row form-group">
                <div class="col-9 mt-2">
                    <div class="card my-1 pt-1 px-1">
                        <mpc-input-text
                            formControlName="searchTerm"
                            [id]="'serialNumber'"
                            label="{{ 'general.serialnumberlong' | translate }}"
                            placeholder="general.serialnumberlong"></mpc-input-text>
                    </div>
                </div>
                <div class="col-3 mt-3">
                    <button class="btn-block btn btn-primary" type="submit">{{ 'general.search' | translate }}</button>
                </div>
            </div>
        </form>
    </div>

    <div [formGroup]="returnForm" class="col-12 border form-card mt-2 pb-2">
        <div *ngIf="loading" class="spinner-border spinner-border-sm m-2 align-self-start"></div>
        <div class="row">
            <div class="col-6 mt-2">
                <div *ngIf="validSerialNumber">
                    <div class="card my-1 pt-1 px-1">
                        <mpc-input-text
                            formControlName="serialNumber"
                            [id]="'serialNumber'"
                            label="{{ 'general.serialnumberlong' | translate }}"
                            placeholder="general.serialnumberlong"></mpc-input-text>
                    </div>
                    <div class="card my-1 pt-1 px-1">
                        <mpc-input-text
                            formControlName="product"
                            [id]="'product'"
                            label="{{ 'general.product' | translate }}"
                            placeholder="general.product"></mpc-input-text>
                    </div>
                    <div class="card my-1 pt-1 px-1">
                        <mpc-select
                            formControlName="eyeSide"
                            [id]="'eyeside'"
                            label="{{ 'general.eyesides' | translate }}"
                            placeholder="general.eyesides"
                            [items]="eyeSides"></mpc-select>
                    </div>
                    <div class="card my-1 pt-1 px-1">
                        <mpc-select
                            formControlName="material"
                            [id]="'material'"
                            label="{{ 'general.material' | translate }}"
                            placeholder="general.product"
                            [items]="materials"></mpc-select>
                    </div>
                </div>
            </div>
            <div class="col-6 mt-2" *ngIf="validSerialNumber">
                <div class="card my-1 pt-1 px-1">
                    <div class="row">
                        <div class="col-form-label col-md-4 my-auto">
                            <label for="optician">
                                {{ 'general.optician' | translate }}
                            </label>
                        </div>
                        <div class="col-md-8">
                            <input
                                #opticanTypeAheadInput
                                id="optician"
                                formControlName="optician"
                                [typeahead]="opticians"
                                (typeaheadOnSelect)="setOptician($event)"
                                [typeaheadOptionsLimit]="4"
                                class="form-control"
                                typeaheadOptionField="Name" />
                        </div>
                    </div>
                </div>
                <div class="card my-1 pt-1 px-1">
                    <mpc-input-text
                        formControlName="reference"
                        [id]="'reference'"
                        label="{{ 'general.reference' | translate }}"
                        placeholder="general.reference"></mpc-input-text>
                </div>
                <div class="card my-1 pt-1 px-1" *ngIf="returnForm.controls.reference.value">
                    <mpc-input-text
                        formControlName="clientReference"
                        [id]="'clientReference'"
                        label="{{ 'general.reference2' | translate }}"
                        placeholder="general.reference2"></mpc-input-text>
                </div>
                <div class="card my-1 pt-1 px-1">
                    <mpc-select
                        formControlName="color"
                        class="no-caret"
                        [id]="'color'"
                        label="{{ 'general.color' | translate }}"
                        placeholder="general.color"
                        [items]="colors"></mpc-select>
                </div>
            </div>
            <div class="col-6 mt-2" *ngIf="validSerialNumber">
                <div class="card my-1 pt-1 px-1">
                    <mpc-select
                        formControlName="returnCategory"
                        [id]="'returnCategory'"
                        label="{{ 'service.lensservice' | translate }}"
                        placeholder="service.lensservice"
                        [items]="returnCategories"></mpc-select>
                </div>
                <div class="card my-1 pt-1 px-1">
                    <mpc-select
                        formControlName="returnType"
                        [id]="'returnType'"
                        label="{{ 'service.type' | translate }}"
                        placeholder="service.type"
                        [items]="returnTypes"></mpc-select>
                </div>
                <div class="card my-1 pt-1 px-1">
                    <mpc-input-textarea
                        formControlName="description"
                        [id]="'description'"
                        label="{{ 'general.description' | translate }}">
                        placeholder="{{ 'general.description' | translate }}">
                    </mpc-input-textarea>
                </div>
            </div>
        </div>
    </div>
</div>
<div *footer>
    <div class="d-flex align-items-center">
        <a [routerLink]="['/logistics']" class="col-9">
            <i class="fas fa-arrow-alt-circle-left me-1"></i>
        </a>
        <div class="ms-auto">
            <button
                class="btn next ms-2"
                id="sendServiceBtn"
                (click)="sendServiceRequest()"
                [disabled]="!returnForm.valid">
                {{ 'service.send' | translate }}
            </button>
        </div>
    </div>
</div>
