import { Component, Input, ViewEncapsulation, Optional, Host, SkipSelf } from '@angular/core';
import { NumberFormatPipe } from '@app/shared/pipes/number.format.pipe';
import { TranslateService } from '@ngx-translate/core';
import { ListOption } from '@app/shared/models';
import { BaseControl } from '@app/shared/components/inputs/base-control';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';

@Component({
    selector: 'mpc-label',
    templateUrl: './label.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: LabelComponent,
            multi: true,
        },
    ],
})
export class LabelComponent extends BaseControl {
    _listoptions: ListOption[];

    @Input() fullheight: false;
    @Input() formatting: unknown;
    @Input() unit: string;

    displayValue = '';

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        public controlContainer: ControlContainer,
        public numberFormatter: NumberFormatPipe,
        public translateService: TranslateService,
    ) {
        super(controlContainer);
    }

    writeValue(value: BaseControl): void {
        this.value = value ?? '';

        this.setToZeroPointZero();

        let displayValue = this.value;

        if (this.formatting) {
            displayValue = this.numberFormatter.transform(this.value, this.formatting) + ' ';
        }

        this.displayValue = displayValue.toString();
    }
}
