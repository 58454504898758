import { Component, OnInit } from '@angular/core';
import { CreditService } from '@app/core/services/api/credit.service';
import { Observable } from 'rxjs';
import { CreditSampleStatistic } from '@app/shared/models/creditSampleStatistic';

@Component({
    templateUrl: './ps-returns.component.html',
})
export class PsReturnsComponent implements OnInit {
    statistics$: Observable<CreditSampleStatistic[]>;

    constructor(private readonly creditService: CreditService) {}

    ngOnInit() {
        this.statistics$ = this.creditService.getCreditCheckStatistics();
    }
}
