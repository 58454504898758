<div class="modal-header">
    <h4 class="modal-title m-auto">
        {{ 'fitlens.residualrefraction' | translate }}
    </h4>
</div>

<div class="modal-body">
    <div class="row mb-3">
        <div class="col-12">
            {{ 'fitlens.residualrefractionwarning' | translate }}
        </div>
    </div>

    <div class="row mb-2">
        <div class="restrefraction-warning-values col-6">
            <div class="row">
                <div class="col-12">
                    <strong>{{ 'general.od' | translate }}:</strong>
                </div>
            </div>
            <div id="residualRefraction-od" class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-5">{{ 'general.spherical' | translate }}:</div>
                        <div class="col-7">
                            <span class="spherical">
                                {{
                                    rightRestRefraction.ResidualRefractionSphere
                                        | numberFormat: '{"parameterType" : "POW"}'
                                }}
                            </span>
                            {{ 'units.dioptry' | translate }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5">{{ 'general.cilinder' | translate }}:</div>
                        <div class="col-7">
                            <span class="cylinder">
                                {{
                                    rightRestRefraction.ResidualRefractionCylinder
                                        | numberFormat: '{"parameterType" : "CYL"}'
                                }}
                            </span>
                            {{ 'units.dioptry' | translate }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5">{{ 'general.axis' | translate }}:</div>
                        <div class="col-7">
                            <span class="axis">
                                {{
                                    rightRestRefraction.ResidualRefractionAx | numberFormat: '{"parameterType" : "AX"}'
                                }}
                            </span>
                            {{ 'units.degree' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="restrefraction-warning-values col-6">
            <div class="row">
                <div class="col-12">
                    <strong>{{ 'general.os' | translate }}:</strong>
                </div>
            </div>
            <div id="residualRefraction-os" class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-5">{{ 'general.spherical' | translate }}:</div>
                        <div class="col-7">
                            <span class="spherical">
                                {{
                                    leftRestRefraction.ResidualRefractionSphere
                                        | numberFormat: '{"parameterType" : "POW"}'
                                }}
                            </span>
                            {{ 'units.dioptry' | translate }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5">{{ 'general.cilinder' | translate }}:</div>
                        <div class="col-7">
                            <span class="cylinder">
                                {{
                                    leftRestRefraction.ResidualRefractionCylinder
                                        | numberFormat: '{"parameterType" : "CYL"}'
                                }}
                            </span>
                            {{ 'units.dioptry' | translate }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5">{{ 'general.axis' | translate }}:</div>
                        <div class="col-7">
                            <span class="axis">
                                {{ leftRestRefraction.ResidualRefractionAx | numberFormat: '{"parameterType" : "AX"}' }}
                            </span>
                            {{ 'units.degree' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer accented-background">
    <span>{{ 'fitlens.residualrefractionquestion' | translate }}</span>
    <button class="btn next ms-auto" (click)="continue()">
        {{ 'general.ok' | translate }}
    </button>
    <button class="btn btn-secondary ms-2" (click)="back()">
        {{ 'general.cancel' | translate }}
    </button>
</div>
