<div class="loginWrapper flex-fill d-flex flex-grow-1">
    <div class="form ms-4 mt-5">
        <form class="mt-5" [formGroup]="formGroup" (ngSubmit)="login()">
            <div class="logo-visavy">
                <img src="assets/images/logo-visavy.svg" alt="Visavy" />
            </div>

            <div class="header">
                <h1>{{ 'login.header' | translate }}</h1>
            </div>
            <div class="description">
                {{ 'login.description' | translate }}
            </div>
            <div class="row" [ngClass]="{ 'has-error': f.submitted && !f.username.valid }">
                <div class="col-md-12">
                    <h2>{{ 'login.username' | translate }}</h2>
                    <input
                        type="text"
                        class="transparent-input"
                        id="username"
                        name="username"
                        formControlName="username" />
                </div>
            </div>
            <div class="row" [ngClass]="{ 'has-error': f.submitted && !f.password.valid }">
                <div class="col-md-12">
                    <h2>{{ 'login.password' | translate }}</h2>
                    <input
                        type="password"
                        class="transparent-input"
                        id="password"
                        name="password"
                        formControlName="password" />
                </div>
            </div>
            <div class="row loginError" *ngIf="loginError">
                <div class="col-md-12">
                    <label class="text-danger">{{ loginError }}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-7 col-sm-12">
                    <button id="login" [disabled]="formGroup.invalid" class="btn btn-primary btn-block">
                        {{ 'login.login' | translate }}
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-left text-sm-center">
                    <a [routerLink]="[]" id="forgotPasswordLink" class="nav-link" (click)="requestNewPassword()">
                        {{ 'login.forgotpassword' | translate }}
                    </a>
                    <a [routerLink]="['/region']" id="reselectRegionLink" class="nav-link" (click)="reselectRegion()">
                        {{ 'login.reselectregion' | translate }}
                    </a>
                </div>
            </div>
        </form>

        <div class="coopervision-logo-login">
            <img src="assets/images/coopervision-logo-horizontal.png" />
        </div>
    </div>
</div>
