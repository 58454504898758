import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { BaseValidator } from '@app/shared/validators/BaseValidator';
import { Country } from '@app/shared/enums';
import { ListOption } from '../models';

export class PostalCodeValidator extends BaseValidator {
    regExpNld = /^[1-9][0-9]{3}[ ]?([A-RT-Za-rt-z][A-Za-z]|[sS][BCbcE-Re-rT-Zt-z])$/;
    regExpBel = /^[0-9]{4}$/;
    regExpUsa = /^[0-9]{5}$/;

    countries: ListOption[];
    constructor(countries: ListOption[]) {
        super();
        this.countries = countries;
    }

    getCountryCodeById(countryId: number): string {
        const country = this.countries.find((c) => c.Id === countryId);

        if (country) {
            return country.Code;
        }
        return '';
    }

    bind(form: UntypedFormGroup): ValidationErrors {
        const postalCode = form.controls['postalCode'];
        const country = form.controls['country'];
        const countryId = parseInt(country.value, 10);
        switch (countryId) {
            case Country.NLD: {
                if (!this.regExpNld.test(postalCode.value)) {
                    return { postalCode: true };
                }
                break;
            }
            case Country.BEL: {
                if (!this.regExpBel.test(postalCode.value)) {
                    return { postalCode: true };
                }
                break;
            }
            case Country.USA: {
                if (!this.regExpUsa.test(postalCode.value)) {
                    return { postalCode: true };
                }
                break;
            }
            default: {
                return null;
            }
        }
        return null;
    }
}
