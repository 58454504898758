import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { LensFiltersComponent } from '@app/fitlens/components/lensfilters.component';
import { FitLensComponent } from '@app/fitlens/fitlens.component';
import { PreviousLensParamsComponent } from '@app/fitlens/components/previouslensparams.component';
import { LensSelectionComponent } from '@app/fitlens/components/lensselection.component';
import { DreamliteOptionsComponent } from '@app/fitlens/components/dreamlite-options/dreamliteoptions.component';
import { ResidualRefractionComponent } from '@app/fitlens/components/residualrefraction.component';
import { DreamliteClearanceComponent } from '@app/fitlens/components/dreamlite-clearance/dreamlite-clearance.component';
import { LensNotchComponent } from '@app/fitlens/components/lens-notch.component';
import { OrderTrainingDialogComponent } from '@app/shared/components/order-training-dialog/order-training-dialog.component';

@NgModule({
    imports: [CommonModule, SharedModule],
    declarations: [
        FitLensComponent,
        LensFiltersComponent,
        LensNotchComponent,
        PreviousLensParamsComponent,
        LensSelectionComponent,
        DreamliteOptionsComponent,
        ResidualRefractionComponent,
        DreamliteClearanceComponent,
        OrderTrainingDialogComponent,
    ],
    exports: [
        FitLensComponent,
        LensFiltersComponent,
        PreviousLensParamsComponent,
        LensSelectionComponent,
        DreamliteOptionsComponent,
        ResidualRefractionComponent,
        OrderTrainingDialogComponent,
    ],
    providers: [],
})
export class FitLensModule {}
