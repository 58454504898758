<div class="spinner-border spinner-border-sm" *ngIf="loading"></div>

<ng-container *ngIf="!loading">
    <div class="row">
        <div class="mb-3" [ngClass]="inputPosition === 'bottom' ? 'col-12' : 'mt-3 col-12 col-md-6 col-lg-9'">
            <div *ngFor="let note of notes" class="mb-2">
                <div class="me-2">
                    <strong>
                        {{ note.AuthorName }},
                        {{ note.IsCreatedOn | mpcdate }}
                        {{ note.IsCreatedOn | date: 'H:mm' }}
                    </strong>
                    <div class="notes-message">{{ note.Message }}</div>
                </div>
            </div>
        </div>
        <div [ngClass]="inputPosition === 'bottom' ? 'col-12' : 'col-12 col-md-6 col-lg-3'">
            <div *ngIf="showInputfield">
                <div class="pb-2 mb-2 title-border-bottom" *ngIf="inputPosition !== 'bottom'">
                    <h3 class="transform-normal">
                        {{ 'clientnotes.notes' | translate | uppercase }}
                        <span
                            class="badge bg-warning ms-1"
                            popover="{{ 'clientnotes.internalextra' | translate }}"
                            triggers="mouseenter:mouseleave">
                            {{ 'clientnotes.internal' | translate }}
                        </span>
                    </h3>
                </div>

                <div class="mb-2">
                    <input
                        class="form-control rounded"
                        type="text"
                        [(ngModel)]="controller"
                        maxlength="40"
                        [placeholder]="'general.controller' | translate" />
                </div>
                <div class="w-100 mb-2">
                    <textarea type="text" rows="6" [(ngModel)]="message" class="form-control rounded"></textarea>
                </div>
                <div class="w-100">
                    <button class="btn btn-success" [disabled]="!canSave" (click)="saveNote()">
                        {{ 'clientnotes.addnote' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
