import { Component, Input } from '@angular/core';
import { FitLensComponent } from '@app/fitlens/fitlens.component';
import { ResidualRefraction } from '../models/residualRefraction.model';

@Component({
    selector: 'residual-refraction',
    templateUrl: 'residualrefraction.component.html',
})
export class ResidualRefractionComponent {
    @Input() fitlens: FitLensComponent;

    get residualRefraction(): ResidualRefraction {
        const rr = new ResidualRefraction();
        rr.Sphere = this.fitlens.fittedLens.ResidualRefractionSphere;
        rr.Ax = this.fitlens.fittedLens.ResidualRefractionAx;
        rr.Cylinder = this.fitlens.fittedLens.ResidualRefractionCylinder;
        return rr;
    }

    get showResidualRefraction(): boolean {
        if (!this.fitlens.options.ShowResidualRefraction) {
            return false;
        }

        return (
            this.fitlens.fittedLens.ResidualRefractionSphere !== 0 ||
            this.fitlens.fittedLens.ResidualRefractionCylinder !== 0 ||
            this.fitlens.fittedLens.ResidualRefractionAx !== 0
        );
    }
}
