import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleAuthGuard } from '@app/core/guards/role.auth.guard';
import { Roles } from '@app/shared/enums';
import { PsComponent } from './ps.component';
import { PsHomeComponent } from './home/ps-home.component';
import { PsSupportOrderComponent } from './supportorder/ps-supportorder.component';
import { SupportorderOverviewComponent } from './supportorderoverview/supportorder-overview.component';
import { PsClientMaintenanceOverviewComponent } from './clientmaintenance/ps-clientmaintenance-overview.component';
import { PsClientMaintenanceComponent } from './clientmaintenance/ps-clientmaintenance.component';
import { ComplaintsOverviewComponent } from './complaintsoverview/complaintsoverview.component';
import { ComplaintComponent } from './complaint/complaint.component';
import { PsReturnsComponent } from './returns/ps-returns.component';
import { EyeliteProcessChangesGuard } from '@app/core/guards/eyelite-process-changes.guard';

const psRoutes: Routes = [
    {
        path: '',
        component: PsComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            {
                path: 'home',
                component: PsHomeComponent,
                canActivate: [RoleAuthGuard],
                data: {
                    roles: [Roles.Ps, Roles.DistributorSupport],
                },
            },
            {
                path: 'supportorderoverview',
                component: SupportorderOverviewComponent,
                canActivate: [RoleAuthGuard],
                data: {
                    roles: [Roles.Ps, Roles.DistributorSupport],
                },
            },
            {
                path: 'clientmaintenanceoverview',
                component: PsClientMaintenanceOverviewComponent,
                canActivate: [RoleAuthGuard],
                data: {
                    roles: [Roles.Ps, Roles.DistributorSupport],
                },
            },
            {
                path: 'complaintsoverview',
                component: ComplaintsOverviewComponent,
                canActivate: [RoleAuthGuard],
                data: {
                    roles: [Roles.Ps],
                },
            },
            {
                path: 'supportorder/:id',
                component: PsSupportOrderComponent,
                canActivate: [RoleAuthGuard],
                data: {
                    roles: [Roles.Ps, Roles.DistributorSupport],
                },
            },
            {
                path: 'clientmaintenance/:id',
                component: PsClientMaintenanceComponent,
                canActivate: [RoleAuthGuard],
                canDeactivate: [EyeliteProcessChangesGuard],
                data: {
                    roles: [Roles.Ps, Roles.DistributorSupport],
                },
            },
            {
                path: 'complaint/:id',
                component: ComplaintComponent,
                canActivate: [RoleAuthGuard],
                data: {
                    roles: [Roles.Ps],
                },
            },
            {
                path: 'returns',
                component: PsReturnsComponent,
                canActivate: [RoleAuthGuard],
                data: {
                    roles: [Roles.Ps],
                },
            },
            {
                path: 'returns/:id',
                component: PsClientMaintenanceComponent,
                canActivate: [RoleAuthGuard],
                data: {
                    roles: [Roles.Ps],
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(psRoutes)],
    exports: [RouterModule],
    providers: [],
})
export class PsRoutingModule {}
