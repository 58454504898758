import { TranslateLanguage } from '@app/shared/models/translateLanguage.model';

export class ProductGroupTranslation extends TranslateLanguage {
    public Id: number;
    public ProductGroupId: number;
    public DistributorId: number;
    public Description: string;
    public PictureUrl: string;
    public SearchField: string;
}
