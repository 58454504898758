import { Component, Input, OnInit } from '@angular/core';
import { Client, Order } from '@app/shared/models';
import { PsSupportOrderComponent } from '../ps-supportorder.component';

@Component({
    selector: 'ps-chats',
    templateUrl: './ps-chats.component.html',
    styleUrls: ['ps-chats.component.scss'],
})
export class PsChatsComponent implements OnInit {
    @Input() psSupportOrderComponent: PsSupportOrderComponent;
    @Input() id: string;

    client: Client;
    order: Order;

    ngOnInit(): void {
        this.client = this.psSupportOrderComponent.getClient();
        this.order = this.psSupportOrderComponent.getOrder();
    }
}
