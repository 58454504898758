import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ListOption } from '@app/shared/models';
import { ClientService } from '@app/core/services/api/client.service';
import { FindClientsRequest } from '@app/shared/models/findClientsRequest.model';
import { FindClientsResponse } from '@app/shared/models/findClientsResponse.model';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subscription, lastValueFrom, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PsClientImportComponent } from './components/ps-client-import/ps-client-import.component';
import { GenderService } from '@app/core/services/api/gender.service';

@Component({
    templateUrl: './ps-clientmaintenance-overview.component.html',
    styleUrls: ['ps-clientmaintenance-overview.component.scss'],
})
export class PsClientMaintenanceOverviewComponent implements OnInit, OnDestroy, AfterViewInit {
    loading = true;
    findClientsRequest: FindClientsRequest;
    findClientsResponse: FindClientsResponse[];
    delayTime = 500;
    filterClientsForm: UntypedFormGroup;
    filterClientsFormSubscription: Subscription;
    genders: ListOption[];

    searchfields = ['Reference', 'Reference2', 'BirthDate', 'Gender', 'OpticianName'];

    constructor(
        private clientService: ClientService,
        private readonly genderService: GenderService,
        private readonly fb: UntypedFormBuilder,
        private readonly modalService: BsModalService,
        public router: Router,
    ) {}

    ngOnInit(): void {
        this.createForm();

        this.filterClientsFormSubscription = this.filterClientsForm.valueChanges
            .pipe(debounce(() => timer(this.delayTime)))
            .subscribe(() => {
                if (this.checkIfDirty()) {
                    this.loadClients().then();
                }
            });
    }

    ngOnDestroy(): void {
        this.filterClientsFormSubscription.unsubscribe();
    }

    createForm(): void {
        this.filterClientsForm = this.fb.group({
            Reference: [],
            Reference2: [],
            BirthDate: [],
            Gender: [],
            OpticianName: [],
        });
    }

    ngAfterViewInit() {
        this.findClientsRequest = {
            Skip: 0,
            Take: 100,
        };

        this.loadGenders();
        this.loadClients().then();
    }

    async loadClients() {
        this.loading = true;
        this.addFiltersToFindClientsRequest(this.findClientsRequest);
        this.findClientsResponse = await lastValueFrom(this.clientService.find(this.findClientsRequest));
        this.loading = false;
    }

    addFiltersToFindClientsRequest(findClientsRequest: FindClientsRequest): void {
        for (const field of this.searchfields) {
            switch (field) {
                case 'Reference': {
                    findClientsRequest.Reference = this.filterClientsForm.controls[field].value;
                    break;
                }
                case 'Reference2': {
                    findClientsRequest.ClientReference = this.filterClientsForm.controls[field].value;
                    break;
                }
                case 'BirthDate': {
                    findClientsRequest.BirthDate = this.filterClientsForm.controls[field].value;
                    break;
                }
                case 'Gender': {
                    if (this.filterClientsForm.controls[field].value) {
                        findClientsRequest.GenderId = this.genderId(this.filterClientsForm.controls[field].value);
                    }
                    break;
                }
                case 'OpticianName': {
                    findClientsRequest.OpticianName = this.filterClientsForm.controls[field].value;
                    break;
                }
            }
        }
    }

    checkIfDirty(): boolean {
        if (this.filterClientsForm.dirty || this.filterClientsForm.dirty) {
            this.filterClientsForm.markAsPristine();
            this.filterClientsForm.markAsPristine();

            return true;
        }

        return false;
    }

    onSelectClient(client: FindClientsResponse): void {
        this.router.navigate(['ps/clientmaintenance/' + client.Id]).then();
    }

    loadGenders(): void {
        this.genderService.getGenders().subscribe((result: ListOption[]) => {
            this.genders = result;
        });
    }

    onFilterByField(): void {
        this.loadClients().then();
    }

    genderName(genderId: number): string {
        if (this.genders && genderId) {
            return this.genders.filter((g) => g.Id === genderId)[0].Name;
        }
        return '';
    }

    genderId(genderName: string): number {
        if (this.genders && genderName) {
            const gender = this.genders.filter((g) => g.Name.toLowerCase() === genderName.toLowerCase())[0];
            if (gender) {
                return gender.Id;
            }
        }
        return 0;
    }

    importClient() {
        this.modalService.show(PsClientImportComponent);
    }
}
