import { Injectable } from '@angular/core';
import { LocalStorageService } from '@app/shared/appservices/localstorage.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { REGION_AVAILABLE_LIST, REGION_DEFAULT_VALUE, REGION_STORAGE_KEY } from '@app/shared/constants/regions';

@Injectable({
    providedIn: 'root',
})
export class RegionService {
    private region = '';

    constructor(
        private localStorage: LocalStorageService,
        private appState: AppStateService,
    ) {}

    public readRegionFromStorage() {
        if (this.appState.isGuiTest) {
            this.region = REGION_DEFAULT_VALUE;
            return;
        }

        const storageRegion = this.localStorage.get(REGION_STORAGE_KEY);
        if (!!storageRegion) {
            this.region = storageRegion;
        }
    }

    public getRegion(): string {
        if (!this.region) {
            this.readRegionFromStorage();
        }

        return this.region;
    }

    public saveRegion(region: string): boolean {
        if (region && REGION_AVAILABLE_LIST.some((x) => x === region)) {
            this.region = region;
            this.localStorage.save(REGION_STORAGE_KEY, region);
            return true;
        }

        return false;
    }

    public regionChosen(): boolean {
        return this.region && REGION_AVAILABLE_LIST.some((x) => x === this.region);
    }
}
