export class RegisterOpticianRequest {
    OpticianName: string;
    City: string;
    OpticianAddress: string;
    OpticianEmail: string;
    OpticianPhone: string;
    VatNumber: string;
    PersonalName: string;
    UserName: string;
    UserEmail: string;
    ReceiptReference: string;
    ReceiptCode: string;
}
