import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Roles } from '@app/shared/enums';
import { LogisticComponent } from '@app/logistic/pages/home/logistic.component';
import { RoleAuthGuard } from '@app/core/guards/role.auth.guard';
import { LogisticOverviewComponent } from '@app/logistic/pages/overview/logistic-overview.component';
import { LogisticManualRequestComponent } from './pages/manualRequest/logistic-manual-request.component';
import { LogisticNewRequestDownloadFormComponent } from './pages/download-form/logistic-newrequest-download-form.component';

const routes: Routes = [
    {
        path: '',
        children: [
            { path: '', component: LogisticComponent },
            { path: 'overview', component: LogisticOverviewComponent },
            { path: 'manualrequest', component: LogisticManualRequestComponent },
            { path: 'newrequest/download/:id', component: LogisticNewRequestDownloadFormComponent },
        ],
        canActivate: [RoleAuthGuard],
        data: {
            roles: [Roles.Logistics],
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [],
})
export class LogisticRoutingModule {}
