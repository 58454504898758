<table class="ps-datatable mt-2">
    <thead>
        <tr>
            <th *ngFor="let field of requestData">
                {{ 'complaintsoverview.returnsummary.' + field | translate }}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let returnStatus of returnStatuses" class="table_entry">
            <td class="m-1">{{ returnStatus.ReturnStatusName }}</td>
            <td class="m-1">{{ returnStatus.IsCreatedOn }}</td>
            <td class="m-1">{{ returnStatus.IsCreatedByUserName }}</td>
        </tr>
    </tbody>
</table>
