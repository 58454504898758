import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { ReturnService } from '@app/core/services/api/return.service';
import { EyeSides, ServiceStatus, ServiceType } from '@app/shared/enums';
import { ReturnsPerRoleQueryCriteria } from '@app/shared/models/ReturnsPerRoleQueryCriteria';
import { ServiceRequest } from '@app/shared/models/service-request.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'complaints-overview-table',
    templateUrl: './complaints-overview-table.component.html',
})
export class ComplaintsOverviewTableComponent implements OnInit {
    public loading = false;
    public pageReturnCount: number;
    public totalReturnCount: number;
    public returnCategoryName = ServiceType;
    public eyeSide = EyeSides;
    public SelectedReturnNumber: string;
    public currentAscendingField = 'ReturnNumber';
    public currentIsAscending = false;
    public formGroup: UntypedFormGroup;
    public returns: ServiceRequest[];
    public returnQueryCriteria = new ReturnsPerRoleQueryCriteria();

    private returnSelected: ServiceRequest;
    private ascendingField$ = new BehaviorSubject<string>('ReturnNumber');
    private isAscending$ = new BehaviorSubject<boolean>(false);
    private returnStatusIds$ = new BehaviorSubject<ServiceStatus[]>([]);
    private creditCategory$ = new BehaviorSubject<boolean>(false);
    private pageIndex$ = new BehaviorSubject<number>(0);
    private readonly destroyRef = inject(DestroyRef);

    public headerFields = [
        'ReturnNumber',
        'InProgressDate',
        'SerialNumber',
        'OpticianName',
        'ClientReference',
        'ClientReference2',
        'EyeSideName',
        'ProductName',
        'ReturnCategoryName',
        'IsChangedByUserName',
    ];

    @Input()
    set returnStatusIds(value: ServiceStatus[]) {
        this.returnStatusIds$.next(value);
    }

    @Input()
    set creditCategory(value: boolean) {
        this.creditCategory$.next(value);
    }

    @Output() selectedReturn = new EventEmitter<ServiceRequest>();
    @Output() redirectToReturnSelected = new EventEmitter<boolean>();

    constructor(
        private readonly returnService: ReturnService,
        private readonly untypedFormBuilder: UntypedFormBuilder,
    ) {}

    public ngOnInit(): void {
        this.createForm();
        this.returnQueryCriteria.setDefault();
        this.setupReturnFetching();
        this.setupSortingSubscriptions();
    }

    private setupSortingSubscriptions(): void {
        this.ascendingField$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((field) => (this.currentAscendingField = field));
        this.isAscending$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((order) => (this.currentIsAscending = order));
    }

    private setupReturnFetching(): void {
        combineLatest([
            this.returnStatusIds$,
            this.creditCategory$,
            this.formGroup.valueChanges.pipe(startWith(this.formGroup.value), debounceTime(500)),
            this.pageIndex$.pipe(startWith(0)),
            this.ascendingField$,
            this.isAscending$,
        ])
            .pipe(
                switchMap(([statusIds, creditCategory, formValues, pageIndex, ascendingField, isAscending]) => {
                    this.loading = true;

                    const criteria = new ReturnsPerRoleQueryCriteria(this.returnQueryCriteria);
                    this.prepareFiltering(criteria, formValues);

                    criteria.returnStatusIds = statusIds;
                    criteria.creditCategory = creditCategory;
                    criteria.PageIndex = pageIndex;
                    criteria.OrderByAscending = isAscending;
                    criteria.OrderByField = ascendingField;

                    return this.returnService.getAllReturnsWithCriteria(criteria);
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe((queryResult) => {
                this.returns = queryResult.Items;
                this.pageReturnCount = this.returns.length;
                this.totalReturnCount = queryResult.TotalItems;
                this.loading = false;
            });
    }

    private createForm(): void {
        this.formGroup = this.untypedFormBuilder.group({
            ReturnNumber: [],
            InProgressDate: [],
            SerialNumber: [],
            OpticianName: [],
            ClientReference: [],
            ClientReference2: [],
            EyeSideName: [],
            ProductName: [],
            ReturnCategoryName: [],
            IsChangedByUserName: [],
            Vigilance: [],
        });
    }

    public selectReturn(serviceRequestReturn: ServiceRequest): void {
        this.returnSelected = this.returnSelected === serviceRequestReturn ? null : serviceRequestReturn;
        this.SelectedReturnNumber = this.returnSelected?.ReturnNumber || '';
        this.postSelectedReturn();
    }

    public doubleClick(serviceRequestReturn: ServiceRequest): void {
        this.returnSelected = serviceRequestReturn;
        this.SelectedReturnNumber = this.returnSelected.ReturnNumber;
        this.postSelectedReturn();
        this.postRedirectToReturnSelected();
    }

    public receivePagingParameters(newPageIndex: number): void {
        this.pageIndex$.next(newPageIndex);
    }

    private postSelectedReturn(): void {
        this.selectedReturn.emit(this.returnSelected);
    }

    private postRedirectToReturnSelected(): void {
        this.redirectToReturnSelected.emit(true);
    }

    private prepareFiltering(criteria: ReturnsPerRoleQueryCriteria, formValues: { [index: string]: string }): void {
        let filterChanged = false;
        for (const field of this.headerFields) {
            if (formValues[field] && formValues[field] !== '') {
                if (criteria.FilterByFields[field] !== formValues[field]) {
                    filterChanged = true;
                }
                criteria.FilterByFields[field] = formValues[field];
            } else {
                if (criteria.FilterByFields[field]) {
                    filterChanged = true;
                }
                delete criteria.FilterByFields[field];
            }
        }

        if (filterChanged) {
            this.pageIndex$.next(0);
        }
    }

    public onSortByField(field: string): void {
        if (this.ascendingField$.getValue() === field) {
            this.isAscending$.next(!this.isAscending$.getValue());
        } else {
            this.isAscending$.next(true);
        }
        this.ascendingField$.next(field);
    }
}
