<div class="modal-body">
    <div *ngIf="receipt" class="mb-4">
        <div class="row">
            <div *ngIf="receipt.Client" class="col-6">
                <div class="title-border-bottom mb-1">
                    <h4>{{ 'general.client' | translate }}</h4>
                </div>
                <client-details
                    [client]="receipt.Client"
                    [twoColumns]="true"
                    [showLabels]="true"
                    *ngIf="receipt.Client"></client-details>
            </div>
            <div
                [ngClass]="
                    receipt.Client.Id && receipt.GlassTreatmentId
                        ? 'col-3'
                        : !receipt.Client.Id && !receipt.GlassTreatmentId
                          ? 'col-12'
                          : 'col-6'
                ">
                <div class="title-border-bottom mb-1">
                    <h4>{{ 'general.receipt' | translate }}</h4>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-5 font-weight-bold">{{ 'general.createdon' | translate }}:</div>
                            <div class="col-7">
                                <span id="isCreatedOn">{{ receipt.IsCreatedOn | mpcdate }}</span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-5 font-weight-bold">{{ 'receipt.receiptstatus' | translate }}:</div>
                            <div class="col-7">
                                <span id="orderStatus">
                                    {{ getStatusTranslationKey() | translate }}
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 font-weight-bold">{{ 'receipt.reference' | translate }}:</div>
                            <div class="col-7">
                                <span id="isCreatedOn">{{ receipt.Reference }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 font-weight-bold">{{ 'receipt.code' | translate }}:</div>
                            <div class="col-7">
                                <span id="isCreatedOn">{{ receipt.Code }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="receipt.GlassTreatmentId" class="col-3">
                <div class="title-border-bottom mb-1">
                    <h4>{{ 'general.frame' | translate }}</h4>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.edgingService' | translate }}:</div>
                            <div class="col-6">
                                {{ getGlassTreatmentName(receipt.GlassTreatmentId) }}
                            </div>
                        </div>
                        <div *ngIf="hasEdgingServiceRemoteEdgingAndFitting()" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.frameBrand' | translate }}:</div>
                            <div class="col-6">{{ receipt.Frame.Brand }}</div>
                        </div>
                        <div *ngIf="hasEdgingServiceRemoteEdgingAndFitting()" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.frameModel' | translate }}:</div>
                            <div class="col-6">{{ receipt.Frame.Model }}</div>
                        </div>
                        <div *ngIf="hasEdgingServiceRemoteEdging()" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.frameType' | translate }}:</div>
                            <div class="col-6">{{ receipt.Frame.Material }}</div>
                        </div>
                        <div *ngIf="hasEdgingServiceRemoteEdgingAndFitting()" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.frameColor' | translate }}:</div>
                            <div class="col-6">{{ receipt.Frame.Color }}</div>
                        </div>
                        <div *ngIf="hasEdgingService()" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.frameSizeBoxA' | translate }}:</div>
                            <div class="col-6">{{ receipt.Frame.SizeBoxA }}</div>
                        </div>
                        <div *ngIf="hasEdgingService()" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.frameSizeBoxB' | translate }}:</div>
                            <div class="col-6">{{ receipt.Frame.SizeBoxB }}</div>
                        </div>
                        <div *ngIf="hasEdgingService()" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.frameDblDimension' | translate }}:</div>
                            <div class="col-6">
                                {{ receipt.Frame.DblDimension }}
                            </div>
                        </div>
                        <div *ngIf="hasEdgingService()" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.pupilDistanceLeft' | translate }}:</div>
                            <div class="col-6">
                                {{ receipt.Frame.PupilDistanceLeft }}
                            </div>
                        </div>
                        <div *ngIf="hasEdgingService()" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.pupilDistanceRight' | translate }}:</div>
                            <div class="col-6">
                                {{ receipt.Frame.PupilDistanceRight }}
                            </div>
                        </div>
                        <div *ngIf="hasEdgingService()" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.ocularCenterLeft' | translate }}:</div>
                            <div class="col-6">
                                {{ receipt.Frame.OcularCenterLeft }}
                            </div>
                        </div>
                        <div *ngIf="hasEdgingService()" class="row">
                            <div class="col-6 font-weight-bold">{{ 'fitglass.ocularCenterRight' | translate }}:</div>
                            <div class="col-6">
                                {{ receipt.Frame.OcularCenterRight }}
                            </div>
                        </div>
                        <div *ngIf="hasEdgingService()" class="row">
                            <div class="col-6 font-weight-bold">{{ 'general.edgePolish' | translate }}:</div>
                            <div class="col-6">
                                {{ receipt.Frame.EdgePolish ? 'Y' : 'N' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 p-0">
        <div class="title-border-bottom">
            <h4>{{ 'general.receipt' | translate }}</h4>
        </div>
        <div class="rounded-bottom p-1 accented-background">
            <receipt-details *ngIf="receipt" [receipt]="receipt" [hideOrderInfo]="true"></receipt-details>
        </div>
    </div>
</div>

<div class="modal-footer accented-background d-flex justify-content-between">
    <div *ngIf="receipt">
        <button type="button" id="downloadPdf" class="btn btn-primary" (click)="downloadPdf()">
            <i class="fa fa-file-pdf"></i>
            &nbsp; download PDF
        </button>
    </div>
    <div *ngIf="receipt && hasEdgingServiceRemoteEdgingAndFitting()">
        <button
            type="button"
            id="downloadFramePackingSlipPdf"
            class="btn btn-primary"
            (click)="downloadShipmentFramePdf()">
            <i class="fa fa-file-pdf"></i>
            &nbsp;{{ 'general.downloadFramePackingSlipPdf' | translate }}
        </button>
    </div>
    <button type="button" id="close" class="btn btn-default" (click)="bsModalRef.hide()">
        {{ 'general.close' | translate }}
    </button>
</div>
