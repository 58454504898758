<div class="w-100 p-4">
    <div class="col-12">
        <h2>{{'ps-supportorder-overview-upload' | translate}}</h2>
        <div class="d-flex flex-grow-1 p-2 fileupload-wrapper">
            <file-upload
                #fileUploader
                [showUploadButton]="true"
                [downloadAttachedFile]="true"
                (uploadButtonPressed)="uploadFiles($event)"
                (uploadResult)="uploadResult($event)"></file-upload>
        </div>
    </div>
</div>
