import { LensType } from './lensType.model';
import { ProposalType } from './proposaltype.model';
import { Client } from './client.model';
import { Order } from './order.model';
import { FittedLens } from './fitted-lens.model';
import { RefractionMeasurement } from './refractionmeasurement.model';
import { TopographicMeasurement } from './topographicMeasurement.model';
import { ProposalOptions } from './proposalOptions.model';
import { Frame } from './frame.model';
import { ProposalAnnualSupply } from './proposal-annual-supply.model';

export class Proposal {
    constructor() {
        this.ProposalDate = new Date(Date.now());
        this.Orders = [];
        this.IsReadOnly = false;
        this.OrderWithSupport = false;
    }

    public Id: number;
    public OpticianId: number;
    public ClientId: number;
    public UserId: number;
    public ProposalDate: Date;
    public LeftFixTypeId: number;
    public LeftFixTypeName: string;
    public RightFixTypeId: number;
    public RightFixTypeName: string;
    public LeftProposalLensDefinitionId: number;
    public RightProposalLensDefinitionId: number;
    public LensTypeId: number;
    public LeftTopographicMeasurementId: number;
    public RightTopographicMeasurementId: number;
    public LeftRefractionMeasurementId: number;
    public RightRefractionMeasurementId: number;
    public LeftOpticianFittedLensId: number;
    public RightOpticianFittedLensId: number;
    public LeftPreCalculatedFittedLensId: number;
    public RightPreCalculatedFittedLensId: number;
    public LeftOriginalFittedLensId: number;
    public RightOriginalFittedLensId: number;
    public LeftPreviousOrderId: number;
    public RightPreviousOrderId: number;
    public ProposalTypeId: number;
    public ProposalType: ProposalType;
    public LensType: LensType;
    public Orders: Order[];
    public Client: Client;
    public LeftOpticianFittedLens: FittedLens;
    public LeftPreCalculatedFittedLens: FittedLens;
    public LeftOriginalFittedLens: FittedLens;
    public RightOpticianFittedLens: FittedLens;
    public RightPreCalculatedFittedLens: FittedLens;
    public RightOriginalFittedLens: FittedLens;
    public LeftRefractionMeasurement: RefractionMeasurement;
    public RightRefractionMeasurement: RefractionMeasurement;
    public LeftTopographicMeasurement: TopographicMeasurement;
    public RightTopographicMeasurement: TopographicMeasurement;
    public Frame: Frame;
    public GlassTreatmentId: number;

    public LeftProposalOptions: ProposalOptions;
    public RightProposalOptions: ProposalOptions;

    public IsReadOnly: boolean;
    public OrderWithSupport: boolean;
    public Step: number;

    public RightLensWarranty: boolean;
    public LeftLensWarranty: boolean;

    public RightAnnualSupply: ProposalAnnualSupply;
    public LeftAnnualSupply: ProposalAnnualSupply;

    public WarrantyExchange: boolean;
}
