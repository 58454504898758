<div *ngIf="loadingPost" class="spinner-border spinner-border-sm m-auto"></div>
<div *ngIf="post">
    <div class="modal-header p-3">
        <h4 class="modal-title m-auto">{{ post.title }}</h4>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 post-content" [innerHTML]="post.content"></div>
        </div>
    </div>
</div>
