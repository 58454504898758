import { Note } from './note.model';

export class Client {
    Id: number;
    OpticianId: number;
    BirthDateDay: number;
    BirthDateMonth: number;
    BirthDateYear: number;
    GenderId: number;
    Reference: string;
    Reference2: string;
    LeftBaseLineTopographicMeasurementId: number;
    RightBaseLineTopographicMeasurementId: number;
    Notes: Note[];

    BirthDate: Date;
    ErpReference: string;
    Street: string;
    Housenumber: string;
    Phonenumber: string;
    Myopie: boolean;
}
