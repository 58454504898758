import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MaterialColor, ListSelectOption } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class MaterialsService {
    private readonly basePath = 'api/materials';

    constructor(private readonly httpClient: HttpClient) {}

    public getMaterialColorsByProductGroupId(productGroupId: number): Observable<MaterialColor[]> {
        const options = {
            params: new HttpParams().set('productGroupId', productGroupId),
        };

        return this.httpClient.get<MaterialColor[]>(`${this.basePath}/colors/options`, options);
    }

    public getMaterials(): Observable<ListSelectOption[]> {
        return this.httpClient.get<ListSelectOption[]>(`${this.basePath}/default/options`);
    }
}
