import { Component, Input, ViewEncapsulation, Optional, Host, SkipSelf } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseControl } from '@app/shared/components/inputs/base-control';

@Component({
    selector: 'mpc-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputTextComponent,
            multi: true,
        },
    ],
})
export class InputTextComponent extends BaseControl {
    public _inputType = 'text';
    public currentInputType = 'text';

    @Input() useAutoFill = false;
    @Input() minLength = 0;
    @Input() maxLength = 999;
    @Input() placeholder: string;
    @Input() prefix: string;
    @Input() suffix: string;
    @Input() leadingZeroes: false;

    @Input('inputType')
    set inputType(value: string) {
        this._inputType = value;
        this.currentInputType = value;
    }

    autocomplete: string = null;
    iconClass: string;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        public controlContainer: ControlContainer,
    ) {
        super(controlContainer);
    }

    toggleShowPasswords() {
        this.currentInputType = this.currentInputType === 'text' ? 'password' : 'text';
    }

    writeValue(value: string): void {
        this.value = value || '';
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    propagateChange(event: any): void {
        this.onChange(event.currentTarget.value);
        this.onTouched();
    }

    refreshSubClasses(): void {
        this.iconClass = this.getIconClass();
        this.autocomplete = this.getAutocomplete();
    }

    getIconClass(): string {
        return this.currentInputType === 'text' ? 'active' : '';
    }

    getAutocomplete(): string {
        if (this.currentInputType === 'password') {
            return 'new-password';
        }

        return this.useAutoFill ? '' : 'no';
    }
}
