<div class="btn-group" role="group">
    <a
        id="{{ Id }}"
        class="btn btn-sm btn-transparent productdatasheetlink"
        *ngIf="productDataSheets && productDataSheets.length === 1"
        target="_blank"
        href="{{ productDataSheets[0].FilePath }}">
        <div *ngIf="shopService.isLensProduct">
            <i class="fas fa-file-alt"></i>
            &nbsp;{{ 'fitlens.lensinformation' | translate }}
        </div>
        <div *ngIf="!shopService.isLensProduct">
            <i class="fas fa-file-alt"></i>
            &nbsp;{{ 'general.info' | translate }}
        </div>
    </a>

    <div class="dropdown" *ngIf="productDataSheets && productDataSheets.length > 1">
        <a
            class="btn btn-sm btn-transparent dropdown-toggle productdatasheetlink"
            href="#"
            role="button"
            id="{{ Id }}-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <div *ngIf="shopService.isLensProduct">
                <i class="fas fa-file-alt"></i>
                &nbsp;{{ 'fitlens.lensinformation' | translate }}
            </div>
            <div *ngIf="!shopService.isLensProduct">
                <i class="fas fa-file-alt"></i>
                &nbsp;{{ 'general.info' | translate }}
            </div>
        </a>

        <div class="dropdown-menu">
            <a
                class="dropdown-item small"
                *ngFor="let dataSheet of productDataSheets"
                target="_blank"
                href="{{ dataSheet.FilePath }}">
                {{ dataSheet.Name }}
            </a>
        </div>
    </div>
</div>
