import { OnInit, Input, Directive } from '@angular/core';
import {
    Chart,
    ChartOptions,
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    Title,
    CategoryScale,
    BarController,
    BarElement,
    Legend,
} from 'chart.js';

@Directive()
export abstract class BaseChartComponent implements OnInit {
    private static readonly backgroundColorDark = '#f1f1f1';
    private static readonly backgroundColorLight = '#333333';

    private static chartIdCounter = 0;
    chartId: number;

    @Input() title: string;

    labels = [];
    chart: Chart;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    chartOptions: ChartOptions<any>;

    protected constructor() {
        Chart.register(
            LineController,
            BarController,
            BarElement,
            LineElement,
            PointElement,
            LinearScale,
            Title,
            CategoryScale,
            Legend,
        );
    }

    public ngOnInit() {
        this.chartId = BaseChartComponent.chartIdCounter++;
    }

    public initiateChart(): void {
        this.chart = new Chart('mpc-chart-' + this.chartId, this.chartOptions);
    }

    public reRenderChart(): void {
        this.chart.destroy();
        this.initiateChart();
    }

    public update(): void {
        this.chart.render();
    }

    public abstract addDataPoint(datasetIndex: number, value: number): void;
    public abstract addLabel(value: string): void;

    public abstract setColors(): void;
    public abstract setDataSet(dataset: unknown): void;

    public setColorScheme(currentTheme: string): void {
        if (this.chart) {
            this.chart['canvas']['style']['padding'] = '25px';
            this.chart['canvas']['style']['borderRadius'] = '5px';
            this.chart['canvas']['style']['backgroundColor'] =
                'dark' === currentTheme
                    ? BaseChartComponent.backgroundColorDark
                    : BaseChartComponent.backgroundColorLight;
            this.chart.update();
        } else {
            console.error('First call initiateChart and then SetColorScheme');
        }
    }
}
