import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Company } from '@app/shared/models/company.model';
import { ListSelectOption } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class CompanyService {
    private readonly basePath = 'api/companies';

    constructor(public httpClient: HttpClient) {}

    public getById(companyId: number): Observable<Company> {
        return this.httpClient.get<Company>(`${this.basePath}/${companyId}`);
    }

    public getCompanies(): Observable<ListSelectOption[]> {
        return this.httpClient.get<ListSelectOption[]>(`${this.basePath}/options`);
    }

    public save(company: Company): Observable<Company> {
        return this.httpClient.post<Company>(this.basePath, company);
    }

    public remove(companyId: number): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${this.basePath}/${companyId}`);
    }
}
