import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ListSelectOption } from '@app/shared/models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ColorService {
    private readonly basePath = 'api/colors';

    constructor(private readonly httpClient: HttpClient) {}

    public getColors(): Observable<ListSelectOption[]> {
        return this.httpClient.get<ListSelectOption[]>(`${this.basePath}/options`);
    }
}
