import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { Util } from '@app/shared/helpers/utility.helper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FileDownloadService {
    constructor(
        private readonly httpClient: HttpClient,
        public loaderService: LoaderService,
    ) {}

    public downloadFile(url: string, options?: object, fileName?: string): Observable<File> {
        return this.httpClient
            .get(url, {
                observe: 'response',
                responseType: 'blob',
                ...options,
            })
            .pipe(
                map((response) => {
                    const blob = response.body;
                    const name =
                        response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0] ?? fileName;

                    return new File([blob], name);
                }),
            );
    }

    async downloadFileWithBody(url: string, body: unknown, culture?: string): Promise<void> {
        const headers = {};

        if (culture != null) {
            headers['Client-Culture'] = culture;
        }

        this.httpClient
            .post(url, body, { observe: 'response', responseType: 'blob', headers: headers })
            .subscribe((response) => {
                const blob = response.body;
                const fileName: string = response.headers
                    .get('Content-Disposition')
                    .split('filename=')[1]
                    .split(';')[0];

                Util.openBlobInBrowser(blob, fileName);
            });
    }
}
