<div class="container-fluid mt-3 mb-4 px-lg-4 flex-fill">
    <div class="row" *ngIf="showOverview">
        <div class="col-2 mt-3">
            <h3>{{ 'complaintsoverview.complaints.in' | translate }}</h3>
            <div
                class="servicerequestbtn mb-2 Received accented-background"
                (click)="onClickType(returnStatuses.Received)"
                [ngClass]="checkIfSelected(returnStatuses.Received)">
                {{ 'ps.complaintsoverview.lensservicerequest.Received' | translate }}
            </div>
            <div
                id="inProgress"
                class="servicerequestbtn mb-2 QaHandling accented-background"
                (click)="onClickType(returnStatuses.QaHandling)"
                [ngClass]="checkIfSelected(returnStatuses.QaHandling)">
                {{ 'ps.complaintsoverview.lensservicerequest.qaHandling' | translate }}
            </div>
            <div class="mt-4 mb-3">
                <h3>{{ 'complaintsoverview.complaints.uit' | translate }}</h3>
            </div>
            <div
                class="servicerequestbtn mb-2 PsHandling accented-background"
                (click)="onClickType(returnStatuses.PsHandling)"
                [ngClass]="checkIfSelected(returnStatuses.PsHandling)">
                {{ 'ps.complaintsoverview.lensservicerequest.psHandling' | translate }}
            </div>
        </div>
        <div class="col-10">
            <complaints-overview-table
                [returnStatusIds]="returnStatusIds"
                (selectedReturn)="receiveReturnParameters($event)"
                (redirectToReturnSelected)="receiveReturnRedirect($event)"></complaints-overview-table>
        </div>
    </div>
    <div class="row" *ngIf="!showOverview">
        <qa-process-complaint [selectedReturn]="returnSelected"></qa-process-complaint>
    </div>
</div>

<div *footer>
    <div class="d-flex align-items-center">
        <a [routerLink]="['/qualityassurance']" class="col-9">
            <i class="fas fa-arrow-alt-circle-left me-1"></i>
            {{ 'general.home' | translate }}
        </a>
        <div class="col-3">
            <app-return-history
                [selectedReturn]="returnSelected"
                [redirectToSelectedReturn]="redirectReturnSelected"
                (returnscan)="receiveScanCall($event)"></app-return-history>
        </div>
    </div>
</div>
