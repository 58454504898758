<password-expiry></password-expiry>

<div class="container home-hero">
    <div [formGroup]="formGroup" class="home-hero__search">
        <input type="text" formControlName="searchTerm" placeholder="{{ 'home.search' | translate }}" maxlength="50" />
        <button>
            <i class="fas fa-search"></i>
        </button>
    </div>

    <a *ngIf="showAcademy" class="home-hero__academy" target="_blank" href="https://www.coopervisionsec.nl/academy">
        <img src="assets/images/coopervision-academy.png" alt="CooperVision Academy" />
    </a>
</div>

<div class="container-fluid cards home-nav">
    <div class="row">
        <div class="col-lg-10 offset-lg-1 px-0">
            <div class="row d-flex justify-content-center">
                <div class="col-3" *ngIf="appState.currentOptician.IsApproved">
                    <div id="clients" class="card" (click)="gotoSearchClient()">
                        <div class="card-body d-flex flex-column align-items-center justify-content-center">
                            <div class="icon">
                                <i class="fas fa-users fa-5x"></i>
                                <div
                                    *ngIf="replacementNotificationCount > 0"
                                    class="position-absolute replacementCount">
                                    <span
                                        class="badge rounded-pill rounded-pill bg-danger"
                                        placement="top"
                                        container="body"
                                        popover="{{
                                            'landing.replacementInfo'
                                                | translate
                                                    : {
                                                          count: replacementNotificationCount
                                                      }
                                        }}"
                                        triggers="mouseenter:mouseleave">
                                        {{ replacementNotificationCount }}
                                    </span>
                                </div>
                            </div>
                            <div class="title">
                                {{ 'landing.customers' | translate }}
                            </div>
                            <div class="info">
                                {{ 'landing.measureandcheck' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3" *ngIf="webshopTileEnabled">
                    <div id="webshop" class="card" (click)="gotoShop()">
                        <div class="card-body d-flex flex-column align-items-center justify-content-center">
                            <div class="icon">
                                <i class="fas fa-shopping-bag fa-5x"></i>
                            </div>
                            <div class="title">
                                {{ 'general.webshop' | translate }}
                            </div>
                            <div class="info">
                                {{ 'landing.stockandpromotion' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-3"
                    *hasFeatureSetting="[
                        {
                            Feature: Features.IsReceiptsOnlyEnabled,
                            Category: FeatureCategories.Optician,
                            IsEnabled: false
                        }
                    ]">
                    <div
                        *ngIf="appState.currentOptician.IsApproved"
                        id="orderStatus"
                        class="card"
                        (click)="gotoOrderStatus()">
                        <div class="card-body d-flex flex-column align-items-center justify-content-center">
                            <div class="icon">
                                <i class="fas fa-clipboard-check fa-5x"></i>
                                <div *ngIf="supportOrderAttentionCount > 0" class="position-absolute supportOrderCount">
                                    <span class="badge rounded-pill bg-danger">{{ supportOrderAttentionCount }}</span>
                                </div>
                            </div>
                            <div class="title">
                                {{ 'landing.orderstatus' | translate }}
                            </div>
                            <div class="info">
                                {{ 'landing.overviewandcommuncation' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-3"
                    *hasFeatureSetting="[
                        {
                            Feature: Features.IsReceiptsOnlyEnabled,
                            Category: FeatureCategories.Optician,
                            IsEnabled: true
                        }
                    ]">
                    <div id="receiptStatus" class="card" (click)="gotoReceiptStatus()">
                        <div class="card-body d-flex flex-column align-items-center justify-content-center">
                            <div class="icon">
                                <i class="fas fa-clipboard-check fa-5x"></i>
                            </div>
                            <div class="title">Receipt status</div>
                            <div class="info">See status</div>
                        </div>
                    </div>
                </div>

                <div
                    class="col-3"
                    *hasFeatureSetting="[
                        {
                            Feature: Features.IsOrderWithReceiptEnabled,
                            Category: FeatureCategories.Optician,
                            IsEnabled: true
                        }
                    ]">
                    <div id="orderReceipts" class="card" (click)="gotoReceipts()">
                        <div class="card-body d-flex flex-column align-items-center justify-content-center">
                            <div class="icon">
                                <i class="fas fa-receipt fa-5x"></i>
                            </div>
                            <div class="title">Receipts</div>
                            <div class="info">Order lenses with a code</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid content" *ngIf="!appState.isGuiTest">
        <div class="row">
            <div class="col-lg-10 offset-lg-1">
                <div class="row" *ngIf="!searchItems">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-12 mb-2">
                                <div class="title-border-bottom">
                                    <h1>{{ 'landing.calendar' | translate }}</h1>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="loadingCalendarItems" class="spinner-border spinner-border-sm m-auto"></div>

                        <ng-container *ngIf="!loadingCalendarItems && calendarItems && calendarItems.length > 0">
                            <div class="row" *ngFor="let calendarItem of calendarItems.slice(0, 3)">
                                <div class="col-2 col-lg-1">
                                    <i class="fas fa-calendar-alt fa-2x"></i>
                                </div>
                                <div class="col-10 col-lg-11">
                                    <strong (click)="showReadMore($event, calendarItem.id)" class="item-title">
                                        {{ calendarItem.title }}
                                    </strong>
                                </div>

                                <ng-container
                                    *ngIf="
                                        calendarItem.excerpt.includes('hellip') ||
                                            calendarItem.content.includes('<!--more-->');
                                        else calenderContentItem
                                    ">
                                    <div
                                        *ngIf="calendarItem.content.includes('<!--more-->')"
                                        class="col-10 col-lg-11 offset-2 offset-lg-1 description"
                                        [innerHTML]="calendarItem.content | split: '<!--more-->'"></div>
                                    <div
                                        *ngIf="!calendarItem.content.includes('<!--more-->')"
                                        class="col-10 col-lg-11 offset-2 offset-lg-1 description"
                                        [innerHTML]="calendarItem.excerpt"></div>

                                    <div class="col-10 col-lg-11 offset-2 offset-lg-1 continueReading">
                                        <a href="#" (click)="showReadMore($event, calendarItem.id)">
                                            {{ 'general.continuereading' | translate }}
                                        </a>
                                    </div>
                                </ng-container>

                                <ng-template #calenderContentItem>
                                    <div
                                        class="col-10 col-lg-11 offset-2 offset-lg-1 description"
                                        [innerHTML]="calendarItem.content"></div>
                                </ng-template>

                                <div class="col-12">
                                    <hr />
                                </div>
                            </div>
                        </ng-container>

                        <div *ngIf="!loadingCalendarItems && (!calendarItems || calendarItems.length === 0)">
                            {{ 'general.noresults' | translate }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-12 mb-2">
                                <div class="title-border-bottom">
                                    <h1>{{ 'landing.news' | translate }}</h1>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="loadingNewsItems" class="spinner-border spinner-border-sm m-auto"></div>

                        <ng-container *ngIf="!loadingNewsItems && newsItems && newsItems.length > 0">
                            <div class="row" *ngFor="let newsItem of newsItems.slice(0, 3)">
                                <div class="col-2 col-lg-1">
                                    <i class="fas fa-bullhorn fa-2x"></i>
                                </div>
                                <div class="col-10 col-lg-11">
                                    <strong (click)="showReadMore($event, newsItem.id)" class="item-title">
                                        {{ newsItem.title }}
                                    </strong>
                                </div>

                                <ng-container
                                    *ngIf="
                                        newsItem.excerpt.includes('hellip') || newsItem.content.includes('<!--more-->');
                                        else newsContentItem
                                    ">
                                    <div
                                        *ngIf="newsItem.content.includes('<!--more-->')"
                                        class="col-10 col-lg-11 offset-2 offset-lg-1 description"
                                        [innerHTML]="newsItem.content | split: '<!--more-->'"></div>
                                    <div
                                        *ngIf="!newsItem.content.includes('<!--more-->')"
                                        class="col-10 col-lg-11 offset-2 offset-lg-1 description"
                                        [innerHTML]="newsItem.excerpt"></div>

                                    <div class="col-10 col-lg-11 offset-2 offset-lg-1 continueReading">
                                        <a href="#" (click)="showReadMore($event, newsItem.id)">
                                            {{ 'general.continuereading' | translate }}
                                        </a>
                                    </div>
                                </ng-container>

                                <ng-template #newsContentItem>
                                    <div
                                        class="col-10 col-lg-11 offset-2 offset-lg-1 description"
                                        [innerHTML]="newsItem.content"></div>
                                </ng-template>

                                <div class="col-12">
                                    <hr />
                                </div>
                            </div>
                        </ng-container>

                        <div *ngIf="!loadingNewsItems && (!newsItems || newsItems.length === 0)">
                            {{ 'general.noresults' | translate }}
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="searchItems">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-12 mb-2">
                                <div class="title-border-bottom">
                                    <h1>
                                        {{ 'landing.searchresults' | translate }}
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="loadingSearchItems" class="spinner-border spinner-border-sm m-auto"></div>

                        <div class="row" *ngFor="let searchItem of searchItems">
                            <div class="col-2 col-lg-1">
                                <i class="fas fa-file-alt fa-2x"></i>
                            </div>
                            <div class="col-10 col-lg-11">
                                <strong>{{ searchItem.Name }}</strong>
                            </div>
                            <div class="col-10 col-lg-11 offset-2 offset-lg-1 continueReading">
                                <a href="{{ searchItem.Description }}" target="_blank">
                                    {{ 'general.continuereading' | translate }}
                                </a>
                            </div>
                            <div class="col-12">
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *footer class="d-flex gap-4 align-items-center">
    <a *ngIf="termsAndConditionsData" href="{{ termsAndConditionsData.FilePath }}" target="_blank">
        {{ 'home.termsandconditions' | translate }}
    </a>
    <a *ngIf="privacyPolicyData" href="{{ privacyPolicyData.FilePath }}" target="_blank">
        {{ 'home.privacypolicy' | translate }}
    </a>
    <a href="#" (click)="showReleaseNotes($event)">{{ 'home.releasenotes' | translate }}</a>
    <a *ngIf="faqUrl" href="{{ faqUrl }}" target="_blank">
        {{ 'home.faq' | translate }}
    </a>
    <a href="{{ contactUrl }}" target="_blank">
        {{ 'home.contactinformation' | translate }}
    </a>
    <div class="logos-container">
        <div class="logo-distributor" *ngIf="appState.currentDistributor.BrandingImageUrl">
            <img [src]="this.appState.currentDistributor.BrandingImageUrl" alt="Distributor" />
        </div>

        <div class="logo-coopervision">
            <img src="assets/images/coopervision-logo-horizontal.png" alt="CooperVision" />
        </div>
    </div>
</div>
