import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticatedUser } from '@app/shared/models';
import { Roles } from '@app/shared/enums';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from '@app/core/services/api/authentication.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { AlertService } from '@app/shared/appservices/alert.service';
import { LoaderService } from '@app/shared/appservices/loader.service';

@Injectable()
export class RoleAuthGuard {
    get CurrentAuthenticatedUser(): AuthenticatedUser {
        return this.appState.authenticatedUser;
    }

    constructor(
        public router: Router,
        public appState: AppStateService,
        public alertService: AlertService,
        public translateService: TranslateService,
        public authenticationService: AuthenticationService,
        public loaderService: LoaderService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        if (this.appState.authenticatedUser && this.appState.authenticatedUser.Roles) {
            return of(this.checkRole(route));
        }

        if (this.appState.authenticatedUser && this.appState.authenticatedUser.AccessToken) {
            this.loaderService.show();

            return this.authenticationService.validateLogin().pipe(
                switchMap((result: boolean) => {
                    this.loaderService.hide();

                    if (result) {
                        return of(this.checkRole(route));
                    } else {
                        this.redirectToLogin();
                        return of(false);
                    }
                }),
            );
        }

        this.redirectToLogin();
        return of(false);
    }

    private checkRole(route: ActivatedRouteSnapshot): boolean {
        let result = false;

        if (this.appState.authenticatedUser.Roles) {
            if (route.data['roles']) {
                // ok if user has any of the matching roles
                const roles = route.data['roles'] as Roles[];
                const roleIds = roles.map((role) => ({ Id: role.valueOf() }));
                result =
                    roleIds.filter((roleId) =>
                        this.appState.authenticatedUser.Roles.some((userRole) => userRole.Id === roleId.Id),
                    ).length > 0;
            } else {
                // ok if no roles were specified and user has at least one role
                result = true;
            }
        }

        if (!result) {
            this.redirectToLogin();
        }

        return result;
    }

    private redirectToLogin(): void {
        this.router.navigate(['login']);
    }
}
