<div class="container-fluid pt-4 px-lg-4 flex-fill" [formGroup]="formGroup">
    <div class="row">
        <!-- Refraction data -->
        <div class="col-12 col-md-8 col-xl-9 mb-3">
            <div class="row">
                <!-- Page title -->
                <div class="mb-2 col-12">
                    <h3 *ngIf="!isFollowUp" class="mt-auto">
                        {{ 'general.refractiondata' | translate }}
                    </h3>
                    <h3 *ngIf="isFollowUp" class="mt-auto">
                        {{ 'general.followup' | translate }}
                    </h3>
                </div>

                <!-- Mobile -->
                <ng-container *ngIf="appState.isMobile || appState.isTablet">
                    <tabset [justified]="true" class="col-12">
                        <tab id="tab-od" *ngIf="canSelectRightEye">
                            <ng-template tabHeading>
                                <div class="d-flex align-items-center">
                                    <div>
                                        <h4>{{ 'general.od' | translate }}</h4>
                                    </div>
                                    <div class="ms-auto hidewheninactive modify">
                                        <div class="form-check" (click)="toggleRightEye()">
                                            <input
                                                type="checkbox"
                                                [checked]="refractionRightEyeSelected"
                                                name="modify-od"
                                                class="form-check-input" />
                                            <label for="modify-od" class="form-check-label">
                                                {{ 'general.modify' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                            <ng-container *ngIf="refractionRightEyeSelected">
                                <ng-container *ngTemplateOutlet="refractionsOD"></ng-container>
                            </ng-container>
                        </tab>

                        <tab id="tab-os" *ngIf="canSelectLeftEye">
                            <ng-template tabHeading>
                                <div class="d-flex align-items-center">
                                    <div>
                                        <h4>{{ 'general.os' | translate }}</h4>
                                    </div>
                                    <div class="ms-auto hidewheninactive modify">
                                        <div class="form-check" (click)="toggleLeftEye()">
                                            <input
                                                type="checkbox"
                                                [checked]="refractionLeftEyeSelected"
                                                name="modify-os"
                                                class="form-check-input" />
                                            <label for="modify-os" class="form-check-label">
                                                {{ 'general.modify' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                            <ng-container *ngIf="refractionLeftEyeSelected">
                                <ng-container *ngTemplateOutlet="refractionsOS"></ng-container>
                            </ng-container>
                        </tab>
                    </tabset>
                </ng-container>

                <!-- Desktop -->
                <ng-container *ngIf="!appState.isMobile && !appState.isTablet">
                    <div class="col-6 pe-2">
                        <div *ngIf="canSelectRightEye">
                            <div class="pt-2 title-border-top d-flex align-items-center mb-2">
                                <div>
                                    <h4>{{ 'general.od' | translate }}</h4>
                                </div>
                                <div class="ms-auto">
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            formControlName="modify-od"
                                            id="modify-od"
                                            name="modify-od"
                                            class="form-check-input" />
                                        <label id="labelfor-modify-od" for="modify-od" class="form-check-label">
                                            {{ 'general.modify' | translate }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="refractionRightEyeSelected">
                                <ng-container *ngTemplateOutlet="refractionsOD"></ng-container>
                            </ng-container>
                        </div>
                    </div>

                    <div class="col-6 ps-2">
                        <div *ngIf="canSelectLeftEye">
                            <div class="pt-2 title-border-top d-flex align-items-center mb-2">
                                <div>
                                    <h4>{{ 'general.os' | translate }}</h4>
                                </div>
                                <div class="ms-auto">
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            formControlName="modify-os"
                                            id="modify-os"
                                            name="modify-os"
                                            class="form-check-input" />
                                        <label id="labelfor-modify-os" for="modify-os" class="form-check-label">
                                            {{ 'general.modify' | translate }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="refractionLeftEyeSelected">
                                <ng-container *ngTemplateOutlet="refractionsOS"></ng-container>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- Notes -->
        <div class="col-12 col-md-4 col-xl-3 mb-3">
            <div class="row">
                <div class="col-12">
                    <div class="pb-2 mb-2 title-border-bottom">
                        <h3 class="transform-normal">
                            {{ 'clientnotes.notes' | translate | uppercase }}
                            <span
                                class="badge bg-warning ms-1"
                                popover="{{ 'clientnotes.internalextra' | translate }}"
                                triggers="mouseenter:mouseleave">
                                {{ 'clientnotes.internal' | translate }}
                            </span>
                        </h3>
                    </div>
                    <client-notes [client]="client" [showInputfield]="true" #clientNotes></client-notes>
                </div>
            </div>
        </div>
    </div>

    <!-- Message -->
    <div class="row mb-4" *ngIf="isRegularFollowUp">
        <div class="offset-3 col-6 p-3 mb-4 rounded accented-background2 regularFollowUpMessage">
            <i class="fas fa-info-circle"></i>
            {{ 'general.refraction.overrefractiontext' | translate }}
        </div>
    </div>

    <ng-template #refractionsOD>
        <div *ngIf="proposal && refractionRightEyeSelected" class="currentmeasurement rounded">
            <refraction-details
                #refractionDetailsOd
                id="refraction-od"
                [eyeSide]="eyesides.Od"
                [hasMyopie]="hasMyopie()"
                [measurement]="proposal.RightRefractionMeasurement"
                [previous]="rightPrevious"
                [showStabilisation]="isRegularFollowUp"
                [showPrevious]="showPreviousMeasurement"></refraction-details>

            <div *ngIf="proposal.RightTopographicMeasurement" class="row m-0">
                <div class="p-3" [ngClass]="showPreviousMeasurement ? 'col-7 col-lg-8' : 'col-12'">
                    <h4 *ngIf="!isDreamLiteFollowUp" class="mb-1">
                        {{ 'measurement.currentmeasurement' | translate }}
                    </h4>
                    <h4 *ngIf="isDreamLiteFollowUp" class="mb-1">
                        {{ 'measurement.diffmap' | translate }}
                    </h4>
                    <hr />

                    <div class="row">
                        <div class="col-sm-5">
                            {{ 'general.date' | translate }}:
                            <br />
                            {{ proposal.RightTopographicMeasurement.Performed | mpcdate }}
                        </div>
                        <div class="col-sm-7 d-flex justify-content-end">
                            <div
                                class="measurementimage"
                                (click)="clickMeasurementImage($event, proposal.RightTopographicMeasurement)">
                                <topo-image-thumbnail
                                    *ngIf="imageOptions"
                                    [id]="'topoimage-od'"
                                    [measurement]="proposal.RightTopographicMeasurement"
                                    [previousMeasurementId]="
                                        isFollowUp ? client.RightBaseLineTopographicMeasurementId : null
                                    "
                                    [imageOptions]="imageOptions"></topo-image-thumbnail>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #refractionsOS>
        <div *ngIf="proposal && refractionLeftEyeSelected" class="currentmeasurement rounded">
            <refraction-details
                #refractionDetailsOs
                id="refraction-os"
                [eyeSide]="eyesides.Os"
                [hasMyopie]="hasMyopie()"
                [measurement]="proposal.LeftRefractionMeasurement"
                [previous]="leftPrevious"
                [showStabilisation]="isRegularFollowUp"
                [showPrevious]="showPreviousMeasurement"></refraction-details>

            <div *ngIf="proposal.LeftTopographicMeasurement" class="row m-0">
                <div class="p-3" [ngClass]="showPreviousMeasurement ? 'col-7 col-lg-8' : 'col-12'">
                    <h4 *ngIf="!isDreamLiteFollowUp" class="mb-1">
                        {{ 'measurement.currentmeasurement' | translate }}
                    </h4>
                    <h4 *ngIf="isDreamLiteFollowUp" class="mb-1">
                        {{ 'measurement.diffmap' | translate }}
                    </h4>
                    <hr />

                    <div class="row">
                        <div class="col-sm-5">
                            {{ 'general.date' | translate }}:
                            <br />
                            {{ proposal.LeftTopographicMeasurement.Performed | mpcdate }}
                        </div>
                        <div class="col-sm-7 d-flex justify-content-end">
                            <div
                                class="measurementimage"
                                (click)="clickMeasurementImage($event, proposal.LeftTopographicMeasurement)">
                                <topo-image-thumbnail
                                    *ngIf="imageOptions"
                                    [id]="'topoimage-os'"
                                    [measurement]="proposal.LeftTopographicMeasurement"
                                    [previousMeasurementId]="
                                        isFollowUp ? client.LeftBaseLineTopographicMeasurementId : null
                                    "
                                    [imageOptions]="imageOptions"></topo-image-thumbnail>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<div *footer class="d-flex align-items-center">
    <div>
        <a href="#" (click)="previous($event)">
            <i class="fas fa-arrow-alt-circle-left me-1"></i>
            {{ 'general.measurement' | translate }}
        </a>
    </div>
    <div class="ms-auto d-flex">
        <div *ngIf="fittingService.isFollowUp" class="mt-1 me-2">
            {{ 'measurement.norefractionneeded' | translate }}
        </div>

        <button
            *ngIf="isFollowUp"
            type="button"
            id="saveCheckup"
            class="btn btn-primary"
            [disabled]="formGroup.invalid || !atLeastOneMeasurementSelected()"
            (click)="next(true)">
            {{ 'general.savecheckup' | translate }}
        </button>
        <button
            type="button"
            id="next"
            class="btn btn-warning mx-1"
            [disabled]="!isNextEnabled()"
            (click)="next(false)">
            {{ 'general.next' | translate }}
        </button>
    </div>
</div>
