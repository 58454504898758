import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureSetting } from '../models/featureSetting.model';
import { FeatureCheckerService } from '../appservices/feature-checker.service';

@Directive({
    selector: '[hasFeatureSetting]',
})
export class HasFeatureSettingDirective implements OnInit {
    private showChildElements = false;

    constructor(
        private readonly templateRef: TemplateRef<unknown>,
        private readonly viewContainer: ViewContainerRef,
        private readonly featureChecker: FeatureCheckerService,
    ) {}

    ngOnInit() {
        this.updateView();
    }

    @Input()
    set hasFeatureSetting(featureSettings: FeatureSetting[]) {
        this.showChildElements = this.featureChecker.check(featureSettings);
        this.updateView();
    }

    private updateView() {
        if (this.showChildElements) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
