<div class="container-fluid mt-4 px-lg-4">
    <div class="row">
        <!-- Move client -->
        <div class="col-3">
            <div class="row">
                <div class="col-12">
                    <div class="mb-4 title-border-bottom">
                        <h3 class="mt-auto">
                            {{ 'moveclient.moveClientToDifferentOpticianLabel' | translate }}
                        </h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div [formGroup]="formGroupOpticians" class="content-section rounded mb-2">
                        <input
                            #myInput
                            (click)="myInput.select()"
                            formControlName="currentOptician"
                            id="currentOptician"
                            [typeahead]="opticians"
                            typeaheadOptionField="Name"
                            (typeaheadOnSelect)="setOptician($event)"
                            [typeaheadOptionsLimit]="4"
                            class="form-control" />
                    </div>

                    <button
                        class="btn btn-success"
                        (click)="moveClientToDifferentOptician()"
                        [disabled]="!selectedOpticianId">
                        {{ 'moveclient.moveClientToDifferentOpticianLabel' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <!-- Eyelite import -->
        <div class="col-9">
            <div class="row">
                <div class="col-12">
                    <div class="mb-4 title-border-bottom">
                        <h3 class="mt-auto">
                            {{ 'eyeliteimport.eyeliteimport' | translate }}
                        </h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <ps-eyeliteimport [client]="client"></ps-eyeliteimport>
                </div>
            </div>
        </div>
    </div>
</div>

<div *footer class="row">
    <div class="ms-auto d-flex">
        <div class="d-flex align-items-center">
            <button
                type="button"
                class="btn btn-proceed mx-1"
                id="exportToZip"
                [disabled]="!client"
                (click)="exportToZip()">
                ZIP {{ 'general.export' | translate }}
            </button>
        </div>
    </div>
</div>
