<div class="form-group row" *ngIf="!formControlOnly">
    <label for="{{ id }}" class="col-form-label my-auto" [ngClass]="labelClass">
        <i
            *ngIf="info && infoPosition === 'left'"
            class="fa fa-question-circle"
            popover="{{ info }}"
            triggers="mouseenter:mouseleave"></i>
        {{ label | translate: labelParams }}
        <i
            *ngIf="info && infoPosition === 'right'"
            class="fa fa-question-circle"
            popover="{{ info }}"
            triggers="mouseenter:mouseleave"></i>
    </label>
    <div [ngClass]="controlClass">
        <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
    </div>
</div>

<div *ngIf="formControlOnly">
    <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
</div>

<ng-template #controlTemplate>
    <div class="d-flex align-items-center flex-row">
        <div class="me-2" *ngIf="_inputType === 'password'">
            <i
                popover="{{ 'settings.passwordshow' | translate }}"
                triggers="mouseenter:mouseleave"
                class="fa fa-eye"
                [ngClass]="iconClass"
                (click)="toggleShowPasswords()"></i>
        </div>

        <div *ngIf="prefix" class="me-2">
            {{ prefix }}
        </div>
        <input
            [attr.type]="currentInputType"
            [attr.id]="id ? id : null"
            value="{{ value }}"
            class="form-control"
            [attr.maxlength]="maxLength"
            [attr.minlength]="minLength"
            (keyup)="propagateChange($event)"
            (focus)="hasFocus = true"
            (blur)="onBlur($event)"
            [attr.autocomplete]="autocomplete"
            [attr.required]="isRequired"
            placeholder="{{ placeholder | translate }}"
            [disabled]="isDisabled" />
        <div *ngIf="suffix" class="ms-2">
            {{ suffix | translate }}
        </div>
    </div>
</ng-template>
