import { InputNumberRange } from '@app/shared/models/input-number-range.model';

export class RangeLogic {
    ranges: InputNumberRange[];

    rangeValues = new Array<number>();
    minValue = null;
    maxValue = null;
    showTooltip = false;

    numberOfDecimals: number;

    constructor(ranges: InputNumberRange[], numberOfDecimals = 2) {
        this.ranges = ranges;
        this.numberOfDecimals = numberOfDecimals;
    }

    public execute(data: number): number {
        for (let i = 0; i < this.ranges.length; i++) {
            const range: InputNumberRange = this.ranges[i];

            for (let j = range.Minimum; j <= range.Maximum; j = Math.round((j + range.Step) * 100) / 100) {
                this.rangeValues.push(j);

                if (this.minValue == null || j < this.minValue) {
                    this.minValue = j;
                }

                if (this.maxValue == null || j > this.maxValue) {
                    this.maxValue = j;
                }
            }
        }

        this.rangeValues = this.rangeValues.sort().reverse();
        this.rangeValues = [...new Set(this.rangeValues)];

        return this.adjustValueToRange(data);
    }

    isOutOfRangeLower(value: number): boolean {
        return value < this.minValue;
    }

    isOutOfRangeUpper(value: number): boolean {
        return value > this.maxValue;
    }

    isRangeValue(newValue: number): boolean {
        let isOk = false;

        if (!this.isOutOfRangeLower(newValue) && !this.isOutOfRangeUpper(newValue)) {
            if (this.rangeValues.length > 0) {
                for (let k = 0; k < this.rangeValues.length; k++) {
                    const rangeValue: number = this.rangeValues[k];
                    if (rangeValue === newValue) {
                        isOk = true;
                        break;
                    }
                }
            } else {
                isOk = true;
            }
        }

        return isOk;
    }

    adjustValueToRange(newValue: number): number {
        if (this.isRangeValue(newValue)) {
            return newValue;
        }

        const closestValues = new Array<number>();

        for (let i = 0; i < this.ranges.length; i++) {
            const range: InputNumberRange = this.ranges[i];

            if (newValue >= range.Maximum) {
                closestValues.push(range.Maximum);
            } else if (newValue <= range.Minimum) {
                closestValues.push(range.Minimum);
            } else {
                closestValues.push(Math.round((newValue - range.Minimum) / range.Step) * range.Step + range.Minimum);
            }
        }

        let closestValue: number = null;
        let closestValueDiff = 0;

        for (let i = 0; i < closestValues.length; i++) {
            const value: number = closestValues[i];

            const diff = Math.abs(value - newValue);
            if (closestValue == null) {
                closestValue = value;
                closestValueDiff = diff;
            } else if (diff < closestValueDiff) {
                closestValue = value;
                closestValueDiff = diff;
            }
        }

        if (closestValue != null) {
            newValue = closestValue;
        }

        return +newValue.toFixed(this.numberOfDecimals);
    }
}
