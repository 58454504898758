import { Component, OnInit, Input } from '@angular/core';
import { ProposalReceiptLineInfo } from '@app/shared/models/proposalReceiptLineInfo.model';
import { ProposalReceiptInfo } from '@app/shared/models/proposalReceiptInfo.model';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { ParameterTypeCodes } from '@app/shared/enums';

@Component({
    selector: 'receipt-details',
    templateUrl: 'receipt-details.component.html',
    styleUrls: ['receipt-details.component.scss'],
})
export class ReceiptDetailsComponent implements OnInit {
    readonly parameterOrder: string[] = [
        ParameterTypeCodes.POW,
        ParameterTypeCodes.CYL,
        ParameterTypeCodes.AX,
        ParameterTypeCodes.DIAM,
        ParameterTypeCodes.RAD,
    ];

    @Input() receipt: ProposalReceiptInfo;
    @Input() hideOrderInfo = false;

    receiptLines: ProposalReceiptLineInfo[];
    headerParameterCodes: string[];
    hasMarking: boolean;

    constructor(public appState: AppStateService) {}

    ngOnInit(): void {
        this.receiptLines = this.receipt.ProposalReceiptLines;
        this.headerParameterCodes = this.createHeaderParameters(this.receiptLines);
        this.hasMarking = this.receiptLines.some((x) => x.LensEngravingName);
    }

    private createHeaderParameters(receiptLines: ProposalReceiptLineInfo[]): string[] {
        const uniqueParameters = [
            ...new Map(receiptLines.flatMap((x) => x.FittedLensParameters).map((x) => [x.Code, x])).values(),
        ];

        return uniqueParameters
            .sort((a, b) => {
                const aIndex = this.parameterOrder.indexOf(ParameterTypeCodes[a.Code]);
                const bIndex = this.parameterOrder.indexOf(ParameterTypeCodes[b.Code]);
                if (aIndex === -1 && bIndex === -1) {
                    return a.SortOrder - b.SortOrder;
                }
                if (aIndex === -1) {
                    return 1;
                }
                if (bIndex === -1) {
                    return -1;
                }
                return aIndex - bIndex;
            })
            .map((param) => {
                return param.Code;
            });
    }

    getReceiptLineStatusClass(): string {
        return this.receipt.IsOrdered ? 'receipt-ordered' : 'receipt-notordered';
    }
}
