<div class="modal-header">
    <h4 class="modal-title m-auto">{{ 'client.mergeclient' | translate }}</h4>
</div>

<div class="modal-body d-flex" [formGroup]="formGroup">
    <div class="w-50">
        <div class="flex-sm-grow-1 p-4">
            <div class="col-12 col-xl-10 offset-xl-2">
                <form [formGroup]="formGroup">
                    <div class="title-border-bottom mb-2">
                        <h3>{{ 'searchclient.searchclients' | translate }}</h3>
                    </div>
                    <mpc-input-text
                        formControlName="reference"
                        [id]="'reference-merge'"
                        label="general.reference"
                        (change)="search()"
                        (keyup)="search()"></mpc-input-text>
                    <mpc-input-text
                        formControlName="reference2"
                        [id]="'reference2-merge'"
                        label="general.reference2"
                        (change)="search()"></mpc-input-text>
                    <mpc-input-date
                        formControlName="birthDate"
                        id="birthDate-merge"
                        label="general.birthdate"
                        [allowIncompleteDate]="true"
                        (change)="search()"></mpc-input-date>
                    <mpc-input-checkbox-list
                        formControlName="gender"
                        label="general.gender"
                        [options]="genderOptions"
                        (change)="search()"
                        mode="single"></mpc-input-checkbox-list>
                    <mpc-input-checkbox-list
                        formControlName="myopia"
                        id="myopia-merge"
                        label="general.myopia"
                        [options]="myopiaOptions"
                        mode="single"
                        (change)="search()"></mpc-input-checkbox-list>
                </form>
            </div>
        </div>
    </div>
    <div class="w-50 ms-2 p-4">
        <div class="title-border-bottom mb-2">
            <h3>{{ 'general.searchresults' | translate }}</h3>
        </div>
        <div class="">
            <div *ngIf="clientSearchResult.length === 0">
                {{ 'client.clientmergenotfound' | translate }}
            </div>
            <div *ngIf="clientSearchResult.length > 0">
                <div *ngFor="let client of clientSearchResult; let i = index">
                    <div
                        class="w-100 rounded client mergeclient p-1 mt-2"
                        (click)="onMergeTargetSelect(client)"
                        id="{{ i }}-mergeclient">
                        {{ client.Reference }}
                        <span *ngIf="client.Reference2">({{ client.Reference2 }})</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="ms-auto d-flex">
        <div *ngIf="mergeIntoClient" class="align-content-center me-2 p-3">
            <h6>
                {{ 'client.mergeclientmsg1' | translate }}
                {{ mergeFromClient.Reference }}
                <span *ngIf="mergeFromClient.Reference2">({{ mergeFromClient.Reference2 }})</span>
                {{ 'client.mergeclientmsg2' | translate }}
                {{ mergeIntoClient.Reference }}
                <span *ngIf="mergeIntoClient.Reference2">({{ mergeIntoClient.Reference2 }})</span>
                {{ 'client.mergeclientmsg3' | translate }}
            </h6>
        </div>
        <button
            class="btn btn-danger m-1"
            (click)="mergeClients()"
            [disabled]="!mergeIntoClient || mergeIntoClient.Id === mergeFromClient.Id"
            id="mergeclientsbutton">
            {{ 'client.mergeclient' | translate }}
        </button>
        <button class="btn btn-default m-1" (click)="bsModalRef.hide()">
            {{ 'general.close' | translate }}
        </button>
    </div>
</div>
