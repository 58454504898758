<div class="flex-fill d-flex flex-column flex-sm-row">
    <div class="flex-sm-grow-1 p-4 searchreceipt-left">
        <div class="col-12 col-xl-10 offset-xl-2">
            <form [formGroup]="searchFormGroup">
                <div class="title-border-bottom mb-2">
                    <h3>{{ 'receiptorder.search' | translate }}</h3>
                </div>
                <mpc-input-text formControlName="Reference" [id]="'Reference'" label="Reference"></mpc-input-text>
                <mpc-input-text formControlName="Code" [id]="'Code'" label="Code"></mpc-input-text>
                <button class="btn btn-primary" (click)="findReceipt()">{{ 'receiptorder.search' | translate }}</button>
            </form>
        </div>

        <div *ngIf="!appState.currentOptician.IsApproved" class="table-responsive col-12 col-xl-10 offset-xl-2">
            <br />
            <br />
            <ng-container *ngIf="usedReceipts$ | async; let usedReceipts">
                <h3>{{ 'receiptorder.recentlyordered' | translate }}</h3>
                <table class="table mb-1 datatable">
                    <thead>
                        <tr>
                            <th>{{ 'general.date' | translate }}</th>
                            <th>{{ 'receipt.reference' | translate }}</th>
                            <th>{{ 'receipt.code' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let usedReceipt of usedReceipts">
                            <td>{{ usedReceipt.CodeUsedOn | mpcdate }}</td>
                            <td>{{ usedReceipt.Reference }}</td>
                            <td>{{ usedReceipt.Code }}</td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
        </div>
    </div>

    <div class="flex-sm-grow-1 p-4 d-flex flex-column accented-background searchreceipt-results">
        <div class="col-12 col-xl-12 flex-sm-grow-1 d-flex flex-column">
            <div class="d-flex title-border-bottom">
                <div>
                    <h3>{{ 'receiptorder.receiptinfo.header' | translate }}</h3>
                </div>
            </div>
            <div id="searchResults" class="flex-grow-1" class="d-flex flex-column">
                <div class="spinner-border spinner-border-sm my-2" *ngIf="isLoading"></div>
                <div class="mt-2" *ngIf="!isLoading && !receipt">
                    <span *ngIf="isSearched && !alreadyUsed" id="noreceiptfound">
                        {{ 'receiptorder.nomatch' | translate }}
                    </span>
                    <span *ngIf="isSearched && alreadyUsed" id="alreadyUsed">
                        {{ 'receiptorder.alreadyused' | translate }}
                    </span>
                    <span *ngIf="!isSearched" class="default-message">{{ 'receiptorder.noitems' | translate }}</span>
                </div>
                <div *ngIf="!isLoading && receipt">
                    <div class="row">
                        <mpc-model-label
                            id="FitDate"
                            label="Measured by Optician:"
                            value="{{ receipt.CreatedByOptician.Name }}"
                            breakpoint=""></mpc-model-label>
                        <mpc-model-label
                            id="FitDate"
                            label="Measured on:"
                            value="{{ proposal.ProposalDate | mpcdate }}"
                            breakpoint=""></mpc-model-label>
                    </div>

                    <div class="row" *ngIf="proposal">
                        <div class="col-md-2 lenstype-wrapper d-flex justify-content-center justify-content-md-end">
                            <div class="flex-column me-3">
                                <div class="d-flex justify-content-center" *ngIf="lensTypeImage">
                                    <div class="circle">
                                        <img
                                            alt="lens type"
                                            class="lenstypeIcon"
                                            src="assets/images/{{ lensTypeImage }}" />
                                    </div>
                                </div>
                                <div *ngIf="lensTypeTitle" class="d-flex justify-content-center mt-3">
                                    <h4>{{ lensTypeTitle | translate }}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-8">
                            <div class="row">
                                <form [formGroup]="orderFormGroup">
                                    <div class="col-12 col-xl-6">
                                        <div *ngIf="proposal.LeftOpticianFittedLens" class="row h-100">
                                            <div class="col-12 ps-xl-2 mb-5">
                                                <div class="row">
                                                    <div class="col-12 pt-3">
                                                        <h1>{{ 'general.os' | translate }}</h1>
                                                    </div>
                                                </div>

                                                <div
                                                    class="row lens-information accented-background3 rounded mx-0 pb-0 pt-3 mb-3">
                                                    <div class="col-md-6">
                                                        <mpc-model-label
                                                            id="leftLensName"
                                                            label="{{ 'general.lens' | translate }}"
                                                            value="{{
                                                                proposal.LeftOpticianFittedLens.LensDefinition.Name
                                                            }}"
                                                            breakpoint=""></mpc-model-label>
                                                        <mpc-model-label
                                                            *ngIf="proposal.LeftOpticianFittedLens.MaterialColor"
                                                            id="leftMaterialName"
                                                            label="{{ 'general.material' | translate }}"
                                                            value="{{
                                                                proposal.LeftOpticianFittedLens.MaterialColor.Material
                                                                    .Name +
                                                                    ' ' +
                                                                    proposal.LeftOpticianFittedLens.MaterialColor.Color
                                                                        .Name
                                                            }}"
                                                            breakpoint=""></mpc-model-label>
                                                        <mpc-model-label
                                                            id="leftMarkings"
                                                            *ngIf="proposal.LensTypeId !== 2"
                                                            label="{{ 'fitlens.lensengraving' | translate }}"
                                                            value="{{
                                                                proposal.LeftOpticianFittedLens.LensEngraving.Name
                                                            }}"
                                                            breakpoint=""></mpc-model-label>

                                                        <mpc-input-number
                                                            [formControlName]="'LeftQuantity'"
                                                            [originalValue]="proposal.LeftProposalOptions.Quantity"
                                                            [id]="'LeftQuantity'"
                                                            [ranges]="[leftQuantityRange]"
                                                            [maxLength]="2"
                                                            [label]="'general.quantity'"></mpc-input-number>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <ng-container
                                                            *ngFor="
                                                                let param of proposal.LeftOpticianFittedLens
                                                                    .FittedLensParameters
                                                            ">
                                                            <mpc-model-label
                                                                label="{{ param.LensDefinitionParameter.Name }}"
                                                                id="left-{{
                                                                    param.LensDefinitionParameter.Name.replace(' ', '-')
                                                                }}"
                                                                [formatting]="getFormatObject(param)"
                                                                [value]="param.Value"
                                                                breakpoint=""></mpc-model-label>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row col-12 col-xl-6">
                                        <div *ngIf="proposal.RightOpticianFittedLens" class="row h-100">
                                            <div class="col-12 ps-xl-2 mb-5">
                                                <div class="row">
                                                    <div class="col-12 pt-3">
                                                        <h1>{{ 'general.od' | translate }}</h1>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row lens-information accented-background3 rounded mx-0 pb-0 pt-3 mb-3">
                                                    <div class="col-md-6">
                                                        <mpc-model-label
                                                            id="rightLensName"
                                                            label="{{ 'general.lens' | translate }}"
                                                            value="{{
                                                                proposal.RightOpticianFittedLens.LensDefinition.Name
                                                            }}"
                                                            breakpoint=""></mpc-model-label>
                                                        <mpc-model-label
                                                            *ngIf="proposal.RightOpticianFittedLens.MaterialColor"
                                                            id="rightMaterialName"
                                                            label="{{ 'general.material' | translate }}"
                                                            value="{{
                                                                proposal.RightOpticianFittedLens.MaterialColor.Material
                                                                    .Name +
                                                                    ' ' +
                                                                    proposal.RightOpticianFittedLens.MaterialColor.Color
                                                                        .Name
                                                            }}"
                                                            breakpoint=""></mpc-model-label>
                                                        <mpc-model-label
                                                            id="rightMarkings"
                                                            *ngIf="proposal.LensTypeId !== 2"
                                                            label="{{ 'fitlens.lensengraving' | translate }}"
                                                            value="{{
                                                                proposal.RightOpticianFittedLens.LensEngraving.Name
                                                            }}"
                                                            breakpoint=""></mpc-model-label>

                                                        <mpc-input-number
                                                            [formControlName]="'RightQuantity'"
                                                            [originalValue]="proposal.RightProposalOptions.Quantity"
                                                            [id]="'RightQuantity'"
                                                            [ranges]="[rightQuantityRange]"
                                                            [maxLength]="2"
                                                            [label]="'general.quantity'"></mpc-input-number>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <ng-container
                                                            *ngFor="
                                                                let param of proposal.RightOpticianFittedLens
                                                                    .FittedLensParameters
                                                            ">
                                                            <mpc-model-label
                                                                label="{{ param.LensDefinitionParameter.Name }}"
                                                                id="left-{{
                                                                    param.LensDefinitionParameter.Name.replace(' ', '-')
                                                                }}"
                                                                [formatting]="getFormatObject(param)"
                                                                [value]="param.Value"
                                                                breakpoint=""></mpc-model-label>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="ms-auto d-flex">
                        <button class="btn btn-primary" (click)="order()" type="button">
                            {{ 'general.order' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *footer>
        <a [routerLink]="['/']">
            <i class="fas fa-arrow-alt-circle-left me-1"></i>
            {{ 'general.home' | translate }}
        </a>
        <div *ngIf="!appState.currentOptician.IsApproved" class="text-warning justify-content-center ms-auto d-flex">
            {{ 'receiptorder.notapprovedinfo' | translate }}
        </div>
    </div>
</div>
