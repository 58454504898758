<div *ngIf="!formControlOnly" class="form-group row" [ngClass]="customClass">
    <label for="{{ id }}" class="col-form-label my-0" [ngClass]="numberLabelClass">
        {{ label | translate: labelParams }}
        <i
            *ngIf="info"
            class="fa fa-question-circle"
            popover="{{ info }}"
            placement="right"
            triggers="mouseenter:mouseleave"></i>
        <i
            *ngIf="warning"
            class="fa fa-exclamation-circle"
            popover="{{ warning }}"
            placement="right"
            triggers="mouseenter:mouseleave"></i>
    </label>
    <div [ngClass]="controlClass" class="d-flex align-items-center">
        <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
    </div>
</div>

<div *ngIf="formControlOnly" [ngClass]="customClass">
    <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
</div>

<ng-template #controlTemplate>
    <div class="d-flex flex-nowrap input-number">
        <div class="input-number-border">
            <input
                class="minus button"
                value="-"
                type="button"
                (click)="onTouched(); decrement()"
                [disabled]="isDisabled || (value | rangeCheck: inputNumberRanges : 'min')"
                tabindex="-1" />
            <input
                #myInput
                (click)="myInput.select()"
                type="text"
                id="{{ id }}"
                popover="{{ 'validation.valuechanged' | translate }}"
                triggers=""
                [isOpen]="doShowPopover"
                [disabled]="isDisabled"
                [(ngModel)]="formattedValue"
                class="form-control field"
                [attr.maxlength]="maxLength"
                (keyup)="validateInput($event)"
                (change)="onChangeInput($event)"
                (blur)="onBlur($event)"
                (focus)="onTouched(); onInputFocus($event)"
                [attr.autocomplete]="useAutoFill ? '' : 'no'"
                [attr.required]="isRequired" />
            <input
                class="plus button"
                value="+"
                type="button"
                (click)="onTouched(); increment()"
                [disabled]="isDisabled || (value | rangeCheck: inputNumberRanges : 'max')"
                tabindex="-1" />
        </div>
        <div *ngIf="unit" class="ms-2">{{ unit | translate }}</div>
    </div>
</ng-template>
