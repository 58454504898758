import { EyeliteLens } from '@app/professional-service/models/eyeliteimport/EyeliteLens.model';
import { EyeliteDreamLiteLens } from '@app/professional-service/models/eyeliteimport/EyeliteDreamLiteLens.model';

export class EyeliteLenses {
    constructor(eyeliteLenses: EyeliteLenses) {
        this.RgpLenses = new Array<EyeliteLens>();

        for (let i = 0; i < eyeliteLenses.RgpLenses.length; i++) {
            this.RgpLenses.push(new EyeliteLens(eyeliteLenses.RgpLenses[i]));
        }

        this.SoftLenses = new Array<EyeliteLens>();
        for (let i = 0; i < eyeliteLenses.SoftLenses.length; i++) {
            this.SoftLenses.push(new EyeliteLens(eyeliteLenses.SoftLenses[i]));
        }

        this.DisposableLenses = new Array<EyeliteLens>();
        for (let i = 0; i < eyeliteLenses.DisposableLenses.length; i++) {
            this.DisposableLenses.push(new EyeliteLens(eyeliteLenses.DisposableLenses[i]));
        }

        this.DreamLiteLenses = new Array<EyeliteDreamLiteLens>();
        for (let i = 0; i < eyeliteLenses.DreamLiteLenses.length; i++) {
            this.DreamLiteLenses.push(new EyeliteDreamLiteLens(eyeliteLenses.DreamLiteLenses[i]));
        }
    }

    public RgpLenses: EyeliteLens[];
    public SoftLenses: EyeliteLens[];
    public DisposableLenses: EyeliteLens[];
    public DreamLiteLenses: EyeliteDreamLiteLens[];
}
