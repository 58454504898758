<div class="modal-header">
    <h4 class="modal-title m-auto">
        {{ 'fitlens.multiplebaselines' | translate }}
    </h4>
</div>

<div class="modal-body">
    <div class="row mb-3">
        <div class="col-12">
            {{ 'fitlens.multiplebaselinescontent' | translate }}
        </div>
    </div>
</div>

<div class="modal-footer accented-background">
    <button class="btn next ms-auto" (click)="continue()">
        {{ 'fitlens.multiplebaselinescontinue' | translate }}
    </button>
    <button class="btn btn-secondary ms-2" (click)="cancel()">
        {{ 'general.cancel' | translate }}
    </button>
</div>
