import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RestRefraction } from '@app/shared/models/restrefraction.model';

@Component({
    selector: 'rest-refraction-warning-dialog',
    templateUrl: 'rest-refraction-warning-dialog.component.html',
})
export class RestRefractionWarningDialogComponent {
    @Input() leftRestRefraction: RestRefraction;
    @Input() rightRestRefraction: RestRefraction;

    @Input() onContinue: () => void;
    @Input() onCancel: () => void;

    constructor(public modalref: BsModalRef) {}

    back(): void {
        this.onCancel();
        this.modalref.hide();
    }

    continue(): void {
        this.onContinue();
        this.modalref.hide();
    }
}
