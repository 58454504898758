import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FittingService } from '@app/core/services/fitting.service';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subscription, lastValueFrom } from 'rxjs';
import { EyeSides, FittingSteps, Features } from '@app/shared/enums';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { GetTopographicMeasurementsByClientIdRequest } from '@app/shared/requestmodels';
import { TopographicMeasurement, Proposal } from '@app/shared/models';
import { TopographicMeasurements } from '@app/shared/models/topographicMeasurements.model';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { AlertService } from '@app/shared/appservices/alert.service';
import { HubProxyService } from '@app/core/services/wss/hubproxy.service';
import { TranslateService } from '@ngx-translate/core';
import { ImageOptions } from '@app/shared/models/image-options.model';
import { TopographicManualCalculation } from '@app/shared/models/topographicManualCalculation.model';
import { MeasurementManualComponent } from '@app/measurement/measurement-manual.component';
import { TopographicManualCalculationWithMeasurement } from '@app/shared/models/TopographicManualCalculationWithMeasurement';
import { SessionService } from '@app/shared/appservices/session.service';
import { SaveFittingContinuationConfiguration } from './saveFittingContinuationConfiguration';
import { TopographicMeasurementService } from '@app/core/services/api/topographic-measurement.service';
import { ProposalService } from '@app/core/services/api/proposal.service';
import { OpticianService } from '@app/core/services/api/optician.service';

@Component({
    selector: 'fitting-measurement',
    templateUrl: 'fitting-measurement.component.html',
})
export class FittingMeasurementComponent implements OnInit, OnDestroy {
    formGroup: UntypedFormGroup;
    get formControls() {
        return this.formGroup.controls;
    }

    newMeasurementsAvailable: Subscription;
    showHistoricMeasurements: Subscription;
    doShowHistoricMeasurements: boolean;

    imageOptions: ImageOptions;

    eyeSides = EyeSides;

    importedMeasurements: TopographicMeasurements;
    historicMeasurements: TopographicMeasurements;

    @ViewChild('rightManualCalculationComponent')
    rightManualCalculationComponent: MeasurementManualComponent;
    @ViewChild('leftManualCalculationComponent')
    leftManualCalculationComponent: MeasurementManualComponent;

    inDeleteMode = false;
    loadingMeasurements = false;

    leftManualCalculationSelected = false;
    rightManualCalculationSelected = false;
    opticianHasManualOnlyLens = false;

    get measurements(): TopographicMeasurements {
        return this.doShowHistoricMeasurements ? this.historicMeasurements : this.importedMeasurements;
    }
    get proposal(): Proposal {
        return this.fittingService.state.proposal;
    }

    get rightManualCalculation(): TopographicManualCalculation {
        return this.fittingService.state.rightManualCalculation;
    }

    get leftManualCalculation(): TopographicManualCalculation {
        return this.fittingService.state.leftManualCalculation;
    }

    constructor(
        public fittingService: FittingService,
        public appState: AppStateService,
        private readonly fb: UntypedFormBuilder,
        private readonly hubProxyService: HubProxyService,
        private readonly router: Router,
        private readonly loaderService: LoaderService,
        private readonly topographicMeasurementService: TopographicMeasurementService,
        private readonly alertService: AlertService,
        private readonly hubServiceProxy: HubProxyService,
        private readonly translate: TranslateService,
        private readonly sessionService: SessionService,
        private readonly proposalService: ProposalService,
        private readonly opticianService: OpticianService,
    ) {}

    ngOnInit(): void {
        this.fittingService.setFittingStep(this.router.url);

        if (!this.fittingService.state.client || !this.proposal) {
            this.router.navigate(['/']).then();
        }

        this.fittingService.configureSaveForContinuation(
            new SaveFittingContinuationConfiguration(
                () => this.isNextEnabled,
                () => this.save(),
            ),
        );

        this.loadImportedMeasurements().then();

        this.imageOptions = new ImageOptions(null);
        this.imageOptions.ShowImageTypes = false;
        this.imageOptions.ShowImageChoice = true;
        this.createForm();

        this.newMeasurementsAvailable = this.hubServiceProxy.newMeasurementsAvailable.subscribe(
            (newMeasurements: TopographicMeasurements) => {
                this.loadingMeasurements = true;

                newMeasurements.LeftTopographicMeasurements.forEach((measurement) => {
                    const isAlreadyInList = this.importedMeasurements.LeftTopographicMeasurements.find(
                        (x) => x.Id === measurement.Id,
                    );
                    if (isAlreadyInList) {
                        return;
                    }

                    measurement.IsMarked = measurement.Id === this.proposal.LeftTopographicMeasurementId;
                    this.importedMeasurements.LeftTopographicMeasurements.unshift(measurement);
                });

                newMeasurements.RightTopographicMeasurements.forEach((measurement) => {
                    const isAlreadyInList = this.importedMeasurements.RightTopographicMeasurements.find(
                        (x) => x.Id === measurement.Id,
                    );
                    if (isAlreadyInList) {
                        return;
                    }

                    measurement.IsMarked = measurement.Id === this.proposal.RightTopographicMeasurementId;
                    this.importedMeasurements.RightTopographicMeasurements.unshift(measurement);
                });

                this.loadingMeasurements = false;
                this.alertService.success(this.translate.instant('general.measurementNew'));
            },
        );

        this.showHistoricMeasurements = this.formControls['showHistoricMeasurements'].valueChanges.subscribe(
            (result: boolean) => {
                this.toggleHistoricMeasurements(result).then();
            },
        );

        if (this.appState.isCompanyFeatureEnabled(Features.ShowHistoricMeasurementsByDefault)) {
            this.formControls['showHistoricMeasurements'].patchValue(true);
        }

        this.opticianService.getLensDefinitions().subscribe((result) => {
            this.opticianHasManualOnlyLens = result.some((lensdef) => lensdef.Product.OnlyManualMeasurements);
        });

        this.leftManualCalculationSelected = !!this.fittingService.state.leftManualCalculation;
        this.rightManualCalculationSelected = !!this.fittingService.state.rightManualCalculation;

        this.hubProxyService.initializeConnection();
    }

    ngOnDestroy(): void {
        if (this.showHistoricMeasurements) {
            this.showHistoricMeasurements.unsubscribe();
        }

        if (this.newMeasurementsAvailable) {
            this.newMeasurementsAvailable.unsubscribe();
        }

        this.hubServiceProxy.closeConnection();
    }

    private checkPreviousOrderHasManualOnlyLens(): boolean {
        return (
            !!this.proposal?.LeftOpticianFittedLens?.LensDefinition?.Product?.OnlyManualMeasurements ||
            !!this.proposal?.RightOpticianFittedLens?.LensDefinition?.Product?.OnlyManualMeasurements
        );
    }

    createForm(): void {
        this.formGroup = this.fb.group({
            showHistoricMeasurements: [false],
        });
    }

    toggleMobileManual(eyeSideId: EyeSides): void {
        if (eyeSideId === EyeSides.Od) {
            this.rightManualCalculationSelected = !this.rightManualCalculationSelected;
        } else {
            this.leftManualCalculationSelected = !this.leftManualCalculationSelected;
        }
    }

    async toggleHistoricMeasurements(newValue: boolean) {
        this.doShowHistoricMeasurements = newValue;

        this.proposal.LeftTopographicMeasurement = null;
        this.proposal.LeftTopographicMeasurementId = null;
        this.proposal.RightTopographicMeasurement = null;
        this.proposal.RightTopographicMeasurementId = null;

        if (this.doShowHistoricMeasurements) {
            this.inDeleteMode = false;
            await this.loadHistoricMeasurements();
        }
    }

    async loadImportedMeasurements() {
        this.loadingMeasurements = true;

        const hasLinkedMeasurements =
            !!this.proposal &&
            (!!this.proposal.LeftTopographicMeasurementId || !!this.proposal.RightTopographicMeasurementId);
        if (hasLinkedMeasurements) {
            // hasLinkedMeasurements implies this step is executed for a second time,
            // because it can only become true by saving this step.
            // If this is the case, we have to undo the linkage here, because maybe the opticien
            // wants to undo this step and link other measurements.
            // This should *not* be done if historic measurements were linked to this proposal,
            // but that will be detected by the server.
            await lastValueFrom(
                this.topographicMeasurementService.removeTopographicMeasurementsFromClient(this.proposal),
            );
        }

        this.importedMeasurements = await lastValueFrom(
            this.topographicMeasurementService.getAvailableTopographicMeasurements(),
        );

        this.updateMeasurements();
        this.loadingMeasurements = false;
    }

    async loadHistoricMeasurements() {
        if (this.historicMeasurements) {
            return;
        }

        this.loadingMeasurements = true;

        const requestModel = new GetTopographicMeasurementsByClientIdRequest();
        requestModel.clientId = this.fittingService.getClientId();
        requestModel.loadLeftTopographicMeasurements = true;
        requestModel.loadRightTopographicMeasurements = true;
        requestModel.pageSize = 999;
        requestModel.pageIndex = 0;

        this.historicMeasurements = await lastValueFrom(
            this.topographicMeasurementService.getTopographicMeasurementsByClientId(requestModel),
        );

        if (this.isFollowUp && this.checkPreviousOrderHasManualOnlyLens()) {
            this.historicMeasurements.LeftTopographicMeasurements =
                this.historicMeasurements.LeftTopographicMeasurements.filter((measurement) => measurement.IsManual);
            this.historicMeasurements.RightTopographicMeasurements =
                this.historicMeasurements.RightTopographicMeasurements.filter((measurement) => measurement.IsManual);
        }

        this.loadingMeasurements = false;
    }

    get isManualCalculationAllowed(): boolean {
        return (
            !!this.fittingService.state.client &&
            !this.fittingService.state.client.Myopie &&
            !this.fittingService.isDreamLiteFollowUp
        );
    }

    get showOD(): boolean {
        return this.fittingService.canSelectRightEye(FittingSteps.Measurement);
    }

    get showOS(): boolean {
        return this.fittingService.canSelectLeftEye(FittingSteps.Measurement);
    }

    get isFollowUp(): boolean {
        return this.fittingService.isDreamLiteFollowUp || this.fittingService.isFollowUp;
    }

    get isSkippable(): boolean {
        return this.fittingService.isFollowUp && !this.fittingService.isDreamLiteFollowUp;
    }

    hasLeftMeasurement(): boolean {
        return this.proposal && this.proposal.LeftTopographicMeasurement != null;
    }

    hasRightMeasurement(): boolean {
        return this.proposal && this.proposal.RightTopographicMeasurement != null;
    }

    updateMeasurements(): void {
        if (this.proposal) {
            // if the measurement in the proposal is not in the list, remove it from the internal list
            if (
                this.proposal.LeftTopographicMeasurement &&
                this.importedMeasurements.LeftTopographicMeasurements.findIndex(
                    (t) => t.Id === this.proposal.LeftTopographicMeasurement.Id,
                ) < 0
            ) {
                this.importedMeasurements.LeftTopographicMeasurements.unshift(this.proposal.LeftTopographicMeasurement);
            }

            if (
                this.proposal.RightTopographicMeasurement &&
                this.importedMeasurements.RightTopographicMeasurements.findIndex(
                    (t) => t.Id === this.proposal.RightTopographicMeasurement.Id,
                ) < 0
            ) {
                this.importedMeasurements.RightTopographicMeasurements.unshift(
                    this.proposal.RightTopographicMeasurement,
                );
            }
        }
    }

    rightMeasurementClicked(measurement: TopographicMeasurement): void {
        if (this.proposal.RightTopographicMeasurementId === measurement.Id) {
            this.proposal.RightTopographicMeasurementId = null;
            this.proposal.RightTopographicMeasurement = null;
        } else {
            this.proposal.RightTopographicMeasurementId = measurement.Id;
            this.proposal.RightTopographicMeasurement = measurement;
        }
    }

    leftMeasurementClicked(measurement: TopographicMeasurement): void {
        if (this.proposal.LeftTopographicMeasurementId === measurement.Id) {
            this.proposal.LeftTopographicMeasurementId = null;
            this.proposal.LeftTopographicMeasurement = null;
        } else {
            this.proposal.LeftTopographicMeasurementId = measurement.Id;
            this.proposal.LeftTopographicMeasurement = measurement;
        }
    }

    measurementDeleted(measurement: TopographicMeasurement): void {
        if (this.proposal.LeftTopographicMeasurementId === measurement.Id) {
            this.proposal.LeftTopographicMeasurement = null;
            this.proposal.LeftTopographicMeasurementId = null;
        }

        if (this.proposal.RightTopographicMeasurementId === measurement.Id) {
            this.proposal.RightTopographicMeasurement = null;
            this.proposal.RightTopographicMeasurementId = null;
        }

        if (this.importedMeasurements) {
            const leftImportedIndex = this.importedMeasurements.LeftTopographicMeasurements.findIndex(
                (m) => m.Id === measurement.Id,
            );
            const rightImportedIndex = this.importedMeasurements.RightTopographicMeasurements.findIndex(
                (m) => m.Id === measurement.Id,
            );

            if (leftImportedIndex >= 0) {
                this.importedMeasurements.LeftTopographicMeasurements.splice(leftImportedIndex, 1);
            }
            if (rightImportedIndex >= 0) {
                this.importedMeasurements.RightTopographicMeasurements.splice(rightImportedIndex, 1);
            }
        }
    }

    measurementIsSelected(measurementId: number): boolean {
        return (
            !!this.proposal &&
            (this.proposal.LeftTopographicMeasurementId === measurementId ||
                this.proposal.RightTopographicMeasurementId === measurementId)
        );
    }

    hasValidMeasurementSelection(): boolean {
        if (this.leftManualCalculationSelected && !this.leftManualCalculationComponent.formGroup.valid) {
            return false;
        }
        if (this.rightManualCalculationSelected && !this.rightManualCalculationComponent.formGroup.valid) {
            return false;
        }
        if (this.rightManualCalculationSelected && this.rightManualCalculationComponent.formGroup.valid) {
            return true;
        }
        if (this.leftManualCalculationSelected && this.leftManualCalculationComponent.formGroup.valid) {
            return true;
        }

        const leftExistingSelected = this.proposal && this.proposal.LeftTopographicMeasurementId != null;
        const rightExistingSelected = this.proposal && this.proposal.RightTopographicMeasurementId != null;

        return leftExistingSelected || rightExistingSelected;
    }

    previous($event: MouseEvent): void {
        $event.preventDefault();
        this.fittingService.gotoPreviousStep();
    }

    get isNextEnabled(): boolean {
        return this.hasValidMeasurementSelection() || this.isSkippable;
    }

    async assignTopographicMeasurementsForFollowup(): Promise<void> {
        if (this.proposal.LeftPreviousOrderId !== null && this.proposal.LeftTopographicMeasurementId === null) {
            if (
                this.proposal.LeftOriginalFittedLens !== null &&
                this.proposal.LeftOriginalFittedLens.TopographicMeasurementId !== null
            ) {
                this.proposal.LeftTopographicMeasurementId =
                    this.proposal.LeftOriginalFittedLens.TopographicMeasurementId;
            } else {
                await this.loadHistoricMeasurements();
                if (this.historicMeasurements.LeftTopographicMeasurements.length > 0) {
                    this.proposal.LeftTopographicMeasurementId =
                        this.historicMeasurements.LeftTopographicMeasurements[
                            this.historicMeasurements.LeftTopographicMeasurements.length - 1
                        ].Id;
                }
            }
        }
        if (this.proposal.RightPreviousOrderId !== null && this.proposal.RightTopographicMeasurementId === null) {
            if (
                this.proposal.RightOriginalFittedLens !== null &&
                this.proposal.RightOriginalFittedLens.TopographicMeasurementId !== null
            ) {
                this.proposal.RightTopographicMeasurementId =
                    this.proposal.RightOriginalFittedLens.TopographicMeasurementId;
            } else {
                await this.loadHistoricMeasurements();
                if (this.historicMeasurements.RightTopographicMeasurements.length > 0) {
                    this.proposal.RightTopographicMeasurementId =
                        this.historicMeasurements.RightTopographicMeasurements[
                            this.historicMeasurements.RightTopographicMeasurements.length - 1
                        ].Id;
                }
            }
        }
    }

    async next() {
        if (this.isFollowUp && !this.fittingService.isDreamLiteFollowUp) {
            await this.assignTopographicMeasurementsForFollowup();
        }

        this.fittingService.setProposalStepOnNextClick();
        if (this.hasLeftMeasurement() || this.leftManualCalculation) {
            this.proposal.LeftProposalOptions.Quantity = 1;
        }
        if (this.hasRightMeasurement() || this.rightManualCalculation) {
            this.proposal.RightProposalOptions.Quantity = 1;
        }

        const savedProposal = await this.save();

        this.fittingService.clearSaveForContinationConfiguration();
        this.fittingService.setProposal(savedProposal);

        // which images were selected determine which eyesides are selected. Exception is the followup process:
        // there the first selection of order lines determines the eyesides - selecting an image is not mandatory for follow-up.
        if (!this.fittingService.isFollowUp) {
            this.fittingService.selectEyesSidesForRefractionStep(this.hasRightMeasurement(), this.hasLeftMeasurement());
        }

        this.fittingService.state.originalLeftRefractionMeasurement = this.proposal.LeftRefractionMeasurement;
        this.fittingService.state.originalRightRefractionMeasurement = this.proposal.RightRefractionMeasurement;

        this.loaderService.hide();
        this.fittingService.gotoNextStep();
    }

    private async save(): Promise<Proposal> {
        this.loaderService.show();

        this.proposal.LeftRefractionMeasurement = null;
        this.proposal.LeftRefractionMeasurementId = null;
        this.proposal.RightRefractionMeasurement = null;
        this.proposal.RightRefractionMeasurementId = null;

        const saveOdPromise = this.saveManualCalculation(this.eyeSides.Od);
        const saveOsPromise = this.saveManualCalculation(this.eyeSides.Os);

        return Promise.all([saveOdPromise, saveOsPromise])
            .then(() => lastValueFrom(this.proposalService.saveTopographicMeasurements(this.proposal)))
            .catch((error) => {
                this.alertService.error(this.translate.instant(error.error.Errors[0]));
                return Promise.reject(error);
            });
    }

    private saveManualCalculation(eyeSideId: number): Promise<unknown> {
        if (
            (eyeSideId === EyeSides.Od && !this.rightManualCalculationSelected) ||
            (eyeSideId === EyeSides.Os && !this.leftManualCalculationSelected)
        ) {
            return Promise.resolve();
        }

        const manualMeasurement = new TopographicMeasurement();
        manualMeasurement.ClientId = this.fittingService.getClientId();
        manualMeasurement.IsManual = true;
        manualMeasurement.EyeSideId = eyeSideId;

        const tmcwm = new TopographicManualCalculationWithMeasurement();

        let manualCalculation: TopographicManualCalculation;
        if (eyeSideId === EyeSides.Od) {
            manualCalculation = this.rightManualCalculationComponent.getManualCalculation();
            this.fittingService.state.rightManualCalculation = manualCalculation;
        } else {
            manualCalculation = this.leftManualCalculationComponent.getManualCalculation();
            this.fittingService.state.leftManualCalculation = manualCalculation;
        }

        tmcwm.ManualCalculation = manualCalculation;
        tmcwm.Measurement = manualMeasurement;

        return new Promise((resolve) => {
            this.topographicMeasurementService.performTopographicManualCalculation(tmcwm).subscribe((result) => {
                const newCalculation: TopographicManualCalculation = result;

                if (eyeSideId === EyeSides.Od) {
                    this.proposal.RightTopographicMeasurement = manualMeasurement;
                    //Backend copies the new TopographicMeasurementId to ManualCalculation model
                    this.proposal.RightTopographicMeasurement.Id = newCalculation.Id;
                    this.proposal.RightTopographicMeasurementId = newCalculation.Id;
                } else {
                    this.proposal.LeftTopographicMeasurement = manualMeasurement;
                    this.proposal.LeftTopographicMeasurement.Id = newCalculation.Id;
                    this.proposal.LeftTopographicMeasurementId = newCalculation.Id;
                }

                this.sessionService.save((eyeSideId === EyeSides.Od ? 'right' : 'left') + 'ManualCalculation', result);
                resolve(result);
            });
        });
    }

    get rightMeasurements(): TopographicMeasurement[] {
        if (this.measurements && this.measurements.RightTopographicMeasurements) {
            return this.measurements.RightTopographicMeasurements.filter(
                (m) =>
                    !(
                        (this.fittingService.isDreamLiteFollowUp && m.IsBaseLine) ||
                        (this.fittingService.isDreamLiteFollowUp && m.IsManual)
                    ),
            );
        }
        return [];
    }

    get leftMeasurements(): TopographicMeasurement[] {
        if (this.measurements && this.measurements.LeftTopographicMeasurements) {
            return this.measurements.LeftTopographicMeasurements.filter(
                (m) =>
                    !(
                        (this.fittingService.isDreamLiteFollowUp && m.IsBaseLine) ||
                        (this.fittingService.isDreamLiteFollowUp && m.IsManual)
                    ),
            );
        }
        return [];
    }

    IsBaseLine(tm: TopographicMeasurement): boolean {
        return tm.IsBaseLine && tm.RefractionMeasurements?.some((rm) => rm.IsBaseLine);
    }
}
