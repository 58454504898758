<div class="form-group row" *ngIf="showResidualRefraction">
    <label for="residualrefraction" class="col-2">
        {{ 'fitlens.residualrefraction' | translate }}
    </label>
    <div class="col-10">
        <label
            id="residualrefraction"
            [ngClass]="{
                'text-danger': residualRefraction.Sphere > 0.5 || residualRefraction.Cylinder < -0.5
            }">
            {{ 'general.spherical' | translate }}
            {{ residualRefraction.Sphere | numberFormat: '{"parameterType":"POW"}' }}
            {{ 'general.cilinder' | translate }}
            {{ residualRefraction.Cylinder | numberFormat: '{"parameterType":"CYL"}' }}
            {{ 'general.axis' | translate }}
            {{ residualRefraction.Ax | numberFormat: '{"parameterType":"AX"}' }}
        </label>
    </div>
</div>
