<app-environment />

<div *ngIf="isHome" class="mainBackground h-100 mx-auto d-flex flex-column">
    <div class="headerBackground"></div>
    <div class="landingBackground flex-grow-1"></div>
</div>

<main-header />

<main class="flex-fill d-flex flex-column text-left main" [ngClass]="isHome ? 'home' : 'main'">
    <router-outlet></router-outlet>
</main>

<footer class="fixed-bottom d-flex flex-column justify-content-center">
    <main-footer />
</footer>

<app-product-tour />
