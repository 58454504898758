import { Input, Optional, Host, SkipSelf, Directive, Output, EventEmitter, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, AbstractControl, ControlContainer } from '@angular/forms';
import { Util } from '@app/shared/helpers/utility.helper';

@Directive()
export class BaseControl implements ControlValueAccessor {
    @Input() formControlName: string | number;
    @Input() id = '';
    @Input() label = '';
    @Input() labelParams = {};
    @Input() info: string;
    @Input() infoPosition: 'left' | 'right' = 'left';
    @Input() warning: string;
    @Input() warningPosition: 'left' | 'right' = 'left';
    @Input() labelWidth = 4; // can be set to 1 - 12, control width will size accordingly
    @Input() breakpoint: '' | 'sm' | 'md' | 'lg' | 'xl' = 'md';
    @Input() formControlOnly = false;
    @Input() isDisabled = false;
    @Input() controlHasFormError: boolean; // Indicates that the control has a dependency error on another control in the form
    @Input() customLabelControlClass: string;
    @Input() customControlClass: string;
    @Input() markLabelAsTouched = false; // Only specific controls need to be marked as touched
    @Input() defaultToZeroPointZero = false;
    @Input() customClass = '';

    @Output() blur = new EventEmitter();

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public control: AbstractControl;
    public value: any;
    public hasFocus = false;

    public labelClass: string[] = [];
    public controlClass: string[] = [];
    public isRequired: boolean = null;
    private readonly destroyRef = inject(DestroyRef);

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        public controlContainer: ControlContainer,
    ) {}

    ngOnInit() {
        this.control = this.controlContainer.control.get(this.formControlName.toString());
        this.value = this.control.value;

        this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.refreshBaseClasses();
            this.refreshSubClasses();
        });

        this.refreshBaseClasses();
        this.refreshSubClasses();
    }

    ngOnChanges() {
        this.refreshBaseClasses();
        this.refreshSubClasses();
    }

    // Call when value has changed programmatically
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public onChange(_: any) {
        // Do nothing
    }

    public onTouched(_?: any) {
        // Do nothing
    }

    public onBlur(_?: any): void {
        this.blur.emit(_);
    }

    // Model -> View changes
    public writeValue(obj: unknown): void {
        this.value = obj;
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    refreshBaseClasses(): void {
        if (this.control) {
            this.labelClass = this.getLabelClass();
            this.controlClass = this.getControlClass();
            this.isRequired = this.getIsRequired();
        }
    }

    refreshSubClasses(): void {
        // Do nothing, override in sub class
    }

    getLabelClass(): string[] {
        const result = [];

        if (this.control && (this.control.errors || this.controlHasFormError)) {
            result.push('error');
        }

        if (this.breakpoint) {
            result.push(`col-${this.breakpoint}-${this.labelWidth}`);
        } else {
            result.push(`col-${this.labelWidth}`);
        }

        if (this.customLabelControlClass) {
            result.push(this.customLabelControlClass);
        }

        if (this.markLabelAsTouched && this.control.touched) {
            result.push('touched'); // Add custom touched class to the label
        }

        return result;
    }

    getControlClass(): string[] {
        const result = [];

        if (this.customControlClass) {
            result.push(this.customControlClass);
            return result;
        }

        if (this.breakpoint) {
            result.push(`col-${this.breakpoint}-${12 - this.labelWidth}`);
        } else {
            result.push(`col-${12 - this.labelWidth}`);
        }

        return result;
    }

    getIsRequired(): boolean {
        const result = Util.hasRequiredField(this.control);
        return result ? true : null;
    }

    setToZeroPointZero(): void {
        if (this.defaultToZeroPointZero && !this.value) {
            this.value = 0.0;
        }
    }
}
