import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderService } from '@app/core/services/api/order.service';
import { of } from 'rxjs';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { Order } from '../shared/models';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { Util } from '@app/shared/helpers/utility.helper';
import { finalize } from 'rxjs/operators';
import { FeatureCategories, Features } from '@app/shared/enums';

@Component({
    selector: 'lens-order-sent',
    templateUrl: 'lens-order-sent.component.html',
    styleUrls: ['lens-order-sent.component.scss'],
})
export class LensOrderSentComponent implements OnInit {
    @ViewChild('orderVideo') private orderVideo: ElementRef;
    Features = Features;
    FeatureCategories = FeatureCategories;
    orderId: number;
    order: Order;
    isCreditOrder = false;
    pageHeader: string;
    processingInfo: string;
    progressReviewInfo: string;
    goToStartScreenInfo = 'ordersent.goToStartScreenInfo';

    public play = true;

    constructor(
        public orderService: OrderService,
        private route: ActivatedRoute,
        public router: Router,
        public loaderService: LoaderService,
        public appState: AppStateService,
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((routeParams) => {
            if (isNaN(routeParams['id'])) {
                this.router.navigate(['/']).then();
                return of();
            }

            this.orderId = +routeParams['id'];
            this.orderService.getById(this.orderId).subscribe((result) => {
                this.order = result;
                this.isCreditOrder = this.order.IsCreditOrderCreated;

                if (this.order.SkipOrderEvaluation === false) {
                    this.pageHeader = 'ordersent.pageHeaderSupport';
                    this.processingInfo = 'ordersent.processingInfoSupport';
                    this.progressReviewInfo = 'ordersent.progressReviewInfoSupport';
                } else {
                    this.pageHeader = 'ordersent.pageHeader';
                    this.processingInfo = 'ordersent.processingInfo';
                    this.progressReviewInfo = 'ordersent.progressReviewInfo';
                }
            });
        });
    }

    downloadPdf() {
        this.loaderService.show();
        this.orderService
            .downloadPdf(this.orderId, navigator.language)
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe((file) => {
                Util.openBlobInBrowser(file, file.name);
            });
    }

    home(): void {
        this.router.navigate(['/']).then();
    }

    changePlayback(): void {
        if (this.play) {
            this.orderVideo.nativeElement.pause();
            this.play = false;
        } else {
            this.orderVideo.nativeElement.play();
            this.play = true;
        }
    }

    gotoOrderStatus(): void {
        this.router.navigate(['/orderstatus']).then();
    }
}
