<ng-container>
    <div class="mt-4 px-xl-4">
        <div class="mb-2 title-border-bottom">
            <h3>{{ 'testutilities.recent-emails' | translate }}</h3>
        </div>

        <table class="table mb-1 email-messages">
            <thead>
                <tr>
                    <th class="col-1">
                        {{ 'testutilities.email.date' | translate }}
                    </th>
                    <th class="col-1">
                        {{ 'testutilities.email.from' | translate }}
                    </th>
                    <th class="col-1">
                        {{ 'testutilities.email.to' | translate }}
                    </th>
                    <th>{{ 'testutilities.email.subject' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let msg of emailMessages" (click)="showContents(msg)">
                    <td>
                        {{ getLocalTime(msg.CreatedOn) | mpcdate }}
                        {{ getLocalTime(msg.CreatedOn) | date: 'HH:mm' }}
                    </td>
                    <td>{{ msg.From }}</td>
                    <td>{{ msg.To }}</td>
                    <td>{{ msg.Subject }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-container>
