import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'mpctime',
})
export class TimePipeProxy implements PipeTransform {
    constructor(private _translateService: TranslateService) {}

    public transform(value: string | number | Date, pattern = 'shortTime'): string {
        const currentLang = this._translateService.currentLang;
        const ngPipe = new DatePipe(currentLang);

        if (value && value.toString() !== 'Invalid Date') {
            return ngPipe.transform(value, pattern);
        }

        return '';
    }
}
