import { Attachment } from './attachment.model';

export class Note {
    Id: number;
    UserId: number;
    UserRoleId: number;
    Message: string;
    IsCreatedOn: Date;
    ClientId: number;
    OrderId: number;
    OpticianId: number;
    IsReadByOptician: boolean;
    IsReadByPs: boolean;

    // readonly
    UserName: string;
    Name: string;
}

export class NoteMessage {
    attachments: Attachment[];

    constructor(
        public content: string,
        public createdOn: Date,
        public createdBy: string,
        public messageOrigin: NoteMessageOrigin,
    ) {
        this.attachments = [];
    }

    isReceived(): boolean {
        return this.messageOrigin === NoteMessageOrigin.Received;
    }

    isSent(): boolean {
        return this.messageOrigin === NoteMessageOrigin.Sent;
    }

    hasAttachments(): boolean {
        return this.attachments.length > 0;
    }
}

export enum NoteMessageOrigin {
    Sent,
    Received,
}
