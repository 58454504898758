export enum ProductGroupCodes {
    SOFT = '27',
    SCLERAL = 'SCLERAL',
    KC = 'KC',
    EMIN = 'EMIN',
    SCLERALEASY = 'SCLERALEASY',
    SCLERALMINI = 'SCLERALMINI',
    LENSES = '1',
    MEDICALLENSES = '26',
    SOLUTIONS = '2',
    ACCESSOIRES = '3',
    MARKETING = '31',
    BYOCOMFORT = '4',
    BYOPROMEDICS = '5',
    DREAMLITE = '24',
    BYOPREMIUM = 'BYOPREMIUM',
    SENSOSELECT = '9',
}

export class ProductGroups {
    static isScleralProductGroup(productGroupCode: string) {
        return (
            productGroupCode === ProductGroupCodes.SCLERAL ||
            productGroupCode === ProductGroupCodes.SCLERALEASY ||
            productGroupCode === ProductGroupCodes.SCLERALMINI
        );
    }
}
