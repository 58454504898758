import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthenticatedUser } from '@app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@app/core/services/api/authentication.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { AlertService } from '@app/shared/appservices/alert.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { BsModalService, ModalOptions, BsModalRef } from 'ngx-bootstrap/modal';
import { LicenseDialogComponent } from '@app/core/components/license-dialog/license-dialog.component';

@Injectable()
export class LicenseAuthGuard {
    get CurrentAuthenticatedUser(): AuthenticatedUser {
        return this.appState.authenticatedUser;
    }

    private modalRef: BsModalRef;
    private licenseDialogShown = false;

    constructor(
        public router: Router,
        public appState: AppStateService,
        public alertService: AlertService,
        public translateService: TranslateService,
        public authenticationService: AuthenticationService,
        public modalService: BsModalService,
        public loaderService: LoaderService,
    ) {}

    canActivate(): Observable<boolean> {
        return this.appState.getLicenseAgreements().pipe(switchMap(() => this.checkDatasheets()));
    }

    checkDatasheets(): Observable<boolean> {
        const datasheetApproved =
            (this.appState.availableDatasheets.licenseAgreement &&
                !this.appState.authenticatedUser.LicenseAgreementApprovedOn) ||
            (this.appState.availableDatasheets.userRegulations &&
                !this.appState.authenticatedUser.UserRegulationsApprovedOn) ||
            (this.appState.availableDatasheets.privacyPolicy &&
                !this.appState.authenticatedUser.VisavyPlatformPrivacyPolicyApprovedOn);

        if (
            this.appState.authenticatedUser &&
            !this.appState.authenticatedUser.IsAdministrator &&
            !this.licenseDialogShown &&
            datasheetApproved
        ) {
            const options: ModalOptions = {
                initialState: {},
                class: 'licensedialog',
            };

            this.modalRef = this.modalService.show(LicenseDialogComponent, options);
            (this.modalRef.content as LicenseDialogComponent).onClose$.subscribe((result) => {
                this.licenseDialogShown = false;

                if (!result) {
                    this.router.navigate(['login']);
                }

                return of(result);
            });
        }

        return of(true);
    }
}
