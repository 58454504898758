import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UploadFile, UploadInput, UploaderOptions, UploadOutput, UploadStatus } from 'ngx-uploader';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { AppStateService } from '@app/shared/appservices/appState.service';

@Component({
    selector: 'file-upload',
    templateUrl: 'fileupload.component.html',
    styleUrls: ['fileupload.component.scss'],
})
export class FileUploadComponent {
    @Input() showUploadButton = false;
    @Input() downloadAttachedFile = false;
    @Output() uploadButtonPressed: EventEmitter<boolean> = new EventEmitter();
    @Output() uploadResult = new EventEmitter<string>();
    @Output() uploadComplete = new EventEmitter<UploadFile>();
    @Output() onOutput = new EventEmitter<UploadOutput>();

    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    uploadStatus: EventEmitter<FileUploadStatus>;

    fileUploadStatus = UploadStatus;

    options: UploaderOptions;
    isUploading = false;
    addingToQueue = false;
    dropped = false;
    numberOfSuccessfulUploads = 0;

    constructor(
        public appConfig: AppConfigService,
        public appState: AppStateService,
    ) {
        this.options = { concurrency: 2, allowedContentTypes: ['*'] };
        this.files = [];
        this.uploadInput = new EventEmitter<UploadInput>();
        this.uploadStatus = new EventEmitter<FileUploadStatus>();
    }

    onUploadOutput(output: UploadOutput): void {
        if (output.type === 'dragOver') {
            this.addingToQueue = true;
        } else if (output.type === 'dragOut') {
            if (!this.dropped) {
                this.addingToQueue = false;
            }
        } else if (output.type === 'drop') {
            this.addingToQueue = true;
            this.dropped = true;
        } else if (output.type === 'allAddedToQueue') {
            this.addingToQueue = false;
        } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
            this.files.push(output.file);
        } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            const index = this.files.findIndex(
                (file) => typeof output.file !== 'undefined' && file.id === output.file.id,
            );
            this.files[index] = output.file;
        } else if (output.type === 'removed') {
            this.files = this.files.filter((file: UploadFile) => file !== output.file);

            if (this.hasNoPendingFiles() && this.hasNoFailedFiles()) {
                this.files = [];
            }
        } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
            console.warn(output.file.name + ' rejected');
        } else if (output.type === 'done' && output.file.responseStatus === 200) {
            if (this.downloadAttachedFile) {
                this.verifyAndDownloadAttachment(output.file);
            }
            this.uploadComplete.emit(output.file);
        }

        if (this.isUploading && this.hasNoPendingFiles()) {
            this.onOutput.emit(output);
            this.uploadResult.emit(`${this.numberOfSuccessfulUploads} / ${this.files.length} successfully uploaded`);

            if (this.hasNoFailedFiles()) {
                this.files = [];
            }

            this.isUploading = false;
            this.addingToQueue = false;
            this.uploadStatus.emit(FileUploadStatus.Completed);
            this.dropped = false;
            this.numberOfSuccessfulUploads = 0;
        }
    }

    verifyAndDownloadAttachment(outputFile: UploadFile): void {
        let blobResponse: Blob;

        if (outputFile.response instanceof Blob) {
            blobResponse = outputFile.response as Blob;
        } else if (typeof outputFile.response === 'string') {
            blobResponse = new Blob([outputFile.response], { type: 'text/csv;charset=utf-8' });
        }

        if (blobResponse.size > 0) {
            let fileName = 'failed-Imports.csv';
            const uploadedFileNameWithoutExtension = outputFile.name.split('.')[0];
            if (uploadedFileNameWithoutExtension.length > 0) {
                fileName = uploadedFileNameWithoutExtension + '_Failed-Imports.csv';
            }
            this.downloadAttachment(blobResponse, fileName);
        } else {
            this.numberOfSuccessfulUploads++;
        }
    }

    downloadAttachment(blob: Blob, fileName: string): void {
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        link.download = fileName;

        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            }),
        );

        setTimeout(function () {
            // For Firefox, it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
        }, 100);
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    startUpload(formData: any, apiEndpoint: string): void {
        if (!this.isUploading) {
            this.isUploading = true;
            this.uploadStatus.emit(FileUploadStatus.Started);

            const event: UploadInput = {
                type: 'uploadAll',
                url: this.appConfig.apiEndpoint + apiEndpoint,
                method: 'POST',
                headers: {
                    Authorization: this.appState.authenticatedUser.AccessToken,
                },
                data: formData,
            };

            this.uploadInput.emit(event);
        }
    }

    cancelUpload(id: string): void {
        this.uploadInput.emit({ type: 'cancel', id: id });
    }

    removeFile(id: string): void {
        this.uploadInput.emit({ type: 'remove', id: id });
    }

    removeAllFiles(): void {
        this.uploadInput.emit({ type: 'removeAll' });
    }

    formatFileSize(bytes: number, decimals: number) {
        if (bytes === 0) {
            return '0 Bytes';
        }

        const k = 1000,
            dm = decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    uploadFiles() {
        this.uploadButtonPressed.emit(true);
    }

    hasNoPendingFiles() {
        return this.files.filter((file) => file.progress.status !== UploadStatus.Done).length === 0;
    }

    hasNoFailedFiles() {
        return (
            this.files.filter(
                (file) =>
                    file.progress.status === UploadStatus.Done &&
                    !(file.responseStatus === null || file.responseStatus === 200),
            ).length === 0
        );
    }
}

export enum FileUploadStatus {
    Started = 1,
    Completed = 2,
}
