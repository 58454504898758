import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductTourComponent } from '@app/features/product-tour/components/product-tour.component';
import { ProductTourDialogComponent } from '@app/features/product-tour/components/product-tour-dialog/product-tour-dialog.component';
import { SharedModule } from '@app/shared/shared.module';
import { ProductTourService } from '@app/features/product-tour/services/product-tour.service';

@NgModule({
    imports: [CommonModule, SharedModule],
    declarations: [ProductTourComponent, ProductTourDialogComponent],
    providers: [ProductTourService],
    exports: [ProductTourComponent],
})
export class ProductTourModule {}
