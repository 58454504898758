<div class="form-group row" *ngIf="!formControlOnly">
    <label for="{{ id }}" class="col-form-label my-auto" [ngClass]="labelClass">
        <i
            *ngIf="info && infoPosition === 'left'"
            class="fa fa-question-circle"
            popover="{{ info }}"
            triggers="mouseenter:mouseleave"></i>
        {{ label | translate: labelParams }}
        <i
            *ngIf="info && infoPosition === 'right'"
            class="fa fa-question-circle"
            popover="{{ info }}"
            triggers="mouseenter:mouseleave"></i>
    </label>
    <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
</div>

<div *ngIf="formControlOnly">
    <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
</div>

<ng-template #controlTemplate>
    <div class="col" [ngClass]="{ 'd-flex align-items-center': direction === 'horizontal' }">
        <ng-container *ngFor="let o of _options; let i = index">
            <div class="form-check" [ngClass]="innerControlClass">
                <input
                    type="checkbox"
                    id="{{ id }}-{{ i }}"
                    name="{{ id }}-group"
                    class="form-check-input"
                    [checked]="o.Selected"
                    [value]="o.Id"
                    (change)="onSelect(o)"
                    [disabled]="isDisabled" />
                <label id="labelfor-{{ id }}-{{ i }}" class="form-check-label" for="{{ id }}-{{ i }}">
                    {{ o.Name | translate }}
                </label>
            </div>
        </ng-container>
    </div>
</ng-template>
