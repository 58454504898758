import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ListOption } from '@app/shared/models/listOption.model';
import { GlassFittingFitGlassComponent } from '@app/fitting/fitting-fitglass.component';
import { InputNumberRange } from '@app/shared/models/input-number-range.model';
import { TranslateService } from '@ngx-translate/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { AlertService } from '@app/shared/appservices/alert.service';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { BevelTypes, Treatments, Materials } from '@app/shared/enums';
import { ListSelectOption } from '@app/shared/models';

@Component({
    selector: 'glassframe',
    templateUrl: 'glassframe.component.html',
})
export class GlassFrameComponent implements OnInit, OnDestroy {
    @Input() glassFittingFitGlassComponent: GlassFittingFitGlassComponent;

    public glassFrameFormGroup: UntypedFormGroup;

    public frameMaterials: ListOption[];
    public glassTreatments: ListOption[];
    public tracers: ListSelectOption[] = [];
    public bevelTypes: ListOption[];

    connection: HubConnection;

    traceBrowse: boolean;
    traceUrl: string;
    traceName: string;
    hasTraceFile = false;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly translateService: TranslateService,
        private appState: AppStateService,
        private alertService: AlertService,
        private appConfigService: AppConfigService,
    ) {
        this.connection = new HubConnectionBuilder()
            .withUrl(
                this.appConfigService.appConfig.izyShapeSignalRServerURL +
                    '/IZyShapeHub?LicenceKey=' +
                    this.appState.currentOptician.IzyShapeLicenseKey +
                    '&SoftwareName=WebBrowser',
            )
            .withAutomaticReconnect()
            .build();
    }

    ngOnInit() {
        this.setGlassTreatments();
        this.setDefaultTreatment();
        this.setFrameMaterials();
        this.setBevelTypes();
        this.createForm();
    }

    async ngOnDestroy() {
        await this.disconnect();
    }

    createForm(): void {
        this.glassFrameFormGroup = this.fb.group({
            glassTreatment: [this.getCurrentGlassTreatmentId(), [Validators.required]],
            frameBrand: [this.getCurrentFrameBrand()],
            frameModel: [this.getCurrentFrameModel()],
            frameMaterial: [this.getCurrentFrameMaterialId()],
            frameColor: [this.getCurrentFrameColor()],
            pupilDistanceLeft: [this.getCurrentPupilDistanceLeft()],
            pupilDistanceRight: [this.getCurrentPupilDistanceRight()],
            ocularCenterLeft: [this.getCurrentOcularCenterLeft()],
            ocularCenterRight: [this.getCurrentOcularCenterRight()],
            frameSizeBoxA: [this.getCurrentFrameSizeBoxA()],
            frameSizeBoxB: [this.getCurrentFrameSizeBoxB()],
            frameDblDimension: [this.getCurrentFrameDblDimension()],
            bevelType: [this.getCurrentBevelTypeId()],
            edgePolish: [this.getCurrentEdgePolish()],
            parameters: this.fb.array([]),
            traceBrowse: false,
            tracerName: [null],
            traceFile: [null],
            frameTracerSizeBoxA: [this.getCurrentFrameSizeBoxA()],
            frameTracerSizeBoxB: [this.getCurrentFrameSizeBoxB()],
            frameTracerDblDimension: [this.getCurrentFrameDblDimension()],
        });
    }

    get loading(): boolean {
        return this.glassFittingFitGlassComponent.loading;
    }

    get formControls() {
        return this.glassFrameFormGroup.controls;
    }

    showFrameControls(): boolean {
        const treatmentId = Number(this.formControls['glassTreatment'].value);

        return treatmentId !== Treatments.none;
    }

    showTraceControls(): boolean {
        return this.hasTraceFile && (this.isTraceFileBrowseAvailable() || this.traceUrl !== '');
    }

    isManualTraceUploadAvailable(): boolean {
        return this.connection.state === HubConnectionState.Connected;
    }

    isTraceFileBrowseAvailable(): boolean {
        return this.isManualTraceUploadAvailable() && this.traceBrowse === true;
    }

    setFrameMaterials(): void {
        this.frameMaterials = [
            new ListOption(Materials.Metal, this.translateService.instant('fitglass.metal'), 'Metal'),
            new ListOption(Materials.Plastic, this.translateService.instant('fitglass.plastic'), 'Plastic'),
            new ListOption(Materials.Nylor, this.translateService.instant('fitglass.nylor'), 'NylonThread'),
        ];
    }

    getFrameMaterials() {
        return this.frameMaterials;
    }

    setBevelTypes(): void {
        this.bevelTypes = [
            new ListOption(
                BevelTypes.OneThirdTwoThirds,
                this.translateService.instant('fitglass.oneThirdTwoThirds'),
                'PercentBack',
            ),
            new ListOption(BevelTypes.FiftyFifty, this.translateService.instant('fitglass.fiftyFifty'), 'PercentBack'),
            new ListOption(BevelTypes.Front, this.translateService.instant('general.front'), 'FollowFront'),
            new ListOption(BevelTypes.Automatic, this.translateService.instant('general.automatic'), 'Optimized'),
        ];
    }

    getBevelTypes() {
        return this.bevelTypes;
    }

    setGlassTreatments(): void {
        this.glassTreatments = [
            new ListOption(Treatments.none, 'general.none', 'None'),
            new ListOption(
                Treatments.remoteEdgingAndFitting,
                'fitglass.remoteEdgingAndFitting',
                'Remote edging and fitting',
            ),
        ];
    }

    getGlassTreatments() {
        return this.glassTreatments;
    }

    getFrameMaterialIdByName(materialName: string): number {
        const frameMaterial = this.frameMaterials.find((m) => m.Name === materialName);
        if (frameMaterial) {
            return frameMaterial.Id;
        }
    }

    getFrameMaterialIdByCode(materialCode: string): number {
        const frameMaterial = this.frameMaterials.find((m) => m.Code === materialCode);
        if (frameMaterial) {
            return frameMaterial.Id;
        }
    }

    getFrameMaterialNameById(materialId: number): string {
        const frameMaterial = this.frameMaterials.find((m) => m.Id === materialId);
        if (frameMaterial) {
            return frameMaterial.Name;
        }
    }

    getFrameMaterialCodeById(materialId: number): string {
        const frameMaterial = this.frameMaterials.find((m) => m.Id === materialId);
        if (frameMaterial) {
            return frameMaterial.Code;
        }
    }

    getBevelTypeIdByCode(bevelTypeCode: string): number {
        const bevelType = this.bevelTypes.find((b) => b.Code === bevelTypeCode);
        if (bevelType) {
            return bevelType.Id;
        }
    }

    getBevelTypeCodeById(bevelTypeId: number): string {
        const bevelType = this.bevelTypes.find((b) => b.Id === bevelTypeId);
        if (bevelType) {
            return bevelType.Code;
        }
    }

    getBevelPositionForBevelType(bevelType: BevelTypes): number {
        if (!bevelType) {
            return null;
        }

        switch (bevelType) {
            case BevelTypes.OneThirdTwoThirds:
                return 70;
            default:
                return 50;
        }
    }

    getTreatmentIdByName(treatmentName: string): number {
        const glassTreatment = this.glassTreatments.find((t) => t.Name === treatmentName);
        if (glassTreatment) {
            return glassTreatment.Id;
        }
    }

    getTreatmentIdByCode(treatmentCode: string): number {
        const glassTreatment = this.glassTreatments.find((t) => t.Code === treatmentCode);
        if (glassTreatment) {
            return glassTreatment.Id;
        }
    }

    getTreatmentNameById(treatmentId: number): string {
        const glassTreatment = this.glassTreatments.find((m) => m.Id === treatmentId);
        if (glassTreatment) {
            return glassTreatment.Name;
        }
    }

    getTreatmentCodeById(treatmentId: number): string {
        const glassTreatment = this.glassTreatments.find((m) => m.Id === treatmentId);
        if (glassTreatment) {
            return glassTreatment.Code;
        }
    }

    getCurrentGlassTreatmentId(): number {
        const treatmentId = this.glassFittingFitGlassComponent.glassTreatment;

        if (treatmentId) {
            return treatmentId;
        } else {
            return Treatments.none;
        }
    }

    getCurrentFrameBrand(): string {
        return this.glassFittingFitGlassComponent.frameBrand;
    }

    getCurrentFrameModel(): string {
        return this.glassFittingFitGlassComponent.frameModel;
    }

    getCurrentFrameMaterialId(): number {
        return this.getFrameMaterialIdByCode(this.glassFittingFitGlassComponent.frameMaterial);
    }

    getCurrentBevelTypeId(): number {
        return this.getBevelTypeIdByCode(this.glassFittingFitGlassComponent.bevelType);
    }

    getCurrentBevelTypeValue(): string {
        return this.glassFittingFitGlassComponent.bevelType;
    }

    getCurrentBevelPosition(): number {
        return this.glassFittingFitGlassComponent.bevelPosition;
    }

    getCurrentFrameColor(): string {
        return this.glassFittingFitGlassComponent.frameColor;
    }

    getCurrentPupilDistanceLeft(): number {
        return this.glassFittingFitGlassComponent.pupilDistanceLeft;
    }

    getCurrentPupilDistanceRight(): number {
        return this.glassFittingFitGlassComponent.pupilDistanceRight;
    }

    getCurrentOcularCenterLeft(): number {
        return this.glassFittingFitGlassComponent.ocularCenterLeft;
    }

    getCurrentOcularCenterRight(): number {
        return this.glassFittingFitGlassComponent.ocularCenterRight;
    }

    getCurrentFrameSizeBoxA(): number {
        return this.glassFittingFitGlassComponent.frameSizeBoxA;
    }

    getCurrentFrameSizeBoxB(): number {
        return this.glassFittingFitGlassComponent.frameSizeBoxB;
    }

    getCurrentFrameDblDimension(): number {
        return this.glassFittingFitGlassComponent.frameDblDimension;
    }

    getCurrentEdgePolish(): boolean {
        return this.glassFittingFitGlassComponent.edgePolish;
    }

    getCurrentFrameTracerSizeBoxA(): number {
        return this.glassFittingFitGlassComponent.frameTracerSizeBoxA;
    }

    getCurrentFrameTracerSizeBoxB(): number {
        return this.glassFittingFitGlassComponent.frameTracerSizeBoxB;
    }

    getCurrentFrameTracerDblDimension(): number {
        return this.glassFittingFitGlassComponent.frameTracerDblDimension;
    }

    async changeGlassTreatment(): Promise<void> {
        const treatmentId = Number(this.formControls['glassTreatment'].value);

        if (treatmentId) {
            this.glassFittingFitGlassComponent.changeGlassTreatment(treatmentId);
            if (treatmentId !== Treatments.remoteEdging) {
                await this.disconnect();
            }
        } else {
            this.setDefaultTreatment();
        }

        this.setControlsAfterTreatmentChange();
    }

    changeFrameBrand(): void {
        const frameBrand = this.formControls['frameBrand'].value;

        this.glassFittingFitGlassComponent.changeFrameBrand(frameBrand);
    }

    changeFrameModel(): void {
        const frameModel = this.formControls['frameModel'].value;

        this.glassFittingFitGlassComponent.changeFrameModel(frameModel);
    }

    changeFrameMaterial(): void {
        const frameMaterialId = Number(this.formControls['frameMaterial'].value);

        const frameMaterialCode = this.getFrameMaterialCodeById(frameMaterialId);

        this.glassFittingFitGlassComponent.changeFrameMaterial(frameMaterialCode);
    }

    changeFrameColor(): void {
        const frameColor = this.formControls['frameColor'].value;

        this.glassFittingFitGlassComponent.changeFrameColor(frameColor);
    }

    changePupilDistanceLeft(): void {
        const pupilDistanceLeft = Number(this.formControls['pupilDistanceLeft'].value);

        this.glassFittingFitGlassComponent.changePupilDistanceLeft(pupilDistanceLeft);
    }

    changePupilDistanceRight(): void {
        const pupilDistanceRight = Number(this.formControls['pupilDistanceRight'].value);

        this.glassFittingFitGlassComponent.changePupilDistanceRight(pupilDistanceRight);
    }

    changeOcularCenterLeft(): void {
        const ocularCenterLeft = Number(this.formControls['ocularCenterLeft'].value);

        this.glassFittingFitGlassComponent.changeOcularCenterLeft(ocularCenterLeft);
    }

    changeOcularCenterRight(): void {
        const ocularCenterRight = Number(this.formControls['ocularCenterRight'].value);

        this.glassFittingFitGlassComponent.changeOcularCenterRight(ocularCenterRight);
    }

    changeframeSizeBoxA(): void {
        const frameSizeBoxA = Number(this.formControls['frameSizeBoxA'].value);

        this.glassFittingFitGlassComponent.changeFrameSizeBoxA(frameSizeBoxA);
    }

    changeframeSizeBoxB(): void {
        const frameSizeBoxB = Number(this.formControls['frameSizeBoxB'].value);

        this.glassFittingFitGlassComponent.changeFrameSizeBoxB(frameSizeBoxB);
    }

    changeframeDblDimension(): void {
        const frameDblDimension = Number(this.formControls['frameDblDimension'].value);

        this.glassFittingFitGlassComponent.changeFrameDblDimension(frameDblDimension);
    }

    changeBevelType(): void {
        const bevelTypeId = Number(this.formControls['bevelType'].value);

        const bevelTypeCode = this.getBevelTypeCodeById(bevelTypeId);

        this.glassFittingFitGlassComponent.changeBevelType(bevelTypeId, bevelTypeCode);

        const bevelPosition = this.getBevelPositionForBevelType(bevelTypeId);

        this.changeBevelPosition(bevelPosition);
    }

    changeBevelPosition(bevelPosition: number): void {
        this.glassFittingFitGlassComponent.changeBevelPosition(bevelPosition);
    }

    changeEdgePolish(): void {
        const edgePolish = !!this.formControls['edgePolish'].value;

        this.glassFittingFitGlassComponent.changeEdgePolish(edgePolish);
    }

    changeTracerName(): void {
        const tracerName = this.formControls['tracerName'].value;

        this.glassFittingFitGlassComponent.changeTracerName(tracerName);
    }

    changeFrameTracerSizeBoxA(): void {
        const frameTracerSizeBoxA = Number(this.formControls['frameTracerSizeBoxA'].value);

        this.glassFittingFitGlassComponent.changeFrameTracerSizeBoxA(frameTracerSizeBoxA);
    }

    changeFrameTracerSizeBoxB(): void {
        const frameTracerSizeBoxB = Number(this.formControls['frameTracerSizeBoxB'].value);

        this.glassFittingFitGlassComponent.changeFrameTracerSizeBoxB(frameTracerSizeBoxB);
    }

    changeFrameTracerDblDimension(): void {
        const frameTracerDblDimension = Number(this.formControls['frameTracerDblDimension'].value);

        this.glassFittingFitGlassComponent.changeFrameTracerDblDimension(frameTracerDblDimension);
    }

    get pupilDistanceRange(): InputNumberRange {
        return new InputNumberRange(20, 38, 0.1, 29);
    }

    get ocularCenterRange(): InputNumberRange {
        return new InputNumberRange(10, 45, 0.1, 28);
    }

    get frameSizeBoxARange(): InputNumberRange {
        return new InputNumberRange(39, 58, 0.1, 49);
    }

    get frameSizeBoxBRange(): InputNumberRange {
        return new InputNumberRange(25, 55, 0.1, 40);
    }

    get frameDblDimensionRange(): InputNumberRange {
        return new InputNumberRange(10, 24, 0.1, 17);
    }

    get traceSizeBoxARange(): InputNumberRange {
        return new InputNumberRange(35, 65, 0.1, 50);
    }

    get traceSizeBoxBRange(): InputNumberRange {
        return new InputNumberRange(35, 65, 0.1, 50);
    }

    get traceDblDimensionRange(): InputNumberRange {
        return new InputNumberRange(5, 40, 0.1, 23);
    }

    changeManualTraceUpload(): void {
        this.traceBrowse = !!this.formControls['traceBrowse'].value;

        this.resetTraceData(true);

        if (this.tracers.length === 0) {
            this.connection.on('GetIZyShapeTracersResponse', (getIZyShapeTracersResponse) => {
                const numberOfTracers = getIZyShapeTracersResponse.LstIZyShapeLightTracerModel.length;
                if (numberOfTracers > 0) {
                    for (let i = 0; i < numberOfTracers; i++) {
                        this.tracers.push({
                            Id: getIZyShapeTracersResponse.LstIZyShapeLightTracerModel[i].Name,
                            Code: getIZyShapeTracersResponse.LstIZyShapeLightTracerModel[i].Name,
                            Name: getIZyShapeTracersResponse.LstIZyShapeLightTracerModel[i].Name,
                            Selected: getIZyShapeTracersResponse.LstIZyShapeLightTracerModel.length === 1,
                        });
                    }
                } else {
                    this.alertService.error(this.translateService.instant('error.izyShapeNoTracers'));
                    this.traceBrowse = false;
                    this.formControls['traceBrowse'].patchValue(false);
                }
            });

            this.connection.invoke('GetIZyShapeTracers').then();
        }
    }

    setControlsAfterTreatmentChange() {
        if (this.treatmentNoneSelected()) {
            this.resetAllControls();
        } else if (this.remoteEdgingAndFittingSelected()) {
            this.resetTraceControls();
        }
        this.setControlsValidation();
        this.updateValueAndValidityForAllControls();
    }

    setControlsValidation() {
        if (!this.treatmentNoneSelected()) {
            if (this.remoteEdgingSelected()) {
                this.setValidationForRemoteEdging();
            }
            this.setGeneralValidation();
        }
    }

    setValidationForRemoteEdging() {
        this.formControls['traceFile'].setValidators([Validators.required]);
        this.formControls['tracerName'].setValidators([Validators.required]);
        this.formControls['frameTracerSizeBoxA'].setValidators([Validators.required]);
        this.formControls['frameTracerSizeBoxB'].setValidators([Validators.required]);
        this.formControls['frameTracerDblDimension'].setValidators([Validators.required]);
    }

    setGeneralValidation() {
        this.formControls['frameMaterial'].setValidators([Validators.required]);
        this.formControls['frameBrand'].setValidators([Validators.required]);
        this.formControls['frameModel'].setValidators([Validators.required]);
        this.formControls['frameColor'].setValidators([Validators.required]);
        this.formControls['frameSizeBoxA'].setValidators([Validators.required]);
        this.formControls['frameSizeBoxB'].setValidators([Validators.required]);
        this.formControls['frameDblDimension'].setValidators([Validators.required]);
        this.formControls['bevelType'].setValidators([Validators.required]);
        this.formControls['pupilDistanceLeft'].setValidators([Validators.required]);
        this.formControls['pupilDistanceRight'].setValidators([Validators.required]);
        this.formControls['ocularCenterLeft'].setValidators([Validators.required]);
        this.formControls['ocularCenterRight'].setValidators([Validators.required]);
    }

    updateValueAndValidityForAllControls() {
        this.formControls['frameBrand'].updateValueAndValidity();
        this.formControls['frameModel'].updateValueAndValidity();
        this.formControls['frameMaterial'].updateValueAndValidity();
        this.formControls['frameColor'].updateValueAndValidity();
        this.formControls['pupilDistanceLeft'].updateValueAndValidity();
        this.formControls['pupilDistanceRight'].updateValueAndValidity();
        this.formControls['ocularCenterLeft'].updateValueAndValidity();
        this.formControls['ocularCenterRight'].updateValueAndValidity();
        this.formControls['frameSizeBoxA'].updateValueAndValidity();
        this.formControls['frameSizeBoxB'].updateValueAndValidity();
        this.formControls['frameDblDimension'].updateValueAndValidity();
        this.formControls['bevelType'].updateValueAndValidity();
        this.formControls['edgePolish'].updateValueAndValidity();

        this.updateValueAndValidityForTraceControls();
    }

    updateValueAndValidityForTraceControls() {
        this.formControls['traceFile'].updateValueAndValidity();
        this.formControls['tracerName'].updateValueAndValidity();
        this.formControls['frameTracerSizeBoxA'].updateValueAndValidity();
        this.formControls['frameTracerSizeBoxB'].updateValueAndValidity();
        this.formControls['frameTracerDblDimension'].updateValueAndValidity();
    }

    resetAllControls() {
        this.resetFrameControls();
        this.resetTraceControls();
    }

    resetFrameControls() {
        this.formControls['frameBrand'].clearValidators();
        this.formControls['frameModel'].clearValidators();
        this.formControls['frameMaterial'].clearValidators();
        this.formControls['frameColor'].clearValidators();
        this.formControls['pupilDistanceLeft'].clearValidators();
        this.formControls['pupilDistanceRight'].clearValidators();
        this.formControls['ocularCenterLeft'].clearValidators();
        this.formControls['ocularCenterRight'].clearValidators();
        this.formControls['frameSizeBoxA'].clearValidators();
        this.formControls['frameSizeBoxB'].clearValidators();
        this.formControls['frameDblDimension'].clearValidators();
        this.formControls['bevelType'].clearValidators();

        this.formControls['frameBrand'].reset();
        this.formControls['frameModel'].reset();
        this.formControls['frameMaterial'].reset();
        this.formControls['frameColor'].reset();
        this.formControls['pupilDistanceLeft'].reset();
        this.formControls['pupilDistanceRight'].reset();
        this.formControls['ocularCenterLeft'].reset();
        this.formControls['ocularCenterRight'].reset();
        this.formControls['frameSizeBoxA'].reset();
        this.formControls['frameSizeBoxB'].reset();
        this.formControls['frameDblDimension'].reset();
        this.formControls['bevelType'].reset();
        this.formControls['edgePolish'].reset();
        this.formControls['frameTracerSizeBoxA'].reset();
        this.formControls['frameTracerSizeBoxB'].reset();
        this.formControls['frameTracerDblDimension'].reset();

        this.changeFrameBrand();
        this.changeFrameModel();
        this.changeFrameMaterial();
        this.changeFrameColor();
        this.changePupilDistanceLeft();
        this.changePupilDistanceRight();
        this.changeOcularCenterLeft();
        this.changeOcularCenterRight();
        this.changeframeSizeBoxA();
        this.changeframeSizeBoxB();
        this.changeframeDblDimension();
        this.changeBevelType();
        this.changeEdgePolish();
    }

    resetTraceControls() {
        this.formControls['traceFile'].clearValidators();
        this.formControls['tracerName'].clearValidators();
        this.formControls['frameTracerSizeBoxA'].clearValidators();
        this.formControls['frameTracerSizeBoxB'].clearValidators();
        this.formControls['frameTracerDblDimension'].clearValidators();

        this.resetTraceData();
    }

    remoteEdgingSelected(): boolean {
        return this.getCurrentGlassTreatmentId() === Treatments.remoteEdging;
    }

    remoteEdgingAndFittingSelected(): boolean {
        return this.getCurrentGlassTreatmentId() === Treatments.remoteEdgingAndFitting;
    }

    treatmentNoneSelected(): boolean {
        return this.getCurrentGlassTreatmentId() === Treatments.none;
    }

    setDefaultTreatment(): void {
        const treatmentId = this.glassFittingFitGlassComponent.glassTreatment;

        if (!treatmentId) {
            this.glassFittingFitGlassComponent.changeGlassTreatment(Treatments.none);
        }
    }

    processTraceFile(files: FileList): void {
        this.resetTraceData(true, false);
        const reader = new FileReader();
        reader.onload = () => {
            const fileContent = <string>reader.result;
            this.saveTraceFile(fileContent);
        };
        if (files && files.length > 0) {
            reader.readAsText(files[0]);
            this.traceName = files[0].name;
        }
    }

    private saveTraceFile(traceFileContent: string): void {
        this.parseTraceFileContent(traceFileContent);
        this.glassFittingFitGlassComponent.changeTraceFile(traceFileContent);
        this.hasTraceFile = true;
        this.formControls['traceFile'].patchValue(true);
    }

    private resetTraceData(keepBrowseActive = false, resetTracerName = true): void {
        this.traceName = '';
        this.traceUrl = '';
        this.hasTraceFile = false;
        this.formControls['traceFile'].patchValue(false);
        this.glassFittingFitGlassComponent.changeTraceFile('');
        if (resetTracerName === true) {
            this.formControls['tracerName'].patchValue('');
            this.glassFittingFitGlassComponent.changeTracerName('');
        }
        if (keepBrowseActive === false) {
            this.traceBrowse = false;
            this.formControls['traceBrowse'].patchValue(false);
        }
    }

    private parseTraceFileContent(traceFileContent: string): void {
        const lines = traceFileContent.split('\r\n');

        lines.forEach((line) => {
            const lineValues = line.split('=');
            const key = lineValues[0];

            switch (key) {
                case 'DBL':
                    const valueDBL = GlassFrameComponent.getFirstNumberValue(lineValues[1]);
                    this.valueToControl(valueDBL, 'frameTracerDblDimension');
                    this.valueToControl(valueDBL, 'frameDblDimension');
                    break;
                case 'HBOX':
                    const valueSizeBoxA = GlassFrameComponent.getFirstNumberValue(lineValues[1]);
                    this.valueToControl(valueSizeBoxA, 'frameTracerSizeBoxA');
                    this.valueToControl(valueSizeBoxA, 'frameSizeBoxA');
                    break;
                case 'VBOX':
                    const valueSizeBoxB = GlassFrameComponent.getFirstNumberValue(lineValues[1]);
                    this.valueToControl(valueSizeBoxB, 'frameTracerSizeBoxB');
                    this.valueToControl(valueSizeBoxB, 'frameSizeBoxB');
                    break;
            }
        });
    }

    private valueToControl(value: number, controlId: string): void {
        this.formControls[controlId].patchValue(value, { emitEvent: true });
        this.formControls[controlId].updateValueAndValidity();
    }

    private static getFirstNumberValue(value: string) {
        const stringValues = value.split(';');
        const firstValue = stringValues[0];
        return Number(firstValue);
    }

    edgingInfoImageName(): string {
        if (this.appState.authenticatedUser.CurrentLanguageId === 1) {
            return 'edging-info.png';
        } else {
            return 'edging-info-EN.png';
        }
    }

    async connect(): Promise<void> {
        try {
            await this.connection.start();
            this.alertService.success(this.translateService.instant('info.izyShapeConnected'));

            this.connection.onclose((error) => {
                if (!!error) {
                    console.warn(error);
                    this.alertService.error(this.translateService.instant('error.izyShapeDisconnectionError'));
                }
            });

            this.connection.on('NewShapeResponse', (newShapeResponse) => {
                // newShapeResponse.OmaFile -> You can retrieve the OMA shape with this byte[]
                // newShapeResponse.EditURL -> You can edit the Shape online with this URL for a set amount of time
                if (newShapeResponse.IZyTraceTracerInformation) {
                    this.resetTraceData();
                    this.alertService.success(this.translateService.instant('info.izyShapeTraceReceived'));
                    this.traceUrl = newShapeResponse.EditURL;
                    this.traceName = newShapeResponse.ShapeName;

                    const tracerName = newShapeResponse.IZyTraceTracerInformation.TracerName;
                    this.glassFittingFitGlassComponent.changeTracerName(tracerName);
                    this.formControls['tracerName'].patchValue(tracerName);

                    const traceFile = atob(newShapeResponse.OmaFile);
                    this.saveTraceFile(traceFile);
                    this.disconnect();
                } else {
                    this.alertService.warning(this.translateService.instant('info.izyShapeInvalidTraceReceived'));
                }
            });
        } catch (ex) {
            console.warn(ex);

            if (ex.statusCode === 403) {
                this.alertService.error(this.translateService.instant('error.izyShapeInvalidLicenceKey'));
            } else {
                this.alertService.error(this.translateService.instant('error.izyShapeGeneralConnectionError'));
            }
        }
    }

    async disconnect(): Promise<void> {
        if (
            this.connection.state !== HubConnectionState.Disconnected &&
            this.connection.state !== HubConnectionState.Disconnecting
        ) {
            await this.connection.stop();
            if (this.traceBrowse === true) {
                this.resetTraceData();
            }
        }
    }
}
