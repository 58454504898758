import { FittedLens } from '@app/shared/models';

export class FittedLensChange {
    previousFittedLens: FittedLens;
    newFittedLens: FittedLens;

    constructor(previousFittedLens: FittedLens, newFittedLens: FittedLens) {
        this.previousFittedLens = previousFittedLens;
        this.newFittedLens = newFittedLens;
    }
}
