import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppStateService } from '../appservices/appState.service';

@Directive({
    selector: '[excludeFromCustomTemplateIdentifierFitlens]',
})
export class ExcludeFromCustomTemplateIdentifierFitlensDirective implements OnInit {
    templateIdentifier = '';
    excludeTemplates: string[] = [];

    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
        public appState: AppStateService,
    ) {}

    ngOnInit() {
        this.templateIdentifier = this.appState.currentCompany?.CustomTemplateIdentifierFitlens;
        this.updateView();
    }

    @Input() set excludeFromCustomTemplateIdentifierFitlens(templates: string[]) {
        this.excludeTemplates = templates;
        this.updateView();
    }

    private updateView() {
        if (this.excludeTemplates.includes(this.templateIdentifier)) {
            this.viewContainer.clear();
        } else if (this.viewContainer.length === 0) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
