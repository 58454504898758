import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ImageOptions } from '../shared/models/image-options.model';
import { FormControl, FormGroup } from '@angular/forms';
import { ListSelectOption, UserSetting } from '../shared/models';
import { UserSettingService } from '@app/core/services/api/user-setting.service';
import { forkJoin, switchMap, tap } from 'rxjs';

interface ImageOptionsFormGroup {
    imageType: FormControl<ListSelectOption>;
    useNormalize: FormControl<ListSelectOption>;
    useMapType: FormControl<ListSelectOption>;
    useMm: FormControl<ListSelectOption>;
}

@Component({
    selector: 'measurement-options',
    templateUrl: 'measurement-options.component.html',
})
export class MeasurementOptionsComponent implements OnInit {
    @Input() imageOptions: ImageOptions;
    @Output() imageOptionsChanged: EventEmitter<ImageOptions> = new EventEmitter();

    public formGroup: FormGroup<ImageOptionsFormGroup>;
    public imageTypeFormOptions: ListSelectOption[] = [];
    public mapTypeFormOptions: ListSelectOption[] = [];
    public mmDptFormOptions: ListSelectOption[] = [];
    public normalizeFormOptions: ListSelectOption[] = [];

    public loading = true;

    constructor(private readonly userSettingService: UserSettingService) {}

    public ngOnInit(): void {
        this.createForm();

        this.formGroup.valueChanges.subscribe(() => {
            this.updateImageOptions();
        });

        forkJoin([
            this.userSettingService.getMeasurementUnitOptions(),
            this.userSettingService.getImageTypeOptions(),
            this.userSettingService.getNormalizeOptions(),
            this.userSettingService.getMapTypeOptions(),
        ])
            .pipe(
                tap(([mmDptFormOptions, imageTypeFormOptions, normalizeFormOptions, mapTypeFormOptions]) => {
                    this.mmDptFormOptions = mmDptFormOptions;
                    this.imageTypeFormOptions = imageTypeFormOptions;
                    this.normalizeFormOptions = normalizeFormOptions;
                    this.mapTypeFormOptions = mapTypeFormOptions;
                }),
                switchMap(() => this.userSettingService.getUserSettings()),
                tap((userSettings: UserSetting) => {
                    this.formGroup.setValue({
                        imageType: this.imageTypeFormOptions.find((x) => x.Id === userSettings.UseImageType),
                        useNormalize: this.normalizeFormOptions.find((x) => x.Id === userSettings.UseNormalize),
                        useMm: this.mmDptFormOptions.find((x) => x.Id === userSettings.UseMm),
                        useMapType: this.mapTypeFormOptions.find((x) => x.Id === userSettings.UseMapType),
                    });
                }),
            )
            .subscribe(() => (this.loading = false));
    }

    private createForm(): void {
        this.formGroup = new FormGroup<ImageOptionsFormGroup>({
            imageType: new FormControl(),
            useNormalize: new FormControl(),
            useMapType: new FormControl(),
            useMm: new FormControl(),
        });
    }

    private updateImageOptions(): void {
        const controls = this.formGroup.controls;

        this.imageOptions.topoImageOptions.UseNormalize =
            controls.useNormalize.value.Code === 'normalizeOption.Normalized';
        this.imageOptions.topoImageOptions.UseMapType = controls.useMapType.value.Code === 'mapType.Tangential';
        this.imageOptions.topoImageOptions.ImageChoice = this.getImageTypeCode(controls.imageType.value.Code);
        this.imageOptions.topoImageOptions.UseMm = controls.useMm.value.Code === 'legendUnit.Mm';

        this.imageOptionsChanged.emit(this.imageOptions);
    }

    private getImageTypeCode(fullCode: string): string {
        // Turns 'imageType.Current' into 'current' for example.
        return fullCode.substring(10).toLocaleLowerCase();
    }
}
