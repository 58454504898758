import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QueryResult, SearchRequest, SearchResult } from '@app/shared/models';
import { HttpUtil } from '@app/shared/utilities/http.util';

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    private readonly basePath = 'api/searches';

    constructor(private readonly httpClient: HttpClient) {}

    public search(search: SearchRequest): Observable<QueryResult<SearchResult>> {
        const options = {
            params: HttpUtil.mapObjectToHttpParams(search),
        };

        return this.httpClient.get<QueryResult<SearchResult>>(this.basePath, options);
    }
}
