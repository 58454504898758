<div class="form-group row">
    <label for="{{ id }}" class="col-form-label col-md-4 my-auto" [ngClass]="labelClass">
        <i
            *ngIf="info && infoPosition === 'left'"
            class="fa fa-question-circle"
            popover="{{ info }}"
            triggers="mouseenter:mouseleave"></i>
        {{ label | translate: labelParams }}
        <i
            *ngIf="info && infoPosition === 'right'"
            class="fa fa-question-circle"
            popover="{{ info }}"
            triggers="mouseenter:mouseleave"></i>
    </label>
    <div [ngClass]="controlClass">
        <ng-container *ngIf="dayMonthYear">
            <div class="row no-gutters">
                <div class="col-4 pe-1">
                    <input
                        type="text"
                        id="{{ id }}-day"
                        class="form-control"
                        placeholder="{{ 'general.day' | translate }}"
                        [(ngModel)]="day"
                        maxlength="2"
                        (keypress)="validateInput($event, day)"
                        (keyup)="changeDay($event)"
                        [disabled]="isDisabled" />
                </div>
                <div class="col-4 pe-1">
                    <input
                        type="text"
                        id="{{ id }}-month"
                        class="form-control"
                        placeholder="{{ 'general.month' | translate }} "
                        [(ngModel)]="month"
                        maxlength="2"
                        (keypress)="validateInput($event, month)"
                        (keyup)="changeMonth($event)"
                        [disabled]="isDisabled" />
                </div>
                <div class="col-4">
                    <input
                        type="text"
                        id="{{ id }}-year"
                        class="form-control"
                        placeholder="{{ 'general.year' | translate }}"
                        [(ngModel)]="year"
                        maxlength="4"
                        (keypress)="validateInput($event, year)"
                        (keyup)="changeYear($event)"
                        [disabled]="isDisabled" />
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="monthDayYear">
            <div class="row no-gutters">
                <div class="col-4 pe-1">
                    <input
                        type="text"
                        id="{{ id }}-month"
                        class="form-control"
                        placeholder="{{ 'general.month' | translate }} "
                        [(ngModel)]="month"
                        maxlength="2"
                        (keypress)="validateInput($event, month)"
                        (keyup)="changeMonth($event)"
                        [disabled]="isDisabled" />
                </div>
                <div class="col-4 pe-1">
                    <input
                        type="text"
                        id="{{ id }}-day"
                        class="form-control"
                        placeholder="{{ 'general.day' | translate }}"
                        [(ngModel)]="day"
                        maxlength="2"
                        (keypress)="validateInput($event, day)"
                        (keyup)="changeDay($event)"
                        [disabled]="isDisabled" />
                </div>
                <div class="col-4">
                    <input
                        type="text"
                        id="{{ id }}-year"
                        class="form-control"
                        placeholder="{{ 'general.year' | translate }}"
                        [(ngModel)]="year"
                        maxlength="4"
                        (keypress)="validateInput($event, year)"
                        (keyup)="changeYear($event)"
                        [disabled]="isDisabled" />
                </div>
            </div>
        </ng-container>
    </div>
</div>
