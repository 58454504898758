import { Injectable } from '@angular/core';
import { DataSheetService } from '@app/core/services/api/datasheet.service';
import { DistributorService } from '@app/core/services/api/distributor.service';
import { LanguageService } from '@app/core/services/api/language.service';
import { OpticianService } from '@app/core/services/api/optician.service';
import { ProductGroupService } from '@app/core/services/api/product-group.service';
import { UserSettingService } from '@app/core/services/api/user-setting.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { DatasheetTypes } from '@app/shared/enums';
import { DataSheet, LensDefinition, ListOption, ListSelectOption, UserSetting } from '@app/shared/models';
import { LensTypeProductGroupNames } from '@app/shared/models/lensTypeProductGroupNames.model';
import { OpticianSetting } from '@app/shared/models/opticianSetting.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SettingsDialogService {
    private userSetting: UserSetting;
    private opticianSetting: OpticianSetting;
    private licenseAgreement: DataSheet;
    private lensNames: LensTypeProductGroupNames;
    private languages: ListOption[];
    private opticians: ListOption[];
    private distributors: ListOption[];
    private lensDefinitions: LensDefinition[];
    private mmDptTypes: ListSelectOption[];
    private normalizeOptions: ListSelectOption[];
    private mapTypeOptions: ListSelectOption[];
    private imageTypes: ListSelectOption[];

    constructor(
        private readonly userSettingService: UserSettingService,
        private readonly opticianService: OpticianService,
        private readonly languageService: LanguageService,
        private readonly translate: TranslateService,
        private readonly distributorService: DistributorService,
        private readonly appState: AppStateService,
        private readonly productGroupService: ProductGroupService,
        private readonly dataSheetService: DataSheetService,
    ) {}

    getLanguages(): Observable<ListOption[]> {
        if (this.languages) {
            return of(this.languages);
        }

        return this.languageService.getLanguagesBySupportedVersion(2).pipe(
            map((languages) =>
                languages.map(
                    (language) =>
                        new ListOption(language.Id, this.translate.instant('language.' + language.Code), language.Code),
                ),
            ),
            tap((languages) => (this.languages = languages)),
        );
    }

    getUserSettings(): Observable<UserSetting> {
        if (this.userSetting) {
            return of(this.userSetting);
        }

        return this.userSettingService.getUserSettings().pipe(tap((userSetting) => (this.userSetting = userSetting)));
    }

    getLensDefinitions(): Observable<LensDefinition[]> {
        if (this.lensDefinitions) {
            return of(this.lensDefinitions);
        }

        return this.opticianService
            .getLensDefinitions()
            .pipe(tap((lensDefinitions) => (this.lensDefinitions = lensDefinitions)));
    }

    getLicenseAgreement(): Observable<DataSheet> {
        if (this.licenseAgreement) {
            return of(this.licenseAgreement);
        }

        return this.dataSheetService.getAgreementDataSheets().pipe(
            map((sheets) => sheets.find((ds: DataSheet) => ds.Code == DatasheetTypes.LicenseAgreement)),
            tap((licenseAgreement) => (this.licenseAgreement = licenseAgreement)),
        );
    }

    getOpticians(): Observable<ListOption[]> {
        if (this.opticians) {
            return of(this.opticians);
        }

        return this.opticianService.getOpticiansByUserName(this.appState.authenticatedUser.UserName).pipe(
            map((opticians) =>
                opticians
                    .map((op) => new ListOption(op.Id, op.FullName, op.Code))
                    .sort((a, b) => a.Name.localeCompare(b.Name)),
            ),
            tap((opticians) => (this.opticians = opticians)),
        );
    }

    getDistributors(): Observable<ListOption[]> {
        if (this.distributors) {
            return of(this.distributors);
        }

        return this.distributorService.getDistributors().pipe(
            map((distributors) =>
                distributors
                    .map((d) => new ListOption(d.Id, d.Name, d.Name))
                    .sort((a, b) => a.Name.localeCompare(b.Name)),
            ),
            tap((distributors) => (this.distributors = distributors)),
        );
    }

    getLensTypeProductGroupNames(): Observable<LensTypeProductGroupNames> {
        if (this.lensNames) {
            return of(this.lensNames);
        }

        return this.productGroupService
            .getLensTypeProductGroupNames()
            .pipe(tap((lensNames) => (this.lensNames = lensNames)));
    }

    getMeasurementUnitOptions() {
        if (this.mmDptTypes) {
            return of(this.mmDptTypes);
        }

        return this.userSettingService
            .getMeasurementUnitOptions()
            .pipe(tap((mmDptTypes) => (this.mmDptTypes = mmDptTypes)));
    }

    getImageTypeOptions() {
        if (this.imageTypes) {
            return of(this.imageTypes);
        }

        return this.userSettingService.getImageTypeOptions().pipe(tap((imageTypes) => (this.imageTypes = imageTypes)));
    }

    getNormalizeOptions() {
        if (this.normalizeOptions) {
            return of(this.normalizeOptions);
        }

        return this.userSettingService
            .getNormalizeOptions()
            .pipe(tap((normalizeOptions) => (this.normalizeOptions = normalizeOptions)));
    }

    getMapTypeOptions() {
        if (this.mapTypeOptions) {
            return of(this.mapTypeOptions);
        }

        return this.userSettingService
            .getMapTypeOptions()
            .pipe(tap((mapTypeOptions) => (this.mapTypeOptions = mapTypeOptions)));
    }

    getOpticianSettings() {
        if (this.opticianSetting) {
            return of(this.opticianSetting);
        }

        return this.opticianService
            .getOpticianSettings(this.appState.currentOptician.Id)
            .pipe(tap((opticianSettings) => (this.opticianSetting = opticianSettings)));
    }
}
