import { EyeSides } from '../enums';
import { FitLensComponent } from '@app/fitlens/fitlens.component';
import { CopyLensData } from '../models/copyData';

export class CopyLensHelper {
    leftEyeSelected: boolean;
    rightEyeSelected: boolean;
    rightFitLensComponent: FitLensComponent;
    leftFitLensComponent: FitLensComponent;

    get showCopyButton(): boolean {
        return this.leftEyeSelected && this.rightEyeSelected;
    }

    onCopyLensInfo(sourceEyeSide: EyeSides) {
        if (sourceEyeSide === EyeSides.Od) {
            CopyLensHelper.copyLensToOtherSide(this.rightFitLensComponent, this.leftFitLensComponent);
        } else {
            CopyLensHelper.copyLensToOtherSide(this.leftFitLensComponent, this.rightFitLensComponent);
        }
    }

    private static copyLensToOtherSide(source: FitLensComponent, target: FitLensComponent): void {
        if (!source || !target) {
            return;
        }

        const copyData = new CopyLensData();
        copyData.lensDefinitionId = source.fittedLens.LensDefinitionId;
        copyData.materialId = source.lensselection.formControls['materialId'].value;
        copyData.colorId = source.lensselection.formControls['colorId'].value;
        copyData.parameters = source.formControls.parameters.value;

        target.receiveCopy(copyData).then();
    }
}
