import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { ScreensaverTriggerService } from '@app/shared/appservices/screensaver-trigger.service';
import { ApplicationInsightsService } from '@app/core/services/external/application-insights.service';
import { NotificationService } from '@app/core/services/wss/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TranslationService } from '@app/core/services/api/translation.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { RegionService } from '@app/core/services/region.service';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
        <mpc-loader></mpc-loader>
    `,
    styles: [':host { display: contents; }'],
})
export class AppComponent implements OnInit, OnDestroy {
    private newTranslationsSubscription: Subscription;
    private apiEndpointSubscription: Subscription;
    private authenticatedApiEndpointSubscription: Subscription;

    constructor(
        private readonly screensaverTriggerService: ScreensaverTriggerService,
        private readonly translateService: TranslateService,
        private readonly translationService: TranslationService,
        private appConfig: AppConfigService,
        private applicationInsightsService: ApplicationInsightsService,
        public appState: AppStateService,
        private notificationService: NotificationService,
        private loaderService: LoaderService,
        private readonly regionService: RegionService,
    ) {}

    ngOnInit(): void {
        // Hard coded here, because language will be loaded below (:
        this.loaderService.showMessage('Loading');
        this.applicationInsightsService.loadAppInsights();

        if (this.appState.authenticatedUser) {
            this.appState.setTheme(this.appState.authenticatedUser.CurrentApplicationTheme);
        }

        this.authenticatedApiEndpointSubscription = this.appConfig.apiEndpointChanges$.subscribe(() => {
            this.loaderService.showMessage('Loading');

            this.notificationService.initialize();
            this.notificationService.registerTranslationNotifications();
            this.newTranslationsSubscription = this.notificationService.newTranslationsAvailable.subscribe((data) => {
                this.translationService.getTranslation(data).subscribe((translation) => {
                    this.translateService.setTranslation(data, translation, false);
                    this.translateService.use(this.appState.authenticatedUser.CurrentLanguageCode);
                });
            });

            if (this.appState.authenticatedUser) {
                this.notificationService.startConnection();
            }

            this.loaderService.hide();
        });

        this.apiEndpointSubscription = this.appConfig.apiEndpointChanges$.subscribe(() => {
            const lang = this.appState.authenticatedUser?.CurrentLanguageCode ?? navigator.language;
            this.translateService.setDefaultLang(lang);
            this.translateService.use(lang);
        });

        if (this.regionService.regionChosen()) {
            this.appConfig.setAppEndpoint(this.regionService.getRegion());
        }

        this.screensaverTriggerService.initialize();
        this.loaderService.hide();
    }

    ngOnDestroy(): void {
        this.screensaverTriggerService.dispose();
        this.newTranslationsSubscription.unsubscribe();
        this.apiEndpointSubscription.unsubscribe();
        this.authenticatedApiEndpointSubscription.unsubscribe();
    }
}
