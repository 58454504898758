import { Component, OnInit } from '@angular/core';
import { AlertService } from '@app/shared/appservices/alert.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { LensDefinition } from '@app/shared/models';
import { OpticianProductSetting } from '@app/shared/models/OpticianProductSetting.model';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { LensConfigurationTabs } from '@app/core/components/settings-dialog/enums/lens-configuration-tabs.enum';
import { SettingsDialogService } from '../../services/settings-dialog.service';
import { OpticianService } from '@app/core/services/api/optician.service';

@Component({
    selector: 'lens-configuration-settings',
    templateUrl: 'lens-configuration-settings.component.html',
    styleUrls: ['lens-configuration-settings.component.scss'],
})
export class LensConfigurationSettingsComponent implements OnInit {
    settingsLoading = false;
    lensDefinitionsLoading = false;
    accountTabInitialized = false;
    lensConfigTabInitialized = false;
    settingsTabInitialized = false;

    lensDefinitions: LensDefinition[];

    selectedLensDefinitionIds: number[] = [];
    originalSelectedLensDefinitionIds: number[] = [];

    lensConfigTab = LensConfigurationTabs.Spherical;
    lensConfigurationTabs = LensConfigurationTabs;

    constructor(
        private readonly settingsDialogService: SettingsDialogService,
        private readonly appState: AppStateService,
        private readonly loaderService: LoaderService,
        private readonly translate: TranslateService,
        private readonly alertService: AlertService,
        private readonly opticianService: OpticianService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.lensDefinitionsLoading = true;
        this.loadLensConfigTab();
    }

    loadLensConfigTab(): void {
        this.settingsDialogService
            .getLensDefinitions()
            .pipe(
                finalize(() => {
                    this.lensDefinitionsLoading = false;
                }),
            )
            .subscribe((result) => {
                this.selectedLensDefinitionIds = result.filter((r) => r.IsActiveForUser).map((r) => r.Id);
                this.originalSelectedLensDefinitionIds = [...this.selectedLensDefinitionIds];

                this.lensDefinitions = result;
            });
    }

    get filteredLensDefinitions(): LensDefinition[] {
        let result: LensDefinition[] = [];

        if (this.lensDefinitions) {
            switch (this.lensConfigTab) {
                case LensConfigurationTabs.Spherical:
                    result = this.lensDefinitions.filter((ld) => ld.IsSpherical);
                    break;
                case LensConfigurationTabs.Toric:
                    result = this.lensDefinitions.filter((ld) => ld.IsToric);
                    break;
                case LensConfigurationTabs.MultiFocal:
                    result = this.lensDefinitions.filter((ld) => ld.IsMultifocal);
                    break;
                case LensConfigurationTabs.MultiFocalToric:
                    result = this.lensDefinitions.filter((ld) => ld.IsMultifocalToric);
                    break;
                default:
                    result = this.lensDefinitions;
                    break;
            }

            result = result.sort((a, b) =>
                a.Product.RankingOrder > b.Product.RankingOrder ? 1 : -1 || a.Name.localeCompare(b.Name),
            );
        }

        return result;
    }

    isLensDefinitionChecked(lensDefinition: LensDefinition): boolean {
        return this.selectedLensDefinitionIds.findIndex((l) => l === lensDefinition.Id) !== -1;
    }

    isSettingsEnabled(): boolean {
        return this.appState.isOptician || this.appState.isPs;
    }

    toggleLensDefinition(event: Partial<MouseEvent>, lensDefinition: LensDefinition): void {
        event.preventDefault();
        const lensDefinitionIndex = this.selectedLensDefinitionIds.findIndex((l) => l === lensDefinition.Id);

        if (lensDefinitionIndex !== -1) {
            this.selectedLensDefinitionIds.splice(lensDefinitionIndex, 1);
        } else {
            this.selectedLensDefinitionIds.push(lensDefinition.Id);
        }
    }

    get changedLensDefinitionIds(): number[] {
        const newIds = this.selectedLensDefinitionIds.filter(
            (l) => this.originalSelectedLensDefinitionIds.findIndex((o) => o === l) < 0,
        );

        const removedIds = this.originalSelectedLensDefinitionIds.filter(
            (l) => this.selectedLensDefinitionIds.findIndex((o) => o === l) < 0,
        );

        return newIds.concat(removedIds);
    }

    saveLensConfiguration(): void {
        if (this.changedLensDefinitionIds.length === 0) {
            return;
        }

        const newIds = this.selectedLensDefinitionIds.filter(
            (l) => this.originalSelectedLensDefinitionIds.findIndex((o) => o === l) < 0,
        );

        const removedIds = this.originalSelectedLensDefinitionIds.filter(
            (l) => this.selectedLensDefinitionIds.findIndex((o) => o === l) < 0,
        );

        const newProductIds = this.lensDefinitions
            .filter((ld) => newIds.findIndex((c) => c === ld.Id) !== -1)
            .map((ld) => ld.Product.Id);

        const removedProductIds = this.lensDefinitions
            .filter((ld) => removedIds.findIndex((c) => c === ld.Id) !== -1)
            .map((ld) => ld.Product.Id);

        const newOpticianProductSettings = newProductIds.map((p) => {
            const ops = new OpticianProductSetting();
            ops.ProductId = p;
            ops.Visible = true;
            ops.OpticianId = this.appState.currentOptician.Id;
            return ops;
        });

        const removedOpticianProductSettings = removedProductIds.map((p) => {
            const ops = new OpticianProductSetting();
            ops.ProductId = p;
            ops.Visible = false;
            ops.OpticianId = this.appState.currentOptician.Id;
            return ops;
        });

        const opticianProductSettings = newOpticianProductSettings.concat(removedOpticianProductSettings);

        this.loaderService.show();
        this.opticianService
            .saveOpticianProductSettings(opticianProductSettings)
            .pipe(
                finalize(() => {
                    this.originalSelectedLensDefinitionIds = [...this.selectedLensDefinitionIds];
                    this.loaderService.hide();
                }),
            )
            .subscribe(() => {
                this.alertService.success(this.translate.instant('general.saveSuccessful'));
            });
    }

    lensConfigTabClass(lensConfigTab: LensConfigurationTabs): string {
        return this.lensConfigTab === lensConfigTab ? 'title-border-bottom' : '';
    }

    clickLensConfigTab(event: MouseEvent, lensConfigTab: LensConfigurationTabs) {
        event.preventDefault();
        this.lensConfigTab = lensConfigTab;
    }
}
