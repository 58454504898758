import { SessionService } from './session.service';

export class ScopedSessionService {
    constructor(
        private sessionService: SessionService,
        private scopePrefix: string,
    ) {}

    private getPrefixedKey(key: string): string {
        return `${this.scopePrefix}.${key}`;
    }

    getAsObject<T>(key: string): T {
        return this.sessionService.getAsObject(this.getPrefixedKey(key));
    }

    clear(): void {
        for (const key of this.sessionService.getKeys()) {
            if (key.startsWith(this.scopePrefix)) {
                this.sessionService.remove(key);
            }
        }
    }

    save(key: string, value: unknown) {
        this.sessionService.save(this.getPrefixedKey(key), value);
    }
}
