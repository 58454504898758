import { OrderLine } from '.';
import { ReturnStatus } from './returnstatus.model';

export class ServiceRequest {
    SerialId: number;
    SerialNumber: string;
    ClientId: number;
    ClientReference: string;
    ClientReference2: string;
    ColorId: number;
    ColorName: string;
    Conclusion: string;
    CreditId: number;
    CreditName: string;
    CreditSentOn: Date;
    Description: string;
    EyeSideCode: string;
    EyeSideId: number;
    EyeSideName: string;
    HandledDate: Date;
    Id: number;
    InProgressDate: Date;
    InternalConclusion: string;
    IsChangedByUserName: string;
    LensTypeId: number;
    MaterialId: number;
    MaterialName: string;
    OpticianId: number;
    OpticianName: string;
    OpticianReturnNumber: number;
    ReturnStatusId: number;
    ReturnStatusCode: string;
    ReturnStatusName: string;
    ReturnStatusRankingOrder: number;
    OrderLine: OrderLine;
    OrderLineId: number;
    ProductId: number;
    ProductGroupId: number;
    ProductName: string;
    ResultId: number;
    ResultName: string;
    ReturnCategoryId: number;
    ReturnCategoryName: string;
    ReturnDate: Date;
    ReturnStatus: ReturnStatus;
    ReturnNumber: string;
    ReturnTypeName: string;
    ReturnTypeId: number;
    Vigilance: boolean;
    CreditSample: boolean;
}
