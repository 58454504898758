import { Component, OnInit, Input } from '@angular/core';
import { ClientService } from '@app/core/services/api/client.service';
import { Client, ListSelectOption } from '@app/shared/models';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective } from '@angular/forms';
import { InputDate } from '@app/shared/components/inputs';
import { Observable } from 'rxjs';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { Features, FeatureCategories } from '@app/shared/enums';

@Component({
    selector: 'client-edit',
    templateUrl: 'client-edit.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class ClientEditComponent implements OnInit {
    Features = Features;
    FeatureCategories = FeatureCategories;
    private _client: Client;

    get client(): Client {
        return this._client;
    }

    @Input() set client(client: Client) {
        this._client = client;
        this.patchClient();
    }

    parentFormGroup: UntypedFormGroup;
    formGroup: UntypedFormGroup;

    get formControls() {
        return this.formGroup.controls;
    }

    genders: ListSelectOption[] = [
        new ListSelectOption(2, 'general.female', ''),
        new ListSelectOption(1, 'general.male', ''),
    ];

    myopiaOptions: ListSelectOption[] = [
        new ListSelectOption(1, 'general.yes', ''),
        new ListSelectOption(0, 'general.no', ''),
    ];

    constructor(
        private readonly parent: FormGroupDirective,
        private readonly fb: UntypedFormBuilder,
        private readonly appState: AppStateService,
        private readonly clientService: ClientService,
    ) {}

    ngOnInit(): void {
        this.parentFormGroup = this.parent.form;
        this.createForm();
    }

    createForm(): void {
        this.formGroup = this.fb.group({
            reference: [this.client ? this.client.Reference : '', [Validators.required, Validators.maxLength(100)]],
            reference2: [this.client ? this.client.Reference2 : '', Validators.maxLength(100)],
            birthDate: [this.client ? InputDate.from(this.client.BirthDate) : null],
            gender: [this.client ? [new ListSelectOption(this.client.GenderId, '', '')] : []],
            myopia: [this.client ? [new ListSelectOption(this.client.Myopie ? 1 : 0, '', '')] : []],
            erpreference: [this.client ? this.client.ErpReference : '', Validators.maxLength(20)],
            street: [this.client ? this.client.Street : '', Validators.maxLength(150)],
            housenumber: [this.client ? this.client.Housenumber : '', Validators.maxLength(20)],
            phonenumber: [this.client ? this.client.Phonenumber : '', Validators.maxLength(20)],
        });

        // Only set the value when they have not checked it before (during the search)
        if (
            this.appState.isDistributorFeatureEnabled(Features.EnableMyopiaForNewClients) &&
            this.client.Myopie === null
        ) {
            this.formGroup.patchValue({ myopia: [new ListSelectOption(1, '', '')] });
        }

        this.formGroup.setParent(this.parentFormGroup);

        if (this.parentFormGroup.controls['client']) {
            this.parentFormGroup.removeControl('client');
        }

        this.parentFormGroup.addControl('client', this.formGroup);
    }

    patchClient(): void {
        if (this.formGroup) {
            this.formGroup.patchValue({
                reference: this.client.Reference,
                reference2: this.client.Reference2,
                birthDate: InputDate.from(this.client.BirthDate),
                gender: this.client.GenderId ? [new ListSelectOption(this.client.GenderId, '', '')] : [],
                myopia: this.client.Myopie,
                erpreference: this.client ? this.client.ErpReference : '',
                street: this.client ? this.client.Street : '',
                housenumber: this.client ? this.client.Housenumber : '',
                phonenumber: this.client ? this.client.Phonenumber : '',
            });
        }
    }

    get addressValidationLabelClass(): string {
        return this.formControls.street.errors || this.formControls.housenumber.errors ? 'error' : '';
    }

    readClient(): Client {
        const c = new Client();

        if (this.client) {
            c.Id = this.client.Id;
        }

        c.Reference = this.formControls.reference.value;
        c.Reference2 = this.formControls.reference2.value;

        const birthDate = this.formControls.birthDate.value as InputDate;

        if (birthDate) {
            c.BirthDate = new Date(birthDate.year, birthDate.month - 1, birthDate.day);
            c.BirthDateYear = birthDate.year;
            c.BirthDateMonth = birthDate.month;
            c.BirthDateDay = birthDate.day;
        }

        if (this.formControls.gender.value && this.formControls.gender.value.length > 0) {
            c.GenderId = this.formControls.gender.value[0].Id;
        }

        if (this.appState.isMyopiaEnabled) {
            c.Myopie =
                this.formControls.myopia.value &&
                this.formControls.myopia.value.length > 0 &&
                this.formControls.myopia.value[0].Id === 1;
        } else {
            c.Myopie = this.client.Myopie;
        }

        if (this.appState.isOpticianFeatureEnabled(Features.ExportByCustomerErpReference)) {
            c.ErpReference = this.formControls.erpreference.value;
            c.Street = this.formControls.street.value;
            c.Housenumber = this.formControls.housenumber.value;
            c.Phonenumber = this.formControls.phonenumber.value;
        }

        return c;
    }

    save(): Observable<Client> {
        const client = this.readClient();
        return this.clientService.save(client);
    }
}
