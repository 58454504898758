import { SupportRequestAttachment } from './supportRequestAttachment.model';
import { Note } from './note.model';
import { Proposal } from './proposal.model';

export class SupportRequest {
    public Id: number;
    public UserId: number;
    public SubmitDate: Date;
    public OpticianId: number;
    public ClientId: number;
    public ProposalId: number;
    public Message: string;
    public SupporterId: number;
    public SupporterName: string;
    public SupportRequestStatusId: number;
    public SupportRequestStatusCode: string;
    public SupportRequestStatusName: string;
    public SupportRequestAttachments: SupportRequestAttachment[];
    public Notes: Note[];
    public Proposal: Proposal;
}
