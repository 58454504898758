import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListOption } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class CountryService {
    private readonly basePath = 'api/countries';

    constructor(public httpClient: HttpClient) {}

    public getCountries(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/options`);
    }

    public getStates(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/united-states/options`);
    }
}
