<form [formGroup]="formGroup">
    <div class="text-uppercase mb-1">
        <strong>GrandVision</strong>
    </div>
    <fieldset [disabled]="isOrdered()">
        <div class="d-flex justify-content-between">
            <div class="col-md-4">
                <mpc-input-text
                    formControlName="perosOrderNumber"
                    [id]="'perosOrderNumber'"
                    label="orderoverview.perosordernumber"
                    [maxLength]="11"></mpc-input-text>
            </div>
            <div class="col-md-4">
                <mpc-input-text
                    formControlName="poOrderNumber"
                    [id]="'poOrderNumber'"
                    label="general.poNumber"></mpc-input-text>
            </div>
            <div class="d-flex justify-content-end">
                <button
                    type="button"
                    id="saveGrandVisionNumbers"
                    class="btn btn-success"
                    [disabled]="formGroup.invalid"
                    (click)="save()">
                    {{ 'general.save' | translate }}
                </button>
            </div>
        </div>
    </fieldset>
</form>
