import { Component, OnInit } from '@angular/core';
import { CreditService } from '@app/core/services/api/credit.service';
import { CreditLimitNotification } from '@app/shared/models/creditLimitNotification';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'credit-limit-notifications',
    templateUrl: './ps-creditlimitnotifications.component.html',
})
export class PsCreditLimitNotificationsComponent implements OnInit {
    notifications: Array<CreditLimitNotification> = [];

    constructor(private creditService: CreditService) {}

    async ngOnInit() {
        this.notifications = await lastValueFrom(this.creditService.getCreditLimitNotifications());
    }
}
