<form [formGroup]="formGroup">
    <div class="spinner-border spinner-border-sm mt-1" *ngIf="loadingData"></div>

    <div class="row align-items-center my-1">
        <div *ngIf="hasMultipleLensTypes" class="col-12 col-sm-6 col-md-5 col-lg-3 col-xl-2 pt-2">
            <mpc-select
                formControlName="selectedLensType"
                [id]="'selectedLensType'"
                [items]="orderedLensTypes"
                label=""
                noSelectionLabel="lenstype.selectlenstype"
                [labelWidth]="0"
                [focus]></mpc-select>
        </div>

        <div class="col-12 col-lg-9 col-xl-10 d-flex" [ngClass]="{ 'col-lg-12 col-xl-12': !hasMultipleLensTypes }">
            <div class="ms-lg-auto">
                <div class="d-inline-block">
                    <i class="fas fa-circle receipt-notordered fg"></i>
                    <span class="label ms-1 me-3">{{ 'receiptstatus.notordered' | translate }}</span>
                </div>
                <div class="d-inline-block">
                    <i class="fas fa-circle receipt-ordered fg"></i>
                    <span class="label ms-1 me-3">{{ 'receiptstatus.ordered' | translate }}</span>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="historyTable$ | async; let receiptHistory">
        <div class="table-responsive">
            <table class="table mb-1 datatable">
                <thead>
                    <tr class="orderline">
                        <th class="orderlinestatus">&nbsp;</th>
                        <th>
                            <a href="#" (click)="applySort($event, 'CreatedDate')">
                                {{ 'general.date' | translate }}
                                <div
                                    class="ms-1 sorticon"
                                    [ngClass]="getSortClass('CreatedDate')"
                                    aria-hidden="true"></div>
                            </a>
                        </th>
                        <th>
                            <a href="#" (click)="applySort($event, 'FittedLens.Product.Name')">
                                {{ 'general.product' | translate }}
                                <div
                                    class="ms-1 sorticon"
                                    [ngClass]="getSortClass('Product.Name')"
                                    aria-hidden="true"></div>
                            </a>
                        </th>
                        <th>
                            <a href="#" (click)="applySort($event, 'FittedLens.Material.Name')">
                                {{ 'general.material' | translate }}
                                <div
                                    class="ms-1 sorticon"
                                    [ngClass]="getSortClass('FittedLens.Material.Name')"
                                    aria-hidden="true"></div>
                            </a>
                        </th>
                        <th>
                            <a href="#" (click)="applySort($event, 'FittedLens.EyeSide')">
                                {{ 'general.eyesides' | translate }}
                                <div
                                    class="ms-1 sorticon"
                                    [ngClass]="getSortClass('FittedLens.EyeSide')"
                                    aria-hidden="true"></div>
                            </a>
                        </th>
                        <ng-container *ngFor="let parameter of receiptHistory.headerParameters">
                            <th>
                                <a href="#" (click)="applySort($event, 'FittedLens.Parameters.' + parameter.code)">
                                    {{ (parameter.code | parameterTranslate) ?? parameter.code }}
                                    <div
                                        class="ms-1 sorticon"
                                        [ngClass]="getSortClass('FittedLens.Parameters.' + parameter.code)"
                                        aria-hidden="true"></div>
                                </a>
                            </th>
                        </ng-container>
                        <th>
                            <a href="#" (click)="applySort($event, 'Quantity')">
                                {{ 'general.quantity' | translate }}
                                <div
                                    class="ms-1 sorticon"
                                    [ngClass]="getSortClass('Quantity')"
                                    aria-hidden="true"></div>
                            </a>
                        </th>
                        <th class="hidden-column" *ngIf="selectedLensTypeId !== LensTypes.DreamLite">
                            <a href="#" (click)="applySort($event, 'FittedLens.Engraving')">
                                {{ 'general.abbreviations.MRK' | translate }}
                                <div
                                    class="ms-1 sorticon"
                                    [ngClass]="getSortClass('FittedLens.Engraving')"
                                    aria-hidden="true"></div>
                            </a>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        id="historyrow_{{ i }}"
                        *ngFor="let item of receiptHistory.data; index as i"
                        (click)="toggleReceiptLine(item)"
                        [ngClass]="getClassForReceiptLine(item)">
                        <td class="orderlinestatus bg" [ngClass]="getReceiptLineStatusClass(item)">&nbsp;</td>
                        <td class="dateColumn">{{ item.CreatedDate | mpcdate }}</td>
                        <td id="productname_{{ i }}">
                            {{ item.FittedLens?.Product?.Name }}
                        </td>
                        <td class="materialColumn">
                            <ng-container *ngIf="item.FittedLens?.Material; let material">
                                {{ material.Name + ' ' + material.Color }}
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="item.FittedLens?.EyeSideId === 1">
                                {{ 'general.os' | translate }}
                            </ng-container>
                            <ng-container *ngIf="item.FittedLens?.EyeSideId === 2">
                                {{ 'general.od' | translate }}
                            </ng-container>
                        </td>

                        <ng-container *ngFor="let head of receiptHistory.headerParameters">
                            <td id="{{ (head.code | lowercase) + '_' + i }}">
                                <ng-container *ngFor="let parameter of item.FittedLens?.Parameters">
                                    <ng-container *ngIf="parameter.Code === head.code">
                                        {{ parameter.Value | parameterFormatter: head.code }}
                                    </ng-container>
                                </ng-container>
                            </td>
                        </ng-container>

                        <td id="quantity_{{ i }}">{{ item.Quantity }}</td>
                        <td class="hidden-column" *ngIf="selectedLensTypeId !== LensTypes.DreamLite">
                            {{ item.FittedLens?.Engraving }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="!loadingData && receiptHistory.data.length === 0">
            <span>{{ 'general.orderlinehistorynoresults' | translate }}</span>
        </div>
    </ng-container>
</form>
