<div class="container-fluid mt-4 px-lg-4 flex-fill" [formGroup]="formGroup">
    <div class="row">
        <!-- Glass data -->
        <div class="col-12 col-md-8 col-xl-9 mb-3">
            <div class="row">
                <!-- Page title -->
                <div class="mb-2 col-12">
                    <h3 class="mt-auto">
                        {{ 'general.fitglass' | translate }}
                    </h3>
                </div>

                <!-- MOBILE -->
                <ng-container *ngIf="appState.isMobile || appState.isTablet">
                    <tabset [justified]="true" class="col-12">
                        <tab id="tab-od">
                            <ng-template tabHeading>
                                <div class="d-flex align-items-center">
                                    <div>
                                        <h4>
                                            {{ 'general.right' | translate }}
                                        </h4>
                                    </div>
                                    <div class="ms-auto hidewheninactive modify">
                                        <div class="form-check" (click)="toggleRightEye()">
                                            <input
                                                type="checkbox"
                                                id="modify-od"
                                                name="modify-od"
                                                [checked]="fitlensRightEyeSelected"
                                                class="form-check-input" />
                                            <label id="labelfor-modify-od" for="modify-od" class="form-check-label">
                                                {{ 'general.modify' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                            <ng-container *ngTemplateOutlet="fitlensOD"></ng-container>
                        </tab>

                        <tab id="tab-os">
                            <ng-template tabHeading>
                                <div class="d-flex align-items-center">
                                    <div>
                                        <h4>
                                            {{ 'general.left' | translate }}
                                        </h4>
                                    </div>
                                    <div class="ms-auto hidewheninactive modify">
                                        <div class="form-check" (click)="toggleLeftEye()">
                                            <input
                                                type="checkbox"
                                                [checked]="fitlensLeftEyeSelected"
                                                id="modify-os"
                                                name="modify-os"
                                                class="form-check-input" />
                                            <label id="labelfor-modify-os" for="modify-os" class="form-check-label">
                                                {{ 'general.modify' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                            <ng-container *ngTemplateOutlet="fitlensOS"></ng-container>
                        </tab>
                    </tabset>
                </ng-container>

                <!-- OTHER DEVICES MD XL -->
                <ng-container *ngIf="!appState.isMobile && !appState.isTablet">
                    <div class="col-6 pe-2">
                        <div class="pt-2 title-border-top d-flex align-items-center mb-2">
                            <div>
                                <h4>{{ 'general.right' | translate }}</h4>
                            </div>
                            <div class="ms-auto">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        formControlName="modify-od"
                                        id="modify-od"
                                        name="modify-od"
                                        class="form-check-input" />
                                    <label id="labelfor-modify-od" for="modify-od" class="form-check-label">
                                        {{ 'general.modify' | translate }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngTemplateOutlet="fitlensOD"></ng-container>
                    </div>

                    <div class="col-6 ps-2">
                        <div class="pt-2 title-border-top d-flex align-items-center mb-2">
                            <div>
                                <h4>{{ 'general.left' | translate }}</h4>
                            </div>
                            <div class="ms-auto">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        formControlName="modify-os"
                                        id="modify-os"
                                        name="modify-os"
                                        class="form-check-input" />
                                    <label id="labelfor-modify-os" for="modify-os" class="form-check-label">
                                        {{ 'general.modify' | translate }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngTemplateOutlet="fitlensOS"></ng-container>
                    </div>
                </ng-container>

                <div class="col-12 mt-4">
                    <glassframe #glassframe [glassFittingFitGlassComponent]="this"></glassframe>
                </div>
            </div>
        </div>

        <!-- Notes -->
        <div class="col-12 col-md-4 col-xl-3 mb-3">
            <div class="row">
                <div class="col-12">
                    <div class="pb-2 mb-2 title-border-bottom">
                        <h3 class="transform-normal">
                            {{ 'clientnotes.notes' | translate | uppercase }}
                            <span
                                class="badge bg-warning ms-1"
                                popover="{{ 'clientnotes.internalextra' | translate }}"
                                triggers="mouseenter:mouseleave">
                                {{ 'clientnotes.internal' | translate }}
                            </span>
                        </h3>
                    </div>
                    <client-notes [client]="client" [showInputfield]="true" #clientNotes></client-notes>
                </div>
            </div>
        </div>

        <!-- TEMPLATES -->
        <ng-template #fitlensOD>
            <div *ngIf="proposal && fitlensRightEyeSelected && rightFittedLens" class="flex-fill d-flex">
                <fit-glass
                    #rightFitLens
                    id="fitlens-od"
                    [fittedLens]="rightFittedLens"
                    [initialFittedLens]="initialRightFittedLens"
                    (isLoading)="handleFitLensLoading(eyeSides.Od, $event)"
                    [client]="client"></fit-glass>
            </div>
        </ng-template>

        <ng-template #fitlensOS>
            <div *ngIf="proposal && fitlensLeftEyeSelected && leftFittedLens" class="flex-fill d-flex">
                <fit-glass
                    #leftFitLens
                    id="fitlens-os"
                    [fittedLens]="leftFittedLens"
                    [initialFittedLens]="initialLeftFittedLens"
                    (isLoading)="handleFitLensLoading(eyeSides.Os, $event)"
                    [client]="client"></fit-glass>
            </div>
        </ng-template>
    </div>
</div>

<div *footer class="d-flex align-items-center">
    <div>
        <a href="#" (click)="previous($event)">
            <i class="fas fa-arrow-alt-circle-left me-1"></i>
            <span>{{ 'general.client' | translate }}</span>
        </a>
    </div>

    <div class="ms-auto d-flex">
        <ng-container *ngIf="!loading">
            <div *ngIf="!orderAllowed()" class="ms-auto me-2 row">
                <span class="col-12 align-self-center">{{ 'fitlens.cannotorder' | translate }}</span>
            </div>
        </ng-container>

        <button
            *ngIf="directOrderIsEnabled()"
            type="button"
            id="directorder"
            class="btn btn-success ms-1"
            (click)="next(false)">
            {{ 'general.directorder' | translate }}
        </button>
        <button
            *ngIf="!directOrderIsEnabled()"
            type="button"
            id="directorderdisabled"
            class="btn btn-default disabled ms-1">
            {{ 'general.directorder' | translate }}
        </button>
    </div>
</div>
