import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { Roles } from '@app/shared/enums';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RoleRouteResolverGuard {
    constructor(
        private readonly router: Router,
        private readonly appState: AppStateService,
    ) {}

    public canActivate(): Observable<boolean> {
        if (this.appState?.authenticatedUser) {
            switch (this.appState.authenticatedUser.CurrentRoleId) {
                case Roles.Optician:
                    break;
                case Roles.Administrator:
                case Roles.DistributorAdmin:
                case Roles.LensConfigurationAdmin:
                case Roles.Marketing:
                    this.router.navigate(['/manage']);
                    break;
                case Roles.Sales:
                    this.router.navigate(['/support']);
                    break;
                case Roles.Ps:
                case Roles.DistributorSupport:
                    this.router.navigate(['/ps']);
                    break;
                case Roles.QualityAssurance:
                    this.router.navigate(['/qualityassurance']);
                    break;
                case Roles.Logistics:
                    this.router.navigate(['/logistics']);
                    break;
                case Roles.Translator:
                    this.router.navigate(['/translator']);
            }
        }

        return of(true);
    }
}
