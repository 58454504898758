import { LensTypes } from '../enums';
import { EyeSides } from '../enums';
import { Proposal } from '.';
import { AppStateService } from '../appservices/appState.service';

export class FittedLensOptions {
    EyeSideId: EyeSides;
    LensTypeId: LensTypes;
    ProposalTypeId: number;
    ShowMaterialField = true;
    ShowMarkingsField = true;
    ShowThicknessField = false;
    ShowDatasheets = true;
    ShowResidualRefraction = true;
    AllParamsReadOnly = false;
    ShowOverRefractionButton = false;
    ShowOverRefractionInfo = false;
    ShowHiddenParams = false;

    Recalculate = true;
    EnableProductGroupFilter = true;

    constructor(
        eyeSide: number = null,
        proposal: Proposal = null,
        isFollowUp = false,
        lensTypeId: number | null = null,
        appstate: AppStateService = null,
    ) {
        if (eyeSide) {
            this.EyeSideId = eyeSide;
        }

        if (proposal) {
            this.LensTypeId = proposal.LensTypeId;
            this.ProposalTypeId = proposal.ProposalTypeId;
            this.ShowMaterialField = proposal.LensTypeId !== LensTypes.DreamLite;
            this.ShowMarkingsField = proposal.LensTypeId !== LensTypes.DreamLite;
            this.ShowOverRefractionButton = proposal.LensTypeId !== LensTypes.DreamLite && !isFollowUp;
            this.ShowOverRefractionInfo = isFollowUp;
        }

        if (lensTypeId) {
            this.LensTypeId = lensTypeId;
        }

        if (appstate && !appstate.isExpertModeEnabled && this.LensTypeId === LensTypes.DreamLite) {
            this.AllParamsReadOnly = true;
        }
    }
}
