import { Pipe, PipeTransform } from '@angular/core';
import { NumberFormatPipe } from './number.format.pipe';
import { ParameterTableDisplayHelper } from '../helpers/parameterTableDisplayHelper';

@Pipe({
    name: 'parameterFormatter',
})
export class ParameterFormatterPipe extends NumberFormatPipe implements PipeTransform {
    transform(value: unknown, ...args: unknown[]): unknown {
        const parameterCode = args[0] as string;
        const format = ParameterTableDisplayHelper.lookup(parameterCode);

        return super.transform(value, format.numberFormat);
    }
}
