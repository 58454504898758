<div class="d-flex justify-content-center" *ngIf="loading">
    <div class="spinner-border spinner-border-sm"></div>
</div>

<div *ngIf="!loading" [formGroup]="formGroup">
    <div class="d-flex">
        <!--OD-->
        <div class="w-50">
            <div class="pe-2">
                <div class="ms-auto hidewheninactive modify">
                    <div class="form-check">
                        <input
                            type="checkbox"
                            formControlName="modify-od"
                            id="modify-od"
                            name="modify-od"
                            class="form-check-input" />
                        <label id="labelfor-modify-od" for="modify-od" class="form-check-label">
                            {{ 'general.modify' | translate }} {{ 'general.od' | translate }}
                        </label>
                    </div>
                </div>

                <div *ngIf="!rightFittedLensEnabled" class="text-center">
                    <div class="btn btn-primary" (click)="generateOrderLine(eyeSides.Od)">
                        {{ 'ps.generateorderline' | translate }}
                    </div>
                </div>

                <div *ngIf="rightFittedLensEnabled && fitlensRightEyeSelected">
                    <div *ngIf="isDreamliteLens() && client && rightDreamLites && rightDreamLites.length > 0">
                        <ps-dreamlite-table
                            [dreamLites]="rightDreamLites"
                            [fittedLens]="rightFittedLens"
                            (onLensDeleted)="deleteFictionalDreamLiteLens($event)"
                            (onLensSelected)="dreamLensSelected($event)"
                            (onFictionalLensAdded)="addFictionalDreamLiteLens(eyeSides.Od)"
                            (onReset)="resetDreamLiteLens(eyeSides.Od)"
                            (onDreamLiteFixtypeResult)="dreamLiteFixTypeResult($event)"></ps-dreamlite-table>
                    </div>

                    <div *ngIf="currentMode === 'original'">
                        <fit-lens
                            #rightFitLens
                            id="fitlens-od"
                            [fittedLens]="proposal.RightOriginalFittedLens"
                            [showCopyButton]="false"
                            [client]="client"
                            [options]="rightReadOnlyOptions"
                            [lensFromPreviousOrderPromise]="lensFromPreviousOrderPromise(eyeSides.Od)"
                            [isDreamLiteFollowUp]="isDreamLiteFollowUp"
                            [isRegularFollowUp]="isFollowUp"
                            [topoMeasurement]="proposal.RightTopographicMeasurement"
                            [refractionMeasurement]="proposal.RightRefractionMeasurement"
                            [validators]="supportValidators"
                            [(sessionStorageKeysFitlens)]="sessionStorageKeysOrder"></fit-lens>
                    </div>

                    <div *ngIf="currentMode === 'practioner'">
                        <fit-lens
                            #rightFitLens
                            id="fitlens-od"
                            [fittedLens]="proposal.RightOpticianFittedLens"
                            [showCopyButton]="false"
                            [client]="client"
                            [options]="rightReadOnlyOptions"
                            [lensFromPreviousOrderPromise]="lensFromPreviousOrderPromise(eyeSides.Od)"
                            [isDreamLiteFollowUp]="isDreamLiteFollowUp"
                            [isRegularFollowUp]="isFollowUp"
                            [topoMeasurement]="proposal.RightTopographicMeasurement"
                            [refractionMeasurement]="proposal.RightRefractionMeasurement"
                            [validators]="supportValidators"
                            [(sessionStorageKeysFitlens)]="sessionStorageKeysOrder"></fit-lens>
                    </div>

                    <div *ngIf="currentMode === 'ps'">
                        <fit-lens
                            *ngIf="proposal"
                            #rightFitLens
                            id="fitlens-od"
                            [fittedLens]="rightFittedLens"
                            [showCopyButton]="false"
                            [client]="client"
                            [lensFromPreviousOrderPromise]="lensFromPreviousOrderPromise(eyeSides.Od)"
                            [options]="rightFittedLensOptions"
                            [isDreamLiteFollowUp]="isDreamLiteFollowUp"
                            [isRegularFollowUp]="isFollowUp"
                            [topoMeasurement]="proposal.RightTopographicMeasurement"
                            [refractionMeasurement]="proposal.RightRefractionMeasurement"
                            [quantity]="rightLensQuantity"
                            [validators]="supportValidators"
                            [(sessionStorageKeysFitlens)]="sessionStorageKeysOrder"></fit-lens>
                        <fit-lens
                            *ngIf="!proposal"
                            #rightFitLens
                            id="fitlens-od"
                            [fittedLens]="rightFittedLens"
                            [showCopyButton]="false"
                            [client]="client"
                            [lensFromPreviousOrderPromise]="lensFromPreviousOrderPromise(eyeSides.Od)"
                            [options]="rightFittedLensOptions"
                            [isDreamLiteFollowUp]="isDreamLiteFollowUp"
                            [isRegularFollowUp]="isFollowUp"
                            [quantity]="rightLensQuantity"
                            [validators]="supportValidators"
                            [(sessionStorageKeysFitlens)]="sessionStorageKeysOrder"></fit-lens>

                        <div class="pt-2" *ngIf="proposal">
                            <measurement-image
                                #rightMeasurementImage
                                id="measurementimage-od"
                                [eyeSide]="eyeSides.Od"
                                [fittedLens]="rightFittedLens"
                                [imageOptions]="imageOptions"
                                [topographicMeasurement]="rightFittedLens.TopographicMeasurement"
                                [refractionMeasurement]="rightFittedLens.RefractionMeasurement"></measurement-image>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--OS-->
        <div class="w-50">
            <div class="pe-2">
                <div class="ms-auto hidewheninactive modify">
                    <div class="form-check">
                        <input
                            type="checkbox"
                            formControlName="modify-os"
                            id="modify-os"
                            name="modify-os"
                            class="form-check-input" />
                        <label id="labelfor-modify-os" for="modify-os" class="form-check-label">
                            {{ 'general.modify' | translate }} {{ 'general.os' | translate }}
                        </label>
                    </div>
                </div>

                <div *ngIf="!leftFittedLensEnabled" class="text-center">
                    <div class="btn btn-primary" (click)="generateOrderLine(eyeSides.Os)">
                        {{ 'ps.generateorderline' | translate }}
                    </div>
                </div>

                <div *ngIf="leftFittedLensEnabled && fitlensLeftEyeSelected">
                    <div *ngIf="isDreamliteLens() && client && leftDreamLites && leftDreamLites.length > 0">
                        <ps-dreamlite-table
                            [dreamLites]="leftDreamLites"
                            [fittedLens]="leftFittedLens"
                            (onLensDeleted)="deleteFictionalDreamLiteLens($event)"
                            (onLensSelected)="dreamLensSelected($event)"
                            (onFictionalLensAdded)="addFictionalDreamLiteLens(eyeSides.Os)"
                            (onReset)="resetDreamLiteLens(eyeSides.Os)"
                            (onDreamLiteFixtypeResult)="dreamLiteFixTypeResult($event)"></ps-dreamlite-table>
                    </div>

                    <div *ngIf="currentMode === 'original'">
                        <fit-lens
                            #leftFitLens
                            id="fitlens-os"
                            [fittedLens]="proposal.LeftOriginalFittedLens"
                            [showCopyButton]="false"
                            [client]="client"
                            [options]="leftReadOnlyOptions"
                            [lensFromPreviousOrderPromise]="lensFromPreviousOrderPromise(eyeSides.Os)"
                            [isDreamLiteFollowUp]="isDreamLiteFollowUp"
                            [isRegularFollowUp]="isFollowUp"
                            [topoMeasurement]="proposal.LeftTopographicMeasurement"
                            [refractionMeasurement]="proposal.LeftRefractionMeasurement"
                            [validators]="supportValidators"
                            [(sessionStorageKeysFitlens)]="sessionStorageKeysOrder"></fit-lens>
                    </div>

                    <div *ngIf="currentMode === 'practioner'">
                        <fit-lens
                            #leftFitLens
                            id="fitlens-os"
                            [fittedLens]="proposal.LeftOpticianFittedLens"
                            [lensFromPreviousOrderPromise]="lensFromPreviousOrderPromise(eyeSides.Os)"
                            [showCopyButton]="false"
                            [client]="client"
                            [options]="leftReadOnlyOptions"
                            [isDreamLiteFollowUp]="isDreamLiteFollowUp"
                            [isRegularFollowUp]="isFollowUp"
                            [topoMeasurement]="proposal.LeftTopographicMeasurement"
                            [refractionMeasurement]="proposal.LeftRefractionMeasurement"
                            [validators]="supportValidators"
                            [(sessionStorageKeysFitlens)]="sessionStorageKeysOrder"></fit-lens>
                    </div>

                    <div *ngIf="currentMode === 'ps'">
                        <fit-lens
                            *ngIf="proposal"
                            #leftFitLens
                            id="fitlens-os"
                            [fittedLens]="leftFittedLens"
                            [showCopyButton]="false"
                            [lensFromPreviousOrderPromise]="lensFromPreviousOrderPromise(eyeSides.Os)"
                            [client]="client"
                            [options]="leftFittedLensOptions"
                            [isDreamLiteFollowUp]="isDreamLiteFollowUp"
                            [isRegularFollowUp]="isFollowUp"
                            [topoMeasurement]="proposal.LeftTopographicMeasurement"
                            [refractionMeasurement]="proposal.LeftRefractionMeasurement"
                            [quantity]="leftLensQuantity"
                            [validators]="supportValidators"
                            [(sessionStorageKeysFitlens)]="sessionStorageKeysOrder"></fit-lens>

                        <fit-lens
                            *ngIf="!proposal"
                            #leftFitLens
                            id="fitlens-os"
                            [fittedLens]="leftFittedLens"
                            [showCopyButton]="false"
                            [lensFromPreviousOrderPromise]="lensFromPreviousOrderPromise(eyeSides.Os)"
                            [client]="client"
                            [options]="leftFittedLensOptions"
                            [isDreamLiteFollowUp]="isDreamLiteFollowUp"
                            [isRegularFollowUp]="isFollowUp"
                            [quantity]="leftLensQuantity"
                            [validators]="supportValidators"
                            [(sessionStorageKeysFitlens)]="sessionStorageKeysOrder"></fit-lens>

                        <div class="pt-2" *ngIf="leftFittedLens.TopographicMeasurement">
                            <measurement-image
                                #leftMeasurementImage
                                id="measurementimage-os"
                                [eyeSide]="eyeSides.Os"
                                [fittedLens]="leftFittedLens"
                                [imageOptions]="imageOptions"
                                [topographicMeasurement]="leftFittedLens.TopographicMeasurement"
                                [refractionMeasurement]="leftFittedLens.RefractionMeasurement"></measurement-image>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="mt-4 p-2 rounded accented-background2"
        *ngIf="(fitlensLeftEyeSelected || fitlensRightEyeSelected) && this.proposal">
        <measurement-image-options
            id="imageOptions"
            [imageOptions]="imageOptions"
            (imageOptionsChanged)="imageOptionsChanged($event)"
            (imageModeChanged)="imageModeChanged($event)"></measurement-image-options>
    </div>

    <div *ngIf="isGrandvisionOrder()" class="mt-4 p-2 rounded accented-background">
        <ps-grandvision [order]="order"></ps-grandvision>
    </div>
</div>

<!-- Footer -->
<div *footer class="d-flex flex-row">
    <div class="ms-2">
        <button class="btn btn-default" (click)="onBack()">
            {{ 'general.back' | translate }}
        </button>

        <ng-template #snapshotInfoOriginal>
            <ng-container
                *ngTemplateOutlet="
                    lensSnapshotInfo;
                    context: {
                        leftLens: proposal.LeftOriginalFittedLens,
                        rightLens: proposal.RightOriginalFittedLens
                    }
                "></ng-container>
        </ng-template>

        <button
            [popover]="snapshotInfoOriginal"
            triggers="mouseenter:mouseleave"
            class="ms-4 btn"
            (click)="setViewModel('original')"
            [ngClass]="currentMode === 'original' ? 'btn-proceed' : 'btn-default'">
            {{ 'ps.opr' | translate }}
        </button>

        <ng-template #snapshotInfoOptician>
            <ng-container
                *ngTemplateOutlet="
                    lensSnapshotInfo;
                    context: {
                        leftLens: proposal.LeftOpticianFittedLens,
                        rightLens: proposal.RightOpticianFittedLens
                    }
                "></ng-container>
        </ng-template>

        <button
            [popover]="snapshotInfoOptician"
            triggers="mouseenter:mouseleave"
            class="ms-2 btn"
            (click)="setViewModel('practioner')"
            [ngClass]="currentMode === 'practioner' ? 'btn-proceed' : 'btn-default'">
            {{ 'ps.ppr' | translate }}
        </button>

        <ng-template #snapshotInfoSupport>
            <ng-container
                *ngTemplateOutlet="
                    lensSnapshotInfo;
                    context: { leftLens: leftFittedLens, rightLens: rightFittedLens }
                "></ng-container>
        </ng-template>

        <button
            [popover]="snapshotInfoSupport"
            triggers="mouseenter:mouseleave"
            class="ms-2 btn"
            (click)="setViewModel('ps')"
            [ngClass]="currentMode === 'ps' ? 'btn-proceed' : 'btn-default'">
            {{ 'ps.psp' | translate }}
        </button>

        <div *ngIf="canChangeLensType" class="btn-group dropup">
            <button
                type="button"
                class="btn btn-secondary dropdown-toggle"
                id="lensTypeDropup"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                {{ 'ps.changeLensType' | translate }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="lensTypeDropup">
                <li
                    *ngFor="let lensType of availableLensTypes()"
                    (click)="changeLensType(lensType.Id)"
                    class="dropdown-item">
                    {{ lensType.Name }}
                </li>
            </ul>
        </div>
    </div>

    <div class="ms-auto d-flex flex-row" *ngIf="{ val: canOrderLenses$ | async } as canOrderLenses">
        <button *ngIf="hasCloseOrderButton()" class="ms-2 btn btn-default" (click)="closeOrder()">
            {{ 'ps.closeOrder' | translate }}
        </button>

        <ng-container *ngIf="hasSaveButton()">
            <button
                class="ms-2 btn btn-primary"
                (click)="updateOrder(3)"
                *ngIf="hasGlobalSupportAnsweredSaveOption()"
                [disabled]="!canOrderLenses.val">
                {{ 'ps-supportorder-overview-stats.GLOBAL_SUPPORT_ANSW' | translate }}
            </button>

            <button
                class="ms-2 btn btn-primary"
                (click)="updateOrder(4)"
                *ngIf="hasWaitingForResponseSaveOption()"
                [disabled]="!canOrderLenses.val">
                {{ 'ps-supportorder-overview-stats.WAITING_FOR_RESPONSE' | translate }}
            </button>

            <button
                class="ms-2 btn btn-primary"
                (click)="updateOrder(1)"
                *ngIf="hasNewSaveOption()"
                [disabled]="!canOrderLenses.val">
                {{ 'ps-supportorder-overview-stats.NEW' | translate }}
            </button>
        </ng-container>

        <div class="btn-group dropup" *ngIf="hasApproveOrderButton()">
            <button
                type="button"
                (click)="approveOrder(false)"
                class="ms-2 btn btn-primary"
                [disabled]="!canOrderLenses.val">
                {{ 'ps.completeorder' | translate }}
            </button>
            <button
                type="button"
                class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                id="orderWithUrgencyDropup"
                data-bs-toggle="dropdown"
                [disabled]="!canOrderLenses.val"
                aria-haspopup="true"
                aria-expanded="false"></button>
            <ul class="dropdown-menu" aria-labelledby="orderWithUrgencyDropup">
                <li class="dropdown-item" (click)="approveOrder(true)">
                    {{ 'ps.completeorderwithurgency' | translate }}
                </li>
            </ul>
        </div>

        <button
            *ngIf="hasReOrderButton()"
            class="ms-2 btn btn-default"
            (click)="reOrder()"
            [disabled]="!canOrderLenses.val">
            {{ 'general.reorder' | translate }}
        </button>
    </div>
</div>

<ng-template #lensSnapshotInfo let-leftLens="leftLens" let-rightLens="rightLens">
    <div class="d-flex flex-column">
        <ng-container *ngTemplateOutlet="lensSnapshotLensInfo; context: { lens: leftLens }"></ng-container>
        <ng-container *ngTemplateOutlet="lensSnapshotLensInfo; context: { lens: rightLens }"></ng-container>
    </div>
</ng-template>

<ng-template #lensSnapshotLensInfo let-lens="lens">
    <div *ngIf="lens" class="d-flex flex-column">
        <span>
            <strong>{{ lens.EyeSideId === 1 ? ('general.os' | translate) : ('general.od' | translate) }}</strong>
        </span>
        <span>
            <small>{{ lens.IsCreatedOn | mpcdate }} {{ lens.IsCreatedOn | date: 'HH:mm' }}</small>
        </span>
        <span>
            <small>
                <i>{{ lens.IsCreatedByUserName }}</i>
            </small>
        </span>
    </div>
</ng-template>
