import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ReturnQAConclusionChanges } from '@app/shared/models/returnQualityAssuranceConclusionChanges.model';

@Component({
    selector: 'app-qa-change',
    templateUrl: './qa-change.component.html',
    styleUrls: ['./qa-change.component.scss'],
})
export class QaChangeComponent implements OnInit, OnChanges {
    formGroup: UntypedFormGroup;

    requestData = [
        'complaintsoverview.returnsummary.ReturnId',
        'complaintsoverview.returnsummary.IsCreatedOn',
        'complaintsoverview.returnsummary.IsCreatedByUserName',
        'general.abbreviations.POW',
        'general.abbreviations.BCR',
        'general.abbreviations.DIAMDIST',
        'general.abbreviations.OZ',
        'general.abbreviations.CYL',
        'general.abbreviations.AX',
        'general.abbreviations.ADD',
        'general.abbreviations.PRIS',
        'general.abbreviations.CMD',
        'general.abbreviations.BCR2',
        'general.abbreviations.SAG',
        'general.abbreviations.STAB',
        'complaintsoverview.returnsummary.Notch',
        'complaintsoverview.returnsummary.Trunc',
        'complaintsoverview.returnsummary.IsOpticClear',
        'complaintsoverview.returnsummary.Additional',
        'complaintsoverview.returnsummary.InternalConclusion',
        'complaintsoverview.returnsummary.MaterialId',
        'complaintsoverview.returnsummary.ColorId',
        'complaintsoverview.returnsummary.SurfaceId',
        'complaintsoverview.returnsummary.EdgeId',
    ];

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.createForm();
    }

    @Input()
    returnQAConclusion: ReturnQAConclusionChanges[];

    createForm(): void {
        this.formGroup = this.fb.group({
            ReturnId: [],
            IsCreatedOn: [],
            IsCreatedByUserName: [],
            Pow: [],
            Bcr: [],
            Diam: [],
            Oz: [],
            Cyl: [],
            Ax: [],
            Add: [],
            Prism: [],
            Cmd: [],
            Bcr2: [],
            Sag: [],
            Stab: [],
            Notch: [],
            Trunc: [],
            IsOpticClear: [],
            Additional: [],
            InternalConclusion: [],
            MaterialId: [],
            ColorId: [],
            SurfaceId: [],
            EdgeId: [],
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.returnQAConclusion.previousValue !== changes.returnQAConclusion.currentValue) {
            this.returnQAConclusion = changes.returnQAConclusion.currentValue;
        }
    }
}
