import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReturnService } from '@app/core/services/api/return.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { Util } from '@app/shared/helpers/utility.helper';
import { ServiceRequest } from '@app/shared/models/service-request.model';
import { lastValueFrom } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'logistic-newrequest-download-form',
    templateUrl: 'logistic-newrequest-download-form.component.html',
})
export class LogisticNewRequestDownloadFormComponent implements OnInit {
    loading = true;
    complaint: ServiceRequest;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly returnService: ReturnService,
        private readonly loaderService: LoaderService,
    ) {}

    async ngOnInit() {
        this.complaint = await lastValueFrom(this.returnService.getById(+this.route.snapshot.paramMap.get('id')));
        if (!this.complaint) {
            await this.router.navigate(['/logistics']);
        }

        this.loading = false;
    }

    downloadPdf() {
        this.loaderService.show();
        this.returnService
            .downloadPdf(this.complaint.Id)
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe((file) => {
                Util.openBlobInBrowser(file, file.name);
            });
    }
}
