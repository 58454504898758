export class ListOption {
    public Id: number;
    public Name: string;
    public Code: string;

    constructor(id: number | string, name: string, code: string) {
        this.Id = id as number;
        this.Name = name;
        this.Code = code;
    }
}
