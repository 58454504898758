import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { AuthenticatedUser, DataSheet, ListOption, UserSetting } from '@app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { UserService } from '@app/core/services/api/user.service';
import { AlertService } from '@app/shared/appservices/alert.service';
import { InitialNavigationService } from '@app/shared/appservices/initial-navigation.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, forkJoin, lastValueFrom, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { SettingsDialogService } from '../../services/settings-dialog.service';
import { Features, FeatureCategories, Roles } from '@app/shared/enums';
import { UserSettingService } from '@app/core/services/api/user-setting.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface AccountSettingsFormGroup {
    currentOptician: FormControl<string | null>;
    currentOpticianId: FormControl<number | null>;
    role: FormControl<number | null>;
    language: FormControl<number | null>;
    distributor: FormControl<number | null>;
    licenseAgreement: FormControl<Date | null>;
    trainingCompleteApprovedOn: FormControl<Date | null>;
}

@Component({
    selector: 'account-settings',
    templateUrl: 'account-settings.component.html',
    styleUrls: ['account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit {
    formGroupAccountSettings: FormGroup<AccountSettingsFormGroup>;

    accountSettingsLoading = false;

    authenticatedUser: AuthenticatedUser;

    languages: ListOption[];
    opticians: ListOption[];
    distributors: ListOption[];
    roles: ListOption[];
    selectedOptician: ListOption;
    selectedDistributor: ListOption;
    userSetting: UserSetting;

    agreementLicense: DataSheet;

    Features = Features;
    FeatureCategories = FeatureCategories;
    private readonly destroyRef = inject(DestroyRef);

    constructor(
        public appState: AppStateService,
        private readonly settingsDialogService: SettingsDialogService,
        private readonly bsModalRef: BsModalRef,
        private readonly initialNavigationService: InitialNavigationService,
        private readonly loaderService: LoaderService,
        private readonly userService: UserService,
        private readonly translate: TranslateService,
        private readonly alertService: AlertService,
        private readonly userSettingService: UserSettingService,
    ) {}

    ngOnInit(): void {
        this.authenticatedUser = this.appState.authenticatedUser;
        this.roles = this.appState.authenticatedUser.Roles.sort((a, b) => a.Name.localeCompare(b.Name)).map((x) => ({
            ...x,
            Code: x.Id.toString(),
        }));

        this.createAccountSettingsForm();

        const sources = [
            this.loadLanguages(),
            this.loadOpticians().pipe(switchMap((listOptions) => this.setCurrentOptician(listOptions))),
            this.settingsDialogService.getUserSettings().pipe(tap((userSettings) => (this.userSetting = userSettings))),
            this.loadDistributors(),
            this.loadDataSheet(),
        ];

        forkJoin(sources).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    }

    setCurrentOptician(listOptions: ListOption[]) {
        if (this.appState.authenticatedUser.CurrentOpticianId > 0) {
            this.selectedOptician = listOptions.find((x) => x.Id === this.appState.authenticatedUser.CurrentOpticianId);

            this.formGroupAccountSettings.controls['currentOptician'].setValue(
                this.selectedOptician ? this.selectedOptician.Name : '',
                { emitEvent: false },
            );
        }

        return of(listOptions);
    }

    createAccountSettingsForm(): void {
        this.formGroupAccountSettings = new FormGroup<AccountSettingsFormGroup>({
            currentOptician: new FormControl(''),
            currentOpticianId: new FormControl(this.appState.authenticatedUser.CurrentOpticianId),
            role: new FormControl(this.appState.authenticatedUser.CurrentRoleId),
            language: new FormControl(this.appState.authenticatedUser.CurrentLanguageId),
            distributor: new FormControl(this.appState.authenticatedUser.DistributorId),
            licenseAgreement: new FormControl(this.appState.authenticatedUser.LicenseAgreementApprovedOn),
            trainingCompleteApprovedOn: new FormControl(this.appState.authenticatedUser.TrainingCompleteApprovedOn),
        });
    }

    loadLanguages(): Observable<ListOption[]> {
        return this.settingsDialogService.getLanguages().pipe(tap((languages) => (this.languages = languages)));
    }

    loadOpticians(): Observable<ListOption[]> {
        return this.settingsDialogService.getOpticians().pipe(tap((opticians) => (this.opticians = opticians)));
    }

    loadDistributors(): Observable<ListOption[]> {
        return this.settingsDialogService
            .getDistributors()
            .pipe(tap((distributors) => (this.distributors = distributors)));
    }

    loadDataSheet(): Observable<DataSheet> {
        return this.settingsDialogService.getLicenseAgreement().pipe(tap((sheets) => (this.agreementLicense = sheets)));
    }

    setOptician(match: TypeaheadMatch): void {
        this.selectedOptician = match.item;
        this.formGroupAccountSettings.controls['currentOpticianId'].patchValue(this.selectedOptician.Id);
    }

    setDistributor(match: TypeaheadMatch): void {
        this.selectedDistributor = match.item;
        this.formGroupAccountSettings.controls['distributor'].patchValue(this.selectedDistributor.Id);
    }

    async saveUserSettings(): Promise<void> {
        let currentLangChanged = false;

        this.accountSettingsLoading = true;
        this.loaderService.show();

        const settings = { ...this.userSetting };

        settings.CurrentApplicationTheme = this.appState.authenticatedUser.CurrentApplicationTheme;

        const selectedLanguageId = Number(this.formGroupAccountSettings.controls['language'].value);
        if (settings.CurrentLanguageId !== selectedLanguageId) {
            settings.CurrentLanguageId = selectedLanguageId;
            currentLangChanged = true;

            const langValue = this.languages.filter((key) => key.Id === selectedLanguageId);
            if (langValue.length === 1) {
                localStorage.setItem('language', langValue[0].Code.split('-')[0]);
            }
        }
        settings.CurrentRoleId = this.formGroupAccountSettings.controls['role'].value;

        if (this.selectedOptician != null) {
            settings.CurrentOpticianId = this.selectedOptician.Id;
        }

        if (this.showDistributors) {
            settings.DistributorId = this.formGroupAccountSettings.controls['distributor'].value;
        }

        const roleChanged = settings.CurrentRoleId !== this.appState.authenticatedUser.CurrentRoleId;
        const opticianChanged =
            settings.CurrentRoleId === Roles.Optician &&
            settings.CurrentOpticianId &&
            (!this.appState.currentOptician || this.appState.currentOptician.Id !== settings.CurrentOpticianId);
        const distributorChanged =
            settings.CurrentRoleId === Roles.Ps &&
            settings.DistributorId &&
            (!this.appState.currentDistributor || this.appState.currentDistributor.Id !== settings.DistributorId);

        this.appState.setVertexSetting(settings.VertexDistance);

        const user = await lastValueFrom(this.userSettingService.saveUserSettings(settings));

        if (distributorChanged) {
            const result = await lastValueFrom(this.userService.getUserById(user.UserId));
            result.DistributorId = settings.DistributorId;
            await lastValueFrom(this.userService.save(result));
        }

        this.accountSettingsLoading = false;
        this.appState.onUserSettingChange(user);

        if (roleChanged || opticianChanged || distributorChanged) {
            this.bsModalRef.hide();
            await this.initialNavigationService.startInitialNavigationRoute();
        }

        if (
            (roleChanged || opticianChanged || distributorChanged) &&
            (user.CurrentRoleId === Roles.Optician || user.CurrentRoleId === Roles.Ps)
        ) {
            await this.appState.loadCurrentAuthenticatedUserItems();
        }

        if (currentLangChanged) {
            this.loadLanguages().subscribe();
            this.userService.languageChanged();
            this.appState.applyUserLanguage();
        }

        if ((Number(this.formGroupAccountSettings.controls['role'].value) as Roles) === Roles.Ps) {
            this.loadDistributors();
        }

        this.alertService.success(this.translate.instant('general.saveSuccessful'), true);
        this.loaderService.hide();
    }

    opticianMustBeSelected(control: AbstractControl): ValidationErrors {
        const selectedRole = Number(control.get('role').value) as Roles;
        if (selectedRole === Roles.Optician && !control.get('currentOpticianId').value) {
            return { opticianMustBeSelected: false };
        }
        return null;
    }

    get showCurrentOptician(): boolean {
        const selectedRole = Number(this.formGroupAccountSettings.controls['role'].value) as Roles;
        return this.opticians && this.opticians.length > 1 && selectedRole === Roles.Optician;
    }

    get showDistributors(): boolean {
        const selectedRole = Number(this.formGroupAccountSettings.controls['role'].value) as Roles;
        return this.distributors && this.distributors.length > 1 && selectedRole === Roles.Ps;
    }

    get currentOpticianHasErrorsClass(): string {
        return this.appState.authenticatedUser.CurrentRoleId === Roles.Optician &&
            !(this.selectedOptician && !this.formGroupAccountSettings.controls['currentOpticianId'].errors)
            ? 'error'
            : '';
    }
}
