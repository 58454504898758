import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { TopographicMeasurement } from '@app/shared/models';
import { TopographicImage } from '@app/shared/models/topographicImage.model';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ImageOptions } from '@app/shared/models/image-options.model';
import { TopographicImageService } from '@app/core/services/api/topographic-image.service';

@Component({
    selector: 'topo-image-thumbnail',
    templateUrl: 'topo-image-thumbnail.component.html',
    styleUrls: ['topo-image-thumbnail.component.scss'],
})
export class TopoImageThumbnailComponent implements OnInit, OnDestroy {
    @Input() id: string;
    @Input() measurement: TopographicMeasurement;
    @Input() imageOptions: ImageOptions;
    @Input() previousMeasurementId: number;

    @Output() imageClicked$: EventEmitter<TopographicMeasurement> = new EventEmitter();

    imageRequest: Subscription;

    imageData = '';
    loading = false;

    constructor(private readonly topographicImageService: TopographicImageService) {}

    ngOnInit(): void {
        if (this.imageRequest) {
            this.imageRequest.unsubscribe();
        }

        if (this.measurement) {
            if (this.measurement.Base64Thumbnail) {
                this.imageData = this.measurement.Base64Thumbnail;
                return;
            }

            this.loading = true;

            if (!this.imageOptions) {
                this.imageOptions = new ImageOptions(null);
            }

            const topographicImage = new TopographicImage();
            topographicImage.TopographicMeasurementId = this.measurement.Id;
            topographicImage.TopographicImageOptions = this.imageOptions.topoImageOptions;
            topographicImage.PreviousTopographicMeasurementId = this.previousMeasurementId
                ? this.previousMeasurementId
                : this.measurement.Id;

            this.imageRequest = this.topographicImageService
                .createTopographicThumbnailImage(topographicImage)
                .pipe(
                    finalize(() => {
                        this.loading = false;
                    }),
                )
                .subscribe((result) => {
                    this.imageData = result as string;
                });
        }
    }

    imageClick(): void {
        this.imageClicked$.emit(this.measurement);
    }

    ngOnDestroy(): void {
        if (this.imageRequest) {
            this.imageRequest.unsubscribe();
        }
    }
}
