import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FooterService {
    private state = new BehaviorSubject<TemplateRef<unknown> | null>(null);
    readonly contents = this.state.asObservable();

    setContents(ref: TemplateRef<unknown>): void {
        this.state.next(ref);
    }

    clearContents(): void {
        this.state.next(null);
    }
}
