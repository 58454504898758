import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'tips-dialog',
    templateUrl: 'tips-dialog.component.html',
})
export class TipsDialogComponent {
    @Input() title: string;
    @Input() content: string;

    constructor(public bsModalRef: BsModalRef) {}

    close(): void {
        this.bsModalRef.hide();
    }
}
