import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListOption } from '@app/shared/models';
import { LensDefinitionListOption } from '@app/shared/models/lensDefinitionListOption.model';
import { ListOptionParameter } from '@app/shared/models/listOptionParameter.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LensService {
    private readonly basePath = 'api/lenses';

    constructor(private readonly httpClient: HttpClient) {}

    public getLensEngravings(eyeSideId: number): Observable<ListOption[]> {
        const options = {
            params: new HttpParams().set('eyeSideId', eyeSideId.toString()),
        };

        return this.httpClient.get<ListOption[]>(`${this.basePath}/engravings/options`, options);
    }

    public getLensTypes(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/types/options`);
    }

    public getAvailableLensTypes(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/available-types/options`);
    }

    public getUnusedParameterTypes(
        lensDefinitionId?: number,
        lensDefinitionParameterId?: number,
    ): Observable<ListOptionParameter[]> {
        let httpParams = new HttpParams();

        if (lensDefinitionId) {
            httpParams = httpParams.set('lensDefinitionId', lensDefinitionId.toString());
        }

        if (lensDefinitionParameterId) {
            httpParams = httpParams.set('lensDefinitionParameterId', lensDefinitionParameterId.toString());
        }

        const options = {
            params: httpParams,
        };

        return this.httpClient.get<ListOptionParameter[]>(`${this.basePath}/unused-parameters-types/options`, options);
    }

    public getLensDefinitions(
        opticianId: number,
        lensTypeId: number,
        lensDefinitionId: number,
    ): Observable<LensDefinitionListOption[]> {
        const options = {
            params: new HttpParams()
                .set('opticianId', opticianId.toString())
                .set('lensTypeId', lensTypeId.toString())
                .set('lensDefinitionId', lensDefinitionId.toString()),
        };

        return this.httpClient.get<LensDefinitionListOption[]>(`${this.basePath}/definitions/options`, options);
    }

    public getNaturalFitTypes(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/natural-fit-types/options`);
    }

    public getLensThickness(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/thicknesses/options`);
    }
}
