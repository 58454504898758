import { Injectable, EventEmitter } from '@angular/core';
import { FittedLensChange } from '@app/fitlens/models/fittedLensChange.model';
import { LensParameterChange } from '@app/fitlens/models/lensParameterChange.model';
import { QuantityChange } from '@app/fitlens/models/quantityChange.model';

@Injectable()
export class FittingEventService {
    fittedLensChanged: EventEmitter<FittedLensChange> = new EventEmitter();
    lensParameterChanged: EventEmitter<LensParameterChange> = new EventEmitter();
    quantityChanged: EventEmitter<QuantityChange> = new EventEmitter();
}
