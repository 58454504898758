import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Attachment } from '@app/shared/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RemoveAttachmentResponse } from '@app/shared/models/remove-attachment-response.model';

@Injectable({ providedIn: 'root' })
export class AttachmentService {
    private readonly basePath = 'api/attachments';

    constructor(private readonly httpClient: HttpClient) {}

    public getAttachmentsForClient(clientId: number): Observable<Attachment[]> {
        const options = {
            params: new HttpParams().set('clientId', clientId).set('IncludeInternalAttachments', false),
        };

        return this.httpClient.get<Attachment[]>(this.basePath, options);
    }

    public getAllAttachmentsForClient(clientId: number): Observable<Attachment[]> {
        const options = {
            params: new HttpParams().set('clientId', clientId).set('IncludeInternalAttachments', true),
        };

        return this.httpClient.get<Attachment[]>(this.basePath, options);
    }

    public downloadAttachment(attachmentId: number, mimeType: string): Observable<Blob> {
        return this.httpClient
            .get<Blob>(`${this.basePath}/${attachmentId}/download`, {
                responseType: 'blob' as 'json',
            })
            .pipe(
                map((result: Blob) => {
                    return new Blob([result], { type: mimeType });
                }),
            );
    }

    public removeAttachment(attachmentId: number): Observable<RemoveAttachmentResponse> {
        return this.httpClient.delete<RemoveAttachmentResponse>(`${this.basePath}/${attachmentId}`);
    }
}
