<div class="step d-flex {{ navbarClass }}">
    <div class="step-inner p-2 d-flex align-items-center">
        <div class="d-flex align-items-center mx-auto">
            <div class="step-icon d-flex align-items-center">
                <i *ngIf="navbarItem.iconName" class="fa fa-2x" [ngClass]="'fa-' + navbarItem.iconName"></i>
                <img
                    alt="header"
                    *ngIf="navbarItem.imageName"
                    class="headericon"
                    src="assets/images/{{ navbarItem.imageName }}" />
            </div>
            <div class="ms-2 d-none d-md-block">
                <div class="text-truncate" *ngIf="navbarItem.title" title="{{ navbarItem.title }}">
                    {{ navbarItem.title | truncate: 15 }}
                </div>
                <div class="text-truncate" *ngIf="navbarItem.subtitle" title="{{ navbarItem.subtitle }}">
                    <strong>{{ navbarItem.subtitle | truncate: 15 }}</strong>
                </div>
            </div>
        </div>

        <div class="divider align-self-center ms-4"></div>
    </div>
</div>
