import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ReturnStatusChange } from '@app/shared/models/returnStatusChange';

@Component({
    selector: 'app-status-change',
    templateUrl: './status-change.component.html',
    styleUrls: ['./status-change.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StatusChangeComponent implements OnInit, OnChanges {
    formGroup: UntypedFormGroup;

    requestData = ['NewStatus', 'IsCreatedOn', 'IsCreatedByUserName'];

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.createForm();
    }

    @Input()
    returnStatuses: ReturnStatusChange[];

    createForm(): void {
        this.formGroup = this.fb.group({
            NewStatus: [],
            IsCreatedOn: [],
            IsCreatedByUserName: [],
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.returnStatuses.previousValue !== changes.returnStatuses.currentValue) {
            this.returnStatuses = changes.returnStatuses.currentValue;
        }
    }
}
