import { Component, DestroyRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderService } from '@app/core/services/api/order.service';
import { WorkItemsService } from '@app/core/services/api/work-items.service';
import { WorkItem } from '@app/professional-service/models/WorkItem.model';
import { Client, Optician, Proposal, OrderWithComplexData } from '@app/shared/models';
import { ProposalService } from '@app/core/services/api/proposal.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SupportOrderService } from '@app/core/services/api/support-order.service';
import { SupportOrderStatus } from '@app/professional-service/models/SupportOrderStatus.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { WorkitemAlreadyAssignedDialogComponent } from './modals/workitem-already-assigned-dialog.component';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { FittingService } from '@app/core/services/fitting.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';
import IPsSupportOrderComponent from './ps-supportorder.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    templateUrl: './ps-supportorder.component.html',
    styleUrls: ['ps-supportorder.component.scss'],
})
export class PsSupportOrderComponent implements IPsSupportOrderComponent, OnInit, OnDestroy {
    @ViewChild('psHeader') psHeader;

    public currentPage = 'order';
    public messageCacheKeyExternalNotes = 'ps-supportorder-externalnotes';
    public messageCacheKeyInternalNotes = 'ps-supportorder-internalnotes';

    public workItem: WorkItem;
    private proposal: Proposal;
    private supportOrderStatuses: SupportOrderStatus[];

    public order: OrderWithComplexData;
    public formGroup: UntypedFormGroup;
    public loading = true;
    public sessionStorageKeys: string[] = [];
    private readonly destroyRef = inject(DestroyRef);

    constructor(
        private readonly fittingService: FittingService,
        private readonly fb: UntypedFormBuilder,
        private orderService: OrderService,
        private readonly workitemsService: WorkItemsService,
        private readonly proposalService: ProposalService,
        private readonly supportOrderService: SupportOrderService,
        public appState: AppStateService,
        private readonly modalService: BsModalService,
        private route: ActivatedRoute,
        public router: Router,
    ) {}

    ngOnDestroy(): void {
        this.sessionStorageKeys.forEach((key) => {
            if (sessionStorage.getItem(key)) {
                sessionStorage.removeItem(key);
            }
        });
    }

    ngOnInit() {
        this.createForm();

        this.loading = true;

        this.loadWorkItemIdFromURL()
            .pipe(
                switchMap((workItemId) => this.loadWorkItem(workItemId)),
                switchMap((workItem) =>
                    forkJoin([
                        this.loadProposal(workItem),
                        this.loadOrder(workItem),
                        this.loadSupportOrderStatuses(),
                    ]).pipe(map(() => workItem)),
                ),
                tap(() => (this.loading = false)),
                switchMap((workItem) => {
                    if (workItem.SupporterId === null || workItem.SupporterId === 0) {
                        return this.workitemsService.updateSupporter(
                            workItem.Id,
                            this.appState.authenticatedUser.UserId,
                        );
                    } else if (this.workItem.SupporterId !== this.appState.authenticatedUser.UserId) {
                        return this.popAlreadyInTreatmentModal().pipe(map(() => workItem));
                    } else {
                        return of(workItem);
                    }
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
    }

    createForm(): void {
        this.formGroup = this.fb.group({
            'modify-od': [],
            'modify-os': [],
        });
    }

    private loadWorkItemIdFromURL(): Observable<number> {
        return this.route.params.pipe(
            map((params) => +params['id']),
            tap((id) => {
                if (isNaN(id)) {
                    this.router.navigate(['/']);
                }
            }),
            filter((id) => !isNaN(id)),
        );
    }

    private loadSupportOrderStatuses(): Observable<SupportOrderStatus[]> {
        return this.supportOrderService
            .getSupportOrderStatuses()
            .pipe(tap((supportOrderStatuses) => (this.supportOrderStatuses = supportOrderStatuses)));
    }

    private loadWorkItem(workItemId: number): Observable<WorkItem> {
        return this.workitemsService.getWorkItem(workItemId).pipe(
            tap((workItem) => (this.workItem = workItem)),
            tap((workItem) => this.fittingService.setClient(workItem.Client)),
        );
    }

    private loadProposal(workItem: WorkItem): Observable<Proposal> {
        return this.proposalService.getById(workItem.ProposalId).pipe(tap((proposal) => (this.proposal = proposal)));
    }

    public loadOrder(workItem: WorkItem): Observable<OrderWithComplexData> {
        return this.orderService.getByIdWithComplexData(workItem.Id).pipe(tap((order) => (this.order = order)));
    }

    getCurrentPage() {
        return this.currentPage;
    }

    setCurrentPage(currentPage: string): void {
        this.currentPage = currentPage;
    }

    getClient(): Client {
        return this.workItem ? this.workItem.Client : null;
    }

    getOptician(): Optician {
        return this.workItem ? this.workItem.Optician : null;
    }

    public getWorkItemId(): number {
        return this.workItem.Id;
    }

    public getProposal(): Proposal {
        return this.proposal;
    }

    public getOrder(): OrderWithComplexData {
        return this.order;
    }

    public getSupportOrderStatus(): SupportOrderStatus {
        if (this.order && this.supportOrderStatuses) {
            return this.supportOrderStatuses.find((sos) => sos.Id === this.order.SupportOrderStatusId);
        }

        return null;
    }

    getSupporterId() {
        return this.order.SupporterId;
    }

    getSelectedSupporter(): number {
        return this.psHeader.selectedSupporterId;
    }

    popAlreadyInTreatmentModal(): Observable<unknown> {
        const modalOptions: unknown = {
            initialState: {
                userId: this.appState.authenticatedUser.UserId,
                orderId: this.workItem.Id,
                onClose: (result) => this.psHeader.updateSupporterForm(result),
            },
            class: 'workitem-already-assigned-dialog',
        };

        return this.modalService.show(WorkitemAlreadyAssignedDialogComponent, modalOptions).onHidden;
    }
}
