<div *ngIf="loading" class="spinner-border spinner-border-sm mx-1"></div>

<ng-container *ngIf="!loading">
    <div class="ps-header">
        <mpc-navbar [navbaritems]="headeritems" (switchPage)="switch($event)"></mpc-navbar>
    </div>

    <div class="wrapper flex-fill d-flex flex-column text-left align-content-stretch">
        <ng-container *ngIf="client">
            <ps-clientmaintenance-details *ngIf="tab === 0"></ps-clientmaintenance-details>
            <ps-measurements class="p-4" *ngIf="tab === 1" [client]="client"></ps-measurements>
            <ps-clientlastorder *ngIf="tab === 2" [client]="client"></ps-clientlastorder>
            <ps-clientattachments *ngIf="tab === 3" [client]="client"></ps-clientattachments>
            <ps-clientactions *ngIf="tab === 4" [client]="client"></ps-clientactions>
        </ng-container>
    </div>
</ng-container>
