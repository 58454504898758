import { Injectable } from '@angular/core';
import { EyeliteFileImport } from '@app/professional-service/models/eyeliteimport/eyelite-file-import.model';

@Injectable({
    providedIn: 'root',
})
export class EyeliteImportService {
    private eyeliteFileImport: EyeliteFileImport;

    public setEyeliteFileImport(eyeliteFileImport: EyeliteFileImport): void {
        this.eyeliteFileImport = eyeliteFileImport;
    }

    public getEyeliteFileImport(): EyeliteFileImport {
        return this.eyeliteFileImport;
    }

    public clearEyeliteFileImport(): void {
        this.eyeliteFileImport = null;
    }
}
