import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LensDefinition, ListOptionOptician, Optician } from '@app/shared/models';
import { Observable } from 'rxjs';
import { RegisterOpticianRequest } from '@app/shared/models/registerOpticianRequest.model';
import { OpticianProductSetting } from '@app/shared/models/OpticianProductSetting.model';
import { OpticianSetting } from '@app/shared/models/opticianSetting.model';

@Injectable({
    providedIn: 'root',
})
export class OpticianService {
    private readonly basePath = 'api/opticians';

    constructor(private readonly httpClient: HttpClient) {}

    public getOpticians(): Observable<ListOptionOptician[]> {
        return this.httpClient.get<ListOptionOptician[]>(`${this.basePath}/options`);
    }

    public getOpticiansByUserName(userName: string): Observable<ListOptionOptician[]> {
        const options = {
            params: new HttpParams().set('userName', userName),
        };

        return this.httpClient.get<ListOptionOptician[]>(`${this.basePath}/options`, options);
    }

    public getById(opticianId: number): Observable<Optician> {
        return this.httpClient.get<Optician>(`${this.basePath}/${opticianId}`);
    }

    public save(optician: Optician): Observable<Optician> {
        return this.httpClient.post<Optician>(`${this.basePath}`, optician);
    }

    public remove(opticianId: number, hardDelete: boolean): Observable<boolean> {
        const options = {
            params: new HttpParams().set('hard-delete', hardDelete),
        };

        return this.httpClient.delete<boolean>(`${this.basePath}/${opticianId}`, options);
    }

    public register(request: RegisterOpticianRequest): Observable<void> {
        return this.httpClient.post<void>(`${this.basePath}/register`, request);
    }

    public getLensDefinitions(): Observable<LensDefinition[]> {
        return this.httpClient.get<LensDefinition[]>(`${this.basePath}/lens-definitions`);
    }

    public saveOpticianProductSetting(
        opticianProductSetting: OpticianProductSetting,
    ): Observable<OpticianProductSetting> {
        return this.httpClient.post<OpticianProductSetting>(
            `${this.basePath}/product-settings`,
            opticianProductSetting,
        );
    }

    public saveOpticianProductSettings(
        opticianProductSettings: OpticianProductSetting[],
    ): Observable<OpticianProductSetting[]> {
        return this.httpClient.post<OpticianProductSetting[]>(
            `${this.basePath}/product-setting-list`,
            opticianProductSettings,
        );
    }

    public getOpticianSettings(opticianId: number): Observable<OpticianSetting> {
        const options = {
            params: new HttpParams().set('opticianId', opticianId),
        };

        return this.httpClient.get<OpticianSetting>(`${this.basePath}/settings`, options);
    }

    public saveOpticianSettings(opticianSettings: OpticianSetting): Observable<OpticianSetting> {
        return this.httpClient.post<OpticianSetting>(`${this.basePath}/settings`, opticianSettings);
    }
}
