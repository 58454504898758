import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DreamLiteWizardFixType } from '@app/shared/models/dreamLiteWizardFixType.model';
import { InputNumberRange } from '@app/shared/models/input-number-range.model';
import { DreamLiteWizardOpticianInput } from '@app/shared/models/dreamLiteWizardOpticianInput.model';
import { FittedLens } from '@app/shared/models/fitted-lens.model';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DreamLiteWizardService } from '@app/core/services/api/dreamlite-wizard.service';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'dreamlitefixtypes-dialog',
    templateUrl: './dreamlitefixtypes-dialog.component.html',
    styleUrls: ['./dreamlitefixtypes-dialog.component.scss'],
})
export class DreamlitefixtypesDialogComponent implements OnInit {
    @Input() fittedLens: FittedLens;

    formGroup: UntypedFormGroup;
    fixTypes: DreamLiteWizardFixType[];
    fixTypeRanges: { [code: string]: InputNumberRange[] };
    selectedFixType: DreamLiteWizardFixType;
    isCancelled = false;

    constructor(
        private bsModalRef: BsModalRef,
        private readonly dreamLiteWizardService: DreamLiteWizardService,
        private readonly fb: UntypedFormBuilder,
    ) {}

    async ngOnInit() {
        this.selectedFixType = null;
        this.createForm();
        await this.loadFixTypes();
    }

    createForm(): void {
        this.formGroup = this.fb.group({
            selectedFixType: [this.selectedFixType],
        });
    }

    async loadFixTypes(): Promise<void> {
        const result = await lastValueFrom(
            this.dreamLiteWizardService.getAllFixTypes(this.fittedLens.LensDefinitionId),
        );

        this.fixTypes = new Array<DreamLiteWizardFixType>();
        this.fixTypeRanges = {};

        result.forEach((item) => {
            item.Visible = true;
            item.DegreeValue = 2;
            item.CorrectionValue = 0;
            item.IsApplied = false;

            if (item.ShowCorrection) {
                const rangeModel = new InputNumberRange(item.MinValue, item.MaxValue, item.Step);
                this.fixTypeRanges[item.Code] = new Array<InputNumberRange>(rangeModel);
            }
            this.addFormControl(item);
            this.fixTypes.push(item);
        });
        this.updateFixTypeVisibility();
    }

    addFormControl(item: DreamLiteWizardFixType) {
        if (item.ShowCorrection) {
            this.formGroup.addControl(item.Code, new UntypedFormControl());
        }
        if (item.ShowDegree) {
            this.formGroup.addControl(item.Code, new UntypedFormControl(1));
        }
    }

    updateFixTypeVisibility() {
        this.fixTypes.forEach((fixType: DreamLiteWizardFixType) => {
            fixType.Visible = true;
            if (fixType.Code === 'ToMultifocal' && this.isMultiFocal()) {
                fixType.Enabled = false;
                fixType.Visible = false;
            } else if (fixType.Code === 'SetOZ' && this.isMultiFocal()) {
                fixType.Enabled = false;
                fixType.Visible = false;
            } else if (fixType.Code === 'MultiFocalEffect' && !this.isMultiFocal()) {
                fixType.Enabled = false;
                fixType.Visible = false;
            } else if (fixType.Code === 'ConvertDreamliteMC') {
                fixType.Enabled = false;
                fixType.Visible = false;
            }
        });
    }

    isMultiFocal(): boolean {
        return (
            this.fittedLens !== null &&
            this.fittedLens.LensDefinition !== null &&
            this.fittedLens.LensDefinition.MultiFocal
        );
    }

    ok() {
        if (
            this.formGroup.controls['selectedFixType'].value !== null ||
            this.formGroup.controls['selectedFixType'].value !== ''
        ) {
            const selectedFixTypeCode = this.formGroup.controls['selectedFixType'].value;
            const selectedFixType = this.fixTypes.filter((ft) => ft.Code === selectedFixTypeCode)[0];
            if (selectedFixType !== null) {
                this.selectedFixType = selectedFixType;
            }
        }

        if (this.selectedFixType === null) {
            this.bsModalRef.hide();
            return;
        }

        if (this.selectedFixType.ShowCorrection) {
            this.selectedFixType.CorrectionValue = this.formGroup.controls[this.selectedFixType.Code].value;
        }
        if (this.selectedFixType.ShowDegree) {
            this.selectedFixType.DegreeValue = this.formGroup.controls[this.selectedFixType.Code].value;
        }

        const dreamLiteWizardOpticianInput = new DreamLiteWizardOpticianInput();
        dreamLiteWizardOpticianInput.FittedLens = this.fittedLens;
        dreamLiteWizardOpticianInput.FixTypesToApply = [this.selectedFixType];

        this.dreamLiteWizardService.calculateLensParametersForOptician(dreamLiteWizardOpticianInput).subscribe(
            (result) => {
                this.fittedLens = result;
                this.bsModalRef.hide();
            },
            () => {
                this.cancel();
            },
        );
    }

    cancel() {
        this.isCancelled = true;
        this.bsModalRef.hide();
    }

    get filteredFixTypes(): DreamLiteWizardFixType[] {
        let result: DreamLiteWizardFixType[] = [];
        if (this.fixTypes) {
            result = this.fixTypes.filter((ft) => ft.Visible);
        }
        return result;
    }

    correctionChanged(fixType: DreamLiteWizardFixType): void {
        this.selectedFixType = fixType;
        this.formGroup.controls['selectedFixType'].patchValue(fixType.Code);
    }
}
