import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    Distributor,
    DistributorProductSetting,
    ListOption,
    ListSelectOption,
    ProductDistributorArticleNumber,
} from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class DistributorService {
    private readonly basePath = 'api/distributors';

    constructor(private readonly httpClient: HttpClient) {}

    public getById(distributorId: number): Observable<Distributor> {
        return this.httpClient.get<Distributor>(`${this.basePath}/${distributorId}`);
    }

    public save(distributor: Distributor): Observable<Distributor> {
        return this.httpClient.post<Distributor>(this.basePath, distributor);
    }

    public getDistributorProductSettings(distributorId: number): Observable<DistributorProductSetting[]> {
        return this.httpClient.get<DistributorProductSetting[]>(`${this.basePath}/${distributorId}/product-settings`);
    }

    public saveDistributorProductSettings(
        distributorProductSettings: DistributorProductSetting[],
    ): Observable<Distributor> {
        return this.httpClient.post<Distributor>(`${this.basePath}/product-settings`, distributorProductSettings);
    }

    public getDistributorCountries(distributorId: number): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/${distributorId}/countries`);
    }

    public saveDistributorCountries(distributor: Distributor): Observable<Distributor> {
        return this.httpClient.post<Distributor>(`${this.basePath}/countries`, distributor);
    }

    public getDistributors(): Observable<ListSelectOption[]> {
        return this.httpClient.get<ListSelectOption[]>(`${this.basePath}/options`);
    }

    public setDistributorApiKey(distributorId: number): Observable<{ ApiKey: string }> {
        return this.httpClient.post<{ ApiKey: string }>(`${this.basePath}/${distributorId}/set-api-key`, null);
    }

    public getArticleNumber(productId: number, distributorId: number): Observable<ProductDistributorArticleNumber> {
        const options = {
            params: new HttpParams().set('productId', productId).set('distributorId', distributorId),
        };

        return this.httpClient.get<ProductDistributorArticleNumber>(
            `${this.basePath}/product-settings/article-number`,
            options,
        );
    }

    public saveArticleNumber(value: ProductDistributorArticleNumber): Observable<void> {
        return this.httpClient.post<void>(`${this.basePath}/product-settings/article-number`, value);
    }
}
