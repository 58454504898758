import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FittingService } from '@app/core/services/fitting.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { CreateOrder, FittedLensParameter, Client, Proposal } from '@app/shared/models';
import { ProposalReceiptService } from '@app/core/services/api/proposal-receipt.service';
import { OrderService } from '@app/core/services/api/order.service';
import { ProposalOptions } from '@app/shared/models/proposalOptions.model';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { Features, FeatureCategories, Treatments } from '@app/shared/enums';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'fitting-glass-overview',
    templateUrl: 'fitting-glass-overview.component.html',
    styleUrls: ['fitting-glass-overview.component.scss'],
})
export class FittingGlassOverviewComponent implements OnInit {
    formGroup: UntypedFormGroup;

    Features = Features;
    FeatureCategories = FeatureCategories;

    get formControls() {
        return this.formGroup.controls;
    }

    get client(): Client {
        return this.fittingService.state.client;
    }

    get proposal(): Proposal {
        return this.fittingService.state.proposal;
    }

    get fitlensRightEyeSelected(): boolean {
        return this.fittingService.state.isRightEyeSideSelected;
    }

    get fitlensLeftEyeSelected(): boolean {
        return this.fittingService.state.isLeftEyeSideSelected;
    }

    constructor(
        private readonly fittingService: FittingService,
        public fb: UntypedFormBuilder,
        public router: Router,
        public appState: AppStateService,
        public loaderService: LoaderService,
        public orderService: OrderService,
        public translate: TranslateService,
        public proposalReceiptService: ProposalReceiptService,
    ) {}

    ngOnInit() {
        this.fittingService.setFittingStep(this.router.url);

        if (!this.client || !this.proposal) {
            this.router.navigate(['/']).then();
        }

        this.createForm();
    }

    get perosBranchNumber(): string {
        return this.appState.getPerosBranchNumber;
    }

    createForm(): void {
        const leftValidators = [];
        if (this.fitlensLeftEyeSelected) {
            leftValidators.push(Validators.required);
        }

        const rightValidators = [];
        if (this.fitlensRightEyeSelected) {
            rightValidators.push(Validators.required);
        }

        let perosOrderNumberValidators = [];
        if (this.perosBranchNumber) {
            perosOrderNumberValidators = [
                Validators.required,
                Validators.maxLength(6),
                Validators.pattern('^(?=.*\\d)[\\d]{1,}$'),
            ];
        }

        this.formGroup = this.fb.group({
            perosOrderNumber: ['', perosOrderNumberValidators],
        });
    }

    getFormatObject(param: FittedLensParameter): object {
        return {
            parameterType: param.LensDefinitionParameter.ParameterType.Code,
        };
    }

    previous($event: MouseEvent): void {
        $event.preventDefault();
        this.fittingService.gotoPreviousStep();
    }

    get glassTreatment(): string {
        if (this.proposal) {
            return this.fittingService.getGlassTreatmentName(this.proposal.GlassTreatmentId);
        }
        return '';
    }

    get frameBrand(): string {
        if (this.proposal && this.proposal.Frame) {
            return this.proposal.Frame.Brand;
        }
        return '';
    }

    get frameModel(): string {
        if (this.proposal && this.proposal.Frame) {
            return this.proposal.Frame.Model;
        }
        return '';
    }

    get frameMaterial(): string {
        if (this.proposal && this.proposal.Frame) {
            return this.proposal.Frame.Material;
        }
        return '';
    }

    get frameColor(): string {
        if (this.proposal && this.proposal.Frame) {
            return this.proposal.Frame.Color;
        }
        return '';
    }

    get pupilDistanceLeft(): string {
        if (this.proposal && this.proposal.Frame) {
            return this.proposal.Frame.PupilDistanceLeft.toString();
        }
        return '';
    }

    get pupilDistanceRight(): string {
        if (this.proposal && this.proposal.Frame) {
            return this.proposal.Frame.PupilDistanceRight.toString();
        }
        return '';
    }

    get ocularCenterLeft(): string {
        if (this.proposal && this.proposal.Frame) {
            return this.proposal.Frame.OcularCenterLeft.toString();
        }
        return '';
    }

    get ocularCenterRight(): string {
        if (this.proposal && this.proposal.Frame) {
            return this.proposal.Frame.OcularCenterRight.toString();
        }
        return '';
    }

    get frameSizeBoxA(): string {
        if (this.proposal && this.proposal.Frame) {
            return this.proposal.Frame.SizeBoxA.toString();
        }
        return '';
    }

    get frameSizeBoxB(): string {
        if (this.proposal && this.proposal.Frame) {
            return this.proposal.Frame.SizeBoxB.toString();
        }
        return '';
    }

    get frameDblDimension(): string {
        if (this.proposal && this.proposal.Frame) {
            return this.proposal.Frame.DblDimension.toString();
        }
        return '';
    }

    get frameTracerSizeBoxA(): string {
        if (this.proposal && this.proposal.Frame) {
            return this.proposal.Frame.TracerSizeBoxA.toString();
        }
        return '';
    }

    get frameTracerSizeBoxB(): string {
        if (this.proposal && this.proposal.Frame) {
            return this.proposal.Frame.TracerSizeBoxB.toString();
        }
        return '';
    }

    get frameTracerDblDimension(): string {
        if (this.proposal && this.proposal.Frame) {
            return this.proposal.Frame.TracerDblDimension.toString();
        }
        return '';
    }

    get bevelType(): string {
        if (this.proposal && this.proposal.Frame) {
            return this.proposal.Frame.BevelType;
        }
        return '';
    }

    get bevelPosition(): string {
        return this.proposal?.Frame?.BevelPosition?.toString();
    }

    get edgePolish(): string {
        if (this.proposal && this.proposal.Frame && this.proposal.Frame.EdgePolish) {
            return this.translate.instant('general.yes');
        }
        return this.translate.instant('general.no');
    }

    get fileAvailable(): string {
        if (this.proposal && this.proposal.Frame && this.proposal.Frame.TraceFile) {
            return this.translate.instant('general.yes');
        }
        return this.translate.instant('general.no');
    }

    showFrameControls(): boolean {
        if (this.proposal) {
            return this.proposal.GlassTreatmentId !== Treatments.none;
        }

        return false;
    }

    remoteEdgingSelected(): boolean {
        if (this.proposal) {
            return this.proposal.GlassTreatmentId === Treatments.remoteEdging;
        }

        return false;
    }

    remoteEdgingAndFittingSelected(): boolean {
        if (this.proposal) {
            return this.proposal.GlassTreatmentId === Treatments.remoteEdgingAndFitting;
        }

        return false;
    }

    treatmentNoneSelected(): boolean {
        if (this.proposal) {
            return this.proposal.GlassTreatmentId === Treatments.none;
        }

        return false;
    }

    private getPerosOrderNumber(): string {
        return (
            ('00000' + this.perosBranchNumber).slice(-5) +
            ('000000' + this.formGroup.controls['perosOrderNumber'].value).slice(-6)
        );
    }

    async next(): Promise<void> {
        this.loaderService.show();

        const createOrder = new CreateOrder();

        createOrder.Proposal = structuredClone(this.proposal);
        createOrder.Proposal.LeftOpticianFittedLens = this.fitlensLeftEyeSelected
            ? createOrder.Proposal.LeftOpticianFittedLens
            : null;
        createOrder.Proposal.RightOpticianFittedLens = this.fitlensRightEyeSelected
            ? createOrder.Proposal.RightOpticianFittedLens
            : null;
        createOrder.SkipOrderEvaluation = !createOrder.Proposal.OrderWithSupport;

        if (createOrder.Proposal.LeftOpticianFittedLens && createOrder.Proposal.LeftOpticianFittedLens) {
            createOrder.Proposal.LeftProposalOptions = new ProposalOptions();
            createOrder.Proposal.LeftProposalOptions.Quantity = 1;
        }
        if (createOrder.Proposal.RightOpticianFittedLens && createOrder.Proposal.RightOpticianFittedLens) {
            createOrder.Proposal.RightProposalOptions = new ProposalOptions();
            createOrder.Proposal.RightProposalOptions.Quantity = 1;
        }

        if (this.perosBranchNumber) {
            createOrder.PerosOrderNumber = this.getPerosOrderNumber();
        }

        const order = await lastValueFrom(this.orderService.createOrder(createOrder));

        this.fittingService.state.orderId = order.Id;
        this.fittingService.clearSaveForContinationConfiguration();

        this.loaderService.hide();
        this.fittingService.gotoNextStep();
        this.fittingService.clear();
    }

    async createReceipt(): Promise<void> {
        this.loaderService.show();
        const receipt = await lastValueFrom(this.proposalReceiptService.createProposalReceipt(this.proposal.Id));
        this.fittingService.storeReceiptIdAndClearSaveConfiguration(receipt.Id);
        this.loaderService.hide();
        this.fittingService.gotoNextStep();
        this.fittingService.clear();
    }
}
