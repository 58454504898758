export enum ReturnType {
    ReplOrdered = 1,
    WrongColor = 2,
    Truncate = 3,
    Other1 = 4,
    LensBroken = 5,
    SerialNumberCheck = 6,
    MeasureParams = 7,
    EdgeBroken = 8,
    VisualAcuity = 9,
    Comfort = 10,
    Other = 11,
    Cleaning = 12,
    BlendEdge = 13,
    Engraving = 14,
    CustomerStopped = 15,
    Complication = 16,
}
