import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class VersionInfoService {
    private readonly basePath = 'api/version-infos';

    constructor(private readonly httpClient: HttpClient) {}

    public getVersionInfo(): Observable<string> {
        return this.httpClient.get<string>(this.basePath);
    }
}
