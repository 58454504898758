import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppComponent } from '@app/app.component';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { AppRoutingModule } from '@app/app.routing.module';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { AuthenticationService } from '@app/core/services/api/authentication.service';
import { ClientEditComponent } from '@app/client/client-edit.component';
import { FittingClientComponent } from '@app/fitting/fitting-client/fitting-client.component';
import { FittingClientEditComponent } from '@app/fitting/fitting-client-edit.component';
import { FittingClientSearchComponent } from '@app/fitting/fitting-client-search.component';
import { FittingFitLensComponent } from '@app/fitting/fitting-fitlens.component';
import { FittingHeaderComponent } from '@app/fitting/fitting-header/fitting-header.component';
import { FittingHeaderItemComponent } from '@app/fitting/fitting-header/fitting-header-item.component';
import { FittingLensTypeComponent } from '@app/fitting/fitting-lenstype.component';
import { FittingMeasurementComponent } from '@app/fitting/fitting-measurement.component';
import { FittingOverviewComponent } from '@app/fitting/fitting-overview.component';
import { FittingComponent } from '@app/fitting/fitting.component';
import { FittingRefractionComponent } from '@app/fitting/fitting-refraction.component';
import { FittingMergeClientComponent } from './fitting/fitting-merge-client.component';
import { FittingDeleteClientComponent } from './fitting/fitting-delete-client.component';
import { LocalStorageService } from '@app/shared/appservices/localstorage.service';
import { MainComponent } from '@app/layout/main/main.component';
import { MainFooterComponent } from '@app/layout/main/components/main-footer/main-footer.component';
import { MainHeaderComponent } from '@app/layout/main/components/main-header/main-header.component';
import { OrderSentComponent } from '@app/order/order-sent.component';
import { HubProxyService } from '@app/core/services/wss/hubproxy.service';
import { RefractionDetailsComponent } from '@app/refraction/refraction-details.component';
import { SessionService } from '@app/shared/appservices/session.service';
import { TokenInterceptor } from '@app/core/interceptors/token.interceptor';
import { LicenseDialogComponent } from '@app/core/components/license-dialog/license-dialog.component';
import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import localeDE from '@angular/common/locales/de';
import localeIT from '@angular/common/locales/it';
import localeDA from '@angular/common/locales/da';
import { FittingService } from '@app/core/services/fitting.service';
import { FitlensResetDialogComponent } from '@app/fitlens/dialogs/fitlens-reset-dialog.component';
import { MeasurementReviewDialogComponent } from '@app/measurement/measurement-review-dialog.component';
import { MeasurementOptionsComponent } from '@app/measurement/measurement-options.component';
import { MeasurementManualComponent } from '@app/measurement/measurement-manual.component';
import { OrderStatusComponent } from '@app/order/order-status/order-status.component';
import { OrderStatusItemComponent } from '@app/order/order-status/components/order-status-items/order-status-item.component';
import { OrderDetailsDialogComponent } from '@app/order/order-details/order-details-dialog.component';
import { LicenseAuthGuard } from '@app/core/guards/license.auth.guard';
import { CartStorageService } from '@app/core/services/cart-storage.service';
import { LensOrderSentComponent } from '@app/order/lens-order-sent.component';
import { GlassOrderSentComponent } from '@app/order/glass-order-sent.component';
import { ShopService } from '@app/core/services/shop.service';
import { PopupComponent } from '@app/shared/popup/popup.component';
import { FittingUnsavedChangesGuard } from '@app/core/guards/fitting-unsaved-changes.guard';
import { RestRefractionWarningDialogComponent } from '@app/dialogs/rest-refraction-warning-dialog.component';
import { MultipleBaselinesWarningDialogComponent } from '@app/dialogs/multiple-baselines-warning-dialog.component';
import { TipsDialogComponent } from '@app/dialogs/tips-dialog.component';
import { ChangeOrderStatusDialogComponent } from '@app/order/order-details/components/change-order-status-dialog/change-order-status-dialog.component';
import { TestutilitiesComponent } from '@app/testutilities/testutilities.component';
import { ScreensaverTriggerService } from '@app/shared/appservices/screensaver-trigger.service';
import { InitialNavigationService } from '@app/shared/appservices/initial-navigation.service';
import { LogoutService } from '@app/shared/appservices/logout.service';
import { ListEmailComponent } from '@app/testutilities/email/list-email.component';
import { FitLensModule } from '@app/fitlens/fitlens.module';
import { PsModule } from '@app/professional-service/ps.module';
import { DeleteTopographicMeasurementDialogComponent } from '@app/dialogs/delete-topographic-measurement-dialog/delete-topographic-measurement-dialog.component';
import { WorkitemAlreadyAssignedDialogComponent } from '@app/professional-service/supportorder/modals/workitem-already-assigned-dialog.component';
import { GlassFittingFitGlassComponent } from '@app/fitting/fitting-fitglass.component';
import { GlassFrameComponent } from '@app/fitting/glassframe.component';
import { FittingGlassOverviewComponent } from '@app/fitting/fitting-glass-overview.component';
import { FitGlassModule } from '@app/fitglass/fitglass.module';
import { AutoFocus } from '@app/shared/directives/auto-focus.directive';
import { AppFactory } from '@app/shared/factories/app.factory';
import { TranslationLoaderFactory } from '@app/shared/factories/translation-loader.factory';
import { PsClientImportComponent } from '@app/professional-service/clientmaintenance/components/ps-client-import/ps-client-import.component';
import { QaModule } from '@app/features/quality-assurance/qa.module';
import { LogisticModule } from '@app/logistic/logistic.module';
import { RefractionFieldsConfigurationService } from '@app/core/services/refractionFieldsConfiguration.service';
import { RegionSelectorComponent } from '@app/features/region-selector/region-selector.component';
import { RegionService } from '@app/core/services/region.service';
import { ApiInterceptor } from '@app/core/interceptors/api.interceptor';
import { ReleaseNotesDialogComponent } from '@app/core/components/release-notes-dialog/release-notes-dialog.component';
import { ReadMoreDialogComponent } from '@app/core/components/read-more-dialog/read-more-dialog.component';
import { ScreensaverComponent } from '@app/core/components/screen-saver/screensaver.component';
import { SettingsDialogModule } from './core/components/settings-dialog/settings-dialog.module';
import { ReceiptModule } from '@app/features/receipt/receipt.module';
import { ReceiptHistoryComponent } from './features/receipt/components/receipt-history/receipt-history.component';
import { SharedModule } from '@app/shared/shared.module';
import { AuthModule } from './features/auth/auth.module';
import { CartModule } from './features/cart/cart.module';
import { OpticianModule } from './features/optician/optician.module';
import { TranslatorModule } from './features/translator/translator.module';
import { AnnualSupplyConfirmationComponent } from './fitting/components/annual-supply-confirmation/annual-supply-confirmation.component';
import { UploadFileDialogComponent } from './dialogs/upload-file-dialog/upload-file-dialog.component';
import { ProductTourModule } from './features/product-tour/product-tour.module';
import { ThemeSwitchComponent } from '@app/layout/main/components/main-header/theme-switch/theme-switch.component';

registerLocaleData(localeNL);
registerLocaleData(localeDE);
registerLocaleData(localeIT);
registerLocaleData(localeDA);

@NgModule({
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslationLoaderFactory,
                deps: [HttpClient],
            },
        }),
        SharedModule.forRoot(),
        TypeaheadModule.forRoot(),
        CarouselModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        LayoutModule,
        HttpClientModule,
        ScrollingModule,
        AppRoutingModule,
        FitLensModule,
        FitGlassModule,
        OpticianModule,
        TranslatorModule,
        PsModule,
        QaModule,
        LogisticModule,
        SettingsDialogModule,
        ReceiptModule,
        AuthModule,
        CartModule,
        ProductTourModule,
    ],
    declarations: [
        AppComponent,
        ChangeOrderStatusDialogComponent,
        ClientEditComponent,
        FittingClientComponent,
        FittingClientEditComponent,
        FittingClientSearchComponent,
        FittingMergeClientComponent,
        FittingDeleteClientComponent,
        FittingFitLensComponent,
        FittingHeaderComponent,
        FittingHeaderItemComponent,
        FittingLensTypeComponent,
        FittingMeasurementComponent,
        FittingOverviewComponent,
        FittingGlassOverviewComponent,
        FittingComponent,
        FittingRefractionComponent,
        FitlensResetDialogComponent,
        AnnualSupplyConfirmationComponent,
        MeasurementReviewDialogComponent,
        RegionSelectorComponent,
        MainComponent,
        MainFooterComponent,
        MainHeaderComponent,
        ThemeSwitchComponent,
        OrderSentComponent,
        LensOrderSentComponent,
        GlassOrderSentComponent,
        ListEmailComponent,
        RefractionDetailsComponent,
        MeasurementOptionsComponent,
        MeasurementManualComponent,
        PsClientImportComponent,
        LicenseDialogComponent,
        OrderStatusComponent,
        OrderStatusItemComponent,
        OrderDetailsDialogComponent,
        ReleaseNotesDialogComponent,
        ScreensaverComponent,
        ReadMoreDialogComponent,
        PopupComponent,
        RestRefractionWarningDialogComponent,
        MultipleBaselinesWarningDialogComponent,
        TestutilitiesComponent,
        TipsDialogComponent,
        UploadFileDialogComponent,
        DeleteTopographicMeasurementDialogComponent,
        WorkitemAlreadyAssignedDialogComponent,
        GlassFittingFitGlassComponent,
        GlassFrameComponent,
        AutoFocus,
        ReceiptHistoryComponent,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: AppFactory,
            deps: [AppConfigService, AppStateService, RegionService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            deps: [AppConfigService, AppStateService, LOCALE_ID],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            deps: [AppStateService, AuthenticationService, LogoutService],
            multi: true,
        },
        AuthenticationService,
        HubProxyService,
        InitialNavigationService,
        LocalStorageService,
        LogoutService,
        SessionService,
        FittingService,
        FittingUnsavedChangesGuard,
        LicenseAuthGuard,
        ScreensaverTriggerService,
        CartStorageService,
        ShopService,
        RefractionFieldsConfigurationService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
