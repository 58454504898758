import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { QaRoutingModule } from './qa.routing.module';
import { QaBarcodeScanComponent, QaComponent, QaOverviewComponent } from '@app/features/quality-assurance/pages';

@NgModule({
    imports: [CommonModule, SharedModule, QaRoutingModule],
    declarations: [QaComponent, QaComponent, QaBarcodeScanComponent, QaOverviewComponent],
    providers: [],
})
export class QaModule {}
