<h3 style="text-align: center">{{ 'orderoverview.products' | translate }}</h3>
<form [formGroup]="mainFormGroup">
    <ng-container>
        <div class="table-responsive">
            <table class="table mb-1 datatable">
                <thead>
                    <tr class="header">
                        <th>&nbsp;</th>
                        <th>
                            <a (click)="applySort($event, 'ClientName')">
                                {{ 'general.client' | translate }}
                                <div
                                    class="ms-1 sorticon"
                                    [ngClass]="'ClientName' | sortclass: currentSortColumn : currentSortOrder"
                                    aria-hidden="true"></div>
                            </a>
                        </th>
                        <th>
                            <a (click)="applySort($event, 'ArtNo')">
                                {{ 'general.artnr' | translate }}
                                <div
                                    class="ms-1 sorticon"
                                    [ngClass]="'ArtNo' | sortclass: currentSortColumn : currentSortOrder"
                                    aria-hidden="true"></div>
                            </a>
                        </th>
                        <th>
                            <a (click)="applySort($event, 'ProductName')">
                                {{ 'general.product' | translate }}
                                <div
                                    class="ms-1 sorticon"
                                    [ngClass]="'ProductName' | sortclass: currentSortColumn : currentSortOrder"
                                    aria-hidden="true"></div>
                            </a>
                        </th>
                        <th class="quanity-column">{{ 'general.quantity' | translate }}</th>
                        <th class="remove-column">&nbsp;</th>
                    </tr>
                </thead>
                <tbody class="lens-table" formArrayName="itemsFormArray">
                    <tr *ngFor="let item of items; index as i" [formGroupName]="i">
                        <td class="first-column" [ngClass]="itemColors[items[i].CartItemId]"></td>
                        <td>{{ item.ClientName }}</td>
                        <td>{{ item.ArtNo }}</td>
                        <td>{{ item.ProductName }}</td>
                        <td>
                            <mpc-input-number
                                *ngIf="item.MaxQuantity > 1"
                                [formControlName]="'quantity'"
                                [id]="'quantity'"
                                [ranges]="[item.QuantityRange]"
                                [maxLength]="2"
                                [breakpoint]="''"
                                [formControlOnly]="true"></mpc-input-number>
                        </td>
                        <td>
                            <i
                                class="fas fa-trash-alt delete-product"
                                title="{{ 'shoppingcart.remove' | translate }}"
                                (click)="deleteItem(item)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</form>
