import { Component, OnInit, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from '@app/shared/appservices/util.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { AccountService } from '@app/core/services/api/account.service';
import { lastValueFrom } from 'rxjs';

@Component({
    templateUrl: 'reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnChanges {
    username: string;
    newPassword: string;
    repeatNewPassword: string;
    newPasswordErrors: string[] = [];
    hasRequestedReset: boolean;
    isActivatingToken: boolean;
    isFinished: boolean;
    displayPasswordExpiredMessage = false;

    constructor(
        private accountService: AccountService,
        private loaderService: LoaderService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private utilService: UtilService,
        public appState: AppStateService,
    ) {}

    async ngOnInit() {
        this.username = this.route.snapshot.paramMap.get('username');
        if (!this.username) {
            const hasUsernameParameter = this.route.snapshot.queryParamMap.get('username');
            if (hasUsernameParameter) {
                this.username = hasUsernameParameter;
            }
        }

        const token = this.route.snapshot.queryParamMap.get('token');
        if (token) {
            this.hasRequestedReset = true;
            this.isActivatingToken = true;
            this.loaderService.show();

            lastValueFrom(this.accountService.activatePassword(token))
                .then(() => {
                    this.isActivatingToken = false;
                    this.isFinished = true;
                })
                .finally(() => {
                    this.loaderService.hide();
                });
        } else {
            if (this.appState.passwordExpiryInfo && this.appState.passwordExpiryInfo.PasswordNeverExpires === false) {
                this.displayPasswordExpiredMessage = this.appState.passwordExpiryInfo.DaysUntilExpiry <= 0;
            }
        }
    }

    ngOnChanges(): void {
        this.newPasswordErrors.splice(0);
    }

    get isResetButtonEnabled(): boolean {
        return !!this.username && !!this.newPassword && !!this.repeatNewPassword;
    }

    resetPassword() {
        this.newPasswordErrors.splice(0);

        if (!this.username) {
            this.newPasswordErrors.push(this.translate.instant('changepassword.usernamemandatory'));
        }

        if (!this.newPassword.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(.{8,50})/)) {
            this.newPasswordErrors.push(this.translate.instant('ErrorMessages.USERS_USER_INVALID_PASSWORD_STRENGTH'));
        }

        if (this.newPassword !== this.repeatNewPassword) {
            this.newPasswordErrors.push(this.translate.instant('changepassword.passwordsdonotmatch'));
        }

        const isValid = this.newPasswordErrors.length === 0;
        if (!isValid) {
            return;
        }

        this.loaderService.show();

        lastValueFrom(this.accountService.resetPassword(this.username, this.newPassword))
            .then(() => {
                this.hasRequestedReset = true;
            })
            .catch((error) => {
                const errors = this.utilService.getServerErrors(error);
                if (errors && errors.length > 0) {
                    this.newPasswordErrors.push(...errors);
                } else {
                    throw error;
                }
            })
            .finally(() => {
                this.loaderService.hide();
            });
    }

    gotoLogin(): void {
        this.router.navigate(['login']).then();
    }
}
