import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, debounceTime, finalize, tap } from 'rxjs/operators';
import { forkJoin, lastValueFrom, of } from 'rxjs';
import { ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { FittingService } from '@app/core/services/fitting.service';
import { WordpressApiService } from '@app/core/services/external/wordpress-api.service';
import { Post } from '@app/shared/models/post.model';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { ShopService } from '@app/core/services/shop.service';
import { DataSheet, SearchRequest, SearchResult } from '@app/shared/models';
import { SearchService } from '@app/core/services/api/search.service';
import { ClientService } from '@app/core/services/api/client.service';
import { SupportOrderService } from '@app/core/services/api/support-order.service';
import { DatasheetTypes, Features, FeatureCategories } from '@app/shared/enums';
import { ReleaseNotesDialogComponent } from '@app/core/components/release-notes-dialog/release-notes-dialog.component';
import { ReadMoreDialogComponent } from '@app/core/components/read-more-dialog/read-more-dialog.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.scss'],
    providers: [WordpressApiService],
})
export class HomeComponent implements OnInit {
    formGroup: UntypedFormGroup;
    privacyPolicyData: DataSheet;
    get formControls() {
        return this.formGroup.controls;
    }

    Features = Features;
    FeatureCategories = FeatureCategories;

    newsItems: Post[];
    calendarItems: Post[];
    searchItems: SearchResult[];
    loadingNewsItems = false;
    loadingCalendarItems = false;
    loadingSearchItems = false;
    contactUrl: string;
    faqUrl: string;
    termsAndConditionsData: DataSheet;
    webshopTileEnabled = false;
    showAcademy: boolean;

    replacementNotificationCount: number;
    supportOrderAttentionCount: number;
    private readonly destroyRef = inject(DestroyRef);

    constructor(
        public appState: AppStateService,
        private readonly fb: UntypedFormBuilder,
        private readonly router: Router,
        private readonly fittingService: FittingService,
        private readonly loaderService: LoaderService,
        private readonly wordpressApiService: WordpressApiService,
        private readonly modalService: BsModalService,
        private readonly shopService: ShopService,
        private readonly searchService: SearchService,
        private readonly clientService: ClientService,
        private readonly supportOrderService: SupportOrderService,
    ) {}

    async ngOnInit() {
        this.loaderService.show();
        this.fittingService.clear();
        this.shopService.clear();

        this.createForm();
        this.loadingNewsItems = true;
        this.loadingCalendarItems = true;

        this.loadNotifications();

        this.contactUrl = this.appState.currentCompany?.ContactUrl;
        this.webshopTileEnabled = this.shouldWebshopTileEnabled();
        this.showAcademy = this.appState.isDistributorFeatureEnabled(Features.Academy);
        this.faqUrl = this.appState.currentCompany?.FaqUrl;

        this.getTermsAndConditionsData();
        this.loadWordpressData();
        this.appState.applyUserLanguage();
        this.loaderService.hide();
    }

    createForm(): void {
        this.formGroup = this.fb.group({
            searchTerm: [''],
        });

        this.formControls['searchTerm'].valueChanges
            .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
            .subscribe(async (result: string) => {
                if (result) {
                    const resultString = (result as string).trim();
                    const searchRequest = new SearchRequest();
                    searchRequest.PageSize = 25;
                    searchRequest.CurrentPage = 0;
                    searchRequest.Filter = resultString;

                    const searchResults = await lastValueFrom(this.searchService.search(searchRequest));
                    this.searchItems = searchResults.Items;
                } else {
                    this.searchItems = null;
                }
            });
    }

    private loadNotifications() {
        if (!this.appState.authenticatedUser.IsOptician) {
            return;
        }

        const getClientReplacementNotificationCount = this.clientService.getClientReplacementNotificationsCount().pipe(
            tap((result) => {
                this.replacementNotificationCount = result;
            }),
        );

        const getSupportOrderRequiresAttentionCount = this.supportOrderService
            .getSupportOrderRequiresAttentionCount()
            .pipe(
                tap((result) => {
                    this.supportOrderAttentionCount = result;
                }),
            );

        forkJoin([getClientReplacementNotificationCount, getSupportOrderRequiresAttentionCount])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
    }

    private loadWordpressData() {
        const getNewsItems = this.wordpressApiService.getNewsItems().pipe(
            tap((result) => {
                this.newsItems = result;
            }),
        );

        const getCalendarItems = this.wordpressApiService.getCalendarItems().pipe(
            tap((result) => {
                this.calendarItems = result;
            }),
        );

        forkJoin([getNewsItems, getCalendarItems])
            .pipe(
                tap(() => {
                    this.loadingNewsItems = true;
                    this.loadingCalendarItems = true;
                }),
                catchError(() => of(null)),
                finalize(() => {
                    this.loadingNewsItems = false;
                    this.loadingCalendarItems = false;
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
    }

    shouldWebshopTileEnabled(): boolean {
        return (
            this.appState.currentOptician.IsApproved &&
            this.appState.isOpticianFeatureEnabled(Features.Webshop) &&
            this.appState.isDistributorFeatureEnabled(Features.WebshopTile)
        );
    }

    gotoSearchClient(): void {
        this.router.navigate(['/searchclient']);
    }

    gotoShop(): void {
        this.router.navigate(['/shop']);
    }

    gotoReceipts(): void {
        this.router.navigate(['/receipt/order']);
    }

    gotoOrderStatus(): void {
        this.router.navigate(['/orderstatus']);
    }

    gotoReceiptStatus(): void {
        this.router.navigate(['/receipt/status']);
    }

    showReleaseNotes($event: MouseEvent) {
        $event.preventDefault();
        const options: ModalOptions = { class: 'releaseNotesDialog' };
        this.modalService.show(ReleaseNotesDialogComponent, options);
    }

    showReadMore($event: MouseEvent, id: number) {
        $event.preventDefault();
        const options: { initialState: { id: number }; class: string } = {
            initialState: { id },
            class: 'releaseNotesDialog',
        };
        this.modalService.show(ReadMoreDialogComponent, options);
    }

    getTermsAndConditionsData() {
        if (!this.appState.isOptician) {
            return;
        }

        this.appState
            .getLicenseAgreements()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.termsAndConditionsData = this.appState.licenseDatasheets.find(
                    (ds: DataSheet) => ds.Code == DatasheetTypes.UserRegulations,
                );
                this.privacyPolicyData = this.appState.licenseDatasheets.find(
                    (ds: DataSheet) => ds.Code == DatasheetTypes.PrivacyPolicy,
                );
            });
    }
}
