<ng-container>
    <div class="mt-4 px-xl-4">
        <div class="mb-2 title-border-bottom">
            <h3>{{ 'testutilities.pagetitle' | translate }}</h3>
        </div>

        <div class="buttonContainer">
            <div id="topo-simulation" (click)="generateTopoImages()" class="testbutton">
                <div class="text-center my-1 title">
                    <h4>
                        <i class="fas fa-play"></i>
                        {{ 'testutilities.topo-simulation' | translate }}
                    </h4>
                </div>
                <div class="text-center description">
                    {{ 'testutilities.topo-simulation-text' | translate: { opticien: opticianName } }}
                </div>
            </div>

            <div id="recent-emails" (click)="recentEmails()" class="testbutton">
                <div class="text-center my-1 title">
                    <h4>
                        <i class="fas fa-inbox"></i>
                        {{ 'testutilities.recent-emails' | translate }}
                    </h4>
                </div>
                <div class="text-center description">
                    {{ 'testutilities.recent-emails-text' | translate }}
                </div>
            </div>

            <div id="importRandomToposToOptician" class="testbutton">
                <div class="text-center my-1 title">
                    <h4>
                        <i class="fas fa-inbox"></i>
                        import random topo
                    </h4>
                </div>
                <div class="text-center description">
                    target optician id:
                    <input type="number" [(ngModel)]="opticianId" />
                    number of topo:
                    <input type="number" [(ngModel)]="numberOfTopos" />
                </div>
                <div>
                    <button (click)="importRandomTopos()">do!</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
