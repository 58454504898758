<div class="container-fluid mt-3 mb-4 px-lg-4 flex-fill">
    <div class="title-border-bottom mb-1">
        <h3 class="mt-auto">{{ 'ps.supportorderoverview' | translate }}</h3>
    </div>

    <div class="row">
        <div class="col-2 mt-3">
            <supportorder-stats [supportorderoverviewComponent]="this"></supportorder-stats>
        </div>
        <div class="col-10">
            <ng-container *ngIf="isOverviewPerosSelected">
                <supportorder-overview-po-attachment></supportorder-overview-po-attachment>
            </ng-container>
            <ng-container *ngIf="!isOverviewDirectOrdersSelected">
                <ng-container *ngTemplateOutlet="supportorders"></ng-container>
            </ng-container>
            <ng-container *ngIf="isOverviewDirectOrdersSelected">
                <ng-container *ngTemplateOutlet="directorders"></ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #paging>
    <div class="d-flex mt-4">
        <div class="pagination">
            <button
                (click)="onPage('first')"
                [disabled]="supportOrderQueryCriteria.PageIndex === 0"
                class="align-middle">
                <h6 class="my-1"><<</h6>
            </button>
            <button
                (click)="onPage('previous')"
                [disabled]="supportOrderQueryCriteria.PageIndex === 0"
                class="align-middle">
                <h6 class="my-1"><</h6>
            </button>
            <button
                *ngFor="let page of pagesShown"
                (click)="onPage(page)"
                class="align-middle"
                [ngClass]="selectedPage(page)">
                <h6 class="my-1">{{ page + 1 }}</h6>
            </button>
            <button
                (click)="onPage('next')"
                [disabled]="supportOrderQueryCriteria.PageIndex === maxPage"
                class="align-middle">
                <h6 class="my-1">></h6>
            </button>
            <button
                (click)="onPage('last')"
                [disabled]="supportOrderQueryCriteria.PageIndex === maxPage"
                class="align-middle">
                <h6 class="my-1">>></h6>
            </button>
        </div>
        <div class="ms-4 align-self-center">
            {{ itemsShownLowerBound }} - {{ itemsShownUpperBound }} {{ 'general.shown' | translate }}, {{ totalOrders }}
            {{ 'general.total' | translate }}
        </div>
    </div>
</ng-template>

<ng-template #directorders>
    <div class="mt-2">
        <table class="ps-datatable">
            <thead>
                <tr>
                    <td *ngIf="isOverviewBlockedOrdersSelected"></td>
                    <td></td>
                    <th *ngFor="let field of directOrderSearchFields" (click)="onSortByField(field)">
                        {{ 'supportoverview.' + field | translate }}
                        <i *ngIf="field === ascendingField && !isAscending" class="fas fa-caret-up ms-auto"></i>
                        <i
                            *ngIf="field !== ascendingField || (field === ascendingField && isAscending)"
                            class="fas fa-caret-down ms-auto"></i>
                    </th>
                </tr>
            </thead>

            <tbody *ngIf="orderInfoMinimalGrid">
                <tr [formGroup]="filterDirectOrdersForm">
                    <td class="td-checkbox" *ngIf="isOverviewBlockedOrdersSelected">
                        <input type="checkbox" id="selectAll" (change)="setSelectAll($any($event.target).checked)" />
                    </td>
                    <td></td>
                    <td *ngFor="let field of directOrderSearchFields">
                        <input
                            class="form-control"
                            formControlName="{{ field }}"
                            type="text"
                            (change)="onFilterByField()" />
                    </td>
                </tr>

                <div class="spinner-border spinner-border-sm" *ngIf="loading"></div>

                <ng-container *ngIf="!loading">
                    <tr
                        *ngFor="let orderInfo of orderInfoMinimalGrid.Items"
                        (click)="onSelectDirectOrder(orderInfo)"
                        class="table_entry">
                        <td *ngIf="isOverviewBlockedOrdersSelected" class="td-checkbox">
                            <input
                                type="checkbox"
                                id="orders-{{ orderInfo.Id }}"
                                value="{{ orderInfo.Id }}"
                                [checked]="isOrderChecked(orderInfo.Id)"
                                (click)="$event.stopPropagation()"
                                (change)="setOrderChecked($event)" />
                        </td>
                        <td>
                            <i
                                *ngIf="orderInfo.IsDirectToHome"
                                class="fas fa-mail-bulk -up ms-auto"
                                triggers="mouseenter:mouseleave"
                                popoverClass="popover"
                                popover="{{ 'orderoverview.directToHome.title' | translate }}"></i>
                        </td>
                        <td class="m-1">{{ orderInfo.Date | mpcdate }}</td>
                        <td class="m-1">{{ orderInfo.OpticianName }}</td>
                        <td class="m-1">
                            {{ orderInfo.ClientReference | slice: 0 : 25 }}
                        </td>
                        <td class="m-1">
                            {{ orderInfo.ClientReference2 | slice: 0 : 25 }}
                        </td>
                        <td class="m-1">{{ orderInfo.TotalQuantity }}</td>
                        <td class="m-1">{{ orderInfo.BirthDate | mpcdate }}</td>
                        <td class="m-1">
                            {{ orderInfo.LensTypeCode }}
                            <i *ngIf="orderInfo.OrderTypeId === orderTypeWebshop" class="fas fa-shopping-cart ms-1"></i>
                        </td>
                        <td class="m-1">
                            {{ orderInfo.Message | slice: 0 : 50 }}
                        </td>
                        <td class="m-1">{{ orderInfo.ProposalTypeName }}</td>
                        <td class="m-1" *ngIf="perosEnabled">{{ orderInfo.OrderReference }}</td>
                        <td class="m-1" *ngIf="perosEnabled">{{ orderInfo.PerosOrderNumber }}</td>
                        <td class="m-1">{{ orderInfo.Id }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <ng-container *ngTemplateOutlet="paging"></ng-container>
    </div>
</ng-template>

<ng-template #supportorders>
    <div class="mt-2">
        <table class="ps-datatable">
            <thead>
                <tr>
                    <td></td>
                    <th *ngFor="let field of supportOrderSearchfields" (click)="onSortByField(field)">
                        {{ 'supportoverview.' + field | translate }}
                        <i *ngIf="field === ascendingField && !isAscending" class="fas fa-caret-up ms-auto"></i>
                        <i
                            *ngIf="field !== ascendingField || (field === ascendingField && isAscending)"
                            class="fas fa-caret-down ms-auto"></i>
                    </th>
                </tr>
            </thead>

            <tbody *ngIf="supportOrders">
                <tr [formGroup]="filterSupportOrdersForm">
                    <td></td>
                    <td *ngFor="let field of supportOrderSearchfields">
                        <input
                            class="form-control"
                            formControlName="{{ field }}"
                            type="text"
                            (change)="onFilterByField()" />
                    </td>
                </tr>

                <div class="spinner-border spinner-border-sm" *ngIf="loading"></div>

                <ng-container *ngIf="!loading">
                    <tr
                        *ngFor="let supportorder of supportOrders"
                        (click)="onSelectSupportOrder(supportorder)"
                        class="table_entry">
                        <td>
                            <i
                                *ngIf="supportorder.IsDirectToHome"
                                class="fas fa-mail-bulk ms-auto"
                                triggers="mouseenter:mouseleave"
                                popoverClass="popover"
                                popover="{{ 'orderoverview.directToHome.title' | translate }}"></i>
                        </td>
                        <td class="m-1">{{ supportorder.Date | mpcdate }}</td>
                        <td class="m-1">{{ supportorder.OpticianName }}</td>
                        <td class="m-1">
                            {{ supportorder.ClientReference | slice: 0 : 25 }}
                        </td>
                        <td class="m-1">
                            {{ supportorder.ClientReference2 | slice: 0 : 25 }}
                        </td>
                        <td class="m-1">
                            {{ supportorder.BirthDate | mpcdate }}
                        </td>
                        <td class="m-1">{{ supportorder.LensTypeCode }}</td>
                        <td class="m-1">
                            {{ supportorder.Message | slice: 0 : 50 }}
                        </td>
                        <td class="m-1">{{ supportorder.ProposalTypeName }}</td>
                        <td class="m-1" *ngIf="perosEnabled">{{ supportorder.OrderReference }}</td>
                        <td class="m-1">{{ supportorder.SupporterName }}</td>
                        <td class="m-1" *ngIf="perosEnabled">{{ supportorder.PerosOrderNumber }}</td>
                        <td class="m-1">{{ supportorder.Id }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <ng-container *ngTemplateOutlet="paging"></ng-container>
    </div>
</ng-template>

<div *footer>
    <div class="d-flex justify-content-end">
        <ng-container *ngIf="isOverviewBlockedOrdersSelected">
            <button
                type="button"
                id="Export"
                [disabled]="selectedOrderIds.length === 0"
                class="btn btn-info me-1"
                (click)="exportOrders()">
                {{ 'general.export' | translate }}
            </button>
            <button
                type="button"
                id="approve"
                [disabled]="selectedOrderIds.length === 0"
                class="btn btn-info me-1"
                (click)="approveOrders()">
                {{ 'general.approve' | translate }}
            </button>
        </ng-container>
    </div>
</div>
