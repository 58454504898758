import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListOption, ProductGroup } from '@app/shared/models';
import { LensTypeProductGroupNames } from '@app/shared/models/lensTypeProductGroupNames.model';
import { map } from 'rxjs/operators';
import { ProductGroupCodes } from '@app/shared/enums';

@Injectable({
    providedIn: 'root',
})
export class ProductGroupService {
    private readonly basePath = 'api/product-groups';

    constructor(private readonly httpClient: HttpClient) {}

    public getAll(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/options`);
    }

    public save(productGroup: ProductGroup): Observable<ListOption> {
        return this.httpClient.post<ListOption>(this.basePath, productGroup);
    }

    public remove(productGroupId: number): Observable<void> {
        const options = {
            params: new HttpParams().set('productGroupId', productGroupId),
        };

        return this.httpClient.delete<void>(this.basePath, options);
    }

    public getProductGroupById(productGroupId: number): Observable<ProductGroup> {
        return this.httpClient.get<ProductGroup>(`${this.basePath}/${productGroupId}`);
    }

    public getProductGroups(): Observable<ProductGroup[]> {
        return this.httpClient.get<ProductGroup[]>(this.basePath);
    }

    public getLensTypeProductGroupNames(): Observable<LensTypeProductGroupNames> {
        return this.getAll().pipe(
            map((listOptions) => ({
                dreamliteName: listOptions.find((x) => x.Code === ProductGroupCodes.DREAMLITE)?.Name,
                byoName: listOptions.find((x) => x.Code === ProductGroupCodes.BYOPREMIUM)?.Name,
                medName: listOptions.find((x) => x.Code === ProductGroupCodes.MEDICALLENSES)?.Name,
                sensoName: listOptions.find((x) => x.Code === ProductGroupCodes.SENSOSELECT)?.Name,
            })),
        );
    }
}
