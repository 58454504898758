import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { CartItemBaseTableComponent } from '../cart-item-base-table/cart-item-base-table.component';

@Component({
    selector: 'cart-item-product-table',
    templateUrl: './cart-item-product-table.component.html',
    styleUrls: ['./cart-item-product-table.component.scss'],
})
export class CartItemProductTableComponent extends CartItemBaseTableComponent {
    constructor(
        protected fb: FormBuilder,
        protected appStateService: AppStateService,
    ) {
        super(fb, appStateService);
    }
}
