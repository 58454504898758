<div class="form ms-4 mt-5">
    <div class="flex-fill d-flex flex-grow-1">
        <div class="row">
            <p>
                {{ 'registeropticiansuccess.info' | translate }}
            </p>
        </div>
        >
        <div class="row">
            <div class="col-7 col-sm-12">
                <button id="login" class="btn btn-primary btn-block" (click)="goToLogin()">
                    {{ 'registeropticiansuccess.gotologin' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
