import { Injectable, EventEmitter, NgZone } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState, IHttpConnectionOptions } from '@microsoft/signalr';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { TopographicMeasurements } from '@app/shared/models/topographicMeasurements.model';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { Roles } from '@app/shared/enums';

@Injectable()
export class HubProxyService {
    public newMeasurementsAvailable: EventEmitter<TopographicMeasurements> = new EventEmitter();

    private connection: HubConnection;

    constructor(
        private zone: NgZone,
        public appState: AppStateService,
        public appConfigService: AppConfigService,
    ) {}

    initializeConnection(): void {
        if (
            this.appState.authenticatedUser &&
            this.appState.authenticatedUser.AccessToken &&
            this.appState.authenticatedUser.CurrentRoleId === Roles.Optician
        ) {
            this.zone.runOutsideAngular(() => this.startHubConnection());
        } else {
            this.closeConnection();
        }
    }

    closeConnection(): void {
        if (
            this.connection &&
            this.connection.state !== HubConnectionState.Disconnected &&
            this.connection.state !== HubConnectionState.Disconnecting
        ) {
            this.connection.stop();
        }
    }

    private startHubConnection() {
        const signalrEndPoint = this.appConfigService.apiEndpoint;
        const options: IHttpConnectionOptions = {
            accessTokenFactory: () => this.appState.authenticatedUser.AccessToken ?? '',
        };

        this.connection = new HubConnectionBuilder()
            .withUrl(signalrEndPoint + 'signalr/measurements-hub', options)
            .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 60000, 60000, 60000, 60000, 60000])
            .build();

        this.connection.start().then(() => {
            this.connection.invoke('JoinOpticianGroup');
        });

        this.connection.onclose((error) => {
            if (!!error) {
                console.warn(error);
            }
        });

        this.connection.on('notifyNewMeasurementAvailable', (measurements: TopographicMeasurements) => {
            this.newMeasurementsAvailable.emit(measurements);
        });
    }
}
