import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from '@app/core/services/api/client.service';
import { FittingService } from '@app/core/services/fitting.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'fitting-delete-client',
    templateUrl: 'fitting-delete-client.component.html',
})
export class FittingDeleteClientComponent {
    constructor(
        private readonly fittingService: FittingService,
        public clientService: ClientService,
        public router: Router,
        public bsModalRef: BsModalRef,
    ) {}

    delete(): void {
        const clientId = this.fittingService.getClientId();

        this.clientService.removeClient(clientId).subscribe(() => {
            this.bsModalRef.hide();
            this.router.navigate(['/searchclient']).then();
        });
    }
}
