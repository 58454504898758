<div class="container-fluid flex-fill" [formGroup]="formGroup">
    <div class="title-border-bottom mt-3 mb-2">
        <h3 *ngIf="hasExistingClient">{{ 'general.client' | translate }}</h3>
        <h3 *ngIf="!hasExistingClient">{{ 'client.newclient' | translate }}</h3>
    </div>
    <client-edit #editClient [client]="client"></client-edit>
</div>

<div *footer class="d-flex align-items-center">
    <div>
        <a *ngIf="hasExistingClient" [routerLink]="['/client', client.Id]">
            <i class="fas fa-arrow-alt-circle-left me-1"></i>
            {{ 'general.client' | translate }}
        </a>
        <a *ngIf="!hasExistingClient" [routerLink]="['/searchclient']" [queryParams]="{ reset: true }">
            <i class="fas fa-arrow-alt-circle-left me-1"></i>
            {{ 'searchclient.searchclients' | translate }}
        </a>
        <ng-container *ngIf="hasExistingClient">
            <button type="button" id="deleteclient" class="btn btn-danger ms-2" (click)="deleteClient()">
                {{ 'client.deleteclient' | translate }}
            </button>
            <button type="button" id="mergeclient" class="btn btn-danger ms-2" (click)="mergeClient()">
                {{ 'client.mergeclient' | translate }}
            </button>
        </ng-container>
    </div>
    <div class="ms-auto">
        <button type="button" id="saveClient" class="btn btn-success" [disabled]="formGroup.invalid" (click)="save()">
            {{ 'general.save' | translate }}
        </button>
    </div>
</div>
