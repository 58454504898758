export class PasswordExpiryInfo {
    constructor(passwordExpiryInfo: PasswordExpiryInfo) {
        this.PasswordExpiryDate = passwordExpiryInfo.PasswordExpiryDate;
        this.PasswordNeverExpires = passwordExpiryInfo.PasswordNeverExpires;
        this.DaysUntilExpiry = passwordExpiryInfo.DaysUntilExpiry;
        this.IsExpired = passwordExpiryInfo.IsExpired;
    }

    PasswordExpiryDate: Date;
    PasswordNeverExpires: boolean;
    DaysUntilExpiry: number;
    IsExpired: boolean;
}
