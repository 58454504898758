import { WarrantyStatus } from '../enums';
import { Manufacturer } from './manufacturer.model';

export class ProductDistributor {
    Id: number;
    Name: string;
    Manufacturer: Manufacturer;
    IsSpareEnabled: boolean;
    IsAnnualSupplyEnabled: boolean;
    WarrantyStatus: WarrantyStatus;
}
