import { WarrantyStatus } from '../enums';

export class OrderHistoryLine {
    Id: number;
    OrderId: number;
    OrderDate: Date;
    ShippedDate: Date | null;
    OrderLineStatusId: number;
    OrderTypeId: number;
    TrackAndTraceLink: string | null;
    IsDirectToHome: boolean;
    IsBaseLine: boolean | null;
    SerialNumbers: string | null;
    ReturnIds: number[];
    WarrantyExchangeAllowed: boolean;
    IsWarrantyApplied: boolean;
    IsSpare: boolean;
    IsAnnualSupplyApplied: boolean;
    IsAnnualSupplyFulfilled: boolean;
    FittedLens: FittedLens | null;
    Product: Product | null;
    Quantity: number;
    Child: OrderHistoryLine | null;
}

interface Product {
    Id: number;
    Name: string;
    AvailableForDirectOrder: boolean;
    AvailableInProcess: boolean | null;
    MaxQuantity: number;
    IsSpareEnabled: boolean;
    IsAnnualSupplyEnabled: boolean;
    WarrantyStatus: WarrantyStatus;
}

interface FittedLens {
    Id: number;
    EyeSideId: number;
    Parameters: FittedLensParameter[];
    Material: Material | null;
    LensTypeId: number | null;
    Engraving: string | null;
}

interface Material {
    Name: string | null;
    Color: string | null;
}

interface FittedLensParameter {
    Id: number;
    Code: string;
    Value: number;
    SortOrder: number;
}
