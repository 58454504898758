import { Component, DestroyRef, HostListener, OnDestroy, OnInit, inject } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { LanguageService } from '@app/core/services/api/language.service';
import { BehaviorSubject, Observable, combineLatest, lastValueFrom } from 'rxjs';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { AlertService } from '@app/shared/appservices/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/services/api/translation.service';
import { ListOption } from '@app/shared/models';
import { Translation } from '@app/shared/models/translation.model';
import { tap, distinctUntilChanged, finalize, switchMap, filter, share, debounceTime } from 'rxjs/operators';
import { TranslationHistoryModel } from '@app/shared/models/translation-history.model';
import { TranslationCategoryService } from '@app/core/services/api/translation-category.service';
import { TranslationCategory } from '@app/shared/models/translation-category.model';
import { Editor, Toolbar } from 'ngx-editor';
import { LanguageTranslationInfo } from '@app/shared/models/language-translation-info.model';
import { LanguageTranslationPercentage } from '@app/shared/models/language-translation-percentage.model';
import { MultilingualTranslation } from '@app/shared/models/multilingual-translation.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TipsDialogComponent } from '@app/dialogs/tips-dialog.component';
import { ConfirmService } from '@app/shared/appservices/confirm.service';
import { Util } from '@app/shared/helpers/utility.helper';
import { UploadFileDialogComponent } from '@app/dialogs/upload-file-dialog/upload-file-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-translations',
    templateUrl: './translations.component.html',
    styleUrls: ['./translations.component.scss'],
})
export class TranslationsComponent implements OnInit, OnDestroy {
    public categories$: Observable<TranslationCategory[]>;
    public languages$: Observable<ListOption[]>;
    public translations: MultilingualTranslation[];
    public allTranslations: MultilingualTranslation[];

    public history$: Observable<TranslationHistoryModel[]>;
    public selectedLanguage$ = new BehaviorSubject<ListOption>(null);
    public selectedCategory$ = new BehaviorSubject<TranslationCategory>(null);
    public selectedTranslation$ = new BehaviorSubject<MultilingualTranslation>(null);
    public showOnlyNotTranslated$ = new BehaviorSubject<boolean>(false);
    public search$ = new BehaviorSubject<string>(null);

    protected editor: Editor;
    protected expanded = false;

    public currentWordform: FormGroup;
    public isTranslationChanged = false;

    public parentLanguageId?: number;
    public parentLanguageCode?: string;
    public languagePercentages: LanguageTranslationPercentage;
    public changedTranslation: string;
    public currentBaseLanguage = 'en-GB';
    public onlyEmptyTranslations = false;

    private readonly destroyRef = inject(DestroyRef);

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly languageService: LanguageService,
        private readonly loaderService: LoaderService,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        private readonly translationService: TranslationService,
        private readonly categoryService: TranslationCategoryService,
        private readonly confirmService: ConfirmService,
        private readonly modalService: BsModalService,
        private route: ActivatedRoute,
        private readonly router: Router,
        private location: Location,
    ) {}

    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['ordered_list', 'bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    public ngOnInit() {
        this.loaderService.show();

        const queryParams = this.route.snapshot.queryParams;
        const languageId = queryParams.languageId ? parseInt(queryParams.languageId) : null;
        const category = queryParams.categoryName ? queryParams.categoryName : null;
        const translationId = queryParams.translationId ? parseInt(queryParams.translationId) : null;

        this.createForm();

        this.loadLanguage(languageId);
        this.loadCategories(category);
        this.loadTranslations(translationId);
        this.loadHistory();
        this.configureChangeRouting();
        this.search();

        this.initForm();
        this.loaderService.hide();
    }

    private createForm(): void {
        this.currentWordform = this.formBuilder.group({
            Id: 0,
            Code: ['', [Validators.required]],
            Translation: '',
            IsHtml: false,
            DefaultLanguageTranslation: '',
            ParentLanguageTranslation: '',
            TranslateLanguageId: 0,
        });
    }

    private initForm(): void {
        this.currentWordform.reset();
        this.currentWordform
            .get('Translation')
            .valueChanges.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
            .subscribe((value: string) => {
                const translation = this.selectedTranslation$.getValue();
                if (translation) {
                    value = !translation.IsHtml ? this.stripHtml(value) : value;
                    if (this.selectedTranslation$ && value !== translation.Translation) {
                        this.isTranslationChanged = true;
                        this.changedTranslation = value;
                    } else {
                        this.isTranslationChanged = false;
                    }
                }
            });

        this.editor = new Editor();
    }

    public ngOnDestroy(): void {
        this.editor.destroy();
    }

    public get currentWordFormControls() {
        return this.currentWordform.controls;
    }

    private loadLanguage(languageId: number): void {
        this.languages$ = this.languageService.getTranslatorLanguages().pipe(
            tap((languages: ListOption[]) => {
                if (languages && languages.length > 0) {
                    this.selectLanguage(languages, languageId);
                    this.loadLanguageTranslationInfo(languageId ? languageId : languages[0].Id);
                    this.loadLanguagePercentage(languageId ? languageId : languages[0].Id);
                }
            }),
        );
    }

    private loadCategories(category: string): void {
        this.categories$ = this.categoryService.getTranslationCategories().pipe(
            tap((categories: TranslationCategory[]) => {
                if (categories && categories.length > 0) {
                    this.selectCategory(categories, category);
                }
            }),
        );
    }

    private configureChangeRouting(): void {
        combineLatest([
            this.selectedLanguage$.pipe(distinctUntilChanged()),
            this.selectedCategory$.pipe(distinctUntilChanged()),
            this.selectedTranslation$.pipe(distinctUntilChanged()),
        ])
            .pipe(
                filter(([selectedLanguage, selectedCategory]) => !!selectedLanguage && !!selectedCategory),
                tap(([selectedLanguage, selectedCategory, selectedTranslation]) => {
                    const queryParams: { languageId?: number; categoryName?: string; translationId?: number } = {};
                    if (selectedLanguage) {
                        queryParams.languageId = selectedLanguage.Id;
                    }
                    if (selectedCategory) {
                        queryParams.categoryName = selectedCategory.CategoryName;
                    }
                    if (selectedTranslation) {
                        queryParams.translationId = selectedTranslation.Id;
                    } else {
                        queryParams.translationId = null;
                    }

                    const url = this.router
                        .createUrlTree([], {
                            relativeTo: this.route,
                            queryParams: queryParams,
                            queryParamsHandling: 'merge',
                        })
                        .toString();

                    this.location.replaceState(url);
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
    }

    private loadTranslations(translationId: number): void {
        combineLatest([
            this.selectedCategory$.pipe(distinctUntilChanged()),
            this.selectedLanguage$.pipe(distinctUntilChanged()),
        ])
            .pipe(
                filter(([selectedCategory, selectedLanguage]) => !!selectedCategory && !!selectedLanguage),
                tap(() => this.loaderService.show()),
                switchMap(([selectedCategory, selectedLanguage]) => {
                    return this.translationService.getMultilingualTranslationsByCategory(
                        selectedLanguage.Id,
                        selectedCategory.CategoryName,
                    );
                }),
                tap((translations) => {
                    const initialTranslation = translations.find((x) => x.Id === translationId);
                    this.selectTranslation(initialTranslation);

                    this.loaderService.hide();
                }),
                share(),
            )
            .subscribe((translations: MultilingualTranslation[]) => {
                this.allTranslations = translations;
                this.translations = translations;
            });
    }

    private loadHistory(): void {
        this.history$ = combineLatest([
            this.selectedLanguage$.pipe(distinctUntilChanged()),
            this.selectedCategory$.pipe(distinctUntilChanged()),
            this.selectedTranslation$.pipe(distinctUntilChanged()),
        ]).pipe(
            filter(
                ([selectedLanguage, selectedCategory, selectedTranslation]) =>
                    !!selectedLanguage && !!selectedCategory && !!selectedTranslation,
            ),
            switchMap(([selectedLanguage, selectedCategory, selectedTranslation]) => {
                return this.translationService.getHistory(
                    selectedLanguage.Id,
                    selectedCategory.CategoryName,
                    selectedTranslation.Id,
                );
            }),
        );
    }

    private selectLanguage(languages: ListOption[], languageId: number): void {
        const index = languages.findIndex((x) => x.Id === languageId);
        this.selectedLanguage$.next(index >= 0 ? languages[index] : languages[0]);
    }

    private selectCategory(categories: TranslationCategory[], category: string): void {
        const index = categories.findIndex((x) => x.CategoryName.toLowerCase() === category?.toLowerCase());
        this.selectedCategory$.next(index >= 0 ? categories[index] : categories[0]);
    }

    public selectTranslation(translation: MultilingualTranslation): void {
        if (!translation) {
            return;
        }

        this.selectedTranslation$.next(translation);

        this.currentWordform.patchValue({
            Id: translation.Id,
            Code: translation.Code,
            Translation: translation.Translation,
            IsHtml: translation.IsHtml,
            DefaultLanguageTranslation: translation.DefaultLanguageTranslation,
            ParentLanguageTranslation: translation.ParentLanguageTranslation,
            TranslateLanguageId: translation.TranslateLanguageId,
        });
    }

    public onCategoryChange(selectedCategory: TranslationCategory): void {
        this.selectedCategory$.next(selectedCategory);
        if (this.selectedCategory$) {
            this.refresh();
            this.currentWordform.reset();
            this.selectedTranslation$.next(null);
        }
    }

    public onLanguageChange(selectedLanguage: ListOption): void {
        this.selectedLanguage$.next(selectedLanguage);
        if (this.selectedLanguage$) {
            this.refresh();
            this.currentWordform.reset();
            this.loadLanguageTranslationInfo(selectedLanguage.Id);
            this.loadLanguagePercentage(selectedLanguage.Id);
            this.selectedTranslation$.next(null);
        }
    }

    private search(): void {
        this.search$.pipe(debounceTime(500), distinctUntilChanged()).subscribe((search) => {
            if (search) {
                this.translations = this.allTranslations.filter(
                    (translation) =>
                        translation !== null &&
                        translation.Translation !== null &&
                        (translation.Code.toLowerCase().includes(search.toLowerCase()) ||
                            translation.Translation.toLowerCase().includes(search.toLowerCase())),
                );
            } else {
                this.setFilteredTranslations();
            }
        });
    }

    public changeBaseLanguage(event: Event): void {
        const selectElement = event.target as HTMLSelectElement;
        const selectedLanguage = selectElement.value;
        this.currentBaseLanguage = selectedLanguage;
    }

    private loadLanguageTranslationInfo(languageId: number): void {
        this.languageService
            .getLanguageTranslationInfo(languageId)
            .subscribe((languageInfo: LanguageTranslationInfo) => {
                this.parentLanguageId = languageInfo.ParentId;
                this.parentLanguageCode = languageInfo.ParentCode;
            });
    }

    private loadLanguagePercentage(languageId: number): void {
        this.languageService.getLanguagePercentageCalculations([languageId], false).subscribe((percentages) => {
            const percentageInfo = percentages[0];
            if (percentageInfo) {
                this.languagePercentages = percentageInfo;
            }
        });
    }

    public toggleShowNotTranslated(): void {
        this.showOnlyNotTranslated$.next(!this.showOnlyNotTranslated$.value);
        this.setFilteredTranslations();
        this.currentWordform.reset();
        this.selectedTranslation$.next(null);
    }

    private setFilteredTranslations(): void {
        if (this.showOnlyNotTranslated$.getValue()) {
            this.translations = this.translations.filter(
                (translation) => !translation.Translation || translation.Translation.length === 0,
            );
        } else {
            this.translations = this.allTranslations;
        }
    }

    public next(): void {
        this.save();
        this.navigateCurrentWord(+1, this.selectedTranslation$.getValue().Id);
    }

    public previous(): void {
        this.save();
        this.navigateCurrentWord(-1, this.selectedTranslation$.getValue().Id);
    }

    private navigateCurrentWord(offset: number, currentId: number): void {
        const currentIndex = this.translations.findIndex((x) => x.Id === currentId);
        const translation =
            this.translations[(currentIndex + offset + this.translations.length) % this.translations.length];
        this.selectTranslation(translation);
    }

    public async save() {
        this.loaderService.show();
        const currentWordId = this.currentWordform.get('Id').value;
        try {
            if (this.isTranslationChanged) {
                const newTranslation = this.changedTranslation;
                const translation: Translation = {
                    Id: currentWordId,
                    Name: newTranslation.replaceAll('“', ''),
                    Code: this.currentWordFormControls['Code'].value,
                    TranslateLanguageId: this.currentWordFormControls['TranslateLanguageId'].value,
                };

                const currentIndex = this.translations.findIndex((x) => x.Id === currentWordId);
                this.translations[currentIndex].Translation = newTranslation;

                this.saveTranslation(translation);
            }
        } catch (error) {
            this.alertService.error(this.translateService.instant('general.saveFailed'));
        }

        this.loaderService.hide();
    }

    private saveTranslation(translation: Translation) {
        this.translationService.saveTranslation(translation, this.selectedCategory$.getValue().CategoryName).subscribe(
            () => {
                this.alertService.success(this.translateService.instant('general.saveSuccessful'));
                this.selectedTranslation$.next({
                    ...this.selectedTranslation$.getValue(),
                    Translation: translation.Name,
                });

                this.refresh();
                this.isTranslationChanged = false;
            },
            () => this.alertService.error(this.translateService.instant('general.saveFailed')),
        );
    }

    @HostListener('document:keydown', ['$event'])
    public onKeydownHandler(event: KeyboardEvent): void {
        if (event.ctrlKey || event.metaKey) {
            switch (event.key) {
                case 's':
                    event.preventDefault();
                    this.save();
                    break;
                case 'ArrowRight':
                    event.preventDefault();
                    this.next();
                    break;
                case 'ArrowLeft':
                    event.preventDefault();
                    this.previous();
                    break;
            }
        }
    }

    public openHelpModal(): void {
        const options: unknown = {
            initialState: {
                title: this.translateService.instant('translations.helpTitle'),
                content: this.translateService.instant('translations.helpContent'),
            },
            class: 'modal-xl',
        };

        this.modalService.show(TipsDialogComponent, options);
    }

    public async openCopyParentModal(): Promise<void> {
        const pageTitle = this.translateService.instant('translations.copyParentTitle');
        const confirmMessage = this.translateService.instant('translations.copyParentMessage');

        const isConfirmed = await this.confirmService.show(pageTitle, confirmMessage);
        if (isConfirmed) {
            this.loaderService.show();
            await lastValueFrom(
                this.translationService.copyParentTranslations(
                    this.selectedLanguage$.getValue().Id,
                    this.parentLanguageId,
                ),
            );
            this.alertService.success(this.translateService.instant('general.saveSuccessful'));
            this.selectedLanguage$.next(this.selectedLanguage$.getValue());

            this.loaderService.hide();
        }
    }

    public refresh(): void {
        this.selectedLanguage$.next(this.selectedLanguage$.getValue());
        this.search$.next(null);
    }

    private stripHtml(html: string): string {
        const tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    }

    protected screenSize(e: MouseEvent): void {
        e.preventDefault();
        this.expanded = !this.expanded;
        const editorContainer = document.querySelector('div.editor') as HTMLElement;
        const editor = document.querySelector('.NgxEditor') as HTMLElement;

        if (this.expanded) {
            editor.style.height = '100%';
            editorContainer.style.position = 'absolute';
            editorContainer.style.top = '5px';
            editorContainer.style.left = '-30vw';
            editorContainer.style.width = '60vw';
            editorContainer.style.height = '55vh';
            editorContainer.style.zIndex = '3';
        } else {
            editor.style.height = '100%';
            editorContainer.style.position = 'relative';
            editorContainer.style.top = '0';
            editorContainer.style.left = '0';
            editorContainer.style.width = '100%';
            editorContainer.style.height = '25vh';
            editorContainer.style.zIndex = '0';
        }
    }

    public exportTranslations(): void {
        this.loaderService.show();

        const languageId = this.selectedLanguage$.getValue().Id;

        this.translationService
            .downloadTranslations(languageId, this.onlyEmptyTranslations)
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe((file) => {
                Util.openBlobInBrowser(file, file.name);
            });
    }

    public async openImportTranslationsModal(): Promise<void> {
        const options: unknown = {
            initialState: {
                title: this.translateService.instant('translations.importTranslationsTitle'),
                content: this.translateService.instant('translations.importTranslationsContent'),
                uploadUrl: 'api/translations/import',
            },
            class: 'modal-xl',
        };

        const modalRef = this.modalService.show(UploadFileDialogComponent, options);
        const modalComp: UploadFileDialogComponent = modalRef.content;

        modalComp.onOutput.subscribe(() => {
            modalRef.hide();
            this.refresh();
        });
    }
}
