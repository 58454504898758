export class AppConfig {
    environment: string;
    izyShapeSignalRServerURL: string;
    productCodeSpectacles: string;
    APPINSIGHTS_CONNECTIONSTRING: string;
    APPINSIGHTS_ROLEINSTANCE_FRONTEND: string;
    APPINSIGHTS_ROLENAME_FRONTEND: string;
    regions: {
        emea: {
            apiEndpoint: string;
            wordpressEndpoint: string;
        };
        americas: {
            apiEndpoint: string;
            wordpressEndpoint: string;
        };
    };
    isNewTranslationFeatureEnabled: boolean;
    appVersion: string;
}
