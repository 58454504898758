import { Component, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FitLensComponent } from '@app/fitlens/fitlens.component';
import { FittedLensChange } from '@app/fitlens/models/fittedLensChange.model';
import { LensParameterChange } from '@app/fitlens/models/lensParameterChange.model';
import { FittedLens } from '@app/shared/models/fitted-lens.model';
import { FittedLensParameter } from '@app/shared/models/fitted-lens-parameter.model';
import { LensDefinitionParameterNumberRange } from '@app/shared/models/lens-definition-parameter-range.model';
import { FittingEventService } from '@app/core/services/fittingEvent.service';
import { Subscription } from 'rxjs';
import { finalize, debounceTime } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DreamLiteWizardService } from '@app/core/services/api/dreamlite-wizard.service';

@Component({
    selector: 'dreamlite-clearance',
    templateUrl: './dreamlite-clearance.component.html',
    styleUrls: ['./dreamlite-clearance.component.scss'],
})
export class DreamliteClearanceComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() fitlens: FitLensComponent;
    @Input() collapsable: boolean;

    loading = false;
    showSlider = true;

    fittedLensChangedSubscription: Subscription;
    lensParameterChangedSubscription: Subscription;

    formGroup: UntypedFormGroup;

    constructor(
        public fittingEventService: FittingEventService,
        private readonly dreamLiteWizardService: DreamLiteWizardService,
        public fb: UntypedFormBuilder,
    ) {}

    ngOnInit() {
        this.formGroup = this.fb.group({
            clearance: [''],
            clearanceAst: [''],
        });

        if (this.collapsable) {
            this.showSlider = false;
        }
    }

    ngAfterViewInit() {
        this.dreamLiteWizardService.getDreamLiteClearance(this.fitlens.fittedLens).subscribe((lens) => {
            if (lens) {
                this.updateClearance(lens);
            }
        });

        this.fittedLensChangedSubscription = this.fittingEventService.fittedLensChanged.subscribe((result) => {
            if (
                result &&
                result.newFittedLens &&
                result.newFittedLens.EyeSideId === this.fitlens.fittedLens.EyeSideId
            ) {
                this.fittedLensChanged(result);
            }
        });

        this.lensParameterChangedSubscription = this.fittingEventService.lensParameterChanged
            .pipe(debounceTime(500))
            .subscribe((result: LensParameterChange) => {
                if (
                    result &&
                    result.newFittedLens &&
                    result.newFittedLens.EyeSideId === this.fitlens.fittedLens.EyeSideId
                ) {
                    this.lensParameterChanged(result);
                }
            });

        this.formGroup.controls['clearance'].valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            if (!this.fitlens.fittedLens || this.loading) {
                return;
            }

            this.loading = true;
            this.dreamLiteWizardService
                .adjustDreamLiteClearance(this.fitlens.fittedLens, value, false)
                .pipe(
                    finalize(() => {
                        this.loading = false;
                    }),
                )
                .subscribe((lens) => {
                    this.updateParams(this.fitlens.fittedLens.FittedLensParameters, lens.FittedLensParameters);
                    this.fitlens.updateParameterFields();
                });
        });

        this.formGroup.controls['clearanceAst'].valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            if (!this.fitlens.fittedLens || this.loading) {
                return;
            }

            this.loading = true;
            this.dreamLiteWizardService
                .adjustDreamLiteClearance(this.fitlens.fittedLens, value, true)
                .pipe(
                    finalize(() => {
                        this.loading = false;
                    }),
                )
                .subscribe((lens) => {
                    this.updateParams(this.fitlens.fittedLens.FittedLensParameters, lens.FittedLensParameters);
                    this.fitlens.updateParameterFields();
                });
        });
    }

    ngOnDestroy(): void {
        if (this.fittedLensChangedSubscription) {
            this.fittedLensChangedSubscription.unsubscribe();
        }
        if (this.lensParameterChangedSubscription) {
            this.lensParameterChangedSubscription.unsubscribe();
        }
    }

    fittedLensChanged(fittedLensChange: FittedLensChange): void {
        if (!this.loading) {
            this.loading = true;
            this.dreamLiteWizardService
                .getDreamLiteClearance(fittedLensChange.newFittedLens)
                .pipe(
                    finalize(() => {
                        this.loading = false;
                    }),
                )
                .subscribe((lens) => {
                    this.updateClearance(lens);
                });
        }
    }

    lensParameterChanged(lensParameterChange: LensParameterChange): void {
        if (!this.loading) {
            this.loading = true;
            this.dreamLiteWizardService
                .getDreamLiteClearance(lensParameterChange.newFittedLens)
                .pipe(
                    finalize(() => {
                        this.loading = false;
                    }),
                )
                .subscribe((lens) => {
                    this.updateClearance(lens);
                });
        }
    }

    updateParams(oldParams: FittedLensParameter[], newParams: FittedLensParameter[]) {
        oldParams.forEach((flp) => {
            const newParam = newParams.find((lp) => lp.LensDefinitionParameterId === flp.LensDefinitionParameterId);
            flp.Value = DreamliteClearanceComponent.adjustParameterValue(
                newParam.Value,
                flp.LensDefinitionParameter.LensDefinitionParameterRanges as LensDefinitionParameterNumberRange[], // TODO: check if this cast is correct
            );
        });
    }

    updateClearance(fittedLens: FittedLens) {
        this.formGroup.controls['clearance'].setValue(fittedLens.DreamLiteClearance, {
            emitEvent: false,
            onlySelf: true,
        });
        this.formGroup.controls['clearanceAst'].setValue(fittedLens.DreamLiteClearanceAst, {
            emitEvent: false,
            onlySelf: true,
        });
    }

    setClearanceValue(value) {
        this.formGroup.controls['clearance'].setValue(value);
    }

    setClearanceAstValue(value) {
        this.formGroup.controls['clearanceAst'].setValue(value);
    }

    private static adjustParameterValue(value: number, ranges: LensDefinitionParameterNumberRange[]): number {
        const closestValues: number[] = [];

        let ii;

        for (ii = 0; ii < ranges.length; ii++) {
            const max = ranges[ii].Maximum;
            const min = ranges[ii].Minimum;
            const step = ranges[ii].Step;

            if (value > max) {
                closestValues.push(max);
            } else if (value < min) {
                closestValues.push(min);
            } else {
                const rounded = min + Math.round((value - min) / step) * step;
                closestValues.push(rounded);
            }
        }

        let closestValue = 999;

        let mindiff = -1;
        for (ii = 0; ii < closestValues.length; ii++) {
            const val = closestValues[ii];

            const diff = Math.abs(value - val);
            if (ii === 0 || diff < mindiff) {
                closestValue = val;
                mindiff = diff;
            }
        }

        return closestValue;
    }

    hideSlider() {
        if (this.collapsable) {
            this.showSlider = !this.showSlider;
        }
    }

    isToric(): boolean {
        return this.fitlens.fittedLens.LensDefinition.IsToric;
    }
}
