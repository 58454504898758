export enum Features {
    HideOpticianOrthoKSeminarDate = 1,
    HideOpticianSearchCode = 2,
    DisplayOpticianCrtCertificationDate = 3,
    HideMyopiaManagement = 4,
    OpticianLensOrderWarranty = 5,
    OrderingSpares = 6,
    ShowHistoricMeasurementsByDefault = 7,
    HideOverrefraction = 8,
    EnableFollowUp = 9,
    EnableFitLens = 10,
    RequireVisualAcuitity = 11,
    AddFitLensToCart = 12,
    HideResidualRefraction = 13,
    LensNotch = 14,
    StrictUserName = 15,
    HideReorderSerialNumber = 16,
    ExportByCustomerErpReference = 17,
    ExpertMode = 18,
    AutoCreditAllowed = 19,
    ClientMaintenance = 20,
    ReturnsAllowed = 21,
    LensTypeMed = 22,
    Myopie = 23,
    Webshop = 24,
    PowerProfile = 25,
    UsePreviousFrontEnd = 26,
    DirectToHome = 27,
    Glass = 28,
    ManualEdiExport = 29,
    WebshopTile = 30,
    Peros = 31,
    UseOrderBlocking = 32,
    Crt = 33,
    Academy = 34,
    EnableMyopiaForNewClients = 35,
    LensTypeMedPlus = 36,
    AllowPasswordNeverExpires = 37,
    IsTrainingToOrderRequired = 38,
    HasReceiptsModule = 39,
    IsSkippingDistributorSupport = 40,
    ReplacementNotifications = 42,
    OrthoK = 43,
    Soft = 44,
    Rgp = 45,
    Marketing = 46,
    BlockOrders = 47,
    IsReceiptsOnlyEnabled = 48,
    IsOrderWithReceiptEnabled = 49,
    ComplaintsAllowed = 50,
    ExternalLogin = 51,
    AnnualSupply = 52,
    AllowDirectOrders = 53,
    HideOrderStatusFields = 54,
    ShowDynamics = 55,
}
