// TODO: this class should probably only be used to notify application insights of errors
// Links for best practices:
// https://rollbar.com/blog/error-handling-with-angular-8-tips-and-best-practices/#:~:text=One%20traditional%20way%20of%20handling,useful%20for%20tracking%20error%20logs.
// https://medium.com/angular-in-depth/expecting-the-unexpected-best-practices-for-error-handling-in-angular-21c3662ef9e4

import { ErrorHandler, Injectable, Injector, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AlertService } from '@app/shared/appservices/alert.service';
import { LoaderService } from '@app/shared/appservices/loader.service';
import { UtilService } from '@app/shared/appservices/util.service';
import { ApplicationInsightsService } from '@app/core/services/external/application-insights.service';
import { AppConfigService } from '../appservices/appConfig.service';
import { AppStateService } from '../appservices/appState.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    // TODO: Use normal dependency injection instead of the injector to get the services
    constructor(
        @Inject(Injector) private injector: Injector,
        private translateService: TranslateService,
    ) {}

    private get alertService(): AlertService {
        return this.injector.get(AlertService);
    }

    private get loaderService(): LoaderService {
        return this.injector.get(LoaderService);
    }

    private get utilService(): UtilService {
        return this.injector.get(UtilService);
    }

    private get router(): Router {
        return this.injector.get(Router);
    }

    private get appConfigService(): AppConfigService {
        return this.injector.get(AppConfigService);
    }

    private get appStateService(): AppStateService {
        return this.injector.get(AppStateService);
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public handleError(error: any): void {
        this.loaderService.hide();

        if (this.appConfigService.appConfig.environment !== 'production') {
            this.appStateService.isGuiTest
                ? console.error(JSON.stringify(error?.stack ?? error))
                : console.error(error);
        }

        this.injector.get<ApplicationInsightsService>(ApplicationInsightsService).trackException(error);

        const httpErrorCode = (error.rejection ? error.rejection.status : error.status) as number;

        switch (httpErrorCode) {
            case 0:
                this.showError('error.serverunavailable');
                break;
            case 400:
                // TODO: This should be handled in the component that calls the api
                const errors = this.utilService.getServerErrors(error);
                if (errors && errors.length > 0) {
                    errors.forEach((errorMessage: string) => {
                        this.showError(errorMessage);
                    });
                } else {
                    this.showError('error.generic');
                }
                break;
            case 401:
                // TODO: This should be handled by the auth interceptor or by the auth guard
                this.showError(this.translateService.instant('error.unauthorized'));
                this.router.navigateByUrl('/login').then();
                break;
            case 403:
                // TODO: This should be handled by the auth interceptor or by the auth guard
                this.showError(this.translateService.instant('error.unauthorized'));
                this.router.navigateByUrl('/unauthorized').then();
                break;
            case undefined:
                // TODO: Dont show notification popup when there is no http status code this is a temporary fix
                // This case is used for example when something breaks in a component think about template errors
                // When it happens in the fitting lenses flow we want to show the error popup otherwise not
                if (this.isFitlensRoute()) {
                    this.showError('error.generic');
                }
                break;
            default:
                this.showError('error.generic');
                break;
        }
    }

    private isFitlensRoute(): boolean {
        return (
            this.router.url.startsWith('measurement') ||
            this.router.url.startsWith('refraction') ||
            this.router.url.startsWith('lenstype') ||
            this.router.url.startsWith('fitlens') ||
            this.router.url.startsWith('overview')
        );
    }

    private showError(message: string): void {
        this.alertService.error(this.translateService.instant(message), true);
    }
}
