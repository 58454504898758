import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FileDownloadService } from '../file-download.service';
import { Translation } from '@app/shared/models/translation.model';
import { TranslationHistoryModel } from '@app/shared/models/translation-history.model';
import { MultilingualTranslation } from '@app/shared/models/multilingual-translation.model';

@Injectable({
    providedIn: 'root',
})
export class TranslationService implements TranslateLoader {
    private readonly basePath = 'api/translations';

    public frontEndLabelTranslations: unknown;

    constructor(
        private readonly httpClient: HttpClient,
        private translateService: TranslateService,
        private fileDownloadService: FileDownloadService,
    ) {}

    // ============ Old Translation API Calls =================

    public getTranslation(language: string): Observable<unknown> {
        const options = {
            params: new HttpParams().set('lang', language),
        };

        return this.httpClient.get(`${this.basePath}`, options);
    }

    public getAllTranslations(languageId: number): Observable<unknown> {
        return this.httpClient.get(`${this.basePath}/${languageId}`);
    }

    public saveAllTranslations(languageId: number, language: string, translations: unknown): Observable<unknown> {
        const options = {
            params: new HttpParams().set('language', language),
        };

        return this.httpClient
            .post(`${this.basePath}/${languageId}`, translations, options)
            .pipe(tap(() => this.translateService.reloadLang(this.translateService.currentLang)));
    }

    public downloadTranslations(languageId: number, empty: boolean): Observable<File> {
        const options = {
            params: new HttpParams().set('empty', empty.toString()),
        };

        return this.fileDownloadService.downloadFile(`${this.basePath}/${languageId}/download`, options);
    }

    // ============ New Translation API Calls =================

    public getMultilingualTranslationsByCategory(
        languageId: number,
        category: string,
    ): Observable<MultilingualTranslation[]> {
        return this.httpClient.get<MultilingualTranslation[]>(
            `${this.basePath}/multilingual-translations/${languageId}/${category}`,
        );
    }

    public saveTranslation(translation: Translation, category: string): Observable<void> {
        return this.httpClient
            .put<void>(`${this.basePath}/save/${category}`, translation)
            .pipe(tap(() => this.translateService.reloadLang(this.translateService.currentLang)));
    }

    public copyParentTranslations(languageId: number, parentLanguageId: number): Observable<void> {
        return this.httpClient.post<void>(`${this.basePath}/copy-parent-translations`, {
            languageId,
            parentLanguageId,
        });
    }

    public getHistory(
        languageId: number,
        category: string,
        entityTranslationId: number,
    ): Observable<TranslationHistoryModel[]> {
        return this.httpClient.get<TranslationHistoryModel[]>(
            `${this.basePath}/history/${languageId}/${category}/${entityTranslationId}`,
        );
    }

    public exportTranslations(languageId: number, onlyEmptyTranslations: boolean): Observable<File> {
        const options = {
            params: new HttpParams().set('onlyEmptyTranslations', onlyEmptyTranslations.toString()),
        };

        return this.fileDownloadService.downloadFile(`${this.basePath}/${languageId}/export`, options);
    }
}
