<div *ngIf="!formControlOnly && !labelControlStyle" [ngClass]="formGroupRowClass">
    <div [ngClass]="myClass">
        <div [ngClass]="customCheckboxClass">
            <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
            <label id="labelfor-{{ id }}" for="{{ id }}" [ngClass]="customLabelClass">{{ label | translate }}</label>
            <i *ngIf="info" class="fa fa-question-circle" popover="{{ info }}" triggers="mouseenter:mouseleave"></i>
        </div>
    </div>
</div>

<ng-container *ngIf="formControlOnly">
    <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
</ng-container>

<ng-container *ngIf="!formControlOnly && labelControlStyle">
    <div [ngClass]="formGroupRowClass">
        <label id="labelfor-{{ id }}" for="{{ id }}" class="col-form-label" [ngClass]="labelClass">
            <i *ngIf="info" class="fa fa-question-circle" popover="{{ info }}" triggers="mouseenter:mouseleave"></i>
            {{ label | translate: labelParams }}
        </label>
        <div [ngClass]="controlClass">
            <div class="form-check">
                <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
                <label id="labelfor-{{ id }}" for="{{ id }}" class="form-check-label"></label>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #controlTemplate>
    <input
        type="checkbox"
        [id]="id"
        [name]="id"
        class="form-check-input"
        [ngClass]="customInputClass"
        [(ngModel)]="value"
        (change)="propagateChange()"
        [disabled]="isDisabled" />
</ng-template>
