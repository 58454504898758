import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsDialogComponent } from '@app/core/components/settings-dialog/settings-dialog.component';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
    selector: 'password-expiry',
    templateUrl: 'password-expiry.component.html',
    styleUrls: ['password-expiry.component.scss'],
})
export class PasswordExpiryComponent implements OnInit {
    displayPasswordExpiryMessage = false;
    displayPasswordExpiryWarning = false;
    daysUntilExpiry: number;

    constructor(
        private readonly router: Router,
        private readonly appState: AppStateService,
        public modalService: BsModalService,
    ) {}

    async ngOnInit(): Promise<void> {
        await this.handlePasswordExpiry();
        this.daysUntilExpiry = this.appState.passwordExpiryInfo?.DaysUntilExpiry;
    }

    async handlePasswordExpiry(): Promise<void> {
        if (this.appState.authenticatedUser) {
            if (!this.appState.passwordExpiryInfo) {
                await this.appState.loadPasswordExpiryInfo();
            }

            this.checkPasswordExpiry();
        }
    }

    checkPasswordExpiry() {
        if (this.appState.passwordExpiryInfo) {
            if (this.appState.passwordExpiryInfo.PasswordNeverExpires === false) {
                if (this.appState.passwordExpiryInfo.IsExpired) {
                    this.router.navigate(['/login/resetpassword']).then();
                } else {
                    this.displayPasswordExpiryMessage = this.appState.passwordExpiryInfo.DaysUntilExpiry <= 14;
                    this.displayPasswordExpiryWarning = this.appState.passwordExpiryInfo.DaysUntilExpiry <= 7;
                }
            }
        }
    }

    showSettings($event: MouseEvent) {
        $event.preventDefault();
        const options: ModalOptions = { class: 'settingsDialog' };
        this.modalService.show(SettingsDialogComponent, options);
    }
}
