<div *ngIf="!loading" id="{{ id }}" [formGroup]="formGroup" class="d-flex {{ id }}">
    <div class="d-flex flex-wrap">
        <div *ngIf="imageOptions.ShowImageTypes" class="d-flex flex-column mx-1 p-1">
            <div class="text-uppercase mb-1">
                <strong>{{ 'measurementoptions.map' | translate }}</strong>
            </div>
            <div class="mx-1">
                <mpc-input-radio-list
                    [isDisabled]="disabled"
                    formControlName="imageType"
                    id="{{ id }}-imageType"
                    [formControlOnly]="true"
                    [options]="imageTypes"></mpc-input-radio-list>
            </div>
        </div>

        <div *ngIf="imageOptions.ShowImageChoice" class="d-flex flex-column mx-1 p-1">
            <div class="text-uppercase mb-1">
                <strong>{{ 'measurementoptions.type' | translate }}</strong>
            </div>
            <div class="mb-1">
                <div class="mx-1">
                    <mpc-select
                        formControlName="imageChoice"
                        id="{{ id }}-imageChoice"
                        [formControlOnly]="true"
                        [items]="imageChoices"
                        [isDisabled]="disabled"></mpc-select>
                </div>
            </div>
        </div>

        <div class="mx-1 p-1">
            <div class="text-uppercase mb-1">
                <strong>{{ 'measurementoptions.options' | translate }}</strong>
            </div>
            <div class="d-flex flex-wrap">
                <div class="mx-1">
                    <mpc-input-checkbox
                        formControlName="useAxis"
                        [isDisabled]="disabled"
                        [id]="id + '-useAxis'"
                        label="measurementoptions.axis"
                        [offset]="false"
                        [formGroupRow]="false"></mpc-input-checkbox>
                </div>
                <ng-container *ngIf="currentImageMode === MeasurementImageModes.Topo">
                    <div><h6>|</h6></div>
                    <div class="ms-2">
                        <mpc-input-radio-list
                            formControlName="useNormalize"
                            [id]="id + '-useNormalize'"
                            [isDisabled]="disabled"
                            [formControlOnly]="true"
                            [options]="normalizeOptions"></mpc-input-radio-list>
                    </div>
                    <div><h6>|</h6></div>
                    <div class="ms-2">
                        <mpc-input-radio-list
                            formControlName="useMapType"
                            [id]="id + '-useMapType'"
                            [formControlOnly]="true"
                            [isDisabled]="disabled"
                            [options]="axitangOptions"></mpc-input-radio-list>
                    </div>
                    <ng-container
                        *ngIf="!imageOptions.topoImageOptions || !imageOptions.topoImageOptions.ShowPowerProfile">
                        <div><h6>|</h6></div>
                        <div class="ms-2">
                            <mpc-input-radio-list
                                formControlName="useMm"
                                [id]="id + '-useMm'"
                                [formControlOnly]="true"
                                [isDisabled]="disabled"
                                [options]="mmDptTypes"></mpc-input-radio-list>
                        </div>
                    </ng-container>
                    <div><h6>|</h6></div>
                    <div class="ms-2">
                        <mpc-input-checkbox
                            formControlName="useSimK"
                            [id]="id + '-useSimK'"
                            label="measurementoptions.eccentricity"
                            [isDisabled]="disabled"
                            [offset]="false"
                            [formGroupRow]="false"></mpc-input-checkbox>
                    </div>
                </ng-container>
            </div>
        </div>

        <div
            *ngIf="imageOptions.topoImageOptions && imageOptions.topoImageOptions.ShowPowerProfile"
            class="d-flex flex-column mx-1 p-1">
            <div class="text-uppercase mb-1">
                <strong>{{ 'general.powerProfile' | translate }}</strong>
            </div>
            <div class="d-flex flex-wrap">
                <div class="d-flex mx-1">
                    <div class="me-1">
                        {{ 'measurementoptions.rotation' | translate }}
                    </div>
                    <mpc-input-number
                        [formControlName]="'powerProfileAxis'"
                        [formControlOnly]="true"
                        [id]="id + '-powerProfileAxis'"
                        [isDisabled]="disabled"
                        [ranges]="[rotationRange]"
                        [formatting]="{ signedNumber: false, decimalCount: 0 }"
                        [maxLength]="3"
                        [unit]="'units.degree'"></mpc-input-number>
                </div>

                <div class="d-flex mx-1">
                    <div class="me-1">
                        {{ 'measurementoptions.pupil' | translate }}
                    </div>
                    <div class="me-1">
                        <i
                            class="fa fa-question-circle"
                            popover="{{ 'measurementoptions.pupilinfo' | translate }}"
                            triggers="mouseenter:mouseleave"></i>
                    </div>
                    <mpc-input-number
                        [formControlName]="'pupilDiam'"
                        [formControlOnly]="true"
                        [id]="id + '-pupilDiam'"
                        [isDisabled]="disabled"
                        [ranges]="[pupilDiamRange]"
                        [formatting]="{ signedNumber: false, decimalCount: 2 }"
                        [maxLength]="4"
                        [unit]="'units.millimeter'"></mpc-input-number>
                </div>
            </div>
        </div>
    </div>
</div>
