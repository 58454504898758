<div class="modal-body rounded p-0 mb-md-0" id="Screensaver" style="min-height: 1px; min-width: 1px">
    <carousel>
        <slide *ngFor="let screensaveritem of screensaverItems">
            <img src="{{ screensaveritem.featuredImage }}" class="d-block w-100" alt="Slide image" />
            <div class="row screensaver-body w-100 m-0 p-3">
                <div class="col-md-6 col-12">
                    <p class="mb-0">
                        <strong>{{ screensaveritem.title }}</strong>
                    </p>
                    <p class="mb-0" [innerHTML]="screensaveritem.excerpt"></p>
                </div>
            </div>
        </slide>
    </carousel>
</div>
