import { Component, Input, ViewChild } from '@angular/core';
import { MeasurementHistoryComponent } from '@app/measurement/measurement-history/measurement-history.component';
import { ImageChoice } from '@app/shared/enums';
import { Client } from '@app/shared/models';

@Component({
    selector: 'ps-measurements',
    templateUrl: './ps-measurements.component.html',
    styleUrls: ['ps-measurements.component.scss'],
})
export class PsMeasurementsComponent {
    @ViewChild('measurementhistory') measurementHistoryControl: MeasurementHistoryComponent;

    @Input() client: Client;

    public imageChoice = ImageChoice;

    get measurementHistoryReloading(): boolean {
        if (this.measurementHistoryControl) {
            return this.measurementHistoryControl?.isReloading;
        }

        return true;
    }

    get currentImageChoice(): ImageChoice {
        if (this.measurementHistoryControl) {
            return this.measurementHistoryControl.currentImageChoice;
        }

        return ImageChoice.Current;
    }

    get isMarkAsBaselineEnabled(): boolean {
        if (!this.measurementHistoryControl) {
            return false;
        }

        return (
            !this.measurementHistoryControl.isLoading && this.measurementHistoryControl.isRegularTopoMeasurementSelected
        );
    }

    public setImageChoice(imageChoice: ImageChoice): void {
        if (this.measurementHistoryControl) {
            this.measurementHistoryControl.setImageChoice(imageChoice);
        }
    }

    public markAsBaseline(): void {
        if (this.measurementHistoryControl) {
            this.measurementHistoryControl.markAsBaseline();
        }
    }
}
