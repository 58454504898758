import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'split',
})
export class SplitPipe implements PipeTransform {
    transform(value: string, split: string, returnKey = 0): string {
        if (split) {
            return value.split(split)[returnKey];
        }

        return "missing arguments ex. split:'splitBy':key";
    }
}
