export class RefractionMeasurement {
    Id: number;
    OpticianId: number;
    ClientId: number;
    RefractionMeasurementTypeId: number;
    TopographicMeasurementId: number;
    EyeSideId: number;
    Performed: Date;
    VisualAcuitySineCorrectoreValueId: number;
    Sphere: number;
    Cylinder: number;
    Axis: number;
    VisualAcuityCumCorrectoreValueId: number;
    Vertex: number;
    NearAdd: number;
    CorneaDiameter: number;
    Stabilisation: number;
    RefractionOverLens: number | null;
    RefractionHA0Sphere: number;
    RefractionHA0Cylinder: number;
    RefractionHA0Ax: number;
    VisualAcuitySineCorrectoreValue: string;
    VisualAcuityCumCorrectoreValue: string;
    MultifocalAddition: number;
    PupilDiameter: number;
    AxisLength: number;
    Cyclo: number;
    BinoculairMeasurement: BinoculairMeasurement;
    BinoculairMeasurementId: number;
    IsOverrefraction: boolean;
    CopiedTopographicMeasurement: boolean;
    IsBaseLine: boolean;

    compareMeasurementsTo(other: RefractionMeasurement): boolean {
        if (other == null) {
            return false;
        }

        return (
            this.VisualAcuitySineCorrectoreValueId === other.VisualAcuitySineCorrectoreValueId &&
            this.Sphere === other.Sphere &&
            this.Cylinder === other.Cylinder &&
            this.Axis === other.Axis &&
            this.VisualAcuityCumCorrectoreValueId === other.VisualAcuityCumCorrectoreValueId &&
            this.Vertex === other.Vertex &&
            this.NearAdd === other.NearAdd &&
            this.CorneaDiameter === other.CorneaDiameter &&
            this.Performed === other.Performed
        );
    }
}
export class BinoculairMeasurement {
    Id: number;
    OpticianId: number;
    ClientId: number;
    PrismaCoverTestDistance: number;
    PrismaCoverTestDistanceTypeId: number;
    PrismaCoverTestNear: number;
    PrismaCoverTestNearTypeId: number;
    PositiveFusionReserveBreak: number;
    PositiveFusionReserveRecovery: number;
    NegativeFusionReserveBreak: number;
    NegativeFusionReserveRecovery: number;
    AccommodationAmplitude: number;
    PositiveRelativeAccommodation: number;
    NegativeRelativeAccommodation: number;
    AccommodationConvergence: number;
    Accomodation: number;
    NearPointConvergenceBreak: number;
    NearPointConvergenceRecovery: number;
    Remarks: number;
}
