<div class="form-group row" *ngIf="!formControlOnly">
    <label for="{{ id }}" class="col-form-label" [ngClass]="labelClass">
        <i *ngIf="info" class="fa fa-question-circle" popover="{{ info }}" triggers="mouseenter:mouseleave"></i>
        {{ label | translate: labelParams }}
    </label>
    <div [ngClass]="controlClass">
        <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
    </div>
</div>

<div *ngIf="formControlOnly">
    <ng-container *ngTemplateOutlet="controlTemplate"></ng-container>
</div>

<ng-template #controlTemplate>
    <textarea
        id="{{ id }}"
        class="form-control"
        rows="{{ rows }}"
        value="{{ value }}"
        placeholder="{{ placeholder }}"
        [attr.maxlength]="maxLength"
        (keyup)="propagateChange($event)"
        [attr.required]="isRequired"
        [disabled]="isDisabled"></textarea>
</ng-template>
