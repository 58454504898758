export class EyeliteDreamLiteLens {
    constructor(eyeliteDreamLiteLens: EyeliteDreamLiteLens) {
        this.R = eyeliteDreamLiteLens.R;
        this.Rc = eyeliteDreamLiteLens.Rc;
        this.Ac = eyeliteDreamLiteLens.Ac;
        this.Ecc = eyeliteDreamLiteLens.Ecc;
        this.R2 = eyeliteDreamLiteLens.R2;
        this.Rc2 = eyeliteDreamLiteLens.Rc2;
        this.Ac2 = eyeliteDreamLiteLens.Ac2;
        this.Ecc2 = eyeliteDreamLiteLens.Ecc2;
        this.Sphere = eyeliteDreamLiteLens.Sphere;
        this.Diam = eyeliteDreamLiteLens.Diam;
        this.Oz = eyeliteDreamLiteLens.Oz;
        this.Eyeside = eyeliteDreamLiteLens.Eyeside;
        this.Problem = eyeliteDreamLiteLens.Problem;
        this.Degree = eyeliteDreamLiteLens.Degree;
        this.Version = eyeliteDreamLiteLens.Version;
        this.Status = eyeliteDreamLiteLens.Status;
        this.ArticleNumber = eyeliteDreamLiteLens.ArticleNumber;
        this.MaterialNumber = eyeliteDreamLiteLens.MaterialNumber;
        this.MeasurementReference = eyeliteDreamLiteLens.MeasurementReference;
        this.Date = eyeliteDreamLiteLens.Date;
        this.UserId = eyeliteDreamLiteLens.UserId;
        this.OrderReference = eyeliteDreamLiteLens.OrderReference;
        this.Param1 = eyeliteDreamLiteLens.Param1;
        this.Toric = eyeliteDreamLiteLens.Toric;
        this.Multifocal = eyeliteDreamLiteLens.Multifocal;
        this.Ozmf = eyeliteDreamLiteLens.Ozmf;
        this.Radd = eyeliteDreamLiteLens.Radd;
        this.Addition = eyeliteDreamLiteLens.Addition;
        this.Diagnostic = eyeliteDreamLiteLens.Diagnostic;

        this.IsSelected = false;
        this.EyeSideId = eyeliteDreamLiteLens.EyeSideId;
    }

    public R: number;
    public Rc: number;
    public Ac: number;
    public Ecc: number;
    public R2: number;
    public Rc2: number;
    public Ac2: number;
    public Ecc2: number;
    public Sphere: number;
    public Diam: number;
    public Oz: number;
    public Eyeside: number;
    public Problem: number;
    public Degree: number;
    public Version: number;
    public Status: number;
    public ArticleNumber: number;
    public MaterialNumber: number;
    public MeasurementReference: number;
    public Date: string;
    public UserId: number;
    public OrderReference: string;
    public Param1: number;
    public Toric: boolean;
    public Multifocal: boolean;
    public Ozmf: number;
    public Radd: number;
    public Addition: number;
    public Diagnostic: boolean;
    public IsSelected: boolean;
    public EyeSideId: number;
}
