import { Component } from '@angular/core';
import { ServiceStatus } from '@app/shared/enums';
import { ServiceRequest } from '@app/shared/models/service-request.model';

@Component({
    selector: 'qa-overview',
    templateUrl: './qa-overview.component.html',
    styleUrls: ['./qa-overview.component.scss'],
})
export class QaOverviewComponent {
    returnStatuses = ServiceStatus;
    returnStatusIds = [this.returnStatuses.Received];
    returnSelected: ServiceRequest;
    showOverview = true;
    redirectReturnSelected = false;

    receiveReturnParameters($event: ServiceRequest) {
        this.returnSelected = $event;
    }

    receiveReturnRedirect($event: boolean) {
        this.redirectReturnSelected = $event;

        if (this.redirectReturnSelected && this.returnSelected.ReturnStatusId === ServiceStatus.QaHandling) {
            this.showOverview = false;
        }
    }

    receiveScanCall($event: boolean) {
        if (this.returnSelected.SerialNumber !== null) {
            this.showOverview = $event;
        }
    }

    onClickType(key: number) {
        this.returnStatusIds = [key];
    }

    checkIfSelected(statusId: number): string {
        return this.returnStatusIds.includes(statusId) ? 'on' : '';
    }
}
