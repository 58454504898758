import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { Tabs } from '@app/core/components/settings-dialog/enums/tabs.enum';
import { LensConfigurationSettingsComponent } from './components/lens-configuration-settings/lens-configuration-settings.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SettingsDialogService } from './services/settings-dialog.service';
import { OpticianService } from '@app/core/services/api/optician.service';
import { VersionInfoService } from '@app/core/services/api/version-info.service';

@Component({
    selector: 'settings-dialog',
    templateUrl: './settings-dialog.component.html',
    styleUrls: ['settings-dialog.component.scss'],
    providers: [OpticianService, SettingsDialogService],
})
export class SettingsDialogComponent implements OnInit, OnDestroy {
    @ViewChild(LensConfigurationSettingsComponent)
    lensConfigurationSettingsComponent: LensConfigurationSettingsComponent;
    @ViewChild(SettingsComponent) settingsComponent: SettingsComponent;

    protected appVersion = '';
    protected apiVersion = '';
    public closeModals: Subscription;
    public tabs = Tabs;
    public tab = Tabs.Account;

    constructor(
        public router: Router,
        public bsModalRef: BsModalRef,
        public appState: AppStateService,
        public appConfigService: AppConfigService,
        public versionInfoService: VersionInfoService,
    ) {}

    ngOnInit(): void {
        this.appVersion = this.normalizeVersion(this.appConfigService.appConfig.appVersion);

        this.versionInfoService.getVersionInfo().subscribe((version) => {
            this.apiVersion = this.normalizeVersion(version);
        });
        this.closeModals = this.appState.closeModals$.subscribe(() => {
            this.bsModalRef.hide();
        });
    }

    ngOnDestroy(): void {
        if (this.closeModals) {
            this.closeModals.unsubscribe();
        }
    }

    switchTab(tab: Tabs): void {
        this.tab = tab;
    }

    tabClass(tab: Tabs): string {
        return this.tab === tab ? 'active' : '';
    }

    toRegionPage(): void {
        this.bsModalRef.hide();
        this.router.navigate(['/region']).then();
    }

    isSettingsEnabled(): boolean {
        return this.appState.isOptician || this.appState.isPs;
    }

    saveLensConfiguration() {
        this.lensConfigurationSettingsComponent.saveLensConfiguration();
    }

    async saveSettings() {
        await this.settingsComponent.saveSettings();
    }

    private normalizeVersion(version: string): string {
        return version.includes('release/') ? version.split('/')[1] : version;
    }
}
