import { FittingSteps } from '@app/shared/enums';
import { CartItem, Client, Proposal, RefractionMeasurement } from '@app/shared/models';
import { SessionService } from '@app/shared/appservices/session.service';
import { TopographicManualCalculation } from '@app/shared/models/topographicManualCalculation.model';
import { ClientSearchRequest } from '@app/shared/requestmodels';
import { ScopedSessionService } from '@app/shared/appservices/scoped-session.service';
import { BevelTypes } from '@app/shared/enums/bevel-types.enum';

export class FittingStateService {
    private _client: Client;
    private _proposal: Proposal;
    private _cartItem: CartItem;
    private session: ScopedSessionService;

    constructor(sessionService: SessionService) {
        this.session = new ScopedSessionService(sessionService, 'fitting-state');
    }

    clear(): void {
        this.session.clear();
    }

    get isReorder(): boolean {
        return this.session.getAsObject<boolean>('isReorder');
    }
    set isReorder(value: boolean) {
        this.session.save('isReorder', value);
    }

    get isSpareOrder(): boolean {
        return this.session.getAsObject<boolean>('isSpareOrder');
    }
    set isSpareOrder(value: boolean) {
        this.session.save('isSpareOrder', value);
    }

    get isAnnualSupplyFullfillmentOrder(): boolean {
        return this.session.getAsObject<boolean>('isAnnualSupplyFullfillmentOrder');
    }
    set isAnnualSupplyFullfillmentOrder(value: boolean) {
        this.session.save('isAnnualSupplyFullfillmentOrder', value);
    }

    get isGlassFitting(): boolean {
        return this.session.getAsObject<boolean>('isGlassFitting');
    }
    set isGlassFitting(value: boolean) {
        this.session.save('isGlassFitting', value);
    }

    get isCartItem(): boolean {
        return this.session.getAsObject<boolean>('isCartItem');
    }

    set isCartItem(value: boolean) {
        this.session.save('isCartItem', value);
    }

    get step(): FittingSteps {
        return this.session.getAsObject<number>('step');
    }
    set step(value: FittingSteps) {
        this.session.save('step', value);
    }

    get previousStep(): FittingSteps {
        return this.session.getAsObject<number>('previousStep');
    }
    set previousStep(value: FittingSteps) {
        this.session.save('previousStep', value);
    }

    get orderId(): number {
        return this.session.getAsObject<number>('orderId');
    }
    set orderId(value: number) {
        this.session.save('orderId', value);
    }

    get receiptId(): number {
        return this.session.getAsObject<number>('receiptId');
    }

    set receiptId(value: number) {
        this.session.save('receiptId', value);
    }

    get isLeftEyeSideSelected(): boolean {
        return this.session.getAsObject<boolean>('isLeftEyeSideSelected');
    }
    set isLeftEyeSideSelected(value: boolean) {
        this.session.save('isLeftEyeSideSelected', value);
    }

    get isRightEyeSideSelected(): boolean {
        return this.session.getAsObject<boolean>('isRightEyeSideSelected');
    }
    set isRightEyeSideSelected(value: boolean) {
        this.session.save('isRightEyeSideSelected', value);
    }

    get originalRightRefractionMeasurement(): RefractionMeasurement {
        return this.session.getAsObject<RefractionMeasurement>('originalRightRefractionMeasurement');
    }
    set originalRightRefractionMeasurement(value: RefractionMeasurement) {
        this.session.save('originalRightRefractionMeasurement', value);
    }

    get originalLeftRefractionMeasurement(): RefractionMeasurement {
        return this.session.getAsObject<RefractionMeasurement>('originalLeftRefractionMeasurement');
    }
    set originalLeftRefractionMeasurement(value: RefractionMeasurement) {
        this.session.save('originalLeftRefractionMeasurement', value);
    }

    get leftManualCalculation(): TopographicManualCalculation {
        return this.session.getAsObject<TopographicManualCalculation>('leftManualCalculation');
    }
    set leftManualCalculation(value: TopographicManualCalculation) {
        this.session.save('leftManualCalculation', value);
    }

    get rightManualCalculation(): TopographicManualCalculation {
        return this.session.getAsObject<TopographicManualCalculation>('rightManualCalculation');
    }
    set rightManualCalculation(value: TopographicManualCalculation) {
        this.session.save('rightManualCalculation', value);
    }

    get selectedBevelType(): BevelTypes {
        return this.session.getAsObject<BevelTypes>('selectedBevelType');
    }
    set selectedBevelType(value: BevelTypes) {
        this.session.save('selectedBevelType', value);
    }

    get clientSearchRequest(): ClientSearchRequest {
        return this.session.getAsObject<ClientSearchRequest>('clientsearchrequest');
    }
    set clientSearchRequest(clientSearchRequest: ClientSearchRequest) {
        this.session.save('clientsearchrequest', clientSearchRequest);
    }

    get client(): Client {
        if (!this._client) {
            this._client = this.session.getAsObject<Client>('client');
        }
        return this._client;
    }
    set client(client: Client) {
        this._client = client;
        this.session.save('client', this._client);
    }

    get proposal(): Proposal {
        if (!this._proposal) {
            this._proposal = this.session.getAsObject<Proposal>('proposal');
        }
        return this._proposal;
    }
    set proposal(proposal: Proposal) {
        this._proposal = proposal;
        this.session.save('proposal', this._proposal);
    }

    get cartItem(): CartItem {
        if (!this._cartItem) {
            this._cartItem = this.session.getAsObject<CartItem>('cartitem');
        }
        return this._cartItem;
    }

    set cartItem(value: CartItem) {
        this._cartItem = value;
        this.session.save('cartitem', this._cartItem);
    }
}
