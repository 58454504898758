import { Pipe, PipeTransform } from '@angular/core';
import { InputNumberRange } from '../models/input-number-range.model';

@Pipe({
    name: 'rangeCheck',
})
export class RangeCheckPipe implements PipeTransform {
    transform(value: number, ranges: InputNumberRange[], checkType: 'min' | 'max'): boolean {
        if (!value || !ranges || ranges.length === 0) {
            return false;
        }
        const extremeValue =
            checkType === 'min'
                ? Math.min(...ranges.map((range) => range.Minimum))
                : Math.max(...ranges.map((range) => range.Maximum));

        return checkType === 'min' ? value <= extremeValue : value >= extremeValue;
    }
}
