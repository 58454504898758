import { Component, OnInit, Input } from '@angular/core';
import { ListOption } from '@app/shared/models';
import { PsSupportOrderComponent } from '../ps-supportorder.component';
import { WorkItemsService } from '@app/core/services/api/work-items.service';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SupportOrderStatus } from '../../models/SupportOrderStatus.model';
import { UserService } from '@app/core/services/api/user.service';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'ps-header',
    templateUrl: './ps-header.component.html',
    styleUrls: ['ps-header.component.scss'],
})
export class PsHeaderComponent implements OnInit {
    @Input() psSupportOrderComponent: PsSupportOrderComponent;
    @Input() showPrevious = true;
    supporterFormGroup: UntypedFormGroup;
    supporters: ListOption[];
    currentPage: string;
    LongClientReference: string;

    constructor(
        private appState: AppStateService,
        private readonly workitemsService: WorkItemsService,
        private readonly fb: UntypedFormBuilder,
        private readonly userService: UserService,
    ) {}

    async ngOnInit() {
        this.createForm();

        const users = await lastValueFrom(this.userService.getSupporterUsers());
        let supporters = new Array<ListOption>();
        const unknownSupporter = new ListOption(0, 'Not assigned', '');
        supporters.push(unknownSupporter);
        supporters = supporters.concat(users);
        this.supporters = supporters;
        this.supporterFormGroup.controls['supporter'].patchValue(this.psSupportOrderComponent.getSupporterId());
        this.currentPage = this.psSupportOrderComponent.getCurrentPage();
    }

    createForm(): void {
        this.supporterFormGroup = this.fb.group({
            supporter: [],
        });
    }

    onHeaderItem(headerTitle: string): void {
        this.currentPage = headerTitle;
        this.psSupportOrderComponent.setCurrentPage(headerTitle);
    }

    get order() {
        const order = this.psSupportOrderComponent.getOrder();
        this.LongClientReference = [order.Client.Reference, order.Client.Reference2].join(' ').trim();
        return order;
    }

    get supportOrderStatus(): SupportOrderStatus {
        return this.psSupportOrderComponent.getSupportOrderStatus();
    }

    get priorityImageSource(): string {
        if (this.order.PriorityId === 2) {
            return '/assets/images/order-priority-' + this.appState.authenticatedUser.CurrentApplicationTheme + '.png';
        }
        return '';
    }

    get selectedSupporterId(): number {
        return this.supporterFormGroup.controls.supporter.value;
    }

    updateSupporterForm(supporterId) {
        this.supporterFormGroup.controls['supporter'].patchValue(supporterId);
    }

    updateSupporter(): void {
        let supporterId = this.selectedSupporterId;
        if (supporterId == 0) {
            // important to keep this at == else this will remain 0
            supporterId = null;
        }
        lastValueFrom(
            this.workitemsService.updateSupporter(this.psSupportOrderComponent.getOrder().Id, supporterId),
        ).then();
    }
}
