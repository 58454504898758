import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppConfigService } from '@app/shared/appservices/appConfig.service';
import { AccountSettingsComponent } from '@app/core/components/settings-dialog/components/account-settings/account-settings.component';
import { SettingsDialogComponent } from '@app/core/components/settings-dialog/settings-dialog.component';
import { SharedModule } from '@app/shared/shared.module';
import { PasswordSettingsComponent } from '@app/core/components/settings-dialog/components/password-settings/password-settings.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { SettingsComponent } from './components/settings/settings.component';
import { LensConfigurationSettingsComponent } from './components/lens-configuration-settings/lens-configuration-settings.component';

@NgModule({
    imports: [TypeaheadModule.forRoot(), CommonModule, SharedModule],
    declarations: [
        SettingsComponent,
        LensConfigurationSettingsComponent,
        PasswordSettingsComponent,
        AccountSettingsComponent,
        SettingsDialogComponent,
    ],
    providers: [AppConfigService],
})
export class SettingsDialogModule {}
