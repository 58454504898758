import { RefractionMeasurement } from '@app/shared/models';

export class RefractionMeasurementHa0Helper {
    static calcCorneaNulDis(refractionMeasurement: RefractionMeasurement): RefractionMeasurement {
        const dbc = refractionMeasurement.Vertex / 1000.0;
        const sphere0 = refractionMeasurement.Sphere / (1.0 - refractionMeasurement.Sphere * dbc);
        const cyl0 =
            (refractionMeasurement.Sphere + refractionMeasurement.Cylinder) /
                (1.0 - (refractionMeasurement.Sphere + refractionMeasurement.Cylinder) * dbc) -
            sphere0;

        refractionMeasurement.RefractionHA0Sphere = sphere0;
        refractionMeasurement.RefractionHA0Cylinder = cyl0;
        refractionMeasurement.RefractionHA0Ax = refractionMeasurement.Axis == null ? 0 : refractionMeasurement.Axis;

        return refractionMeasurement;
    }
}
