<div class="modal-header">
    <h4 *ngIf="!loading" class="modal-title m-auto">
        {{ 'general.servicerequest' | translate }}
        {{ serviceData.Return.ReturnNumber }}
    </h4>
</div>

<div class="modal-body p-4">
    <div class="position-absolute d-flex align-items-center rounded img-overlay img-loading" *ngIf="loading">
        <div class="spinner-border spinner-border-sm m-auto"></div>
    </div>

    <div *ngIf="!loading">
        <div class="row" *ngIf="serialNumberAlreadyReturned">
            <div class="col-12">
                <div class="title-border-bottom serialnumberalreadyreturned mb-2 pb-3">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{ 'service.serialnumberalreadyreturned' | translate }}
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="row">
                    <div class="col-4 font-weight-bold">
                        {{ 'general.serialnumberlong' | translate }}
                    </div>
                    <div class="col-8" id="serialnumber">
                        {{ serviceData.Return.SerialNumber }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 font-weight-bold">
                        {{ 'general.product' | translate }}
                    </div>
                    <div class="col-8">{{ serviceData.Return.ProductName }}</div>
                </div>
                <div class="row">
                    <div class="col-4 font-weight-bold">
                        {{ 'general.material' | translate }}
                    </div>
                    <div class="col-8">{{ serviceData.Return.MaterialName }}</div>
                </div>
                <div class="row">
                    <div class="col-4 font-weight-bold">
                        {{ 'general.eyesides' | translate }}
                    </div>
                    <div class="col-8">{{ serviceData.Return.EyeSideName }}</div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="row">
                    <div class="col-4 font-weight-bold">
                        {{ 'general.optician' | translate }}
                    </div>
                    <div class="col-8">{{ serviceData.Return.OpticianName }}</div>
                </div>
                <div class="row" *ngIf="serviceData.Return.ClientReference">
                    <div class="col-4 font-weight-bold">
                        {{ 'general.yourreference' | translate }}
                    </div>
                    <div class="col-8">
                        {{ serviceData.Return.ClientReference }}
                    </div>
                </div>
                <div class="row" *ngIf="serviceData.Return.ClientReference2">
                    <div class="col-4 font-weight-bold">
                        {{ 'general.reference2' | translate }}
                    </div>
                    <div class="col-8">
                        {{ serviceData.Return.ClientReference2 }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <hr />
        </div>

        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-2 font-weight-bold">
                        {{ 'general.servicerequest' | translate }}
                    </div>
                    <div class="col-10">
                        {{ serviceData.Return.ReturnCategoryName }}
                    </div>
                </div>
                <div class="row" *ngIf="serviceData.Return.ReturnCategoryId === 3">
                    <div class="col-2 font-weight-bold">Type:</div>
                    <div class="col-10">{{ serviceData.Return.ReturnTypeName }}</div>
                </div>
                <div class="row">
                    <div class="col-2 font-weight-bold">
                        {{ 'general.description' | translate }}
                    </div>
                    <div class="col-10">{{ serviceData.Return.Description }}</div>
                </div>
            </div>
        </div>

        <div class="row">
            <hr />
        </div>

        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="row">
                    <div class="col-4 font-weight-bold">
                        {{ 'service.conclusion' | translate }}
                    </div>
                    <div class="col-8">{{ serviceData.ReturnConclusion?.Conclusion }}</div>
                </div>
                <div class="row">
                    <div class="col-4 font-weight-bold">
                        {{ 'service.result' | translate }}
                    </div>
                    <div class="col-8">{{ serviceData.ReturnConclusion?.ReturnResultName }}</div>
                </div>
                <div *ngIf="!serviceData.Return.CreditSentOn" class="row">
                    <div class="col-4 font-weight-bold">
                        {{ 'service.inprogressdate' | translate }}
                    </div>
                    <div class="col-8">{{ serviceData.Return.InProgressDate | mpcdate }}</div>
                </div>
                <div *ngIf="serviceData.Return.CreditSentOn" class="row">
                    <div class="col-4 font-weight-bold">
                        {{ 'service.dispatchdate' | translate }}
                    </div>
                    <div class="col-8">
                        {{ serviceData.Return.CreditSentOn | mpcdate }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="row">
                    <div class="col-4 font-weight-bold">
                        {{ 'service.crediting' | translate }}
                    </div>
                    <div class="col-8">{{ serviceData.ReturnConclusion?.ReturnCreditName }}</div>
                </div>
                <div class="row">
                    <div class="col-4 font-weight-bold">
                        {{ 'service.supporter' | translate }}
                    </div>
                    <div class="col-8">
                        {{ serviceData.Return.IsChangedByUserName }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer accented-background">
    <div class="ms-auto">
        <button class="btn btn-primary" (click)="downloadPdf()">
            {{ 'service.download' | translate }}
        </button>
        <button class="btn btn-default ms-2" id="cancelServiceDialog" (click)="bsModalRef.hide()">
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</div>
