import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { SupportOrderQueryCriteria } from '../models/SupportOrderQueryCriteria.model';
import { SupportOrderStatus } from '../models/SupportOrderStatus.model';
import { SupportOrderList } from '../models/SupportOrderList.model';
import { SupportOrder } from '../models/SupportOrder.Model';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SupportOrderService } from '@app/core/services/api/support-order.service';
import { OrderService } from '@app/core/services/api/order.service';
import { Router } from '@angular/router';
import { AppStateService } from '@app/shared/appservices/appState.service';
import { OrderInfoMinimalQueryCriteria } from '@app/shared/models/orderInfoMinimalQueryCriteria.model';
import { OrderInfoMinimal } from '@app/shared/models/orderInfoMinimal.model';
import { QueryResult } from '@app/shared/models/queryResult.model';
import { OrderTypes, SupportOrderStatuses, OrderStatuses, Features } from '@app/shared/enums';
import { UpdateOrderStatus } from '@app/shared/models/updateOrderStatus.model';
import { OrderStatusChange } from '@app/shared/models/orderStatusChange.model';
import { SupportEventService } from '@app/core/services/supportEvent.service';
import { Subscription, lastValueFrom, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
    selector: 'supportorder-overview',
    templateUrl: 'supportorder-overview.component.html',
    styleUrls: ['./supportorder-overview.component.scss'],
})
export class SupportorderOverviewComponent implements OnInit, OnDestroy, AfterViewInit {
    orderTypeWebshop = OrderTypes.WebshopOrder;
    loading = true;
    supportOrderList: SupportOrderList;
    supportOrders: SupportOrder[];

    filterSupportOrdersForm: UntypedFormGroup;
    filterSupportOrdersFormSubscription: Subscription;
    filterDirectOrdersForm: UntypedFormGroup;
    filterDirectOrdersFormSubscription: Subscription;

    delayTime = 500;

    selectedSupportorderStatusId = SupportOrderStatuses.New;
    isOverviewMyOrdersSelected = false;
    isOverviewOrdersWithUnreadMessagesSelected = false;
    isOverviewPerosSelected = false;
    isOverviewDirectOrdersSelected: boolean;
    isOverviewBlockedOrdersSelected = false;

    ascendingField = 'Date';
    isAscending = false;
    isSupportOrderRequest = true;
    perosEnabled = false;

    supportOrderQueryCriteria: SupportOrderQueryCriteria;
    maxPage: number;
    totalOrders: number;
    pagesShown = [0, 1, 2, 3, 4];
    itemsShownLowerBound = 0;
    itemsShownUpperBound = 0;

    selectedOrderIds: number[] = [];

    orderInfoMinimalQueryCriteria: OrderInfoMinimalQueryCriteria;
    orderInfoMinimalGrid: QueryResult<OrderInfoMinimal>;

    date = 'Date';
    opticianName = 'OpticianName';
    clientReference = 'ClientReference';
    clientReference2 = 'ClientReference2';
    birthDate = 'BirthDate';
    lensTypeCode = 'LensTypeCode';
    message = 'Message';
    proposalTypeName = 'ProposalTypeName';
    orderReference = 'OrderReference';
    supporterName = 'SupporterName';
    perosOrderNumber = 'PerosOrderNumber';
    orderId = 'Id';
    totalQuantity = 'TotalQuantity';

    supportOrderSearchfields = [
        this.date,
        this.opticianName,
        this.clientReference,
        this.clientReference2,
        this.birthDate,
        this.lensTypeCode,
        this.message,
        this.proposalTypeName,
        this.orderReference,
        this.supporterName,
        this.perosOrderNumber,
        this.orderId,
    ];

    directOrderSearchFields = [
        this.date,
        this.opticianName,
        this.clientReference,
        this.clientReference2,
        this.totalQuantity,
        this.birthDate,
        this.lensTypeCode,
        this.message,
        this.proposalTypeName,
        this.orderReference,
        this.perosOrderNumber,
        this.orderId,
    ];

    constructor(
        public supportOrderService: SupportOrderService,
        public orderService: OrderService,
        public appState: AppStateService,
        private readonly fb: UntypedFormBuilder,
        public router: Router,
        public supportEventService: SupportEventService,
    ) {
        this.supportOrderQueryCriteria = new SupportOrderQueryCriteria();
        this.supportOrderQueryCriteria.setDefault();
        this.orderInfoMinimalQueryCriteria = new OrderInfoMinimalQueryCriteria();
        this.orderInfoMinimalQueryCriteria.setDefault();
        this.orderInfoMinimalGrid = new QueryResult<OrderInfoMinimal>();
    }

    ngOnInit(): void {
        this.createForm();
        this.createSubscriptions();
    }

    ngOnDestroy(): void {
        this.filterDirectOrdersFormSubscription.unsubscribe();
        this.filterSupportOrdersFormSubscription.unsubscribe();
    }

    createSubscriptions() {
        this.filterDirectOrdersFormSubscription = this.filterDirectOrdersForm.valueChanges
            .pipe(debounce(() => timer(this.delayTime)))
            .subscribe(() => {
                if (this.checkIfDirty()) {
                    this.findDirectOrders();
                }
            });

        this.filterSupportOrdersFormSubscription = this.filterSupportOrdersForm.valueChanges
            .pipe(debounce(() => timer(this.delayTime)))
            .subscribe(() => {
                if (this.checkIfDirty()) {
                    this.findSupportOrders();
                }
            });
    }

    createForm(): void {
        this.filterSupportOrdersForm = this.fb.group({
            Date: [],
            OpticianName: [],
            ClientReference: [],
            ClientReference2: [],
            BirthDate: [],
            LensTypeCode: [],
            Message: [],
            ProposalTypeName: [],
            OrderReference: [],
            SupporterName: [],
            PerosOrderNumber: [],
            Id: [],
        });
        this.filterDirectOrdersForm = this.fb.group({
            Date: [],
            OpticianName: [],
            ClientReference: [],
            ClientReference2: [],
            TotalQuantity: [],
            BirthDate: [],
            LensTypeCode: [],
            Message: [],
            ProposalTypeName: [],
            OrderReference: [],
            selectedOrderIds: [],
            PerosOrderNumber: [],
            Id: [],
        });
    }

    ngAfterViewInit() {
        this.perosEnabled = this.appState.isDistributorFeatureEnabled(Features.Peros);
        this.findSupportOrders();
        this.addOrRemovePerosRelatedColumns();
    }

    onSelectSupportOrder(supportOrder: SupportOrder): void {
        this.router.navigate(['ps/supportorder/' + supportOrder.Id]).then();
    }

    onSelectDirectOrder(orderInfoMinimal: OrderInfoMinimal): void {
        if (orderInfoMinimal.OrderTypeId !== OrderTypes.WebshopOrder) {
            this.router.navigate(['ps/supportorder/' + orderInfoMinimal.Id]).then();
        }
    }

    isSupportOrderStatusSelected(id: number): boolean {
        return this.selectedSupportorderStatusId === id;
    }

    findSupportOrders() {
        this.supportOrderQueryCriteria.SupportOrderStatusId = this.selectedSupportorderStatusId;
        this.supportOrderQueryCriteria.ShowMyItemsOnly = this.isOverviewMyOrdersSelected;
        this.supportOrderQueryCriteria.ShowSupportOrdersWithNewMessageOnly =
            this.isOverviewOrdersWithUnreadMessagesSelected;
        this.supportOrderQueryCriteria.ShowPerosOrdersOnly = this.isOverviewPerosSelected;
        this.addOrRemovePerosRelatedColumns();
        this.addSorting(this.supportOrderQueryCriteria);
        this.addFiltersToSupportOrderQueryCriteria(this.supportOrderQueryCriteria);

        this.loading = true;
        this.supportOrderService.getSupportOrders(this.supportOrderQueryCriteria).subscribe((list) => {
            this.supportOrderList = list;
            this.supportOrders = this.supportOrderList.Items;
            this.totalOrders = this.supportOrderList.TotalItems;
            this.maxPage = parseInt('' + this.totalOrders / 50, 10);
            this.setPagesShown();
            this.loading = false;
        });
    }

    findDirectOrders() {
        this.orderInfoMinimalQueryCriteria.PageIndex = this.supportOrderQueryCriteria.PageIndex;
        this.orderInfoMinimalQueryCriteria.ShowNoPoOrderEntered = this.isOverviewPerosSelected;
        this.orderInfoMinimalQueryCriteria.ShowBlockedOrder = this.isOverviewBlockedOrdersSelected;
        this.addDirectOrderSorting(this.orderInfoMinimalQueryCriteria);
        this.addFiltersToDirectOrderQueryCriteria(this.orderInfoMinimalQueryCriteria);
        this.addOrRemovePerosRelatedColumns();

        this.loading = true;
        this.orderService.find(this.orderInfoMinimalQueryCriteria).subscribe((list) => {
            const queryResult: QueryResult<OrderInfoMinimal> = list;
            this.orderInfoMinimalGrid.Items = queryResult.Items;
            this.orderInfoMinimalGrid.TotalItems = queryResult.TotalItems;
            this.totalOrders = queryResult.TotalItems;
            this.maxPage = parseInt('' + this.totalOrders / 50, 10);
            this.setPagesShown();
            this.loading = false;
        });
    }

    onSortByField(field: string): void {
        this.isAscending = this.ascendingField === field ? !this.isAscending : false;
        this.ascendingField = field;
        if (
            this.isOverviewDirectOrdersSelected ||
            this.isOverviewPerosSelected ||
            this.isOverviewBlockedOrdersSelected
        ) {
            this.findDirectOrders();
        } else {
            this.findSupportOrders();
        }
    }

    setOverviewMyOrders(isSelected: boolean): void {
        this.selectedSupportorderStatusId = null;
        this.isOverviewDirectOrdersSelected = false;
        this.isOverviewMyOrdersSelected = isSelected;
        this.isOverviewOrdersWithUnreadMessagesSelected = false;
        this.isOverviewPerosSelected = false;
        this.isOverviewBlockedOrdersSelected = false;
        this.findSupportOrders();
    }

    setOverviewPeros(isSelected: boolean): void {
        this.selectedSupportorderStatusId = null;
        this.isOverviewDirectOrdersSelected = true;
        this.isOverviewMyOrdersSelected = false;
        this.isOverviewOrdersWithUnreadMessagesSelected = false;
        this.isOverviewPerosSelected = isSelected;
        this.isOverviewBlockedOrdersSelected = false;
        this.findDirectOrders();
    }

    setOverviewBlockedOrders(blockedOrdersSelected: boolean): void {
        this.selectedSupportorderStatusId = null;
        this.isOverviewDirectOrdersSelected = true;
        this.isOverviewMyOrdersSelected = false;
        this.isOverviewOrdersWithUnreadMessagesSelected = false;
        this.isOverviewPerosSelected = false;
        this.isOverviewBlockedOrdersSelected = blockedOrdersSelected;
        this.findDirectOrders();
    }

    setOverviewDirectOrders(): void {
        this.selectedSupportorderStatusId = null;
        this.isOverviewDirectOrdersSelected = true;
        this.isOverviewMyOrdersSelected = false;
        this.isOverviewOrdersWithUnreadMessagesSelected = false;
        this.isOverviewPerosSelected = false;
        this.isOverviewBlockedOrdersSelected = false;
        this.findDirectOrders();
    }

    onFilterByField(): void {
        if (this.checkIfDirty()) {
            if (this.isOverviewDirectOrdersSelected || this.isOverviewPerosSelected) {
                this.findDirectOrders();
            } else {
                this.findSupportOrders();
            }
        }
    }

    checkIfDirty(): boolean {
        if (this.filterSupportOrdersForm.dirty || this.filterDirectOrdersForm.dirty) {
            this.filterSupportOrdersForm.markAsPristine();
            this.filterDirectOrdersForm.markAsPristine();

            return true;
        }

        return false;
    }

    setOverviewOrdersWithUnreadMessages(isSelected: boolean): void {
        this.selectedSupportorderStatusId = null;
        this.isOverviewDirectOrdersSelected = false;
        this.isOverviewMyOrdersSelected = false;
        this.isOverviewOrdersWithUnreadMessagesSelected = isSelected;
        this.isOverviewPerosSelected = false;
        this.isOverviewBlockedOrdersSelected = false;
        this.findSupportOrders();
    }

    addDirectOrderSorting(orderInfoMinimalQueryCriteria: OrderInfoMinimalQueryCriteria): void {
        orderInfoMinimalQueryCriteria.OrderByAscending = this.isAscending;
        orderInfoMinimalQueryCriteria.OrderByField = this.ascendingField;
    }

    addSorting(supportOrderQueryCriteria: SupportOrderQueryCriteria): void {
        supportOrderQueryCriteria.OrderByAscending = this.isAscending;
        supportOrderQueryCriteria.OrderByField = this.ascendingField;
    }

    addFiltersToDirectOrderQueryCriteria(orderInfoMinimalQueryCriteria: OrderInfoMinimalQueryCriteria): void {
        orderInfoMinimalQueryCriteria.FilterByFields = {};
        for (const field of this.directOrderSearchFields) {
            if (
                this.filterDirectOrdersForm.controls[field].value &&
                this.filterDirectOrdersForm.controls[field].value !== ''
            ) {
                orderInfoMinimalQueryCriteria.FilterByFields[field] = this.filterDirectOrdersForm.controls[field].value;
            }
        }
    }

    addFiltersToSupportOrderQueryCriteria(supportOrderQueryCriteria: SupportOrderQueryCriteria): void {
        supportOrderQueryCriteria.FilterByFields = {};
        for (const field of this.supportOrderSearchfields) {
            if (
                this.filterSupportOrdersForm.controls[field].value &&
                this.filterSupportOrdersForm.controls[field].value !== ''
            ) {
                supportOrderQueryCriteria.FilterByFields[field] = this.filterSupportOrdersForm.controls[field].value;
            }
        }
    }

    determineField(field: string): boolean {
        if (field === this.ascendingField) {
            this.ascendingField = '';
            return false;
        } else {
            this.ascendingField = field;
            return true;
        }
    }

    resetSortingAndFilters() {
        this.ascendingField = 'Date';
        this.isAscending = true;

        for (const control of Object.keys(this.filterSupportOrdersForm.controls)) {
            this.filterSupportOrdersForm.controls[control].patchValue(null);
        }
    }

    onSortBySupportOrderStatus(supportOrderStatus: SupportOrderStatus) {
        this.isOverviewPerosSelected = false;
        this.isOverviewBlockedOrdersSelected = false;
        this.isOverviewMyOrdersSelected = false;
        this.isOverviewOrdersWithUnreadMessagesSelected = false;
        this.isOverviewDirectOrdersSelected = false;
        this.selectedSupportorderStatusId = supportOrderStatus.Id;

        this.findSupportOrders();
    }

    onPage(command: string | number): void {
        switch (command) {
            case 'first': {
                this.supportOrderQueryCriteria.PageIndex = 0;
                break;
            }
            case 'previous': {
                this.supportOrderQueryCriteria.PageIndex--;
                break;
            }
            case 'next': {
                this.supportOrderQueryCriteria.PageIndex++;
                break;
            }
            case 'last': {
                this.supportOrderQueryCriteria.PageIndex = this.maxPage;
                break;
            }
            default: {
                this.supportOrderQueryCriteria.PageIndex = parseInt(command?.toString(), 10);
            }
        }

        if (this.isOverviewDirectOrdersSelected || this.isOverviewPerosSelected) {
            this.findDirectOrders();
        } else {
            this.findSupportOrders();
        }
    }

    setPagesShown(): void {
        if (this.totalOrders > 5 * this.supportOrderQueryCriteria.PageSize) {
            this.pagesShown = [];
            this.pagesShown.push(this.supportOrderQueryCriteria.PageIndex - 2);
            this.pagesShown.push(this.supportOrderQueryCriteria.PageIndex - 1);
            this.pagesShown.push(this.supportOrderQueryCriteria.PageIndex);
            this.pagesShown.push(this.supportOrderQueryCriteria.PageIndex + 1);
            this.pagesShown.push(this.supportOrderQueryCriteria.PageIndex + 2);

            if (this.pagesShown[0] < 0) {
                this.pagesShown = [0, 1, 2, 3, 4];
            }

            if (this.pagesShown[0] > this.maxPage - 4) {
                this.pagesShown = [
                    this.maxPage - 4,
                    this.maxPage - 3,
                    this.maxPage - 2,
                    this.maxPage - 1,
                    this.maxPage,
                ];
            }
        } else {
            this.pagesShown = [];
            for (let i = 0; i * 50 < this.totalOrders; i++) {
                this.pagesShown.push(i);
            }
        }

        this.itemsShownLowerBound =
            this.supportOrderQueryCriteria.PageIndex * this.supportOrderQueryCriteria.PageSize + 1;
        this.itemsShownUpperBound =
            this.supportOrderQueryCriteria.PageIndex * this.supportOrderQueryCriteria.PageSize + 50 > this.totalOrders
                ? this.totalOrders
                : this.supportOrderQueryCriteria.PageIndex * this.supportOrderQueryCriteria.PageSize + 50;
    }

    selectedPage(page: number): string {
        return this.supportOrderQueryCriteria.PageIndex === page ? 'selectedPage' : '';
    }

    isOrderChecked(orderId: number): boolean {
        return this.selectedOrderIds.indexOf(orderId) > -1;
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    setOrderChecked($event: any): void {
        const val: number = parseInt($event.currentTarget.value, 10);
        const index = this.selectedOrderIds.indexOf(val);

        if ($event.currentTarget.checked) {
            if (index < 0) {
                this.selectedOrderIds.push(val);
            }
        } else if (index > -1) {
            this.selectedOrderIds.splice(index, 1);
        }
    }

    setSelectAll(isChecked: boolean): void {
        if (isChecked === true) {
            this.selectedOrderIds = this.orderInfoMinimalGrid.Items.map((o) => o.Id);
            this.filterDirectOrdersForm.controls['selectedOrderIds'].patchValue(
                this.orderInfoMinimalGrid.Items.map((o) => o.Id),
            );
        } else {
            this.selectedOrderIds = [];
            this.filterDirectOrdersForm.controls['selectedOrderIds'].patchValue(this.selectedOrderIds);
        }
    }

    async approveOrders(): Promise<void> {
        const request = new UpdateOrderStatus();
        request.OrderStatusChanges = this.selectedOrderIds.map(
            (orderId) =>
                <OrderStatusChange>{
                    OrderId: orderId,
                    StatusId: OrderStatuses.Approved,
                },
        );

        await lastValueFrom(this.orderService.updateOrderStatus(request));

        this.findDirectOrders();

        this.supportEventService.announceBlockedOrdersApproved();
    }

    private addOrRemovePerosRelatedColumns() {
        //Initial Order Reference Index if exists
        const directOrderPerosIndex = this.directOrderSearchFields.findIndex((x) => x === this.perosOrderNumber);
        const supportOrderPerosIndex = this.supportOrderSearchfields.findIndex((x) => x === this.perosOrderNumber);

        if (this.perosEnabled) {
            //Initial Order Reference Index if exists
            const directOrderReferenceIndex1 = this.directOrderSearchFields.findIndex((x) => x === this.orderReference);
            const supportOrderReferenceIndex = this.supportOrderSearchfields.findIndex(
                (x) => x === this.orderReference,
            );

            //placement column index, index just before orderReference should be added to
            const directOrderProposalTypeNameIndex = this.directOrderSearchFields.findIndex(
                (x) => x === this.proposalTypeName,
            );
            const supportProposalTypeNameIndex = this.supportOrderSearchfields.findIndex(
                (x) => x === this.proposalTypeName,
            );

            if (directOrderReferenceIndex1 === -1 && directOrderProposalTypeNameIndex > -1) {
                this.directOrderSearchFields.splice(directOrderProposalTypeNameIndex, 0, this.orderReference);
            }
            if (supportOrderReferenceIndex === -1 && supportProposalTypeNameIndex > -1) {
                this.supportOrderSearchfields.splice(supportProposalTypeNameIndex, 0, this.orderReference);
            }

            //placement column index, index just before perosOrderNumber should be added to
            const directOrderReferenceIndex2 = this.directOrderSearchFields.findIndex((x) => x === this.orderReference);
            const supporterNameIndex = this.supportOrderSearchfields.findIndex((x) => x === this.supporterName);

            if (directOrderPerosIndex === -1 && directOrderReferenceIndex2 > -1) {
                this.directOrderSearchFields.splice(directOrderReferenceIndex2, 0, this.perosOrderNumber);
            }
            if (supportOrderPerosIndex === -1 && supporterNameIndex > -1) {
                this.supportOrderSearchfields.splice(supporterNameIndex, 0, this.perosOrderNumber);
            }
        } else {
            if (directOrderPerosIndex > -1) {
                this.directOrderSearchFields.splice(directOrderPerosIndex, 1);
            }
            if (supportOrderPerosIndex > -1) {
                this.supportOrderSearchfields.splice(supportOrderPerosIndex, 1);
            }
            //Order Reference find new index because the above lines have altered indexing
            const supportOrderReferenceIndex = this.supportOrderSearchfields.findIndex(
                (x) => x === this.orderReference,
            );
            const directOrderReferenceIndex = this.directOrderSearchFields.findIndex((x) => x === this.orderReference);

            if (supportOrderReferenceIndex > -1) {
                this.supportOrderSearchfields.splice(supportOrderReferenceIndex, 1);
            }
            if (directOrderReferenceIndex > -1) {
                this.directOrderSearchFields.splice(directOrderReferenceIndex, 1);
            }
        }
    }

    exportOrders(): void {
        this.orderService.exportOrders(this.selectedOrderIds);
    }
}
