import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataSheet, ListOption } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class DataSheetService {
    private readonly basePath = 'api/datasheets';

    constructor(private readonly httpClient: HttpClient) {}

    public getById(dataSheetId: number): Observable<DataSheet> {
        return this.httpClient.get<DataSheet>(`${this.basePath}/${dataSheetId}`);
    }

    public save(dataSheet: DataSheet): Observable<DataSheet> {
        return this.httpClient.post<DataSheet>(this.basePath, dataSheet);
    }

    public remove(dataSheetId: number): Observable<void> {
        return this.httpClient.delete<void>(`${this.basePath}/${dataSheetId}`);
    }

    public getAgreementDataSheets(): Observable<DataSheet[]> {
        return this.httpClient.get<DataSheet[]>(`${this.basePath}/agreements`);
    }

    public getAllDatasheets(): Observable<ListOption[]> {
        return this.httpClient.get<ListOption[]>(`${this.basePath}/options`);
    }
}
