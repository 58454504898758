import { QueryCriteria } from '@app/shared/models/queryCriteria.model';
import { OrderInfoMinimal } from '@app/shared/models/orderInfoMinimal.model';

export class OrderInfoMinimalQueryCriteria extends QueryCriteria<OrderInfoMinimal> {
    constructor(orderQueryCriteria?: OrderInfoMinimalQueryCriteria) {
        super(orderQueryCriteria);

        if (orderQueryCriteria != null) {
            this.ProductName = orderQueryCriteria.ProductName;
            this.OpticianName = orderQueryCriteria.OpticianName;
            this.Date = orderQueryCriteria.Date;
        }
    }

    public ProductName: string;
    public OpticianName: string;
    public Date: Date;
    public ShowNoPoOrderEntered: boolean;
    public ShowBlockedOrder: boolean;

    public setDefault(): void {
        this.OrderByAscending = true;
        this.OrderByField = 'Date';
        this.PageIndex = 0;
        this.PageSize = 50;
        this.UsePaging = true;
        this.ShowNoPoOrderEntered = false;
        this.ShowBlockedOrder = false;
    }
}
